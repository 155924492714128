import { Button } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { FC, useContext, useState } from "react";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import DealerDataCtx from "../../../../userData/DealerDataCtx";
import CustomerPricingModal from "./CustomerPricingModal";
import DealerPricingModal from "./DealerPricingModal";

interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
};

const ProposalInformationDiscountsSectionRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const user = useContext(DealerDataCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const [showDealerPricingModal, setShowDealerPricingModal] = useState(false);
    const [showCustomerPricingModal, setShowCustomerPricingModal] = useState(false);

    return (
        <>
            <Button onClick={()=> setShowDealerPricingModal(true)}>{i18next.format(t('common-phrases.sltDealerPricing'), 'capitalize-each')}</Button>
            <DealerPricingModal layoutId={props.layoutId} systemId={props.systemId} dbPrj={props.dbPrj} show={showDealerPricingModal} onClose={() => setShowDealerPricingModal(false)}/>
            <Button onClick={()=> setShowCustomerPricingModal(true)}>{i18next.format(t('common-phrases.sltCustomerPricing'), 'capitalize-each')}</Button>
            <CustomerPricingModal layoutId={props.layoutId} systemId={props.systemId} dbPrj={props.dbPrj} show={showCustomerPricingModal} onClose={() => setShowCustomerPricingModal(false)}/>
        </>
    );
};

export default ProposalInformationDiscountsSectionRenderer;