/* 
    SUPERCEEDED, not removed so to not crash any DB relient on this action
    USE: SetSpansV2
*/

import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { SideEnum, getSide } from 'rdptypes/helpers/SideEnum';
import { BuckBoostTypes, GearDriveTypes, SpanTypes, TowerHeights, WrapAroundSpanTypes } from 'rdptypes/project/ISystemBase.AutoGenerated';
import { ISpan } from 'rdptypes/project/Types';
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';
import { renumberSpanAndTowers, switchSidesIfNeeded } from './helpers/spans';

const actionTypeId = "SetSpanTowers";

export interface ISetSpanData {
    lengthFeet: number;
    wrapAroundSpan?: WrapAroundSpanTypes;
    clockwiseWrapAngleRelativeToPreviousSpanDegrees?: number;
    anticlockwiseWrapAngleRelativeToPreviousSpanDegrees?: number;
    dropSpanStartRelativeToPreviousSpanStart?: number;
    dropSpanEndRelativeToPreviousSpanEnd?: number;

    spacing?: number;
    wireGauge?: number;
    spanType?: SpanTypes;
    
    towerType?: TowerHeights;
    centerDrive?: GearDriveTypes;

    extension?: boolean;
}
interface ActionData extends IActionData {
    layoutId: string;
    systemId: string;
    forwardSpans: ISetSpanData[];
    aftSpans?: ISetSpanData[];
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const sys = state.layouts[data.layoutId].systems[data.systemId];

    const flangedSide = getSide(sys, SideEnum.Flanged);
    const flexSide = getSide(sys, SideEnum.Flex);

    // flanged side
    {
        let flangedSideEndBoom: ISpan | undefined;
        if (flangedSide.Span.length !== 0 && flangedSide.Span[flangedSide.Span.length - 1].EndBoom) {
            flangedSideEndBoom = flangedSide.Span.pop();
        }
        let flangedSideSwingArm: ISpan | undefined;
        if (flangedSide.Span.length !== 0 && flangedSide.Span[flangedSide.Span.length - 1].SwingArm) {
            flangedSideSwingArm = flangedSide.Span.pop();
        }
    
        flangedSide.Tower = []
        flangedSide.Span = []
        data.forwardSpans.forEach((x, idx) => {
            const newId = idx + 1;
            const disconnecting = x.dropSpanStartRelativeToPreviousSpanStart !== undefined || x.dropSpanEndRelativeToPreviousSpanEnd !== undefined;
            flangedSide.Span.push({
                SpanNumber: newId, 
                Disconnecting: disconnecting, 
                Length: x.lengthFeet,
                dropSpanEndRelativeToPreviousSpanEnd: x.dropSpanEndRelativeToPreviousSpanEnd,
                dropSpanStartRelativeToPreviousSpanStart: x.dropSpanStartRelativeToPreviousSpanStart,
                SpanType: x.spanType,
                Spacing: x.spacing,
                MinCableGauge: x.wireGauge,
                Extension: x.extension
            }); 
            flangedSide.Tower.push({
                TowerNumber: newId, 
                WrapAroundSpan: x.wrapAroundSpan || WrapAroundSpanTypes.None, 
                Tires: {},
                clockwiseWrapAngleRelativeToPreviousSpanDegrees: x.clockwiseWrapAngleRelativeToPreviousSpanDegrees,
                anticlockwiseWrapAngleRelativeToPreviousSpanDegrees: x.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
                TowerType: x.towerType,
                CenterDrive: x.centerDrive,
                BuckBoost: BuckBoostTypes.aNone
            }); 
        })
        if (flangedSideSwingArm) {
            flangedSide.Span.push(flangedSideSwingArm);
        }
        if (flangedSideEndBoom) {
            flangedSide.Span.push(flangedSideEndBoom);
        }
    }
    
    // flex side
    if (sys.lateral) {
        let flexSideEndBoom: ISpan | undefined;
        if (flexSide.Span.length !== 0 && flexSide.Span[flexSide.Span.length - 1].EndBoom) {
            flexSideEndBoom = flexSide.Span.pop();
        }
        let flexSideSwingArm: ISpan | undefined;
        if (flexSide.Span.length !== 0 && flexSide.Span[flexSide.Span.length - 1].SwingArm) {
            flexSideSwingArm = flexSide.Span.pop();
        }
    
        flexSide.Tower = []
        flexSide.Span = []
        if (data.aftSpans) {
            data.aftSpans.forEach((x, idx) => {
                const newId = idx + 1;
                const disconnecting = x.dropSpanStartRelativeToPreviousSpanStart !== undefined || x.dropSpanEndRelativeToPreviousSpanEnd !== undefined;
                flexSide.Span.push({
                    SpanNumber: newId, 
                    Disconnecting: disconnecting, 
                    Length: x.lengthFeet,
                    dropSpanEndRelativeToPreviousSpanEnd: x.dropSpanEndRelativeToPreviousSpanEnd,
                    dropSpanStartRelativeToPreviousSpanStart: x.dropSpanStartRelativeToPreviousSpanStart,
                    SpanType: x.spanType,
                    Spacing: x.spacing,
                    MinCableGauge: x.wireGauge,
                    Extension: x.extension
                }); 
                flexSide.Tower.push({
                    TowerNumber: newId, 
                    WrapAroundSpan: x.wrapAroundSpan || WrapAroundSpanTypes.None, 
                    Tires: {},
                    clockwiseWrapAngleRelativeToPreviousSpanDegrees: x.clockwiseWrapAngleRelativeToPreviousSpanDegrees,
                    anticlockwiseWrapAngleRelativeToPreviousSpanDegrees: x.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
                    TowerType: x.towerType,
                    CenterDrive: x.centerDrive
                }); 
            })
        }
        if (flexSideSwingArm) {
            flexSide.Span.push(flexSideSwingArm);
        }
        if (flexSideEndBoom) {
            flexSide.Span.push(flexSideEndBoom);
        }

        sys.flexIsFwd = false;
    }

    renumberSpanAndTowers(flangedSide);
    renumberSpanAndTowers(flexSide);
    switchSidesIfNeeded(sys);
    
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});

export const createSetSpanTowersAction = (
    layoutId: string,
    systemId: string,
    data: {
        forwardSpans: ISetSpanData[],
        aftSpans?: ISetSpanData[]
    },
    authState: IAuthState) => createAction(
        actionTypeId,
        {
            layoutId,
            systemId,
            ...data
        } as ActionData,
        authState);