import { Feature, LineString, lineString } from "@turf/turf";
import IAction from "rdptypes/IAction";
import { HoseFeedTypes, SystemTypes, WaterFeedTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { createNewMultiAction } from "../../../../actions/MultiAction";
import { createNewSystemActionV2 } from "../../../../actions/NewSystemActionV2";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import { createSystemDefaultActions } from "../../../../actions/defaultActions";
import IAuthState from "../../../../auth/IAuthState";
import { staticDevSettingsDbProvider } from "../../../../db/DevSettingsDbProvider";
import IDbProject from "../../../../db/IDbProject";
import { createLateralActionsFromOptimizedSystem } from "../../../../helpers/optimizedSystemHelpers/createLateralActionsFromOptimizedSystem";
import * as optimizeLateralModule from "../../../../optimization/lateral";
import { IInteractionState_DrawLateralSystem } from "../interactionState";


export const handleDrawLateralSystem = (
    features: Feature[],
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
    interactionState: IInteractionState_DrawLateralSystem,
    systemDefaults?: ((layoutId: string, systemId: string, authState: IAuthState) => IAction[])
) => {
    if (features.length !== 1 && features[0].geometry.type !== "LineString") {
        throw new Error(`Unexpected feature created`);
    }
    
    // laterals must be a straight line:
    const incommingLineStringPoints = (features[0] as Feature<LineString>).geometry.coordinates;
    if (incommingLineStringPoints.length < 2) {
        throw new Error(`Unexpected feature created`);
    }

    const systemType = interactionState.canalFeed
        ? SystemTypes.CanalFeedMaxigator
        : SystemTypes.HoseFeedMaxigator;

    const waterFeed = interactionState.endFeed
        ? WaterFeedTypes.EndFeed
        : WaterFeedTypes.CenterFeed;

    const pivotingLateral = systemType === SystemTypes.HoseFeedMaxigator &&
        waterFeed === WaterFeedTypes.EndFeed &&
        interactionState.pivotingLateral === true &&
        !interactionState.optimizationArgs;

    // NOTE: reverse the points so that fwd side if up if line drawn left->right in deshler, NE
    let line: LineString;
    if (pivotingLateral) {
        line = lineString(incommingLineStringPoints.reverse()).geometry;
    }
    else {
        line = lineString([
            incommingLineStringPoints[incommingLineStringPoints.length - 1],
            incommingLineStringPoints[0]
        ]).geometry;
    }
    


    if (interactionState.optimizationArgs) {
        const optimizedSystem = optimizeLateralModule.optimize({
            layoutId: layoutId!,
            project: dbPrj.state,
            optimizationSettings: {
                ...interactionState.optimizationArgs,
                feedLine: line
            }
        })
        if (optimizedSystem) {
            const { systemId, actions } = createLateralActionsFromOptimizedSystem({
                systemDefaults,
                canalFeed: interactionState.canalFeed,
                systemType,
                optimizedSystem,
                layoutId,
                authState,
                endFeed: interactionState.endFeed
            });
            if (actions) {
                dbPrj.pushAction(
                    createNewMultiAction(actions, authState)
                )
            }
        }
    }
    else {
        const customerSettings = staticDevSettingsDbProvider.customer.get();
        const dealerPricing = staticDevSettingsDbProvider.dealerPricing.get();
        const { systemId, action } = createNewSystemActionV2(
            layoutId!, systemType, 
            customerSettings, dealerPricing,
            authState
        );
        const actions: IAction[] = [action];
        actions.push(action);
        
        if (systemDefaults) {
            for (const def of systemDefaults(layoutId, systemId, authState)) {
                actions.push(def);
            }
        }
        else {
            actions.push(...createSystemDefaultActions(layoutId, systemId, authState));
        }

        actions.push(createNewUpdateSystemPropertyAction(
            layoutId!,
            systemId,
            "lateral.line",
            line,
            authState
        ));
        if (interactionState.canalFeed) {
            actions.push(createNewUpdateSystemPropertyAction(
                layoutId!,
                systemId,
                "lateral.canalWidthFeet",
                interactionState.canalFeed.canalWidthFeet,
                authState
            ));
            actions.push(createNewUpdateSystemPropertyAction(
                layoutId!,
                systemId,
                "lateral.distanceFromCanalCenterToFwdSide",
                interactionState.canalFeed.distanceFromCanalCenterToFwdSide,
                authState
            ));
            actions.push(createNewUpdateSystemPropertyAction(
                layoutId!,
                systemId,
                "lateral.distanceFromCanalCenterToAftSide",
                interactionState.canalFeed.distanceFromCanalCenterToAftSide,
                authState
            ));
        }
        else {
            if (pivotingLateral) {
                actions.push(createNewUpdateSystemPropertyAction(
                    layoutId!,
                    systemId,
                    "Lateral.HoseFeed.HoseFeedType",
                    HoseFeedTypes.PivotingLateral,
                    authState
                ));
            }
        }
        actions.push(createNewUpdateSystemPropertyAction(
            layoutId!,
            systemId,
            "Lateral.WaterFeed",
            waterFeed,
            authState
        ));

        dbPrj.pushAction(
            createNewMultiAction(actions, authState)
        );
    }
}
