import { Text, View } from "@react-pdf/renderer";
import { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { SystemSpanSide, getElectricalReportMotorInformation } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import IDevSettingsState from "../../db/IDevSettingsState";
import { DisplayLengthUnitBuilder } from "../../helpers/lengths";
import { documentStyles } from "../styles";
import FixedWidthTable, { IRow } from "./table/FixedWidthTable";

export interface Props {
    quote: QuoteClass;
}

export interface IElectricalMotorInformationTableRow {
    location: string;
    type: string;
    length: number;
    amps: number;
    totalAmps: number;
    kW: number;
    voltage: number;
    kva: number;
    cable: string;
}

const ElectricalMotorInformationTable: FC<Props> = (props) => {

    const settings = React.useContext(DevSettingsCtx);

    let {quote} = props;
    
    let elements: JSX.Element[] = [];
    let flangedSideMotors = getElectricalReportMotorInformation(quote, SystemSpanSide.flanged);
    
    if (flangedSideMotors.length){
        elements.push(<View style={documentStyles.heading}>
            <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltFlangedSide")}</Text>
        </View>);
        elements.push(getTableElement(flangedSideMotors, settings));
    }

    let flexSideMotors = getElectricalReportMotorInformation(quote, SystemSpanSide.flex);
    if (flexSideMotors.length){
        elements.push(<View style={documentStyles.heading}>
            <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltFlexSide")}</Text>
        </View>);
        elements.push(getTableElement(flexSideMotors, settings));
    }

    elements.push(<Text>{t("common-phrases.lsltDesignDisclaimer")}</Text>)

    return (<View style={documentStyles.section}>
            {elements}
        </View>
    );
};

const getTableElement = (motors: IElectricalMotorInformationTableRow[], settings: IDevSettingsState) => {
    let rows: IRow[] = [{cells: [{
        text: t("common-phrases.sltLocation"),
        widthPc: 17,
        header: true
    },
    {
        text: t("common-phrases.sltType"),
        widthPc: 12,
        header: true
    },
    {
        text: t("common-phrases.sltLength"),
        widthPc: 10,
        header: true
    },
    {
        text: t("common-phrases.sltAmps"),
        widthPc: 8,
        header: true
    },
    {
        text: t("common-phrases.sltTotalAmps"),
        widthPc: 14,
        header: true
    },
    {
        text: "kW",
        widthPc: 6,
        header: true
    },
    {
        text: t("common-phrases.sltVoltage"),
        widthPc: 11,
        header: true
    },
    {
        text: "KVA",
        widthPc: 8,
        header: true
    },
    {
        text: t("common-phrases.sltCable"),
        widthPc: 14,
        header: true
    }]}];

    motors.forEach((m) => {
        let length = new DisplayLengthUnitBuilder(m.length, 'feet')
            .convert(settings.dealerSettings.display.current.lengths)
            .appendValue(1)
            .appendString(" ")
            .appendSymbol()
            .toString();

        rows.push({cells: [{
            text: m.location,
            widthPc: 17
        },
        {
            text: m.type,
            widthPc: 12
        },
        {
            text: length,
            widthPc: 10
        },
        {
            text: m.amps.toFixed(1),
            widthPc: 8
        },
        {
            text: m.totalAmps.toFixed(1),
            widthPc: 14
        },
        {
            text: m.kW.toFixed(1),
            widthPc: 6
        },
        {
            text: m.voltage.toFixed(1),
            widthPc: 11
        },
        {
            text: m.kva.toFixed(1),
            widthPc: 8
        },
        {
            text: m.cable,
            widthPc: 14
        }]})});

    return <View style={{paddingLeft: 15, paddingRight: 15}}>
            <FixedWidthTable data={{rows}}/>
        </View>;
}


export default ElectricalMotorInformationTable;

