
// These enums can't be relied on to extract shape type as the enum values are reused several times
export enum EShapeType {
    Point,
    Line,
    EquipmentBoundary,
    WheelObstacle,
    SpanObstacle,
    PivotCenter,
    WheelTrack,
    Span = WheelTrack,
    EndBoom = WheelTrack,
    G_SAC = WheelTrack,
    N_SAC = WheelTrack,
    G_MSAC = WheelTrack,
    N_MSAC = WheelTrack,
    G_SSAC = WheelTrack,
    N_SSAC = WheelTrack,
    ElectricLine = Line,
    WaterLine = Line,
    Pump = Point,
    Cross = Point,
    Label = Point,
    Canal = Line,
    WetAreaBoundary = EquipmentBoundary,
    PivotCenterBoundary = EquipmentBoundary,
    PowerTrack = WheelTrack,
}
export interface IRapFilePoint {
    Longitude: number;
    Latitude: number;
    Altitude: null;
}
export type RapFileStyles = 
    'EquipmentBoundary' |
    'SpanObstacle' |
    'WheelObstacle' |
    'WetAreaBoundary' |
    'PivotCenterBoundary' |
    'PowerTrack' |
    'WaterLine' |
    'ElectricLine' |
    'Line' |
    'Canal' |
    'PivotCenter' |
    'Pump' |
    'Cross' |
    'Label' |
    'Point';

export interface IRapFileShape {
    ShapeType: EShapeType;
    Points: IRapFilePoint[];
    Label: string;
    ShapeName: string;
    Style: RapFileStyles; 
    Complete: boolean;

}

export interface IRapFile {
    ProjectData: {
        Id: string;
        GrowerName: string;
        FieldName: string;
        DateTicksCreated: number;
        DateTicksLastModified: number;
        Complete: boolean;
        Shapes: IRapFileShape[];
    }
    HardwareId: string;
    HardwareIds: string[];
}

export const APP_HARDWARE_IDS = [ '123456' ];
export const RDP_HARDWARE_ID = 'RDPEXPORT';
export const RAP_PASSWORD = "XUvkp2f9mg)f]Gz.YFkAVLpJ7V$'F<";
