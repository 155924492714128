import { SACZoneControlTypes, SwingArmLengths } from "rdptypes/project/ISystemBase.AutoGenerated";
import { GetOrCreateValue } from "../OtherHelpers";

export class ESACZoneOutletRelations {
    private data = new Map<SACZoneControlTypes, Map<SwingArmLengths, Map<number, number>>>();
    constructor() {
        this.Init();
    }

    public SelectZone = (zct: SACZoneControlTypes, sal: SwingArmLengths, outlet: number): number => {
        try {
            return this.data.get(zct).get(sal).get(outlet);
        } catch (ex) {
            throw new Error();
            /* TODO: Throw New Exception($"Error in {NameOf(SelectZone)} with parameters ({zct}, {sal}, {outlet})", ex) */
        }
    }

    private InitData = (zct: SACZoneControlTypes, sal: SwingArmLengths, zones: number[]) => {
        try {
            let sals = GetOrCreateValue(this.data, zct, () => new Map<SwingArmLengths, Map<number, number>>());
            let outlets = GetOrCreateValue(sals, sal, () => new Map<number, number>())
            let outlet: number = 0;
            zones.forEach((zone) => {
                outlet += 1;
                outlets.set(outlet, zone);
            }
            );
        } catch (ex) {
            throw new Error();
            /* TODO: Throw New Exception($"Error in {NameOf(Init)} with parameters ({zct}, {sal}, factors:{{ {String.Join(",", zones)} }}", ex) */
        }
    }

    private Init = () => {
        this.InitData(SACZoneControlTypes.ESAC060, SwingArmLengths.SAC156, [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6 ]);
        this.InitData(SACZoneControlTypes.ESAC060, SwingArmLengths.SAC175, [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6 ]);
        this.InitData(SACZoneControlTypes.ESAC060, SwingArmLengths.SAC194, [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6 ]);
        this.InitData(SACZoneControlTypes.ESAC060, SwingArmLengths.SAC213, [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6 ]);

        this.InitData(SACZoneControlTypes.ESAC120, SwingArmLengths.SAC156, [ 1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6, 7, 7, 7, 7, 8, 8, 8, 8, 9, 9, 9, 9, 10, 10, 11, 11, 12, 12 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SwingArmLengths.SAC175, [ 1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6, 7, 7, 7, 7, 8, 8, 8, 8, 9, 9, 9, 9, 10, 10, 10, 10, 11, 11, 11, 11, 12, 12, 12, 12 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SwingArmLengths.SAC194, [ 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 7, 7, 7, 7, 8, 8, 8, 8, 9, 9, 9, 9, 10, 10, 10, 10, 11, 11, 11, 11, 12, 12, 12, 12 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SwingArmLengths.SAC213, [ 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 7, 7, 7, 7, 7, 7, 8, 8, 8, 8, 8, 9, 9, 9, 9, 9, 9, 10, 10, 10, 10, 11, 11, 11, 11, 12, 12, 12, 12, 12 ]);

        this.InitData(SACZoneControlTypes.ESAC125, SwingArmLengths.SAC156, [ 1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6, 7, 7, 7, 7, 8, 8, 8, 8, 9, 9, 9, 9, 10, 10, 11, 11, 12, 12 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SwingArmLengths.SAC175, [ 1, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6, 7, 7, 7, 7, 8, 8, 8, 8, 9, 9, 9, 9, 10, 10, 10, 10, 11, 11, 11, 11, 12, 12, 12, 12 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SwingArmLengths.SAC194, [ 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 7, 7, 7, 7, 8, 8, 8, 8, 9, 9, 9, 9, 10, 10, 10, 10, 11, 11, 11, 11, 12, 12, 12, 12 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SwingArmLengths.SAC213, [ 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 7, 7, 7, 7, 7, 7, 8, 8, 8, 8, 8, 9, 9, 9, 9, 9, 9, 10, 10, 10, 10, 11, 11, 11, 11, 12, 12, 12, 12, 12 ]);
    }

}

