import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import IDbProject from "../../../../db/IDbProject";
import DealerPricingRenderer from "../../../DealerSettingsDialog/DealerPricingRenderer";

interface Props {
    onClose: () => void;
    show: boolean;
    layoutId: string;
    systemId: string;
    dbPrj: IDbProject;
}

const DealerPricingModal: React.FC<Props> = ({
    onClose, show, layoutId, systemId, dbPrj
}) => {

    return (
        <Dialog
            open={show}
            onClose={onClose}
        >
            <DialogTitle id="dealer-pricing-modal-title">
                {i18next.format(t('common-phrases.sltDealerPricing'), 'capitalize-each')}
            </DialogTitle>
            <DialogContent>
                <DealerPricingRenderer proposalSettings={{layoutId, systemId, dbPrj}}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DealerPricingModal;