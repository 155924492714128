import { Text, View } from "@react-pdf/renderer";
import { t } from "i18next";
import { DeviceTypes } from "rdptypes/project/ISprinklers";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import { BoosterPumpText, EndGunText, SimpleEndGunText, SimpleValveText } from "roedata/roe_migration/CommonFunctions";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { FieldSets, HasPowerTowerEndBoom, IsCenterFeed, IsDualSided } from "roedata/roe_migration/SystemFunctions";
import { PackageDescriptorBuilder, SpacingBuilder, SystemSpanSide, getESPRelatedLoss, getSystemAreaAcres, getSystemGPM, getSystemHoursToApplyOneInch, getSystemIrrigatedAcres, getSystemMaxGPM, getSystemMinGPM, getSystemTopOfInletPressurePsi, getValuesFromFieldDesign } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { DisplayAreaUnitBuilder } from "../../helpers/areas";
import IProject from "../../model/project/IProject";
import { documentStyles } from "../styles";
import FixedWidthTable, { ICell, IRow } from "./table/FixedWidthTable";

interface Props {
    quote: QuoteClass;
    systemId: string;
    layoutId: string;
    project: IProject;
};

const SprinklerPackageInformationTable: FC<Props> = (props) => {
    const settings = React.useContext(DevSettingsCtx);
    let {quote, layoutId, systemId, project} = props;
    let rows: IRow[] = [];

    let gpm = getSystemGPM(quote);
    let maxGpm = getSystemMaxGPM(quote);
    let minGpm = getSystemMinGPM(quote);

    const ns = "common-phrases."

    let cells: ICell[] = [{
        text: `${t(ns + "sltMaxGPM")}: ${typeof maxGpm === "number" ? maxGpm.toFixed(1): "N/A"}`,
        widthPc: 30
    },
    {
        text: `${t(ns + "sltSystemGPM1")}: ${typeof gpm === "number" ? getSystemGPM(quote).toFixed(1) : "N/A"}`,
        widthPc: 30
    }, 
    {
        text: `${t(ns + "sltMinGPM")}: ${typeof minGpm === "number" ? minGpm.toFixed(1) : "N/A"}`,
        widthPc: 30
    }];

    rows.push({cells});

    let esppsi = getESPRelatedLoss(quote);
    let hasPowerTowerEndBoom = HasPowerTowerEndBoom(quote.System);
    let isCenterFeed = IsCenterFeed(quote.System);
    
    let endPressure = quote.System?.FlangedSide?.SprinklerChart?.IrrigationProperties?.ComputedEndPressure ?? 0;
    let elevation = quote.System?.SprinklerProperties?.PeakElevation ?? 0;

    let endgun1 = quote.RightEOSClass.FieldSet.DataValid() ? (FieldSets(quote.System).SprinklerChart.DataValid() ? SimpleEndGunText(quote.System.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.EndGunTypeCalculated) : 
    SimpleEndGunText(quote.System.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary)) : "N/A";

    if (endgun1 !== "N/A"){
        endgun1 += "/" + SimpleValveText(quote.System.FlangedSide.EndOfSystem.EndGun.Valve)
    }

    let endgun2 = quote.RightEOSClass && quote.RightEOSClass.FieldSet.DataValid() ? SimpleEndGunText(quote.System.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary) : "N/A";
    if (endgun2 !== "N/A"){
        endgun2 += "/" + SimpleValveText(quote.System.FlangedSide.EndOfSystem.EndGun.SecondaryValve)
    }

    //weird logic but I think this follows from ReportData.vb which tracks EndGun2 and LeftEndGun
    if (quote.LeftEOSClass && quote.LeftEOSClass.FieldSet.DataValid()){
        if (!quote.SprinklerChartClass.FieldSet.DataValid()){
            if (hasPowerTowerEndBoom || isCenterFeed){
                endgun2 = SimpleEndGunText(quote.System.FlexSide.EndOfSystem.EndGun.EndGunTypePrimary) + "/" + SimpleValveText(quote.System.FlexSide.EndOfSystem.EndGun.Valve);
            }
            else {
                endgun2 = SimpleEndGunText(quote.System.FlexSide.EndOfSystem.EndGun.EndGunTypeSecondary) + "/" + SimpleValveText(quote.System.FlexSide.EndOfSystem.EndGun.SecondaryValve);
            }
        }
        else if (hasPowerTowerEndBoom){
            endgun2 = EndGunText(quote.System.FlexSide.EndOfSystem.EndGun.EndGunTypePrimary, quote.System.FlexSide.EndOfSystem.EndGun.EndGunTypeSecondary, quote.System.FlexSide.EndOfSystem.EndGun.Valve);
        }
    }

    let boosterPump = BoosterPumpText(quote.System.FlangedSide.EndOfSystem.EndGun.BoosterPump);
    let topOfInletPressure = getSystemTopOfInletPressurePsi(quote);

    cells = [{
        text:  `${hasPowerTowerEndBoom ? (t(ns + "sltSecTowerEndPressure")) : isCenterFeed ? (t(ns +"sltRightEndPressure")) : (t(ns + "sltEndPressure1" ))}: ${endPressure.toFixed(0)} ${t(ns + "sltPSI")}`,
        widthPc: 30
    },
    {
        text: `${t(ns + "sltPivotTopPressure")}: ${typeof topOfInletPressure === "number" ? topOfInletPressure.toFixed(0) + " " +  t(ns + "sltPSI"): "N/A"}`,
        widthPc: 30
    }, {
        text: `${t(ns + "sltElevation")}: ${elevation.toFixed(0)}'`,
        widthPc: 30
    }];

    rows.push({cells});

    cells = [{
        text: `${hasPowerTowerEndBoom ? (t(ns + "sltSecTowerEndGun")) : isCenterFeed ? (t(ns + "sltRightEndGun")) : endgun2 ? (t(ns + "slt1stEndGun")) : (t(ns + "sltEndGun"))}: ${endgun1}`,
        widthPc: 30
    },
    {
        text: `${hasPowerTowerEndBoom ? (t(ns + "sltSecTowerBoosterPump")) : isCenterFeed ? (t(ns+"sltRightBoosterPump")) : (t(ns + "sltBoosterPump"))}: ${boosterPump}`,
        widthPc: 30
    }];

    if (!isCenterFeed && esppsi > 0){
        cells.push({
            text: `${t(ns + "sltESPPSI")}: ${esppsi.toFixed(0)}`,
            widthPc: 30
        })
    }
    else if (isCenterFeed || hasPowerTowerEndBoom){
        cells.push({
            text: `${isCenterFeed ? (t(ns + "sltRightSideGPM")) : (t(ns+"sltMainSystemGPM"))}: ${quote.System?.FlangedSide?.SprinklerChart?.IrrigationProperties?.SideGPM.toFixed(0) ?? "N/A"}`,
            widthPc: 30
        })
    }

    rows.push({cells});

    if (endgun2){
        cells = [{
            text: `${hasPowerTowerEndBoom ? (t(ns + "sltPowerTowerEndGun")) : isCenterFeed ? (t(ns + "sltLeftEndGun")) : (t(ns +"slt2ndEndGun"))}: ${endgun2}`,
            widthPc: 30
        }];

        if (isCenterFeed || hasPowerTowerEndBoom){
            cells.push({
                text: `${isCenterFeed ? (t(ns + "sltLeftBoosterPump")) : (t(ns + "sltPowerBoosterPump"))}: ${BoosterPumpText(quote.System.FlexSide.EndOfSystem.EndGun.BoosterPump)}`,
                widthPc: 30
            }, {
                text: `${isCenterFeed ? (t(ns + "sltLeftSideGPM")) : (t(ns + "sltPowerEndBoomGPM"))}: ${quote.System?.FlexSide?.SprinklerChart?.IrrigationProperties?.SideGPM.toFixed(0) ?? "N/A"}`,
                widthPc: 30
            });
        }

        rows.push({cells});
    }

    let valuesFromFieldDesign = getValuesFromFieldDesign(quote, systemId, layoutId, project);

    let systemArea = quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro ? new DisplayAreaUnitBuilder(getSystemAreaAcres(quote, valuesFromFieldDesign), 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(1)
        .appendString(" ")
        .appendFullName()
        .toString() : "Unknown";

    let irrigatedArea = quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro ? new DisplayAreaUnitBuilder(getSystemIrrigatedAcres(quote, valuesFromFieldDesign), 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(2)
        .appendString(" ")
        .appendFullName()
        .toString() : "Unknown";

    cells = [{
        text: `${t(ns + "sltHoursToApplyOneInch")}: ${typeof getSystemHoursToApplyOneInch(quote, valuesFromFieldDesign) === "undefined" ? "N/A" : getSystemHoursToApplyOneInch(quote, valuesFromFieldDesign).toFixed(2)}`,
        widthPc: 30
    }, {
        text: `${t(ns + "sltSystemArea")}: ${systemArea}`,
        widthPc: 30
    }, {
        text: `${t(ns + "sltTotalArea")}: ${irrigatedArea}`,
        widthPc: 30
    }];

    rows.push({cells});

    let dualSprinklerPackageEndGunOperationDescriptor: string = null;
    let latMode = quote.System.SprinklerProperties.EndGunOperationLateralMode;
    let pivotMode = quote.System.SprinklerProperties.EndGunOperationPivotMode;
    if (latMode || pivotMode){
        dualSprinklerPackageEndGunOperationDescriptor = "End Gun will operate in ";
        if (latMode && pivotMode){
            dualSprinklerPackageEndGunOperationDescriptor += "Lateral Mode & Pivot Mode";
        }
        else if (latMode){
            dualSprinklerPackageEndGunOperationDescriptor += "Lateral Mode only";
        }
        else{
            dualSprinklerPackageEndGunOperationDescriptor += "Pivot Mode only;"
        }
    }

    return (
        <View style={documentStyles.section}>
            <View style={documentStyles.heading}>
                <Text style={documentStyles.sectionHeader}>{t(ns + "sltSprinklerPackageInfo")}</Text>
            </View>
            <View style={{paddingLeft: 15, paddingRight: 15}}>
                <FixedWidthTable
                    data={{rows}}
                />
                {quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && <Text>{PackageDescriptorBuilder(quote.System.FlangedSide.Sprinklers.Package[0], "Flanged Pkg 1", settings.dealerSettings.display.current, quote, SystemSpanSide.flanged)}</Text>}
                {
                    quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro &&
                    quote.System.FlangedSide.Sprinklers.Package[1] &&
                    quote.System.FlangedSide.Sprinklers.Package[1].Device !== DeviceTypes.None && 
                    quote.System.FlangedSide.Sprinklers.Package[1].Device !== null && <Text>{PackageDescriptorBuilder(quote.System.FlangedSide.Sprinklers.Package[1], "Flanged Pkg 2", settings.dealerSettings.display.current, quote, SystemSpanSide.flanged)}</Text>
                }
                {
                    quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro &&
                    quote.System.FlangedSide.Sprinklers.Package[2] &&
                    quote.System.FlangedSide.Sprinklers.Package[2].Device !== DeviceTypes.None && 
                    quote.System.FlangedSide.Sprinklers.Package[2].Device !== null && <Text>{PackageDescriptorBuilder(quote.System.FlangedSide.Sprinklers.Package[2], "Flanged Pkg 3", settings.dealerSettings.display.current, quote, SystemSpanSide.flanged)}</Text>
                }
                {
                    quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro &&
                    dualSprinklerPackageEndGunOperationDescriptor !== null && <Text>{dualSprinklerPackageEndGunOperationDescriptor}</Text>
                }
                {quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && <Text>{SpacingBuilder(quote, SystemSpanSide.flanged, "Flanged Side")}</Text>}
                {
                    IsDualSided(quote.System) && <Text>{PackageDescriptorBuilder(quote.System.FlexSide.Sprinklers.Package[0], "Flex Pkg 1", settings.dealerSettings.display.current, quote, SystemSpanSide.flex)}</Text>
                }
                {
                    IsDualSided(quote.System) && 
                    quote.System.FlexSide.Sprinklers.Package[1] &&
                    quote.System.FlexSide.Sprinklers.Package[1].Device !== DeviceTypes.None &&
                        quote.System.FlexSide.Sprinklers.Package[1].Device !== null && <Text>{PackageDescriptorBuilder(quote.System.FlexSide.Sprinklers.Package[1], "Flex Pkg 2", settings.dealerSettings.display.current, quote, SystemSpanSide.flex)}</Text>
                }
                {
                    IsDualSided(quote.System) && 
                    quote.System.FlexSide.Sprinklers.Package[2] &&
                    quote.System.FlexSide.Sprinklers.Package[2].Device !== DeviceTypes.None &&
                        quote.System.FlexSide.Sprinklers.Package[2].Device !== null && <Text>{PackageDescriptorBuilder(quote.System.FlexSide.Sprinklers.Package[2], "Flex Pkg 3", settings.dealerSettings.display.current, quote, SystemSpanSide.flex)}</Text>
                }
                {
                    IsDualSided(quote.System) && <Text>{SpacingBuilder(quote, SystemSpanSide.flex, "Flex Side")}</Text>
                }
            </View>
        </View>
    );
};

export default SprinklerPackageInformationTable;