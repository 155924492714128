import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, Typography } from "@mui/material";
import ICard from "rdptypes/roe/ICard";
import * as React from "react";
import { FC, useState } from "react";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import IDbProject from "../../../db/IDbProject";
import { translateUnit } from "../../../helpers/translation";
import { pageStyles } from "../styles";
import ComponentListRenderer from "./ComponentListRenderer";
import IComponentRenderContext from "./IComponentRenderContext";

interface Props {
    cmp: ICard,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    ctx: IComponentRenderContext,
    ignoreImgTooltips?: boolean,
};


const CardRenderer: FC<Props> = (props) => {

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const [cardOpen, setCardOpen] = useState(false);
    let parentGroupLabel = props.cmp.title !== "" ? props.cmp.title : null;

    const cardStyle = props.cmp.noOutline ? pageStyles.cardStyle.notOutlined : pageStyles.cardStyle.outlined;
    const settings = React.useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;

    const collapsable = props.cmp.collapsable === true
        || (typeof props.cmp.collapsable === "function" && props.cmp.collapsable(system));

    return (
        <>
            <Card style={cardStyle} variant={props.cmp.noOutline ? "elevation" : "outlined"} >           
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    {props.cmp.title && props.cmp.title !== "" &&
                        <Typography sx={pageStyles.cardTitleStyle}>{translateUnit(props.cmp, displaySettings)}</Typography>
                    }
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: "flex-end"}}>
                        {
                            collapsable &&
                            <div onClick={() => setCardOpen(!cardOpen)} style={{paddingLeft: 10}}>
                            {
                                cardOpen && <ExpandLessIcon />
                            }
                            {
                                !cardOpen && <ExpandMoreIcon />
                            }
                            </div>
                        }
                    {props.cmp.image && <img src={`data:image/jpeg;base64,${props.cmp.image}`} />}
                    </div>              
                </div>
                      
                {props.cmp.children && (!collapsable || cardOpen) &&
                        <ComponentListRenderer
                            parentGroupLabel={parentGroupLabel}
                            oneColumn={props.cmp.forceOneColumn || false}
                            components={props.cmp.children}
                            dbPrj={props.dbPrj}
                            ignoreImgTooltips={props.ignoreImgTooltips}
                            layoutId={props.layoutId}
                            inline={props.cmp.childrenInline}
                            systemId={props.systemId}
                            ctx={props.ctx}
                            />
                }
            </Card>
        </>
    );
};

export default CardRenderer;