import { AlertTitle } from "@mui/material";
import Alert from "@mui/material/Alert";
import * as React from "react";
import DealerDataCtx from "../userData/DealerDataCtx";

import LockIcon from "@mui/icons-material/Lock";

const FeatureNotEnabled: React.FC<{}> = () => {
    const user = React.useContext(DealerDataCtx);

    return (
        <Alert icon={<LockIcon fontSize="inherit" />} severity="warning">
            <AlertTitle>Feature not activated</AlertTitle>
            This feature is not currently activated for your dealership. To request access, please email reinkedesigncenter@reinke.com or call 402-365-7251.
        </Alert>
    );
}

export default FeatureNotEnabled;