import { Point, convertLength, destination, feature, featureCollection, polygon } from "@turf/turf";
import { IPartialPivotOptimizerResult } from ".";
import { ILosV3Input, IResult, ITriangleSector, generateLineOfSightTrianglesV3 } from "../lineOfSightTriangles.v3";

interface ITriangleSectorSolution {
    radius: number;
    leftBearing: number;
    rightBearing: number;
}

const calcBearing = (p: number[]): number => {
    const angle = Math.atan2(p[0], p[1]);
    let bearing = angle * 180 / Math.PI;
    return (bearing + 360) % 360;
}

const findLineArcIntersections = (p1: number[], p2: number[], cp: number[], radius: number) => {
    const x1 = p1[0] - cp[0];
    const x2 = p2[0] - cp[0];
    const y1 = p1[1] - cp[1];
    const y2 = p2[1] - cp[1];
    const dx = (x2 - x1);
    const dy = (y2 - y1); 

    let i1: number[];
    let i2: number[];
    if (dx === 0) {
        // then line is: x = c
        // using y2 + x2 = r2:
        // y = +/- sqrt(r2 - x2)
        if (x1 > radius) {
            // no intersections:
            return [];
        }
        const y = Math.sqrt(radius * radius - x1 * x1);
        i1 = [ x1, y ];
        i2 = [ x1, -y ];
    }
    else {
        const m = dy / dx;
        const c = y1 - m * x1;
    
        const p = 0;
        const q = 0;
        const r = radius;
    
        const A = m * m + 1;
        const B = 2 * (m * c - m * q - q);
        const C = (q * q - r * r + p * p - 2 * c * q + c * c);

        const B24AC = B * B - 4 * A * C;
        if (B24AC < 0) {
            // no intersections:
            return [];
        }
    
        const ix1 = (-B + Math.sqrt(B24AC)) / (2 * A);
        const ix2 = (-B - Math.sqrt(B24AC)) / (2 * A);
    
        const iy1 = m * ix1 + c;
        const iy2 = m * ix2 + c;
        i1 = [ ix1, iy1 ];
        i2 = [ ix2, iy2 ];
    }

    let b1 = calcBearing(i1);
    let b2 = calcBearing(i2);

    const bp1 = calcBearing(p1);
    let bp2 = calcBearing(p2);
    if (bp2 < bp1) bp2 += 360;
    if (b1 < bp1) b1 += 360;
    if (b2 < bp1) b2 += 360;
    const ret: number[] = [];
    if (b1 >= bp1 && b1 <= bp2) {
        ret.push((b1 + 360) % 360)
    }
    if (b2 >= bp1 && b2 <= bp2) {
        ret.push((b2 + 360) % 360)
    }

    return ret;
}
const antiClockwiseBearingDifference = (b1: number, b2: number) => {
    const angle = b2 - b1;
    const clockwise = angle % 360;
    const anticlockwise = 360 - clockwise;
    return anticlockwise % 360;
}
const clockwiseBearingDifference = (b1: number, b2: number) => {
    const angle = b2 - b1;
    if (angle === 360) return 360;
    const clockwise = (angle + 360) % 360;
    return clockwise;
}
const extendTriangleSector = (cp: number[], triangleSectorIndex: number, triangleSectors: ITriangleSector[], maxSystemRadiusMeters: number = Number.MAX_VALUE, minSystemRadiusMeters: number = 0): ITriangleSectorSolution | undefined => {
    const output = triangleSectors[triangleSectorIndex];
    const radius = Math.min(output.minimum.distance, maxSystemRadiusMeters);
    if (radius < minSystemRadiusMeters) {
        return undefined;
    }

    let leftBearing = output.start.bearing;
    let rightBearing = output.end.bearing;
    if (leftBearing === rightBearing) return undefined;


    let iOutputLeft: number = triangleSectorIndex === 0 ? triangleSectors.length - 1 : triangleSectorIndex - 1;
    let leftInterBearing: number;
    if (output.minimum.bearing === output.start.bearing && triangleSectors[iOutputLeft].minimum.distance < output.minimum.distance) {
        // the line is getting closer, and so we cannot extend
        leftInterBearing = output.minimum.bearing;
    }
    else {
        while (iOutputLeft !== triangleSectorIndex && radius <= triangleSectors[iOutputLeft].minimum.distance ) {
            leftBearing = triangleSectors[iOutputLeft].start.bearing;
            iOutputLeft--;
            if (iOutputLeft < 0) iOutputLeft = triangleSectors.length - 1;
        }
        if (iOutputLeft === triangleSectorIndex) {
            // we created a full pivot
            return {
                radius,
                leftBearing: 0,
                rightBearing: 360
            }
        }
        
        // extend the left bearing to intersect the left sector
        if (triangleSectors[iOutputLeft].minimum.bearing === triangleSectors[iOutputLeft].end.bearing) {
            // we can't extend
            leftInterBearing = leftBearing;
        }
        else {
            // // we can extend
            const ibs = findLineArcIntersections(
                triangleSectors[iOutputLeft].start.position, 
                triangleSectors[iOutputLeft].end.position, 
                cp, 
                radius);
            // find the right most bearing from leftBearing (leftBearing is iOutputLeft end at this point)
            
            const ibs2 = ibs
                .map(i => antiClockwiseBearingDifference(leftBearing, i));
            let min: { i: number, diff: number } | undefined = undefined;
            for (let i = 0; i < ibs.length; i++) {
                if (!min ||  ibs2[i] < min.diff) {
                    min = { i: ibs[i], diff: ibs2[i]}
                }
            }
            if (min) {
                leftInterBearing = (min.i + 360) % 360;
            }
            else {
                leftInterBearing = leftBearing;
            }
        }
    }

    let iOutputRight = triangleSectorIndex === triangleSectors.length - 1 ? 0 : triangleSectorIndex + 1;
    let rightInterBearing: number;
    if (output.minimum.bearing === output.end.bearing && triangleSectors[iOutputRight].minimum.distance < output.minimum.distance) {
        // the line is getting closer, and so we cannot extend
        rightInterBearing = output.minimum.bearing;
    }
    else {
        while (iOutputRight !== iOutputLeft && radius <= triangleSectors[iOutputRight].minimum.distance ) {
            rightBearing = triangleSectors[iOutputRight].end.bearing;
            iOutputRight++;
            if (iOutputRight === triangleSectors.length) iOutputRight = 0;
        }
        // extend the right bearing to intersect the right sector
        if (triangleSectors[iOutputRight].minimum.bearing === triangleSectors[iOutputRight].start.bearing) {
            // we can't extend
            rightInterBearing = rightBearing;
        }
        else {
            // // we can extend
            const ibs = findLineArcIntersections(
                triangleSectors[iOutputRight].start.position,
                triangleSectors[iOutputRight].end.position,
                cp, 
                radius
            );
    
            
            const ibs2 = ibs
                .map(i => clockwiseBearingDifference(rightBearing, i));
            let min: { i: number, diff: number } | undefined = undefined;
            for (let i = 0; i < ibs.length; i++) {
                if (!min ||  ibs2[i] < min.diff) {
                    min = { i: ibs[i], diff: ibs2[i]}
                }
            }
            if (min) {
                rightInterBearing = (min.i + 360) % 360;
            }
            else {
                rightInterBearing = rightBearing;
            }
        }
    }

    // TODO: Check this fix works correctly:
    // If a full pivot exists, it should be found when increasing the leftBearing and 
    // would have returned already.
    // Thus, if the right bearing is the same as the left bearing, we must have a 'slither', and so,
    // no solution
    if (Math.abs(rightInterBearing - leftInterBearing) < 0.01) {
        return undefined;
    }

    return {
        radius,
        leftBearing: leftInterBearing,
        rightBearing: rightInterBearing
    }
}

// This is a debug function to inspect the generated triangle sectors:
const tsToFc = (center: Point, triangleSectors: IResult) => {
    const ps = featureCollection(
        [
            ...triangleSectors.sectors.map((ts, idx) => {
                return polygon(
                    [
                        [
                            center.coordinates,
                            triangleSectors.mph.metersToWgs84(ts.start.position[0],ts.start.position[1]),
                            triangleSectors.mph.metersToWgs84(ts.end.position[0],ts.end.position[1]),
                            center.coordinates
                        ]
                    ],
                    {
                        iOutput: idx
                    }
                )
            }),
        ]
    )
    console.log("ps",ps)

    const temp = [];
    let ii = 0;
    for (const ts of triangleSectors.sectors) {
        const l1 = Math.sqrt(ts.start.position[0] * ts.start.position[0] + ts.start.position[1] * ts.start.position[1])
        const l2 = Math.sqrt(ts.end.position[0] * ts.end.position[0] + ts.end.position[1] * ts.end.position[1])
        const lm = Math.sqrt(ts.minimum.position[0] * ts.minimum.position[0] + ts.minimum.position[1] * ts.minimum.position[1])        
        const p1 = destination(center.coordinates, l1, ts.start.bearing, { units: 'meters' });
        const p2 = destination(center.coordinates, l2, ts.end.bearing, { units: 'meters' });
        const pm = destination(center.coordinates, lm, ts.minimum.bearing, { units: 'meters' });
        const pm2 = destination(center.coordinates, ts.minimum.distance, ts.minimum.bearing, { units: 'meters' });
        ii++;
        temp.push(
            polygon([[
                center.coordinates, 
                p1.geometry.coordinates, 
                p2.geometry.coordinates, 
                center.coordinates
            ]]),
            pm, pm2
        )
    }
    temp.push(
        feature(center)
    )
    console.log("ps2",featureCollection(temp))
}

let iSkip = 0;
let debug = false;

interface IArgs {
    center: Point,
    losV3Input: ILosV3Input;
    maxSystemRadiusFt?: number;
    minSystemRadiusFt?: number;
}

const printDebugInfo = (triangleSectors: IResult, args: IArgs) => {
    console.log("Triangle sectors", triangleSectors)
    tsToFc(args.center, triangleSectors)

    {
        console.log("starts")
        let t = "";
        triangleSectors.sectors.forEach(ts => {
            t += ts.start.position[0] + "," + ts.start.position[1] + "\n"
        })
        if (triangleSectors.sectors.length) {
            t += triangleSectors.sectors[0].start.position[0] + "," + triangleSectors.sectors[0].start.position[1] + "\n"
        }
        let t2 = "";
        triangleSectors.sectors.forEach(ts => {
            t2 += ts.minimum.position[0] + "," + ts.minimum.position[1] + "\n"
        })
        console.log(t);
        console.log(t2);
    }
    
    {
        console.log("ends")
        let t = "";
        triangleSectors.sectors.forEach(ts => {
            t += ts.end.position[0] + "," + ts.end.position[1] + "\n"
        })
        if (triangleSectors.sectors.length) {
            t += triangleSectors.sectors[0].end.position[0] + "," + triangleSectors.sectors[0].end.position[1] + "\n"
        }
        console.log(t);
    }
}
export const optimizeByCenter = async (args: IArgs): Promise<IPartialPivotOptimizerResult> => {

    // const tpp = createTimerLog("cpo.postbuffer.pp.optimize.consider.optimize");
    const losV3Input = args.losV3Input;
    // tpp.logDeltaMS("input");
    const triangleSectors = generateLineOfSightTrianglesV3({
        center: args.center,
        lineSegments: losV3Input.lineSegments
    }, debug);
    // tpp.logDeltaMS("los");

    let best: {
        area: number,
        solution: ITriangleSectorSolution
    } | undefined = undefined;

    if (debug) {
        printDebugInfo(triangleSectors, args);
        iSkip++;
        if (iSkip >= triangleSectors.sectors.length) {
            iSkip = 0;
        }
    }
    
    const maxSystemRadiusMeters = args.maxSystemRadiusFt
        ? convertLength(args.maxSystemRadiusFt, 'feet', 'meters')
        : undefined;
    const minSystemRadiusMeters = args.minSystemRadiusFt
        ? convertLength(args.minSystemRadiusFt, 'feet', 'meters')
    : undefined;
    for (let iOutput = 0; iOutput < triangleSectors.sectors.length; iOutput++) {
        const solution = extendTriangleSector(triangleSectors.metersCenter, iOutput, triangleSectors.sectors, maxSystemRadiusMeters, minSystemRadiusMeters);
        if (debug) {
            // if (iOutput !== 43) continue;
            // if (iOutput !== iSkip) continue;
            // console.log(iOutput, solution)
        }
        if (!solution) continue;
        const { radius } = solution;
        let leftBearing = solution.leftBearing;
        let rightBearing = solution.rightBearing;
        if (clockwiseBearingDifference(leftBearing, rightBearing) < 0.3) continue;
        // we want the left and right bearings to the nearest 0.1 degree
        leftBearing = Math.ceil(leftBearing * 10) / 10;
        rightBearing = Math.floor(rightBearing * 10) / 10;
        const cbd = clockwiseBearingDifference(leftBearing, rightBearing); // calculate again as these l/r bearings have been rounded now
        const aSector = Math.PI * radius * radius * cbd / 360;
        if (!best || best.area < aSector) {
            // console.log(">>", iOutput, aSector, cbd, radius)
            best = {
                area: aSector,
                solution: {
                    radius,
                    leftBearing,
                    rightBearing
                }
            }
        }

    }
    // tpp.logDeltaMS("extend");
    
    if (!best) return undefined;

    // console.log("Best", best)
    // tpp.logDeltaMS("result");
    return {
        center: args.center,
        radiusFeet: convertLength(best.solution.radius, 'meters', 'feet') - 0.5,  // Simon 06/02/2024: Note, the radius is sometimes a little too bit (perhaps due to coord sys difference, remove half a foot here to be conservative)
        minBearing: best.solution.leftBearing,
        maxBearing: best.solution.rightBearing,
        areaM2: best.area
    }
}