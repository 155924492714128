import ChangeCircle from "@mui/icons-material/ChangeCircle";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from '@mui/icons-material/Error';
import SendIcon from '@mui/icons-material/Send';
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import { BlobProvider } from "@react-pdf/renderer";
import IGetOrderStatusResp from "rdptypes/api/IGetOrderStatusResp";
import IPutOrderRequest from "rdptypes/api/IPutOrderRequest";
import IProposal from "rdptypes/roe/IProposal";
import * as React from "react";
import { FC, useContext, useState } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { buildMapics } from "../../../../../roedata/mapics/MapicsBuilder";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import ApiClientCtx from "../../../../api/ApiClientCtx";
import ApiRequestState, { ApiRequestStatus } from "../../../../api/ApiRequestState";
import DbCtx from "../../../../db/DbCtx";
import DevSettingsCtx from "../../../../db/DevSettingsCtx";
import IrrigationSystemPurchaseOrder from "../../../../docGeneration/docTypes/IrrigationSystemPurchaseOrder";
import DealerDataCtx from "../../../../userData/DealerDataCtx";
import FeatureNotEnabled from "../../../FeatureNotEnabled";
import Spinner from "../../../Spinner";
import { getValuesFromFieldDesign } from "../Proposal/ProposalHelpers";
import AuthCtx from "./../../../../auth/AuthCtx";
import IDbProject from "./../../../../db/IDbProject";

interface Props {
    cmp: IProposal,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
};

const SendOrderRenderer: FC<Props> = (props) => {
    const user = useContext(DealerDataCtx);

    if (!user.permissions.enableOnlineOrdering) return <FeatureNotEnabled />;

    const authState = useContext(AuthCtx);
    const devSettings = useContext(DevSettingsCtx);
    const dbState = useContext(DbCtx);

    const api = useContext(ApiClientCtx);

    const layout = props.dbPrj.state.layouts[props.layoutId!];
    const system = layout.systems[props.systemId!];
    const grower = dbState.growers[props.dbPrj.state.growerId];

    const [submittingOrder, setSubmittingOrder] = useState<boolean>(false);

    const [deletingOrder, setDeletingOrder] = useState<boolean>(false);
    const [deletingOrderError, setDeletingOrderError] = useState<string | undefined>(undefined);
    const [deletingOrderSuccess, setDeletingOrderSuccess] = useState<boolean>(false);


    const quoteId = system.orderSubmissionStatus?.success ? system.orderSubmissionStatus.quoteId : undefined;

    const [orderStatus, setOrderStatus] = useState<ApiRequestState<IGetOrderStatusResp> | undefined>(undefined);

    // TODO uncomment to enable order change functionality
    /*useEffect(() => {
        (async () => {
            if (system.orderSubmissionStatus?.success) {
                setOrderStatus(await api.getOrder(quoteId));
            } else {
                setOrderStatus(undefined);
            }
        })();
    }, [system.orderSubmissionStatus]);*/

    const proposalGenerated = !!system.proposalGenerated;

    const readyToResubmit =
        system.orderSubmissionStatus?.success &&
        proposalGenerated &&
        system.proposalGenerated !== (system.orderSubmissionStatus.proposalId ?? true);

    const canSubmit =
        !system.orderSubmissionStatus?.success ||
        (readyToResubmit && orderStatus?.status === ApiRequestStatus.Success && orderStatus.result.allowChange);

    const send = async (pdf: Blob) => {
        const pdfBase64 = await new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve((reader.result as string).replace(/^data:.+;base64,/, ''));
            reader.readAsDataURL(pdf);
        });

        const valuesFromFieldDesign = getValuesFromFieldDesign(new QuoteClass(system), props.systemId, props.layoutId, props.dbPrj.state);

        const mapicsData = buildMapics(
            system,
            grower,
            user,
            valuesFromFieldDesign
        );

        const req: IPutOrderRequest = {
            system: system,
            mapicsOrder: mapicsData,
            pdf: pdfBase64,
            change: readyToResubmit
        };

        setSubmittingOrder(true);
        setDeletingOrderError(undefined);
        setDeletingOrderSuccess(false);

        const resp = await api.putOrder(props.systemId!, req);
        if (resp.status === ApiRequestStatus.Success) {
            props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                props.layoutId,
                props.systemId,
                "orderSubmissionStatus",
                {
                    success: resp.result.success,
                    quoteId: resp.result.success ? resp.result.quoteId : undefined,
                    errorMessage: resp.result.success ? undefined : resp.result.message,
                    timestamp: resp.result.timestamp,
                    proposalId: system.proposalGenerated
                },
                authState));
        } else {
            props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                props.layoutId,
                props.systemId,
                "orderSubmissionStatus",
                {
                    success: false,
                    errorMessage: resp.error
                },
                authState));
        }

        setSubmittingOrder(false);
    };

    const changeOrder = async () => {
        if (!confirm("You are currently able to change this order. Clicking OK below will remove the proposal and unlock the system for editing. Once you've changed the system you should create a new proposal and resubmit the order.\n\nPlease be quick! The old order is still processing and there's no guarantee that you'll still be able to change the order by the time you're ready to resubmit.")) {
            return;
        }

        props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
            props.layoutId,
            props.systemId,
            "proposalGenerated",
            false,
            authState));
    };

    const cancelOrder = async () => {
        if (!confirm("Are you sure you want to cancel this order?")) {
            return;
        }

        setDeletingOrder(true);
        setDeletingOrderError(undefined);
        setDeletingOrderSuccess(false);

        const resp = await api.deleteOrder(props.systemId!);
        if (resp.status === ApiRequestStatus.Success) {
            setDeletingOrderSuccess(true);
            props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                props.layoutId,
                props.systemId,
                "orderSubmissionStatus",
                undefined,
                authState));
        } else {
            setDeletingOrderError(resp.error);
        }

        setDeletingOrder(false);
    };

    if (!system.proposalGenerated) {
        return (
            <Alert sx={{ marginBottom: 1 }} icon={<ErrorIcon fontSize="inherit" />} severity="warning">
                    <AlertTitle>Proposal not generated</AlertTitle>
                    You must first generate the proposal before sending the order.
                </Alert>
        );
    }

    if (submittingOrder) {
        return <Spinner inline title="Submitting order to Reinke" />;
    }

    if (deletingOrder) {
        return <Spinner inline title="Requesting order cancellation" />;
    }

    /* We have to bridge all conexts that might be needed within the PDF renderers. 
    See https://github.com/facebook/react/issues/17275#issuecomment-550322731 */
    const doc =
        <DealerDataCtx.Provider value={user}>
            <DevSettingsCtx.Provider value={devSettings}>
                <IrrigationSystemPurchaseOrder grower={grower} project={props.dbPrj.state} systemId={props.systemId} layoutId={props.layoutId} />
            </DevSettingsCtx.Provider>
        </DealerDataCtx.Provider>;

    return (
        <>
            {system.orderSubmissionStatus?.success &&
                <Alert sx={{ marginBottom: 1 }} icon={<CheckIcon fontSize="inherit" />} severity="success">
                    Order submitted successfully on {system.orderSubmissionStatus.timestamp}. Reinke quote ID: {system.orderSubmissionStatus.quoteId}
                </Alert>}

            {system.orderSubmissionStatus && !system.orderSubmissionStatus.success &&
                <Alert sx={{ marginBottom: 1 }} icon={<ErrorIcon fontSize="inherit" />} severity="error">
                    <AlertTitle>Error submitting order</AlertTitle>
                    {system.orderSubmissionStatus.errorMessage}
                </Alert>}

            {deletingOrderSuccess &&
                <Alert sx={{ marginBottom: 1 }} icon={<CheckIcon fontSize="inherit" />} severity="success">
                    Your order was successfully cancelled.
                </Alert>}

            {deletingOrderError &&
                <Alert sx={{ marginBottom: 1 }} icon={<ErrorIcon fontSize="inherit" />} severity="error">
                    <AlertTitle>Error cancelling order</AlertTitle>
                    {deletingOrderError}
                </Alert>}

            {!system.orderSubmissionStatus &&
                <Alert sx={{ marginBottom: 1 }} icon={<SendIcon fontSize="inherit" />} severity="info">
                    Your order is ready for submission to Reinke!
                </Alert>}

            {orderStatus?.status === ApiRequestStatus.InProgress && <Spinner inline title="Getting order status" />}
            {orderStatus?.status === ApiRequestStatus.Error &&
                <Alert sx={{ marginBottom: 1 }} icon={<ErrorIcon fontSize="inherit" />} severity="error">
                    <AlertTitle>Error getting order status</AlertTitle>
                    {orderStatus.error}
                </Alert>}

            { orderStatus?.status === ApiRequestStatus.Success &&
                <Alert sx={{ marginBottom: 1 }} icon={<ChangeCircle fontSize="inherit" />} severity="info">
                    <AlertTitle>Modify this order</AlertTitle>
                        <Button
                        onClick={changeOrder}
                        variant="contained"
                        color="inherit"
                        disabled={!(orderStatus?.status === ApiRequestStatus.Success && orderStatus.result.allowChange && proposalGenerated && !readyToResubmit)}
                        sx={{mr: 1}}
                        >Change order</Button>
                        <Button
                        onClick={cancelOrder}
                        variant="contained"
                        color="inherit"
                        disabled={!(orderStatus?.status === ApiRequestStatus.Success && orderStatus.result.allowCancel)}
                        >Cancel order</Button>
                </Alert>}

            { orderStatus?.status === ApiRequestStatus.Success && !orderStatus.result.allowChange && readyToResubmit &&
                <Alert sx={{ marginBottom: 1 }} icon={<ErrorIcon fontSize="inherit" />} severity="error">
                    <AlertTitle>Error cancelling order</AlertTitle>
                    Sorry, it's too late to submit changes to this order. Your original order is still processing. Please contact Dealer Support.
                </Alert>}

            {canSubmit && <>
                <BlobProvider document={doc}>
                    {({ blob, url, loading, error }) => {
                        if (loading) {
                            <Spinner inline title="Preparing order for submission" />
                        } else if (error) {
                            <Alert sx={{ marginBottom: 1 }} icon={<ErrorIcon fontSize="inherit" />} severity="error">
                                <AlertTitle>Error perparing order</AlertTitle>
                                {`${error}`}
                            </Alert>
                        } else {
                            return (<>
                            <Box sx={{mb:1}}>
                                Click the Submit Order button below to submit changes to your order. Until you submit changes your old order will continue processing.
                            </Box>
                                <Button variant="contained" onClick={() => send(blob)}>Submit Order</Button>
                                </>);
                        }
                    }}
                </BlobProvider>
            </>}
        </>
    );
};

export default SendOrderRenderer;