import { Text, View } from "@react-pdf/renderer";
import * as React from "react";
import { FC } from "react";
import VRI_Zones from "roedata/roe_migration/VRIClass.VRI_Zones";
import { documentStyles } from "../styles";
import FixedWidthTable, { IRow } from "./table/FixedWidthTable";

export interface Props {
    vriZones: VRI_Zones;
}

const VRIZonesSection: FC<Props> = (props) => {
    let {vriZones} = props;

    let rows: IRow[] = [{cells: [{
        text: "Zone",
        header: true,
        widthPc: 7
    },
    {
        text: "Starting Location",
        header: true,
        widthPc: 10
    },
    {
        text: "Ending Location",
        header: true,
        widthPc: 10
    },
    {
        text: "Width",
        header: true,
        widthPc: 7
    },
    {
        text: "First Device",
        header: true,
        widthPc: 8
    },
    {
        text: "Last Device",
        header: true,
        widthPc: 10
    },
    {
        text: "Acres",
        header: true,
        widthPc: 10
    },
    {
        text: "Max. Diameter",
        header: true,
        widthPc: 10
    },
    {
        text: "Controlled",
        header: true,
        widthPc: 10
    },
    {
        text: "Zone Relay Address",
        header: true,
        widthPc: 10
    },
    {
        text: "Valve Box",
        header: true,
        widthPc: 10
    }]}];

    for (let idx = 1; idx <= vriZones.Count; idx++){
        let zone = vriZones.Get(idx);
        if (zone.InUse){
            rows.push({cells: [{
                text: (idx).toFixed(),
                widthPc: 7
            },
            {
                text: zone.StartingLocation.toFixed(1),
                widthPc: 10
            },
            {
                text: zone.EndingLocation.toFixed(1),
                widthPc: 10
            },
            {
                text: zone.Length.toFixed(1),
                widthPc: 7
            },
            {
                text: zone.DeviceAfterStartingLocation.toFixed(),
                widthPc: 8
            },
            {
                text: zone.DeviceBeforeEndingLocation.toFixed(),
                widthPc: 10
            },
            {
                text: zone.Acres.toFixed(1),
                widthPc: 10
            },
            {
                text: zone.MaxDiameter.toFixed(1),
                widthPc: 10
            },
            {
                text: zone.Controlled ? "Yes" : "No",
                widthPc: 10
            },
            {
                text: zone.Controlled ? zone.ZoneRelayAddress : "",
                widthPc: 10
            },
            {
                text: zone.Controlled ? zone.ValveBoxIndex()?.toFixed() : "",
                widthPc: 10
            }]});
        }
    }

    return (<View>s
            <View style={documentStyles.heading}>
                    <Text style={documentStyles.sectionHeader}>VRI Zones</Text>
            </View>
            <FixedWidthTable
                data={{rows}}
            />
        </View>
    );
};

export default VRIZonesSection;