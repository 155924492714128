import { Alert, Stack } from "@mui/material";
import { t } from "i18next";
import IComponent from "rdptypes/roe/IComponent";
import IPage from "rdptypes/roe/IPage";
import { IRule } from "rdptypes/roe/IRule";
import * as React from "react";
import { FC } from "react";
import IDbProject from "../../../db/IDbProject";
import ComponentListRenderer from "./ComponentListRenderer";
import IComponentRenderContext from "./IComponentRenderContext";

export interface PageRendererProps {
    page: IPage,
    rules: IRule[],
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    ctx: IComponentRenderContext,
    ignoreImgTooltips?: boolean,
    
    // Allows the components to be filtered: Default [undefined] will return all components
    //  NOTE: This was added so this component could be used in the DealerSettings modal.
    //        The DealerSettings model does not wan to display certain components on cenrtain pages
    filterComponentsPredicate?: (component: IComponent) => boolean;
    
    oneColArrangement?: boolean;
    spacing?: number;
};

const allowAllComponentsPredicate = () => true;

const PageRenderer: FC<PageRendererProps> = (props) => {
    const filterComponentsPredicate = props.filterComponentsPredicate || allowAllComponentsPredicate;
    const filteredComponents = props.page.components.filter(x => filterComponentsPredicate(x));

    var oneColArrangement = props.oneColArrangement || filteredComponents.filter((c: IComponent) => c.type == "towers" || c.type === "spans" || c.type === "sidetable"|| c.type === "generaltable" || c.type === "sprinklers" || c.type === "vri" || c.type=== "proposal" || c.type === "electricalReportRenderer" || c.type === "tabs" || c.type === "miscItems").length > 0;
    var fullWithComponents = filteredComponents.filter((c: IComponent) => c.layoutHint === "fullWidth");
    let component: JSX.Element;

    let isProposalPage = props.page.title === "Proposal" || props.page.title["en"] === "Proposal";
    let isSendOrderPage = props.page.title === "Send Order" || props.page.title["en"] === "Send Order";
    
    if (!isProposalPage && !isSendOrderPage &&
        props.dbPrj.state.layouts[props.layoutId] && 
        props.dbPrj.state.layouts[props.layoutId].systems[props.systemId] &&
        props.dbPrj.state.layouts[props.layoutId].systems[props.systemId].proposalGenerated){
            component = <Alert severity="warning">{t("proposals.already-generated-proposal")}</Alert>
    }
    else {
        component = <ComponentListRenderer
            oneColumn={oneColArrangement}
            components={filteredComponents}
            dbPrj={props.dbPrj}
            layoutId={props.layoutId}
            ignoreImgTooltips={props.ignoreImgTooltips}
            systemId={props.systemId}
            parentGroupLabel={null}
            ctx={props.ctx}
             />;
    }

    return (
        <>
            <Stack spacing={props.spacing === undefined ? 2 : props.spacing} direction={oneColArrangement ? "column" : "row"} style={{width: '100%'}}>
                {component}
            </Stack>
            {
                fullWithComponents.length > 0 &&
                <Stack direction="column">
                    <ComponentListRenderer
                        oneColumn={true}
                        components={fullWithComponents}
                        dbPrj={props.dbPrj}
                        layoutId={props.layoutId}
                        ignoreImgTooltips={props.ignoreImgTooltips}
                        systemId={props.systemId}
                        parentGroupLabel={null}
                        ctx={props.ctx}
                        />
                </Stack>
            }
        </>
    );
};

export default PageRenderer;