import {
    Checkbox,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import * as React from "react";

interface Props {
    label: string;
    disabled?: boolean;
    checked: boolean;
    indeterminate?: boolean;
    onClick: () => void;
    className?: string;
}
const ListItemCheckbox: React.FC<React.PropsWithChildren<Props>> = ({
    label, disabled, checked, onClick, indeterminate, className
}) => {

    return (
        <ListItemButton
            role={undefined}
            onClick={onClick}
            disabled={disabled}
            className={className}
        >
            <ListItemIcon sx={{ minWidth: 0 }}>
                <Checkbox
                    edge="start"
                    disableRipple
                    checked={checked}
                    indeterminate={indeterminate}
                />
            </ListItemIcon>
            <ListItemText
                primary={label}
            />
        </ListItemButton>
    );
};

export default ListItemCheckbox;