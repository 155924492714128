import { LinearProgress, Typography } from "@mui/material";
import * as React from "react";
import * as workerClass from "./worker";

interface Props {
    progress?: workerClass.IWFOProgress
}

const normalise = (value: number, min: number, max: number) => ((value - min) * 100) / (max - min);

export const ProgressBody: React.FC<Props> = ({ progress }) => {
    if (!progress) return null;
    return (
        <>                                
            <Typography alignSelf={'center'} variant="subtitle1">{progress.message}</Typography>
            {
                progress.nTotalSystems
                    ? <LinearProgress variant="determinate" value={normalise(progress.iCurrentSystem, 0, progress.nTotalSystems)} />
                    : <LinearProgress variant="indeterminate" />
            }
            {
                progress.currentSystemProgress 
                    ? (
                        <>
                            <LinearProgress variant="determinate" value={normalise(progress.currentSystemProgress.iStep, 0, progress.currentSystemProgress.nTotalSteps)} />
                            <Typography alignSelf={'center'}>{progress.currentSystemProgress.message}</Typography>
                        </>
                    )
                    :  (
                        <>
                            <LinearProgress variant="determinate" value={0} />
                            <Typography alignSelf={'center'}>&nbsp;</Typography>
                        </>
                    )
            }
        </>
    )
}