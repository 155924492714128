import { booleanContains, booleanDisjoint, buffer, distance, envelope, multiPolygon } from "@turf/turf";
import { IGetSystemValidityArgs, SystemValidity } from "./interfaces";
import { createTimerLog } from "../../helpers/logging";

const SAC_BUFFER_CENTIMETERS = 2;
// Args: 
// - systemAreaPolygon : excludes SAC

const cache: {
    [key: string]: {
        hash: number;
        validity: SystemValidity;
    }
} = {}

export const getSystemValidity = (layoutId: string, systemId: string, args: IGetSystemValidityArgs): SystemValidity => {
    return _getSystemValidity(args);
}

const _getSystemValidity = ({
    systemClearanceObstacles: clearanceObstacles, systemClearanceBoundaries: clearanceBoundaries, systemAreaPolygon, center, systemClearancePivotCenterBoundaries: clearancePivotCenterBoundaries,
    wheelTracks, systemClearanceWheelObstacles: clearanceWheelObstacles, systemClearanceSystemObstacles: _clearanceSystemObstacles, sac,
    allowOverlap, feedLine, sacResult, centerPivot
}: IGetSystemValidityArgs): SystemValidity => {
    
    // START: CRITICAL
    if ((sacResult && !sacResult.success) || !clearanceBoundaries.length || !systemAreaPolygon || (clearancePivotCenterBoundaries && !clearancePivotCenterBoundaries.length)) {
        return 'critical';
    }
    const systemEnvelope = envelope(systemAreaPolygon);
    if (!clearanceBoundaries.some(b => booleanContains(b, systemEnvelope))) {
        if (!clearanceBoundaries.some(b => booleanContains(b, systemAreaPolygon))) {
            return 'critical';
        }
    }
    const clearanceSystemObstacles = _clearanceSystemObstacles.map(x => {
        if (center && x.pivotCenter && x.radiusEnvelope) {
            const d = distance(center, x.pivotCenter, { units: 'feet'});
            if (d > centerPivot.radiusEnvelope + x.radiusEnvelope) {
                return {
                    ...x,
                    polygons: []
                }
            }
        }
        return {
            ...x,
            polygons: x.polygons.filter(y => !booleanDisjoint(envelope(y), systemEnvelope))
        }
    });
    const overlappableSystemPolygons = clearanceSystemObstacles.filter(x => allowOverlap || x.allowOverlap).flatMap(x => x.polygons);
    const nonOverlappableSystemPolygons = clearanceSystemObstacles.filter(x => !allowOverlap && !x.allowOverlap).flatMap(x => x.polygons);
    const nonOverlappablePivotCenters = clearanceSystemObstacles.map(x => x.pivotCenter).filter(x => x !== undefined);
    const nonOverlappableFeedLines = clearanceSystemObstacles.map(x => x.laterlFeedLine).filter(x => x !== undefined);
    if (nonOverlappableSystemPolygons.some(b => !booleanDisjoint(b, systemAreaPolygon))) {
        return 'critical';
    }
    if (nonOverlappablePivotCenters.some(b => !booleanDisjoint(b, systemEnvelope))) {
        if (nonOverlappablePivotCenters.some(b => !booleanDisjoint(b, systemAreaPolygon))) {
            return 'critical';
        }
    }
    if (nonOverlappableFeedLines.some(b => !booleanDisjoint(b, systemEnvelope))) {
        if (nonOverlappableFeedLines.some(b => !booleanDisjoint(b, systemAreaPolygon))) {
            return 'critical';
        }
    }
    
    if (center || feedLine) {
        const centerObj = center || feedLine;
        if (overlappableSystemPolygons.some(b => !booleanDisjoint(b, centerObj))) {
            return 'critical';
        }
    }

    if (sac) {
        const sacEnvelope = envelope(multiPolygon(sac.polygons.map(p => p.coordinates)));

        // NOTE: This test is not perfect. We should only test if the HTower is in the HTower clearance, not the entire system
        if (!sac.hTowerClearanceBoundaries.some(b => booleanContains(b, systemAreaPolygon))) {
            return 'critical';
        }
        if (!sac.polygons.some(p => sac.sTowerClearanceBoundaries.map(b => buffer(b, SAC_BUFFER_CENTIMETERS, { units: 'centimeters'})).some(b => booleanContains(b, p)))) {
            return 'critical';
        }

        if (nonOverlappableSystemPolygons.filter(o => !booleanDisjoint(envelope(o), sacEnvelope)).some(o => {
            return (sac.polygons.some(p => {
                return !booleanDisjoint(o, p)
            }))
        })) {
            return 'critical';
        }

        if (overlappableSystemPolygons.filter(o => !booleanDisjoint(envelope(o), sacEnvelope)).some(o => {
            return (sac.polygons.some(p => {
                return !booleanDisjoint(o, p)
            }))
        })) {
            return 'warning';
        }
    }
    // END: CRITICAL

    // START: WARNING
    if (clearanceObstacles.flatMap(x => x.polygons).filter(o => !booleanDisjoint(o, systemEnvelope)).some(o => !booleanDisjoint(o, systemAreaPolygon))) {
        return 'warning';
    }
    if (overlappableSystemPolygons.filter(o => !booleanDisjoint(envelope(o), systemEnvelope)).some(o => !booleanDisjoint(o, systemAreaPolygon))) {
        return 'warning';
    }
    if (clearancePivotCenterBoundaries && center && !clearancePivotCenterBoundaries.some(pcb => booleanContains(pcb, center))) {
        return 'warning';
    }
    if (wheelTracks && clearanceWheelObstacles && wheelTracks.some(wt => {
        return clearanceWheelObstacles.flatMap(x => x.polygons).some(wo => !booleanDisjoint(wt, wo));
    })) {
        return 'warning';
    }
    if (sac) {
        if (sac.sTowerClearanceObstacles.flatMap(x => x.polygons).map(b => buffer(b, -SAC_BUFFER_CENTIMETERS, { units: 'centimeters'})).some(o => sac.polygons.some(p => !booleanDisjoint(o, p)))) {
            return 'warning';
        }
        if (sac.sTowerClearanceWheelObstacles && sac.wheelTracks.some(wt => {
            return sac.sTowerClearanceWheelObstacles.flatMap(x => x.polygons).map(b => buffer(b, -SAC_BUFFER_CENTIMETERS, { units: 'centimeters'})).some(wo => !booleanDisjoint(wt, wo));
        })) {
            return 'warning';
        }
    }
    // END: WARNING

    return 'valid';
}