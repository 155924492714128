import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import IAuthState from "../../../auth/IAuthState";
import IProject from "../../../model/project/IProject";
import * as optimizeCenterPivotModule from "../../../optimization/centerPivot";
import * as optimizeLateralModule from "../../../optimization/lateral";

export enum Interaction {
    SimpleSelect,
    DirectSelect,
    DrawObstacle,
    DrawWheelObstacle,
    DrawBoundary,
    DrawWetAreaBoundary,
    DrawPivotCenterBoundary,
    DrawPivotSystem,
    DrawLateralSystem,
    DrawCrossAnnotation,// TODO: point to cross
    DrawElectricLineAnnotation,
    DrawLabelAnnotation,
    DrawLineAnnotation,
    DrawCanalAnnotation,
    DrawPointAnnotation,
    DrawPumpAnnotation,
    DrawWaterLineAnnotation,
    CenterPivotSelect,
    LateralSelect,
    DrawPivotCenter_ForOptimizedFullPivot,
    DrawPivotCenter_ForOptimizedPartialPivot,
    DrawPivotCenter_ForOptimizedFullOrPartialPivot,
    Measure,
    DynamicCenterPivotOptimization,
    DynamicLateralOptimization,
    SegmentSelect
}

export interface IInteractionState_DrawPivotSystem {
    interactionType: Interaction.DrawPivotSystem,
    optimizationArgs?: optimizeCenterPivotModule.IOptimizationSettings;
}

export interface IInteractionState_DrawLateralSystem {
    interactionType: Interaction.DrawLateralSystem,
    optimizationArgs?: optimizeLateralModule.IOptimizationSettings;
    canalFeed?: {
        canalWidthFeet: number;
        distanceFromCanalCenterToFwdSide: number;
        distanceFromCanalCenterToAftSide: number;
    };
    endFeed?: boolean;
    pivotingLateral?: boolean;
}

export type IInteractionState = {
    interactionType: Exclude<Interaction, Interaction.DrawPivotSystem | Interaction.DrawLateralSystem | Interaction.DrawLabelAnnotation>;
} | {
    interactionType: Interaction.DrawLabelAnnotation,
    text: string
} | IInteractionState_DrawPivotSystem 
  | {
    interactionType: Interaction.DrawPivotSystem,
    optimizationArgs?: optimizeCenterPivotModule.IOptimizationSettings;
} | IInteractionState_DrawLateralSystem
  | {
    interactionType: Interaction.DynamicCenterPivotOptimization,
    optimizationSettings: optimizeCenterPivotModule.IOptimizationSettings;
    project: IProject;
    layoutId: string;
    authState: IAuthState;
    systemType: SystemTypes.CenterPivot;
} | {
    interactionType: Interaction.DynamicLateralOptimization,
    optimizationSettings: optimizeLateralModule.IOptimizationSettings;
    project: IProject;
    layoutId: string;
    authState: IAuthState;
    systemType: SystemTypes.HoseFeedMaxigator;
} | {
    interactionType: Interaction.DynamicLateralOptimization,
    canalFeed: {
        canalWidthFeet: number;
        distanceFromCanalCenterToFwdSide: number;
        distanceFromCanalCenterToAftSide: number;
    };
    optimizationSettings: optimizeLateralModule.IOptimizationSettings;
    project: IProject;
    layoutId: string;
    authState: IAuthState;
    systemType: SystemTypes.CanalFeedMaxigator;
}
