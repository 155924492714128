import { Box, Button, Divider, Typography } from "@mui/material";
import { t } from "i18next";
import { UserClass } from "rdptypes/IUserData";
import IGrowerBase from "rdptypes/api/IGrowerBase";
import * as React from "react";
import { useContext } from "react";
import partsCatalog from "roedata/data/partsCatalog";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { formatCents } from "../../../../../docGeneration/DocumentGenerationHelpers";
import ISystem from "../../../../../model/project/ISystem";
import DealerDataCtx from "../../../../../userData/DealerDataCtx";
import { getGroupedPartsForSystem } from "../ProposalHelpers";
import ProposalWarnings from "../ProposalWarnings";
import roeData from "./../../../staticDataLoader";

interface Props {
    system: ISystem;
    grower: IGrowerBase;
}

export default function LiveProposal(props: Props) {
    const user = useContext(DealerDataCtx);

    const showItemPrices = user.loggedInUser.class === UserClass.superAdmin
    || user.loggedInUser.class === UserClass.territoryAdmin;

    const warnings = roeData.rules.flatMap(rule => rule.apply(props.system, user, props.grower));

    let totalCostCents = 0;
    let sectionIdToElementMapping: {[sectionId: string] : {elements: JSX.Element[]}} = {};

    let partsGroupedBySection = getGroupedPartsForSystem(props.system).partSections;
    let anyPriceIsNaN = false;

    const formatCentsForCsv = (costStr: string) => {
        return costStr.replace(",","").replace("$", "");
    }

    const wrapForCsv = (str: string) => {
        return '"' + str.replace(/"/g, '""') + '"';
    }

    let csv = "Qty,Part";
    if (showItemPrices){
        csv += ",Price ($)"
    }

    for (let sectionId in partsGroupedBySection){
        let sectionLabel: string = "Unknown";
        for (const [key, value] of Object.entries(roeData.poSchema.sections)) {
            if (value.seq.toString() === sectionId){
                sectionLabel = value.label;
            }
        }

        sectionIdToElementMapping[sectionId] = {elements: []};
        sectionIdToElementMapping[sectionId].elements.push(<Typography style={{textDecoration: "underline"}}>{sectionLabel}</Typography>);

        partsGroupedBySection[sectionId].forEach((p) => {
            if (!(p.partNumber in user.priceList)) {
                console.log(`Part number ${p.partNumber} not in price list`);
                return;
            }
            if (!(p.partNumber in partsCatalog)) {
                console.log(`Part number ${p.partNumber} not in catalog`);
                return;
            }

            let costStr: string;
            let cost = p.qty * user.priceList[p.partNumber].unitPriceUsdCents;
            if (!isNaN(cost)){
                totalCostCents += cost;
                costStr = formatCents(cost);
            }
            else {
                anyPriceIsNaN = true;
                costStr = "Unknown";
            }

            let part = partsCatalog[p.partNumber];
            csv += `\r\n${p.qty},${wrapForCsv(part.mapicsDescription)}`;
            if (showItemPrices) {
                csv += `,${formatCentsForCsv(costStr)}`;
                costStr = ": " + costStr;
            } else {
                costStr = "";
            }
            sectionIdToElementMapping[sectionId].elements.push(<Typography fontSize={12}>{p.qty} * {part.mapicsDescription} ({p.partNumber}){costStr}</Typography>);
        });
    }

    if (props.system?.MainlineValveOptions?.Valves?.MainlineValve){
        let quote = new QuoteClass(props.system);
        props.system.MainlineValveOptions.Valves.MainlineValve.forEach((valve) => {
            let info = quote.MainlineValvesClass.GetMainlineValveInfo(valve, user.priceList);
            let price = info.Price();

            let costStr: string;
            if (!isNaN(price)){
                totalCostCents += price;
                costStr = formatCents(price);
            }
            else {
                anyPriceIsNaN = true;
                costStr = "Unknown";
            }

            let desc = `${info.Description} (${info.PartNumber()})`;
            csv += `\r\n${info.Quantity},${wrapForCsv(desc)}`;
            if (showItemPrices) {
                csv += `,${formatCentsForCsv(costStr)}`;
                costStr = ": " + costStr;
            } else {
                costStr = "";
            }

            let element = <Typography fontSize={12}>{info.Quantity} * {desc}{costStr}</Typography>


            //MainLine Valves SectionId = "14"
            if (!("14" in sectionIdToElementMapping)){
                let sectionLabel: string = "Unknown";
                for (const [key, value] of Object.entries(roeData.poSchema.sections)) {
                    if (value.seq.toString() === "14"){
                        sectionLabel = value.label;
                    }
                }

                sectionIdToElementMapping["14"] = {elements: [
                    <Typography style={{textDecoration: "underline"}}>{sectionLabel}</Typography>
                ]};
            }

            sectionIdToElementMapping[14].elements.push(element);
        });
    }

    let totalStr = `${t("common-phrases.sltTotalListPrice")}: ${formatCents(totalCostCents)}`;
    let paddingBottom = "5px";
    if (anyPriceIsNaN){
        totalStr += "*";
        paddingBottom = "22px";
    }

    let elements: JSX.Element[] = [];
    for (const [key, value] of Object.entries(sectionIdToElementMapping)) {
        elements = elements.concat(value.elements);
        elements.push(<Divider/>);
    } 

    const onDownloadCsv = () => {
        //to allow us to use the "download" prop of the a tag but be styled (reliably) like a MaterialUI button
        const link = document.createElement("a");
        link.download = `${props.system.name}-proposal.csv`;
        link.href = `data:text/csv;charset=utf-8, ${encodeURIComponent(csv)}`;
        link.click();
    }

    return (
        <>
        <Box sx={{paddingTop: "5px", paddingBottom, paddingRight: "10px"}}>
        {
            !warnings.some(x => x.severity === "denied") && <Box>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center",  marginBottom: "5px"}}>
                    <Button variant="outlined" onClick={(e) => {
                        e.stopPropagation();
                        onDownloadCsv();
                    }} color="primary">
                        {t("download")} CSV
                    </Button>                
                    <Typography sx={{ fontSize:"1.2em"}}>
                        <strong>{totalStr}</strong>       
                    </Typography>
                </Box>
                {
                    anyPriceIsNaN && <Typography sx={{ fontSize:"0.7em", textAlign: "right"}}>
                        <strong>*{t("common-phrases.sltTotalPriceIncomplete")}</strong>       
                    </Typography>
                }
            </Box>
        }
            <Divider sx={{marginBottom: "5px"}}/>
                <ProposalWarnings warnings={warnings} />
                <Box sx={{ textAlign: "right" }}>
                    {!warnings.some(x => x.severity === "denied") && elements}
                </Box>
        </Box>
        </>
    );
}