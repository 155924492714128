import { IEnhProgress } from "./IEnhProgress";
import { ProgressDialog } from "./ProgressDialog";

export class NewThreadProgressDialogManager {
    private dialog: ProgressDialog;
    public get Progress(): IEnhProgress {
        return this.dialog;
    }

    constructor(isCancelled: () => Promise<boolean>) {
        this.dialog = new ProgressDialog(isCancelled);
    }

    public Show(title: string) {
    }

    public Close(): void {
        this.dialog.CloseFinished();
    }
}