import { Alert, Stack } from "@mui/material";
import { IRuleResult } from "rdptypes/roe/IRule";
import * as React from "react";
import { FC } from "react";

interface Props {
    warnings: IRuleResult[];
};

const ProposalWarnings: FC<Props> = (props) => {
    const uniqueWarnings: IRuleResult[] = [];
    for (const w of props.warnings) {
        const existing = uniqueWarnings.find(x => x.description === w.description);
        if (existing) {
            if (w.severity === "error"
            || (w.severity === "warning" && existing.severity !== "error")) {
                existing.severity = w.severity;
            }
        } else {
            uniqueWarnings.push(w);
        }
    }

    return (
        <>
            <Stack sx={{ width: '100%' }} spacing={2}>
                {uniqueWarnings.map((warning) => (
                    <Alert severity={warning.severity === "denied" ? "error" : warning.severity}>{warning.description}</Alert>
                ))}
            </Stack>
        </>
    );
};

export default ProposalWarnings;