import { Stack, TextField } from "@mui/material";
import i18next, { t } from "i18next";
import IPrecisionEndGunAcres from "rdptypes/roe/IPrecisionEndGunAcres";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { createNewUpdateSystemPropertyAction } from "../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../auth/AuthCtx";
import IDbProject from "../../../db/IDbProject";
import { getValue } from "../../../helpers/objectPathResolver";
import ISystem from "../../../model/project/ISystem";
import { pageStyles, secondaryColor } from "../styles";
import IComponentRenderContext from "./IComponentRenderContext";
import { isCenterPivot } from "./Proposal/ProposalHelpers";

interface Props {
    cmp: IPrecisionEndGunAcres,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    ctx: IComponentRenderContext,
    parentGroupLabel: string | null, //either the same as the current component, don't show title; different from parent, do show title in grey; null, not in a group so display in blue
    small: boolean;
};

const parseFloatOrUndefined = (str: string): number | undefined => {
    const num = parseFloat(str);
    return isNaN(num) ? undefined : num;
}

const percentageToDegrees = (pc: number) => {
    return (pc/100) * 360;
}

const degreesToPercentage = (degrees: number) => {
    return (degrees/360) * 100;
}

const getStringValueFromNumber = (value: number, decimalPlaces?: number): string | undefined => {
    if (value === undefined || value === null) return undefined;
    if (decimalPlaces === undefined) return value.toString();
    return value.toFixed(decimalPlaces);
}

const getStringValueFromSystem = (system: ISystem, path: string, decimalPlaces?: number): string | undefined => {
    let val = getValue(system, path);
    return getStringValueFromNumber(val, decimalPlaces);
}

const PrecisionEndGunAcresRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    let degrees: string =  undefined;
    let percentage = parseFloatOrUndefined(getStringValueFromSystem(system, props.ctx.fieldRoot + props.cmp.fieldPath));

    if (typeof percentage !== "undefined"){
        degrees = (percentageToDegrees(percentage)).toFixed(0);
    }

    const [degrees_, setDegrees_] = useState<string | undefined>(degrees);

    const fieldPath = props.ctx.fieldRoot + props.cmp.fieldPath;
    const fvr = props.ctx.vws.validationResult?.getField(fieldPath);
    const errorSeverity = fvr?.severity;

    let textInputStyle = {};
    if (props.parentGroupLabel === null)
    {
        textInputStyle["marginLeft"] = props.small ? "4px" :  "10px";
        textInputStyle["paddingRight"] = props.small ? "4px" : "20px";
    }
    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};

    if (errorSeverity !== "error" && errorSeverity !== "notset" && props.parentGroupLabel !== props.cmp.title && props.parentGroupLabel !== null)
    {
        formInputLabelStyle["color"] = secondaryColor;
    }

    return (
        <Stack direction="row"  style={{marginTop: 20}}>
            <TextField
                inputProps={{
                    min: 1,
                    max: 100,
                    style: {fontSize: props.small ? "12px" : "1rem", minWidth: "40px", maxWidth: props.small ? "40px" : "100%"}
                }}
                error={errorSeverity === "error" || errorSeverity === "notset"}
                style={{marginLeft: 10, marginRight: 10}}
                InputLabelProps={{sx:formInputLabelStyle, shrink: true}}
                fullWidth
                variant="standard"
                label={isCenterPivot(system) ? i18next.format(t("common-phrases.sltPercentOfArc"), "capitalize-each") : i18next.format(t("common-phrases.sltPercentOfFieldLength"), "capitalize-each")}
                type="number"
                value={percentage}
                onChange={(ev) => {
                        let val = parseFloatOrUndefined(ev.target.value);
                        let degrees: string = undefined;
                        if (typeof val !== "undefined"){
                            degrees = percentageToDegrees(val).toFixed(0);
                        }

                        setDegrees_(degrees);

                        props.ctx.pushActionAndImproveScores(createNewUpdateSystemPropertyAction(
                            props.layoutId,
                            props.systemId,
                            props.cmp.fieldPath,
                            val,
                            authState
                        ), [], false)
                    }
                }
            />{
                isCenterPivot(system) && <TextField
                    inputProps={{
                        min: 1,
                        max: 360,
                        style: {fontSize: props.small ? "12px" : "1rem", minWidth: "40px", maxWidth: props.small ? "40px" : "100%"}
                    }}
                    style={{marginLeft: 10, marginRight: 10}}
                    InputLabelProps={{sx:formInputLabelStyle, shrink: true}}
                    fullWidth
                    variant="standard"
                    label={"Degrees"}
                    type="number"
                    value={degrees_}
                    onChange={(ev) => {
                        let val = parseFloatOrUndefined(ev.target.value);
                        let percentage: number = undefined;
                        if (typeof val !== "undefined"){
                            setDegrees_(val.toString());
                            percentage = Math.round(degreesToPercentage(val));
                        }
                        props.ctx.pushActionAndImproveScores(createNewUpdateSystemPropertyAction(
                            props.layoutId,
                            props.systemId,
                            props.cmp.fieldPath,
                            percentage,
                            authState
                        ), [], false)
                    }}
                />
            }
        </Stack>
    );
};

export default PrecisionEndGunAcresRenderer;