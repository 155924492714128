import { Feature, MultiPolygon, Point, Polygon, bbox, booleanContains, buffer, distance, feature, point, pointGrid, pointsWithinPolygon, simplify } from "@turf/turf";
import { IOptions } from "..";
import { customIntersect } from "../../../GeometryHelpers";
import { ILosV3Input, generateLineOfSightV3Input } from "../lineOfSightTriangles.v3";
import { optimizeByCenter } from "./optimizeByCenterV2";

// Method 3 adapted from:
// https://www.redblobgames.com/articles/visibility/
// https://github.com/Silverwolf90/2d-visibility

interface IArgs {
    boundary: Polygon;
    obstacles: Polygon[];
    center?: Point;
    centerBoundary?: Polygon;
    maxSystemRadiusFt?: number;
    minSystemRadiusFt?: number;
}

export type IPartialPivotOptimizerResult = undefined | {
    center: Point,
    radiusFeet: number,
    minBearing: number,
    maxBearing: number,
    areaM2: number;
}


const optimize = async (args: IArgs, options?: IOptions): Promise<IPartialPivotOptimizerResult> => {
    // const tpp = createTimerLog("cpo.postbuffer.pp.optimize");
    const nCells = Math.abs(options?.nCells || 100);
    const quick = options?.quick || false;
    if (args.center) {
        const boundaryPolygon = args.boundary;
        if (!boundaryPolygon) return undefined;
        const losV3Input = generateLineOfSightV3Input({
            obstacles: args.obstacles,
            boundaryPolygon: boundaryPolygon,
            minSystemRadiusFt: args.minSystemRadiusFt
        });
        const losV3InputByCenter: ILosV3Input[] = losV3Input.filter(input => {
            return booleanContains(input.holedBoundary, args.center);
        });
        if (losV3InputByCenter.length === 0) return undefined;
        if (losV3InputByCenter.length !== 1) {
            console.warn("generateLineOfSightV3Input generated multiple boundaries which includes this center", losV3InputByCenter, args.center);
        }
        const result = await optimizeByCenter({
            losV3Input: losV3InputByCenter[0],
            center: args.center,
            maxSystemRadiusFt: args.maxSystemRadiusFt,
            minSystemRadiusFt: args.minSystemRadiusFt
        })
        return result;
    }
    
    const bboxBoundary = args.centerBoundary || args.boundary;
    const [ minX, minY, maxX, maxY ] = bbox(bboxBoundary);
    // restrict the number of points to test for speed purposes
    let x = distance([ minX, minY ], [ maxX, minY ], { units: 'feet' });
    let y = distance([ minX, maxY ], [ maxX, maxY ], { units: 'feet' });
    const d = Math.max(x / nCells, y / nCells, 10);

    const pg = pointGrid([ minX, minY, maxX, maxY ], d, { units: 'meters', mask: feature(bboxBoundary) });
    if (!quick) {
        // here we are adding some boundary verticies using buffer and simplify
        // this helps to find a more optimal pp
        // buffering allows the pp to explore points away from radii
        // simplifying reduces the number of points to consider
        if (bboxBoundary) {
            for (let i = 1; i <= 21; i += 10) {
                let f: Feature<Polygon | MultiPolygon> | null = buffer(bboxBoundary, -i, { units: 'feet' });
                if (f && args.centerBoundary) {
                    f = customIntersect(f, args.centerBoundary);
                }
                if (f) {
                    f = simplify(f, { tolerance: 0.00001, highQuality: false });
                    // console.log(">> i", i, f.geometry.coordinates[0].length, args.boundary.coordinates[0].length)
                    // console.log(JSON.stringify(featureCollection([feature(args.boundary), f])))
                }
                if (f) {
                    const coords = f.geometry.type === 'MultiPolygon'
                        ? f.geometry.coordinates
                        : [ f.geometry.coordinates ];
                    for (const poly of coords) {
                        for (const ring of poly) {
                            for (const coord of ring) {
                                pg.features.push(point(coord));
                            }
                        }
                    }
                }
            }
        }
    }
    // tpp.logDeltaMS("points");

    let best: IPartialPivotOptimizerResult | undefined = undefined;

    const losV3Input = generateLineOfSightV3Input({
        obstacles: args.obstacles,
        boundaryPolygon: args.boundary,
        minSystemRadiusFt: args.minSystemRadiusFt
    });
    const considerCenter = async (center: Feature<Point>) => {
        // const tpp = createTimerLog("cpo.postbuffer.pp.optimize.consider");
        const losV3InputByCenter: ILosV3Input[] = losV3Input.filter(input => {
            return booleanContains(input.holedBoundary, center);
        });
        if (losV3InputByCenter.length === 0) return null;
        if (losV3InputByCenter.length !== 1) {
            console.warn("generateLineOfSightV3Input generated multiple boundaries which includes this center", losV3InputByCenter, center);
        }

        const boundaryPolygon = args.boundary;
        if (!boundaryPolygon) return null;

        if (pointsWithinPolygon(center, boundaryPolygon).features.length === 0) {
            // this center is not inside the boundary
            return null;
        }
        if (args.centerBoundary && pointsWithinPolygon(center, args.centerBoundary).features.length === 0) {
            // this center is not inside the pivot center boundary
            return null;
        }

        // tpp.logDeltaMS("init")
        const result = await optimizeByCenter({
            losV3Input: losV3InputByCenter[0],
            center: center.geometry,
            maxSystemRadiusFt: args.maxSystemRadiusFt,
            minSystemRadiusFt: args.minSystemRadiusFt
        })
        // tpp.logDeltaMS("optimize")
        if (result) {
            if (!best || best.areaM2 < result.areaM2) {
                best = result;
            }
        }
        // tpp.logDeltaMS("result")
    }
    await Promise.all(pg.features.map(x => considerCenter(x)));
    // tpp.logDeltaMS("consider points");
    
    // console.log("BEST>>>", JSON.stringify(best));
    return best ? best : undefined;
}

export default optimize;