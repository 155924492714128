import { InputLabel } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { t } from "i18next";
import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { EndOfSystemTypes, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useContext } from "react";
import { createSetEndOfSystemAction } from "../../../../actions/SetEndOfSystemAction";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from '../../../../db/IDbProject';
import { pageStyles, primaryColor } from "../../styles";
import IComponentRenderContext from "../IComponentRenderContext";
import { IEndOfSystemValidationResult } from "./endOfSystemValidator";

interface Props {
  dbPrj: IDbProject;
  layoutId: string;
  systemId: string;
  side: SideEnum;
  ctx: IComponentRenderContext;
  endOfSystemValidation: IEndOfSystemValidationResult;
};

const prefix = "roe.end-of-system.";

const EndOfSystemTypeSelect: FC<Props> = (props) => {

  const authState = useContext(AuthCtx);
  const project = props.dbPrj.state;
  const layout = project.layouts[props.layoutId];
  const system = layout.systems[props.systemId];
  const currentValue = getSide(system, props.side).EndOfSystem.EndOfSystemType;
  const canHaveSac = props.ctx.editingDealerDefaults || system.SystemProperties.SystemType === SystemTypes.CenterPivot || system.SystemProperties.SystemType === SystemTypes.SwingArmRetro;
  const canHaveEndBoom = props.ctx.editingDealerDefaults || (system.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && system.SystemProperties.SystemType !== SystemTypes.Ancillary);

  const fvr = props.endOfSystemValidation.endOfSystemType;
  let errorSeverity = fvr?.severity;

  
  let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
  if (errorSeverity !== "error" && errorSeverity !== "notset"){
    formInputLabelStyle["color"] = primaryColor;
  }

  var menuItems: JSX.Element[] = [];

    if (props.ctx.editingDealerDefaults) {
        const menuItemCmp = (
            <MenuItem 
                key="Select" 
                value={-1}
            > 
                <ListItemText>Select (No Default)</ListItemText>
            </MenuItem>
        )
        menuItems.push(menuItemCmp);
    }

  if (canHaveEndBoom) {
    // none option:
    const disabled = fvr?.badValues.has(EndOfSystemTypes.EndBoom);
    let toolTipText = (fvr?.badValues.get(EndOfSystemTypes.EndBoom)?.reasons ?? []).join(", ");
    if (toolTipText !== "") {
      toolTipText = "Selecting this option would introduce validation errors: " + toolTipText;
    }
    const menuItemCmp = (
        <MenuItem 
            key={EndOfSystemTypes.EndBoom} 
            value={EndOfSystemTypes.EndBoom}
            title={toolTipText}
            style={{color: disabled ? "lightgrey" : undefined}} // Cannot use the disabled prop here because it blocks the tooltips
        > 
            <ListItemText>{t(prefix + "end-boom")}</ListItemText>
        </MenuItem>
    )
    menuItems.push(menuItemCmp);
  }
  if (canHaveSac) {
    // none option:
    const disabled = fvr?.badValues.has(EndOfSystemTypes.SAC);
    let toolTipText = (fvr?.badValues.get(EndOfSystemTypes.SAC)?.reasons ?? []).join(", ");
    if (toolTipText !== "") {
      toolTipText = "Selecting this option would introduce validation errors: " + toolTipText;
    }
    const menuItemCmp = (
        <MenuItem 
            key={EndOfSystemTypes.SAC} 
            value={EndOfSystemTypes.SAC}
            title={toolTipText}
            style={{color: disabled ? "lightgrey" : undefined}} // Cannot use the disabled prop here because it blocks the tooltips
        > 
            <ListItemText>{t(prefix + "swing-arm-corner")}</ListItemText>
        </MenuItem>
    )
    menuItems.push(menuItemCmp);
  }

  return (
    <FormControl variant="standard" sx={{ m: 1, width: '100%', margin: 0 }} error={errorSeverity === "error" || errorSeverity === "notset"}>
        <InputLabel shrink style={formInputLabelStyle}>{t(prefix + "type")}</InputLabel>
        <Select
            sx={{ color: (errorSeverity === "error" || errorSeverity === "notset") ? "red": undefined }}
            value={currentValue === undefined ? -1 : currentValue}
            onChange={(ev) => {
                const value = ev.target.value;

                if (value === -1) {
                    props.ctx.pushActionAndImproveScores(
                            createSetEndOfSystemAction(
                                props.layoutId,
                                props.systemId,
                                props.side,
                                {
                                    clear: true
                                },
                                authState
                            ), [], true
                        );
                    return;
                }

                if (fvr?.badValues.has(value)) {
                    // Disabled
                    return;
                }
                switch (value as EndOfSystemTypes) {
                    case EndOfSystemTypes.EndBoom:
                    case EndOfSystemTypes.SAC: {
                        props.ctx.pushActionAndImproveScores(
                                createSetEndOfSystemAction(
                                    props.layoutId,
                                    props.systemId,
                                    props.side,
                                    {
                                        endOfSystemType: value as EndOfSystemTypes
                                    },
                                    authState
                                ), [], true
                            )
                        break;
                    }
                }
            }}  
            renderValue={(v) => {
                if (v === -1) return props.ctx.editingDealerDefaults ? "Select (No Defaults)" : "Select";
                if (v === EndOfSystemTypes.EndBoom) {
                    return t(prefix + "end-boom");
                }
                if (v === EndOfSystemTypes.SAC) {
                    return t(prefix + "swing-arm-corner");
                }
                return v;
            }}       
        >
            {
                menuItems
            }
        </Select>
    </FormControl>
  );
}

export default EndOfSystemTypeSelect;