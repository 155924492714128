import IAction from "rdptypes/IAction";
import IGrowerBase from "rdptypes/api/IGrowerBase";
import IComponent from "rdptypes/roe/IComponent";
import * as React from "react";
import { PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { createNewMultiAction } from "../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import AuthCtx from "../../auth/AuthCtx";
import IAuthState from "../../auth/IAuthState";
import { SystemValidationResult, validateComponent } from "../../components/roe/validation/SystemValidator";
import IDbProject from "../../db/IDbProject";
import { setValue } from "../../helpers/objectPathResolver";
import ISystem from "../../model/project/ISystem";
import DealerDataCtx from "../../userData/DealerDataCtx";
import IValidationWorkerState, { ValidationWorkerStatus } from "./IValidationWorkerState";
import ValidationWorkerCtx from "./ValidationWorkerCtx";
import { ComponentToValidate, ValidationManagerRequest, ValidationManagerResponse } from "./manager";
import { ValidationWorkerRequest, ValidationWorkerResponse } from "./worker";

interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
    grower: IGrowerBase;
}

interface IValidationTask {
    id: string;
    system: ISystem;
    componentsToValidate?: ComponentToValidate[];
    changingFieldPaths: string[];
    nCompletedComponents: number;
    nextComponentIndex?: number;
    validationResult?: SystemValidationResult;
    mainPageId: string;
    resolve: (result: SystemValidationResult) => void;
    reject: (err: any) => void;
}

const ValidationWorkerProvider: React.FC<PropsWithChildren<Props>> = (props) => {
    const authState = useContext(AuthCtx);
    const user = useContext(DealerDataCtx);

    const manager = useRef<Worker | undefined>();
    const workers = useRef<Worker[] | undefined>();
    const workerStates = useRef<boolean[] | undefined>();
    const currentValidationTask = useRef<IValidationTask | undefined>();

    const assignWork = () => {
        if (currentValidationTask.current.componentsToValidate) {
            for (let i = 0;
                i < workerStates.current.length
                && currentValidationTask.current.nextComponentIndex < currentValidationTask.current.componentsToValidate.length;
                i++) {
                if (!workerStates.current[i]) {
                    const cmps = currentValidationTask.current.componentsToValidate.slice(
                        currentValidationTask.current.nextComponentIndex,
                        Math.min(50, currentValidationTask.current.componentsToValidate.length - currentValidationTask.current.nextComponentIndex));
                    workers.current[i].postMessage({
                        id: currentValidationTask.current.id,
                        system: currentValidationTask.current.system,
                        cmps,
                        user: {
                            ...user,
                            priceList: {} // Avoid copying price list to workers, for the time being, for performance reasons
                        }
                    } as ValidationWorkerRequest);
                    workerStates.current[i] = true;
                    currentValidationTask.current.nextComponentIndex += cmps.length;
                }
            }
        }
    };

    // Curry prevValidationResult
    const validateWithPrevValidationResult = (prevValidationResult: SystemValidationResult | undefined) => (
        system: ISystem,
        changingFieldPaths: string[],
        mainPageId: string,
    ) => validate(system, changingFieldPaths, mainPageId, prevValidationResult);

    const validateCmp = (
        system: ISystem,
        cmp: IComponent,
        fieldRoot: string,
        pageId: string,
    ) => {
        const validationResult = new SystemValidationResult();
        validateComponent(cmp, {
            system,
            user,
            grower: props.grower,
            fieldRoot: fieldRoot,
            pageId: pageId,
            result: validationResult,
            fast: false
        });
        return validationResult;
    }
    
    const validate = (
        system: ISystem,
        changingFieldPaths: string[],
        mainPageId: string,
        prevValidationResult: SystemValidationResult | undefined) => {
        return new Promise<SystemValidationResult>((resolve, reject) => {
            init();

            if (currentValidationTask.current) {
                currentValidationTask.current.reject(new Error("Validation interrupted"));
            }

            setVws({
                status: ValidationWorkerStatus.Validating,
                validationResult: prevValidationResult,
                validate: validateWithPrevValidationResult(prevValidationResult),
                validateCmp,
            });
            currentValidationTask.current = {
                id: uuidv4(),
                changingFieldPaths,
                system,
                mainPageId,
                nCompletedComponents: 0,
                resolve,
                reject
            };
            console.log(`Validation [${currentValidationTask.current.id}] starting`);
            manager.current.postMessage({
                id: currentValidationTask.current.id,
                system,
                mainPageId
            } as ValidationManagerRequest);
        });
    }
    
    const [ vws, setVws ] = useState<IValidationWorkerState>({
        status: ValidationWorkerStatus.Validated,
        validate: validateWithPrevValidationResult(undefined),
        validateCmp,
    });

    // Initialize when validate() is first called, rather than inside a useEffect, because we want to be able
    // to call validate() from within a child component's useEffect, which will be run before this
    // component's useEffect due to the execution order of componentDidMount in React.
    const init = () => {
        if (manager.current) {
            // Already initialized
            return;
        }

        manager.current = new Worker(
            new URL("./manager.ts", import.meta.url),
            { type: "module" }
        )
        manager.current.onmessage = (
            ev: MessageEvent<ValidationManagerResponse>
        ) => {
            const { id, cmps } = ev.data;
            if (id === currentValidationTask.current.id) {
                currentValidationTask.current.componentsToValidate = cmps;
                currentValidationTask.current.nextComponentIndex = 0;
                currentValidationTask.current.validationResult = new SystemValidationResult();
                assignWork();
            }
        };

        workers.current = [];
        workerStates.current = [];
        for (let i = 0; i < navigator.hardwareConcurrency ?? 8; i++) {
            let iworker = i;
            var worker = new Worker(
                new URL("./worker.ts", import.meta.url),
                { type: "module" }
            );
            worker.onmessage = (
                ev: MessageEvent<ValidationWorkerResponse>
            ) => {
                const { id, fvrFields } = ev.data;
                workerStates.current[iworker] = false;
                if (id === currentValidationTask.current.id) {
                    for (const id of Object.keys(fvrFields)) {
                        const existingField = currentValidationTask.current.validationResult.fields[id];
                        if (existingField) {
                            for (const bv of fvrFields[id].badValues) {
                                existingField.badValues.set(bv[0], bv[1]);
                            }
                            for (const pageId of fvrFields[id].pageIds) {
                                existingField.pageIds.add(pageId);
                            }
                            if (fvrFields[id].severity === "error" || fvrFields[id].severity === "notset") {
                                existingField.severity = fvrFields[id].severity;
                            }
                            if (existingField.severity !== "error" && existingField.severity !== "notset") {
                                existingField.severity = fvrFields[id].severity;
                            }
                            if (fvrFields[id].reccomendChange) {
                                existingField.reccomendChange = true;
                                existingField.recommendedValue = fvrFields[id].reccomendChange;
                            }
                        } else {
                            currentValidationTask.current.validationResult.fields[id] = fvrFields[id];
                        }
                    }
                    currentValidationTask.current.nCompletedComponents++;
                    if (currentValidationTask.current.nCompletedComponents === currentValidationTask.current.componentsToValidate.length) {
                        console.log(`Validation [${currentValidationTask.current.id}] finished`);
                        currentValidationTask.current.resolve(currentValidationTask.current.validationResult);
                        setVws({
                            status: ValidationWorkerStatus.Validated,
                            validate: validateWithPrevValidationResult(currentValidationTask.current.validationResult),
                            validationResult: currentValidationTask.current.validationResult,
                            validateCmp,
                        });
                        currentValidationTask.current = undefined;
                    } else if (currentValidationTask.current.nCompletedComponents % 50 === 0) {
                        console.log(`Validation [${currentValidationTask.current.id}] progress ${currentValidationTask.current.nCompletedComponents} / ${currentValidationTask.current.componentsToValidate.length}`);
                        /*setVws({
                            status: ValidationWorkerStatus.Validating,
                            validate: validateWithPrevValidationResult(currentValidationTask.current.validationResult),
                            validationResult: currentValidationTask.current.validationResult
                        });*/
                    }
                }
                assignWork();
            };
            workers.current.push(worker);
            workerStates.current.push(false);
        };
    };

    useEffect(() => {
        // Return cleanup function
        return () => {
            if (!manager.current) {
                // Not yet initialized
                return;
            }

            // Unmount
            manager.current.terminate();
            manager.current = undefined;

            for (const worker of workers.current) {
                worker.terminate();
            }
            workers.current = undefined;
            workerStates.current = undefined;
        };
    }, []);

    return (
        <ValidationWorkerCtx.Provider value={vws}>
            {props.children}
        </ValidationWorkerCtx.Provider>
    );
}

export const improveScores = (
    system: ISystem,
    authState: IAuthState,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    vr: SystemValidationResult,
    changingFieldPaths: string[]): IAction | undefined => {
    // We apply improvements to the system passed in, not the system from the DB,
    // because the DB might not have been updated at the moment this runs.
    const updatedSystem = JSON.parse(JSON.stringify(system));

    const actions: IAction[] = [];
    for (const fp of vr.getFieldPaths()) {
        // Don't change straight back
        if (changingFieldPaths.some(x => x === fp)) continue;

        const vrf = vr.getField(fp);
        if (vrf.reccomendChange) {
            vrf.severity = undefined;
            vrf.reccomendChange = false;
            if (vrf.isComplex) {
                const recommendedValues = JSON.parse(vrf.recommendedValue);
                for (const [fieldPath, value] of Object.entries(recommendedValues)) {
                    actions.push(
                        createNewUpdateSystemPropertyAction(
                            layoutId,
                            systemId,
                            fieldPath,
                            value,
                            authState
                        )
                    );
                    setValue(updatedSystem, fieldPath, value);
                }
            }
            else {
                actions.push(
                    createNewUpdateSystemPropertyAction(
                        layoutId,
                        systemId,
                        fp,
                        vrf.recommendedValue,
                        authState
                    )
                );
                setValue(updatedSystem, fp, vrf.recommendedValue);
            }
        }
    }
    if (actions.length) {
        return createNewMultiAction(actions, authState);
    } else {
        return undefined;
    }
}

export default ValidationWorkerProvider;