import { InputLabel } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { EndOfSystemTypes, SwingArmLengths } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useContext } from "react";
import { createSetEndOfSystemAction } from "../../../../actions/SetEndOfSystemAction";
import AuthCtx from "../../../../auth/AuthCtx";
import DevSettingsCtx from "../../../../db/DevSettingsCtx";
import IDbProject from '../../../../db/IDbProject';
import { getSwingArmLengths } from "../../../../reinkeComponentConversions/swingArmLengths";
import { pageStyles, primaryColor } from "../../styles";
import IComponentRenderContext from "../IComponentRenderContext";
import { IEndOfSystemValidationResult } from "./endOfSystemValidator";

interface Props {
  dbPrj: IDbProject;
  layoutId: string;
  systemId: string;
  side: SideEnum;
  ctx: IComponentRenderContext;
  endOfSystemValidation: IEndOfSystemValidationResult;
};

const SwingArmLengthSelect: FC<Props> = (props) => {

  const settings = useContext(DevSettingsCtx);
  const displaySettings = settings.dealerSettings.display.current;
  const authState = useContext(AuthCtx);
  const project = props.dbPrj.state;
  const layout = project.layouts[props.layoutId];
  const system = layout.systems[props.systemId];
  const currentValue = getSide(system, props.side).EndOfSystem.SwingArmLength;
  
  const fvr = props.endOfSystemValidation.swingArmLength;
  let errorSeverity = fvr?.severity;

  var menuItems: JSX.Element[] = [];

  if (props.ctx.editingDealerDefaults) {
      const menuItemCmp = (
          <MenuItem 
              key="Select" 
              value={SwingArmLengths.None}
          > 
              <ListItemText>Select (No Default)</ListItemText>
          </MenuItem>
      )
      menuItems.push(menuItemCmp);
  }

  getSwingArmLengths(displaySettings.lengths).forEach((option, i) => {
    const disabled = fvr?.badValues.has(option.originalValue);
    let toolTipText = (fvr?.badValues.get(option.originalValue)?.reasons ?? []).join(", ");
    if (toolTipText !== "") {
      toolTipText = "Selecting this option would introduce validation errors: " + toolTipText;
    }

    const menuItemCmp = (
      <MenuItem 
        key={option.originalValue} value={option.originalValue}
        style={{color: disabled ? "lightgrey" : undefined}} // Cannot use the disabled prop here because it blocks the tooltips
        title={toolTipText}>                                              
        <ListItemText>{option.label}</ListItemText>
      </MenuItem>
    );
    menuItems.push(menuItemCmp);
  });

  let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
  if (errorSeverity !== "error" && errorSeverity !== "notset"){
    formInputLabelStyle["color"] = primaryColor;
  }
  
  return (
    <FormControl variant="standard" sx={{ m: 1, width: '100%', margin: 0 }} error={errorSeverity === "error" || errorSeverity === "notset"}>
        <InputLabel shrink style={formInputLabelStyle}>Swing Arm Length</InputLabel>
        <Select
            sx={{ color: (errorSeverity === "error" || errorSeverity === "notset") ? "red": undefined }}
            value={currentValue ?? SwingArmLengths.None}
            onChange={(ev) => {
                const value = ev.target.value;

                if (fvr?.badValues.has(value)) {
                  // Disabled
                  return;
                }
                props.ctx.pushActionAndImproveScores(
                    createSetEndOfSystemAction(
                        props.layoutId,
                        props.systemId,
                        props.side,
                        {
                            endOfSystemType: EndOfSystemTypes.SAC,
                            swingArmLength: value as SwingArmLengths 
                        },
                        authState
                    ), [], true
                )
            }}      
            renderValue={(v) => {
                if (v === SwingArmLengths.None) {
                  return props.ctx.editingDealerDefaults ? "Select (No Default)" : "Select"
                }
                return getSwingArmLengths(displaySettings.lengths).find(x => x.originalValue === v)?.label;
            }}      
        >
            {
                menuItems
            }
        </Select>
    </FormControl>
  );
}

export default SwingArmLengthSelect;