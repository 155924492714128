import styled from "@emotion/styled";
import { AppBar, Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Tooltip } from "@mui/material";
import * as React from "react";
import { FC, useContext, useLayoutEffect, useState } from "react";

import "@fontsource/libre-franklin/900.css";
import { Handyman } from "@mui/icons-material";
import Business from "@mui/icons-material/Business";
import Close from "@mui/icons-material/Close";
import DocsMenuIcon from '@mui/icons-material/FileOpen';
import Key from "@mui/icons-material/Key";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from '@mui/icons-material/Menu';
import { t } from "i18next";
import { UserClass } from "rdptypes/IUserData";
import { useNavigate } from "react-router-dom";
import AuthCtx from "../../auth/AuthCtx";
import { VERSION } from "../../constants";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import DealerDataCtx from "../../userData/DealerDataCtx";
import DealerSettingsDialog from "../DealerSettingsDialog";
import LogInAsDialog from "../LogInAsDialog";
import { MapLayerType } from "../Map";
import NameAvatar from "../NameAvatar";
import MapLayersMenuItem from "./MapLayersMenuItem";
import ReinkeIcon from "./ReinkeIcon";
import SyncMenuItem from "./SyncMenuItem";

interface Props {
    leftContent?: JSX.Element;
    rightContent?: JSX.Element[];
    onClose?: () => any;

    hideMapLayerOptions?: boolean;
}

const LogoText = styled.span`
    color: white;
    font-family: "Libre Franklin", sans-serif;
    font-weight: 900;
    font-size: 14px;
`;

const docs: { name: string, url: URL }[] = [
    { name: "Pivoting Lateral", url: new URL('./../../assets/marketingDocs/0615_Pivoting_Lateral_Sales_Slick.pdf', import.meta.url) },
    { name: "Pivoting Lateral Data Sheet", url: new URL('./../../assets/marketingDocs/0415_Pivoting_Lateral_Data_Sheet.pdf', import.meta.url) },
    { name: "Aux Box", url: new URL('./../../assets/marketingDocs/Aux Box Sell Sheet Final Digital.pdf', import.meta.url) },
    { name: "Center Pivot vs Drip Irrigation", url: new URL('./../../assets/marketingDocs/Center Pivot vs Drip Irrig SS Final.pdf', import.meta.url) },
    { name: "Center Pivot vs Flood Irrigation", url: new URL('./../../assets/marketingDocs/Center Pivot vs Flood Irrig SS Final.pdf', import.meta.url) },
    { name: "Reinke Company Profile", url: new URL('./../../assets/marketingDocs/Company Profile Brochure Rev B 1123 Digital.pdf', import.meta.url) },
    { name: "CropX", url: new URL('./../../assets/marketingDocs/CropX Sell Sheet Rev C 1122.pdf', import.meta.url) },
    { name: "Reinke Direct ET by CropX", url: new URL('./../../assets/marketingDocs/Direct ET CropX Sell Sheet Final Digital.pdf', import.meta.url) },
    { name: "FreeWave", url: new URL('./../../assets/marketingDocs/FREEWAVE Sell Sheet Final 101922.pdf', import.meta.url) },
    { name: "Maintenance-Free Bearing", url: new URL('./../../assets/marketingDocs/MFB, Sell Sheet, Color, Print.pdf', import.meta.url) },
    { name: "RC10", url: new URL('./../../assets/marketingDocs/RC 10 - 710437 Rev A.pdf', import.meta.url) },
    { name: "RC10d", url: new URL('./../../assets/marketingDocs/RC10d Data Sheet Rev A.pdf', import.meta.url) },
    { name: "Reinke Wheel Gearbox", url: new URL('./../../assets/marketingDocs/Rev A - 3-20 - Wheel Gearbox, Sell Sheet.pdf', import.meta.url) },
    { name: "Advanced Plus", url: new URL('./../../assets/marketingDocs/Rev A - Advanced Plus Sell Sheet.pdf', import.meta.url) },
    { name: "Annex", url: new URL('./../../assets/marketingDocs/Rev A - Annex Sell Sheet.pdf', import.meta.url) },
    { name: "Connect", url: new URL('./../../assets/marketingDocs/Rev A - Connect Sell Sheet.pdf', import.meta.url) },
    { name: "Sprinkler Lube", url: new URL('./../../assets/marketingDocs/Sprinkler Lube Flyer final 012116.pdf', import.meta.url) },
    { name: "VRI", url: new URL('./../../assets/marketingDocs/VRI Brochure Rev -.pdf', import.meta.url) },
];

const RdpAppBar: FC<Props> = (props) => {
    const auth = useContext(AuthCtx);
    const userInfo = useContext(DealerDataCtx);
    const assumedAnotherUser = userInfo.assumedUser.id !== userInfo.loggedInUser.id;
    const navigate = useNavigate();

    const devSettingsState = useContext(DevSettingsCtx);

    const [accountMenuTarget, setAccountMenuTarget] = React.useState<null | HTMLElement>(null);
    const [docsMenuTarget, setDocsMenuTarget] = React.useState<null | HTMLElement>(null);
    const [toolsMenuTarget, setToolsMenuTarget] = React.useState<null | HTMLElement>(null);
    const [allSettingsMenuTarget, setAllSettingsMenuTarget] = React.useState<null | HTMLElement>(null);
    const [mapLayersMenuTarget, setMapLayersMenuTarget] = React.useState<null | HTMLElement>(null);

    const [ showDealerSettingsDialog, setShowDealerSettingsDialog ] = useState(false);
    const [ showLogInAsDialog, setShowLogInAsDialog ] = useState(false);

    const openAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAccountMenuTarget(event.currentTarget);
    };

    const closeAccountMenu = () => {
        setAccountMenuTarget(null);
    };

    const returnToMyAccount = () => {
        auth.setAssumedUserId(auth.loggedInUserId);
        navigate("/");
    }

    const switchUser = () => {
        auth.setAssumedUserId(undefined);
        auth.setLoggedInUserId(undefined);
        navigate("/");
    };

    const openDocsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setDocsMenuTarget(event.currentTarget);
    };

    const closeDocsMenu = () => {
        setDocsMenuTarget(null);
    };

    const openToolsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setToolsMenuTarget(event.currentTarget);
    };

    const openAllSettingsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAllSettingsMenuTarget(event.currentTarget);
    };

    const openMapLayersMenu = (event: React.MouseEvent<HTMLElement>) => {
        setMapLayersMenuTarget(event.currentTarget);
    };

    const closeToolsMenu = () => {
        setToolsMenuTarget(null);
    };

    const closeAllSettingsMenu = () => {
        setAllSettingsMenuTarget(null);
    };

    const closeMapLayersMenu = () => {
        setMapLayersMenuTarget(null);
    };

    const openDealerSettingsDialog = () => {
        closeAccountMenu();
        setShowDealerSettingsDialog(true);
    }
    const closeDealerSettingsDialog = () => {
        setShowDealerSettingsDialog(false);
    }

    const openLogInAsDialog = () => {
        closeAccountMenu();
        setShowLogInAsDialog(true);
    }
    const closeLogInAsDialog = () => {
        setShowLogInAsDialog(false);
    }

    const setMapLayerType = (type: MapLayerType) => {
        closeMapLayersMenu(); 
        devSettingsState.mapSettings.setMapLayerType(type);
    }

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
          function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
          }
          window.addEventListener('resize', updateSize);
          updateSize();
          return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
      }

    const ns = "nav.";

    var rhsIcons: JSX.Element[] = [];

    if(props.rightContent){
        props.rightContent.forEach(icon =>{
            rhsIcons.push(icon);
        });
    }
    
    if(!props.hideMapLayerOptions){
        rhsIcons.push(<MapLayersMenuItem />);
    }
    rhsIcons.push(<div>
        <Tooltip title="Documents" placement="bottom">
            <IconButton
                size="large"
                aria-label="toollbox"
                aria-haspopup="true"
                onClick={openDocsMenu}
                color="inherit"
            >
                <DocsMenuIcon />
            </IconButton>
        </Tooltip>
        <Menu
            anchorEl={docsMenuTarget}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={!!docsMenuTarget}
            onClose={closeDocsMenu}
        >
            {docs.map(doc =>  <MenuItem onClick={() =>{closeDocsMenu(); window.open(doc.url.toString(), '_blank');}}>{doc.name}</MenuItem>)}
        </Menu> 
        </div>);
    rhsIcons.push(<div>
        <Tooltip title={t(ns + "toolbox")} placement="bottom">
            <IconButton
                size="large"
                aria-label="toollbox"
                aria-haspopup="true"
                onClick={openToolsMenu}
                color="inherit"
            >
                <Handyman />
            </IconButton>
        </Tooltip>
        <Menu
            anchorEl={toolsMenuTarget}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={!!toolsMenuTarget}
            onClose={closeToolsMenu}
        >
            <MenuItem onClick={() => {closeToolsMenu();window.open("/tools/mainLinePressureLoss", '_blank'); }}>{t(ns + "tools." + "pressure-loss")}</MenuItem>
        </Menu> 
        </div>);
    rhsIcons.push(<SyncMenuItem />);
    rhsIcons.push(<div>             
        <IconButton
            size="large"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={openAccountMenu}
            color="inherit"
        >
            <NameAvatar name={userInfo.assumedUser.name} />
        </IconButton>
        <Menu
            anchorEl={accountMenuTarget}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={!!accountMenuTarget}
            onClose={closeAccountMenu}
        >
        <Tooltip title="Configure dealer information, logo and pricing" placement="left">
            <MenuItem onClick={openDealerSettingsDialog}>
                <ListItemIcon>
                    <Business fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t(ns + "dealer-settings")}...</ListItemText>
            </MenuItem>
        </Tooltip>
        {/*<Tooltip title="Configure your account" placement="left">
            <MenuItem onClick={closeAccountMenu}><ListItemIcon>
                <ManageAccounts fontSize="small" />
            </ListItemIcon>
                <ListItemText>{t(ns + "account-settings")}...</ListItemText></MenuItem>
        </Tooltip>*/}
        {userInfo.loggedInUser.class !== UserClass.dealer // Don't show "Log in as..." to dealers since they can't assume any other user
        && userInfo.loggedInUser.id === userInfo.assumedUser.id
        && <Tooltip title="Log in as another user" placement="left">
            <MenuItem onClick={openLogInAsDialog}><ListItemIcon>
                <Key fontSize="small" />
            </ListItemIcon>
                <ListItemText>Log in as...</ListItemText></MenuItem>
        </Tooltip>}
        {userInfo.loggedInUser.id !== userInfo.assumedUser.id
        && <Tooltip title="Return to my account" placement="left">
            <MenuItem onClick={returnToMyAccount}><ListItemIcon>
                <Key fontSize="small" />
            </ListItemIcon>
                <ListItemText>Back to my account</ListItemText></MenuItem>
        </Tooltip>}
        <Divider />
        <MenuItem onClick={switchUser}><ListItemIcon>
            <Logout fontSize="small" />
        </ListItemIcon><ListItemText>Logout</ListItemText></MenuItem>
        <Divider />
        <MenuItem disabled><ListItemText>{userInfo.assumedUser.email}</ListItemText></MenuItem>
        <MenuItem disabled><ListItemText>Version: {VERSION}</ListItemText></MenuItem>
    </Menu>
    </div>);

    const [windowWidth, windowHeight] = useWindowSize();

    return (
        <AppBar id="reinke-app-bar"
        style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}
        color={assumedAnotherUser ? "secondary" : "primary"}
        position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar variant="dense">
                <ReinkeIcon full={windowWidth >= 1000} />
                {props.leftContent}
                <Box sx={{ flexGrow: 1 }}></Box>
                {
                    windowWidth > 1500 &&
                    rhsIcons.map(icon => icon)
                }
                {
                    windowWidth < 1500 &&
                    <>
                    <Tooltip title={/*t(ns + "toolbox")*/ ""} placement="bottom">
                        <IconButton
                            size="large"
                            aria-label="settings"
                            aria-haspopup="true"
                            onClick={openAllSettingsMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>  
                    <Menu
                        anchorEl={allSettingsMenuTarget}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={!!allSettingsMenuTarget}
                        onClose={closeAllSettingsMenu}
                    >
                        {rhsIcons.map(icon => <MenuItem>{icon}</MenuItem>)}
                    </Menu>
                    </>                     
                }
                {props.onClose &&
                    <IconButton
                        color="inherit"
                        aria-label="close"
                        size="large"
                        onClick={props.onClose}
                    >
                        <Close />
                    </IconButton>}

                {
                    showDealerSettingsDialog && (
                        <DealerSettingsDialog
                            closeDialog={closeDealerSettingsDialog}
                        />
                    )
                }

                {
                    showLogInAsDialog && (
                        <LogInAsDialog
                            closeDialog={closeLogInAsDialog}
                        />
                    )
                }
            </Toolbar>
        </AppBar>
    );
};

export default RdpAppBar;