import { AgriInjectTypes, EndOfSystemTypes, ISystemBase, SystemTypes, WaterFeedTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import FieldSet from "./FieldSet";

export default class SystemFieldSets {
    private m_SystemType = new FieldSet();
    private m_CenterPivot = new FieldSet();
    private m_KwikTow = new FieldSet();
    private m_HoseFeed = new FieldSet();
    private m_CanalFeed = new FieldSet();
    private m_SACRetro = new FieldSet();
    private m_Guidance = new FieldSet();
    private m_ControlPanel = new FieldSet();
    private m_FlangedSpans = new FieldSet();
    private m_FlexSpans = new FieldSet();
    private m_FlangedTowers = new FieldSet();
    private m_FlexTowers = new FieldSet();
    private m_FlangedEndOfSystem = new FieldSet();
    private m_FlexEndOfSystem = new FieldSet();
    private m_SwingArm = new FieldSet();
    private m_SprinklerConfig = new FieldSet();
    private m_SprinklerChart = new FieldSet();
    private m_Options = new FieldSet();
    private m_MainlineValveOptions = new FieldSet();
    private m_AgriInject = new FieldSet();
    private m_Ontrac = new FieldSet();
    private m_SprinklerLube = new FieldSet();
    private m_ClemonsFilters = new FieldSet();
    private m_CheckValves = new FieldSet();
    private m_HeatExchangers = new FieldSet();
    private m_FlowmeterComponents = new FieldSet();
    private m_PhaseConverters = new FieldSet();
    private m_QuoteProperties = new FieldSet();
    private m_RC10 = new FieldSet();
    private m_CropX = new FieldSet();
    private m_SprinklerProperties = new FieldSet();

    constructor(p: ISystemBase) {
       
        this.m_CenterPivot.valid &&= p.SystemProperties.SystemType === SystemTypes.CenterPivot;
        this.m_KwikTow.valid &&= p.SystemProperties.SystemType === SystemTypes.KwikTow;
        this.m_HoseFeed.valid &&= p.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator;
        this.m_CanalFeed.valid &&= p.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator;
        this.m_SACRetro.valid &&= p.SystemProperties.SystemType === SystemTypes.SwingArmRetro;

        const IsHoseFeedValid = this.m_HoseFeed.valid;
        const IsCanalFeedValid = this.m_CanalFeed.valid;
        const IsMaxigator = IsHoseFeedValid || IsCanalFeedValid;
        const IsNotMaxigator = !IsMaxigator;

        this.m_Guidance.valid &&= IsMaxigator;

        const IsSACRetro = this.m_SACRetro.valid;
        const IsNotSACRetro = !IsSACRetro;

        const IsEDMP = p.Circle.EngineDrivenMiniPivot;
        const IsNotEDMP = !IsEDMP;

        const IsCenterPivotValid = this.m_CenterPivot.valid;
        const IsKwikTowValid = this.m_KwikTow.valid;
        const IsPivotSystem = IsCenterPivotValid || IsKwikTowValid;

        this.m_ControlPanel.valid &&= IsNotEDMP;
        this.m_ControlPanel.valid &&= IsMaxigator || IsPivotSystem;

        const IsGuidanceValid = this.m_Guidance.valid

        const IsControlPanelValid = this.m_ControlPanel.valid;
        const IsControlPanelValidOrIsEDMP = IsControlPanelValid || IsEDMP;

        this.m_FlangedSpans.valid &&= IsNotSACRetro;
        this.m_FlangedSpans.valid &&= IsControlPanelValidOrIsEDMP;
        this.m_FlangedSpans.valid &&= IsNotMaxigator || IsGuidanceValid;

        const IsFlangedSpansValid = this.m_FlangedSpans.valid;

        const IsCenterFeed = IsMaxigator && p.Lateral.WaterFeed === WaterFeedTypes.CenterFeed;
        const IsNotCenterFeed = !IsCenterFeed;

        this.m_FlexSpans.valid &&= IsCenterFeed;
        this.m_FlexSpans.valid &&= IsFlangedSpansValid;

        const IsFlexSpansValid = this.m_FlexSpans.valid;

        this.m_FlangedTowers.valid &&= IsNotSACRetro;
        this.m_FlangedTowers.valid &&= IsFlangedSpansValid;
        this.m_FlangedTowers.valid &&= IsNotCenterFeed || IsFlexSpansValid;

        const IsFlangedTowersValid = this.m_FlangedTowers.valid;
        this.m_FlexTowers.valid &&= IsFlexSpansValid;
        this.m_FlexTowers.valid &&= IsFlangedTowersValid;

        const IsFlexTowersValid = this.m_FlexTowers.valid;
        this.m_FlangedEndOfSystem.valid &&= p.FlangedSide.EndOfSystem.EndOfSystemType !== undefined;
        this.m_FlangedEndOfSystem.valid &&= IsSACRetro || IsFlangedTowersValid;
        this.m_FlangedEndOfSystem.valid &&= IsNotCenterFeed || IsFlexTowersValid;

        const IsPowerTowerEndBoom = !!p.Lateral?.HoseFeed?.PowerTowerEndBoom;

        this.m_FlexEndOfSystem.valid &&= IsFlexTowersValid || (IsPowerTowerEndBoom && IsFlangedSpansValid);
        this.m_FlexEndOfSystem.valid &&= p.FlexSide.EndOfSystem.EndOfSystemType !== undefined;

        const IsFlangedEOSValid = this.m_FlangedEndOfSystem.valid;
        const IsNotEOSEndBoom = p.FlangedSide.EndOfSystem.EndOfSystemType !== EndOfSystemTypes.EndBoom;
        this.m_SwingArm.valid &&= IsFlangedEOSValid;
        this.m_SwingArm.valid &&= IsNotEOSEndBoom;

        const IsDualSided = IsCenterFeed || IsPowerTowerEndBoom;
        const IsNotDualSided = !IsDualSided;

        const IsFlexEndOfSystemValid = this.m_FlexEndOfSystem.valid;
        const rFlexEOS = IsNotDualSided || IsFlexEndOfSystemValid;

        const IsSwingArmValid = this.m_SwingArm.valid;

        const IsEOSEndBoom = p.FlangedSide.EndOfSystem.EndOfSystemType === EndOfSystemTypes.EndBoom;
        const rEndBoomOrSAC = IsEOSEndBoom || IsSwingArmValid;

        this.m_SprinklerConfig.valid &&= IsNotSACRetro;
        this.m_SprinklerConfig.valid &&= IsFlangedEOSValid;
        this.m_SprinklerConfig.valid &&= rFlexEOS;
        this.m_SprinklerConfig.valid &&= rEndBoomOrSAC;

        this.m_SprinklerChart.valid &&= IsNotSACRetro;
        this.m_SprinklerChart.valid &&= IsFlangedEOSValid;
        this.m_SprinklerChart.valid &&= rFlexEOS;
        this.m_SprinklerChart.valid &&= rEndBoomOrSAC;
        this.m_SprinklerChart.valid &&= this.m_SprinklerConfig.valid;

        this.m_Options.valid &&= IsNotSACRetro;
        this.m_Options.valid &&= IsFlangedEOSValid;
        this.m_Options.valid &&= rFlexEOS;
        this.m_Options.valid &&= rEndBoomOrSAC;

        this.m_RC10.valid &&= !!p.RC10?.IsEnabled;
        this.m_Ontrac.valid &&= !!p.Ontrac?.IsEnabled;
        this.m_CropX.valid &&= !!p.CropX?.IsEnabled;
        this.m_AgriInject.valid &&= (p.AgriInject?.AgriInjectType ?? AgriInjectTypes.None) !== AgriInjectTypes.None;

        const IsHeatExchangersValid = this.m_HeatExchangers.valid;
        const IsAgriInjectValid = this.m_AgriInject.valid;
        const IsCheckValvesValid = this.m_CheckValves.valid;
        const IsClemonsFiltersValid = this.m_ClemonsFilters.valid;
        const IsSprinklerLubeValid = this.m_SprinklerLube.valid;
        const IsPhaseConvertersValid = this.m_PhaseConverters.valid;
        const IsMainlineValveOptionsValid = this.m_MainlineValveOptions.valid;
        const IsOntracValid = this.m_Ontrac.valid;
        const IsRC10Valid = this.m_RC10.valid;
        const IsCropXValid = this.m_CropX.valid;
        const IsFlowmeterComponentsValid = this.m_FlowmeterComponents.valid;

        const HasAncillary = IsHeatExchangersValid || IsAgriInjectValid || IsCheckValvesValid || IsClemonsFiltersValid || IsSprinklerLubeValid || IsPhaseConvertersValid || IsMainlineValveOptionsValid || IsOntracValid || IsRC10Valid || IsFlowmeterComponentsValid || IsCropXValid;

        const IsSACRetroAndSwingArmValid = IsSACRetro && IsSwingArmValid;
        const IsOptionsValid = this.m_Options.valid;
        const IsSprinklerChartValid = this.m_SprinklerChart.valid;

        this.m_QuoteProperties.valid &&= IsSACRetroAndSwingArmValid || HasAncillary || (IsOptionsValid && IsSprinklerChartValid);
    }

    public get SystemType() { return this.m_SystemType; }

    public get CenterPivot() { return this.m_CenterPivot; }

    public get HoseFeed() { return this.m_HoseFeed; }

    public get CanalFeed() { return this.m_CanalFeed; }

    public get Guidance() { return this.m_Guidance; }

    public get FlangedSpans() { return this.m_FlangedSpans; }

    public get FlexSpans() { return this.m_FlexSpans; }

    public get FlangedTowers() { return this.m_FlangedTowers; }

    public get FlexTowers() { return this.m_FlexTowers; }

    public get FlangedEndOfSystem() { return this.m_FlangedEndOfSystem; }

    public get FlexEndOfSystem() { return this.m_FlexEndOfSystem; }

    public get Options() { return this.m_Options; }

    public get MainlineValveOptions() { return this.m_MainlineValveOptions; }

    public get KwikTow() { return this.m_KwikTow; }

    public get ControlPanel() { return this.m_ControlPanel; }

    public get SACRetro() { return this.m_SACRetro; }

    public get SwingArm() { return this.m_SwingArm; }

    public get SprinklerConfig() { return this.m_SprinklerConfig; }

    public get SprinklerProperties() { return this.m_SprinklerProperties; }

    public get SprinklerChart() { return this.m_SprinklerChart; }

    public get AgriInject() { return this.m_AgriInject; }

    public get Ontrac() { return this.m_Ontrac; }

    public get SprinklerLube() { return this.m_SprinklerLube; }

    public get ClemonsFilters() { return this.m_ClemonsFilters; }

    public get CheckValves() { return this.m_CheckValves; }

    public get HeatExchangers() { return this.m_HeatExchangers; }

    public get FlowmenterComponents() { return this.m_FlowmeterComponents; }

    public get QuoteProperties() { return this.m_QuoteProperties; }

    public get RC10() { return this.m_RC10; }

    public get CropX() { return this.m_CropX; }
}