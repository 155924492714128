import { Text, View } from "@react-pdf/renderer";
import * as React from "react";
import { FC } from "react";
import { documentStyles } from "../../styles";

interface ITableData {
    rows: IRow[];
}

export interface IRow {
    cells: ICell[];
}

export interface ICell {
    text?: string;
    child?: JSX.Element;
    header?: boolean;
    underline?: boolean;
    widthPc: number;
    textAlignRight?: boolean;
    alignChildRight?: boolean;
    textAlignCenter?: boolean;
    bottomBorder?: boolean;
    fullBorder?: boolean;
    trustCellsOwnFormat?: boolean;
    fat?: boolean;
    fatTop?: boolean;
}

interface Props {
    data: ITableData;
};

const FixedWidthTable: FC<Props> = (props) => {
    return (
        <>
            <View style={[documentStyles.table, {marginTop: 10}]}>
                {props.data.rows !== null && props.data.rows.map((row) => {
                    return <View style={[documentStyles.row]}>
                        {
                            row.cells.map((cell) => {
                                let cellContent: JSX.Element | null = null;
                                if (cell.text)
                                {
                                    cellContent = <Text style={{
                                        fontWeight: cell.header? "bold" : "normal",
                                        textAlign: cell.textAlignRight? "right" : cell.textAlignCenter ? "center" : "left",
                                        textDecoration: cell.underline? "underline" : "none"}}>
                                            {cell.text}
                                    </Text>;
                                }
                                else if (cell.child) {
                                    if (cell.trustCellsOwnFormat){
                                        cellContent = cell.child;
                                    }
                                    else{
                                        cellContent = <View style={{
                                                textAlign: cell.textAlignRight? "right" : cell.textAlignCenter ? "center" : "left"}}>
                                                {cell.child}
                                            </View>;
                                    }
                                }

                                return <View style={{width: cell.widthPc + "%",
                                        border: cell.fullBorder? 1: 0 ,
                                        borderBottom: cell.bottomBorder || cell.fullBorder ? 1 : 0, 
                                        paddingTop: cell.fat? 10 : cell.fatTop? 20 : 0,
                                        paddingBottom: cell.fat? 10: 0
                                    }}>
                                    {cellContent}
                                </View>
                            })
                        }
                    </View>
                })}
            </View>
        </>
    );
};

export default FixedWidthTable;