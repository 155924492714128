import { IFlangedSideSpan, IFlexSideSpan } from "rdptypes/project/ISystemBase.AutoGenerated";
import { ISide } from "rdptypes/project/Types";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import { Side } from "roedata/roe_migration/Types";
import ISystem from "../../model/project/ISystem";

export const numberOfSpansExcludingEos = (spans: IFlangedSideSpan[] | IFlexSideSpan[]) => {
    let c = 0;
    for (const span of spans) {
        if (!span.EndBoom && !span.SwingArm) {
            c++;
        }
    }
    return c;
}

export const renumberSpanAndTowers = (side: Side) => {
    for (let i = 0; i < side.Span.length; i++) {
        side.Span[i].SpanNumber = i + 1;
    }
    for (let i = 0; i < side.Tower.length; i++) {
        side.Tower[i].TowerNumber = i + 1;
    }
}

const sumSpanLengthsExcludingEos = (side: ISide) => {
    let l = 0;
    for (const s of side.Span) {
        if (s.EndBoom || s.SwingArm || !Number.isFinite(spanf.LengthInFeet(side, s))) continue;
        l += spanf.LengthInFeet(side, s);
    }
    return l;
}

export const switchSidesIfNeeded = (system: ISystem) => {
    if (!system.FlexSide.Span.length) return;
    const flangedLength = sumSpanLengthsExcludingEos(system.FlangedSide);
    const flexLength = sumSpanLengthsExcludingEos(system.FlexSide);
    if (flexLength > flangedLength) {
        const tempSide = structuredClone(system.FlangedSide);
        system.FlangedSide = system.FlexSide;
        system.FlexSide = tempSide;
        system.flexIsFwd = !system.flexIsFwd;
    }
}