import { Box } from "@mui/material";
import { grey } from '@mui/material/colors';
import i18next, { t } from "i18next";
import { EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from 'react';
import { documentStyles } from "../../styles";

interface Props {
    endgunInformation: {
        endGun: EndGunTypes;
        isPrimary: boolean;
        onOffs: {
            on: number;
            off: number;
        }[];
    }[];
    sideLabel: string;
}

const EndgunInformationTable: FC<Props> = React.forwardRef((props) => {
    const ns = "reports.";

    let colWidth = 100/3;
    let oneColWidth = colWidth + "%";

    let fontSize = "0.8em";
    let cellStyle = {...documentStyles.cell, justifyContent: "left", padding: 4, width: oneColWidth, fontSize};

    let rows: JSX.Element [] = [];

    props.endgunInformation.forEach((gun) => {
        gun.onOffs.forEach((onOff, i) => {
            let cells: JSX.Element[] = [];
            let lastRowForGun = i === gun.onOffs.length - 1;

            cells.push(<div style={{ ...cellStyle, borderBottom: lastRowForGun ? "1px solid white" : "", backgroundColor: grey[200] }}>
                {
                    i === 0 && <strong style={{fontFamily: "Arial Black"}}>{gun.endGun} {gun.isPrimary ? " (Primary)" : " (Secondary)"}</strong>
                }
            </div>);

            cells.push(<div style={{ ...cellStyle, borderBottom: lastRowForGun ? "1px solid white" : "", backgroundColor: grey[200] }}>{onOff.on}</div>);
            cells.push(<div style={{ ...cellStyle, borderBottom: lastRowForGun ? "1px solid white" : "", backgroundColor: grey[200] }}>{onOff.off}</div>);

            rows.push(<div style={{...documentStyles.row}}>
                {cells}
            </div>);
        });
    });
    
    return <Box style={{border: "1px solid black", marginBottom: 20}}>
        <Box style={documentStyles.heading}>
            <Box style={{...documentStyles.sectionHeader, fontSize: 14}}>{props.sideLabel}</Box>
        </Box>
        <Box style={{paddingLeft: 15, paddingRight: 15}}>
            <Box style={{...documentStyles.table, marginBottom: 0}}>
                <div style={{...documentStyles.row}}>
                    <div style={{...cellStyle, fontWeight: "bold", width: "100%", justifyContent: "center", backgroundColor: grey[200]}}>{props.sideLabel}</div>
                </div>
                <div style={{...documentStyles.row}}>
                    <div style={{ ...cellStyle, borderBottom: "1px solid white", backgroundColor: grey[200] }}><strong style={{fontFamily: "Arial Black"}}>{i18next.format(t('end-gun'), 'capitalize')}</strong></div>
                    <div style={{ ...cellStyle, borderBottom: "1px solid white", backgroundColor: grey[200] }}><strong style={{fontFamily: "Arial Black"}}>{i18next.format(t('on'), 'capitalize')} ({t("ft")})</strong></div>
                    <div style={{ ...cellStyle, borderBottom: "1px solid white", backgroundColor: grey[200] }}><strong style={{fontFamily: "Arial Black"}}>{i18next.format(t('off'), 'capitalize')} ({t("ft")})</strong></div>
                </div>
                {rows}
            </Box>
        </Box>
    </Box>
});

export default EndgunInformationTable;