import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import { FC } from "react";
import { AnnotationType, annotationTypeToString } from "../../../../../../model/project/IAnnotation";
import { Interaction } from "../../../interactionState";
import AddLabelDialog from "./AddLabelDialog";

export type ICloseAnnotationResponse =
    | {
          interaction:
              | Interaction.DrawCanalAnnotation
              | Interaction.DrawElectricLineAnnotation
              | Interaction.DrawLineAnnotation
              | Interaction.DrawPointAnnotation
              | Interaction.DrawPumpAnnotation
              | Interaction.DrawWaterLineAnnotation;
          message: string;
      }
    | {
          interaction: Interaction.DrawLabelAnnotation;
          message: string;
          labelAnnotationText: string;
      };
interface Props {
    onClose: (args: ICloseAnnotationResponse) => void;
    onCancel: () => void;
    open: boolean;
}

const AddAnnotationDialog: FC<Props> = (props) => {
    const [addLabelDialogOpen, setAddLabelDialogOpen] = React.useState(false);

    const handleClick = (type: AnnotationType) => {
        let response: ICloseAnnotationResponse | null = null;
        switch (type) {
            case AnnotationType.canal:
                response = { interaction: Interaction.DrawCanalAnnotation, message: "Please draw the canal" };
                break;
            case AnnotationType.electricLine:
                response = {
                    interaction: Interaction.DrawElectricLineAnnotation,
                    message: "Please draw the electric line",
                };
                break;
            case AnnotationType.label:
                setAddLabelDialogOpen(true);
                break;
            case AnnotationType.line:
                response = { interaction: Interaction.DrawLineAnnotation, message: "Please draw the line annotation" };
                break;
            case AnnotationType.point:
                response = {
                    interaction: Interaction.DrawPointAnnotation,
                    message: "Please draw the point annotation",
                };
                break;
            case AnnotationType.pump:
                response = { interaction: Interaction.DrawPumpAnnotation, message: "Please draw the pump annotation" };
                break;
            case AnnotationType.waterLine:
                response = { interaction: Interaction.DrawWaterLineAnnotation, message: "Please draw the water line" };
                break;
        }
        if (response) {
            props.onClose(response);
        }
    };

    const ns = "map-display.";

    return (
        <>
            <Dialog onClose={() => props.onCancel()} open={props.open}>
                <DialogTitle>{i18next.format(`${i18next.t(ns + "select-annotation-type")}`, 'capitalize-each')}</DialogTitle>
                <List sx={{ pt: 0 }}>
                    {Object.keys(AnnotationType)
                        .filter((key) => !isNaN(Number(AnnotationType[key])))
                        .map((t_) => {
                            return (
                                <ListItem key={t_}>
                                    <ListItemButton autoFocus onClick={() => handleClick(AnnotationType[t_])}>
                                        <ListItemText
                                            sx={{ textAlign: "center" }}
                                            primary={annotationTypeToString(AnnotationType[t_], true)}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                </List>
            </Dialog>
            <AddLabelDialog
                open={addLabelDialogOpen}
                onClose={(text) => {
                    setAddLabelDialogOpen(false);
                    props.onClose({
                        interaction: Interaction.DrawLabelAnnotation,
                        labelAnnotationText: text,
                        message: i18next.t(ns + "click-label-annotation"),
                    });
                }}
                onCancel={() => {
                    setAddLabelDialogOpen(false);
                }}
            />
        </>
    );
};

export default AddAnnotationDialog;
