import { Typography } from "@mui/material";
import INote from "rdptypes/roe/INote";
import * as React from "react";
import { FC } from "react";
import { pageStyles } from "../styles";

interface Props {
    cmp: INote;
};

const NoteRenderer: FC<Props> = (props) => {
    return (
        <>
            <Typography sx={pageStyles.cardTitleStyle}>{props.cmp.title}</Typography>
        </>
    );
};

export default NoteRenderer;