import i18next, { t } from "i18next";
import { ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import IBoolean from "rdptypes/roe/IBoolean";
import { IChoiceOption } from "rdptypes/roe/IChoice";
import { IComplexChoiceOption } from "rdptypes/roe/IComplexChoice";
import IComponent from "rdptypes/roe/IComponent";
import INumber from "rdptypes/roe/INumber";
import IPage from "rdptypes/roe/IPage";
import { IColumn } from "rdptypes/roe/ISideTable";
import { IDisplaySettingsFormState } from "../components/DealerSettingsDialog/TabDisplaySettings";

export const translateUnit = (opt: IComplexChoiceOption | IChoiceOption | IBoolean, settings: IDisplaySettingsFormState) => {
    if (opt.title_meters && settings.lengths === "meters"){
        for(let langCode in opt.title_meters){
            if(langCode === i18next.language){
                return opt.title_meters[langCode];
            }
        }
        return opt.title_meters["en"];
      }
      if (opt.title_centimeters && (settings.minorLengths === "centimeters" || settings.minorLengths === "millimeters")){
        for(let langCode in opt.title_centimeters){
            if(langCode === i18next.language){
                return opt.title_centimeters[langCode];
            }
        }
        return opt.title_centimeters["en"];
      }
      if (opt.title_bar && settings.pressures === "bar"){
        for(let langCode in opt.title_bar){
            if(langCode === i18next.language){
                return opt.title_bar[langCode];
            }
        }
        return opt.title_bar["en"];
      }
      if (opt.title_m3h && settings.irrigationRates === "cmh"){
        for(let langCode in opt.title_m3h){
            if(langCode === i18next.language){
                return opt.title_m3h[langCode];
            }
        }
        return opt.title_m3h["en"];
      }
      if (opt.title_liters && settings.volumes === "liters"){
        for(let langCode in opt.title_liters){
            if(langCode === i18next.language){
                return opt.title_liters[langCode];
            }
        }
        return opt.title_liters["en"];
      }
      if (opt.title_kilos && settings.weights === "kilos"){
        for(let langCode in opt.title_kilos){
            if(langCode === i18next.language){
                return opt.title_kilos[langCode];
            }
        }
        return opt.title_kilos["en"];
      }
      return translateBase(opt.title);
}

export const translateBase = (text: string | { [code: string]: string }) => {
    if(typeof text === 'string'){
        return text; //no translations defined
    }else{
        for(let langCode in text){
            if(langCode === i18next.language){
                return text[langCode];
            }
        }
        return text["en"];
    }
}

export const translate = (cmp: IComponent | IChoiceOption | IComplexChoiceOption | IPage | IColumn, sys?: ISystemBase) => {
    if ((cmp as IPage).titleFunc && sys){
        return translateBase((cmp as IPage).titleFunc(sys));
    }
    return translateBase(cmp.title);
}

export const translateUnits = (cmp: INumber, settings: IDisplaySettingsFormState) => {
    if(cmp.units){
        //if our display settings are meters and our val is being stored as feet
        if (settings.lengths === "meters" &&
            (cmp.units === "ft" || cmp.units === "feet" || (cmp.units["en"] && (cmp.units["en"] === "ft" || cmp.units["en"] === "feet")))
        ){
            return "m";
        }

        if (settings.lengths === "meters" && cmp.units_meters){
            return translateBase(cmp.units_meters);
        }

        return translateBase(cmp.units);
    }
   return "";
}

export const translateSlt = (str: string) => {
    if (!str.startsWith("slt")){
        return str;
    }

    return t("common-phrases." + str);
}