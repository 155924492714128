import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Position } from "rdptypes/geoTypes";
import * as React from "react";
import { FC, useState } from "react";

interface Props {
    value: Position;
    onChange: (coord: Position) => void;
};

interface GetLatLonResponse {
    trs: string;
    generatedplss: string[];
    coordinates: {
        plssid: string;
        lat: number;
        lon: number;
    }[];
    features: any[];
    status: "success" | "error";
    statusmsg?: string;
}

const states = ["AL", "AR", "AZ", "CA", "CO", "CT", "DE", "FL", "GA", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY"];
const meridianCodes = {
    "01": "1ST PRINCIPAL",
    "02": "2ND PRINCIPAL",
    "03": "3RD PRINCIPAL",
    "04": "4TH PRINCIPAL",
    "05": "5TH PRINCIPAL",
    "06": "6TH PRINCIPAL",
    "07": "BLACK HILLS",
    "08": "BOISE",
    "09": "CHICKASAW",
    "10": "CHOCTAW",
    "11": "CIMARRON",
    "14": "GILA SALT RIVER",
    "15": "HUMBOLDT",
    "16": "HUNTSVILLE",
    "17": "INDIAN",
    "18": "LOUISIANA",
    "19": "MICHIGAN",
    "20": "PRINCIPAL MT",
    "21": "MOUNT DIABLO",
    "23": "NEW MEXICO",
    "24": "ST HELENA",
    "25": "ST STEPHENS",
    "26": "SALT LAKE",
    "27": "SAN BERNARDINO",
    "29": "TALLAHASSEE",
    "30": "UINTAH SPEC",
    "31": "UTE",
    "32": "WASHINGTON",
    "33": "WILLAMETTE",
    "34": "WIND RIVER",
    "35": "OHIO RIVER SURVEY",
    "36": "BETWEEN THE MIAMIS",
    "39": "1ST SCIOTO RIVER BASE",
    "40": "2ND SCIOTO RIVER BASE",
    "46": "4TH PRINCIPLE MN WI",
    "47": "WEST OF GREAT MIAMI",
    "48": "US MILITARY SURVEY",
    "91": "CT WESTERN RES OH",
    "DE": "DELAWARE",
    "GA": "GEORGIA",
    "KY": "KENTUCKY",
    "MD": "MARYLAND",
    "ME": "MAINE",
    "NC": "NORTH CAROLINA",
    "NH": "NEW HAMPSHIRE",
    "NJ": "NEW JERSEY",
    "NY": "NEW YORK",
    "OH": "OHIO",
    "PA": "INVALID MERIDIAN CODE",
    "SC": "SOUTH CAROLINA",
    "TN": "TENNESSEE",
    "TX": "TEXAS",
    "VA": "VIRGINIA",
    "VT": "VERMONT",
    "WV": "WEST VIRGINIA"
};
const stateMeridians = {
    "AL": ["02", "03", "05", "10", "16", "19", "25", "29", "32"],
    "AR": ["05", "10", "16", "18", "19", "25", "46", "NC"],
    "AZ": ["14", "27"],
    "CA": ["14", "15", "21", "27"],
    "CO": ["06", "23", "31"],
    "CT": ["05"],
    "DE": ["29", "DE"],
    "FL": ["05", "09", "16", "18", "19", "25", "29"],
    "GA": ["GA"],
    "IA": ["02", "03", "05"],
    "ID": ["08", "21"],
    "IL": ["02", "03", "04", "05", "10", "25", "32", "46"],
    "IN": ["01", "02", "03", "19"],
    "KS": ["06", "17"],
    "KY": ["05", "18", "KY", "TN", "WV"],
    "LA": ["03", "05", "10", "18", "19", "24", "25", "29"],
    "MD": ["MD"],
    "ME": ["19", "ME"],
    "MI": ["05", "09", "10", "16", "18", "19", "25", "29", "46"],
    "MN": ["05", "10", "18", "19", "46"],
    "MO": ["03", "05", "10", "16", "19", "25"],
    "MS": ["03", "05", "09", "10", "16", "18", "19", "24", "25", "29", "32"],
    "MT": ["05", "07", "20"],
    "NC": ["NC"],
    "ND": ["05", "20"],
    "NE": ["06"],
    "NH": ["NH"],
    "NJ": ["NJ"],
    "NM": ["14", "23", "26"],
    "NV": ["21", "27"],
    "NY": ["19", "NY"],
    "OH": ["01", "05", "19", "35", "36", "39", "40", "47", "48", "91", "OH"],
    "OK": ["06", "11", "17"],
    "OR": ["33"],
    "PA": ["MD", "PA"],
    "SC": ["SC"],
    "SD": ["05", "06", "07", "20"],
    "TN": ["16", "TN"],
    "TX": ["TX"],
    "UT": ["26", "30"],
    "VA": ["VA"],
    "VT": ["46", "VT"],
    "WA": ["33"],
    "WI": ["04", "05", "46"],
    "WV": ["35", "VA", "WV"],
    "WY": ["06", "34"]
};

const PlssEditor: FC<Props> = (props) => {
    const [state, setState] = useState("NE");
    const [meridian, setMeridian] = useState("06");
    const [township, setTownship] = useState("2");
    const [townshipNs, setTownshipNs] = useState<"N" | "S">("N");
    const [range, setRange] = useState("4");
    const [rangeEw, setRangeEw] = useState<"E" | "W">("W");
    const [section, setSection] = useState("");
    

    const search = async () => {
        // https://glorecords.pathfinder.blm.gov/assets/pdfs/serviceUserGuide.pdf
        let ref = `${state} ${meridian} T${township}${townshipNs} R${range}${rangeEw}`;
        if (section !== "") {
            ref = `${ref} SEC ${section}`;
        }

        try {
            const resp = await fetch(`https://gis.blm.gov/arcgis/rest/services/Cadastral/BLM_Natl_PLSS_CadNSDI/MapServer/exts/CadastralSpecialServices/GetLatLon?trs=${ref}&returnalllevels=&f=pjson`);
            if (!resp.ok) {
                alert(`Error fetching coordinates from BLM server.`);
                return;
            }
            const respJson: GetLatLonResponse = await resp.json();
            if (respJson?.status !== "success") {
                alert(`Error fetching coordinates from BLM server. Request: ${ref} Response: ${respJson.statusmsg}`);
                return;
            }
            if (typeof respJson?.coordinates?.[0]?.lat === "number"
                && typeof respJson?.coordinates?.[0]?.lon === "number") {
                props.onChange([respJson.coordinates[0].lon, respJson.coordinates[0].lat]);
            }
            else {
                alert(`Fetched coordinates from BLM server but response was not valid.`);
            }
        } catch (err) {
            alert(`Exception whilst fetching coordinates from BLM server.`);
        }
    };

    return (
        <>
            <FormControl sx={{ ml: 2 }}>
                <InputLabel>State</InputLabel>
                <Select
                    label="State"
                    value={state}
                    onChange={e => {
                        setState(e.target.value);
                        setMeridian(stateMeridians[e.target.value][0]);
                    }}
                >
                    {states.map(state => <MenuItem key={state} value={state}>{state}</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel>Meridian</InputLabel>
                <Select
                    label="Meridian"
                    value={meridian}
                    onChange={e => setMeridian(e.target.value)}
                >
                    {stateMeridians[state].map(meridian => <MenuItem key={meridian} value={meridian}>{meridian}: {meridianCodes[meridian]}</MenuItem>)}
                </Select>
            </FormControl>
            <TextField sx={{ ml: 1, width: 90 }} label="Township" value={township} onChange={e => setTownship(e.target.value)} />
            <Select
                value={townshipNs}
                onChange={e => setTownshipNs(e.target.value as ("N" | "S"))}
            >

                <MenuItem value="N">N</MenuItem>
                <MenuItem value="S">S</MenuItem>
            </Select>
            <TextField sx={{ ml: 1, width: 90 }} label="Range" value={range} onChange={e => setRange(e.target.value)} />
            <Select
                value={rangeEw}
                onChange={e => setRangeEw(e.target.value as ("E" | "W"))}
            >

                <MenuItem value="E">E</MenuItem>
                <MenuItem value="W">W</MenuItem>
            </Select>
            <TextField sx={{ ml: 1, width: 90 }} label="Section" value={section} onChange={e => setSection(e.target.value)} />
            <Button sx={{ ml: 2 }} onClick={search}>Search</Button>
        </>
    );
};

export default PlssEditor;