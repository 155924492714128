import { t } from "i18next";
import * as React from "react";
import { useContext } from "react";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import CustomDefaultsRadioGroup from "./CustomDefaultsRadioGroup";
import CustomerSettingsRenderer from "./CustomerSettingsRenderer";

const TabBody: React.FC = () => {
    const ns = "dealer-settings.";
    const devSettingsState = useContext(DevSettingsCtx);

    return (
        <>
            <CustomDefaultsRadioGroup 
                title={t(ns + "customer-settings")}
                useCustomDefaults={devSettingsState.dealerSettings.customer.useCustom}
                setUseCustomDefaults={devSettingsState.dealerSettings.customer.setUseCustom}
            />
             {
                devSettingsState.dealerSettings.customer.useCustom && (<>
                        <CustomerSettingsRenderer/>
                    </>
                )
             }   
        </>
    );
}

export default <TabBody />;
