import { LineString, Point, Polygon } from "@turf/turf";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import IProject from "../../model/project/IProject";
import { applyObstacleClearanceTo } from "../../optimization/helpers/base";
import { BoundaryHelper } from "../BoundaryHelper";
import { ObstacleHelper } from "../ObstacleHelper";
import { ESegmentClearanceType } from "../SegmentHelper";
import CenterPivotGeometryHelper from "./CenterPivotGeometryHelper";
import LateralGeometryHelper from "./LateralGeometryHelper";

export type SystemValidity = 'valid' | 'warning' | 'critical' | 'unknown';

export const getSwingArmClearancePolygons = (project: IProject, layoutId: string) => {
    const layout = project.layouts[layoutId];
    const sTowerClearanceBoundaries = layout.fieldBoundary ? BoundaryHelper.getClearancePolygons(layout.fieldBoundary, ESegmentClearanceType.STowerClearance) : [];
    const hTowerClearanceBoundaries = layout.fieldBoundary ? BoundaryHelper.getClearancePolygons(layout.fieldBoundary, ESegmentClearanceType.HTowerClearance) : [];
    const sTowerClearanceObstacles = layout.obstacles.map((x, idx) => {
        return {
            obstacleIndex: idx,
            polygons: ObstacleHelper.getClearancePolygons(x, ESegmentClearanceType.STowerClearance)
        }
    });
    const hTowerClearanceObstacles = layout.obstacles.map((x, idx) => {
        return {
            obstacleIndex: idx,
            polygons: ObstacleHelper.getClearancePolygons(x, ESegmentClearanceType.HTowerClearance)
        }
    });
    const sTowerClearanceWheelObstacles = layout.wheelObstacles.map((x, idx) => {
        return {
            wheelObstacleIndex: idx,
            polygons: ObstacleHelper.getClearancePolygons(x, ESegmentClearanceType.STowerClearance)
        }
    });
    const hTowerClearanceWheelObstacles = layout.wheelObstacles.map((x, idx) => {
        return {
            wheelObstacleIndex: idx,
            polygons: ObstacleHelper.getClearancePolygons(x, ESegmentClearanceType.HTowerClearance)
        }
    });
    return {
        sTowerClearanceBoundaries, sTowerClearanceObstacles, sTowerClearanceWheelObstacles,
        hTowerClearanceBoundaries, hTowerClearanceObstacles, hTowerClearanceWheelObstacles,
    }
}
export interface ILayoutClearancePolygons {
    clearanceBoundaries: Polygon[];
    clearanceObstacles: {
        obstacleIndex: number;
        polygons: Polygon[];
    }[];
    clearancePivotCenterBoundary: Polygon[];
    clearanceWheelObstacles: {
        wheelObstacleIndex: number;
        polygons: Polygon[];
    }[];
}
export const getLayoutClearancePolygons = (project: IProject, layoutId: string): ILayoutClearancePolygons => {
    const layout = project.layouts[layoutId];
    const clearanceBoundaries = layout.fieldBoundary ? BoundaryHelper.getClearancePolygons(layout.fieldBoundary) : [];
    const clearancePivotCenterBoundary = layout.pivotCenterBoundary ? BoundaryHelper.getClearancePolygons(layout.pivotCenterBoundary) : undefined;
    const clearanceObstacles = layout.obstacles.map((x, idx) => {
        return {
            obstacleIndex: idx,
            polygons: ObstacleHelper.getClearancePolygons(x)
        }
    });
    const clearanceWheelObstacles = layout.wheelObstacles.map((x, idx) => {
        return {
            wheelObstacleIndex: idx,
            polygons: ObstacleHelper.getClearancePolygons(x)
        }
    });
    return {
        clearanceBoundaries, clearanceObstacles, clearancePivotCenterBoundary, clearanceWheelObstacles
    }
}
export interface IBufferedSystemPolygonsForSystemClearancePolygons { 
    [systemId: string]: { 
        polygons: Polygon[], radiusEnvelope?: number 
    } 
}
export const getSystemClearancePolygons = (project: IProject, layoutId: string, thisSystemId: string, buffedSystemPolygons: IBufferedSystemPolygonsForSystemClearancePolygons = {}) => {
    const layout = project.layouts[layoutId];
    const clearanceSystemObstacles: ISystemForSystemValidity[] = [];
    for (const [systemId, system] of Object.entries(layout.systems)) {
        if (systemId === thisSystemId) continue;
        let bsp = buffedSystemPolygons[systemId];
        let polygons = bsp?.polygons;
        let radiusEnvelope = bsp?.radiusEnvelope;
        if (system.SystemProperties.SystemType === SystemTypes.CenterPivot) {
            if (!bsp) {
                const systemGeometryHelper = new CenterPivotGeometryHelper({
                    project: project,
                    layoutId,
                    systemId
                })
                const p = systemGeometryHelper.getAreaPolygon({includeEndguns: false, includeSAC: true});
                polygons = p ? applyObstacleClearanceTo(p, project.systemClearance) : [];
                radiusEnvelope = systemGeometryHelper.systemRadiusFeetIncludingEndboomOrSac;
            }
            clearanceSystemObstacles.push({
                polygons,
                allowOverlap: system.overlapping,
                pivotCenter: system.centerPivot.point,
                radiusEnvelope
            });
        }
        else if (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) {
            if (!bsp) {
                const systemGeometryHelper = new LateralGeometryHelper({
                    project: project,
                    layoutId,
                    systemId
                })
                const p = systemGeometryHelper.getAreaPolygon();
                polygons = p ? applyObstacleClearanceTo(p, project.systemClearance) : [];
            }
            clearanceSystemObstacles.push({
                polygons,
                allowOverlap: system.overlapping,
                laterlFeedLine: system.lateral.line
            });
        }
    }
    return {
        clearanceSystemObstacles
    }
}

export interface IGetSystemValidityArgs_Sac {
    hTowerClearanceObstacles: { obstacleIndex: number; polygons: Polygon[] }[];
    hTowerClearanceBoundaries: Polygon[];
    hTowerClearanceWheelObstacles: { wheelObstacleIndex: number; polygons: Polygon[] }[];
    sTowerClearanceObstacles: { obstacleIndex: number; polygons: Polygon[] }[];
    sTowerClearanceBoundaries: Polygon[];
    sTowerClearanceWheelObstacles: { wheelObstacleIndex: number; polygons: Polygon[] }[];
    polygons: Polygon[];
    wheelTracks: LineString[];
}

interface ISystemForSystemValidity {
    polygons: Polygon[];
    allowOverlap?: boolean
    pivotCenter?: Point;
    laterlFeedLine?: LineString;
    radiusEnvelope?: number;
}
export interface IGetSystemValidityArgs {
    systemClearanceObstacles: { obstacleIndex: number; polygons: Polygon[] }[];
    systemClearanceBoundaries: Polygon[];
    systemClearancePivotCenterBoundaries?: Polygon[];
    systemClearanceWheelObstacles: { wheelObstacleIndex: number; polygons: Polygon[] }[];
    systemClearanceSystemObstacles: ISystemForSystemValidity[];
    center?: Point;
    feedLine?: LineString | Polygon;
    systemAreaPolygon?: Polygon;
    wheelTracks?: LineString[]; // <-- make this non optional

    sac?: IGetSystemValidityArgs_Sac;
    sacResult?: { success: boolean };

    allowOverlap: boolean;
    centerPivot?: {
        radiusEnvelope: number;
    }
}