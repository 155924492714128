import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { useContext, useState } from "react";
import { IGrowerWithId } from ".";
import AuthCtx from "../../../auth/AuthCtx";
import DbCtx from "../../../db/DbCtx";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import GrowersFromCSVDialog from "./GrowersFromCSVDialog";

interface Props {
  searchRegex: RegExp | undefined;
  selectGrower: (growerId: string) => void;
  growers: IGrowerWithId[];
  highlightText: (text: string) => string | JSX.Element;
  setGrowerDialogOpen: (id: string) => void;
}

const GrowerListTab: React.FC<Props> = (props) => {
  const headerCellStyle = {
    fontWeight: "bold"
  }

  const dbState = useContext(DbCtx);

  const [csvUploadDialogOpen, setCsvUploadDialogOpen] = useState(false);

  const devSettingsState = useContext(DevSettingsCtx);
  const authState = useContext(AuthCtx);

  let rows: JSX.Element[] = [];
  props.growers.forEach((gwid) => {
    if (gwid.grower.deleted && !devSettingsState.growerFilterSettings.showDeleted) {
      return;
    }

    if (gwid.grower.owner !== authState.assumedUserId//shared with me
      && !devSettingsState.growerFilterSettings.showSharedWithMe
    ){
      return;
    }

    rows.push(<TableRow
      sx={{
        '&.MuiTableRow-root:hover':{
          cursor: 'pointer' 
        },
       }}
      hover
      key={gwid.id}
      onClick={() => props.selectGrower(gwid.id)}
    >
      <TableCell>{props.highlightText(gwid.grower.name)}</TableCell>
      {
        devSettingsState.growerFilterSettings.showDeleted && <TableCell>{props.highlightText(gwid.grower.deleted ? "Deleted" : "Active")}</TableCell>
      }
      {
        devSettingsState.growerFilterSettings.showSharedWithMe && <TableCell>{props.highlightText(gwid.grower.owner === authState.assumedUserId ? "Mine" : "Shared")}</TableCell>
      }
      <TableCell>{gwid.grower.shippingAddress.line1}{gwid.grower.shippingAddress.line2 && `, ${gwid.grower.shippingAddress.line2}`}</TableCell>
      <TableCell>{gwid.grower.shippingAddress.city}</TableCell>
      <TableCell>{gwid.grower.shippingAddress.state}</TableCell>
      <TableCell>{gwid.grower.shippingAddress.zip}</TableCell>
      <TableCell>{gwid.grower.shippingAddress.country}</TableCell>
      <TableCell>            
        <Tooltip title={i18next.format(i18next.t("edit"), 'capitalize')} placement="bottom">
              <IconButton color="primary"  onClick={(e) => 
                {
                  e.stopPropagation();
                  e.preventDefault();
                  props.setGrowerDialogOpen(gwid.id);
                }}>
                <CreateOutlinedIcon/>
              </IconButton>
            </Tooltip></TableCell>
      </TableRow>);
  });

  const [filterEl, setFilterEl] = React.useState<null | HTMLElement>(null);
  const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
      setFilterEl(event.currentTarget);
  };
  const closeFilterMenu = () => {
      setFilterEl(null);
  };

  const setShowDeleted = () => {
    closeFilterMenu(); 
    devSettingsState.growerFilterSettings.setShowDeleted(!devSettingsState.growerFilterSettings.showDeleted);
  }

  const setShowSharedWithMe = () => {
    closeFilterMenu(); 
    devSettingsState.growerFilterSettings.setShowSharedWithMe(!devSettingsState.growerFilterSettings.showSharedWithMe);
  }

  return (
    <>
      <Stack direction="row" spacing={2} sx={{alignItems:"center", marginLeft: "0.5em", marginTop:"0.5em", fontSize: "1.5em"}}>
          <span>{i18next.format(t('growers'), 'capitalize')}</span><Button variant="outlined" onClick={() => {setCsvUploadDialogOpen(true);}}>{t('upload-csv')}</Button>
          <Button variant="outlined"
                aria-label="filters"
                aria-haspopup="true"
                onClick={openFilterMenu}
              >
                {i18next.format(t("filter"), 'capitalize-each')}
          </Button>
          <Menu
                anchorEl={filterEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={!!filterEl}
                onClose={closeFilterMenu}
            >
                <MenuItem>
                    <FormControlLabel control={<Checkbox                           
                        checked={devSettingsState.growerFilterSettings.showDeleted}
                        onChange={setShowDeleted}
                    />} label={i18next.format(t("projects.show-deleted"), 'capitalize')} />
                </MenuItem>
                <MenuItem>
                    <FormControlLabel control={<Checkbox                           
                        checked={devSettingsState.growerFilterSettings.showSharedWithMe}
                        onChange={setShowSharedWithMe}
                    />} label={i18next.format(t("projects.show-shared-with-me"), 'capitalize')} />
                </MenuItem>
            </Menu>
      </Stack>

        <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCellStyle}>{i18next.format(i18next.t('name'), 'capitalize')}</TableCell>
                  {
                    devSettingsState.growerFilterSettings.showDeleted && <TableCell sx={headerCellStyle}>{props.highlightText("State")}</TableCell>
                  }
                  {
                    devSettingsState.growerFilterSettings.showSharedWithMe && <TableCell sx={headerCellStyle}>{props.highlightText("Permission")}</TableCell>
                  }
                  <TableCell sx={headerCellStyle}>{i18next.format(i18next.t('address'), 'capitalize')}</TableCell>
                  <TableCell sx={headerCellStyle}>{i18next.format(i18next.t('city'), 'capitalize')}</TableCell>
                  <TableCell sx={headerCellStyle}>{i18next.format(i18next.t('state'), 'capitalize')}</TableCell>
                  <TableCell sx={headerCellStyle}>{i18next.format(i18next.t('zip'), 'capitalize')}</TableCell>
                  <TableCell sx={headerCellStyle}>{i18next.format(i18next.t('country'), 'capitalize')}</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                  {rows}
                  {rows.length === 0 && <TableRow>
                    <TableCell colSpan={(devSettingsState.growerFilterSettings.showDeleted && devSettingsState.growerFilterSettings.showSharedWithMe) ? 9 : (devSettingsState.growerFilterSettings.showDeleted || devSettingsState.growerFilterSettings.showSharedWithMe) ? 8 : 7} sx={{textAlign: "center"}}>
                      {i18next.t("no-fields-match-criteria")}
                    </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
        </TableContainer>
        <GrowersFromCSVDialog dbState={dbState} open={csvUploadDialogOpen} onClose={(growers) => {
          if (typeof(growers) === "undefined" || growers.length === 0){
            setCsvUploadDialogOpen(false);
          }
          else {
            growers.forEach(async (grower) => {
              const gid = await dbState.newGrower(grower);
            });
            setCsvUploadDialogOpen(false);
          }
        }}/>
    </>
  );
};

export default GrowerListTab;
