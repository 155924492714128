import { Position, destination, toMercator, toWgs84 } from "@turf/turf";

export interface IArgs {
    center: Position;
    scaleToMeters: number;
}

export class MetersProjectionHelper {

    private _scaleFactor: number;
    private _m0: Position;

    constructor(args: IArgs) {
        // to find the scale factor, we will consider a point 1000m N and 1000m E, and take the average of the
        // difference in meters difference from 1000m
        const p1 = destination(args.center, args.scaleToMeters,  0, { units: 'meters' });
        const p2 = destination(args.center, args.scaleToMeters,  90, { units: 'meters' });
        this._m0 = toMercator(args.center);
        const m1 = toMercator(p1);
        const m2 = toMercator(p2);

        const d1x = Math.abs(m1.geometry.coordinates[0] - this._m0[0])
        const d1y = Math.abs(m1.geometry.coordinates[1] - this._m0[1])
        const d2x = Math.abs(m2.geometry.coordinates[0] - this._m0[0])
        const d2y = Math.abs(m2.geometry.coordinates[1] - this._m0[1])

        const d1 = Math.sqrt(d1x ** 2 + d1y ** 2);
        const d2 = Math.sqrt(d2x ** 2 + d2y ** 2);
        const d = (d1 + d2) * 0.5;
        this._scaleFactor = args.scaleToMeters / d;
    }

    // factories:
    public static fromWgs80(longitude: number, latitude: number, scaleToMeters: number) {
        return new MetersProjectionHelper({
            center: [longitude, latitude],
            scaleToMeters
        })
    }

    // // public methods:
    public wgs84ToMeters(longitude: number, latitude: number) {
        const m = toMercator([ longitude, latitude ]);
        return [
            (m[0] - this._m0[0]) * this._scaleFactor,
            (m[1] - this._m0[1]) * this._scaleFactor
        ]
    }
    public metersToWgs84(longitude: number, latitude: number) {
        const m = toWgs84([ longitude / this._scaleFactor + this._m0[0], latitude / this._scaleFactor + this._m0[1]]);
        return [
            m[0],
            m[1]
        ]
    }
}
  