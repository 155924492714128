import { EndOfSystemTypes, ISystemBase } from "../project/ISystemBase.AutoGenerated";
import { ISpan } from "../project/Types";
import { SideEnum, getSide } from "./SideEnum";


export const getEndBoom = (sys: ISystemBase, side: SideEnum): ISpan | undefined => {
    const systemSide = getSide(sys, side);
    const spans = systemSide.Span;    
    if (spans.length === 0) { 
        return undefined;
    }
    else if (
        spans[spans.length - 1]?.EndBoom && 
        (systemSide.EndOfSystem.EndOfSystemType === EndOfSystemTypes.EndBoom || systemSide.EndOfSystem.EndOfSystemType === EndOfSystemTypes.SAC)
    ) {
        return spans[spans.length - 1];
    }
    else {
        return undefined;
    }
}

export const getEndBoomLength = (sys: ISystemBase, side: SideEnum): number | undefined => {
    const endBoom = getEndBoom(sys, side);
    if (!endBoom) {
        const systemSide = getSide(sys, side);
        if (systemSide.EndOfSystem.EndBoomLengthSelected) {
            return 0;
        } else {
            return undefined;
        }
    } else {
        return endBoom.Length;
    }
}