import { Feature, Polygon } from "@turf/turf";
import SegmentHelper from "../../../../GeometryHelpers/SegmentHelper";
import { createNewUpdateLayoutPropertyAction } from "../../../../actions/UpdateLayoutProperty";
import IAuthState from "../../../../auth/IAuthState";
import IDbProject from "../../../../db/IDbProject";
import IWetAreaBoundary from "../../../../model/project/IWetAreaBoundary";


export const handleWetAreaBoundary = (
    features: Feature[],
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
) => {
    if (features.length !== 1 && features[0].geometry.type !== "Polygon") {
        throw new Error(`Unexpected feature created`);
    }
    // a wet area boundary has no clearance, and cannot be updated to have such
    const wetAreaBoundary: IWetAreaBoundary = {
        segments: SegmentHelper.polygonToSegments(features[0].geometry as Polygon, 0)
    }
    dbPrj.pushAction(createNewUpdateLayoutPropertyAction(
        layoutId,
        "wetAreaBoundary",
        wetAreaBoundary,
        authState
    ));
}
