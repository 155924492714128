'use strict';
import { booleanDisjoint, polygon } from "@turf/turf";
// This is a re-implementation of:
// https://github.com/mapbox/polylabel
// updated to include pivot center exclusion (centerBoundary)
import Queue from "tinyqueue";

interface IPolylabelExtendedArgs {
    polygon: number[][][];
    precision?: number;
    debug?: boolean;
    centerBoundary?: number[][][];
}

type PoleOfInaccessibility = [ number, number] & { distance?: number };

export function polylabelExtended(args: IPolylabelExtendedArgs) {
    const polygon = args.polygon;
    const precision = args.precision || 1.0;
    const debug = args.debug || false;
    const centerBoundary = args.centerBoundary;

    if (polygon.length === 0 || polygon[0].length === 0) return undefined;

    // find the bounding box of the outer ring
    let minX!: number;
    let minY!: number;
    let maxX!: number;
    let maxY!: number;
    if (centerBoundary) {
        for (let i = 0; i < centerBoundary[0].length; i++) {
            const p = centerBoundary[0][i];
            if (!i || p[0] < minX) minX = p[0];
            if (!i || p[1] < minY) minY = p[1];
            if (!i || p[0] > maxX) maxX = p[0];
            if (!i || p[1] > maxY) maxY = p[1];
        }
    }
    else {
        for (let i = 0; i < polygon[0].length; i++) {
            const p = polygon[0][i];
            if (!i || p[0] < minX) minX = p[0];
            if (!i || p[1] < minY) minY = p[1];
            if (!i || p[0] > maxX) maxX = p[0];
            if (!i || p[1] > maxY) maxY = p[1];
        }
    }

    var width = maxX - minX;
    var height = maxY - minY;
    var cellSize = Math.min(width, height);
    var h = cellSize / 2;

    if (cellSize === 0) {
        var degeneratePoleOfInaccessibility: PoleOfInaccessibility = [minX, minY];
        degeneratePoleOfInaccessibility.distance = 0;
        return degeneratePoleOfInaccessibility;
    }

    // a priority queue of cells in order of their "potential" (max distance to polygon)
    var cellQueue = new Queue<Cell>(undefined, compareMax);

    // cover polygon with initial cells
    for (var x = minX; x < maxX; x += cellSize) {
        for (var y = minY; y < maxY; y += cellSize) {
            cellQueue.push(new Cell(x + h, y + h, h, polygon, centerBoundary));
        }
    }

    // take centroid as the first best guess
    var bestCell = getCentroidCell(polygon, centerBoundary);

    // second guess: bounding box centroid
    var bboxCell = new Cell(minX + width / 2, minY + height / 2, 0, polygon, centerBoundary);
    if (bboxCell.d > bestCell.d && bboxCell.b > 0) bestCell = bboxCell;

    if (centerBoundary && bestCell.b < 0) {
        const x = centerBoundary[0][0][0];
        const y = centerBoundary[0][0][1];
        bestCell = new Cell(x, y, 0, polygon, centerBoundary);
    }

    var numProbes = cellQueue.length;

    while (cellQueue.length) {
        // pick the most promising cell from the queue
        var cell = cellQueue.pop();

        if (cell === undefined) continue;

        // update the best cell if we found a better one
        if (cell.d > bestCell.d && cell.b > 0) {
            bestCell = cell;
            if (debug) console.log('found best %f after %d probes', Math.round(1e4 * cell.d) / 1e4, numProbes);
        }

        // do not drill down further if there's no chance of a better solution
        if (cell.max - bestCell.d <= precision) continue;

        // split the cell into four cells
        h = cell.h / 2;
        cellQueue.push(new Cell(cell.x - h, cell.y - h, h, polygon, centerBoundary));
        cellQueue.push(new Cell(cell.x + h, cell.y - h, h, polygon, centerBoundary));
        cellQueue.push(new Cell(cell.x - h, cell.y + h, h, polygon, centerBoundary));
        cellQueue.push(new Cell(cell.x + h, cell.y + h, h, polygon, centerBoundary));
        numProbes += 4;
    }

    if (debug) {
        console.log('num probes: ' + numProbes);
        console.log('best distance: ' + bestCell.d);
    }

    var poleOfInaccessibility: PoleOfInaccessibility = [bestCell.x, bestCell.y];
    poleOfInaccessibility.distance = bestCell.d;
    return poleOfInaccessibility;
}

function compareMax(a: Cell, b: Cell) {
    return b.max - a.max;
}

class Cell {
    public x: number;
    public y: number;
    public h: number;
    public d: number;
    public max: number;

    public b: number;

    constructor(x: number, y: number, h: number, _polygon: number[][][], centerBoundary?: number[][][]) {
        this.x = x; // cell center x
        this.y = y; // cell center y
        this.h = h; // half the cell size
        this.d = pointToPolygonDist(x, y, _polygon); // distance from cell center to polygon
        this.b = centerBoundary && pointToPolygonDist(x, y, centerBoundary) || 1;
        if (this.b < 0) {
            const a = polygon([[
                [x - h, y - h],
                [x - h, y + h],
                [x + h, y + h],
                [x + h, y - h],
                [x - h, y - h]
            ]])
            const b = polygon(centerBoundary!)
            const d = booleanDisjoint(a, b)
            if (d) {
                this.max = 0
            }
            else {
                this.max = this.d + this.h * Math.SQRT2; // max distance to polygon within a cell
            }
        }
        else {
            this.max = this.d + this.h * Math.SQRT2; // max distance to polygon within a cell
        }
    }
}

// signed distance from point to polygon outline (negative if point is outside)
function pointToPolygonDist(x: number, y: number, polygon: number[][][]) {
    var inside = false;
    var minDistSq = Infinity;

    for (var k = 0; k < polygon.length; k++) {
        var ring = polygon[k];

        for (var i = 0, len = ring.length, j = len - 1; i < len; j = i++) {
            var a = ring[i];
            var b = ring[j];

            if ((a[1] > y !== b[1] > y) &&
                (x < (b[0] - a[0]) * (y - a[1]) / (b[1] - a[1]) + a[0])) inside = !inside;

            minDistSq = Math.min(minDistSq, getSegDistSq(x, y, a, b));
        }
    }

    return minDistSq === 0 ? 0 : (inside ? 1 : -1) * Math.sqrt(minDistSq);
}

// get polygon centroid
function getCentroidCell(polygon: number[][][], centerBoundary?: number[][][]) {
    var area = 0;
    var x = 0;
    var y = 0;
    var points = polygon[0];

    for (var i = 0, len = points.length, j = len - 1; i < len; j = i++) {
        var a = points[i];
        var b = points[j];
        var f = a[0] * b[1] - b[0] * a[1];
        x += (a[0] + b[0]) * f;
        y += (a[1] + b[1]) * f;
        area += f * 3;
    }
    if (area === 0) return new Cell(points[0][0], points[0][1], 0, polygon, centerBoundary);
    return new Cell(x / area, y / area, 0, polygon, centerBoundary);
}

// get squared distance from a point to a segment
function getSegDistSq(px: number, py: number, a: number[], b: number[]) {

    var x = a[0];
    var y = a[1];
    var dx = b[0] - x;
    var dy = b[1] - y;

    if (dx !== 0 || dy !== 0) {

        var t = ((px - x) * dx + (py - y) * dy) / (dx * dx + dy * dy);

        if (t > 1) {
            x = b[0];
            y = b[1];

        } else if (t > 0) {
            x += dx * t;
            y += dy * t;
        }
    }

    dx = px - x;
    dy = py - y;

    return dx * dx + dy * dy;
}