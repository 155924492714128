const numberOrString = (str: string): number | string => {
    if (/^\d+$/.test(str)) {
        return parseInt(str, 10);
    } else {
        return str;
    }
}

const getLastField = (root: any, path: string, autoCreate: boolean): { root: any, field: number | string } | undefined => {
    const split = path.replace("]", "").split(/\.|\[/).map(numberOrString);
    for (let i = 0; i < split.length - 1; i++) {
        if (!(split[i] in root)) {
            if (autoCreate) {
                root[split[i]] = {};
            } else {
                return undefined;
            }
        }
        root = root[split[i]];
    }
    return {
        root,
        field: split[split.length - 1],
    };
}

export const setValue = (root: any, path: string, value: any): void => {
    const lf = getLastField(root, path, true)!;
    lf.root[lf.field] = value;
}

export const getValue = (root: any, path: string): any => {
    const lf = getLastField(root, path, false);
    if (!lf) {
        return undefined;
    }
    return lf.root[lf.field];
}