import {
    Box,
    Button,
    CssBaseline,
    Typography,
} from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FieldInformationAppBarItem from "../../../components/FieldInformationAppBarItem";
import RdpAppBar from "../../../components/RdpAppBar";
import DbCtx from "../../../db/DbCtx";
import RDPProposal from "../../../docGeneration/docTypes/RDPProposal";
import DeleteSystemButton from "../SystemDesign/DeleteSystemButton";
import SystemNameField from "../SystemDesign/SystemNameField";

const RDPProposalPage = () => {

  const navigate = useNavigate();
  const dbState = useContext(DbCtx);

  const { projectId, layoutId, systemId, type } = useParams();

  const dbPrj = dbState.projects[projectId!];
  const project = dbPrj.state;

  let systemIds: string[] = systemId.split("|");

  const system = project.layouts[layoutId!]?.systems[systemIds[0]];
  if (!system) {
    return <>{t("waiting-for-system")}</>;
  }

  var rightContent = [];
  if(systemIds.length === 1){
    rightContent.push(
      <DeleteSystemButton
        projectId={projectId}
        layoutId={layoutId}
        systemId={systemIds[0]}
        onDelete={() => {
          navigate(`/projects/${projectId}/layouts/${layoutId}/map`);
        }}
      />);
      rightContent.push(<Button sx={{ color: "inherit" }}>Export Shape</Button>);
  }

  return (
    <>
      <CssBaseline />
      <RdpAppBar
        leftContent={
          <>
            {systemIds.length > 1 && <Typography variant="h6" ml={3} style={{fontSize: '0.875rem', marginRight: 10}}>
                {t("nav.systems.multiple-systems")}
            </Typography>}
            {systemIds.length === 1 && <SystemNameField layoutId={layoutId} projectId={projectId} systemId={systemIds[0]}/>}
            <FieldInformationAppBarItem 
                  project={project}
                  layoutId={layoutId}
              />
          </>
        }
        rightContent={rightContent}  
        onClose={() =>  navigate(`/projects/${projectId}/layouts/${layoutId}/map`)}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "5em",
          alignItems: "center",
        }}
      >
        <RDPProposal type={type} systemIds={systemIds} />
      </Box>
    </>
  );
};

export default RDPProposalPage;
