import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { FC, useState } from "react";
import { HexColorPicker } from "react-colorful";

interface Props {
    open: boolean;
    color: string;
    updateColor: (newColor: string) => void;
    label: string;
    close: () => void;
}

const ColorPickerDialog: FC<Props> = (props) => {
    const [color, setColor] = useState(props.color);

    const ns = "dealer-settings.map-settings-options.";

    return (
        <Dialog open={props.open} onClose={props.close} sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                minWidth: "400px",
              },
            },
          }}>
        <DialogTitle>
                {t(ns + "change-color")}
            </DialogTitle>
        <DialogContent>
            <span>{props.label}</span>
            <HexColorPicker style={{marginTop: "2em", marginLeft: "auto", marginRight: "auto"}} color={props.color} onChange={(c) => setColor(c)}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>{t("cancel")}</Button>
            <Button onClick={() => {
                props.updateColor(color);
                props.close();            
            }}>{t("ok")}</Button>
            
        </DialogActions>
    </Dialog>   
    );
};

export default ColorPickerDialog;