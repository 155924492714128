import ISprinklerChart from "rdptypes/project/ISprinklerChart";
import ISprinklers, { DeviceTypes, IPackage, KometSprayTypes, idxSprinkler } from "rdptypes/project/ISprinklers";
import { EndGunAcresMethods, GeneratorModels, HoseFeedTypes, IDealerProperties, ILateralHoseFeed, IMainlineValveOptionsValves, IOntracCell, IOntracSatellitePlus, ISprinklerPropertiesVRIZones, ISystemBase, SpanTypes, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IsDualSided } from "./SystemFunctions";
import { EndGun, TOutlets, TPackages } from "./Types";

// TODO Bring this in from Dealer Settings now
export const IsInternational = (dealer: IDealerProperties): boolean => false;

export const ValvesCount = (x: IMainlineValveOptionsValves): number => x.MainlineValve.length;
export const SatellitePlusCount = (x: IOntracSatellitePlus): number => x.SatellitePlusUnit.length;
export const CellCount = (x: IOntracCell): number => x.CellUnit.length;
export const VRIZonesCount = (x: ISprinklerPropertiesVRIZones): number => x.Zone.length;

export const PercentOfCircle = (sys: ISystemBase): number => {
    if (!sys.Circle?.CenterPivot?.isPartialPivot) return 100;
    if (sys.Circle?.CenterPivot?.clockwiseCompassHeadingStart === undefined
        || sys.Circle?.CenterPivot?.clockwiseCompassHeadingEnd === undefined
        || sys.Circle?.CenterPivot.clockwiseCompassHeadingEnd === sys.Circle?.CenterPivot.clockwiseCompassHeadingStart) return 100;

    return 100 * ((sys.Circle?.CenterPivot.clockwiseCompassHeadingEnd - sys.Circle?.CenterPivot.clockwiseCompassHeadingStart + 360) % 360) / 360;
}

export const DegreeOfSweep = (sys: ISystemBase): number => {
    if (!sys.Circle?.CenterPivot?.isPartialPivot) return 360;
    if (sys.Circle?.CenterPivot?.clockwiseCompassHeadingStart === undefined
        || sys.Circle?.CenterPivot?.clockwiseCompassHeadingEnd === undefined
        || sys.Circle?.CenterPivot.clockwiseCompassHeadingEnd === sys.Circle?.CenterPivot.clockwiseCompassHeadingStart) return 360;

    return (sys.Circle?.CenterPivot.clockwiseCompassHeadingEnd - sys.Circle?.CenterPivot.clockwiseCompassHeadingStart + 360) % 360;
}

export const Outlets = (sprinklerChart: ISprinklerChart): TOutlets => ({
    Count: sprinklerChart.Outlet.length,
    ToList: () => sprinklerChart.Outlet,
    Outlet: sprinklerChart.Outlet
})

export const Packages = (sprinklers: ISprinklers): TPackages => ({
    Count: sprinklers.Package.length,
    ToList: () => sprinklers.Package,
    Package: sprinklers.Package
})


export const Pivoting = (hoseFeed: ILateralHoseFeed): boolean => {
    return hoseFeed.PivotOption || hoseFeed.CenterLift;
}


export const GetWattageValue = (gm: GeneratorModels): number => {
    switch (gm) {

        case GeneratorModels.None:
            return 0.0;
        case GeneratorModels.Lima_10_0_kW:
            return 10.0;
        case GeneratorModels.Lima_12_5_kW:
            return 12.5;
        case GeneratorModels.Lima_15_0_kW:
            return 15.0;
        case GeneratorModels.Lima_20_0_kW:
            return 20.0;
        case GeneratorModels.Lima_25_0_kW:
            return 25.0;
        case GeneratorModels.Lima_30_0_kW:
            return 30.0;
        case GeneratorModels.MeccAlte_05_0_kW:
            return 5.0;
        case GeneratorModels.MeccAlte_07_5_kW:
            return 7.5;
        case GeneratorModels.MeccAlte_10_0_kW:
            return 10.0;
        case GeneratorModels.MeccAlte_15_0_kW:
            return 15.0;
        case GeneratorModels.MeccAlte_18_0_kW:
            return 18.0;
        case GeneratorModels.MeccAlte_25_0_kW:
            return 25.0;
        case GeneratorModels.MeccAlte_27_0_kW:
            return 27.0;
        case GeneratorModels.MeccAlte_30_0_kW:
            return 30.0;
        default:
            return -1;
    }
}

export const EndGunAcresMethod = (EndGun: EndGun): EndGunAcresMethods => EndGun.EndGunAcresMethod;

export const PercentOfArc = (EndGun: EndGun): number => EndGun.PercentOfArc;

export const CornersWatered = (EndGun: EndGun): number => EndGun.CornersWatered;

export const SidesWatered = (EndGun: EndGun): number => EndGun.SidesWatered;

export const GetOrCreateValue = <K, V>(map: Map<K, V>, key: K, factory: () => V): V => {
    let r = map.get(key);
    if (!r) {
        r = factory();
        map.set(key, r);
    }
    return r;
}

export const AnyPolyLineSpans = (system: ISystemBase): boolean => {
    let spans = system.FlangedSide.Span.slice();
    if (IsDualSided(system)){
        spans.push(...system.FlexSide.Span);
    }

    spans.forEach((span) => {
        if (span.SpanType === SpanTypes.PL2065G || span.SpanType === SpanTypes.PL2085G){
            return true;
        }
    });

    return false;
}
export const IsA100_CF200 = (system: ISystemBase): boolean => // this.SystemTypeClass.DataValid() && 
        system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && // this.HoseFeedClass.DataValid() && 
        (system.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.A100
            || system.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200);
export const DeterminePackageTypeFromDeviceType = (pck: IPackage): idxSprinkler | null => {
    switch(pck.Device){
        /**------------------Nelson----------------- */
        case DeviceTypes.NelsonR3000FCNRotatorBlue:
        case DeviceTypes.NelsonR3000RotatorBlue:
        case DeviceTypes.NelsonR3000RotatorWhite :
        case DeviceTypes.NelsonR3000RotatorRed :
        case DeviceTypes.NelsonR3000RotatorOrange :
        case DeviceTypes.NelsonR3000RotatorBrown :
        case DeviceTypes.NelsonR3000RotatorOlive :
        case DeviceTypes.NelsonR3000FCNRotatorGreen :
        case DeviceTypes.NelsonR3000RotatorGreen :
            return idxSprinkler.NelsonR3000Rotator;
        case DeviceTypes.NelsonS3000SpinnerRed :
        case DeviceTypes.NelsonS3000SpinnerPurple :
        case DeviceTypes.NelsonS3000SpinnerYellow :
            return idxSprinkler.NelsonS3000Spinner;
        case DeviceTypes.NelsonO3000OrbitorBlue :
        case DeviceTypes.NelsonO3000OrbitorPurple :
        case DeviceTypes.NelsonO3000OrbitorBlack :
            return idxSprinkler.NelsonO3000Orbitor;
        case DeviceTypes.NelsonO3030OrbitorFXWhite :
        case DeviceTypes.NelsonO3030OrbitorFXBlack :
            return idxSprinkler.NelsonO3030OrbitorFX;
        case DeviceTypes.NelsonA3000AcceleratorGold :
        case DeviceTypes.NelsonA3000AcceleratorNavy :
        case DeviceTypes.NelsonA3000AcceleratorMaroon :
            return idxSprinkler.NelsonA3000Accelerator;
        case DeviceTypes.NelsonD3000FCNSpray :
        case DeviceTypes.NelsonD3000Spray :
            return idxSprinkler.NelsonD3000Spray;
         /**-------------------Senninger------------------- */ 
         case DeviceTypes.Senninger12MediumSpacingPlasticImpact:
            return idxSprinkler.sprSenningerConstantSpacingPlasticImpact;
        case DeviceTypes.Senninger6DoubleWhiteVanePlasticImpact:
            return idxSprinkler.sprSenningerVariableSpacingPlasticImpactWhite;
        case DeviceTypes.Senninger6DoubleRedVanePlasticImpact:
            return idxSprinkler.sprSenningerVariableSpacingPlasticImpactRed
        case DeviceTypes.SenningerLowAngleIWobWhite:
            return idxSprinkler.sprSenningerIWob;
        case DeviceTypes.SenningerLowAngleIWobBlue:
            return idxSprinkler.sprSenningerIWob;
        case DeviceTypes.SenningerHighAngleIWobBlack:
            return idxSprinkler.sprSenningerIWob;
        case DeviceTypes.SenningerStandardAngleIWobGray:
            return idxSprinkler.sprSenningerIWob;
        case DeviceTypes.SenningerXiWobBlack:
            return idxSprinkler.sprSenningerXiwob;
        case DeviceTypes.SenningerXiWobWhite:
            return idxSprinkler.sprSenningerXiwob;
        case DeviceTypes.SenningerXiWobBlue:
            return idxSprinkler.sprSenningerXiwob;
        case DeviceTypes.SenningerXiWobGray:
            return idxSprinkler.sprSenningerXiwob;
        case DeviceTypes.SenningerSuperSpray:
            return idxSprinkler.sprSenningerSuperSpray;
        case DeviceTypes.SenningerLDNSpray:
            return idxSprinkler.sprSenningerLDNSpray;
        case DeviceTypes.SenningerQuadSpray:
            return idxSprinkler.sprSenningerQuadSpray;
        case DeviceTypes.SenningerXcelWobbler:
            return idxSprinkler.sprSenningerXcelWobbler;
        /**-----------------Komet-------------------- */
        case DeviceTypes.KometTwisterBlack:
            return idxSprinkler.sprKometTwisterBlack;
        case DeviceTypes.KometTwisterBlue:
            return idxSprinkler.sprKometTwisterBlue;
        case DeviceTypes.KometTwisterYellow:
            return idxSprinkler.sprKometTwisterYellow
        case DeviceTypes.KometTwisterWhite:
            return idxSprinkler.sprKometTwisterWhite
        case DeviceTypes.KometSpray:
            switch(pck.KometSpray.Pad){
                case KometSprayTypes.Blue:
                    return idxSprinkler.sprKometSprayBlue;
                case KometSprayTypes.Grey:
                    return idxSprinkler.sprKometSprayGrey;
                case KometSprayTypes.Black:
                    return idxSprinkler.sprKometSprayBlack;
                case KometSprayTypes.Yellow:
                    return idxSprinkler.sprKometSprayYellow
            }
        default:
            return null;

    }
}