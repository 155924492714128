import "@fontsource/libre-franklin/900.css";
import MapIcon from '@mui/icons-material/Map';
import { Checkbox, Divider, FormControlLabel, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { FC, useContext } from "react";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { MapLayerType } from "../Map";
import ClearancesListItem from "./DisplayLabelsMenu/ClearancesListItem";
import LabelsListItem from "./DisplayLabelsMenu/LabelsListItem";

interface Props {

}

const MapLayersMenuItem: FC<Props> = (props) => {

    const devSettingsState = useContext(DevSettingsCtx);

    const [mapLayersMenuTarget, setMapLayersMenuTarget] = React.useState<null | HTMLElement>(null);

    const openMapLayersMenu = (event: React.MouseEvent<HTMLElement>) => {
        setMapLayersMenuTarget(event.currentTarget);
    };
    const closeMapLayersMenu = () => {
        setMapLayersMenuTarget(null);
    };

    const setMapLayerType = (type: MapLayerType) => {
        closeMapLayersMenu(); 
        devSettingsState.mapSettings.setMapLayerType(type);
    }

    const ns = "nav.";

    return (<>
            <Tooltip title={t(ns + "map-layer")} placement="bottom">
                <IconButton
                    size="large"
                    aria-label="maplayers"
                    aria-haspopup="true"
                    onClick={openMapLayersMenu}
                    color="inherit"
                >
                    <MapIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={mapLayersMenuTarget}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={!!mapLayersMenuTarget}
                onClose={closeMapLayersMenu}
            >
                <MenuItem onClick={() => {setMapLayerType(MapLayerType.MapBox)}}>
                    <FormControlLabel control={<Checkbox                           
                        checked={devSettingsState.mapSettings.layerType === MapLayerType.MapBox}
                        onChange={(ev) => {setMapLayerType(MapLayerType.MapBox)}}
                    />} label="Map Box" />
                </MenuItem>
                <MenuItem onClick={() => {setMapLayerType(MapLayerType.MapBoxRoadLabels)}}>
                    <FormControlLabel control={<Checkbox                           
                        checked={devSettingsState.mapSettings.layerType === MapLayerType.MapBoxRoadLabels}
                        onChange={(ev) => {setMapLayerType(MapLayerType.MapBoxRoadLabels)}}
                    />} label={`Map Box (${t(ns + "road-labels")})`} />
                </MenuItem>
                <MenuItem onClick={() => {setMapLayerType(MapLayerType.Azure)}}>
                    <FormControlLabel control={<Checkbox                           
                        checked={devSettingsState.mapSettings.layerType === MapLayerType.Azure}
                        onChange={(ev) => {setMapLayerType(MapLayerType.Azure)}}
                    />} label="Azure Map" />
                </MenuItem>
                <MenuItem onClick={() => {setMapLayerType(MapLayerType.Bing)}}>
                    <FormControlLabel control={<Checkbox                           
                        checked={devSettingsState.mapSettings.layerType === MapLayerType.Bing}
                        onChange={(ev) => {setMapLayerType(MapLayerType.Bing)}}
                    />} label="Bing Map" />
                </MenuItem>
                <MenuItem onClick={() => {setMapLayerType(MapLayerType.BingWithRoadLabels)}}>
                    <FormControlLabel control={<Checkbox                           
                        checked={devSettingsState.mapSettings.layerType === MapLayerType.BingWithRoadLabels}
                        onChange={(ev) => {setMapLayerType(MapLayerType.BingWithRoadLabels)}}
                    />} label={`Bing Map (${t(ns + "road-labels")})`} />
                </MenuItem>
                <ClearancesListItem  />
                <Divider />
                <LabelsListItem />
            </Menu>
        </>        
    );
};

export default MapLayersMenuItem;