const jsonMappings = require('./ImageFileMappings.json');

export const getImageFileMappings = (): ImgFileMapping[] => {  
  return jsonMappings;
}

export interface ImgFileMapping {
    fileName: string;
    fieldPath: string;
    id?: string;
}

export const getImgSource =  (fieldPath: string, id?: string): string | null => {
    var found = jsonMappings.find(x => x.fieldPath === fieldPath && (!id || id === x.id));  
    return found ? `http://roe-prod.reinke.com/sysdocs/${found.fileName}` : null;
}
