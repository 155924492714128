import { Mark } from "@mui/base";
import Slider from "@mui/material/Slider";
import * as React from "react";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { DisplayLengthUnitBuilder } from "../../helpers/lengths";
import { LATERAL_ELEVATION_PROFILE_STEP_FEET } from "./lateralElevationProfiler";

interface Props {
  offset: number;
  onChange: (offset: number) => void;
  maxOffset: number;
}

const OffsetSlider: React.FC<Props> = ({
  offset,
  onChange,
  maxOffset,
}) => {
  const settings = React.useContext(DevSettingsCtx);
  const sliderValues: { offset: number, marks: Mark }[] = [];
  let i = 0;

  let crntOffset = 0;
  while (crntOffset < maxOffset) {
    let label: string;
    if (crntOffset % 100 === 0) {
      label = new DisplayLengthUnitBuilder(crntOffset, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(1)
        .appendShortName()
        .toString()
    }
    else {
      label = "";
    }
    sliderValues.push({
      offset: crntOffset,
      marks: {
        value: crntOffset,
        label
      }
    });
    crntOffset += LATERAL_ELEVATION_PROFILE_STEP_FEET;
  }

  const found = sliderValues.find(x => x.offset === offset);
  const value = found ? found.marks.value : 0;

  // Slider SX is set here based on the bearing state due to the reason that the long value label is lost at the edges of the slider
  // There is probably a way to do this in pure CSS - I dont know how
  let sliderSx: {} = {
    "&.MuiSlider-markLabel": {
      transform: "none",
    },
  };
  if (found && found.marks.value < maxOffset * 0.1) {
    sliderSx["& .MuiSlider-valueLabel"] = {
      left: 0,
      borderBottomLeftRadius: 0,
      "&::before": {
        transform: "translateX(-375%) translate(-50%, 50%) rotate(45deg)"
      },
    };
  } else if (found && found.marks.value > maxOffset * 0.9) {
    sliderSx["& .MuiSlider-valueLabel"] = {
      right: 0,
      borderBottomLeftRadius: 0,
      "&::before": {
        transform: "translateX(550%) translate(-50%, 50%) rotate(45deg)"
      },
    };
  } else {
    sliderSx["& .MuiSlider-valueLabel"] = {
      left: "calc(var(--Slider-thumbSize) / 2)",
      borderBottomLeftRadius: 0
    };
  }

  return (
    <Slider
      aria-label="offset"
      min={0}
      max={maxOffset}
      step={LATERAL_ELEVATION_PROFILE_STEP_FEET}
      marks={sliderValues.map(x => x.marks)}
      valueLabelDisplay="on"
      track={false}
      value={value}
      onChange={(_, newValue) => {
        const found = sliderValues.find(x => x.marks.value === newValue);
        if (found) {
          onChange(found.offset);
        }
      }}
      valueLabelFormat={(value) => {
        const found = sliderValues.find(x => x.marks.value === value);
        if (found) {
          return new DisplayLengthUnitBuilder(found.offset, 'feet')
            .convert(settings.dealerSettings.display.current.lengths)
            .appendString("Offset ")
            .appendValue(1)
            .appendShortName()
            .toString();
        }
        return "";
      }}
      sx={sliderSx}
    />
  );
};

export default OffsetSlider;
