import IAction from "rdptypes/IAction";
import IProject from "../model/project/IProject";

/**
 * Action executors are simply functions which apply an action to a (mutable) project state. Note
 * that these are not type safe so we have to be careful when constructing actions, that the data is
 * acceptable to the corresponding executor. Instead of constructing IAction instances directly, it
 * is recommended that each action type defines a "create" function to be used for constructing all
 * actions of that type, and that the executor is defined alongside this function.
 */
type ActionExecutor = (action: IAction, state: IProject) => void;

/**
 * The definitive mapping between action type IDs and their corresponding executors.
 */
const executors: { [actionTypeId: string]: ActionExecutor } = {};

/**
 * Adds a new action executor to the registry.
 */
export const registerExecutor = (actionTypeId: string, executor: ActionExecutor) => {
    executors[actionTypeId] = executor;
}

/**
 * Executes a single action using the registered executor.
 */
export const executeAction = (action: IAction, state: IProject) => {
    if (!(action.actionTypeId in executors)) {
        throw new Error(`No executor registered for action ID ${action.actionTypeId}`);
    }
    executors[action.actionTypeId](action, state);
}

/**
 * Executes all actions in sequence, starting from a blank project.
 */
export const executeAllActions = (actions: IAction[]) => {
    const state = createBlankProject();
    for (const action of actions) {
        executeAction(action, state);
    }
    return state;
}

/**
 * @returns A blank project state to which subsequent actions can be applied.
 */
export const createBlankProject = () => {
    return {
        layouts: {}
    } as IProject;
}