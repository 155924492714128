import { ISpan, ITower } from "rdptypes/project/Types";
import ISystem from "../../../model/project/ISystem";

export const shouldCalculateEndGuns = (previousSystem: ISystem | undefined, incommingSystem: ISystem): boolean => {
    if (!previousSystem) return true;
  
    const fieldGetters = [
        (a: ISystem) => a.SystemProperties?.SystemType,
        (a: ISystem) => a.Circle?.CenterPivot?.isPartialPivot,
        (a: ISystem) => a.Circle?.CenterPivot?.clockwiseCompassHeadingStart,
        (a: ISystem) => a.Circle?.CenterPivot?.clockwiseCompassHeadingEnd,
        (a: ISystem) => a.Circle?.SwingArm?.LeadingSwingArm,
        (a: ISystem) => a.centerPivot?.point?.coordinates[0],
        (a: ISystem) => a.centerPivot?.point?.coordinates[1],

        (a: ISystem) => a.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypePrimary,
        (a: ISystem) => a.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypeSecondary,
        (a: ISystem) => a.FlangedSide?.EndOfSystem?.SwingArmLength,
        (a: ISystem) => a.FlangedSide?.EndOfSystem?.EndOfSystemType,
        (a: ISystem) => a.FlangedSide?.Span?.length,        
        (a: ISystem) => a.FlangedSide?.Tower?.length,    
        (a: ISystem) => a.endGuns?.flangedSidePrimaryThrow,    
        (a: ISystem) => a.endGuns?.flangedSideSecondaryThrow,

        (a: ISystem) => a.FlexSide?.EndOfSystem?.EndGun?.EndGunTypePrimary,
        (a: ISystem) => a.FlexSide?.EndOfSystem?.EndGun?.EndGunTypeSecondary,
        (a: ISystem) => a.FlexSide?.EndOfSystem?.SwingArmLength,
        (a: ISystem) => a.FlexSide?.EndOfSystem?.EndOfSystemType,
        (a: ISystem) => a.FlexSide?.Span?.length,        
        (a: ISystem) => a.FlexSide?.Tower?.length,    
        (a: ISystem) => a.endGuns?.flexSidePrimaryThrow,    
        (a: ISystem) => a.endGuns?.flexSideSecondaryThrow,

        (a: ISystem) => a.lateral?.canalWidthFeet,
        (a: ISystem) => a.lateral?.distanceFromCanalCenterToAftSide,
        (a: ISystem) => a.lateral?.distanceFromCanalCenterToFwdSide,
        (a: ISystem) => a.flexIsFwd,
        (a: ISystem) => a.lateral?.line?.coordinates.length,
        (a: ISystem) => a.lateral?.line && JSON.stringify(a.lateral.line.coordinates),
        (a: ISystem) => a.lateral?.anticlockwisePivotLineIndicies && JSON.stringify(a.lateral.anticlockwisePivotLineIndicies),
        (a: ISystem) => a.lateral?.startPivot?.angleDegrees,
        (a: ISystem) => a.lateral?.startPivot?.lengthAlongCenterLineFt,
        (a: ISystem) => a.lateral?.startPivot?.retrace,
        (a: ISystem) => a.lateral?.endPivot?.angleDegrees,
        (a: ISystem) => a.lateral?.endPivot?.lengthAlongCenterLineFt,
        (a: ISystem) => a.lateral?.endPivot?.retrace,
        (a: ISystem) => JSON.stringify(a.sacOptimizerResult)
        
    ]
    if (fieldGetters.some(fieldGetter => fieldGetter(incommingSystem) !== fieldGetter(previousSystem))) {
        return true;
    }

    // we will compare each span tower for a change. The fieldGetters check has already checked that the 
    // number of spans is the same between previous system and incomming system. Even so, we will compare
    // with a nullable spanTower incase some tower is undefined at an index.
    const spanFieldGetters = [
        (a?: ISpan) => a?.Length,
        (a?: ISpan) => a?.Extension,
        (a?: ISpan) => a?.dropSpanEndRelativeToPreviousSpanEnd,
        (a?: ISpan) => a?.dropSpanStartRelativeToPreviousSpanStart,
        (a?: ISpan) => a?.Disconnecting
    ]
    for (let i = 0; i < incommingSystem.FlangedSide?.Span?.length || 0; i++) {
        if (spanFieldGetters.some(fieldGetter => fieldGetter(incommingSystem.FlangedSide?.Span[i]) !== fieldGetter(previousSystem.FlangedSide?.Span[i]))) {
            return true;
        }
    }

    // we will compare each span tower for a change. The fieldGetters check has already checked that the 
    // number of spans is the same between previous system and incomming system. Even so, we will compare
    // with a nullable spanTower incase some tower is undefined at an index.
    const towerFieldGetters = [
        (a?: ITower) => a?.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
        (a?: ITower) => a?.clockwiseWrapAngleRelativeToPreviousSpanDegrees,
        (a?: ITower) => a?.WrapAroundSpan,
    ]
    for (let i = 0; i < incommingSystem.FlangedSide?.Span?.length || 0; i++) {
        if (towerFieldGetters.some(fieldGetter => fieldGetter(incommingSystem.FlangedSide?.Tower[i]) !== fieldGetter(previousSystem.FlangedSide?.Tower[i]))) {
            return true;
        }
    }

    // if the path data has changed then we will trigger a regeneration:
    const incommingPathData = incommingSystem.pathData || [];
    const previousPathData = previousSystem.pathData || [];
    if (incommingPathData.length !== previousPathData.length) {
        return true;
    }
    else {
        for (let i = 0; i < incommingPathData.length; i++) {
            const crntIncomming = incommingPathData[i];
            const crntPrevious = previousPathData[i];
            if (crntIncomming.angle !== crntPrevious.angle || crntIncomming.radius !== crntPrevious.radius) {
                return true;
            }
        }
    }
    
    
    // If we have gotten this far, then the systems are the same interms of endguns
    return false;

}