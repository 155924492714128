import { Feature, LineString } from "@turf/turf";
import { createNewLineAnnotation } from "../../../../actions/NewLineAnnotationAction";
import IAuthState from "../../../../auth/IAuthState";
import IDbProject from "../../../../db/IDbProject";
import { AnnotationType } from "../../../../model/project/IAnnotation";


export const handleElectricLine =  (
    features: Feature[],
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
) => {
    if (features.length !== 1 && features[0].geometry.type !== "LineString") {
        throw new Error(`Unexpected feature created`);
    }
    dbPrj.pushAction(createNewLineAnnotation(
        layoutId,
        AnnotationType.electricLine,
        features[0].geometry as LineString,
        authState
    ));
}
