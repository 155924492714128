import { LineString, Point } from "@turf/turf";
import { ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import { SprinklerEngineWarning } from "roedata/roe_migration/SprinklerEngineTypes/SprinklerEngineWarning";
import { IEndgunInformation } from "../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import { ILateralEndGunInformation } from "../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { PathData } from "../../optimization/centerPivot/sac/SACOptimizer/ThinkGeo/PathUtilities";
import { ISacOptimizerResultModel } from "./ISacOptimizerResultModel";

/**
 * Represents an irrigation system contained within a field layout.
 */
export default interface ISystem extends ISystemBase {
    name: string;
    
    lastModifiedTimestamp: number;
    lastModifiedUserId: string;

    productLine?: ProductLine;

    centerPivot?: ICenterPivot;
    lateral?: ILateral;


    sacOptimizerResult: ISacOptimizerResultModel;

    SprinklerEngineResultWarnings?: SprinklerEngineWarning[];

    endGuns?: {
        flangedSidePrimaryThrow?: number;
        flangedSideSecondaryThrow?: number;
        flexSidePrimaryThrow?: number;
        flexSideSecondaryThrow?: number;
        centerPivotOnOffs?: IEndgunInformation[];
        lateralOnOffsFlex?: ILateralEndGunInformation[];
        lateralOnOffsFlanged?: ILateralEndGunInformation[];
    }

    pathData?: PathData[];

    flexIsFwd?: boolean;
    overlapping?: boolean;

    /**
     * There are normally two steps to submitting an order to MAPICS. We first call quote/create which generates
     * a unique Quote ID, which we can show in the Dealer Purchase Order. The results are saved in this createQuoteStatus.
     * We also set proposalGenerated to true. If we are working offline then proposalGenerated can be set to true without
     * createQuoteStatus being set. In this case we cannot show any Quote ID on the DPO.
     */
    createQuoteStatus?: {
        success: boolean,
        quoteId?: string,
        errorMessage?: string,
        timestamp: string
    }

    /**
     * undefined or false if no proposal has been generated (or the proposal has been deleted)
     * true for old proposals
     * Random ID for new proposals
     */
    proposalGenerated?: boolean | string;

    orderSubmissionStatus?: {
        success: boolean,
        quoteId?: string,
        errorMessage?: string,
        timestamp: string,

        // May be undefined for old submissions. In this case assume true.
        proposalId?: string | true
    }
}

export enum ProductLine {
    e3 = "e3",
    e2 = "e2",
    edmp = "edmp"
}

export interface ICenterPivot {
	point: Point;
}

export interface IPivotingLateralInformation {
    retrace?: boolean;
    lengthAlongCenterLineFt?: number;
    angleDegrees?: number;
}
export interface ILateral {
    line: LineString;
    canalWidthFeet?: number;
    distanceFromCanalCenterToFwdSide?: number;
    distanceFromCanalCenterToAftSide?: number;
    anticlockwisePivotLineIndicies?: number[];
    startPivot?: IPivotingLateralInformation;
    endPivot?: IPivotingLateralInformation;
}