import { Text, View } from "@react-pdf/renderer";
import { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import ISystem from "../../model/project/ISystem";
import { documentStyles } from "../styles";
import FixedWidthTable, { IRow } from "./table/FixedWidthTable";

export interface Props {
    quote: QuoteClass;
}

const CustomerCommentsList: FC<Props> = (props) => {
    let {quote} = props;
    let comments = (quote.System as ISystem)?.QuoteProperties?.ProposalInformation?.customerComments || "";

    let rows: IRow[] = [{cells: [{
        text: comments,
        widthPc: 100
    }]}];

    return (<View style={documentStyles.section}>
            <View style={documentStyles.heading}>
                    <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltCustomerComments")}</Text>
            </View>
            <View style={{paddingLeft: 15, paddingRight: 15}}>
            <FixedWidthTable
                data={{rows}}
            />
            </View>
        </View>
    );
};

export default CustomerCommentsList;