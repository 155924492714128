import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from '../auth/IAuthState';
import { setValue } from '../helpers/objectPathResolver';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordProjectChange } from './helpers';

const actionTypeId = "UpdateProjectProperty";

interface ActionData extends IActionData {
    property: string;
    value: any;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;

    setValue(state, data.property, data.value);
    recordProjectChange(action, state);
});

export const createNewUpdateProjectPropertyAction =
    (property: string, value: any, authState: IAuthState) => createAction(
        actionTypeId,
        {
            property,
            value
        } as ActionData,
        authState);