import { GuidanceTypes, HoseFeedTypes, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import { GPSCoverageStudyLateralOk, LongPLSystemOk } from "../Valids.Valids";
import { GPSCoverageStudyLateralOkDto } from "../Valids.dto.Valids";

export default class GuidanceForm {
    private bSingleSpanMaxi: boolean;
    private bPivotingLateral: boolean;
    private systemType: SystemTypes;
    private eHoseFeedType: HoseFeedTypes;
    private bTowable: boolean;

    constructor(private Quote: QuoteClass){
        this.bSingleSpanMaxi = false;
        this.bPivotingLateral = false;
        this.bTowable = this.Quote.IsTowable();
        this.systemType = this.Quote.System.SystemProperties.SystemType;

        if (this.systemType === SystemTypes.HoseFeedMaxigator){
            if (this.Quote.HoseFeedClass.FieldSet.DataValid()){
                this.eHoseFeedType = this.Quote.System.Lateral.HoseFeed.HoseFeedType;
                if (this.eHoseFeedType === HoseFeedTypes.PivotingLateral){
                    this.bPivotingLateral = true;
                }
            }
            if (this.Quote.System.Lateral.HoseFeed.SingleSpanMaxi){
                this.bSingleSpanMaxi = true;
            }
        }
    }

    public validate = (): void => {
        let bPivoting = false;
        let hoseFeedType: HoseFeedTypes = undefined;

        if (this.Quote.HoseFeedClass.FieldSet.DataValid()){
            let hf = this.Quote.System.Lateral.HoseFeed;
            if (hf.PivotOption) bPivoting = true;
            hoseFeedType = hf.HoseFeedType;
        }

        if (this.Quote.System?.Lateral?.Guidance){
            let g = this.Quote.System.Lateral.Guidance;

            if (g.GuidanceType === GuidanceTypes.NavigatorGPS) {
                let data: GPSCoverageStudyLateralOkDto = {
                    GPSBaseOption: g.GPSBase,
                    GuidanceType: g.GuidanceType,
                    Country: this.Quote.System.DealerProperties.Country,
                    GuidanceLocation: g.Location,
                    HoseFeedType: hoseFeedType
                }

                if (!GPSCoverageStudyLateralOk(g.GPSCoverageStudyCompleted, data)){
                    this.Quote.Warnings.Add(`Lateral GPS coverage study invalid`);
                }
            }

            if (!LongPLSystemOk(g.FurrowOptions.LongSystem, {
                GuidanceType: g.GuidanceType,
                HoseFeedType: this.Quote.System.Lateral.HoseFeed.HoseFeedType,
                IsSingleSpanMaxi: this.Quote.IsSingleSpanMaxi(),
            })){
                this.Quote.Warnings.Add(`Guidance long system option invalid`);
            }
        }
    }
}