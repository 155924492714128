import { Feature, Polygon } from "@turf/turf";
import { createNewWheelObstacleAction } from "../../../../actions/NewWheelObstacleAction";
import IAuthState from "../../../../auth/IAuthState";
import { IFieldSettings } from "../../../../components/DealerSettingsDialog/FieldSettings";
import IDbProject from "../../../../db/IDbProject";


export const handleWheelObstacle = (
    features: Feature[],
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
    fieldSettings: IFieldSettings,
) => {
    if (features.length !== 1 && features[0].geometry.type !== "Polygon") {
        throw new Error(`Unexpected feature created`);
    }
    dbPrj.pushAction(createNewWheelObstacleAction(
        layoutId,
        {
            polygon: features[0].geometry as Polygon,
            clearance: fieldSettings.minObstacleClearanceFeet
        },
        authState
    ));
}
