// N is the array size
// maxLength is the max combination size
// example: combinations(3,2) ->
//              [[0], [1], [0, 1], [2], [0, 2], [1, 2]]
export const combinations = (N: number, maxLength: number) => {
  const array = [...Array(N).keys()];
  const subsets = [[]] as number[][];

  for (const el of array) {
    const last = subsets.length - 1;
    for (let i = 0; i <= last; i++) {
      if (subsets[i].length < maxLength) {
        subsets.push([...subsets[i], el]);
      }
    }
  }

  return subsets.slice(1);
};
