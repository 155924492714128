import RestorePage from '@mui/icons-material/RestorePage';
import { IconButton, MenuItem, Tooltip } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { useState } from "react";
import ResetProposalConfirmationDialog from "./ResetProposalConfirmationDialog";

interface IProps {
    projectId: string;
    layoutId: string;
    systemId: string;
    type?: 'button' | 'menuItem';
    onReset: () => void;
}

const ResetProposalButton: React.FC<IProps> = ({
    projectId, layoutId, systemId, type = 'button', onReset
}) => {

    const [ showConfirmation, setShowConfirmation ] = useState(false);

    const handleOnClick = () => {
        setShowConfirmation(true);
    }

    const buttonComponent = type === 'menuItem'
        ? (
            <MenuItem 
                onClick={handleOnClick}
                sx={{ color: "inherit" }}
                
            >
                {i18next.format(t('reset-proposal'), 'capitalize-each')}
            </MenuItem>
        )
        : (
            <Tooltip title={i18next.format(t('reset-proposal'), 'capitalize-each')} placement="bottom">
                <IconButton 
                    onClick={handleOnClick}
                    sx={{ color: "inherit" }}
                    
                >
                        <RestorePage/>      
                </IconButton>
            </Tooltip>
        )

    return (
        <>
            {buttonComponent}
            {
                showConfirmation && (
                    <ResetProposalConfirmationDialog
                        projectId={projectId}
                        layoutId={layoutId}
                        systemId={systemId}
                        onCancel={() => {
                            setShowConfirmation(false);
                        }}
                        onReset={() => {
                            setShowConfirmation(false);
                            onReset();
                        }}
                    />
                )
            }
        </>
    )
}

export default ResetProposalButton;