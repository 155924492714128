import QuoteClass from "../QuoteClass";
import { strLanguageText } from "../TranslationsLib";

// NOTE: Simon has only partially converted this class.
// The partial conversion is due to a vlaidation rule existing within
// the form code. 
// This one rule has been added on demand
export default class SprinklersPackagesForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        const pe = this.Quote.System.SprinklerProperties.PeakElevation;
        if (pe < 0 || pe > 150) {
            this.Quote.Warnings.Add(strLanguageText("sltElevationMustBeBetween"));
        }
    }
}