import * as React from "react";
import { FC } from "react";
import { SYSTEM_DESIGN_CONSTANTS } from "../SystemDiagram/constants";

interface IProps {
    startDistanceInches: number;
    elevationFeet: number;
}

const Wheel: FC<IProps> = ({ startDistanceInches, elevationFeet }) => {
    const towerWidth = SYSTEM_DESIGN_CONSTANTS.pipe.width;
    const wheelOffset = SYSTEM_DESIGN_CONSTANTS.wheel.offset;
    const wheelWidth = SYSTEM_DESIGN_CONSTANTS.wheel.width;
    const wheelHeight = SYSTEM_DESIGN_CONSTANTS.wheel.height;

    return (
        <>
            <path 
                d={`
                    M ${startDistanceInches + towerWidth} ${elevationFeet * 12 + wheelHeight * 0.5} 
                    l 0 ${towerWidth}`
                } 
                stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
                strokeWidth={towerWidth} />
            <path 
                d={`
                    M ${startDistanceInches + towerWidth + wheelOffset} ${elevationFeet * 12} 
                    l 0 ${wheelHeight}`
                } 
                stroke={SYSTEM_DESIGN_CONSTANTS.wheel.color} 
                strokeWidth={wheelWidth} />
        </>
    );
};

export default Wheel;