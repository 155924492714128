import { t } from "i18next";
import { barToPsi, psiToBar } from "../docGeneration/DocumentGenerationHelpers";

export type DisplayPressureUnits = 'psi' | 'bar';  
export const DisplayPressureUnitsMap = {
    psi: {
        longName: 'psi',
        fullName: 'psi',
        shortName: 'psi',
        value: 'psi' as DisplayPressureUnits
    }, 
    bar: {
        longName: 'bar',
        fullName: 'bar',
        shortName: 'bar',
        value: 'bar' as DisplayPressureUnits
    }
} as const;

export class DisplayPressureUnitBuilder {
    static shortName = (unit: DisplayPressureUnits) => DisplayPressureUnitsMap[unit].shortName;
    static longName = (unit: DisplayPressureUnits) => DisplayPressureUnitsMap[unit].longName;
    static fullName = (unit: DisplayPressureUnits) => DisplayPressureUnitsMap[unit].fullName;

    static convert = (value: number, from: DisplayPressureUnits, to: DisplayPressureUnits) => {
        if (from === to) return value;
        if (to === "bar"){
            return psiToBar(value);
        }
        return barToPsi(value);
    }

    private _value: number;
    private _unit: DisplayPressureUnits;
    private _formattedString = "";

    constructor(value: number, unit: DisplayPressureUnits) {
        this._value = value;
        this._unit = unit;
    }

    convert = (finalUnit: DisplayPressureUnits) => {
        this._value = DisplayPressureUnitBuilder.convert(this._value, this._unit, finalUnit);
        this._unit = finalUnit;
        return this;
    }

    appendValue = (toFixed?: number) => {
        if (toFixed === undefined) {
            this._formattedString += this._value.toString();
        }
        else {
            this._formattedString += this._value.toFixed(toFixed);
        }
        return this;
    }

    appendString = (value: string) => {
        this._formattedString += value;
        return this;
    }

    appendShortName = () => {
        this._formattedString += DisplayPressureUnitBuilder.shortName(this._unit);
        return this;
    }
    
    appendLongName = () => {
        this._formattedString += t(DisplayPressureUnitBuilder.longName(this._unit));
        return this;
    }

    appendFullName = () => {
        this._formattedString += t(DisplayPressureUnitBuilder.fullName(this._unit));
        return this;
    }

    toString = () => {
        return this._formattedString;
    }

}
