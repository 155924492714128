import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { DisplayLengthUnitBuilder, DisplayLengthUnits } from "../../helpers/lengths";
import { isSpanExtensionsPosibleByLength } from "../../helpers/validation/spanExtensions";
import { getEndBoomLengths } from "../../reinkeComponentConversions/endBoomLengths";
import { createSpanLabel, getSpanLengths } from "../../reinkeComponentConversions/spanLengths";
import { disabledColor, pageStyles } from "../roe/styles";

export interface PartialSpanLengthItem { spanLength: number, extension: boolean };
export type SpanLengthItem = PartialSpanLengthItem & { totalLength: number };
export const getTotalSpanLength = (partialItem: PartialSpanLengthItem) => 
    partialItem.spanLength + (partialItem.extension ? 4 : 0) + spanf.additionalSpanLength(partialItem.spanLength);
export const partialToSpanLengthItem = (partialItem: PartialSpanLengthItem): SpanLengthItem => ({
    ...partialItem,
    totalLength: getTotalSpanLength(partialItem)
})

interface Props {
    restrictSpanLengths: boolean;
    restrictedSpanLengths: PartialSpanLengthItem[];
    onChangeRestrictedSpanLengths: (spanLengths: PartialSpanLengthItem[]) => void;
    options: {
        type: "End Boom"
    } | {
        type: "Span";
    }
}

const getAvailableSpanLengths = (units: DisplayLengthUnits) => {
    const dict: { [totalLength: string ]: { spanLength: number, extension: boolean, totalLength: number, label: string }} = {};
    const baseSpanLengths = getSpanLengths(units);
    for (const spanLength of baseSpanLengths) {
        const totalLength = getTotalSpanLength({ spanLength: spanLength.originalValue, extension: false });
        if (!(totalLength in dict)) {
            dict[totalLength] = { 
                spanLength: spanLength.originalValue, 
                extension: false, 
                totalLength: totalLength,
                label: createSpanLabel(units, spanLength.originalValue)
            };
        }
        else {
            if (dict[totalLength].extension) {
                dict[totalLength].extension = false;
                dict[totalLength].spanLength = spanLength.originalValue;
                dict[totalLength].label = createSpanLabel(units, spanLength.originalValue);
            }
        } 
        
        // in isSpanExtensionsPosibleByLength: we will just choose the least restrictive (not hose feed or first span),
        // the idea here is to list all spans
        const bIsExtPossible = isSpanExtensionsPosibleByLength(spanLength.originalValue, false, 2);
        if (bIsExtPossible) {
            const totalLength = getTotalSpanLength({ spanLength: spanLength.originalValue, extension: true });
            if (!(totalLength in dict)) {
                dict[totalLength] = { 
                    spanLength: spanLength.originalValue, 
                    extension: true, 
                    totalLength: totalLength,
                    label: `${createSpanLabel(units, spanLength.originalValue + 4)} (${createSpanLabel(units, spanLength.originalValue)} + ${createSpanLabel(units, 4)} ext)`
                };
            }
        }
    }
    return Object.values(dict).sort((a, b) => a.totalLength - b.totalLength);
}
const getAvailableEndBoomLengths = (units: DisplayLengthUnits) => {
    const dict: { [totalLength: string ]: { spanLength: number, extension: boolean, totalLength: number, label: string }} = {};
    const baseSpanLengths = getEndBoomLengths(units);
    for (const spanLength of baseSpanLengths) {
        const totalLength = getTotalSpanLength({ spanLength: spanLength.originalValue, extension: false });
        dict[totalLength] = { 
            spanLength: spanLength.originalValue, 
            extension: false, 
            totalLength: totalLength,
            label: spanLength.label
        };
    }
    return Object.values(dict).sort((a, b) => a.totalLength - b.totalLength);
}

export const RestrictSpanLengthsControl: React.FC<Props> = ({
    restrictSpanLengths, restrictedSpanLengths, onChangeRestrictedSpanLengths, options
}) => {
    
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const settings = React.useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;
    const handleChangeRestrictedSpanLengths = (event: SelectChangeEvent<string[]>) => {
        const {
          target: { value },
        } = event;

        // NOTE: Clicking close with no selection will add an undefined to the incoming value array.
        // Here we filter that out if required
        const availableIndiciesMaybeContainingClose = typeof value === 'string' ? value.split(',') : value;
        const availableIndicies = availableIndiciesMaybeContainingClose.filter(x => x !== undefined);
        const restrictedSpanLengths = availableIndicies.map(x => {
            const index = parseInt(x);
            return availableSpanLengths[index];
        });
        onChangeRestrictedSpanLengths(restrictedSpanLengths);
    };

    const availableSpanLengths = options.type === 'Span'
        ? getAvailableSpanLengths(displaySettings.lengths)
        : getAvailableEndBoomLengths(displaySettings.lengths);

    const ns = "optimization-dialog.";

    const selectedIndicies = restrictedSpanLengths.map(x => 
        availableSpanLengths.findIndex(y => y.spanLength === x.spanLength && y.extension === x.extension)
    ).filter(x => x !== -1).map(x => x.toString());


    const disabled = !restrictSpanLengths;
    let formInputLabelStyle = {
        ...pageStyles.formInputLabelStyle
    };
    if (disabled) {
        formInputLabelStyle['color'] = disabledColor;
    }

    return (        
        <FormControl variant="standard" fullWidth>
            <InputLabel shrink={true} style={{...formInputLabelStyle}} id="span-select-label">{options.type === 'Span' ? t(ns + "span-lengths") : t(ns + "end-boom-lengths")} ({t(DisplayLengthUnitBuilder.shortName(displaySettings.lengths))})</InputLabel>
            <Select
                disabled={disabled}
                labelId="span-select-label"
                id="span-select"
                multiple
                value={selectedIndicies}
                onChange={handleChangeRestrictedSpanLengths}
                renderValue={(values) => {
                    return values.map(x => availableSpanLengths[parseInt(x)])
                                 .sort((a, b) => a.totalLength - b.totalLength)
                                 .map(v => v.label)
                                 .join(", ");
                }}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
            >
                <Stack direction='row' justifyContent={'end'}>
                    <IconButton aria-label="close" size="small" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                {
                    availableSpanLengths.map((length, idx) => {
                        return (
                            <MenuItem
                                key={idx}
                                value={idx.toString()}
                            >
                                <Checkbox disableRipple checked={selectedIndicies.includes(idx.toString())}/>
                                {length.label}
                            </MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}