import {
    BaseInletSizes,
    CenterPivotTypes,
    CollectorRingTypes,
    Countries,
    ElectricalFrequencies,
    EndTowerPositioningTypes,
    FlowmeterModels,
    FlowmeterTypes,
    HoseFeedTypes,
    InletTypes,
    PanelModels,
    PressureTransducerOptions,
    SwitchMountTypes,
    SystemTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import { FloatsAreEqual } from "./CommonFunctions";



export const PanelModelOK = (type: PanelModels, data: PanelModelOKDto): boolean => {
    switch (type) {
        case PanelModels.RPMBasic:
            if ([SystemTypes.CanalFeedMaxigator, SystemTypes.HoseFeedMaxigator].indexOf(data.SystemType) !== -1) {
                return false;
            }
            break;
        case PanelModels.RPMStandard:
            if (data.SystemType === SystemTypes.HoseFeedMaxigator && data.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                return false;
            }
            break;
        case PanelModels.RPMAdvanced:
            if ([SystemTypes.CanalFeedMaxigator, SystemTypes.HoseFeedMaxigator].indexOf(data.SystemType) !== -1) {
                return false;
            }
            break;
        case PanelModels.RPMAdvancedPlus:
            if (data.SystemType === SystemTypes.HoseFeedMaxigator) {
                if ([HoseFeedTypes.A100, HoseFeedTypes.CF200, HoseFeedTypes.PivotingLateral].indexOf(data.HoseFeedType) !== -1) {
                    return false;
                }
            }
            break;
        case PanelModels.RPMConnect:
            if (data.IsInternational) {
                return false;
            }
            if ([SystemTypes.CanalFeedMaxigator, SystemTypes.HoseFeedMaxigator].indexOf(data.SystemType) !== -1) {
                return false;
            }
            break;
        case PanelModels.RPMPreferred:
            if (data.SystemType === SystemTypes.HoseFeedMaxigator && [HoseFeedTypes.A100, HoseFeedTypes.CF200].indexOf(data.HoseFeedType) !== -1) {
                return false;
            }
            break;
    }
    return true;
}

// Rules implemented in page instead
/*export const ElectricalFrequencyOK = (type: ElectricalFrequencies, data: ElectricalFrequencyOKDto): boolean => {
    switch (type) {
        case ElectricalFrequencies.a60:
            break;
        case ElectricalFrequencies.a50:
            if (data.Is230V) {
                return false;
            }
            if (data.SystemType === SystemTypes.HoseFeedMaxigator && [HoseFeedTypes.A100, HoseFeedTypes.CF200].indexOf(data.HoseFeedType) !== -1) {
                return false;
            }
            break;
    }
    return true;
}*/

export const ElectricalFrequency2OK = (type: ElectricalFrequencies, data: ElectricalFrequency2OKDto): boolean => {
    return true;
}

export const RemotePanelOK = (testCheckedState: boolean, data: RemotePanelOKDto): boolean => {
    if (testCheckedState) {
        if ([SystemTypes.CenterPivot, SystemTypes.KwikTow].indexOf(data.SystemType) === -1) {
            return false;
        }
        if (data.RemoteControlPanelLessMount) {
            return false;
        }
    } else {
    }
    return true;
}

export const RemotePanelLessMountOK = (testCheckedState: boolean, data: RemotePanelOKDto): boolean => {
    if (testCheckedState) {
        if ([SystemTypes.CenterPivot, SystemTypes.KwikTow].indexOf(data.SystemType) === -1) {
            return false;
        }
        if (data.RemoteControlPanel) {
            return false;
        }
    } else {
    }
    return true;
}

export const GenStartOK = (testCheckedState: boolean, data: GenStartOKDto): boolean => {
    if (testCheckedState) {
    } else {
        switch (data.SystemType) {
            case SystemTypes.HoseFeedMaxigator:
                if ([HoseFeedTypes.A100, HoseFeedTypes.CF200].indexOf(data.HoseFeedType) !== -1) {
                    return false;
                }
                break;
            case SystemTypes.CanalFeedMaxigator:
                return false;
                break;
        }
    }
    return true;
}

export const GFIOK = (testCheckedState: boolean, data: GFIOKDto): boolean => {
    if (testCheckedState) {
        if (data.IsInternational) {
            return false;
        }
        if (data.Is230V) {
            return false;
        }
    } else {
    }
    return true;
}

export const GroundingBarOK = (testCheckedState: boolean, data: GroundingBarOKDto): boolean => {
    if (testCheckedState) {
        if (data.SystemType === SystemTypes.HoseFeedMaxigator && [HoseFeedTypes.A100, HoseFeedTypes.CF200].indexOf(data.HoseFeedType) !== -1) {
            return false;
        }
    } else {
    }
    return true;
}

export const NonFuseCarrierOk = (testCheckedState: boolean, data: NonFuseCarrierOkDto): boolean => {
    if (testCheckedState) {
        if (data.SystemType === SystemTypes.HoseFeedMaxigator && [HoseFeedTypes.CF200].indexOf(data.HoseFeedType) !== -1) {
            return false;
        }
    } else {
    }
    return true;
}

export const EndTowerPositioningOK = (eEndTowerPositioning: EndTowerPositioningTypes, data: EndTowerPositioningOKDto): boolean => {
    switch (eEndTowerPositioning) {
        case EndTowerPositioningTypes.None:
            switch (data.SystemType) {
                case SystemTypes.CenterPivot:
                case SystemTypes.KwikTow:
                    if ([PanelModels.RPMConnect, PanelModels.RPMPreferred].indexOf(data.PanelModel) !== -1) {
                        return false;
                    }
                    break;
                case SystemTypes.HoseFeedMaxigator:
                case SystemTypes.CanalFeedMaxigator:
                    if ([PanelModels.RPMConnect, PanelModels.RPMPreferred].indexOf(data.PanelModel) !== -1) {
                        return false;
                    }
                    break;
            }
            break;
        case EndTowerPositioningTypes.Resolver:
            if (data.SystemType !== SystemTypes.CenterPivot) {
                return false;
            }
            if ([PanelModels.RPMBasic, PanelModels.RPMStandard].indexOf(data.PanelModel) !== -1) {
                return false;
            }
            break;
        case EndTowerPositioningTypes.GPS:
            if ([PanelModels.RPMBasic, PanelModels.RPMStandard].indexOf(data.PanelModel) !== -1) {
                return false;
            }
            break;
    }
    return true;
}

export const CollectorReelOK = (collectorReel: CollectorRingTypes, data: CollectorReelOKDto): boolean => {
    if (collectorReel !== CollectorRingTypes.a10Ring && data.PivotAutoReverse) {
        return false;
    }
    switch (collectorReel) {
        case CollectorRingTypes.None:
            if (data.SystemType === SystemTypes.CenterPivot && FloatsAreEqual(data.PivotPercent, 100)) {
                return false;
            }
            if ([PanelModels.RPMAdvanced, PanelModels.RPMPreferred].indexOf(data.PanelModel) !== -1 && data.EndTowerPositioningType === EndTowerPositioningTypes.Resolver) {
                return false;
            }
            break;
        case CollectorRingTypes.a10Ring:
            if (data.SystemType !== SystemTypes.CenterPivot) {
                return false;
            }
            break;
        case CollectorRingTypes.a11RingICII:
        case CollectorRingTypes.a11RingUnited:
            if (data.SystemType !== SystemTypes.CenterPivot) {
                return false;
            }
            if (data.EndTowerPositioningType === EndTowerPositioningTypes.Resolver) {
                return false;
            }
            break;
        case CollectorRingTypes.a14Ring:
            if ([SystemTypes.CenterPivot, SystemTypes.HoseFeedMaxigator].indexOf(data.SystemType) === -1) {
                return false;
            }
            if (data.SystemType === SystemTypes.HoseFeedMaxigator && !data.PivotingLateral) {
                return false;
            }
            break;
    }
    return true;
}

export const FlowmeterModelOK = (type: FlowmeterModels, data: FlowmeterModelOKDto): boolean => {
    switch (type) {
        case FlowmeterModels.None:
            break;
        case FlowmeterModels.McPropeller:
        case FlowmeterModels.McMag:
            if (type === FlowmeterModels.McMag) {
                return false;
            }
            switch (data.SystemType) {
                case SystemTypes.HoseFeedMaxigator:
                    if (data.BaseInletSize !== BaseInletSizes.a8) {
                        return false;
                    }
                    break;
                case SystemTypes.KwikTow:
                    return false;
                    break;
            }
            break;
    }
    return true;
}

export const FlowmeterTypeOK = (type: FlowmeterTypes, data: FlowmeterOKDto): boolean => {
    switch (type) {
        case FlowmeterTypes.None:
            if ([FlowmeterModels.None, FlowmeterModels.Seametrics].indexOf(data.FlowmeterModel) === -1) {
                return false;
            }
            break;
        case FlowmeterTypes.Vertical:
            if (data.HasPivotRiserPipeOption) {
                return false;
            }
            if ([FlowmeterModels.None, FlowmeterModels.Seametrics].indexOf(data.FlowmeterModel) !== -1) {
                return false;
            }
            if (!data.VerticalFlowTubeKitAllowed) {
                return false;
            }
            break;
        case FlowmeterTypes.Horizontal:
            if ([FlowmeterModels.None, FlowmeterModels.Seametrics].indexOf(data.FlowmeterModel) !== -1) {
                return false;
            }
            switch (data.SystemType) {
                case SystemTypes.CanalFeedMaxigator:
                case SystemTypes.HoseFeedMaxigator:
                case SystemTypes.KwikTow:
                    return false;
                    break;
            }
            break;
    }
    return true;
}

export const HorizontalFlowtubeWithVanesOK = (testCheckedState: boolean, data: HorizontalFlowtubeWithVanesOKDto): boolean => {
    if (testCheckedState) {
        if (!data.IsFlowtubeKitAllowed) {
            return false;
        }
        if (data.FlowmeterLocation !== FlowmeterTypes.Horizontal) {
            return false;
        }
        if (data.FlowmeterModel === FlowmeterModels.Seametrics) {
            return false;
        }
    } else {
    }
    return true;
}

export const HorizontalFlowtubeWithFS100OK = (testCheckedState: boolean, data: HorizontalFlowtubeWithFS100OKDto): boolean => {
    if (testCheckedState) {
        if (!data.IsFlowtubeKitAllowed) {
            return false;
        }
        if (data.FlowmeterLocation !== FlowmeterTypes.Horizontal) {
            return false;
        }
        if (data.IsFlowtubeWithVanesPresent) {
            return false;
        }
        if (data.FlowmeterModel !== FlowmeterModels.McPropeller) {
            return false;
        }
    } else {
    }
    return true;
}

export const FlowmeterPulseOK = (testCheckedState: boolean, data: FlowmeterPulseOKDto): boolean => {
    if (testCheckedState) {
        if ([FlowmeterModels.None, FlowmeterModels.Seametrics].indexOf(data.FlowmeterModel) !== -1) {
            return false;
        }
        if ([PanelModels.RPMAdvancedPlus, PanelModels.RPMConnect, PanelModels.RPMPreferred].indexOf(data.PanelModel) === -1) {
            return false;
        }
    } else {
        if ([PanelModels.RPMAdvancedPlus, PanelModels.RPMConnect, PanelModels.RPMPreferred].indexOf(data.PanelModel) !== -1) {
            if (data.FlowmeterModel === FlowmeterModels.McMag) {
                return false;
            }
            if (data.FlowmeterModel === FlowmeterModels.McPropeller) {
            }
        }
    }
    return true;
}

export const LowVoltageSafetyOK = (testCheckedState: boolean, data: LowVoltageSafetyOKDto): boolean => {
    if (testCheckedState) {
        if (data.Is230V) {
            return false;
        }
        if (data.IsInternational) {
            return false;
        }
        if (data.PanelModel === PanelModels.RPMBasic) {
            return false;
        }
        if (data.SystemType === SystemTypes.HoseFeedMaxigator && [HoseFeedTypes.A100, HoseFeedTypes.CF200].indexOf(data.HoseFeedType) !== -1) {
            return false;
        }
    } else {
    }
    return true;
}

export const SwitchMountOK = (type: SwitchMountTypes, data: SwitchMountOKDto): boolean => {
    switch (type) {
        case SwitchMountTypes.None:
            if (data.SystemType === SystemTypes.KwikTow && data.EndTowerPositioningType === EndTowerPositioningTypes.None) {
                return false;
            }
            break;
        case SwitchMountTypes.CamWheel:
            if (data.EndTowerPositioningType !== EndTowerPositioningTypes.None) {
                return false;
            }
            if (data.SystemType === SystemTypes.CenterPivot && data.CollectorRingType === CollectorRingTypes.None) {
                return false;
            }
            if ([SystemTypes.HoseFeedMaxigator, SystemTypes.CanalFeedMaxigator, SystemTypes.SwingArmRetro].indexOf(data.SystemType) !== -1) {
                return false;
            }
            if (data.PanelModel === PanelModels.RPMPreferred) {
                return false;
            }
            break;
        case SwitchMountTypes.CamPlate:
            if (data.EndTowerPositioningType !== EndTowerPositioningTypes.None) {
                return false;
            }
            if (data.SystemType !== SystemTypes.CenterPivot) {
                return false;
            }
            if ([PanelModels.RPMPreferred, PanelModels.RPMAdvancedPlus].indexOf(data.PanelModel) !== -1) {
                return false;
            }
            if (data.CollectorRingType === CollectorRingTypes.None) {
                return false;
            }
            break;
    }
    return true;
}

export const PACTimerOK = (testCheckedState: boolean, data: PACTimerOKDto): boolean => {
    if (testCheckedState) {
        switch (data.PanelModel) {
            case PanelModels.RPMBasic:
            case PanelModels.RPMAdvancedPlus:
            case PanelModels.RPMConnect:
            case PanelModels.RPMPreferred:
                return false;
                break;
        }
    } else {
        if (data.PanelModel === PanelModels.RPMAdvanced) {
            return false;
        }
    }
    return true;
}

export const PivotAutoStopOK = (testCheckedState: boolean, data: PivotAutoStopOKDto): boolean => {
    if (testCheckedState) {
        if ([SystemTypes.CenterPivot, SystemTypes.KwikTow].indexOf(data.SystemType) === -1) {
            return false;
        }
        switch (data.SwitchMount) {
            case SwitchMountTypes.None:
                if (data.EndTowerPositioning === EndTowerPositioningTypes.None) {
                    return false;
                }
                break;
            case SwitchMountTypes.CamPlate:
                if (data.CollectorReel === CollectorRingTypes.a10Ring) {
                    return false;
                }
                if (data.PanelType === PanelModels.RPMBasic) {
                    return false;
                }
                break;
        }
    } else {
        if ([SystemTypes.CenterPivot, SystemTypes.KwikTow].indexOf(data.SystemType) !== -1 && data.EndTowerPositioning !== EndTowerPositioningTypes.None) {
            return false;
        }
    }
    return true;
}

export const PivotAutoReverseOK = (testCheckedState: boolean, data: PivotAutoReverseOKDto): boolean => {
    if (testCheckedState) {
        if (data.CollectorReelType !== CollectorRingTypes.a10Ring) {
            return false;
        }
        if (data.EndTowerPositioningType !== EndTowerPositioningTypes.None) {
            return false;
        }
        switch (data.SystemType) {
            case SystemTypes.CenterPivot:
                if (data.EndTowerPositioningType === EndTowerPositioningTypes.None) {
                    if (data.SwitchMountType !== SwitchMountTypes.CamWheel) {
                        return false;
                    }
                }
                break;
            case SystemTypes.KwikTow:
                switch (data.PanelModel) {
                    case PanelModels.RPMBasic:
                    case PanelModels.RPMStandard:
                        return false;
                        break;
                }
                if (data.EndTowerPositioningType === EndTowerPositioningTypes.None) {
                    return false;
                }
                break;
            default:
                return false;
                break;
        }
    } else {
    }
    return true;
}

export const CartAutoReverseOK = (testCheckedState: boolean, data: CartAutoReverseOKDto): boolean => {
    if (testCheckedState) {
        if ([SystemTypes.HoseFeedMaxigator, SystemTypes.CanalFeedMaxigator].indexOf(data.SystemType) === -1) {
            return false;
        }
        if (data.SystemType === SystemTypes.HoseFeedMaxigator && data.InletType !== InletTypes.ReverseOnHose) {
            return false;
        }
    } else {
    }
    return true;
}

export const EndGunShutoffOverrideOK = (testCheckedState: boolean, data: EndGunShutoffOverrideOKDto): boolean => {
    if (testCheckedState) {
        if (data.PanelModel === PanelModels.RPMBasic) {
            return false;
        }
        if (data.SystemType === SystemTypes.HoseFeedMaxigator && [HoseFeedTypes.A100, HoseFeedTypes.CF200].indexOf(data.HoseFeedType) !== -1) {
            return false;
        }
    } else {
        if (data.SystemType === SystemTypes.CanalFeedMaxigator) {
            return false;
        }
        if (data.SystemType === SystemTypes.HoseFeedMaxigator && [HoseFeedTypes.A100, HoseFeedTypes.CF200].indexOf(data.HoseFeedType) === -1) {
            return false;
        }
        if ([PanelModels.RPMAdvanced, PanelModels.RPMAdvancedPlus, PanelModels.RPMConnect, PanelModels.RPMPreferred].indexOf(data.PanelModel) !== -1) {
            return false;
        }
    }
    return true;
}

// Rules implemented in page instead
/*export const PowerAutoRestartOK = (testCheckedState: boolean, data: PowerAutoRestartOKDto): boolean => {
    if (testCheckedState) {
        if (data.SystemType === SystemTypes.HoseFeedMaxigator && data.HoseFeedType === HoseFeedTypes.CF200) {
            return false;
        }
    } else {
        if ([PanelModels.RPMAdvanced, PanelModels.RPMAdvancedPlus, PanelModels.RPMConnect, PanelModels.RPMPreferred].indexOf(data.PanelModel) !== -1) {
            return false;
        }
    }
    return true;
}*/

export const ChemAuxControlOK = (testCheckedState: boolean, data: ChemAuxControlOKDto): boolean => {
    if (testCheckedState) {
        if (data.PanelModel === PanelModels.RPMBasic) {
            return false;
        }
    } else {
        if ([PanelModels.RPMAdvanced, PanelModels.RPMAdvancedPlus, PanelModels.RPMConnect, PanelModels.RPMPreferred].indexOf(data.PanelModel) !== -1) {
            return false;
        }
    }
    return true;
}

export const InjectorPumpOK = (testCheckedState: boolean, data: InjectorPumpOKDto): boolean => {
    if (testCheckedState) {
        if (!data.HasChemAuxControl) {
            return false;
        }
        if (data.SystemType !== SystemTypes.HoseFeedMaxigator) {
            return false;
        }
        if (data.HoseFeedType !== HoseFeedTypes.CF200) {
            return false;
        }
        if (data.InjectorPumpReceptacle30A) {
            return false;
        }
    } else {
    }
    return true;
}

export const InjectorPumpReceptacle30AmpOK = (testCheckedState: boolean, data: InjectorPumpReceptacleXAmpOKDto): boolean => {
    if (testCheckedState) {
        if (typeof data.PanelModel === "undefined") return false;
        switch (data.PanelModel) {
            case PanelModels.RPMBasic:
            case PanelModels.RPMStandard:
                if (!data.HasChemAuxControl) {
                    return false;
                }
                break;
        }
        if (data.Is230V) {
            return false;
        }
        if (data.InjectorPumpReceptacle20A) {
            return false;
        }
    } else {
    }
    return true;
}

export const RainGaugeOK = (testCheckedState: boolean, data: RainGaugeOKDto): boolean => {
    if (testCheckedState) {
        if (data.PanelModel !== PanelModels.RPMPreferred) {
            return false;
        }
    } else {
    }
    return true;
}

export const WindGaugeOK = (testCheckedState: boolean, data: WindGaugeOKDto): boolean => {
    if (testCheckedState) {
        if (data.PanelModel !== PanelModels.RPMPreferred) {
            return false;
        }
        if (!data.HasRainGauge) {
            return false;
        }
    } else {
    }
    return true;
}

export const LightningArrestorOK = (testCheckedState: boolean, data: LightningArrestorOKDto): boolean => {
    if (testCheckedState) {
        if (data.SystemType === SystemTypes.HoseFeedMaxigator && [HoseFeedTypes.A100].indexOf(data.HoseFeedType) !== -1) {
            return false;
        }
    } else {
        switch (data.PanelModel) {
            case PanelModels.RPMAdvanced:
            case PanelModels.RPMAdvancedPlus:
            case PanelModels.RPMConnect:
            case PanelModels.RPMPreferred:
                return false;
                break;
        }
    }
    return true;
}

// Rules implemented in page instead
/*export const MCPSpanCableDisconnectOk = (testCheckedState: boolean, data: MCPSpanCableDisconnectOkDto): boolean => {
    if (testCheckedState) {
        if (data.SystemType === SystemTypes.HoseFeedMaxigator && [HoseFeedTypes.CF200].indexOf(data.HoseFeedType) !== -1) {
            return false;
        }
    } else {
    }
    return true;
}*/

export const PressureSwitchOK = (testCheckedState: boolean, data: PressureSwitchOKDto): boolean => {
    if (testCheckedState) {
        if (data.HasPressureTransducer) {
            return false;
        }
        if (data.HasOtherLPSwitch) {
            return false;
        }
        if ([SystemTypes.CanalFeedMaxigator, SystemTypes.HoseFeedMaxigator].indexOf(data.SystemType) !== -1) {
            if (data.HasUltraLowPressureSwitch) {
                return false;
            }
        }
    } else {
        if (!data.HasUltraLowPressureSwitch) {
            switch (data.SystemType) {
                case SystemTypes.CanalFeedMaxigator:
                    if ([PanelModels.RPMAdvancedPlus, PanelModels.RPMPreferred].indexOf(data.PanelModel) === -1) {
                        return false;
                    }
                    break;
                case SystemTypes.HoseFeedMaxigator:
                    if ([HoseFeedTypes.A100, HoseFeedTypes.CF200].indexOf(data.HoseFeedType) !== -1) {
                        return false;
                    }
                    break;
            }
        }
    }
    return true;
}

export const PressureTransducerOK = (type: PressureTransducerOptions, data: PressureTransducerOKDto): boolean => {
    if (type !== PressureTransducerOptions.None) {
        if (data.PressureSwitch) {
            return false;
        }
        if ([PanelModels.RPMConnect, PanelModels.RPMAdvancedPlus, PanelModels.RPMPreferred].indexOf(data.PanelModel) === -1) {
            return false;
        }
    }
    switch (type) {
        case PressureTransducerOptions.None:
            break;
        case PressureTransducerOptions.AtPivot:
            if (data.SystemType === SystemTypes.HoseFeedMaxigator && data.HoseFeedType === HoseFeedTypes.CF200) {
                return false;
            }
            break;
        case PressureTransducerOptions.AtEnd:
            if (data.SystemType === SystemTypes.HoseFeedMaxigator && data.HoseFeedType === HoseFeedTypes.CF200) {
                return false;
            }
            if (data.LateralOnly) {
                return false;
            }
            if (data.EndTowerPosistionOption !== EndTowerPositioningTypes.GPS) {
                return false;
            }
            if (data.PanelModel === PanelModels.RPMPreferred) {
                if (data.PivotingLateral) {
                    return false;
                }
            } else {
                return false;
            }
            break;
    }
    return true;
}

export const PressureTransducer0to25PSIOk = (testCheckedState: boolean, data: PressureTransducer0to25PSIOkDto): boolean => {
    if (testCheckedState) {
        if (data.SystemType !== SystemTypes.CenterPivot) {
            return false;
        }
        if (data.PressureTransducerOption !== PressureTransducerOptions.AtPivot) {
            return false;
        }
        if ([PanelModels.RPMAdvancedPlus, PanelModels.RPMConnect, PanelModels.RPMPreferred].indexOf(data.PanelModel) === -1) {
            return false;
        }
        if (data.HasSwingArm) {
            return false;
        }
        if (data.HasEndGun) {
            return false;
        }
        if (data.PsiAtTopOfInlet > 25) {
            return false;
        }
    } else {
    }
    return true;
}

export const PressureRestartOK = (testCheckedState: boolean, data: PressureRestartOKDto): boolean => {
    if (testCheckedState) {
        if (!(data.HasLowPressureSafety || data.HasPressureTransducer)) {
            return false;
        }
        if (data.HasLowPressureSafety) {
            switch (data.PanelType) {
                case PanelModels.RPMBasic:
                    return false;
                    break;
            }
        }
        if (data.HasGenerator) {
            return false;
        }
    } else {
        if (data.HasGenerator) {
            return true;
        }
        if ((data.HasLowPressureSafety || data.HasPressureTransducer) && [PanelModels.RPMPreferred, PanelModels.RPMAdvancedPlus, PanelModels.RPMConnect].indexOf(data.PanelType) !== -1) {
            return false;
        }
    }
    return true;
}

export const FrostControlOK = (testCheckedState: boolean, data: FrostControlOKDto): boolean => {
    if (testCheckedState) {
        if (data.PanelModel !== PanelModels.RPMStandard) {
            return false;
        }
        if (data.SystemType === SystemTypes.HoseFeedMaxigator && [HoseFeedTypes.A100].indexOf(data.HoseFeedType) !== -1) {
            return false;
        }
    } else {
    }
    return true;
}

export const TemperatureProbeOK = (testCheckedState: boolean, data: TemperatureProbeOKDto): boolean => {
    if (testCheckedState) {
        if (data.HasFrostControl) {
            return false;
        }
        if ([PanelModels.RPMBasic, PanelModels.RPMStandard].indexOf(data.PanelModel) !== -1) {
            return false;
        }
    } else {
    }
    return true;
}

export const PanelULOK = (testCheckedState: boolean, data: PanelULOKDto): boolean => {
    if (testCheckedState) {
        if (data.IsInternational) {
            return false;
        }
    } else {
    }
    return true;
}

export const TowerULOK = (testCheckedState: boolean, data: TowerULOKDto): boolean => {
    if (testCheckedState) {
        if (data.Country !== Countries.UnitedStates) {
            return false;
        }
    } else {
        if (data.Is230V && data.Country === Countries.UnitedStates) {
            return false;
        }
    }
    return true;
}

export const LargeServiceWireKitOK = (testCheckedState: boolean, data: LargeServiceWireKitOKDto): boolean => {
    if (testCheckedState) {
        if (data.PanelModel === PanelModels.RPMBasic) {
            return false;
        }
        if ([SystemTypes.CanalFeedMaxigator, SystemTypes.HoseFeedMaxigator].indexOf(data.SystemType) !== -1) {
            return false;
        }
    } else {
    }
    return true;
}




export class PanelModelOKDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public IsInternational: boolean;
}


// Rules implemented in page instead
/*export class ElectricalFrequencyOKDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public Is230V: boolean;
    public HasGenerator: boolean;
}*/

export class ElectricalFrequency2OKDto {
    public ElectricalFrequency: ElectricalFrequencies;
}

export class EndTowerPositioningOKDto {
    public SystemType: SystemTypes;
    public PanelModel: PanelModels;
}

export class CollectorReelOKDto {
    public EndTowerPositioningType: EndTowerPositioningTypes;
    public PanelModel: PanelModels;
    public SystemType: SystemTypes;
    // public HoseFeedType: HoseFeedTypes;
    public PivotPercent: number;
    public PivotingLateral: boolean;
    public PivotAutoReverse: boolean;
}

export class FlowmeterModelOKDto {
    public SystemType: SystemTypes;
    public BaseInletSize: BaseInletSizes;
}

export class FlowmeterOKDto {
    public SystemType: SystemTypes;
    public FlowmeterModel: FlowmeterModels;
    public HasPivotRiserPipeOption: boolean;
    public VerticalFlowTubeKitAllowed: boolean;
}

export class FlowmeterPulseOKDto {
    public PanelModel: PanelModels;
    public FlowmeterModel: FlowmeterModels;
}

export class SwitchMountOKDto {
    public SystemType: SystemTypes;
    public PanelModel: PanelModels;
    public EndTowerPositioningType: EndTowerPositioningTypes;
    public CenterPivotType: CenterPivotTypes;
    public CollectorRingType: CollectorRingTypes;
}

export class PivotAutoReverseOKDto {
    public SystemType: SystemTypes;
    public SwitchMountType: SwitchMountTypes;
    public CollectorReelType: CollectorRingTypes;
    public EndTowerPositioningType: EndTowerPositioningTypes;
    public PanelModel: PanelModels;
}

export class CartAutoReverseOKDto {
    public SystemType: SystemTypes;
    public PanelModel: PanelModels;
    public InletType: InletTypes;
}

// Rules implemented in page instead
/*export class PowerAutoRestartOKDto {
    public PanelModel: PanelModels;
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
}*/

export class ChemAuxControlOKDto {
    public PanelModel: PanelModels;
}

export class InjectorPumpOKDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public HasChemAuxControl: boolean;
    public InjectorPumpReceptacle20A: boolean;
    public InjectorPumpReceptacle30A: boolean;
}

export class InjectorPumpReceptacleXAmpOKDto {
    public PanelModel: PanelModels;
    public HasChemAuxControl: boolean;
    public Is230V: boolean;
    public InjectorPumpReceptacle20A: boolean;
    public InjectorPumpReceptacle30A: boolean;
}

export class PressureSwitchOKDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public PanelModel: PanelModels;
    public HasOtherLPSwitch: boolean;
    public HasUltraLowPressureSwitch: boolean;
    public HasPressureTransducer: boolean;
}

export class PressureTransducerOKDto {
    public PanelModel: PanelModels;
    public PressureSwitch: boolean;
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public EndTowerPosistionOption: EndTowerPositioningTypes;
    public PivotingLateral: boolean;
    public LateralOnly: boolean;
}

export class PressureTransducer0to25PSIOkDto {
    public HasSwingArm: boolean;
    public HasEndGun: boolean;
    public PsiAtTopOfInlet: number;
    public PanelModel: PanelModels;
    public SystemType: SystemTypes;
    public PressureTransducerOption: PressureTransducerOptions;
}

export class PressureRestartOKDto {
    public HasLowPressureSafety: boolean;
    public HasPressureTransducer: boolean;
    public PanelType: PanelModels;
    public HasGenerator: boolean;
}

export class LightningArrestorOKDto {
    public PanelModel: PanelModels;
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    // public Is230Volt: boolean;
}

// Rules implemented in page instead
/*export class MCPSpanCableDisconnectOkDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
}*/

export class RemotePanelOKDto {
    public SystemType: SystemTypes;
    public RemoteControlPanel: boolean;
    public RemoteControlPanelLessMount: boolean;
}

export class GenStartOKDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
}

export class GFIOKDto {
    public Is230V: boolean;
    public IsInternational: boolean;
}

export class GroundingBarOKDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    // public IsInternational: boolean; 
}

export class NonFuseCarrierOkDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
}

export class HorizontalFlowtubeWithVanesOKDto {
    public FlowmeterModel: FlowmeterModels;
    public FlowmeterLocation: FlowmeterTypes;
    public IsFlowtubeKitAllowed: boolean;
}

export class EndGunShutoffOverrideOKDto {
    public PanelModel: PanelModels;
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
}

export class HorizontalFlowtubeWithFS100OKDto {
    public FlowmeterLocation: FlowmeterTypes;
    public IsFlowtubeKitAllowed: boolean;
    public FlowmeterModel: FlowmeterModels;
    public IsFlowtubeWithVanesPresent: boolean;
}

export class LowVoltageSafetyOKDto {
    public Is230V: boolean;
    public PanelModel: PanelModels;
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public IsInternational: boolean;
}

export class PACTimerOKDto {
    public PanelModel: PanelModels;
}

export class PivotAutoStopOKDto {
    public SystemType: SystemTypes;
    public SwitchMount: SwitchMountTypes;
    public CollectorReel: CollectorRingTypes;
    public EndTowerPositioning: EndTowerPositioningTypes;
    public PanelType: PanelModels;
}

export class RainGaugeOKDto {
    public PanelModel: PanelModels;
}

export class WindGaugeOKDto {
    public PanelModel: PanelModels;
    public HasRainGauge: boolean;
}

export class FrostControlOKDto {
    public PanelModel: PanelModels;
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
}

export class TemperatureProbeOKDto {
    public PanelModel: PanelModels;
    public HasFrostControl: boolean;
}

export class PanelULOKDto {
    public IsInternational: boolean;
}

export class TowerULOKDto {
    public Is230V: boolean;
    public Country: Countries;
}

export class LargeServiceWireKitOKDto {
    public PanelModel: PanelModels;
    public SystemType: SystemTypes;
}