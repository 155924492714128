import * as spanf from "roedata/roe_migration/SpanFunctions";
import { SpanLengthItem } from "../../components/OptimizeSystemDialog/RestrictSpanLengthsControl";
import { isEndboomOk } from "../../helpers/validation/endboom";
import { isSpanExtensionsPosibleByLength } from "../../helpers/validation/spanExtensions";
import { bestFillLengthFromSelection } from "./spanCombinationsCache";

export const sum = (arr: number[]) => {
    let count = 0;
    arr.forEach(x => count += x);
    return count;
}
export const sumOptimizedSpans = (args: {
    spans: IFillSystemSpan[];
    endBoom?: number;
}) => {
    let count = args.endBoom || 0;
    args.spans.forEach(x => count += x.spanAndExtensionLength);
    return count;
}


export interface IAvoidWheelRegion { from: number, to: number };

interface IEndBoomCompatibilityItem { endBoomLength: number, allowableLastSpans: SpanLengthItem[] };
export const fillSystemWithEndBoom = (
    avoidWheelRegions: IAvoidWheelRegion[], allowableSpanLengths: SpanLengthItem[], systemTo: number, allowableEndBoomLengths: number[],
    isHoseFeed: boolean, startDistance: number = 0, currentNumberOfSpans: number
) => {
    if (!allowableEndBoomLengths.length) {
        return {
            spans: fillSystem(avoidWheelRegions, allowableSpanLengths, systemTo, startDistance, true, isHoseFeed, currentNumberOfSpans)
        }
    }
    else {
        let bestSpans: IFillSystemSpan[] = [];
        let bestLength = 0;
        let bestEndBoom: number | undefined = undefined;

        const endBoomLengthCompatibility: IEndBoomCompatibilityItem[] = [];
        for (const endBoomLength of allowableEndBoomLengths) {
            const crntEntry: IEndBoomCompatibilityItem = {
                endBoomLength,
                allowableLastSpans: []
            }
            for (const lastSpanLength of allowableSpanLengths) {
                if (isEndboomOk(endBoomLength, { SpanLength: lastSpanLength.spanLength, IsSpanExt: lastSpanLength.extension })) {
                    crntEntry.allowableLastSpans.push(lastSpanLength);
                }
            }
            if (crntEntry.allowableLastSpans.length) {
                endBoomLengthCompatibility.push(crntEntry);
            }
        }

        for (const endBoomLengthEntry of endBoomLengthCompatibility) {
            const effectiveEndBoomLength = endBoomLengthEntry.endBoomLength + spanf.additionalSpanLength(endBoomLengthEntry.endBoomLength);
            if (endBoomLengthEntry.allowableLastSpans.length === allowableSpanLengths.length) {
                // all spans are compatible
                const result = fillSystem(
                    avoidWheelRegions, 
                    allowableSpanLengths, 
                    systemTo - effectiveEndBoomLength, 
                    startDistance, 
                    false, 
                    isHoseFeed,
                    currentNumberOfSpans
                );
                const length = sum(result.map(x => x.spanAndExtensionLength)) + effectiveEndBoomLength;
                if (length > bestLength) {
                    bestSpans = result;
                    bestLength = length;
                    bestEndBoom = endBoomLengthEntry.endBoomLength;
                }
            }
            else {
                // only some spans are compatible
                for (const lastSpanLength of endBoomLengthEntry.allowableLastSpans) {
                    const result1 = fillSystem(
                        avoidWheelRegions, 
                        allowableSpanLengths, 
                        systemTo - lastSpanLength.totalLength - effectiveEndBoomLength, 
                        startDistance, 
                        false, 
                        isHoseFeed,
                        currentNumberOfSpans
                    );
                    const length1 = sum(result1.map(x => x.spanAndExtensionLength));
                    const result2 = fillSystem(
                        avoidWheelRegions, 
                        [ lastSpanLength ], 
                        systemTo - effectiveEndBoomLength, 
                        startDistance + length1, 
                        false, 
                        isHoseFeed,
                        currentNumberOfSpans + result1.length
                    );
                    const length2 = length1 + sum(result2.map(x => x.spanAndExtensionLength)) + effectiveEndBoomLength;
                    if (length2 > bestLength) {
                        bestSpans = [ ...result1, ...result2 ];
                        bestLength = length;
                        bestEndBoom = endBoomLengthEntry.endBoomLength;
                    }
                }
            }
        }

        // console.log("Best", bestSpans, bestEndBoom);
        return {
            spans: bestSpans,
            endBoom: bestEndBoom
        };
    }
}

export interface IFillSystemSpan { spanLength: number, spanExtension: boolean, spanAndExtensionLength: number };

 // The start distance parameter allows us to account for the 2foot center pivot offset
export const fillSystem = (
    avoidWheelRegions: IAvoidWheelRegion[], 
    allowableSpanLengths: SpanLengthItem[], 
    systemTo: number, 
    startDistance: number, 
    hasLastSpan1ft: boolean,
    isHoseFeed: boolean,
    currentNumberOfSpans: number
): IFillSystemSpan[] => {
    const setOfAllowableFirstSpanLengths = new Set<number>();
    const setOfAllowableNonFirstSpanLengths = new Set<number>();
    for (const spanLength of allowableSpanLengths) {
        setOfAllowableNonFirstSpanLengths.add(spanLength.totalLength);
        if (!spanLength.extension || isSpanExtensionsPosibleByLength(spanLength.spanLength, isHoseFeed, 1)) {
            setOfAllowableFirstSpanLengths.add(spanLength.totalLength);
        }
    }

    const sortedAllowableFirstSpanLengths = [ ...setOfAllowableFirstSpanLengths ].sort((a,b) => b - a);
    const sortedAllowableNonFirstSpanLengths = [ ...setOfAllowableNonFirstSpanLengths ].sort((a,b) => b - a);
    const arraysDiffer = sortedAllowableFirstSpanLengths.join(",") !== sortedAllowableNonFirstSpanLengths.join(",");
    let spans: number[] = [];
    if (arraysDiffer) {
        for (const firstSpan of sortedAllowableFirstSpanLengths) {
            if (firstSpan + startDistance <= (systemTo - (hasLastSpan1ft ? 1 : 0))) {
                const crntSpans = fillSystem2(avoidWheelRegions, sortedAllowableNonFirstSpanLengths, systemTo, startDistance + firstSpan, hasLastSpan1ft, currentNumberOfSpans + 1 + spans.length);
                if (sum(spans) < firstSpan + sum(crntSpans)) {
                    spans = [ firstSpan, ...crntSpans ];
                }
            }
        }
    }
    else {
        spans = fillSystem2(avoidWheelRegions, sortedAllowableNonFirstSpanLengths, systemTo, startDistance, hasLastSpan1ft, currentNumberOfSpans + spans.length);
    }

    return spans.map(s => {
        const spanAndExtensionLength = s;
        const spanExtension = allowableSpanLengths.find(t => t.totalLength === s).extension;
        const spanLength = spanExtension ? s - 4 : s;
        return {
            spanLength, spanExtension, spanAndExtensionLength
        }
    })
}


 // The start distance parameter allows us to account for the 2foot center pivot offset
 const fillSystem2 = (
    avoidWheelRegions: IAvoidWheelRegion[], 
    allowableSpanLengths: number[], 
    systemTo: number, 
    startDistance: number, 
    hasLastSpan1ft: boolean,
    currentNumberOfSpans: number
) => {
    const sortedAllowableSpanLengths = [...allowableSpanLengths];
    sortedAllowableSpanLengths.sort((a,b) => b - a);
    const maxAllowableSpan = sortedAllowableSpanLengths[0]

    
    const avoidWheelRegionsCopy = [...avoidWheelRegions];
    // NOTE: The spanf, LengthsInFeet function adds 1 foot to the last span in a non-endboom system
    let systemToCopy = systemTo - (hasLastSpan1ft ? 1 : 0);

    avoidWheelRegionsCopy.sort((a,b) => a.from - b.from);
    let i = 1;
    while (i < avoidWheelRegionsCopy.length) {
        const prev = avoidWheelRegionsCopy[i-1];
        const crnt = avoidWheelRegionsCopy[i];
        if (crnt.from >= prev.from && crnt.from <= prev.to) {
            prev.to = Math.max(prev.to, crnt.to);
            avoidWheelRegionsCopy.splice(i, 1);
        }
        else {
            i++;
        }
    }

    // remove/modify wheel obstacles past the systemTo length:
    let j = 0;
    while (j < avoidWheelRegionsCopy.length) {
        const crntRegion = avoidWheelRegionsCopy[j];
        if (crntRegion.from >= systemToCopy) {
            avoidWheelRegionsCopy.splice(j)
        }
        else if (crntRegion.to > systemToCopy) {
            systemToCopy = crntRegion.from - (hasLastSpan1ft ? 1 : 0);
            avoidWheelRegionsCopy.splice(j)
        }
        else {
            j++;
        }
    }

    // now begin filling the systemTo length with spans:
    let spans: number[] = [];
    let currentTo = startDistance;
    let undoArgs: {
        spans: number[],
        currentTo: number,
        arg1: number,
    };
    for (let iVoidRegion = 0; iVoidRegion < avoidWheelRegionsCopy.length; iVoidRegion++) {
        const voidRegion = avoidWheelRegionsCopy[iVoidRegion];
        if (voidRegion.from < currentTo) continue;
        const crntSpans = bestFillLengthFromSelection(voidRegion.from - currentTo, sortedAllowableSpanLengths, currentNumberOfSpans + spans.length);
        if (crntSpans.length === 0) {
            // then we could not fill this section, try to span over it:
        }
        else {
            undoArgs = {
                spans: [ ...spans],
                currentTo: currentTo,
                arg1: voidRegion.from - currentTo, 
            }
            spans.push(...crntSpans);        
            currentTo += sum(crntSpans);
        }

        const voidLength = Math.min(voidRegion.to - currentTo, systemToCopy - currentTo);
        if (voidLength > maxAllowableSpan) {
            // can't span this void
            if (hasLastSpan1ft && undoArgs) {
                // if the last spans added were calculated without the 1ft last span value, so remove and recalculate here:
                spans = undoArgs.spans;        
                currentTo = undoArgs.currentTo
                const nextCrntSpans = bestFillLengthFromSelection(undoArgs.arg1 - 1, sortedAllowableSpanLengths, currentNumberOfSpans + spans.length);
                spans.push(...nextCrntSpans);        
                currentTo += sum(nextCrntSpans);
            }
            return spans;
        }
        
        let i = 0;
        let foundValid = false;
        const isValidSpan = () => {
            if (i === sortedAllowableSpanLengths.length) return false;
            if (sortedAllowableSpanLengths[i] < voidLength) return false;
            if (systemToCopy < currentTo + sortedAllowableSpanLengths[i]) {
                return false;
            }
            if (
                avoidWheelRegionsCopy.slice(iVoidRegion + 1).some(vr => {
                    return (
                        (vr.from < currentTo + sortedAllowableSpanLengths[i]) &&
                        (vr.to > currentTo  + sortedAllowableSpanLengths[i])
                    );
                })
            ) {
                return false;
            }
            return true;
        }
        do {
            foundValid = isValidSpan();
            if (!foundValid) {
                i++;
            }
        } while (!foundValid && i < sortedAllowableSpanLengths.length);

        if (!foundValid) {
            if (hasLastSpan1ft && undoArgs) {
                // if the last spans added were calculated without the 1ft last span value, so remove and recalculate here:
                spans = undoArgs.spans;        
                currentTo = undoArgs.currentTo
                const nextCrntSpans = bestFillLengthFromSelection(undoArgs.arg1 - 1, sortedAllowableSpanLengths, currentNumberOfSpans + spans.length);
                spans.push(...nextCrntSpans);        
                currentTo += sum(nextCrntSpans);
            }
            return spans;
        }
        // Note: This might occasionaly be the last span, and therefor have 1 ft
        undoArgs = undefined;
        spans.push(sortedAllowableSpanLengths[i]);
        currentTo += sortedAllowableSpanLengths[i];
    }
    
    const crntSpans = bestFillLengthFromSelection(systemToCopy - currentTo - (hasLastSpan1ft ? 1 : 0), sortedAllowableSpanLengths, currentNumberOfSpans + spans.length);
    if (crntSpans.length) {
        spans.push(...crntSpans);
    }
    else {
        if (hasLastSpan1ft && undoArgs) {
            // if the last spans added were calculated without the 1ft last span value, so remove and recalculate here:
            spans = undoArgs.spans;        
            currentTo = undoArgs.currentTo
            const nextCrntSpans = bestFillLengthFromSelection(undoArgs.arg1 - 1, sortedAllowableSpanLengths, currentNumberOfSpans + spans.length);
            spans.push(...nextCrntSpans);        
            currentTo += sum(nextCrntSpans);
        }
    }
    return spans
}
