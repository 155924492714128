import * as React from "react";
import { FC } from "react";
import { SYSTEM_DESIGN_CONSTANTS } from "./constants";

interface IProps {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    xc: number;
    yc: number;
}

const SpanSupport: FC<IProps> = ({ x1, y1, x2, y2, xc, yc }) => {
    const pipeWidth = SYSTEM_DESIGN_CONSTANTS.pipe.width;

    return (
        <>
            <path 
                d={`
                    M ${xc} ${yc} 
                    L ${x1} ${y1}
                `} 
                stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
                strokeWidth={pipeWidth} />
            <path 
                d={`
                    M ${xc} ${yc} 
                    L ${x2} ${y2}
                `} 
                stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
                strokeWidth={pipeWidth} />
        </>
    );
};

export default SpanSupport;