import { LineShape } from "../Core/LineShape";
import { MultilineShape } from "../Core/MultilineShape";
import { Vertex } from "../Core/Vertex";
import { Vector2 } from "../Numerics/Vector2";
import { EndGunDataContainer } from "../Objects/EndGunDataContainer";
import { Parallel } from "../Tasks/Parallel";
import { Interlocked } from "../Threading/Interlocked";
import { SACCentrePivotType } from "./SACCentrePivotType";
import { SACOptimizationProblem } from "./SACOptimizationProblem";

import { jsts } from "../jstsLib";

export class SACOptimizationSolution {
    public get CoverageArea(): number {
        if (this.CoverageShape === null) throw new Error("CoverageShape has not been initialized");
        return this.CoverageShape.getArea();
    }
    public CoverageShape: jsts.geom.Geometry | null = null;
    public CoverageShapePartialOutside: Vertex[] | null = null;
    public CoverageShapePartialInside: Vertex[] | null = null;
    public EndBoomTrack: MultilineShape | null = null;
    public GuidanceWheelTrack: MultilineShape | null = null;
    public NonGuidanceWheelTrack: MultilineShape | null = null;
    public problem: SACOptimizationProblem | null = null;

    public endGuns: EndGunDataContainer[] = [];

    public get Problem() {
        if (this.problem === null) throw new Error("Porblem has not been initialized");
        return this.problem;
    }

    public static FromAlphas(
        problem: SACOptimizationProblem,
        iPivotAngleMin: number, iPivotAngleMax: number,
        alphas: number[]
        ): SACOptimizationSolution
    {
        const sln = new SACOptimizationSolution();
        sln.EndBoomTrack = SACOptimizationSolution.GetEndBoomTrack(problem, iPivotAngleMin, iPivotAngleMax, alphas),
        sln.GuidanceWheelTrack = SACOptimizationSolution.GetGuidanceWheelTrack(problem, iPivotAngleMin, iPivotAngleMax, alphas),
        sln.NonGuidanceWheelTrack = SACOptimizationSolution.GetNonGuidanceWheelTrack(problem, iPivotAngleMin, iPivotAngleMax, alphas),
        sln.problem = problem;

        if (problem.CentrePivotType === SACCentrePivotType.Full) {
            const outside = SACOptimizationSolution.GetCoverageOutside(problem, iPivotAngleMin, iPivotAngleMax, alphas);
            sln.CoverageShape = SACOptimizationSolution.GetFullCoverageShape(
                outside,
                problem);
        } 
        else {

            sln.CoverageShapePartialOutside = SACOptimizationSolution.GetCoverageOutside(problem, iPivotAngleMin, iPivotAngleMax, alphas);
            sln.CoverageShapePartialInside = SACOptimizationSolution.GetPartialCoverageInside(problem);
            sln.CoverageShape = SACOptimizationSolution.GetPartialCoverageShape(
                sln.CoverageShapePartialOutside,
                sln.CoverageShapePartialInside);
        }

        return sln;
    }

    public static GetPartialCoverageShape(
        outside: Vertex[],
        inside: Vertex[]): jsts.geom.MultiPolygon
    {
        
        const coordinates: jsts.geom.Coordinate[] = [];
        for (const p of outside) {
            coordinates.push(new jsts.geom.Coordinate(p.X, p.Y));
        }
        for (const p of inside) {
            coordinates.push(new jsts.geom.Coordinate(p.X, p.Y));
        }
        coordinates.push(coordinates[0]);

        const geomFactory = new jsts.geom.GeometryFactory();
        const polygon = geomFactory.createPolygon(coordinates);
        return geomFactory.createMultiPolygon([ polygon ]);
    }

    public static GetFullCoverageShape(
        outside: Vertex[],
        problem: SACOptimizationProblem): jsts.geom.Geometry | null
    {        
        const coordinates: jsts.geom.Coordinate[] = [];
        for (const p of outside) {
            coordinates.push(new jsts.geom.Coordinate(p.X, p.Y));
        }
        coordinates.push(coordinates[0]);

        const geomFactory = new jsts.geom.GeometryFactory();
        const polygon = geomFactory.createPolygon(coordinates);
        return geomFactory.createMultiPolygon([ polygon ]).difference(problem.ParentSystemPolygon);
    }


    public static GetPartialCoverageInside(Problem: SACOptimizationProblem): Vertex[] {
        const points: Vertex[] = [];
        for (let i = 0; i <= 360; i++) {
            const theta1 = Problem.PartialPivotAngleDegreesMin;
            let theta2 = Problem.PartialPivotAngleDegreesMax;
            if (theta1 > theta2) {
                theta2 += 360;
            }
            const theta = (theta2 - (i / 360.0) * (theta2 - theta1)) * Math.PI / 180.0;
            points.push(SACOptimizationSolution.GetPoint(Problem, theta, Problem.Model.Configuration.PivotSpanLengthMetres));
        }
        return points;
    }

    public static GetCoverageOutside(
        Problem: SACOptimizationProblem,
        iPivotAngleMin: number, iPivotAngleMax: number,
        alphas: number[]): Vertex[]
    {
        const ranges = new Array<number>(Problem.PivotAngleStepCount).fill(0);
        // const rangeLocks = Enumerable.Range(0, Problem.PivotAngleStepCount).Select(i => new object()).ToArray();

        let iRangeMin: number;
        let iRangeMax: number;

        if (Problem.CentrePivotType === SACCentrePivotType.Partial) {
            // TODO: There is a bug here (also apparent in RDP2). If the pivot start < pivot end, but the end boom
            // end > end boom start, then wrong poly produced.
            const eeb0 = Problem.Model.EndOfEndBoom(Problem.GetTheta(iPivotAngleMin), alphas[0]);
            const tan0 = (Math.atan2(eeb0.X, eeb0.Y) + 2 * Math.PI) % (2 * Math.PI);
            const eeb1 = Problem.Model.EndOfEndBoom(Problem.GetTheta(iPivotAngleMax), alphas[iPivotAngleMax - iPivotAngleMin]);
            const tan1 = (Math.atan2(eeb1.X, eeb1.Y) + 2 * Math.PI) % (2 * Math.PI);

            iRangeMin = Math.ceil(Problem.PivotAngleStepCount * tan0 / (2 * Math.PI)) % Problem.PivotAngleStepCount;
            iRangeMax = Math.floor(Problem.PivotAngleStepCount * tan1 / (2 * Math.PI));
            if (iRangeMax < iRangeMin) {
                iRangeMax += Problem.PivotAngleStepCount;
            }
        }
        else {
            iRangeMin = 0;
            iRangeMax = Problem.PivotAngleStepCount - 1;
        }

        Parallel.For(iPivotAngleMin, iPivotAngleMax + 1, iPivotAngle =>
        {
            Interlocked.CompareExchange([ ranges[iPivotAngle % Problem.PivotAngleStepCount] ],
                Problem.Model.Configuration.PivotSpanLengthMetres, 0);

            const alpha = alphas[iPivotAngle - iPivotAngleMin];

            for (let i = 0; true; i += (alpha < Math.PI ? -1 : 1)) {
                const dtheta = -i * Problem.PivotAngleSpacingRadians;

                const iRange = (iPivotAngle + i + Problem.PivotAngleStepCount) % Problem.PivotAngleStepCount;

                // Sine rule
                const y = Problem.Model.Configuration.PivotSpanLengthMetres * Math.sin(dtheta) / Math.sin(alpha + dtheta);
                if (y > Problem.Model.Configuration.SwingSpanLengthMetres + Problem.Model.Configuration.EndBoomLengthMetres) {
                    break;
                }

                const x = Problem.Model.Configuration.PivotSpanLengthMetres * Math.sin(alpha) / Math.sin(alpha + dtheta);
                if (x > ranges[iRange]) {
                    // lock (rangeLocks[iRange])
                    {
                        if (x > ranges[iRange])
                        {
                            ranges[iRange] = x;
                        }
                    }
                }
            }

            if (iPivotAngle !== iPivotAngleMin || Problem.CentrePivotType === SACCentrePivotType.Full) {
                const iPivotAngle0 = iPivotAngle === iPivotAngleMin ? iPivotAngleMax : iPivotAngle - 1;
                const iPivotAngle1 = iPivotAngle;

                const theta0 = Problem.GetTheta(iPivotAngle0);
                const alpha0 = alphas[iPivotAngle0 - iPivotAngleMin];
                const eeb0 = Problem.Model.r(theta0, alpha0).add(Problem.Model.b(theta0, alpha0));
                const tan0 = (Math.atan2(eeb0.X, eeb0.Y) + 2 * Math.PI) % (2 * Math.PI);
                const len0 = Math.sqrt(eeb0.X * eeb0.X + eeb0.Y * eeb0.Y);
                const iRange0 = Problem.PivotAngleStepCount * tan0 / (2 * Math.PI);

                const theta1 = Problem.GetTheta(iPivotAngle1);
                const alpha1 = alphas[iPivotAngle1 - iPivotAngleMin];
                const eeb1 = Problem.Model.r(theta1, alpha1).add(Problem.Model.b(theta1, alpha1));
                const tan1 = (Math.atan2(eeb1.X, eeb1.Y) + 2 * Math.PI) % (2 * Math.PI);
                const len1 = Math.sqrt(eeb1.X * eeb1.X + eeb1.Y * eeb1.Y);
                const iRange1 = Problem.PivotAngleStepCount * tan1 / (2 * Math.PI);

                let lena: number;
                let lenb: number;
                let iRangeA: number;
                let iRangeB: number;
                if (tan0 < tan1 && tan1 - tan0 < Math.PI) {
                    lena = len0;
                    lenb = len1;
                    iRangeA = iRange0;
                    iRangeB = iRange1;
                }
                else if (tan0 < tan1 && tan1 - tan0 >= Math.PI) {
                    lena = len1;
                    lenb = len0;
                    iRangeA = iRange1;
                    iRangeB = iRange0 + Problem.PivotAngleStepCount;
                }
                else if (tan1 <= tan0 && tan0 - tan1 < Math.PI) {
                    lena = len1;
                    lenb = len0;
                    iRangeA = iRange1;
                    iRangeB = iRange0;
                }
                else {
                    lena = len0;
                    lenb = len1;
                    iRangeA = iRange0;
                    iRangeB = iRange1 + Problem.PivotAngleStepCount;
                }

                for (let iRange = Math.ceil(iRangeA); iRange <= Math.floor(iRangeB); iRange++) {
                    const len = lena + (lenb - lena) * (iRange - iRangeA) / (iRangeB - iRangeA);

                    if (len > ranges[iRange % Problem.PivotAngleStepCount]) {
                        // lock (rangeLocks[iRange % Problem.PivotAngleStepCount])
                        {
                            if (len > ranges[iRange % Problem.PivotAngleStepCount])
                            {
                                ranges[iRange % Problem.PivotAngleStepCount] = len;
                            }
                        }
                    }
                }
            }
        });

        const points: Vertex[] = [];
        for (let iRange = iRangeMin; iRange <= iRangeMax; iRange++) {
            const theta = Problem.GetTheta(iRange);
            points.push(SACOptimizationSolution.GetPoint(Problem, theta, ranges[iRange % Problem.PivotAngleStepCount]));
        }

        return points;
    }

    public static GetPoint(problem: SACOptimizationProblem, theta: number, r: number): Vertex {
        return new Vertex(
            problem.PivotCentre.x + r * Math.sin(theta),
            problem.PivotCentre.y + r * Math.cos(theta)
            );
    }

    public static GetEndBoomTrack(
        Problem: SACOptimizationProblem,
        iPivotAngleMin: number, iPivotAngleMax: number,
        alphas: number[]): MultilineShape
    {
        const track =  SACOptimizationSolution.GetTrack((theta, alpha) => Problem.Model.r(theta, alpha).add(Problem.Model.b(theta, alpha)),
            Problem, iPivotAngleMin, iPivotAngleMax, alphas);
        const lineShape = new LineShape(track);
        return new MultilineShape([ lineShape ]);
    }

    public static GetGuidanceWheelTrack(
        Problem: SACOptimizationProblem,
        iPivotAngleMin: number, iPivotAngleMax: number,
        alphas: number[]): MultilineShape
    {
        const track =  SACOptimizationSolution.GetTrack((theta, alpha) => Problem.Model.r(theta, alpha).add(Problem.Model.w(theta, alpha)),
            Problem, iPivotAngleMin, iPivotAngleMax, alphas);
        const lineShape = new LineShape(track);
        return new MultilineShape([ lineShape ]);
    }

    public static GetNonGuidanceWheelTrack(
        Problem: SACOptimizationProblem,
        iPivotAngleMin: number, iPivotAngleMax: number,
        alphas: number[]): MultilineShape
    {
        const track =  SACOptimizationSolution.GetTrack((theta, alpha) => Problem.Model.r(theta, alpha).subtract(Problem.Model.w(theta, alpha)),
            Problem, iPivotAngleMin, iPivotAngleMax, alphas);
        const lineShape = new LineShape(track);
        return new MultilineShape([ lineShape ]);
    }

    static GetTrack(f: (arg1: number, arg2: number) => Vector2,
        Problem: SACOptimizationProblem,
        iPivotAngleMin: number, iPivotAngleMax: number,
        alphas: number[]) /*IEnumerable<Vertex>*/ : Vertex[]
    {
        const pointsArr = new Array<Vertex>(iPivotAngleMax - iPivotAngleMin + 1);
        Parallel.For(iPivotAngleMin, iPivotAngleMax + 1, iPivotAngle =>
        {
            let theta = Problem.GetTheta(iPivotAngle);
            const alpha = alphas[iPivotAngle - iPivotAngleMin];
            if (Problem.CentrePivotType === SACCentrePivotType.Partial) {
                if (iPivotAngle === iPivotAngleMin) {
                    theta = Problem.PartialPivotAngleDegreesMin * Math.PI / 180.0;
                }

                if (iPivotAngle === iPivotAngleMax) {
                    theta = Problem.PartialPivotAngleDegreesMax * Math.PI / 180.0;
                }
            }

            let v = f(theta, alpha);
            pointsArr[iPivotAngle-iPivotAngleMin] = new Vertex(Problem.PivotCentre.x + v.X, Problem.PivotCentre.y + v.Y);
        });
        return pointsArr;
    }

    // public GeneratePath(systemId: string, pivotCentre: PointShape): void {
    //     // FastResampleWheelTrack expects individual line segments
    //     var splitTrack = new MultilineShape();
    //     if (this.GuidanceWheelTrack === null) throw new Error("GuidanceWheelTrack not initialized");
        
    //     for (const line of this.GuidanceWheelTrack.Lines) {
    //         for (let i = 0; i < line.Vertices.length - 1; i++) {
    //             splitTrack.Lines.push(new LineShape([ line.Vertices[i], line.Vertices[i + 1] ]));
    //         }
    //     }

    //     const pathData: PathData[] | null= PathUtilities.FastResampleWheelTrack(
    //         pivotCentre, 1000, splitTrack, systemId);
    //     if (pathData !== null) {
    //         PathUtilities.CreatePathArchive(pathData, systemId);
    //     }
    // }

    public GeneratePathKml(systemId: string): void {
        // PathUtilities.CreateKmlPathArchive(GuidanceWheelTrack, systemId);
    }

    public static Union(solutions: SACOptimizationSolution[]): SACOptimizationSolution {
        const r = new SACOptimizationSolution();
        r.CoverageShape = SACOptimizationSolution.GetPartialCoverageShape(
            solutions.filter(sln => sln.CoverageShapePartialOutside !== null).flatMap(sln => sln.CoverageShapePartialOutside!),
            solutions.reverse().filter(sln => sln.CoverageShapePartialInside !== null).flatMap(sln => sln.CoverageShapePartialInside!)
            ),
        r.EndBoomTrack = new MultilineShape(),
        r.GuidanceWheelTrack = new MultilineShape(),
        r.NonGuidanceWheelTrack = new MultilineShape();

        if (solutions.length !== 0) {
            r.endGuns = solutions[0].endGuns.map(eg => {
                const egdc = new EndGunDataContainer();
                egdc.EndGunAreas = [];
                egdc.CalculatedThrow = eg.CalculatedThrow;
                egdc.CalculatedThrowUnit = eg.CalculatedThrowUnit;
                return egdc;
            })
        }
        else {
            r.endGuns = [];
        }

        for (const s of solutions) {
            if (s.EndBoomTrack === null) throw new Error("EndBoomTrack not initialized");            
            for (const l of s.EndBoomTrack.Lines)
            {
                r.EndBoomTrack.Lines.push(l);
            }
            
            if (s.GuidanceWheelTrack === null) throw new Error("GuidanceWheelTrack not initialized");
            for (const l of s.GuidanceWheelTrack.Lines)
            {
                r.GuidanceWheelTrack.Lines.push(l);
            }
            
            if (s.NonGuidanceWheelTrack === null) throw new Error("NonGuidanceWheelTrack not initialized");
            for (const l of s.NonGuidanceWheelTrack.Lines)
            {
                r.NonGuidanceWheelTrack.Lines.push(l);
            }
            
            if (r.endGuns.length !== s.endGuns.length) throw new Error("Union of end guns cannot occur when number of end guns differ in input solutions");            
            for (let iEndGun = 0; iEndGun < s.endGuns.length; iEndGun++) {
                const eg = s.endGuns[iEndGun];
                for (let iArea = 0; iArea < eg.EndGunAreas.length; iArea++) {
                    const ega = eg.EndGunAreas[iArea];
                    r.endGuns[iEndGun].EndGunAreas.push(ega);
                }
            }
        }

        return r;
    }
}
