import { t } from "i18next";
import { SystemTypes, TireTypes, TowerHeights } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import * as ohf from "../OtherHelpers";
import QuoteClass from "../QuoteClass";
import { TireTypeOK } from "../Valids.Valids";

export default class KwikTowForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        if (this.Quote.System.SystemProperties.SystemType !== SystemTypes.KwikTow) return;

        if (!TireTypeOK(
            this.Quote.System.Circle.KwikTow.Tires.TireType, {
                TowerHeight: TowerHeights.Standard,
                PowerTowerTireSize: TireTypes.Recaps,
                IsSingleSpanMaxigator: this.Quote.IsSingleSpanMaxi(),
                TireSize: this.Quote.System.Circle.KwikTow.Tires.TireSize,
                IsA100: false,
                IsSACTower: false,
                IsInternational: ohf.IsInternational(this.Quote.System.DealerProperties)
        })) {
            this.Quote.Warnings.Add(`Unsupported tire type/size combination for Kwik Tow`);
        }

        const pc = this.Quote.System.Circle.KwikTow.PercentOfCircle;
        if (typeof pc === "undefined" || pc < 1 || pc > 100) {
            this.Quote.Warnings.Add("Kwik Tow percent of circle: " + t("common-phrases.sltPercentOutOfRange"));
        }
    }
}