import * as React from "react";
import { FC } from "react";
import { SYSTEM_DESIGN_CONSTANTS } from "../SystemDiagram/constants";

interface IProps {
    startDistanceInches: number;
    height: number;
}

const EndGun: FC<IProps> = ({ startDistanceInches, height }) => {
    const pipeWidth = SYSTEM_DESIGN_CONSTANTS.pipe.width;

    return (
        <path 
            d={`
                M ${startDistanceInches} ${height}
                l ${15} ${15}
                l ${10} ${0}
            `} 
            stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
            strokeWidth={pipeWidth} 
            fill="none"
            strokeLinecap='round'
        />
    );
};

export default EndGun;