
export default interface ISprinklers {
    BoomBacks: IBoomBack;
    Package: IPackage[];
}

export interface IBoomBack {
    Device?: BoomBackDevices;
    BoomBackType?: BoomBackTypes;
    PipeMaterial?: PipeMaterialTypes;
    Valves?: boolean;
    FirstTower?: number;
    QtyPerTower?: number;
}

export interface ID3000 {
    Plate1: D3000Plates;
    Plate2: D3000Plates;
    Plate3: D3000Plates;
    SprinklerConverter: boolean;
    BubblerClipLEPA: boolean;
}

export enum RegulatorTypes {
    None = "None",
    NelsonBlueLFHF = "NelsonBlueLFHF",
    SenningerLFMF = "SenningerLFMF",
    NelsonBlueSTLF = "NelsonBlueSTLF",
    NelsonBlueSTHF = "NelsonBlueSTHF",
    NelsonBlueFTLF = "NelsonBlueFTLF",
    NelsonBlueFTHF = "NelsonBlueFTHF",
    SenningerLF = "SenningerLF",
    SenningerMF = "SenningerMF",
    SenningerPSR = "SenningerPSR",
    KometKPR = "KometKPR"
}

export interface IRegulator {
    AsNeeded: boolean;
    Pressure: RegulatorPressures;
    RegulatorType: RegulatorTypes;
    Thread: ThreadTypes;
}

export enum ThreadTypes {
    Square = "Square",
    Female = "Female"
}

export enum RegulatorPressures {
    a6 = "a6",
    a10 = "a10",
    a15 = "a15",
    a20 = "a20",
    a25 = "a25",
    a30 = "a30",
    a40 = "a40"
}

export interface IFitting {
    FittingType: FittingTypes;
    DropNextToEndGun: boolean;
}

export enum BoomBackTypes {
    None = "None",
    Standard = "Standard",
    Tower = "Tower"
}

export enum D3000Plates {
    None = "None",
    Tan = "Tan",
    Blue = "Blue",
    Green = "Green",
    Brown = "Brown",
    Orange = "Orange",
    Gray = "Gray",
    Black = "Black",
    Turqoise = "Turqoise",
    Purple = "Purple",
    Red = "Red",
    D4Maroon = "D4Maroon",
    D6Gold = "D6Gold",
}

export enum BoomBackDevices {
    D3000 = "D3000",
    S3000 = "S3000",
    R3000 = "R3000",
    LDN = "LDN",
    Fan = "Fan",
    Komet = "Komet",
    KometTwisterPartCircle = "KometTwisterPartCircle"
}

export interface IPackage {
    PackageNumber: number;
    SecondNozzleGPM: number;
    ThirdNozzleGPM: number;
    D3000: ID3000;
    KometSpray: IKometSpray;
    Device: DeviceTypes;
    Drop: DropTypes;
    Fitting: IFitting;
    HoseDrop: IHoseDrop;
    LDN: ILDN;
    Regulator: IRegulator;
    RigidDrop: IRigidDrop;
    SuperSprayPad: SuperSprayPadTypes;
    TrashBusterBody: boolean;
    Valve: OutletValveTypes;
    NormalSpacing: boolean;
    UseNelson3030: boolean;
    UseNelsonAD3030MT: boolean;
    DevicesDoubled: boolean;
    EndingLocation: number;
    Spacing: number;
    NozzleClip: NozzleClipTypes;
    DropSpacingGradually: boolean;

    userSelectedPlate?: boolean; // SW added 2024.06.10: As plate for the UI is determined from package. A flag has been added to detect when the user self selects the plate
}

export enum RigidDropMaterialTypes {
Galvanized = "Galvanized",
PVC = "PVC",
ComboPVCHose = "ComboPVCHose",
Poly = "Poly",
Combo = "Combo",
IACO = "IACO",
}



    export enum RigidDropBottomFittings {
        Gray = "Gray",
        Black = "Black",
        Galvanized = "Galvanized"
}

    export enum UPipeMaterialTypes {
        Galvanized = "Galvanized",
        PVC = "PVC",
        BlackPlastic = "BlackPlastic",
        Nelson180 = "Nelson180"
}

export enum idxWobbler{
    wob9GBlack = "wob9GBlack",
    wob6GGray = "wob6GGray",
    wob9GBlue = "wob9GBlue",
    wob6GWhite = "wob6GWhite",
}


export enum idxXiWob {
    xiWobBlack = "xiWobBlack",
    xiwobBlue = "xiwobBlue",
    xiwobWhite = "xiwobWhite",
    xiwobGray = "xiwobGray"
}

export enum idxSprinkler {
    // <Obsolete("Sprinkler device is Discontinued Product")>
    _NelsonMediumSpacingBrassImpact = 0,
    // <Obsolete("Sprinkler device is Discontinued Product")>
    _NelsonVariableSpacingBrassImpactS = 1,
    // <Obsolete("Sprinkler device is Discontinued Product")>
    _NelsonVariableSpacingBrassImpactD = 2,
    // <Obsolete("Sprinkler device is Discontinued Product")>
    _NelsonVariableSpacingBrassImpactDFCN = 3,
    // <Obsolete("Sprinkler device is Discontinued Product")>
    _NelsonVariableSpacingPlasticImpactD = 4,
    // <Obsolete("Sprinkler device is Discontinued Product")>
    _NelsonVariableSpacingPlasticImpactDFCN = 5,
    /**
     * Nelson R3000 Rotator
     */
    NelsonR3000Rotator = 6,
    /**
     * Nelson S3000 Spinner
     */
    NelsonS3000Spinner = 7,
    /**
     * Nelson O3000 Orbitor
     */
    NelsonO3000Orbitor = 8,
    /**
     * Nelson A3000 Accelerator
     */
    NelsonA3000Accelerator = 9,
    /**
     * Nelson D3000 Spray
     */
    NelsonD3000Spray = 10,
    sprSenningerConstantSpacingPlasticImpact = 11,
    sprSenningerVariableSpacingPlasticImpactWhite = 12,
    sprSenningerVariableSpacingPlasticImpactRed = 13,
    sprSenningerIWob = 14,
    sprSenningerXiwob = 15,
    sprSenningerSuperSpray = 16,
    sprSenningerLDNSpray = 17,
    // <Obsolete("ROE-475 - Disable Senninger Quad Sprays")>
    sprSenningerQuadSpray = 18,
    sprKometTwisterBlack = 19,
    sprKometTwisterBlue = 20,
    sprKometTwisterYellow = 21,
    sprKometSprayBlue = 22,
    sprKometSprayGrey = 23,
    sprKometSprayBlack = 24,
    sprKometSprayYellow = 25,
    sprKometTwisterWhite = 26,
    sprSenningerXcelWobbler = 27,
    sprKometTwisterPartCircle = 28,
    /**
     * Nelson 3030 OrbitorFX
     */
    NelsonO3030OrbitorFX = 29,
}

export enum RigidDropTypes {
    Fixed = "Fixed",
    Variable = "Variable"
}

export interface IRigidDrop {
    BottomFitting: RigidDropBottomFittings;
    DropMaterial: RigidDropMaterialTypes;
    DropType: RigidDropTypes;
    Length: number;
    ReinforcementClamp: boolean;
    UPipeMaterial: UPipeMaterialTypes;
    DeviceWeight: DeviceWeights;
    GroundClearance: number;
}

export interface IHoseDrop {
    DragAdapter: boolean;
    DragSock: boolean;
    HoseBottomClamp: HoseClamps;
    HoseTopClamp: HoseClamps;
    HoseTopFitting: HoseTopFittings;
    RegulatorLocation: RegulatorLocations;
    ScrewClamp: boolean;
    ReinkeBlue: boolean;
    UseKometTrussRodSlings: boolean;
    SubstituteSTxHB: boolean;
    UPipeReach: UPipeReaches;
    UPipeFitting: UPipeFittings;
    UPipeType: UPipeTypes;
    Weight: HoseDropWeights;
    WeightBottomFitting: WeightBottomFittings;
    WeightTopFitting: WeightTopFittings;
    DeviceWeight: DeviceWeights;
    GroundClearance: number;
}

export enum DeviceTypes {
    /**
     * None - duh
     */
    None = 0,
    /**
     * Nelson 15/18 Medium Spacing Brass Impact
     */
    // <Obsolete("This device is discontinued. Should clean up code.")>
    Nelson1518MediumSpacingBrassImpact = 1,
    /**
     * Nelson R3000 Partial Circle Rotator
     */
    NelsonR3000PartCircle = 2,
    /**
     * Senninger 12 Medium Spacing Plastic Impact
     */
    Senninger12MediumSpacingPlasticImpact = 3,
    /**
     * Nelson 15 Single 3RN Brass Impact
     */
    // <Obsolete("This device is discontinued. Should clean up code.")>
    Nelson15Single3RNBrassImpact = 4,
    /**
     * Nelson 15 Double 3RN Brass Impact
     */
    // <Obsolete("This device is discontinued. Should clean up code.")>
    Nelson15Double3RNBrassImpact = 5,
    /**
     * Nelson 15 Double FCN Brass Impact
     */
    // <Obsolete("This device is discontinued. Should clean up code.")>
    Nelson15DoubleFCNBrassImpact = 6,
    /**
     * Senninger Xi-Wob 610 Blue
     */
    SenningerXiWobBlue = 7,
    /**
     * Nelson 8 Double 3RN Plastic Impact
     */
    // <Obsolete("This device is discontinued. Should clean up code.")>
    Nelson8Double3RNPlasticImpact = 8,
    /**
     * Nelson 8 Double FCN Plastic Impact
     */
    // <Obsolete("This device is discontinued. Should clean up code.")>
    Nelson8DoubleFCNPlasticImpact = 9,
    /**
     * Senninger 6 Double White Vane Plastic Impact
     */
    Senninger6DoubleWhiteVanePlasticImpact = 10,
    /**
     * Senninger 6 Double Red Vane Plastic Impact
     */
    Senninger6DoubleRedVanePlasticImpact = 11,
    /**
     * Nelson O3000 Orbitor Purple
     */
    NelsonO3000OrbitorPurple = 12,
    /**
     * Senninger Standard Angle I-Wob Gray
     */
    SenningerStandardAngleIWobGray = 13,
    /**
     * Nelson S3000 Spinner Red
     */
    NelsonS3000SpinnerRed = 14,
    /**
     * Nelson S3000 Spinner Purple
     */
    NelsonS3000SpinnerPurple = 15,
    /**
     * Nelson S3000 Spinner Yellow
     */
    NelsonS3000SpinnerYellow = 16,
    /**
     * Nelson S3000 Spinner Purple Low Pressure
     */
    NelsonS3000SpinnerPurpleLowPressure = 17,
    /**
     * Nelson S3000 Spinner Yellow Low Pressure
     */
    NelsonS3000SpinnerYellowLowPressure = 18,
    /**
     * Nelson D3000 Spray
     */
    NelsonD3000Spray = 19,
    /**
     * Nelson D3000 FCN Spray
     */
    NelsonD3000FCNSpray = 20,
    /**
     * Nelson R3000 Rotator Red
     */
    NelsonR3000RotatorRed = 21,
    /**
     * Nelson R3000 Rotator Blue
     */
    NelsonR3000RotatorBlue = 22,
    /**
     * Nelson R3000 FCN Rotator Blue
     */
    NelsonR3000FCNRotatorBlue = 23,
    /**
     * Nelson R3000 Rotator Green
     */
    NelsonR3000RotatorGreen = 24,
    /**
     * Nelson R3000 FCN Rotator Green
     */
    NelsonR3000FCNRotatorGreen = 25,
    /**
     * Nelson R3000 Rotator Orange
     */
    NelsonR3000RotatorOrange = 26,
    /**
     * Nelson R3000 Rotator Brown
     */
    NelsonR3000RotatorBrown = 27,
    /**
     * Nelson R3000 Rotator White
     */
    NelsonR3000RotatorWhite = 28,
    /**
     * Nelson A3000 Accelerator Maroon
     */
    NelsonA3000AcceleratorMaroon = 29,
    /**
     * Senninger Super Spray
     */
    SenningerSuperSpray = 30,
    /**
     * Senninger Quad Spray
     */
    // <Obsolete("ROE-682 - Senninger QuadSpray is obsolete.")>
    SenningerQuadSpray = 31,
    /**
     * Senninger LDN Spray
     */
    SenningerLDNSpray = 32,
    /**
     * Senninger Low Angle I-Wob White
     */
    SenningerLowAngleIWobWhite = 33,
    /**
     * Senninger High Angle I-Wob Black
     */
    SenningerHighAngleIWobBlack = 34,
    /**
     * Senninger Low Angle I-Wob Blue
     */
    SenningerLowAngleIWobBlue = 35,
    /**
     * Senninger 180� Fan Spray
     */
    Senninger180Fan = 36,
    /**
     * Nelson D3000 Partial Circle Spray
     */
    NelsonD3000PartCircle = 37,
    /**
     * Nelson S3000 Partial Circle Spinner
     */
    NelsonS3000PartCircle = 38,
    /**
     * Senninger Xi-Wob 910 Gray
     */
    SenningerXiWobGray = 39,
    /**
     * Senninger Xi-Wob 615 Black
     */
    SenningerXiWobBlack = 40,
    /**
     * Komet Twister Black
     */
    KometTwisterBlack = 41,
    /**
     * Senninger Xi-Wob 605 White
     */
    // <Obsolete("ROE-677 - 605 White, Med Droplet for Xi-Wob is obsolete.")>
    SenningerXiWobWhite = 42,
    /**
     * Nelson A3000 Accelerator Gold
     */
    NelsonA3000AcceleratorGold = 43,
    /**
     * Nelson O3000 Orbitor Black
     */
    NelsonO3000OrbitorBlack = 44,
    /**
     * Nelson O3000 Orbitor Blue
     */
    NelsonO3000OrbitorBlue = 45,
    /**
     * Nelson A3000 Accelerator Navy
     */
    NelsonA3000AcceleratorNavy = 46,
    /**
     * Nelson A3000 Accelerator Navy Low Pressure
     */
    NelsonA3000AcceleratorNavyLowPressure = 47,
    /**
     * Komet Twister Blue
     */
    KometTwisterBlue = 48,
    /**
     * Komet Twister Yellow
     */
    KometTwisterYellow = 49,
    /**
     * Komet Spray
     */
    KometSpray = 50,
    /**
     * Komet Partial Circle Spray
     */
    KometSprayPartCircle = 51,
    /**
     * Komet Partial Circle Twister
     */
    KometTwisterPartCircle = 52,
    _Removed1 = 53,
    _Removed2 = 54,
    _Removed3 = 55,
    /**
     * Komet Twister White
     */
    KometTwisterWhite = 56,
    /**
     * Senninger Xcel Wobbler
     */
    SenningerXcelWobbler = 57,
    /**
     * Nelson R3000 Rotator Olive
     */
    NelsonR3000RotatorOlive = 58,
    /**
     * Nelson O3030 OrbitorFX White
     */
    NelsonO3030OrbitorFXWhite = 59,
    /**
     * Nelson O3030 OrbitorFX Black
     * (Drops Only)
     */
    NelsonO3030OrbitorFXBlack = 60,
}
export enum PipeMaterialTypes {
    Galvanized = "Galvanized",
    PVC = "PVC"
}
export enum DropTypes {
    None = "None",
    Rigid = "Rigid",
    Hose = "Hose"
}
export enum DeviceWeights {
    None = "None",
    OnePound = "OnePound",
    Magnum = "Magnum"
}
export enum SuperSprayPadTypes {
    CottonChemWhite = "CottonChemWhite",
    Concave36DeepBlue = "Concave36DeepBlue",
    Concave36MedBlue = "Concave36MedBlue",
    Flat24DeepBlack = "Flat24DeepBlack",
    Flat36DeepBlack = "Flat36DeepBlack",
    Flat36MedBlack = "Flat36MedBlack",
    FlatSmoothBlack = "FlatSmoothBlack"
}
export enum NozzleClipTypes {
    None = "None",
    Dual = "Dual",
    Triple = "Triple"
}
export enum OutletValveTypes {
    None = "None",
    Brass = "Brass",
    PlasticLever = "PlasticLever",
    PlasticKnob = "PlasticKnob"
}
export enum FittingTypes {
    None = "None",
    Close = "Close",
    Galvanized = "Galvanized",
    StainlessSteel = "StainlessSteel"
}


export enum LDNTypes {
    MultiPad = "MultiPad",
    SinglePad = "SinglePad"
}

export enum LDNPadTypes {
    Blue = "Blue",
    Black = "Black",
    White = "White",
    Black24Deep = "Black24Deep"
}

export enum LDNChemPadTypes {
    None = "None",
    Red = "Red",
    White = "White",
    Beige = "Beige"
}

export interface ILDN {
    ChemPad: LDNChemPadTypes;
    BubblerShroud: boolean;
    LDNType: LDNTypes;
    Pad: LDNPadTypes;
}

export enum KometSprayTypes {
    Blue = "Blue",
    Grey = "Grey",
    Black = "Black",
    Yellow = "Yellow"
}

export interface IKometSpray {
    Pad: KometSprayTypes
}

export enum UPipeReaches {
    Standard = "Standard",
    a12Inch = "a12Inch",
    a20Inch = "a20Inch"
}

export enum UPipeFittings {
    MxHB = "MxHB",
    MxM = "MxM",
    MxF = "MxF"
}

export enum UPipeTypes {
    Galv = "Galv",
    PVC = "PVC",
    Alum = "Alum",
    BlackPlasticSingle180 = "BlackPlasticSingle180",
    BlackPlasticSingle125 = "BlackPlasticSingle125",
    BlackPlasticDouble125 = "BlackPlasticDouble125",
    Nelson180 = "Nelson180"
}

export enum RegulatorLocations {
    Bottom = "Bottom",
    Top = "Top"
}

export enum HoseTopFittings {
    None = "None",
    FxHBBlack = "FxHBBlack",
    MxHBGray = "MxHBGray",
    MxHBBlack = "MxHBBlack"
}

export enum HoseClamps {
    Screw = "Screw",
    Pinch = "Pinch"
}

export enum HoseDropWeights {
    None = "None",
    MxM18Galv = "MxM18Galv",
    MxHB18Galv = "MxHB18Galv",
    MxHB24Galv = "MxHB24Galv",
    SlipOver30Poly = "SlipOver30Poly",
    FxF24Poly = "FxF24Poly",
    SlimMxHB = "SlimMxHB",
    NelsonCoil = "NelsonCoil",
}

export enum WeightTopFittings {
    None = "None",
    FxHBBlack = "FxHBBlack",
    MxHBGray = "MxHBGray",
    MxHBBlack = "MxHBBlack"
}

export enum WeightBottomFittings {
    None = "None",
    FxHBBlack = "FxHBBlack",
    MxHBGray = "MxHBGray",
    MxHBBlack = "MxHBBlack",
    CloseGray = "CloseGray",
    CloseBlack = "CloseBlack",
    CloseGalv = "CloseGalv",
    CouplingGray = "CouplingGray",
    CouplingBlack = "CouplingBlack",
    CouplingGalv = "CouplingGalv"
}

export const DefaultFitting: IFitting = {
    FittingType: FittingTypes.None,
    DropNextToEndGun: false
};

export const DefaultHoseDrop: IHoseDrop = {
    UPipeReach: UPipeReaches.Standard,
    UPipeFitting: UPipeFittings.MxHB,
    UPipeType: UPipeTypes.Galv,
    RegulatorLocation: RegulatorLocations.Bottom,
    HoseTopFitting: HoseTopFittings.None,
    HoseTopClamp: HoseClamps.Screw,
    HoseBottomClamp: HoseClamps.Screw,
    Weight: HoseDropWeights.None,
    WeightTopFitting: WeightTopFittings.None,
    WeightBottomFitting: WeightBottomFittings.None,
    DeviceWeight: DeviceWeights.None,
    DragAdapter: false,
    DragSock: false,
    ScrewClamp: false,
    SubstituteSTxHB: false,
    ReinkeBlue: false,
    UseKometTrussRodSlings: false,
    GroundClearance: 0
}

export const DefaultRigidDrop: IRigidDrop = {
    DropType: RigidDropTypes.Fixed,
    Length: 5,
    DropMaterial: RigidDropMaterialTypes.Galvanized,
    UPipeMaterial: UPipeMaterialTypes.Galvanized,
    BottomFitting: RigidDropBottomFittings.Gray,
    ReinforcementClamp: false,
    DeviceWeight: DeviceWeights.None,
    GroundClearance: 0
}

export const DefaultLDN: ILDN = {
    LDNType: LDNTypes.MultiPad,
    Pad: LDNPadTypes.Blue,
    ChemPad: LDNChemPadTypes.None,
    BubblerShroud: false
}

export const DefaultD3000: ID3000 = {
    Plate1: D3000Plates.None,
    Plate2: D3000Plates.None,
    Plate3: D3000Plates.None,
    SprinklerConverter: false,
    BubblerClipLEPA: false
}

export const DefaultKometSpray: IKometSpray = {
    Pad: KometSprayTypes.Blue
}

export const DefaultRegulator: IRegulator = {
    RegulatorType: RegulatorTypes.None,
    Thread: ThreadTypes.Square,
    Pressure: RegulatorPressures.a6,
    AsNeeded: false
}

export const DefaultPackage: IPackage = {
    PackageNumber: 0,
    SecondNozzleGPM: 0,
    ThirdNozzleGPM: 0,
    Device: DeviceTypes.None,
    NozzleClip: NozzleClipTypes.None,
    Drop: DropTypes.None,
    Fitting: DefaultFitting,
    HoseDrop: DefaultHoseDrop,
    RigidDrop: DefaultRigidDrop,
    SuperSprayPad: SuperSprayPadTypes.CottonChemWhite,
    LDN: DefaultLDN,
    D3000: DefaultD3000,
    KometSpray: DefaultKometSpray,
    TrashBusterBody: false,
    Valve: OutletValveTypes.None,
    Regulator: DefaultRegulator,
    NormalSpacing: false,
    UseNelson3030: false,
    UseNelsonAD3030MT: false,
    DevicesDoubled: false,
    EndingLocation: 0,
    Spacing: 0,
    DropSpacingGradually: false
}