// will return elevation profiles by offset
import * as turf from "@turf/turf";
import { ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import { IsPivotingLateral } from "roedata/roe_migration/SystemFunctions";
import { ILayoutElevation } from "../../GeometryHelpers/GeometryProvider";
import LateralGeometryHelper, { ILineSegment, getLateralLineString, getSegmentLinePositions } from "../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import IProject from "../../model/project/IProject";
import { createFeatureFromGeometry } from "../LayoutMap/drawFeaturesFactory";
import { IElevationProfile } from "./interfaces";

export const LATERAL_ELEVATION_PROFILE_STEP_FEET = 30;

interface IArgs {
    systemId: string;
    layoutId: string;
    project: IProject;
    elevation: ILayoutElevation;
}

interface IReturn {
    normalizedStartOffsetFeet: number;
    normalizedEndOffsetFeet: number;
    minimumElevation: {
        offsetFeet: number;
        elevationFeet: number;
    }
    maximumElevation: {
        offsetFeet: number;
        elevationFeet: number;
    }
    lateralStart: {
        elevationFeet: number;
    }
    profiles: IElevationProfile[];
    systemLength: number;
}

export const lateralElevationProfiler = (args: IArgs): IReturn | undefined => {

    const { systemId, layoutId, project, elevation } = args;

    // BEGIN: Check validity
    // we can only extract elevations if the elvation fns are available:
    if (!elevation) {
        return undefined;
    }
    
    // we will only extract an elevation profile if this is a lateral:
    const system = project.layouts[layoutId]?.systems[systemId];
    if (!system || !system.lateral) {
        return undefined;
    }
    
    // we will only extract an elevation profile if all span tower lengths are defined:
    if (system.FlangedSide.Span.some((x) => !Number.isFinite(spanf.LengthInFeet(system.FlangedSide, x)))) {
        return undefined;
    }
    if (system.FlexSide.Span.some((x) => !Number.isFinite(spanf.LengthInFeet(system.FlexSide, x)))) {
        return undefined;
    }
    
    // we will only extract an elevation profile if the system length is not 0:
    const lateralGeometry = new LateralGeometryHelper({ systemId, layoutId, project });
    let flangedDistance = 0;
    let flexDistance = 0;
    if (system.FlangedSide.Span.length) {
        flangedDistance += spanf.EndingRadius(system, system.FlangedSide, system.FlangedSide.Span[system.FlangedSide.Span.length - 1]);
    }
    if (system.FlexSide.Span.length) {
        flexDistance += spanf.EndingRadius(system, system.FlexSide, system.FlexSide.Span[system.FlexSide.Span.length - 1]);
    }
    let aftDistanceFromCenterFeet = (lateralGeometry.isRigidSideForward() ? flexDistance : flangedDistance) + lateralGeometry.distanceFromCanalCenterToAftSide;
    let fwdDistanceFromCenterFeet = (lateralGeometry.isRigidSideForward() ? flangedDistance : flexDistance) + lateralGeometry.distanceFromCanalCenterToFwdSide;
    
    if (aftDistanceFromCenterFeet === 0 && fwdDistanceFromCenterFeet === 0) {
        return undefined;
    }
    // END: Check validity

    // normalize and set the inital bearing for the side view:
    const normalizedStartOffsetFeet = 0;

    // extend system length by 10foot each side needed by system design
    aftDistanceFromCenterFeet += 10;
    fwdDistanceFromCenterFeet += 10;
    
    const minimumElevation = {
        offsetFeet: 0,
        elevationFeet: Number.MAX_VALUE
    };
    const maximumElevation = {
        offsetFeet: 0,
        elevationFeet: Number.MIN_VALUE
    };

    const lateralStartElevationFeature = elevation.getPosition(system.lateral.line.coordinates[1]);
    if (!lateralStartElevationFeature || lateralStartElevationFeature.properties.elevationMeters === null) {
        return undefined;
    }
    const lateralStartElevationMeters = lateralStartElevationFeature.properties.elevationMeters;
    const lateralStartElevation = Math.sign(lateralStartElevationMeters) * turf.convertLength(Math.abs(lateralStartElevationMeters), 'meters', 'feet');

    const profiles: IElevationProfile[] = [];
    let lostLength = 0;
    const segmentsToUse = getSegmentsToUse(system, lateralGeometry);
    const segmentsWithLength = segmentsToUse.map(segment => {
        const segmnetFeedLine = getLateralLineString([ segment ], 0);
        const length = turf.length(turf.lineString(segmnetFeedLine.coordinates), { units: 'feet' });
        return {
            segment,
            length
        }

    });


    const feedLine = turf.feature(getLateralLineString(segmentsToUse, 0));
    const systemFeedLength = turf.length(feedLine, { units: 'feet'});

    // const positionsa: turf.Position[][] = [];
    // console.log("segs",structuredClone(segmentsWithLength))
    for (let offset = 0; offset <= systemFeedLength; offset++) {
        const offsetProfile: IElevationProfile = [];

        // const positions: turf.Position[] = [];
        while (segmentsWithLength.length && segmentsWithLength[0].length < (offset - lostLength)) {
            // console.log("splicing")
            lostLength += segmentsWithLength[0].length;
            segmentsWithLength.splice(0, 1);
        }

        for (
            let distanceFeet = 0;
            distanceFeet <= aftDistanceFromCenterFeet + LATERAL_ELEVATION_PROFILE_STEP_FEET;
            distanceFeet += LATERAL_ELEVATION_PROFILE_STEP_FEET
        ) {
            let segmentPositions: turf.helpers.Position[] = [];
            try {
                segmentPositions = getSegmentLinePositions(segmentsWithLength[0].segment, -distanceFeet, { trimStart: offset - lostLength });
            }
            catch (e) {
                // TODO: The lateralElevationProfiler and Side View need refactoring carfully to account for
                // the segments (introduced due to pivoting laterals)
                console.log("Failed to trim aft segment in lateralElevationProfiler");
                console.log(e)
                return undefined;
            }
            
            const elevationFeature = elevation.getPosition(segmentPositions[0]);
            if (!elevationFeature || elevationFeature.properties.elevationMeters === null) {
                return undefined;
            }
            const elevationMeters = elevationFeature.properties.elevationMeters;
            // positions.unshift(segmentPositions[0])
            if (elevationMeters === null) {
                // then we cannot provide a complete profile. Exit
                return;
            }
            const elevationFeet = Math.sign(elevationMeters) * turf.convertLength(Math.abs(elevationMeters), 'meters', 'feet');
            offsetProfile.push({
                minDistanceFeet: -distanceFeet - LATERAL_ELEVATION_PROFILE_STEP_FEET,
                maxDistanceFeet: -distanceFeet,
                elevationFeet,
            });
            if (elevationFeet < minimumElevation.elevationFeet) {
                minimumElevation.offsetFeet = offset;
                minimumElevation.elevationFeet = elevationFeet;
            }
            if (elevationFeet > maximumElevation.elevationFeet) {
                maximumElevation.offsetFeet = offset;
                maximumElevation.elevationFeet = elevationFeet;
            }
        }
        offsetProfile.reverse();
        for (
            let distanceFeet = 0;
            distanceFeet <= fwdDistanceFromCenterFeet + LATERAL_ELEVATION_PROFILE_STEP_FEET;
            distanceFeet += LATERAL_ELEVATION_PROFILE_STEP_FEET
        ) {
            let segmentPositions: turf.helpers.Position[] = [];
            try {
                segmentPositions = getSegmentLinePositions(segmentsWithLength[0].segment, distanceFeet, { trimStart: offset - lostLength });
            }
            catch (e) {
                // TODO: The lateralElevationProfiler and Side View need refactoring carfully to account for
                // the segments (introduced due to pivoting laterals)
                console.log("Failed to trim fwd segment in lateralElevationProfiler");
                console.log(e)
                return undefined;
            }
            
            const elevationFeature = elevation.getPosition(segmentPositions[0]);
            if (!elevationFeature || elevationFeature.properties.elevationMeters === null) {
                return undefined;
            }
            const elevationMeters = elevationFeature.properties.elevationMeters;
            // positions.push(segmentPositions[0])
            if (elevationMeters === null) {
                // then we cannot provide a complete profile. Exit
                return;
            }
            const elevationFeet = Math.sign(elevationMeters) * turf.convertLength(Math.abs(elevationMeters), 'meters', 'feet');
            offsetProfile.push({
                minDistanceFeet: distanceFeet,
                maxDistanceFeet: distanceFeet + LATERAL_ELEVATION_PROFILE_STEP_FEET,
                elevationFeet,
            });
            if (elevationFeet < minimumElevation.elevationFeet) {
                minimumElevation.offsetFeet = offset;
                minimumElevation.elevationFeet = elevationFeet;
            }
            if (elevationFeet > maximumElevation.elevationFeet) {
                maximumElevation.offsetFeet = offset;
                maximumElevation.elevationFeet = elevationFeet;
            }
        }
        
        profiles.push(offsetProfile);
        // positionsa.push(positions)
    }
    // console.log("profiles", profiles)
    // if (positionsa.length) {
    //     console.log("line debug", turf.multiLineString(positionsa))
    // }
    // else {
    //     console.log("line positions", positionsa)
    // }
    return {
        profiles,
        normalizedStartOffsetFeet,
        normalizedEndOffsetFeet: systemFeedLength,
        minimumElevation,
        maximumElevation,
        systemLength: aftDistanceFromCenterFeet + fwdDistanceFromCenterFeet,
        lateralStart: {
            elevationFeet: lateralStartElevation
        }
    }
}

export const generateLateralSweepLineFeature = (systemId: string, project: IProject, layoutId: string, offset: number) => {
    const geoHelper = new LateralGeometryHelper({
        systemId,
        project,
        layoutId
    });

    const flangedTowers = geoHelper.directionalRigidSideSpanTowers;
    const flexTowers = geoHelper.directionalFlexSideSpanTowers;
    let flangedRadius = flangedTowers.length ? flangedTowers.slice(-1)[0].outsideRadius : 0;
    let flexRadius = flexTowers.length ? flexTowers.slice(-1)[0].outsideRadius : 0;
    
    const segments = getSegmentsToUse(project.layouts[layoutId].systems[systemId], geoHelper);
    if (flangedRadius >= 0) {
        flangedRadius += 10;
        flexRadius -= 10
    }
    else {
        flangedRadius -= 10
        flexRadius += 10;
    }
    for (const segment of segments) {
        const segmnetFeedLine = getLateralLineString([ segment ], 0);
        const len = turf.length(turf.lineString(segmnetFeedLine.coordinates), { units: 'feet' });
        if (len <= offset) {
            offset -= len;
        }
        else {
            const flangedLinePositions = getSegmentLinePositions(segment, flangedRadius, { trimStart: offset });
            const flexLinePositions = getSegmentLinePositions(segment, flexRadius, { trimStart: offset });
            const p1 = flangedLinePositions[0];
            const p2 = flexLinePositions[0];
            const sweepLineString = turf.lineString([
                p1, p2
            ]);
            const sweepLineFeature = createFeatureFromGeometry(
                sweepLineString.geometry,
                "sweepline"
            );
            return [sweepLineFeature];
        }
    }
    return [];
};

const getSegmentsToUse = (system: ISystemBase, lateralGeometry: LateralGeometryHelper) : ILineSegment[] => 
    (IsPivotingLateral(system) && lateralGeometry.directionalRigidSideSpanTowers.length)
        ? lateralGeometry.directionalRigidSideSpanTowers[0].segments.filter((x): x is ILineSegment => x.type === 'line')
        : lateralGeometry.feedLineSegments;