import { SwingArmLengths } from "../project/ISystemBase.AutoGenerated";

export const swingArmLengthsFeet = [
    { 
        value: SwingArmLengths.SAC156, 
        feet: 156, 
        enbdoomFeet: 51, 
        label: "MSAC (156' Span W/51' EB)", 
        conversionLabel: (value1: string, value2: string) => `MSAC (${value1} Span W/${value2} EB)`,
        shortLabel: "MSAC"
    },
    { 
        value: SwingArmLengths.SAC175, 
        feet: 175, 
        enbdoomFeet: 61, 
        label: "MSSAC (175' Span W/61' EB)", 
        conversionLabel: (value1: string, value2: string) => `MSSAC (${value1} Span W/${value2} EB)`,
        shortLabel: "MSSAC"
    },
    { 
        value: SwingArmLengths.SAC194, 
        feet: 194, 
        enbdoomFeet: 85, 
        label: "SAC (194' Span W/85' EB)", 
        conversionLabel: (value1: string, value2: string) => `SAC (${value1} Span W/${value2} EB)`,
        shortLabel: "SAC"
    },
    { 
        value: SwingArmLengths.SAC213, 
        feet: 213, 
        enbdoomFeet: 105, 
        label: "SSAC (213' Span W/105' EB)", 
        conversionLabel: (value1: string, value2: string) => `SSAC (${value1} Span W/${value2} EB)`,
        shortLabel: "SSAC"
    },
];