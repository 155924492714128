export enum POSections {
    CenterPivot = 1,
    CanalFeed = 2,
    KwikTow = 3,
    HoseFeed = 4,
    ControlPanel = 5,
    SpanAssemblies = 6,
    EndOfSystem = 7,
    MaxigatorGuidance = 8,
    Towers = 9,
    SwingArm = 10,
    Options = 11,
    Sprinkler = 12,
    AgriInject = 13,
    MainlineValves = 14,
    //NOT IN USE = 15,
    SprinklerLube = 16,
    ClemonsFilters = 17,
    CheckValves = 18,
    HeatExchangers = 19,
    RadioTelemetry = 20,
    VRI = 21,
    FlowmeterComponentsPartsPricing = 22,
    RC10 = 23,
    DualSprinklerPackage = 24,
    CropX = 25,
    RC10Retro = 26,
}

export enum POGroups {
    //CenterPivot 1
    CenterPivot_RiserPipe = 2,

    //ControlPanel 5
    ControlPanel_VRIPanelKit = 17,
    ControlPanel_ElectHydraulicPumpKit = 43,
    ControlPanel_SatellitePlusKit = 49,
    ControlPanel_CanalFeedSelfCleaningScreenBoosterPump = 53,
    ControlPanel_TowableBaseSwitch = 54,

    //Towers 9
    Towers_DriveShield = 2,
    Towers_TowerFuseKit = 15,

    //SwingArm 10
    // SACEnhancedWaterPackage = 17,
    SwingArm_SteeringMotorCover = 30,

    //Options 11
    Options_WarrantyPacket = 25,

    //RadioTelemetry = 20,
    RadioTelemetry_CableTheft = 1,
    RadioTelemetry_Radio = 2,
    RadioTelemetry_SatellitePlusRetro = 3,
    RadioTelemetry_Cell = 4,

    //VRI = 21,
    VRI_Tubing = 1,
    VRI_Compressor = 2,
    VRI_Valves = 3,
    VRI_ValveBox = 4,
    VRI_MountKit = 7,

    FlowmeterComponents_Parts = 1,

    //DualSprinklerPackage = 24,
    DualSprinklerPackage_Tubing = 1,
    DualSprinklerPackage_Compressor = 2,
    DualSprinklerPackage_Valves = 3,
    DualSprinklerPackage_ValveBox = 4,
    DualSprinklerPackage_MountKit = 5,

    //CropX = 25,
    CropX_Probe = 1,
    CropX_Probeless = 2,
    CropX_InstallKit = 3,
    CropX_Antenna = 4,
}