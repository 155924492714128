import { DeviceTypes } from "rdptypes/project/ISprinklers";

export interface NozzleRecord {
    Sequence_x0020_Number: number,
    A: number;
    B: number;
    C: number;
    MinPSI: number;
    MaxPSI: number;
    MaxSpace: number;
    DevCode: number;
    Nozzle1Size: number;
}

export class DeviceNozzleSet {
    constructor(private nozzles: NozzleRecord[]) {}

    public Nozzles = (i: number): NozzleRecord => i === 0 ? {
		Sequence_x0020_Number: 0,
		A: 0,
		B: 0,
		C: 0,
		MinPSI: 0,
		MaxPSI: 0,
		MaxSpace: 0,
		DevCode: 0,
		Nozzle1Size: 0,
	} : this.nozzles.filter(x => x.Sequence_x0020_Number === i)[0];
	public Count = (): number => this.nozzles.length + 1; // Include default 0 entry
}

export const DeviceNozzle = (dt: DeviceTypes): DeviceNozzleSet => {
    return new DeviceNozzleSet(NozzleData.filter(x => x.DeviceType === dt));
}

const NozzleData = [
	{
		DeviceType: 1,
		Sequence_x0020_Number: 1,
		A: 0.9055,
		B: 0.03685,
		C: -0.0001152,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 101,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 2,
		A: 1.13,
		B: 0.04941,
		C: -0.0001576,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 101,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 3,
		A: 1.513,
		B: 0.05973,
		C: -0.0001795,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 4,
		A: 1.808,
		B: 0.07456,
		C: -0.0002373,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 5,
		A: 2.146,
		B: 0.09145,
		C: -0.0003077,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 6,
		A: 2.505,
		B: 0.1081,
		C: -0.0003413,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 7,
		A: 2.733,
		B: 0.1376,
		C: -0.0005263,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 8,
		A: 2.563,
		B: 0.1866,
		C: -0.0008946,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 9,
		A: 3.675,
		B: 0.1739,
		C: -0.0006186,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 30,
		DevCode: 103,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 10,
		A: 4.051,
		B: 0.2008,
		C: -0.0007389,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 30,
		DevCode: 103,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 11,
		A: 4.478,
		B: 0.2239,
		C: -0.0008308,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 30,
		DevCode: 103,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 12,
		A: 4.681,
		B: 0.2579,
		C: -0.001012,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 30,
		DevCode: 103,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 13,
		A: 6.635,
		B: 0.238,
		C: -0.0006609,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 30,
		DevCode: 103,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 14,
		A: 6.17,
		B: 0.27163,
		C: -0.0008405,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 104,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 15,
		A: 6.861,
		B: 0.29974,
		C: -0.0009173,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 104,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 16,
		A: 7.515,
		B: 0.32822,
		C: -0.0010595,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 104,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 17,
		A: 8.153,
		B: 0.35589,
		C: -0.0010904,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 104,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 18,
		A: 8.77,
		B: 0.38241,
		C: -0.0011666,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 104,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 19,
		A: 9.533,
		B: 0.4068,
		C: -0.0012046,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 104,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 20,
		A: 10.17,
		B: 0.44141,
		C: -0.0013455,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 104,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 21,
		A: 10.8,
		B: 0.47043,
		C: -0.0014355,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 104,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 22,
		A: 11.34,
		B: 0.46786,
		C: -0.0015,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 105,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 23,
		A: 12.21,
		B: 0.48354,
		C: -0.0015179,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 105,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 24,
		A: 12.54,
		B: 0.51668,
		C: -0.0016607,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 105,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 25,
		A: 13.41,
		B: 0.53493,
		C: -0.0016786,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 105,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 26,
		A: 13.88,
		B: 0.55875,
		C: -0.0017679,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 105,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 27,
		A: 14.67,
		B: 0.57618,
		C: -0.0017679,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 105,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 28,
		A: 15.27,
		B: 0.60175,
		C: -0.0018393,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 105,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 29,
		A: 15.79,
		B: 0.63571,
		C: -0.002,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 105,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 30,
		A: 16.26,
		B: 0.67279,
		C: -0.0021786,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 105,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 31,
		A: 17,
		B: 0.67154,
		C: -0.0020893,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 105,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 1,
		Sequence_x0020_Number: 32,
		A: 17.77,
		B: 0.72675,
		C: -0.0023393,
		MinPSI: 45,
		MaxPSI: 100,
		MaxSpace: 30,
		DevCode: 105,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 1,
		A: 0.5993,
		B: 0.05014,
		C: -0.000302,
		MinPSI: 15,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 2,
		A: 0.6996,
		B: 0.05852,
		C: -0.000352,
		MinPSI: 15,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 3,
		A: 0.7935,
		B: 0.06639,
		C: -0.0004,
		MinPSI: 14,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 4,
		A: 0.8961,
		B: 0.07497,
		C: -0.000452,
		MinPSI: 14,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 5,
		A: 0.9932,
		B: 0.08309,
		C: -0.0005,
		MinPSI: 14,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 6,
		A: 1.118,
		B: 0.09357,
		C: -0.000564,
		MinPSI: 14,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 7,
		A: 1.244,
		B: 0.1041,
		C: -0.000627,
		MinPSI: 14,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 8,
		A: 1.349,
		B: 0.11289,
		C: -0.00068,
		MinPSI: 14,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 9,
		A: 1.495,
		B: 0.1251,
		C: -0.000754,
		MinPSI: 14,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 10,
		A: 1.624,
		B: 0.13583,
		C: -0.000818,
		MinPSI: 14,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 11,
		A: 1.791,
		B: 0.1498,
		C: -0.000902,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 12,
		A: 1.932,
		B: 0.16168,
		C: -0.000974,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 13,
		A: 2.1,
		B: 0.17572,
		C: -0.001058,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 14,
		A: 2.252,
		B: 0.18838,
		C: -0.001135,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 15,
		A: 2.452,
		B: 0.20515,
		C: -0.001236,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 16,
		A: 2.621,
		B: 0.21928,
		C: -0.001321,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 17,
		A: 2.806,
		B: 0.23475,
		C: -0.001414,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 18,
		A: 2.971,
		B: 0.24858,
		C: -0.001497,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 19,
		A: 3.195,
		B: 0.26725,
		C: -0.00161,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 20,
		A: 3.405,
		B: 0.28486,
		C: -0.001716,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 21,
		A: 3.617,
		B: 0.30262,
		C: -0.001823,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 22,
		A: 3.812,
		B: 0.31893,
		C: -0.001921,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 23,
		A: 4.006,
		B: 0.33515,
		C: -0.002019,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 24,
		A: 4.28,
		B: 0.35805,
		C: -0.002157,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 25,
		A: 4.526,
		B: 0.3786,
		C: -0.00228,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 26,
		A: 4.778,
		B: 0.39969,
		C: -0.002408,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 27,
		A: 5.021,
		B: 0.42007,
		C: -0.00253,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 28,
		A: 5.319,
		B: 0.44498,
		C: -0.00268,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 29,
		A: 5.565,
		B: 0.4656,
		C: -0.002804,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 30,
		A: 5.833,
		B: 0.48797,
		C: -0.002939,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 31,
		A: 6.101,
		B: 0.51037,
		C: -0.003074,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 32,
		A: 6.394,
		B: 0.5349,
		C: -0.003222,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 33,
		A: 6.678,
		B: 0.55868,
		C: -0.003365,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 34,
		A: 7.017,
		B: 0.58701,
		C: -0.003536,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 35,
		A: 7.298,
		B: 0.61057,
		C: -0.003678,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 36,
		A: 7.553,
		B: 0.63192,
		C: -0.003806,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 2,
		Sequence_x0020_Number: 37,
		A: 7.893,
		B: 0.66029,
		C: -0.003977,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 1,
		A: 0.7607,
		B: 0.06601,
		C: -0.0003512,
		MinPSI: 35,
		MaxPSI: 60,
		MaxSpace: 25,
		DevCode: 161,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 2,
		A: 1.054,
		B: 0.07774,
		C: -0.000381,
		MinPSI: 35,
		MaxPSI: 60,
		MaxSpace: 25,
		DevCode: 161,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 3,
		A: 1.179,
		B: 0.10345,
		C: -0.0005595,
		MinPSI: 35,
		MaxPSI: 65,
		MaxSpace: 25,
		DevCode: 162,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 4,
		A: 1.371,
		B: 0.10827,
		C: -0.0005417,
		MinPSI: 35,
		MaxPSI: 70,
		MaxSpace: 25,
		DevCode: 162,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 5,
		A: 1.525,
		B: 0.11488,
		C: -0.0005238,
		MinPSI: 35,
		MaxPSI: 75,
		MaxSpace: 25,
		DevCode: 162,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 6,
		A: 1.704,
		B: 0.12131,
		C: -0.0005238,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 25,
		DevCode: 162,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 7,
		A: 2.025,
		B: 0.12292,
		C: -0.0004702,
		MinPSI: 35,
		MaxPSI: 85,
		MaxSpace: 25,
		DevCode: 162,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 8,
		A: 2.107,
		B: 0.13702,
		C: -0.0005595,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 25,
		DevCode: 163,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 9,
		A: 2.061,
		B: 0.15899,
		C: -0.0007202,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 25,
		DevCode: 163,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 10,
		A: 2.307,
		B: 0.16673,
		C: -0.000744,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 25,
		DevCode: 163,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 11,
		A: 2.5,
		B: 0.17881,
		C: -0.0008095,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 25,
		DevCode: 163,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 12,
		A: 2.636,
		B: 0.19071,
		C: -0.0008571,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 25,
		DevCode: 163,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 13,
		A: 2.764,
		B: 0.20583,
		C: -0.0009405,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 25,
		DevCode: 163,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 14,
		A: 2.921,
		B: 0.21613,
		C: -0.0009702,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 25,
		DevCode: 163,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 15,
		A: 3.068,
		B: 0.22923,
		C: -0.0010298,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 25,
		DevCode: 163,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 16,
		A: 3.229,
		B: 0.24077,
		C: -0.0010774,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 25,
		DevCode: 163,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 17,
		A: 3.536,
		B: 0.24792,
		C: -0.0010774,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 25,
		DevCode: 163,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 18,
		A: 3.882,
		B: 0.25566,
		C: -0.0011012,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 25,
		DevCode: 163,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 19,
		A: 4.132,
		B: 0.27018,
		C: -0.0011964,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 25,
		DevCode: 163,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 20,
		A: 5.448,
		B: 0.23643,
		C: -0.0006905,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 21,
		A: 6.802,
		B: 0.21036,
		C: -0.0003452,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 22,
		A: 5.607,
		B: 0.28785,
		C: -0.001,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 23,
		A: 6.318,
		B: 0.28661,
		C: -0.000839,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 24,
		A: 6.607,
		B: 0.30684,
		C: -0.000899,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 25,
		A: 6.679,
		B: 0.32631,
		C: -0.001059,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 26,
		A: 6.989,
		B: 0.35131,
		C: -0.001238,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 27,
		A: 7.279,
		B: 0.3772,
		C: -0.001506,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 28,
		A: 8.078,
		B: 0.36524,
		C: -0.001167,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 29,
		A: 8.289,
		B: 0.38577,
		C: -0.001256,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 30,
		A: 8.571,
		B: 0.40363,
		C: -0.001292,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 31,
		A: 8.679,
		B: 0.4494,
		C: -0.001726,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 32,
		A: 8.918,
		B: 0.46946,
		C: -0.001839,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 33,
		A: 9.279,
		B: 0.48357,
		C: -0.001857,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 34,
		A: 10.23,
		B: 0.50304,
		C: -0.001946,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 35,
		A: 10.89,
		B: 0.50416,
		C: -0.001845,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 36,
		A: 11.22,
		B: 0.5175,
		C: -0.001857,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 37,
		A: 11.3,
		B: 0.58571,
		C: -0.002393,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 38,
		A: 11.39,
		B: 0.60548,
		C: -0.002476,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 39,
		A: 11.67,
		B: 0.62209,
		C: -0.00253,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 40,
		A: 12.58,
		B: 0.6697,
		C: -0.002863,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.40625,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 41,
		A: 12.56,
		B: 0.70083,
		C: -0.003083,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.40625,
	},
	{
		DeviceType: 3,
		Sequence_x0020_Number: 42,
		A: 12.75,
		B: 0.71893,
		C: -0.003143,
		MinPSI: 45,
		MaxPSI: 95,
		MaxSpace: 25,
		DevCode: 164,
		Nozzle1Size: 0.40625,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 1,
		A: 0.9055,
		B: 0.03658,
		C: -0.0001152,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 101,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 2,
		A: 1.13,
		B: 0.04941,
		C: -0.0001576,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 101,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 3,
		A: 1.513,
		B: 0.05973,
		C: -0.0001795,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 4,
		A: 1.808,
		B: 0.07456,
		C: -0.0002373,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 5,
		A: 2.146,
		B: 0.09145,
		C: -0.0003077,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 6,
		A: 2.505,
		B: 0.1081,
		C: -0.0003413,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 102,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 7,
		A: 2.733,
		B: 0.1376,
		C: -0.0005263,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 102,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 8,
		A: 2.563,
		B: 0.1866,
		C: -0.0008946,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 102,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 9,
		A: 3.675,
		B: 0.1739,
		C: -0.0006186,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 20,
		DevCode: 103,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 10,
		A: 4.051,
		B: 0.2008,
		C: -0.0007389,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 20,
		DevCode: 103,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 11,
		A: 4.478,
		B: 0.2239,
		C: -0.0008308,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 20,
		DevCode: 103,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 12,
		A: 4.681,
		B: 0.2579,
		C: -0.001012,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 20,
		DevCode: 103,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 13,
		A: 6.635,
		B: 0.238,
		C: -0.0006609,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 15,
		DevCode: 103,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 14,
		A: 7.332,
		B: 0.2612,
		C: -0.0007196,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 15,
		DevCode: 103,
		Nozzle1Size: 0,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 15,
		A: 7.658,
		B: 0.2744,
		C: -0.0007721,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 15,
		DevCode: 106,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 16,
		A: 8.111,
		B: 0.2993,
		C: -0.0008671,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 15,
		DevCode: 106,
		Nozzle1Size: 0,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 17,
		A: 8.417,
		B: 0.30705,
		C: -0.00086,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 15,
		DevCode: 106,
		Nozzle1Size: 0,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 18,
		A: 8.727,
		B: 0.3178,
		C: -0.0008869,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 15,
		DevCode: 106,
		Nozzle1Size: 0,
	},
	{
		DeviceType: 4,
		Sequence_x0020_Number: 19,
		A: 9.081,
		B: 0.3286,
		C: -0.000911,
		MinPSI: 40,
		MaxPSI: 90,
		MaxSpace: 15,
		DevCode: 106,
		Nozzle1Size: 0,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 1,
		A: 0.9055,
		B: 0.03658,
		C: -0.0001152,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 101,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 2,
		A: 1.13,
		B: 0.04941,
		C: -0.0001576,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 101,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 3,
		A: 1.513,
		B: 0.05973,
		C: -0.0001795,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 4,
		A: 1.808,
		B: 0.07456,
		C: -0.0002373,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 5,
		A: 2.146,
		B: 0.09145,
		C: -0.0003077,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 6,
		A: 2.505,
		B: 0.1081,
		C: -0.0003413,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 102,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 7,
		A: 2.733,
		B: 0.1376,
		C: -0.0005263,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 102,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 8,
		A: 2.563,
		B: 0.1866,
		C: -0.0008946,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 102,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 9,
		A: 3.335,
		B: 0.1602,
		C: -0.000575,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 107,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 10,
		A: 3.605,
		B: 0.1772,
		C: -0.000625,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 107,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 11,
		A: 3.905,
		B: 0.1938,
		C: -0.0007,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 107,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 12,
		A: 4.21,
		B: 0.212,
		C: -0.00078,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 107,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 13,
		A: 4.58,
		B: 0.2305,
		C: -0.00085,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 107,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 14,
		A: 4.988,
		B: 0.25038,
		C: -0.000931,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 107,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 15,
		A: 5.408,
		B: 0.27022,
		C: -0.001019,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 107,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 16,
		A: 5.838,
		B: 0.29233,
		C: -0.001119,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 107,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 17,
		A: 6.3,
		B: 0.31295,
		C: -0.001213,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 107,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 18,
		A: 6.52,
		B: 0.32654,
		C: -0.001155,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 107,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 19,
		A: 7.014,
		B: 0.3432,
		C: -0.001179,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 107,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 20,
		A: 7.357,
		B: 0.3687,
		C: -0.001298,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 107,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 21,
		A: 7.857,
		B: 0.38929,
		C: -0.001357,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 107,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 5,
		Sequence_x0020_Number: 22,
		A: 7.54,
		B: 0.454,
		C: -0.002,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 107,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 1,
		A: 0.9055,
		B: 0.03685,
		C: -0.0001152,
		MinPSI: 35,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 101,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 2,
		A: 1.13,
		B: 0.04941,
		C: -0.0001576,
		MinPSI: 35,
		MaxPSI: 65,
		MaxSpace: 30,
		DevCode: 101,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 3,
		A: 1.513,
		B: 0.05973,
		C: -0.0001795,
		MinPSI: 35,
		MaxPSI: 70,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 4,
		A: 1.808,
		B: 0.07456,
		C: -0.0002373,
		MinPSI: 35,
		MaxPSI: 75,
		MaxSpace: 30,
		DevCode: 102,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 5,
		A: 4.53,
		B: 0.03165,
		C: -0.000221,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 108,
		Nozzle1Size: 0.055,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 6,
		A: 4.69,
		B: 0.0309,
		C: -0.000223,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 108,
		Nozzle1Size: 0.06,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 7,
		A: 5.25,
		B: 0.058,
		C: -0.000405,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 108,
		Nozzle1Size: 0.07,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 8,
		A: 6.33,
		B: 0.0588,
		C: -0.000416,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 108,
		Nozzle1Size: 0.08,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 9,
		A: 6.99,
		B: 0.05956,
		C: -0.000435,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 108,
		Nozzle1Size: 0.09,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 10,
		A: 7.04,
		B: 0.0788,
		C: -0.0005171,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 108,
		Nozzle1Size: 0.1,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 11,
		A: 7.54,
		B: 0.084,
		C: -0.0005969,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 109,
		Nozzle1Size: 0.05,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 12,
		A: 8.051,
		B: 0.07365,
		C: -0.000519,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 109,
		Nozzle1Size: 0.055,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 13,
		A: 8.21,
		B: 0.0729,
		C: -0.000521,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 109,
		Nozzle1Size: 0.06,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 14,
		A: 9.38,
		B: 0.0618,
		C: -0.000446,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 109,
		Nozzle1Size: 0.06,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 15,
		A: 9.94,
		B: 0.0889,
		C: -0.000628,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 109,
		Nozzle1Size: 0.07,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 16,
		A: 10.5,
		B: 0.116,
		C: -0.00081,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 109,
		Nozzle1Size: 0.07,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 17,
		A: 11.58,
		B: 0.1168,
		C: -0.000821,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 109,
		Nozzle1Size: 0.08,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 18,
		A: 12.66,
		B: 0.1176,
		C: -0.000832,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 109,
		Nozzle1Size: 0.08,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 19,
		A: 13.32,
		B: 0.11836,
		C: -0.000851,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 109,
		Nozzle1Size: 0.09,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 20,
		A: 13.98,
		B: 0.11912,
		C: -0.00087,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 109,
		Nozzle1Size: 0.09,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 21,
		A: 14.03,
		B: 0.13836,
		C: -0.000952,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 109,
		Nozzle1Size: 0.1,
	},
	{
		DeviceType: 6,
		Sequence_x0020_Number: 22,
		A: 14.08,
		B: 0.1576,
		C: -0.001034,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 109,
		Nozzle1Size: 0.1,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 1,
		A: 0.635,
		B: 0.05238,
		C: -0.000334,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 2,
		A: 0.736,
		B: 0.05861,
		C: -0.00035,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 3,
		A: 0.853,
		B: 0.06379,
		C: -0.000341,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 4,
		A: 1.004,
		B: 0.07192,
		C: -0.000425,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 5,
		A: 1.064,
		B: 0.08024,
		C: -0.000421,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 6,
		A: 1.18,
		B: 0.09075,
		C: -0.000482,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 7,
		A: 1.157,
		B: 0.10525,
		C: -0.0005554,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 8,
		A: 1.243,
		B: 0.11447,
		C: -0.0006161,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 9,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 10,
		A: 1.441,
		B: 0.1411,
		C: -0.0007446,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 11,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 12,
		A: 1.661,
		B: 0.17113,
		C: -0.0009125,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 13,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 14,
		A: 2.023,
		B: 0.19633,
		C: -0.0010304,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 15,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 16,
		A: 2.219,
		B: 0.2409,
		C: -0.0014554,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 17,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 18,
		A: 2.557,
		B: 0.27385,
		C: -0.0016661,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 19,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 20,
		A: 2.921,
		B: 0.30917,
		C: -0.0019232,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 21,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 22,
		A: 3.39,
		B: 0.33525,
		C: -0.0020036,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 23,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 24,
		A: 3.832,
		B: 0.36898,
		C: -0.0021964,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 25,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 26,
		A: 4.27,
		B: 0.40229,
		C: -0.0023429,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 27,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 28,
		A: 4.76,
		B: 0.43482,
		C: -0.0024464,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 29,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 30,
		A: 5.262,
		B: 0.46912,
		C: -0.0025679,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 31,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 32,
		A: 5.913,
		B: 0.49172,
		C: -0.0024482,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 33,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 34,
		A: 6.478,
		B: 0.52638,
		C: -0.0025393,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 7,
		Sequence_x0020_Number: 35,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 5,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 1,
		A: 0.95,
		B: 0.0575,
		C: -0.00025,
		MinPSI: 20,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 141,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 2,
		A: 1.236,
		B: 0.06893,
		C: -0.00025,
		MinPSI: 20,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 141,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 3,
		A: 1.551,
		B: 0.08761,
		C: -0.000375,
		MinPSI: 20,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 141,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 4,
		A: 2.021,
		B: 0.09691,
		C: -0.000348,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 141,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 5,
		A: 2.196,
		B: 0.11807,
		C: -0.000464,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 141,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 6,
		A: 2.382,
		B: 0.14509,
		C: -0.00058,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 141,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 7,
		A: 2.896,
		B: 0.16557,
		C: -0.000714,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 141,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 8,
		A: 3.563,
		B: 0.17061,
		C: -0.000589,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 141,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 9,
		A: 3.701,
		B: 0.2022,
		C: -0.000777,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 141,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 10,
		A: 4.154,
		B: 0.22568,
		C: -0.000911,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 141,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 11,
		A: 3.659,
		B: 0.2273,
		C: -0.000973,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 142,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 12,
		A: 3.853,
		B: 0.24325,
		C: -0.001054,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 142,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 13,
		A: 4.572,
		B: 0.23005,
		C: -0.000777,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 142,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 14,
		A: 4.576,
		B: 0.27121,
		C: -0.001179,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 142,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 15,
		A: 4.64,
		B: 0.30211,
		C: -0.001411,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 142,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 16,
		A: 5.585,
		B: 0.27727,
		C: -0.001027,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 142,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 17,
		A: 5.278,
		B: 0.31619,
		C: -0.001348,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 142,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 18,
		A: 6.129,
		B: 0.30725,
		C: -0.001125,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 142,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 19,
		A: 6.616,
		B: 0.31884,
		C: -0.001143,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 142,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 20,
		A: 5.914,
		B: 0.37463,
		C: -0.001607,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 142,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 21,
		A: 6.698,
		B: 0.36056,
		C: -0.001384,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 142,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 22,
		A: 6.638,
		B: 0.38481,
		C: -0.001509,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 142,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 23,
		A: 7.39,
		B: 0.38282,
		C: -0.001482,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 142,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 24,
		A: 7.051,
		B: 0.41649,
		C: -0.001705,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 142,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 25,
		A: 7.256,
		B: 0.43276,
		C: -0.001732,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 142,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 8,
		Sequence_x0020_Number: 26,
		A: 7.752,
		B: 0.42408,
		C: -0.00158,
		MinPSI: 30,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 142,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 1,
		A: 2.76,
		B: 0.0144,
		C: -0.0002,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 151,
		Nozzle1Size: 0.03,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 2,
		A: 2.73,
		B: 0.0227,
		C: -0.000172,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 151,
		Nozzle1Size: 0.035,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 3,
		A: 3.79,
		B: 0.0135,
		C: -0.000127,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 151,
		Nozzle1Size: 0.043,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 4,
		A: 3.52,
		B: 0.042,
		C: -0.000298,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 151,
		Nozzle1Size: 0.05,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 5,
		A: 4.53,
		B: 0.03165,
		C: -0.000221,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 151,
		Nozzle1Size: 0.055,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 6,
		A: 4.868,
		B: 0.04161,
		C: -0.000279,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 151,
		Nozzle1Size: 0.06,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 7,
		A: 5.25,
		B: 0.058,
		C: -0.000405,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 151,
		Nozzle1Size: 0.07,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 8,
		A: 6.33,
		B: 0.0588,
		C: -0.000416,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 151,
		Nozzle1Size: 0.08,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 9,
		A: 6.99,
		B: 0.05956,
		C: -0.000435,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 151,
		Nozzle1Size: 0.09,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 10,
		A: 7.04,
		B: 0.0788,
		C: -0.0005171,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 151,
		Nozzle1Size: 0.1,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 11,
		A: 7.54,
		B: 0.084,
		C: -0.0005969,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 152,
		Nozzle1Size: 0.05,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 12,
		A: 8.051,
		B: 0.07365,
		C: -0.000519,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 152,
		Nozzle1Size: 0.055,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 13,
		A: 8.262,
		B: 0.09051,
		C: -0.000649,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 152,
		Nozzle1Size: 0.06,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 14,
		A: 9.258,
		B: 0.10474,
		C: -0.000767,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 152,
		Nozzle1Size: 0.06,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 15,
		A: 9.821,
		B: 0.11166,
		C: -0.000795,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 152,
		Nozzle1Size: 0.07,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 16,
		A: 10.5,
		B: 0.116,
		C: -0.00081,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 152,
		Nozzle1Size: 0.07,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 17,
		A: 11.58,
		B: 0.1168,
		C: -0.000821,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 152,
		Nozzle1Size: 0.08,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 18,
		A: 12.66,
		B: 0.1176,
		C: -0.000832,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 152,
		Nozzle1Size: 0.08,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 19,
		A: 13.32,
		B: 0.11836,
		C: -0.000851,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 152,
		Nozzle1Size: 0.09,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 20,
		A: 13.98,
		B: 0.11912,
		C: -0.00087,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 152,
		Nozzle1Size: 0.09,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 21,
		A: 14.03,
		B: 0.13836,
		C: -0.000952,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 152,
		Nozzle1Size: 0.1,
	},
	{
		DeviceType: 9,
		Sequence_x0020_Number: 22,
		A: 14.08,
		B: 0.1576,
		C: -0.001034,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 152,
		Nozzle1Size: 0.1,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 1,
		A: 0.7607,
		B: 0.06601,
		C: -0.0003512,
		MinPSI: 35,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 171,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 2,
		A: 1.054,
		B: 0.07774,
		C: -0.000381,
		MinPSI: 35,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 171,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 3,
		A: 1.179,
		B: 0.10345,
		C: -0.0005595,
		MinPSI: 35,
		MaxPSI: 72,
		MaxSpace: 30,
		DevCode: 172,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 4,
		A: 1.371,
		B: 0.10827,
		C: -0.0005417,
		MinPSI: 35,
		MaxPSI: 74,
		MaxSpace: 30,
		DevCode: 172,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 5,
		A: 1.525,
		B: 0.11488,
		C: -0.0005238,
		MinPSI: 35,
		MaxPSI: 76,
		MaxSpace: 30,
		DevCode: 172,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 6,
		A: 1.704,
		B: 0.12131,
		C: -0.0005238,
		MinPSI: 35,
		MaxPSI: 78,
		MaxSpace: 30,
		DevCode: 172,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 7,
		A: 2.025,
		B: 0.12292,
		C: -0.0004702,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 30,
		DevCode: 172,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 8,
		A: 2.107,
		B: 0.13702,
		C: -0.0005595,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 173,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 9,
		A: 2.061,
		B: 0.15899,
		C: -0.0007202,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 173,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 10,
		A: 2.307,
		B: 0.16673,
		C: -0.000744,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 173,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 11,
		A: 2.5,
		B: 0.17881,
		C: -0.0008095,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 173,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 12,
		A: 2.636,
		B: 0.19071,
		C: -0.0008571,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 173,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 13,
		A: 2.764,
		B: 0.20583,
		C: -0.0009405,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 173,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 14,
		A: 2.921,
		B: 0.21613,
		C: -0.0009702,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 173,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 15,
		A: 3.068,
		B: 0.22923,
		C: -0.0010298,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 173,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 16,
		A: 3.229,
		B: 0.24077,
		C: -0.0010774,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 173,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 17,
		A: 3.536,
		B: 0.24792,
		C: -0.0010774,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 173,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 18,
		A: 3.882,
		B: 0.25566,
		C: -0.0011012,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 173,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 19,
		A: 4.132,
		B: 0.27018,
		C: -0.0011964,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 173,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 20,
		A: 4.182,
		B: 0.275,
		C: -0.00125,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 174,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 21,
		A: 4.207,
		B: 0.3106,
		C: -0.0014881,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 174,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 22,
		A: 4.579,
		B: 0.32619,
		C: -0.0015476,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 174,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 23,
		A: 4.986,
		B: 0.34095,
		C: -0.0015952,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 174,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 24,
		A: 5.329,
		B: 0.36185,
		C: -0.001756,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 174,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 25,
		A: 5.939,
		B: 0.3728,
		C: -0.0018155,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 174,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 26,
		A: 6.282,
		B: 0.38863,
		C: -0.0018988,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 174,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 27,
		A: 6.754,
		B: 0.39964,
		C: -0.0019286,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 174,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 28,
		A: 7.146,
		B: 0.42161,
		C: -0.002125,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 174,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 29,
		A: 7.643,
		B: 0.43845,
		C: -0.0022738,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 20,
		DevCode: 174,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 30,
		A: 8.157,
		B: 0.45732,
		C: -0.0024464,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 174,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 10,
		Sequence_x0020_Number: 31,
		A: 8.671,
		B: 0.47619,
		C: -0.0026191,
		MinPSI: 35,
		MaxPSI: 80,
		MaxSpace: 15,
		DevCode: 174,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 1,
		A: 0.7607,
		B: 0.06601,
		C: -0.0003512,
		MinPSI: 25,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 175,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 2,
		A: 1.054,
		B: 0.07774,
		C: -0.000381,
		MinPSI: 25,
		MaxPSI: 60,
		MaxSpace: 30,
		DevCode: 175,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 3,
		A: 1.179,
		B: 0.10345,
		C: -0.0005595,
		MinPSI: 25,
		MaxPSI: 65,
		MaxSpace: 30,
		DevCode: 176,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 4,
		A: 1.371,
		B: 0.10827,
		C: -0.0005417,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 30,
		DevCode: 176,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 5,
		A: 1.525,
		B: 0.11488,
		C: -0.0005238,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 30,
		DevCode: 176,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 6,
		A: 1.704,
		B: 0.12131,
		C: -0.0005238,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 30,
		DevCode: 176,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 7,
		A: 2.025,
		B: 0.12292,
		C: -0.0004702,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 30,
		DevCode: 176,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 8,
		A: 2.107,
		B: 0.13702,
		C: -0.0005595,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 30,
		DevCode: 177,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 9,
		A: 2.061,
		B: 0.15899,
		C: -0.0007202,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 177,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 10,
		A: 2.307,
		B: 0.16673,
		C: -0.000744,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 177,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 11,
		A: 2.5,
		B: 0.17881,
		C: -0.0008095,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 177,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 12,
		A: 2.636,
		B: 0.19071,
		C: -0.0008571,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 177,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 13,
		A: 2.764,
		B: 0.20583,
		C: -0.0009405,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 177,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 14,
		A: 2.921,
		B: 0.21613,
		C: -0.0009702,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 177,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 15,
		A: 3.068,
		B: 0.22923,
		C: -0.0010298,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 177,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 16,
		A: 3.229,
		B: 0.24077,
		C: -0.0010774,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 177,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 17,
		A: 3.536,
		B: 0.24792,
		C: -0.0010774,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 177,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 18,
		A: 3.882,
		B: 0.25566,
		C: -0.0011012,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 177,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 19,
		A: 4.132,
		B: 0.27018,
		C: -0.0011964,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 177,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 20,
		A: 4.182,
		B: 0.275,
		C: -0.00125,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 178,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 21,
		A: 4.207,
		B: 0.3106,
		C: -0.0014881,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 178,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 22,
		A: 4.579,
		B: 0.32619,
		C: -0.0015476,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 178,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 23,
		A: 4.986,
		B: 0.34095,
		C: -0.0015952,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 178,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 24,
		A: 5.329,
		B: 0.36185,
		C: -0.001756,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 178,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 25,
		A: 5.939,
		B: 0.3728,
		C: -0.0018155,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 178,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 26,
		A: 6.282,
		B: 0.38863,
		C: -0.0018988,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 178,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 27,
		A: 6.754,
		B: 0.39964,
		C: -0.0019286,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 178,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 28,
		A: 7.146,
		B: 0.42161,
		C: -0.002125,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 178,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 29,
		A: 7.643,
		B: 0.43845,
		C: -0.0022738,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 20,
		DevCode: 178,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 30,
		A: 8.157,
		B: 0.45732,
		C: -0.0024464,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 15,
		DevCode: 178,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 11,
		Sequence_x0020_Number: 31,
		A: 8.671,
		B: 0.47619,
		C: -0.0026191,
		MinPSI: 25,
		MaxPSI: 70,
		MaxSpace: 15,
		DevCode: 178,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 1,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 10,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 2,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 10,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 3,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 10,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 4,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 5,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 6,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 7,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 8,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 9,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 10,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 11,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 12,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 13,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 14,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 15,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 16,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 17,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 18,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 19,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 20,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 21,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 22,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 23,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 24,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 25,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 20,
		DevCode: 14,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 26,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 14,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 27,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 14,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 28,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 14,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 29,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 14,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 30,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 14,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 31,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 14,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 32,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 14,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 33,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 14,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 34,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 14,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 35,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 14,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 36,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 14,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 37,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 14,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 38,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 14,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 39,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 14,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 12,
		Sequence_x0020_Number: 40,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 14,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 1,
		A: 0.635,
		B: 0.05238,
		C: -0.000334,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 234,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 2,
		A: 0.736,
		B: 0.05861,
		C: -0.00035,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 234,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 3,
		A: 0.853,
		B: 0.06379,
		C: -0.000341,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 234,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 4,
		A: 1.004,
		B: 0.07192,
		C: -0.000425,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 234,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 5,
		A: 1.064,
		B: 0.08024,
		C: -0.000421,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 234,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 6,
		A: 1.18,
		B: 0.09075,
		C: -0.000482,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 234,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 7,
		A: 1.157,
		B: 0.10525,
		C: -0.0005554,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 234,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 8,
		A: 1.243,
		B: 0.11447,
		C: -0.0006161,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 234,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 9,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 234,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 10,
		A: 1.441,
		B: 0.1411,
		C: -0.0007446,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 234,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 11,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 12,
		A: 1.661,
		B: 0.17113,
		C: -0.0009125,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 13,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 14,
		A: 2.023,
		B: 0.19633,
		C: -0.0010304,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 15,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 16,
		A: 2.219,
		B: 0.2409,
		C: -0.0014554,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 17,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 18,
		A: 2.557,
		B: 0.27385,
		C: -0.0016661,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 19,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 20,
		A: 2.921,
		B: 0.30917,
		C: -0.0019232,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 21,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 22,
		A: 3.39,
		B: 0.33525,
		C: -0.0020036,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 23,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 24,
		A: 3.832,
		B: 0.36898,
		C: -0.0021964,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 25,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 26,
		A: 4.27,
		B: 0.40229,
		C: -0.0023429,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 27,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 28,
		A: 4.76,
		B: 0.43482,
		C: -0.0024464,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 29,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 30,
		A: 5.262,
		B: 0.46912,
		C: -0.0025679,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 31,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 32,
		A: 5.913,
		B: 0.49172,
		C: -0.0024482,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 33,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 34,
		A: 6.478,
		B: 0.52638,
		C: -0.0025393,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 35,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 234,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 36,
		A: 7.223,
		B: 0.54986,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 234,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 37,
		A: 7.598,
		B: 0.56363,
		C: -0.0024857,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 234,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 38,
		A: 8.001,
		B: 0.57688,
		C: -0.0024946,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 234,
		Nozzle1Size: 0.3984375,
	},
	{
		DeviceType: 13,
		Sequence_x0020_Number: 39,
		A: 8.377,
		B: 0.592,
		C: -0.0025375,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 234,
		Nozzle1Size: 0.40625,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 1,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 15,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 2,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 15,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 3,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 15,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 4,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 15,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 5,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 6,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 7,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 8,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 9,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 10,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 11,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 12,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 13,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 14,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 15,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 16,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 17,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 18,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 19,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 20,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 21,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 22,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 23,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 24,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 25,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 26,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 27,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 28,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 29,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 30,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 31,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 32,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 33,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 34,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 35,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 36,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 14,
		Sequence_x0020_Number: 37,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 191,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 1,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 15,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 2,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 15,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 3,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 15,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 4,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 15,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 5,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 6,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 7,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 8,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 9,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 10,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 11,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 12,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 13,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 14,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 14,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 15,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 16,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 17,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 18,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 19,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 20,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 21,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 22,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 23,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 24,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 25,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 26,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 27,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 28,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 29,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 30,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 31,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 13,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 192,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 32,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 33,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 34,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 35,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 36,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 15,
		Sequence_x0020_Number: 37,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 1,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 15,
		MaxPSI: 23,
		MaxSpace: 19,
		DevCode: 193,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 2,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 15,
		MaxPSI: 23,
		MaxSpace: 19,
		DevCode: 193,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 3,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 15,
		MaxPSI: 23,
		MaxSpace: 19,
		DevCode: 193,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 4,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 15,
		MaxPSI: 23,
		MaxSpace: 19,
		DevCode: 193,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 5,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 15,
		MaxPSI: 23,
		MaxSpace: 19,
		DevCode: 193,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 6,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 15,
		MaxPSI: 23,
		MaxSpace: 19,
		DevCode: 193,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 7,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 15,
		MaxPSI: 23,
		MaxSpace: 19,
		DevCode: 193,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 8,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 14,
		MaxPSI: 22,
		MaxSpace: 18,
		DevCode: 193,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 9,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 14,
		MaxPSI: 22,
		MaxSpace: 18,
		DevCode: 193,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 10,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 14,
		MaxPSI: 22,
		MaxSpace: 18,
		DevCode: 193,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 11,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 14,
		MaxPSI: 22,
		MaxSpace: 18,
		DevCode: 193,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 12,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 14,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 193,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 13,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 14,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 193,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 14,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 14,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 193,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 15,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 14,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 193,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 16,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 14,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 193,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 17,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 14,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 193,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 18,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 14,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 193,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 19,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 14,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 193,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 20,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 14,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 193,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 21,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 14,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 193,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 22,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 14,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 193,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 23,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 14,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 193,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 24,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 25,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 26,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 27,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 28,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 29,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 30,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 31,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 32,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 33,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 34,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 35,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 36,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 16,
		Sequence_x0020_Number: 37,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 1,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 2,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 3,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 4,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 5,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 6,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 7,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 8,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 9,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 10,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 11,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 12,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 13,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 14,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 15,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 16,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 17,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 18,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 19,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 20,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 21,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 22,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 23,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 24,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 25,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 26,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 27,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 28,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 29,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 30,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 31,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 32,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 33,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 34,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 17,
		Sequence_x0020_Number: 35,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 192,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 1,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 10,
		MaxPSI: 23,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 2,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 10,
		MaxPSI: 23,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 3,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 10,
		MaxPSI: 23,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 4,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 10,
		MaxPSI: 23,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 5,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 10,
		MaxPSI: 23,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 6,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 9,
		MaxPSI: 22,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 7,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 9,
		MaxPSI: 22,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 8,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 9,
		MaxPSI: 22,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 9,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 9,
		MaxPSI: 22,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 10,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 9,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 11,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 9,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 12,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 9,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 13,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 9,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 14,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 9,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 15,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 9,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 16,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 9,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 17,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 9,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 18,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 9,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 19,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 9,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 20,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 9,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 21,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 9,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 22,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 23,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 24,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 25,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 26,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 27,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 28,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 29,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 30,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 31,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 32,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 33,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 34,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 8,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 18,
		Sequence_x0020_Number: 35,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 8,
		MaxPSI: 24,
		MaxSpace: 11,
		DevCode: 193,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 1,
		A: 0.2474,
		B: 0.0207,
		C: -0.000125,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.0703125,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 2,
		A: 0.305,
		B: 0.02551,
		C: -0.000154,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 3,
		A: 0.3673,
		B: 0.03073,
		C: -0.000185,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 4,
		A: 0.4461,
		B: 0.03732,
		C: -0.000225,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 5,
		A: 0.5224,
		B: 0.0437,
		C: -0.000263,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 6,
		A: 0.5993,
		B: 0.05014,
		C: -0.000302,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 7,
		A: 0.6996,
		B: 0.05852,
		C: -0.000352,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 8,
		A: 0.7935,
		B: 0.06639,
		C: -0.0004,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 9,
		A: 0.8961,
		B: 0.07497,
		C: -0.000452,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 10,
		A: 0.9932,
		B: 0.08309,
		C: -0.0005,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 11,
		A: 1.118,
		B: 0.09357,
		C: -0.000564,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 12,
		A: 1.244,
		B: 0.1041,
		C: -0.000627,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 13,
		A: 1.349,
		B: 0.11289,
		C: -0.00068,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 14,
		A: 1.495,
		B: 0.1251,
		C: -0.000754,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 15,
		A: 1.624,
		B: 0.13583,
		C: -0.000818,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 16,
		A: 1.791,
		B: 0.1498,
		C: -0.000902,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 17,
		A: 1.932,
		B: 0.16168,
		C: -0.000974,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 18,
		A: 2.1,
		B: 0.17572,
		C: -0.001058,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 19,
		A: 2.252,
		B: 0.18838,
		C: -0.001135,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 20,
		A: 2.452,
		B: 0.20515,
		C: -0.001236,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 21,
		A: 2.621,
		B: 0.21928,
		C: -0.001321,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 22,
		A: 2.806,
		B: 0.23475,
		C: -0.001414,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 23,
		A: 2.971,
		B: 0.24858,
		C: -0.001497,
		MinPSI: 6,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 24,
		A: 3.195,
		B: 0.26725,
		C: -0.00161,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 25,
		A: 3.405,
		B: 0.28486,
		C: -0.001716,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 26,
		A: 3.617,
		B: 0.30262,
		C: -0.001823,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 27,
		A: 3.812,
		B: 0.31893,
		C: -0.001921,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 28,
		A: 4.006,
		B: 0.33515,
		C: -0.002019,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 29,
		A: 4.28,
		B: 0.35805,
		C: -0.002157,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 217,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 30,
		A: 4.526,
		B: 0.3786,
		C: -0.00228,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 31,
		A: 4.778,
		B: 0.39969,
		C: -0.002408,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 32,
		A: 5.021,
		B: 0.42007,
		C: -0.00253,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 33,
		A: 5.319,
		B: 0.44498,
		C: -0.00268,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 34,
		A: 5.565,
		B: 0.4656,
		C: -0.002804,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 35,
		A: 5.833,
		B: 0.48797,
		C: -0.002939,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 36,
		A: 6.101,
		B: 0.51037,
		C: -0.003074,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 37,
		A: 6.394,
		B: 0.5349,
		C: -0.003222,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 38,
		A: 6.678,
		B: 0.55868,
		C: -0.003365,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 39,
		A: 7.017,
		B: 0.58701,
		C: -0.003536,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 40,
		A: 7.298,
		B: 0.61057,
		C: -0.003678,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 41,
		A: 7.553,
		B: 0.63192,
		C: -0.003806,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 19,
		Sequence_x0020_Number: 42,
		A: 7.893,
		B: 0.66029,
		C: -0.003977,
		MinPSI: 5,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 216,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 1,
		A: 1.194,
		B: 0.03619,
		C: -0.0003437,
		MinPSI: 25,
		MaxPSI: 54,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.02,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 2,
		A: 1.583,
		B: 0.03488,
		C: -0.0002843,
		MinPSI: 25,
		MaxPSI: 54,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.025,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 3,
		A: 2.093,
		B: 0.04289,
		C: -0.0004662,
		MinPSI: 25,
		MaxPSI: 53,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.03,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 4,
		A: 2.233,
		B: 0.04584,
		C: -0.0003833,
		MinPSI: 25,
		MaxPSI: 53,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.035,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 5,
		A: 2.555,
		B: 0.05183,
		C: -0.0004283,
		MinPSI: 25,
		MaxPSI: 52,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.04,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 6,
		A: 2.655,
		B: 0.06679,
		C: -0.0006492,
		MinPSI: 25,
		MaxPSI: 52,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.045,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 7,
		A: 3.418,
		B: 0.05366,
		C: -0.0004354,
		MinPSI: 25,
		MaxPSI: 51,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.05,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 8,
		A: 3.857,
		B: 0.06372,
		C: -0.0005258,
		MinPSI: 25,
		MaxPSI: 51,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.055,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 9,
		A: 3.924,
		B: 0.08,
		C: -0.0006099,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.06,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 10,
		A: 5.205,
		B: 0.06232,
		C: -0.0004795,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.07,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 11,
		A: 4.85,
		B: 0.13119,
		C: -0.0012423,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.08,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 12,
		A: 5.487,
		B: 0.12257,
		C: -0.0010722,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.09,
	},
	{
		DeviceType: 20,
		Sequence_x0020_Number: 13,
		A: 5.724,
		B: 0.15724,
		C: -0.0014527,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 11,
		DevCode: 221,
		Nozzle1Size: 0.1,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 1,
		A: 0.5993,
		B: 0.05014,
		C: -0.000302,
		MinPSI: 15,
		MaxPSI: 44,
		MaxSpace: 24,
		DevCode: 183,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 2,
		A: 0.6996,
		B: 0.05852,
		C: -0.000352,
		MinPSI: 15,
		MaxPSI: 44,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 3,
		A: 0.7935,
		B: 0.06639,
		C: -0.0004,
		MinPSI: 14,
		MaxPSI: 44,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 4,
		A: 0.8961,
		B: 0.07497,
		C: -0.000452,
		MinPSI: 14,
		MaxPSI: 43,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 5,
		A: 0.9932,
		B: 0.08309,
		C: -0.0005,
		MinPSI: 14,
		MaxPSI: 43,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 6,
		A: 1.118,
		B: 0.09357,
		C: -0.000564,
		MinPSI: 14,
		MaxPSI: 43,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 7,
		A: 1.244,
		B: 0.1041,
		C: -0.000627,
		MinPSI: 14,
		MaxPSI: 42,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 8,
		A: 1.349,
		B: 0.11289,
		C: -0.00068,
		MinPSI: 14,
		MaxPSI: 42,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 9,
		A: 1.495,
		B: 0.1251,
		C: -0.000754,
		MinPSI: 14,
		MaxPSI: 42,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 10,
		A: 1.624,
		B: 0.13583,
		C: -0.000818,
		MinPSI: 14,
		MaxPSI: 42,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 11,
		A: 1.791,
		B: 0.1498,
		C: -0.000902,
		MinPSI: 13,
		MaxPSI: 34,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 12,
		A: 1.932,
		B: 0.16168,
		C: -0.000974,
		MinPSI: 13,
		MaxPSI: 34,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 13,
		A: 2.1,
		B: 0.17572,
		C: -0.001058,
		MinPSI: 13,
		MaxPSI: 34,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 14,
		A: 2.252,
		B: 0.18838,
		C: -0.001135,
		MinPSI: 13,
		MaxPSI: 34,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 15,
		A: 2.452,
		B: 0.20515,
		C: -0.001236,
		MinPSI: 13,
		MaxPSI: 33,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 16,
		A: 2.621,
		B: 0.21928,
		C: -0.001321,
		MinPSI: 13,
		MaxPSI: 33,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 17,
		A: 2.806,
		B: 0.23475,
		C: -0.001414,
		MinPSI: 13,
		MaxPSI: 33,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 18,
		A: 2.971,
		B: 0.24858,
		C: -0.001497,
		MinPSI: 13,
		MaxPSI: 33,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 19,
		A: 3.195,
		B: 0.26725,
		C: -0.00161,
		MinPSI: 13,
		MaxPSI: 33,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 20,
		A: 3.405,
		B: 0.28486,
		C: -0.001716,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 21,
		A: 3.617,
		B: 0.30262,
		C: -0.001823,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 22,
		A: 3.812,
		B: 0.31893,
		C: -0.001921,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 23,
		A: 4.006,
		B: 0.33515,
		C: -0.002019,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 24,
		A: 4.28,
		B: 0.35805,
		C: -0.002157,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 25,
		A: 4.526,
		B: 0.3786,
		C: -0.00228,
		MinPSI: 13,
		MaxPSI: 31,
		MaxSpace: 22,
		DevCode: 183,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 26,
		A: 5.021,
		B: 0.42007,
		C: -0.00253,
		MinPSI: 13,
		MaxPSI: 31,
		MaxSpace: 18.5,
		DevCode: 183,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 27,
		A: 5.565,
		B: 0.4656,
		C: -0.002804,
		MinPSI: 13,
		MaxPSI: 31,
		MaxSpace: 18.5,
		DevCode: 183,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 28,
		A: 6.101,
		B: 0.51037,
		C: -0.003074,
		MinPSI: 13,
		MaxPSI: 31,
		MaxSpace: 18.5,
		DevCode: 183,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 29,
		A: 6.678,
		B: 0.55868,
		C: -0.003365,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 183,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 30,
		A: 7.298,
		B: 0.61057,
		C: -0.003678,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 183,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 21,
		Sequence_x0020_Number: 31,
		A: 7.893,
		B: 0.66029,
		C: -0.003977,
		MinPSI: 13,
		MaxPSI: 30,
		MaxSpace: 11,
		DevCode: 183,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 1,
		A: 0.5993,
		B: 0.05014,
		C: -0.000302,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 28,
		DevCode: 181,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 2,
		A: 0.6996,
		B: 0.05852,
		C: -0.000352,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 3,
		A: 0.7935,
		B: 0.06639,
		C: -0.0004,
		MinPSI: 20,
		MaxPSI: 60,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 4,
		A: 0.8961,
		B: 0.07497,
		C: -0.000452,
		MinPSI: 20,
		MaxPSI: 60,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 5,
		A: 0.9932,
		B: 0.08309,
		C: -0.0005,
		MinPSI: 20,
		MaxPSI: 58,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 6,
		A: 1.118,
		B: 0.09357,
		C: -0.000564,
		MinPSI: 20,
		MaxPSI: 58,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 7,
		A: 1.244,
		B: 0.1041,
		C: -0.000627,
		MinPSI: 20,
		MaxPSI: 56,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 8,
		A: 1.349,
		B: 0.11289,
		C: -0.00068,
		MinPSI: 20,
		MaxPSI: 56,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 9,
		A: 1.495,
		B: 0.1251,
		C: -0.000754,
		MinPSI: 19,
		MaxPSI: 56,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 10,
		A: 1.624,
		B: 0.13583,
		C: -0.000818,
		MinPSI: 19,
		MaxPSI: 56,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 11,
		A: 1.791,
		B: 0.1498,
		C: -0.000902,
		MinPSI: 19,
		MaxPSI: 54,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 12,
		A: 1.932,
		B: 0.16168,
		C: -0.000974,
		MinPSI: 19,
		MaxPSI: 54,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 13,
		A: 2.1,
		B: 0.17572,
		C: -0.001058,
		MinPSI: 19,
		MaxPSI: 54,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 14,
		A: 2.252,
		B: 0.18838,
		C: -0.001135,
		MinPSI: 19,
		MaxPSI: 52,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 15,
		A: 2.452,
		B: 0.20515,
		C: -0.001236,
		MinPSI: 18,
		MaxPSI: 52,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 16,
		A: 2.621,
		B: 0.21928,
		C: -0.001321,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 17,
		A: 2.806,
		B: 0.23475,
		C: -0.001414,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 18,
		A: 2.971,
		B: 0.24858,
		C: -0.001497,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 19,
		A: 3.195,
		B: 0.26725,
		C: -0.00161,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 20,
		A: 3.405,
		B: 0.28486,
		C: -0.001716,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 21,
		A: 3.617,
		B: 0.30262,
		C: -0.001823,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 22,
		A: 3.812,
		B: 0.31893,
		C: -0.001921,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 23,
		A: 4.006,
		B: 0.33515,
		C: -0.002019,
		MinPSI: 13,
		MaxPSI: 45,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 24,
		A: 4.28,
		B: 0.35805,
		C: -0.002157,
		MinPSI: 13,
		MaxPSI: 45,
		MaxSpace: 21.6,
		DevCode: 181,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 25,
		A: 4.526,
		B: 0.3786,
		C: -0.00228,
		MinPSI: 13,
		MaxPSI: 42,
		MaxSpace: 22.6,
		DevCode: 181,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 26,
		A: 5.021,
		B: 0.42007,
		C: -0.00253,
		MinPSI: 13,
		MaxPSI: 42,
		MaxSpace: 18.5,
		DevCode: 181,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 27,
		A: 5.565,
		B: 0.4656,
		C: -0.002804,
		MinPSI: 13,
		MaxPSI: 42,
		MaxSpace: 18.5,
		DevCode: 181,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 28,
		A: 6.101,
		B: 0.51037,
		C: -0.003074,
		MinPSI: 13,
		MaxPSI: 42,
		MaxSpace: 18.5,
		DevCode: 181,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 29,
		A: 6.678,
		B: 0.55868,
		C: -0.003365,
		MinPSI: 13,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 181,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 30,
		A: 7.298,
		B: 0.61057,
		C: -0.003678,
		MinPSI: 13,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 181,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 22,
		Sequence_x0020_Number: 31,
		A: 7.893,
		B: 0.66029,
		C: -0.003977,
		MinPSI: 13,
		MaxPSI: 40,
		MaxSpace: 11,
		DevCode: 181,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 1,
		A: 1.194,
		B: 0.03619,
		C: -0.0003437,
		MinPSI: 25,
		MaxPSI: 54,
		MaxSpace: 28,
		DevCode: 184,
		Nozzle1Size: 0.02,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 2,
		A: 1.583,
		B: 0.03488,
		C: -0.0002843,
		MinPSI: 25,
		MaxPSI: 54,
		MaxSpace: 22,
		DevCode: 184,
		Nozzle1Size: 0.025,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 3,
		A: 2.093,
		B: 0.04289,
		C: -0.0004662,
		MinPSI: 25,
		MaxPSI: 53,
		MaxSpace: 22,
		DevCode: 184,
		Nozzle1Size: 0.03,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 4,
		A: 2.233,
		B: 0.04584,
		C: -0.0003833,
		MinPSI: 25,
		MaxPSI: 53,
		MaxSpace: 22,
		DevCode: 184,
		Nozzle1Size: 0.035,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 5,
		A: 2.555,
		B: 0.05183,
		C: -0.0004283,
		MinPSI: 25,
		MaxPSI: 52,
		MaxSpace: 22,
		DevCode: 184,
		Nozzle1Size: 0.04,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 6,
		A: 2.655,
		B: 0.06679,
		C: -0.0006492,
		MinPSI: 25,
		MaxPSI: 52,
		MaxSpace: 22,
		DevCode: 184,
		Nozzle1Size: 0.045,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 7,
		A: 3.418,
		B: 0.05366,
		C: -0.0004354,
		MinPSI: 25,
		MaxPSI: 51,
		MaxSpace: 21,
		DevCode: 184,
		Nozzle1Size: 0.05,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 8,
		A: 3.857,
		B: 0.06372,
		C: -0.0005258,
		MinPSI: 25,
		MaxPSI: 51,
		MaxSpace: 20,
		DevCode: 184,
		Nozzle1Size: 0.055,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 9,
		A: 3.924,
		B: 0.08,
		C: -0.0006099,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 19,
		DevCode: 184,
		Nozzle1Size: 0.06,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 10,
		A: 5.205,
		B: 0.06232,
		C: -0.0004795,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 18,
		DevCode: 184,
		Nozzle1Size: 0.07,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 11,
		A: 4.85,
		B: 0.13119,
		C: -0.0012423,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 17,
		DevCode: 184,
		Nozzle1Size: 0.08,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 12,
		A: 5.487,
		B: 0.12257,
		C: -0.0010722,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 16,
		DevCode: 184,
		Nozzle1Size: 0.09,
	},
	{
		DeviceType: 23,
		Sequence_x0020_Number: 13,
		A: 5.724,
		B: 0.15724,
		C: -0.0014527,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 15,
		DevCode: 184,
		Nozzle1Size: 0.1,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 1,
		A: 0.5993,
		B: 0.05014,
		C: -0.000302,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 28,
		DevCode: 182,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 2,
		A: 0.6996,
		B: 0.05852,
		C: -0.000352,
		MinPSI: 30,
		MaxPSI: 60,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 3,
		A: 0.7935,
		B: 0.06639,
		C: -0.0004,
		MinPSI: 20,
		MaxPSI: 60,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 4,
		A: 0.8961,
		B: 0.07497,
		C: -0.000452,
		MinPSI: 20,
		MaxPSI: 60,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 5,
		A: 0.9932,
		B: 0.08309,
		C: -0.0005,
		MinPSI: 20,
		MaxPSI: 58,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 6,
		A: 1.118,
		B: 0.09357,
		C: -0.000564,
		MinPSI: 20,
		MaxPSI: 58,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 7,
		A: 1.244,
		B: 0.1041,
		C: -0.000627,
		MinPSI: 20,
		MaxPSI: 56,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 8,
		A: 1.349,
		B: 0.11289,
		C: -0.00068,
		MinPSI: 20,
		MaxPSI: 56,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 9,
		A: 1.495,
		B: 0.1251,
		C: -0.000754,
		MinPSI: 19,
		MaxPSI: 56,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 10,
		A: 1.624,
		B: 0.13583,
		C: -0.000818,
		MinPSI: 19,
		MaxPSI: 56,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 11,
		A: 1.791,
		B: 0.1498,
		C: -0.000902,
		MinPSI: 19,
		MaxPSI: 54,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 12,
		A: 1.932,
		B: 0.16168,
		C: -0.000974,
		MinPSI: 19,
		MaxPSI: 54,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 13,
		A: 2.1,
		B: 0.17572,
		C: -0.001058,
		MinPSI: 19,
		MaxPSI: 54,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 14,
		A: 2.252,
		B: 0.18838,
		C: -0.001135,
		MinPSI: 19,
		MaxPSI: 52,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 15,
		A: 2.452,
		B: 0.20515,
		C: -0.001236,
		MinPSI: 18,
		MaxPSI: 52,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 16,
		A: 2.621,
		B: 0.21928,
		C: -0.001321,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 17,
		A: 2.806,
		B: 0.23475,
		C: -0.001414,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 18,
		A: 2.971,
		B: 0.24858,
		C: -0.001497,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 19,
		A: 3.195,
		B: 0.26725,
		C: -0.00161,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 20,
		A: 3.405,
		B: 0.28486,
		C: -0.001716,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 21,
		A: 3.617,
		B: 0.30262,
		C: -0.001823,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 22,
		A: 3.812,
		B: 0.31893,
		C: -0.001921,
		MinPSI: 13,
		MaxPSI: 50,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 23,
		A: 4.006,
		B: 0.33515,
		C: -0.002019,
		MinPSI: 13,
		MaxPSI: 45,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 24,
		A: 4.28,
		B: 0.35805,
		C: -0.002157,
		MinPSI: 13,
		MaxPSI: 45,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 25,
		A: 4.526,
		B: 0.3786,
		C: -0.00228,
		MinPSI: 13,
		MaxPSI: 42,
		MaxSpace: 22,
		DevCode: 182,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 26,
		A: 5.021,
		B: 0.42007,
		C: -0.00253,
		MinPSI: 13,
		MaxPSI: 42,
		MaxSpace: 18.5,
		DevCode: 182,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 27,
		A: 5.565,
		B: 0.4656,
		C: -0.002804,
		MinPSI: 13,
		MaxPSI: 42,
		MaxSpace: 18.5,
		DevCode: 182,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 24,
		Sequence_x0020_Number: 28,
		A: 6.101,
		B: 0.51037,
		C: -0.003074,
		MinPSI: 13,
		MaxPSI: 42,
		MaxSpace: 18.5,
		DevCode: 182,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 1,
		A: 1.194,
		B: 0.03619,
		C: -0.0003437,
		MinPSI: 25,
		MaxPSI: 54,
		MaxSpace: 28,
		DevCode: 185,
		Nozzle1Size: 0.02,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 2,
		A: 1.583,
		B: 0.03488,
		C: -0.0002843,
		MinPSI: 25,
		MaxPSI: 54,
		MaxSpace: 22,
		DevCode: 185,
		Nozzle1Size: 0.025,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 3,
		A: 2.093,
		B: 0.04289,
		C: -0.0004662,
		MinPSI: 25,
		MaxPSI: 53,
		MaxSpace: 22,
		DevCode: 185,
		Nozzle1Size: 0.03,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 4,
		A: 2.233,
		B: 0.04584,
		C: -0.0003833,
		MinPSI: 25,
		MaxPSI: 53,
		MaxSpace: 22,
		DevCode: 185,
		Nozzle1Size: 0.035,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 5,
		A: 2.555,
		B: 0.05183,
		C: -0.0004283,
		MinPSI: 25,
		MaxPSI: 52,
		MaxSpace: 22,
		DevCode: 185,
		Nozzle1Size: 0.04,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 6,
		A: 2.655,
		B: 0.06679,
		C: -0.0006492,
		MinPSI: 25,
		MaxPSI: 52,
		MaxSpace: 22,
		DevCode: 185,
		Nozzle1Size: 0.045,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 7,
		A: 3.418,
		B: 0.05366,
		C: -0.0004354,
		MinPSI: 25,
		MaxPSI: 51,
		MaxSpace: 21,
		DevCode: 185,
		Nozzle1Size: 0.05,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 8,
		A: 3.857,
		B: 0.06372,
		C: -0.0005258,
		MinPSI: 25,
		MaxPSI: 51,
		MaxSpace: 20,
		DevCode: 185,
		Nozzle1Size: 0.055,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 9,
		A: 3.924,
		B: 0.08,
		C: -0.0006099,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 19,
		DevCode: 185,
		Nozzle1Size: 0.06,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 10,
		A: 5.205,
		B: 0.06232,
		C: -0.0004795,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 18,
		DevCode: 185,
		Nozzle1Size: 0.07,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 11,
		A: 4.85,
		B: 0.13119,
		C: -0.0012423,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 17,
		DevCode: 185,
		Nozzle1Size: 0.08,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 12,
		A: 5.487,
		B: 0.12257,
		C: -0.0010722,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 16,
		DevCode: 185,
		Nozzle1Size: 0.09,
	},
	{
		DeviceType: 25,
		Sequence_x0020_Number: 13,
		A: 5.724,
		B: 0.15724,
		C: -0.0014527,
		MinPSI: 25,
		MaxPSI: 50,
		MaxSpace: 15,
		DevCode: 185,
		Nozzle1Size: 0.1,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 1,
		A: 0.5993,
		B: 0.05014,
		C: -0.000302,
		MinPSI: 15,
		MaxPSI: 31,
		MaxSpace: 24,
		DevCode: 2,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 2,
		A: 0.6996,
		B: 0.05852,
		C: -0.000352,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 3,
		A: 0.7935,
		B: 0.06639,
		C: -0.0004,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 4,
		A: 0.8961,
		B: 0.07497,
		C: -0.000452,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 5,
		A: 0.9932,
		B: 0.08309,
		C: -0.0005,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 6,
		A: 1.118,
		B: 0.09357,
		C: -0.000564,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 7,
		A: 1.244,
		B: 0.1041,
		C: -0.000627,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 8,
		A: 1.349,
		B: 0.11289,
		C: -0.00068,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 9,
		A: 1.495,
		B: 0.1251,
		C: -0.000754,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 10,
		A: 1.624,
		B: 0.13583,
		C: -0.000818,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 11,
		A: 1.791,
		B: 0.1498,
		C: -0.000902,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 12,
		A: 1.932,
		B: 0.16168,
		C: -0.000974,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 13,
		A: 2.1,
		B: 0.17572,
		C: -0.001058,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 14,
		A: 2.252,
		B: 0.18838,
		C: -0.001135,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 15,
		A: 2.452,
		B: 0.20515,
		C: -0.001236,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 16,
		A: 2.621,
		B: 0.21928,
		C: -0.001321,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 17,
		A: 2.806,
		B: 0.23475,
		C: -0.001414,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 18,
		A: 2.971,
		B: 0.24858,
		C: -0.001497,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 19,
		A: 3.195,
		B: 0.26725,
		C: -0.00161,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 20,
		A: 3.405,
		B: 0.28486,
		C: -0.001716,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 21,
		A: 3.617,
		B: 0.30262,
		C: -0.001823,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 22,
		A: 3.812,
		B: 0.31893,
		C: -0.001921,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 23,
		A: 4.006,
		B: 0.33515,
		C: -0.002019,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 24,
		A: 4.28,
		B: 0.35805,
		C: -0.002157,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 25,
		A: 4.526,
		B: 0.3786,
		C: -0.00228,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 22,
		DevCode: 2,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 26,
		A: 5.021,
		B: 0.42007,
		C: -0.00253,
		MinPSI: 13,
		MaxPSI: 28,
		MaxSpace: 14.5,
		DevCode: 2,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 27,
		A: 5.565,
		B: 0.4656,
		C: -0.002804,
		MinPSI: 13,
		MaxPSI: 28,
		MaxSpace: 14.5,
		DevCode: 2,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 28,
		A: 6.101,
		B: 0.51037,
		C: -0.003074,
		MinPSI: 13,
		MaxPSI: 28,
		MaxSpace: 14.5,
		DevCode: 2,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 29,
		A: 6.678,
		B: 0.55868,
		C: -0.003365,
		MinPSI: 13,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 2,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 30,
		A: 7.298,
		B: 0.61057,
		C: -0.003678,
		MinPSI: 13,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 2,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 26,
		Sequence_x0020_Number: 31,
		A: 7.893,
		B: 0.66029,
		C: -0.003977,
		MinPSI: 13,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 2,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 1,
		A: 0.5993,
		B: 0.05014,
		C: -0.000302,
		MinPSI: 15,
		MaxPSI: 31,
		MaxSpace: 24,
		DevCode: 186,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 2,
		A: 0.6996,
		B: 0.05852,
		C: -0.000352,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 3,
		A: 0.7935,
		B: 0.06639,
		C: -0.0004,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 4,
		A: 0.8961,
		B: 0.07497,
		C: -0.000452,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 5,
		A: 0.9932,
		B: 0.08309,
		C: -0.0005,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 6,
		A: 1.118,
		B: 0.09357,
		C: -0.000564,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 7,
		A: 1.244,
		B: 0.1041,
		C: -0.000627,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 8,
		A: 1.349,
		B: 0.11289,
		C: -0.00068,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 9,
		A: 1.495,
		B: 0.1251,
		C: -0.000754,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 10,
		A: 1.624,
		B: 0.13583,
		C: -0.000818,
		MinPSI: 15,
		MaxPSI: 30,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 11,
		A: 1.791,
		B: 0.1498,
		C: -0.000902,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 12,
		A: 1.932,
		B: 0.16168,
		C: -0.000974,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 13,
		A: 2.1,
		B: 0.17572,
		C: -0.001058,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 14,
		A: 2.252,
		B: 0.18838,
		C: -0.001135,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 15,
		A: 2.452,
		B: 0.20515,
		C: -0.001236,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 16,
		A: 2.621,
		B: 0.21928,
		C: -0.001321,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 17,
		A: 2.806,
		B: 0.23475,
		C: -0.001414,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 18,
		A: 2.971,
		B: 0.24858,
		C: -0.001497,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 19,
		A: 3.195,
		B: 0.26725,
		C: -0.00161,
		MinPSI: 14,
		MaxPSI: 29,
		MaxSpace: 21,
		DevCode: 186,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 20,
		A: 3.405,
		B: 0.28486,
		C: -0.001716,
		MinPSI: 14,
		MaxPSI: 28,
		MaxSpace: 14,
		DevCode: 186,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 21,
		A: 3.617,
		B: 0.30262,
		C: -0.001823,
		MinPSI: 14,
		MaxPSI: 28,
		MaxSpace: 14,
		DevCode: 186,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 22,
		A: 3.812,
		B: 0.31893,
		C: -0.001921,
		MinPSI: 14,
		MaxPSI: 28,
		MaxSpace: 14,
		DevCode: 186,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 23,
		A: 4.006,
		B: 0.33515,
		C: -0.002019,
		MinPSI: 14,
		MaxPSI: 28,
		MaxSpace: 14,
		DevCode: 186,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 24,
		A: 4.28,
		B: 0.35805,
		C: -0.002157,
		MinPSI: 14,
		MaxPSI: 28,
		MaxSpace: 14,
		DevCode: 186,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 25,
		A: 4.526,
		B: 0.3786,
		C: -0.00228,
		MinPSI: 14,
		MaxPSI: 28,
		MaxSpace: 14,
		DevCode: 186,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 26,
		A: 4.778,
		B: 0.39969,
		C: -0.002408,
		MinPSI: 14,
		MaxPSI: 28,
		MaxSpace: 14,
		DevCode: 186,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 27,
		A: 5.021,
		B: 0.42007,
		C: -0.00253,
		MinPSI: 14,
		MaxPSI: 28,
		MaxSpace: 14,
		DevCode: 186,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 28,
		A: 5.319,
		B: 0.44498,
		C: -0.00268,
		MinPSI: 14,
		MaxPSI: 28,
		MaxSpace: 14,
		DevCode: 186,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 29,
		A: 5.565,
		B: 0.4656,
		C: -0.002804,
		MinPSI: 14,
		MaxPSI: 28,
		MaxSpace: 14,
		DevCode: 186,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 30,
		A: 5.833,
		B: 0.48797,
		C: -0.002939,
		MinPSI: 14,
		MaxPSI: 28,
		MaxSpace: 14,
		DevCode: 186,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 31,
		A: 6.101,
		B: 0.51037,
		C: -0.003074,
		MinPSI: 14,
		MaxPSI: 28,
		MaxSpace: 14,
		DevCode: 186,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 32,
		A: 6.394,
		B: 0.5349,
		C: -0.003222,
		MinPSI: 13,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 186,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 33,
		A: 6.678,
		B: 0.55868,
		C: -0.003365,
		MinPSI: 13,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 186,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 34,
		A: 7.017,
		B: 0.58701,
		C: -0.003536,
		MinPSI: 13,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 186,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 35,
		A: 7.298,
		B: 0.61057,
		C: -0.003678,
		MinPSI: 13,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 186,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 36,
		A: 7.553,
		B: 0.63192,
		C: -0.003806,
		MinPSI: 13,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 186,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 27,
		Sequence_x0020_Number: 37,
		A: 7.893,
		B: 0.66029,
		C: -0.003977,
		MinPSI: 13,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 186,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 1,
		A: 0.5993,
		B: 0.05014,
		C: -0.000302,
		MinPSI: 15,
		MaxPSI: 34,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 2,
		A: 0.6996,
		B: 0.05852,
		C: -0.000352,
		MinPSI: 15,
		MaxPSI: 34,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 3,
		A: 0.7935,
		B: 0.06639,
		C: -0.0004,
		MinPSI: 15,
		MaxPSI: 34,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 4,
		A: 0.8961,
		B: 0.07497,
		C: -0.000452,
		MinPSI: 15,
		MaxPSI: 34,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 5,
		A: 0.9932,
		B: 0.08309,
		C: -0.0005,
		MinPSI: 15,
		MaxPSI: 34,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 6,
		A: 1.118,
		B: 0.09357,
		C: -0.000564,
		MinPSI: 15,
		MaxPSI: 34,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 7,
		A: 1.244,
		B: 0.1041,
		C: -0.000627,
		MinPSI: 15,
		MaxPSI: 34,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 8,
		A: 1.349,
		B: 0.11289,
		C: -0.00068,
		MinPSI: 15,
		MaxPSI: 34,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 9,
		A: 1.495,
		B: 0.1251,
		C: -0.000754,
		MinPSI: 15,
		MaxPSI: 34,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 10,
		A: 1.624,
		B: 0.13583,
		C: -0.000818,
		MinPSI: 15,
		MaxPSI: 34,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 11,
		A: 1.791,
		B: 0.1498,
		C: -0.000902,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 12,
		A: 1.932,
		B: 0.16168,
		C: -0.000974,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 13,
		A: 2.1,
		B: 0.17572,
		C: -0.001058,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 14,
		A: 2.252,
		B: 0.18838,
		C: -0.001135,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 15,
		A: 2.452,
		B: 0.20515,
		C: -0.001236,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 16,
		A: 2.621,
		B: 0.21928,
		C: -0.001321,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 17,
		A: 2.806,
		B: 0.23475,
		C: -0.001414,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 18,
		A: 2.971,
		B: 0.24858,
		C: -0.001497,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 19,
		A: 3.195,
		B: 0.26725,
		C: -0.00161,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 20,
		A: 3.405,
		B: 0.28486,
		C: -0.001716,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 21,
		A: 3.617,
		B: 0.30262,
		C: -0.001823,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 22,
		A: 3.812,
		B: 0.31893,
		C: -0.001921,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 23,
		A: 4.006,
		B: 0.33515,
		C: -0.002019,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 24,
		A: 4.28,
		B: 0.35805,
		C: -0.002157,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 25,
		A: 4.526,
		B: 0.3786,
		C: -0.00228,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 26,
		A: 4.778,
		B: 0.39969,
		C: -0.002408,
		MinPSI: 14,
		MaxPSI: 32,
		MaxSpace: 21.6,
		DevCode: 3,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 27,
		A: 5.021,
		B: 0.42007,
		C: -0.00253,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 14.5,
		DevCode: 3,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 28,
		A: 5.319,
		B: 0.44498,
		C: -0.00268,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 14.5,
		DevCode: 3,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 29,
		A: 5.565,
		B: 0.4656,
		C: -0.002804,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 14.5,
		DevCode: 3,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 30,
		A: 5.833,
		B: 0.48797,
		C: -0.002939,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 14.5,
		DevCode: 3,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 31,
		A: 6.101,
		B: 0.51037,
		C: -0.003074,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 14.5,
		DevCode: 3,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 32,
		A: 6.394,
		B: 0.5349,
		C: -0.003222,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 11,
		DevCode: 3,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 33,
		A: 6.678,
		B: 0.55868,
		C: -0.003365,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 11,
		DevCode: 3,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 34,
		A: 7.017,
		B: 0.58701,
		C: -0.003536,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 11,
		DevCode: 3,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 35,
		A: 7.298,
		B: 0.61057,
		C: -0.003678,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 11,
		DevCode: 3,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 36,
		A: 7.553,
		B: 0.63192,
		C: -0.003806,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 11,
		DevCode: 3,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 28,
		Sequence_x0020_Number: 37,
		A: 7.893,
		B: 0.66029,
		C: -0.003977,
		MinPSI: 13,
		MaxPSI: 32,
		MaxSpace: 11,
		DevCode: 3,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 1,
		A: 0.2113,
		B: 0.03647,
		C: -0.000441,
		MinPSI: 10,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 2,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 10,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 3,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 9,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 4,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 9,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 5,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 9,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 6,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 9,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 7,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 9,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 8,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 9,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 9,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 10,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 11,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 12,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 13,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 14,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 15,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 16,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 17,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 18,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 19,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 20,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 21,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 22,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 23,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 24,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 25,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 26,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 27,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 28,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 29,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 30,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 31,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 32,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 33,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 34,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 35,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 36,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 37,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 38,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 39,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 40,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 29,
		Sequence_x0020_Number: 41,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 1,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 1,
		A: 0.202,
		B: 0.01576,
		C: -0.000075,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 297,
		Nozzle1Size: 0.0625,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 2,
		A: 0.24,
		B: 0.02,
		C: -0.00010,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 297,
		Nozzle1Size: 0.0703125,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 3,
		A: 0.308,
		B: 0.02549,
		C: -0.000129,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 297,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 4,
		A: 0.385,
		B: 0.03127,
		C: -0.000173,
		MinPSI: 4,
		MaxPSI: 41,
		MaxSpace: 10,
		DevCode: 297,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 5,
		A: 0.465,
		B: 0.03813,
		C: -0.00023,
		MinPSI: 4,
		MaxPSI: 42,
		MaxSpace: 10,
		DevCode: 297,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 6,
		A: 0.556,
		B: 0.04429,
		C: -0.000268,
		MinPSI: 4,
		MaxPSI: 43,
		MaxSpace: 10,
		DevCode: 297,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 7,
		A: 0.635,
		B: 0.05238,
		C: -0.000334,
		MinPSI: 4,
		MaxPSI: 44,
		MaxSpace: 10,
		DevCode: 297,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 8,
		A: 0.736,
		B: 0.05861,
		C: -0.00035,
		MinPSI: 4,
		MaxPSI: 45,
		MaxSpace: 10,
		DevCode: 297,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 9,
		A: 0.853,
		B: 0.06379,
		C: -0.000341,
		MinPSI: 4,
		MaxPSI: 46,
		MaxSpace: 10,
		DevCode: 297,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 10,
		A: 1.004,
		B: 0.07192,
		C: -0.000425,
		MinPSI: 4,
		MaxPSI: 47,
		MaxSpace: 10,
		DevCode: 297,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 11,
		A: 1.064,
		B: 0.08024,
		C: -0.000421,
		MinPSI: 4,
		MaxPSI: 48,
		MaxSpace: 10,
		DevCode: 297,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 12,
		A: 1.18,
		B: 0.09075,
		C: -0.000482,
		MinPSI: 4,
		MaxPSI: 49,
		MaxSpace: 10,
		DevCode: 297,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 13,
		A: 1.157,
		B: 0.10525,
		C: -0.0005554,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 14,
		A: 1.243,
		B: 0.11447,
		C: -0.0006161,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 15,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 16,
		A: 1.441,
		B: 0.1411,
		C: -0.0007446,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 17,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 18,
		A: 1.661,
		B: 0.17113,
		C: -0.0009125,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 19,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 20,
		A: 2.023,
		B: 0.19633,
		C: -0.0010304,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 21,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 22,
		A: 2.219,
		B: 0.2409,
		C: -0.0014554,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 23,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 24,
		A: 2.557,
		B: 0.27385,
		C: -0.0016661,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 25,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 26,
		A: 2.921,
		B: 0.30917,
		C: -0.0019232,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 27,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 28,
		A: 3.39,
		B: 0.33525,
		C: -0.0020036,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 29,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 30,
		A: 3.832,
		B: 0.36898,
		C: -0.0021964,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 31,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 32,
		A: 4.27,
		B: 0.40229,
		C: -0.0023429,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 33,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 34,
		A: 4.76,
		B: 0.43482,
		C: -0.0024464,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 35,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 36,
		A: 5.262,
		B: 0.46912,
		C: -0.0025679,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 37,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 38,
		A: 5.913,
		B: 0.49172,
		C: -0.0024482,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 39,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 40,
		A: 6.478,
		B: 0.52638,
		C: -0.0025393,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 41,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 42,
		A: 7.223,
		B: 0.54986,
		C: -0.0024768,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 43,
		A: 7.598,
		B: 0.56363,
		C: -0.0024857,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 44,
		A: 8.001,
		B: 0.57688,
		C: -0.0024946,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.3984375,
	},
	{
		DeviceType: 30,
		Sequence_x0020_Number: 45,
		A: 8.377,
		B: 0.592,
		C: -0.0025375,
		MinPSI: 5,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 298,
		Nozzle1Size: 0.40625,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 1,
		A: 0.202,
		B: 0.01576,
		C: -0.000075,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.0625,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 2,
		A: 0.24,
		B: 0.02,
		C: -0.00010,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.0703125,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 3,
		A: 0.308,
		B: 0.02549,
		C: -0.000129,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 4,
		A: 0.385,
		B: 0.03127,
		C: -0.000173,
		MinPSI: 4,
		MaxPSI: 41,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 5,
		A: 0.465,
		B: 0.03813,
		C: -0.00023,
		MinPSI: 4,
		MaxPSI: 42,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 6,
		A: 0.556,
		B: 0.04429,
		C: -0.000268,
		MinPSI: 4,
		MaxPSI: 43,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 7,
		A: 0.635,
		B: 0.05238,
		C: -0.000334,
		MinPSI: 4,
		MaxPSI: 44,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 8,
		A: 0.736,
		B: 0.05861,
		C: -0.00035,
		MinPSI: 4,
		MaxPSI: 45,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 9,
		A: 0.853,
		B: 0.06379,
		C: -0.000341,
		MinPSI: 4,
		MaxPSI: 46,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 10,
		A: 1.004,
		B: 0.07192,
		C: -0.000425,
		MinPSI: 4,
		MaxPSI: 47,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 11,
		A: 1.064,
		B: 0.08024,
		C: -0.000421,
		MinPSI: 4,
		MaxPSI: 48,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 12,
		A: 1.18,
		B: 0.09075,
		C: -0.000482,
		MinPSI: 4,
		MaxPSI: 49,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 13,
		A: 1.367,
		B: 0.0945,
		C: -0.00043,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 14,
		A: 1.468,
		B: 0.10295,
		C: -0.000482,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 15,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 16,
		A: 1.441,
		B: 0.1411,
		C: -0.0007446,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 17,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 18,
		A: 1.661,
		B: 0.17113,
		C: -0.0009125,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 19,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 20,
		A: 2.023,
		B: 0.19633,
		C: -0.0010304,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 21,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 22,
		A: 2.219,
		B: 0.2409,
		C: -0.0014554,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 23,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 24,
		A: 2.557,
		B: 0.27385,
		C: -0.0016661,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 25,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 26,
		A: 2.921,
		B: 0.30917,
		C: -0.0019232,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 27,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 28,
		A: 3.39,
		B: 0.33525,
		C: -0.0020036,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 29,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 30,
		A: 3.832,
		B: 0.36898,
		C: -0.0021964,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 31,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 32,
		A: 4.27,
		B: 0.40229,
		C: -0.0023429,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 33,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 34,
		A: 4.76,
		B: 0.43482,
		C: -0.0024464,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 35,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 36,
		A: 5.262,
		B: 0.46912,
		C: -0.0025679,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 37,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 38,
		A: 5.913,
		B: 0.49172,
		C: -0.0024482,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 39,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 40,
		A: 6.478,
		B: 0.52638,
		C: -0.0025393,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 31,
		Sequence_x0020_Number: 41,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 4,
		MaxPSI: 50,
		MaxSpace: 7,
		DevCode: 271,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 1,
		A: 0.202,
		B: 0.01576,
		C: -0.000075,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 251,
		Nozzle1Size: 0.0625,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 2,
		A: 0.24,
		B: 0.02,
		C: -0.00010,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 251,
		Nozzle1Size: 0.0703125,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 3,
		A: 0.308,
		B: 0.02549,
		C: -0.000129,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 251,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 4,
		A: 0.385,
		B: 0.03127,
		C: -0.000173,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 251,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 5,
		A: 0.465,
		B: 0.03813,
		C: -0.00023,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 251,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 6,
		A: 0.556,
		B: 0.04429,
		C: -0.000268,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 251,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 7,
		A: 0.635,
		B: 0.05238,
		C: -0.000334,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 251,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 8,
		A: 0.736,
		B: 0.05861,
		C: -0.00035,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 251,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 9,
		A: 0.853,
		B: 0.06379,
		C: -0.000341,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 251,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 10,
		A: 1.004,
		B: 0.07192,
		C: -0.000425,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 251,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 11,
		A: 1.064,
		B: 0.08024,
		C: -0.000421,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 251,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 12,
		A: 1.18,
		B: 0.09075,
		C: -0.000482,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 251,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 13,
		A: 1.367,
		B: 0.0945,
		C: -0.00043,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 252,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 14,
		A: 1.468,
		B: 0.10295,
		C: -0.000482,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 252,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 15,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 252,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 16,
		A: 1.441,
		B: 0.1411,
		C: -0.0007446,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 252,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 17,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 252,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 18,
		A: 1.661,
		B: 0.17113,
		C: -0.0009125,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 252,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 19,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 252,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 20,
		A: 2.023,
		B: 0.19633,
		C: -0.0010304,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 252,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 21,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 252,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 22,
		A: 2.219,
		B: 0.2409,
		C: -0.0014554,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 252,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 23,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 253,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 24,
		A: 2.557,
		B: 0.27385,
		C: -0.0016661,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 253,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 25,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 253,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 26,
		A: 2.921,
		B: 0.30917,
		C: -0.0019232,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 253,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 27,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 253,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 28,
		A: 3.39,
		B: 0.33525,
		C: -0.0020036,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 253,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 29,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 253,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 30,
		A: 3.832,
		B: 0.36898,
		C: -0.0021964,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 253,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 31,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 253,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 32,
		A: 4.27,
		B: 0.40229,
		C: -0.0023429,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 253,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 33,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 34,
		A: 4.76,
		B: 0.43482,
		C: -0.0024464,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 35,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 36,
		A: 5.262,
		B: 0.46912,
		C: -0.0025679,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 37,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 38,
		A: 5.913,
		B: 0.49172,
		C: -0.0024482,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 39,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 40,
		A: 6.478,
		B: 0.52638,
		C: -0.0025393,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 41,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 42,
		A: 7.223,
		B: 0.54986,
		C: -0.0024768,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 43,
		A: 7.598,
		B: 0.56363,
		C: -0.0024857,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 44,
		A: 8.001,
		B: 0.57688,
		C: -0.0024946,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.3984375,
	},
	{
		DeviceType: 32,
		Sequence_x0020_Number: 45,
		A: 8.377,
		B: 0.592,
		C: -0.0025375,
		MinPSI: 4,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 254,
		Nozzle1Size: 0.40625,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 1,
		A: 0.635,
		B: 0.05238,
		C: -0.000334,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 2,
		A: 0.736,
		B: 0.05861,
		C: -0.00035,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 3,
		A: 0.853,
		B: 0.06379,
		C: -0.000341,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 4,
		A: 1.004,
		B: 0.07192,
		C: -0.000425,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 5,
		A: 1.064,
		B: 0.08024,
		C: -0.000421,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 6,
		A: 1.18,
		B: 0.09075,
		C: -0.000482,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 7,
		A: 1.157,
		B: 0.10525,
		C: -0.0005554,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 8,
		A: 1.243,
		B: 0.11447,
		C: -0.0006161,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 9,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 10,
		A: 1.441,
		B: 0.1411,
		C: -0.0007446,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 11,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 12,
		A: 1.661,
		B: 0.17113,
		C: -0.0009125,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 13,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 14,
		A: 2.023,
		B: 0.19633,
		C: -0.0010304,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 15,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 16,
		A: 2.219,
		B: 0.2409,
		C: -0.0014554,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 17,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 18,
		A: 2.557,
		B: 0.27385,
		C: -0.0016661,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 19,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 20,
		A: 2.921,
		B: 0.30917,
		C: -0.0019232,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 21,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 22,
		A: 3.39,
		B: 0.33525,
		C: -0.0020036,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 23,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 24,
		A: 3.832,
		B: 0.36898,
		C: -0.0021964,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 25,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 26,
		A: 4.27,
		B: 0.40229,
		C: -0.0023429,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 27,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 28,
		A: 4.76,
		B: 0.43482,
		C: -0.0024464,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 29,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 30,
		A: 5.262,
		B: 0.46912,
		C: -0.0025679,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 31,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 32,
		A: 5.913,
		B: 0.49172,
		C: -0.0024482,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 33,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 34,
		A: 6.478,
		B: 0.52638,
		C: -0.0025393,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 35,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 231,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 36,
		A: 7.223,
		B: 0.54986,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 231,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 37,
		A: 7.598,
		B: 0.56363,
		C: -0.0024857,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 231,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 38,
		A: 8.001,
		B: 0.57688,
		C: -0.0024946,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 231,
		Nozzle1Size: 0.3984375,
	},
	{
		DeviceType: 33,
		Sequence_x0020_Number: 39,
		A: 8.377,
		B: 0.592,
		C: -0.0025375,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 231,
		Nozzle1Size: 0.40625,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 1,
		A: 0.635,
		B: 0.05238,
		C: -0.000334,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 232,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 2,
		A: 0.736,
		B: 0.05861,
		C: -0.00035,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 232,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 3,
		A: 0.853,
		B: 0.06379,
		C: -0.000341,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 232,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 4,
		A: 1.004,
		B: 0.07192,
		C: -0.000425,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 232,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 5,
		A: 1.064,
		B: 0.08024,
		C: -0.000421,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 232,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 6,
		A: 1.18,
		B: 0.09075,
		C: -0.000482,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 232,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 7,
		A: 1.157,
		B: 0.10525,
		C: -0.0005554,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 232,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 8,
		A: 1.243,
		B: 0.11447,
		C: -0.0006161,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 232,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 9,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 232,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 10,
		A: 1.441,
		B: 0.1411,
		C: -0.0007446,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 232,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 11,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 12,
		A: 1.661,
		B: 0.17113,
		C: -0.0009125,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 13,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 14,
		A: 2.023,
		B: 0.19633,
		C: -0.0010304,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 15,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 16,
		A: 2.219,
		B: 0.2409,
		C: -0.0014554,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 17,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 18,
		A: 2.557,
		B: 0.27385,
		C: -0.0016661,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 19,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 20,
		A: 2.921,
		B: 0.30917,
		C: -0.0019232,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 21,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 22,
		A: 3.39,
		B: 0.33525,
		C: -0.0020036,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 23,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 24,
		A: 3.832,
		B: 0.36898,
		C: -0.0021964,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 25,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 26,
		A: 4.27,
		B: 0.40229,
		C: -0.0023429,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 27,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 28,
		A: 4.76,
		B: 0.43482,
		C: -0.0024464,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 29,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 30,
		A: 5.262,
		B: 0.46912,
		C: -0.0025679,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 31,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 32,
		A: 5.913,
		B: 0.49172,
		C: -0.0024482,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 33,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 34,
		A: 6.478,
		B: 0.52638,
		C: -0.0025393,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 35,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 232,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 36,
		A: 7.223,
		B: 0.54986,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 232,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 37,
		A: 7.598,
		B: 0.56363,
		C: -0.0024857,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 232,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 38,
		A: 8.001,
		B: 0.57688,
		C: -0.0024946,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 232,
		Nozzle1Size: 0.3984375,
	},
	{
		DeviceType: 34,
		Sequence_x0020_Number: 39,
		A: 8.377,
		B: 0.592,
		C: -0.0025375,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 232,
		Nozzle1Size: 0.40625,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 1,
		A: 0.635,
		B: 0.05238,
		C: -0.000334,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 2,
		A: 0.736,
		B: 0.05861,
		C: -0.00035,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 3,
		A: 0.853,
		B: 0.06379,
		C: -0.000341,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 4,
		A: 1.004,
		B: 0.07192,
		C: -0.000425,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 5,
		A: 1.064,
		B: 0.08024,
		C: -0.000421,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 6,
		A: 1.18,
		B: 0.09075,
		C: -0.000482,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 7,
		A: 1.157,
		B: 0.10525,
		C: -0.0005554,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 8,
		A: 1.243,
		B: 0.11447,
		C: -0.0006161,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 9,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 10,
		A: 1.441,
		B: 0.1411,
		C: -0.0007446,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 233,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 11,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 12,
		A: 1.661,
		B: 0.17113,
		C: -0.0009125,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 13,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 14,
		A: 2.023,
		B: 0.19633,
		C: -0.0010304,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 15,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 16,
		A: 2.219,
		B: 0.2409,
		C: -0.0014554,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 17,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 18,
		A: 2.557,
		B: 0.27385,
		C: -0.0016661,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 19,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 20,
		A: 2.921,
		B: 0.30917,
		C: -0.0019232,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 21,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 22,
		A: 3.39,
		B: 0.33525,
		C: -0.0020036,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 23,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 24,
		A: 3.832,
		B: 0.36898,
		C: -0.0021964,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 25,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 26,
		A: 4.27,
		B: 0.40229,
		C: -0.0023429,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 27,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 28,
		A: 4.76,
		B: 0.43482,
		C: -0.0024464,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 29,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 30,
		A: 5.262,
		B: 0.46912,
		C: -0.0025679,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 31,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 32,
		A: 5.913,
		B: 0.49172,
		C: -0.0024482,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 33,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 34,
		A: 6.478,
		B: 0.52638,
		C: -0.0025393,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 35,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 10,
		DevCode: 233,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 36,
		A: 7.223,
		B: 0.54986,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 233,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 37,
		A: 7.598,
		B: 0.56363,
		C: -0.0024857,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 233,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 38,
		A: 8.001,
		B: 0.57688,
		C: -0.0024946,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 233,
		Nozzle1Size: 0.3984375,
	},
	{
		DeviceType: 35,
		Sequence_x0020_Number: 39,
		A: 8.377,
		B: 0.592,
		C: -0.0025375,
		MinPSI: 7,
		MaxPSI: 25,
		MaxSpace: 12,
		DevCode: 233,
		Nozzle1Size: 0.40625,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 1,
		A: 0.248,
		B: 0.02856,
		C: -0.0001643,
		MinPSI: 8,
		MaxPSI: 40,
		MaxSpace: 10,
		DevCode: 999,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 2,
		A: 0.39,
		B: 0.04196,
		C: -0.000275,
		MinPSI: 8,
		MaxPSI: 42,
		MaxSpace: 10,
		DevCode: 999,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 3,
		A: 0.56,
		B: 0.05621,
		C: -0.0003786,
		MinPSI: 8,
		MaxPSI: 44,
		MaxSpace: 10,
		DevCode: 999,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 4,
		A: 0.748,
		B: 0.06916,
		C: -0.0004036,
		MinPSI: 8,
		MaxPSI: 46,
		MaxSpace: 10,
		DevCode: 999,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 5,
		A: 0.944,
		B: 0.08639,
		C: -0.0004929,
		MinPSI: 8,
		MaxPSI: 48,
		MaxSpace: 10,
		DevCode: 999,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 6,
		A: 1.157,
		B: 0.10525,
		C: -0.0005554,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 999,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 7,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 10,
		DevCode: 999,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 8,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 9,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 10,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 11,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 12,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 13,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 14,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 15,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 16,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 17,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 10,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 18,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 10,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 19,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 36,
		Sequence_x0020_Number: 20,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 8,
		MaxPSI: 50,
		MaxSpace: 6,
		DevCode: 999,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 1,
		A: 0.1714,
		B: 0.02959,
		C: -0.000358,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.0703125,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 2,
		A: 0.2113,
		B: 0.03647,
		C: -0.000441,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 3,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 4,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 5,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 6,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 7,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 8,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 9,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 10,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 11,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 12,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 13,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 14,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 15,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 16,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 17,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 18,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 19,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 20,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 21,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 22,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 23,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 24,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 25,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 26,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 27,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 28,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 29,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 30,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 31,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 32,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 33,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 34,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 35,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 36,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 37,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 38,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 39,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 40,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 41,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 37,
		Sequence_x0020_Number: 42,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 999,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 1,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 15,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 2,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 15,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 3,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 11,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 4,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 11,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 5,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 11,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 6,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 11,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 7,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 8,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 9,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 10,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 11,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 12,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 13,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 14,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 15,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 16,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 10,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 17,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 18,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 19,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 20,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 21,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 22,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 23,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 24,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 25,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 26,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 27,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 28,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 38,
		Sequence_x0020_Number: 29,
		A: 3.478,
		B: 0.60049,
		C: 0.007261,
		MinPSI: 9,
		MaxPSI: 24,
		MaxSpace: 15,
		DevCode: 999,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 1,
		A: 0.635,
		B: 0.05238,
		C: -0.000334,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 2,
		A: 0.736,
		B: 0.05861,
		C: -0.00035,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 3,
		A: 0.853,
		B: 0.06379,
		C: -0.000341,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 4,
		A: 1.004,
		B: 0.07192,
		C: -0.000425,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 5,
		A: 1.064,
		B: 0.08024,
		C: -0.000421,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 6,
		A: 1.18,
		B: 0.09075,
		C: -0.000482,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 7,
		A: 1.157,
		B: 0.10525,
		C: -0.0005554,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 20,
		DevCode: 6,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 8,
		A: 1.243,
		B: 0.11447,
		C: -0.0006161,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 20,
		DevCode: 6,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 9,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 20,
		DevCode: 6,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 10,
		A: 1.441,
		B: 0.1411,
		C: -0.0007446,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 20,
		DevCode: 6,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 11,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 12,
		A: 1.661,
		B: 0.17113,
		C: -0.0009125,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 13,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 14,
		A: 2.023,
		B: 0.19633,
		C: -0.0010304,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 15,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 16,
		A: 2.219,
		B: 0.2409,
		C: -0.0014554,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 17,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 18,
		A: 2.557,
		B: 0.27385,
		C: -0.0016661,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 19,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 20,
		A: 2.921,
		B: 0.30917,
		C: -0.0019232,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 21,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 22,
		A: 3.39,
		B: 0.33525,
		C: -0.0020036,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 23,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 24,
		A: 3.832,
		B: 0.36898,
		C: -0.0021964,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 25,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 26,
		A: 4.27,
		B: 0.40229,
		C: -0.0023429,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 27,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 28,
		A: 4.76,
		B: 0.43482,
		C: -0.0024464,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 29,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 30,
		A: 5.262,
		B: 0.46912,
		C: -0.0025679,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 31,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 32,
		A: 5.913,
		B: 0.49172,
		C: -0.0024482,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 33,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 34,
		A: 6.478,
		B: 0.52638,
		C: -0.0025393,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 39,
		Sequence_x0020_Number: 35,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 6,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 1,
		A: 0.635,
		B: 0.05238,
		C: -0.000334,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 2,
		A: 0.736,
		B: 0.05861,
		C: -0.00035,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 3,
		A: 0.853,
		B: 0.06379,
		C: -0.000341,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 4,
		A: 1.004,
		B: 0.07192,
		C: -0.000425,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 5,
		A: 1.064,
		B: 0.08024,
		C: -0.000421,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 6,
		A: 1.18,
		B: 0.09075,
		C: -0.000482,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 5,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 7,
		A: 1.157,
		B: 0.10525,
		C: -0.0005554,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 20,
		DevCode: 7,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 8,
		A: 1.243,
		B: 0.11447,
		C: -0.0006161,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 20,
		DevCode: 7,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 9,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 20,
		DevCode: 7,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 10,
		A: 1.441,
		B: 0.1411,
		C: -0.0007446,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 20,
		DevCode: 7,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 11,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 12,
		A: 1.661,
		B: 0.17113,
		C: -0.0009125,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 13,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 14,
		A: 2.023,
		B: 0.19633,
		C: -0.0010304,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 15,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 16,
		A: 2.219,
		B: 0.2409,
		C: -0.0014554,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 17,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 18,
		A: 2.557,
		B: 0.27385,
		C: -0.0016661,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 19,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 20,
		A: 2.921,
		B: 0.30917,
		C: -0.0019232,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 21,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 22,
		A: 3.39,
		B: 0.33525,
		C: -0.0020036,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 23,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 24,
		A: 3.832,
		B: 0.36898,
		C: -0.0021964,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 25,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 26,
		A: 4.27,
		B: 0.40229,
		C: -0.0023429,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 27,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 28,
		A: 4.76,
		B: 0.43482,
		C: -0.0024464,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 29,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 30,
		A: 5.262,
		B: 0.46912,
		C: -0.0025679,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 31,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 32,
		A: 5.913,
		B: 0.49172,
		C: -0.0024482,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 33,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 34,
		A: 6.478,
		B: 0.52638,
		C: -0.0025393,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 40,
		Sequence_x0020_Number: 35,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 7,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 1,
		A: 0.2113,
		B: 0.03647,
		C: -0.000441,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 20,
		DevCode: 20,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 2,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 20,
		DevCode: 20,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 3,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 4,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 5,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 6,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 7,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 8,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 9,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 10,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 11,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 12,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 13,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 14,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 15,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 16,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 17,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 18,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 19,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 20,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 21,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 22,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 23,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 24,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 25,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 26,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 27,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 28,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 29,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 30,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 31,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 32,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 33,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 34,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 35,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 36,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 37,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 38,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 39,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 40,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 41,
		Sequence_x0020_Number: 41,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 20,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 1,
		A: 0.635,
		B: 0.05238,
		C: -0.000334,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 8,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 2,
		A: 0.736,
		B: 0.05861,
		C: -0.00035,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 8,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 3,
		A: 0.853,
		B: 0.06379,
		C: -0.000341,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 8,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 4,
		A: 1.004,
		B: 0.07192,
		C: -0.000425,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 8,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 5,
		A: 1.064,
		B: 0.08024,
		C: -0.000421,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 8,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 6,
		A: 1.18,
		B: 0.09075,
		C: -0.000482,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 19,
		DevCode: 8,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 7,
		A: 1.157,
		B: 0.10525,
		C: -0.0005554,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 20,
		DevCode: 9,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 8,
		A: 1.243,
		B: 0.11447,
		C: -0.0006161,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 20,
		DevCode: 9,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 9,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 20,
		DevCode: 9,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 10,
		A: 1.441,
		B: 0.1411,
		C: -0.0007446,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 20,
		DevCode: 9,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 11,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 12,
		A: 1.661,
		B: 0.17113,
		C: -0.0009125,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 13,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 14,
		A: 2.023,
		B: 0.19633,
		C: -0.0010304,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 15,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 16,
		A: 2.219,
		B: 0.2409,
		C: -0.0014554,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 17,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 18,
		A: 2.557,
		B: 0.27385,
		C: -0.0016661,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 19,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 20,
		A: 2.921,
		B: 0.30917,
		C: -0.0019232,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 21,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 22,
		A: 3.39,
		B: 0.33525,
		C: -0.0020036,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 23,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 24,
		A: 3.832,
		B: 0.36898,
		C: -0.0021964,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 25,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 26,
		A: 4.27,
		B: 0.40229,
		C: -0.0023429,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 27,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 28,
		A: 4.76,
		B: 0.43482,
		C: -0.0024464,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 29,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 30,
		A: 5.262,
		B: 0.46912,
		C: -0.0025679,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 31,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 32,
		A: 5.913,
		B: 0.49172,
		C: -0.0024482,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 33,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 34,
		A: 6.478,
		B: 0.52638,
		C: -0.0025393,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 42,
		Sequence_x0020_Number: 35,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 7,
		MaxPSI: 18,
		MaxSpace: 10,
		DevCode: 9,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 1,
		A: 0.2113,
		B: 0.03647,
		C: -0.000441,
		MinPSI: 15,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 2,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 15,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 3,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 10,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 4,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 10,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 5,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 10,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 6,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 10,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 7,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 10,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 8,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 10,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 9,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 10,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 11,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 12,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 13,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 14,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 15,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 16,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 17,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 18,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 19,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 20,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 21,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 22,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 23,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 24,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 25,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 26,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 27,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 28,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 29,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 30,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 31,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 32,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 33,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 34,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 35,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 36,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 37,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 38,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 39,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 40,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 43,
		Sequence_x0020_Number: 41,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 10,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 1,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 10,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 2,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 10,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 3,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 10,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 4,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 5,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 6,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 7,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 8,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 9,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 10,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 11,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 12,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 13,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 11,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 14,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 15,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 16,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 17,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 18,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 19,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 20,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 21,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 22,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 23,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 24,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 25,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 26,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 27,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 28,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 29,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 11,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 30,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 11,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 31,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 11,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 32,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 11,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 33,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 11,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 34,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 11,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 35,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 11,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 36,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 11,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 37,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 11,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 38,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 11,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 39,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 11,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 44,
		Sequence_x0020_Number: 40,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 11,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 1,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 10,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 2,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 10,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 3,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 10,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 4,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 5,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 6,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 7,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 8,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 9,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 10,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 11,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 12,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 13,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 20,
		DevCode: 12,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 14,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 15,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 16,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 17,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 18,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 19,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 8,
		MaxPSI: 25,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 20,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 21,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 22,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 23,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 24,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 25,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 26,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 27,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 28,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 29,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 14.5,
		DevCode: 12,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 30,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 12,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 31,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 12,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 32,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 12,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 33,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 12,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 34,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 12,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 35,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 12,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 36,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 12,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 37,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 12,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 38,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 12,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 39,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 12,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 45,
		Sequence_x0020_Number: 40,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 8,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 12,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 1,
		A: 0.2113,
		B: 0.03647,
		C: -0.000441,
		MinPSI: 15,
		MaxPSI: 21,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 2,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 15,
		MaxPSI: 21,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 3,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 4,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 5,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 6,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 7,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 8,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 9,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 10,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 11,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 12,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 13,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 14,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 15,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 16,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 17,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 18,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 19,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 15,
		MaxPSI: 20,
		MaxSpace: 21,
		DevCode: 13,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 20,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 21,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 22,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 23,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 24,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 25,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 26,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 27,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 28,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 29,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 30,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 31,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 32,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 33,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 34,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 35,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 36,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 37,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 38,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 39,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 40,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 46,
		Sequence_x0020_Number: 41,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 1,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 10,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 2,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 10,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 3,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 10,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 4,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 10,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 5,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 10,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 6,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 10,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 7,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 8,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 9,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 10,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 11,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 12,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 13,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 14,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 15,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 16,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 17,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 6,
		MaxPSI: 20,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 18,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 19,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 20,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 6,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 21,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 22,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 23,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 24,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 25,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 19,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 26,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 27,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 28,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 29,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 30,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 31,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 32,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 33,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 34,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 35,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 36,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 37,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 38,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 47,
		Sequence_x0020_Number: 39,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 18,
		MaxSpace: 11,
		DevCode: 13,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 1,
		A: 0.2113,
		B: 0.03647,
		C: -0.000441,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 18,
		DevCode: 21,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 2,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 18,
		DevCode: 21,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 3,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 4,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 5,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 6,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 7,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 8,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 9,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 10,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 11,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 12,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 13,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 14,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 15,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 16,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 17,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 18,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 19,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 20,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 21,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 22,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 23,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 24,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 25,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 26,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 27,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 28,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 29,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 30,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 31,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 32,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 33,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 34,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 35,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 36,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 37,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 38,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 39,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 40,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 48,
		Sequence_x0020_Number: 41,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 21,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 1,
		A: 0.2113,
		B: 0.03647,
		C: -0.000441,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 22,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 2,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 15,
		DevCode: 22,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 3,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 4,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 5,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 6,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 7,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 8,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 9,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 10,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 11,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 12,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 13,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 14,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 15,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 16,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 17,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 18,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 19,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 20,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 21,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 22,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 23,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 24,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 25,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 26,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 27,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 28,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 29,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 30,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 31,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 32,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 33,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 34,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 35,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 36,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 37,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 38,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 39,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 40,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 49,
		Sequence_x0020_Number: 41,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 22,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 1,
		A: 0.1714,
		B: 0.02959,
		C: -0.000358,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.0703125,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 2,
		A: 0.2113,
		B: 0.03647,
		C: -0.000441,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 3,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 4,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 5,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 6,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 7,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 8,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 9,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 10,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 11,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 12,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 13,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 14,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 15,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 16,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 17,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 18,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 19,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 20,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 21,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 22,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 23,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 24,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 25,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 26,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 27,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 28,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 29,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 30,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 31,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 32,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 33,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 34,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 35,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 36,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 37,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 38,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 39,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 40,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 41,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 50,
		Sequence_x0020_Number: 42,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 30,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 1,
		A: 0.1714,
		B: 0.02959,
		C: -0.000358,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.0703125,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 2,
		A: 0.2113,
		B: 0.03647,
		C: -0.000441,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 3,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 4,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 5,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 6,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 7,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 8,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 9,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 6,
		MaxPSI: 28,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 10,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 11,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 12,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 13,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 14,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 15,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 16,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 17,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 18,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 19,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 20,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 21,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 22,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 23,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 6,
		MaxPSI: 27,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 24,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 25,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 26,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 27,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 28,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 29,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 30,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 31,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 51,
		Sequence_x0020_Number: 32,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 26,
		MaxSpace: 11,
		DevCode: 34,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 1,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 2,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 3,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 4,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 5,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 6,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 7,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 8,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 9,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 10,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 11,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 12,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 13,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 14,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 15,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 16,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 17,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 18,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 19,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 20,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 21,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 22,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 23,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 24,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 25,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 26,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 27,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 28,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 29,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 30,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 31,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 32,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 33,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 34,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 35,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 36,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 52,
		Sequence_x0020_Number: 37,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 28,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 1,
		A: 0.2113,
		B: 0.03647,
		C: -0.000441,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 20,
		DevCode: 26,
		Nozzle1Size: 0.078125,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 2,
		A: 0.2545,
		B: 0.04393,
		C: -0.000531,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 20,
		DevCode: 26,
		Nozzle1Size: 0.0859375,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 3,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 4,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 5,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 6,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 7,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 8,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 9,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 10,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 11,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 12,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 13,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 14,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 15,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 16,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 17,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 18,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 19,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 20,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 21,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 6,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 22,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 23,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 24,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 25,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 26,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 27,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 28,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 29,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 30,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 31,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 32,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 33,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 34,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 35,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 36,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 37,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 38,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 39,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 40,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 56,
		Sequence_x0020_Number: 41,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 21,
		MaxSpace: 11,
		DevCode: 26,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 1,
		A: 0.465,
		B: 0.03813,
		C: -0.00023,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 22,
		DevCode: 27,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 2,
		A: 0.556,
		B: 0.04429,
		C: -0.000268,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 22,
		DevCode: 27,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 3,
		A: 0.635,
		B: 0.05238,
		C: -0.000334,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 22,
		DevCode: 27,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 4,
		A: 0.736,
		B: 0.05861,
		C: -0.00035,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 22,
		DevCode: 27,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 5,
		A: 0.853,
		B: 0.06379,
		C: -0.000341,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 22,
		DevCode: 27,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 6,
		A: 1.004,
		B: 0.07192,
		C: -0.000425,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 22,
		DevCode: 27,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 7,
		A: 1.064,
		B: 0.08024,
		C: -0.000421,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 22,
		DevCode: 27,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 8,
		A: 1.18,
		B: 0.09075,
		C: -0.000482,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 22,
		DevCode: 27,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 9,
		A: 1.157,
		B: 0.10525,
		C: -0.0005554,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 22,
		DevCode: 27,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 10,
		A: 1.243,
		B: 0.11447,
		C: -0.0006161,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 22,
		DevCode: 27,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 11,
		A: 1.365,
		B: 0.12798,
		C: -0.0006875,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 22,
		DevCode: 27,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 12,
		A: 1.441,
		B: 0.1411,
		C: -0.0007446,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 22,
		DevCode: 27,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 13,
		A: 1.526,
		B: 0.15497,
		C: -0.0008,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 14,
		A: 1.661,
		B: 0.17113,
		C: -0.0009125,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 15,
		A: 1.86,
		B: 0.18114,
		C: -0.0009286,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 16,
		A: 2.023,
		B: 0.19633,
		C: -0.0010304,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 17,
		A: 2.167,
		B: 0.2136,
		C: -0.0011482,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 18,
		A: 2.219,
		B: 0.2409,
		C: -0.0014554,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 19,
		A: 2.442,
		B: 0.25209,
		C: -0.0014714,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 20,
		A: 2.557,
		B: 0.27385,
		C: -0.0016661,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 21,
		A: 2.655,
		B: 0.30048,
		C: -0.0019661,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 22,
		A: 2.921,
		B: 0.30917,
		C: -0.0019232,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 23,
		A: 3.207,
		B: 0.3191,
		C: -0.0019268,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 24,
		A: 3.39,
		B: 0.33525,
		C: -0.0020036,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 25,
		A: 3.635,
		B: 0.35009,
		C: -0.0020768,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 26,
		A: 3.832,
		B: 0.36898,
		C: -0.0021964,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 27,
		A: 4.036,
		B: 0.38719,
		C: -0.0023071,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 28,
		A: 4.27,
		B: 0.40229,
		C: -0.0023429,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 29,
		A: 4.573,
		B: 0.4125,
		C: -0.0022839,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 30,
		A: 4.76,
		B: 0.43482,
		C: -0.0024464,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 31,
		A: 5.014,
		B: 0.45206,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 32,
		A: 5.262,
		B: 0.46912,
		C: -0.0025679,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 33,
		A: 5.578,
		B: 0.48095,
		C: -0.002525,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 34,
		A: 5.913,
		B: 0.49172,
		C: -0.0024482,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 35,
		A: 6.195,
		B: 0.50852,
		C: -0.0024768,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 36,
		A: 6.478,
		B: 0.52638,
		C: -0.0025393,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 57,
		Sequence_x0020_Number: 37,
		A: 6.814,
		B: 0.53942,
		C: -0.0025179,
		MinPSI: 7,
		MaxPSI: 12,
		MaxSpace: 12,
		DevCode: 27,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 1,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 10,
		MaxPSI: 27,
		MaxSpace: 24,
		DevCode: 187,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 2,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 10,
		MaxPSI: 27,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 3,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 10,
		MaxPSI: 27,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 4,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 10,
		MaxPSI: 27,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 5,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 10,
		MaxPSI: 27,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 6,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 10,
		MaxPSI: 27,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 7,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 10,
		MaxPSI: 27,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 8,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 9,
		MaxPSI: 26,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 9,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 9,
		MaxPSI: 26,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 10,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 9,
		MaxPSI: 26,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 11,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 9,
		MaxPSI: 26,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 12,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 9,
		MaxPSI: 26,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 13,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 9,
		MaxPSI: 26,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 14,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 7,
		MaxPSI: 20,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 15,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 7,
		MaxPSI: 20,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 16,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 7,
		MaxPSI: 20,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 17,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 7,
		MaxPSI: 20,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 18,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 7,
		MaxPSI: 20,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 19,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 7,
		MaxPSI: 20,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 20,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 7,
		MaxPSI: 20,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 21,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 7,
		MaxPSI: 20,
		MaxSpace: 21.6,
		DevCode: 187,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 22,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 7,
		MaxPSI: 16,
		MaxSpace: 14.5,
		DevCode: 187,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 23,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 7,
		MaxPSI: 16,
		MaxSpace: 14.5,
		DevCode: 187,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 24,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 7,
		MaxPSI: 16,
		MaxSpace: 14.5,
		DevCode: 187,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 25,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 7,
		MaxPSI: 16,
		MaxSpace: 14.5,
		DevCode: 187,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 26,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 7,
		MaxPSI: 16,
		MaxSpace: 14.5,
		DevCode: 187,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 27,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 7,
		MaxPSI: 16,
		MaxSpace: 14.5,
		DevCode: 187,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 28,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 7,
		MaxPSI: 16,
		MaxSpace: 14.5,
		DevCode: 187,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 29,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 7,
		MaxPSI: 16,
		MaxSpace: 14.5,
		DevCode: 187,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 30,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 7,
		MaxPSI: 16,
		MaxSpace: 14.5,
		DevCode: 187,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 31,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 7,
		MaxPSI: 16,
		MaxSpace: 14.5,
		DevCode: 187,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 32,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 7,
		MaxPSI: 16,
		MaxSpace: 14.5,
		DevCode: 187,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 33,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 7,
		MaxPSI: 16,
		MaxSpace: 14.5,
		DevCode: 187,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 34,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 7,
		MaxPSI: 15,
		MaxSpace: 11,
		DevCode: 187,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 35,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 7,
		MaxPSI: 15,
		MaxSpace: 11,
		DevCode: 187,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 36,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 7,
		MaxPSI: 15,
		MaxSpace: 11,
		DevCode: 187,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 37,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 7,
		MaxPSI: 15,
		MaxSpace: 11,
		DevCode: 187,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 38,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 7,
		MaxPSI: 15,
		MaxSpace: 11,
		DevCode: 187,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 58,
		Sequence_x0020_Number: 39,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 7,
		MaxPSI: 15,
		MaxSpace: 11,
		DevCode: 187,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 1,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 2,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 3,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 4,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 5,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 6,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 7,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 8,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 9,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 10,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 11,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 12,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 13,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 14,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 15,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 16,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 17,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 18,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 19,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 20,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 21,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 22,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 23,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 24,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 25,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 26,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 27,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 28,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 15,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 29,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 15,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 30,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 15,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 31,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 15,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 32,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 15,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 33,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 15,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 34,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 15,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 35,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 15,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 36,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 15,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 37,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 15,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 38,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 15,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 59,
		Sequence_x0020_Number: 39,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 15,
		Nozzle1Size: 0.390625,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 1,
		A: 0.3091,
		B: 0.05335,
		C: -0.000645,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.09375,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 2,
		A: 0.3619,
		B: 0.06247,
		C: -0.000755,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.1015625,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 3,
		A: 0.4152,
		B: 0.07168,
		C: -0.000867,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.109375,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 4,
		A: 0.4847,
		B: 0.08366,
		C: -0.001012,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.1171875,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 5,
		A: 0.5497,
		B: 0.09489,
		C: -0.001147,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.125,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 6,
		A: 0.6208,
		B: 0.10717,
		C: -0.001296,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.1328125,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 7,
		A: 0.6881,
		B: 0.11878,
		C: -0.001436,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.140625,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 8,
		A: 0.7748,
		B: 0.13375,
		C: -0.001617,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.1484375,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 9,
		A: 0.8621,
		B: 0.1488,
		C: -0.001799,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.15625,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 10,
		A: 0.9349,
		B: 0.16138,
		C: -0.001951,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.1640625,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 11,
		A: 1.036,
		B: 0.17882,
		C: -0.002162,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.171875,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 12,
		A: 1.125,
		B: 0.19416,
		C: -0.002348,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.1796875,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 13,
		A: 1.241,
		B: 0.21414,
		C: -0.002589,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.1875,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 14,
		A: 1.339,
		B: 0.23111,
		C: -0.002794,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.1953125,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 15,
		A: 1.455,
		B: 0.25118,
		C: -0.003037,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.203125,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 16,
		A: 1.56,
		B: 0.26928,
		C: -0.003256,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.2109375,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 17,
		A: 1.699,
		B: 0.29325,
		C: -0.003546,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.21875,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 18,
		A: 1.816,
		B: 0.31345,
		C: -0.00379,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.2265625,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 19,
		A: 1.944,
		B: 0.33556,
		C: -0.004058,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.234375,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 20,
		A: 2.059,
		B: 0.35532,
		C: -0.004296,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.2421875,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 21,
		A: 2.213,
		B: 0.38203,
		C: -0.004619,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.25,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 22,
		A: 2.359,
		B: 0.40719,
		C: -0.004924,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.2578125,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 23,
		A: 2.506,
		B: 0.43257,
		C: -0.005231,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.265625,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 24,
		A: 2.641,
		B: 0.4559,
		C: -0.005513,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.2734375,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 25,
		A: 2.775,
		B: 0.47907,
		C: -0.005793,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.28125,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 26,
		A: 2.965,
		B: 0.51181,
		C: -0.006189,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.2890625,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 27,
		A: 3.135,
		B: 0.5412,
		C: -0.006544,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.296875,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 28,
		A: 3.31,
		B: 0.57134,
		C: -0.006909,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 21,
		DevCode: 16,
		Nozzle1Size: 0.3046875,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 29,
		A: 3.478,
		B: 0.60049,
		C: -0.007261,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 16,
		Nozzle1Size: 0.3125,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 30,
		A: 3.685,
		B: 0.63607,
		C: -0.007691,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 16,
		Nozzle1Size: 0.3203125,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 31,
		A: 3.856,
		B: 0.66556,
		C: -0.008048,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 16,
		Nozzle1Size: 0.328125,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 32,
		A: 4.041,
		B: 0.69752,
		C: -0.008434,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 16,
		Nozzle1Size: 0.3359375,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 33,
		A: 4.227,
		B: 0.72956,
		C: -0.008822,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 16,
		Nozzle1Size: 0.34375,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 34,
		A: 4.43,
		B: 0.76461,
		C: -0.009246,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 16,
		Nozzle1Size: 0.3515625,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 35,
		A: 4.626,
		B: 0.79859,
		C: -0.009657,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 16,
		Nozzle1Size: 0.359375,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 36,
		A: 4.861,
		B: 0.8391,
		C: -0.010146,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 16,
		Nozzle1Size: 0.3671875,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 37,
		A: 5.056,
		B: 0.87279,
		C: -0.010554,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 16,
		Nozzle1Size: 0.375,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 38,
		A: 5.233,
		B: 0.90329,
		C: -0.010923,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 16,
		Nozzle1Size: 0.3828125,
	},
	{
		DeviceType: 60,
		Sequence_x0020_Number: 39,
		A: 5.468,
		B: 0.94387,
		C: -0.011413,
		MinPSI: 5,
		MaxPSI: 12,
		MaxSpace: 13,
		DevCode: 16,
		Nozzle1Size: 0.390625,
	},
];