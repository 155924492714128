import Base64 from 'crypto-js/enc-base64';
import md5 from 'crypto-js/md5';
import { saveAs } from 'file-saver';
import { ElectricalFrequencies, SACZoneControlTypes, VRIValveType } from "rdptypes/project/ISystemBase.AutoGenerated";
import { AcresToRadius, RadiusToAcres } from "roedata/roe_migration/CommonFunctions";
import { FieldSets, HasSwingArmCorner, IsDualSided, IsMaxigator } from "roedata/roe_migration/SystemFunctions";
import VRI_Zones from "roedata/roe_migration/VRIClass.VRI_Zones";
import { create, fragment } from 'xmlbuilder2';
import { XMLBuilder } from "xmlbuilder2/lib/interfaces";
import { formatDateWithTime } from "../../../../docGeneration/DocumentGenerationHelpers";
import ISystem from "../../../../model/project/ISystem";

export const getVRIZoneValveTypeStr = (type: VRIValveType) => {
    if (type === VRIValveType.ReinkeSealMatic){
        return "Reinke Seal Matic";
    }
    if (type === VRIValveType.Poly){
        return "Poly";
    }
}

export const getMaxVRIZones = (sys: ISystem): number => {
    let frequency = ElectricalFrequencies.a60;
    if (FieldSets(sys).ControlPanel.DataValid()) frequency = sys.ControlPanel.ElectricalFrequency;

    let towerCount = sys.FlangedSide.Tower.length;
    let sa = HasSwingArmCorner(sys) ? sys.Circle.SwingArm : undefined;

    if (towerCount <= 7){
        return typeof(sa) === "undefined" ? 24 : sa.ZoneControlType === SACZoneControlTypes.ESAC060 ? 18 : 12;
    }
    if (frequency === ElectricalFrequencies.a50 && towerCount <= 9){
        return typeof(sa) === "undefined" ? 18 : sa.ZoneControlType === SACZoneControlTypes.ESAC060 ? 12 : 6;
    }
    if (towerCount <= 12){
        if (frequency === ElectricalFrequencies.a50){
            return typeof(sa) === "undefined" ? 0 : 12; //I think this follows the logic in VRIForm.vb line 112 vbut the 0 seems strange
        }
        return typeof(sa) === "undefined" ? 18 : sa.ZoneControlType === SACZoneControlTypes.ESAC060 ? 12 : 6;
    }

    return 0;
}

export const add1Sprinkler = (m_zones: VRI_Zones, rowNumber: number) => {
    m_zones.MoveEndingLocation(rowNumber, 1);
}

export const subtract1Sprinkler = (m_zones: VRI_Zones, rowNumber: number) => {
    m_zones.MoveEndingLocation(rowNumber, -1);
}

export const addZone = (m_zones: VRI_Zones, rowNumber: number) => {
    m_zones.Add(rowNumber);
}

export const deleteZone = (m_zones: VRI_Zones, rowNumber: number) => {
    m_zones.Delete(rowNumber);
}

export const updateZoneControlledState = (m_zones: VRI_Zones, rowNumber, checked: boolean) => {
    m_zones.UpdateControlledState(rowNumber, checked);
}

export const equalSpace = (m_zones: VRI_Zones, maxZones: number) => {
    const iMaxZones = m_zones.OutletsInUseCount < maxZones ?  m_zones.OutletsInUseCount : maxZones;

    const zoneLength = m_zones.Length/iMaxZones;
    m_zones.Clear();

    let exitFor = false;
    for (let i = 1; i <= maxZones && !exitFor; i++){
        let z = m_zones.Get(i);
        z.InUse = true;
        z.Controlled = true;

        if (i === maxZones){
            z.SetEndingLocation(m_zones.EndingLocation);
        }
        else {
            z.SetEndingLocation(m_zones.StartingLocation + (zoneLength * (i)));
            while (z.DeviceCount <= 0){
                if (i > 1 && m_zones.Get(i-1).DeviceCount > 1){
                    m_zones.Get(i - 1).SetEndingLocation(m_zones.DeviceLocationByIdx(m_zones.Get(i - 1).DeviceBeforeEndingLocation) - 1);
                }
                else {
                    z.SetEndingLocation(m_zones.DeviceLocationByIdx(z.DeviceAfterEndingLocation) + 1);
                    if (z.EndingLocation === m_zones.EndingLocation) {
                        exitFor = true;
                        break;
                    }
                }
            }
        }
    }
}

export const equalAcres = (m_zones: VRI_Zones, maxZones: number, sys: ISystem) => {
    if (IsMaxigator(sys)){
        equalSpace(m_zones, maxZones);
        return;
    }

    const iMaxZones = m_zones.OutletsInUseCount < maxZones ?  m_zones.OutletsInUseCount : maxZones;
    const zoneAcres = RadiusToAcres(m_zones.EndingLocation)/iMaxZones;
    m_zones.Clear();

    let exitFor = false;
    for (let i = 1; i <= maxZones && !exitFor; i++){
        let z = m_zones.Get(i);
        z.InUse = true;
        z.Controlled = true;

        if (i === maxZones){
            z.SetEndingLocation(m_zones.EndingLocation);
        }
        else {
            z.SetEndingLocation(AcresToRadius(zoneAcres * i));
            while (z.DeviceCount <= 0){
                if (i > 1 && m_zones.Get(i-1).DeviceCount > 1){
                    m_zones.Get(i - 1).SetEndingLocation(m_zones.DeviceLocationByIdx(m_zones.Get(i - 1).DeviceBeforeEndingLocation) - 1);
                }
                else {
                    z.SetEndingLocation(m_zones.DeviceLocationByIdx(z.DeviceAfterEndingLocation) + 1);
                    if (z.EndingLocation === m_zones.EndingLocation) {
                        exitFor = true;
                        break;
                    }
                }
            }
        }
    }
}

export const exportZones = (m_Zones: VRI_Zones, system: ISystem) => {
    let blob = new Blob([generateZoneXML(m_Zones, system)], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "VRI_Zones.RRx");
}

const generateZoneXML = (m_Zones: VRI_Zones, system: ISystem) => {
    const systemXml = getSystemXml(m_Zones, system);
    const systemXmlStr = getSystemXml(m_Zones, system).end();

    const spansXml = getSpansXml(system);
    const spansXmlStr = getSpansXml(system).end();

    const zonesXml = getZonesXml(m_Zones);
    const zonesXmlStr = getZonesXml(m_Zones).end();

    const root = create({version: "1.0", encoding: "utf-8"})
        .ele("Root")
            .ele("XMLSchemaVersion").txt("1.0.0001").up()
            .ele("DocInfo")
                .ele("ModifiedDate").txt(formatDateWithTime(new Date())).up()
                .ele("CultureCode").txt("en-GB").up()
                .ele("ProgramName").txt("Reinke Systems").up()
                .ele("ProgramVersion").txt("RDP 3").up()
                .ele("SystemChecksum").txt(getChecksum(systemXmlStr)).up()
                .ele("SpansChecksum").txt(getChecksum(spansXmlStr)).up()
                .ele("ZonesChecksum").txt(getChecksum(zonesXmlStr)).up()
                .ele("FieldLength").txt(m_Zones.MaxiRun.toString()).up()
            .up()
            .ele("VRI")
                .ele("System", {"Type": IsMaxigator(system)})
                    .import(systemXml)
                .up()
                .ele("Spans")
                    .import(spansXml)
                .up()
                .ele("Zones")
                    .import(zonesXml)
                .up()
            .up()
        .up();

    return root.end({prettyPrint: true});
}

const getSystemXml = (m_Zones: VRI_Zones, system: ISystem) => {
    return fragment()
            .ele("RoeID").txt("0").up()
            .ele("DealerAccNo").txt(system.DealerProperties.AccountNumber).up()
            .ele("Longitude").txt("0").up()
            .ele("Latitude").txt("0").up()
            .ele("Length").txt((m_Zones.EndingLocation - m_Zones.StartingLocation).toString()).up()
            .ele("SystemFlow").txt(system.SprinklerProperties.TotalGPM.toString()).up()
            .ele("NumberOfZones").txt(m_Zones.Count.toString()).up();
}

const getSpansXml = (system: ISystem) => {
    let spanFragments: XMLBuilder[] = [];

    let iSpanOrder = 1;
    if (IsDualSided(system)){
        for (let i = system.FlexSide.Span.length - 1; i >= 0 ; i--) {
            spanFragments.push(fragment()
                .ele("Span", {"Length": system.FlexSide.Span[i].Length, "Order": iSpanOrder}).up()
            );
            iSpanOrder++;
        }
    }

    for (let i = 0; i < system.FlangedSide.Span.length; i++) {
        spanFragments.push(fragment()
            .ele("Span", {"Length": system.FlangedSide.Span[i].Length, "Order": iSpanOrder}).up()
        );
        iSpanOrder++;
    }
    
    let spanFragment = fragment();
    spanFragments.forEach((frag) => {
        spanFragment.import(frag);
    });

    return spanFragment;
}

const getZonesXml = (m_Zones: VRI_Zones) => {
    let zoneFragments: XMLBuilder[] = [];

    for (let i = 0; i <= m_Zones.Count; i++){
        let zone = m_Zones.Get(i);
        if (zone.InUse){
            zoneFragments.push(fragment()
                .ele("Zone", {
                    "Order": i, 
                    "AcresCovered": zone.Acres, 
                    "MaxDiameter": zone.MaxDiameter, 
                    "NumberOfSprinklers": zone.DeviceCount, 
                    "RelayAddress": zone.ZoneRelayAddress, 
                    "Width": zone.Length})
                .up()
            );
        }
    }

    let zoneFragment = fragment();
    zoneFragments.forEach((frag) => {
        zoneFragment.import(frag);
    });

    return zoneFragment;
}

const getChecksum = (s: string): string => {
    return Base64.stringify(md5(s));
}