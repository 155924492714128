import { Text, View } from "@react-pdf/renderer";
import i18next, { t } from "i18next";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { getSystemAreaAcres, getSystemElevationFeet, getSystemEndGunAcres, getSystemEndPressurePsi, getSystemGPM, getSystemHoursToApplyOneInch, getSystemIrrigatedAcres, getSystemLengthFeet, getSystemMaxGPM, getSystemMinGPM, getSystemModel, getSystemSACAcres, getSystemTopOfInletPressurePsi, getValuesFromFieldDesign } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { DisplayAreaUnitBuilder } from "../../helpers/areas";
import { DisplayLengthUnitBuilder } from "../../helpers/lengths";
import IProject from "../../model/project/IProject";
import ISystem from "../../model/project/ISystem";
import { formatSystemType } from "../DocumentGenerationHelpers";
import { documentStyles } from "../styles";
import FixedWidthTable, { ICell, IRow } from "./table/FixedWidthTable";

interface Props {
    quote: QuoteClass;
    systemId: string;
    layoutId: string;
    project: IProject;
};

const SystemInformationTable: FC<Props> = (props) => {
    const settings = React.useContext(DevSettingsCtx);

    let {quote, systemId, layoutId, project} = props;

    let gpm = getSystemGPM(quote);
    let maxGpm = getSystemMaxGPM(quote);
    let minGpm = getSystemMinGPM(quote);

    let systemLength = new DisplayLengthUnitBuilder(getSystemLengthFeet(quote), 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(0)
        .appendString(" ")
        .appendSymbol()
        .toString();

    let elevation = new DisplayLengthUnitBuilder(getSystemElevationFeet(quote), 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(0)
        .appendString(" ")
        .appendSymbol()
        .toString();

    let valuesFromFieldDesign = getValuesFromFieldDesign(quote, systemId, layoutId, project);

    let systemArea = quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro ? new DisplayAreaUnitBuilder(getSystemAreaAcres(quote,valuesFromFieldDesign), 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(1)
        .appendString(" ")
        .appendFullName()
        .toString() : "Unknown";

    let ns1 = "reports.";
    let ns2 = "common-phrases.";

    let rows: IRow[] = [
        {
            cells: [
                {
                    text: t(ns2 + "sltSystemType") + ": ",
                    header: true,
                    widthPc: 20
                },
                {
                    text: formatSystemType(quote.System as ISystem),
                    header: true,
                    widthPc: 30
                }, 
                {
                    text: t(ns2 + "sltModel") + ": ",
                    header: true,
                    widthPc: 20
                },
                {
                    text: getSystemModel(quote, settings),
                    header: true,
                    widthPc: 30
                }
            ]
        },
        {
            cells: [
                {
                    text: t(ns2 + "sltSystemGPM1") + ":",
                    widthPc: 20
                }, 
                {
                    text: typeof gpm === "number" ? gpm.toFixed(1) + (" " + t(ns2 + "sltGPM")) : "N/A",
                    widthPc: 30
                },
                {
                    text: i18next.format(t(ns1 + 'system-length'), 'capitalize-each') + ":",
                    widthPc: 20
                },
                {
                    text: systemLength,
                    widthPc: 30
                },
            ]
        },
        {
            cells: [
                {
                    text: t(ns2 + "sltMaxGPM") + ":",
                    widthPc: 20
                }, 
                {
                    text: typeof maxGpm === "number" ? maxGpm.toFixed(1) + (" " + t(ns2 + "sltGPM")) : "N/A",
                    widthPc: 30
                },
                {
                    text: t(ns2 + "sltMinGPM") + ":",
                    widthPc: 20
                },
                {
                    text: typeof minGpm === "number" ? minGpm.toFixed(1) + (" " + t(ns2 + "sltGPM")) : "N/A",
                    widthPc: 30
                },
            ]
        },
        {
            cells: [
                {
                    text: t(ns2 + "sltElevation") + ":",
                    widthPc: 20
                }, 
                {
                    text: elevation,
                    widthPc: 30
                },
                {
                    text: t(ns2 + "sltSystemArea") + ":",
                    widthPc: 20
                },
                {
                    text: systemArea,
                    widthPc: 30
                },
            ]
        }
    ];

    let topOfInletPressure = getSystemTopOfInletPressurePsi(quote);

    let cells: ICell[] = [
        {
            text: t(ns2 + "sltPivotTopPressure") + ":",
            widthPc: 20
        }, 
        {
            text: typeof topOfInletPressure === "number" ? topOfInletPressure.toFixed(1) + (" " + t(ns2 + "sltPSI")) : "N/A",
            widthPc: 30
    }]

    let sacArea = typeof getSystemSACAcres(quote, valuesFromFieldDesign) === "number" ? new DisplayAreaUnitBuilder(getSystemSACAcres(quote, valuesFromFieldDesign), 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(2)
        .appendString(" ")
        .appendFullName()
        .toString() : "N/A";

    if (HasSwingArmCorner(quote.System)) {
        cells.push({
            text: t(ns2 + "sltSacArea") + ":",
            widthPc: 20
        }, 
        {
            text: sacArea,
            widthPc: 30
        });
    }

    rows.push({cells});

    let endPressure = getSystemEndPressurePsi(quote);
    let endGunAcres = getSystemEndGunAcres(quote, valuesFromFieldDesign);

    let endgunArea = typeof endGunAcres === "number" ? new DisplayAreaUnitBuilder(endGunAcres, 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(2)
        .appendString(" ")
        .appendFullName()
        .toString() : "N/A";


    let irrigatedArea = quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro ? new DisplayAreaUnitBuilder(getSystemIrrigatedAcres(quote, valuesFromFieldDesign), 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(2)
        .appendString(" ")
        .appendFullName()
        .toString() : "Unknown";

    rows.push({cells: [{
        text: t(ns2 + "sltEndPressure1") + ":",
        widthPc: 20
        }, 
        {
            text: typeof endPressure === "number" ? endPressure.toFixed(1) + (" " + t(ns2 + "sltPSI")) : "N/A",
            widthPc: 30
        },
        {
            text: t(ns2 + "sltEndgunArea") + ":",
            widthPc: 20
        },
        {
            text: endgunArea,
            widthPc: 30
        }
    ]}, {
        cells: [{
            text: t(ns2 + "sltHoursToApplyOneInch") + ":",
            widthPc: 20
            }, 
            {
                text: typeof getSystemHoursToApplyOneInch(quote, valuesFromFieldDesign) === "undefined" ? "N/A" : getSystemHoursToApplyOneInch(quote, valuesFromFieldDesign).toFixed(1) + (" " + t(ns2 + "sltHours")),
                widthPc: 30
            },
            {
                text: t(ns2 + "sltTotalArea") + ":",
                widthPc: 20
            },
            {
                text: irrigatedArea,
                widthPc: 30
            }
    ]});

    return <View wrap={false} style={documentStyles.section}>
            <View style={documentStyles.heading}>
                <Text style={documentStyles.sectionHeader}>{t(ns2 + "sltSystemInformation")}</Text>
            </View>
            <View style={{paddingLeft: 15, paddingRight: 15}}>
                <FixedWidthTable
                    data={{rows}}
                />   
            </View>
    </View>
};

export default SystemInformationTable;