import { TextField } from "@mui/material";
import { IMainlineValveOptionsValvesMainlineValve } from "rdptypes/project/ISystemBase.AutoGenerated";
import IMainlineValveCode from "rdptypes/roe/IMainlineValveCode";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import IDbProject from "../../../db/IDbProject";
import { getValue } from "../../../helpers/objectPathResolver";
import { translate } from "../../../helpers/translation";
import { pageStyles, secondaryColor } from "../styles";
import IComponentRenderContext from "./IComponentRenderContext";

interface Props {
    cmp: IMainlineValveCode,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    ctx: IComponentRenderContext,
    parentGroupLabel: string | null, //either the same as the current component, don't show title; different from parent, do show title in grey; null, not in a group so display in blue
    small: boolean;
};

const MainlineValveCodeRenderer: FC<Props> = (props) => {
    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    let showLabel = props.parentGroupLabel !== translate(props.cmp);
    let textInputStyle = {};
    let inputStyle={};
    
    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    if (props.parentGroupLabel === null)
    {
        formInputLabelStyle["marginLeft"] = "10px";
        formInputLabelStyle["marginRight"] = "10px";
        if (props.small){
            textInputStyle["fontSize"] = "12px";
            inputStyle["fontSize"] = "12px";
        }
        textInputStyle["paddingRight"] = "20px";
        inputStyle["marginLeft"] = "10px";
    }
    else if (props.parentGroupLabel !== translate(props.cmp))
    {
        formInputLabelStyle["color"] = secondaryColor;
    }

    const mv: IMainlineValveOptionsValvesMainlineValve = getValue(system, props.ctx.fieldRoot.substring(0, props.ctx.fieldRoot.length - 1));
    const quote = new QuoteClass(system);

let value: string;

try {
    const valveInfo = quote.MainlineValvesClass.GetMainlineValveInfo(mv);
    value = valveInfo.BasePartNumber;
} catch {
    value = "";
}

    return (
        <>
            <TextField
                size="small"
                InputLabelProps={{sx:formInputLabelStyle, shrink: true}}
                InputProps={{sx: inputStyle}}
                style={textInputStyle}
                variant="standard"
                fullWidth
                label={showLabel ? translate(props.cmp) : null}       
                disabled         
                value={value}
            />
        </>
    );
};

export default MainlineValveCodeRenderer;