import PrintIcon from '@mui/icons-material/Print';
import { Fab } from '@mui/material';
import * as React from "react";
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import RDPProposalContent from '../components/RDPProposal/RDPProposalContent';

interface Props {
    systemIds: string[];
    type: string;
}

const RDPProposal: FC<Props> = React.forwardRef((props) => {
    const { projectId, layoutId } = useParams();
    const {systemIds, type} = props;

    return (
        <>
            <Fab className="no-print" style={{position: "fixed", right: "60px", top: "100px"}} onClick={() => {window.print();}}><PrintIcon/></Fab>
            <RDPProposalContent type={type} projectId={projectId!} layoutId={layoutId!} systemIds={systemIds}/>
        </>
    );
});

export default RDPProposal;