import * as React from "react";
import { FC } from 'react';
import { IProjectWithId } from "../../../routes/pages/ProjectList";
import FieldSummaryTable from "./FieldSummaryTable";
import SystemSummaryTable from "./SystemSummaryTable";

interface Props {
    systemIds: string[];
    layoutId: string;
    pwid: IProjectWithId;
}

const MultipleSystemSummaryPage: FC<Props> = React.forwardRef((props) => {
    const {systemIds, pwid, layoutId} = props;

    let tables: JSX.Element[] = [<FieldSummaryTable systemIds={systemIds} pwid={pwid} layoutId={layoutId}/>];
    
    systemIds.forEach((systemId) => {
        tables.push(<SystemSummaryTable systemId={systemId} pwid={pwid} layoutId={layoutId}/>);
    });

    return <>{tables}</>;
});

export default MultipleSystemSummaryPage;