import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { InputAdornment, TextField } from "@mui/material";
import IAction from "interface/IAction";
import INumber from "rdptypes/roe/INumber";
import * as React from "react";
import { FC, useContext } from "react";
import { createNewMultiAction } from "../../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../auth/AuthCtx";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import IDbProject from "../../../db/IDbProject";
import { feetToMeters, metersToFeet } from "../../../docGeneration/DocumentGenerationHelpers";
import { getValue } from "../../../helpers/objectPathResolver";
import { translateBase, translateUnit, translateUnits } from "../../../helpers/translation";
import ISystem from "../../../model/project/ISystem";
import { pageStyles, secondaryColor } from "../styles";
import IComponentRenderContext from "./IComponentRenderContext";

interface Props {
    cmp: INumber,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    ctx: IComponentRenderContext,
    parentGroupLabel: string | null,
    small?: boolean;
};

const getStringValueFromNumber = (value: number, decimalPlaces?: number): string | undefined => {
    if (value === undefined || value === null) return undefined;
    if (decimalPlaces === undefined) return value.toString();
    return value.toFixed(decimalPlaces);
}

const getStringValueFromSystem = (system: ISystem, path: string, decimalPlaces?: number): string | undefined => {
    let val = getValue(system, path);
    return getStringValueFromNumber(val, decimalPlaces);
}

const getDecimalPlaces = (cmp: INumber): number | undefined => {
    if (cmp.isInteger) return 0;
    if (cmp.decimalPlaces === undefined) return undefined;
    return cmp.decimalPlaces;
}

const NumberRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];
    const settings = React.useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;

    const ftToM = displaySettings.lengths === "meters" && (props.cmp.units && (props.cmp.units === "ft" || props.cmp.units === "feet" || (props.cmp.units["en"] && (props.cmp.units["en"] === "ft" || props.cmp.units["en"] === "feet"))));
    let dp = getDecimalPlaces(props.cmp);
    const decimalPlaces = ftToM && dp === undefined ? 2 : dp;

    const [value, setValue] = React.useState(getStringValueFromSystem(system, props.ctx.fieldRoot + props.cmp.fieldPath, decimalPlaces));    
    React.useEffect(()=> {
        setValue(getStringValueFromSystem(system, props.ctx.fieldRoot + props.cmp.fieldPath, decimalPlaces));
    }, [getValue(system, props.ctx.fieldRoot + props.cmp.fieldPath)]);
    
    const fieldPath = props.ctx.fieldRoot + props.cmp.fieldPath;
    const fvr = props.ctx.vws.validationResult?.getField(fieldPath);
    const errorSeverity = fvr?.severity;

    let showLabel = props.parentGroupLabel !== props.cmp.title;
    let textInputStyle = {};
    if (props.parentGroupLabel === null)
    {
        textInputStyle["marginLeft"] = props.small ? "4px" :  "10px";
        textInputStyle["paddingRight"] = props.small ? "4px" : "20px";
    }
    if (props.cmp.small){
        textInputStyle["marginLeft"] = "10px";
    }

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};

    if (errorSeverity !== "error" && errorSeverity !== "notset" && props.parentGroupLabel !== props.cmp.title && props.parentGroupLabel !== null)
    {
        formInputLabelStyle["color"] = secondaryColor;
    }

    const disabled = props.cmp.disabled?.(system) ?? false;

    const roundVal = (val: number): number => {
        return Math.round(val * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    }

    let icon: React.ReactNode = null;
    switch (props.cmp.icon) {
        case "rotate-left":
            icon = <RotateLeftIcon />
            break;
        case "rotate-right":
            icon = <RotateRightIcon />
            break;
    }

    let divStyle: React.CSSProperties = props.cmp.small ? {} : {display: 'flex', flexDirection: 'column', };

    return (
        <>
            <div style={divStyle} className="number-input">
                {
                    showLabel && (
                        <span style={{
                            color: errorSeverity === "error" || errorSeverity === "notset" ? "red" : '#1976d2',
                            fontSize: 14, fontWeight: 700, paddingLeft: 10, 
                            display: "flex", alignItems: "center" 
                        }}>
                            {icon}
                            {translateUnit(props.cmp, displaySettings)}
                        </span>
                    )
                }
                <TextField
                    error={errorSeverity === "error" || errorSeverity === "notset"}
                    InputLabelProps={{sx:formInputLabelStyle, shrink: true}}
                    style={textInputStyle}
                    variant="standard"
                    size="small"
                    type={"number"}
                    value={ftToM ? roundVal(feetToMeters(parseFloat(value))) : value}
                    disabled={disabled !== false}
                    title={disabled !== false ? translateBase(disabled) : undefined}
                    onChange={(ev) => {
                        let val = parseFloat(ev.target.value);
                        if (ftToM){
                            val = metersToFeet(val);
                            val = roundVal(val);
                        }
                        setValue(val.toString());
                    }}
                    onBlur={(ev) => {
                        let valNum = parseFloat(ev.target.value);
                        if (ftToM){
                            valNum = metersToFeet(valNum);
                        }

                        if (decimalPlaces !== undefined) {
                            valNum = roundVal(valNum);
                        }
                        if (props.cmp.min !== undefined && valNum < props.cmp.min) {
                            valNum = props.cmp.min;
                        }
                        else if (props.cmp.min !== undefined && valNum > props.cmp.max) {
                            valNum = props.cmp.max
                        }

                        const actions: IAction[] = [];
                        for (const fieldRoot of props.ctx.changeFieldRoots) {
                            actions.push(
                                createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    fieldRoot + props.cmp.fieldPath,
                                    valNum,
                                    authState
                                )
                            )
                            const hookResult = props.cmp.hook?.(valNum, system) || [];
                            for (const r of hookResult) {
                                actions.push(
                                    createNewUpdateSystemPropertyAction(
                                        props.layoutId,
                                        props.systemId,
                                        fieldRoot + r.fieldPath,
                                        r.value,
                                        authState,
                                    )
                                )
                            }
                        }
                        props.ctx.pushActionAndImproveScores(
                            createNewMultiAction(actions, authState),
                            props.ctx.changeFieldRoots.map(fieldRoot => fieldRoot + props.cmp.fieldPath), false);
                        setValue(getStringValueFromNumber(valNum, decimalPlaces));
                    }}
                    InputProps={{
                        sx: {
                            color: errorSeverity === "error" || errorSeverity === "notset" ? "red" : undefined
                        },
                        startAdornment: props.cmp.prefix ? <InputAdornment position="start">{props.cmp.prefix}</InputAdornment> : undefined,
                        endAdornment: props.cmp.units ? <InputAdornment position="end">{translateUnits(props.cmp, displaySettings)}</InputAdornment> : undefined
                    }}
                    inputProps={{
                        min: props.cmp.min,
                        max: props.cmp.max,
                        step: decimalPlaces === undefined ? undefined : Math.pow(10, -decimalPlaces),
                        style: {fontSize: props.small ? "12px" : "1rem", minWidth: "40px", maxWidth: props.small ? "40px" : "100%"}
                    }}
                />
            </div>
        </>
    );
};

export default NumberRenderer;