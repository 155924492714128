import { D3000Plates, DeviceTypes, DeviceWeights, DropTypes, FittingTypes, HoseClamps, HoseDropWeights, HoseTopFittings, IPackage, LDNChemPadTypes, LDNPadTypes, LDNTypes, NozzleClipTypes, OutletValveTypes, RegulatorLocations, RegulatorPressures, RegulatorTypes, RigidDropBottomFittings, RigidDropMaterialTypes, RigidDropTypes, SuperSprayPadTypes, ThreadTypes, UPipeFittings, UPipeMaterialTypes, UPipeReaches, UPipeTypes, WeightBottomFittings, WeightTopFittings, idxSprinkler, idxWobbler, idxXiWob } from "rdptypes/project/ISprinklers";
import { ISystemBase, SprinklerManufacturerTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { DeterminePackageTypeFromDeviceType } from "roedata/roe_migration/OtherHelpers";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { SpansClass } from "roedata/roe_migration/SpansClass";
import { HasEnergySaverPackage, HasSwingArmCorner, HasVRI } from "roedata/roe_migration/SystemFunctions";
import { D3000OptionArrayOk, DeviceWeightOkay, DeviceWeightOnRigidOkay, DropBottomFittingOptionOk, DropLengthOptionOk, DropTypeOptionOk, DropWeightOptionOk, FittingOptionOk, HoseTopFittingOptionOk, LDNChemPadTypeOptionOk, LDNPadTypeOptionOk, PSIOptionKometOk, PSIOptionNelsonOk, PSIOptionSenningerOk, RSN3000OptionOK, UPipeFittingOK, UPipeOptionOk, UPipeReachOK, UPipeTypeOK, WeightBottomFittingOptionOk } from "roedata/roe_migration/Valids.Valids";
import { idxRSNPlates } from "roedata/roe_migration/Valids.dto.Valids";
import { DetermineStartAndStopSpans, getAllEnumValues } from "./SprinklerHelper";

export const DetermineIWobblerFromDeviceType = (deviceType: DeviceTypes):idxWobbler  => {
    switch(deviceType){
        case DeviceTypes.SenningerLowAngleIWobWhite:
            return idxWobbler.wob6GWhite;
        case DeviceTypes.SenningerLowAngleIWobBlue:
            return idxWobbler.wob9GBlue;
        case DeviceTypes.SenningerHighAngleIWobBlack:
            return idxWobbler.wob9GBlack;
        case DeviceTypes.SenningerStandardAngleIWobGray:
            return idxWobbler.wob6GGray;
        default: return null;       
    }
}

export const DetermineXiWobFromDeviceType = (deviceType: DeviceTypes): idxXiWob => {
    switch(deviceType){
        case DeviceTypes.SenningerXiWobBlack:
            return idxXiWob.xiWobBlack;
        case DeviceTypes.SenningerXiWobWhite:
            return idxXiWob.xiwobWhite;
        case DeviceTypes.SenningerXiWobBlue:
            return idxXiWob.xiwobBlue;
        case DeviceTypes.SenningerXiWobGray:
            return idxXiWob.xiwobGray;
        default: return null;
    }
}

export interface SprinklerExtras {
    packageType: idxSprinkler;
    plateType: idxRSNPlates;
    isFCN3000: boolean;
    xiWob: idxXiWob;
    wobbler: idxWobbler;
}

export const SenningerPlatesRequired = (deviceType: DeviceTypes): boolean => {
    switch(deviceType){
        case DeviceTypes.SenningerHighAngleIWobBlack:
        case DeviceTypes.SenningerLowAngleIWobBlue :
        case DeviceTypes.SenningerStandardAngleIWobGray :
        case DeviceTypes.SenningerLowAngleIWobWhite :
        case DeviceTypes.SenningerXiWobBlack :
        case DeviceTypes.SenningerXiWobWhite :
        case DeviceTypes.SenningerXiWobBlue :
        case DeviceTypes.SenningerXiWobGray :
        case DeviceTypes.SenningerSuperSpray :
        case DeviceTypes.SenningerLDNSpray :
        case DeviceTypes.SenningerXcelWobbler :
            return true;
        default: return false;

    }
}

export const GetLDNPadTypeOptions = (pck: IPackage): LDNPadTypes[] => {
    var types: LDNPadTypes[] = [];
    
    var data = {IsLDN: pck.Device === DeviceTypes.SenningerLDNSpray,HasHoseDropWeight: pck.Drop === DropTypes.Hose && pck.HoseDrop.DeviceWeight !== DeviceWeights.None,
        LDNType: pck.LDN.LDNType};
    Object.keys(LDNPadTypes).forEach((p: LDNPadTypes) => {     
        if(LDNPadTypeOptionOk(p, data)){
            types.push(p);
        }
    });

    return types;
}

export const GetLDNChemPadTypeOptions = (pck: IPackage): LDNChemPadTypes[] => {
    var types: LDNChemPadTypes[] = [];
    
    var data = {IsLDN: pck.Device === DeviceTypes.SenningerLDNSpray,HasHoseDropWeight: pck.Drop === DropTypes.Hose && pck.HoseDrop.DeviceWeight !== DeviceWeights.None,
        LDNType: pck.LDN.LDNType, HasHoseDrop: pck.Drop === DropTypes.Hose, LDNPadType: pck.LDN.Pad};
    Object.keys(LDNChemPadTypes).forEach((p: LDNChemPadTypes) => {   
        var ok = LDNChemPadTypeOptionOk(p, data);
        if(ok){
            types.push(p);
        }
    });

    return types;
}

export const GetRigidDropLengthOptions = (pck: IPackage): number[] => {
    return [5, 5.5, 6, 6.5, 7, 7.5, 8].filter(x => DropLengthOptionOk(x, {RigidDropMaterialType: pck.RigidDrop.DropMaterial, RigidDropType: pck.RigidDrop.DropType}));;
}

export const GetRigidDropMaterialTypes = (pck: IPackage, system: ISystemBase, flexSide: boolean): RigidDropMaterialTypes[] => {
    var spansClass = new SpansClass();
    spansClass.Quote = new QuoteClass(system);
    spansClass.Side = flexSide ? system.FlexSide : system.FlangedSide;
    var bIsO3000 = pck.Device === DeviceTypes.NelsonO3000OrbitorBlack || pck.Device ===  DeviceTypes.NelsonO3000OrbitorBlue || pck.Device ===  DeviceTypes.NelsonO3000OrbitorPurple;
    var bIsKometTwister = pck.Device === DeviceTypes.KometTwisterBlack || pck.Device ===  DeviceTypes.KometTwisterBlue || pck.Device ===  DeviceTypes.KometTwisterYellow || pck.Device ===  DeviceTypes.KometTwisterWhite;
    const startStopSpans = DetermineStartAndStopSpans(pck, system, spansClass.Side, true);
    var dto = { DeviceType: pck.Device, IsIWob: DeviceTypeIWob(pck.Device), IsO3000: bIsO3000, AnyPoly: spansClass.AnyPoly(), IsXiWob: DeviceTypeXiWob(pck.Device),
     AnyAlum: spansClass.AnyAluminum(), IsKometTwister: bIsKometTwister,
    HasSAC: HasSwingArmCorner(system),
    HasVRI: HasVRI(system),
    HasSACAirCompressor: HasSwingArmCorner(system) && system.Circle.SwingArm.AirCompressor,
    PackageCoversSAC: startStopSpans.PackageCoversSAC,};
    return Object.keys(RigidDropMaterialTypes).map((x: RigidDropMaterialTypes) => x).filter(x => DropTypeOptionOk(x, dto));
}

export const ReinforcementClampEnabled = (pck: IPackage) => {
    return pck.RigidDrop.DropType === RigidDropTypes.Variable || pck.RigidDrop.Length  === 7 || pck.RigidDrop.Length  === 7.5 || pck.RigidDrop.Length  === 8;
}

export const GetUPipeReachOptions = (pck: IPackage, system: ISystemBase, flexSide: boolean): UPipeReaches[] => {
    var spansClass = new SpansClass();
    spansClass.Quote = new QuoteClass(system);
    spansClass.Side = flexSide ? system.FlexSide : system.FlangedSide;
    return Object.keys(UPipeReaches).map((x: UPipeReaches) => x).filter(x => UPipeReachOK(x, {IsPolyOnly: spansClass.AnyPoly()}));
}

export const GetUpipeFittingOptions = (pck: IPackage): UPipeFittings[] => {
    return Object.keys(UPipeFittings).map((x: UPipeFittings) => x).filter(x => UPipeFittingOK(x, {UPipeReach: pck.HoseDrop.UPipeReach}));
}

export const GetUPipeTypeOptions = (pck: IPackage, system: ISystemBase, flexSide: boolean): UPipeTypes[] => {
    var spansClass = new SpansClass();
    spansClass.Quote = new QuoteClass(system);
    spansClass.Side = flexSide ? system.FlexSide : system.FlangedSide;
    const startStopSpans = DetermineStartAndStopSpans(pck, system, spansClass.Side, true);
    return Object.keys(UPipeTypes).map((x: UPipeTypes) => x).filter(x => UPipeTypeOK(x, {
        IsPolyOnly: spansClass.AnyPoly(), 
        AnyAlum: spansClass.AnyAluminum(),
        Is2045: spansClass.Any2045(),
        UPipeFitting: pck.HoseDrop.UPipeFitting,
        UPipeReach: pck.HoseDrop.UPipeReach,
        HasSAC: HasSwingArmCorner(system),
        PackageCoversSAC: startStopSpans.PackageCoversSAC,
    }));
}

export const GetRegulatorLocationOptions = (pck: IPackage): RegulatorLocations[] => {
    var locations: RegulatorLocations[] = [];
    if(!(pck.Regulator.RegulatorType === RegulatorTypes.None || pck.Regulator.Thread === ThreadTypes.Square || pck.Device === DeviceTypes.SenningerQuadSpray ||
        ([DeviceTypes.SenningerHighAngleIWobBlack, DeviceTypes.SenningerLowAngleIWobBlue,
            DeviceTypes.SenningerStandardAngleIWobGray, DeviceTypes.SenningerLowAngleIWobWhite,
            DeviceTypes.SenningerXiWobBlack, DeviceTypes.SenningerXiWobBlue,
            DeviceTypes.SenningerXiWobGray, DeviceTypes.SenningerXiWobWhite,
            DeviceTypes.KometTwisterBlack, DeviceTypes.KometTwisterBlue,
            DeviceTypes.KometTwisterYellow, DeviceTypes.KometTwisterWhite].indexOf(pck.Device) > -1 && pck.Regulator.Pressure === RegulatorPressures.a20) ||
            pck.HoseDrop.UPipeFitting === UPipeFittings.MxHB)){
        locations.push(RegulatorLocations.Top);
    }
    if(pck.Regulator.RegulatorType !== RegulatorTypes.None){
        locations.push(RegulatorLocations.Bottom);
    }

    return locations;
}

export const GetHoseTopFittings = (pck: IPackage): HoseTopFittings[] => {
    var bRegsOnTop =  pck.Regulator.RegulatorType !== RegulatorTypes.None && pck.HoseDrop.RegulatorLocation === RegulatorLocations.Top;
    return Object.keys(HoseTopFittings).map((x: HoseTopFittings) => x).filter(x => HoseTopFittingOptionOk(x, {UPipeFitting: pck.HoseDrop.UPipeFitting, HasRegsOnTop: bRegsOnTop}));
}

export const getHoseBottomClampOptions = (pck: IPackage): HoseClamps[] => {
    var clamps: HoseClamps[] = [HoseClamps.Pinch];
    if(pck.HoseDrop.Weight !== HoseDropWeights.SlipOver30Poly){
        clamps.unshift(HoseClamps.Screw);
    }

    return clamps;
}

export const GetDropWeightOptions = (pck: IPackage, system: ISystemBase, flexSide: boolean): HoseDropWeights[] => {
    var spansClass = new SpansClass();
    spansClass.Quote = new QuoteClass(system);
    spansClass.Side = flexSide ? system.FlexSide : system.FlangedSide;
    const isIWob = [DeviceTypes.SenningerHighAngleIWobBlack, DeviceTypes.SenningerLowAngleIWobBlue,
        DeviceTypes.SenningerStandardAngleIWobGray, DeviceTypes.SenningerLowAngleIWobWhite,
        DeviceTypes.SenningerXiWobBlack, DeviceTypes.SenningerXiWobBlue,
        DeviceTypes.SenningerXiWobGray, DeviceTypes.SenningerXiWobWhite,
        DeviceTypes.KometTwisterBlack, DeviceTypes.KometTwisterBlue,
        DeviceTypes.KometTwisterYellow, DeviceTypes.KometTwisterWhite].indexOf(pck.Device) > -1;
    return Object.keys(HoseDropWeights).map((x: HoseDropWeights) => x).filter(x => DropWeightOptionOk(x, {DeviceType: pck.Device, IsIWob: isIWob, 
        AnyAlum: spansClass.AnyAluminum(), AnyPoly: spansClass.AnyPoly()}));
}

export const GetWeightTopFittingOptions = (pck: IPackage): WeightTopFittings[] => {
    var fittings = [];
    var bNoWeightTopFitting = pck.HoseDrop.Weight === HoseDropWeights.SlipOver30Poly || pck.HoseDrop.Weight === HoseDropWeights.None || 
    pck.HoseDrop.Weight === HoseDropWeights.MxHB18Galv || pck.HoseDrop.Weight === HoseDropWeights.MxHB24Galv;

    if(bNoWeightTopFitting){
        fittings.push(WeightTopFittings.None);
    }

    if(!bNoWeightTopFitting && pck.HoseDrop.Weight === HoseDropWeights.FxF24Poly){
        fittings.push(WeightTopFittings.MxHBGray);
        fittings.push(WeightTopFittings.MxHBBlack);
    }

    if(!bNoWeightTopFitting && pck.HoseDrop.Weight === HoseDropWeights.MxM18Galv){
        fittings.push(WeightTopFittings.FxHBBlack);
    }
    
    return fittings;
}

export const GetWeightBottomFittingOptions = (pck: IPackage, system: ISystemBase, flexSide: boolean): WeightBottomFittings[] => {
    var spansClass = new SpansClass();
    spansClass.Quote = new QuoteClass(system);
    spansClass.Side = flexSide ? system.FlexSide : system.FlangedSide;  
    const bIsIWob = [DeviceTypes.SenningerHighAngleIWobBlack, DeviceTypes.SenningerLowAngleIWobBlue,
        DeviceTypes.SenningerStandardAngleIWobGray, DeviceTypes.SenningerLowAngleIWobWhite,
        DeviceTypes.SenningerXiWobBlack, DeviceTypes.SenningerXiWobBlue,
        DeviceTypes.SenningerXiWobGray, DeviceTypes.SenningerXiWobWhite,
        DeviceTypes.KometTwisterBlack, DeviceTypes.KometTwisterBlue,
        DeviceTypes.KometTwisterYellow, DeviceTypes.KometTwisterWhite].indexOf(pck.Device) > -1;
    
    return Object.keys(WeightBottomFittings).map((x: WeightBottomFittings) => x).filter(x => WeightBottomFittingOptionOk(x, {
        IsNoWeightBottomFittingOK: bNoWeightBottomFittingOK(pck), HoseDropWeight: pck.HoseDrop.Weight,
            HasFemaleConnection: bFemaleConnection(pck), HasMaleConnection: bMaleConnection(pck), AnyPoly: spansClass.AnyPoly(), IsIWob: bIsIWob
    }));
}

const bRegsOnBottom = (pck: IPackage): boolean => {
    return pck.Regulator.RegulatorType !== RegulatorTypes.None && pck.HoseDrop.RegulatorLocation ===RegulatorLocations.Bottom;
}

const bForceMaleConnection = (pck: IPackage): boolean => {
    var bForceMaleConnection: boolean = false;
    if(pck.Device === DeviceTypes.SenningerQuadSpray){
            bForceMaleConnection = true;
    }
    if(bRegsOnBottom(pck) && ! pck.Regulator.AsNeeded) {
            bForceMaleConnection = true;
    }

    return bForceMaleConnection;
}

const bMaleConnection = (pck: IPackage): boolean => {
    return bRegsOnBottom(pck) || bForceMaleConnection(pck);
}

const bFemaleConnection = (pck: IPackage): boolean => { return  !bMaleConnection(pck); }

const bNoWeightBottomFittingOK = (pck: IPackage): boolean => {
    return  (bForceMaleConnection(pck) && 
    [HoseDropWeights.MxM18Galv, HoseDropWeights.MxHB18Galv, HoseDropWeights.MxHB24Galv, HoseDropWeights.SlimMxHB, HoseDropWeights.NelsonCoil].indexOf(pck.HoseDrop.Weight) > -1) ||
        (bFemaleConnection(pck) && pck.HoseDrop.Weight === HoseDropWeights.FxF24Poly);
}

export const STxHBoptionEnabled = (pck: IPackage): boolean => {
    var bSpecialThreadOK = [DeviceTypes.NelsonD3000Spray, DeviceTypes.NelsonD3000FCNSpray, DeviceTypes.NelsonR3000RotatorRed, DeviceTypes.NelsonR3000RotatorBlue,
        DeviceTypes.NelsonR3000RotatorGreen, DeviceTypes.NelsonR3000RotatorOrange, DeviceTypes.NelsonR3000RotatorBrown,DeviceTypes.NelsonR3000RotatorOlive,
        DeviceTypes.NelsonR3000RotatorWhite, DeviceTypes.NelsonR3000FCNRotatorBlue, DeviceTypes.NelsonR3000FCNRotatorGreen, DeviceTypes.NelsonS3000SpinnerRed,
        DeviceTypes.NelsonS3000SpinnerPurple, DeviceTypes.NelsonS3000SpinnerYellow, DeviceTypes.NelsonS3000SpinnerPurpleLowPressure, DeviceTypes.NelsonS3000SpinnerYellowLowPressure,
        DeviceTypes.NelsonA3000AcceleratorMaroon, DeviceTypes.NelsonA3000AcceleratorGold, DeviceTypes.NelsonA3000AcceleratorNavy,
         DeviceTypes.NelsonA3000AcceleratorNavyLowPressure].indexOf(pck.Device) > -1;

    return !bNoWeightBottomFittingOK(pck) && !bForceMaleConnection(pck) && bSpecialThreadOK &&
     (pck.HoseDrop.Weight === HoseDropWeights.SlipOver30Poly || pck.HoseDrop.Weight === HoseDropWeights.None)
}

export const GetDeviceWeights = (pck: IPackage): DeviceWeights[] => {
    return Object.keys(DeviceWeights).map(x => x as DeviceWeights).filter(x => DeviceWeightOkay(x, {DeviceType: pck.Device, HoseDropWeight: pck.HoseDrop.Weight, RegulatorLocation: pck.HoseDrop.RegulatorLocation,
    RegulatorType: pck.Regulator.RegulatorType,UseNelson3030: pck.UseNelson3030, RegulatorUseAsNeeded: pck.Regulator.AsNeeded}));
}

export const GetRigidDropUPipeOptions = (pck: IPackage, system: ISystemBase, flexSide: boolean): UPipeMaterialTypes[] => {
    var spansClass = new SpansClass();
    spansClass.Quote = new QuoteClass(system);
    spansClass.Side = flexSide ? system.FlexSide : system.FlangedSide;  
    const startStopSpans = DetermineStartAndStopSpans(pck, system, spansClass.Side, true);
    return Object.keys(UPipeMaterialTypes).map((x: UPipeMaterialTypes) => x).filter(x => UPipeOptionOk(x, {
        RigidDropMaterialType: pck.RigidDrop.DropMaterial,
        AnyPoly: spansClass.AnyPoly(),
        AnyAlum: spansClass.AnyAluminum(),
        RigidDropLength: pck.RigidDrop.Length,
        HasSAC: HasSwingArmCorner(system),
        PackageCoversSAC: startStopSpans.PackageCoversSAC}));
}

export const GetRigidWeightOptions = (pck: IPackage): DeviceWeights[] => {   
    return (Object.keys(DeviceWeights)).map(x => x as DeviceWeights).filter(x => DeviceWeightOnRigidOkay(x, {DeviceType: pck.Device, RigidDropMaterialType: pck.RigidDrop.DropMaterial}));
}

export const GetRigidDropBottomFittingOptions = (pck: IPackage, system: ISystemBase, flexSide: boolean): RigidDropBottomFittings[] => {   
    var spansClass = new SpansClass();
    spansClass.Quote = new QuoteClass(system);
    spansClass.Side = flexSide ? system.FlexSide : system.FlangedSide;  
    return Object.keys(RigidDropBottomFittings).map((x: RigidDropBottomFittings) => x).filter(x => DropBottomFittingOptionOk(x, 
        {AnyPoly: spansClass.AnyPoly(), AnyAlum: spansClass.AnyAluminum(), UPipeMaterialType: pck.RigidDrop.UPipeMaterial}));
}

export const GetFittingOptions = (pck: IPackage, system: ISystemBase, flexSide: boolean):FittingTypes[] => {
    var types: FittingTypes[] = [];
    var spansClass = new SpansClass();
    spansClass.Quote = new QuoteClass(system);
    spansClass.Side = flexSide ? system.FlexSide : system.FlangedSide;
    var data = {Package: pck, RegulatorType: pck.Regulator.RegulatorType, 
        UseStainlessSteelMountsAndFittings: flexSide ? system.FlexSide.EndOfSystem.StainlessSteelMounts : system.FlangedSide.EndOfSystem.StainlessSteelMounts,
        SpansClass: spansClass, PackageClass: null}; 
    Object.keys(FittingTypes).map((x: FittingTypes) => x).forEach((p: FittingTypes) => {   
        var ok = FittingOptionOk(p, data);
        if(ok){
            types.push(p);
        }
    });
    return types;
}

export const DeviceTypeXiWob = (type: DeviceTypes): boolean => {
    switch(type){
        case DeviceTypes.SenningerXiWobBlack:
        case DeviceTypes.SenningerXiWobWhite:
        case DeviceTypes.SenningerXiWobBlue:
        case DeviceTypes.SenningerXiWobGray:
            return true;
        default: return false;
    }
}

export const DeviceTypeIWob = (type: DeviceTypes): boolean => {
    switch(type){
        case DeviceTypes.SenningerHighAngleIWobBlack:
        case DeviceTypes.SenningerLowAngleIWobBlue:
        case DeviceTypes.SenningerStandardAngleIWobGray:
        case DeviceTypes.SenningerLowAngleIWobWhite:
            return true;
        default: return false;
    }
}

export const GetSuperSprayPadOptions = (pck: IPackage): SuperSprayPadTypes[] => {
    var types: SuperSprayPadTypes[] = [];
    if(pck.Device === DeviceTypes.SenningerSuperSpray){
        types.push(SuperSprayPadTypes.Concave36DeepBlue);
        types.push(SuperSprayPadTypes.Concave36MedBlue);
        types.push(SuperSprayPadTypes.Flat24DeepBlack);
        types.push(SuperSprayPadTypes.Flat36DeepBlack);
        if(pck.Drop !== DropTypes.None){
            types.push(SuperSprayPadTypes.Flat36MedBlack);
            types.push(SuperSprayPadTypes.FlatSmoothBlack);
        }
        if(pck.Drop === DropTypes.Hose){
            types.push(SuperSprayPadTypes.CottonChemWhite);
        }
    }

    return types;
}

export const GetXiWobTypes = (pck: IPackage) => {
    if(DeviceTypeXiWob(pck.Device) && pck.Drop !== DropTypes.None){
        return [idxXiWob.xiWobBlack, idxXiWob.xiwobBlue, idxXiWob.xiwobGray];
    }else{
        return [];
    }
}

export const GetLDNTypeOptions = ( pck: IPackage): LDNTypes[] => {
    var types: LDNTypes[] = [];
    if(pck.Device === DeviceTypes.SenningerLDNSpray && pck.Drop !== DropTypes.None){
        types.push(LDNTypes.MultiPad);
    }
    if(pck.Device === DeviceTypes.SenningerLDNSpray){
        types.push(LDNTypes.SinglePad);
    }
    return types;
}

export const DetermineSprinklerExtrasFromPackage = (pck: IPackage): SprinklerExtras =>{
    return {
        packageType: DeterminePackageTypeFromDeviceType(pck),
        isFCN3000: DetermineIs3000FCNFromDeviceType(pck.Device),
        plateType: DetermineRSAPlateFromDeviceType(pck.Device),
        xiWob: DetermineXiWobFromDeviceType(pck.Device),
        wobbler: DetermineIWobblerFromDeviceType(pck.Device)
    }
}

export const GetD3000PlateOptions = (pck: IPackage): D3000Plates[] => {
    var options: D3000Plates[] = [];
    var dto = {
        IsD3000: true,
        DropType: pck.Drop,
        NozzleClipType: NozzleClipTypes.None,
        CountChecked: pck.D3000.Plate3 !== null ? 3 : pck.D3000.Plate2 !== null ? 2 : pck.D3000.Plate1 !== null ? 1 : 0,
        BubblerClipLEPAChecked: pck.D3000.BubblerClipLEPA,
        SprinklerConverterChecked: pck.D3000.SprinklerConverter,
        RegulatorPressure: pck.Regulator.Pressure,
        D3000PlatesChecked: [pck.D3000.Plate1, pck.D3000.Plate2, pck.D3000.Plate3],
        RegulatorType: pck.Regulator.RegulatorType,
    };
    Object.keys(D3000Plates).map((x: D3000Plates) => x).forEach((p: D3000Plates) => {
            var ok = D3000OptionArrayOk(true, p, dto);
            if(ok){
                options.push(p);
            }
    });
    return options;
}

export const D3000SelectEnabled = (deviceType: DeviceTypes): boolean => {
    return deviceType === DeviceTypes.NelsonD3000Spray || deviceType === DeviceTypes.NelsonD3000FCNSpray;
}

export const GetRSN3000Options = (pck: IPackage, system: ISystemBase): idxRSNPlates[] => {
    var plates: idxRSNPlates[] = [];
    var bIsD3000: boolean = false;
    var bIsR3000: boolean = false;
    var bIsS3000: boolean = false;
    var bIsA3000: boolean = false;
    var bIsO3000: boolean = false;
    switch(pck.Device){
            case DeviceTypes.NelsonD3000Spray:
            case DeviceTypes.NelsonD3000FCNSpray:
                bIsD3000 = true;
                break;
            case DeviceTypes.NelsonR3000RotatorRed:
            case DeviceTypes.NelsonR3000RotatorBlue:
            case DeviceTypes.NelsonR3000RotatorWhite:
            case DeviceTypes.NelsonR3000FCNRotatorBlue:
            case DeviceTypes.NelsonR3000RotatorGreen: 
            case DeviceTypes.NelsonR3000FCNRotatorGreen:
            case DeviceTypes.NelsonR3000RotatorOrange:
            case DeviceTypes.NelsonR3000RotatorBrown:
            case DeviceTypes.NelsonR3000RotatorOlive:
                bIsR3000 = true;
                break;
            case DeviceTypes.NelsonS3000SpinnerRed: 
            case DeviceTypes.NelsonS3000SpinnerPurple: 
            case DeviceTypes.NelsonS3000SpinnerYellow:
            case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure:
            case DeviceTypes.NelsonS3000SpinnerYellowLowPressure:
                bIsS3000 = true;
                break;
            case DeviceTypes.NelsonA3000AcceleratorMaroon:
            case DeviceTypes.NelsonA3000AcceleratorGold:
            case DeviceTypes.NelsonA3000AcceleratorNavy:
            case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
                bIsA3000 = true;
                break;
            case DeviceTypes.NelsonO3000OrbitorBlack:
            case DeviceTypes.NelsonO3000OrbitorBlue:
            case DeviceTypes.NelsonO3000OrbitorPurple:
            case DeviceTypes.NelsonO3030OrbitorFXBlack:
            case DeviceTypes.NelsonO3030OrbitorFXWhite:
                bIsO3000 = true;
                break;
    }
    if(!bIsR3000 && !bIsS3000 && !bIsA3000 && !bIsO3000){
        return [];
    }
    const dto = {
        DeviceType: pck.Device,
        DropType: pck.Drop,
        PivotCenterHeight: system.Circle.CenterPivot.PivotCenterHeight,
        RegulatorPressure: pck.Regulator.Pressure,
        RegulatorType: pck.Regulator.RegulatorType,
        SystemType: system.SystemProperties.SystemType,
        IsA3000: bIsA3000,
        IsO3000: bIsO3000,
        IsR3000: bIsR3000,
        IsS3000: bIsS3000,
        UseNelson3030: pck.UseNelson3030,
        HoseDropGroundClearance: pck.HoseDrop.GroundClearance,
        RigidDropGroundClearance: pck.RigidDrop.GroundClearance,
        RigidDropType: pck.RigidDrop.DropType,
        SprinklerConverterChecked: pck.D3000.SprinklerConverter,
    };
    getAllEnumValues(Object.keys(idxRSNPlates)).forEach((p: idxRSNPlates) => {
        if(RSN3000OptionOK(p, dto)){
            plates.push(p);
        }
    });

    return plates;
}

export const GetRegulatorOptionFromPackageType = (packageType: idxSprinkler): RegulatorTypes[] => {
    switch(packageType){
        case idxSprinkler.NelsonR3000Rotator:
        case idxSprinkler.NelsonS3000Spinner:
        case idxSprinkler.NelsonO3000Orbitor:
        case idxSprinkler.NelsonA3000Accelerator:
        case idxSprinkler.NelsonD3000Spray:
            return [RegulatorTypes.None, RegulatorTypes.NelsonBlueLFHF];
        case idxSprinkler.NelsonO3030OrbitorFX:
            return [RegulatorTypes.NelsonBlueLFHF];
        case idxSprinkler.sprSenningerConstantSpacingPlasticImpact:
        case idxSprinkler.sprSenningerVariableSpacingPlasticImpactWhite:
        case idxSprinkler.sprSenningerVariableSpacingPlasticImpactRed:
            return [RegulatorTypes.None, RegulatorTypes.SenningerLFMF];
        case idxSprinkler.sprSenningerIWob:
        case idxSprinkler.sprSenningerXiwob:
        case idxSprinkler.sprSenningerSuperSpray:
        case idxSprinkler.sprSenningerLDNSpray:
            return [RegulatorTypes.None, RegulatorTypes.SenningerLFMF, RegulatorTypes.SenningerPSR];
        case idxSprinkler.sprSenningerXcelWobbler:
            return [RegulatorTypes.SenningerPSR];
        case idxSprinkler.sprKometTwisterWhite:
        case idxSprinkler.sprKometTwisterBlack:
        case idxSprinkler.sprKometTwisterBlue:
        case idxSprinkler.sprKometTwisterYellow:
        case idxSprinkler.sprKometSprayBlack:
        case idxSprinkler.sprKometSprayBlue:
        case idxSprinkler.sprKometSprayYellow:
        case idxSprinkler.sprKometSprayGrey:
            return [RegulatorTypes.None, RegulatorTypes.KometKPR];       
    }

    return [];
}

export const PlatesRequired = (deviceType: DeviceTypes): boolean => {
    switch(deviceType){
        case DeviceTypes.NelsonR3000FCNRotatorBlue: 
        case DeviceTypes.NelsonR3000RotatorBlue: 
        case DeviceTypes.NelsonR3000RotatorWhite: 
        case DeviceTypes.NelsonR3000FCNRotatorGreen: 
        case DeviceTypes.NelsonR3000RotatorGreen: 
        case DeviceTypes.NelsonR3000RotatorRed: 
        case DeviceTypes.NelsonR3000RotatorBrown: 
        case DeviceTypes.NelsonR3000RotatorOlive: 
        case DeviceTypes.NelsonR3000RotatorOrange: 
        case DeviceTypes.NelsonS3000SpinnerPurple: 
        case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure: 
        case DeviceTypes.NelsonS3000SpinnerRed: 
        case DeviceTypes.NelsonS3000SpinnerYellow: 
        case DeviceTypes.NelsonS3000SpinnerYellowLowPressure: 
        case DeviceTypes.NelsonD3000FCNSpray: 
        case DeviceTypes.NelsonD3000Spray: 
        case DeviceTypes.NelsonO3000OrbitorBlack: 
        case DeviceTypes.NelsonO3000OrbitorBlue: 
        case DeviceTypes.NelsonO3000OrbitorPurple: 
        case DeviceTypes.NelsonO3030OrbitorFXBlack: 
        case DeviceTypes.NelsonO3030OrbitorFXWhite: 
        case DeviceTypes.NelsonA3000AcceleratorMaroon: 
        case DeviceTypes.NelsonA3000AcceleratorGold: 
        case DeviceTypes.NelsonA3000AcceleratorNavy:
        case DeviceTypes.SenningerHighAngleIWobBlack: 
        case DeviceTypes.SenningerLowAngleIWobBlue:
        case DeviceTypes.SenningerStandardAngleIWobGray: 
        case DeviceTypes.SenningerLowAngleIWobWhite:
        case DeviceTypes.SenningerXiWobBlack: 
        case DeviceTypes.SenningerXiWobWhite:
        case DeviceTypes.SenningerXiWobBlue:
        case DeviceTypes.SenningerXiWobGray:
        case DeviceTypes.SenningerSuperSpray:
        case DeviceTypes.SenningerLDNSpray:
        case DeviceTypes.SenningerXcelWobbler:
            return true;
        default: return false;
    }
}

export const GetValveTypes = (pck: IPackage): OutletValveTypes[] => {
    var options = [OutletValveTypes.None, OutletValveTypes.Brass];
    if(pck.Drop === DropTypes.Hose || (pck.Drop === DropTypes.Rigid && pck.RigidDrop.DropMaterial !== RigidDropMaterialTypes.Galvanized)){
        options.push(OutletValveTypes.PlasticLever);
        options.push(OutletValveTypes.PlasticKnob);
    }

    return options;
}

export const GetRegulatorPressureFromRegulatorType = (pck: IPackage, manufacturer: SprinklerManufacturerTypes): RegulatorPressures[] => {
    
    var allPSIOptions = [RegulatorPressures.a6, RegulatorPressures.a10, RegulatorPressures.a15, RegulatorPressures.a20, RegulatorPressures.a25, RegulatorPressures.a30, RegulatorPressures.a40];
    var availableOptions = [];

    switch(manufacturer){
        case SprinklerManufacturerTypes.Nelson:
            allPSIOptions.forEach(psi => {
                if(PSIOptionNelsonOk(psi, {
                    DeviceType: pck.Device,
                    RegulatorType: pck.Regulator.RegulatorType,
                    DropType: pck.Drop,
                    PackageNumber: pck.PackageNumber,
                })){
                    availableOptions.push(psi);
                }
            });
            break;
        case SprinklerManufacturerTypes.Komet:
            allPSIOptions.forEach(psi => {
                if(PSIOptionKometOk(psi, {
                    DeviceType: pck.Device,
                    RegulatorType: pck.Regulator.RegulatorType,
                    DropType: pck.Drop,
                    PackageNumber: pck.PackageNumber,
                })){
                    availableOptions.push(psi);
                }
            });
            break;
        case SprinklerManufacturerTypes.Senninger:
            allPSIOptions.forEach(psi => {
                if(PSIOptionSenningerOk(psi, {
                    DeviceType: pck.Device,
                    RegulatorType: pck.Regulator.RegulatorType,
                    DropType: pck.Drop,
                    PackageNumber: pck.PackageNumber,
                })){
                    availableOptions.push(psi);
                }
            });
            break;
        default: return [];
    }
    return availableOptions;
}

export const GetNozzleClipFromPackageType = (packageType: idxSprinkler, pck: IPackage, sys: ISystemBase): NozzleClipTypes[] => {
    switch(packageType){
        case idxSprinkler.sprSenningerConstantSpacingPlasticImpact:
        case idxSprinkler.sprSenningerVariableSpacingPlasticImpactWhite:
        case idxSprinkler.sprSenningerVariableSpacingPlasticImpactRed:
        case idxSprinkler.NelsonO3030OrbitorFX:
        case idxSprinkler.sprSenningerXcelWobbler:
            return [NozzleClipTypes.None];
        case idxSprinkler.sprSenningerIWob:
            return [NozzleClipTypes.None, NozzleClipTypes.Dual, NozzleClipTypes.Triple];      
        case idxSprinkler.NelsonR3000Rotator:
        case idxSprinkler.NelsonS3000Spinner:
        case idxSprinkler.NelsonA3000Accelerator:
        case idxSprinkler.NelsonD3000Spray: 
            if(pck.UseNelson3030){
                return [NozzleClipTypes.None, NozzleClipTypes.Dual];   
            }else{
                return [NozzleClipTypes.None, NozzleClipTypes.Dual, NozzleClipTypes.Triple];   
            }    
        case idxSprinkler.NelsonO3000Orbitor:
            if (!HasEnergySaverPackage(sys)) {
                if(pck.UseNelson3030){
                    return [NozzleClipTypes.None, NozzleClipTypes.Dual];   
                }else{
                    return [NozzleClipTypes.None, NozzleClipTypes.Dual, NozzleClipTypes.Triple];   
                }  
            }       
            else {
                return [NozzleClipTypes.None];
            } 
        case idxSprinkler.sprSenningerXiwob:
        case idxSprinkler.sprSenningerSuperSpray:
        case idxSprinkler.sprSenningerLDNSpray:
        case idxSprinkler.sprKometTwisterWhite:
        case idxSprinkler.sprKometTwisterBlack:
        case idxSprinkler.sprKometTwisterBlue:
        case idxSprinkler.sprKometTwisterYellow:
        case idxSprinkler.sprKometSprayBlack:
        case idxSprinkler.sprKometSprayBlue:
        case idxSprinkler.sprKometSprayYellow:
        case idxSprinkler.sprKometSprayGrey:
            return [NozzleClipTypes.None, NozzleClipTypes.Dual];       
    }

    return [];
}

export const DetermineRSAPlateFromDeviceType = (deviceType: DeviceTypes): idxRSNPlates => {
    switch(deviceType){
        case DeviceTypes.NelsonR3000FCNRotatorBlue:
        case DeviceTypes.NelsonR3000RotatorBlue:
            return idxRSNPlates.rsnU4Blue;
        case DeviceTypes.NelsonR3000RotatorWhite :
            return idxRSNPlates.rsnU6White;
        case DeviceTypes.NelsonR3000RotatorRed :
        case DeviceTypes.NelsonS3000SpinnerRed :
            return idxRSNPlates.rsnD6Red;
        case DeviceTypes.NelsonR3000RotatorOrange :
            return idxRSNPlates.rsnD8Orange;
        case DeviceTypes.NelsonR3000RotatorBrown :
            return idxRSNPlates.rsnD10Brown;
        case DeviceTypes.NelsonR3000RotatorOlive :
            return idxRSNPlates.rsnD10Olive;
        case DeviceTypes.NelsonR3000FCNRotatorGreen :
        case DeviceTypes.NelsonR3000RotatorGreen :
            return idxRSNPlates.rsnD4Green;    
        case DeviceTypes.NelsonS3000SpinnerPurple :
            return idxRSNPlates.rsnD6Purple;        
        case DeviceTypes.NelsonS3000SpinnerYellow :
            return idxRSNPlates.rsnD8Yellow;
        case DeviceTypes.NelsonO3000OrbitorBlue :
            return idxRSNPlates.rsn9GBlue;
        case DeviceTypes.NelsonO3000OrbitorPurple :
            return idxRSNPlates.rsn18GPurple;
        case DeviceTypes.NelsonO3000OrbitorBlack :
            return idxRSNPlates.rsn9GBlack;
        case DeviceTypes.NelsonO3030OrbitorFXWhite :
            return idxRSNPlates.rsn9GWhite;
        case DeviceTypes.NelsonO3030OrbitorFXBlack :
           return idxRSNPlates.rsn9GBlack;
        case DeviceTypes.NelsonA3000AcceleratorGold :
            return idxRSNPlates.rsnD6Gold;
        case DeviceTypes.NelsonA3000AcceleratorNavy :
            return idxRSNPlates.rsnU6Navy;
        case DeviceTypes.NelsonA3000AcceleratorMaroon :
           return idxRSNPlates.rsnD4Maroon;       
        default:
            return null;

    }
}

export const DetermineIs3000FCNFromDeviceType = (deviceType: DeviceTypes): boolean => {
    switch(deviceType){
        case DeviceTypes.NelsonR3000FCNRotatorBlue:
        case DeviceTypes.NelsonR3000FCNRotatorGreen:
        case DeviceTypes.NelsonD3000FCNSpray:
            return true;
        default:
            return false;
    }
}

export const DetermineSprinklerDeviceType = (plateExtras: SprinklerExtras,  dropType: DropTypes) => {
    switch(plateExtras.packageType){
        case idxSprinkler.NelsonR3000Rotator:
            switch(plateExtras.plateType){
                case idxRSNPlates.rsnU4Blue:
                    if(plateExtras.isFCN3000){
                        return DeviceTypes.NelsonR3000FCNRotatorBlue;
                    }else{
                        return DeviceTypes.NelsonR3000RotatorBlue;
                    }
                case idxRSNPlates.rsnU6White:
                    return DeviceTypes.NelsonR3000RotatorWhite;
                case idxRSNPlates.rsnD6Red:
                    return DeviceTypes.NelsonR3000RotatorRed;
                case idxRSNPlates.rsnD8Orange:
                    return DeviceTypes.NelsonR3000RotatorOrange;
                case idxRSNPlates.rsnD10Brown:
                    return DeviceTypes.NelsonR3000RotatorBrown;
                case idxRSNPlates.rsnD10Olive:
                    return DeviceTypes.NelsonR3000RotatorOlive;
                case idxRSNPlates.rsnD4Green:
                    if(plateExtras.isFCN3000){
                        return DeviceTypes.NelsonR3000FCNRotatorGreen;
                    }else{
                        return DeviceTypes.NelsonR3000RotatorGreen;
                    }
                default:
                    if(plateExtras.isFCN3000){
                        return DeviceTypes.NelsonR3000FCNRotatorGreen;
                    }else{
                        return DeviceTypes.NelsonR3000RotatorGreen;
                    }                 
            }
        case idxSprinkler.NelsonS3000Spinner:
            switch (plateExtras.plateType){
                case idxRSNPlates.rsnD6Red:
                    return DeviceTypes.NelsonS3000SpinnerRed;
                case idxRSNPlates.rsnD6Purple:
                    return DeviceTypes.NelsonS3000SpinnerPurple;
                case idxRSNPlates.rsnD8Yellow:
                    return DeviceTypes.NelsonS3000SpinnerYellow;
                default:
                    return DeviceTypes.NelsonS3000SpinnerYellow;                   
            }
        case idxSprinkler.NelsonO3000Orbitor:
            switch(plateExtras.plateType){
                case idxRSNPlates.rsn9GBlue:
                    return DeviceTypes.NelsonO3000OrbitorBlue;
                case idxRSNPlates.rsn18GPurple:
                    return DeviceTypes.NelsonO3000OrbitorPurple;
                case idxRSNPlates.rsn9GBlack:
                    return DeviceTypes.NelsonO3000OrbitorBlack;
                default:
                    return DeviceTypes.NelsonO3000OrbitorBlack;
            }
        case idxSprinkler.NelsonO3030OrbitorFX:
            switch(plateExtras.plateType){
                case idxRSNPlates.rsn9GWhite:
                    return DeviceTypes.NelsonO3030OrbitorFXWhite;
                case idxRSNPlates.rsn9GBlack:
                    return DeviceTypes.NelsonO3030OrbitorFXBlack;
                default:
                    if (dropType === DropTypes.None){
                        return DeviceTypes.NelsonO3030OrbitorFXWhite;
                    }else{
                        return DeviceTypes.NelsonO3030OrbitorFXBlack;
                    }                  
            }
        case idxSprinkler.NelsonA3000Accelerator:
            switch(plateExtras.plateType){
                case idxRSNPlates.rsnD6Gold:
                    return DeviceTypes.NelsonA3000AcceleratorGold;
                case idxRSNPlates.rsnU6Navy:
                    return DeviceTypes.NelsonA3000AcceleratorNavy;
                case idxRSNPlates.rsnD4Maroon:
                    return DeviceTypes.NelsonA3000AcceleratorMaroon;
                default:
                    return DeviceTypes.NelsonA3000AcceleratorMaroon;                  
            }
        case idxSprinkler.NelsonD3000Spray:
            if(plateExtras.isFCN3000){
                return DeviceTypes.NelsonD3000FCNSpray;
            }else{
                return DeviceTypes.NelsonD3000Spray;
            }
            /**--------------Senninger---------------- */
        case idxSprinkler.sprSenningerConstantSpacingPlasticImpact:
            return DeviceTypes.Senninger12MediumSpacingPlasticImpact;
        case idxSprinkler.sprSenningerVariableSpacingPlasticImpactWhite:
            return DeviceTypes.Senninger6DoubleWhiteVanePlasticImpact;
        case idxSprinkler.sprSenningerVariableSpacingPlasticImpactRed:
            return DeviceTypes.Senninger6DoubleRedVanePlasticImpact;
        case idxSprinkler.sprSenningerIWob:
            switch(plateExtras.wobbler){
                case idxWobbler.wob9GBlack:
                    return DeviceTypes.SenningerHighAngleIWobBlack;
                case idxWobbler.wob9GBlue:
                    return DeviceTypes.SenningerLowAngleIWobBlue;
                case idxWobbler.wob6GGray:
                    return DeviceTypes.SenningerStandardAngleIWobGray;
                default:
                    return DeviceTypes.SenningerLowAngleIWobWhite;
            }           
        case idxSprinkler.sprSenningerXiwob:
            switch(plateExtras.xiWob){
                case idxXiWob.xiWobBlack:
                    return DeviceTypes.SenningerXiWobBlack;
                case idxXiWob.xiwobWhite:
                    return DeviceTypes.SenningerXiWobWhite;
                case idxXiWob.xiwobBlue:
                    return DeviceTypes.SenningerXiWobBlue;
                default:
                    return DeviceTypes.SenningerXiWobGray;
            }
        case idxSprinkler.sprSenningerSuperSpray:
            return DeviceTypes.SenningerSuperSpray;
        case idxSprinkler.sprSenningerLDNSpray:
            return DeviceTypes.SenningerLDNSpray;
        case idxSprinkler.sprSenningerQuadSpray:
            return DeviceTypes.SenningerQuadSpray;
        case idxSprinkler.sprSenningerXcelWobbler:
            return DeviceTypes.SenningerXcelWobbler;
        /**------------------Komet-------------------------- */
        case idxSprinkler.sprKometTwisterBlack:
            return DeviceTypes.KometTwisterBlack;
        case idxSprinkler.sprKometTwisterBlue:
            return DeviceTypes.KometTwisterBlue;
        case idxSprinkler.sprKometTwisterYellow:
            return DeviceTypes.KometTwisterYellow;
        case idxSprinkler.sprKometTwisterWhite:
            return DeviceTypes.KometTwisterWhite;
        case idxSprinkler.sprKometSprayBlack:
        case idxSprinkler.sprKometSprayBlue:
        case idxSprinkler.sprKometSprayGrey:
        case idxSprinkler.sprKometSprayYellow:
            return DeviceTypes.KometSpray;
        default:
            return DeviceTypes.None;
            //Throw New Exception($"{NameOf(DetermineSprinklerDeviceType)} - Unable to resolve Device Type from: {data.Sprinkler}, {RSNPlate}, {Is3000FCN}");
    }
}
