import * as countries from "i18n-iso-countries";
import IAction from "rdptypes/IAction";
import { default as IDbGrower, default as IGrowerBase, IOldGrower } from "rdptypes/api/IGrowerBase";
import { IProjectInfo } from "rdptypes/api/IListProjectsResponse";
import IProjectData from "rdptypes/api/IProjectData";
import * as React from "react";
import { FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { actionTypeId as UndoActionTypeId } from "../actions/UndoAction";
import { createBlankProject, executeAction } from "../actions/actionExecutorRegistry";
import ApiClientCtx from "../api/ApiClientCtx";
import { ApiRequestStatus } from "../api/ApiRequestState";
import Spinner from "../components/Spinner";
import DealerDataCtx from "../userData/DealerDataCtx";
import DbCtx from "./DbCtx";
import IDbState from "./IDbState";
import ISyncState, { SyncStatus } from "./ISyncState";
import SyncStateCtx from "./SyncStateCtx";


const IndexedDbProvider: FC<PropsWithChildren<{}>> = (props) => {
    const api = useContext(ApiClientCtx);
    const dealer = useContext(DealerDataCtx);

    /**
     * Gets all project IDs currently (projects can move between growers) associated with one grower.
     * @param growerIds
     * @returns 
     */
    const getProjects = (growerId: string) => new Promise<IProjectData[]>((resolve, reject) => {
        const req = idb.current!
            .transaction("projects", "readonly")
            .objectStore("projects")
            .index("idxLatestGrowerId")
            .openCursor(IDBKeyRange.only(growerId));
        const projectIds: IProjectData[] = [];
        req.onsuccess = (ev) => {
            const cursor = req.result;
            if (cursor) {
                const pd: IProjectData = cursor.value;
                projectIds.push(pd);
                cursor.continue();
            }
            else {
                resolve(projectIds);
            }
        }
    });

    const getProjectsForGrowers = async (growerIds: string[]) => {
        const ret: IProjectData[] = [];
        for (const growerId of growerIds) {
            const projects = await getProjects(growerId);
            for (const pd of projects) {
                ret.push(pd);
            }
        }
        return ret;
    }

    const getGrowers = async () => {
        const growers = new Map((await getGrowersOwned()).map(x => [x.growerId, x]));
        for (const grower of (await getGrowersShared())) {
            if (!(grower.growerId in growers)) {
                growers.set(grower.growerId, grower);
            }
        }
        return Array.from(growers.values());
    }

    const convertOldGrower = (maybeOldGrower: any): IDbGrower => {
        let newGrower: IDbGrower;

        if ("address" in maybeOldGrower) {
            const oldGrower: IOldGrower = maybeOldGrower;

            newGrower = {
                growerId: oldGrower.growerId,
                owner: oldGrower.owner,
                dealershipNumber: oldGrower.dealershipNumber,
                sharedWithDealership: oldGrower.sharedWithDealership,
                deleted: oldGrower.deleted,
                lastModified: oldGrower.lastModified,

                name: oldGrower.name,
                farmManagerName: oldGrower.farmManagerName,
                legalDescription: oldGrower.legalDescription,
                email: oldGrower.email,
                phone: oldGrower.phone,

                shippingAddress: {
                    line1: oldGrower.address,
                    line2: "",
                    city: oldGrower.city,
                    state: oldGrower.state,
                    zip: oldGrower.zip,
                    country: oldGrower.country,
                },

                mailingAddress: oldGrower.mailingAddress ? {
                    line1: oldGrower.mailingAddress,
                    line2: "",
                    city: oldGrower.mailingCityStateCountryZip,
                    state: "",
                    zip: "",
                    country: "",
                } : undefined,
            };
        } else if ("billingAddress" in maybeOldGrower) {
            maybeOldGrower.mailingAddress = maybeOldGrower.billingAddress;
            delete maybeOldGrower.billingAddress;
            newGrower = maybeOldGrower as IDbGrower;
        } else {
            newGrower = maybeOldGrower as IDbGrower;
        }

        if (newGrower.shippingAddress && !countries.alpha3ToAlpha2(newGrower.shippingAddress.country)) {
            newGrower.shippingAddress.country = "USA";
        }

        if (newGrower.mailingAddress && !countries.alpha3ToAlpha2(newGrower.mailingAddress.country)) {
            newGrower.mailingAddress.country = "USA";
        }

        return newGrower;
    }

    const getGrowersOwned = () => new Promise<IDbGrower[]>((resolve, reject) => {
        const req = idb.current!
            .transaction("growers", "readonly")
            .objectStore("growers")
            .index("idxOwner")
            .openCursor(IDBKeyRange.only(dealer.assumedUser.id));
        const growers: IDbGrower[] = [];
        req.onsuccess = (ev) => {
            const cursor = req.result;
            if (cursor) {
                const grower: IDbGrower = convertOldGrower(cursor.value);
                growers.push(grower);
                cursor.continue();
            }
            else {
                resolve(growers);
            }
        }
    });


    const getGrowersShared = () => new Promise<IDbGrower[]>((resolve, reject) => {
        const req = idb.current!
            .transaction("growers", "readonly")
            .objectStore("growers")
            .index("idxDealershipPermission")
            .openCursor(IDBKeyRange.bound([dealer.dealership.number, "readonly"], [dealer.dealership.number, "readwrite"]));
        const growers: IDbGrower[] = [];
        req.onsuccess = (ev) => {
            const cursor = req.result;
            if (cursor) {
                const grower: IDbGrower = convertOldGrower(cursor.value);
                growers.push(grower);
                cursor.continue();
            }
            else {
                resolve(growers);
            }
        }
    });

    const setGrowers = (growers: IDbGrower[]) => new Promise<void>((resolve, reject) => {
        const trans = idb.current!
            .transaction(["growers"], "readwrite");

        for (const g of growers) {
            trans
                .objectStore("growers")
                .put(g);
        }
        trans.oncomplete = (ev) => {
            resolve();
        }
    });

    const getProjectActions = (projectId: string) => new Promise<IAction[]>((resolve, reject) => {
        const req = idb.current!
            .transaction("actions", "readonly")
            .objectStore("actions")
            .openCursor(IDBKeyRange.bound([projectId, 0], [projectId, 99999999]));
        const actions: IAction[] = [];
        req.onsuccess = (ev) => {
            const cursor = req.result;
            if (cursor) {
                const action = cursor.value.action as IAction;
                actions.push(action);
                cursor.continue();
            }
            else {
                resolve(actions);
            }
        }
    });

    const replaceProjectActions = (projectData: IProjectData) => new Promise<void>((resolve, reject) => {
        const trans = idb.current!
            .transaction(["actions", "projects"], "readwrite");

        const prj = {
            projectId: projectData.projectId,
            nextSeq: 0,
            latestGrowerId: projectData.latestGrowerId,
        };

        trans
            .objectStore("actions").delete(IDBKeyRange.bound([projectData.projectId, 0], [projectData.projectId, 99999999]))

        for (const action of projectData.actions) {
            trans
                .objectStore("actions")
                .put({
                    projectId: projectData.projectId,
                    seq: prj.nextSeq,
                    action
                });
            prj.nextSeq++;
        }

        trans
            .objectStore("projects")
            .put(prj);

        trans.oncomplete = (ev) => {
            resolve();
        }
    });

    const beginSync = async () => {
        try {
            setSyncState((prevState) => ({
                ...prevState,
                status: SyncStatus.InProgress,
                beginSync: () => { }, // Don't allow sync whilst sync in progress
            }));

            const outgoingGrowers = new Map((await getGrowers()).map(g => [g.growerId, g]));

            const getGrowersResp = await api.getGrowers();
            if (getGrowersResp.status !== ApiRequestStatus.Success) {
                setSyncState((prevState) => ({
                    ...prevState,
                    status: SyncStatus.Offline,
                }));
                return;
            }
            const incomingGrowers = new Map(getGrowersResp.result!.growers.map(g => [g.growerId, g]));

            const incomingChangedGrowers: IGrowerBase[] = [];
            for (const grower of incomingGrowers) {
                if (!outgoingGrowers.has(grower[0]) || grower[1].lastModified > outgoingGrowers.get(grower[0]).lastModified) {
                    incomingChangedGrowers.push(grower[1]);
                }
            }

            const outgoingChangedGrowers: IGrowerBase[] = [];
            for (const grower of outgoingGrowers) {
                if (!incomingGrowers.has(grower[0]) || grower[1].lastModified > incomingGrowers.get(grower[0]).lastModified) {
                    outgoingChangedGrowers.push(grower[1]);
                }
            }

            if (incomingChangedGrowers) {
                await setGrowers(incomingChangedGrowers as any);
            }

            const putGrowersResp = await api.putGrowers({
                growers: outgoingChangedGrowers
            });
            if (putGrowersResp.status !== ApiRequestStatus.Success) {
                setSyncState((prevState) => ({
                    ...prevState,
                    status: SyncStatus.Offline,
                }));
                return;
            }

            const localOnlyProjects = new Map((await getProjectsForGrowers(Array.from(outgoingGrowers.keys())))
                .map(pd => [pd.projectId, pd]));
            const listProjectsResponse = await api.listProjects();
            if (listProjectsResponse.status !== ApiRequestStatus.Success) {
                setSyncState((prevState) => ({
                    ...prevState,
                    status: SyncStatus.Offline,
                }));
                return;
            }

            const syncJobs: ISyncJob[] = [];

            for (const pi of listProjectsResponse.result.projects) {
                const localGrowerId = localOnlyProjects.get(pi.id)?.latestGrowerId ?? pi.latestGrowerId;
                localOnlyProjects.delete(pi.id);
                const syncJob = await getProjectSyncJob({ ...pi, latestGrowerId: localGrowerId });
                if (syncJob) {
                    syncJobs.push(syncJob);
                }
            }

            for (const pd of localOnlyProjects.values()) {
                const syncJob = await getProjectSyncJob({ id: pd.projectId, latestGrowerId: pd.latestGrowerId, lastActionId: undefined });
                if (syncJob) {
                    syncJobs.push(syncJob);
                }
            }

            let ncomplete = 0;

            await Promise.all(syncJobs.map(async sj => {
                await syncProject(sj);
                ncomplete++;
                setSyncState((prevState) => ({
                    ...prevState,
                    progressPercent: 100 * ncomplete / syncJobs.length,
                }));
            }));

            if (syncJobs.some(x => x.mode === "pull")) {
                // Only need to update DB state if we have pulled some changes
                // TODO in the future only update DB state if the active project has changed!
                await updateDbState();
            }

            setSyncState((prevState) => ({
                ...prevState,
                status: SyncStatus.Success,
                lastSyncSuccess: Date.now(),
            }));
        } catch (ex) {
            setSyncState((prevState) => ({
                ...prevState,
                status: SyncStatus.Error,
            }));
        } finally {
            setSyncState((prevState) => ({
                ...prevState,
                beginSync,
                firstSync: false,
                progressPercent: undefined,
            }));
            if (refSyncTimeout.current) {
                clearTimeout(refSyncTimeout.current);
            }
            refSyncTimeout.current = setTimeout(beginSync, 60000);
        }
    }

    interface ISyncJob {
        pi: IProjectInfo;
        mode: "push" | "pull";
        actions: IAction[];
    }

    const getProjectSyncJob = async (pi: IProjectInfo): Promise<ISyncJob | undefined> => {
        const actions = await getProjectActions(pi.id);

        if (!pi.lastActionId) {
            if (actions.length) {
                // No actions on remote so always push
                return {
                    pi,
                    mode: "push",
                    actions
                };
            } else {
                // Empty local project, don't push
                return undefined;
            }
        } else if (!actions.length) {
            // No local actions so always pull
            return {
                pi,
                mode: "pull",
                actions
            };
        } else {
            const localLastActionId = actions[actions.length - 1].id;

            if (localLastActionId === pi.lastActionId) {
                // Nothing to sync
                return undefined;
            } else if (actions.some(x => x.id === pi.lastActionId)) {
                // Local actions need to be pushed
                return {
                    pi,
                    mode: "push",
                    actions
                };
            } else {
                // Remote actions need to be pulled
                // TODO check for conflict
                return {
                    pi,
                    mode: "pull",
                    actions
                };
            }
        }
    }

    const syncProject = async (sj: ISyncJob) => {
        if (sj.mode === "push") {
            const resp = await api.syncPutProject({
                projectId: sj.pi.id,
                actions: sj.actions,
                latestGrowerId: sj.pi.latestGrowerId
            });
            if (resp.status !== ApiRequestStatus.Success) {
                // TODO error
                return;
            }
        } else if (sj.mode === "pull") {
            const resp = await api.syncGetProject(sj.pi.id);
            if (resp.status !== ApiRequestStatus.Success) {
                // TODO error
                return;
            }
            await replaceProjectActions(resp.result!);
        }
    }

    const [dbState, setDbState] = useState<IDbState | undefined>(undefined);
    const [syncState, setSyncState] = useState<ISyncState>({
        status: SyncStatus.NotStarted,
        beginSync: beginSync,
        firstSync: true
    });
    const refSyncTimeout = React.useRef<NodeJS.Timeout>(undefined)
    const idb = React.useRef<IDBDatabase | undefined>(undefined);

    useEffect(() => {
        const req = indexedDB.open("rdp3", 4);
        req.onupgradeneeded = (ev) => {
            const db = req.result;

            let projectsStore: IDBObjectStore;
            if (!db.objectStoreNames.contains("projects")) {
                projectsStore = db.createObjectStore("projects", { keyPath: "projectId" });
            } else {
                projectsStore = req.transaction.objectStore("projects");
            }
            if (!projectsStore.indexNames.contains("idxLatestGrowerId")) {
                projectsStore.createIndex("idxLatestGrowerId", "latestGrowerId", { unique: false });
            }

            if (!db.objectStoreNames.contains("actions")) {
                db.createObjectStore("actions", { keyPath: ["projectId", "seq"] });
            }

            let growersStore: IDBObjectStore;
            if (!db.objectStoreNames.contains("growers")) {
                growersStore = db.createObjectStore("growers", { keyPath: "growerId" });
            } else {
                growersStore = req.transaction.objectStore("growers");
            }
            if (!growersStore.indexNames.contains("idxOwner")) {
                growersStore.createIndex("idxOwner", "owner", { unique: false });
            }
            if (!growersStore.indexNames.contains("idxDealershipPermission")) {
                growersStore.createIndex("idxDealershipPermission", ["dealershipNumber", "sharedWithDealership"], { unique: false });
            }
        };
        req.onerror = (ev) => {
            alert(req.error);
        }
        req.onsuccess = async (ev) => {
            idb.current = req.result;
            await updateDbState();

            await beginSync();
        }
    }, []);

    useEffect(() => {
        if (!idb.current) return;
        setDbState(undefined); // Display the Loading IndexedDB modal until we're ready for user interaction
        updateDbState();
    }, [dealer]);

    const updateGrower = async (grower: IDbGrower, growerId: string) => {
        const trans = idb.current!
            .transaction(["growers"], "readwrite");

        const update = async () => {
            await updateDbState();
        }

        trans.oncomplete = (ev) => {
            update();
        }

        trans
            .objectStore("growers")
            .put({
                ...grower,
                growerId,
            });

        return growerId;
    }

    const deleteGrower = async (grower: IDbGrower, growerId: string, willBeDeleted: boolean) => {
        if (grower.owner !== dealer.assumedUser.id && grower.sharedWithDealership === "readonly") {
            if (willBeDeleted){
                throw new Error("Cannot delete read-only grower")
            }
            else {
                throw new Error("Cannot un-delete read-only grower")
            }
        }

        const trans = idb.current!
            .transaction(["growers"], "readwrite");

        const update = async () => {
            await updateDbState();
        }

        trans.oncomplete = (ev) => {
            update();
        }

        trans
            .objectStore("growers")
            .put({
                ...grower,
                growerId,
                deleted: willBeDeleted,
            });

        return growerId;
    }

    const newGrower = async (grower: IDbGrower) => {
        const growerId = uuidv4();

        const trans = idb.current!
            .transaction(["growers"], "readwrite");

        const update = async () => {
            await updateDbState();
        }

        trans.oncomplete = (ev) => {
            update();
        }

        trans
            .objectStore("growers")
            .put({
                ...grower,
                growerId,
            });

        return growerId;
    }

    const newProject = (initialActions: IAction[]) => {
        // Execute the project actions only to find the initial grower ID
        const prj = applyProjectActions(initialActions);

        const projectId = uuidv4();

        const trans = idb.current!
            .transaction(["projects", "actions"], "readwrite");

        const update = async () => {
            await updateDbState();
        }

        trans.oncomplete = (ev) => {
            update();
        }

        trans
            .objectStore("projects")
            .put({
                projectId,
                nextSeq: initialActions.length,
                latestGrowerId: prj.growerId
            });

        for (let seq = 0; seq < initialActions.length; seq++) {
            trans
                .objectStore("actions")
                .put({
                    projectId,
                    seq,
                    action: {
                        ...initialActions[seq],
                        undoMode: "block", // Don't allow undoing new project actions
                    } as IAction
                });
        }

        return projectId;
    }

    const pushAction = async (projectId: string, action: IAction, dbState: IDbState) => {
        console.log(`pushAction(${projectId}, ${JSON.stringify(action)})`);

        if (dbState.projects[projectId].readonly) {
            console.log("Cannot push action to read-only grower");
            return;
        }

        // Apply the action to the system
        const prj = dbState.projects[projectId].state;
        executeAction(action, prj);

        await new Promise<void>((resolve, reject) => {
            const trans = idb.current!
                .transaction(["actions", "projects"], "readwrite");
            const req = trans
                .objectStore("projects")
                .get(projectId)
            req.onsuccess = (ev) => {
                trans
                    .objectStore("actions")
                    .put({
                        projectId,
                        seq: req.result.nextSeq,
                        action
                    });

                req.result.nextSeq++;
                req.result.latestGrowerId = prj.growerId; // This has to come after we call executeAction
                trans
                    .objectStore("projects")
                    .put(req.result);
            };

            trans.oncomplete = (ev) => {
                resolve();
            }
        });

        if (action.actionTypeId === UndoActionTypeId) {
            // For undo we need to reapply all the project actions in sequence
            const actions = await getProjectActions(projectId);
            dbState.projects[projectId].state = applyProjectActions(actions);
        } else {
            // For all other action types we simply call setState with the updated dbState
        }

        setDbState({ ...dbState });
        return dbState.projects[projectId].state;
    }

    const applyProjectActions = (actions: IAction[]) => {
        const prj = createBlankProject();

        // Apply undos
        actions = [...actions];
        for (let i = 1; i < actions.length; i++) {
            const action = actions[i];
            if (action.actionTypeId === UndoActionTypeId) {
                if (actions[i - 1].undoMode !== "block") {
                    i--;
                    let nToRemove = 2;

                    while (i != 0 && actions[i].undoMode === "include") {
                        i--;
                        nToRemove++;
                    }

                    actions.splice(i, nToRemove);
                    i -= 1; // i is about to be incremented again
                }
            }
        }

        for (const action of actions) {
            executeAction(action, prj);
        }
        return prj;
    }

    const updateDbState = async () => {
        const newDbState: IDbState = {
            projects: {},
            newProject,
            growers: {},
            newGrower,
            updateGrower,
            deleteGrower
        };

        if (dealer) {
            // If we are not logged in yet, and so have no dealer context, we can't populate projects or growers.

            const growers = await getGrowers();
            for (const grower of growers) {
                // Add deleted growers also. Filter these out in the UI.
                newDbState.growers[grower.growerId] = grower;
            }

            const projects = await getProjectsForGrowers(growers.map(g => g.growerId));
            for (const project of projects) {
                const pid = project.projectId;
                const actions = await getProjectActions(pid);
                const prj = applyProjectActions(actions);
                const grower = newDbState.growers[prj.growerId];
                newDbState.projects[pid] = {
                    state: prj,
                    pushAction: (action) => pushAction(pid, action, newDbState),
                    readonly: grower?.owner !== dealer.assumedUser.id && grower?.sharedWithDealership === "readonly"
                };
            }
        }

        console.log("setDbState from updateDbState");
        setDbState(newDbState);
    };

    if (!dbState) {
        return (
            <Spinner title="Loading..." />
        );
    } else {
        return (
            <DbCtx.Provider value={dbState}>
                <SyncStateCtx.Provider value={syncState}>
                    {props.children}
                </SyncStateCtx.Provider>
            </DbCtx.Provider>
        );
    }
};

export default IndexedDbProvider;