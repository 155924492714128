import { Text, View } from "@react-pdf/renderer";
import { t } from "i18next";
import { ElectricalFrequencies } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { getRemotePanelVoltageLoss, getSystemDragCordVoltageLoss, getSystemElectricalFrequencyHertz, getSystemFullLoadAmps, getSystemMinimumVoltage, getSystemRequiredGeneratorHP, getSystemRequiredkW, getSystemVoltageatMCP } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import { documentStyles } from "../styles";
import FixedWidthTable, { IRow } from "./table/FixedWidthTable";

export interface Props {
    quote: QuoteClass;
}

const ElectricalSystemInformationTable: FC<Props> = (props) => {
    let {quote} = props;
    
    const ns = "common-phrases."
    let rows: IRow[] = [{
        cells: [{
            text: t(ns + "sltFullLoadAmps") + ":",
            widthPc: 35,
            header: true
        }, {
            text: getSystemFullLoadAmps(quote).toFixed(1),
            widthPc: 12
        }]
    },
    {
        cells: [{
            text: t(ns + "sltRequiredKWs") + ":",
            widthPc: 35,
            header: true
        }, {
            text: getSystemRequiredkW(quote).toFixed(1),
            widthPc: 12
        }]
    },
    {
        cells: [{
            text: t(ns + "sltRequiredGeneratorHP") + ":",
            widthPc: 35,
            header: true
        }, {
            text: getSystemRequiredGeneratorHP(quote).toFixed(1),
            widthPc: 12
        }]
    },
    {
        cells: [{
            text: t(ns + "sltSourceVoltage") + ":",
            widthPc: 35,
            header: true
        }, {
            text: typeof(getSystemVoltageatMCP(quote)) !== "undefined" ? getSystemVoltageatMCP(quote).toFixed(1) : "",
            widthPc: 12
        }]
    }];

    let remotePanelVoltageLoss = getRemotePanelVoltageLoss(quote);

    if (remotePanelVoltageLoss > 0){
        rows.push({cells: [{
            text: t(ns + "sltRemotePanelVoltageLoss") + ":",
            widthPc: 35,
            header: true
        }, {
            text: remotePanelVoltageLoss.toFixed(1),
            widthPc: 12
        }]})
    }

    let dragCordVoltageLoss = getSystemDragCordVoltageLoss(quote);

    if (remotePanelVoltageLoss > 0){
        rows.push({cells: [{
            text: t(ns + "sltDragCordVoltageLoss") + ":",
            widthPc: 35,
            header: true
        }, {
            text: dragCordVoltageLoss.toFixed(1),
            widthPc: 12
        }]})
    }


    rows.push({
        cells: [{
            text: t(ns + "sltMinimumVoltage") + ":",
            widthPc: 35,
            header: true
        }, {
            text: typeof(getSystemMinimumVoltage(quote)) !== "undefined" ? getSystemMinimumVoltage(quote).toFixed(1) : "",
            widthPc: 12
        }]
    },
    {
        cells: [{
            text: t(ns + "sltElectricalFrequency") + ":",
            widthPc: 35,
            header: true
        }, {
            text: getSystemElectricalFrequencyHertz(quote) === ElectricalFrequencies.a50 ?  "50 Hz" : "60 Hz",
            widthPc: 12
        }]
    });

    return (<View style={documentStyles.section}>
            <View style={documentStyles.heading}>
                <Text style={documentStyles.sectionHeader}>{t(ns + "sltSystemInformation")}</Text>
            </View>
            <View style={{paddingLeft: 15, paddingRight: 15}}>
                <FixedWidthTable data={{rows}}/>
            </View>
        </View>
    );
};

export default ElectricalSystemInformationTable;

