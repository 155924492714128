import { IDrawUpdateExtEvent_CenterPivotSelected, IDrawUpdateExtEvent_LateralSelected } from "../../../../components/Map";
import { ISelectedItem } from "../selectedItem";

export const handleSystemSelected = (
    event: IDrawUpdateExtEvent_CenterPivotSelected | IDrawUpdateExtEvent_LateralSelected,
    setSelectedItem: (item: ISelectedItem) => void,
) => {
    if (event.definition.systemId) {
        setSelectedItem({ item: 'system', id: event.definition.systemId });
    }
}