import { TextField } from "@mui/material";
import IText from "rdptypes/roe/IText";
import * as React from "react";
import { FC, useContext } from "react";
import { createNewUpdateSystemPropertyAction } from "../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../auth/AuthCtx";
import IDbProject from "../../../db/IDbProject";
import { getValue } from "../../../helpers/objectPathResolver";
import { translate } from "../../../helpers/translation";
import { pageStyles, secondaryColor } from "../styles";
import IComponentRenderContext from "./IComponentRenderContext";

interface Props {
    cmp: IText,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    parentGroupLabel: string | null;
    ctx: IComponentRenderContext;
    small?: boolean;
};

const TextRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const [value, setValue] = React.useState(getValue(system, props.cmp.fieldPath));
    
    const fieldPath = props.ctx.fieldRoot + props.cmp.fieldPath;
    const fvr = props.ctx.vws.validationResult?.getField(fieldPath);
    const errorSeverity = fvr?.severity;
    
    //ensure cmp re-renders with updated fieldPath
    React.useEffect(()=> {
        setValue(getValue(system, props.cmp.fieldPath));
    }, [props.cmp.fieldPath]);

    let showLabel = props.parentGroupLabel !== translate(props.cmp);
    let textInputStyle = {};
    let inputStyle={};
    
    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    if (props.parentGroupLabel === null)
    {
        formInputLabelStyle["marginLeft"] = props.small ? "4x" : "10px";
        formInputLabelStyle["marginRight"] = props.small ? "4px" : "10px";
        textInputStyle["paddingRight"] = props.small ? "4px": "20px";
        inputStyle["marginLeft"] = props.small ? "4px" : "10px";
    }
    else if (props.parentGroupLabel !== translate(props.cmp))
    {
        formInputLabelStyle["color"] = secondaryColor;
    }

    return (
        <>
            <TextField
                error={errorSeverity === "error" || errorSeverity === "notset"}
                size="small"
                InputLabelProps={{sx:formInputLabelStyle, shrink: true}}
                InputProps={{sx: inputStyle}}
                style={textInputStyle}
                variant="standard"
                fullWidth
                label={showLabel ? translate(props.cmp) : null}                
                value={value}
                onChange={(ev) => {
                    setValue(ev.target.value);
                }}
                onBlur={(ev) => props.ctx.pushActionAndImproveScores(createNewUpdateSystemPropertyAction(
                    props.layoutId,
                    props.systemId,
                    props.cmp.fieldPath,
                    value,
                    authState
                ), [], false)}
                multiline={props.cmp.multiline}       
                inputProps={{
                    style: {fontSize: props.small ? "12px" : "1rem"}
                }}        
            />
        </>
    );
};

export default TextRenderer;