import * as turf from "@turf/turf";
import { Feature, LineString, MultiPolygon, Polygon, Position, along, area, buffer, convertArea, coordAll, destination, difference, feature, intersect, length, lineSliceAlong, lineString, multiLineString, multiPolygon, point, polygon, union } from "@turf/turf";
import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { getSpansWithoutEndOfSystem, getSpansWithoutSAC } from "rdptypes/helpers/Spans";
import { EndGunTypes, HoseFeedTypes, SystemTypes, WaterFeedTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import { IsPivotingLateral } from "roedata/roe_migration/SystemFunctions";
import { getAftSide, getFwdSide } from "..";
import { customDifference, customUnion, lineSliceAlongCustom, loCustom, ptldCustom } from "../..";
import { EDisplayCoordinate } from "../../../components/DealerSettingsDialog/EDisplayCoordinate";
import { formatLatLn } from "../../../docGeneration/DocumentGenerationHelpers";
import { ImportExportFeature, ImportExportFeatureProperties } from "../../../helpers/importExport";
import { EndGunLocation, getEndGunThrow } from "../../../model/project/IEndGun";
import ILayout from "../../../model/project/ILayout";
import IProject from "../../../model/project/IProject";
import ISystem from "../../../model/project/ISystem";
import calculateEngGunOnOffs from "../../../optimization/centerPivot/endgun";
import { BoundaryHelper } from "../../BoundaryHelper";
import FeatureHelpers from "../../FeatureHelpers";
import { IElevationPointFeature } from "../../GeometryProvider/IElevationPointFeature";
import { mapElevationToFeature } from "../../GeometryProvider/mapElevationToFeature";
import { ObstacleHelper } from "../../ObstacleHelper";
import CenterPivotGeometryHelper from "../CenterPivotGeometryHelper";
import { IBufferedSystemPolygonsForSystemClearancePolygons, IGetSystemValidityArgs, ILayoutClearancePolygons, getLayoutClearancePolygons, getSystemClearancePolygons } from "../interfaces";
import { ISpanVertex } from "./systemCoordinates";
import mapboxgl = require("mapbox-gl");
interface IDropSpanProperties {
    spanTowerIndex: number;
    fullLine: LineString;
}
export interface IProperties {
    isLateral: true;
    systemId: string;
    layoutId: string;
    activeSystem: boolean;
}
interface ILateralGeometryHelperArgs {
    layoutId: string;
    systemId: string;
    project: IProject;
}
interface ILateralGeometryHelperOptions {
    isActive?: boolean;
}

type PivotDirection = "clockwise" | "anticlockwise";
export interface ILineSegment { 
    type: "line", 
    p1: Position, 
    p2: Position, 
    p1Direction: PivotDirection, 
    p2Direction: PivotDirection, 
    pivotStart?: boolean, 
    pivotEnd?: boolean
    inside: boolean;
    retracing?: boolean;
};
interface IPivotSegment { 
    type: PivotDirection, 
    center: Position, 
    b1: number, 
    b2: number,
    pivotStart?: boolean, 
    pivotEnd?: boolean,
    retracing?: boolean
};
export type IlateralSegment = ILineSegment | IPivotSegment;
const getSegmentLine_Line = (segment: ILineSegment, radius: number, options?: { trimStart?: number, trimEnd?: number }): Position[] => {
    const multiplier = segment.inside ? -1 : 1;
    const trimStart = options?.trimStart || 0;
    const trimEnd = options?.trimEnd || 0;
    const line = radius === 0
        ? lineString([ segment.p1, segment.p2 ])
        : loCustom(lineString([ segment.p1, segment.p2 ]), radius * multiplier, { units: 'feet' });
    if (trimStart === 0 && trimEnd === 0) {
        return line.geometry.coordinates;
    }
    const len = length(line, { units: 'feet' });
    return lineSliceAlong(
        line,
        trimStart,
        len - trimEnd,
        { units: 'feet' }
    ).geometry.coordinates;
}
const getSegmentLine_Pivot = (segment: IPivotSegment, radius: number, options?: { trimStart?: number, trimEnd?: number }): Position[] => {
    if (radius === 0) {
        return [ segment.center ];
    }
    const trimStart = options?.trimStart || 0;
    const trimEnd = options?.trimEnd || 0;
    const arc = segment.type === 'clockwise'
        ? FeatureHelpers.GetLineArcDrawFeature(
            point(segment.center).geometry,
            radius,
            segment.b1,
            segment.b2,
            null,
            { units: 'feet', degreeIncrement: 0.1, direction: 'clockwise' }
        )
        : FeatureHelpers.GetLineArcDrawFeature(
            point(segment.center).geometry,
            radius,
            segment.b1,
            segment.b2,
            null,
            { units: 'feet', degreeIncrement: 0.1, direction: 'anticlockwise' }
        );

    if (trimStart === 0 && trimEnd === 0) {
        return arc.geometry.coordinates;
    }
    const len = length(arc, { units: 'feet' });
    const trimmed = lineSliceAlong(
        arc,
        trimStart,
        len - trimEnd,
        { units: 'feet' }
    );
    return trimmed.geometry.coordinates;
}
export const getSegmentLinePositions = (segment: IlateralSegment, radius: number, options?: { trimStart?: number, trimEnd?: number }): Position[] => {
    if (segment.type === 'line') {
        return getSegmentLine_Line(segment, radius, options);
    }
    else {
        return getSegmentLine_Pivot(segment, radius, options);
    }
}

const getSegmentLength = (segment: (ILineSegment | IPivotSegment), radius: number): number => {
    const line: Feature<LineString> = lineString(getSegmentLinePositions(segment, radius));
    return length(line, { units: 'feet' });
}
export interface ILateralSpanTowerV2 {
    lengthFeet: number;
    segments: IlateralSegment[];
    leftLostSegments: IlateralSegment[];
    rightLostSegments: IlateralSegment[];
    insideRadius: number;
    outsideRadius: number;
    isDropSpan: boolean;
    spanTowerIndex: number;
    isEndBoom?: boolean;
}

interface ILateralEndGunPartV2 {
    segments: IlateralSegment[];
    insideRadius: number;
    outsideRadius: number;
    isPrimary: boolean;
}

export interface ILateralEndGunInformation { 
    endGun: EndGunTypes,
    isPrimary: boolean,
    onOffs: { on: number, off: number }[] ,
    throwFeet: number;
}
export interface EndGunDescription{
    features: {feature: Feature<Polygon>, isPrimary: boolean}[];
    endGunInformation: ILateralEndGunInformation[]
}

export default class LateralGeometryHelper {

    private project: IProject;
    private layout: ILayout;
    private layoutId: string;
    private system: ISystem;
    private systemId: string;

    private isActive = false;
    
    private _flexSideDirectionalSpanTowersFromFeedLine: ILateralSpanTowerV2[] | undefined = undefined;
    private _rigidSideDirectionalSpanTowersFromFeedLine: ILateralSpanTowerV2[] | undefined = undefined;
    private _feedLineSegments: ILineSegment[] | undefined = undefined;
    private _flexSideDirectionalEndGunParts: ILateralEndGunPartV2[] | undefined = undefined;
    private _rigidSideDirectionalEndGunParts: ILateralEndGunPartV2[] | undefined = undefined;
    
    constructor(args: ILateralGeometryHelperArgs, options?: ILateralGeometryHelperOptions) {

        const { layoutId, systemId, project } = args;

        this.project = project;
        if (!this.project) {
            throw new Error("No project associated with this system");
        }
        
        this.layoutId = layoutId;
        this.layout = this.project.layouts[layoutId];
        if (!this.layout) {
            throw new Error("No layout associated with this system");
        }

        this.system = this.layout.systems[systemId];
        this.systemId = systemId;

        if (!this.system || !this.system.lateral || (this.system.SystemProperties.SystemType !== SystemTypes.HoseFeedMaxigator
            && this.system.SystemProperties.SystemType !== SystemTypes.CanalFeedMaxigator)) {
            throw new Error("This system is not a lateral");
        }
        
        if (options) {
            if (options.isActive !== undefined) this.isActive = options.isActive;
        }
    }

    // private accessors:
    private get aftSideSpanCount(): number {
        // includes end boom if defined
        return getSpansWithoutSAC(this.system, getAftSide(this.system)).length;
    }
    public get isCanalFeed(): boolean {
        return this.system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator;
    }

    public get isHoseFeed(): boolean {
        return this.system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator;
    }

    private get lateralCenterLine() {
        return this.system.lateral!.line;
    }
    private get canalCenterLine() {
        return this.lateralCenterLine;
    }
    public get canalWidthFeet(): number {
        return this.isCanalFeed ? this.system.lateral!.canalWidthFeet! : 0;
    }
    public get distanceFromCanalCenterToFwdSide(): number {
        return this.isCanalFeed ? this.system.lateral!.distanceFromCanalCenterToFwdSide! : 0;
    }
    public get distanceFromCanalCenterToAftSide(): number {
        return this.isCanalFeed ? this.system.lateral!.distanceFromCanalCenterToAftSide! : 0;
    }
    public get hasFlexSide() {
        return getSpansWithoutEndOfSystem(this.system, SideEnum.Flex).length !== 0;
    }

    public getPowerTowerCartType() {
        //TODO: check this
        //In RDP 2 we return 4 wheel/2 wheel depending on the power tower type
        if (this.isHoseFeed && this.system.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.FourWheelDrive){
            return "4 Wheel";
        }

        return "2 Wheel";
    }

    public getPowerTrackStartStop() {
        let lineStr = this.system.lateral.line;
        let coords: string[] = [];

        lineStr.coordinates.forEach((coord) => {
            //in Position, lng is first
            let parts = formatLatLn(coord[1], coord[0], EDisplayCoordinate.DMS);
            coords.push(parts.latPart + " / " + parts.lonPart);
        });

        return coords;
    }

    public get cartPathLength() {
        const feedLine = turf.feature(this.system.lateral.line);
        return turf.length(feedLine, { units: 'feet'});
    }

    private feedCenterLine(): LineString {
        if (!this.isCanalFeed) {
            return this.lateralCenterLine;
        }
        else {
            return lateralLineOffset(
                this.canalCenterLine, 
                this.feedCenterLineOffset(), 
                { units: 'feet', arc: 'natural' }
            ).geometry;
        }
    }
    private feedCenterLineOffset() {
        if (!this.isCanalFeed) {
            return 0;
        }
        else {
            return this.isRigidSideForward() ? this.distanceFromCanalCenterToFwdSide : -this.distanceFromCanalCenterToAftSide;
        }
    }
    private get hasFlexDrop(): boolean {
        return (!this.isCanalFeed && this.system.Lateral.WaterFeed === WaterFeedTypes.CenterFeed && this.system.Lateral.DropFlexSide);
    }
    private isFlexDropValid(): boolean {
        const start = this.system.Lateral.dropSpanStartRelativeToPreviousSpanStart || 0;
        const end = this.system.Lateral.dropSpanEndRelativeToPreviousSpanEnd || 0;
        if (start === 0 && end === 0) {
            return true;
        }
        const l = length(feature(this.feedCenterLine()), { units: 'feet' });
        if (l - end - start < 1) {
            // invalid flex drop:
            return false;
        }
        return true;
    }
    private flexSideCenterLine(excludeFlexDrop = false) {
        if (!this.isCanalFeed) {
            if (!excludeFlexDrop && this.hasFlexDrop) {
                const start = this.system.Lateral.dropSpanStartRelativeToPreviousSpanStart || 0;
                const end = this.system.Lateral.dropSpanEndRelativeToPreviousSpanEnd || 0;
                if (start === 0 && end === 0) {
                    return this.feedCenterLine();
                }
                const l = length(feature(this.feedCenterLine()), { units: 'feet' });
                return lineSliceAlongCustom(
                    this.feedCenterLine(),
                    l - end, // feed line is in reverse
                    start,
                    { units: "feet" }
                ).geometry
            }
            return this.feedCenterLine();
        }
        else {
            return lateralLineOffset(
                this.canalCenterLine, 
                (this.isRigidSideForward() ? -this.distanceFromCanalCenterToAftSide : this.distanceFromCanalCenterToFwdSide), 
                { units: 'feet', arc: 'natural' }
            ).geometry;
        }
    }
    public feedLineOrPolygon(): LineString | Polygon {
        if (!this.isCanalFeed) {
            return this.feedCenterLine();
        }
        
        const feed = this.feedCenterLine();
        return polygon([
            [
                ...feed.coordinates,
                ...this.flexSideCenterLine().coordinates.reverse(),
                feed.coordinates[0]
            ]
        ]).geometry;
    }
    public isRigidSideForward() {
        return this.system.flexIsFwd !== true;
    }

    public calculateElevationProfile(map: mapboxgl.Map) {
        const FEED_RESOLUTION = 10;
        const getSpanTowerAtRadius = (spanTowers:  ILateralSpanTowerV2[], radius: number) => {
            for (const spanTower of spanTowers) {
                if (spanTower.insideRadius <= spanTower.outsideRadius) {
                    if (spanTower.insideRadius <= radius && spanTower.outsideRadius >= radius) {
                        return spanTower;
                    }
                }
                else {
                    if (spanTower.insideRadius >= radius && spanTower.outsideRadius <= radius) {
                        return spanTower;
                    }
                }
            }
            return null;
        }
        const getSpanTowerLinePosition = (spanTower:  ILateralSpanTowerV2, feedStart: number, radius: number) => {
            if (!spanTower) return null;
            let rStart = 0;
            for (const s of spanTower.leftLostSegments) {
                rStart += getSegmentLength(s, 0);
                if (rStart > feedStart) return null;
            }
            let rEnd = rStart;
            for (let i = 0; i < spanTower.segments.length; i++) {
                const s = spanTower.segments[i];
                if (s.type !== "line") {
                    // we are only considering line sections here,
                    // it is assumed radius sections do not add any length to the line position
                    continue;
                }
                rStart = rEnd;
                rEnd += getSegmentLength(s, 0);
                if (rEnd < feedStart) continue;
                const trimStart = feedStart - rStart;
                if (trimStart === 0) {
                    return getSegmentLinePositions(s, radius)[0];
                }
                if (rEnd === feedStart) {
                    return getSegmentLinePositions(s, radius).slice(-1)[0];
                }
                const a = getSegmentLinePositions(s, radius, { trimStart: feedStart - rStart });
                return a[0];
            }
            return null;
        }
        const getSpanTowerPivotPosition = (spanTower:  ILateralSpanTowerV2, feedStart: number, radius: number, center: Position) => {
            if (!spanTower) return null;
            for (let i = 0; i < spanTower.segments.length; i++) {
                const s = spanTower.segments[i];
                if (s.type === "line") {
                    // we are only considering pivot sections here
                    continue;
                }
                if (s.center[0] === center[0] && s.center[1] === center[1]) {
                    return getSegmentLinePositions(s, radius);
                }
            }
            return null;
        }
        const getMaxElevationFeatureFromPositions = (map: mapboxgl.Map, positions: Position[]): IElevationPointFeature | null => {
            const elevationPositions = positions
                .map(x => mapElevationToFeature(map, x))
                .filter(x => x && x.properties.elevationMeters !== null);
            if (elevationPositions.length) {
                const best = elevationPositions.reduce(
                    (prev, crnt) => {
                        if (crnt.properties.elevationMeters > prev.properties.elevationMeters) {
                            return crnt;
                        }
                        return prev;
                    },
                    elevationPositions[0]
                )
                return best;
            }
            return null;
        }
        
        interface IData {
            offset: number;
            feedLineElevationFeature: IElevationPointFeature;
            flangedSide: {
                radius: number;
                feedLineElevationFeature: IElevationPointFeature;
            }[];
            flexSide: {
                radius: number;
                feedLineElevationFeature: IElevationPointFeature;
            }[];
        }
        const data: IData[] = [];
        let runningOffset = 0;
        const generateRadiusOffsets = (maxRadius: number) => {
            const radiusOffsets: number[] = [];
            if (maxRadius > 0) {
                for (let i = 0; i < maxRadius; i += FEED_RESOLUTION) {
                    radiusOffsets.push(i)
                }
                radiusOffsets.push(maxRadius);
            }
            else if (maxRadius < 0) {
                for (let i = 0; i > maxRadius; i -= FEED_RESOLUTION) {
                    radiusOffsets.push(i)
                }
                radiusOffsets.push(maxRadius);
            }
            return radiusOffsets;
        }
        const flangedSideRadii: number[] = [];
        if (this.directionalRigidSideSpanTowers.length) {
            const max = this.directionalRigidSideSpanTowers.slice(-1)[0].outsideRadius;
            flangedSideRadii.push(...generateRadiusOffsets(max));
        }
        const flexSideRadii: number[] = [];
        if (this.directionalFlexSideSpanTowers.length) {
            const max = this.directionalFlexSideSpanTowers.slice(-1)[0].outsideRadius;
            flexSideRadii.push(...generateRadiusOffsets(max));
        }

        // we need to use the rigid side segments as the feed line does not include any pivots or retracing
        // There will always be rigid side towers if there are spans.
        if (!this.directionalRigidSideSpanTowers.length) return []
        const insideRigidTower = this.directionalRigidSideSpanTowers[0];

        for (const segment of insideRigidTower.segments) {
            let segmentOffset = 0;
            if (segment.type !== 'line') {
                // then its a radius:
                const d: IData = {
                    offset: runningOffset,
                    flangedSide: [],
                    flexSide: [],
                    feedLineElevationFeature: mapElevationToFeature(map, segment.center),
                }
                for (const radius of flangedSideRadii) {
                    const spanTower = getSpanTowerAtRadius(this.directionalRigidSideSpanTowers, radius);
                    const positions = getSpanTowerPivotPosition(spanTower, runningOffset + segmentOffset, radius, segment.center);
                    const maxElevationFeature = getMaxElevationFeatureFromPositions(map, positions);
                    if (maxElevationFeature) {
                        d.flangedSide.push({
                            radius,
                            feedLineElevationFeature: maxElevationFeature
                        })
                    }
                }
                for (const radius of flexSideRadii) {
                    const spanTower = getSpanTowerAtRadius(this.directionalFlexSideSpanTowers, radius);
                    const positions = getSpanTowerPivotPosition(spanTower, runningOffset + segmentOffset, radius, segment.center);
                    const maxElevationFeature = getMaxElevationFeatureFromPositions(map, positions);
                    if (maxElevationFeature) {
                        d.flexSide.push({
                            radius,
                            feedLineElevationFeature: maxElevationFeature
                        })
                    }
                }
                data.push(d);
            }
            else {
                // then its a line
                const segmentLength = getSegmentLength(segment, 0);
                const segmentOffsets: number[] = [];
                for (let i = 0; i < segmentLength; i += FEED_RESOLUTION) {
                    segmentOffsets.push(i);
                }
                segmentOffsets.push(segmentLength);
                for (const segmentOffset of segmentOffsets) {
                    const segmentSections = getSegmentLine_Line(segment, 0, { trimStart: segmentOffset });
                    const d: IData = {
                        offset: runningOffset + segmentOffset,
                        feedLineElevationFeature: mapElevationToFeature(map, segmentSections[0]),
                        flangedSide: [],
                        flexSide: []
                    }
                    for (const radius of flangedSideRadii) {
                        const spanTower = getSpanTowerAtRadius(this.directionalRigidSideSpanTowers, radius);
                        try {
                            const position = getSpanTowerLinePosition(spanTower, runningOffset + segmentOffset, radius);
                            if (position) {
                                d.flangedSide.push({
                                    radius,
                                    feedLineElevationFeature: mapElevationToFeature(map, position)
                                })
                            }
                        }
                        catch {
                            console.warn(`Flanged elevation position (feed:${segmentOffset}, distance:${radius}) could not be calculated`);
                        }
                    }
                    for (const radius of flexSideRadii) {
                        const spanTower = getSpanTowerAtRadius(this.directionalFlexSideSpanTowers, radius);
                        try {
                            const position = getSpanTowerLinePosition(spanTower, runningOffset + segmentOffset, radius);
                            if (position) {
                                d.flexSide.push({
                                    radius,
                                    feedLineElevationFeature: mapElevationToFeature(map, position)
                                })
                            }
                        }
                        catch (e) {
                            console.warn(`Flex elevation position (feed:${segmentOffset}, distance:${radius}) could not be calculated`);
                        }
                    }
                    data.push(d);
                }
                runningOffset += segmentLength;
            }
        }
        
        return data;
    } 

    
    public get directionalFlexSideSpanTowers(): ILateralSpanTowerV2[] {
        if (this._flexSideDirectionalSpanTowersFromFeedLine === undefined) {
            this._calculateDirectionalSpanTowersV2();
        }
        return this._flexSideDirectionalSpanTowersFromFeedLine!;
    }
    public get directionalRigidSideSpanTowers(): ILateralSpanTowerV2[] {
        if (this._rigidSideDirectionalSpanTowersFromFeedLine === undefined) {
            this._calculateDirectionalSpanTowersV2();
        }
        return this._rigidSideDirectionalSpanTowersFromFeedLine!;
    }
    public get feedLineSegments(): ILineSegment[] {
        if (this._feedLineSegments === undefined) {
            this._calculateDirectionalSpanTowersV2();
        }
        return this._feedLineSegments!;
    }

    private get directionalFlexSideEndGunParts(): ILateralEndGunPartV2[] {
        if (this._flexSideDirectionalEndGunParts === undefined) {
            this._calculateDirectionalSpanTowersV2();
        }
        return this._flexSideDirectionalEndGunParts!;
    }
    private get directionalRigidSideEndGunParts(): ILateralEndGunPartV2[] {
        if (this._rigidSideDirectionalEndGunParts === undefined) {
            this._calculateDirectionalSpanTowersV2();
        }
        return this._rigidSideDirectionalEndGunParts!;
    }

    private _systemValidityArgs: IGetSystemValidityArgs | null = null;
    public getSystemValidityArgs(_layoutClearancePolygons?: ILayoutClearancePolygons, buffedSystemPolygons?: IBufferedSystemPolygonsForSystemClearancePolygons) {
        if (!this._systemValidityArgs) {
            const systemAreaPolygon = this.getAreaPolygon();
            const layoutClearancePolygons = _layoutClearancePolygons ?? getLayoutClearancePolygons(
                this.project,
                this.layoutId
            );
            const systemClearancePolygons = getSystemClearancePolygons(
                this.project,
                this.layoutId,
                this.systemId,
                buffedSystemPolygons
            );
            this._systemValidityArgs = {
                systemClearanceObstacles: layoutClearancePolygons.clearanceObstacles,
                systemClearanceBoundaries: layoutClearancePolygons.clearanceBoundaries,
                systemAreaPolygon,
                systemClearanceWheelObstacles: layoutClearancePolygons.clearanceWheelObstacles,
                systemClearanceSystemObstacles: systemClearancePolygons.clearanceSystemObstacles,
                wheelTracks: this.getWheelTracks(),
                allowOverlap: this.system.overlapping || false,
                feedLine: this.feedLineOrPolygon()
            }
        }
        return this._systemValidityArgs!;
    }
    private get propertiesForAll(): IProperties {        
        return {
            isLateral: true,
            systemId: this.systemId,
            layoutId: this.layoutId,
            activeSystem: this.isActive
        }
    }
    public get selectModeDefinition() {
        return this.propertiesForAll;
    }

    // public accessors:
    // private methods:
    private _getIrrigatedAreaPolygonsForSide(side: "flanged" | "flex"): {
        mainSystem: Feature<Polygon>[];
        drop: Feature<Polygon>[];
    } {
        // includes end boom if defined
        const mainSystem: Feature<Polygon>[] = [];
        const drop: Feature<Polygon>[] = [];
        let hasPassedDropSpan = false;
        let u: Feature<MultiPolygon | Polygon> | null = null;
        const towers = side === "flex" ? this.directionalFlexSideSpanTowers : this.directionalRigidSideSpanTowers;
        for (const spanTower of towers) {
            const result = getLateralLinePolygons(
                spanTower.segments,
                spanTower.insideRadius,
                spanTower.outsideRadius,
                u
            )
            u = result.updatedExclude;

            const rdpFeatureType = hasPassedDropSpan
                ? "system/irrigatedArea-dropSpan"
                : (
                    side === "flex"
                        ? "system/irrigatedArea-flexSide"
                        : "system/irrigatedArea"
                );
            for (const p of result.polygons) {
                const irrigatedArea = polygon(
                    p.coordinates,
                    {
                        ...this.propertiesForAll,
                        rdpFeatureType,
                        activeSystem: this.isActive
                    }
                )
                
                if (hasPassedDropSpan) {
                    drop.push(irrigatedArea);
                }
                else {
                    mainSystem.push(irrigatedArea);
                }
            }

            if (!hasPassedDropSpan) {
                hasPassedDropSpan = spanTower.isDropSpan;
            }
        }
        return { mainSystem, drop };
    }

    private _getFlexSideIrrigatedAreaPolygons(): {
        mainSystem: Feature<Polygon>[];
        drop: Feature<Polygon>[];
    } {
        return this._getIrrigatedAreaPolygonsForSide("flex");
    }
    private _getRigidSideIrrigatedAreaPolygons(): {
        mainSystem: Feature<Polygon>[];
        drop: Feature<Polygon>[];
    } {
        return this._getIrrigatedAreaPolygonsForSide("flanged");
    }

    public get aftSpans() {
        return getSpansWithoutSAC(this.system, getAftSide(this.system));
    }

    public get fwdSpans() {
        return getSpansWithoutSAC(this.system, getFwdSide(this.system));
    }

    private get anticlockwisePivotIndicies() {
        // Note, as the line is reversed to find the line segments,
        // we also need to invert the indicies here
        const anticlockwisePivotLineIndicies = (this.system.lateral.anticlockwisePivotLineIndicies || []).slice();
        for (let i = 0; i < anticlockwisePivotLineIndicies.length; i++) {
            anticlockwisePivotLineIndicies[i] = this.system.lateral.line.coordinates.length - 1 - anticlockwisePivotLineIndicies[i];
        }
        return anticlockwisePivotLineIndicies;
    }

    private _calculateDirectionalSpanTowersV2() {
        type PartialSpanTower = {
            lengthFeet: number;
            isDropSpan: false;
            spanTowerIndex: number;
            isEndBoom?: boolean;
        } | {
            lengthFeet: number;
            dropSpanStartRelativeToPreviousSpanStart: number;
            dropSpanEndRelativeToPreviousSpanEnd: number;
            isDropSpan: true;
            spanTowerIndex: number;
            isEndBoom?: boolean;
        }
        const partialSpanTowers: PartialSpanTower[] = [];

        const aftSpans = getSpansWithoutSAC(this.system, getAftSide(this.system));
        const fwdSpans = getSpansWithoutSAC(this.system, getFwdSide(this.system));
        const allSpans = [
            ...[...aftSpans.map((x, spanTowerIndex) => ({ span: x, spanTowerIndex, sideEnum: getAftSide(this.system) }))].reverse(),
            ...fwdSpans.map((x, spanTowerIndex) => ({ span: x, spanTowerIndex, sideEnum: getFwdSide(this.system) })),
        ]

        const returnIfCannotCalculate = () => {
            this._flexSideDirectionalSpanTowersFromFeedLine = [];
            this._rigidSideDirectionalSpanTowersFromFeedLine = [];
            this._flexSideDirectionalEndGunParts = [];
            this._rigidSideDirectionalEndGunParts = [];
            this._feedLineSegments = [];
        }
        for (let i = 0; i < allSpans.length; i++) {
            const {span, spanTowerIndex, sideEnum } = allSpans[i];
            const multiplier = getAftSide(this.system) === sideEnum ? -1 : 1;
            if (!Number.isFinite(spanf.LengthInFeet(getSide(this.system, sideEnum), span))) {
                console.log("Invalid span length");
                return returnIfCannotCalculate();
            };
            if (span.Disconnecting) {
                partialSpanTowers.push({
                    lengthFeet: multiplier * spanf.LengthInFeet(getSide(this.system, sideEnum), span),
                    dropSpanStartRelativeToPreviousSpanStart: span.dropSpanStartRelativeToPreviousSpanStart || 0,
                    dropSpanEndRelativeToPreviousSpanEnd: span.dropSpanEndRelativeToPreviousSpanEnd || 0,
                    isDropSpan: true,
                    spanTowerIndex,
                    isEndBoom: span.EndBoom
                })
            }
            else {
                partialSpanTowers.push({
                    lengthFeet: multiplier * spanf.LengthInFeet(getSide(this.system, sideEnum), span),
                    isDropSpan: false,
                    spanTowerIndex,
                    isEndBoom: span.EndBoom
                })
            }
        }

        const rigidSideFwd = this.isRigidSideForward();

        const anticlockwisePivotLineIndicies = this.anticlockwisePivotIndicies;

        const generateFeedLineSegments = (feedLine: LineString) => {
            const lineSegments: ILineSegment[] = [];

            const findAngleBetween = (p1: Position, p2: Position, p3: Position) => {
                const P1 = { x: p1[0], y: p1[1] }
                const P2 = { x: p2[0], y: p2[1] }
                const P3 = { x: p3[0], y: p3[1] }
                const a1 = Math.atan2(P3.y - P1.y, P3.x - P1.x);
                const a2 = Math.atan2(P2.y - P1.y, P2.x - P1.x);
                const delta_a = a2 - a1;
                let degrees_a = delta_a * 180 / Math.PI;
                degrees_a = (degrees_a + 360) % 360;
                return degrees_a;
            }

            const pivotDirectionFromInsideInformation = (segmentInside: boolean, pivotInside: boolean): PivotDirection => {
                return segmentInside
                    ? pivotInside ? "anticlockwise" : "clockwise"
                    : pivotInside ? "clockwise" : "anticlockwise";
            }

            // NOTE: Due to a change in the direction laterals should be drawn for end feed,
            // the end points of the linestring were flipped after drawing.
            // This was fine with just two points, but now there are multiple points on the
            // linestring, it becomes a little harder to understand.
            // As such, the segments start inside with the feed line reversed when creating segments:
            // TODO: in future, add the line string naturally, but make sure the end feed still renders in the correct
            // direction.
            let previousSegmentInside = true; 
            const reversedFeedLine = feedLine.coordinates.slice().reverse();
            let previousP2direction = pivotDirectionFromInsideInformation(previousSegmentInside, anticlockwisePivotLineIndicies.includes(0));
            for (let iLineSegment = 0; iLineSegment < reversedFeedLine.length - 1; iLineSegment++) {
                const lineSegment = lineString(reversedFeedLine.slice(iLineSegment, iLineSegment + 2));

                const p1inside = anticlockwisePivotLineIndicies.includes(iLineSegment);
                const p2inside = anticlockwisePivotLineIndicies.includes(iLineSegment + 1);

                const pC = reversedFeedLine[iLineSegment];
                const pL = reversedFeedLine[iLineSegment - 1];
                const pR = reversedFeedLine[iLineSegment + 1];
                if (pC && pL && pR) {
                    const angle = findAngleBetween(pC, pR, pL);
                    if (!previousSegmentInside) {
                        if (angle <= 180 && !p1inside) {
                            previousSegmentInside = !previousSegmentInside;
                        }
                        else if (angle > 180 && p1inside) {
                            previousSegmentInside = !previousSegmentInside;
                        }
                    }
                    else {
                        if (angle <= 180 && p1inside) {
                            previousSegmentInside = !previousSegmentInside;
                        }
                        else if (angle > 180 && !p1inside) {
                            previousSegmentInside = !previousSegmentInside;
                        }
                    }
                }

                const p2Direction = pivotDirectionFromInsideInformation(previousSegmentInside, p2inside);
                lineSegments.push({
                    type: 'line',
                    p1: lineSegment.geometry.coordinates[0],
                    p2: lineSegment.geometry.coordinates[1],
                    p1Direction: previousP2direction,
                    p2Direction,
                    inside: previousSegmentInside
                });
                previousP2direction = p2Direction;
            }
            return lineSegments;
        }

        const generateLateralSegments = (feedLine: LineString, spanTowers: PartialSpanTower[], startLength: number, feedDrop?: { start: number, end: number }): ILateralSpanTowerV2[] => {
            const lineSegments: ILineSegment[] = generateFeedLineSegments(feedLine); // Length N

            
            const { startPivot, endPivot } = this.system.lateral;

            const lineSegmentsClone = structuredClone(lineSegments).map(x => ({ ...x, retracing: true }));
            let startPivotSegment: IPivotSegment | undefined = undefined;
            const startLineSegments: ILineSegment[] = [];
            if (startPivot) {
                let { lengthAlongCenterLineFt, angleDegrees, retrace } = startPivot;

                // case 1: retrace the lateral
                // angle and lengthAlongCenterLineFt are ignored:
                if (retrace) {
                    startLineSegments.push(...lineSegmentsClone);
                    lineSegmentsClone.splice(0, lineSegmentsClone.length);
                }
                // case 2: partial retrace
                // angle is ignored
                else if (lengthAlongCenterLineFt) {
                    while (lineSegmentsClone.length && getSegmentLength(lineSegmentsClone[0], 0) < lengthAlongCenterLineFt) {
                        lengthAlongCenterLineFt -= getSegmentLength(lineSegmentsClone[0], 0);
                        const addingSegment = lineSegmentsClone.splice(0, 1)[0];
                        startLineSegments.push(addingSegment);
                    }
                    if (lineSegmentsClone.length && lengthAlongCenterLineFt) {
                        const segmentLine = lineString(getSegmentLine_Line(lineSegmentsClone[0], 0));
                        const trimmedLine = lineSliceAlongCustom(segmentLine, 0, lengthAlongCenterLineFt, { units: 'feet' });
                        startLineSegments.push({
                            type: 'line',
                            p1: trimmedLine.geometry.coordinates[0],
                            p2: trimmedLine.geometry.coordinates[1],
                            p1Direction: lineSegmentsClone[0].p1Direction,
                            p2Direction: lineSegmentsClone[0].p2Direction,
                            inside: lineSegmentsClone[0].inside,
                            retracing: true
                        })
                        lineSegmentsClone[0].p1 = trimmedLine.geometry.coordinates[1];
                    }
                }
                // case 3: pivot only
                else if (angleDegrees && angleDegrees <= 180) {
                    const segment = lineSegmentsClone[0];
                    const type = segment.p1Direction;
                    const center = segment.p1;
                    const offset1 = lineString(getSegmentLine_Line(segment, 100));
                    const o1 = offset1.geometry.coordinates[0];
                    const b2 = turf.bearing(center, o1, { final: true });
                    const b1 = b2 - (type === 'anticlockwise' ? -1 : 1) * angleDegrees;
                    startPivotSegment = {
                        type,
                        b1: b1,
                        b2: b2,
                        center,
                        pivotStart: true
                    }
                }
                
                // if any startLineSegments were generated,
                // we need to reverse them
                startLineSegments.reverse();

                for (let i = 0; i < startLineSegments.length; i++) {
                    const s = startLineSegments[i];
                    
                    // switch the ends:
                    const temp = s.p1;
                    s.p1 = s.p2;
                    s.p2 = temp;

                    // switch the end directions
                    const temp2 = s.p1Direction;
                    s.p1Direction = s.p2Direction;
                    s.p2Direction = temp2;

                    // invert all first directions except the first segment:
                    if (i !== 0) {
                        s.p1Direction = s.p1Direction === 'clockwise' ? 'anticlockwise' : 'clockwise';
                    }
                    
                    // invert all last directions except the last segment:
                    if (i !== startLineSegments.length - 1) {
                        s.p2Direction = s.p2Direction === 'clockwise' ? 'anticlockwise' : 'clockwise';
                    }
                }
            }
            
            let endPivotSegment: IPivotSegment | undefined = undefined;
            const endSegments: ILineSegment[] = [];
            if (endPivot) {
                const retrace = !startPivot?.retrace && endPivot.retrace; // we will only retrace if the start did not retrace already
                let lengthAlongCenterLineFt = !startPivot?.retrace && endPivot.lengthAlongCenterLineFt; // we will only partial retrace if the start pivot did retrace already
                const angleDegrees = (endPivot.retrace || endPivot.lengthAlongCenterLineFt) ? 180 : endPivot.angleDegrees;
                
                // reverse the segments
                lineSegmentsClone.reverse();
                for (let i = 0; i < lineSegmentsClone.length; i++) {
                    const s = lineSegmentsClone[i];
                    
                    // switch the ends:
                    const temp = s.p1;
                    s.p1 = s.p2;
                    s.p2 = temp;

                    // switch the end directions
                    const temp2 = s.p1Direction;
                    s.p1Direction = s.p2Direction;
                    s.p2Direction = temp2;

                    // invert all first directions except the first segment:
                    if (i !== 0) {
                        s.p1Direction = s.p1Direction === 'clockwise' ? 'anticlockwise' : 'clockwise';
                    }
                    
                    // invert all last directions except the last segment:
                    if (i !== startLineSegments.length - 1) {
                        s.p2Direction = s.p2Direction === 'clockwise' ? 'anticlockwise' : 'clockwise';
                    }
                }


                // case 1: retrace the lateral
                // angle and lengthAlongCenterLineFt are ignored:
                if (retrace) {
                    endSegments.push(...lineSegmentsClone);
                    lineSegmentsClone.splice(0, lineSegmentsClone.length);
                }
                // case 2: partial retrace
                // angle is ignored
                else if (lengthAlongCenterLineFt) {
                    while (lineSegmentsClone.length && getSegmentLength(lineSegmentsClone[0], 0) < lengthAlongCenterLineFt) {
                        lengthAlongCenterLineFt -= getSegmentLength(lineSegmentsClone[0], 0);
                        endSegments.push(...lineSegmentsClone.splice(0, 1));
                    }
                    if (lineSegmentsClone.length && lengthAlongCenterLineFt) {
                        const segmentLine = lineString(getSegmentLine_Line(lineSegmentsClone[0], 0));
                        const trimmedLine = lineSliceAlongCustom(segmentLine, 0, lengthAlongCenterLineFt, { units: 'feet' });
                        endSegments.push({
                            type: 'line',
                            p1: trimmedLine.geometry.coordinates[0],
                            p2: trimmedLine.geometry.coordinates[1],
                            p1Direction: lineSegmentsClone[0].p1Direction,
                            p2Direction: lineSegmentsClone[0].p2Direction,
                            inside: lineSegmentsClone[0].inside,
                            retracing: true
                        })
                        lineSegmentsClone[0].p1 = trimmedLine.geometry.coordinates[1];
                    }
                }
                // case 3: pivot only
                else if (angleDegrees && angleDegrees <= 180) {
                    if (!lineSegmentsClone.length) {
                        // if the startPivot used up all the segments, regenerate the last segment to use here
                        lineSegmentsClone.push(structuredClone(lineSegments.slice(-1).map(x => ({ ...x, retracing: true }))[0]));
                        lineSegmentsClone.forEach(s => {
                            const temp = s.p1;
                            s.p1 = s.p2;
                            s.p2 = temp;

                            const temp2 = s.p1Direction;
                            s.p1Direction = s.p2Direction;
                            s.p2Direction = temp2;
                        })
                    }
                    const segment = lineSegmentsClone[0];
                    const type = segment.p1Direction;
                    const center = segment.p1;
                    const offset1 = lineString(getSegmentLine_Line(segment, -100));
                    const o1 = offset1.geometry.coordinates[0];
                    const b1 = turf.bearing(center, o1, { final: true });
                    const b2 = b1 + (type === 'anticlockwise' ? -1 : 1) * angleDegrees;
                    endPivotSegment = {
                        type,
                        b1,
                        b2,
                        center,
                        pivotEnd: true
                    }
                }
            }

            lineSegments.unshift(...startLineSegments);
            lineSegments.push(...endSegments);
            if (lineSegments.length && IsPivotingLateral(this.system)) {
                if (!startPivotSegment) {
                    lineSegments[0].pivotStart = true;
                }
                if (!endPivotSegment) {
                    lineSegments.slice(-1)[0].pivotEnd = true;
                }
            }
            // next gather the radii's:
            const radiusSegments: IPivotSegment[] = []; // Length N - 1
            for (let iLineSegment = 0; iLineSegment < lineSegments.length - 1; iLineSegment++) {
                const leftSegment = lineSegments[iLineSegment];
                const rightSegment = lineSegments[iLineSegment + 1];
                const center = leftSegment.p2;
                const offset1 = lineString(getSegmentLine_Line(leftSegment, 100));
                const offset2 = lineString(getSegmentLine_Line(rightSegment, 100));
                const o1 = offset1.geometry.coordinates[1];
                const o2 = offset2.geometry.coordinates[0];
                const b1 = turf.bearing(center, o1, { final: true });
                const b2 = turf.bearing(center, o2, { final: true });
                const type = lineSegments[iLineSegment].p2Direction;
                radiusSegments.push({ center, b1, b2, type });
            }

            // now combine:
            const combinedSegments: IlateralSegment[] = [
                lineSegments[0]
            ];
            for (let i = 0; i < radiusSegments.length; i++) {
                combinedSegments.push(radiusSegments[i], lineSegments[i + 1]);
            }
            if (startPivotSegment) {
                combinedSegments.unshift(startPivotSegment);
            }
            if (endPivotSegment) {
                combinedSegments.push(endPivotSegment);
            }
            let leftLostSegments: IlateralSegment[] = [];
            let rightLostSegments: IlateralSegment[] = [];

            const result: ILateralSpanTowerV2[] = [];
            let runningLength = startLength;

            if (feedDrop && combinedSegments.length) {
                const res = trimAndMutateSegments(combinedSegments, feedDrop.start, feedDrop.end, runningLength);
                leftLostSegments = res.leftLostSegments;
                rightLostSegments = res.rightLostSegments;
            }

            for (let i = 0; i < spanTowers.length; i++) {
                const spanTower = spanTowers[i];
                result.push({
                    ...spanTower,
                    segments: structuredClone(combinedSegments),
                    leftLostSegments: structuredClone(leftLostSegments),
                    rightLostSegments: structuredClone(rightLostSegments).reverse(),
                    insideRadius: runningLength,
                    outsideRadius: runningLength + spanTower.lengthFeet
                })
                runningLength += spanTower.lengthFeet;
                if (spanTower.isDropSpan && combinedSegments.length) {
                    const res = trimAndMutateSegments(combinedSegments, spanTower.dropSpanStartRelativeToPreviousSpanStart, spanTower.dropSpanEndRelativeToPreviousSpanEnd, runningLength);
                    leftLostSegments = res.leftLostSegments;
                    rightLostSegments = res.rightLostSegments
                }
                else {
                    leftLostSegments = [];
                    rightLostSegments = [];
                }
            }
            return result;
        }

        const generateLateralEndGunSegmentsV2 = (lateralSpanTowers: ILateralSpanTowerV2[], endGunInformations: ILateralEndGunInformation[]) => {
            if (!lateralSpanTowers.length) return [];
            const lastTower = lateralSpanTowers.slice(-1)[0];
            const combinedSegmentsBase = lastTower.segments;
            const fullLength = length(
                feature(getLateralLineString(combinedSegmentsBase, lastTower.outsideRadius)), { units: 'feet' }
            );
            
            const result: ILateralEndGunPartV2[] = [];
            for (let i = 0; i < endGunInformations.length; i++) {
                const endGunInformation = endGunInformations[i];
                for (const onOff of endGunInformation.onOffs) {
                    const combinedSegments = structuredClone(combinedSegmentsBase);
                    let trimStart = onOff.on;
                    while (combinedSegments.length && trimStart >= getSegmentLength(combinedSegments[0], lastTower.outsideRadius)) {
                        trimStart -= getSegmentLength(combinedSegments[0], lastTower.outsideRadius);
                        combinedSegments.splice(0, 1);
                    }
                    if (combinedSegments.length && trimStart) {
                        const startSegment = combinedSegments[0];
                        if (startSegment.type === 'line') {
                            const newLine = getSegmentLine_Line(startSegment, 0, { trimStart });
                            startSegment.p1 = newLine[0];
                        }
                        else {
                            const newLine = getSegmentLine_Pivot(startSegment, lastTower.outsideRadius, { trimStart });
                            const p = newLine[0];
                            startSegment.b1 = turf.bearing(startSegment.center, p, { final: true });
                        }
                    }
                    
                    
                    let trimEnd = fullLength - onOff.off;
                    while (combinedSegments.length && trimEnd >= getSegmentLength(combinedSegments.slice(-1)[0], lastTower.outsideRadius)) {
                        trimEnd -= getSegmentLength(combinedSegments.slice(-1)[0], lastTower.outsideRadius);
                        combinedSegments.splice(-1, 1);
                    }
                    if (combinedSegments.length && trimEnd) {
                        const endSegment = combinedSegments.slice(-1)[0];
                        if (endSegment.type === 'line') {
                            const newLine = getSegmentLine_Line(endSegment, 0, { trimEnd });
                            endSegment.p2 = newLine[1];
                        }
                        else {
                            const newLine = getSegmentLine_Pivot(endSegment, lastTower.outsideRadius, { trimEnd });
                            const p = newLine.slice(-1)[0];
                            endSegment.b2 = turf.bearing(endSegment.center, p, { final: true });
                        }
                    }
                    
                    result.push({
                        segments: combinedSegments,
                        insideRadius: lastTower.outsideRadius,
                        outsideRadius: lastTower.outsideRadius + (lastTower.outsideRadius > 0 ? endGunInformation.throwFeet : -endGunInformation.throwFeet),
                        isPrimary: endGunInformation.isPrimary
                    }); 
                }
            }
            return result;
        }

        const flangedFeedLine = this.lateralCenterLine;// this.feedCenterLine();
        const flexFeedLine = this.lateralCenterLine;// = this.feedCenterLine();// = this.flexSideCenterLine(true);
        const fledDrop = this.hasFlexDrop ?
            {
                start: this.system.Lateral.dropSpanStartRelativeToPreviousSpanStart || 0,
                end: this.system.Lateral.dropSpanEndRelativeToPreviousSpanEnd || 0
            }
            : undefined;

        const partialAftSide = partialSpanTowers.slice(0, this.aftSideSpanCount).reverse();
        const feedLineAft = rigidSideFwd ? flexFeedLine : flangedFeedLine;
        const feedDropAft = rigidSideFwd ? fledDrop : undefined;
        const startLengthAft = this.isCanalFeed
            ? -this.distanceFromCanalCenterToAftSide
            : 0;
        const aftSpanTowers: ILateralSpanTowerV2[] = generateLateralSegments(feedLineAft, partialAftSide, startLengthAft, feedDropAft);

        const partialFwdSide = partialSpanTowers.slice(this.aftSideSpanCount);
        const feedLineFwd = rigidSideFwd ? flangedFeedLine : flexFeedLine;
        const feedDropFwd = rigidSideFwd ? undefined : fledDrop;
        const startLengthFwd = this.isCanalFeed
            ? this.distanceFromCanalCenterToFwdSide
            : 0;
        const fwdSpanTowers: ILateralSpanTowerV2[] = generateLateralSegments(feedLineFwd, partialFwdSide, startLengthFwd, feedDropFwd);
        
        this._flexSideDirectionalSpanTowersFromFeedLine = rigidSideFwd ? aftSpanTowers : fwdSpanTowers.map(x => ({ ...x, lengthFeet: -x.lengthFeet }));
        this._rigidSideDirectionalSpanTowersFromFeedLine = rigidSideFwd ? fwdSpanTowers : aftSpanTowers.map(x => ({ ...x, lengthFeet: -x.lengthFeet }));
        this._feedLineSegments = generateFeedLineSegments(flangedFeedLine);
        this._flexSideDirectionalEndGunParts = generateLateralEndGunSegmentsV2(
            this._flexSideDirectionalSpanTowersFromFeedLine,
            this.system.endGuns?.lateralOnOffsFlex || []
        )
        this._rigidSideDirectionalEndGunParts = generateLateralEndGunSegmentsV2(
            this._rigidSideDirectionalSpanTowersFromFeedLine,
            this.system.endGuns?.lateralOnOffsFlanged || []
        )

        // console.log("fs", this._flexSideDirectionalSpanTowersFromFeedLine)
        // console.log("rs", this._rigidSideDirectionalSpanTowersFromFeedLine)
        // console.log("fe", this._flexSideDirectionalEndGunParts)
        // console.log("re", this._rigidSideDirectionalEndGunParts)
    }

    public calculateEndGunPolygonsForSide(side: 'rigid' | 'flex', additionalObstacles: Polygon[] = []): EndGunDescription {
        const DEFAULT_NO_ENDGUNS_RETURN: EndGunDescription = { features: [], endGunInformation: [] };
        const features: {feature: Feature<Polygon>, isPrimary: boolean}[] = [];        
        const endGunInformation: { endGun: EndGunTypes, isPrimary: boolean, onOffs: { on: number, off: number }[], throwFeet: number }[] = [];        
        if (side === 'flex' && !this.hasFlexSide) { 
            return DEFAULT_NO_ENDGUNS_RETURN;
        }
        const nonBufferedBoundary = this.layout.fieldBoundary
            ? BoundaryHelper.getPolygon(this.layout.fieldBoundary)
            : undefined;
        if (!nonBufferedBoundary) {
            return DEFAULT_NO_ENDGUNS_RETURN;
        }

        const nonBufferedAllowableAreaPolygon = this.layout.wetAreaBoundary
            ? BoundaryHelper.getPolygon(this.layout.wetAreaBoundary)
            : nonBufferedBoundary;
        const allowableAreaPolygonWithSystem = turf.booleanContains(nonBufferedAllowableAreaPolygon, this.lateralCenterLine)
            ? nonBufferedAllowableAreaPolygon
            : undefined;
        if (!allowableAreaPolygonWithSystem) {
            return DEFAULT_NO_ENDGUNS_RETURN;
        }

        let _allowableAreaPolygon: Feature<Polygon | MultiPolygon> | null = feature(
            allowableAreaPolygonWithSystem
        );
        const tryAddObstacleToAllowableAreaPolygon = (obstacle: Polygon | Feature<Polygon | MultiPolygon>): boolean => {
            try {
                _allowableAreaPolygon = customDifference(_allowableAreaPolygon, obstacle);
                return true;
            }
            catch {
                _allowableAreaPolygon = null;
                return false;
            }
        }
        const nonBufferedObstacles = this.layout.obstacles.map(x => ObstacleHelper.getPolygon(x));
        for (const obs of nonBufferedObstacles) {
            if (!tryAddObstacleToAllowableAreaPolygon(obs)) {
                return DEFAULT_NO_ENDGUNS_RETURN;
            }
        }

        for (const obs of additionalObstacles) {
            if (!tryAddObstacleToAllowableAreaPolygon(obs)) {
                return DEFAULT_NO_ENDGUNS_RETURN;
            }
        }

        const allSystems = Object.entries(this.layout.systems);
        for (let i = 0; i < allSystems.length; i++) {
            const [systemId, system] = allSystems[i];
            if (systemId === this.systemId) continue;
            if (system.SystemProperties.SystemType === SystemTypes.CenterPivot) {
                const systemGeometryHelper = new CenterPivotGeometryHelper({
                    project: this.project,
                    layoutId: this.layoutId,
                    systemId
                })
                const p = systemGeometryHelper.getAreaPolygon({includeEndguns: false, includeSAC: true});
                if (p) {
                    if (!tryAddObstacleToAllowableAreaPolygon(p)) {
                        return DEFAULT_NO_ENDGUNS_RETURN;
                    }
                }
            }
            else if (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) {
                const systemGeometryHelper = new LateralGeometryHelper({
                    project: this.project,
                    layoutId: this.layoutId,
                    systemId
                })
                const p = systemGeometryHelper.getAreaPolygon();
                if (p) {   
                    if (!tryAddObstacleToAllowableAreaPolygon(p)) {
                        return DEFAULT_NO_ENDGUNS_RETURN;
                    }
                }
            }
        }

        let throwMultiplier: number;
        if (this.isRigidSideForward()) {
            throwMultiplier = side === 'rigid' ? 1 : -1;
        }
        else {
            throwMultiplier = side === 'flex' ? 1 : -1;
        }
        
        const endGunTypes: {type: EndGunTypes, isPrimary: boolean, throwFeet: number}[] = [];
        const systemSide = side === 'rigid'
            ? this.system.FlangedSide
            : this.system.FlexSide;
        const sideEnum = side === 'rigid'
            ? SideEnum.Flanged
            : SideEnum.Flex;
        if (systemSide.EndOfSystem.EndGun.EndGunTypePrimary && systemSide.EndOfSystem.EndGun.EndGunTypePrimary !== EndGunTypes.None) {
            const throwFeet = getEndGunThrow(this.system, sideEnum, EndGunLocation.Primary);
            if (throwFeet) {
                endGunTypes.push({
                    type: systemSide.EndOfSystem.EndGun.EndGunTypePrimary, 
                    isPrimary: true,
                    throwFeet: throwFeet
                });
            }
        }
        if (systemSide.EndOfSystem.EndGun.EndGunTypeSecondary && systemSide.EndOfSystem.EndGun.EndGunTypeSecondary !== EndGunTypes.None) {
            const throwFeet = getEndGunThrow(this.system, sideEnum, EndGunLocation.Secondary);
            if (throwFeet) {
                endGunTypes.push({
                    type: systemSide.EndOfSystem.EndGun.EndGunTypeSecondary, 
                    isPrimary: false,
                    throwFeet: throwFeet
                });
            }
        }
        const lastTower = side === 'rigid'
            ? this.directionalRigidSideSpanTowers[this.directionalRigidSideSpanTowers.length - 1]
            : this.directionalFlexSideSpanTowers[this.directionalFlexSideSpanTowers.length - 1];
        
        const thisSystemPolygons = lastTower.segments.map(s => {
            const p = getLateralLinePolygons([s], 0, lastTower.outsideRadius);
            return p.polygons;
        })
        for (const endGun of endGunTypes) {
            const throwFeet = endGun.throwFeet;

            let systemConfigurationHeadingFrom = 0;
            const onOffs: { on: number; off: number; }[] = [];

            // First add the endguns for line segments:
            for (let iSegment = 0; iSegment < lastTower.segments.length; iSegment++) {
                const segment = lastTower.segments[iSegment];
                
                const lastSpanLine = getLateralLineString([ segment ], lastTower.outsideRadius);
                if (segment.type !== 'line') {
                    systemConfigurationHeadingFrom += length(feature(lastSpanLine), { units: 'feet' });
                    continue;
                }
                const endGunRigidEnd = getLateralLineString([ segment ], lastTower.outsideRadius + throwFeet * throwMultiplier);
                
                const endGunFullPolygon = polygon([
                    [
                        ...lastSpanLine.coordinates,
                        ...[...endGunRigidEnd.coordinates].reverse(),
                        lastSpanLine.coordinates[0]
                    ]
                ]);
                
                let segmentAllowableAreaPolygon = intersect(endGunFullPolygon, _allowableAreaPolygon);
                if (!segmentAllowableAreaPolygon) continue;
                for (let idx = 0; idx < thisSystemPolygons.length; idx++) {
                    if (idx !== iSegment) {
                        for (const p of thisSystemPolygons[idx]) {
                            segmentAllowableAreaPolygon = segmentAllowableAreaPolygon ? customDifference(segmentAllowableAreaPolygon, p) : null;
                        }
                    }
                }
                if (!segmentAllowableAreaPolygon) continue;
                
                const endGunAllowablePolygonCoords = coordAll(segmentAllowableAreaPolygon);
                const leadingLine = lineString([
                    lastSpanLine.coordinates[0], endGunRigidEnd.coordinates[0]
                ])
                const endGunAllowablePolygonCoordDistances = [ 
                    ...new Set(endGunAllowablePolygonCoords.map(x => ptldCustom(x, leadingLine, { units: 'feet' })))
                ].sort((a, b) => a - b);
        
                const endGunPolys: {feature: Feature<Polygon>, isPrimary: boolean}[] = [];
                for (let i = 0; i < endGunAllowablePolygonCoordDistances.length - 1; i++) {
                    const d1 = endGunAllowablePolygonCoordDistances[i];
                    const d2 = endGunAllowablePolygonCoordDistances[i + 1];
                    const p1 = along(lastSpanLine, d1, { units: 'feet' }).geometry.coordinates;
                    const p2 = along(lastSpanLine, d2, { units: 'feet' }).geometry.coordinates;
                    const o1 = along(endGunRigidEnd, d1, { units: 'feet' }).geometry.coordinates;
                    const o2 = along(endGunRigidEnd, d2, { units: 'feet' }).geometry.coordinates;
                    const irrigatedPolygon = polygon(
                        [
                            [
                                p1,p2,o2,o1,p1
                            ]
                        ], {
                            rdpFeatureType: "system/irrigatedArea-endGun",
                            ...this.propertiesForAll
                        }
                    )
                    const bufferedIrrigatedPolygon = buffer(irrigatedPolygon, -0.5, { units: 'feet'});
                    if (!bufferedIrrigatedPolygon) continue;
        
                    if (difference(bufferedIrrigatedPolygon, segmentAllowableAreaPolygon) === null) {
                        const on = Math.round((d1 + systemConfigurationHeadingFrom) * 100) / 100;
                        const off = Math.round(( d2 + systemConfigurationHeadingFrom ) * 100) / 100;
                        const prevOnOff = onOffs[onOffs.length - 1];
                        if (prevOnOff && prevOnOff.off === on) {
                            prevOnOff.off = off;
                        }
                        else {
                            onOffs.push({ 
                                on: Math.round((d1 + systemConfigurationHeadingFrom) * 100) / 100, 
                                off: Math.round(( d2 + systemConfigurationHeadingFrom ) * 100) / 100
                            });
                        }
                        endGunPolys.push({feature: irrigatedPolygon, isPrimary: endGun.isPrimary});                    
                    }
                }
        
                for (let i = 0; i < endGunPolys.length; i++) {
                    if (!tryAddObstacleToAllowableAreaPolygon(endGunPolys[i].feature)) {
                        return DEFAULT_NO_ENDGUNS_RETURN;
                    }
                }
                features.push(...endGunPolys);
                systemConfigurationHeadingFrom += length(feature(lastSpanLine), { units: 'feet' });
            }

            // Now add the radii:
            systemConfigurationHeadingFrom = 0;
            for (let iSegment = 0; iSegment < lastTower.segments.length; iSegment++) {
                const segment = lastTower.segments[iSegment];
                const lastSpanLine = getLateralLineString([ segment ], lastTower.outsideRadius);
                if (segment.type === 'line') {
                    // handled above:
                    systemConfigurationHeadingFrom += length(feature(lastSpanLine), { units: 'feet' });
                    continue;
                }
                
                const rings = _allowableAreaPolygon.geometry.type === 'Polygon'
                    ? [ _allowableAreaPolygon.geometry.coordinates ]
                    : _allowableAreaPolygon.geometry.coordinates;
                for (const ring of rings) {
                    const boundary = polygon([ ring[0] ]).geometry;
                    const obstacles = ring.slice(1).map(x => polygon([ x ]).geometry);
                    thisSystemPolygons.forEach((ps, idx) => {
                        if (idx !== iSegment) {
                            for (const p of ps) {
                                obstacles.push(p)
                            }
                        }
                    });

                    // The line of sight polygons used in calcualteEndGunOnOffs are confused by points that lie at the center
                    // of the los. Therefor, the obstacles are diffed with the buffer so that they do not include this center
                    // point.
                    // Further more, the obstacles from the lastTower outer radius and beyond is all we are interested in interms of
                    // los collisions
                    const buff = buffer(point(segment.center), lastTower.outsideRadius, { units: "feet" });
                    const obstaclesMinusCenterBuffer: Polygon[] = [];
                    obstacles.forEach(x => {
                        const d = customDifference(x, buff);
                        if (d.geometry.type === 'Polygon') {
                            obstaclesMinusCenterBuffer.push(d.geometry)
                        }
                        else {
                            d.geometry.coordinates.forEach(y => {
                                const p = polygon(y);
                                obstaclesMinusCenterBuffer.push(p.geometry);
                            })
                        }
                    });
                    
                    const results = calculateEngGunOnOffs({
                        system: {
                            center: point(segment.center).geometry,
                            minBearing: segment.type === 'anticlockwise' ? segment.b2 : segment.b1,
                            maxBearing: segment.type === 'anticlockwise' ? segment.b1 : segment.b2,
                            radiusFeet: lastTower.outsideRadius
                        },
                        throwDistanceFeet: throwFeet,
                        obstacles: obstaclesMinusCenterBuffer,
                        boundary
                    })

                    // console.log("args", 
                    //     turf.featureCollection([
                    //         boundary, ...obstaclesMinusCenterBuffer
                    //     ].map(x => feature(x)))
                    // )
                    // console.log("results", results)
                    
                    for (const result of results) {
                        const p1 = destination(segment.center, lastTower.outsideRadius, segment.type === 'anticlockwise' ? result.endBearing : result.startBearing, { units: 'feet' });
                        const p2 = destination(segment.center, lastTower.outsideRadius, segment.type === 'anticlockwise' ? result.startBearing : result.endBearing, { units: 'feet' });
                        const np1 = turf.nearestPointOnLine(lastSpanLine, p1, { units: 'feet' });
                        const np2 = turf.nearestPointOnLine(lastSpanLine, p2, { units: 'feet' });
                        if (Math.abs(np2.properties.location - np1.properties.location) < 1) {
                            console.log("End gun area to small, skipping")
                            continue;
                        }
                        onOffs.push({
                            on: systemConfigurationHeadingFrom + np1.properties.location,
                            off: systemConfigurationHeadingFrom + np2.properties.location,
                        })
                        
                        const poly = getCombineLateralLinePolygons(
                            [ {
                                ...segment,
                                b1: segment.type === 'anticlockwise' ? result.endBearing : result.startBearing,
                                b2:  segment.type === 'anticlockwise' ? result.startBearing : result.endBearing,
                            }], lastTower.outsideRadius, lastTower.outsideRadius + throwFeet
                        )
                        if (!tryAddObstacleToAllowableAreaPolygon(poly)) {
                            return DEFAULT_NO_ENDGUNS_RETURN;
                        }
                    }
                }
                systemConfigurationHeadingFrom += length(feature(lastSpanLine), { units: 'feet' });
            }
            endGunInformation.push({ endGun: endGun.type, onOffs, isPrimary: endGun.isPrimary, throwFeet });
        }
        return {
            features,
            endGunInformation
        };
    }
    private _calculateEndGunPolygonsForSide(side: 'rigid' | 'flex'): EndGunDescription {
        const features: {feature: Feature<Polygon>, isPrimary: boolean}[] = [];        
        let endGunInformation: { endGun: EndGunTypes, isPrimary: boolean, onOffs: { on: number, off: number }[], throwFeet: number }[] = [];

        const endGunParts = side === 'rigid'
            ? this.directionalRigidSideEndGunParts
            : this.directionalFlexSideEndGunParts;

        for (const part of endGunParts) {
            const poly = getLateralLinePolygons(part.segments, part.insideRadius, part.outsideRadius);
            features.push(
                ...poly.polygons.map(p => {
                    return {
                        feature: feature(
                            p,
                            {
                                rdpFeatureType: "system/irrigatedArea-endGun",
                                ...this.propertiesForAll
                            }
                        ),
                        isPrimary: part.isPrimary
                    }
                })
            )
        }
        return {
            features,
            endGunInformation
        };
    }

    private _calculateEndGuns(): {flex: EndGunDescription, rigid: EndGunDescription} {
        const flex = this._calculateEndGunPolygonsForSide('flex');
        const rigid = this._calculateEndGunPolygonsForSide('rigid');
        return {flex, rigid};
    }

    getEndGunInformation() {
        const { flex, rigid } = this._calculateEndGuns();
        return {
            rigid: rigid.endGunInformation,
            flex: flex.endGunInformation
        }
    }

    private _getDrawFeaturesSimple() {
        const features: Feature[] = [];

        const irrigatedAreasFlanged: Polygon[] = [];
        const irrigatedAreasFlex: Polygon[] = [];
        const wheelTracks: { label?: string; geometry: LineString; }[] = [];
        const endGunAreas: Polygon[] = [];
        const dropIrrigatedAreas: Polygon[] = [];

        // feed line
        features.push(
            feature(
                this.feedCenterLine(),
                {
                    ...this.propertiesForAll,
                    rdpFeatureType: "system/lateral"
                }
            )
        )

        // canal:
        if (this.isCanalFeed) {
            const line1 = lateralLineOffset(this.canalCenterLine, -this.canalWidthFeet * 0.5, { units: 'feet' });
            const line2 = lateralLineOffset(this.canalCenterLine, this.canalWidthFeet * 0.5, { units: 'feet' });
            features.push(
                polygon(
                    [
                        [
                            ...line1.geometry.coordinates,
                            ...line2.geometry.coordinates.reverse(),
                            line1.geometry.coordinates[0]
                        ]
                    ],
                    {
                        ...this.propertiesForAll,
                        rdpFeatureType: "system/canal"
                    }
                )
            )
        }

        // irrigated area flex side
        const flexSide = this._getFlexSideIrrigatedAreaPolygons();
        irrigatedAreasFlex.push(...flexSide.mainSystem.map(x => x.geometry));
        dropIrrigatedAreas.push(...flexSide.drop.map(x => x.geometry));

        // irrigated area rigid side
        // here
        const rigidSide = this._getRigidSideIrrigatedAreaPolygons();
        irrigatedAreasFlanged.push(...rigidSide.mainSystem.map(x => x.geometry));
        dropIrrigatedAreas.push(...rigidSide.drop.map(x => x.geometry));

        // end guns:
        const endguns = this._calculateEndGuns();
        endGunAreas.push(...endguns.flex.features.map(x => x.feature.geometry));
        endGunAreas.push(...endguns.rigid.features.map(x => x.feature.geometry));

        // wheel tracks:
        const detailedWheelTracks = this.getWheelTracksDetailed();
        if (detailedWheelTracks.flexSideStart) {
            wheelTracks.push({ geometry: detailedWheelTracks.flexSideStart });
        }
        detailedWheelTracks.flexSide.forEach((x, idx) => {
            wheelTracks.push({ geometry: x, label: (idx + 1).toString() });
        });
        if (detailedWheelTracks.rigidSideStart) {
            wheelTracks.push({ geometry: detailedWheelTracks.rigidSideStart });
        }
        detailedWheelTracks.rigidSide.forEach((x, idx) => {
            wheelTracks.push({ geometry: x, label: (idx + 1).toString() });
        });
        
        if (irrigatedAreasFlanged.length) {
            const mf = multiPolygon(irrigatedAreasFlanged.map(x => x.coordinates), {
                rdpFeatureType: "system/irrigatedArea",
                ...this.propertiesForAll
            })
            features.push(mf);
        }
        if (irrigatedAreasFlex.length) {
            const mf = multiPolygon(irrigatedAreasFlex.map(x => x.coordinates), {
                rdpFeatureType: "system/irrigatedArea-flexSide",
                ...this.propertiesForAll
            })
            features.push(mf);
        }
        if (wheelTracks.length) {
            const mf = multiLineString(
                wheelTracks.map(x => x.geometry.coordinates), 
                {
                    rdpFeatureType: "system/wheelTrack",
                    ...this.propertiesForAll,
                    rdpSpanNumberLabels: wheelTracks.map(x => x.label)
                })
            features.push(mf);
        }
        if (endGunAreas.length) {
            const mf = multiPolygon(endGunAreas.map(x => x.coordinates), {
                rdpFeatureType: "system/irrigatedArea-endGun",
                ...this.propertiesForAll
            })
            features.push(mf);
        }
        if (dropIrrigatedAreas.length) {
            const mf = multiPolygon(dropIrrigatedAreas.map(x => x.coordinates), {
                rdpFeatureType: "system/irrigatedArea-dropSpan",
                ...this.propertiesForAll
            })
            features.push(mf);
        }

        return features;
    }
    private _getDrawFeaturesSelectMode() {
        const features: Feature[] = [];

        if (this.directionalFlexSideSpanTowers.length === 0 && this.directionalRigidSideSpanTowers.length === 0) {
            // lateral center line (canal center or feed center)
            features.push(
                feature(
                    this.lateralCenterLine,
                    {
                        ...this.propertiesForAll,
                        rdpFeatureType: "system/lateral"
                    }
                )
            )
            return features;
        }

        // system area:
        const result = this._getSinglePolygonAndVerticies();
        if (result.geometry && result.geometry.type === 'Polygon') {
            features.push(
                feature(
                    result.geometry,
                    {
                        ...this.propertiesForAll,
                        rdpFeatureType: "centerPivotSelectMode/irrigatedArea",
                        activeSystem: this.isActive,
                        wheelTrackFeatureId: `wt-${this.systemId}`
                    }
                )
            )
        }

        // wheel tracks:
        if (result.wheelTracks) {
            const wheelTracks = multiLineString(
                result.wheelTracks.map(x => x.coordinates),
                {
                    rdpFeatureType: "centerPivotSelectMode/wheelTracks"
                }
            )
            wheelTracks.id = `wt-${this.systemId}`;
            features.push(wheelTracks);
        }

        {
            // end gun areas are added so that they are visible in lateral select mode.
            // They are hidden from that mode when a vertex is dragged.
            // end guns:
            const endguns = this._calculateEndGuns();
            const egfeatures: Feature<Polygon>[] = [
                ...endguns.flex.features.map(x => x.feature),
                ...endguns.rigid.features.map(x => x.feature)
            ];        
            features.push(...egfeatures);
        }
        
        return features;
    }

    // public methods:
    getRigidSideLeadingTower() {
        return this.system.FlangedSide.Tower[0];
    }
    getFlexSideLeadingTower() {
        if (!this.isCanalFeed) return undefined;
        return this.system.FlexSide.Tower[0];
    }
    getRigidSideLeadingSpan() {
        return this.system.FlangedSide.Span[0];
    }
    getFlexSideLeadingSpan() {
        if (!this.isCanalFeed) return undefined;
        return this.system.FlexSide.Span[0];
    }
    getDistanceFromSystemCenterToAftStartFeet() {
        if (!this.isCanalFeed) {
            return 0;
        }
        return this.distanceFromCanalCenterToAftSide;
    }
    getDistanceFromSystemCenterToFwdStartFeet() {
        if (!this.isCanalFeed) {
            return 0;
        }
        return this.distanceFromCanalCenterToFwdSide;
    }
    getDistanceFromSystemCenterToAftEndFeet() {
        let runningLengthFeet = 0;
        const spans = this.isRigidSideForward() 
            ? this.directionalFlexSideSpanTowers 
            : this.directionalRigidSideSpanTowers;
        if (spans.length) {
            runningLengthFeet = Math.abs(spans.slice(-1)[0].outsideRadius);
        }
        runningLengthFeet += this.getDistanceFromSystemCenterToAftStartFeet();
        return runningLengthFeet;
    }
    getDistanceFromSystemCenterToFwdEndFeet() {
        let runningLengthFeet = 0;
        const spans = this.isRigidSideForward() 
            ? this.directionalRigidSideSpanTowers 
            : this.directionalFlexSideSpanTowers;
        if (spans.length) {
            runningLengthFeet = Math.abs(spans.slice(-1)[0].outsideRadius);
        }
        runningLengthFeet += this.getDistanceFromSystemCenterToFwdStartFeet();
        return runningLengthFeet;
    }

    getDistanceFeetAftStartToCanalCenterFeet() {
        let runningLengthFeet = 0;
        if (this.isRigidSideForward()) {
            this.directionalFlexSideSpanTowers.forEach(x => runningLengthFeet += x.lengthFeet);
        }
        else {
            this.directionalRigidSideSpanTowers.forEach(x => runningLengthFeet += x.lengthFeet);
        }
        runningLengthFeet += this.distanceFromCanalCenterToAftSide;
        return runningLengthFeet;
    }
    getIrrigatedAreaPolygons() {
        const flexSide = this._getFlexSideIrrigatedAreaPolygons();
        const rigidSide = this._getRigidSideIrrigatedAreaPolygons();
        const endGuns = this._calculateEndGuns();

        const egfeatures: Feature<Polygon>[] = [
            ...endGuns.flex.features.map(x => x.feature),
            ...endGuns.rigid.features.map(x => x.feature)
        ];        

        const a = [ 
            ...rigidSide.mainSystem, ...rigidSide.drop,
            ...flexSide.mainSystem, ...flexSide.drop,
            ...egfeatures 
        ];
        return a;
    }
    getFlexSideMainIrrigatedAreaAcres() {
        const areaPolygons = this._getFlexSideIrrigatedAreaPolygons();
        let runningIrrigatedAreaSqm = 0;
        areaPolygons.mainSystem.forEach(p => {
            runningIrrigatedAreaSqm += area(p);
        })
        return convertArea(runningIrrigatedAreaSqm, 'meters', 'acres');
    }
    getFlexSideDropIrrigatedAreaAcres() {
        const areaPolygons = this._getFlexSideIrrigatedAreaPolygons();
        let runningIrrigatedAreaSqm = 0;
        areaPolygons.drop.forEach(p => {
            runningIrrigatedAreaSqm += area(p);
        })
        return convertArea(runningIrrigatedAreaSqm, 'meters', 'acres');
    }
    getFlangedSideMainIrrigatedAreaAcres() {
        const areaPolygons = this._getRigidSideIrrigatedAreaPolygons();
        let runningIrrigatedAreaSqm = 0;
        areaPolygons.mainSystem.forEach(p => {
            runningIrrigatedAreaSqm += area(p);
        })
        return convertArea(runningIrrigatedAreaSqm, 'meters', 'acres');
    }
    getFlangedSideDropIrrigatedAreaAcres() {
        const areaPolygons = this._getRigidSideIrrigatedAreaPolygons();
        let runningIrrigatedAreaSqm = 0;
        areaPolygons.drop.forEach(p => {
            runningIrrigatedAreaSqm += area(p);
        })
        return convertArea(runningIrrigatedAreaSqm, 'meters', 'acres');
    }
    getEndGunIrrigatedAreaAcres() {
        const endGuns = this._calculateEndGuns();

        let flexAcres: {primary: number[], secondary: number[]} = {primary: [], secondary: []};
        endGuns.flex.features.forEach(p => {
            if (p.isPrimary){
                flexAcres.primary.push(convertArea(area(p.feature), 'meters', 'acres'));
            }
            else {
                flexAcres.secondary.push(convertArea(area(p.feature), 'meters', 'acres'));
            }
        });
        
        let rigidAcres: {primary: number[], secondary: number[]} = {primary: [], secondary: []};
        endGuns.rigid.features.forEach(p => {
            if (p.isPrimary){
                rigidAcres.primary.push(convertArea(area(p.feature), 'meters', 'acres'));
            }
            else {
                rigidAcres.secondary.push(convertArea(area(p.feature), 'meters', 'acres'));
            }
        });

        return {flexAcres, rigidAcres};
    }
    getIrrigatedAreaAcres(): number | undefined {
        let endgunAcres = this.getEndGunIrrigatedAreaAcres();
        const areas = [
            this.getFlangedSideMainIrrigatedAreaAcres(),
            this.getFlangedSideDropIrrigatedAreaAcres(),
            this.getFlexSideMainIrrigatedAreaAcres(),
            this.getFlexSideDropIrrigatedAreaAcres(),
            ...endgunAcres.flexAcres.primary,
            ...endgunAcres.flexAcres.secondary,
            ...endgunAcres.rigidAcres.primary,
            ...endgunAcres.rigidAcres.secondary
        ];
        let runningIrrigatedArea = 0;
        areas.forEach(a => {
            runningIrrigatedArea += a as number;
        })
        return runningIrrigatedArea;
    }
    getDrawFeatures(drawMode?: string) {
        if (this.isActive && drawMode === "lateral_select") {
            return this._getDrawFeaturesSelectMode();
        }
        else {
            return this._getDrawFeaturesSimple();
        }
    }
    
    private _getSinglePolygonAndVerticies(): { 
        geometry: Polygon | LineString, 
        verticies: ISpanVertex[], 
        wheelTracks: LineString[],
        flexSidePolygon?: Polygon,
        flangedSidePolygon?: Polygon
    } {
        // console.log("sys", this.system)
        const verticies: ISpanVertex[] = [];
        const feedVerticies: ISpanVertex[] = [];
        const feedLine = this.lateralCenterLine;
        for (let i = 0; i < this.lateralCenterLine.coordinates.length; i++) {
            const handle = point(feedLine.coordinates[i]).geometry;            
            if (
                i === 0 && 
                IsPivotingLateral(this.system) &&
                (this.system.FlangedSide.Span.length && (!this.system.lateral.endPivot || this.system.lateral.startPivot?.retrace))
            ) {
                // dont add pivot end, the startPivot vertex will be used
                // console.log("wont add end feed")
            }
            else if (
                (i === this.lateralCenterLine.coordinates.length - 1) && 
                IsPivotingLateral(this.system) &&
                (this.system.FlangedSide.Span.length && (!this.system.lateral.startPivot || this.system.lateral.endPivot?.retrace))
            ) {
                // dont add pivot start, the endPivot vertex will be used
                // console.log("wont add start feed")
            }
            else {
                feedVerticies.push({
                    type: 'feedLine',
                    handle,
                    vertexIndex: i,
                    pivotIdLabel: IsPivotingLateral(this.system) ? (this.lateralCenterLine.coordinates.length - i).toString() : undefined
                });
            }
        }

        type IrrigatedAreaResult = { irrigatedArea: Polygon, channel?: Polygon[] } | undefined;
        const getIrrigatedAreaPolygonForSide = (side: "flanged" | "flex"): IrrigatedAreaResult => {
            let u: Feature<MultiPolygon | Polygon> | null = null;
            const towers = side === "flex" ? this.directionalFlexSideSpanTowers : this.directionalRigidSideSpanTowers;
            const firstTower = towers[0];
            if (!firstTower) return undefined;
            const insideRadius = firstTower.insideRadius;
            for (let i = towers.length - 1; i >= 0; i--) {
                const spanTower = towers[i];
                if (i === towers.length - 1 || spanTower.isDropSpan) {
                    u = getCombineLateralLinePolygons(
                        spanTower.segments,
                        insideRadius,
                        spanTower.outsideRadius,
                        u
                    )
                }
            }

            let irrigatedArea: Polygon;
            if (!u) {
                console.log("Irrigated area polygon was not formed");
                return undefined;
            }
            
            if (u.geometry.type === 'MultiPolygon') {
                const fc = turf.featureCollection(u.geometry.coordinates.map(p => polygon(p)));
                const d = turf.dissolve(fc);
                if (d.features.length === 1) {
                    irrigatedArea = d.features[0].geometry;
                }
                else {
                    console.log("Irrigated area polygon generated a multipolygon. This multipolygon could not be dissolved", u, d);
                    return undefined;
                }
            }

            if (u.geometry.type === 'Polygon') {
                irrigatedArea = u.geometry;
            }
            return {
                irrigatedArea
            }
        }

        const getChannel = () => {
            if (this.isCanalFeed && this.directionalFlexSideSpanTowers.length && this.directionalRigidSideSpanTowers.length) {
                const leadingFlanged = this.directionalRigidSideSpanTowers[0];
                const leadingFlex = this.directionalFlexSideSpanTowers[0];
                const lineFlanged = getLateralLineString(leadingFlanged.segments, leadingFlanged.insideRadius);
                const lineFlex = getLateralLineString(leadingFlex.segments, leadingFlex.insideRadius);
                return polygon([
                    [
                        ...lineFlanged.coordinates,
                        ...lineFlex.coordinates.slice().reverse(),
                        lineFlanged.coordinates[0]
                    ]
                ])
            }
            return undefined;
        }

        const getVerticiesForSide = (side: "flanged" | "flex") => {
            const towers = side === 'flex'
                ? this.directionalFlexSideSpanTowers
                : this.directionalRigidSideSpanTowers;
            const verticies: ISpanVertex[] = [];

            if (IsPivotingLateral(this.system) && towers.length) {
                const tower = towers[0];
                const insideTrackLineFull = getLateralLineString(this.feedLineSegments, 0);
                const firstSegment = tower.segments.filter((x): x is ILineSegment => x.type === 'line')[0];
                const lastSegment = tower.segments.filter((x): x is ILineSegment => x.type === 'line').slice(-1)[0];
                const b1 = turf.bearing(firstSegment.p2, firstSegment.p1);
                const handleStart = destination(firstSegment.p1, 0, b1, { units: 'feet' });
                const b2 = turf.bearing(lastSegment.p1, lastSegment.p2);
                const handleEnd = destination(lastSegment.p2, 0, b2, { units: 'feet' });
                if (!this.system.lateral.startPivot?.retrace) {
                    verticies.push({
                        type: 'pivotingEnd',
                        handle: handleEnd.geometry,
                        line: insideTrackLineFull,
                        tower,
                        pivotIdLabel: this.system.lateral.endPivot
                            ? "end" 
                            : (this.system.lateral.line.coordinates.length - 1 + 1).toString()
                    });
                }
                if(!this.system.lateral.endPivot?.retrace) {
                    verticies.push({
                        type: 'pivotingStart',
                        handle: handleStart.geometry,
                        line: insideTrackLineFull,
                        tower,
                        pivotIdLabel: this.system.lateral.startPivot
                            ? "start" 
                            : "1"
                    });
                }
            }

            for (let i = 0; i < towers.length; i++) {
                const prevSpanTower = towers[i - 1];
                const spanTower = towers[i];
                if (prevSpanTower?.isDropSpan) {
                    const insideTrackLineFull = getLateralLineString(prevSpanTower.segments, spanTower.insideRadius);
                    if (spanTower.leftLostSegments.length) {
                        const insideTrackLineLeft = getLateralLineString(spanTower.leftLostSegments, spanTower.insideRadius);     
                        verticies.push({
                            type: 'dropSpanStart',
                            handle: point(insideTrackLineLeft.coordinates.slice(-1)[0]).geometry,
                            spanIndex: prevSpanTower.spanTowerIndex,
                            line: insideTrackLineFull,
                            side: side === 'flex' ? "FlexSide" : "FlangedSide"
                        })
                    }
                    else {
                        verticies.push({
                            type: 'dropSpanStart',
                            handle: point(insideTrackLineFull.coordinates[0]).geometry,
                            spanIndex: prevSpanTower.spanTowerIndex,
                            line: insideTrackLineFull,
                            side: side === 'flex' ? "FlexSide" : "FlangedSide"
                        })
                    }
                    if (spanTower.rightLostSegments.length) {
                        const insideTrackLineRight = getLateralLineString(spanTower.rightLostSegments, spanTower.insideRadius); 
                        verticies.push({
                            type: 'dropSpanEnd',
                            handle: point(insideTrackLineRight.coordinates[0]).geometry,
                            spanIndex: prevSpanTower.spanTowerIndex,
                            line: insideTrackLineFull,
                            side: side === 'flex' ? "FlexSide" : "FlangedSide"
                        });
                    }
                    else {
                        verticies.push({
                            type: 'dropSpanEnd',
                            handle: point(insideTrackLineFull.coordinates.slice(-1)[0]).geometry,
                            spanIndex: prevSpanTower.spanTowerIndex,
                            line: insideTrackLineFull,
                            side: side === 'flex' ? "FlexSide" : "FlangedSide"
                        })
                    }
                }
            }

            return {
                verticies
            }            
        }

        const getFlexDropVerticies = () => {
            const flexTower1 = this.directionalFlexSideSpanTowers[0];
            const flangedTower1 = this.directionalRigidSideSpanTowers[0];
            
            const insideFlexTrackLine = getLateralLineString(flexTower1.segments, flexTower1.insideRadius + 0.5 * flexTower1.outsideRadius);
            const insideFlangedTrackLine = getLateralLineString(flangedTower1.segments, flangedTower1.insideRadius);

            const verticies: ISpanVertex[] = [                
                {
                    type: 'flexDropStart',
                    handle: point(insideFlexTrackLine.coordinates[0]).geometry,
                    line: insideFlangedTrackLine
                },
                {
                    type: 'flexDropEnd',
                    handle: point(insideFlexTrackLine.coordinates.slice(-1)[0]).geometry,
                    line: insideFlangedTrackLine
                }
            ];
            return verticies;
        }

        // system area flex side
        let flexSideIrrigatedAreaResult: IrrigatedAreaResult = undefined;
        if (this.hasFlexSide) {
            if (this.hasFlexDrop) {
                if (!this.isFlexDropValid()) {
                    return {
                        geometry: feedLine,
                        verticies: feedVerticies,
                        wheelTracks: this.getWheelTracks()
                    }
                }
                else {
                    verticies.push(...getFlexDropVerticies());
                }
            }
            const flexSideVerticies = getVerticiesForSide('flex');
            verticies.push(...flexSideVerticies.verticies);
            flexSideIrrigatedAreaResult = getIrrigatedAreaPolygonForSide('flex');
        }

        // system area rigid side
        let flangedSideIrrigatedAreaResult: IrrigatedAreaResult = undefined;
        {
            const flangedSideVerticies = getVerticiesForSide('flanged');
            verticies.push(...flangedSideVerticies.verticies);
            flangedSideIrrigatedAreaResult = getIrrigatedAreaPolygonForSide('flanged');
        }

        if (!flangedSideIrrigatedAreaResult) {
            return {
                geometry: feedLine,
                verticies: feedVerticies,
                wheelTracks: this.getWheelTracks()
            }
        }
        
        verticies.push(...feedVerticies);
        if (!flexSideIrrigatedAreaResult) {
            return {
                geometry: flangedSideIrrigatedAreaResult.irrigatedArea,
                verticies,
                wheelTracks: this.getWheelTracks(),
                flangedSidePolygon: flangedSideIrrigatedAreaResult.irrigatedArea
            }
        }
        
        let systemAreaUnion = turf.union(flangedSideIrrigatedAreaResult.irrigatedArea, flexSideIrrigatedAreaResult.irrigatedArea);
        if (flangedSideIrrigatedAreaResult.channel?.length) {
            for (const p of flangedSideIrrigatedAreaResult.channel) {
                systemAreaUnion = turf.union(systemAreaUnion, p);
            }
        }
        if (flexSideIrrigatedAreaResult.channel?.length) {
            for (const p of flexSideIrrigatedAreaResult.channel) {
                systemAreaUnion = turf.union(systemAreaUnion, p);
            }
        }
        const channel = getChannel();
        if (channel) {
            systemAreaUnion = turf.union(systemAreaUnion, channel);
        }
        let systemArea: Polygon | undefined;
        if (!systemAreaUnion || systemAreaUnion.geometry.type !== "Polygon") {
            console.log("systemAreaUnion is not a polygon", systemAreaUnion);
            systemArea = undefined;
        }
        else {
            systemArea = systemAreaUnion.geometry;
        }
        
        return {
            geometry: systemArea,
            verticies,
            wheelTracks: this.getWheelTracks(),
            flexSidePolygon: flexSideIrrigatedAreaResult.irrigatedArea,
            flangedSidePolygon: flangedSideIrrigatedAreaResult.irrigatedArea
        }

    }
    
    // This is without end gun area
    getAreaPolygon(): Polygon | undefined {
        const result = this._getSinglePolygonAndVerticies();
        if (result.geometry.type === 'Polygon') {
            return result.geometry;
        }
        else {
            return undefined;
        }

    }
    
    public static isLateral(feature?: Feature) {
        if (!feature || !feature.properties) return false;
        const isLateral = feature.properties.user_isLateral || feature.properties.isLateral;
        return isLateral === true;
    }
    public static getDefinition(feature?: Feature): IProperties | undefined {
        if (!feature || !feature.properties) return undefined;
        const systemId = feature.properties.user_systemId || feature.properties.systemId;
        const layoutId = feature.properties.user_layoutId || feature.properties.layoutId;
        const isLateral = feature.properties.user_isLateral || feature.properties.isLateral;
        if (!isLateral || !systemId) return undefined;
        return {
            isLateral: true,
            systemId,
            layoutId,
            activeSystem: false
        }
    }    
    public static createSingleGeoJSONFeature(system: ISystem) {

        const helper = new LateralGeometryHelper({
            systemId: "1",
            layoutId: "1",
            project: {
                layouts: {
                    "1": {
                        systems: {
                            "1": system
                        }
                    }
                }
            } as any
        })

        return helper._getSinglePolygonAndVerticies();
    }

    public getExportFeatures(): ImportExportFeature[] {
        const features: ImportExportFeature[] = [];

        // feed line
        features.push(
            feature(
                this.feedCenterLine(),
                {
                    importType: 'feedLine'
                }
            ) as ImportExportFeature
        )

        // canal:
        if (this.isCanalFeed) {
            features.push(
                feature(
                    this.canalCenterLine,
                    {
                        importType: 'canal'
                    }
                )
            )
        }

        // // irrigated area flex side
        {
            let u: Feature<Polygon|MultiPolygon> | null = null;
            const flexSide = this._getFlexSideIrrigatedAreaPolygons();
            for (const ia of [...flexSide.mainSystem, ...flexSide.drop ]) {
                if (u === null) {
                    u = ia;
                }
                else {
                    u = union(u, ia);
                }
            }
            if (u) {
                features.push(
                    feature(
                        u.geometry,
                        {
                            importType: 'flexSideIrrigatedArea'
                        }
                    )
                )
            }
        }

        // // irrigated area rigid side
        {
            let u: Feature<Polygon|MultiPolygon> | null = null;
            const rigidSide = this._getRigidSideIrrigatedAreaPolygons();
            for (const ia of [...rigidSide.mainSystem, ...rigidSide.drop ]) {
                if (u === null) {
                    u = ia;
                }
                else {
                    u = union(u, ia);
                }
            }
            if (u) {
                features.push(
                    feature(
                        u.geometry,
                        {
                            importType: 'rigidSideIrrigatedArea'
                        }
                    )
                )
            }
        }

        // // end guns:
        const endGuns = this._calculateEndGuns();
        const egfeatures: Feature<Polygon>[] = [
            ...endGuns.flex.features.map(x => x.feature),
            ...endGuns.rigid.features.map(x => x.feature)
        ];     

        for (const eg of egfeatures) {
            const p = polygon<ImportExportFeatureProperties>(
                eg.geometry.coordinates, {
                    importType: 'endGunIrrigatedArea'
                }
            )
            features.push(p);
        }

        console.log("fe", features)

        return features;
    }

    
    private getWheelTracks(): LineString[] {
        const detailed = this.getWheelTracksDetailed();
        const tracks: LineString[] = [];
        if (detailed.flexSideStart) {
            tracks.push(detailed.flexSideStart);
        }
        tracks.push(...detailed.flexSide);
        tracks.push(detailed.rigidSideStart);
        tracks.push(...detailed.rigidSide);

        return tracks;
    }
    
    private getWheelTracksDetailed() {
        let flexSideStart: LineString | undefined = undefined;
        const flexSide: LineString[] = [];
        let rigidSideStart: LineString | undefined = undefined;
        const rigidSide: LineString[] = [];
        
        // system area flex side
        if (this.hasFlexSide) {
            flexSideStart = this.flexSideCenterLine();
            for (const spanTower of this.directionalFlexSideSpanTowers) {
                // dont add wheel track if end boom
                if (!spanTower.isEndBoom) {
                    const trackLine = getLateralLineString(spanTower.segments, spanTower.outsideRadius);
                    flexSide.push(trackLine);
                }
            }
        }

        // system area rigid side
        {
            rigidSideStart = this.feedCenterLine();
            for (const spanTower of this.directionalRigidSideSpanTowers) {
                // dont add wheel track if end boom
                if (!spanTower.isEndBoom) {
                    const trackLine = getLateralLineString(spanTower.segments, spanTower.outsideRadius);
                    rigidSide.push(trackLine);
                }
            }
        }

        return {
            flexSideStart, flexSide,
            rigidSideStart, rigidSide
        };
    }
}


export const lateralLineOffset = (
    line: LineString | Feature<LineString>, distance: number, options: { units: turf.Units, arc?: "clockwise" | "anticlockwise" | "natural", debug?: boolean } = { units: 'kilometers' }
): Feature<LineString> => {

    const incommingLine = line.type === 'Feature' 
        ? line.geometry
        : line;

    if (distance === 0) {
        return lineString(incommingLine.coordinates);
    };

    const coords: Position[] = [];

    const segments: { line: LineString, offset: LineString }[] = [];
    for (let i = 0; i < incommingLine.coordinates.length - 1; i++) {
        const c1 = incommingLine.coordinates[i];
        const c2 = incommingLine.coordinates[i+1];
        const ib = turf.bearing(c1, c2) + 90;
        const p1 = destination(c1, distance, ib, { units: options.units });
        const p2 = destination(c2, distance, ib, { units: options.units });
        segments.push({
            line: lineString([ c1, c2 ]).geometry,
            offset: lineString([ p1.geometry.coordinates, p2.geometry.coordinates ]).geometry
        });
    }

    coords.push(segments[0].offset.coordinates[0]);
    for (let i = 0; i < segments.length - 1; i++) {
        const s1 = segments[i];
        const s2 = segments[i + 1];
        const pc = s1.line.coordinates[1];
        const bLeft = turf.bearing(pc, s1.offset.coordinates[1], { final: true });
        const bRight = turf.bearing(pc, s2.offset.coordinates[0], { final: true });

        let arcOption = options.arc;
        // if (!arcOption || arcOption === 'natural') {
        //     const [ xc, yc ] = pc;
        //     const [ x1, y1 ] = s1.offset.coordinates[1];
        //     const [ x2, y2 ] = s2.offset.coordinates[0];
        //     const a1 = Math.atan2(xc - x2, yc - y2) / Math.PI * 180;
        //     const a2 =  Math.atan2(x1 - xc, y1 - yc) / Math.PI * 180;
        //     const a = Math.atan2(xc - x2, yc - y2) - Math.atan2(x1 - xc, y1 - yc);
        //     if (options.debug) console.log("a", a / Math.PI * 180, a1,a2)
        //     if (a < Math.PI && a > 0) {
        //         arcOption = 'clockwise';
        //     }
        //     else if (a > -Math.PI && a < 0) {
        //         arcOption = 'anticlockwise';
        //     }
        // }
        switch (arcOption) {
            case 'clockwise': {
                const arc = FeatureHelpers.GetLineArcDrawFeature(
                    point(pc).geometry, distance, bRight, bLeft, null, { units: options.units, degreeIncrement: 0.1 }
                )
                coords.push(...arc.geometry.coordinates.reverse());
                break;
            }
            case 'anticlockwise': {
                const arc = FeatureHelpers.GetLineArcDrawFeature(
                    point(pc).geometry, distance, bLeft, bRight, null, { units: options.units, degreeIncrement: 0.1 }
                )
                coords.push(...arc.geometry.coordinates);
                break;
            }
            default: {
                const inter = turf.lineIntersect(s1.offset, s2.offset);
                if (!inter.features.length) {
                    coords.push(s1.offset.coordinates[1], s2.offset.coordinates[0]);
                }
                else {
                    if (inter.features.length !== 1) {
                        throw new Error("too many intersections");
                    }
                    coords.push(inter.features[0].geometry.coordinates);
                }
                break;
            }
        }
    }
    coords.push(segments.slice(-1)[0].offset.coordinates[1]);
    
    const f = lineString(coords);
    
    return f;

}

const getLateralLinePolygons = (
    segments: IlateralSegment[], 
    insideRadius: number, outsideRadius: number,
    exclude: Feature<Polygon | MultiPolygon> | null = null
): { polygons: Polygon[], updatedExclude:  Feature<Polygon | MultiPolygon> | null } => {

    const polygons: Polygon[] = [];
    let updatedExclude: Feature<Polygon | MultiPolygon> | null = exclude;

    const considerPolygon = (poly: Feature<Polygon>) => {
        const d = updatedExclude ? customDifference(poly, updatedExclude) : poly;
        if (d) {
            try {
                updatedExclude = updatedExclude ? customUnion(updatedExclude, d) : d;
            }
            catch (e) {
                console.log(">>", updatedExclude, d)
                throw e;
            }
            if (d.geometry.type === 'Polygon') {
                polygons.push(d.geometry);
            }
            else {
                for (const p of d.geometry.coordinates) {
                    if (p.length && p[0].length > 4) {
                        // only add valid polygons
                        polygons.push(polygon(p).geometry);
                    }
                }
            }
        }
    }
    
    for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        const iPositions = getSegmentLinePositions(segment, insideRadius);
        const oPositions = getSegmentLinePositions(segment, outsideRadius);
        const poly = polygon([
            [
                ...iPositions,
                ...oPositions.reverse(),
                iPositions[0]
            ]
        ]);
        considerPolygon(poly);
    }
    return {
        polygons,
        updatedExclude
    };

}

export const getLateralLineString = (
    segments: IlateralSegment[], 
    radius: number
): LineString => {

    const positions: Position[] = [];
    
    for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        const oPositions = getSegmentLinePositions(segment, radius);
        positions.push(...oPositions);
    }

    return lineString(positions).geometry;

}


export const getCombineLateralLinePolygons = (
    segments: IlateralSegment[], 
    insideRadius: number, outsideRadius: number,
    combined: Feature<Polygon | MultiPolygon> | null = null
): Feature<Polygon | MultiPolygon> | null => {

    let updatedCombined: Feature<Polygon | MultiPolygon> | null = combined;

    const considerPolygon = (poly: Feature<Polygon | MultiPolygon>) => {
        try {
            updatedCombined = updatedCombined ? customUnion(updatedCombined, poly) : poly;
        }
        catch (e) {
            console.log(">>", updatedCombined, poly)
            throw e;
        }
    }
    
    const polys: Position[][][] = [];
    for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        // the opposing line segmnets when retracing cause artifacts in the
        // mapbox rendered polygon. As such, if we are retracing and the
        // inside radius is 0. Extend this inside the pivot slightly using -1.
        const ir = (insideRadius === 0 && segment.retracing && segment.type === 'line') ? -1 : insideRadius;
        const iPositions = getSegmentLinePositions(segment, ir);
        const oPositions = getSegmentLinePositions(segment, outsideRadius);
        polys.push([
            [
                ...iPositions,
                ...oPositions.reverse(),
                iPositions[0]
            ]
        ])
    }
    // The seams can sometimes cause problems with union:
    for (let i = 0; i < segments.length - 1; i++) {
        const segment1 = segments[i];
        const segment2 = segments[i + 1];
        const iSegment1Positions = getSegmentLinePositions(segment1, insideRadius);
        const oSegment1Positions = getSegmentLinePositions(segment1, outsideRadius);
        const iSegment2Positions = getSegmentLinePositions(segment2, insideRadius);
        const oSegment2Positions = getSegmentLinePositions(segment2, outsideRadius);
        polys.push([ 
            [
                iSegment1Positions.slice(-1)[0], 
                iSegment2Positions[0], 
                oSegment2Positions[0], 
                oSegment1Positions.slice(-1)[0], 
                iSegment1Positions.slice(-1)[0], 
            ]
        ]);
    }
    if (polys.length) {
        considerPolygon(turf.multiPolygon(polys));
    }
    return updatedCombined;
}

const trimAndMutateSegments = (combinedSegments: IlateralSegment[], start: number, end: number, runningLength: number) => {
    const leftLostSegments: IlateralSegment[] = [];
    const rightLostSegments: IlateralSegment[] = [];
    if (start === 0 && end === 0) {
        return { leftLostSegments, rightLostSegments };
    }
    let trimStart = start;
    while (combinedSegments.length && trimStart >= getSegmentLength(combinedSegments[0], runningLength)) {
        trimStart -= getSegmentLength(combinedSegments[0], runningLength);
        leftLostSegments.push(...combinedSegments.splice(0, 1));
    }
    if (combinedSegments.length && trimStart) {
        const startSegment = combinedSegments[0];
        if (startSegment.type === 'line') {
            const segmentCopy = structuredClone(startSegment);
            const newLine = getSegmentLine_Line(startSegment, 0, { trimStart });
            startSegment.p1 = newLine[0];
            segmentCopy.p2 = newLine[0];
            leftLostSegments.push(segmentCopy);
        }
        else {
            const segmentCopy = structuredClone(startSegment);
            const newLine = getSegmentLine_Pivot(startSegment, runningLength, { trimStart });
            const p = newLine[0];
            startSegment.b1 = turf.bearing(startSegment.center, p, { final: true });
            segmentCopy.b2 = startSegment.b1;
            leftLostSegments.push(segmentCopy);
        }
    }
    
    let trimEnd = end;
    while (combinedSegments.length && trimEnd >= getSegmentLength(combinedSegments.slice(-1)[0], runningLength)) {
        trimEnd -= getSegmentLength(combinedSegments.slice(-1)[0], runningLength);
        rightLostSegments.push(...combinedSegments.splice(-1, 1));
    }
    if (combinedSegments.length && trimEnd) {
        const endSegment = combinedSegments.slice(-1)[0];
        if (endSegment.type === 'line') {
            const segmentCopy = structuredClone(endSegment);
            const newLine = getSegmentLine_Line(endSegment, 0, { trimEnd });
            endSegment.p2 = newLine[1];
            segmentCopy.p1 = newLine[1];
            rightLostSegments.push(segmentCopy);
        }
        else {
            const segmentCopy = structuredClone(endSegment);
            const newLine = getSegmentLine_Pivot(endSegment, runningLength, { trimEnd });
            const p = newLine.slice(-1)[0];
            endSegment.b2 = turf.bearing(endSegment.center, p, { final: true });
            segmentCopy.b1 = endSegment.b2;
            rightLostSegments.push(segmentCopy);
        }
    }
    return { leftLostSegments, rightLostSegments };
}