import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, SelectChangeEvent, Stack } from "@mui/material";
import Select from '@mui/material/Select';
import { EndOfSystemTypes, SwingArmLengths } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { getSwingArmLengths } from "../../reinkeComponentConversions/swingArmLengths";
import { disabledColor, pageStyles } from "../roe/styles";
import { EndOfSystemOptimizationType_Type } from "./EndOfSystemOptimizationType_Type";
import { RestrictSpanLengthsControl } from "./RestrictSpanLengthsControl";

interface Props {
    endOfSystemType?: EndOfSystemTypes;
    restrictedEndBoomLengths: number[];
    sacType?: SwingArmLengths;
    onChangeEndOfSystem: (args: {
        endOfSystemType?: EndOfSystemTypes, 
        restrictedEndBoomLengths: number[], 
        sacType?: SwingArmLengths,
        sacIsLeading?: boolean;
    }) => void;
    canHaveSac: boolean;
    disabled?: boolean;
    sacIsLeading?: boolean;

    showTypeChoice?: boolean
}

export const EndOfSystemOptimizationType: React.FC<Props> = ({
    endOfSystemType, restrictedEndBoomLengths, sacType, onChangeEndOfSystem, canHaveSac, disabled = false, sacIsLeading = false,
    showTypeChoice = true
}) => {
    const settings = React.useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;

    const handleChangeSacType = (event: SelectChangeEvent<string>) => {
        onChangeEndOfSystem({ 
            restrictedEndBoomLengths: [],
            endOfSystemType: EndOfSystemTypes.SAC,
            sacType: event.target.value as SwingArmLengths,
            sacIsLeading
        })
    };
    const handleChangeSacIsLeading = (sacIsLeading: boolean) => {
        onChangeEndOfSystem({ 
            restrictedEndBoomLengths: [],
            endOfSystemType: EndOfSystemTypes.SAC,
            sacType,
            sacIsLeading
        })
    }

    let formInputLabelStyle = {
        ...pageStyles.formInputLabelStyle
    };
    if (disabled) {
        formInputLabelStyle['color'] = disabledColor;
    }
    
    return (        
        <Stack>
        
            {
                showTypeChoice && (
                    <EndOfSystemOptimizationType_Type
                        endOfSystemType={endOfSystemType}
                        restrictedEndBoomLengths={restrictedEndBoomLengths}
                        sacType={sacType}
                        onChangeEndOfSystem={onChangeEndOfSystem}
                        canHaveSac={canHaveSac}
                        disabled={disabled}
                    />
                )
            }
            

            {
                !disabled && endOfSystemType === EndOfSystemTypes.EndBoom && (
                    <RestrictSpanLengthsControl
                        restrictSpanLengths={true}
                        restrictedSpanLengths={restrictedEndBoomLengths.map(x => ({ spanLength: x, extension: false, totalLength: x}))}
                        onChangeRestrictedSpanLengths={(x) => {
                            onChangeEndOfSystem({
                                endOfSystemType: EndOfSystemTypes.EndBoom,
                                restrictedEndBoomLengths: x.map(y => y.spanLength)
                            })
                        }}
                        options={{
                            type: "End Boom"
                        }}
                    />
                )
            }
            

            {
                !disabled && endOfSystemType === EndOfSystemTypes.SAC && (
                    <Stack direction={'row'} spacing={1}>
                        <FormControl variant="standard" sx={{ flexGrow: 1 }}>        
                            <InputLabel shrink={true} style={{...formInputLabelStyle}}  id="swing-arm-select-label">Swing Arm Length</InputLabel>
                            <Select
                                labelId="span-select-label"
                                id="swing-arm-select"
                                value={sacType === undefined ? "" : sacType}
                                onChange={handleChangeSacType}
                            >
                                {
                                    getSwingArmLengths(displaySettings.lengths).map(x => (
                                        <MenuItem key={x.originalValue} value={x.originalValue}>                          
                                            {x.label}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        
                        <FormControl sx={{ justifyContent: 'flex-end' }}>
                            <RadioGroup
                                name="leading-or-trailing"
                                value={sacIsLeading === true ? "leading" : "trailing"}
                                onChange={(ev) => {
                                    handleChangeSacIsLeading(ev.target.value === 'leading');
                                }}
                                row
                            >
                                <FormControlLabel value="trailing" control={<Radio />} label="Trailing" />
                                <FormControlLabel value="leading" control={<Radio />} label="Leading" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                )
            }
        </Stack>
    )
}