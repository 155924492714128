import CSection2 from "./CSection2";
import PartsAssembler from "./PartsAssembler";
import QuoteClass from "./QuoteClass";
import { SprinklersChartSideClass } from "./SprinklersChartSideClass";
import * as sysf from "./SystemFunctions";
export class SprinklersChartClass extends CSection2 {
    public RightSprinklersClass: SprinklersChartSideClass;
    public LeftSprinklersClass: SprinklersChartSideClass;
/*public ResetData = () => {
if (sysf.FieldSets(this.Quote.System).SprinklerConfig.DataValid()) {
if (this.Quote.System.SprinklerProperties.VRIZones.Count > 1) {
MsgBox(strLanguageText("sltVRIReset"));
this.Quote.System.SprinklerProperties.VRIZones.Count = 0;
}
}
super.ResetData();
}
*//*public Compute = (): boolean => {
try {this.RightSprinklersClass.PrepData();
this.LeftSprinklersClass.PrepData();
let ser: SprinklerEngineRunner;
let res: SprinklerEngineResult = ser.BuildSprinklerPackage();
switch (res.RunResult) {
case RunResults.Success:
let vz: VRIZones = this.Quote.System.SprinklerProperties.VRIZones;
if (vz.Count > 1) {
MsgBox(strLanguageText("sltVRIReset"));
vz.Count = 0;
}
return true;
break;
case RunResults.UserStop:
sysf.FieldSets(this.Quote.System).RollbackEdit();
AdvMsgBox(null, res.Message, MsgBoxStyle.Information, "Sprinkler Engine: User Cancelled Operation", false);
return false;
break;
case RunResults.Failure:
sysf.FieldSets(this.Quote.System).RollbackEdit();
AdvMsgBox(null, res.Message, MsgBoxStyle.Critical, "Sprinkler Engine: Critical Errors", false);
return false;
break;
default:
// new Exception()
throw new Error();
break;
}
}
catch (ex) {
MessageBox.Show(ex.Message, "Property Out Of Range", MessageBoxButtons.OK, MessageBoxIcon.Information);
sysf.FieldSets(this.Quote.System).RollbackEdit();
return false;
}
return true;
}
*/public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/
        this.RightSprinklersClass.DataOK(WithWarnings);
        this.LeftSprinklersClass.DataOK(WithWarnings);
    }
/*public AssembleWarnings = () => {
this.RightSprinklersClass.AssembleWarnings();
this.LeftSprinklersClass.AssembleWarnings();
if (sysf.IsDualSided(this.Quote.System)) {
if (this.Quote.System.FlexSide.SprinklerChart.IrrigationProperties.CoverageRadiusWithEndGun > this.Quote.System.FlangedSide.SprinklerChart.IrrigationProperties.CoverageRadiusWithEndGun) {
this.Quote.Warnings.Add(strLanguageText("sltFlexCantBeLongerThanFlanged"), WarningLevels.SectionBlock);
}
}
}
*/public WritePO = (PO: PartsAssembler) => {
        this.RightSprinklersClass.WritePO(PO);
        if (sysf.IsDualSided(this.Quote.System)) {
            this.LeftSprinklersClass.WritePO(PO);
        }
    }
   
    constructor(pQuote: QuoteClass) {
        super();
        this.Quote = pQuote;
    this.RightSprinklersClass = new SprinklersChartSideClass();
    this.LeftSprinklersClass = new SprinklersChartSideClass();
    this.RightSprinklersClass.Init(this.Quote, true);
    this.LeftSprinklersClass.Init(this.Quote, false);
    this.RightSprinklersClass.TowersClass = this.Quote.RightTowersClass;
    this.RightSprinklersClass.Side = pQuote.System.FlangedSide;
    this.LeftSprinklersClass.TowersClass = this.Quote.LeftTowersClass;
    this.LeftSprinklersClass.Side = pQuote.System.FlexSide;
    }
}
