import RDPProposalIcon from '@mui/icons-material/InsertDriveFile';
import Straighten from "@mui/icons-material/Straighten";
import Undo from "@mui/icons-material/Undo";
import { IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import i18next, { t } from "i18next";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createClearMeasurementsAction } from "../../../../actions/ClearMeasurementsAction";
import { createNewUndoAction } from "../../../../actions/UndoAction";
import { createNewUpdateProjectPropertyAction } from "../../../../actions/UpdateProjectProperty";
import AuthCtx from "../../../../auth/AuthCtx";
import FieldInformationAppBarItem from "../../../../components/FieldInformationAppBarItem";
import RdpAppBar from "../../../../components/RdpAppBar";
import DbCtx from "../../../../db/DbCtx";
import { ProjectState } from "../../../../model/project/IProject";
import { IInteractionState, Interaction } from '../interactionState';
import { ISelectedItem } from '../selectedItem';
import ClearMeasurementIcon from "./ClearMeasurementIcon";
import FieldSettingsMenu from "./FieldSettingsMenu";
import ImportMenu from "./ImportMenu";
import LayoutsMenu from "./LayoutsMenu";
import ProjectsMenu from "./ProjectsMenu";
import SystemSelectDialog from "./SystemSelectDialog";
import SystemsMenu from "./SystemsMenu";

interface Props {
    projectId: string;
    layoutId: string;
    interactionState: IInteractionState;
    setInteractionState: (interactionState: IInteractionState) => void;
    selectedItem: ISelectedItem;
    setSelectedItem: (item: ISelectedItem) => void;
    autoNavigateToAnotherLayout: (nextLayoutId?: string) => void;
}

const LayoutMapAppBar: React.FC<Props> = ({
    projectId, layoutId,
    interactionState, setInteractionState,
    selectedItem, setSelectedItem,
    autoNavigateToAnotherLayout
}) => {

    const authState = useContext(AuthCtx);
    const dbState = useContext(DbCtx);
    const navigate = useNavigate();

    const dbProject = dbState.projects[projectId];
    const project = dbProject.state;
    const layout = project.layouts[layoutId];

    const [fieldReportMenuTarget, setFieldReportMenuTarget] = React.useState<null | HTMLElement>(null);
    const openFieldReportMenu = (event: React.MouseEvent<HTMLElement>) => {
        setFieldReportMenuTarget(event.currentTarget);
    };
    const closeFieldReportMenu = () => {
        setFieldReportMenuTarget(null);
    };

    const [rdpProposalType, setRdpProposalType] = useState<"simple" | "full" | null>(null);
    const [systemSelectDialogOpen, setSystemSelectDialogOpen] = useState(false);

    
    // START: Systems logic:
    const navigateOnSystem = (systemType: SystemTypes) => {
        if (systemType === SystemTypes.CenterPivot) {
            setInteractionState({ interactionType: Interaction.CenterPivotSelect });
        }
        else if (systemType === SystemTypes.CanalFeedMaxigator || systemType === SystemTypes.HoseFeedMaxigator) {
            setInteractionState({ interactionType: Interaction.LateralSelect });
        }
    }
    // END: Systems logic:

    const navigateToRDPProposal = (systemIds: string[]) => {
        if (rdpProposalType !== null) {
            let url = `../systems/rdpproposal/${rdpProposalType}`;
            navigate(`${url}/${systemIds.join('|')}`);
            setRdpProposalType(null);
        }
    }
    
    // START: Projects logic (aka. fields)
    const growersActiveProjects = Object.entries(dbState.projects)
        .filter(([otherProjectId, otherDbProject]) => {
            return (
                otherDbProject.state.growerId === project.growerId &&
                otherDbProject.state.projectState === ProjectState.Active
            )
        })
        .map(([otherProjectId, otherDbProject]) => {
            return {
                projectId: otherProjectId,
                project: otherDbProject.state
            }
        })
        .sort((a, b) => a.project.name.localeCompare(b.project.name));
    const onDeleteProject = () => {
        const nextProject = growersActiveProjects.find(x => x.projectId !== projectId);
        const growerId = dbProject.state.growerId;
        dbProject.pushAction(createNewUpdateProjectPropertyAction("projectState", ProjectState.Deleted, authState));
        if (nextProject === undefined) {
            navigate("/projects?grower="+ growerId);
        }
        else {
            navigateToAnotherProject(nextProject.projectId);
        }
    }
    const navigateToAnotherProject = (nextprojectId: string) => {
        setInteractionState({ interactionType: Interaction.SimpleSelect });
        setSelectedItem({ item: 'none' });
        navigate(`/projects/${nextprojectId}`)
    }
    // END: Projects logic (aka. fields)

    
    // START: Layouts logic:
    const navigateToAnotherLayout = (nextLayoutId: string) => {
        setSelectedItem({ item: 'none' });
        setInteractionState({ interactionType: Interaction.SimpleSelect });
        navigate(`/projects/${projectId}/layouts/${nextLayoutId}/map`);
    }
    // END: Layouts logic:

    const ns = "map-display.";

    const rightContet: JSX.Element[] = [];

    rightContet.push(
        <Tooltip title={t("nav.undo")} placement="bottom">
            <IconButton
                disabled={![ Interaction.SimpleSelect, Interaction.LateralSelect, Interaction.CenterPivotSelect ].includes(interactionState.interactionType)}
                size="large"
                aria-label="undo"
                onClick={async () => {
                    const updatedDbPrj = await dbProject.pushAction(createNewUndoAction(authState));
                    if (!updatedDbPrj.layouts[layoutId]) {
                        navigate(`/projects/${projectId}`);
                    }
                }}
                color="inherit"
            >
                <Undo />
            </IconButton>
        </Tooltip>);

    if(layoutId && projectId){
        rightContet.push(<ImportMenu 
            dbPrj={dbProject} 
            layoutId={layoutId} 
            grower={dbState.growers[project.growerId]}
            projectId={projectId}
        />);
    }
    rightContet.push(
        <Tooltip title={i18next.format(`${i18next.t("measure")}`, 'capitalize')} placement="bottom">
            <IconButton
                size="large"
                aria-label="measure"
                onClick={() => {
                    setInteractionState({ interactionType: Interaction.Measure });
                }}
                color="inherit"
                disabled={interactionState.interactionType !== Interaction.SimpleSelect}
            >
                <Straighten />
            </IconButton>
        </Tooltip>
    );
    rightContet.push(
        <Tooltip title={i18next.format(`${i18next.t(ns + "clear-measurements")}`, 'capitalize')} placement="bottom">
            <IconButton
                disabled={!layout.measurements.length || interactionState.interactionType !== Interaction.SimpleSelect}
                size="large"
                aria-label="clear-measurements"
                onClick={() => {
                    dbProject.pushAction(
                        createClearMeasurementsAction(layoutId, authState)
                    )
                }}
                color="inherit"
            >
                <ClearMeasurementIcon disabled={!layout.measurements.length || interactionState.interactionType !== Interaction.SimpleSelect} />
            </IconButton>
        </Tooltip>);

    rightContet.push(    
        <div>
            <Tooltip title={i18next.format(`${i18next.t(ns + "field-report")}`, 'capitalize')} placement="bottom">
                <IconButton
                    size="large"
                    aria-label="general-field-report"
                    onClick={openFieldReportMenu}
                    color="inherit"
                >
                    <RDPProposalIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={fieldReportMenuTarget}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={!!fieldReportMenuTarget}
                onClose={closeFieldReportMenu}
            >
                <MenuItem onClick={() => {closeFieldReportMenu(); setRdpProposalType("simple"); setSystemSelectDialogOpen(true);}}>{t("reports.general-print-out")}</MenuItem>
                <MenuItem onClick={() => {closeFieldReportMenu(); setRdpProposalType("full"); setSystemSelectDialogOpen(true);}}>{t("reports.full-field-report")}</MenuItem>
            </Menu>
        </div>
        );

    rightContet.push(<FieldSettingsMenu dbPrj={dbProject} />);

    return (
        <>
            <RdpAppBar
                onClose={() => navigate("/projects?grower=" + project.growerId)}
                leftContent={
                    <>
                        <Typography variant="h6" component="div" px={2} style={{fontSize: '0.875rem', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {dbState.growers[project.growerId].name}
                        </Typography>

                        <ProjectsMenu
                            projectId={projectId!}
                            navigateToAnotherProject={navigateToAnotherProject}
                            onDeleteProject={onDeleteProject}
                            growersActiveProjects={growersActiveProjects}
                        />

                        <LayoutsMenu
                            layoutId={layoutId!}
                            projectId={projectId!}
                            navigateToAnotherLayout={navigateToAnotherLayout}
                            autoNavigateToAnotherLayout={autoNavigateToAnotherLayout}
                        />

                        <SystemsMenu
                            layoutId={layoutId!}
                            projectId={projectId!}
                            navigateOnSystem={navigateOnSystem}
                            selectedSystemId={selectedItem.item === 'system' ? selectedItem.id : undefined}
                        />
                        
                        <FieldInformationAppBarItem 
                            project={project}
                            layoutId={layoutId}
                        />
                    </>
                }
                rightContent={rightContet}
            />
            
            <SystemSelectDialog 
                open={systemSelectDialogOpen}
                onClose={() => {setRdpProposalType(null); setSystemSelectDialogOpen(false);}}
                layoutId={layoutId!}
                project={dbProject}
                onConfirm={(args) => {
                    navigateToRDPProposal(args.systemIds);
                }}
            />
        </>
    );
}


export default LayoutMapAppBar;