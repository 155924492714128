import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordProjectChange } from './helpers';

const actionTypeId = "DeleteLayout";

interface ActionData extends IActionData {
    layoutId: string;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    if (state.layouts[data.layoutId]) {
        delete state.layouts[data.layoutId];
    }

    recordProjectChange(action, state);
});

export const deleteLayoutAction = (authState: IAuthState, layoutId: string) => {
    const action = createAction(
        actionTypeId,
        {
            layoutId
        } as ActionData,
        authState);
    return {
        action
    };
};