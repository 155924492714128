import { Text, View } from "@react-pdf/renderer";
import * as React from "react";
import { FC } from "react";
import VRI_Zones from "roedata/roe_migration/VRIClass.VRI_Zones";
import { documentStyles } from "../styles";
import FixedWidthTable, { IRow } from "./table/FixedWidthTable";

export interface Props {
    vriZones: VRI_Zones;
}

const VRIValveBoxesSection: FC<Props> = (props) => {
    let {vriZones} = props;

    let rows: IRow[] = [{cells: [{
        text: "Valve Box",
        header: true,
        widthPc: 15
    },
    {
        text: "First Zone",
        header: true,
        widthPc: 15
    },
    {
        text: "Last Zone",
        header: true,
        widthPc: 15
    },
    {
        text: "Tower",
        header: true,
        widthPc: 15
    },
    {
        text: "Tower Location",
        header: true,
        widthPc: 20
    },
    {
        text: "Tubing Required",
        header: true,
        widthPc: 20
    }]}];

    for (let idx = 1; idx <= vriZones.ValveBoxCount; idx++){
        let box = vriZones.ValveBoxes.Get()[idx];
        rows.push({cells: [{
            text: (idx).toFixed(),
            widthPc: 15
        },
        {
            text: (box.StartZone).toFixed(),
            widthPc: 15
        },
        {
            text: (box.EndZone).toFixed(),
            widthPc: 15
        },
        {
            text: (box.Tower).toFixed(),
            widthPc: 15
        },
        {
            text: box.TowerLocation.toFixed(),
            widthPc: 20
        },
        {
            text: box.TubingLength.toFixed(),
            widthPc: 20
        }]});
    }

    return (<View>
            <View style={documentStyles.heading}>
                    <Text style={documentStyles.sectionHeader}>VRI Valve Boxes</Text>
            </View>
            <FixedWidthTable
                data={{rows}}
            />
        </View>
    );
};

export default VRIValveBoxesSection;