import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordProjectChange } from './helpers';

const actionTypeId = "ClearMeasurementsAction";

interface ActionData extends IActionData {
    layoutId: string;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const layout = state.layouts[data.layoutId];
    if (!layout) return;
    layout.measurements = []
    recordProjectChange(action, state);
});

export const createClearMeasurementsAction = (layoutId: string, authState: IAuthState) => {
    return createAction(
        actionTypeId,
        {
            layoutId
        } as ActionData,
        authState);
};