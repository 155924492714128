import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useContext } from "react";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import DealerDataCtx from "../../../userData/DealerDataCtx";
import { primaryColor } from "../../styles";

interface IProps {
}

const DealerInformationLine: React.FC<IProps> = ({

}) => {
    const { dealership: dealerInfo } = useContext(DealerDataCtx);
    const devSettingsState = useContext(DevSettingsCtx);
    const lineArt = new URL("../../../docGeneration/assets/pivotLineArt.png", import.meta.url);
    const dealerLogo = devSettingsState.dealerSettings.logo.current ? devSettingsState.dealerSettings.logo.current.logo : lineArt.toString();

    let parts: string[] = [];
    if (dealerInfo.city) parts.push(dealerInfo.city);
    if (dealerInfo.state) parts.push(`${dealerInfo.state} ${dealerInfo.zip}`);
    if (dealerInfo.country) parts.push(dealerInfo.country);

    return <Box style={{width: "100%", position: "absolute", bottom: 0}}>
        <Box style={{display: "flex", flexDirection: "row", paddingLeft: 15, paddingRight: 15, marginBottom: 10}}>
            <Box style={{flex: 1}}></Box>
            <Box style={{flex: 2, display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Typography fontWeight="bold" fontSize={14}>{dealerInfo.name}</Typography>
                <Typography fontSize={14}>{dealerInfo.addressLine1} • {parts.join(",")}</Typography>
                <Typography fontSize={14}>{dealerInfo.telephone}</Typography>
            </Box>
            <Box style={{flex: 1, textAlign: "center"}}>
                <img src={dealerLogo.toString()} style={{ maxHeight: 60}} />
            </Box>
        </Box>
        <Box style={{backgroundColor: primaryColor, height: 20}}></Box>
    </Box>
};

export default DealerInformationLine;
