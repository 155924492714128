import { Document, Page, Text, View } from '@react-pdf/renderer';
import * as React from "react";
import { FC } from 'react';
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { configurationValid, customPricingApplied } from '../../components/roe/componentRenderers/Proposal/ProposalHelpers';
import ISystem from '../../model/project/ISystem';
import { IDocumentType } from '../DocumentGenerationHelpers';
import DocumentFirstPageHeader from '../components/DocumentFirstPageHeader';
import ElectricalMotorInformationTable from '../components/ElectricalMotorInformationTable';
import ElectricalSystemInformationTable from '../components/ElectricalSystemInformationTable';
import { documentStyles } from '../styles';

interface Props {
    system: ISystem;
}

const PAGE_SIZE = "LETTER";

const ElectricalReport: FC<Props> = (props) => {
    const {system} = props;
    const quote = new QuoteClass(system);

    return (
        <>
            <Document>
                <Page size={PAGE_SIZE} style={documentStyles.page}>
                    <DocumentFirstPageHeader documentType={IDocumentType.electricalReport}/>
                    {
                        customPricingApplied(system) && <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>CUSTOM PRICING APPLIED</Text>
                        </View>
                    }
                    {                    
                        !configurationValid(system) && <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>INCOMPLETE PROPOSAL</Text>
                        </View>
                    }
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        <ElectricalSystemInformationTable quote={quote}/>
                        <ElectricalMotorInformationTable quote={quote} />
                    </View>
                    <View fixed style={documentStyles.firstPageFooter}/>
                </Page>
            </Document>
        </>
    );
};

export default ElectricalReport;