import { RunResults, WarningSeverity } from "./Enums";
import { SprinklerEngineWarning } from "./SprinklerEngineWarning";

export class SprinklerEngineResult {
    constructor() {
        this._warnings = [];
    }

    public get RunResult() {
        if (this.IsUserStop) {
            return RunResults.UserStop;
        }
        if (this.IsCritical) {
            return RunResults.Failure;
        }
        return RunResults.Success;
    }

    public get Warnings() {
        return this._warnings;
    }

    private _warnings: SprinklerEngineWarning[];
    public get IsCritical() {
        if (!!this.Warnings && this.Warnings.some((w) =>
            w.WarningSeverity === WarningSeverity.Critical)) {
            return true;
        }
        return false;
    }

    public get IsUserStop() {
        if (!!this.Warnings && this.Warnings.some((w) =>
            w.WarningSeverity === WarningSeverity.UserStop)) {
            return true;
        }
        return false;
    }

    public get Message() {
        return "";
        /* TODO
        if (!this.Warnings || this.Warnings.length === 0) {
            return "";
        }
        let sb: StringBuilder;
        this.Warnings.OrderBy((o) =>
            o.WarningSeverity, new SortClass()).Take(6).ForEach((e) =>
                sb.AppendLine(strLanguageText(e.Message))
);
        if (this.Warnings.Count > 6) {
            sb.AppendLine(strLanguageText($"... and {Warnings.Count - 6} more warnings."));
        }
        return sb.ToString();*/
    }


}

/*export class SortClass {
    private Compare = (x: WarningSeverity, y: WarningSeverity): number => {
        return ((new CaseInsensitiveComparer()).Compare(y, x));
    }

}*/
