import { DeviceTypes, RegulatorPressures } from "rdptypes/project/ISprinklers";
import {
    BoosterPumpTypes,
    BuckBoostTypes,
    ElectricalFrequencies,
    EndGunTypes,
    GearDriveTypes,
    ISystemBase,
    PanelModels,
    RC10PanelModels,
    SpanTypes,
    SprinklerManufacturerTypes,
    SystemTypes,
    TireSizes,
    TireTypes,
    TowerHeights,
    ValveTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import { SystemMotionEnum } from "./CommonConstants";
import * as sidef from "./SideFunctions";
import { idxLeadType } from "./TowerFunctions";
import { strLanguageText } from "./TranslationsLib";
import { Side, Tires } from "./Types";
/*constructor() {
ResetDealerLogo();
}*/
/*export const IsDeveloper = (): boolean => {
if (DeveloperCodes.indexOf(AppClass.Instance.Config.DealerNumber) !== -1) {
return true;
}
return false;
}
*/export const GetMinimalEndGunPressures = (eEndGunType: EndGunTypes, sNozzleSize: number, bDiffuser: boolean, sDangerPSI: number, sWarningPSI: number) => {
switch (eEndGunType) {
case EndGunTypes.R75:
sDangerPSI = 40;
break;
case EndGunTypes.R75LP:
sDangerPSI = 25;
break;
case EndGunTypes.R55:
case EndGunTypes.R55i:
sDangerPSI = 15;
break;
default:
if (sNozzleSize <= 0.35) {if (bDiffuser) {
sDangerPSI = 25;
}
else {
sDangerPSI = 30;
}
sWarningPSI = 35;
}
else if (sNozzleSize <= 0.4) {if (bDiffuser) {
sDangerPSI = 25;
}
else {
sDangerPSI = 35;
}
sWarningPSI = 37;
}
else if (sNozzleSize <= 0.45) {if (bDiffuser) {
sDangerPSI = 25;
}
else {
sDangerPSI = 35;
}
sWarningPSI = 39;
}
else if (sNozzleSize <= 0.5) {if (bDiffuser) {
sDangerPSI = 25;
}
else {
sDangerPSI = 36;
}
sWarningPSI = 40;
}
else if (sNozzleSize <= 0.55) {if (bDiffuser) {
sDangerPSI = 25;
}
else {
sDangerPSI = 36;
}
sWarningPSI = 41;
}
else if (sNozzleSize <= 0.6) {if (bDiffuser) {
sDangerPSI = 25;
}
else {
sDangerPSI = 37;
}
sWarningPSI = 42;
}
else if (sNozzleSize <= 0.65) {if (bDiffuser) {
sDangerPSI = 25;
}
else {
sDangerPSI = 37;
}
sWarningPSI = 43;
}
else if (sNozzleSize <= 0.7) {if (bDiffuser) {
sDangerPSI = 25;
}
else {
sDangerPSI = 38;
}
sWarningPSI = 44;
}
else if (sNozzleSize <= 0.75) {if (bDiffuser) {
sDangerPSI = 25;
}
else {
sDangerPSI = 38;
}
sWarningPSI = 45;
}
else if (sNozzleSize <= 0.8) {if (bDiffuser) {
sDangerPSI = 25;
}
else {
sDangerPSI = 39;
}
sWarningPSI = 46;
}
else if (sNozzleSize <= 0.85) {if (bDiffuser) {
sDangerPSI = 25;
}
else {
sDangerPSI = 39;
}
sWarningPSI = 47;
}
else if (sNozzleSize <= 0.9) {if (bDiffuser) {
sDangerPSI = 25;
}
else {
sDangerPSI = 40;
}
sWarningPSI = 48;
}
else if (true) {if (bDiffuser) {
sDangerPSI = 28;
}
else {
sDangerPSI = 45;
}
sWarningPSI = 50;
}
break;
}
}
/*export const SpanTablesAsHTML = (Quote: QuoteClass): string => {
let cSpan: string = "";
let cSpanType: string = "";
let cLength: string = "";
let cSpacing: string = "";
let cExt: string = "";
let cDist: string = "";
let cPSILoss: string = "";
let cLengthWithExt: string = "";
let cDiameter: string = "";
let cConductors: string = "";
let cTire: string = "";
let sb: StringBuilder;
let iRigidSpan: number = this.Quote.RigidSpan();
let bCenterFeed: boolean = sysf.IsCenterFeed(this.Quote.System);
let bIsEDMP: boolean = sysf.IsEDMP(this.Quote.System);
let bHasSAC: boolean = sysf.HasSwingArmCorner(this.Quote.System);
if (sidef.NumberOfSpans(this.Quote.System.FlangedSide) > 0) {
sb.Append("<TABLE class=list cellpadding=0 cellspacing=0 bordercolor=#111111 width=100%>");
if (!(bCenterFeed)) {
sb.Append("<TR><TD width=100% colspan=8><span class=header2>" + strLanguageText("sltSpanInformation") + "</span></TD></TR>");
}
else {
sb.Append("<TR><TD width=100% colspan=8><span class=header2>" + strLanguageText("sltRightSpanInfo") + "</span></TD></TR>");
}
sb.Append("</TABLE>");
sb.Append("<TABLE class=list cellpadding=0 cellspacing=0 bordercolor=#111111 width=100%>");
sb.Append("<THEAD>");
sb.Append("<TR><TD align=right><U><B>" + strLanguageText("sltNumber1") + "</B></U></TD>" + "<TD >&nbsp;</TD>" + "<TD ><U><B>" + strLanguageText("sltType") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltLength") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltExt") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltSpacing") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltWheelTrack") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltTireSize") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltPSILoss") + "</B></U></TD>" + bIsEDMP ? "</TR>" : "<TD align=right><U><B>" + strLanguageText("sltSpanCable") + "</B></U></TD></TR>");
sb.Append("</THEAD>");
sb.Append("<TBODY>");
for (let i: number = 1; i <= sidef.NumberOfSpans(this.Quote.System.FlangedSide); i++
) {
this.Quote.GetRightSpanInfo(i, cSpan, cSpanType, cLength, cSpacing, cExt, cDist, cPSILoss, cLengthWithExt, cDiameter, cConductors, cTire);
sb.Append("<TR><TD align=right>" + cSpan + "</TD>" + "<TD>" + iRigidSpan === i ? strLanguageText("sltRigid") : "" + "&nbsp;" + sidef.GuidanceTower(this.Quote.System.FlangedSide) === i ? strLanguageText("sltGuidance") : "" + "&nbsp;</TD>" + "<TD>" + cSpanType + "</TD>" + "<TD align=right>" + cLength + "</TD>" + "<TD align=right>" + cExt + "</TD>" + "<TD align=right>" + cSpacing + "</TD>" + "<TD align=right>" + cDist + "</TD>" + "<TD align=right>" + cTire + "</TD>" + "<TD align=right>" + cPSILoss + "</TD>" + bIsEDMP ? "</TR>" : "<TD align=right>" + cConductors + "</TD></TR>");
}
if (bHasSAC) {
this.Quote.GetRightSpanInfo(-1, cSpan, cSpanType, cLength, cSpacing, cExt, cDist, cPSILoss, cLengthWithExt, cDiameter, cConductors, cTire);
sb.Append("<TR><TD align=right>" + cSpan + "</TD>" + "<TD>&nbsp;</TD>" + "<TD>" + cSpanType + "</TD>" + "<TD align=right>" + cLength + "</TD>" + "<TD align=right>" + cExt + "</TD>" + "<TD align=right>" + cSpacing + "</TD>" + "<TD align=right>" + cDist + "</TD>" + "<TD align=right>" + cTire + "</TD>" + "<TD align=right>" + cPSILoss + "</TD>" + "</TR>");
}
if (this.Quote.RightEOSClass.DataValid()) {
this.Quote.GetRightSpanInfo(0, cSpan, cSpanType, cLength, cSpacing, cExt, cDist, cPSILoss, cLengthWithExt, cDiameter, cConductors, cTire);
sb.Append("<TR><TD align=right>" + cSpan + "</TD>" + "<TD>&nbsp;</TD>" + "<TD>" + cSpanType + "</TD>" + "<TD align=right>" + cLength + "</TD>" + "<TD align=right>" + cExt + "</TD>" + "<TD align=right>" + cSpacing + "</TD>" + "<TD align=right>" + cDist + "</TD>" + "<TD align=right>&nbsp;</TD>" + "<TD align=right>" + cPSILoss + "</TD></TR>");
}
for (let i: number = 1; i <= sidef.NumberOfTowers(this.Quote.System.FlangedSide); i++
) {
switch (this.Quote.System.FlangedSide.Tower[i - 1].BuckBoost) {
case BuckBoostTypes.a15KVA:
sb.Append("<TR><TD colspan=8>" + strLanguageText("slt15KVA") + " " + strLanguageText("sltBuckandBoostTransformer1") + " " + strLanguageText("sltTower") + ": " + i + "</TD></TR>");
break;
break;
case BuckBoostTypes.a225KVA:
sb.Append("<TR><TD colspan=8>" + strLanguageText("slt22_5KVA") + " " + strLanguageText("sltBuckandBoostTransformer1") + " " + strLanguageText("sltTower") + ": " + i + "</TD></TR>");
break;
break;
}
}
sb.Append("<TR><TD class=underline colspan=10><span class=break >&nbsp;</span></TD></TR>");
sb.Append("</TBODY>");
sb.Append("</TABLE>");
}
if (sysf.IsDualSided(this.Quote.System)) {
sb.Append("<TABLE class=list cellpadding=0 cellspacing=0 bordercolor=#111111 width=100%>");
if (!(bCenterFeed)) {
sb.Append("<TR><TD width=100% colspan=8><span class=header2>" + strLanguageText("sltPowerTowerEnd") + "</span></TD></TR>");
}
else {
sb.Append("<TR><TD width=100% colspan=8><span class=header2>" + strLanguageText("sltLeftSpanInfo") + "</span></TD></TR>");
}
sb.Append("</TABLE>");
sb.Append("<TABLE class=list cellpadding=0 cellspacing=0 bordercolor=#111111 width=100%>");
sb.Append("<THEAD>");
sb.Append("<TR><TD align=right><U><B>" + strLanguageText("sltNumber1") + "</B></U></TD>" + "<TD >&nbsp;</TD>" + "<TD ><U><B>" + strLanguageText("sltType") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltLength") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltExt") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltSpacing") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltWheelTrack") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltTireSize") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltPSILoss") + "</B></U></TD>" + "<TD align=right><U><B>" + strLanguageText("sltSpanCable") + "</B></U></TD></TR>");
sb.Append("</THEAD>");
sb.Append("<TBODY>");
for (let i: number = 1; i <= sidef.NumberOfSpans(this.Quote.System.FlexSide); i++
) {
this.Quote.GetLeftSpanInfo(i, cSpan, cSpanType, cLength, cSpacing, cExt, cDist, cPSILoss, cLengthWithExt, cDiameter, cConductors, cTire);
sb.Append("<TR><TD align=right>" + cSpan + "</TD>" + "<TD>" + sidef.GuidanceTower(this.Quote.System.FlexSide) === i ? strLanguageText("sltGuidance") : "" + "&nbsp;</TD>" + "<TD>" + cSpanType + "</TD>" + "<TD align=right>" + cLength + "</TD>" + "<TD align=right>" + cExt + "</TD>" + "<TD align=right>" + cSpacing + "</TD>" + "<TD align=right>" + cDist + "</TD>" + "<TD align=right>" + cTire + "</TD>" + "<TD align=right>" + cPSILoss + "</TD>" + "<TD align=right>" + cConductors + "</TD></TR>");
}
if (this.Quote.LeftEOSClass.DataValid()) {
this.Quote.GetLeftSpanInfo(0, cSpan, cSpanType, cLength, cSpacing, cExt, cDist, cPSILoss, cLengthWithExt, cDiameter, cConductors, cTire);
sb.Append("<TR><TD align=right>" + cSpan + "</TD>" + "<TD>&nbsp;</TD>" + "<TD>" + cSpanType + "</TD>" + "<TD align=right>" + cLength + "</TD>" + "<TD align=right>" + cExt + "</TD>" + "<TD align=right>" + cSpacing + "</TD>" + "<TD align=right>" + cDist + "</TD>" + "<TD align=right>&nbsp;</TD>" + "<TD align=right>" + cPSILoss + "</TD></TR>");
}
for (let i: number = 1; i <= sidef.NumberOfTowers(this.Quote.System.FlexSide); i++
) {
switch (this.Quote.System.FlexSide.Tower[i - 1].BuckBoost) {
case BuckBoostTypes.a15KVA:
sb.Append("<TR><TD colspan=8>" + strLanguageText("slt15KVA") + " " + strLanguageText("sltBuckandBoostTransformer1") + " " + strLanguageText("sltTower") + ": " + i + "</TD></TR>");
break;
break;
case BuckBoostTypes.a225KVA:
sb.Append("<TR><TD colspan=8>" + strLanguageText("slt22_5KVA") + " " + strLanguageText("sltBuckandBoostTransformer1") + " " + strLanguageText("sltTower") + ": " + i + "</TD></TR>");
break;
break;
}
}
sb.Append("<TR><TD class=underline colspan=10><span class=break >&nbsp;</span></TD></TR>");
sb.Append("</TBODY>");
sb.Append("</TABLE>");
}
return sb.ToString;
}
*//*export const TypeIsAForm = (pType: System.Type): boolean => {
while (true) {if (pType.FullName === typeof(Windows.Forms.Form).FullName) {
return true;
}
else {
pType = pType.BaseType;
if (pType === null) {
return false;
}
}
}
return false;
}
*//*export const RebateMonthAsString = (Month: RebateMonths): string => {
switch (Month) {
case RebateMonths.Jan:
return strLanguageText("sltJan");
break;
case RebateMonths.Feb:
return strLanguageText("sltJan");
break;
case RebateMonths.Mar:
return strLanguageText("sltMar");
break;
case RebateMonths.Apr:
return strLanguageText("sltApr");
break;
case RebateMonths.May:
return strLanguageText("sltMay");
break;
case RebateMonths.Jun:
return strLanguageText("sltJun");
break;
case RebateMonths.Jul:
return strLanguageText("sltJul");
break;
case RebateMonths.Aug:
return strLanguageText("sltAug");
break;
case RebateMonths.Sep:
return strLanguageText("sltSep");
break;
case RebateMonths.Oct:
return strLanguageText("sltOct");
break;
case RebateMonths.Nov:
return strLanguageText("sltNov");
break;
case RebateMonths.Dec:
return strLanguageText("sltDec");
break;
}
}
*/
export const RequiredGPMGivenArc = (IrrigationRate: number, ArcStartLocation: number, ArcEndLocation: number, ArcLength: number, sysMot: SystemMotionEnum): number => {
    if (ArcLength === 0) {
        return;
    }
    if (IrrigationRate < 0) {
        return;
    }
    if (sysMot === SystemMotionEnum.Pivot) {
        return IrrigationRate * ((Math.pow(ArcEndLocation, 2) - Math.pow(ArcStartLocation, 2)) / (Math.pow(ArcLength, 2)));
    }
    else {
        return IrrigationRate * ((ArcEndLocation - ArcStartLocation) / ArcLength);
    }
}
export const PressureLossHazenWilliams = (Flowrate: number, Distance: number, CFactor: number, InsideDiameter: number): number => {
return (4.53091 / (Math.pow(InsideDiameter, 4.8704))) * (Math.pow((Flowrate / CFactor), 1.8518)) * Distance;
}

export const PressureLossSimpsonsRule = (IrrigationRate: number, PipeStartLocation: number, PipeEndLocation: number, ArcStartLocation: number, ArcEndLocation: number, ArcLength: number, sysMot: SystemMotionEnum, CFactor: number, InsideDiameter: number): number => {
    let sngBeginLossFactor: number;
    let sngMidLossFactor: number;
    let sngEndLossFactor: number;
    let sngGPM: number = RequiredGPMGivenArc(IrrigationRate, ArcStartLocation, ArcLength, ArcLength, sysMot);
    sngBeginLossFactor = (4.53091 / (Math.pow(InsideDiameter, 4.8704))) * (Math.pow((sngGPM / CFactor), 1.8518));
    sngGPM = RequiredGPMGivenArc(IrrigationRate, ArcStartLocation + (ArcEndLocation - ArcStartLocation) / 2, ArcLength, ArcLength, sysMot);
    sngMidLossFactor = (4.53091 / (Math.pow(InsideDiameter, 4.8704))) * (Math.pow((sngGPM / CFactor), 1.8518));
    if (ArcLength <= ArcEndLocation) {
        sngEndLossFactor = 0;
    }
    else {
        sngGPM = RequiredGPMGivenArc(IrrigationRate, ArcEndLocation, ArcLength, ArcLength, sysMot);
        sngEndLossFactor = (4.53091 / (Math.pow(InsideDiameter, 4.8704))) * (Math.pow((sngGPM / CFactor), 1.8518));
    }
    return (sngBeginLossFactor + 4 * sngMidLossFactor + sngEndLossFactor) / 6 * (PipeEndLocation - PipeStartLocation);
}
export const EstEndGunCoverage = (EndGun: EndGunTypes, Diffuser: boolean): number => {
switch (EndGun) {
case EndGunTypes.None:
return 0;
break;
case EndGunTypes.DualP85:
if (Diffuser) {
return 40;
}
return 60;
break;
case EndGunTypes.SingleP85:
case EndGunTypes.DualP85:
if (Diffuser) {
return 50;
}
return 70;
break;
case EndGunTypes.SR75:
return 90;
break;
default:
return 100;
break;
}
}
/*export const AdvMsgBox = (ParentFrm: Form, Prompt: string, Buttons: MsgBoxStyle, Title: string, DisplayOKCancel: boolean): MsgBoxResult => {
let f: FRichMsgBox;
f.Text = Title;
if ((Buttons && MsgBoxStyle.OkCancel)) {
f.OKOnly = false;
f.btnOK.Text = DisplayOKCancel ? strLanguageText("sltOk") : strLanguageText("sltAccept");
f.btnCancel.Text = DisplayOKCancel ? strLanguageText("sltCancel") : strLanguageText("sltReject");
}
else {
f.OKOnly = true;
if ((Buttons && MsgBoxStyle.Critical)) {
f.btnOK.Text = DisplayOKCancel ? strLanguageText("sltCancel") : strLanguageText("sltReject");
}
else {
f.btnOK.Text = DisplayOKCancel ? strLanguageText("sltOk") : strLanguageText("sltAccept");
}
}
return f.Msg(Prompt, ParentFrm);
}
*/export const ElectricalFrequencyToText = (Frequency: ElectricalFrequencies): string => {
    switch (Frequency) {
        case ElectricalFrequencies.a50:
        return "50 Hz";
        break;
        case ElectricalFrequencies.a60:
        return "60 Hz";
        break;
    }
}
export const TireSelector = (TireSize: TireSizes, TireType: TireTypes, OneTireMountedInReverse: boolean, Tubed: boolean, ThreeTires: boolean = false, TwelveLugs: boolean = false, IsSwingArm: boolean = false): number => {
    switch (TireSize) {
        case TireSizes.a11x225:
            switch (TireType) {
                case TireTypes.Recaps:
                    if (!(ThreeTires)) {
                        if (!(OneTireMountedInReverse)) {
                            if (!(Tubed)) {
                                return 2;
                            }
                            else {
                                return 84;
                            }
                        }
                        else {
                            if (!(Tubed)) {
                                return 4;
                            }
                            else {
                                return 86;
                            }
                        }
                    }
                    else {
                        if (!(Tubed)) {
                            return 54;
                        }
                        else {
                            return 88;
                        }
                    }
                    break;
                case TireTypes.MachII:
                    if (ThreeTires) {
                        return 97;
                    }
                    else {
                        return 93;
                    }
                    break;
            }
            break;
        case TireSizes.a112x24:
            switch (TireType) {
                case TireTypes.FourPly:
                    if (!(ThreeTires)) {
                        if (!(OneTireMountedInReverse)) {
                            if (!(Tubed)) {
                                return 6;
                            }
                            else {
                                return 8;
                            }
                        }
                        else {
                            if (!(Tubed)) {
                                return 10;
                            }
                            else {
                                return 12;
                            }
                        }
                    }
                    else {
                        if (!(Tubed)) {
                            return 56;
                        }
                        else {
                            return 58;
                        }
                    }
                    break;
                case TireTypes.MachII:
                    if (ThreeTires) {
                        return 94;
                    }
                    else {
                        return 90;
                    }
                    break;
                case TireTypes.Rhinogator:
                    if (ThreeTires) {
                        return 111;
                    }
                    else {
                        return 110;
                    }
                    break;
            }
            break;
        case TireSizes.a149x24:
            switch (TireType) {
                case TireTypes.FourPly:
                    if (!(ThreeTires)) {
                        if (!(OneTireMountedInReverse)) {
                            if (!(Tubed)) {
                                return 14;
                            }
                            else {
                                return 16;
                            }
                        }
                        else {
                            if (!(Tubed)) {
                                return 18;
                            }
                            else {
                                return 20;
                            }
                        }
                    }
                    else {
                        if (!(Tubed)) {
                            return 60;
                        }
                        else {
                            return 62;
                        }
                    }
                    break;
                case TireTypes.Turf:
                    if (!(Tubed)) {
                        if (!(ThreeTires)) {
                            return 22;
                        }
                        else {
                            return 64;
                        }
                    }
                    else {
                        if (!(ThreeTires)) {
                            return 24;
                        }
                        else {
                            return 66;
                        }
                    }
                    break;
                case TireTypes.NonDirectional:
                    if (!(Tubed)) {
                        if (!(ThreeTires)) {
                            return 131;
                        }
                        else {
                            return 133;
                        }
                    }
                    else {
                        if (!(ThreeTires)) {
                            return 130;
                        }
                        else {
                            return 132;
                        }
                    }
                    break;
                case TireTypes.MachII:
                    if (ThreeTires) {
                        return 95;
                    }
                    else {
                        return 91;
                    }
                    break;
                case TireTypes.Rhinogator:
                    if (ThreeTires) {
                        return 115;
                    }
                    else {
                        return 114;
                    }
                    break;
                case TireTypes.Radial:
                    if (IsSwingArm) {
                        OneTireMountedInReverse = true;
                        if (Tubed) {
                            if (OneTireMountedInReverse) {
                                return 141;
                            }
                            if (ThreeTires) {
                                return 157;
                            }
                        }
                        else {
                            if (OneTireMountedInReverse) {
                                return 140;
                            }
                            if (ThreeTires) {
                                return 156;
                            }
                        }
                    }
                    else {
                        if (Tubed) {
                            if (OneTireMountedInReverse) {
                                return 155;
                            }
                            if (ThreeTires) {
                                return 157;
                            }
                            return 153;
                        }
                        else {
                            if (OneTireMountedInReverse) {
                                return 154;
                            }
                            if (ThreeTires) {
                                return 156;
                            }
                            return 152;
                        }
                    }
                    break;
            }
            break;
        case TireSizes.a169x24:
            switch (TireType) {
                case TireTypes.SixPly:
                    if (!(ThreeTires)) {
                        if (!(OneTireMountedInReverse)) {
                            if (!(Tubed)) {
                                return 26;
                            }
                            else {
                                return 28;
                            }
                        }
                        else {
                            if (!(Tubed)) {
                                return 30;
                            }
                            else {
                                return 32;
                            }
                        }
                    }
                    else {
                        if (!(Tubed)) {
                            return 68;
                        }
                        else {
                            return 70;
                        }
                    }
                    break;
                case TireTypes.Turf:
                    if (!(Tubed)) {
                        if (!(ThreeTires)) {
                            return 34;
                        }
                        else {
                            return 72;
                        }
                    }
                    else {
                        if (!(ThreeTires)) {
                            return 36;
                        }
                        else {
                            return 73;
                        }
                    }
                    break;
                case TireTypes.Shark:
                    if (ThreeTires) {
                        return 163; //352268	TIRE OPT/TWR-SHARK STD-3 WHLS
                    } else {
                        return 162; //352267	TIRE OPT/TWR-SHARK STD-2 WHLS
                    }
            }
            break;
        case TireSizes.a112x38:
            switch (TireType) {
                case TireTypes.FourPly:
                    if (!(ThreeTires)) {
                        if (!(OneTireMountedInReverse)) {
                            if (!(Tubed)) {
                                return 46;
                            }
                            else {
                                return 48;
                            }
                        }
                        else {
                            if (!(Tubed)) {
                                return 50;
                            }
                            else {
                                return 52;
                            }
                        }
                    }
                    else {
                        if (!(Tubed)) {
                            return 80;
                        }
                        else {
                            return 82;
                        }
                    }
                    break;
                case TireTypes.MachII:
                    if (ThreeTires) {
                        if (TwelveLugs) {
                            return 101;
                        }
                        else {
                            return 96;
                        }
                    }
                    else {
                        if (TwelveLugs) {
                            return 100;
                        }
                        else {
                            return 92;
                        }
                    }
                    break;
                case TireTypes.Rhinogator:
                    if (ThreeTires) {
                        return 113;
                    }
                    else {
                        return 112;
                    }
                    break;
                case TireTypes.NonDirectional:
                    if (!(Tubed)) {
                        if (ThreeTires) {
                            return 160;
                        }
                        return 158;
                    }
                    else {
                        if (ThreeTires) {
                            return 161;
                        }
                        return 159;
                    }
                    break;
            }
            break;
        case TireSizes.a136x38:
            switch (TireType) {
                case TireTypes.SixPly:
                    if (!(ThreeTires)) {
                        if (!(OneTireMountedInReverse)) {
                            return 40;
                        }
                        else {
                            return 44;
                        }
                    }
                    else {
                        return 78;
                    }
                    break;
                case TireTypes.MachII:
                    if (ThreeTires) {
                        return 99;
                    }
                    else {
                        return 98;
                    }
                    break;
            }
            break;
        case TireSizes.a32085R38:
            switch (TireType) {
                case TireTypes.Radial:
                    if (IsSwingArm) {
                        OneTireMountedInReverse = true;
                    }
                    if (!(ThreeTires)) {
                        if (!(OneTireMountedInReverse)) {
                            if (!(Tubed)) {
                                return 121;
                            }
                            else {
                                return 120;
                            }
                        }
                        else {
                            if (!(Tubed)) {
                                if (IsSwingArm) {
                                    return 142;
                                }
                                else {
                                    return 123;
                                }
                            }
                            else {
                                if (IsSwingArm) {
                                    return 143;
                                }
                                else {
                                    return 122;
                                }
                            }
                        }
                    }
                    else {
                        if (!(Tubed)) {
                            return 125;
                        }
                        else {
                            return 124;
                        }
                    }
                    break;
            }
            break;
        case TireSizes.a32085R24:
            switch (TireType) {
                case TireTypes.Radial:
                    if (IsSwingArm) {
                        OneTireMountedInReverse = true;
                    }
                    if (!(Tubed)) {
                        if (ThreeTires) {
                            return 150;
                        }
                        if (OneTireMountedInReverse) {
                            return 148;
                        }
                        return 146;
                    }
                    else {
                        if (ThreeTires) {
                            return 151;
                        }
                        if (OneTireMountedInReverse) {
                            return 149;
                        }
                        return 147;
                    }
                    break;
            }
            break;
        case TireSizes.a38080R38:
            switch (TireType) {
                case TireTypes.Radial:
                    if (IsSwingArm) {
                        if (IsSwingArm) {
                            OneTireMountedInReverse = true;
                        }
                        if (!(Tubed)) {
                            if (OneTireMountedInReverse) {
                                if (IsSwingArm) {
                                    return 144;
                                }
                                else {
                                    return 142;
                                }
                            }
                        }
                        else {
                            if (OneTireMountedInReverse) {
                                if (IsSwingArm) {
                                    return 145;
                                }
                                else {
                                    return 143;
                                }
                            }
                        }
                    }
                    else {
                        if (!(Tubed)) {
                            if (ThreeTires) {
                                return 144;
                            }
                            if (OneTireMountedInReverse) {
                                return 142;
                            }
                            return 140;
                        }
                        else {
                            if (ThreeTires) {
                                return 145;
                            }
                            if (OneTireMountedInReverse) {
                                return 143;
                            }
                            return 141;
                        }
                    }
                    break;
            }
            break;
    }
}
export const RadiusToAcres = (Radius: number): number => {
return Math.PI * (Math.pow(Radius, 2) / 43560);
}
export const AcresToRadius = (Acres: number): number => {
return Math.sqrt((43560 * Acres) / Math.PI);
}
export const RegulatorPressure = (Pressure: RegulatorPressures): number => {
    switch (Pressure) {
        case RegulatorPressures.a6:
            return 6;
        case RegulatorPressures.a10:
            return 10;
        case RegulatorPressures.a15:
            return 15;
        case RegulatorPressures.a20:
            return 20;
        case RegulatorPressures.a25:
            return 25;  
        case RegulatorPressures.a30:
            return 30;
        case RegulatorPressures.a40:
            return 40;
    }
}
/*export const TextToTempFile = (strText: string, strFileName: string): string => {
let strTempFileName: string = Path.GetTempFileName();
let strNewFile: string = Path.GetDirectoryName(strTempFileName) + "\" + strFileName;
if (File.Exists(strNewFile)) {
File.Delete(strNewFile);
}
File.Move(strTempFileName, strNewFile);
let outputstream: FileStream;
let outputwriter: StreamWriter;
try {outputstream = new FileStream(, FileMode.Create);
outputwriter = new StreamWriter();
outputwriter.Write(strText);
}
finally {
if (outputwriter !== null) {
outputwriter.Dispose();
}
else if (outputstream !== null) {
outputstream.Dispose();
}
}
return strNewFile;
}
*//*export const StringFromResource = (ResourceName: string): string => {
Using stream As Stream = Assembly.GetExecutingAssembly.GetManifestResourceStream($"ReinkeSystems.{ResourceName}")
            Using sr As New StreamReader(stream)
                Return sr.ReadToEnd
            End Using
        End Using
}
*//*export const GetTempFile = (strFileExtension: string): string => {
let strTempFileName: string = Path.GetTempFileName();
let strNewFile: string = Replace(strTempFileName, ".tmp", "." + strFileExtension, undefined, undefined, CompareMethod.Text);
if (File.Exists(strNewFile)) {
let stroldFile: string = strTempFileName;
strTempFileName = Path.GetTempFileName();
try {File.Delete(stroldFile);
}
catch {
}
strNewFile = Replace(strTempFileName, ".tmp", "." + strFileExtension, undefined, undefined, CompareMethod.Text);
}
File.Move(strTempFileName, strNewFile);
return strNewFile;
}
*//*export const MetersToFeet = (Meters: number): number => {
if (AppClass.Instance.Config.Measurement === Measurements.Metric) {
return Meters * 3.281;
}
else {
return Meters;
}
}
*/export const FeetToMeters = (Feet: number): number => {
    // TODO Icon - Support Metric units
/*if (AppClass.Instance.Config.Measurement === Measurements.Metric) {
return Feet / 3.281;
}
else */{
return Feet;
}
}
export const PSIToBar = (PSI: number): number => {
    // TODO Icon - Support Metric units
/*if (AppClass.Instance.Config.Measurement === Measurements.Metric) {
return PSI / 14.5038;
}
else */{
return PSI;
}
}
/*export const BarToPSI = (Bar: number): number => {
if (AppClass.Instance.Config.Measurement === Measurements.Metric) {
return Bar * 14.5038;
}
else {
return Bar;
}
}
*/export const AcresToHa = (Acre: number): number => {
return Acre / 2.471;
}
/*export const InchesToCMs = (Inches: number): number => {
if (AppClass.Instance.Config.Measurement === Measurements.Metric) {
return Inches / 0.394;
}
else {
return Inches;
}
}
*/
export const GPMToM3Hour = (GPM: number): number => {
    return GPM;
/*if (AppClass.Instance.Config.Measurement === Measurements.Metric) {
return GPM / 4.402868;
}
else {
return GPM;
}*/
}
/*export const M3HourToGPM = (M3Hour: number): number => {
if (AppClass.Instance.Config.Measurement === Measurements.Metric) {
return M3Hour * 4.402868;
}
else {
return M3Hour;
}
}
*/export const ConvertToAcres = (dTenthsOfAnInchSquared: number): number => {
return dTenthsOfAnInchSquared / 627266482.632;
}
export const SpanTypeText = (type: SpanTypes): string => {
switch (type) {
case SpanTypes.E2100:
return strLanguageText("sltE2100");
break;
case SpanTypes.E2085:
return strLanguageText("sltE2085");
break;
case SpanTypes.E2065:
return strLanguageText("sltE2065");
break;
case SpanTypes.E2060:
return strLanguageText("sltE2060");
break;
case SpanTypes.E2045:
return strLanguageText("sltE2045");
break;
case SpanTypes.E2665:
return strLanguageText("sltE2665");
break;
case SpanTypes.E2660:
return strLanguageText("sltE2660");
break;
case SpanTypes.AlumIV:
return strLanguageText("sltAlumIV");
break;
case SpanTypes.A80G:
return strLanguageText("sltA80G");
break;
case SpanTypes.A60G:
return strLanguageText("sltA60G");
break;
case SpanTypes.S2085G:
return strLanguageText("sltS2085G");
break;
case SpanTypes.S2065G:
return strLanguageText("sltS2065G");
break;
case SpanTypes.PL2085G:
return strLanguageText("sltPL2085G");
break;
case SpanTypes.PL2065G:
return strLanguageText("sltS2065PL");
break;
}
}
export const DistanceStringWithUnits = (DistanceInFeet: number): string => {
let s: string = DistanceInFeet + "'";
// TODO support metric
/*if (AppClass.Instance.Config.Measurement === Measurements.Metric) {
return ConvertUSMeasureToMetricMeasure(s);
}*/
return s;
}
export const SpanLengthsToFeet = (type: number): number => {
    switch (type) {
        case 80:
            return 80;
            break;
        case 99:
            return 99;
            break;
        case 118:
            return 118;
            break;
        case 120:
            return 120;
            break;
        case 137:
            return 137;
            break;
        case 140:
            return 140;
            break;
        case 156:
            return 156;
            break;
        case 160:
            return 160;
            break;
        case 175:
            return 175;
            break;
        case 180:
            return 180;
            break;
        case 186.7:
            return 186.7;
            break;
        case 194:
            return 194;
            break;
        case 204:
            return 204;
            break;
        case 213:
            return 213;
            break;
        case 13:
            return 13;
            break;
        case 23:
            return 23;
            break;
        case 32:
            return 32;
            break;
        case 42:
            return 42;
            break;
        case 51:
            return 51;
            break;
        case 61:
            return 61;
            break;
        case 85:
            return 85;
            break;
        case 105:
            return 105;
            break;
    }
}
export const SpacingText2 = (i: number): string => {
switch (i) {
case 40:
return strLanguageText("slt40inches");
break;
case 57:
return strLanguageText("slt57inches");
break;
case 114:
return strLanguageText("slt114inches");
break;
}
}
export const SpanCableText = (type: number, bEDMP: boolean): string => {
if (bEDMP) {
return strLanguageText("sltNone");
}
else {
switch (type) {
case 8:
return strLanguageText("slt8Pound");
break;
case 10:
return strLanguageText("slt10Gauge");
break;
case 12:
return strLanguageText("slt12Gauge");
break;
}
}
}
/*export const TermsText = (paymentType: PaymentTypes, otherTerms: string, InterestFree: boolean, InterestFreeDate: string, LowInterest: boolean): string => {
let str = strLanguageText(paymentType.GetEnumDescription());
if (InterestFree) {
return `${str} - ${strLanguageText("sltInterestFreeUntil")} ${InterestFreeDate}`;
}
else if (LowInterest) {
return `${str} - ${strLanguageText("sltLowInterest")}`;
}
else if (paymentType === PaymentTypes.Other) {
return `${str} - ${otherTerms}`;
}
return str;
}
*/export const SimpleEndGunText = (type: EndGunTypes): string => {
    switch (type) {
        case EndGunTypes.None:
            return strLanguageText("sltNone");
        case EndGunTypes.DualP85:
            return strLanguageText("slt2P85s");
        case EndGunTypes.SingleP85:
            return strLanguageText("slt1P85");
        case EndGunTypes.SR100:
            return strLanguageText("sltSR100");
        case EndGunTypes.SR75:
            return strLanguageText("sltSR75");
        case EndGunTypes.SR100NV:
            return strLanguageText("sltSR100NV");
        case EndGunTypes.SimeWing:
            return strLanguageText("sltSimeWing");
        case EndGunTypes.TwinMax:
            return strLanguageText("sltTwinMax");
        case EndGunTypes.Twin101Ultra:
            return strLanguageText("sltTwinUltra");
        case EndGunTypes.R55:
            return strLanguageText("sltR55");
        case EndGunTypes.R55i:
            return strLanguageText("sltR55i");
        case EndGunTypes.R75:
            return strLanguageText("sltR75");
        case EndGunTypes.R75LP:
            return strLanguageText("sltR75LP");
    }

    return "N/A";
}
export const EndGunText = (ePrimaryEndGun: EndGunTypes, eSecondaryEndGun: EndGunTypes, iEndGunValve: ValveTypes): string => {
    let EndGunText = SimpleEndGunText(ePrimaryEndGun);
    if (eSecondaryEndGun !== EndGunTypes.None) {
        EndGunText += "/" + SimpleEndGunText(eSecondaryEndGun);
    }

    switch (iEndGunValve) {
        case ValveTypes.None:
            return EndGunText + "/" + strLanguageText("sltNone");
        case ValveTypes.Manual:
            return EndGunText + "/" + strLanguageText("sltManual");
        case ValveTypes.Diaphragm:
            return EndGunText + "/" + strLanguageText("sltDiaphragm");
        case ValveTypes.Auto800:
            return EndGunText + "/" + strLanguageText("slt800P");
        case ValveTypes.Auto1000:
            return EndGunText + "/" + strLanguageText("slt1000P");
        case ValveTypes.Reinke:
            return EndGunText + "/" + strLanguageText("sltReinkeValve");
    }
    return EndGunText;
}
export const SimpleValveText = (type: ValveTypes) => {
    switch (type) {
        case ValveTypes.None:
            return strLanguageText("sltNone");
        case ValveTypes.Manual:
            return strLanguageText("sltManual");
        case ValveTypes.Diaphragm:
            return strLanguageText("sltDiaphragm");
        case ValveTypes.Auto800:
            return strLanguageText("slt800P");
        case ValveTypes.Auto1000:
            return strLanguageText("slt1000P");
        case ValveTypes.Reinke:
            return strLanguageText("sltReinkeValve");
        break;
    }

    return "N/A";
}
export const BoosterPumpText = (type: BoosterPumpTypes): string => {
    switch (type) {
        case BoosterPumpTypes.None:
            return strLanguageText("sltNone");
        case BoosterPumpTypes.TwoHP:
            return strLanguageText("slt2HP");
        case BoosterPumpTypes.FiveHP:
            return strLanguageText("slt5HP");
    }

    return "N/A";
}
export const DeviceTypeText = (type: DeviceTypes, useNelson3030: boolean, useNelsonAD3030MT: boolean): string => {
    switch (type) {
        case DeviceTypes.Nelson15Single3RNBrassImpact:
            return strLanguageText("sltNelson15Single3RNBI");
        case DeviceTypes.Nelson15DoubleFCNBrassImpact:
            return strLanguageText("sltNelson15DoubleFCNBI");
        case DeviceTypes.Nelson1518MediumSpacingBrassImpact:
            return strLanguageText("sltNelson1518MediumSpacingBI");
        case DeviceTypes.Nelson15Double3RNBrassImpact:
            return strLanguageText("sltNelson15Double3RNBI");
        case DeviceTypes.Nelson8DoubleFCNPlasticImpact:
            return strLanguageText("sltNelson8DoubleFCNPI");
        case DeviceTypes.Nelson8Double3RNPlasticImpact:
            return strLanguageText("sltNelson8Double3RNPI");
        case DeviceTypes.Senninger12MediumSpacingPlasticImpact:
            return strLanguageText("sltSenningerConstantPlastic");
        case DeviceTypes.Senninger6DoubleWhiteVanePlasticImpact:
            return strLanguageText("sltSenningerVariablePlasticWhite");
        case DeviceTypes.Senninger6DoubleRedVanePlasticImpact:
            return strLanguageText("sltSenningerVariablePlasticRed");
        case DeviceTypes.NelsonR3000RotatorBlue:
            if (useNelson3030) {
                return strLanguageText("sltNelsonR3030RotatorBlue");
            }
            else {
                return strLanguageText("sltNelsonR3000aRotatorBlue");
            }
        case DeviceTypes.NelsonR3000RotatorWhite:
            if (useNelson3030) {
                return strLanguageText("sltNelsonR3030RotatorWhite");
            }
            else {
                return strLanguageText("sltNelsonR3000aRotatorWhite");
            }
        case DeviceTypes.NelsonR3000FCNRotatorBlue:
            return strLanguageText("sltNelsonR3000aFCNRotatorBlue");
        case DeviceTypes.NelsonR3000RotatorGreen:
            if (useNelson3030) {
                return strLanguageText("sltNelsonR3030RotatorGreen");
            }
            else {
                return strLanguageText("sltNelsonR3000aRotatorGreen");
            }
        case DeviceTypes.NelsonR3000FCNRotatorGreen:
            return strLanguageText("sltNelsonR3000aFCNRotatorGreen");
        case DeviceTypes.NelsonR3000RotatorRed:
            if (useNelson3030) {
                return strLanguageText("sltNelsonR3030RotatorRed");
            }
            else {
                return strLanguageText("sltNelsonR3000aRotatorRed");
            }
        case DeviceTypes.NelsonR3000RotatorOrange:
            if (useNelson3030) {
                return strLanguageText("sltNelsonR3030RotatorOrange");
            }
            else {
                return strLanguageText("sltNelsonR3000aRotatorOrange");
            }
        case DeviceTypes.NelsonR3000RotatorBrown:
            if (useNelson3030) {
                return strLanguageText("sltNelsonR3030RotatorBrown");
            }
            else {
                return strLanguageText("sltNelsonR3000aRotatorBrown");
            }
        case DeviceTypes.NelsonR3000RotatorOlive:
            if (useNelson3030) {
                return strLanguageText("sltNelsonR3030RotatorOlive");
            }
            else {
                return strLanguageText("sltNelsonR3000aRotatorOlive");
            }
        case DeviceTypes.NelsonO3000OrbitorBlack:
            if (useNelson3030) {
                return strLanguageText("sltNelsonO3030OrbitorBlack");
            }
            else {
                return strLanguageText("sltNelsonO3000aOrbitorBlack");
            }
        case DeviceTypes.NelsonO3000OrbitorPurple:
            if (useNelson3030) {
                return strLanguageText("sltNelsonO3030OrbitorPurple");
            }
            else {
                return strLanguageText("sltNelsonO3000aOrbitorPurple");
            }
        case DeviceTypes.NelsonO3000OrbitorBlue:
            if (useNelson3030) {
                return strLanguageText("sltNelsonO3030OrbitorBlue");
            }
            else {
                return strLanguageText("sltNelsonO3000aOrbitorBlue");
            }
        case DeviceTypes.NelsonO3030OrbitorFXWhite:
            if (useNelson3030) {
                return strLanguageText("sltNelsonO3030OrbitorWhite");
            }
            else {
                return strLanguageText("sltNelsonO3000aOrbitorWhite");
            }
        case DeviceTypes.NelsonO3030OrbitorFXBlack:
            return strLanguageText("sltNelsonO3030OrbitorFXBlack");
        case DeviceTypes.NelsonO3030OrbitorFXWhite:
            return strLanguageText("sltNelsonO3030OrbitorFXWhite");
        case DeviceTypes.NelsonS3000SpinnerRed:
            if (useNelson3030) {
                return strLanguageText("sltNelsonS3030SpinnerRed");
            }
            else {
                return strLanguageText("sltNelsonS3000aSpinnerRed");
            }
        case DeviceTypes.NelsonS3000SpinnerPurple:
            if (useNelson3030) {
                return strLanguageText("sltNelsonS3030SpinnerPurple");
            }
            else {
                return strLanguageText("sltNelsonS3000aSpinnerPurple");
            }
        case DeviceTypes.NelsonS3000SpinnerYellow:
            if (useNelson3030) {
                return strLanguageText("sltNelsonS3030SpinnerYellow");
            }
            else {
                return strLanguageText("sltNelsonS3000aSpinnerYellow");
            }
        case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure:
            if (useNelson3030) {
                return strLanguageText("sltNelsonS3030SpinnerPurpleLowPressure");
            }
            else {
                return strLanguageText("sltNelsonS3000aSpinnerPurpleLowPressure");
            }
        case DeviceTypes.NelsonS3000SpinnerYellowLowPressure:
            if (useNelson3030) {
                return strLanguageText("sltNelsonS3030SpinnerYellowLowPressure");
            }
            else {
                return strLanguageText("sltNelsonS3000aSpinnerYellowLowPressure");
            }
        case DeviceTypes.NelsonA3000AcceleratorMaroon:
            if (useNelson3030) {
                if (useNelsonAD3030MT) {
                    return strLanguageText("sltNelsonA3030MTAcceleratorMaroon");
                }
                else {
                    return strLanguageText("sltNelsonA3030AcceleratorMaroon");
                }
            }
            else {
                return strLanguageText("sltNelsonA3000aAcceleratorMaroon");
            }
        case DeviceTypes.NelsonA3000AcceleratorGold:
            if (useNelson3030) {
                if (useNelsonAD3030MT) {
                    return strLanguageText("sltNelsonA3030MTAcceleratorGold");
                }
                else {
                    return strLanguageText("sltNelsonA3030AcceleratorGold");
                }
            }
            else {
                return strLanguageText("sltNelsonA3000aAcceleratorGold");
            }
        case DeviceTypes.NelsonD3000Spray:
            if (useNelson3030) {
                if (useNelsonAD3030MT) {
                    return strLanguageText("sltNelsonD3030MT");
                }
                else {
                    return strLanguageText("sltNelsonD3030");
                }
            }
            else {
                return strLanguageText("sltNelsonD3000a");
            }
        case DeviceTypes.NelsonA3000AcceleratorNavy:
            if (useNelson3030) {
                return strLanguageText("sltNelsonA3030AcceleratorNavy");
            }
            else {
                return strLanguageText("sltNelsonA3000aAcceleratorNavy");
            }
        case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
            if (useNelson3030) {
                return strLanguageText("sltNelsonA3030AcceleratorNavyLowPressure");
            }
            else {
                return strLanguageText("sltNelsonA3000aAcceleratorNavyLowPressure");
            }
        case DeviceTypes.NelsonD3000FCNSpray:
            return strLanguageText("sltNelsonD3000aFCNSpray");
        case DeviceTypes.NelsonD3000PartCircle:
            return strLanguageText("sltPartialCircleD3000");
        case DeviceTypes.NelsonS3000PartCircle:
            return strLanguageText("sltPartialCircleS3000");
        case DeviceTypes.SenningerLowAngleIWobWhite:
            return strLanguageText("sltSenningerLowAngleIWobWhite");
        case DeviceTypes.SenningerLowAngleIWobBlue:
            return strLanguageText("sltSenningerLowAngleIWobBlue");
        case DeviceTypes.SenningerStandardAngleIWobGray:
            return strLanguageText("sltSenningerStandardAngleIWobGray");
        case DeviceTypes.SenningerHighAngleIWobBlack:
            return strLanguageText("sltSenningerHighAngleIWobBlack");
        case DeviceTypes.SenningerXiWobBlack:
            return strLanguageText("sltSenningerXiWobBlack");
        case DeviceTypes.SenningerXiWobWhite:
            return strLanguageText("sltSenningerXiWobWhite");
        case DeviceTypes.SenningerXiWobBlue:
            return strLanguageText("sltSenningerXiWobBlue");
        case DeviceTypes.SenningerXiWobGray:
            return strLanguageText("sltSenningerXiWobGray");
        case DeviceTypes.SenningerXcelWobbler:
            return strLanguageText("sltSenningerXcelWobbler");
        case DeviceTypes.SenningerSuperSpray:
            return strLanguageText("sltSenningerSuperSpray");
        case DeviceTypes.SenningerLDNSpray:
            return strLanguageText("sltSenningerLDNSpray");
        case DeviceTypes.SenningerQuadSpray:
            return strLanguageText("sltSenningerQuadSpray");
        case DeviceTypes.Senninger180Fan:
            return strLanguageText("sltSenninger180Fan");
        case DeviceTypes.KometTwisterBlack:
            return strLanguageText("sltKometTwisterBlack");
        case DeviceTypes.KometTwisterWhite:
            return strLanguageText("sltKometTwisterWhite");
        case DeviceTypes.KometTwisterBlue:
            return strLanguageText("sltKometTwisterBlue");
        case DeviceTypes.KometTwisterYellow:
            return strLanguageText("sltKometTwisterYellow");
        case DeviceTypes.KometSpray:
            return strLanguageText("sltKometSpray");
        case DeviceTypes.KometSprayPartCircle:
            return strLanguageText("sltKometSprayPartCircle");
        case DeviceTypes.KometTwisterPartCircle:
            return strLanguageText("sltKometTwisterPartCircle");
        case DeviceTypes.None:
            return strLanguageText("sltNoPackage");
        default:
            // new ArgumentException()
            throw new Error();
    }
}
/*export const BoomBackDeviceText = (type: BoomBackDevices): string => {
switch (type) {
case BoomBackDevices.D3000:
return strLanguageText("sltPartialCircleD3000");
break;
case BoomBackDevices.S3000:
return strLanguageText("sltPartialCircleS3000");
break;
case BoomBackDevices.R3000:
return strLanguageText("sltPartialCircleR3000");
break;
case BoomBackDevices.LDN:
return strLanguageText("sltPartialCircleLDN");
break;
case BoomBackDevices.Fan:
return strLanguageText("sltSenninger180Fan");
break;
case BoomBackDevices.Komet:
return strLanguageText("sltKometSprayPartCircle");
break;
case BoomBackDevices.KometTwisterPartCircle:
return strLanguageText("sltKometTwisterPartCircle");
break;
default:
// new ArgumentException()
throw new Error();
break;
}
}
*/
export const TireSize = (i: TireSizes): string => {
    switch (i) {
        case TireSizes.a112x24:
            return strLanguageText("slt112x24");
        case TireSizes.a112x38:
            return strLanguageText("slt112x38");
        case TireSizes.a11x225:
            return strLanguageText("slt11x225");
        case TireSizes.a136x38:
            return strLanguageText("slt136x38");
        case TireSizes.a149x24:
            return strLanguageText("slt149x24");
        case TireSizes.a169x24:
            return strLanguageText("slt169x24");
        case TireSizes.a32085R38:
            return strLanguageText("slt32085R38");
        case TireSizes.a32085R24:
            return strLanguageText("slt32085R24");
        case TireSizes.a38080R38:
            return strLanguageText("slt38080R38");
    }
}
const TireType = (i: TireTypes): string => {
switch (i) {
case TireTypes.FourPly:
return strLanguageText("sltFourPly");
break;
case TireTypes.MachII:
return strLanguageText("sltMachII");
break;
case TireTypes.Recaps:
return strLanguageText("sltRecaps");
break;
case TireTypes.SixPly:
return strLanguageText("sltSixPly");
break;
case TireTypes.Turf:
return strLanguageText("sltTurf");
break;
case TireTypes.Rhinogator:
return strLanguageText("sltRhinogator");
break;
case TireTypes.NonDirectional:
return strLanguageText("sltNonDirectional");
break;
case TireTypes.Radial:
return strLanguageText("sltRadial");
break;
case TireTypes.Shark:
return strLanguageText("sltShark");
break;
}
}
export const TireAndWheelText = (Tire: Tires): string => {
    return `${TireSize(Tire.TireSize)}-G  ${TireType(Tire.TireType)}`;
}
export const BuckBoostText = (i: BuckBoostTypes): string => {
switch (i) {
case BuckBoostTypes.aNone:
return strLanguageText("sltNone");
break;
case BuckBoostTypes.a15KVA:
return strLanguageText("slt15KVA");
break;
case BuckBoostTypes.a225KVA:
return strLanguageText("slt22_5KVA");
break;
}
}
/*const WrapAroundSpanText = (WrapAroundSpanType: WrapAroundSpanTypes): string => {
switch (WrapAroundSpanType) {
case WrapAroundSpanTypes.None:
return strLanguageText("sltNone");
break;
case WrapAroundSpanTypes.TenDegree:
return "10°";
break;
case WrapAroundSpanTypes.NinetyDegree:
return "90°";
break;
}
}
*//*export const IsNumericEx = (Expression: string): boolean => {
if (Expression.Substring(Expression.Length - 1, 1) === "%") {
return true;
}
return IsNumeric(Expression);
}
*//*export const ParseNumericValue = (Expression: string): number => {
if (InStr(1, Expression, "$")) {
Expression = Replace(Expression, "$", "");
}
if (InStr(1, Expression, ",")) {
Expression = Replace(Expression, ",", "");
}
if (InStr(1, Expression, "%")) {
Expression = Replace(Expression, "%", "");
}
if (Trim(Expression) === "") {
Expression = "0";
}
let result: number = 0;
Double.TryParse(Expression, result);
return result;
}
*//*export const SetHTMLStyleColor = (Element: HtmlElement, Color: string) => {
if (Element === null) {
return;
}
let s: string = Element.Style;
let c: string = "color: " + Color;
let PreColor: string = "";
if (s !== null && s.Length > 0) {
let styles: string = s.Split(";");
for (let style: string of styles) {
if (!(style.ToLower.indexOf("color") !== -1)) {
if (PreColor.Length > 0) {
PreColor += ";";
}
PreColor += style;
}
}
}
if (PreColor.Length > 0) {
PreColor += ";";
}
Element.Style = PreColor + c;
}
*//*export const HTMLInputElementValidateNumber = (WhichInput: HtmlElement) => {
let s: string = WhichInput.GetAttribute("value");
if (IsNumeric(s)) {
return;
}
switch (s) {
case ".":
case ",":
case "$":
case "%":
return;
break;
}
if (s.Length > 0) {
WhichInput.InnerText = s.Substring(0, s.Length - 1);
}
}
*/export const LeadType = (eTowerType: TowerHeights, bIsSACHingeTower: boolean): idxLeadType => {
    switch (eTowerType) {
        case TowerHeights.Standard:
            if (bIsSACHingeTower) {
                return idxLeadType.ltLong;
            }
            return idxLeadType.ltStandard;
            break;
        case TowerHeights.LowProfile:
            if (bIsSACHingeTower) {
                return idxLeadType.ltStandard;
            }
            return idxLeadType.ltLowProfile;
            break;
        case TowerHeights.Sugargator:
            return idxLeadType.ltSugargator;
            break;
        case TowerHeights.Supergator:
            return idxLeadType.ltSupergator;
            break;
    }
}
/*export const HtmlReplaceSpace = (strHTML: string): string => {
return Replace(strHTML, " ", "&nbsp;");
}
*//*export const PackageHeader = (): string => {
return "<TD><u>" + strLanguageText("sltPkg") + "</u></TD>" + "<TD><u>" + strLanguageText("sltPackageType") + "</u></TD>" + "<TD><u>" + strLanguageText("sltEnd") + "</u></TD>";
}
*//*export const DescribeANullPackageInHTML = (i: number): string => {
return "<TD>" + i + "</TD><TD>" + strLanguageText("sltNoPackage") + "</TD><TD>N/A</TD>";
}
*//*export const DescribeAPackageInHTML = (Package: Package): string => {
return "<TD>" + Package.PackageNumber + "</TD><TD>" + DeviceTypeText(Package.Device, Package.UseNelson3030, Package.UseNelsonAD3030MT) + "</TD><TD>" + spanf.EndingLocation(Package) > 0 ? Format(FeetToMeters(spanf.EndingLocation(Package)), "0.0") : "EOS" + "</TD>";
}
*//*export const TowerHeader = (): string => {
return "<TD><u>" + strLanguageText("sltTower") + "</u></TD>" + "<TD><u>" + strLanguageText("sltType") + "</u></TD>" + "<TD><u>" + strLanguageText("sltCenterDrive") + "</u></TD>" + "<TD><u>" + strLanguageText("sltTires") + "</u></TD>" + "<TD><u>" + strLanguageText("sltOptions") + "</u></TD>";
}
*//*export const DescribeATowerInHTML = (Tower: Tower, EDMP: boolean, bReversed: boolean): string => {
let t: string = "";
if (Tower.Guidance) {
t = strLanguageText("sltGdnc");
}
if (Tower.AgriTrac) {
t += ", " + strLanguageText("sltAgTrac");
}
if (Tower.WrapAroundSpan !== WrapAroundSpanTypes.None) {
t += ", " + WrapAroundSpanText(Tower.WrapAroundSpan);
}
if (Tower.BuckBoost !== BuckBoostTypes.aNone) {
t += ", " + BuckBoostText(Tower.BuckBoost);
}
if (Tower.TowerAutoReverse) {
t += ", " + strLanguageText("sltRev");
}
if (Tower.TowerAutoStop) {
t += ", " + strLanguageText("sltStop");
}
if (Tower.ThreeTires) {
if (Tower.ThreeTiresFlex) {
t += ", " + strLanguageText("sltTWTwrFlex");
}
else {
t += ", " + strLanguageText("slt3WTwr");
}
}
if (Tower.HeavyDuty) {
t += ", " + strLanguageText("sltHdLg");
}
if (Tower.TowerCrossBrace) {
t += ", " + strLanguageText("sltTwrBr");
}
if (Tower.StiffenerTrussToPipe) {
t += ", " + strLanguageText("sltStfTP");
}
if (Tower.StiffenerStabilizers) {
t += ", " + strLanguageText("sltStfStb");
}
if (Len(t) === 0) {
t = "&nbsp;";
}
else {
if (t.IndexOf(", ", StringComparison.Ordinal) === 0) {
t = t.Remove(0, 2);
}
}
let s: string = "<TD>" + Tower.TowerNumber + bReversed ? " R" : "" + "</TD><TD>" + TowerTypeText(Tower.TowerType) + "</TD><TD>" + CenterDriveText(Tower.CenterDrive, EDMP) + "</TD><TD>" + TireAndWheelText(Tower.Tires) + "</TD><TD>" + t + "</TD>";
return s;
}
*//*export const MLVHeader = (): string => {
return "<TD>&nbsp;</TD>" + "<TD><u>" + strLanguageText("sltQty") + "</u></TD>" + "<TD><u>" + strLanguageText("sltSize") + "</u></TD>" + "<TD><u>" + strLanguageText("sltPressure") + "</u></TD>" + "<TD><u>" + strLanguageText("sltControlFunction") + "</u></TD>" + "<TD><u>" + strLanguageText("sltControlFunctionOptional") + "</u></TD>" + "<TD><u>" + strLanguageText("sltOnOffMode") + "</u></TD>" + "<TD><u>" + strLanguageText("sltFilter") + "</u></TD>";
}
*//*export const DescribeAMLVInHTML = (MLV: MainlineValve): string => {
let sb: StringBuilder;
sb.Append("<TD>" + MLV.MainlineValveNumber + "</TD>" + "<TD>" + MLV.Quantity + "</TD>");
switch (MLV.Size) {
case ValveSizes.a4Inch:
sb.Append("<TD>" + strLanguageText("slt4Inch") + "</TD>");
break;
case ValveSizes.a6Inch:
sb.Append("<TD>" + strLanguageText("slt6inch") + "</TD>");
break;
case ValveSizes.a8Inch:
sb.Append("<TD>" + strLanguageText("slt8Inch") + "</TD>");
break;
}
switch (MLV.Pressure) {
case Pressures.a30To200Psi:
sb.Append("<TD>" + strLanguageText("slt30To200PSI") + "</TD>");
break;
case Pressures.a18To80Psi:
sb.Append("<TD>" + strLanguageText("slt18To80PSI") + "</TD>");
break;
}
switch (MLV.ControlFunction) {
case ControlFunctions.aManualOnOff:
sb.Append("<TD>" + strLanguageText("sltManualOnOff") + "</TD>");
break;
case ControlFunctions.a5To50PSIPressureReducingValve:
sb.Append("<TD>" + strLanguageText("slt5To50PSIPressureReducingValve") + "</TD>");
break;
case ControlFunctions.a25To120PSIPressureReducingValve:
sb.Append("<TD>" + strLanguageText("slt25To120PSIPressureReducingValve") + "</TD>");
break;
case ControlFunctions.a25To200PSIPressureReducingValve:
sb.Append("<TD>" + strLanguageText("slt25To200PSIPressureReducingValve") + "</TD>");
break;
}
switch (MLV.ControlFunctionOption) {
case ControlFunctionOptions.None:
sb.Append("<TD>" + strLanguageText("sltNone") + "</TD>");
break;
case ControlFunctionOptions.SleeveExhaust:
sb.Append("<TD>" + strLanguageText("sltSleeveExhaust") + "</TD>");
break;
case ControlFunctionOptions.RateOfFlowValve:
sb.Append("<TD>" + strLanguageText("sltRateOfFlowValve") + "</TD>");
break;
}
switch (MLV.OnOffMode) {
case OnOffModes.aNoSolenoid:
sb.Append("<TD>" + strLanguageText("sltNoSolenoid") + "</TD>");
break;
case OnOffModes.a12To24VDCLatchSolenoid_5AmpMax:
sb.Append("<TD>" + strLanguageText("slt12To24VDCLatchSolenoid_5AmpMax") + "</TD>");
break;
case OnOffModes.a12VDCSolenoid_6AmpMax:
sb.Append("<TD>" + strLanguageText("slt12VDCSolenoid_6AmpMax") + "</TD>");
break;
case OnOffModes.a24VDCSolenoid_3AmpMax:
sb.Append("<TD>" + strLanguageText("slt24VDCSolenoid_3AmpMax") + "</TD>");
break;
case OnOffModes.a24VACSolenoid_3AmpInrushCurrent:
sb.Append("<TD>" + strLanguageText("slt24VACSolenoid_3AmpInrushCurrent") + "</TD>");
break;
case OnOffModes.a24VACSolenoid1_1AmpInrushHeavyDuty:
sb.Append("<TD>" + strLanguageText("slt24VACSolenoid1_1AmpInrushHeavyDuty") + "</TD>");
break;
case OnOffModes.a24VACSolenoid3_6AmpInrushCurrent:
sb.Append("<TD>" + strLanguageText("slt24VACSolenoid3_6AmpInrushCurrent") + "</TD>");
break;
case OnOffModes.a120VACSolenoid1_16Inch:
sb.Append("<TD>" + strLanguageText("slt120VACSolenoid1_16Inch") + "</TD>");
break;
case OnOffModes.a120VACSolenoid1_8Inch:
sb.Append("<TD>" + strLanguageText("slt120VACSolenoid1_8Inch") + "</TD>");
break;
case OnOffModes.aHydraulicRemoteControl:
sb.Append("<TD>" + strLanguageText("sltHydraulicRemoteControl") + "</TD>");
break;
}
switch (MLV.Filter) {
case Filters.ExternalFilter:
sb.Append("<TD>" + strLanguageText("sltExternalFilter") + "</TD>");
break;
case Filters.InternalFilter:
sb.Append("<TD>" + strLanguageText("sltInternalFilter") + "</TD>");
break;
}
return sb.ToString;
}
*//*export const SpanHeader = (): string => {
return "<TD><u>" + strLanguageText("sltSpan") + "</u></TD>" + "<TD><u>" + strLanguageText("sltType") + "</u></TD>" + "<TD><u>" + strLanguageText("sltLength") + "</u></TD>" + "<TD><u>" + strLanguageText("sltSpacing") + "</u></TD>" + "<TD><u>" + strLanguageText("sltCable") + "</u></TD>" + "<TD><u>" + strLanguageText("sltExtraConductors") + "</u></TD>" + "<TD><u>" + strLanguageText("sltDistance") + "</u></TD>" + "<TD><u>" + strLanguageText("sltOptions") + "</u></TD>";
}
*//*export const DescribeASpanInHTML = (Span: Span, bEDMP: boolean, bAutoSized: boolean, bRigid: boolean): string => {
let t: string = "";
if (Span.Extension) {
t = strLanguageText("sltExt");
}
if (Span.Disconnecting) {
t += ", " + strLanguageText("sltDisc");
}
if (Span.LargeTrussRods) {
t += ", " + strLanguageText("sltTrRd");
}
if (Span.Stabilizers) {
t += ", " + strLanguageText("sltSpnStb");
}
if (Len(t) === 0) {
t = "&nbsp;";
}
else {
if (t.IndexOf(", ", StringComparison.Ordinal) === 0) {
t = t.Remove(0, 2);
}
}
return "<TD>" + Span.SpanNumber + bRigid ? "R" : "" + "</TD><TD>" + SpanTypeText(Span.SpanType) + "</TD><TD>" + DistanceStringWithUnits(SpanLengthsToFeet(Span.Length)) + "</TD><TD>" + SpacingText2(Span.Spacing) + "</TD><TD>" + SpanCableText(bAutoSized ? Span.CableGauge : Span.MinCableGauge, bEDMP) + "</TD><TD>" + Span.ExtraCableConductors + "</TD><TD>" + DistanceStringWithUnits(spanf.EndingLocation(Span)) + "</TD><TD>" + t + "</TD>";
}
*/export const CenterDriveText = (gdt: GearDriveTypes, bEDMP: boolean): string => {
switch (gdt) {
case GearDriveTypes.Standard:
if (!(bEDMP)) {
return strLanguageText("sltStd_481_36Rpm");
}
else {
return strLanguageText("slt60to1UMC");
}
break;
case GearDriveTypes.High:
if (!(bEDMP)) {
return strLanguageText("sltHigh_241_72Rpm");
}
else {
return strLanguageText("slt40to1UMC");
}
break;
case GearDriveTypes.Low:
if (!(bEDMP)) {
return strLanguageText("sltLow_721_24Rpm");
}
else {
return strLanguageText("slt30to1UMC");
}
break;
case GearDriveTypes.TwoThirtyVolt:
return strLanguageText("slt230Volt");
break;
}
}
/*const TowerTypeText = (i: TowerHeights): string => {
switch (i) {
case TowerHeights.Standard:
return strLanguageText("sltStandard");
break;
case TowerHeights.LowProfile:
return strLanguageText("sltLowProfile");
break;
case TowerHeights.Sugargator:
return strLanguageText("sltSugargator");
break;
case TowerHeights.Supergator:
return strLanguageText("sltSupergator");
break;
}
}
*//*export const QuoteComplete = (Quote: QuoteClass, SuppressDialogs: boolean): boolean => {
let IsInternational = ohf.IsInternational(this.Quote.System.DealerProperties);
if ((!(AppClass.Instance.ReinkeUserMode()) || AppClass.Instance.DebugShipDate) && this.Quote.SystemTypeClass.FieldSet.DataValid()) {
if (!(this.Quote.InfoClass.FieldSet.DataValid())) {
if (!(SuppressDialogs)) {
if (IsInternational) {
MsgBox(strLanguageText("sltPleaseCompleteProposalWOShipDate"));
}
else {
MsgBox(strLanguageText("sltPleaseCompleteProposalWShipDate"));
}
}
return false;
}
let qp = this.Quote.System.QuoteProperties;
if (!(ValidateCustomerData(new CustomerDataFieldsDto()))) {
return false;
}
}
return true;
}
*/export class CustomerDataFieldsDto {
    public SuppressDialogs: boolean;
    public IsInternational: boolean;
    public ShipDate: string;
    public BypassShippingValidation: boolean;
    public SystemType?: SystemTypes;
    public HasSwingArmCordner: boolean;
    public DealerName: string;
    public CustomerName: string;
    public Address: string;
    public City: string;
    public State: string;
    public Zip: string;
    public ShipVia: string;
    public Salesperson: string;
}
/*export const ValidateCustomerData = (data: CustomerDataFieldsDto): boolean => {
if (AppClass.Instance.ReinkeUserMode() && !(AppClass.Instance.DebugShipDate)) {
return true;
}
if (!(ohf.IsInternational(data))) {
if (!(String.IsNullOrWhiteSpace(data.ShipDate))) {
if (!(IsDate(data.ShipDate))) {
if (!(data.SuppressDialogs)) {
MsgBox(strLanguageText("sltPleaseEnterRequestedShipDate"), MsgBoxStyle.Critical);
}
return false;
}
let d: Date;
Date.TryParse(data.ShipDate, d);
if (d <= Now.Date) {
if (!(data.SuppressDialogs)) {
MsgBox(strLanguageText("sltShippingMustBeInFuture"), MsgBoxStyle.Critical);
}
return false;
}
if ([DayOfWeek.Saturday, DayOfWeek.Sunday].indexOf(d.DayOfWeek) !== -1) {
if (!(data.SuppressDialogs)) {
MsgBox(strLanguageText("sltNoSatSunShipDate"), MsgBoxStyle.Critical);
}
return false;
}
}
if (String.IsNullOrWhiteSpace(data.ShipVia)) {
if (!(data.SuppressDialogs)) {
MsgBox(strLanguageText("sltPleaseEnterShipVia"), MsgBoxStyle.Critical);
}
return false;
}
}
if (String.IsNullOrWhiteSpace(data.CustomerName) || UCase(Trim(data.CustomerName)) === UCase(Trim(data.DealerName))) {
if (!(data.SuppressDialogs)) {
MsgBox(strLanguageText("sltCustomerNameCannotBeDealerName"), MsgBoxStyle.Critical);
}
return false;
}
if (String.IsNullOrWhiteSpace(data.Salesperson)) {
if (!(data.SuppressDialogs)) {
MsgBox(strLanguageText("sltPleaseCompleteSalesPerson"), MsgBoxStyle.Critical);
}
return false;
}
if (String.IsNullOrWhiteSpace(data.Address)) {
if (!(data.SuppressDialogs)) {
MsgBox(strLanguageText("sltPleaseCompleteAddress"), MsgBoxStyle.Critical);
}
return false;
}
if (String.IsNullOrWhiteSpace(`${data.City}${data.State}${data.Zip}`) && !(ohf.IsInternational(data))) {
if (!(data.SuppressDialogs)) {
MsgBox(strLanguageText("sltPleaseCompleteCityStateZip"), MsgBoxStyle.Critical);
}
return false;
}
return true;
}
*/export const RollsToOrder = (FeetNeeded: number, FeetPerRoll: number): number => {
    let i: number = Math.floor(FeetNeeded / FeetPerRoll);
    FeetNeeded -= i * FeetPerRoll;
    if (FeetNeeded > 0) {
        i += 1;
    }
    return i;
}
/*export const FileToStringUTF8Encoding = (strFileName: string): string => {
Using fs As FileStream = File.Open(strFileName, FileMode.Open, FileAccess.Read)
            Dim buffer(fs.Length - 1) As Byte
            fs.Read(buffer, 0, fs.Length)
            Return Encoding.UTF8.GetString(buffer) ' UTF8Encoding.UTF8.GetString(buffer)
        End Using
}
*//*export const FileToStringDefaultEncoding = (strFileName: string): string => {
Using fs As FileStream = File.Open(strFileName, FileMode.Open, FileAccess.Read)
            Dim buffer(fs.Length - 1) As Byte
            fs.Read(buffer, 0, fs.Length)
            Return Encoding.Default.GetString(buffer)
        End Using
}
*//*export const StringToFileUTF8Encoding = (s: string, strFileName: string) => {
Using fs As FileStream = File.Open(strFileName, FileMode.Create, FileAccess.Write)
            Dim bytes As Byte() = Encoding.UTF8.GetBytes(s)
            fs.Write(bytes, 0, bytes.Length)
            '  fs.Close()
        End Using
}
*//*const ReplaceSpanTag = (strHTML: string, SpanTagID: string, NewSpanTag: string): boolean => {
let len: number = strHTML.Length;
if (len < 1) {
return false;
}
let iStart: number = InStr(1, strHTML, "<span id="" + SpanTagID, CompareMethod.Text);
if (iStart < 1) {
iStart = InStr(1, strHTML, "<span id=" + SpanTagID, CompareMethod.Text);
if (iStart < 1) {
return false;
}
}
let iEnd: number = InStr(iStart, strHTML, "</span>", CompareMethod.Text);
if (iEnd < 1) {
return false;
}
iEnd += 6;
let p1: string = strHTML.Substring(0, iStart - 1);
let p2: string = strHTML.Substring(iEnd, len - iEnd);
strHTML = p1 + NewSpanTag + p2;
return true;
}
*//*export const ReferenceImagesToLocal = (s: string) => {
while (ReplaceSpanTag(s, "ReinkeLogo", Logo2001aEmbed)) {}
while (ReplaceSpanTag(s, "ReinkeEII", LogoEIIEmbed)) {}
}
*//*export const ReferenceImagesToLocalForOldReports = (s: string) => {
while (ReplaceSpanTag(s, "logo", Logo2001aEmbed)) {}
while (ReplaceSpanTag(s, "eii", LogoEIIEmbed)) {}
}
*/export const SprinklerBrand = (Device: DeviceTypes): SprinklerManufacturerTypes => {
    switch (Device) {
        case DeviceTypes.Nelson1518MediumSpacingBrassImpact:
        case DeviceTypes.NelsonR3000PartCircle:
        case DeviceTypes.Nelson15Single3RNBrassImpact:
        case DeviceTypes.Nelson15Double3RNBrassImpact:
        case DeviceTypes.Nelson15DoubleFCNBrassImpact:
        case DeviceTypes.Nelson8Double3RNPlasticImpact:
        case DeviceTypes.Nelson8DoubleFCNPlasticImpact:
        case DeviceTypes.NelsonS3000SpinnerRed:
        case DeviceTypes.NelsonS3000SpinnerPurple:
        case DeviceTypes.NelsonS3000SpinnerYellow:
        case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure:
        case DeviceTypes.NelsonS3000SpinnerYellowLowPressure:
        case DeviceTypes.NelsonD3000Spray:
        case DeviceTypes.NelsonD3000FCNSpray:
        case DeviceTypes.NelsonR3000RotatorRed:
        case DeviceTypes.NelsonR3000RotatorBlue:
        case DeviceTypes.NelsonR3000FCNRotatorBlue:
        case DeviceTypes.NelsonR3000RotatorGreen:
        case DeviceTypes.NelsonR3000FCNRotatorGreen:
        case DeviceTypes.NelsonR3000RotatorOrange:
        case DeviceTypes.NelsonR3000RotatorBrown:
        case DeviceTypes.NelsonR3000RotatorOlive:
        case DeviceTypes.NelsonR3000RotatorWhite:
        case DeviceTypes.NelsonA3000AcceleratorMaroon:
        case DeviceTypes.NelsonD3000PartCircle:
        case DeviceTypes.NelsonS3000PartCircle:
        case DeviceTypes.NelsonA3000AcceleratorGold:
        case DeviceTypes.NelsonO3000OrbitorBlack:
        case DeviceTypes.NelsonO3000OrbitorBlue:
        case DeviceTypes.NelsonO3000OrbitorPurple:
        case DeviceTypes.NelsonA3000AcceleratorNavy:
        case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
            return SprinklerManufacturerTypes.Nelson;
            break;
        case DeviceTypes.KometSpray:
        case DeviceTypes.KometSprayPartCircle:
        case DeviceTypes.KometTwisterBlack:
        case DeviceTypes.KometTwisterBlue:
        case DeviceTypes.KometTwisterYellow:
            return SprinklerManufacturerTypes.Komet;
            break;
        default:
            return SprinklerManufacturerTypes.Senninger;
            break;
    }
}
/*export const OntracSubscriptionText = (SubscriptionPeriod: RadioTelemetrySubscriptionPeriods): string => {
switch (SubscriptionPeriod) {
case RadioTelemetrySubscriptionPeriods.a4Months:
return "4 Month";
break;
case RadioTelemetrySubscriptionPeriods.a6Months:
return "6 Month";
break;
case RadioTelemetrySubscriptionPeriods.a9Months:
return "9 Month";
break;
default:
return "12 Month";
break;
}
}
*/export const Interprolate = (Point: number, Boundary1: number, Boundary2: number, Bookend1: number, Bookend2: number): number => {
let Ratio: number = (Point - Boundary1) / (Boundary2 - Boundary1);
return Ratio * (Bookend2 - Bookend1) + Bookend1;
}
export const FloatsAreEqual = (f1: number | undefined, f2: number): boolean => {
    if (typeof f1 === "undefined" && f2 === 0) return true;
if (f1 === f2) {
return true;
}
const sign1 = f1 > 0 ? 1 : f1 < 0 ? -1 : 0;
const sign2 = f2 > 0 ? 1 : f2 < 0 ? -1 : 0;
if (sign1 !== sign2) {
return false;
}
let d: number = Math.abs(f1 - f2);
if (f2 === 0) {
return d < 1E-05;
}
else {
return d / Math.abs(f2) < 1E-05;
}
}
/*export const IsDealerLogoPresent = (): boolean => {
if (DealerLogo.Width >= 400 && DealerLogo.Height >= 200) {
return true;
}
return false;
}
*//*export const ResetDealerLogo = () => {
_dealerLogo = new Lazy<Image>();
}
*//*_dealerLogo: Lazy<Image>;*/
/*public DealerLogo = (): Image => {
return _dealerLogo.Value;
}
*//*_dealerLogoFormat: ImageFormat = ImageFormat.Png;*/
/*public DealerLogoFormat = (): ImageFormat => {
return _dealerLogoFormat;
}
*//*export const ViewPdfReport = (strFilenamePath: string, strPdfTitle: string = "PDF Report") => {
if (AppClass.Instance.blnConstrainPdfs) {
if (File.Exists(strFilenamePath)) {
let PDF: FHTML2;
PDF.OpenPDFAsNonModal(strFilenamePath, strPdfTitle);
}
else {
MsgBox(StringFormat(strLanguageText("sltFileMissing"), strFilenamePath));
}
}
else {
try {Process.Start(strFilenamePath);
}
catch (ex) {
MsgBox(StringFormat(strLanguageText("sltApplicationNotFoundPdf"), strFilenamePath));
}
}
}
*//*export const InternetWorks = (): boolean => {
if (!(NetworkInterface.GetIsNetworkAvailable())) {
return false;
}
try {let cs = new ClientSettings().ApplyDefaults();
SessionState.UseHttps = true;
cs.BaseUri = SessionState.VersionUri;
let client: UtilitiesClient;
if (client.Ping() === WebAPI.Common.Constants.WebAPI_Ping_Result) {
return true;
}
}
catch {
return false;
}
return false;
}
*//*export const ValidProposalInformationFields = (frmquote: QuoteForm): boolean => {
if (!(sysf.FieldSets(this.Quote.System).QuoteProperties.DataValid())) {
return false;
}
let qp = frmquote.Quote.System.QuoteProperties;
if (String.IsNullOrWhiteSpace(qp.CustomerName) || String.IsNullOrWhiteSpace(qp.Salesperson) || String.IsNullOrWhiteSpace(qp.Address1) || String.IsNullOrWhiteSpace(qp.Address2City) || String.IsNullOrWhiteSpace(qp.Address2Country) || String.IsNullOrWhiteSpace(qp.Address2Zip)) {
return false;
}
if (qp.Address2Country !== "NZL" && String.IsNullOrWhiteSpace(qp.Address2State)) {
return false;
}
let cpi = qp.CustomerPaymentInfo;
if (cpi.PaymentType === PaymentTypes.NotSelected) {
}
return true;
}
*//*export const ValidCustomerPaymentInfo = (frmquote: QuoteForm): boolean => {
if (!(sysf.FieldSets(this.Quote.System).QuoteProperties.DataValid())) {
return false;
}
let cpi = frmquote.Quote.System.QuoteProperties.CustomerPaymentInfo;
if (cpi.PaymentType === PaymentTypes.NotSelected) {
return false;
}
return true;
}
*//*export const NewVersionAvailable = (newVersion: string, ver: System.Version): boolean => {
let majorVersionString: string = newVersion.Substring(0, 1);
let minorVersionString: string = newVersion.Substring(1, 2);
let buildVersionString: string = newVersion.Substring(3, 3);
let revisionVersionString: string = newVersion.Substring(6, 3);
if (ParseNumericValue(majorVersionString) >= ver.Major) {
if (ParseNumericValue(majorVersionString) > ver.Major) {
return true;
}
else {
if (ParseNumericValue(minorVersionString) >= ver.Minor) {
if (ParseNumericValue(minorVersionString) > ver.Minor) {
return true;
}
else {
if (ParseNumericValue(buildVersionString) >= ver.Build) {
if (ParseNumericValue(buildVersionString) > ver.Build) {
return true;
}
else {
if (ParseNumericValue(revisionVersionString) > ver.Revision) {
return true;
}
}
}
}
}
}
}
return false;
}
*//*export const SendErrorToCloud = (qc: QuoteClass, msg: string, dt: DateTime, ex: Exception = null, bypassAppClass: boolean = false): boolean => {
try {let err = new ErrorCreateRequest();
let cs = new ClientSettings().ApplyDefaults();
SessionState.UseHttps = true;
cs.BaseUri = SessionState.VersionUri;
let _client = new ErrorClient();
_client.Send(err);
}
catch (exep) {
if (!(AppClass.Instance.RunMode === RunModes.Production)) {
MessageBox.Show("Sending Error Failed!");
}
}
}
*//*export const GetOSProductName = (): string => {
Using key As RegistryKey = Registry.LocalMachine,
            skey As RegistryKey = key.OpenSubKey("SOFTWARE\Wow6432Node\Microsoft\Windows NT\CurrentVersion")
            Dim pn = skey.GetValue("ProductName").ToString()
            Return pn
        End Using
}
*//*export const GetErrorInfoHeader = (dt: DateTime, suppressErrorHeader: boolean = false): string => {
let sb = new StringBuilder();
if (!(suppressErrorHeader)) {
sb.AppendLine(`An application error occurred. Please contact the Reinke with the following information:`).AppendLine();
}
sb.AppendLine(`Program Version: ${My.Application.Info.Version.ToString()}`);
sb.AppendLine(`OS: ${GetOSProductName()} ${Environment.Is64BitOperatingSystem ? "(64-Bit)" : "(32-Bit)"}`);
sb.AppendLine(`IsAdmin: ${IsAdmin()}`);
sb.AppendLine(`IsPowerUser: ${IsPowerUser()}`);
sb.AppendLine(`Time: ${dt}`);
sb.AppendLine(`DealerNumber: ${AppClass.Instance.Config.DealerNumber}`);
sb.AppendLine(`Language: ${AppClass.Instance.Config.Language}`);
sb.AppendLine(`Measurement: ${AppClass.Instance.Config.Measurement}`);
sb.AppendLine(`Country: ${AppClass.Instance.Config.Country}`);
sb.AppendLine(`CurrentRegion-Name: ${RegionInfo.CurrentRegion.EnglishName}`);
sb.AppendLine(`CurrentCulture: ${JsonConvert.SerializeObject(CultureInfo.CurrentCulture, Formatting.Indented, new JsonSerializerSettings())}`);
return sb.ToString();
}
*/export const SelectDualSprinklerPackageValveBoxTowerNumber = (sys: ISystemBase, flangedSide: Side): number => {
    let sprinklerBoxTowerNumber: number = 0;
    let towerCount: number = sidef.Towers(flangedSide).Count;
    if (towerCount === 2) {
        sprinklerBoxTowerNumber = 1;
    }
    else {
        let sysMidpoint: number = sidef.Spans(sys, flangedSide).ToList().map((s) => SpanLengthsToFeet(s.Length)).reduce((p, v) => p + v, 0) / 2;
        let sysTestDistance: number = 0;
        for (let towerNumber: number = 1; towerNumber <= towerCount; towerNumber++
        ) {
            sysTestDistance += SpanLengthsToFeet(flangedSide.Span[towerNumber - 1].Length);
            if (sysTestDistance > sysMidpoint) {
                break;
            }
            sprinklerBoxTowerNumber = towerNumber;
        }
    }
    return sprinklerBoxTowerNumber;
}
export const RC10PanelModelsToPanelModel = (input: RC10PanelModels): PanelModels => {
    switch (input) {
        case RC10PanelModels.RPMBasic:
            return PanelModels.RPMBasic;
            break;
        case RC10PanelModels.RPMStandard:
            return PanelModels.RPMStandard;
            break;
        case RC10PanelModels.RPMAdvanced:
            return PanelModels.RPMAdvanced;
            break;
        case RC10PanelModels.RPMAdvancedPlus:
            return PanelModels.RPMAdvancedPlus;
            break;
        case RC10PanelModels.RPMConnect:
            return PanelModels.RPMConnect;
            break;
        case RC10PanelModels.RPMPreferred:
            return PanelModels.RPMPreferred;
            break;
        case RC10PanelModels.RPMStandardWithAutoReverse:
            return PanelModels.RPMStandard;
            break;
        case RC10PanelModels.RPMPrefTouchScreen:
            return PanelModels.RPMPreferred;
            break;
    }
}
/*export const GetDealerDiscount = (): wv3d.DiscountConfig => {
SessionState.UseHttps = true;
let settings = new ClientSettings().ApplyDefaults();
settings.BaseUri = SessionState.VersionUri;
let client: DiscountClient;
let req = new DiscountActiveRequest();
let resp = client.GetActive(req);
if (resp.ResultInfo.Type !== ResultType.Success) {
// new Exception()
throw new Error();
}
return resp.DiscountConfig;
}
*/

/**
 * Max tower speed relates to the motor, gears, tires and electrical frequency. 
 * Each tower configuration will have a max speed, but the last tower of the 
 * Center Pivot is of most interest. 
 * This calculation may be used for any tower, but Laterals may also use this. 
 */
export const CalculateMaxTowerSpeed = (tireSize: TireSizes, tireType: TireTypes, gearDriveType: GearDriveTypes, electricalFrequency: ElectricalFrequencies): number =>
{
    let wheelGearRatio: number;
    switch (gearDriveType) {
        case GearDriveTypes.Standard:
            wheelGearRatio = 40
            break;
        case GearDriveTypes.High:
            wheelGearRatio = 25.5
            break;
        case GearDriveTypes.Low:
            wheelGearRatio = 60
            break;
    }

    const circumference = GetRollingCircumference(tireSize, tireType);

    const motorRpm = electricalFrequency === ElectricalFrequencies.a60 ? 1745.0 : 1425.0;
    const rpm = (1 / 50) * (1 / wheelGearRatio) * motorRpm;
    return circumference * rpm / 12;
}

/**
 * returns the appropriate Circumference value given a combination of TireSizes and TireTypes.
 */
export const GetRollingCircumference = (tireSize: TireSizes, tireType: TireTypes): number => {
    switch (tireSize) {
        case TireSizes.a11x225:
            switch (tireType) {
                case TireTypes.Recaps:
                    return 137.25
                case TireTypes.MachII:
                    return 119.5
            }
        case TireSizes.a112x24:
            switch (tireType) {
                case TireTypes.FourPly:
                    return 134.0
                case TireTypes.MachII:
                    return 121.34
                case TireTypes.Rhinogator:
                    return 124.0
            }
        case TireSizes.a149x24:
            switch (tireType) {
                case TireTypes.FourPly:
                    return 146.0
                case TireTypes.NonDirectional:
                    return 143.0
                case TireTypes.Radial:
                    return 148.78 //NOTE: See 380/85R24 Radial 
                case TireTypes.Turf:
                    return 144.0
                case TireTypes.MachII:
                    return 133.17
                case TireTypes.Rhinogator:
                    return 144.0
            }
        case TireSizes.a169x24:
            switch (tireType) {
                case TireTypes.SixPly:
                    return 155.0
                case TireTypes.Turf:
                    return 150.63
                case TireTypes.Shark:
                    return 133.51
            }
        case TireSizes.a112x38:
            switch (tireType) {
                case TireTypes.FourPly:
                    return 172.0
                case TireTypes.NonDirectional:
                    return 172.0
                case TireTypes.MachII:
                    return 151.2
                case TireTypes.Rhinogator:
                    return 163.0
            }
        case TireSizes.a136x38:
            switch (tireType) {
                case TireTypes.SixPly:
                    return 185.3
                case TireTypes.MachII:
                    return 152.0
            }
        case TireSizes.a32085R38:
            if (tireType === TireTypes.Radial) return 183.93
            break;
        case TireSizes.a32085R24:
            if (tireType === TireTypes.Radial) return 138.0
            break;
        case TireSizes.a38080R38:
            if (tireType === TireTypes.Radial) return 188.27
            break;
                    //38085R38 Radial	28.8 ???? IS THIS /85R OR /80R ?????
        case TireSizes.a38085R24:
            throw new Error("{NameOf(TireSizes)}.{tireSize} is obsolete and should never be used.")
            //If tireType = TireTypes.Radial Then return 148.78 //??? 151.0
        case TireSizes.a184x26:
            throw new Error("{NameOf(TireSizes)}.{tireSize} is obsolete and should never be used.")
            //return 169.0
    }
    throw new Error("Circumference value required for combination - {NameOf(TireSizes)}:{tireSize}, {NameOf(TireTypes)}{tireType}")
}

export const GetRollingRadius = (tireSize: TireSizes, tireType: TireTypes): number => {
    const circumference = GetRollingCircumference(tireSize, tireType);
    const radius = circumference / (2 * Math.PI);
    return radius;
}

export const GetRollingRadiusStaticValues = (tireSize: TireSizes, tireType: TireTypes): number => {
    let dRollingRadius = 0;
    switch (tireSize) {
        case TireSizes.a11x225:
            switch (tireType) {
                case TireTypes.Recaps:
                    dRollingRadius = 21.84
                case TireTypes.MachII:
                    dRollingRadius = 19.02
            }
        case TireSizes.a112x24:
            switch (tireType) {
                case TireTypes.FourPly:
                    dRollingRadius = 21.33
                case TireTypes.MachII:
                    dRollingRadius = 19.31
                case TireTypes.Rhinogator:
                    dRollingRadius = 19.74
            }
        case TireSizes.a149x24:
            switch (tireType) {
                case TireTypes.FourPly:
                    dRollingRadius = 23.24
                case TireTypes.Turf:
                    dRollingRadius = 22.1
                case TireTypes.MachII:
                    dRollingRadius = 21.19
                case TireTypes.Rhinogator:
                    dRollingRadius = 22.92
                case TireTypes.NonDirectional:
                    dRollingRadius = 22.92
            }
        case TireSizes.a169x24:
            switch (tireType) {
                case TireTypes.SixPly:
                    dRollingRadius = 24.67
                case TireTypes.Turf:
                    dRollingRadius = 23.97
                case TireTypes.Shark:
                    dRollingRadius = 23.24
            }
        case TireSizes.a112x38:
            switch (tireType) {
                case TireTypes.FourPly:
                    dRollingRadius = 27.37
                case TireTypes.MachII:
                    dRollingRadius = 24.06
                case TireTypes.Rhinogator:
                    dRollingRadius = 25.94
                case TireTypes.NonDirectional:
                    dRollingRadius = 27.37
            }
        case TireSizes.a136x38:
            switch (tireType) {
                case TireTypes.SixPly:
                    dRollingRadius = 29.49
                case TireTypes.MachII:
                    dRollingRadius = 24.19
            }
        case TireSizes.a184x26:
            throw new Error("Obsolete and should never be used.")
        case TireSizes.a32085R38: //TireTypes.Radial ONLY
            dRollingRadius = 29.27
        case TireSizes.a32085R24: //TireTypes.Radial ONLY
            dRollingRadius = 21.96
        case TireSizes.a38080R38: //TireTypes.Radial ONLY
            dRollingRadius = 29.96
                    //38085R38 Radial	28.8 ???? IS THIS /85R OR /80R ?????
        case TireSizes.a38085R24: //TireTypes.Radial ONLY
            dRollingRadius = 23.68
    }
    return dRollingRadius
}