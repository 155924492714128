import { Typography } from "@mui/material";
import * as React from "react";

interface Props {
}


export const ErrorBody: React.FC<Props> = ({ }) => {
    return (
        <>                                
            <Typography alignSelf={'center'} variant="subtitle1">A problem has occured trying to optimize this field</Typography>
        </>
    )
}