import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import { FC, useState } from "react";
import { createNewUpdateLayoutPropertyAction } from "../../../../actions/UpdateLayoutProperty";
import AuthCtx from "../../../../auth/AuthCtx";
import DbCtx from "../../../../db/DbCtx";

interface Props {
  layoutId: string;
  projectId: string;
  onClose: () => void;
}

const RenameLayoutDialog: FC<Props> = (props) => {
  const authState = React.useContext(AuthCtx);
  const dbState = React.useContext(DbCtx);

  const dbProject = dbState.projects[props.projectId];
  const project = dbProject.state;
  const layout = project.layouts[props.layoutId];

  const ns = "projects.";

    const [newLayoutName, setNewLayoutName] = useState("");
    const getNewLayoutName = () => newLayoutName.trim();

    let errorText = "";
    if (getNewLayoutName() === layout.name) {
      errorText = i18next.t(ns + "new-layout-name-same");
    }
    else if (
      Object.entries(project.layouts)
        .filter(([id,_]) => id !== props.layoutId)
        .map(([_, x]) => x.name)
        .includes(getNewLayoutName())
    ) {
      errorText = i18next.t(ns + "new-layout-name-unique");
    }
    else if (getNewLayoutName().length > 80) {
      errorText = i18next.t(ns + "new-layout-name-long");
    }

    const hasError = errorText !== "";

    return (
      <Dialog onClose={props.onClose} open={true} fullWidth>
        <DialogTitle>{i18next.format(`${i18next.t("rename")} ${i18next.t("layout")}`, 'capitalize')}</DialogTitle>
          <DialogContent>
              <DialogContentText>
                {i18next.t(ns + "current-layout-name")}: {layout.name}
              </DialogContentText>
              <TextField
                error={hasError}
                autoFocus
                id="label-text"
                fullWidth
                variant="standard"
                onChange={(e) => {
                  setNewLayoutName(e.target.value);
                }}
                helperText={errorText || " "}
              />
          </DialogContent>
          <DialogActions>
            <Button 
              disabled={hasError || getNewLayoutName() === ""}
              onClick={() => {
                dbProject.pushAction(
                  createNewUpdateLayoutPropertyAction(
                    props.layoutId,
                    "name",
                    getNewLayoutName(),
                    authState
                  )
                )
                props.onClose();
            }}>{i18next.t("ok")}</Button>
            <Button onClick={props.onClose}>{i18next.t("cancel")}</Button>
          </DialogActions>
      </Dialog>
    );
  }

  export default RenameLayoutDialog;