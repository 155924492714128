import { PressureTransducerOptions } from "rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import { IsLateralOnly, IsPivotingLateral } from "../SystemFunctions";
import { PressureTransducerOK, PressureTransducerOKDto } from "../Valids.MainControlPanel";

export default class ControlPanelForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        let cp = this.Quote?.System?.ControlPanel;
        if (cp.PanelModel){
            //if low pressure safety is selected then we don't need to check pressure transducer
            let dto: PressureTransducerOKDto = {
                PanelModel: cp.PanelModel,
                PivotingLateral: IsPivotingLateral(this.Quote.System),
                LateralOnly: IsLateralOnly(this.Quote.System),
                PressureSwitch: cp.LowPressureSafetyLP || cp.LowPressureSafetyReg,
                EndTowerPosistionOption: cp.EndTowerPositioning,
                SystemType: this.Quote.System.SystemProperties.SystemType,
                HoseFeedType: this.Quote.System.Lateral.HoseFeed.HoseFeedType
            }

            if (!PressureTransducerOK(cp.PressureTransducerOptions ?? PressureTransducerOptions.None, dto)){
                this.Quote.Warnings.AddWithTargets(["ControlPanel.PressureTransducerOptions"],`Pressure transducer invalid`);
            }
        }
    }
}