// Imports System.Collections.Specialized
// Imports System.Diagnostics.CodeAnalysis
// Imports System.Threading

// 'The generic OrderedDictionary class is primarly used for the MotorsOrderedDictionary and also used for managing translations
// 'It is a mashup of List (which keeps it ordered by index) and Hashtable (which allows a lookup by key)
// 'It is different than a SortedList (which is ordered by key and allows lookup both by key and by index)
// 'It is different than a SortedDictionary (which is ordered by key and allows lookup only by key)
// 'It is different than a Dictionary (which is not-ordered and allows lookup only by key)
// 'It is different than a List (which is ordered by index and allows lookup only by index)
// 'The reason is that the Motors are kept in a specific order from Power Tower to the end and the electrical calculator expects them in that order
// 'However, once they are added, key lookup operations like this "Motors.Item(MotorLocations.BoosterPump)" need to be done as well. 

// ' ReSharper Disable All
// Public Interface IOrderedDictionary(Of TKey, TValue)
//     Inherits IOrderedDictionary
//     Inherits IDictionary(Of TKey, TValue)
//     Shadows Function Add(key As TKey, value As TValue) As Integer
//     Shadows Sub Insert(index As Integer, key As TKey, value As TValue)
//     Default Shadows Property Item(index As Integer) As TValue
// End Interface

export default class OrderedDictionary<K, V> {

    protected _m: Map<K,V> = new Map<K,V>()

    public Add(key: K, value: V) {
        this._m.set(key, value);
        return this._m.size - 1;
    }

    public get Count() {
        return this._m.size;
    }

    public getByIndex(index: number) {
        // TODO: Check this.
        // NOTE: VB was overloading an integer enum to get the value by enum or index
        // I don;t think this can be done in JS, so getByIndex has been created
        return elementAt(this._m.values(), index);
    }
}

function elementAt<V>(generator: IterableIterator<V>, n: number) {
    "use strict";

    let i = 0;

    if (n < 0) {
        throw new Error("Invalid index");
    }

    for (let value of generator) {
        if (i++ === n) {
            return value;
        }
    }

    throw new Error("Generator has fewer than " + n + " elements");
}