import { Units, convertArea } from "@turf/turf";
import { t } from "i18next";

// BEGIN NOTE: 
//  These strings are kept consistant with turfjs so they can be used like for like with turfjs
// The _ version is for ease of use with typesafety
export type DisplayAreaUnits = Extract<Units, 'hectares' | 'acres'>;  
export const DisplayAreaUnitsMap = { 
    // NOTE: key name must as found in DisplayAreaUnits
    hectares: {
        longName: 'hectares',
        fullName: 'hectares',
        shortName: 'ha',
        value: 'hectares' as DisplayAreaUnits
    }, 
    acres: {
        longName: 'ac',
        fullName: 'acres',
        shortName: 'acre',
        value: 'acres' as DisplayAreaUnits
    }
} as const;
// END NOTE

export class DisplayAreaUnitBuilder {
    static shortName = (unit: DisplayAreaUnits) => DisplayAreaUnitsMap[unit].shortName;
    static longName = (unit: DisplayAreaUnits) => DisplayAreaUnitsMap[unit].longName;
    static fullName = (unit: DisplayAreaUnits) => DisplayAreaUnitsMap[unit].fullName;

    static convert = (value: number, from: DisplayAreaUnits, to: DisplayAreaUnits) => {
        if (from === to) return value;
        return convertArea(value, from, to);
    }

    private _value: number;
    private _unit: DisplayAreaUnits;
    private _formattedString = "";

    constructor(value: number, unit: DisplayAreaUnits) {
        this._value = value;
        this._unit = unit;
    }

    convert = (finalUnit: DisplayAreaUnits) => {
        this._value = DisplayAreaUnitBuilder.convert(this._value, this._unit, finalUnit);
        this._unit = finalUnit;
        return this;
    }

    appendValue = (toFixed?: number) => {
        if (toFixed === undefined) {
            this._formattedString += this._value.toString();
        }
        else {
            this._formattedString += this._value.toFixed(toFixed);
        }
        return this;
    }

    appendString = (value: string) => {
        this._formattedString += value;
        return this;
    }

    appendShortName = () => {
        this._formattedString += DisplayAreaUnitBuilder.shortName(this._unit);
        return this;
    }
    
    appendLongName = () => {
        this._formattedString += t(DisplayAreaUnitBuilder.longName(this._unit));
        return this;
    }

    appendFullName = () => {
        this._formattedString += t(DisplayAreaUnitBuilder.fullName(this._unit));
        return this;
    }

    toString = () => {
        return this._formattedString;
    }

}
