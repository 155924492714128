import * as turf from "@turf/turf";
import { IElevationPointFeature } from "./IElevationPointFeature";

export const mapElevationToFeature = (map: mapboxgl.Map, coord: turf.helpers.Position): IElevationPointFeature => {
    const elevationMeters = map.queryTerrainElevation(
        {
            lng: coord[0], lat: coord[1]
        },
        { exaggerated: false }
    )
    return turf.point(coord, { elevationMeters, relativeToCenter: null })
}