import { Vector2 } from "../Numerics/Vector2";

export class EndGunDataContainer {
    public EndGunAreas: EndGunAreaContainer[] = [];
    public CalculatedThrowUnit?: string;
    public CalculatedThrow?: number;
}

export class EndGunAreaContainer {
    public CenterLatitude?: number;
    public CenterLongitude?: number;
    public positions: {
        start: Vector2;
        end: Vector2;
    }[] = [];
}