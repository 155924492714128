import { DeviceTypes, IPackage, NozzleClipTypes, RegulatorTypes } from "rdptypes/project/ISprinklers";
import CSection2 from "./CSection2";
import PartsAssembler from "./PartsAssembler";
import { SprinklersHoseDropClass } from './SprinklersHoseDropClass';
import * as sysf from "./SystemFunctions";
import { strLanguageText } from "./TranslationsLib";
import { Side } from "./Types";
export class SprinklersPackageClass extends CSection2 {
    
    public SideKey: "R" | "L";
    public PackageNumber: number;

    // public SpansClass: SpansClass;
    private get Side(): Side {
        return this.SideKey === "R" ? this.Quote.System.FlangedSide : this.Quote.System.FlexSide;
    }
    // private get Packages() {
    //     return this.Side.Sprinklers;
    // }
    private get Package(): IPackage {
        let sp = this.Side.Sprinklers;
        // ???? 
        // if (sp.Package.length < this.PackageNumber) {
            // sp.Packages.Count = PackageNumber
            // sp.Package(PackageNumber).PackageNumber = PackageNumber
        // }
        return sp.Package[this.PackageNumber - 1];
        // Packages = sp.Packages
    }


    public DataOK(PA: PartsAssembler, WithWarnings?: boolean, DependencyCheckInProgress?: boolean): void {
        this.DataOKWithReturn(PA, WithWarnings, DependencyCheckInProgress);
    }

    public DataOKWithReturn(PA: PartsAssembler, WithWarnings?: boolean, DependencyCheckInProgress?: boolean): boolean {
        // const iStart = this.DetermineStartSpan();
        // const iEnd = this.DetermineStopSpan();

        switch (this.Package.Device) {
            case DeviceTypes.None:
            case DeviceTypes.Nelson15DoubleFCNBrassImpact:
            case DeviceTypes.Nelson8DoubleFCNPlasticImpact:
            case DeviceTypes.NelsonD3000FCNSpray:
            case DeviceTypes.NelsonR3000FCNRotatorBlue:
            case DeviceTypes.NelsonR3000FCNRotatorGreen:
                break;
            default:
                if (this.Package.Regulator.RegulatorType === RegulatorTypes.None) {
                    if (
                        this.Quote.System.SprinklerProperties.PeakElevation !== 0 && 
                        this.Quote.System.SprinklerProperties.PeakElevation >= this.Quote.System.SprinklerProperties.DesignedEndPressure * 0.462
                    ) {
                        this.Quote.Warnings.Add(strLanguageText("sltTheElevationToEndPressure"));
                        return false;
                    }
                }
                break;
        }

        if (this.Package.NozzleClip !== NozzleClipTypes.None && sysf.HasEnergySaverPackage(this.Quote.System)) {
            this.Quote.Warnings.Add(strLanguageText("sltDualNozzleClip"));
            return false;
        }

        if (!this.CheckHoseDrop(WithWarnings)) {
            return false;
        }

        return true;
    }
    
    public WritePO(PO: PartsAssembler): void {
        // pass: nothing to do
    }

    // private DetermineStartSpan() {
    //     let iStart: number;
    //     if (this.Package.PackageNumber === 1) {
    //         iStart = 1
    //     } 
    //     else {
    //         iStart = this.SpanFromLocation(this.Packages[this.Package.PackageNumber - 2].EndingLocation + 0.01);
    //     }
    //     return iStart;
    // }
    // private DetermineStopSpan() {
    //     let iEnd: number;
    //     if (this.Package.EndingLocation === 0) {
    //         iEnd = sidef.NumberOfSpans(this.Side);
    //     } 
    //     else {
    //         iEnd = this.SpanFromLocation(this.Package.EndingLocation);
    //     }
    //     return iEnd;
    // }

    // private SpanFromLocation(l: number): number {
    //     if (l <= 30) {
    //         return 1;
    //     }
    //     const k: number = sidef.NumberOfSpans(this.Side) - 1;
    //     for (let i = 0; i <= k; i++) {    
            
    //         if (
    //             l >= spanf.StartingLocation(this.Quote.System, this.Side, this.Side.Span[i]) &&
    //             l <= spanf.EndingLocation(this.Quote.System, this.Side, this.Side.Span[i])
    //         ) {
    //             return i;
    //         }
    //     }
    //     // Should never get here
    //     return k;
    // }
    
    private CheckHoseDrop(WithWarnings: boolean): boolean {
        const SprinklerHoseDrop = new SprinklersHoseDropClass();
        SprinklerHoseDrop.Quote = this.Quote;
        SprinklerHoseDrop.Package = this.Package;
        SprinklerHoseDrop.DataOK(null, WithWarnings);
        return true;
    }
}
