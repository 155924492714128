
// ''' <summary>
// ''' VRI Valve Box
// ''' </summary>
export default class VRI_ValveBox {
    public StartZone: number = 0;
    public EndZone: number = 0;
    public TowerIndex: number = 0;
    public Tower: number = 0;
    public TowerLocation: number = 0;
    public TubingLength: number = 0;
}