import { Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import * as React from "react";
import { FC } from "react";

import "@fontsource/libre-franklin/900.css";
import Typography from "@mui/material/Typography/Typography";
import ToolsPageBase from "../toolComponents/ToolPageBase";


interface Props {

}

enum PhaseType{
    ThreePhase,
    SinglePhase,
}

enum WireType {
    Aluminium,
    Copper
}

interface UndergroundWireSizeSystem{
    id: number,
    length: number,
    sysFLA: number,
    sysVoltageDrop: number,
    eosVoltage: number,
    lineWireGauge: string,
    wireType: WireType,
    lineFLA: number,
    lineVoltageDrop: number;
}

interface EditingSystem extends UndergroundWireSizeSystem {
    new: boolean;
}

const UndergroundWireSizingPage: FC<Props> = (props) => {

    const [voltageSource, setVoltageSource] = React.useState<null | number>(480);
    const [phase, setPhase] = React.useState<null | PhaseType>(PhaseType.ThreePhase);
    const [tableRows, setTableRows] = React.useState<UndergroundWireSizeSystem[]>([]);
    const [systemToEditOrAdd, setSystemToEditOrAdd] = React.useState<null | EditingSystem>(null);

    var gaugeValues: string[] = ["14", "12", "10", "8", "6", "4", "2", "1", "0", "00", "000", "0000", "250 kCM", "300 kCM", "350 kCM", "400 kCM"];

    return (
        <ToolsPageBase title="Underground Wire Sizing Tool">
             <Stack direction="row">
                <TextField
                    variant="filled"
                    size="small"
                    type="number"
                    label={"Voltage Source:"}                
                    value={voltageSource}
                    onChange={(ev) => setVoltageSource(+ev.target.value)}         
                />
                <FormControlLabel 
                    control={<Checkbox 
                        style={{padding: 0, paddingLeft: 10, paddingRight: 5, marginLeft: 20}}
                        checked={phase === PhaseType.ThreePhase}
                        onChange={(ev) => setPhase(PhaseType.ThreePhase)}
                    />} 
                    label={<span>Three Phase</span>} 
                />
                <FormControlLabel 
                    control={<Checkbox 
                        style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                        checked={phase === PhaseType.SinglePhase}
                        onChange={(ev) => setPhase(PhaseType.SinglePhase)}
                    />} 
                    label={<span>Single Phase</span>} 
                />
             </Stack>
             <TableContainer component={Paper} style={{marginBottom: 20, marginTop: 20}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell  align="center"><b>System</b></TableCell>
                            <TableCell align="center"><b>Length(ft)</b></TableCell>
                            <TableCell align="center"><b>Sys. FLA</b></TableCell>
                            <TableCell align="center"><b>Sys. Voltage Drop</b></TableCell>
                            <TableCell align="center"><b>EOS Voltage</b></TableCell>
                            <TableCell align="center"><b>Line Wire Gauge</b></TableCell>
                            <TableCell align="center"><b>Wire Type</b></TableCell>
                            <TableCell align="center"><b>Line FLA</b></TableCell>
                            <TableCell align="center"><b>Line Voltage Drop</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            tableRows.length === 0 &&
                            <TableRow key="empty"> <TableCell colSpan={9} align="center"><span>No data available</span></TableCell></TableRow>
                        }
                        {tableRows.map((row) => (
                            <TableRow key={row.id} onClick={() => {setSystemToEditOrAdd({...row, new: false});}}>
                                <TableCell align="center">{row.id}</TableCell>
                                <TableCell align="center">{row.length}</TableCell>
                                <TableCell align="center">{row.sysFLA}</TableCell>
                                <TableCell align="center">{row.sysVoltageDrop}</TableCell>
                                <TableCell align="center">{row.eosVoltage}</TableCell>
                                <TableCell align="center">{row.lineWireGauge}</TableCell>
                                <TableCell align="center">{row.wireType === WireType.Aluminium ? "Aluminium" : "Copper"}</TableCell>
                                <TableCell align="center">{row.lineFLA}</TableCell>
                                <TableCell align="center">{row.lineVoltageDrop}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
    </TableContainer>
             <Stack direction="row" style={{justifyContent: 'space-between'}}>
                <Stack direction="row">
                    <Button variant="contained" style={{marginRight: 5}} onClick={() => {
                        setSystemToEditOrAdd({
                            id: tableRows.length,
                            eosVoltage: 0, //??
                            length: 1000,
                            lineFLA: 10, //same as Sys FLA?
                            lineVoltageDrop: 29,
                            lineWireGauge: "10",
                            sysFLA: 10,
                            sysVoltageDrop: 25,
                            wireType: WireType.Aluminium,
                            new: true
                        });
                        }}>Add System</Button>
                    <Button variant="outlined" onClick={()=>setTableRows([])}>Clear Grid</Button>
                </Stack>
                <Stack direction="row">
                    <Button variant="outlined" style={{marginRight: 5}}>Print</Button>
                    <Button variant="contained" style={{marginRight: 5}} onClick={() => window.close()}>Close</Button>
                </Stack>                 
             </Stack>
             {
                systemToEditOrAdd &&
                <Dialog
                    open={systemToEditOrAdd !== null}
                    onClose={() => setSystemToEditOrAdd(null)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title"> {systemToEditOrAdd ? "Edit System " + systemToEditOrAdd.id : "Add System"}</DialogTitle>
                    <DialogContent>
                        <Card variant="outlined" style={{marginBottom: 10}}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>System Specifications</Typography>
                                <TextField
                                    variant="filled"
                                    size="small"
                                    type="number"
                                    label={"Full Load Amps:"}                
                                    value={systemToEditOrAdd.sysFLA}
                                    style={{marginRight: 10}}
                                    onChange={(ev) => setSystemToEditOrAdd({...systemToEditOrAdd, sysFLA: +ev.target.value})}   
                                    InputProps={{endAdornment: <InputAdornment position="end">A</InputAdornment>, }}      
                                />
                                 <TextField
                                    variant="filled"
                                    size="small"
                                    type="number"
                                    label={"Voltage Drop:"}                
                                    value={systemToEditOrAdd.sysVoltageDrop}
                                    onChange={(ev) => setSystemToEditOrAdd({...systemToEditOrAdd, sysVoltageDrop: +ev.target.value})}  
                                    InputProps={{ endAdornment: <InputAdornment position="end">v</InputAdornment>, }}         
                                />
                            </CardContent>
                        </Card>
                        <Stack direction="column">
                            <TextField
                                variant="filled"
                                size="small"
                                type="number"
                                style={{marginBottom: 10}}
                                label={"Distance from System 1:"}                
                                value={systemToEditOrAdd.length}
                                InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment>, }}  
                                onChange={(ev) => setSystemToEditOrAdd({...systemToEditOrAdd, length: +ev.target.value})}         
                            />
                            <TextField
                                variant="filled"
                                size="small"
                                type="number"
                                label={"Line Voltage Drop:"}                
                                value={systemToEditOrAdd.lineVoltageDrop}
                                style={{marginBottom: 10}}
                                InputProps={{ endAdornment: <InputAdornment position="end">v</InputAdornment>, }}         
                                onChange={(ev) => setSystemToEditOrAdd({...systemToEditOrAdd, lineVoltageDrop: +ev.target.value})}         
                            />
                                <TextField
                                variant="filled"
                                size="small"
                                disabled
                                type="number"
                                label={"Line Full Load Amps:"}                
                                value={systemToEditOrAdd.lineFLA}
                                style={{marginBottom: 10}}
                                InputProps={{ endAdornment: <InputAdornment position="end">A</InputAdornment>, }}                 
                            />
                        </Stack>                            
                        <Card variant="outlined">
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Wire</Typography>
                                <Stack direction="row" style={{alignItems: 'center'}}>
                                    <Typography style={{marginRight: 10}}>Type:</Typography>
                                    <FormControlLabel 
                                        control={<Checkbox 
                                            style={{}}
                                            checked={systemToEditOrAdd.wireType === WireType.Aluminium}
                                            onChange={(ev) => setSystemToEditOrAdd({...systemToEditOrAdd, wireType: WireType.Aluminium})}
                                        />} 
                                        label={<span>Aluminium</span>} 
                                    />
                                    <FormControlLabel 
                                        control={<Checkbox 
                                            style={{}}
                                            checked={systemToEditOrAdd.wireType === WireType.Copper}
                                            onChange={(ev) => setSystemToEditOrAdd({...systemToEditOrAdd, wireType: WireType.Copper})}
                                        />} 
                                        label={<span>Copper</span>} 
                                    />
                                </Stack>                               
                                <FormControl variant="filled" size="small" fullWidth>
                                    <InputLabel >Gauge</InputLabel>
                                    <Select
                                    value={systemToEditOrAdd.lineWireGauge}
                                    onChange={(ev) => setSystemToEditOrAdd({...systemToEditOrAdd, lineWireGauge: ev.target.value})}
                                    >
                                        {gaugeValues.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
                                    </Select>
                                </FormControl>                                       
                            </CardContent>
                        </Card>                   
                    </DialogContent>
                    <DialogActions>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', padding: 20}}>
                            {
                                !systemToEditOrAdd.new &&
                                <Button variant="outlined" color="error" onClick={() =>{
                                    var tableRowsCopy: UndergroundWireSizeSystem[] = Object.assign(tableRows);           
                                    tableRowsCopy.splice( tableRowsCopy.findIndex(x => x.id === systemToEditOrAdd.id) , 1);
                                    setTableRows(tableRowsCopy);
                                    setSystemToEditOrAdd(null);
                                    }}>Remove</Button>
                            }
                           {
                            systemToEditOrAdd.new && <div></div>
                           }
                            <Stack direction="row">
                                <Button variant="outlined" style={{marginRight: 10}} onClick={() =>setSystemToEditOrAdd(null)}>Close</Button>
                                <Button variant="contained" onClick={() =>{     
                                    var tableRowsCopy: UndergroundWireSizeSystem[] = Object.assign(tableRows);                                         
                                    if(systemToEditOrAdd.new){                           
                                        tableRowsCopy.push(systemToEditOrAdd);
                                        setTableRows(tableRowsCopy);
                                    } else{
                                    var toEditIndex = tableRowsCopy.findIndex(x => x.id === systemToEditOrAdd.id);
                                    tableRowsCopy[toEditIndex] = systemToEditOrAdd;
                                    setTableRows(tableRowsCopy);
                                    }
                                    setSystemToEditOrAdd(null);
                                    }}>Save</Button>
                            </Stack>
                        </div>
                    </DialogActions>
                </Dialog>
             }            
        </ToolsPageBase>
    );
};

export default UndergroundWireSizingPage;