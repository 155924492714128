import { t } from "i18next";
import { kilosToPounds, poundsToKilos } from "../docGeneration/DocumentGenerationHelpers";

export type DisplayWeightsUnits = 'pounds' | 'kilos';  
export const DisplayWeightsUnitsMap = {
    pounds: {
        longName: 'pounds',
        fullName: 'pounds',
        shortName: 'lbs',
        value: 'pounds' as DisplayWeightsUnits
    }, 
    kilos: {
        longName: 'kilograms',
        fullName: 'kilograms',
        shortName: 'kg',
        value: 'kilos' as DisplayWeightsUnits
    }
} as const;

export class DisplayWeightsUnitsBuilder {
    static shortName = (unit: DisplayWeightsUnits) => DisplayWeightsUnitsMap[unit].shortName;
    static longName = (unit: DisplayWeightsUnits) => DisplayWeightsUnitsMap[unit].longName;
    static fullName = (unit: DisplayWeightsUnits) => DisplayWeightsUnitsMap[unit].fullName;

    static convert = (value: number, from: DisplayWeightsUnits, to: DisplayWeightsUnits) => {
        if (from === to) return value;
        if (to === "kilos"){
            return poundsToKilos(value);
        }
        return kilosToPounds(value);
    }

    private _value: number;
    private _unit: DisplayWeightsUnits;
    private _formattedString = "";

    constructor(value: number, unit: DisplayWeightsUnits) {
        this._value = value;
        this._unit = unit;
    }

    convert = (finalUnit: DisplayWeightsUnits) => {
        this._value = DisplayWeightsUnitsBuilder.convert(this._value, this._unit, finalUnit);
        this._unit = finalUnit;
        return this;
    }

    appendValue = (toFixed?: number) => {
        if (toFixed === undefined) {
            this._formattedString += this._value.toString();
        }
        else {
            this._formattedString += this._value.toFixed(toFixed);
        }
        return this;
    }

    appendString = (value: string) => {
        this._formattedString += value;
        return this;
    }

    appendShortName = () => {
        this._formattedString += DisplayWeightsUnitsBuilder.shortName(this._unit);
        return this;
    }
    
    appendLongName = () => {
        this._formattedString += t(DisplayWeightsUnitsBuilder.longName(this._unit));
        return this;
    }

    appendFullName = () => {
        this._formattedString += t(DisplayWeightsUnitsBuilder.fullName(this._unit));
        return this;
    }

    toString = () => {
        return this._formattedString;
    }

}
