import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { useContext } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { createNewUpdateProjectPropertyAction } from "../../actions/UpdateProjectProperty";
import AuthCtx from "../../auth/AuthCtx";
import DbCtx from "../../db/DbCtx";
import { ProjectState } from "../../model/project/IProject";

const Project = () => {
    const { projectId } = useParams();
    const dbState = useContext(DbCtx);
    const authState = useContext(AuthCtx);

    const navigate = useNavigate();

    if (dbState.projects[projectId].state.projectState === ProjectState.Deleted) {
        return <>
            <Dialog
                open={true}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                {i18next.format(t('projects.deleted-project'), 'capitalize-each')}
            </DialogTitle>
            <DialogContent>
                <p>{t('projects.project-has-been-deleted')}</p>
                <p>{t('projects.un-delete-project')}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    navigate(-1);//go back
                }}>{t("cancel")}</Button>
                <Button
                    onClick={async() => {
                    await dbState.projects[projectId].pushAction(createNewUpdateProjectPropertyAction("projectState", ProjectState.Active, authState));
                }}>{t("ok")}</Button>
            </DialogActions>
            </Dialog>
        </>
    }

    return (
        <>
            <Outlet />
        </>
    );
}

export default Project;