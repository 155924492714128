import { TableCell, TableRow } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { FC, useState } from "react";
import { IDealerMapFillColor } from ".";
import ColorPickerDialog from "./ColorPickerDialog";

interface Props {
    dealerColor: IDealerMapFillColor;
    addOrUpdateDealerColor: (dc: IDealerMapFillColor) => void;
}

enum ColorPickerMode {
    none,
    textColor,
    textHaloColor,
    fillColor,
    lineColor,
    circleColor
}

const ns = "dealer-settings.map-settings-options.";
const subns = "map-draw-features.";

const DealerSettingsColorPickerRow: FC<Props> = (props) => {
    const [colorPickerMode, setColorPickerMode] = useState(ColorPickerMode.none);
    const {dealerColor, addOrUpdateDealerColor} = {...props};

    let colorCells: JSX.Element[] = [];

    if (dealerColor.fillColor){
        colorCells.push(<TableCell sx={{
            '&.MuiTableCell-root:hover':{
                cursor: 'pointer'
            },
            backgroundColor: dealerColor.fillColor, width: "2em"
        }} onClick={() => setColorPickerMode(ColorPickerMode.fillColor)}/>);
    }

    if (dealerColor.lineColor){
        colorCells.push(<TableCell sx={{
            '&.MuiTableCell-root:hover':{
                cursor: 'pointer'
            },
            backgroundColor: dealerColor.lineColor, width: "2em"
        }} onClick={() => setColorPickerMode(ColorPickerMode.lineColor)}/>);
    }

    if (dealerColor.textColor){
        colorCells.push(<TableCell sx={{
            '&.MuiTableCell-root:hover':{
                cursor: 'pointer'
            },
            backgroundColor: dealerColor.textColor, width: "2em"
        }} onClick={() => setColorPickerMode(ColorPickerMode.textColor)}/>);
    }

    if (dealerColor.textHaloColor){
        colorCells.push(<TableCell sx={{
            '&.MuiTableCell-root:hover':{
                cursor: 'pointer'
            },
            backgroundColor: dealerColor.textHaloColor, width: "2em"
        }} onClick={() => setColorPickerMode(ColorPickerMode.textHaloColor)}/>);
    }
    
    if (dealerColor.circleColor){
        colorCells.push(<TableCell sx={{
            '&.MuiTableCell-root:hover':{
                cursor: 'pointer'
            },
            backgroundColor: dealerColor.circleColor, width: "2em"
        }} onClick={() => setColorPickerMode(ColorPickerMode.circleColor)}/>);
    }

    const getColorPickerModeLabel = () => {
        let label = t(ns + subns + dealerColor.id);
        switch (colorPickerMode){
            case ColorPickerMode.fillColor:
                return label + " - " + t(ns + "fill-color");
            case ColorPickerMode.lineColor:
                return label + " - " + t(ns + "line-color");
            case ColorPickerMode.textColor:
                return label + " - " + t(ns + "text-color");
            case ColorPickerMode.textHaloColor:
                return label + " - " + t(ns + "text-halo-color");
            case ColorPickerMode.circleColor:
                return label + " - " + t(ns + "circle-color");
            default:
                return label;
        }
    }

    const color = colorPickerMode === ColorPickerMode.fillColor 
        ? dealerColor.fillColor 
        : colorPickerMode === ColorPickerMode.lineColor 
            ? dealerColor.lineColor 
            : colorPickerMode === ColorPickerMode.textColor 
                ? dealerColor.textColor 
                : colorPickerMode === ColorPickerMode.textHaloColor 
                    ? dealerColor.textColor 
                    : colorPickerMode === ColorPickerMode.circleColor 
                        ? dealerColor.circleColor 
                        : "";

    return (<>
            <TableRow>
                <TableCell>{t(ns + subns + dealerColor.id)}</TableCell>
                {colorCells}
            </TableRow>
            <ColorPickerDialog
                label={getColorPickerModeLabel()}
                open={colorPickerMode !== ColorPickerMode.none}
                close={()=>setColorPickerMode(ColorPickerMode.none)}
                color={color}
                updateColor={(newColor) => {
                    let dc = {...props.dealerColor}

                    if (colorPickerMode === ColorPickerMode.fillColor){
                        dc.fillColor = newColor;
                    }
                    if (colorPickerMode === ColorPickerMode.lineColor){
                        dc.lineColor = newColor;
                    }
                    if (colorPickerMode === ColorPickerMode.textColor){
                        dc.textColor = newColor;
                    }
                    if (colorPickerMode === ColorPickerMode.textHaloColor){
                        dc.textHaloColor = newColor;
                    }
                    if (colorPickerMode === ColorPickerMode.circleColor){
                        dc.circleColor = newColor;
                    }

                    props.addOrUpdateDealerColor(dc);
                }}
            />
        </>        
    );
};

export default DealerSettingsColorPickerRow;