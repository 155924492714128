import { ISacOptimizerSettingsFormState } from "../../../../../components/DealerSettingsDialog/SacOptimizerSettings";

export class SACModelConstraints {
    public FeetToMetres = 0.3048;

    public MinExtensionAngleDegrees = 60;
    public MaxExtensionAngleDegrees = 162;

    public MinSteeringAngleDegrees = -15;
    public MaxSteeringAngleDegrees = 90;

    public MaxStwrHtwrSpeedRatio = 1.65;

    public MaxSteeringRateDegreesPerMetre: number;

    // TODO: Take these params from some settings
    public constructor(settings: ISacOptimizerSettingsFormState) {
        const { sacMaxStwrSteeringAngleRateDegreesPerFoot } = settings;

        // const sacMaxStwrSteeringAngleRateDegreesPerFoot = 3.1

        // this.MinExtensionAngleDegrees = Properties.Settings.Default.SacMinExtensionAngleDegrees;
        // this.MaxExtensionAngleDegrees = Properties.Settings.Default.SacMaxExtensionAngleDegrees;
        // this.MinSteeringAngleDegrees = Properties.Settings.Default.SacMinSteeringAngleDegrees;
        // this.MaxSteeringAngleDegrees = Properties.Settings.Default.SacMaxSteeringAngleDegrees;
        // this.MaxStwrHtwrSpeedRatio = Properties.Settings.Default.SacMaxHtwrStwrSpeedRatio;
        this.MaxSteeringRateDegreesPerMetre = sacMaxStwrSteeringAngleRateDegreesPerFoot / this.FeetToMetres;
    }
}