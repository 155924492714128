/* 
 * The span combinations are very time consuming as length increases
    as such, the span combination cache will only consider combinations up to MAX_CACHE_LENGTH
    it will then take multiples of this up the correct length
 */


interface ILengthsCacheInner {
    parentLength?: number;
    length: number;
    totalLength: number;
}
interface ILengthsCache {
    [ length: string ]: ILengthsCacheInner
}

const savedFillLengthCache: {
    [ selectFromCommaSeparated: string ]: {
        checkedTo: number;
        lengths: ILengthsCache;
    }
} = {};

export const MAX_N_SPANS = 24;
const MAX_CACHE_LENGTH = 2000;
const getCacheToLength = (selectFrom: number[]): number => {
    const maxSelectFromValue = Math.max(...selectFrom);
    const n = Math.ceil(MAX_CACHE_LENGTH / maxSelectFromValue);
    return n * maxSelectFromValue;
}

const bestFillLengthFromSelectionInternal = (length: number, selectFrom: number[], currentNumberOfSpans: number): number[] => {
    // const timer = createTimerLog("cpo.bestFillInternal " + length.toString());
    if (currentNumberOfSpans >= MAX_N_SPANS) {
        return [];
    }
    const savedFillLengths = getCacheInner(length, selectFrom);
    const lengthsThatFit = (Object.values(savedFillLengths) as ILengthsCacheInner[])
        .filter(x => x.totalLength <= length)
        .map(x => x.totalLength)
        .sort((a,b) => b - a);
    if (!lengthsThatFit.length) return [];
    let foundFit = false;
    let i = 0;
    let spans: number[] = []
    while (!foundFit && i < lengthsThatFit.length) {
        const current = lengthsThatFit[i];
        spans = [];
        let existing: { parentLength?: number, length: number } | undefined = savedFillLengths[current.toFixed(2)];
        let runningLen = current;
        foundFit = true;
        while (existing) {
            const len = existing.length;
            runningLen -= len;
            spans.push(len);
            existing = existing.parentLength
                ? savedFillLengths[existing.parentLength.toFixed(2)]
                : undefined;
            if (currentNumberOfSpans + spans.length > MAX_N_SPANS) {
                existing = undefined;
                foundFit = false;
            }
        }
        i++;
    }
    // timer.logMS();
    if (foundFit) {
        return spans;
    }
    return [];
}

export const bestFillLengthFromSelection = (length: number, selectFrom: number[], currentNumberOfSpans: number): number[] => {
    // const timer = createTimerLog("getCahce");
    let workingLength = length;
    const spans: number[] = [];
    let lastFill: number[] = [ -1 ];

    const selectFromMinToMax = [ ...selectFrom ].sort((a,b) => a - b);    
    const maxValue = getCacheToLength(selectFromMinToMax);

    while (workingLength > 0 && lastFill.length) {
        let thisLength = workingLength - maxValue > 0
            ? maxValue
            : workingLength;
        lastFill = bestFillLengthFromSelectionInternal(thisLength, selectFromMinToMax, currentNumberOfSpans + spans.length);
        for (const l of lastFill) {
            workingLength -= l;
            spans.push(l);
        }
    }
    // timer.logDeltaMS("1");
    return spans.sort((a, b) => b - a);
}
const getCacheInner = (length: number, selectFrom: number[]): ILengthsCache => {

    if (!selectFrom.length) return {};

    const commaString = selectFrom.join(",");
    if (!savedFillLengthCache[commaString]) {
        savedFillLengthCache[commaString] = {
            checkedTo: 0,
            lengths: {}
        };
        selectFrom.forEach(x => { 
            savedFillLengthCache[commaString].lengths[x.toFixed(2)] = { length: x, totalLength: x }
        })
    }

    if (length > savedFillLengthCache[commaString].checkedTo) {
        // update cache from checkedTo:
        const maxSelectFrom = Math.max(...selectFrom);
        const workingFillLengths: { totalLength: number }[] = (Object.values(savedFillLengthCache[commaString].lengths) as ILengthsCacheInner[])
            .filter(x => x.totalLength >= savedFillLengthCache[commaString].checkedTo - maxSelectFrom)
            .map(x => ({ totalLength: x.totalLength }));
    
        while (workingFillLengths.length) {
            const currentFillLength = workingFillLengths.pop()!;
            for (const x of selectFrom) {
                if (currentFillLength.totalLength + x <= length + maxSelectFrom) {
                    const nextFillLength = currentFillLength.totalLength + x;
                    let savedFillLength = savedFillLengthCache[commaString].lengths[nextFillLength.toFixed(2)];
                    if (savedFillLength) {
                        if (currentFillLength.totalLength === savedFillLength.parentLength) {
                            // do nothing
                        }
                        else {
                            let existingSpanCount = 0;
                            let existing = savedFillLength;
                            while (existing.parentLength) {
                                existingSpanCount++;
                                existing = savedFillLengthCache[commaString].lengths[existing.parentLength.toFixed(2)];
                            }
                            
                            let incommingSpanCount = 1;
                            let incomming = savedFillLengthCache[commaString].lengths[currentFillLength.totalLength.toFixed(2)];
                            while (incomming.parentLength) {
                                incommingSpanCount++;
                                incomming = savedFillLengthCache[commaString].lengths[incomming.parentLength.toFixed(2)];
                            }
    
                            if (incommingSpanCount < existingSpanCount) {
                                savedFillLength.parentLength = currentFillLength.totalLength;
                                savedFillLength.length = x;
                                workingFillLengths.unshift({ totalLength: savedFillLength.totalLength })
                            }
                        }
                    }
                    else {
                        if (x <= length) {
                            savedFillLengthCache[commaString].lengths[nextFillLength.toFixed(2)] = {
                                parentLength: currentFillLength.totalLength,
                                length: x,
                                totalLength: nextFillLength
                            }
                            workingFillLengths.push({
                                totalLength: nextFillLength
                            })
                        }
                    }
                }
            }
        }

        savedFillLengthCache[commaString].checkedTo = length;
    }

    // from cache:
    return savedFillLengthCache[commaString].lengths;
}