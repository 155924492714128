import { Alert, Button, Stack, Tab, Tabs } from "@mui/material";
import { t } from "i18next";
import IAction from "rdptypes/IAction";
import { IPackage } from "rdptypes/project/ISprinklers";
import { ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useContext, useEffect } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { RunResults, WarningSeverity, WarningTypes } from "roedata/roe_migration/SprinklerEngineTypes/Enums";
import { SprinklerEngineResult } from "roedata/roe_migration/SprinklerEngineTypes/SprinklerEngineResult";
import { SprinklerEngineRunner } from "roedata/roe_migration/SprinklerEngineTypes/SprinklerEngineRunner";
import { SprinklerEngineWarning } from "roedata/roe_migration/SprinklerEngineTypes/SprinklerEngineWarning";
import { IsDualSided } from "roedata/roe_migration/SystemFunctions";
import { sprinklersPrefix } from "..";
import { createNewMultiAction } from "../../../../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../../../auth/AuthCtx";
import IAuthState from "../../../../../auth/IAuthState";
import DevSettingsCtx from "../../../../../db/DevSettingsCtx";
import IDbProject from "../../../../../db/IDbProject";
import { psiToBar } from "../../../../../docGeneration/DocumentGenerationHelpers";
import { getValue } from "../../../../../helpers/objectPathResolver";
import ISystem from "../../../../../model/project/ISystem";
import { pageStyles, secondaryColor } from "../../../styles";
import IComponentRenderContext from "../../IComponentRenderContext";
import { getWarningSeverityType, getWarningType, isSprinklerCalcAvailable } from "../helpers/SprinklerHelper";
import OutletsTable from "./OutletsTable";
import SpanIrrigationInformation from "./SpanIrrigationInformation";
import SpanIrrigationTable from "./SpanIrrigationTable";

interface Props {
    sys: ISystemBase;
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
    packagesInUse: IPackage[];
    ctx: IComponentRenderContext;
};

export const generateChart = (authState: IAuthState, dbPrj: IDbProject, layoutId: string, systemId: string, system: ISystem) => {
    var ser = new SprinklerEngineRunner();
    const project = dbPrj.state;

    // Old ROE code mutates the system directly so first take a copy
    //JB why is ^ a problem? We need MaximumGPM and other properties to be set on the system...
    const newSys: ISystemBase = JSON.parse(JSON.stringify(system));
    var quote;
    try{
        quote = new QuoteClass(newSys);
        var prepDataWarnings: SprinklerEngineWarning[] = [];
        quote.SprinklerChartClass.RightSprinklersClass.PrepData(prepDataWarnings);
       
        if(IsDualSided(system)){
            quote.SprinklerChartClass.LeftSprinklersClass.PrepData(prepDataWarnings);
        }
        
        var result: SprinklerEngineResult;

        if(prepDataWarnings.length === 0){
            result = ser.BuildSprinklerPackage(newSys);
        }         

        var allWarnings = !result ? prepDataWarnings : prepDataWarnings.concat(result.Warnings);
        
        const actions: IAction[] = [
            createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "SprinklerEngineResultWarnings",
            allWarnings,
            authState)
        ];

        if (result && result.RunResult === RunResults.Success) {
            actions.push(
            createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "SprinklerProperties",
                newSys.SprinklerProperties,
                authState),

            createNewUpdateSystemPropertyAction(
                layoutId,
                systemId,
                "FlangedSide.SprinklerChart",
                newSys.FlangedSide.SprinklerChart,
                authState)   
            );                   
    
            if(IsDualSided(system)){
                actions.push(createNewUpdateSystemPropertyAction(
                    layoutId,
                    systemId,
                    "FlexSide.SprinklerChart",
                    newSys.FlexSide.SprinklerChart,
                    authState)); 
            } 
        }

        return actions;
        
    }catch (err){
        let err_ = err as Error;
        console.log(err);
        const errStr = err_.message !== "" ? err_.message + err_.stack : err_.stack;

        const actions: IAction[] = [
            createNewUpdateSystemPropertyAction(
            layoutId,
            systemId,
            "SprinklerEngineResultWarnings",
            [{
                WarningSeverity: WarningSeverity.Critical,
                WarningType: WarningTypes.InvalidData,
                Context: "",
                Message: errStr
            }],
            authState)
        ];

        return actions;
    }
}

const SprinklerChart: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const [tabIndex, setTabIndex] = React.useState(0);
    const dbPrj = props.dbPrj;
    const project = dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    let sprinklerEngineResultWarnings: SprinklerEngineWarning[] = getValue(system, "SprinklerEngineResultWarnings") ?? null;

    // Workaround bug where sometimes sprinklerEngineResultWarnings was set to an object
    // instead of a singleton array.
    if (sprinklerEngineResultWarnings && sprinklerEngineResultWarnings.constructor.name !== "Array") {
        sprinklerEngineResultWarnings = [sprinklerEngineResultWarnings as any];
    }

    let quote = new QuoteClass(system);
    
    const settings = useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;

    useEffect(() => {
       
    }, []);

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    formInputLabelStyle["color"] = secondaryColor;

    var informationalWarnings = sprinklerEngineResultWarnings ? sprinklerEngineResultWarnings.filter(x => x.WarningSeverity === WarningSeverity.Information) : [];
    var errors = sprinklerEngineResultWarnings ? sprinklerEngineResultWarnings.filter(x => x.WarningSeverity !== WarningSeverity.Information): [];

    var calcButtonAvailable = isSprinklerCalcAvailable(system.SprinklerProperties, props.packagesInUse);

    var tabs = [<Tab label="Summary"/>];
    if(IsDualSided(props.sys)){
        tabs.push(<Tab label="Flanged Outlets"/>);
        tabs.push(<Tab label="Flexed Outlets"/>);
        tabs.push(<Tab label="Flanged Span Irrigation Information"  />);
        tabs.push(<Tab label="Flexed Span Irrigation Information"  />);
    } else{
        tabs.push(<Tab label="Outlets"/>);
        tabs.push(<Tab label="Span Irrigation Information"  />);
    }
    
    return (
        <Stack style={{height: '100%', marginRight: 15}}>
            {
                !sprinklerEngineResultWarnings &&
                <Stack style={{justifyContent: 'center', alignItems: 'center', marginTop:  50 }}>
                    <p>{calcButtonAvailable ?  t(sprinklersPrefix + "generate-sprinkler-chart") : t(sprinklersPrefix + "chart-generation-unavailable")}</p>          
                    <Button variant="contained" disabled={!calcButtonAvailable} style={{maxWidth: 300}} onClick={() => {
                        var actions = generateChart(authState, dbPrj, props.layoutId, props.systemId, system);
                        props.ctx.pushActionAndImproveScores(createNewMultiAction(actions, authState), [], false);
                    }}>{t(sprinklersPrefix + "calculate")} <b style={{marginLeft: 5}}> {t(sprinklersPrefix + "sprinkler-chart")}</b></Button>            
                </Stack>
            }
            {
                sprinklerEngineResultWarnings &&
                <Stack direction="row" style={{alignItems: 'center', marginTop: 10, marginBottom: 20}}>                  
                    <Button size="medium"  disabled={!calcButtonAvailable} variant="contained" style={{margin: 5}} onClick={() => {
                        var actions = generateChart(authState, dbPrj, props.layoutId, props.systemId, system);
                        props.ctx.pushActionAndImproveScores(createNewMultiAction(actions, authState), [], false);
                    }}><b>recalculate</b></Button>                          
                </Stack>
            }     
            {
                errors.length > 0 &&
                <>
                <p>{t(sprinklersPrefix + "chart-error")}</p>
                {
                     errors.map(w => 
                        <Alert  severity={getWarningSeverityType(w.WarningSeverity)}  style={{marginTop: 5}}>
                            <span><b>{getWarningType(w.WarningType)}</b> : {w.Context} - {w.Message}</span>
                        </Alert>)
                }
                </>              
            }
            {
                informationalWarnings.length > 0 &&                   
                informationalWarnings.map(w => 
                    <Alert  severity={getWarningSeverityType(w.WarningSeverity)}  style={{marginTop: 5}}>
                        <span><b>{getWarningType(w.WarningType)}</b> : {w.Context} - {w.Message}</span>
                    </Alert>)                     
            }
            {
                sprinklerEngineResultWarnings && errors.length === 0 &&
                    <Stack direction="column">
                        <Tabs                       
                            style={{marginLeft: 20, paddingRight: 30, width: '100%', }}
                            variant="fullWidth"
                            value={tabIndex} 
                            onChange={(event: React.SyntheticEvent, newValue: number) => { setTabIndex(newValue); }} 
                        >
                            {tabs}
                        </Tabs>                      
                        <div style={{marginTop: 30}}></div>
                        {
                            tabIndex === 0 &&
                            <SpanIrrigationInformation sys={system} systemId={props.systemId} layoutId={props.layoutId} project={project} />
                        }
                        {
                            tabIndex === 1 &&
                            <OutletsTable 
                                outlets={props.sys.FlangedSide.SprinklerChart ? props.sys.FlangedSide.SprinklerChart.Outlet : []}
                                sys={system}
                                side={props.sys.FlangedSide}
                             />
                        }
                        {
                            tabIndex === 2 && IsDualSided(props.sys) &&
                            <OutletsTable 
                                outlets={props.sys.FlexSide.SprinklerChart ? props.sys.FlexSide.SprinklerChart.Outlet : []} 
                                sys={system}
                                side={props.sys.FlexSide}
                            />
                        }
                        {
                            ((tabIndex === 2 && !IsDualSided(props.sys)) || (tabIndex === 3)) &&
                            <SpanIrrigationTable 
                                additionalSpanData={props.sys.FlangedSide.SprinklerChart ? props.sys.FlangedSide.SprinklerChart.AdditionalSpanData : null}
                                calcLoss={(spanNr) => {
                                    if(quote){
                                        let lossPsi = quote.SprinklerChartClass.RightSprinklersClass.SpanPressureLoss(spanNr);
                                        if (displaySettings.pressures === "psi") return lossPsi.toFixed(1);
                                        return psiToBar(lossPsi).toFixed(1);
                                    }else{
                                        return "-";
                                    }
                                }}
                            />
                        }
                        {
                            tabIndex === 4 && 
                            <SpanIrrigationTable 
                                additionalSpanData={props.sys.FlexSide.SprinklerChart ? props.sys.FlexSide.SprinklerChart.AdditionalSpanData : null}
                                calcLoss={(spanNr) => {
                                    if(quote){
                                        let lossPsi = quote.SprinklerChartClass.LeftSprinklersClass.SpanPressureLoss(spanNr);
                                        if (displaySettings.pressures === "psi") return lossPsi.toFixed(1);
                                        return psiToBar(lossPsi).toFixed(1);
                                    }else{
                                        return "-";
                                    }
                                }}
                                />
                        }
                    </Stack>
            }          
        </Stack>
        
    );
};

export default SprinklerChart;