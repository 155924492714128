import * as React from "react";
import { PropsWithChildren } from "react";
import IDbProject from "../../db/IDbProject";
import IValidationWorkerState, { ValidationWorkerStatus } from "../../workers/ValidationWorker/IValidationWorkerState";
import ValidationWorkerCtx from "../../workers/ValidationWorker/ValidationWorkerCtx";
import { SystemValidationResult } from "../roe/validation/SystemValidator";

interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
}

// Currently, this Validation Provider performs no validation
const SystemDefaultsValidationWorkerProvider: React.FC<PropsWithChildren<Props>> = (props) => {

    const vws: IValidationWorkerState = {
        status: ValidationWorkerStatus.Validated,
        validate: async () => new SystemValidationResult(),
        validateCmp: () => new SystemValidationResult()
    };

    return (
        <ValidationWorkerCtx.Provider value={vws}>
            {props.children}
        </ValidationWorkerCtx.Provider>
    );
}
export default SystemDefaultsValidationWorkerProvider;