import { Text, View } from "@react-pdf/renderer";
import * as React from "react";
import { FC } from "react";
import { formatDateLong } from "../DocumentGenerationHelpers";
import { documentStyles } from "../styles";

interface Props {
};

const Footer: FC<Props> = (props) => {
    return (
        <View fixed style={documentStyles.footer}>
            <Text/>
        <Text render={({ pageNumber, totalPages }) => (
            `${pageNumber}/${totalPages}`
    )} fixed />
        <Text>{formatDateLong(new Date())}</Text>
    </View>
    );
};

export default Footer;