import SegmentPolygonHelper from "../../../GeometryHelpers/SegmentPolygonHelper";
import ILayout from "../../../model/project/ILayout";
import IProject, { ProjectType } from "../../../model/project/IProject";
import { shouldCalculateEndGuns } from "./shouldCalculateEndGuns";

interface IProjectsMapIncomming {
    [projectId: string]: IProject | undefined;
}
export interface IProjectsMap {
    [projectId: string]: IProject;
}
type IFindModifiedSystemsItem = {
    projectId: string;
    layoutIds: string[];
}

const isLayoutEqual = (layout1: ILayout, layout2: ILayout) => {
    if (!SegmentPolygonHelper.isEqual(layout1.fieldBoundary, layout2.fieldBoundary)) return false;
    if (!SegmentPolygonHelper.isEqual(layout1.pivotCenterBoundary, layout2.pivotCenterBoundary)) return false;
    if (!SegmentPolygonHelper.isEqual(layout1.wetAreaBoundary, layout2.wetAreaBoundary)) return false;
    if (layout1.obstacles.length !== layout2.obstacles.length) return false;
    for (let i = 0; i < layout1.obstacles.length; i++) {
        if (!SegmentPolygonHelper.isEqual(layout1.obstacles[i], layout2.obstacles[i])) return false;
    }
    if (layout1.wheelObstacles.length !== layout2.wheelObstacles.length) return false;
    for (let i = 0; i < layout1.wheelObstacles.length; i++) {
        if (!SegmentPolygonHelper.isEqual(layout1.wheelObstacles[i], layout2.wheelObstacles[i])) return false;
    }
    return true;
}

export const findModifiedLayouts = (previousProjects: IProjectsMapIncomming, incomingProjects: IProjectsMap): IFindModifiedSystemsItem[] => {
    const items: IFindModifiedSystemsItem[] = [];
    for (const projectId of Object.keys(incomingProjects)) {
        const incommingProject = incomingProjects[projectId];
        if (incommingProject.projectType !== ProjectType.LayoutAndDesign) continue;
        const previousProject = previousProjects && previousProjects[projectId];
        const layoutIds: string[] = [];
        const incommingLayoutIds = Object.keys(incommingProject.layouts);
        if (!previousProject) {
            layoutIds.push(...incommingLayoutIds);
        }
        else {
            for (const layoutId of incommingLayoutIds) {
                const incommingLayout = incommingProject.layouts[layoutId];
                const previousLayout = previousProject?.layouts[layoutId];
                const isPreviousLayoutEqual = previousLayout === undefined ? false : isLayoutEqual(previousLayout, incommingLayout);
                if (!isPreviousLayoutEqual) {
                    layoutIds.push(layoutId);                
                }
                else {
                    if (Object.keys(incommingLayout.systems).length !== Object.keys(previousLayout.systems).length) {
                        layoutIds.push(layoutId);
                    }
                    else {
                        if (
                            Object.keys(incommingLayout.systems).some(systemId => {
                                const incommingSystem = incommingLayout.systems[systemId];
                                const previousSystem = previousLayout?.systems[systemId];
                                const shouldGenerate = shouldCalculateEndGuns(previousSystem, incommingSystem);
                                return shouldGenerate;
                            })
                        ) {
                            layoutIds.push(layoutId);
                        }
                    }
                }
            }
        }
        if (layoutIds.length) {
            items.push({
                projectId,
                layoutIds
            })
        }
    }
    return items;
}
