import { styled as muiStyled } from "@mui/material/styles";

export const MapViewContainer = muiStyled('div', {
    shouldForwardProp: (prop) => (
        prop !== "bottomDrawerOpen" && 
        prop !== "leftDrawerOpen" && 
        prop !== "leftDrawerWidth" && 
        prop !== "bottomDrawerHeight"
    )
})<{ 
    bottomDrawerOpen: boolean;
    leftDrawerOpen: boolean;
    leftDrawerWidth: number;
    bottomDrawerHeight: number;
}>(({ theme, bottomDrawerOpen, leftDrawerOpen, leftDrawerWidth, bottomDrawerHeight }) => ({
    position: 'relative',
    height: "100%",
    ...(bottomDrawerOpen && {
        transition: theme.transitions.create(["height"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
        height: `calc(100% - ${bottomDrawerHeight}px)`,
    }),
    
    ...(leftDrawerOpen && {
        transition: theme.transitions.create(["width", "left"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            width: `calc(100% - ${leftDrawerWidth}px)`,
            left: `${leftDrawerWidth}px`,
    })
}));