import { ISystemBase, SpanTypes, TireTypes, TowerHeights } from "rdptypes/project/ISystemBase.AutoGenerated";
import { GetTireHeightAdjustment } from "./SideFunctions";
import { HasSwingArmCorner, IsReverseTow } from "./SystemFunctions";
import { Side, Tower } from "./Types";

export enum idxLeadType {
    ltStandard,
    ltLowProfile,
    ltSugargator,
    ltLong,
    ltSupergator,
}

    /**
     * A-B-C Boxes control the movement of the system. 
     */
    export enum BoxTypes {
        /**
         * A-Box is the standard control box for towers. Electrical controls (switches) go in these boxes. 
         */
        ABox,
        
        
        /**
         * B-Box is special because it has an "over-watering timer" and is ALWAYS before
         * the C Box. (Exceptions are Drop Span may not have a B-Box) 
         */
        BBox,
        
        /**
         * C-Box has fewer components in it. The C box is always at the LAST Tower.
         * The %timer tells the C-Box how fast to go. All other towers try to 
         * stay in-line with (follow) the last tower.B-Box is special because it has an "over-watering timer" and is ALWAYS before
         * the C Box. (Exceptions are Drop Span may not have a B-Box) 
         */
        CBox,
        /**
         * Box used to control sprinklers on a Pivoting Lateral exclusively 
         * when the Dual Sprinkler Package option is selected
         */
        SprinklerControlBox,
        
        /**
         * Same as an A-Box with guidance equipment in it. 
         */
        GuidanceBox
}

export const GetTowerHeight = (sys: ISystemBase, side: Side, tower: Tower) => {
    let tha = GetTireHeightAdjustment(tower.Tires);
    if (tower.TowerNumber !== side.Tower.length || !HasSwingArmCorner(sys)){
        if (!IsReverseTow(sys)){
            let st = side.Span[tower.TowerNumber - 1].SpanType;

            if (tower.TowerType === TowerHeights.LowProfile){
                if (st === SpanTypes.A60G) return 8 + tha;
                return 9 + GetTireHeightAdjustment(tower.Tires);
            }
            if (tower.TowerType === TowerHeights.Sugargator){
                if (st === SpanTypes.A80G) return 16 + tha;
                return 18 + tha;
            }
            if (st === SpanTypes.AlumIV || st === SpanTypes.A60G) return 12 + tha;
            return 13 + tha;
        }
        return 13 + tha;
    }

    if (tower.TowerType === TowerHeights.LowProfile) return 8 + tha;
    return 12 + tha;
}

export const AllSideTowerTiresNonDirectional = (side: Side) => 
    side.Tower.length && side.Tower.every(t => t.Tires.TireType === TireTypes.NonDirectional);