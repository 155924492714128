import { Button, IconButton, Snackbar, Stack, Tab, Tabs } from "@mui/material";
import * as React from "react";
import { FC, useContext, useState } from "react";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";

import styled from "@emotion/styled";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { t } from "i18next";
import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { IPackage } from "rdptypes/project/ISprinklers";
import { SprinklerManufacturerTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { HasPowerTowerEndBoom, IsDualSided } from "../../../../../roedata/roe_migration/SystemFunctions";
import { GeometryCtx } from "../../../../GeometryHelpers/GeometryProvider";
import { pageStyles, secondaryColor } from "../../styles";
import IComponentRenderContext from "../IComponentRenderContext";
import SprinklerChart from "./SprinklerChart/SprinklerChart";
import SprinklerOptions from "./SprinklerOptions";
import SprinklerPackageComponent from "./SprinklerPackage";
import { generateSprinklerValidator } from "./sprinklerValidator";

interface Props {
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    ctx: IComponentRenderContext,
};

export const sprinklerSelectStyle = true ? (true? {marginLeft: "10px", marginRight: "10px"} :{}) : {
    '& .MuiSelect-select .notranslate::after':  "props.cmp.title"
        ? {
            content: `"${ "props.cmp.title"}"`,
            opacity: 0.42,
          }
        : {},
};

export const sprinklersPrefix = "roe.sprinklers.";

const SprinklerTab = styled(Tab)({
    width: 125,
    wrapped: true
})

const Sprinklers: FC<Props> = (props) => {

    const dbPrj = props.dbPrj;
    const project = dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];
    const authState = useContext(AuthCtx);
    const [tabIndex, setTabIndex] = React.useState(0);
    const layoutElevation = useContext(GeometryCtx)?.elevation;

    const sprinklerValidator = generateSprinklerValidator({
        dbPrj,
        layoutId: props.layoutId,
        systemId: props.systemId,
        authState,
        ctx: props.ctx
    });

    const getPackageInUse = (): IPackage[] =>{
        const packages: IPackage[] = [];
        for(let i = 0; i<3; i++){
            packages.push(getSide(system, SideEnum.Flanged).Sprinklers.Package[i]);
        }
        if(IsDualSided(system)){
            for(let i=0; i<3; i++){
                packages.push(getSide(system, SideEnum.Flex).Sprinklers.Package[i]);
            }
        }
        return packages;
    }

    const [ openImgSource, setOpenImgSource ] = useState<string | null>(null);

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    formInputLabelStyle["color"] = secondaryColor;

    const packagesDisabled = sprinklerValidator.options.isError || 
        sprinklerValidator.getSide(SideEnum.Flanged).boomBacks.isError ||
        sprinklerValidator.getSide(SideEnum.Flex).boomBacks.isError ||
        ((system.SprinklerProperties.SprinklerEngineCode ?? SprinklerManufacturerTypes.None) === SprinklerManufacturerTypes.None);

    const chartDisabled = packagesDisabled ||
        sprinklerValidator.getSide(SideEnum.Flanged).packages.some(x => x.isError) ||
        (IsDualSided(system) && sprinklerValidator.getSide(SideEnum.Flex).packages.some(x => x.isError));

    // console.log("AA", {
    //     options: sprinklerValidator.options,
    //     flanged: sprinklerValidator.getSide(SideEnum.Flanged),
    //     flex: sprinklerValidator.getSide(SideEnum.Flex),
    // })
    const handleTooltipOpen = (src?: string) => {
        if(src) {
            setOpenImgSource(src);
        }  
    }
    
    const handleTooltipClose = () => {      
        setOpenImgSource(null);         
    }
    const createTabOptions = () => {
        const tabOptions: { tab: JSX.Element, body: JSX.Element, disabled: boolean }[] = [];

        // options:
        tabOptions.push({
            tab: (
                <SprinklerTab
                    key="tab-options"
                    label={t(sprinklersPrefix + "sprinkler-options")}
                />
            ),
            body: (
                <SprinklerOptions 
                    sprinklerValidator={sprinklerValidator}
                    layoutElevation={layoutElevation}
                    system={system}
                    layoutId={props.layoutId}
                    systemId={props.systemId}
                    project={project}
                    handleTooltipOpen={handleTooltipOpen}
                    handleTooltipClose={handleTooltipClose}
                />
            ),
            disabled: false
        });

        // flanged side:
        for (let i = 0; i < 3; i++) {
            const label = `${(IsDualSided(system) ? (HasPowerTowerEndBoom(system) ? t(sprinklersPrefix + "maxi-package") : t(sprinklersPrefix + "flanged-package")) : "Package")} ${i + 1}`;
            const disabled = packagesDisabled || 
                getSide(system, SideEnum.Flanged).Sprinklers.Package.slice(0, i).some(x => !x.EndingLocation);
            tabOptions.push({
                tab: (
                    <SprinklerTab 
                        key={`tab-flanged-${i}`}
                        label={label} 
                        disabled={disabled}
                        className={chartDisabled && !packagesDisabled && !disabled ? "tab_highlighted" : ""}
                    />
                ),
                body: disabled
                    ? null
                    : (
                        <SprinklerPackageComponent 
                            system={system} 
                            handleTooltipOpen={handleTooltipOpen}
                            handleTooltipClose={handleTooltipClose}
                            package={getSide(system, SideEnum.Flanged).Sprinklers.Package[i]}
                            sprinklerValidator={sprinklerValidator}
                            side={SideEnum.Flanged}
                            key={`tab-flanged-body-${i}`}
                            index={i}
                        />
                    ),
                disabled
            })
        }

        // flex side:
        if (IsDualSided(system)) {
            for (let i = 0; i < 3; i++) {
                const label = `${HasPowerTowerEndBoom(system) ? t(sprinklersPrefix + "power-tower-package") : t(sprinklersPrefix + "flexed-package")} ${i + 1}`;
                const disabled = packagesDisabled || 
                    getSide(system, SideEnum.Flex).Sprinklers.Package.slice(0, i).some(x => !x.EndingLocation);
                tabOptions.push({
                    tab: (
                        <SprinklerTab 
                            key={`tab-flex-${i}`}
                            label={label} 
                            disabled={disabled}
                            className={chartDisabled && !packagesDisabled && !disabled ? "tab_highlighted" : ""}
                        />
                    ),
                    body: disabled
                        ? null
                        : (
                            <SprinklerPackageComponent 
                                system={system} 
                                handleTooltipOpen={handleTooltipOpen}
                                handleTooltipClose={handleTooltipClose}
                                package={getSide(system, SideEnum.Flex).Sprinklers.Package[i]}
                                sprinklerValidator={sprinklerValidator}
                                side={SideEnum.Flex}
                                key={`tab-flex-body-${i}`}
                                index={i}
                            />
                        ),
                    disabled
                })
            }
        }

        // chart:
        tabOptions.push({
            tab: (
                <SprinklerTab 
                    key="tab-chart"
                    label={t(sprinklersPrefix + "sprinkler-chart")} 
                    disabled={chartDisabled}
                    className={!chartDisabled ? "tab_highlighted" : ""}
                />
            ),
            body: (
                <SprinklerChart 
                    ctx={props.ctx} 
                    sys={system} 
                    dbPrj={props.dbPrj} 
                    layoutId={props.layoutId} 
                    systemId={props.systemId}
                    packagesInUse={getPackageInUse()} 
                />
            ),
            disabled: chartDisabled
        })


        return tabOptions;
    }
    const tabs = createTabOptions();

    const getStopIndicies = () => {
        let firstIndex = 0;
        for (let i = tabs.length - 1; i >= 0; i--) {
            if (!tabs[i].disabled) {
                firstIndex = i;
            }
        }

        let lastIndex = 0;
        for (let i = 0; i < tabs.length; i++) {
            if (!tabs[i].disabled) {
                lastIndex = i;
            }
        }
        return { firstIndex, lastIndex };
    }
    const stopIndicies = getStopIndicies();
    return (
        <>         
            <Stack direction="column" style={{ marginRight: 15}}>
                <Stack direction="row" style={{justifyContent: 'space-between'}}>
                    <IconButton
                        onClick={()=>{
                            let newTabIndex = tabIndex - 1;
                            while(tabs[newTabIndex].disabled){
                                newTabIndex--;
                            }
                            setTabIndex(newTabIndex);     
                        }}
                        disabled={tabIndex === stopIndicies.firstIndex}
                    >
                        <ArrowBackIcon/>
                    </IconButton>
                    <Tabs 
                        style={{marginLeft: 20, marginRight: 20, width: '100%'}}
                        variant="fullWidth"
                        centered
                        value={tabIndex} 
                        onChange={(event: React.SyntheticEvent, newValue: number) => setTabIndex(newValue) }
                    >
                        { 
                            tabs.map(t => t.tab)
                        }
                        
                    </Tabs>
                    <IconButton              
                        onClick={()=>{
                            var newTabIndex = tabIndex + 1;
                            while(tabs[newTabIndex].disabled){
                                newTabIndex++;
                            }
                            setTabIndex(newTabIndex);                           
                        }}
                        disabled={tabIndex === stopIndicies.lastIndex}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                </Stack>
                {
                    tabs[tabIndex].body
                }
                {
                    tabIndex < tabs.length - 1 && <Button
                        variant="contained"
                        style={{width: "fit-content"}}              
                        onClick={()=>{
                            var newTabIndex = tabIndex + 1;
                            while(tabs[newTabIndex].disabled){
                                newTabIndex++;
                            }
                            setTabIndex(newTabIndex);                           
                        }}
                        disabled={tabIndex === stopIndicies.lastIndex}
                    >
                        {t("next")} <ArrowForwardIcon />
                    </Button>
                }
                {
                    openImgSource &&
                        <Snackbar
                            open={openImgSource !== null && openImgSource !== undefined}
                            autoHideDuration={6000}
                            onClose={() => {setOpenImgSource(null); }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}                
                        >
                            <div style={{marginTop: 50, marginRight: 20}}><img src={openImgSource} /></div>
                        </Snackbar>
                }
            </Stack>
        </>
    );
};

export default Sprinklers;