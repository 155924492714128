import { ISystemBase, SystemTypes, TowTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "./roe_migration/QuoteClass";
import { EndingLocation, PipeType, SpanPipeTypes } from "./roe_migration/SpanFunctions";
import { FieldSets, HasEnergySaverPackage, IsDualSided } from "./roe_migration/SystemFunctions";


export const getSystemLengthFeet = (sys: ISystemBase): number => {
    let sysLengthFeet = 0;
    let flangedSpans = sys.FlangedSide.Span;
    if (flangedSpans.length > 0){
        sysLengthFeet += EndingLocation(sys, sys.FlangedSide, flangedSpans[flangedSpans.length - 1]);
    }

    if (IsDualSided(sys)){
        let flexSideSpans = sys.FlexSide.Span;
        if (flexSideSpans.length > 0){
            sysLengthFeet += EndingLocation(sys, sys.FlexSide, flexSideSpans[flexSideSpans.length - 1]);
        }
    }

    return sysLengthFeet;
}

export const getSystemElectricalFrequencyHertz = (sys: ISystemBase) => sys.ControlPanel.ElectricalFrequency;


export const getSystemTopOfInletPressurePsi = (quote: QuoteClass): number => {
    //this used to use PumpElevation but I don't think current ROE has a separate Pump/PeakElevation anyway
    if (!quote.System.SprinklerProperties || typeof quote.System.SprinklerProperties.PivotPressure === "undefined" || typeof quote.System.SprinklerProperties.PeakElevation === "undefined"){
        return undefined;
    }

    if (HasEnergySaverPackage(quote.System)){
        let mmfrp = quote.GetMinMaxFlowRateAndPSILoss();

        let sPumpElevationLoss = 0 - (quote.System.SprinklerProperties.PeakElevation / 2.31);
        let totalGPM = quote.System.SprinklerProperties.TotalGPM;

        let sMainLineFactorLoss = quote.System.SprinklerProperties.MainlineLossAtMaxGPMForESP * Math.pow((totalGPM/mmfrp.maxFlow), 1.85);

        return quote.System.SprinklerProperties.PumpPressureAtSysGPMForESP - quote.BasePressureMod() - sPumpElevationLoss - sMainLineFactorLoss;
    }
    else {
        return quote.System.SprinklerProperties.PivotPressure;
    }
}

export const getSystemElevationFeet = (sys: ISystemBase): number => sys.SprinklerProperties.PeakElevation ?? 0;



export const getESPRelatedLoss = (quote: QuoteClass): number => {
    if (!HasEnergySaverPackage(quote.System) || !FieldSets(quote.System).SprinklerChart.DataValid() || !FieldSets(quote.System).SprinklerConfig.DataValid()){
        return 0;
    }

    let sprinklerprops = quote.System.SprinklerProperties;

    let mmfrp = quote.GetMinMaxFlowRateAndPSILoss();
    let endPressure = quote.System.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure;
    let systemElevationLoss = sprinklerprops.PeakElevation / 2.31;
    let pumpElevationLoss = 0 - sprinklerprops.PumpElevation / 2.31;

    let totalGPM = quote.System.SprinklerProperties.TotalGPM;
    let sMainLineFactorLoss = quote.System.SprinklerProperties.MainlineLossAtMaxGPMForESP * Math.pow((totalGPM/mmfrp.maxFlow), 1.85);

    let psiLossAtSysFlow = sprinklerprops.PivotPressure - endPressure - systemElevationLoss;

    return psiLossAtSysFlow + sprinklerprops.PumpPressureAtSysGPMForESP - endPressure - systemElevationLoss - quote.BasePressureMod()  - pumpElevationLoss - sMainLineFactorLoss;
}



export const isCenterPivot = (sys: ISystemBase) => {
    let st = sys.SystemProperties.SystemType;
    return st === SystemTypes.CenterPivot || st === SystemTypes.SwingArmRetro || st === SystemTypes.KwikTow;
}

export const systemHasPipeType = (quote: QuoteClass, pts: SpanPipeTypes[]) => {
    if (quote.System.FlangedSide.Span.length > 0){
        quote.System.FlangedSide.Span.forEach((s) => {
            let pt = PipeType(quote.System, quote.System.FlangedSide, s);
            if (pts.indexOf(pt) !== -1) return true;
        });
    }
    if (quote.System.FlexSide.Span.length > 0){
        quote.System.FlexSide.Span.forEach((s) => {
            let pt = PipeType(quote.System, quote.System.FlexSide, s);
            if (pts.indexOf(pt) !== -1) return true;
        });
    }

    return false;
}

export const enableJackKit = (sys: ISystemBase) => (sys.SystemProperties.SystemType === SystemTypes.CenterPivot && sys.Circle.CenterPivot.TowOptions.TowType !== TowTypes.None)
|| (sys.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && sys.Lateral.HoseFeed.Towable) || (sys.SystemProperties.SystemType === SystemTypes.KwikTow);