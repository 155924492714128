import { InputLabel } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getEndOfSystemIfValid } from "rdptypes/helpers/EndOfSystem";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import { EndOfSystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useContext } from "react";
import { createSetEndOfSystemAction } from "../../../../actions/SetEndOfSystemAction";
import AuthCtx from "../../../../auth/AuthCtx";
import DevSettingsCtx from "../../../../db/DevSettingsCtx";
import IDbProject from '../../../../db/IDbProject';
import { getEndBoomLengths } from "../../../../reinkeComponentConversions/endBoomLengths";
import { pageStyles, primaryColor } from "../../styles";
import IComponentRenderContext from "../IComponentRenderContext";
import { IEndOfSystemValidationResult } from "./endOfSystemValidator";

interface Props {
  dbPrj: IDbProject;
  layoutId: string;
  systemId: string;
  side: SideEnum;
  ctx: IComponentRenderContext;
  endOfSystemValidation: IEndOfSystemValidationResult;
};

const EndBoomSelect: FC<Props> = (props) => {

  const settings = useContext(DevSettingsCtx);
  const displaySettings = settings.dealerSettings.display.current;
  const authState = useContext(AuthCtx);
  const project = props.dbPrj.state;
  const layout = project.layouts[props.layoutId];
  const system = layout.systems[props.systemId];
  const currentValue = getEndOfSystemIfValid(system, props.side)?.endBoomLength;

  const fvr = props.endOfSystemValidation.endBoomLength;
  let errorSeverity = fvr?.severity;
  
  var menuItems: JSX.Element[] = [];

  if (props.ctx.editingDealerDefaults) {
      const menuItemCmp = (
          <MenuItem 
              key="Select" 
              value={undefined}
          > 
              <ListItemText>Select (No Default)</ListItemText>
          </MenuItem>
      )
      menuItems.push(menuItemCmp);
  }

  {
    // none option:
    const disabled = fvr?.badValues.has(0);
    let toolTipText = (fvr?.badValues.get(0)?.reasons ?? []).join(", ");
    if (toolTipText !== "") {
      toolTipText = "Selecting this option would introduce validation errors: " + toolTipText;
    }
    const menuItemCmp = (
      <MenuItem 
        key="None" 
        value={0} 
        title={toolTipText}
        style={{color: disabled ? "lightgrey" : undefined}} // Cannot use the disabled prop here because it blocks the tooltips
      >                          
        <ListItemText>None (3' end cap)</ListItemText>
      </MenuItem>
    );
    menuItems.push(menuItemCmp);
  }

  getEndBoomLengths(displaySettings.lengths).forEach((option, i) => {
    const disabled = fvr?.badValues.has(option.originalValue);
    let toolTipText = (fvr?.badValues.get(option.originalValue)?.reasons ?? []).join(", ");
    if (toolTipText !== "") {
      toolTipText = "Selecting this option would introduce validation errors: " + toolTipText;
    }

    const menuItemCmp = (
      <MenuItem 
        key={option.originalValue} value={option.originalValue}
        style={{color: disabled ? "lightgrey" : undefined}} // Cannot use the disabled prop here because it blocks the tooltips
        title={toolTipText}>                                              
        <ListItemText>{option.label}</ListItemText>
      </MenuItem>
    );
    menuItems.push(menuItemCmp);
  });
  
  let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
  if (errorSeverity !== "error" && errorSeverity !== "notset"){
    formInputLabelStyle["color"] = primaryColor;
  }
  
  return (
    <FormControl variant="standard" sx={{ m: 1, width: '100%', margin: 0 }} error={errorSeverity === "error" || errorSeverity === "notset"}>
        <InputLabel shrink style={formInputLabelStyle}>End Boom Length</InputLabel>
        <Select
            sx={{ color: (errorSeverity === "error" || errorSeverity === "notset") ? "red": undefined }}
            value={currentValue}
            displayEmpty
            renderValue={_ => currentValue === undefined ? props.ctx.editingDealerDefaults ? "Select (No Default)" : "Select"
              : currentValue === 0 ? "None (3' end cap)"
              : getEndBoomLengths(displaySettings.lengths).filter(x => x.originalValue == currentValue)[0].label}
            onChange={(ev) => {
                const value = ev.target.value;

                if (value === undefined) {

                    props.ctx.pushActionAndImproveScores(
                      createSetEndOfSystemAction(
                          props.layoutId,
                          props.systemId,
                          props.side,
                          {
                              endOfSystemType: EndOfSystemTypes.EndBoom
                          },
                          authState
                      ), [], true
                  );
                  return;
                }

                const endBoomLengthFeet = typeof value === 'number' ? value : parseFloat(value);
                
                if (fvr?.badValues.has(endBoomLengthFeet)) {
                  // Disabled
                  return;
                }
                props.ctx.pushActionAndImproveScores(
                    createSetEndOfSystemAction(
                        props.layoutId,
                        props.systemId,
                        props.side,
                        {
                            endOfSystemType: EndOfSystemTypes.EndBoom,
                            endBoomLengthFeet
                        },
                        authState
                    ), [], true
                )
            }}         
        >
            {
                menuItems
            }
        </Select>
    </FormControl>
  );
}

export default EndBoomSelect;