import { AccountInfo, AuthenticationResult, IPublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import IUserData from "rdptypes/IUserData";
import IDeleteOrderResponse from "rdptypes/api/IDeleteOrderResponse";
import IGetAllowedShipDatesReq from "rdptypes/api/IGetAllowedShipDatesReq";
import IGetAllowedShipDatesResp from "rdptypes/api/IGetAllowedShipDatesResp";
import IGetGrowersResponse from "rdptypes/api/IGetGrowersResponse";
import IGetOrderStatusResp from "rdptypes/api/IGetOrderStatusResp";
import IGetUsersICanAssumeResp from "rdptypes/api/IGetUsersICanAssumeResp";
import IListProjectsResponse from "rdptypes/api/IListProjectsResponse";
import IProjectData from "rdptypes/api/IProjectData";
import IPutOrderRequest from "rdptypes/api/IPutOrderRequest";
import IPutOrderResponse from "rdptypes/api/IPutOrderResponse";
import { AZURE_B2C_CLIENT_ID } from "../constants";
import ApiRequestState, { ApiRequestStatus } from "./ApiRequestState";
import IApiClient from "./IApiClient";

const baseUrl = "/api";

export default class FetchApiClient implements IApiClient {
    constructor(
        private msal: IPublicClientApplication,
        private account: AccountInfo,
        private assumedUserId: string) {
    }

    request = async <T>(path: string, options?: RequestInit): Promise<ApiRequestState<T>> => {
        let token: AuthenticationResult;

        try {
            token = await this.msal.acquireTokenSilent({
                scopes: [AZURE_B2C_CLIENT_ID],
                account: this.account
            });

            if (!token.expiresOn || token.expiresOn.getTime() - Date.now() < 300000) {
                console.log(`Forcing ID token refresh due to expired token from cache: ${token.expiresOn}`);

                // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206#issuecomment-1433286509

                token = await this.msal.acquireTokenSilent({
                    scopes: [AZURE_B2C_CLIENT_ID],
                    account: this.account,
                    forceRefresh: true
                });
            }
        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
                token = await this.msal.acquireTokenPopup({
                    scopes: [AZURE_B2C_CLIENT_ID],
                    account: this.account
                });
            } else {
                throw err;
            }
        }

        options = { ...options, headers: { ...options?.headers,
            "X-Reinke-Authorization": token.idToken,
            "X-Reinke-Assumed-UserId": this.assumedUserId,
        }};

        try {
            const resp = await fetch(`${baseUrl}${path}`, options );
            if (resp.status < 200 || resp.status >= 400) {
                return {
                    status: ApiRequestStatus.Error,
                    error: `Status: ${resp.status}: ${await resp.text()} `
                };
            }
            const json = await resp.json();

            return {
                status: ApiRequestStatus.Success,
                result: json
            };
        } catch (err) {
            return {
                status: ApiRequestStatus.Error,
                result: err
            };
        }
    };

    listProjects = () => this.request<IListProjectsResponse>("/projects");
    syncGetProject = (projectId: string) => this.request<IProjectData>(`/projects/${projectId}`);
    syncPutProject = (project: IProjectData) => this.request<void>(`/projects/${project.projectId}`, { method: "PUT", body: JSON.stringify(project) });
    getGrowers = () => this.request<IGetGrowersResponse>("/growers");
    putGrowers = (growers: IGetGrowersResponse) => this.request<void>(`/growers`, { method: "PUT", body: JSON.stringify(growers) });
    putOrder = (systemId: string, req: IPutOrderRequest) => this.request<IPutOrderResponse>(`/orders/${systemId}`, { method: "PUT", body: JSON.stringify(req), headers: {
        "content-type": "application/json"
    } });
    getMe = () => this.request<IUserData>("/me");
    getUsersICanAssume = () => this.request<IGetUsersICanAssumeResp>("/me/usersICanAssume");
    getAllowedShipDates = (query: IGetAllowedShipDatesReq) => this.request<IGetAllowedShipDatesResp>("/allowedShipDates", { method: "POST", body: JSON.stringify(query), headers: {
        "content-type": "application/json"
    } });
    getOrder = (quoteId: string) => this.request<IGetOrderStatusResp>(`/orders/${quoteId}`);
    deleteOrder = (systemId) => this.request<IDeleteOrderResponse>(`/orders/${systemId}`, { method: "DELETE" });
}