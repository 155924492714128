export class OutletControlRecord {
    public OutletCount: number = 0;
    public PressureNotMetCount: number = 0;
    public PressureExceededCount: number = 0;
    public SprinklersExist: boolean = false;
    
    public Reset = () => {
        this.SprinklersExist = false;
        this.PressureExceededCount = 0;
        this.PressureNotMetCount = 0;
    }
}

