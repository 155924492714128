import { SideEnum } from "rdptypes/helpers/SideEnum";
import { isCenterPivot } from "../../helpers/system";
import ISystem from "../../model/project/ISystem";

export const getFwdSide = (sys: ISystem): SideEnum => {
    if (isCenterPivot(sys)) {
        return SideEnum.Flanged;
    }
    if (sys.flexIsFwd) {
        return SideEnum.Flex;
    }
    return SideEnum.Flanged;
}

export const getAftSide = (sys: ISystem): SideEnum | undefined => {
    if (isCenterPivot(sys)) {
        return undefined;
    }
    if (sys.flexIsFwd) {
        return SideEnum.Flanged;
    }
    return SideEnum.Flex;
}