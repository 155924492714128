import IAction from "rdptypes/IAction";
import { ISpan, ITower } from "rdptypes/project/Types";
import IAuthState from "../auth/IAuthState";
import { IFieldSettings } from "../components/DealerSettingsDialog/FieldSettings";
import { ISacOptimizerSettingsFormState } from "../components/DealerSettingsDialog/SacOptimizerSettings";
import { ILogoSettingsFormState } from "../components/DealerSettingsDialog/TabDealerLogo";
import { IDisplaySettingsFormState } from "../components/DealerSettingsDialog/TabDisplaySettings";
import { IMapSettingsFormState } from "../components/DealerSettingsDialog/TabMapSettings";
import { MapLayerType } from "../components/Map";
import { IOptimizeCenterPivotFormState } from "../components/OptimizeSystemDialog/OptimizeCenterPivotForm";
import { IOptimizeLateralFormState } from "../components/OptimizeSystemDialog/OptimizeLateralForm";
import { ERdpFeatureType } from "../helpers/ERdpFeatureTypes";
import { ERdpLabels } from "../helpers/ERdpLabels";
import IDbContextProject from "./IDbProject";

export interface IDealerCustomerSettings {
    listPriceDiscountPercent: number;
    markupPercentOverDealerCost: number;
    markupDollarOverDealerCost: number;
    salesTaxPercent: number;
    systemIsTaxable: boolean;
    freightIsTaxable: boolean;
    pivotPadIsTaxable: boolean;
    installationIsTaxable: boolean;
    tradeinIsTaxable: boolean;
    printListPrice: boolean;
    onlyPrintTotalPrice: boolean;
}//these represent default settings and some can be overwritten during proposal generation

export interface IDealerPricing {
    programDiscountPercent: number;
    deliveryDiscountPercent: number;
    tmDiscountPercent: number;
}

export const DEFUALT_MAP_LABELS_SETTINGS: ERdpFeatureType[] = [
    ERdpFeatureType.FIELD_BOUNDARY,
    ERdpFeatureType.PIVOT_CENTER_BOUNDARY,
    ERdpFeatureType.WET_AREA_BOUNDARY,
    ERdpFeatureType.SPAN_OBSTACLE,
    ERdpFeatureType.WHEEL_OBSTACLE,
    ERdpFeatureType.CANAL_ANNOTATION,
    ERdpFeatureType.ELECTRIC_LINE_ANNOTATION,
    ERdpFeatureType.LABEL_ANNOTATION,
    ERdpFeatureType.LINE_ANNOTATION,
    ERdpFeatureType.POINT_ANNOTATION,
    ERdpFeatureType.PUMP_ANNOTATION,
    ERdpFeatureType.WATER_LINE_ANNOTATION,
    ERdpFeatureType.CENTER_PIVOT_SYSTEM,
    ERdpFeatureType.LATERAL_SYSTEM
]

export default interface IDevSettingsState {
    mapSettings: {
        layerType: MapLayerType,
        setMapLayerType: (type: MapLayerType) => void,
        showLabels: boolean,
        setShowLabels: (show: boolean) => void,
        mapLabels: (ERdpFeatureType | ERdpLabels)[];
        setMapLabels: (mapLabels: (ERdpFeatureType | ERdpLabels)[]) => void;
        showClearances: boolean;
        setShowClearances: (show: boolean) => void;
    },
    growerFilterSettings: {
        showDeleted: boolean,
        setShowDeleted: (show: boolean) => void,
        showSharedWithMe: boolean,
        setShowSharedWithMe: (show: boolean) => void
    },
    projectFilterSettings : {
        showDeleted: boolean,
        setShowDeleted: (show: boolean) => void
    },
    dealerSettings: {
        system: {
            custom: {
                dbProject: IDbContextProject;
                layoutId: string;
                systemId: string;
                getSystemProperties: (layoutId: string, systemId: string, authState: IAuthState) => IAction[];
                getSpanProperties: () => ISpan;
                getTowerProperties: () => ITower;
            },
            useCustom: boolean;
            setUseCustom: (useCustom: boolean) => void;
        },
        customer: {
            custom: {
                formState: IDealerCustomerSettings,
                setFormState: (updatedFormState: IDealerCustomerSettings) => void
            },
            useCustom: boolean;
            setUseCustom: (useCustom: boolean) => void;
        },        
        dealerPricing: {
            custom: {
                formState: IDealerPricing,
                setFormState: (updatedFormState: IDealerPricing) => void
            },
            useCustom: boolean;
            setUseCustom: (useCustom: boolean) => void;
        },
        optimization: {
            centerPivot: {
                custom: {
                    formState: IOptimizeCenterPivotFormState;
                    setFormState: (updatedFormState: IOptimizeCenterPivotFormState) => void;
                },
                useCustom: boolean;
                setUseCustom: (useCustom: boolean) => void;
            },
            lateral: {
                custom: {
                    formState: IOptimizeLateralFormState;
                    setFormState: (updatedFormState: IOptimizeLateralFormState) => void;
                },
                useCustom: boolean;
                setUseCustom: (useCustom: boolean) => void;
            }
        },
        display: {
            custom: {
                formState: IDisplaySettingsFormState;
                setFormState: (updatedFormState: IDisplaySettingsFormState) => void;
            },
            useCustom: boolean;
            setUseCustom: (useCustom: boolean) => void;
            current: IDisplaySettingsFormState;
        },
        map: {
            custom: {
                formState: IMapSettingsFormState;
                setFormState: (updatedFormState: IMapSettingsFormState) => void;
            },
            useCustom: boolean;
            setUseCustom: (useCustom: boolean) => void;
            current: IMapSettingsFormState;
        },
        sacOptimizer: {
            custom: {
                formState: ISacOptimizerSettingsFormState;
                setFormState: (updatedFormState: ISacOptimizerSettingsFormState) => void;
            },
            useCustom: boolean;
            setUseCustom: (useCustom: boolean) => void;
            current: ISacOptimizerSettingsFormState;
        },
        logo: {
            custom: {
                formState: ILogoSettingsFormState;
                setFormState: (updatedFormState: ILogoSettingsFormState) => void;
            },
            useCustom: boolean;
            setUseCustom: (useCustom: boolean) => void;
            current: ILogoSettingsFormState;            
        }
    },
    fieldSettings: {
        custom: {
            formState: IFieldSettings;
            setFormState: (updatedFormState: IFieldSettings) => void;
        },
        useCustom: boolean;
        setUseCustom: (useCustom: boolean) => void;
        current: IFieldSettings;
    }
}
