import { Feature } from "@turf/turf";
import IAction from "rdptypes/IAction";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { ICloseSystemResponse } from ".";
import { createNewMultiAction } from "../../../../../../actions/MultiAction";
import { createNewUpdateLayoutPropertyAction } from "../../../../../../actions/UpdateLayoutProperty";
import AuthCtx from "../../../../../../auth/AuthCtx";
import OptimizeSystemDialog from "../../../../../../components/OptimizeSystemDialog";
import { IOptimizeSystemDialogResult, ISetClearancesFn } from "../../../../../../components/OptimizeSystemDialog/OptimizeSystemDialog";
import DbCtx from "../../../../../../db/DbCtx";
import * as optimizeCenterPivotModule from "../../../../../../optimization/centerPivot";
import * as optimizeLateralModule from "../../../../../../optimization/lateral";
import { OptimizationRunnerCtx } from "../../../../../../workers/OptimizationWorker";
import { Interaction } from "../../../interactionState";

interface Props {
    optimizeSystemType: "centerPivot" | "lateral";
    projectId: string;
    layoutId: string;
    onClose: (args: ICloseSystemResponse) => void;
    renderAdditionalFeatures: (features: Feature[]) => void;
    onCancel: () => void;
}

export const AddOptimizedSystem: React.FC<Props> = ({
    optimizeSystemType, projectId, layoutId, onClose, renderAdditionalFeatures, onCancel
}) => {
    
    const authState = React.useContext(AuthCtx);
    const dbState = React.useContext(DbCtx);
    // const settingsState = useContext(DevSettingsCtx);
    const optimizationRunnerState = React.useContext(OptimizationRunnerCtx);
    const dbProject = dbState.projects[projectId];
    const project = dbProject.state;
    const layout = project.layouts[layoutId];

    // START: info for optimize dialog
    // Note, wetArea and pivot center boundaries do not have s- or h- ctower clearances
    const hasClearancesBeenSet = (
        layout.fieldBoundary?.segments.some(x => x.sTowerClearanceOffset || x.hTowerClearanceOffset) ||
        layout.obstacles.some(o => o.segments.some(x => x.sTowerClearanceOffset || x.hTowerClearanceOffset)) ||
        layout.wheelObstacles.some(o => o.segments.some(x => x.sTowerClearanceOffset || x.hTowerClearanceOffset))
    );
    const setClearances: ISetClearancesFn = (sTowerClearanceFt: number, hTowerClearanceFt: number) => {
        const actions: IAction[] = [];
        if (layout.fieldBoundary) {
            const cloned = structuredClone(layout.fieldBoundary);
            cloned.segments.forEach(s => {
                s.sTowerClearanceOffset = sTowerClearanceFt;
                s.hTowerClearanceOffset = hTowerClearanceFt;
            });
            actions.push(
                createNewUpdateLayoutPropertyAction(
                    layoutId,
                    "fieldBoundary",
                    cloned,
                    authState
                )
            )
        }
        if (layout.obstacles.length) {
            const cloned = structuredClone(layout.obstacles);
            for (const c of cloned) {
                c.segments.forEach(s => {
                    s.sTowerClearanceOffset = sTowerClearanceFt;
                    s.hTowerClearanceOffset = hTowerClearanceFt;
                });
            }
            actions.push(
                createNewUpdateLayoutPropertyAction(
                    layoutId,
                    "obstacles",
                    cloned,
                    authState
                )
            )
        }
        if (layout.wheelObstacles.length) {
            const cloned = structuredClone(layout.wheelObstacles);
            for (const c of cloned) {
                c.segments.forEach(s => {
                    s.sTowerClearanceOffset = sTowerClearanceFt;
                    s.hTowerClearanceOffset = hTowerClearanceFt;
                });
            }
            actions.push(
                createNewUpdateLayoutPropertyAction(
                    layoutId,
                    "wheelObstacles",
                    cloned,
                    authState
                )
            )
        }
        if (actions.length) {
            dbProject.pushAction(
                createNewMultiAction(actions, authState)
            );
        }
    }
    // END: info for optimize dialog

    return (
        <OptimizeSystemDialog
            type = {optimizeSystemType}
            hasClearancesBeenSet={hasClearancesBeenSet}
            setClearances={setClearances}
            onClose={async (dialogResult: IOptimizeSystemDialogResult | null) => {
                if (dialogResult != null) {
                    if (optimizeSystemType === "centerPivot" && dialogResult.type === SystemTypes.CenterPivot) {
                        const { result } = dialogResult;
                        if (!result.optimizeSystem) {
                            onClose({ interaction: Interaction.DrawPivotSystem });
                        }
                        else {
                            const optimizationSettings: optimizeCenterPivotModule.IOptimizationSettings = {
                                allowPartialPivots: result.allowPartialPivots,
                                allowWrapSpans: result.allowWrapSpans,
                                maxNumberOfWrapSpans: result.maxNumberofWraps,
                                allowableSpanLengths: result.allowableSpanLengths,
                                allowDropSpans: result.allowDropSpans,
                                maxNumberOfDropSpans: result.maxNumberofDropSpans,
                                allowableEndBoomLengths: result.allowableEndBoomLengths,
                                sacType: result.sacType,
                                endOfSystemType: result.endOfSystemType,
                                sacIsLeading: result.sacIsLeading,
                                primaryEndGun: result.primaryEndGun,
                                secondaryEndGun: result.secondaryEndGun,
                                primaryEndGunThrow: result.primaryEndGunThrow,
                                secondaryEndGunThrow: result.secondaryEndGunThrow,
                                maxSystemRadiusFt: result.maxSystemRadiusFt,
                                minSystemRadiusFt: result.minSystemRadiusFt,
                                cpaRatio: result.cpaRatio
                            }
                            if (!result.optimizeCenter) {
                                onClose({ 
                                    interactionState: {
                                        interactionType: Interaction.DynamicCenterPivotOptimization,
                                        systemType: SystemTypes.CenterPivot,
                                        project: dbProject.state,
                                        optimizationSettings,
                                        layoutId: layoutId,
                                        authState
                                    }
                                });
                            }
                            else {
                                optimizationRunnerState.run({ 
                                    type: 'wfo',
                                    settings: optimizationSettings,
                                    dbProject: dbProject,
                                    layoutId,
                                    numberOfSystems: result.maxNumberOfSystems,
                                    renderFeatures: (fs) => renderAdditionalFeatures(fs)
                                });
                                onCancel();
                            }
                        }
                    }
                    else if (optimizeSystemType === 'lateral' && dialogResult.type === SystemTypes.HoseFeedMaxigator) {                                    
                        const { result } = dialogResult;
                        if (!result.optimizeSystem) {
                            onClose({
                                interactionState: {
                                    interactionType: Interaction.DrawLateralSystem,
                                    endFeed: result.endFeed,
                                    pivotingLateral: result.pivotingLateral
                                }
                            });
                        }
                        else {
                            const optimizationSettings: optimizeLateralModule.IOptimizationSettings = {
                                allowableSpanLengths: result.allowableSpanLengths,
                                endFeed: result.endFeed,
                                canalFeed: undefined,
                                allowDropSpans: result.allowDropSpans,
                                maxNumberOfDropSpans: result.maxNumberofDropSpans,
                                endOfSystemTypeAft: result.endOfSystemTypeAft,
                                restrictedEndBoomLengthsAft: result.restrictedEndBoomLengthsAft,
                                endOfSystemTypeFwd: result.endOfSystemTypeFwd,
                                restrictedEndBoomLengthsFwd: result.restrictedEndBoomLengthsFwd,
                                primaryEndGun: result.primaryEndGun,
                                secondaryEndGun: result.secondaryEndGun,
                                primaryEndGunThrow: result.primaryEndGunThrow,
                                secondaryEndGunThrow: result.secondaryEndGunThrow
                            }
                            onClose({
                                interactionState: {
                                    interactionType: Interaction.DynamicLateralOptimization,
                                    systemType: SystemTypes.HoseFeedMaxigator,
                                    project: dbProject.state,
                                    optimizationSettings,
                                    layoutId: layoutId,
                                    authState
                                }
                            });
                        }
                    }
                    else if (optimizeSystemType === 'lateral' && dialogResult.type === SystemTypes.CanalFeedMaxigator) {                                    
                        const { result } = dialogResult;
                        if (!result.optimizeSystem) {
                            onClose({
                                interactionState: {
                                    interactionType: Interaction.DrawLateralSystem,
                                    canalFeed: {
                                        canalWidthFeet: result.canalWidthFeet,
                                        distanceFromCanalCenterToFwdSide: result.distanceFromCanalCenterToFwdSide,
                                        distanceFromCanalCenterToAftSide: result.distanceFromCanalCenterToAftSide,
                                    },
                                    endFeed: result.endFeed
                                }
                            });
                        }
                        else {
                            const optimizationSettings: optimizeLateralModule.IOptimizationSettings = {
                                allowableSpanLengths: result.allowableSpanLengths,
                                endFeed: result.endFeed,
                                canalFeed: {
                                        canalCenterToFwdSide: result.distanceFromCanalCenterToFwdSide,
                                        canalCenterToAftSide: result.distanceFromCanalCenterToAftSide
                                    },
                                allowDropSpans: result.allowDropSpans,
                                maxNumberOfDropSpans: result.maxNumberofDropSpans,
                                endOfSystemTypeAft: result.endOfSystemTypeAft,
                                restrictedEndBoomLengthsAft: result.restrictedEndBoomLengthsAft,
                                endOfSystemTypeFwd: result.endOfSystemTypeFwd,
                                restrictedEndBoomLengthsFwd: result.restrictedEndBoomLengthsFwd,
                                primaryEndGun: result.primaryEndGun,
                                secondaryEndGun: result.secondaryEndGun,
                                primaryEndGunThrow: result.primaryEndGunThrow,
                                secondaryEndGunThrow: result.secondaryEndGunThrow
                            }
                            onClose({
                                interactionState: {
                                    interactionType: Interaction.DynamicLateralOptimization,
                                    systemType: SystemTypes.CanalFeedMaxigator,
                                    project: dbProject.state,
                                    optimizationSettings,
                                    layoutId: layoutId,
                                    authState,
                                    canalFeed: {
                                        canalWidthFeet: result.canalWidthFeet,
                                        distanceFromCanalCenterToFwdSide: result.distanceFromCanalCenterToFwdSide,
                                        distanceFromCanalCenterToAftSide: result.distanceFromCanalCenterToAftSide,
                                    }
                                }
                            });
                        }
                    }
                }
                else {
                    onCancel();
                }
            }}
        />
    );
}