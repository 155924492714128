[
    {
    "fileName":"Orbitor.jpg",
    "fieldPath":"Sprinklers / package type",
    "id":""
    },
    {
    "fileName":"AnchorBolts.png",
    "fieldPath":"Circle.CenterPivot.AnchorPackage",
    "id":"Bolt"
    },
    {
    "fileName":"SkidPkg.png",
    "fieldPath":"Circle.CenterPivot.AnchorPackage",
    "id":"Skid"
    },
    {
    "fileName":"ComPadAdapter.png",
    "fieldPath":"Circle.CenterPivot.AnchorPackage",
    "id":"CompetitivePadAdapter"
    },
    {
    "fileName":"ComPadAdapterChannelKit.png",
    "fieldPath":"Circle.CenterPivot.AnchorPackage",
    "id":"CompetitivePadAdapterWithChannel"
    },
    {
    "fileName":"E2065.jpg",
    "fieldPath":"Circle.CenterPivot.CenterPivotType",
    "id":"E2065"
    },
    {
    "fileName":"E2045.jpg",
    "fieldPath":"Circle.CenterPivot.CenterPivotType",
    "id":"E2045"
    },
    {
    "fileName":"E2045Pad.jpg",
    "fieldPath":"Circle.CenterPivot.E2045FoundationFormKit",
    "id":""
    },
    {
    "fileName":"EngineDrivenMiniPivot.png",
    "fieldPath":"design only wizard",
    "id":""
    },
    {
    "fileName":"StdLip.jpg",
    "fieldPath":"Circle.CenterPivot.Gasket",
    "id":"Standard"
    },
    {
    "fileName":"TripleLip.jpg",
    "fieldPath":"Circle.CenterPivot.Gasket",
    "id":"TripleLip"
    },
    {
    "fileName":"ElbowPlain.jpg",
    "fieldPath":"Circle.CenterPivot.PipeBottomFitting",
    "id":"ElbowPlain"
    },
    {
    "fileName":"ElbowSAE.jpg",
    "fieldPath":"Circle.CenterPivot.PipeBottomFitting",
    "id":"ElbowSAE"
    },
    {
    "fileName":"ElbowCoupler.jpg",
    "fieldPath":"Circle.CenterPivot.PipeBottomFitting",
    "id":"Elbow8Inch"
    },
    {
    "fileName":"8to6SAERedElbow.JPG",
    "fieldPath":"Circle.CenterPivot.PipeBottomFitting",
    "id":"ElbowReducer"
    },
    {
    "fileName":"8to6SAEFlangeAdapter.JPG",
    "fieldPath":"Circle.CenterPivot.PipeBottomAdapter",
    "id":""
    },
    {
    "fileName":"Adapter8to6StraightenerPipe.JPG",
    "fieldPath":"Circle.CenterPivot.PipeBottomAdapter8to6",
    "id":""
    },
    {
    "fileName":"ScreenFilterPad.jpg",
    "fieldPath":"Circle.CenterPivot.PipeBottomFitting",
    "id":"Filter700GPM"
    },
    {
    "fileName":"RiserFor6x19FlangedDevice.JPG",
    "fieldPath":"Circle.CenterPivot.RiserPipeFor6x19FlangeDevice",
    "id":""
    },
    {
    "fileName":"RiserFor8x23FlangedDevice.JPG",
    "fieldPath":"Circle.CenterPivot.RiserPipeFor8x23FlangeDevice",
    "id":""
    },
    {
    "fileName":"PivotCenterWalkway.jpg",
    "fieldPath":"Circle.CenterPivot.Walkway",
    "id":""
    },
    {
    "fileName":"SkidTowPkg.png",
    "fieldPath":"Circle.CenterPivot.TowOptions.TowType",
    "id":"SkidTow"
    },
    {
    "fileName":"FourWheelPivotMover.jpg",
    "fieldPath":"Circle.CenterPivot.TowOptions.TowType",
    "id":"FourWheelPivotMover"
    },
    {
    "fileName":"FlowTubeVanes.jpg",
    "fieldPath":"ControlPanel.FlowtubeWithVanes",
    "id":""
    },
    {
    "fileName":"FS100straightener.jpg",
    "fieldPath":"ControlPanel.FlowtubeWithFS100",
    "id":""
    },
    {
    "fileName":"10RingUnited.jpg",
    "fieldPath":"ControlPanel.CollectorRing",
    "id":"a10Ring"
    },
    {
    "fileName":"11RingICII.JPG",
    "fieldPath":"ControlPanel.CollectorRing",
    "id":"a11RingICII"
    },
    {
    "fileName":"PivotResolver.jpg",
    "fieldPath":"ControlPanel.EndTowerPositioning",
    "id":"Resolver"
    },
    {
    "fileName":"EOSGPS.jpg",
    "fieldPath":"ControlPanel.EndTowerPositioning",
    "id":"GPS"
    },
    {
    "fileName":"Propeller.jpg",
    "fieldPath":"ControlPanel.FlowmeterModel",
    "id":"McPropeller"
    },
    {
    "fileName":"Mag.jpg",
    "fieldPath":"ControlPanel.FlowmeterModel",
    "id":"McMag"
    },
    {
    "fileName":"Vertical.jpg",
    "fieldPath":"ControlPanel.Flowmeter",
    "id":"Vertical"
    },
    {
    "fileName":"Horizontal.jpg",
    "fieldPath":"ControlPanel.Flowmeter",
    "id":"Horizontal"
    },
    {
    "fileName":"McMag3000.jpg",
    "fieldPath":"\"flow tube label",
    "id":" we don't have it\""
    },
    {
    "fileName":"RPMBasic.png",
    "fieldPath":"ControlPanel.PanelModel",
    "id":"RPMBasic"
    },
    {
    "fileName":"RPMStandard.jpg",
    "fieldPath":"ControlPanel.PanelModel",
    "id":"RPMStandard"
    },
    {
    "fileName":"RPMAdvanced.jpg",
    "fieldPath":"ControlPanel.PanelModel",
    "id":"RPMAdvanced"
    },
    {
    "fileName":"RPMAdvancedPlus.jpg",
    "fieldPath":"ControlPanel.PanelModel",
    "id":"RPMAdvancedPlus"
    },
    {
    "fileName":"RPMConnect.JPG",
    "fieldPath":"ControlPanel.PanelModel",
    "id":"RPMConnect"
    },
    {
    "fileName":"RPMTouchScreen.jpg",
    "fieldPath":"ControlPanel.PanelModel",
    "id":"RPMPreferred"
    },
    {
    "fileName":"CamPlate.jpg",
    "fieldPath":"ControlPanel.SwitchMount",
    "id":"CamPlate"
    },
    {
    "fileName":"WeepPlugTB.jpg",
    "fieldPath":"ControlPanel.WeepHolePlug50Pack",
    "id":""
    },
    {
    "fileName":"WeepPlug.jpg",
    "fieldPath":"ControlPanel.WeepHolePlug5Pack",
    "id":""
    },
    {
    "fileName":"2hpStdMount.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.BoosterPump",
    "id":"TwoHP"
    },
    {
    "fileName":"ExtraReinkeEndSign.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.ExtraReinkeEndSign",
    "id":""
    },
    {
    "fileName":"2hpVertMount.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.VerticalBoosterMount",
    "id":""
    },
    {
    "fileName":"R55i.JPG",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
    "id":"R55i"
    },
    {
    "fileName":"R55.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
    "id":"R55"
    },
    {
    "fileName":"R75.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
    "id":"R75"
    },
    {
    "fileName":"P85AS.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
    "id":"SingleP85"
    },
    {
    "fileName":"SR75BigGun.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
    "id":"SR75"
    },
    {
    "fileName":"SR100BigGun.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
    "id":"SR100"
    },
    {
    "fileName":"SRNV100BigGun.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
    "id":"SR100NV"
    },
    {
    "fileName":"WingEndGun.png",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
    "id":"SimeWing"
    },
    {
    "fileName":"KometTwinmax18.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
    "id":"TwinMax"
    },
    {
    "fileName":"KometTwin101Ultra.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
    "id":"Twin101Ultra"
    },
    {
    "fileName":"2BallValve.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.Valve",
    "id":"Manual"
    },
    {
    "fileName":"AquamaticValve.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.Valve",
    "id":"Diaphragm"
    },
    {
    "fileName":"ReinkeValve.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.Valve",
    "id":"Reinke"
    },
    {
    "fileName":"Nelson800Valve.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.Valve",
    "id":"Auto800"
    },
    {
    "fileName":"Nel1000Valve.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndGun.Valve",
    "id":"Auto1000"
    },
    {
    "fileName":"EndLight.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.EndLight",
    "id":"Standard"
    },
    {
    "fileName":"StdSAC.jpg",
    "fieldPath":"not in vb",
    "id":""
    },
    {
    "fileName":"StrainerLastTwrTop.jpg",
    "fieldPath":"FlangedSide.EndOfSystem.Strainer",
    "id":""
    },
    {
    "fileName":"FurrowGuide.jpg",
    "fieldPath":"Lateral.Guidance.GuidanceType",
    "id":"Furrow"
    },
    {
    "fileName":"CntrLift.jpg",
    "fieldPath":"Lateral.HoseFeed.CenterLift",
    "id":""
    },
    {
    "fileName":"4WhlHosEndFeed.jpg",
    "fieldPath":"Lateral.HoseFeed.HoseFeedType",
    "id":"FourWheelDrive"
    },
    {
    "fileName":"A100.jpg",
    "fieldPath":"Lateral.HoseFeed.HoseFeedType",
    "id":"A100"
    },
    {
    "fileName":"PivotingLateral2.jpg",
    "fieldPath":"Lateral.HoseFeed.HoseFeedType",
    "id":"PivotingLateral"
    },
    {
    "fileName":"PwrTwrBoom.jpg",
    "fieldPath":"Lateral.HoseFeed.PowerTowerEndBoom",
    "id":""
    },
    {
    "fileName":"4WhlCntrFd.jpg",
    "fieldPath":"Lateral.WaterFeed",
    "id":"CenterFeed"
    },
    {
    "fileName":"O3000English.pdf",
    "fieldPath":"sprinkler nelson orbitor 3000 package type",
    "id":""
    },
    {
    "fileName":"O3000Spanish.pdf",
    "fieldPath":"pdf not picture",
    "id":""
    },
    {
    "fileName":"FlushValve.jpg",
    "fieldPath":"Options.FlushValve",
    "id":""
    },
    {
    "fileName":"MoveBarricade.jpg",
    "fieldPath":"Options.MovableBarricades",
    "id":""
    },
    {
    "fileName":"StayBarricade.jpg",
    "fieldPath":"Options.StandardLowProfileStationaryBarricades",
    "id":""
    },
    {
    "fileName":"StdPivotLight.jpg",
    "fieldPath":"Options.PivotLight",
    "id":"Standard"
    },
    {
    "fileName":"ReinkeSystemsUserGuide.pdf",
    "fieldPath":"pdf",
    "id":""
    },
    {
    "fileName":"ConduitClips.jpg",
    "fieldPath":"SpanProperties.ConduitClipWOSpringClip",
    "id":""
    },
    {
    "fileName":"ClipTDC.jpg",
    "fieldPath":"SpanProperties.TheftDeterrentClip",
    "id":""
    },
    {
    "fileName":"CN+_Disclaimer3.jpg",
    "fieldPath":"SpanProperties.GalvanizedCNPlus",
    "id":""
    },
    {
    "fileName":"LongSystemAlignment.jpg",
    "fieldPath":"SpanProperties.LongSystemAlignment",
    "id":""
    },
    {
    "fileName":"SpanStabilizer.jpg",
    "fieldPath":"Stabilizers",
    "id":""
    },
    {
    "fileName":"SpanDisconnect.jpg",
    "fieldPath":"Disconnecting",
    "id":""
    },
    {
    "fileName":"CN+_Disclaimer1.jpg",
    "fieldPath":"Circle.SACRetro.ParentSpanType",
    "id":"Valley"
    },
    {
    "fileName":"CN+_Disclaimer2.jpg",
    "fieldPath":"Circle.SACRetro.ParentSpanType",
    "id":"ValleyWithDrop"
    },
    {
    "fileName":"Endpressuredrop.jpg",
    "fieldPath":"SprinklerProperties.EndPressureGauge",
    "id":""
    },
    {
    "fileName":"3TN3NV.JPG",
    "fieldPath":"SprinklerProperties.SprinklerEngineCode",
    "id":"Nelson"
    },
    {
    "fileName":"iWobUP3components.jpg",
    "fieldPath":"SprinklerProperties.SprinklerEngineCode",
    "id":"Senninger"
    },
    {
    "fileName":"KPTfeatures.jpg",
    "fieldPath":"SprinklerProperties.SprinklerEngineCode",
    "id":"Komet"
    },
    {
    "fileName":"PressureGauge.jpg",
    "fieldPath":"SprinklerProperties.PressureGauge",
    "id":"a100PSI"
    },
    {
    "fileName":"BlueHose.jpg",
    "fieldPath":"HoseDrop.ReinkeBlue",
    "id":""
    },
    {
    "fileName":"KometTrussRodClips.JPG",
    "fieldPath":"HoseDrop.UseKometTrussRodSlings",
    "id":""
    },
    {
    "fileName":"1lbWeights.JPG",
    "fieldPath":"HoseDrop.DeviceWeight",
    "id":"OnePound"
    },
    {
    "fileName":"UniversalMagnumWeight.jpg",
    "fieldPath":"HoseDrop.DeviceWeight",
    "id":"Magnum"
    },
    {
    "fileName":"HoseDragAdapter.png",
    "fieldPath":"HoseDrop.DragAdapter",
    "id":""
    },
    {
    "fileName":"slimweight.jpg",
    "fieldPath":"HoseDrop.Weight",
    "id":"6"
    },
    {
    "fileName":"CoilWeight.JPG",
    "fieldPath":"HoseDrop.Weight",
    "id":"7"
    },
    {
    "fileName":"SenningerSgl180Gooseneck.JPG",
    "fieldPath":"HoseDrop.UPipeType",
    "id":"3"
    },
    {
    "fileName":"SenningerSgl125Gooseneck.JPG",
    "fieldPath":"HoseDrop.UPipeType",
    "id":"4"
    },
    {
    "fileName":"SenningerDbl125Gooseneck.JPG",
    "fieldPath":"HoseDrop.UPipeType",
    "id":"5"
    },
    {
    "fileName":"NelsonGoosenecks.JPG",
    "fieldPath":"HoseDrop.UPipeType",
    "id":"6"
    },
    {
    "fileName":"MaleAdapter.png",
    "fieldPath":"HoseDrop.WeightBottomFitting",
    "id":"3"
    },
    {
    "fileName":"KometNozzleCarrier.jpg",
    "fieldPath":"KometNozzleClip",
    "id":"Dual"
    },
    {
    "fileName":"KPT_ontop.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"26"
    },
    {
    "fileName":"KPTla.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"20"
    },
    {
    "fileName":"KPTula.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"21"
    },
    {
    "fileName":"KPS.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"22"
    },
    {
    "fileName":"KPSgrey.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"23"
    },
    {
    "fileName":"KPSblack.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"24"
    },
    {
    "fileName":"KPSyellow.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"25"
    },
    {
    "fileName":"KPTstd.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"19"
    },
    {
    "fileName":"KometKPRPrecisionRegulator.tif",
    "fieldPath":"Regulator.RegulatorType",
    "id":"10"
    },
    {
    "fileName":"Nelson3030Series.JPG",
    "fieldPath":"UseNelson3030",
    "id":""
    },
    {
    "fileName":"ADMNPTbody.jpg",
    "fieldPath":"UseNelsonAD3030MT",
    "id":""
    },
    {
    "fileName":"DualNozzleClips.JPG",
    "fieldPath":"NelsonNozzleClip",
    "id":"Dual"
    },
    {
    "fileName":"TriNozzleClip.png",
    "fieldPath":"NelsonNozzleClip",
    "id":"Triple"
    },
    {
    "fileName":"FlowControlNoz.png",
    "fieldPath":"Sprinklers.PackageType",
    "id":""
    },
    {
    "fileName":"FlowControlNoz.png",
    "fieldPath":"Sprinklers.PackageType",
    "id":""
    },
    {
    "fileName":"R3000.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"6"
    },
    {
    "fileName":"S3000.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"7"
    },
    {
    "fileName":"O3000.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"8"
    },
    {
    "fileName":"A3000.png",
    "fieldPath":"Sprinklers.PackageType",
    "id":"9"
    },
    {
    "fileName":"D3000.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"10"
    },
    {
    "fileName":"OrbitorFXwht-blk.JPG",
    "fieldPath":"Sprinklers.PackageType",
    "id":"29"
    },
    {
    "fileName":"BubblerClip.png",
    "fieldPath":"D3000.BubblerClipLEPA",
    "id":""
    },
    {
    "fileName":"Converter.JPG",
    "fieldPath":"D3000.SprinklerConverter",
    "id":""
    },
    {
    "fileName":"BubbleWidePlate.JPG",
    "fieldPath":"D3000.Plate",
    "id":"1"
    },
    {
    "fileName":"D3000blue.png",
    "fieldPath":"D3000.Plate",
    "id":"2"
    },
    {
    "fileName":"D3000green.png",
    "fieldPath":"D3000.Plate",
    "id":"3"
    },
    {
    "fileName":"D3000brown.png",
    "fieldPath":"D3000.Plate",
    "id":"4"
    },
    {
    "fileName":"D3000orange.png",
    "fieldPath":"D3000.Plate",
    "id":"5"
    },
    {
    "fileName":"D3000gray.png",
    "fieldPath":"D3000.Plate",
    "id":"6"
    },
    {
    "fileName":"D3000black.png",
    "fieldPath":"D3000.Plate",
    "id":"7"
    },
    {
    "fileName":"D3000turquoise.png",
    "fieldPath":"D3000.Plate",
    "id":"8"
    },
    {
    "fileName":"D3000purple.png",
    "fieldPath":"D3000.Plate",
    "id":"9"
    },
    {
    "fileName":"ChemPlate.jpg",
    "fieldPath":"D3000.Plate",
    "id":"10"
    },
    {
    "fileName":"A3000MaroonPlate.JPG",
    "fieldPath":"D3000.Plate",
    "id":"11"
    },
    {
    "fileName":"GoldPlateConverter.JPG",
    "fieldPath":"D3000.Plate",
    "id":"12"
    },
    {
    "fileName":"3000FlowControl.jpg",
    "fieldPath":"D3000.isFCN3000",
    "id":""
    },
    {
    "fileName":"R3000Blue.jpg",
    "fieldPath":"RSAO3000Plate",
    "id":"0"
    },
    {
    "fileName":"R3000White.jpg",
    "fieldPath":"RSAO3000Plate",
    "id":"1"
    },
    {
    "fileName":"R3000Green.jpg",
    "fieldPath":"RSAO3000Plate",
    "id":"2"
    },
    {
    "fileName":"RS3000Red.jpg",
    "fieldPath":"RSAO3000Plate",
    "id":"3"
    },
    {
    "fileName":"S3000purple.png",
    "fieldPath":"RSAO3000Plate",
    "id":"4"
    },
    {
    "fileName":"S3000yellow.png",
    "fieldPath":"RSAO3000Plate",
    "id":"5"
    },
    {
    "fileName":"R3000Orange.jpg",
    "fieldPath":"RSAO3000Plate",
    "id":"6"
    },
    {
    "fileName":"R3000Brown.jpg",
    "fieldPath":"RSAO3000Plate",
    "id":"7"
    },
    {
    "fileName":"A3000maroon.png",
    "fieldPath":"RSAO3000Plate",
    "id":"8"
    },
    {
    "fileName":"A3000gold.png",
    "fieldPath":"RSAO3000Plate",
    "id":"9"
    },
    {
    "fileName":"A3000navy.png",
    "fieldPath":"RSAO3000Plate",
    "id":"10"
    },
    {
    "fileName":"O3000black.png",
    "fieldPath":"RSAO3000Plate",
    "id":"11"
    },
    {
    "fileName":"O3000blue.png",
    "fieldPath":"RSAO3000Plate",
    "id":"12"
    },
    {
    "fileName":"O3000purple.png",
    "fieldPath":"RSAO3000Plate",
    "id":"13"
    },
    {
    "fileName":"R3000Olive.JPG",
    "fieldPath":"RSAO3000Plate",
    "id":"14"
    },
    {
    "fileName":"TrashBusterBody.png",
    "fieldPath":"D3000.TrashBusterBody",
    "id":""
    },
    {
    "fileName":"SqThrdReg.jpg",
    "fieldPath":"Regulator.Thread",
    "id":"0"
    },
    {
    "fileName":"FemThrdReg.jpg",
    "fieldPath":"Regulator.Thread",
    "id":"1"
    },
    {
    "fileName":"PVC.jpg",
    "fieldPath":"RigidDrop.DropMaterial",
    "id":"1"
    },
    {
    "fileName":"PVCcombo.jpg",
    "fieldPath":"RigidDrop.DropMaterial",
    "id":"2"
    },
    {
    "fileName":"BlackPoly.jpg",
    "fieldPath":"RigidDrop.DropMaterial",
    "id":"3"
    },
    {
    "fileName":"DropClampToPipe.jpg",
    "fieldPath":"RigidDrop.ReinforcementClamp",
    "id":""
    },
    {
    "fileName":"UP3DualNozzleCarrier.JPG",
    "fieldPath":"SenningerNozzleClip",
    "id":"Dual"
    },
    {
    "fileName":"6degree.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"12"
    },
    {
    "fileName":"6degree.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"13"
    },
    {
    "fileName":"iwobup3.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"14"
    },
    {
    "fileName":"xiwobup3.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"15"
    },
    {
    "fileName":"SuperSprayUP3.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"16"
    },
    {
    "fileName":"ldnup3.jpg",
    "fieldPath":"Sprinklers.PackageType",
    "id":"17"
    },
    {
    "fileName":"XcelWobbler.JPG",
    "fieldPath":"Sprinklers.PackageType",
    "id":"27"
    },
    {
    "fileName":"CornChemPad.jpg",
    "fieldPath":"LDN.ChemPad",
    "id":"1"
    },
    {
    "fileName":"CottonChemPad.jpg",
    "fieldPath":"LDN.ChemPad",
    "id":"2"
    },
    {
    "fileName":"BubblerPad.jpg",
    "fieldPath":"LDN.ChemPad",
    "id":"3"
    },
    {
    "fileName":"LDNUP3multi.jpg",
    "fieldPath":"LDN.LDNType",
    "id":"0"
    },
    {
    "fileName":"LDNUP3single.jpg",
    "fieldPath":"LDN.LDNType",
    "id":"1"
    },
    {
    "fileName":"LDNconcavePlate.jpg",
    "fieldPath":"LDN.Pad",
    "id":"0"
    },
    {
    "fileName":"LDNflatPlate.jpg",
    "fieldPath":"LDN.Pad",
    "id":"1"
    },
    {
    "fileName":"CottonChemPlate.jpg",
    "fieldPath":"LDN.Pad",
    "id":"2"
    },
    {
    "fileName":"Cotton.jpg",
    "fieldPath":"SuperSprayPad",
    "id":"0"
    },
    {
    "fileName":"Concave36.jpg",
    "fieldPath":"SuperSprayPad",
    "id":"1"
    },
    {
    "fileName":"Concave36.jpg",
    "fieldPath":"SuperSprayPad",
    "id":"2"
    },
    {
    "fileName":"24Flat36.jpg",
    "fieldPath":"SuperSprayPad",
    "id":"3"
    },
    {
    "fileName":"24Flat36.jpg",
    "fieldPath":"SuperSprayPad",
    "id":"4"
    },
    {
    "fileName":"24Flat36.jpg",
    "fieldPath":"SuperSprayPad",
    "id":"5"
    },
    {
    "fileName":"FlatSmooth.jpg",
    "fieldPath":"SuperSprayPad",
    "id":"6"
    },
    {
    "fileName":"iwobup3family.jpg",
    "fieldPath":"XcelWobblerType",
    "id":"0"
    },
    {
    "fileName":"XiWobFamilyUP3.jpg",
    "fieldPath":"XiWobType",
    "id":"0"
    },
    {
    "fileName":"pmrmfReg.jpg",
    "fieldPath":"Regulator.RegulatorType",
    "id":"2"
    },
    {
    "fileName":"psrReg.jpg",
    "fieldPath":"Regulator.RegulatorType",
    "id":"9"
    },
    {
    "fileName":"BrassValve.JPG",
    "fieldPath":"ValveType",
    "id":"Brass"
    },
    {
    "fileName":"ballvalve.jpg",
    "fieldPath":"ValveType",
    "id":"PlasticKnob"
    },
    {
    "fileName":"JennyIndAirComp.jpg",
    "fieldPath":"Circle.SwingArm.AirCompressor",
    "id":""
    },
    {
    "fileName":"760.jpg",
    "fieldPath":"Circle.SwingArm.LeadingSwingArm",
    "id":""
    },
    {
    "fileName":"ReinkeSealMatic.jpg",
    "fieldPath":"not in vb",
    "id":""
    },
    {
    "fileName":"CenterPivot.JPG",
    "fieldPath":"System type from designer wizard",
    "id":""
    },
    {
    "fileName":"4WhlHosEndFd.jpg",
    "fieldPath":"System type from designer wizard",
    "id":""
    },
    {
    "fileName":"CanalFdCntrFd.jpg",
    "fieldPath":"System type from designer wizard",
    "id":""
    },
    {
    "fileName":"KwikTow.jpg",
    "fieldPath":"System type from designer wizard",
    "id":""
    },
    {
    "fileName":"StdSAC.jpg",
    "fieldPath":"System type from designer wizard",
    "id":""
    },
    {
    "fileName":"SystemWarrantyOverview.pdf",
    "fieldPath":"pdf",
    "id":""
    },
    {
    "fileName":"SystemWarrantyOverviewI.pdf",
    "fieldPath":"pdf",
    "id":""
    },
    {
    "fileName":"DrainKit.jpg",
    "fieldPath":"TowerProperties.LPDrainKit",
    "id":""
    },
    {
    "fileName":"WatermanDrain.jpg",
    "fieldPath":"TowerProperties.WatermanDrain",
    "id":""
    },
    {
    "fileName":"CplerStd.jpg",
    "fieldPath":"TowerProperties.Coupler",
    "id":"HiTorque"
    },
    {
    "fileName":"CPLRCX.jpg",
    "fieldPath":"TowerProperties.Coupler",
    "id":"CXCoupler"
    },
    {
    "fileName":"SteelUjoint.png",
    "fieldPath":"TowerProperties.Coupler",
    "id":"SteelUJoints"
    },
    {
    "fileName":"Moderate740UHD.jpg",
    "fieldPath":"TowerProperties.WheelGear",
    "id":"SevenFourty"
    },
    {
    "fileName":"740ad.jpg",
    "fieldPath":"TowerProperties.WheelGear",
    "id":"SevenFourtyAD"
    },
    {
    "fileName":"745.jpg",
    "fieldPath":"TowerProperties.WheelGear",
    "id":"SevenFourtyFive"
    },
    {
    "fileName":"AgriTRACS.jpg",
    "fieldPath":"Agri-TRACS",
    "id":""
    },
    {
    "fileName":"3WhlFlexTwr.jpg",
    "fieldPath":"ThreeTiresFlex",
    "id":""
    },
    {
    "fileName":"HDTowerLeg.jpg",
    "fieldPath":"HeavyDuty",
    "id":""
    },
    {
    "fileName":"StiffenerStabilizers.jpg",
    "fieldPath":"StiffenerStabilizers",
    "id":""
    },
    {
    "fileName":"StiffenerTrussToPipe.jpg",
    "fieldPath":"StiffenerTrussToPipe",
    "id":""
    },
    {
    "fileName":"TowerCrossBrace.jpg",
    "fieldPath":"TowerCrossBrace",
    "id":""
    },
    {
    "fileName":"TireOptions.png",
    "fieldPath":"Tires.TireSize",
    "id":"a11x225"
    },
    {
    "fileName":"52TitanRadial.jpg",
    "fieldPath":"Tires.TireSize",
    "id":"a32085R38"
    },
    {
    "fileName":"TitanRaindropNonDirectional.jpg",
    "fieldPath":"Tires.TireType",
    "id":"NonDirectional"
    },
    {
    "fileName":"MachII.jpg",
    "fieldPath":"Tires.TireType",
    "id":"MachII"
    },
    {
    "fileName":"RhinoGatorWhls.jpg",
    "fieldPath":"Tires.TireType",
    "id":"Rhinogator"
    },
    {
    "fileName":"Std3WhlTwr.jpg",
    "fieldPath":"ThreeTires",
    "id":""
    },
    {
    "fileName":"TASBarricade.jpg",
    "fieldPath":"TowerAutoStop",
    "id":""
    },
    {
    "fileName":"ReinkeCenterDrive.png",
    "fieldPath":"CenterDrive",
    "id":"Standard"
    },
    {
    "fileName":"GalvWheel.jpg",
    "fieldPath":"not in vb",
    "id":""
    },
    {
    "fileName":"WrapSpan.jpg",
    "fieldPath":"WrapAroundSpan",
    "id":"NinetyDegree"
    },
    {
    "fileName":"CropX25Probe.jpg",
    "fieldPath":"CropX.SoilProbeV25Qty",
    "id":""
    },
    {
    "fileName":"CropX35Probe.jpg",
    "fieldPath":"CropX.SoilProbeV35Qty",
    "id":""
    },
    {
    "fileName":"CropXInstallKit.jpg",
    "fieldPath":"CropX.InstallationKitV25Qty",
    "id":""
    },
    {
    "fileName":"CropXInstalled.jpg",
    "fieldPath":"CropX.InstallationKitV35Qty",
    "id":""
    },
    {
    "fileName":"OpenFrame.JPG",
    "fieldPath":"Options.GeneratorFrameType",
    "id":"OpenFrame"
    },
    {
    "fileName":"EnclosedFrame.JPG",
    "fieldPath":"Options.GeneratorFrameType",
    "id":"EnclosedFrame"
    },
    {
    "fileName":"MurphyControlPanel.JPG",
    "fieldPath":"Options.EngDigitalPanel",
    "id":""
    }
    ]