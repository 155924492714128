import { bearing, destination, featureCollection, nearestPoint, point, sector } from "@turf/turf";
import { Feature } from "geojson";
import { MapMouseEvent } from "mapbox-gl";
import ISelectModeExtension, { LngLat } from "./ISelectModeExtension";

import createVertex from "./copied-from-mapbox-gl-draw/create_vertex";

const isSector = (geojson) => {
    return geojson.properties.user_rdpFeatureType === 'system/partialPivot'
                     || geojson.properties.rdpFeatureType === 'system/partialPivot'
}

function createSupplementaryPointsForSector(geojson) {
    // console.log("SectorSelectModeExtension.createSupplementaryPointsForSector", geojson)
    const { properties, geometry } = geojson;
  
    if (!isSector(geojson)) return null;

    const center = geojson.properties.center || geojson.properties.user_center;
    const radiusFeet = geojson.properties.radiusFeet || geojson.properties.user_radiusFeet;
    const bearingStart = geojson.properties.bearingStart || geojson.properties.user_bearingStart;
    const bearingEnd = geojson.properties.bearingEnd || geojson.properties.user_bearingEnd;
    const p1 = destination(center, radiusFeet, bearingStart, { units: 'feet' });
    const p2 = destination(center, radiusFeet, bearingEnd, { units: 'feet' });
    const pointsFc = featureCollection(geojson.geometry.coordinates[0].map(x => point(x)))
    // console.log("pointsFc",pointsFc)
    const fp1 = nearestPoint(p1, pointsFc as any)
    const fp2 = nearestPoint(p2, pointsFc as any)
    // console.log("fp1",fp1,fp2)
  
    const supplementaryPoints = [
        createVertex(properties.id, fp1.geometry.coordinates, `0.${fp1.properties.featureIndex}`, false),
        createVertex(properties.id, fp2.geometry.coordinates, `0.${fp2.properties.featureIndex}`, false)
    ];
    return supplementaryPoints;
  }

export const SectorSelectModeExtension: ISelectModeExtension = {
    supportsEditingFeature: function (geojson: Feature): boolean {
        const _isSector = isSector(geojson);
        console.log("SectorSelectModeExtension.supportsEditingFeature", _isSector)
        return _isSector;
    },

    createSupplementaryPoints: function (geojson: Feature): Feature[] {
        return createSupplementaryPointsForSector(geojson);
    },

    dragVertex: function (state: any, e: MapMouseEvent, delta: LngLat) {
        const geojson: Feature = state.feature;

        if (state.selectedCoordPaths.length !== 1) return;
        const path = state.selectedCoordPaths[0];
        const beginPoint = path.endsWith("1");

        const center = geojson.properties.center || geojson.properties.user_center;
        const radiusFeet = geojson.properties.radiusFeet || geojson.properties.user_radiusFeet;
        let bearingStart = geojson.properties.bearingStart || geojson.properties.user_bearingStart;
        let bearingEnd = geojson.properties.bearingEnd || geojson.properties.user_bearingEnd;



        const movedVertex = point([e.lngLat.lng, e.lngLat.lat]);
        const updatedBearing = bearing(center, movedVertex);
        if (beginPoint) {
            bearingStart = updatedBearing;
        }
        else {
            bearingEnd = updatedBearing
        }
        
        const sectorFeature = sector(
            center,
            radiusFeet,
            bearingStart,
            bearingEnd,
            { units: "feet", steps: 3600 }
        );
        
        (geojson as any).incomingCoords(sectorFeature.geometry.coordinates);
        geojson.properties.bearingStart = bearingStart;
        geojson.properties.bearingEnd = bearingEnd;
    },
    
    onDrag(state, e) {
        console.log("Overridden on Drag")
    },
}