import {
    AgriInjectTypes,
    ElectricalFrequencies,
    GallonsPerHourTypes,
    TankTypes,
    UnitTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/poSchema";
import CSection2 from "./CSection2";
import PartsAssembler from "./PartsAssembler";
export class AgriInjectClass extends CSection2 {
    public ComputedElectricalFrequencyCode = (): ElectricalFrequencies | undefined => {
        return this.Quote.System.ControlPanel.ElectricalFrequency/* ?? this.Quote.System.DealerProperties.DefaultElectricalFrequency;*/
    }
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {

    }
    public WritePO = (PO: PartsAssembler) => {
        if (this.Quote.System.ControlPanel.ElectricalFrequency !== ElectricalFrequencies.a50) {
            switch (this.Quote.System.AgriInject.AgriInjectType) {
                case AgriInjectTypes.Fertigation:
                    switch (this.Quote.System.AgriInject.Unit) {
                        case UnitTypes.Simplex:
                            switch (this.Quote.System.AgriInject.GallonsPerHour) {
                                case GallonsPerHourTypes.a30:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-34-111030-0", 1); // Simplex 30 gph 3ph complete
                                    break;
                                case GallonsPerHourTypes.a55:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-34-111055-0", 1); // Simplex 55 gph 3ph complete
                                    break;
                                case GallonsPerHourTypes.a110:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-34-111110-0", 1); // Simplex 110 gph 3ph complete
                                    break;
                                case GallonsPerHourTypes.a150:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-34-114150-0", 1); // Simplex 150 gph 3ph complete
                                    break;
                            }
                            break;
                        case UnitTypes.Duplex:
                            switch (this.Quote.System.AgriInject.GallonsPerHour) {
                                case GallonsPerHourTypes.a30x30:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-34-141330-0", 1); // Duplex 30x30 gph 3ph complete
                                    break;
                                case GallonsPerHourTypes.a4x30:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-34-141430-0", 1); // Duplex 4x30 gph 3ph complete
                                    break;
                                case GallonsPerHourTypes.a85x85:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-34-144885-0", 1); // Duplex 85x85 gph 3ph complete
                                    break;
                            }
                            break;
                    }
                    break;
                case AgriInjectTypes.Insectigator:
                    switch (this.Quote.System.AgriInject.Tank) {
                        case TankTypes.a20:
                            PO.InsertPart(schema.groups.AgriInject.groupId, "887-34-212902-0", 1); // 20 gallon insectigator 3ph
                            break;
                        case TankTypes.a30:
                            PO.InsertPart(schema.groups.AgriInject.groupId, "887-34-312902-0", 1); // 30 gallon insectigator 3ph
                            break;
                    }
                    break;
                case AgriInjectTypes.Chemigation:
                    switch (this.Quote.System.AgriInject.Unit) {
                        case UnitTypes.Simplex:
                            switch (this.Quote.System.AgriInject.Tank) {
                                case TankTypes.a70:
                                    switch (this.Quote.System.AgriInject.GallonsPerHour) {
                                        case GallonsPerHourTypes.a5_6:
                                            PO.InsertPart(schema.groups.AgriInject.groupId, "888-34-712005-0", 1); // Simplex 70 chem 3ph 5.6 gph
                                            break;
                                        case GallonsPerHourTypes.a18:
                                            PO.InsertPart(schema.groups.AgriInject.groupId, "888-34-712018-0", 1); // Simplex 70 chem 3ph 18 gph
                                            break;
                                    }
                                    break;
                                case TankTypes.a110:
                                    switch (this.Quote.System.AgriInject.GallonsPerHour) {
                                        case GallonsPerHourTypes.a5_6:
                                            PO.InsertPart(schema.groups.AgriInject.groupId, "888-34-112005-0", 1); // Simplex 110 chem 3ph 5.6 gph
                                            break;
                                        case GallonsPerHourTypes.a18:
                                            PO.InsertPart(schema.groups.AgriInject.groupId, "888-34-112018-0", 1); // Simplex 110 chem 3ph 18 gph
                                            break;
                                    }
                                    break;
                                case TankTypes.a200:
                                    switch (this.Quote.System.AgriInject.GallonsPerHour) {
                                        case GallonsPerHourTypes.a18:
                                            PO.InsertPart(schema.groups.AgriInject.groupId, "888-34-212018-0", 1); // Simplex 200 chem 3ph 18 gph
                                            break;
                                        case GallonsPerHourTypes.a30:
                                            PO.InsertPart(schema.groups.AgriInject.groupId, "888-34-212030-0", 1); // Simplex 200 chem 3ph 30 gph
                                            break;
                                    }
                                    break;
                            }
                            break;
                        case UnitTypes.Duplex:
                            switch (this.Quote.System.AgriInject.Tank) {
                                case TankTypes.a70:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "888-34-712430-0", 1); // Duplex 70 chem 3ph 4x30 gph
                                    break;
                                case TankTypes.a110:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "888-34-112430-0", 1); // Duplex 110 chem 3ph 4x30 gph
                                    break;
                                case TankTypes.a200:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "888-34-212430-0", 1); // Duplex 200 chem 3ph 4x30 gph
                                    break;
                            }
                            break;
                    }
                    break;
            }
        } else {
            switch (this.Quote.System.AgriInject.AgriInjectType) {
                case AgriInjectTypes.Fertigation:
                    switch (this.Quote.System.AgriInject.Unit) {
                        case UnitTypes.Simplex:
                            switch (this.Quote.System.AgriInject.GallonsPerHour) {
                                case GallonsPerHourTypes.a30:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-54-111030-0", 1); // Simplex 30 gph 3ph complete
                                    break;
                                case GallonsPerHourTypes.a55:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-54-111055-0", 1); // Simplex 55 gph 3ph complete
                                    break;
                                case GallonsPerHourTypes.a110:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-54-111110-0", 1); // Simplex 110 gph 3ph complete
                                    break;
                                case GallonsPerHourTypes.a150:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-54-114150-0", 1); // Simplex 150 gph 3ph complete
                                    break;
                            }
                            break;
                        case UnitTypes.Duplex:
                            switch (this.Quote.System.AgriInject.GallonsPerHour) {
                                case GallonsPerHourTypes.a30x30:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-54-141330-0", 1); // Duplex 30x30 gph 3ph complete
                                    break;
                                case GallonsPerHourTypes.a4x30:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-54-141430-0", 1); // Duplex 4x30 gph 3ph complete
                                    break;
                                case GallonsPerHourTypes.a85x85:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "882-54-144885-0", 1); // Duplex 85x85 gph 3ph complete
                                    break;
                            }
                            break;
                    }
                    break;
                case AgriInjectTypes.Insectigator:
                    switch (this.Quote.System.AgriInject.Tank) {
                        case TankTypes.a20:
                            PO.InsertPart(schema.groups.AgriInject.groupId, "887-54-212902-0", 1); // 20 gallon insectigator 3ph
                            break;
                        case TankTypes.a30:
                            PO.InsertPart(schema.groups.AgriInject.groupId, "887-54-312902-0", 1); // 30 gallon insectigator 3ph
                            break;
                    }
                    break;
                case AgriInjectTypes.Chemigation:
                    switch (this.Quote.System.AgriInject.Unit) {
                        case UnitTypes.Simplex:
                            switch (this.Quote.System.AgriInject.Tank) {
                                case TankTypes.a70:
                                    switch (this.Quote.System.AgriInject.GallonsPerHour) {
                                        case GallonsPerHourTypes.a5_6:
                                            PO.InsertPart(schema.groups.AgriInject.groupId, "888-54-712005-0", 1); // Simplex 70 chem 3ph 5.6 gph
                                            break;
                                        case GallonsPerHourTypes.a18:
                                            PO.InsertPart(schema.groups.AgriInject.groupId, "888-54-712018-0", 1); // Simplex 70 chem 3ph 18 gph
                                            break;
                                    }
                                    break;
                                case TankTypes.a110:
                                    switch (this.Quote.System.AgriInject.GallonsPerHour) {
                                        case GallonsPerHourTypes.a5_6:
                                            PO.InsertPart(schema.groups.AgriInject.groupId, "888-54-112005-0", 1); // Simplex 110 chem 3ph 5.6 gph
                                            break;
                                        case GallonsPerHourTypes.a18:
                                            PO.InsertPart(schema.groups.AgriInject.groupId, "888-54-112018-0", 1); // Simplex 110 chem 3ph 18 gph
                                            break;
                                    }
                                    break;
                                case TankTypes.a200:
                                    switch (this.Quote.System.AgriInject.GallonsPerHour) {
                                        case GallonsPerHourTypes.a18:
                                            PO.InsertPart(schema.groups.AgriInject.groupId, "888-54-212018-0", 1); // Simplex 200 chem 3ph 18 gph
                                            break;
                                        case GallonsPerHourTypes.a30:
                                            PO.InsertPart(schema.groups.AgriInject.groupId, "888-54-212030-0", 1); // Simplex 200 chem 3ph 30 gph
                                            break;
                                    }
                                    break;
                            }
                            break;
                        case UnitTypes.Duplex:
                            switch (this.Quote.System.AgriInject.Tank) {
                                case TankTypes.a70:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "888-54-712430-0", 1); // Duplex 70 chem 3ph 4x30 gph
                                    break;
                                case TankTypes.a110:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "888-54-112430-0", 1); // Duplex 110 chem 3ph 4x30 gph
                                    break;
                                case TankTypes.a200:
                                    PO.InsertPart(schema.groups.AgriInject.groupId, "888-54-212430-0", 1); // Duplex 200 chem 3ph 4x30 gph
                                    break;
                            }
                            break;
                    }
                    break;
            }
        }
    }
}