import { Box, Card, CardContent, Typography } from '@mui/material';
import i18next, { t } from "i18next";
import IDbGrower from "rdptypes/api/IGrowerBase";
import * as React from "react";
import { FC } from 'react';
import { IProjectWithId } from "../../../routes/pages/ProjectList";
import { primaryColor } from "../../styles";
import SimpleSystemContent from "../RDPProposal/SimpleSystemContent";
import DealerInformationLine from "./DealerInformationLine";
import MultipleSystemSummaryPage from "./MultipleSystemSummaryPage";
import SystemDetailsPage from "./SystemDetailsPage";

interface Props {
    layoutId: string;
    systemIds: string[];
    grower: IDbGrower;
    pwid: IProjectWithId;
}

const DetailedSystemContent: FC<Props> = React.forwardRef((props) => {
    const {layoutId, grower, pwid, systemIds} = props;
    const reinkeLogo = new URL('../../../assets/reinkeLogo/whiteFull.svg', import.meta.url);

    let pageContent: JSX.Element[][] = [];

    const layoutSystemIds: string[] = [];
    for(var systemId in pwid.project.layouts[layoutId].systems) {
        if( pwid.project.layouts[layoutId].systems.hasOwnProperty(systemId)) {
            layoutSystemIds.push(systemId);
        }
    }

    if (systemIds.length > 1){
        pageContent.push([<SimpleSystemContent insideFullReport showLogoInsteadOfMiniMap layoutId={layoutId} grower={grower} pwid={pwid} systemIds={systemIds}/>]);
        pageContent.push([<MultipleSystemSummaryPage systemIds={systemIds} pwid={pwid} layoutId={layoutId} />]);
    }

    systemIds.forEach((systemId) => {
        pageContent.push([<SimpleSystemContent showLogoInsteadOfMiniMap={layoutSystemIds.length === 1 && systemIds.length === 1} insideFullReport layoutId={layoutId} grower={grower} pwid={pwid} systemIds={[systemId]}/>]);
        pageContent.push([<SystemDetailsPage layoutId={layoutId} pwid={pwid} systemId={systemId} />]);
    });

    return (<>
        {pageContent.map((x) => {
            return <div className="printable-page" style={{ width: "216mm", height: "279mm", marginBottom: 10}}>
                <Card className="unbreakable" style={{width: "100%", height: "279mm", position: "relative"}}>
                    <CardContent style={{padding: 0}}>
                        <Box style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 15, padding: 30, backgroundColor: primaryColor}}>
                            <Box style={{flex: 2}}>
                                <Typography style={{color: "white", fontWeight: "bold", fontFamily: "Arial Black"}}>{i18next.format(t("reports.field-design"), "uppercase")}</Typography>
                            </Box>
                            <Box style={{flex: 1}}>
                                <img src={reinkeLogo.toString()} style={{ width: 100, float: "right" }} />
                            </Box>
                        </Box>
                        <Box style={{padding: 15}}>
                            {x}
                        </Box>
                        <DealerInformationLine />
                    </CardContent>
                </Card>
            </div>
        })}
    </>);
});

export default DetailedSystemContent;