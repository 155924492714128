import DeleteIcon from '@mui/icons-material/Delete';
import { Checkbox, IconButton, TableCell, TableRow, TextField } from "@mui/material";
import * as React from "react";
import { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { IMiscItem } from "../../../../model/project/ISystem";
import DollarFormattedInput from "../DollarFormattedInput";

interface Props {
    idx: number;
    deleteMiscItem: (idx: number) => void;
    saveMiscItem: (item: IMiscItem, idx: number) => void;
    miscItem: IMiscItem | null;
};

const MiscItemRow: FC<Props> = (props) => {
    const {miscItem, idx, deleteMiscItem, saveMiscItem} = props;

    const [description, setDescription] = useState(miscItem ? miscItem.description : "");
    const [priceUSD, setPriceUSD] = useState<number| null>(miscItem ? miscItem.priceCents / 100 : null);
    const [taxable, setIsTaxable] = useState(miscItem? miscItem.taxable : false);

    const saveMiscItem_ = (description_: string, priceUSD_: number, taxable_: boolean) => {
        let priceCents = priceUSD_ * 100;
        saveMiscItem({description: description_, priceCents: priceCents, taxable: taxable_, id: miscItem?.id ?? uuidv4()}, idx);
    }

    return (
        <>
            <TableRow>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>
                    <TextField size="small" value={description} onChange={(e) => {setDescription(e.target.value)}} onBlur={(e) => {
                        let val = e.target.value;
                        let priceUSD_ = priceUSD;
                        if (priceUSD_ === null) {
                            priceUSD_ = 0;;
                        }
                        setDescription(val);
                        setPriceUSD(priceUSD_)
                        saveMiscItem_(val, priceUSD_, taxable);
                    }}/>
                </TableCell>
                <TableCell>
                    <TextField size="small" value={priceUSD === null ? "" : priceUSD} InputProps={{
                        inputComponent: DollarFormattedInput as any}} onChange={(e) => {
                            if (e.target.value === ""){
                                setPriceUSD(null);
                            }
                            let val = parseFloat(e.target.value);
                            if (isNaN(val)) return;
                            setPriceUSD( val );
                        }} onBlur={(e) => {
                            let val = e.target.value;
                            let valNum = parseFloat(val.replace("$", "").replace(",", ""));
                            if (val !== "" && isNaN(valNum)) return;

                            let stateVal = val === "" ? null : valNum;
                            let description_ = description === "" ? "Misc item description" : description;
                            setDescription(description_);
                            setPriceUSD(stateVal === null ? 0 : stateVal);
                            saveMiscItem_(description_, stateVal, taxable);
                    }}/>
                </TableCell>
                <TableCell>
                    <Checkbox checked={taxable} onChange={(e) => {
                        let checked = e.target.checked;
                        let description_ = description === "" ? "Misc item description" : description;
                        let priceCents_ = priceUSD === null ? 0 : priceUSD;
                        setIsTaxable(checked);
                        saveMiscItem_(description_, priceCents_, checked);
                    }}/>
                </TableCell>
                <TableCell>
                    <IconButton aria-label="delete" disabled={miscItem === null} color="primary" onClick={() => {deleteMiscItem(idx)}}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    );
};

export default MiscItemRow;