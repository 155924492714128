import * as mapboxgl from "mapbox-gl";
import { LngLatBoundsLike } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext, useEffect, useRef, useState } from "react";

import { Box, Stack, Toolbar } from "@mui/material";
import { Feature } from "@turf/turf";
import { t } from "i18next";
import { Position } from "rdptypes/geoTypes";
import { GeometryCtx, IGeometryCtx } from "../../GeometryHelpers/GeometryProvider";
import { IProperties as ICenterPivotProperties } from "../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import { IProperties as ILateralProperties } from "../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import DbCtx from "../../db/DbCtx";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { formatLatLn } from "../../docGeneration/DocumentGenerationHelpers";
import IProject from "../../model/project/IProject";
import ISystem from "../../model/project/ISystem";
import { IMapFeaturePermissions, getMapFeaturePermissions } from "../../routes/pages/LayoutMapPage/MapSpeedDial/mapFeaturePermissions";
import mapDrawStyles from "../LayoutMap/mapDrawStyles";
import { mergeMapStyles } from "../LayoutMap/mapSettingsMerger";
import CenterPivotSelectMode from "./CenterPivotSelectMode";
import DynamicCenterPivotOptimizationMode, { IDrawUpdateExtEvent_DynamicCenterPivotOptimizationResult } from "./DynamicCenterPivotOptimizationMode";
import DynamicLateralOptimizationMode, { IDrawUpdateExtEvent_DynamicLateralOptimizationResult } from "./DynamicLateralOptimizationMode";
import ExtDirectSelect from "./ExtDirectSelect";
import ExtDrawLineStringMode from "./ExtDrawLineStringMode";
import ExtSimpleSelect from "./ExtSimpleSelect";
import LateralSelectMode from "./LateralSelectMode";
import ProjectSelectMode from "./ProjectSelectMode";
import SegmentSelectMode, { IDrawUpdateExtEvent_SegmentModeEntered, IDrawUpdateExtEvent_SegmentUpdated, SEGMENT_SELECT } from "./SegmentSelectMode";
import { updateActions } from "./copied-from-mapbox-gl-draw/constants.js";
import { IMapContext, MapContext } from "./mapContext";

mapboxgl.accessToken = "pk.eyJ1IjoiaWNvbnNvZnR3YXJlIiwiYSI6ImNsYXV6MnExZTAwajQzcWxpcHdjanZxYTgifQ.R0vQipvh4nMVTsNezSlamg";


/* NOTES:
 * MapBox has an interactive prop which disbales interaction with the Map
 * MapBoxDraw however does not include this functionality. And remains active
 * when MapBox interactive is flase.
 * As a workaround, the Map div is styled to ignore pointer-events when requesting
 * an interactive = false map
 **/
const FillDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
const NonInteractiveFillDiv = styled(FillDiv)`
    pointer-events: none;
`;

interface IDrawUpdateExtEvent_ProjectHovered {
    action: "project_hovered";
    definition: {
        projectId: string | undefined;
    }
}
interface IDrawUpdateExtEvent_ProjectSelected {
    action: "project_selected";
    definition: {
        projectId: string | undefined;
    }
}
export interface IDrawUpdateExtEvent_CenterPivotUpdated {
    action: "center_pivot_updated",
    definition: ICenterPivotProperties;
    updatedSystem: ISystem;
}
export interface IDrawUpdateExtEvent_CenterPivotSelected {
    action: "center_pivot_selected",
    definition: any;
}
export interface IDrawUpdateExtEvent_LateralUpdated {
    action: "lateral_updated",
    definition: ILateralProperties;
    updatedSystem: ISystem;
}
export interface IDrawUpdateExtEvent_LateralSelected {
    action: "lateral_selected",
    definition: any;
}

export interface IDrawUpdateEvent_ChangeCoordinates {
    action: "change_coordinates",
    definition: {
        features: Feature[]
    }
}
export interface IDrawUpdateEvent_Move {
    action: "move",
    definition: {
        features: Feature[]
    }
}

export type IDrawUpdateExtEvent = 
    IDrawUpdateExtEvent_CenterPivotSelected |
    IDrawUpdateExtEvent_CenterPivotUpdated |
    IDrawUpdateExtEvent_ProjectHovered |
    IDrawUpdateExtEvent_ProjectSelected |
    IDrawUpdateExtEvent_LateralSelected |
    IDrawUpdateExtEvent_LateralUpdated |
    IDrawUpdateEvent_ChangeCoordinates |
    IDrawUpdateEvent_Move |
    IDrawUpdateExtEvent_DynamicCenterPivotOptimizationResult |
    IDrawUpdateExtEvent_DynamicLateralOptimizationResult |
    IDrawUpdateExtEvent_SegmentUpdated |
    IDrawUpdateExtEvent_SegmentModeEntered
;

interface Props {
    overideDrawStyles?: object[];

    bounds?: LngLatBoundsLike;
    center?: Position;
    zoom?: number;
    onMove?: (center: Position, zoom: number) => any;

    drawMode?: string | { mode: string, options: { test: string }};
    onDrawModeChanged?: (newDrawMode: string) => any;

    onDrawCreate?: (features: Feature[], map: IMapContext) => any;
    onDrawUpdateExt?: (event: IDrawUpdateExtEvent) => any;

    onDrawSelectionChange?: (args: { features: Feature[] }) => void;

    drawFeatures?: Feature[];
    interactive?: boolean;

    children?: React.ReactNode;

    projectId?: string; // This is here so *some* map modes can access the dbProject
    layoutId?: string; // This is here so *some* map modes can access the dbProject

    showProposalGeneratedWarning?: boolean;
}

export enum MapLayerType{
    MapBox,
    MapBoxRoadLabels,
    Bing,
    BingWithRoadLabels,
    Azure
}

interface IRdpFunctions {
    getProject: () => IProject | undefined;
    getMapPermissions: () => IMapFeaturePermissions;
    getGeometryState: () => IGeometryCtx | null;
}
export const rdpFunctions = (that: MapboxDraw.DrawCustomModeThis): IRdpFunctions => {
    return that.map['rdpFunctions'] as IRdpFunctions;
}
export const rdpFunctionsFromMap = (map: mapboxgl.Map): IRdpFunctions => {
    return map['rdpFunctions'] as IRdpFunctions;
}

// Track Bing maps metadata as a static variable to reduce the number of API calls
let bingMapsTileUrlTemplate: string | null = null;
let bingMapsWithRoads: boolean | null = null;
let bingFetchAttempts = 0;
const BING_FETCH_ATTEMPTS_MAX = 5;
const bingMapKey = 'Aihcmg9yg63hlxbcqpnxq8_lu_4KP2g4WhCJL0_ho7rkTM9O1IcYyNxZH9fWOSj4'; //Dev key from Csilla's Bing Map account (csilla@icon.software)

const fetchBingMetaData = async (withRoads: boolean): Promise<string | null> => {
    let roadChange = bingMapsWithRoads !== withRoads;
    if (!roadChange && (bingMapsTileUrlTemplate || bingFetchAttempts > BING_FETCH_ATTEMPTS_MAX)) {
        return bingMapsTileUrlTemplate;
    }

    const maptype = withRoads ? "AerialWithLabelsOnDemand" : "Aerial";
    const response = await fetch(`https://dev.virtualearth.net/REST/V1/Imagery/Metadata/${maptype}?output=json&include=ImageryProviders&key=${bingMapKey}&uriScheme=https`);
    const json = await response.json();

    if (!json || !json.resourceSets || !json.resourceSets.length || !json.resourceSets[0].resources || !json.resourceSets[0].resources.length || !json.resourceSets[0].resources[0].imageUrlSubdomains || !json.resourceSets[0].resources[0].imageUrlSubdomains.length) return;
    var imageUrl = json.resourceSets[0].resources[0].imageUrl;
    var subdomain = json.resourceSets[0].resources[0].imageUrlSubdomains[0];
    if (!imageUrl || !subdomain) return;
    bingMapsTileUrlTemplate = imageUrl.replace("{subdomain}", subdomain);
    bingMapsWithRoads = withRoads;
    
    if(!bingMapsTileUrlTemplate) {
        bingFetchAttempts++;
        return await fetchBingMetaData(withRoads);
    }
    return bingMapsTileUrlTemplate;
} 

const Map: FC<Props> = (props) => {

    const showProposalGeneratedWarning = props.showProposalGeneratedWarning || false;

    const dbState = useContext(DbCtx);
    const interactive = props.interactive === undefined ? true : props.interactive;
    const devSettingsState = useContext(DevSettingsCtx);
    const geometryState = useContext(GeometryCtx);

    const mapContainer: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
    const [mp, setMp] = useState<{ map: mapboxgl.Map, draw: MapboxDraw } | null>(null);

    const [mousePosition, setMousePosition] = useState<mapboxgl.LngLat | undefined>(undefined);

    /** This is not very nice, but we use a ref to track the latest version of the onDrawCreate/onDrawUpdate prop
     * because there's no way to replace event handlers on the mapboxgl.Map.
     */
    const local = useRef({
        onDrawSelectionChange: props.onDrawSelectionChange,
        onDrawCreate: props.onDrawCreate, 
        onDrawUpdateExt: props.onDrawUpdateExt
    });
    useEffect(() => {
        local.current = {
            onDrawSelectionChange: props.onDrawSelectionChange,
            onDrawCreate: props.onDrawCreate, 
            onDrawUpdateExt: props.onDrawUpdateExt
        }
    }, [ props.onDrawSelectionChange, props.onDrawCreate, props.onDrawUpdateExt ]);

    const getDrawFeatureCollection = () => {
        return {
            type: "FeatureCollection",
            features: props.drawFeatures ?? []
        } as any;
    }

    useEffect(() => {
        //Force map redraw when we change dealer settings colours
        if (mp) {
            initAsync().then(res => {
                const prev = mp.map;
                res.draw.set(getDrawFeatureCollection());
                setMp(res);
                prev.remove();
            })
        }
    }, [devSettingsState.dealerSettings.map.custom.formState.colors, devSettingsState.dealerSettings.map.useCustom]);

    const initAsync = async () => {
        const style = await getMapLayerStyle();

        const mapOptions: mapboxgl.MapboxOptions = {
            container: mapContainer.current as HTMLElement,
            style,
            fadeDuration: 0
        };
        if (props.bounds) mapOptions.bounds = props.bounds;
        if (props.center) mapOptions.center = props.center;
        if (props.zoom) mapOptions.zoom = props.zoom;
        const m = new mapboxgl.Map(mapOptions);
        await m.once("idle");
        // NOTE: mapbox-dem source is no longer required here as elevations are
        // taken care of from GeometryCtx.
        // However, if removed the drag verticies when editing map objects
        // are rendered below geometry.
        // For some reason, when this source is added, the verticies are rendered above the geometry.
        // Thus, this source is left here until a better fix can be found.
        m.addSource("mapbox-dem", {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
            tileSize: 512,
            maxzoom: 14,
        });
        m.setTerrain({ source: "mapbox-dem" });
        // END NOTE

        const modes = {
            ...MapboxDraw.modes,
            simple_select: ExtSimpleSelect,
            direct_select: ExtDirectSelect,
            center_pivot_select: CenterPivotSelectMode,
            lateral_select: LateralSelectMode,
            project_select: ProjectSelectMode,
            draw_line_string: ExtDrawLineStringMode,
            dynamic_center_pivot_optimization: DynamicCenterPivotOptimizationMode,
            dynamic_lateral_optimization: DynamicLateralOptimizationMode
        }
        modes[SEGMENT_SELECT] = SegmentSelectMode;
        
        const d = new MapboxDraw({
            displayControlsDefault: false,
            styles: props.overideDrawStyles 
                ? props.overideDrawStyles 
                : devSettingsState.dealerSettings.map.useCustom 
                    ? mergeMapStyles(devSettingsState.dealerSettings.map.custom.formState.colors) 
                    : mapDrawStyles,
            userProperties: true,
            modes
        } as any);//TODO remove as any and fix modes types
        m.addControl(d);

        m.on("draw.modechange", (ev) => {
            props.onDrawModeChanged?.(ev.mode);
        });
        
        m.on("draw.create", (ev) => {
            local.current.onDrawCreate?.(ev.features, mapContext);
        });
        
        m.on("draw.update", (ev) => {
            if ('action' in ev) {
                if (ev.action === updateActions.CHANGE_COORDINATES) {
                    local.current.onDrawUpdateExt?.({
                        action: 'change_coordinates',
                        definition: {
                            features: ev.features as Feature[]
                        }
                    });
                }
                else if (ev.action === updateActions.MOVE) {
                    local.current.onDrawUpdateExt?.({
                        action: 'move',
                        definition: {
                            features: ev.features as Feature[]
                        }
                    });
                }
            }
        });

        // This is a custom event
        m.on("draw.update_ext", (ev) => {
            local.current.onDrawUpdateExt?.(ev);
        });

        m.on('move', () => {
            props.onMove?.(m!.getCenter().toArray() as Position, m!.getZoom());
        });

        
        m.on('mousemove', (e) => {
            setMousePosition(e.lngLat);
        });

        m.on('draw.selectionchange', (e) => {
            local.current.onDrawSelectionChange?.(e);
        });
        return {
            map: m, draw: d
        };
    };


    useEffect(() => {
        const initPromise = initAsync();
        const mp2 = initPromise.then(res => {
            res.draw.set(getDrawFeatureCollection());
            setMp(res);
            return res;
        })
        return () => {
            mp2.then(x => x.map.remove());
            mp?.map.remove();
        }
    }, []);

    useEffect(() => {
        // NOTE: The draw features are removed and then re-added only to force
        //       mapbox draw to re-draw the features. I'm sure there is a better
        //       way to force a re-draw.
        //       The re-draw is required as the custom mapbox draw extension is
        //       responsible for adding labels. 
        mp?.draw.deleteAll();
        mp?.draw.set(getDrawFeatureCollection());
    }, [
        devSettingsState.mapSettings.showLabels,
        devSettingsState.mapSettings.mapLabels, 
        devSettingsState.dealerSettings.display.current.lengths, 
        devSettingsState.dealerSettings.display.current.areas
    ])
    
    useEffect(() => {
        if (!mp) return; // wait for map to initialize
        if (props.center) {
            const mapCenter = mp.map.getCenter();
            if (!mp.map.isMoving() &&
                (props.center[0] !== mapCenter.lng || props.center[1] !== mapCenter.lat)) {
                // If the user is moving the map then assume they're the source of the event so don't fight
                // them.
                mp.map.setCenter(props.center);
            }
        }
    }, [props.center]);

    const handleGetBingMapStyle = async (withRoads: boolean) : Promise<mapboxgl.Style> => {
        const tileUrl = await fetchBingMetaData(withRoads);
        if (tileUrl) {
            return {
                version: 8,
                sources: {
                    bing: {
                        type: 'raster',
                        tiles: [tileUrl],                   
                        tileSize: 256,                    
                    }
                },
                layers: [{
                    id: 'bing',
                    type: 'raster',
                    source: 'bing',
                }],
                glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
            };
        }      
    }

    useEffect(() => {
        if (!mp) return; // wait for map to initialize
        if (props.zoom) {
            const mapZoom = mp.map.getZoom();
            if (!mp.map.isZooming() && props.zoom !== mapZoom) {
                // If the user is zooming the map then assume they're the source of the event so don't fight
                // them.
                mp.map.setZoom(props.zoom);
            }
        }
    }, [props.zoom]);

    useEffect(() => {
        if (!mp || props.drawMode === undefined) return;
        const drawMode = props.drawMode ?? "simple_select";
        if (drawMode !== mp.draw.getMode()) {
            if (typeof drawMode === 'string') {
                mp.draw.changeMode(drawMode);
            }
            else {
                mp.draw.changeMode(drawMode.mode, drawMode.options);
            }
        }
    }, [props.drawMode, mp]);

    useEffect(() => {
        // we need a try/catch, as, if we try to set the draw features after destroying the map it will error
        try {
            mp?.draw.set(getDrawFeatureCollection());
        }
        catch {
            // pass
        }
    }, [props.drawFeatures, mp]);

    const mapContext: IMapContext = {
        fitBounds: (bounds: [number, number, number, number]) => {
            if (!mp) return;
            mp.map.fitBounds(bounds);
        },
        changeMode: (mode, options) => {
            if (!mp) return;
            mp.draw.changeMode(mode, options);
        }
    }

    const getMapLayerStyle = async(): Promise<string | mapboxgl.Style> => {
        switch (devSettingsState.mapSettings.layerType) {
            case MapLayerType.MapBoxRoadLabels:
                return 'mapbox://styles/mapbox/satellite-streets-v12';
            case MapLayerType.Azure:   
                return {
                        version: 8,
                        sources: {
                            azure: {
                                type: 'raster',
                                tiles: ["https://atlas.microsoft.com/map/imagery/png?api-version=1.0&subscription-key=4l5QoI_S2BgAdNZ0B4yITkMMBQQ3iGgvIzX3eJ2KoW0&style=satellite&zoom={z}&x={x}&y={y}"],                   
                                tileSize: 256,                    
                            }
                        },
                        layers: [{
                            id: 'azure',
                            type: 'raster',
                            source: 'azure',
                        }],                    
                        glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
                    };
            case MapLayerType.BingWithRoadLabels:
                return await handleGetBingMapStyle(true);
            case MapLayerType.Bing:
                return await handleGetBingMapStyle(false);
            case MapLayerType.MapBox:
            default:
                return 'mapbox://styles/mapbox/satellite-v9';
        }
    }

    useEffect(() => {
        if (!mp) return; // wait for map to initialize
        (async () => {
            const style = await getMapLayerStyle();
            mp.map.setStyle(style, { diff: false });
        })();
    }, [devSettingsState.mapSettings.layerType])
    
    const prettyPrintCoordinates = mousePosition ? formatLatLn(mousePosition.lat, mousePosition.lng, devSettingsState.dealerSettings.display.current.coordinates) : undefined;
    
    useEffect(() => {
        console.log("updating map rdpFunctions")
        if (mp) {
            const fns: IRdpFunctions = {
                getProject: () => {
                    if (props.projectId) {
                        return dbState.projects[props.projectId]?.state;
                    }
                },
                getMapPermissions: () => {
                    const layout = dbState.projects[props.projectId]?.state.layouts[props.layoutId];
                    return getMapFeaturePermissions(layout);
                },
                getGeometryState() {
                    return geometryState;
                },
            }
            mp.map['rdpFunctions'] = fns;
        }
    }, [dbState, mp, props.projectId, geometryState]);

    const isProposalGeneratedInLayout = dbState.projects[props.projectId]?.state.layouts[props.layoutId]
        ? Object.values(dbState.projects[props.projectId]?.state.layouts[props.layoutId].systems).some(x => x.proposalGenerated)
        : false;

    return (
        <MapContext.Provider value={mapContext}>
            {
                interactive 
                    ? <FillDiv ref={mapContainer}>{props.children}</FillDiv> 
                    : <NonInteractiveFillDiv ref={mapContainer}>{props.children}</NonInteractiveFillDiv>
            }
            <Toolbar variant="dense" />
            <MouseCoordinateContainer>
                <Stack direction='column' gap={1}>
                    {
                        prettyPrintCoordinates && (
                            <MouseCoordinateBox>
                                <Stack flexDirection={'row'} justifyContent={'center'} gap={2}>
                                    <Box flex={1} sx={{ textAlign: 'end' }}>
                                        {prettyPrintCoordinates.latPart}
                                    </Box>
                                    <Box flex={1} sx={{ textAlign: 'start' }}>
                                        {prettyPrintCoordinates.lonPart}
                                    </Box>
                                </Stack>
                            </MouseCoordinateBox>
                        )
                    }
                    {
                        showProposalGeneratedWarning && isProposalGeneratedInLayout && (
                            <ProposalWarningBox>
                                {t("proposals.map-warning")}
                            </ProposalWarningBox>
                        )
                    }
                </Stack>
            </MouseCoordinateContainer>
        </MapContext.Provider>
    )
}

export default Map;

const MouseCoordinateContainer = styled.div`
    position: relative;
    left: 0;
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

`;
const MouseCoordinateBox = styled.div`
    background-color: #ffffff80;
    width: 400px;
    text-align: center;
    padding: 5px 5px 0 5px;
    margin: 0;
    height: 22px;
    font-family: "Courier New", Courier, monospace;
    border-radius: 4px;
`;
const ProposalWarningBox = styled.div`
    background-color: #ee791880;
    width: 400px;
    text-align: center;
    padding: 5px 5px 0 5px;
    margin: 0;
    height: 20px;
    border-radius: 4px;
`;