import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from '../auth/IAuthState';
import { setValue } from '../helpers/objectPathResolver';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';

const actionTypeId = "UpdateSystemProperty";

interface ActionData extends IActionData {
    layoutId: string;
    systemId: string;

    property: string;
    value: any;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    if (!state.layouts[data.layoutId]?.systems[data.systemId]) return;
    setValue(state.layouts[data.layoutId].systems[data.systemId], data.property, data.value);
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});

export const createNewUpdateSystemPropertyAction = (
    layoutId: string,
    systemId: string,
    property: string,
    value: any,
    authState: IAuthState) => createAction(
        actionTypeId,
        {
            layoutId,
            systemId,
            property,
            value
        } as ActionData,
        authState);