import IAction from "interface/IAction";
import IActionData from "interface/IActionData";
import { SideEnum, getSide } from 'rdptypes/helpers/SideEnum';
import { ISystemBase } from 'rdptypes/project/ISystemBase.AutoGenerated';
import { ISpan } from 'rdptypes/project/Types';
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { createDeleteSpanTowerAction } from './DeleteSpan';
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';
import { renumberSpanAndTowers, switchSidesIfNeeded } from './helpers/spans';

const actionTypeId = "DeleteSpanTowerV2";

// NOTE: The delete span tower action has been superceeded by V2.
// The V1 action has been left so to not crash existing DBs
// As the action is no longer imported anywhere, the registor does not execute
// Bellow it is imported so to register the executer
const dummy = typeof createDeleteSpanTowerAction

interface ActionData extends IActionData {
    layoutId: string;
    systemId: string;
    spanNumbers: {
        flangedSideSpanNumbers?: number[],
        flexSideSpanNumbers?: number[],
    }
}

const handleSide = (sys: ISystemBase, sideEnum: SideEnum, spanNumbers: number[]) => {
    const side = getSide(sys, sideEnum);

    const spans = side.Span;

    let endBoom: ISpan | undefined;
    if (spans.length !== 0 && spans[spans.length - 1].EndBoom) {
        endBoom = spans.pop();
    }    
    let swingArm: ISpan | undefined;
    if (spans.length !== 0 && spans[spans.length - 1].SwingArm) {
        swingArm = spans.pop();
    }
    const towers = side.Tower;

    for (let i = 0; i < spanNumbers.length; i++) {
        const spanAndTowerIndex = spans.findIndex(x => x.SpanNumber === spanNumbers[i]);
        if (spanAndTowerIndex !== -1) {
            spans.splice(spanAndTowerIndex, 1);
            towers.splice(spanAndTowerIndex, 1);
        }
    }

    if (swingArm) {
        spans.push(swingArm);
    }
    if (endBoom) {
        spans.push(endBoom);
    }
    renumberSpanAndTowers(side);
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const sys = state.layouts[data.layoutId].systems[data.systemId];

    // Flanged side:
    if (data.spanNumbers.flangedSideSpanNumbers && data.spanNumbers.flangedSideSpanNumbers.length) {
        handleSide(sys, SideEnum.Flanged, data.spanNumbers.flangedSideSpanNumbers);
    }
    
    // Flex side:
    if (data.spanNumbers.flexSideSpanNumbers && data.spanNumbers.flexSideSpanNumbers.length) {
        handleSide(sys, SideEnum.Flex, data.spanNumbers.flexSideSpanNumbers);
    }

    switchSidesIfNeeded(sys);
    
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});

export const createDeleteSpanTowerActionV2 = (
    layoutId: string,
    systemId: string,
    spanNumbers: {
        flangedSideSpanNumbers?: number[],
        flexSideSpanNumbers?: number[],
    },
    authState: IAuthState) => createAction(
        actionTypeId,
        {
            layoutId,
            systemId,
            spanNumbers
        } as ActionData,
        authState);