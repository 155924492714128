import { GPSBaseOptions, GuidanceLocations, GuidanceTypes, ISystemBase, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IRule } from "rdptypes/roe/IRule";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "lateralGuidance",
            title: "Lateral Guidance",
            position: 2.5,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType !== SystemTypes.CenterPivot && sys.SystemProperties.SystemType !== SystemTypes.KwikTow && sys.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && sys.SystemProperties.SystemType !== SystemTypes.Ancillary,
            components: [
                {
                    type: "card",
                    title: "Guidance Options",
                    children: [
                        {
                            type: "choice",
                            fieldPath: "Lateral.Guidance.GuidanceType", 
                            title: "Guidance Type",
                            options: [
                                { id: "Furrow", title: 'Furrow'},
                                { id: "Cable", title: 'Cable'},
                                { id: "BuriedWire", title: 'Buried Wire'},
                                { id: "FenceLine", title: 'Fence Line'},
                                { id: "NavigatorGPS", title: 'Navigator GPS'},
                            ],
                            enumNameHint: "GuidanceTypes",
                            includeOnlyValidationRules: [],
                        },
                        {
                            visible: (system: ISystemBase) =>
                                system.SystemProperties.SystemType !== SystemTypes.HoseFeedMaxigator
                                && system.Lateral.Guidance.Location !== GuidanceLocations.PowerTower
                                && system.Lateral.Guidance.GuidanceType === GuidanceTypes.Furrow,
                            type: "choice",
                            fieldPath: "Lateral.Guidance.FurrowOptions.Winch",
                            title: "Winch",
                            options: [
                                { id: "Manual", title: 'Manual'},
                                { id: "Electric", title: 'Electric'},
                            ],
                            enumNameHint: "WinchTypes",
                        },
                        {
                            type: "choice",
                            fieldPath: "Lateral.Guidance.Location",
                            title: "Guidance Location",
                            options: [
                                { id: "PowerTower", title: 'Power Tower'},
                                { id: "OuterTower", title: 'Outer Tower'},
                            ],
                            enumNameHint: "GuidanceLocations",
                            includeOnlyValidationRules: [],
                        },
                    ]
                },
                {
                    type: "number",
                    fieldPath: "Lateral.LengthOfField",
                    title: "Length of Run",
                    units: "ft",
                    required: true
                },
                {
                    visible: (system: ISystemBase) => system.Lateral.Guidance.GuidanceType === GuidanceTypes.NavigatorGPS,
                    type: "choice",
                    fieldPath: "Lateral.Guidance.GPSBase",
                    title: "GPS Base",
                    options: [
                        { id: "WithBase", title: 'With Base - Freewave Radio'},
                        { id: "ExistingReinkeFreewave", title: 'Without Base - Use Existing Reinke Freewave Base'},
                        { id: "ExistingNonReinke", title: 'Without Base - Use Existing Non-Reinke Base'},
                    ],
                    enumNameHint: "GPSBaseOptions",
                },
                {
                    visible: (system: ISystemBase) => system.Lateral.Guidance.GuidanceType === GuidanceTypes.Cable,
                    type: "card",
                    title: "Cable Options",
                    children: [
                        {
                            type: "number",
                            fieldPath: "Lateral.Guidance.CableOptions.RollsOfCable",
                            title: "Guide Cable (in 300' rolls)",
                        },
                        {
                            type: "number",
                            fieldPath: "Lateral.Guidance.CableOptions.ExtraCableStops",
                            title: "Extra Cable Stops",
                        },
                    ]
                },
                {
                    type: "booleangroup",
                    layoutHint: "secondary",
                    components: [
                        { type: "boolean", title: "Furrow Wheel", fieldPath: "Lateral.Guidance.FurrowOptions.LongSystem"},
                        { type: "boolean", title: "Solar Powered", fieldPath: "Lateral.Guidance.BuriedWireOptions.SolarPowered"},
                    ],
                },
                {
                    visible: (system: ISystemBase) => system.Lateral.Guidance.GuidanceType === GuidanceTypes.BuriedWire,
                    type: "card",
                    title: "Buried Wire",
                    children: [
                        {
                            type: "choice",
                            fieldPath: "Lateral.Guidance.BuriedWireOptions.Wire.WireType",
                            title: "Wire Type",
                            options: [
                                { id: "Shielded", title: '14/1 Shielded'},
                                { id: "NonShielded", title: '14/1 Non-Shielded'},
                            ],
                            enumNameHint: "WireTypes",
                        },
                        {
                            type: "number",
                            fieldPath: "Lateral.Guidance.BuriedWireOptions.Wire.RollsOfWire",
                            title: "Buried Wire (in 10,000' rolls)",
                        }
                    ]
                },
                {
                    visible: (system: ISystemBase) => system.Lateral.Guidance.GuidanceType === GuidanceTypes.NavigatorGPS
                    && [GPSBaseOptions.WithBase, GPSBaseOptions.ExistingReinkeFreewave].indexOf(system.Lateral.Guidance.GPSBase) !== -1,
                    type: "card",
                    children: [
                        {
                            type: "choice",
                            fieldPath: "Lateral.Guidance.GPSCoverageStudyCompleted",
                            title: "GPS Coverage Study",
                            options: [
                                { id: false, title: "Needed"},
                                { id: true, title: "Completed"},
                            ],

                            // Don't validate because required text boxes will probably not yet be filled in
                           validate: false
                        },
                        {
                            type: "text",
                            fieldPath: "Lateral.Guidance.GPSCoverageStudyNetworkName",
                            title: "Network Name",
                            multiline: true,
                            required: true,
                        },
                        {
                            type: "text",
                            fieldPath: "Lateral.Guidance.GPSCoverageStudySiteName",
                            title: "Site Name",
                            multiline: true,
                            required: true,
                        },
                        {
                            visible: (sys: ISystemBase) => sys.Lateral.Guidance.GPSCoverageStudyCompleted === false,
                            type: "text",
                            fieldPath: "Lateral.Guidance.GPSCoverageStudyBaseLocation",
                            title: "Base Location (LAT/LON)",
                            multiline: true,
                            required: true,
                        },
                        {
                            visible: (sys: ISystemBase) => sys.Lateral.Guidance.GPSCoverageStudyCompleted === false,
                            type: "text",
                            fieldPath: "Lateral.Guidance.GPSCoverageStudyLateralStartLocation",
                            title: "Lateral Start Location (LAT/LON)",
                            multiline: true,
                            required: true,
                        },
                        {
                            visible: (sys: ISystemBase) => sys.Lateral.Guidance.GPSCoverageStudyCompleted === false,
                            type: "text",
                            fieldPath: "Lateral.Guidance.GPSCoverageStudyLateralEndLocation",
                            title: "Lateral End Location (LAT/LON)",
                            multiline: true,
                            required: true,
                        },
                    ],
                },
            ],
        },
    ],
    rules: [
    ] as IRule[],
};