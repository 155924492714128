import {
    CanalFeedEngines,
    CanalFeedGenerators,
    CartTypes,
    FuelTankSizes,
    GearDriveTypes,
    IntakeTypes,
    Pumps,
    SpanTypes,
    WaterFeedTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/poSchema";
import CSection2 from "./CSection2";
import { TireSelector } from "./CommonFunctions";
import * as ohf from "./OtherHelpers";
import PartsAssembler from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as spanf from "./SpanFunctions";
import { idxCableConductors } from "./SpanFunctions";
import { strLanguageText } from "./TranslationsLib";
import { Tires } from "./Types";
import { CanalEngineOK, TubesOK } from "./Valids.Valids";
export class CanalFeedClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        const cf = this.Quote.System.Lateral.CanalFeed;
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(strLanguageText("sltCanalFeedSection"));
        return false;
        }*/
        if (!(TubesOK(this.Quote.System.Lateral.Tires.TubesForTires, {
            TireSize: this.Quote.System.Lateral.Tires.TireSize,
            TireType: this.Quote.System.Lateral.Tires.TireType
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltTubesForTires"));
        }
        if (this.Quote.System.Lateral.ButterflyValves) {
            if (this.Quote.System.Lateral.WaterFeed !== WaterFeedTypes.CenterFeed) {
                this.Quote.Warnings.Add(strLanguageText("sltButterflyValves"));
            }
        }
        if (cf.BoosterPumpSelfCleaningScreen) {
            if (cf.Intake === IntakeTypes.Float) {
                this.Quote.Warnings.Add(strLanguageText("sltBoosterPumpSelfCleaningScreen"));
            }
        }
        if (cf.FourFootSuctionPipes * 4 + cf.SixFootSuctionPipes * 6 > 10) {
            this.Quote.Warnings.Add(strLanguageText("sltSuctionPipeQuantity"));
        }
        if (cf.Intake === IntakeTypes.LiftKit) {
            if (cf.FourFootSuctionPipes === 0 || cf.SixFootSuctionPipes !== 0) {
                this.Quote.Warnings.Add(strLanguageText("sltIntake"));
            }
        }
        if (!(CanalEngineOK(cf.Engine, {
            IsInternational: ohf.IsInternational(this.Quote.System.DealerProperties),
            Pump: cf.Pump,
            Generator: cf.Generator,
            CartType: cf.Cart
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltEngine"));
        }
        this.Quote.Warnings.Add(strLanguageText("sltConsultReinkeEnginePump"), WarningLevels.Informational);
        this.Quote.Warnings.Add(strLanguageText("sltMaxiSlopeWarning"), WarningLevels.Informational);
        this.Quote.Warnings.Add(strLanguageText("sltDeerePrice"), WarningLevels.Informational);
    }
    public WritePO = (PO: PartsAssembler) => {
        let eFirstRightSpanType: SpanTypes = SpanTypes.E2100;
        let eFirstLeftSpanType: SpanTypes = SpanTypes.E2100;
        let b14C: boolean = false;
        if (this.Quote.RightSpansClass.FieldSet.DataValid()) {
            if (sidef.NumberOfSpans(this.Quote.System.FlangedSide) > 0) {
                eFirstRightSpanType = this.Quote.System.FlangedSide.Span[1 - 1].SpanType;
                if (spanf.CableConductors(this.Quote.System, this.Quote.System.FlangedSide, this.Quote.System.FlangedSide.Span[1 - 1]) === idxCableConductors.cc14) {
                    b14C = true;
                }
            }
        }
        if (this.Quote.LeftSpansClass.FieldSet.DataValid()) {
            if (sidef.NumberOfSpans(this.Quote.System.FlexSide) > 0) {
                eFirstLeftSpanType = this.Quote.System.FlexSide.Span[1 - 1].SpanType;
                if (spanf.CableConductors(this.Quote.System, this.Quote.System.FlexSide, this.Quote.System.FlexSide.Span[1 - 1]) === idxCableConductors.cc14) {
                    b14C = true;
                }
            }
        }
        switch (this.Quote.System.Lateral.CanalFeed.Engine) {
            case CanalFeedEngines.Dom67:
                PO.InsertPart(schema.groups.CanalFeed_Engine.groupId, "353020", 1); // ENG OPT-DEERE 4045TFC03, 65 HP
                break;
            case CanalFeedEngines.Dom64:
                PO.InsertPart(schema.groups.CanalFeed_Engine.groupId, "393060", 1); // ENGINE-JCB CF
                PO.InsertPart(schema.groups.CanalFeed_Engine.groupId, "354021", 1); // FUEL FITTINGS KIT-CART CF JCB
                break;
            case CanalFeedEngines.DomReserved1:
                break;
            case CanalFeedEngines.Dom109:
                PO.InsertPart(schema.groups.CanalFeed_Engine.groupId, "353024", 1); // ENG OPT-DEERE 4045HFC93,109 HP
                break;
            case CanalFeedEngines.Dom129:
                PO.InsertPart(schema.groups.CanalFeed_Engine.groupId, "353025", 1); // ENG OPT-DEERE 6068HFC93,129 HP
                break;
            case CanalFeedEngines.DomReserved2:
                break;
            case CanalFeedEngines.Int52:
                PO.InsertPart(schema.groups.CanalFeed_Engine.groupId, "392840", 1); // ENG OPT-DEERE 4045TF290, 54 HP
                break;
            case CanalFeedEngines.Int69:
                PO.InsertPart(schema.groups.CanalFeed_Engine.groupId, "393053", 1); // ENG OPT-DEERE 4045T, 69 HP
                break;
            case CanalFeedEngines.Int84:
                PO.InsertPart(schema.groups.CanalFeed_Engine.groupId, "393054", 1); // ENG OPT-JD 4045T-250, 84 HP-T1
                break;
            case CanalFeedEngines.Int108:
                PO.InsertPart(schema.groups.CanalFeed_Engine.groupId, "393055", 1); // ENG OPT-JD 6068T-150,108 HP-T1
                break;
            case CanalFeedEngines.Int150:
                PO.InsertPart(schema.groups.CanalFeed_Engine.groupId, "393057", 1); // ENG OPT-JD 6068T250, 150 HP-T1
                break;
            case CanalFeedEngines.Int255:
                PO.InsertPart(schema.groups.CanalFeed_Engine.groupId, "392846", 1); // ENG OPT-DEERE 6090H485, 255 HP
                break;
        }
        switch (this.Quote.System.Lateral.CanalFeed.Pump) {
            case Pumps.a5RBS:
                PO.InsertPart(schema.groups.CanalFeed_Pump.groupId, "392851", 1); // PUMP-CORNELL 5RBS
                break;
            case Pumps.a6RBS:
                PO.InsertPart(schema.groups.CanalFeed_Pump.groupId, "392852", 1); // PUMP-CORNELL 6RBS
                break;
            case Pumps.a8H:
                PO.InsertPart(schema.groups.CanalFeed_Pump.groupId, "392853", 1); // PUMP-CORNELL 8H
                break;
            case Pumps.a5YBH:
                if (this.Quote.System.Lateral.CanalFeed.Engine === CanalFeedEngines.Dom64) {
                    PO.InsertPart(schema.groups.CanalFeed_Pump.groupId, "392862", 1); // PUMP-CORNELL 5YBQ F16
                }
                else {
                    PO.InsertPart(schema.groups.CanalFeed_Pump.groupId, "392858", 1); // PUMP-CORNELL 5YBQ
                }
                break;
            case Pumps.a6YB:
                PO.InsertPart(schema.groups.CanalFeed_Pump.groupId, "392859", 1); // PUMP-CORNELL 6YBQ
                break;
            case Pumps.a10RB:
                PO.InsertPart(schema.groups.CanalFeed_Pump.groupId, "392856", 1); // PUMP-CORNELL 10RB
                break;
        }
        switch (this.Quote.System.Lateral.CanalFeed.FuelTankSize) {
            case FuelTankSizes.a300:
                PO.InsertPart(schema.groups.CanalFeed_FuelTank.groupId, "392878", 1); // FUEL TNK W/GAUGE-300GAL-DF MX
                break;
            case FuelTankSizes.a550:
                PO.InsertPart(schema.groups.CanalFeed_FuelTank.groupId, "392879", 1); // FUEL TNK W/GAUGE-550 GAL-DF MX
                break;
        }
        switch (this.Quote.System.Lateral.CanalFeed.Generator) {
            case CanalFeedGenerators.a10KW:
                PO.InsertPart(schema.groups.CanalFeed_Generator.groupId, "392834", 1); // GENERATOR ASY-10 KW-CANAL FEED
                break;
            case CanalFeedGenerators.a1212KW:
                PO.InsertPart(schema.groups.CanalFeed_Generator.groupId, "392835", 1); // GENERATOR ASY-12.5 KW-CANAL FD
                break;
            case CanalFeedGenerators.a15KW:
                PO.InsertPart(schema.groups.CanalFeed_Generator.groupId, "392836", 1); // GENERATOR ASY-15KW-CANAL FEED
                break;
            case CanalFeedGenerators.a20KW:
                PO.InsertPart(schema.groups.CanalFeed_Generator.groupId, "392837", 1); // GENERATOR ASY-20KW-CANAL FEED
                break;
            case CanalFeedGenerators.a25KW:
                PO.InsertPart(schema.groups.CanalFeed_Generator.groupId, "392838", 1); // GENERATOR ASY-25KW-CANAL FEED
                break;
            case CanalFeedGenerators.a30KW:
                PO.InsertPart(schema.groups.CanalFeed_Generator.groupId, "392839", 1); // GENERATOR ASY-30KW-CANAL FEED
                break;
            case CanalFeedGenerators.MeccAlte_10_0_KW:
                PO.InsertPart(schema.groups.CanalFeed_Generator.groupId, "392890", 1); // GENERATOR ASY-10KW-MECC - CF
                break;
            case CanalFeedGenerators.MeccAlte_15_0_KW:
                PO.InsertPart(schema.groups.CanalFeed_Generator.groupId, "392891", 1); // GENERATOR ASY-15KW-MECC - CF
                break;
        }
        switch (this.Quote.System.Lateral.CanalFeed.Cart) {
            case CartTypes.Standard:
                if (this.Quote.System.Lateral.CanalFeed.Pump === Pumps.a10RB) {
                    switch (this.Quote.System.Lateral.CanalFeed.Intake) {
                        case IntakeTypes.Float:
                            PO.InsertPart(schema.groups.CanalFeed_PowerTowerCartAssembly.groupId, "392870", 1); // CANAL FEED CART W/16" INTAKE
                            break;
                        case IntakeTypes.SelfCleaningScreen:
                            PO.InsertPart(schema.groups.CanalFeed_PowerTowerCartAssembly.groupId, "392874", 1); // CANAL FEED,16" INT,SLF CLN SCR
                            break;
                    }
                }
                else {
                    if (this.Quote.System.Lateral.CanalFeed.Engine === CanalFeedEngines.Dom64) {
                        PO.InsertPart(schema.groups.CanalFeed_PowerTowerCartAssembly.groupId, "395818", 1); // POWER TWR CART-CF JCB
                    }
                    else {
                        PO.InsertPart(schema.groups.CanalFeed_PowerTowerCartAssembly.groupId, "395815-G", 1); // POWER TWR CART-CANAL FEED-EII
                    }
                }
                break;
            case CartTypes.Sugargator:
                PO.InsertPart(schema.groups.CanalFeed_PowerTowerCartAssembly.groupId, "395816", 1); // POWER TOWER-SUGAR CANAL FEED
                break;
        }
        switch (this.Quote.System.Lateral.CanalFeed.Intake) {
            case IntakeTypes.Float:
                if (this.Quote.System.Lateral.CanalFeed.Pump === Pumps.a10RB) {
                    PO.InsertPart(schema.groups.CanalFeed_Intake.groupId, "392867", 1); // FLOAT OPT-16" SUCTION-CANAL FD
                }
                else {
                    PO.InsertPart(schema.groups.CanalFeed_Intake.groupId, "392822", 1); // FLOAT OPTION-CANAL FEED
                }
                break;
            case IntakeTypes.SelfCleaningScreen:
                switch (this.Quote.System.Lateral.CanalFeed.Pump) {
                    case Pumps.a10RB:
                        PO.InsertPart(schema.groups.CanalFeed_Intake.groupId, "392872", 1); // FLOAT OPT,SLF-CLN,16" SUC CF
                        break;
                    case Pumps.a6RBS:
                    case Pumps.a6YB:
                        PO.InsertPart(schema.groups.CanalFeed_Intake.groupId, "392830", 1); // SLF-CLN-SCN OPT 2900 GPM 6RB
                        break;
                    default:
                        PO.InsertPart(schema.groups.CanalFeed_Intake.groupId, "392823", 1); // SLF-CLN-SCN OPT 2900 GPM-5RB
                        break;
                }
                break;
            case IntakeTypes.LiftKit:
                switch (this.Quote.System.Lateral.CanalFeed.FourFootSuctionPipes) {
                    case 1:
                        PO.InsertPart(schema.groups.CanalFeed_Intake.groupId, "392820", 1); // LIFT KIT, DLR. SUPPLIED SCREEN
                        break;
                    case 2:
                        PO.InsertPart(schema.groups.CanalFeed_Intake.groupId, "392825", 1); // LIFT KIT, DLR. SUPPLIED SCREEN
                        break;
                }
                break;
        }
        if (this.Quote.System.Lateral.CanalFeed.BoosterPumpSelfCleaningScreen) {
            switch (this.Quote.System.Lateral.CanalFeed.Pump) {
                case Pumps.a10RB:
                    PO.InsertPart(schema.groups.CanalFeed_BoosterPumpSelfCleaningScreen.groupId, "392850", 1); // BOOST PUMP-16" SELF CLEAN SCRN
                    break;
                default:
                    PO.InsertPart(schema.groups.CanalFeed_BoosterPumpSelfCleaningScreen.groupId, "392829", 1); // BOOST PUMP OPT-SELF CLEAN SCRN
                    break;
            }
        }
        if (this.Quote.System.Lateral.CanalFeed.BoosterPumpSelfCleaningScreen) {
            PO.InsertPart(schema.groups.ControlPanel_CanalFeedSelfCleaningScreenBP.groupId, "350936", 1); // ELECT KIT-BP-SELF CLEAN SCREEN
        }
        switch (this.Quote.System.Lateral.WaterFeed) {
            case WaterFeedTypes.CenterFeed:
                if (this.Quote.System.Lateral.ButterflyValves) {
                    if (b14C) {
                        switch (this.Quote.System.Lateral.CanalFeed.Cart) {
                            case CartTypes.Standard:
                                PO.InsertPart(schema.groups.CanalFeed_WyeAssembly.groupId, "395856-14", 1); // CEN FD-CNL FD W/VLV & 14C-EII
                                break;
                            case CartTypes.Sugargator:
                                PO.InsertPart(schema.groups.CanalFeed_WyeAssembly.groupId, "395943-14", 1); // CEN FEED W/VLV-14C-SUGAR CANAL
                                break;
                        }
                    }
                    else {
                        switch (this.Quote.System.Lateral.CanalFeed.Cart) {
                            case CartTypes.Standard:
                                PO.InsertPart(schema.groups.CanalFeed_WyeAssembly.groupId, "395856-G", 1); // CENTER FEED W/VALVE-CNL FD-EII
                                break;
                            case CartTypes.Sugargator:
                                PO.InsertPart(schema.groups.CanalFeed_WyeAssembly.groupId, "395943", 1); // CENTER FEED W/VLV-SUGAR CANAL
                                break;
                        }
                    }
                }
                else {
                    if (b14C) {
                        switch (this.Quote.System.Lateral.CanalFeed.Cart) {
                            case CartTypes.Standard:
                                if (this.Quote.System.Lateral.CanalFeed.Pump === Pumps.a10RB) {
                                    PO.InsertPart(schema.groups.CanalFeed_WyeAssembly.groupId, "392865-14", 1); // CEN CANAL FD-10"X8" WYE W/14C
                                }
                                else {
                                    PO.InsertPart(schema.groups.CanalFeed_WyeAssembly.groupId, "395855-14", 1); // CEN FD OPT-CNL FD W/14C-EII
                                }
                                break;
                            case CartTypes.Sugargator:
                                PO.InsertPart(schema.groups.CanalFeed_WyeAssembly.groupId, "395942-14", 1); // CENTER FEED 14C-SUGAR CANAL FD
                                break;
                        }
                    }
                    else {
                        switch (this.Quote.System.Lateral.CanalFeed.Cart) {
                            case CartTypes.Standard:
                                if (this.Quote.System.Lateral.CanalFeed.Pump === Pumps.a10RB) {
                                    PO.InsertPart(schema.groups.CanalFeed_WyeAssembly.groupId, "392865", 1); // CEN CANAL FEED OPT-10"X8" WYE
                                }
                                else {
                                    PO.InsertPart(schema.groups.CanalFeed_WyeAssembly.groupId, "395855-G", 1); // CENTER FEED OPT-CANAL FD-EII
                                }
                                break;
                            case CartTypes.Sugargator:
                                PO.InsertPart(schema.groups.CanalFeed_WyeAssembly.groupId, "395942", 1); // CENTER FEED OPT-SUGAR CANAL FD
                                break;
                        }
                    }
                }
                break;
            case WaterFeedTypes.EndFeed:
                PO.InsertPart(schema.groups.CanalFeed_WyeAssembly.groupId, "395857-G", 1); // END FEED OPTION-CANAL FEED-EII
                break;
        }
        if (this.Quote.System.Lateral.CanalFeed.FourFootSuctionPipes > 0) {
            if (this.Quote.System.Lateral.CanalFeed.Pump === Pumps.a10RB) {
                PO.InsertPart(schema.groups.CanalFeed_SuctionExtensions.groupId, "392868", this.Quote.System.Lateral.CanalFeed.FourFootSuctionPipes); // 4' EXTENSION-16" SUCTION PIPE
            }
            else {
                PO.InsertPart(schema.groups.CanalFeed_SuctionExtensions.groupId, "392848-G", this.Quote.System.Lateral.CanalFeed.FourFootSuctionPipes); // 4 FT EXTENSION OPTION-CANAL FD
            }
        }
        if (this.Quote.System.Lateral.CanalFeed.SixFootSuctionPipes > 0) {
            if (this.Quote.System.Lateral.CanalFeed.Pump === Pumps.a10RB) {
                PO.InsertPart(schema.groups.CanalFeed_SuctionExtensions.groupId, "392869", this.Quote.System.Lateral.CanalFeed.SixFootSuctionPipes); // 6' EXTENSION-16" SUCTION PIPE
            }
            else {
                PO.InsertPart(schema.groups.CanalFeed_SuctionExtensions.groupId, "392849-G", this.Quote.System.Lateral.CanalFeed.SixFootSuctionPipes); // 6 FT EXTENSION OPTION-CANAL FD
            }
        }
        PO.InsertPart(schema.groups.CanalFeed_PumpPrimer.groupId, "392832", 1); // PRIMER OPT-460 VAC-CANAL FEED
        PO.InsertPart(schema.groups.ControlPanel_CanalFeedSelfCleaningScreenBP.groupId, "352832", 1); // PRIMING KIT-MCP-RPM LINEAR
        if (this.Quote.System.Lateral.CanalFeed.Pump !== Pumps.a10RB) {
            PO.InsertPart(schema.groups.CanalFeed_PumpPrimer.groupId, "392821", 1); // CHECK VALVE OPT-12" CANAL FEED
        }
        switch (this.Quote.System.Lateral.GearDrive) {
            case GearDriveTypes.Standard:
                PO.InsertPart(schema.groups.CanalFeed_CenterGearDrives.groupId, "392467", 1); // HEL GEAR OPT STD SPD CANAL FD
                break;
            case GearDriveTypes.High:
                PO.InsertPart(schema.groups.CanalFeed_CenterGearDrives.groupId, "392468", 1); // HEL GEAR OPT HI SPD CANAL FD
                break;
        }
        let iSelectedTire: number;
        let t: Tires = this.Quote.System.Lateral.Tires;
        iSelectedTire = TireSelector(t.TireSize, t.TireType, t.OneTireMountedInReverse, t.TubesForTires);
        PO.InsertPartByIteration(schema.groups.CanalFeed_TireandWheelAssembly.groupId, iSelectedTire, 2);
        switch (eFirstRightSpanType) {
            case SpanTypes.E2085:
            case SpanTypes.PL2085G:
            case SpanTypes.S2085G:
                PO.InsertPart(schema.groups.CanalFeed_FlangedTowerTop.groupId, "395853-G", 1); // EXT-FLG WYE-8 5/8-CANAL FD-EII
                break;
            case SpanTypes.S2065G:
                PO.InsertPart(schema.groups.CanalFeed_FlangedTowerTop.groupId, "395913PL", 1); // TOWER TOP REDUCER-S2065PL-MAXI
                break;
            default:
                PO.InsertPart(schema.groups.CanalFeed_FlangedTowerTop.groupId, "395854-G", 1); // WYE EXT-REDUCR-8"-6 5/8-CF-EII
                break;
        }
        if (this.Quote.System.Lateral.WaterFeed === WaterFeedTypes.CenterFeed) {
            switch (eFirstLeftSpanType) {
                case SpanTypes.E2085:
                case SpanTypes.PL2085G:
                case SpanTypes.S2085G:
                    PO.InsertPart(schema.groups.CanalFeed_ReceiverJointTowerTop.groupId, "395858-G", 1); // WYE JOINT-8 5/8-CANAL FEED-EII
                    break;
                case SpanTypes.A80G:
                    PO.InsertPart(schema.groups.CanalFeed_ReceiverJointTowerTop.groupId, "395864", 1); // WYE JNT-CANAL FD-8" TO A80G
                    break;
                case SpanTypes.A60G:
                case SpanTypes.AlumIV:
                    PO.InsertPart(schema.groups.CanalFeed_ReceiverJointTowerTop.groupId, "395863", 1); // WYE JNT-CANAL FD-8" TO A4/A60G
                    break;
                case SpanTypes.S2065G:
                    PO.InsertPart(schema.groups.CanalFeed_ReceiverJointTowerTop.groupId, "395862SS", 1); // WYE JNT-CNL FD-2085G/S2065G
                    break;
                case SpanTypes.PL2065G:
                    PO.InsertPart(schema.groups.CanalFeed_ReceiverJointTowerTop.groupId, "395862PL", 1); // WYE JNT-CNL FD-2085G-S2065PL
                    break;
                default:
                    PO.InsertPart(schema.groups.CanalFeed_ReceiverJointTowerTop.groupId, "395859-G", 1); // WYE JNT-8 5/8-6 5/8-CNL FD-EII
                    break;
            }
        }
    }
}