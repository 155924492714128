import { ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import IPartsList from "rdptypes/roe/IPartsList";
import PartsAssembler from "../roe_migration/PartsAssembler";
import QuoteClass, { classSelectors } from "../roe_migration/QuoteClass";

const plgFactory = (executor: (quote: QuoteClass, pa: PartsAssembler) => any): ((system: ISystemBase) => IPartsList) => {
    return (sys: ISystemBase) => {
        var quote = new QuoteClass(sys);
        var pa = new PartsAssembler(quote);
        executor(quote, pa);
        return pa.PartsList;
    };
}

export default {
    partsListGenerators: [
        ...classSelectors.map(cs => plgFactory((quote, pa) => {
            var c = cs(quote);
            if (c.DataValid()) c.WritePO(pa);
        })),
    ] as ((system: ISystemBase) => IPartsList)[]
};