import { CanalFeedEngines, CanalFeedGenerators, CartTypes, DragHoseStandardLengths, ElectricalFrequencies, HoseAndCouplerKitTypes, HoseFeedTypes, ISystemBase, IntakeTypes, Pumps, SystemTypes, WaterFeedTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IRule } from "rdptypes/roe/IRule";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "lateral",
            title: "Lateral",
            position: 2,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType !== SystemTypes.CenterPivot && sys.SystemProperties.SystemType !== SystemTypes.KwikTow && sys.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && sys.SystemProperties.SystemType !== SystemTypes.Ancillary,
            components: [
                
                {
                    type: "choice",
                    fieldPath: "Lateral.HoseFeed.HoseFeedType",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator &&
                        system.Lateral.HoseFeed.HoseFeedType !== HoseFeedTypes.PivotingLateral,
                    title: "Hose Feed Type",
                    options: [
                        { id: "Standard", title: '2 Wheel Drive'},
                        { id: "DoubleEndFeed", title: 'Double End Feed'},
                        { id: "FourWheelDrive", title: '4 Wheel Drive'},
                        { id: "Sugargator", title: 'Sugargator'},
                        { id: "CF200", title: 'CF-200'}
                    ],
                    enumNameHint: "HoseFeedTypes",
                    includeOnlyValidationRules: [
                        "Water Feed", // Disable some hose feed types for center feed
                    ]
                },
                {
                    type: "choice",
                    fieldPath: "Lateral.HoseFeed.PivotingLateralPivotCenterType",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator &&
                        system.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral,
                    validate: false,
                    title: "Pivot Center Options",
                    options: [
                        { id: "E2065", title: 'E2065 (for all 6-5/8" and 6" spans)'},
                        { id: "A80G", title: 'A80G (for 8" Aluminium spans)'},
                        { id: "E2085", title: 'E2085 (for all 8-5/8" spans)'},
                    ],
                    enumNameHint: "PivotingLateralPivotCenterTypes",
                },
                { 
                    type: "boolean",
                    visible: (system: ISystemBase) => system.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral,
                    title: "Pivot Center w/ Stainless Steel Riser Pipe", fieldPath: "Lateral.HoseFeed.PivotCenterWithSSRiserPipe",
                    marginBottom: true
                },
                { 
                    type: "boolean", 
                    title: "Single Span Maxi", 
                    fieldPath: "Lateral.HoseFeed.SingleSpanMaxi", 
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator &&
                        (system.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.Standard || system.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.FourWheelDrive || 
                            system.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.Sugargator || system.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200),
                    marginBottom: true
                },
                {
                    type: "choice",
                    fieldPath: "Lateral.GearDrive",
                    title: "Center Drive",
                    options: [
                        { id: "Standard", title: "Std Speed, 40:1" },
                        { id: "High", title: "High Speed, 25:1" },
                    ],
                    enumNameHint: "GearDriveTypes"
                },          
                {
                    type: "choice",
                    fieldPath: "Lateral.HoseFeed.InletType",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator,
                    title: "Inlet Type",
                    options: [
                        { id: "None", title: 'None'},
                        { id: "AutoReverseOverHose", title: 'Auto Reverse Over the Hose'},
                        { id: "StandardHose", title: 'Standard Hose'},
                        { id: "DualHose", title: 'Dual Hose'},
                        { id: "ReverseOnHose", title: 'Reverse On Hose'},
                    ],
                    enumNameHint: "InletTypes",
                },
                {
                    type: "choice",
                    fieldPath: "Lateral.HoseFeed.HoseAndCouplerKit",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator,
                    title: "Hose and Couplers",
                    options: [
                        { id: "None", title: 'None'},
                        { id: "HoseWithCouplers", title: 'Soft Hose w/Couplers'},
                        { id: "CouplersOnly", title: 'Coupler Kit Only (Soft Hose)'},
                        { id: "HardHoseAdapter", title: 'Adapter Kit Only (Hard Hose)'},
                    ],
                    includeOnlyValidationRules: [],
                    enumNameHint: "HoseAndCouplerKitTypes",
                },
                {
                    type: "number",
                    fieldPath: "Lateral.HoseFeed.HardHoseAdapters",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator
                    && (system.Lateral.HoseFeed.HoseAndCouplerKit ?? HoseAndCouplerKitTypes.None) !== HoseAndCouplerKitTypes.None,
                    title: "Hose / Couplers Quantity",
                    units: "Quantity",
                    default: 1 //default values not currently working (defaultActions.tsx) but this has been requested by Reinke - adding to NewSystemAction for now
                },
                {
                    type: "choice",
                    fieldPath: "Lateral.HoseFeed.BaseInletSize", 
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator,
                    title: "Hose Diameter",
                    options: [
                        { id: "a4", title: '4"'},
                        { id: "a45", title: '4 1/2"'},
                        { id: "a5", title: '5"'},
                        { id: "a6", title: '6"'},
                        { id: "a8", title: '8"'},
                    ],
                    enumNameHint: "BaseInletSizes",
                    includeOnlyValidationRules: []
                },
                {
                    type: "choice",
                    fieldPath: "Lateral.HoseFeed.DragHoseStandardLength", 
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator
                        && system.Lateral.HoseFeed.HoseAndCouplerKit === HoseAndCouplerKitTypes.HoseWithCouplers,
                    title: "Drag Hose Length",
                    options: [
                        { id: "a165", title: '165\''},
                        { id: "a220", title: '220\''},
                        { id: "a260", title: '260\''},
                        { id: "a330", title: '330\''},
                        { id: "a520", title: '520\''},
                        { id: "NonStandard", title: 'Other'},
                    ],
                    enumNameHint: "DragHoseStandardLengths",
                },
                {
                    type: "number",
                    fieldPath: "Lateral.HoseFeed.DragHoseNonStandardLength",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator
                        && system.Lateral.HoseFeed.HoseAndCouplerKit === HoseAndCouplerKitTypes.HoseWithCouplers
                        && system.Lateral.HoseFeed.DragHoseStandardLength === DragHoseStandardLengths.NonStandard,
                    title: "Drag Hose Non-Standard Length",
                    units: "ft"
                },
                {
                    type: "complexChoice",
                    title: {"en" :"Tires", "es": "Neumático"},
                    options: [
                        { title: "14.9 x 24", nestedOptions: [
                            { title: { "en" :"4-Ply", "es": "4 Capas" }, values: { "Lateral.Tires.TireSize": "a149x24", "Lateral.Tires.TireType": "FourPly" } },
                            { title: "Non-Directional", values: { "Lateral.Tires.TireSize": "a149x24", "Lateral.Tires.TireType": "NonDirectional" } },
                            { title: "Radial", values: { "Lateral.Tires.TireSize": "a149x24", "Lateral.Tires.TireType": "Radial" } },
                            { title: "Turf", values: { "Lateral.Tires.TireSize": "a149x24", "Lateral.Tires.TireType": "Turf" } },
                        ] },
                        { title: "16.9 x 24", nestedOptions: [
                            { title: { "en" :"6-Ply", "es": "6 Capas" }, values: { "Lateral.Tires.TireSize": "a169x24", "Lateral.Tires.TireType": "SixPly" } },
                            { title: "Turf", values: { "Lateral.Tires.TireSize": "a169x24", "Lateral.Tires.TireType": "Turf" } },
                        ] },
                        { title: "13.6 x 38", nestedOptions: [
                            { title: { "en" :"6-Ply", "es": "6 Capas" }, values: { "Lateral.Tires.TireSize": "a136x38", "Lateral.Tires.TireType": "SixPly" } },
                        ] },
                        { title: "320/85R38", nestedOptions: [
                            { title: "Radial", values: { "Lateral.Tires.TireSize": "a32085R38", "Lateral.Tires.TireType": "Radial" } },
                        ] },
                    ],
                    includeOnlyValidationRules: []
                },
                {
                    type: "choice",
                    fieldPath: "Lateral.HoseFeed.CF200Pump",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && system.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200,
                    title: "CF200 Pump",
                    options: [
                        { id: "Hp15", title: '15Hp Motor with 4RB Pump (500-750GPM @ 28-22psi)'},
                        { id: "Hp20", title: '20Hp Motor with 4RB Pump (500-850GPM @ 36-27psi)'},
                        { id: "Hp25", title: '25Hp Motor with 4RB Pump (500-900GPM @ 43-34psi)'},
                    ],
                    enumNameHint: "Pumps",
                },
                {
                    type: "choice",
                    fieldPath: "Lateral.CanalFeed.Intake",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator ,
                    title: "Intake",
                    options: [
                        { id: "Float", title: 'Float with Perforated Screen'},
                        { id: "SelfCleaningScreen", title: 'Self Cleaning Screen'},
                    ],
                    enumNameHint: "IntakeTypes",
                },
                {
                    type: "booleangroup",
                    layoutHint: "secondary",
                    components: [
                        { type: "boolean", title: "Tubes for Tires", fieldPath: "Lateral.Tires.TubesForTires"},
                        { type: "boolean", title: "One Tire Mounted In Reverse", fieldPath: "Lateral.Tires.OneTireMountedInReverse"},
                        { 
                            type: "boolean", title: "Butterfly Valves", 
                            visible: (system: ISystemBase) => system.Lateral.WaterFeed === WaterFeedTypes.CenterFeed,
                            fieldPath: "Lateral.ButterflyValves"},
                        { 
                            type: "boolean", 
                            title: "Drop Flex Side", 
                            visible: (system: ISystemBase) => system.Lateral.WaterFeed === WaterFeedTypes.CenterFeed && system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator,
                            fieldPath: "Lateral.DropFlexSide",
                            hook: (value: boolean) => {
                                if (value) {
                                    return [
                                        { fieldPath: "Lateral.dropSpanStartRelativeToPreviousSpanStart", value: 0 },
                                        { fieldPath: "Lateral.dropSpanEndRelativeToPreviousSpanEnd", value: 0 }
                                    ]
                                }
                            }
                        }
                    ],
                },
                {
                    type: "booleangroup",
                    layoutHint: "secondary",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator ,
                    components: [
                        { type: "boolean", title: "Pivoting (Center Lift)", improveScores: true, fieldPath: "Lateral.HoseFeed.CenterLift",includeOnlyValidationRules: []},
                        { type: "boolean", title: "Pivot Kit (Anchor Chains)", fieldPath: "Lateral.HoseFeed.PivotOption", includeOnlyValidationRules: []},
                        { 
                            type: "boolean", 
                            title: "Power Tower End Boom", 
                            fieldPath: "Lateral.HoseFeed.PowerTowerEndBoom", 
                            includeOnlyValidationRules: [],
                            hook: (value: boolean) => {
                                return [{
                                    fieldPath: "FlexSide.Span", 
                                    value: []
                                }];
                            }
                        }, 
                        { type: "boolean", title: "Towable", fieldPath: "Lateral.HoseFeed.Towable", autoChange: false, includeOnlyValidationRules: []},
                        { type: "boolean", title: "Hydraulic Cylinder Kit for Axles", fieldPath: "Lateral.HoseFeed.HydraulicLift",includeOnlyValidationRules: []},
                        { type: "boolean", title: "HD765 Wheel Gear", fieldPath: "Lateral.HoseFeed.HDWheelGear"},
                        { type: "boolean", title: "Reverse Tow", fieldPath: "SystemProperties.ReverseTow"},
                        { type: "boolean", title: "480V Hydraulic Pump Kit", fieldPath: "Lateral.HoseFeed.HydraulicPumpKit",includeOnlyValidationRules: []},
                        { type: "boolean", title: "Ballast Kit", fieldPath: "Lateral.HoseFeed.BallastKit"},
                        { type: "boolean", title: "Rotate Cart", fieldPath: "Lateral.HoseFeed.RotateCart"},
                        { type: "boolean", title: "Safety Valve", fieldPath: "Lateral.HoseFeed.SafetyValve"},

                    ],
                },
                {
                    type: "number",
                    fieldPath: "Lateral.HoseFeed.ElectricDragCordLength",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator ,
                    title: "Electric Drag Cord Length",
                    units: "ft",
                    default: 0
                },   
                { 
                    type: "boolean",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator
                        && system.Lateral.HoseFeed.ElectricDragCordLength > 0,
                    title: "Junction Box W/Plug",
                    marginBottom: true,
                    fieldPath: "Lateral.HoseFeed.JunctionBoxWithPlug"},
                {
                    type: "number",
                    fieldPath: "Lateral.HoseFeed.JunctionBoxWithoutPlug",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator
                        && system.Lateral.HoseFeed.ElectricDragCordLength > 0
                        && system.Lateral.HoseFeed.JunctionBoxWithPlug,
                    title: "Junction Box W/O Plug",
                    units: "Quantity"
                },
                {
                    type: "choice",
                    fieldPath: "Lateral.CanalFeed.FuelTankSize", 
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator ,
                    title: "Fuel Tank",
                    options: [
                        { id: "a300", title: '300 Gallons'},
                        { id: "a550", title: '550 Gallons'},
                    ],
                    enumNameHint: "FuelTankSizes",
                },
                {
                    type: "card",
                    title: "Suction Pipe Ext.",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator ,
                    children: [
                        {
                            type: "number",
                            fieldPath: "Lateral.CanalFeed.FourFootSuctionPipes",
                            title: "4' Qty",
                        },
                        {
                            type: "number",
                            fieldPath: "Lateral.CanalFeed.SixFootSuctionPipes",
                            title: "6' Qty",
                        },
                    ]
                },
                {
                    type: "choice",
                    fieldPath: "Lateral.CanalFeed.Cart",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator ,
                    title: "Cart Type",
                    options: [
                        { id: "Standard", title: 'Standard'},
                        { id: "Sugargator", title: 'Sugargator'},
                    ],
                    enumNameHint: "CartTypes",
                },
                {
                    type: "choice",
                    fieldPath: "Lateral.CanalFeed.Generator",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator ,
                    title: "Generator",
                    options: [
                        { id: "a10KW", title: 'Lima 10 kW'},
                        { id: "a12KW", title: 'Lima 12.5 kW'},
                        { id: "a15KW", title: 'Lima 15 kW'},
                        { id: "a20KW", title: 'Lima 20 kW'},
                        { id: "a25KW", title: 'Lima 25 kW'},
                        { id: "a30KW", title: 'Lima 30 kW'},
                        { id: "MeccAlte_10_0_KW", title: 'MeccAlte 10 kW'},
                        { id: "MeccAlte_15_0_KW", title: 'MeccAlte 15 kW'},
                    ],
                    enumNameHint: "CanalFeedGenerators",
                },
                {
                    type: "choice",
                    fieldPath: "Lateral.CanalFeed.Pump",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator ,
                    title: "Pump",
                    options: [
                        { id: "a5RBS", title: '5RBS, 1000-1800 GPM (65 PSI Max)'},
                        { id: "a6RBS", title: '6RBS, 1800-3500 GPM (65 PSI Max)'},
                        { id: "a8H", title: '8H, 2400-4200 GPM (75 PSI Max)'},
                        { id: "a10RB", title: '10RB, 3500-5000 GPM (55 PSI Max)'},
                        { id: "a5YBH", title: '5YBQ, 1000-1600 GPM (30 PSI Max)'},//TODO: check that this is correct - enum val a5YBH, text 5YBQ
                        { id: "a6YB", title: '6YBQ, 1600-2400 GPM (35 PSI Max)'},//TODO: check that this is correct - enum val a6YB, text 6YBQ
                    ],
                    enumNameHint: "Pumps",
                },
                {
                    type: "choice",
                    fieldPath: "Lateral.CanalFeed.Engine",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator ,
                    title: "Engine",
                    options: [
                        { id: "Dom67", title: '4045TFC03, 66HP'},
                        { id: "Dom64", title: 'JBC430, <64HP'},
                        { id: "Dom109", title: '4045HFC93, 109 HP'},
                        { id: "Dom129", title: '6068HFC93, 129 HP'},
                        { id: "Int52", title: '4040TF-290, 54 HP'},
                        { id: "Int69", title: '4045T-150, 69 HP'},
                        { id: "Int84", title: '4045T-250, 84 HP-T1'},
                        { id: "Int108", title: '6068T-150, 108 HP-T1'},
                        { id: "Int150", title: '6068T-250, 150 HP-T1'},
                        { id: "Int255", title: '6090H-485, 255 HP'}
                    ],
                    enumNameHint: "CanalFeedEngines",
                },
                {
                    type: "choice",
                    fieldPath: "Options.PivotLight",
                    title: {"en" : "Pivot Light", "es": "Lámpara en Pivote"},
                    options: [
                        { id: "None", title: {"en" : 'None', "es": "Ninguno"}, },                                            
                        { id: "Standard", title: {"en" : 'Standard', "es": "Estándar" }},                                        
                        { id: "StrobeClear", title: {"en" : 'Strobe - Std 50 ECP - Clear', "es": "Estrobo - Std 50 ECP - Borran" }},
                        { id: "StrobeAmber", title: {"en" : 'Strobe - Std 50 ECP - Amber', "es": "Estrobo - Std 50 ECP - Ámbar"  }},    
                        { id: "StrobeBlue", title: {"en" : 'Strobe - Std 50 ECP - Blue', "es": "Estrobo - Std 50 ECP - Azul" }},   
                        { id: "StrobeGreen", title: {"en" : 'Strobe - Std 50 ECP - Green' , "es": "Estrobo - Std 50 ECP - Verde"}},   
                        { id: "StrobeRed", title: {"en" : 'Strobe - Std 50 ECP - Red', "es": "Estrobo - Std 50 ECP - Rojo" }},   
                        { id: "Strobe400Clear", title: {"en" : 'Strobe - 400 ECP - Clear', "es": "Estrobo - Std 400 ECP - Borran" }},   
                        { id: "Strobe400Amber", title: {"en" : 'Strobe - 400 ECP - Amber', "es": "Estrobo - Std 400 ECP - Ámbar" }},   
                        { id: "Strobe400Blue", title: {"en" : 'Strobe - 400 ECP - Blue', "es": "Estrobo - Std 400 ECP - Azul"  }},   
                        { id: "Strobe400Green", title: {"en" : 'Strobe - 400 ECP - Green', "es": "Estrobo - Std 400 ECP - Verde" }},   
                        { id: "Strobe400Red", title: {"en" : 'Strobe - 400 ECP - Red', "es": "Estrobo - Std 400 ECP - Rojo" }},                                             
                    ],
                    enumNameHint: "LightTypes",
                },      
                { type: "boolean", title: {"en" : "Pivot Light Constant On Kit", "es": "Kit Prendido Cte."}, fieldPath: "Options.PivotLightRAMSConstantOn"},
                {
                    type: "card",
                    title: "Power Supply",
                    children: [
                        {
                            type: "choice",
                            fieldPath: "ControlPanel.ElectricalFrequency",
                            enumNameHint: 'ElectricalFrequencies',
                            options: [
                                { id: ElectricalFrequencies.a50, title: "50Hz" },
                                { id: ElectricalFrequencies.a60, title: "60Hz" }
                            ],
                            title: { en: "Electrical Frequency", es: "Frecuencia Eléctrica" },
                            validate: false,
                            hook: (value: string, sys: ISystemBase) => {
                                if (value === ElectricalFrequencies.a60) {                         
                                    return [
                                        { fieldPath: "ControlPanel.SourceVoltage", value: 480 },      
                                        { fieldPath: "ControlPanel.MinVoltage", value: 440 },                                     
                                    ]
                                }
                                if (value === ElectricalFrequencies.a50) {
                                    return [
                                        { fieldPath: "ControlPanel.SourceVoltage", value: 380 },   
                                        { fieldPath: "ControlPanel.MinVoltage", value: 360 },                                            
                                    ]
                                }
                            }
                        }
                    ],
                },
                {
                    type: "booleangroup",
                    layoutHint: "secondary",
                    visible: (system: ISystemBase) => system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator && system.Lateral.CanalFeed.Intake === IntakeTypes.SelfCleaningScreen,
                    components: [
                        { type: "boolean", title: "Booster Pump - Self Cleaning Screen", fieldPath: "Lateral.CanalFeed.BoosterPumpSelfCleaningScreen"},
                    ],
                },
            ],
        },
    ],
    rules: [
        {
            apply: (system, user, grower, quick) =>
                ([ CanalFeedGenerators.MeccAlte_05_0_KW, CanalFeedGenerators.MeccAlte_07_5_KW, CanalFeedGenerators.MeccAlte_18_0_KW,
                    CanalFeedGenerators.MeccAlte_25_0_KW, CanalFeedGenerators.MeccAlte_27_0_KW, CanalFeedGenerators.MeccAlte_30_0_KW
                ].indexOf(system.Lateral?.CanalFeed?.Generator) !== -1) ? [{
                id: "roeLegacy.CanalFeedGeneratorsOk.1",
                description: "Selected canal feed generator is not supported.",
                severity: "error",
                targets: [
                    "Lateral.CanalFeed.Generator"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                ([CanalFeedGenerators.a20KW, CanalFeedGenerators.MeccAlte_10_0_KW, CanalFeedGenerators.MeccAlte_15_0_KW].indexOf(system.Lateral?.CanalFeed?.Generator) !== -1
                    && [Pumps.a10RB].indexOf(system.Lateral?.CanalFeed?.Pump) !== -1) ? [{
                id: "roeLegacy.CanalFeedGeneratorsOk.2",
                description: "Selected canal feed generator is not supported with selected pump.",
                severity: "error",
                targets: [
                    "Lateral.CanalFeed.Generator"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) && 
                    !system.Lateral.Tires.OneTireMountedInReverse ? [{
                id: "roeLegacy.TireReversedAvail.1",
                description: "One tire mounted in reverse invalid.",
                severity: "error",
                targets: [
                    "Lateral.Tires.OneTireMountedInReverse"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                ([CanalFeedEngines.Dom64].indexOf(system.Lateral?.CanalFeed?.Engine) !== -1
                    && [Pumps.a5YBH].indexOf(system.Lateral?.CanalFeed?.Pump) !== -1) ? [{
                id: "roeLegacy.PumpOK.1",
                description: "Selected canal feed pump is not supported with selected engine.",
                severity: "error",
                targets: [
                    "Lateral.CanalFeed.Pump"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                ([Pumps.a10RB].indexOf(system.Lateral?.CanalFeed?.Pump) !== -1 && !user.dealership.international) ? [{
                id: "roeLegacy.PumpOK.2",
                description: "Selected pump is only available to international dealers.",
                severity: "error",
                targets: [
                    "Lateral.CanalFeed.Pump"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                ([Pumps.a10RB].indexOf(system.Lateral?.CanalFeed?.Pump) !== -1 && [undefined, WaterFeedTypes.CenterFeed].indexOf(system.Lateral?.WaterFeed) === -1) ? [{
                id: "roeLegacy.PumpOK.3",
                description: "Selected pump only supports center feed systems.",
                severity: "error",
                targets: [
                    "Lateral.CanalFeed.Pump"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                ([Pumps.a10RB].indexOf(system.Lateral?.CanalFeed?.Pump) !== -1 && [undefined, CartTypes.Standard].indexOf(system.Lateral?.CanalFeed?.Cart) === -1) ? [{
                id: "roeLegacy.PumpOK.4",
                description: "Selected pump only supports standard cart type.",
                severity: "error",
                targets: [
                    "Lateral.CanalFeed.Pump"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                ([Pumps.a10RB].indexOf(system.Lateral?.CanalFeed?.Pump) !== -1 && system.Lateral?.ButterflyValves) ? [{
                id: "roeLegacy.PumpOK.5",
                description: "Selected pump does not support butterfly valves.",
                severity: "error",
                targets: [
                    "Lateral.CanalFeed.Pump"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                ([Pumps.a10RB].indexOf(system.Lateral?.CanalFeed?.Pump) !== -1 && [undefined, CanalFeedGenerators.a25KW, CanalFeedGenerators.a30KW].indexOf(system.Lateral?.CanalFeed?.Generator) === -1) ? [{
                id: "roeLegacy.PumpOK.6",
                description: "Selected pump does not support selected canal feed generator.",
                severity: "error",
                targets: [
                    "Lateral.CanalFeed.Pump"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) => system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator
                || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && !user.permissions.enableLateral ? [{
                id: "RadioDenied",
                description: "Ordering of lateral systems is not currently activated for your dealership.",
                severity: "denied",
                targets: []
            }] : []
        },
    ] as IRule[],
};