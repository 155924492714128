import { Feature, Polygon } from "@turf/turf";
import { createNewObstacleAction } from "../../../../actions/NewObstacleAction";
import IAuthState from "../../../../auth/IAuthState";
import { IFieldSettings } from "../../../../components/DealerSettingsDialog/FieldSettings";
import IDbProject from "../../../../db/IDbProject";


export const handleObstacle =  (
    features: Feature[],
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
    fieldSettings: IFieldSettings,
) => {
    if (features.length !== 1 && features[0].geometry.type !== "Polygon") {
        throw new Error(`Unexpected feature created`);
    }
    dbPrj.pushAction(createNewObstacleAction(
        layoutId,
        {
            polygon: features[0].geometry as Polygon,
            clearance: fieldSettings.minObstacleClearanceFeet
        },
        authState
    ));
}
