/* 
    SUPERCEEDED, not removed so to not crash any DB relient on this action
    USE: DeleteSpanTowerV2
*/

import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { SideEnum, getSide } from 'rdptypes/helpers/SideEnum';
import { ISpan } from 'rdptypes/project/Types';
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';
import { renumberSpanAndTowers, switchSidesIfNeeded } from './helpers/spans';

const actionTypeId = "DeleteSpanTower";

interface ActionData extends IActionData {
    layoutId: string;
    systemId: string;
    side: SideEnum;
    spanNumber: number;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const sys = state.layouts[data.layoutId].systems[data.systemId];
    const side = getSide(sys, data.side);

    const spans = side.Span;

    let endBoom: ISpan | undefined;
    if (spans.length !== 0 && spans[spans.length - 1].EndBoom) {
        endBoom = spans.pop();
    }    
    let swingArm: ISpan | undefined;
    if (spans.length !== 0 && spans[spans.length - 1].SwingArm) {
        swingArm = spans.pop();
    }
    const towers = side.Tower;

    const spanAndTowerIndex = spans.findIndex(x => x.SpanNumber === data.spanNumber);

    if (spanAndTowerIndex === -1) return;

    spans.splice(spanAndTowerIndex, 1);
    towers.splice(spanAndTowerIndex, 1);

    if (swingArm) {
        spans.push(swingArm);
    }
    if (endBoom) {
        spans.push(endBoom);
    }

    renumberSpanAndTowers(side);
    switchSidesIfNeeded(sys);
    
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});

export const createDeleteSpanTowerAction = (
    layoutId: string,
    systemId: string,
    side: SideEnum,
    spanNumber: number,
    authState: IAuthState) => createAction(
        actionTypeId,
        {
            layoutId,
            systemId,
            side,
            spanNumber
        } as ActionData,
        authState);