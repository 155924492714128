import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import { FC } from "react";
import { Interaction } from "../../interactionState";

export type ICloseObstacleResponse = {
    interaction: Interaction.DrawObstacle | Interaction.DrawWheelObstacle;
    message: string;
};
interface Props {
    onClose: (args: ICloseObstacleResponse) => void;
    onCancel: () => void;
    open: boolean;
}

enum ObstacleTypes {
    Span,
    Wheel,
}

const AddObstacleDialog: FC<Props> = (props) => {
    const handleClick = (type: ObstacleTypes) => {
        let response: ICloseObstacleResponse | null = null;
        switch (type) {
            case ObstacleTypes.Span:
                response = {
                    interaction: Interaction.DrawObstacle,
                    message: "Please draw the obstacle polygon by clicking on the corners in sequence",
                };
                break;
            case ObstacleTypes.Wheel:
                response = {
                    interaction: Interaction.DrawWheelObstacle,
                    message: "Please draw the wheel obstacle polygon by clicking on the corners in sequence",
                };
                break;
        }
        if (response) {
            props.onClose(response);
        }
    };

    const ns = "map-display.";

    return (
        <Dialog onClose={() => props.onCancel()} open={props.open}>
            <DialogTitle>{i18next.format(`${i18next.t(ns + "select-obstacle-type")}`, 'capitalize-each')}</DialogTitle>
            <List sx={{ pt: 0 }}>
                <ListItem>
                    <ListItemButton autoFocus onClick={() => handleClick(ObstacleTypes.Span)}>
                        <ListItemText sx={{ textAlign: "center" }} primary={i18next.format(`${i18next.t("span")}`, 'capitalize-each')} />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton autoFocus onClick={() => handleClick(ObstacleTypes.Wheel)}>
                        <ListItemText sx={{ textAlign: "center" }} primary={i18next.format(`${i18next.t("wheel")}`, 'capitalize-each')} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Dialog>
    );
};

export default AddObstacleDialog;
