
export const createTimerLog = (id: string = "time") => {
    const t = Date.now();
    let d: number = t;
    return {
      logS: () => {
        console.log(id + ":", Math.round((Date.now() - t) / 1000 * 10) / 10,"s");
      },
      logMS: () => {
        console.log(id + ":", (Date.now() - t),"ms");
      },
      logDeltaMS: (subtitle: string) => {
        const now = Date.now();
        console.log(id + ":", subtitle, ":", (now - d),"ms");
        d = now;
      }
    }
  }