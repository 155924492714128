export const spanLengthsFeet = [
    80,
    99,
    118,
    120,
    137,
    140,
    156,
    160,
    175,
    180,
    186.7,
    194,
    204,
    213
];