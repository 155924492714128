import { EndGunAcresMethods, EndGunTypes, ISystemBase, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { AcresToHa, ConvertToAcres, EstEndGunCoverage, FloatsAreEqual, RadiusToAcres } from "./CommonFunctions";
import * as othf from "./OtherHelpers";
import * as sidef from "./SideFunctions";
import * as spanf from "./SpanFunctions";
import * as sysf from "./SystemFunctions";
import { EndGun } from "./Types";

export interface IValuesFromFieldDesign {
    systemAcres: number,
    irrigatedAcres: number,
    irrigatedAcresFullEndGun: number,
    endGunAcres: number
}

export default class Acres {
    public SystemAcres = 0;
    public IrrigatedAcres = 0;
    public IrrigatedAcresFullEndGun = 0;
    public EndGunAcres = 0;

    private IsMaxi = false;
    private HasESP = false;
    private PercentCircle = 0;
    private FlangedParentCoverage = 0; // This is the coverage of the parent system, including last sprinkler device. 
    private FlangedEndGun: EndGun;

    constructor(System: ISystemBase, valuesFromFieldDesign: IValuesFromFieldDesign) {

        //  -Beginning of System
        //  -Length = Length of pipe
        //  -Radius = Length of arc created by pipe, not water
        //  -Coverage = Length of arc created by water  

        this.IsMaxi = sysf.IsMaxigator(System)
        this.HasESP = sysf.HasEnergySaverPackage(System)

        if (valuesFromFieldDesign){
            this.SystemAcres = valuesFromFieldDesign.systemAcres;
            this.IrrigatedAcres = valuesFromFieldDesign.irrigatedAcres;
            this.IrrigatedAcresFullEndGun = valuesFromFieldDesign.irrigatedAcresFullEndGun;
            this.EndGunAcres = valuesFromFieldDesign.endGunAcres;
            
            return;
        }

        const FlangedSide = System.FlangedSide
        this.FlangedEndGun = FlangedSide.EndOfSystem.EndGun

        let FlangedParentRadius = 0; //This is the radius of the parent system
        let FlangedTotalRadius = 0; //This is the radius of the entire system. 

        if (sidef.NumberOfSpans(FlangedSide) === 0) return;

        FlangedParentRadius = spanf.EndingRadius(System, FlangedSide, FlangedSide.Span[sidef.NumberOfSpans(FlangedSide) - 1])
        FlangedTotalRadius = spanf.EndingRadius(System, FlangedSide, FlangedSide.Span[FlangedSide.Span.length - 1])


        let FlangedEndGunCoverage = 0; //This is the coverage of only the end gun  (End Gun Radius = End Gun Coverage)
        let FlangedSystemCoverage = 0; //This is the coverage of the entire system, without end gun
        let FlangedTotalCoverage = 0; //This is the coverage of the entire system, with end gun
        
        let ips = FlangedSide.SprinklerChart?.IrrigationProperties ?? undefined;

        if (sysf.FieldSets(System).SprinklerChart.DataValid() && ips.EndGunIrrigationProperties && 
            typeof ips.CoverageRadiusWithoutSwingArm !== "undefined" && 
            typeof ips.CoverageRadiusWithoutEndGun !== "undefined" &&
            typeof ips.CoverageRadiusWithEndGun !== "undefined") {
            // FlangedEOSSprinklerCoverage = FlangedSide.SprinklerChart.IrrigationProperties.CoverageRadiusWithoutEndGun - FlangedTotalRadius
            FlangedEndGunCoverage = ips.EndGunIrrigationProperties.Radius
            this.FlangedParentCoverage = ips.CoverageRadiusWithoutSwingArm
            FlangedSystemCoverage = ips.CoverageRadiusWithoutEndGun
            FlangedTotalCoverage = ips.CoverageRadiusWithEndGun
        } else { //These are estimates
            const FlangedEOSSprinklerCoverage = 5 //This is the coverage of the last sprinker device beyond the end of the pipe
            //FlangedEOSSprinklerCoverage = 5
            if (sysf.FieldSets(System).FlangedEndOfSystem.DataValid()) {
                FlangedEndGunCoverage = EstEndGunCoverage(this.FlangedEndGun.EndGunTypePrimary, false)
            }
            this.FlangedParentCoverage = FlangedParentRadius + FlangedEOSSprinklerCoverage
            FlangedSystemCoverage = FlangedTotalRadius + FlangedEOSSprinklerCoverage
            FlangedTotalCoverage = FlangedTotalRadius + (FloatsAreEqual(FlangedEndGunCoverage, 0) ? FlangedEOSSprinklerCoverage : FlangedEndGunCoverage)
        }

        const FlexSide = System.FlexSide
        const FlexEndGun = FlexSide.EndOfSystem.EndGun

        //    let FlexParentRadius: number //This is the radius of the parent system
        let FlexTotalRadius = 0;//This is the radius of the entire system. 
        if (sidef.NumberOfSpans(FlexSide) > 0) {
            //     FlexParentRadius = FlexSide.Span(FlexSide.NumberOfSpans).EndingRadius
            FlexTotalRadius = spanf.EndingRadius(System, FlexSide, FlexSide.Span[FlexSide.Span.length - 1])
        }

        //    let FlexParentCoverage: number //This is the coverage of the parent system, including last sprinkler device. 
        let FlexSystemCoverage = 0; //This is the coverage of the entire system, without end gun
        let FlexTotalCoverage = 0; //This is the coverage of the entire system, with end gun

        ips = FlexSide.SprinklerChart?.IrrigationProperties ?? undefined;

        if (sysf.IsDualSided(System)) {
            if (sysf.FieldSets(System).SprinklerChart.DataValid() && ips.EndGunIrrigationProperties && 
                typeof ips.CoverageRadiusWithoutSwingArm !== "undefined" && 
                typeof ips.CoverageRadiusWithoutEndGun !== "undefined" &&
                typeof ips.CoverageRadiusWithEndGun !== "undefined") {
                    //   FlexEOSSprinklerCoverage = .CoverageRadiusWithoutEndGun - FlexTotalRadius
                    //   FlexEndGunCoverage = .EndGunIrrigationProperties.Radius
                    //         FlexParentCoverage = .CoverageRadiusWithoutSwingArm
                    FlexSystemCoverage = FlexSide.SprinklerChart.IrrigationProperties.CoverageRadiusWithoutEndGun
                    FlexTotalCoverage = FlexSide.SprinklerChart.IrrigationProperties.CoverageRadiusWithEndGun
            } else { //These are estimates
                let FlexEOSSprinklerCoverage = 0; //This is the coverage of the last sprinker device beyond the end of the pipe
                let FlexEndGunCoverage = 0; //This is the coverage of only the end gun  (End Gun Radius = End Gun Coverage)
                FlexEOSSprinklerCoverage = 5
                if (sysf.FieldSets(System).FlexEndOfSystem.DataValid()) {
                    FlexEndGunCoverage = EstEndGunCoverage(FlexEndGun.EndGunTypePrimary, false)
                }
                //      FlexParentCoverage = FlexParentRadius + FlexEOSSprinklerCoverage
                FlexSystemCoverage = FlexTotalRadius + FlexEOSSprinklerCoverage
                FlexTotalCoverage = FlexTotalRadius + (FloatsAreEqual(FlexEndGunCoverage, 0) ? FlexEOSSprinklerCoverage : FlexEndGunCoverage)
            }
        }

        //*************************************

        let SACCornerAcres= 0;
        let SACSideAcres= 0;
        let SACEndGunCornerAcres= 0;
        let SACEndGunSideAcres= 0;
        if (sysf.HasSwingArmCorner(System)) {
            SACCornerAcres = (this.TheDarinNeffCornerEquation(FlangedSystemCoverage, this.FlangedParentCoverage) - (Math.PI * Math.pow(this.FlangedParentCoverage,2)) / 4) / 43560
            SACSideAcres = this.TheDarinNeffSideEquation(FlangedSystemCoverage, this.FlangedParentCoverage) / 43560
            SACEndGunCornerAcres = (this.TheDarinNeffCornerEquation(FlangedTotalCoverage, this.FlangedParentCoverage) - this.TheDarinNeffCornerEquation(FlangedTotalRadius, this.FlangedParentCoverage)) / 43560
            SACEndGunSideAcres = (this.TheDarinNeffSideEquation(FlangedTotalCoverage, this.FlangedParentCoverage) - this.TheDarinNeffSideEquation(FlangedTotalRadius, this.FlangedParentCoverage)) / 43560
        }

        if (this.IsMaxi) {
            if (sysf.FieldSets(System).Guidance.DataValid()) {
                //SystemAcres = FlangedSystemCoverage
                //IrrigatedAcres = FlangedTotalCoverage
                //If System.IsDualSided() Then
                //  SystemAcres += FlexSystemCoverage
                //  IrrigatedAcres += FlexTotalCoverage
                //End If
                //SystemAcres *= System.Lateral.LengthOfField / 43560
                //IrrigatedAcres *= System.Lateral.LengthOfField / 43560
                //IrrigatedAcresFullEndGun = IrrigatedAcres
                //EndGunAcres = IrrigatedAcres - SystemAcres

                //System Acres
                this.SystemAcres = FlangedSystemCoverage * System.Lateral.LengthOfField / 43560
                if (sysf.IsDualSided(System)) {
                    this.SystemAcres += FlexSystemCoverage * System.Lateral.LengthOfField / 43560
                }
                this.IrrigatedAcres = this.SystemAcres
                this.IrrigatedAcresFullEndGun = this.SystemAcres

                //IrrigatedAcresFullEndGun
                let FlangedEndGunAcres= 0;
                let FlexEndGunAcres= 0;
                FlangedEndGunAcres = (FlangedTotalCoverage - FlangedSystemCoverage) * System.Lateral.LengthOfField / 43560
                if (sysf.IsDualSided(System)) {
                    FlexEndGunAcres = (FlexTotalCoverage - FlexSystemCoverage) * System.Lateral.LengthOfField / 43560
                }
                this.IrrigatedAcresFullEndGun += FlangedEndGunAcres + FlexEndGunAcres

                //IrrigatedAcres
                if (sysf.FieldSets(System).FlangedEndOfSystem.DataValid()) {
                    if (othf.EndGunAcresMethod(this.FlangedEndGun) === EndGunAcresMethods.Precision) {
                        FlangedEndGunAcres *= othf.PercentOfArc(this.FlangedEndGun) / 100
                    }
                    if (sysf.IsDualSided(System)) {
                        if (othf.EndGunAcresMethod(FlexEndGun) === EndGunAcresMethods.Precision) {
                            FlexEndGunAcres *= othf.PercentOfArc(FlexEndGun) / 100
                        }
                    }
                    this.IrrigatedAcres += FlangedEndGunAcres + FlexEndGunAcres

                    //EndGunAcres
                    this.EndGunAcres = this.IrrigatedAcres - this.SystemAcres
                }
            }
        } else {
            this.SystemAcres = RadiusToAcres(this.FlangedParentCoverage)
            this.IrrigatedAcres = this.SystemAcres
            this.IrrigatedAcresFullEndGun = RadiusToAcres(FlangedTotalCoverage)
            if (this.IrrigatedAcres > 0) {
                if (sysf.HasSwingArmCorner(System)) {
                    if (this.FlangedEndGun.EndGunTypePrimary ?? EndGunTypes.None !== EndGunTypes.None) {
                        this.EndGunAcres = othf.CornersWatered(this.FlangedEndGun) * SACEndGunCornerAcres + othf.SidesWatered(this.FlangedEndGun) * SACEndGunSideAcres
                        this.IrrigatedAcres += this.EndGunAcres
                    }
                    this.IrrigatedAcres += othf.CornersWatered(this.FlangedEndGun) * SACCornerAcres + othf.SidesWatered(this.FlangedEndGun) * SACSideAcres
                } else {
                    if (FlangedEndGunCoverage > 0) { //Has End Gun
                        let ip: number = Math.sqrt(Math.pow(FlangedTotalCoverage, 2) - Math.pow(this.FlangedParentCoverage, 2));
                        let EndGunCornerAcres: number = (((((Math.PI / 4) - Math.atan(ip / this.FlangedParentCoverage)) * Math.pow(FlangedTotalCoverage, 2)) + (ip * this.FlangedParentCoverage)) / 43560) - (RadiusToAcres(this.FlangedParentCoverage) / 4);
                        let x = RadiusToAcres(FlangedTotalCoverage);
                        let EndGunSideAcres: number = (RadiusToAcres(FlangedTotalCoverage) / 4) - EndGunCornerAcres - (this.SystemAcres / 4);
                        switch (othf.EndGunAcresMethod(this.FlangedEndGun)) {
                            case EndGunAcresMethods.Traditional:
                                this.EndGunAcres = othf.CornersWatered(this.FlangedEndGun) * EndGunCornerAcres + othf.SidesWatered(this.FlangedEndGun) * EndGunSideAcres
                                break;
                            case EndGunAcresMethods.Precision:
                                this.EndGunAcres = othf.PercentOfArc(this.FlangedEndGun) / 100 * (4 * EndGunCornerAcres + 4 * EndGunSideAcres)
                                break;
                        }
                        this.IrrigatedAcres += this.EndGunAcres
                    }
                }
            }
            switch (System.SystemProperties.SystemType) {
                case SystemTypes.CenterPivot:
                case SystemTypes.KwikTow:
                    this.PercentCircle = othf.PercentOfCircle(System) / 100
                    break;
                default:
                    this.PercentCircle = 1
                    break;
            }
            this.SystemAcres *= this.PercentCircle
            this.EndGunAcres *= this.PercentCircle
            this.IrrigatedAcres *= this.PercentCircle
            this.IrrigatedAcresFullEndGun *= this.PercentCircle
        }
    }

    public GPMPerAcreGivenGPM = (GPM: number, units: "gpm" | "cmh"): number => {
        let GPMPerAcre= 0;

        if (this.IsMaxi) {
            let MaxiAcres: number = this.IrrigatedAcresFullEndGun
            if (FloatsAreEqual(MaxiAcres, 0)) return 0
            if (units === "cmh"){
                MaxiAcres = AcresToHa(MaxiAcres);
            }
            GPMPerAcre = GPM / MaxiAcres
        } else {
            //     SquareFeet = PercentCircle * RadiusToArea(FlangedTotalCoverage)
            let TotalAcres: number = this.IrrigatedAcresFullEndGun
            if (units === "cmh"){
                TotalAcres = AcresToHa(TotalAcres);
            }
            if (FloatsAreEqual(TotalAcres, 0)) return 0
            if (this.HasESP) {
                let ParentAcres: number = this.SystemAcres
                let ExtendPercent= 0;
                if (this.FlangedEndGun.EndGunTypePrimary ?? EndGunTypes.None !== EndGunTypes.None) {
                    ExtendPercent = othf.CornersWatered(this.FlangedEndGun) * 0.1 + othf.SidesWatered(this.FlangedEndGun) * 0.15
                }
                //        let retract: number = 1 - extend
                //GPMPerSF = (GPM / ParentSquareFeet) * retract + (GPM / TotalSquareFeet) * extend
                // BRB TODO, if ESP is reintroduced in the future, then check this deals with hectares correctly
                GPMPerAcre = GPM * ((1 - ExtendPercent) / ParentAcres + (ExtendPercent / TotalAcres))
            } else {
                GPMPerAcre = GPM / TotalAcres
            }
        }
        return GPMPerAcre
    }

    public GPMGivenGPMPerAcre = (GPMPerAcre: number, units: "gpm" | "cmh"): number => {
        let GPM= 0;
        if (this.IsMaxi) {
            let MaxiAcres: number = this.IrrigatedAcresFullEndGun
            if (units === "cmh"){
                MaxiAcres = AcresToHa(MaxiAcres);
            }
            if (FloatsAreEqual(MaxiAcres, 0)) return 0
            GPM = GPMPerAcre * MaxiAcres
        } else {
            //     SquareFeet = PercentCircle * RadiusToAcres(FlangedTotalCoverage)
            let TotalAcres: number = this.IrrigatedAcresFullEndGun
            if (units === "cmh"){
                TotalAcres = AcresToHa(TotalAcres);
            }
            if (FloatsAreEqual(TotalAcres, 0)) return 0
            if (this.HasESP) {
                let ParentAcres: number = this.SystemAcres
                let ExtendPercent= 0;
                if (this.FlangedEndGun.EndGunTypePrimary ?? EndGunTypes.None !== EndGunTypes.None) {
                    ExtendPercent = othf.CornersWatered(this.FlangedEndGun) * 0.1 + othf.SidesWatered(this.FlangedEndGun) * 0.15
                }
                GPM = GPMPerAcre / ((1 - ExtendPercent) / ParentAcres + (ExtendPercent / TotalAcres))
            } else {
                GPM = GPMPerAcre * TotalAcres
            }
        }
        return GPM
    }

    private Radians = (Degrees: number): number => {
        return Degrees * Math.PI / 180
    }


    private TheDarinNeffCornerEquation = (a: number, b: number): number => {
        return (Math.sqrt(Math.pow(a,2) - Math.pow(b,2)) * b) + ((this.Radians(90) - 2 * Math.acos(b / a)) / 2 * Math.pow(a,2))
    }

    private TheDarinNeffSideEquation = (a: number, b: number): number => {
        return (Math.pow(a,2) * Math.acos(b / a)) - (b * Math.sqrt(Math.pow(a,2) - Math.pow(b,2)))
    }


    public HoursToApplyAnInch = (GPM: number): number => {
        let dHai: number;

        // In the old ROE code, GPMPerAcreGivenGPM used to sometimes return 
        // GPM per hectare instead, depending on region settings. And this function
        // used to include a check whether it needed to be converted back to acres.
        // This logic is no longer needed because now we tell GPMPerAcre whether we want
        // hectares or acres returned.
        dHai = 1 / this.GPMPerAcreGivenGPM(GPM, "gpm");

        //Convert from Minutes to apply an acre to Hours to apply a Cubic Inch
        return dHai / (ConvertToAcres(1) * 100 * 231 * 60)
    }

}