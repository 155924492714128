// Note: JSTS does not import well
// importing and adding the type leads to a usable version of jsts with typings
import type * as jstsTypes from "jsts";
import * as jstsImport from "jsts/dist/jsts.min.js";

import * as turf from "@turf/turf";
import { MetersProjectionHelper } from "../projection";

// from turf
const coordinateFromTurfPosition = (turfPosition: turf.Position, metersProjectionHelper: MetersProjectionHelper): jsts.geom.Coordinate => {
    const projected = metersProjectionHelper.wgs84ToMeters(turfPosition[0], turfPosition[1]);
    return new jsts.geom.Coordinate(projected[0], projected[1]);
}
const linearRingFromTurfPositions = (turfPositions: turf.Position[], metersProjectionHelper: MetersProjectionHelper): jsts.geom.LinearRing => {
    return new jsts.geom.GeometryFactory().createLinearRing(turfPositions.map(x => coordinateFromTurfPosition(x, metersProjectionHelper)));
}
const polygonFromTurfPolygon = (turfPolygon: turf.Polygon, metersProjectionHelper: MetersProjectionHelper): jsts.geom.Polygon => {
    const [ outerRing, ...innnerRings ] = turfPolygon.coordinates.map(x => linearRingFromTurfPositions(x, metersProjectionHelper));
    return new jsts.geom.GeometryFactory().createPolygon(outerRing, innnerRings);        
}
const multiPolygonFromTurfPolygons = (turfPolygons: turf.Polygon[], metersProjectionHelper: MetersProjectionHelper): jsts.geom.MultiPolygon => {
    return new jsts.geom.GeometryFactory().createMultiPolygon(turfPolygons.map(x => polygonFromTurfPolygon(x, metersProjectionHelper)));
}

// to turf
const coordinateToTurfPosition = (coordinate: jsts.geom.Coordinate, metersProjectionHelper: MetersProjectionHelper): turf.Position => {
    const projected = metersProjectionHelper.metersToWgs84(coordinate.x, coordinate.y);
    return projected;
}
const linearRingToTurfPositions = (linearRing: jsts.geom.LinearRing, metersProjectionHelper: MetersProjectionHelper): turf.Position[] => {
    const projected = linearRing.getCoordinates().map(x => coordinateToTurfPosition(x, metersProjectionHelper));
    return projected;
}
const polygonToTurfPositions = (polygon: jsts.geom.Polygon, metersProjectionHelper: MetersProjectionHelper): turf.Position[][] => {
    const outerRing = polygon.getExteriorRing();
    const innerRings: jsts.geom.LinearRing[] = [];
    for (let i = 0; i < polygon.getNumInteriorRing(); i++) {
        innerRings.push(polygon.getInteriorRingN(i));
    }
    const projectedPositions = [
        linearRingToTurfPositions(outerRing, metersProjectionHelper),
        ...innerRings.map(x => linearRingToTurfPositions(x, metersProjectionHelper))
    ];
    const projected = turf.polygon(projectedPositions).geometry.coordinates;
    return projected;
}
const multiPolygonToTurfPositions = (multiPolygon: jsts.geom.MultiPolygon, metersProjectionHelper: MetersProjectionHelper): turf.Position[][][] => {
    const polygons: jsts.geom.Polygon[] = [];
    for (let i = 0; i < multiPolygon.getNumGeometries(); i++) {
        polygons.push(multiPolygon.getGeometryN(i) as jsts.geom.Polygon);
    }
    const projected = turf.multiPolygon(polygons.map(x => polygonToTurfPositions(x, metersProjectionHelper))).geometry.coordinates;
    return projected;
}
const geometryToTurfPositions = (geometry: jsts.geom.Geometry, metersProjectionHelper: MetersProjectionHelper): turf.Position[][][] => {
    switch (geometry.getGeometryType()) {
        case 'Polygon':
            return [ polygonToTurfPositions(geometry as jsts.geom.Polygon, metersProjectionHelper) ];
        case 'MultiPolygon':
            return multiPolygonToTurfPositions(geometry, metersProjectionHelper);
        default:
            throw new Error("Not yet implemented for type: " + geometry.getGeometryType());
    }
}

export const jsts = jstsImport as typeof jstsTypes;
export const extensions = {
    coordinateFromTurfPosition,
    multiPolygonFromTurfPolygons,
    geometryToTurfPositions
}
