import { Image } from '@react-pdf/renderer';
import * as React from "react";
import { FC } from "react";
import Logo from "../../assets/blue.png";

interface Props {
    style: any;
}

const ReinkeLogo: FC<Props> = (props) => {
    //TODO: register fonts and fontweights

    return <Image source={Logo} style={props.style}/>;
};

export default ReinkeLogo;