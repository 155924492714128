import { getEndOfSystemIfValid } from "rdptypes/helpers/EndOfSystem";
import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { EndOfSystemTypes, SwingArmLengths } from "rdptypes/project/ISystemBase.AutoGenerated";
import { swingArmLengthsFeet } from "rdptypes/reinkeComponents";
import IEndOfSystemTypeChoice from "rdptypes/roe/IEndOfSystemTypeChoice";
import { IRuleResult } from "rdptypes/roe/IRule";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import * as sysf from "roedata/roe_migration/SystemFunctions";
import { strLanguageText } from "roedata/roe_migration/TranslationsLib";
import { EndOfSystemOK } from "roedata/roe_migration/Valids.Valids";
import { getEndBoomLengths } from "../../../../reinkeComponentConversions/endBoomLengths";
import { FieldValidationResult, IValidationContext } from "../../validation/SystemValidator";
import IComponentRenderContext from "../IComponentRenderContext";

export interface IEndOfSystemValidationResult {
    endOfSystemType?: FieldValidationResult;
    endBoomLength?: FieldValidationResult;
    swingArmLength?: FieldValidationResult;
}

const getDummyFieldPathForEndOfSystemType = (side: SideEnum) => `dummy.endOfSystem.${side === SideEnum.Flanged ? "Flanged" : "Flex"}.endOfSystemType`;
const getDummyFieldPathForEndBoomLength = (side: SideEnum) => `dummy.endOfSystem.${side === SideEnum.Flanged ? "Flanged" : "Flex"}.endBoomLength`;
const getDummyFieldPathForSwingArmLength = (side: SideEnum) => `dummy.endOfSystem.${side === SideEnum.Flanged ? "Flanged" : "Flex"}.swingArmLength`;

const ruleResultFilter = (dummyFieldPath: string, ruleResult: IRuleResult) => {
    return ruleResult.severity === "error" && ruleResult.targets.indexOf(dummyFieldPath) !== -1;
}

const validateEndOfSystemTypes = (cmp: IEndOfSystemTypeChoice, ctx: IValidationContext): FieldValidationResult => {
    const fvr = ctx.result.getField(getDummyFieldPathForEndOfSystemType(cmp.side));
    fvr.pageIds.add(ctx.pageId);
    const sys = ctx.system;

    for (const eosType of [EndOfSystemTypes.EndBoom, EndOfSystemTypes.SAC]) {
        const eosOK = EndOfSystemOK(
            eosType, 
            { 
                SwingArmAllowed: sysf.SwingArmAllowed(sys), 
                SystemType: sys.SystemProperties.SystemType
            }
        );
        if (!eosOK) {
            fvr.badValues.set(eosType, { reasons: [ strLanguageText("sltEndOfSystem") ] });
        }
    }
    
    const side = getSide(sys, cmp.side);
    if (!side.EndOfSystem.EndOfSystemType) {
        fvr.severity = 'notset';
    }
    else if (fvr.badValues.has(side.EndOfSystem.EndOfSystemType)) {
        fvr.severity = 'error';
    }
    return fvr;
}

const validateEndBoomLength = (cmp: IEndOfSystemTypeChoice, ctx: IValidationContext): FieldValidationResult => {
    const dummyFieldPath = getDummyFieldPathForEndBoomLength(cmp.side);

    const fvr = ctx.result.getField(dummyFieldPath);
    fvr.pageIds.add(ctx.pageId);
    const sys = ctx.system;
    const side = getSide(sys, cmp.side);

    if (side.EndOfSystem?.EndOfSystemType === EndOfSystemTypes.EndBoom) {        
        const currentValue = getEndOfSystemIfValid(sys, cmp.side)?.endBoomLength;

        if (currentValue === undefined) {
            fvr.severity = "notset";
        }

        const sysClone = structuredClone(sys);
        const clonedSide = getSide(sysClone, cmp.side);
        if (clonedSide.Span.length && clonedSide.Span.slice(-1)[0].EndBoom) {
            // remove it
            clonedSide.Span.splice(-1, 1);
        }
        // no endboom length
        const qc = new QuoteClass(sysClone);
        const eosQc = cmp.side === SideEnum.Flanged ? qc.RightEOSClass : qc.LeftEOSClass;
        eosQc.DataOK(null, null, null);
        // console.log("qc", 0, qc.ruleResults);

        const filteredRules = qc.ruleResults.filter(x => ruleResultFilter(dummyFieldPath, x));
        if (filteredRules.length) {
            fvr.badValues.set(0, { reasons: filteredRules.map(x => x.description) });
        }

        getEndBoomLengths("feet").forEach(ebl => {

            const sysCloneClone = structuredClone(sysClone);
            const clonedCloneSide = getSide(sysCloneClone, cmp.side);
            clonedCloneSide.Span.push({
                Length: ebl.originalValue,
                EndBoom: true
            })
            const qc = new QuoteClass(sysCloneClone);
            const eosQc = cmp.side === SideEnum.Flanged ? qc.RightEOSClass : qc.LeftEOSClass;
            eosQc.DataOK(null, null, null);
            // console.log("qc", ebl.originalValue, qc.ruleResults);
            const filteredRules = qc.ruleResults.filter(x => ruleResultFilter(dummyFieldPath, x));
            if (filteredRules.length) {
                fvr.badValues.set(ebl.originalValue, { reasons: filteredRules.map(x => x.description) });
            }
        })

        if (currentValue !== undefined && fvr.badValues.has(currentValue)) {
            fvr.severity = 'error';
        }
    }
    return fvr;
}

const validateSwingArmLength = (cmp: IEndOfSystemTypeChoice, ctx: IValidationContext): FieldValidationResult => {    
    const dummyFieldPath = getDummyFieldPathForSwingArmLength(cmp.side);

    const fvr = ctx.result.getField(dummyFieldPath);
    fvr.pageIds.add(ctx.pageId);
    const sys = ctx.system;
    const side = getSide(sys, cmp.side);

    if (side.EndOfSystem?.EndOfSystemType === EndOfSystemTypes.SAC) {
        const sysClone = structuredClone(sys);
        const clonedSide = getSide(sysClone, cmp.side);
        if (clonedSide.Span.length && clonedSide.Span.slice(-1)[0].EndBoom) {
            // remove it
            clonedSide.Span.splice(-1, 1);
        }
        if (clonedSide.Span.length && clonedSide.Span.slice(-1)[0].SwingArm) {
            // remove it
            clonedSide.Span.splice(-1, 1);
        }
        // const qc = new QuoteClass(sysClone);
        // const eosQc = cmp.side === SideEnum.Flanged ? qc.RightEOSClass : qc.LeftEOSClass;
        // eosQc.DataOK(null, null, null);
        // const filteredRules = qc.ruleResults.filter(ruleResultFilterSwingArmLength);
        // if (filteredRules.length) {
        //     fvr.badValues.set(SwingArmLengths.None, { reasons: filteredRules.map(x => x.description) });
        // }
        
        swingArmLengthsFeet.forEach(swa => {

            const sysCloneClone = structuredClone(sysClone);
            const clonedCloneSide = getSide(sysCloneClone, cmp.side);
            clonedCloneSide.Span.push({
                Length: swa.feet,
                SwingArm: true,
            })
            clonedCloneSide.Span.push({
                Length: swa.enbdoomFeet,
                EndBoom: true
            })
            clonedCloneSide.EndOfSystem.SwingArmLength = swa.value;
            const qc = new QuoteClass(sysCloneClone);
            const eosQc = cmp.side === SideEnum.Flanged ? qc.RightEOSClass : qc.LeftEOSClass;
            eosQc.DataOK(null, null, null);
            // console.log("qc", ebl.originalValue, qc.ruleResults);
            
            const filteredRules = qc.ruleResults.filter(x => ruleResultFilter(dummyFieldPath, x));
            if (filteredRules.filter(x => x.severity === "error").length) {
                fvr.badValues.set(swa.value, { reasons: filteredRules.map(x => x.description) });
            }
        })
        if (!side.EndOfSystem?.SwingArmLength || side.EndOfSystem.SwingArmLength === SwingArmLengths.None) {
            fvr.severity = 'notset';
        } else if (fvr.badValues.has(side.EndOfSystem.SwingArmLength)) {
            fvr.severity = 'error';
        }
    }
    return fvr;
}
export const endOfSystemValidator = (cmp: IEndOfSystemTypeChoice, ctx: IValidationContext): void => {
    validateEndOfSystemTypes(cmp, ctx);
    validateEndBoomLength(cmp, ctx);
    validateSwingArmLength(cmp, ctx);
}

export const getEndOfSystemValidation = (cmp: IEndOfSystemTypeChoice, ctx: IComponentRenderContext): IEndOfSystemValidationResult => {
    return {
        endOfSystemType: ctx.vws.validationResult?.getField(getDummyFieldPathForEndOfSystemType(cmp.side)),
        endBoomLength: ctx.vws.validationResult?.getField(getDummyFieldPathForEndBoomLength(cmp.side)),
        swingArmLength: ctx.vws.validationResult?.getField(getDummyFieldPathForSwingArmLength(cmp.side))
    }
}