import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, Tooltip } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { createNewUpdateProjectPropertyAction } from "../../../../actions/UpdateProjectProperty";
import AuthCtx from "../../../../auth/AuthCtx";
import PositiveConvertedDecimalField from "../../../../components/CustomInputComponents/PositiveConvertedDecimalField";
import DevSettingsCtx from "../../../../db/DevSettingsCtx";
import IDbProject from "../../../../db/IDbProject";
import { DisplayLengthUnitBuilder } from "../../../../helpers/lengths";

interface Props {
    dbPrj: IDbProject;
}

interface IFieldSettingsForm {
    systemClearance: number;
}
const FieldSettingsMenu: React.FC<Props> = ({ dbPrj }) => {

    const authState = React.useContext(AuthCtx);
    const displaySettings = React.useContext(DevSettingsCtx).dealerSettings.display.current;

    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

    const [tempFormState, setTempFormState] = React.useState<IFieldSettingsForm>({
        systemClearance: dbPrj.state.systemClearance
    });

    React.useEffect(() => {
        setTempFormState({
            systemClearance: dbPrj.state.systemClearance
        });
    }, [ dbPrj.state, dialogOpen]);

    const handleOk = () => {
        dbPrj.pushAction(
            createNewUpdateProjectPropertyAction(
                'systemClearance', tempFormState.systemClearance, authState
            )
        )
        setDialogOpen(false);
    }
    const handleCancel = () => {
        setDialogOpen(false);
    }

    return (
        <>
            <Tooltip title={t("field-settings.field-settings")} placement="bottom">
                <IconButton
                    size="large"
                    aria-label={t("field-settings.field-settings")}
                    aria-haspopup="true"
                    onClick={() => setDialogOpen(true)}
                    color="inherit"
                >
                    <PermDataSettingIcon />
                </IconButton>
            </Tooltip>

            <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
                <DialogTitle>{t("field-settings.field-settings")}</DialogTitle>
                <DialogContent dividers>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <PositiveConvertedDecimalField
                            textFieldProps={{
                                label: `${i18next.format(t("field-settings.minimum-system-clearance"), 'capitalize-each')} (${t(DisplayLengthUnitBuilder.shortName(displaySettings.lengths))})`
                            }}
                            value={tempFormState.systemClearance}
                            setValue={(value) => setTempFormState((prev) => ({ ...prev, systemClearance: value }))}
                            originalUnit="feet"
                            displayUnit={displaySettings.lengths}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>{t("cancel")}</Button>
                    <Button onClick={handleOk}>{t("ok")}</Button>
                </DialogActions>
            </Dialog>                    
        </>
    )
}


export default FieldSettingsMenu;