import * as React from "react";
import { FC } from "react";
import { SYSTEM_DESIGN_CONSTANTS } from "../SystemDiagram/constants";

interface IProps {
    heightInches: number;
    elevationFeet: number;
}

const PivotCenter: FC<IProps> = ({ elevationFeet, heightInches }) => {
    const centerPivotElevationBase = elevationFeet * 12;
    const centerPivotElevationTop = heightInches + centerPivotElevationBase;
    const riserWidth = SYSTEM_DESIGN_CONSTANTS.pipe.width;
    const pivotBaseWidth = SYSTEM_DESIGN_CONSTANTS.pivot.baseWidth;

    return (
        <>
            <path 
                d={`
                    M ${-pivotBaseWidth * 0.5} ${centerPivotElevationBase} 
                    L 0 ${centerPivotElevationTop}
                    M ${pivotBaseWidth * 0.5} ${centerPivotElevationBase} 
                    L 0 ${centerPivotElevationTop}
                `} 
                stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
                strokeWidth={riserWidth}
                fill="none"
                strokeLinecap="round" />
            <path 
                d={`
                    M 0 ${centerPivotElevationBase} 
                    L 0 ${centerPivotElevationTop}
                `} 
                stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
                strokeWidth={riserWidth}
                fill="none" />
            <path 
                d={`
                    M 0 ${centerPivotElevationTop} 
                    L ${2 * 12} ${centerPivotElevationTop}
                `} 
                stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
                strokeWidth={riserWidth}
                fill="none" />
        </>
    );
};

export default PivotCenter;