import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, styled } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { useContext } from "react";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import DealerDataCtx from "../../userData/DealerDataCtx";
import CustomDefaultsRadioGroup from "./CustomDefaultsRadioGroup";

const CONSTANTS = {
    title: ''
}

export interface ILogoSettingsFormState {
    logo: string;
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const TabBody: React.FC = () => {
    const user = useContext(DealerDataCtx);
    const devSettingsState = useContext(DevSettingsCtx);
    const lineArt = new URL("../../docGeneration/assets/pivotLineArt.png", import.meta.url);

    const dealerLogo = devSettingsState.dealerSettings.logo.current ? devSettingsState.dealerSettings.logo.current.logo : lineArt.toString();

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                resolve(fileReader.result);
            }
                fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    return (
        <>
            <CustomDefaultsRadioGroup 
                title={CONSTANTS.title}
                useCustomDefaults={devSettingsState.dealerSettings.logo.useCustom}
                setUseCustomDefaults={devSettingsState.dealerSettings.logo.setUseCustom}
                alternativeDefaultLabelKey="use-default-logo"
                alternativeOwnLabelKey="use-own-logo"
            />
            {
                !devSettingsState.dealerSettings.logo.useCustom && (
                    <Box>
                        <Box>{t("dealer-settings.no-custom-logo")}</Box>
                        <img src={lineArt.toString()} style={{ width: 200, marginTop: "1em" }} />
                    </Box>
                )
            }
            {
                devSettingsState.dealerSettings.logo.useCustom && <Box>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                        >
                            {t("upload")}
                            <VisuallyHiddenInput type="file" accept="image/*" onChange={async (event) => {
                                const base64 = await convertBase64(event.target.files[0]);
                                devSettingsState.dealerSettings.logo.custom.setFormState({logo: base64 as string});
                            }}/>
                        </Button>
                        <Box>
                            <img src={dealerLogo} style={{marginTop: "1em", maxHeight: 100 }} />
                        </Box>
                </Box>
            }
        </>
    );
}

export default <TabBody />;