import { BaseInletSizes, CenterPivotTypes, CollectorRingTypes, HoseFeedTypes, PivotingLateralPivotCenterTypes, SpanTypes, SystemTypes, WaterFeedTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as othf from "../OtherHelpers";
import QuoteClass from "../QuoteClass";
import * as sidef from "../SideFunctions";
import * as sysf from "../SystemFunctions";
import { Side, Span } from "../Types";
import { SpanDisconnectOptionOK, SpanLengthOK } from "../Valids.Valids";

export default class SpansSingleSpanForm {
    private bKwikTow: boolean;
    private bDEF: boolean;
    private bHoseFeed: boolean;
    private bEndFeed: boolean;
    private bCanalFeed: boolean;
    private bFlangedSide: boolean;
    private bSingleSpanMaxi: boolean;
    private b230Volt: boolean;
    private IsPivotingLateral: boolean;
    private eSystemType: SystemTypes;
    private eCenterPivotType: CenterPivotTypes;
    private eHoseFeedType: HoseFeedTypes;
    private ePivotingLateralPivotCenterType: PivotingLateralPivotCenterTypes;
    private eHoseFeedBaseInlets: BaseInletSizes;
    private bOppositeSideStainless: boolean;
    private bFlexSide: boolean;
    private bReverseTow: boolean;
    private bEDMP: boolean;

    constructor(private Quote: QuoteClass, private side: Side) {
        this.bKwikTow = false;
        this.bDEF = false;
        this.bHoseFeed = false;
        this.bEndFeed = false;
        this.bCanalFeed = false;
        this.b230Volt = false;

        this.eSystemType = Quote.System.SystemProperties.SystemType;
        this.bEDMP = sysf.IsEDMP(Quote.System);
        this.bReverseTow = sysf.IsReverseTow(Quote.System);

        switch (this.eSystemType) {
            case SystemTypes.CenterPivot:
            this.eCenterPivotType = Quote.System.Circle.CenterPivot.CenterPivotType
            break;
        case SystemTypes.KwikTow:
            this.bKwikTow = true;
            break;
        case SystemTypes.HoseFeedMaxigator:
            this.bHoseFeed = true;
            this.IsPivotingLateral = Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral;
            this.eHoseFeedType = Quote.System.Lateral.HoseFeed.HoseFeedType;
            this.ePivotingLateralPivotCenterType = Quote.System.Lateral.HoseFeed.PivotingLateralPivotCenterType;
            this.eHoseFeedBaseInlets = Quote.System.Lateral.HoseFeed.BaseInletSize;
            {
                const eWaterFeed = Quote.System.Lateral.WaterFeed;
                if (eWaterFeed === WaterFeedTypes.EndFeed) this.bEndFeed = true;
            }
            if (Quote.System.Lateral.HoseFeed.SingleSpanMaxi) this.bSingleSpanMaxi = true;
            switch (Quote.System.Lateral.HoseFeed.HoseFeedType) {
                case HoseFeedTypes.DoubleEndFeed:
                    this.bDEF = true;
                    break;
            }
            break;
        case SystemTypes.CanalFeedMaxigator:
            this.bCanalFeed = true;
            {
                const eWaterFeed = Quote.System.Lateral.WaterFeed;
                if (eWaterFeed === WaterFeedTypes.EndFeed) this.bEndFeed = true;
            }
            break;
        }

        this.b230Volt = sysf.Is230V(Quote.System);

        this.bOppositeSideStainless = false;
        this.bFlexSide = false;
        if (!sidef.FlangedSide(Quote.System, side)) {
            this.bFlexSide = true;
            if (Quote.System.FlangedSide.Span.length) {
                switch (Quote.System.FlangedSide.Span[0].SpanType) {
                    case SpanTypes.S2065G, SpanTypes.PL2065G, SpanTypes.S2085G, SpanTypes.PL2085G:
                        this.bOppositeSideStainless = true;
                }
            }
        }
        this.bFlangedSide = !this.bFlexSide;
    }

    // TODO Icon - Check some other validation rules that seems to be hard-coded
    // in the form, but may also be implemented by other checks in Valids.ts.
    public validate = (): void => {
        let previousSpan: Span;
        for (const span of this.side.Span!) {
            if (!span.EndBoom && !span.SwingArm) {
                this.validateSpan(span, previousSpan);
            }
            previousSpan = span;
        }
    }

    private getSideLabel = () => sidef.FlangedSide(this.Quote.System, this.side)
        ? "Flanged" : "Flex";

    private validateSpan = (span: Span, previousSpan: Span) => {
        let bDeny213Use = false;
        if (this.bReverseTow
            && (sysf.IsMaxigator(this.Quote.System) || this.eSystemType === SystemTypes.KwikTow)
            && !this.Quote.IsA100_CF200()) {
                bDeny213Use = true;
            }
        if (span.SpanNumber === 1
            && this.eSystemType === SystemTypes.CenterPivot
            && this.Quote.ControlPanelClass.FieldSet.DataValid()
            && this.Quote.System.ControlPanel.CollectorRing === CollectorRingTypes.None
            && othf.PercentOfCircle(this.Quote.System) === 100) {
                bDeny213Use = true;
            }
        

        /*if (!SpanTypeOK(
            span.SpanType, {
                SpanNumber: span.SpanNumber!,
                SpanCount: 0,
                SystemType: this.eSystemType,
                CenterPivotType: this.eCenterPivotType,
                HoseFeedType: this.eHoseFeedType,
                HoseFeedBaseInlets: this.eHoseFeedBaseInlets,
                IsSingleSpanMaxi: this.bSingleSpanMaxi,
                IsEDMP: this.bEDMP,
                IsReverseTow: this.bReverseTow,
                Is230Volt: this.b230Volt,
                IsFlexSide: this.bFlexSide,
                PreviousSpanType: previousSpan?.SpanType,
                IsOppositeSideStainless: this.bOppositeSideStainless,
                IsReversedSpan: false,
                PivotingLateralPivotCenterType: this.ePivotingLateralPivotCenterType
            }
        )) {
            this.Quote.Warnings.Add(`${this.getSideLabel()} span #${span.SpanNumber} type ${span.SpanType} not allowed in this location`);
        }*/

        if (span.SpanType && !SpanLengthOK(
            span.Length!, {
                SpanType: span.SpanType!,
                SystemType: this.Quote.System.SystemProperties!.SystemType!,
                SpanNumber: span.SpanNumber!,
                IsDeny213Use: bDeny213Use,
                IsFlangedSide: this.bFlangedSide,
                IsKwikTow: this.bKwikTow,
                IsSingleSpanMaxi: this.bSingleSpanMaxi,
                IsPivotingLateral: this.IsPivotingLateral,
                HoseFeedType: this.eHoseFeedType,
                IsTowable: this.Quote.IsTowable(),
            }
        )) {
            this.Quote.Warnings.AddWithTargets(
                [`${this.getSideLabel()}Side.Span[${span.SpanNumber - 1}].Length`],
                `${this.getSideLabel()} span #${span.SpanNumber} length ${span.Length} not allowed in this location`);
        }

        if (!SpanDisconnectOptionOK(
            span.Disconnecting, {
                SpanNumber: span.SpanNumber,
                Is230Volt: this.b230Volt,
                IsKwikTow: this.bKwikTow,
                IsHoseFeed: this.bHoseFeed,
                IsEndFeed: this.bEndFeed,
                IsDEF: this.bDEF,
                HasEndGunSecondary: sidef.HasEndGunSecond(this.side),
                HasVRI: sysf.HasVRI(this.Quote.System),
                PreviousSpanType: previousSpan?.SpanType,
                SpanType: span.SpanType,
                PreviousTowerHeight: span.SpanNumber == 1 ? undefined : this.side.Tower[span.SpanNumber - 2].TowerType,
                TowerHeight: this.side.Tower[span.SpanNumber - 1]?.TowerType,
                NextTowerHeight: span.SpanNumber >= this.side.Tower.length ? undefined : this.side.Tower[span.SpanNumber].TowerType,
            }
        )) {
            this.Quote.Warnings.Add(`${this.getSideLabel()} span #${span.SpanNumber} disconnect not supported in this location`);
        }
    }
}