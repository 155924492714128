const notSelectedStyles = [
    {
        id: "imported-point-feature-notselected",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["==", "meta", "feature"], ["==", "user_import", false]],
        paint: {
            "circle-radius": 3,
            "circle-color": "#808080",
        },
    },
    {
        id: "imported-linestring-feature-notselected",
        type: "line",
        filter: ["all", ["==", "$type", "LineString"], ["==", "meta", "feature"], ["==", "user_import", false]],
        paint: {
            "line-color": "#808080",
            "line-width": 3,
        },
    },
    {
        id: "imported-polygon-feature-notselected",
        type: "fill",
        filter: ["all", ["==", "$type", "Polygon"], ["==", "meta", "feature"], ["==", "user_import", false]],
        paint: {
            "fill-color": "#808080",
            "fill-opacity": 0.7,
        },
    },
];
const selectedStyles = [
    {
        id: "import-boundary-selected",
        type: "line",
        filter: ["all", ["==", "user_importAs", "boundary"], ["==", "user_import", true]],
        paint: {
            "line-color": "#0000ff",
            "line-width": 3,
        },
    },
    {
        id: "import-wet-area-boundary-selected",
        type: "line",
        filter: ["all", ["==", "user_importAs", "wetAreaBoundary"], ["==", "user_import", true]],
        paint: {
            "line-color": "#87CEEB",
            "line-width": 3,
        },
    },
    {
        id: "import-pivot-center-boundary-selected",
        type: "line",
        filter: ["all", ["==", "user_importAs", "pivotCenterBoundary"], ["==", "user_import", true]],
        paint: {
            "line-color": "#000000",
            "line-width": 3,
        },
    },
    {
        id: "import-obstacle-selected",
        type: "line",
        filter: ["all", ["==", "user_importAs", "obstacle"], ["==", "user_import", true]],
        paint: {
            "line-color": "#ff0000",
            "line-width": 3,
        },
    },
    {
        id: "import-wheel-obstacle-selected",
        type: "line",
        filter: ["all", ["==", "user_importAs", "wheelObstacle"], ["==", "user_import", true]],
        paint: {
            "line-color": "#8b0000",
            "line-width": 3,
        },
    },
    {
        id: "import-pump-selected",
        type: "circle",
        filter: ["all", ["==", "user_importAs", "pump"], ["==", "user_import", true]],
        paint: {
            "circle-radius": 5,
            "circle-color": "#0514e6",
        },
    },
    {
        id: "import-point-selected",
        type: "circle",
        filter: ["all", ["==", "user_importAs", "point"], ["==", "user_import", true]],
        paint: {
            "circle-radius": 5,
            "circle-color": "#b700ff",
        },
    },
    {
        id: "import-electricline-selected",
        type: "line",
        filter: ["all", ["==", "user_importAs", "electricLine"], ["==", "user_import", true]],
        paint: {
            "line-color": "#eb34e8",
            "line-width": 3,
        },
    },
    {
        id: "import-waterline-selected",
        type: "line",
        filter: ["all", ["==", "user_importAs", "waterLine"], ["==", "user_import", true]],
        paint: {
            "line-color": "#0dd7de",
            "line-width": 3,
        },
    },
    {
        id: "import-line-selected",
        type: "line",
        filter: ["all", ["==", "user_importAs", "line"], ["==", "user_import", true]],
        paint: {
            "line-color": "#0cad2a",
            "line-width": 3,
        },
    },
    {
        id: "import-label",
        type: "symbol",
        filter: ["all", ["==", "user_importAs", "label"], ["==", "user_import", true]],
        layout: {
            // get the title name from the source's "title" property
            "text-field": ["get", "user_title"],
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        },
        paint: {
            "text-halo-width": 0.3,
            "text-halo-color": "black",
            "text-color": "white",
        },
    },
    {
        id: "import-canal-selected",
        type: "line",
        filter: ["all", ["==", "user_importAs", "canal"], ["==", "user_import", true]],
        paint: {
            "line-color": "#0dd7de",
            "line-width": 5,
        },
    },
    {
        id: "import-pivotCenter-selected",
        type: "circle",
        filter: ["all", ["==", "user_importAs", "pivotCenter"], ["==", "user_import", true]],
        paint: {
            "circle-radius": 5,
            "circle-color": "#ff0000",
        },
    },
    {
        id: "import-mainIrrigatedArea-selected",
        type: "fill",
        filter: ["all", ["==", "user_importAs", "mainIrrigatedArea"], ["==", "user_import", true]],
        paint: {
            "fill-color": "#0000ff",
            "fill-opacity": 0.3,
        },
    },
    {
        id: "import-sacIrrigatedArea-selected",
        type: "fill",
        filter: ["all", ["==", "user_importAs", "sacIrrigatedArea"], ["==", "user_import", true]],
        paint: {
            "fill-color": "#0000ff",
            "fill-opacity": 0.3,
        },
    },
    {
        id: "import-endGunIrrigatedArea-selected",
        type: "fill",
        filter: ["all", ["==", "user_importAs", "endGunIrrigatedArea"], ["==", "user_import", true]],
        paint: {
            "fill-color": "#0000ff",
            "fill-opacity": 0.3,
        },
    },
    {
        id: "import-wrapIrrigatedArea-selected",
        type: "fill",
        filter: ["all", ["==", "user_importAs", "wrapIrrigatedArea"], ["==", "user_import", true]],
        paint: {
            "fill-color": "#0000ff",
            "fill-opacity": 0.3,
        },
    },
    {
        id: "import-feedLine-selected",
        type: "line",
        filter: ["all", ["==", "user_importAs", "feedLine"], ["==", "user_import", true]],
        paint: {
            "line-color": "#fcce03",
            "line-width": 3,
        },
    },
    {
        id: "import-rigidSideIrrigatedArea-selected",
        type: "fill",
        filter: ["all", ["==", "user_importAs", "rigidSideIrrigatedArea"], ["==", "user_import", true]],
        paint: {
            "fill-color": "#0000ff",
            "fill-opacity": 0.3,
        },
    },
    {
        id: "import-flexSideIrrigatedArea-selected",
        type: "fill",
        filter: ["all", ["==", "user_importAs", "flexSideIrrigatedArea"], ["==", "user_import", true]],
        paint: {
            "fill-color": "#5dbb63",
            "fill-opacity": 0.3,
        },
    },
];
const importDrawStyles = [...notSelectedStyles, ...selectedStyles];

export default importDrawStyles;
