import { Backdrop, Button, Stack, Typography } from "@mui/material";
import { Feature, Polygon } from "@turf/turf";
import IAction from "rdptypes/IAction";
import * as React from "react";
import FeatureHelpers from "../../GeometryHelpers/FeatureHelpers";
import { createNewMultiAction } from "../../actions/MultiAction";
import AuthCtx from "../../auth/AuthCtx";
import IAuthState from "../../auth/IAuthState";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { staticDevSettingsDbProvider } from "../../db/DevSettingsDbProvider";
import IDbProject from "../../db/IDbProject";
import { createCenterPivotActionsFromOptimizedSystem } from "../../helpers/optimizedSystemHelpers/createCenterPivotActionsFromOptimizedSystem";
import * as optimizeCenterPivotModule from "../../optimization/centerPivot";
import { sumOptimizedSpans } from "../../optimization/spans/optimizeSpansCommon";
import { ErrorBody } from "./ErrorBody";
import { ProgressBody } from "./ProgressBody";
import * as workerClass from "./worker";

const isSupported = !!window.Worker;
let didWarn = false;

type IOptimizationRunnerRunArgs = {
    type: 'wfo';
    settings: optimizeCenterPivotModule.IOptimizationSettings;
    dbProject: IDbProject;
    layoutId: string;
    numberOfSystems?: number;
    renderFeatures?: (features: Feature[]) => void;
}

interface IOptimizationRunnerState {
    run: (args: IOptimizationRunnerRunArgs) => void;
};

export const OptimizationRunnerCtx: React.Context<IOptimizationRunnerState> = React.createContext(null);

export const OptimizationRunnerProvider: React.FC<React.PropsWithChildren<{}>> = ({ 
    children
}) => {
    const [isRunning, setIsRunning] = React.useState<{ dbPrj: IDbProject, layoutId: string } | undefined>(undefined);
    const [progress, setProgress] = React.useState<workerClass.IWFOProgress | undefined>(undefined);
    const workerRef = React.useRef<Worker | null>(null);
    const authState = React.useContext(AuthCtx);
    const settingsState = React.useContext(DevSettingsCtx);
    const [errorOccured, setErrorOccured] = React.useState(false);

    React.useEffect(() => {
        if (!isSupported && !didWarn) {
            didWarn = true;
            alert("Your browser does not support workers. As a result Whole Field Optimization may be slow.");
        }
    }, []);


    const createOptimizedSystems = (
        layoutId: string, dbProject: IDbProject,
        optimizedSystems: optimizeCenterPivotModule.IOptimizedSystem[]
    ) => {
        const customerSettings = staticDevSettingsDbProvider.customer.get();
        const dealerPricing = staticDevSettingsDbProvider.dealerPricing.get();

        const fieldLayoutSystemDefaults = settingsState.dealerSettings.system.useCustom 
        ? (layoutId: string, systemId: string, authState: IAuthState) => settingsState.dealerSettings.system.custom
            .getSystemProperties(layoutId, systemId, authState)
            // When applying system defaults on field design, ignore end of system actions
            .filter(action => action.actionTypeId !== "SetEndOfSystem")
        : undefined;

        const allActions: IAction[] = [];
        for (let i = 0; i < optimizedSystems.length; i++) {
            const optimizedSystem = optimizedSystems[i];
            const { actions } = createCenterPivotActionsFromOptimizedSystem({
                optimizedSystem,
                layoutId,
                authState,
                systemDefaults: fieldLayoutSystemDefaults,
                spanDefaults: staticDevSettingsDbProvider.span.getUseCustom()
                    ? staticDevSettingsDbProvider.span.get()
                    : undefined,
                towerDefaults: staticDevSettingsDbProvider.tower.getUseCustom()
                    ? staticDevSettingsDbProvider.tower.get()
                    : undefined,
                customerSettings,
                dealerPricing,
            });
            allActions.push(createNewMultiAction(actions, authState))
        }
        if (allActions.length) {
            dbProject.pushAction(
                createNewMultiAction(allActions, authState)
            );
        }
    }

    const clearFeatures = React.useRef(() => {});
    const value: IOptimizationRunnerState = {
        run: (args) => {
            const renderFeatures = args.renderFeatures || (() => {});
            clearFeatures.current();
            clearFeatures.current = () => renderFeatures([]);
            if (isRunning) {
                console.warn("Cannot run optimization, an optimization is already running")
            }
            setErrorOccured(false);
            setProgress(undefined);
            setIsRunning({ dbPrj: args.dbProject, layoutId: args.layoutId });
            
            workerRef.current = new Worker(
                /* @ts-ignore */
                new URL("./worker.ts", import.meta.url),
                { type: "module" }
            )
            const customerSettings = staticDevSettingsDbProvider.customer.get();
            const dealerPricing = staticDevSettingsDbProvider.dealerPricing.get();
            const msg: workerClass.IOptimizationRunnerWorkerInputMessage_Run = {
                run: 'wfo',
                args: {
                    layoutId: args.layoutId,
                    project: args.dbProject.state,
                    optimizationSettings: args.settings,
                    numberOfSystems: args.numberOfSystems,
                    dealerSettings: {
                        customerSettings,
                        dealerPricing,
                    },
                    spanDefaults: staticDevSettingsDbProvider.span.getUseCustom()
                        ? staticDevSettingsDbProvider.span.get()
                        : undefined,
                    towerDefaults: staticDevSettingsDbProvider.tower.getUseCustom()
                        ? staticDevSettingsDbProvider.tower.get()
                        : undefined
                },
                workerN: window.navigator.hardwareConcurrency ?? 8
            }
            let t = Date.now();
            const interval = 1000; // 1s
            let nSystemsForProgress = 0;
            workerRef.current.onmessage = (
                ev: MessageEvent<
                    workerClass.IOptimizationRunnerWorkerOutputMessage_Result | 
                    workerClass.IOptimizationRunnerWorkerOutputMessage_Progress |
                    workerClass.IOptimizationRunnerWorkerOutputMessage_Error
                >
            ) => {
                const optimizedSystemToFeature = (x: optimizeCenterPivotModule.IOptimizedSystem, p: {}) => {
                    let f: Feature<Polygon>;
                    if (x.clockwiseCompassHeadingEnd || x.clockwiseCompassHeadingStart) {
                        f = FeatureHelpers.GetSectorDrawFeature(
                            x.center,
                            sumOptimizedSpans({ spans: x.spanTowers.map(y => ({ spanLength: y.spanLength, spanExtension: y.extension, spanAndExtensionLength: y.spanLength + (y.extension ? 4 : 0)})), endBoom: x.endBoom }),
                            x.clockwiseCompassHeadingStart || 0,
                            x.clockwiseCompassHeadingEnd || 360,
                            p,
                            { units: "feet", degreeIncrement: 1 }
                        )
                    }
                    else {
                        f = FeatureHelpers.GetCircleDrawFeature(
                            x.center,
                            sumOptimizedSpans({ spans: x.spanTowers.map(y => ({ spanLength: y.spanLength, spanExtension: y.extension, spanAndExtensionLength: y.spanLength + (y.extension ? 4 : 0)})), endBoom: x.endBoom }),
                            p,
                            { units: "feet", degreeIncrement: 1 }
                        )
                    }
                    return f;
                }

                const data = ev.data;
                if (data.run !== 'wfo') return;
                if (data.type === 'result') {
                    renderFeatures([]);
                    createOptimizedSystems(args.layoutId, args.dbProject, data.payload.optimizedSystem);
                    setIsRunning(undefined);
                    setProgress(undefined);
                    workerRef.current.terminate();
                    workerRef.current = null;
                }
                else if (data.type === 'progress') {
                    setProgress(data.payload.progress);
                    if (renderFeatures) {
                        let n = Date.now();
                        const fixed = data.payload.progress.state.fixed.map(x => optimizedSystemToFeature(x, {
                            rdpFeatureType: 'centerPivotSelectMode/irrigatedArea',
                            validity: 'valid'
                        }));
                        if (n - t > interval || nSystemsForProgress !== fixed.length) {
                            nSystemsForProgress = fixed.length;
                            renderFeatures([ ...fixed ]);
                            t = n;
                        }
                    }
                }
                else if (data.type === 'error') {
                    renderFeatures([]);
                    console.log("An error occured during wfo");
                    console.log(data.message);
                    setErrorOccured(true);
                }
            }
            workerRef.current.postMessage(msg);
        }
    }

    return (
        <OptimizationRunnerCtx.Provider value={value}>
            {children}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={!!isRunning}
            >
                <Stack direction={'column'} width={'50%'} gap={2}>
                    <Typography alignSelf={'center'}>System Optimization in Progress</Typography>
                    {
                        errorOccured
                            ? <ErrorBody />
                            : <ProgressBody progress={progress} />
                    }
                    <Stack direction={'column'} justifyContent={'center'} gap={2}>
                        <Button
                            color="error"
                            variant="contained"
                            onClick={() => {
                                workerRef.current.terminate();
                                workerRef.current = null;
                                clearFeatures.current();
                                setIsRunning(undefined);
                                setProgress(undefined);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                const systems = progress?.state.fixed;
                                const layoutId = isRunning?.layoutId;
                                const dbPrj = isRunning?.dbPrj;
                                workerRef.current.terminate();
                                workerRef.current = null;
                                clearFeatures.current();
                                setIsRunning(undefined);
                                setProgress(undefined);
                                if (!systems || !systems.length || !layoutId || !dbPrj) return;
                                createOptimizedSystems(layoutId, dbPrj, systems);
                            }}
                            disabled={!isRunning || !progress?.state?.fixed.length}
                        >
                            {
                                progress?.state?.fixed.length
                                    ? progress.state.fixed.length === 1
                                        ? "Accept 1 system"
                                        : `Accept ${progress.state.fixed.length} systems`
                                    : "0 systems"
                            }
                            
                        </Button>
                    </Stack>
                </Stack>
            </Backdrop>
        </OptimizationRunnerCtx.Provider>
    );
}