import { IEnhProgress } from "./IEnhProgress";

export class ProgressScaling implements IEnhProgress {
    readonly inner: IEnhProgress;
    readonly progressMin: number;
    readonly progressMax: number;

    public constructor(inner: IEnhProgress, progressMin: number, progressMax: number) {
        this.inner = inner;
        this.progressMin = progressMin;
        this.progressMax = progressMax;
    }

    public Report(barPercent: number) {
        this.inner.Report(this.progressMin + (this.progressMax - this.progressMin) * barPercent / 100);
    }

    public SetStatusMessage(msg: string) {
        this.inner.SetStatusMessage(msg);
    }

    public isCancelPending(): Promise<boolean> {
        return this.inner.isCancelPending();
    }
}