/**
 * NOTES:
 * 
 * When relying on geometry to compute overlapping features, the step count on curves is
 * very importtant.
 * 
 * turf has a steps property, when used on a sector, these steps define the number of steps
 * in a full circle. Not in the sector
 * 
 * I have used 3600 steps, or 10 steps per degree
 * 
 */
export const OPTIMIZATION_CONSTANTS = {
    SECTOR_STEPS: 3600
}