import { IComparable } from "../System/IComparable";

class InvalidOperationException extends Error {
    constructor(msg: string = "") {
        super(`InvalidOperationException: ${msg}`);
    }
}

export class PairingHeapNode<K,V> {
    public Key: K;
    public Value: V;
    public FirstChild: PairingHeapNode<K,V> | null = null;
    public NextSibling: PairingHeapNode<K,V> | null = null;
    public PreviousSiblingOrParent: PairingHeapNode<K,V> | null = null;

    constructor(key: K, value: V) {
        this.Key = key;
        this.Value = value;
    }
}


export class PairingHeap<K extends IComparable<K>, V> /*: IEnumerable<V> where K : IComparable<K> */ {
    

    Root: PairingHeapNode<K,V> | null = null;

    public get IsEmpty() {
        return this.Root === null;
    }

    public get First() {
        if (this.Root === null) {
            throw new InvalidOperationException();
        }
        return this.Root.Value;
    }

    public Insert(key: K, value: V): PairingHeapNode<K,V> {
        const newNode = new PairingHeapNode(
            key,
            value
        );

        const newHeap = new PairingHeap<K, V>();
        newHeap.Root = newNode;
        this.Root = PairingHeap.Merge(newHeap,this).Root;

        return newNode;
    }

    public RemoveFirst(): void {
        if (this.Root === null) {
            throw new InvalidOperationException("Empty heap");
        }
        if (this.Root.FirstChild !== null) {
            this.Root.FirstChild.PreviousSiblingOrParent = null;
        }
        this.Root = PairingHeap.MergePairs(this.Root.FirstChild).Root;
    }

    public DecreaseKey(node: PairingHeapNode<K, V>, newKey: K): void
    {
        if (newKey.CompareTo(node.Key) === 0)
        {
            return;
        }

        if (newKey.CompareTo(node.Key) > 0)
        {
            throw new InvalidOperationException("Cannot increase key");
        }

        node.Key = newKey;
        if (node === this.Root) {
            // Decreasing the key of the root keeps the same root
            return;
        }
        if (node.NextSibling !== null) {
            node.NextSibling.PreviousSiblingOrParent = node.PreviousSiblingOrParent;
        }

        if (node.PreviousSiblingOrParent) {
            if (node === node.PreviousSiblingOrParent.FirstChild) {
                // Node is first child
                node.PreviousSiblingOrParent.FirstChild = node.NextSibling;
            }
            else
            {
                node.PreviousSiblingOrParent.NextSibling = node.NextSibling;
            }
        }

        node.PreviousSiblingOrParent = null;
        node.NextSibling = null;
        const newHeap = new PairingHeap<K, V>();
        newHeap.Root = node;
        this.Root = PairingHeap.Merge(newHeap, this).Root;
    }

    static Merge<K extends IComparable<K>, V>(heap1:PairingHeap<K, V>, heap2:PairingHeap<K, V>): PairingHeap<K, V> 
    {
        if (heap1.Root === null) {
            return heap2;
        }
        if (heap2.Root === null) {
            return heap1;
        }
        if (heap1.Root.Key.CompareTo(heap2.Root.Key) <= 0) {
            if (heap1.Root.FirstChild !== null) {
                heap1.Root.FirstChild.PreviousSiblingOrParent = heap2.Root;
            }
            heap2.Root.PreviousSiblingOrParent = heap1.Root;
            heap2.Root.NextSibling = heap1.Root.FirstChild;
            heap1.Root.FirstChild = heap2.Root;
            return heap1;
        }
        else {
            if (heap2.Root.FirstChild !== null) {
                heap2.Root.FirstChild.PreviousSiblingOrParent = heap1.Root;
            }
            heap1.Root.PreviousSiblingOrParent = heap2.Root;
            heap1.Root.NextSibling = heap2.Root.FirstChild;
            heap2.Root.FirstChild = heap1.Root;
            return heap2;
        }
    }

    static MergePairs<K extends IComparable<K>, V>(n0: PairingHeapNode<K, V> | null): PairingHeap<K, V> {
        if (n0 === null) {
            const newHeap = new PairingHeap<K, V>();
            newHeap.Root = null;
            return newHeap;
        }
        
        const l0 = new PairingHeap<K, V>();
        l0.Root = n0;

        if (n0.NextSibling === null) {
            return l0;
        }

        const n1 = n0.NextSibling;
        n0.NextSibling = null;
        const l1 = new PairingHeap<K, V>();
        l1.Root = n1;
        
        const n2 = n1.NextSibling;
        n1.PreviousSiblingOrParent = null;
        n1.NextSibling = null;

        if (n2 !== null) {
            n2.PreviousSiblingOrParent = null;
        }
        
        return PairingHeap.Merge(PairingHeap.Merge(l0, l1), PairingHeap.MergePairs(n2));
    }

    // public IEnumerator<V> GetEnumerator()
    // {
    //     return new Enumerator(Root);
    // }

    // IEnumerator IEnumerable.GetEnumerator()
    // {
    //     return GetEnumerator();
    // }


    // class Enumerator : IEnumerator<V>
    // {
    //     LinkedList<PairingHeapNode> l = new LinkedList<PairingHeapNode>();

    //     public Enumerator(PairingHeapNode root)
    //     {
    //         if (root !== null)
    //         {
    //             l.AddLast(root);
    //         }
    //     }

    //     public V Current { get; private set; }

    //     object IEnumerator.Current => Current;

    //     public void Dispose()
    //     {
    //     }

    //     public bool MoveNext()
    //     {
    //         if (l.First !== null)
    //         {
    //             Current = l.First.Value.Value;
    //             for (var child = l.First.Value.FirstChild; child !== null; child = child.NextSibling)
    //             {
    //                 l.AddLast(child);
    //             }
    //             l.RemoveFirst();
    //             return true;
    //         }
    //         else
    //         {
    //             return false;
    //         }
    //     }

    //     public void Reset()
    //     {
    //         throw new NotImplementedException();
    //     }
    // }
}