import { t } from "i18next";
import { gallonsToLiters, litersToGallons } from "../docGeneration/DocumentGenerationHelpers";

export type DisplayVolumeUnits = 'gallons' | 'liters';  
export const DisplayVolumeUnitsMap = {
    gallons: {
        longName: 'gallons',
        fullName: 'gallons',
        shortName: 'gal',
        value: 'gallons' as DisplayVolumeUnits
    }, 
    liters: {
        longName: 'liters',
        fullName: 'liters',
        shortName: 'l',
        value: 'liters' as DisplayVolumeUnits
    }
} as const;

export class DisplayVolumeUnitsBuilder {
    static shortName = (unit: DisplayVolumeUnits) => DisplayVolumeUnitsMap[unit].shortName;
    static longName = (unit: DisplayVolumeUnits) => DisplayVolumeUnitsMap[unit].longName;
    static fullName = (unit: DisplayVolumeUnits) => DisplayVolumeUnitsMap[unit].fullName;

    static convert = (value: number, from: DisplayVolumeUnits, to: DisplayVolumeUnits) => {
        if (from === to) return value;
        if (to === "gallons"){
            return litersToGallons(value);
        }
        return gallonsToLiters(value);
    }

    private _value: number;
    private _unit: DisplayVolumeUnits;
    private _formattedString = "";

    constructor(value: number, unit: DisplayVolumeUnits) {
        this._value = value;
        this._unit = unit;
    }

    convert = (finalUnit: DisplayVolumeUnits) => {
        this._value = DisplayVolumeUnitsBuilder.convert(this._value, this._unit, finalUnit);
        this._unit = finalUnit;
        return this;
    }

    appendValue = (toFixed?: number) => {
        if (toFixed === undefined) {
            this._formattedString += this._value.toString();
        }
        else {
            this._formattedString += this._value.toFixed(toFixed);
        }
        return this;
    }

    appendString = (value: string) => {
        this._formattedString += value;
        return this;
    }

    appendShortName = () => {
        this._formattedString += DisplayVolumeUnitsBuilder.shortName(this._unit);
        return this;
    }
    
    appendLongName = () => {
        this._formattedString += t(DisplayVolumeUnitsBuilder.longName(this._unit));
        return this;
    }

    appendFullName = () => {
        this._formattedString += t(DisplayVolumeUnitsBuilder.fullName(this._unit));
        return this;
    }

    toString = () => {
        return this._formattedString;
    }

}
