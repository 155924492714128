export default {
    pages: [
        /*{
            title: "Product Line",
            position: 1,
            components: [
                {
                    type: "productLineChoice",
                },
            ],
        },*/
    ],
};