export default interface ApiRequestState<T> {
    status: ApiRequestStatus;
    error?: string;
    result?: T;
}

export enum ApiRequestStatus {
    InProgress,
    Success,
    Error
}