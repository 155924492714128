import { Feature } from "@turf/turf";
import * as React from "react";
import { IEditMode } from "../../../../components/Map/SegmentSelectMode";
import DbCtx from "../../../../db/DbCtx";
import { IInteractionState, Interaction } from "../interactionState";
import { ISelectedItem } from "../selectedItem";
import MapSpeedDialAddFeature from "./MapSpeedDialAddFeature";
import MapSpeedDialFeatureSelected from "./MapSpeedDialFeatureSelected";
import MapSpeedDialSystemSelected from "./MapSpeedDialSystemSelected";
import { getMapFeaturePermissions } from "./mapFeaturePermissions";

interface Props {
    projectId: string;
    layoutId: string;
    selectedItem: ISelectedItem;
    setSelectedItem: (item: ISelectedItem) => void;
    navigateFromSystem: () => void;
    setInteractionState: (interactionState: IInteractionState) => void;
    currentSegmentSelectEditMode?: IEditMode;
    renderAdditionalFeatures: (features: Feature[]) => void;
}

const MapSpeedDial: React.FC<Props> = ({
    projectId, layoutId, navigateFromSystem, setSelectedItem, setInteractionState,
    currentSegmentSelectEditMode, renderAdditionalFeatures,
    selectedItem
}) => {

    const dbProject = React.useContext(DbCtx).projects[projectId];
    const project = dbProject.state; 
    const layout = project.layouts[layoutId];
    const mapPermissions = getMapFeaturePermissions(layout);
    
    // START: Speed Dial logic:
    // if selectedSystemId : show system speed dial
    // if any other rdp feature is selected, we want to show the edit menu
    // else we want to show the add features menu
    let speedDialComponent: React.ReactNode = null;
    if (selectedItem.item === 'system' && layout) {
        if (mapPermissions.editSystem(selectedItem.id)) {
            speedDialComponent = (
                <MapSpeedDialSystemSelected
                    projectId={projectId}
                    layoutId={layoutId}
                    systemId={selectedItem.id}
                    onDelete={navigateFromSystem}
                />
            )
        }
    }
    else {
        if (selectedItem.item === 'feature' && selectedItem.feature.properties?.rdpFeatureType) {
            speedDialComponent = (
                <MapSpeedDialFeatureSelected
                    projectId={projectId}
                    layoutId={layoutId}
                    feature={selectedItem.feature}
                    onDeleteCallback={() => setSelectedItem({ item: 'none' })}
                    permissions={mapPermissions}
                    currentSegmentSelectEditMode={currentSegmentSelectEditMode}
                />
            )
        }
        else {
            const hasFieldBoundary = !!layout.fieldBoundary;
            speedDialComponent = (                
                <MapSpeedDialAddFeature
                    permissions={mapPermissions}
                    onAction={(action) => {
                        if ("interaction" in action) {
                            switch (action.interaction) {
                                case Interaction.DrawLabelAnnotation:
                                    setInteractionState({ interactionType: Interaction.DrawLabelAnnotation, text: action.labelAnnotationText });                                    
                                    break;
                            
                                default:
                                    setInteractionState({ interactionType: action.interaction });
                                    break;
                            }
                        }
                        else if ("interactionState" in action) {
                            setInteractionState(action.interactionState);
                        }
                    }}
                    hasFieldBoundary={hasFieldBoundary}
                    projectId={projectId}
                    layoutId={layoutId}
                    renderAdditionalFeatures={renderAdditionalFeatures}
                />
            )
        }
    }
    // END: Speed Dial logic

    return speedDialComponent;
}

export default MapSpeedDial;