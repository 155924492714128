import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { FittingTypes, IPackage } from "rdptypes/project/ISprinklers";
import { ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useEffect } from "react";
import { DropNextToEndGunOk, FittingOptionsOk } from "roedata/roe_migration/Valids.Valids";
import { pageStyles } from "../../../styles";
import { DetermineSprinklerExtrasFromPackage } from "../helpers/Rules";
import { getRegulatorFittingTypeLabel } from "../helpers/SprinklerHelper";
import { ISprinklerPackageValidator } from "../sprinklerValidator/interfaces";

interface Props {
    package: IPackage;
    system: ISystemBase;
    flexedSide: boolean;
    handleTooltipOpen: (src?: string) => void;
    handleTooltipClose: () => void;
    packageValidator: ISprinklerPackageValidator;
};

const FittingOptions: FC<Props> = (props) => {

    useEffect(() => {    }, []);

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    var showFittingSection = FittingOptionsOk({Sprinkler: DetermineSprinklerExtrasFromPackage(props.package).packageType ,
        UseNelson3030: props.package.UseNelson3030,
        SprinklerManufacturerType: props.system.SprinklerProperties.SprinklerEngineCode,
        DropType: props.package.Drop});
    let dropNextToEndGunEnabled = DropNextToEndGunOk(true, {Package: props.package});

    var fittingOptions = props.packageValidator.fitting.fittingType.allowableValues;
    
    return (
        <>
            { showFittingSection &&
                <Stack direction="row" style={{maxWidth: 600, alignItems: 'center', marginTop: 20, marginLeft: 10}}>
                    <FormControl variant="standard" style={{width: '50%', marginRight: 10}} error={props.packageValidator.fitting.fittingType.isError} >
                        <InputLabel shrink={true} sx={formInputLabelStyle} >Regulator Fitting</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getRegulatorFittingTypeLabel(selected, props.system.SprinklerProperties.SprinklerEngineCode);
                            }}                         
                            value={props.packageValidator.fitting.fittingType.value || ""}
                            onChange={(ev) => props.packageValidator.fitting.fittingType.set(ev.target.value as FittingTypes)}
                        >
                            {
                                fittingOptions.map((key) => 
                                    <MenuItem key={key} value={key}>{getRegulatorFittingTypeLabel(key, props.system.SprinklerProperties.SprinklerEngineCode)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControlLabel disabled={!dropNextToEndGunEnabled} style={{}} control={<Checkbox 
                        style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                        checked={props.packageValidator.fitting.dropNextToEndgun.value || false}
                        onChange={(ev) => props.packageValidator.fitting.dropNextToEndgun.set(ev.target.checked)}
                    />} label={`48" Drop next to End Gun`} />
                </Stack>
        }        
        </>
    );
};

export default FittingOptions;