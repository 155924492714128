import { Point, Polygon } from "@turf/turf";
import { t } from "i18next";
import { EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from 'react';
import CenterPivotGeometryHelper from "../../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper from "../../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { isCenterPivot } from "../../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import { IEndgunOnOffs } from "../../../optimization/centerPivot/endgun";
import { IProjectWithId } from "../../../routes/pages/ProjectList";
import ElevationTable from "./ElevationTable";
import LateralEndgunInformationTable from "./LateralEndgunInformationTable";
import LateralSpanTable from "./LateralSpanTable";
import PivotEndgunInformationTable from "./PivotEndgunInformationTable";
import PivotSpanTable from "./PivotSpanTable";
import SystemDetailsTable from "./SystemDetailsTable";

interface Props {
    systemId: string;
    layoutId: string;
    pwid: IProjectWithId;
}

interface SlimmedEndGunInformation {
    center: Point;
    throwStartFeet: number;
    throwEndFeet: number;
    onOffs: IEndgunOnOffs[];
    type: EndGunTypes;
}

const SystemDetailsPage: FC<Props> = React.forwardRef((props) => {
    const {systemId, pwid, layoutId} = props;

    let tables: JSX.Element[] = [<SystemDetailsTable systemId={systemId} pwid={pwid} layoutId={layoutId} />];
    const system = pwid.project.layouts[layoutId].systems[systemId];
    let endgunInformationTables: JSX.Element[] = [];
    let helper: CenterPivotGeometryHelper | LateralGeometryHelper;

    if (isCenterPivot(system)){
        tables.push(<PivotSpanTable system={system}/>);

        helper = new CenterPivotGeometryHelper({
            project: pwid.project,
            layoutId: layoutId!,
            systemId: systemId!
        });

        const addedGuns: Polygon[] = [];
        if (system.sacOptimizerResult && system.sacOptimizerResult.success) {
            system.sacOptimizerResult.geometry.endGunAreas.forEach(ps => addedGuns.push(...ps));
        }

        let egi = helper.calculateEndGunOnOffs(addedGuns);
        let primaryEgis: SlimmedEndGunInformation[] = [];
        let secondaryEgis: SlimmedEndGunInformation[] = [];

        egi.forEach((eg) => {
            let egi_: SlimmedEndGunInformation = {...eg};
            if (eg.isPrimary){
                primaryEgis.push(egi_);
            }
            else {
                secondaryEgis.push(egi_);
            }
        });

        if (primaryEgis.length > 0){
            endgunInformationTables.push(<PivotEndgunInformationTable endgunInformation={primaryEgis} primary/>);
        }
        if (secondaryEgis.length > 0){
            endgunInformationTables.push(<PivotEndgunInformationTable endgunInformation={secondaryEgis} primary={false}/>);
        }
    }
    else {
        tables.push(<LateralSpanTable system={system}/>)
        helper = new LateralGeometryHelper({
            project: pwid.project,
            layoutId: layoutId!,
            systemId: systemId!
        });

        let egi = helper.getEndGunInformation();
        if (egi.rigid.length > 0){
            endgunInformationTables.push(<LateralEndgunInformationTable endgunInformation={egi.rigid} sideLabel={t("proposals.rigid-side-end-guns")}/>);
        }
        if (egi.flex.length > 0){
            endgunInformationTables.push(<LateralEndgunInformationTable endgunInformation={egi.flex} sideLabel={t("proposals.flex-side-end-guns")}/>);
        }
    }

    let evTable = <ElevationTable layoutId={layoutId} systemId={systemId} projectId={pwid.id} fontSize="0.8em"/>;

    tables.push(evTable);
    if (endgunInformationTables.length > 0){
        tables.push(endgunInformationTables[0]);
    }
    if (endgunInformationTables.length > 1){
        tables.push(endgunInformationTables[1]);
    }

    return <>
        {tables}
    </>;
});

export default SystemDetailsPage;