// Option Infer On
// Imports ReinkeSystems.Data

class ElectricalCableGaugeHelper {

    

    private _CableGauge: number = -1;
    private _12GaugeAllowed: boolean = false;
    private _10GaugeAllowed: boolean = false;
    private _8GaugeAllowed: boolean = false;

    // This VB constructor is handled by the class constructor
//     Public Sub New()
//     End Sub

    constructor(InitialGauge: number = undefined, AllowableResize: ElectricalCableGaugeHelper.AllowableResize = undefined) {
        // Note: the next line with optional params is to handle the overloaded, no parameters constructor found in VB
        if (InitialGauge === undefined || AllowableResize === undefined) return;
        this._CableGauge = InitialGauge;
        switch (this._CableGauge) {
            case 12:
                this._12GaugeAllowed = true;
                switch (AllowableResize) {
                    case ElectricalCableGaugeHelper.AllowableResize.Larger:
                        this._10GaugeAllowed = true;
                        this._8GaugeAllowed = true;
                        break;
                }
                break;
            case 10:
                this._10GaugeAllowed = true;
                switch (AllowableResize) {
                    case ElectricalCableGaugeHelper.AllowableResize.Larger:
                        this._8GaugeAllowed = true;
                        break;
                }
                break;
            case 8:
                this._8GaugeAllowed = true;
                break;
        }
    }

    public get Gauge(): number {
        // TODO: Check this,
        // Note: The VB initialises this value to -1 (which is not a correct enum value)
        // I have had to cast here to please TS
        return this._CableGauge;
    }

    public IncreaseGauge(): boolean {
        switch (this._CableGauge) {
            case 12:
                if (!this._10GaugeAllowed) return false;
                this._CableGauge = 10;
                break;
            case 10:
                if (!this._8GaugeAllowed) return false;
                this._CableGauge = 8;
                break;
            default:
                return false;
        }
        return true;
    }

    public DecreaseGauge(): boolean {
        switch (this._CableGauge) {
            case 10:
                if (!this._12GaugeAllowed) return false;
                this._CableGauge = 12;
                break;
            case 8:
                if (!this._10GaugeAllowed) return false;
                this._CableGauge = 10;
                break;
            default:
                return false;
        }
        return true;
    }

    public SetFloor() {
        switch (this._CableGauge) {
            case 10:
                this._12GaugeAllowed = false;
                break;
            case 8:
                this._12GaugeAllowed = false;
                this._10GaugeAllowed = false;
                break;
        }
    }

//     'Public ReadOnly Property CircularMils() As Integer
//     '  Get
//     '    Select Case m_CableGauge
//     '      Case SpanCableGauges.a14
//     '        Return 4110
//     '      Case SpanCableGauges.a12
//     '        Return 6530
//     '      Case SpanCableGauges.a10
//     '        Return 10380
//     '      Case SpanCableGauges.a8
//     '        Return 16510
//     '    End Select
//     '  End Get
//     'End Property

    public get OhmsPerFoot(): number {
        switch (this._CableGauge) {
            case 14:
                return 0.00258;
            case 12:
                return 0.00162;
            case 10:
                return 0.00102;
            case 8:
                return 0.000641;
        }
    }

}

namespace ElectricalCableGaugeHelper {
    export enum AllowableResize {
        None,
        Larger
    }
}

export default ElectricalCableGaugeHelper;