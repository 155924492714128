import { WireGauge, WireMaterial } from "rdptypes/project/IMCP";
import { IControlPanel, ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
export enum IntakeSizes {
    a6,
    a8,
    a10,
}

// Wrapper method kept in case voltage calculation changes
export const MinVoltage = (cp: IControlPanel): number => cp.MinVoltage;

// Wrapper method kept in case voltage calculation changes
export const SourceVoltage = (cp: IControlPanel): number => cp.SourceVoltage;

const awgDiameterInch = (value: number) => 0.005 * Math.pow(92, ((36 - value) / 39 ));
const mcmDiameterInch = (value: number) => Math.sqrt(value * 1000) / 1000;
const WIRE_SIZES = [
    { enum: WireGauge.AWG_14, diameterInches: awgDiameterInch(14) },
    { enum: WireGauge.AWG_12, diameterInches: awgDiameterInch(12) },
    { enum: WireGauge.AWG_10, diameterInches: awgDiameterInch(10) },
    { enum: WireGauge.AWG_8, diameterInches: awgDiameterInch(8) },
    { enum: WireGauge.AWG_6, diameterInches: awgDiameterInch(6) },
    { enum: WireGauge.AWG_4, diameterInches: awgDiameterInch(4) },
    { enum: WireGauge.AWG_2, diameterInches: awgDiameterInch(2) },
    { enum: WireGauge.AWG_1, diameterInches: awgDiameterInch(1) },
    { enum: WireGauge.AWG_0, diameterInches: awgDiameterInch(0) },
    { enum: WireGauge.AWG_00, diameterInches: awgDiameterInch(-1) },
    { enum: WireGauge.AWG_000, diameterInches: awgDiameterInch(-2) },
    { enum: WireGauge.AWG_0000, diameterInches: awgDiameterInch(-3) },
    { enum: WireGauge.MCM_250, diameterInches: mcmDiameterInch(250) },
    { enum: WireGauge.MCM_300, diameterInches: mcmDiameterInch(300) },
    { enum: WireGauge.MCM_350, diameterInches: mcmDiameterInch(350) },
    { enum: WireGauge.MCM_400, diameterInches: mcmDiameterInch(400) },
    { enum: WireGauge.MCM_450, diameterInches: mcmDiameterInch(450) },
    { enum: WireGauge.MCM_500, diameterInches: mcmDiameterInch(500) },
    { enum: WireGauge.MCM_600, diameterInches: mcmDiameterInch(600) },
    { enum: WireGauge.MCM_750, diameterInches: mcmDiameterInch(750) },
    { enum: WireGauge.MCM_1000, diameterInches: mcmDiameterInch(1000) }
]

// resistivity: ohm-meters
// area : cross sectional area in m2
// return units: ohms/meter
const resistancePerFoot = (resistivity: number, areaIn2: number) => {
    return resistivity / (areaIn2 / 144);
}

const voltageDropSinglePhase = (current: number, resistancePerFoot: number, lengthFeet: number) => {
    return 2 * current * lengthFeet * resistancePerFoot;
}

const voltageDrop3Phase = (current: number, resistancePerFoot: number, lengthFeet: number) => {
    return Math.sqrt(3) * current * lengthFeet * resistancePerFoot;
}

const materialToResistivity = (material: WireMaterial): number => {
    // in ohm-meters
    // Values confirmed on call with Reinke 2024.05.01
    // Values come from https://www.engineeringtoolbox.com/copper-aluminum-conductor-resistance-d_1877.html
    switch (material) {
        case WireMaterial.Aluminium:
            return 2.65e-8;
        case WireMaterial.Copper:
            return 1.724e-8;
        default:
            return 99999;
    }
}
enum CurrentType {
    AC_SinglePhase = "AC_SinglePhase",
    AC_ThreePhase = "AC_ThreePhase"
}
export const RemotePanelOptions_VoltageLoss = (system: ISystemBase, current?: number): number => {
    const {  wireGauge, wireMaterial, lengthFt } = system.mcp;
    const currentType = system.Circle.SinglePhase230VoltSystem ? CurrentType.AC_SinglePhase : CurrentType.AC_ThreePhase;
    if (!wireGauge || !wireMaterial || current === undefined || 
        !currentType || lengthFt === undefined
    ) {
        return 0;
    }
    const resistivityOhmMeters = materialToResistivity(wireMaterial);
    const resistivityOhmFeet = resistivityOhmMeters * 3.28084;
    const wireInfo = WIRE_SIZES.find(x => x.enum === wireGauge);
    if (wireInfo === undefined) {
        return 0;
    }
    const areaIn2 = Math.PI * Math.pow(wireInfo.diameterInches / 2, 2);
    const rpf = resistancePerFoot(resistivityOhmFeet, areaIn2);
    let vd: number;
    switch (currentType) {
        case CurrentType.AC_SinglePhase:
            vd = voltageDropSinglePhase(current, rpf, lengthFt);  
            break;
        case CurrentType.AC_ThreePhase:
            vd = voltageDrop3Phase(current, rpf, lengthFt);
            break;
        default:
            return 0;
    }
    return vd;
}