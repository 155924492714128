import { IEnhProgress } from "../../Progress/IEnhProgress";
import { QuantizedSACOptimizationState } from "../QuantizedSACOptimizationState";

export class Order0SACOptimizer {
    public readonly State: QuantizedSACOptimizationState;
    
    public constructor(state: QuantizedSACOptimizationState) {
        this.State = state;
    }
    
    public async Optimize(progress: IEnhProgress): Promise<boolean> {
        progress.SetStatusMessage("Finding order-0 solution");

        let r = true;
        const rLock = {}; //new object();

        let counter = 0;
        const n = this.State.iPivotAngleMax - this.State.iPivotAngleMin + 1;

        for (
            let iPivotAngle = this.State.iPivotAngleMin; 
            iPivotAngle < this.State.iPivotAngleMax + 1;
            iPivotAngle++
        ) {
            if (iPivotAngle % 10 === 0 && await progress.isCancelPending()) {
                r = false;
                return false;
            }

            const i = iPivotAngle - this.State.iPivotAngleMin;
            
            this.State.BestSolution[i] = i > 0 ? this.State.BestSolution[i - 1] : 0;

            if (this.State.CheckConstraintsOrder0NoCache(iPivotAngle, this.State.BestSolution[i])) {
                for (;
                    this.State.BestSolution[i] > 0
                    && this.State.CheckConstraintsOrder0NoCache(iPivotAngle, this.State.BestSolution[i] - 1);
                    this.State.BestSolution[i]--)
                {
                }
            }
            else
            {
                this.State.BestSolution[i]++;
                for (;
                    this.State.BestSolution[i] <= this.State.iExtensionAngleMaxFeasible
                    && !this.State.CheckConstraintsOrder0NoCache(iPivotAngle, this.State.BestSolution[i]);
                    this.State.BestSolution[i]++)
                {
                }
            }

            if (this.State.BestSolution[i] === this.State.iExtensionAngleMaxFeasible + 1) {
                r = false;
            }

            counter++
            if (counter % 100 === 0) {
                progress.Report(100.0 * counter / n);
            }
        };
        return r;
    }
}