import { Document, Page, Text, View } from '@react-pdf/renderer';
import i18next, { t } from "i18next";
import IDbGrower from "rdptypes/api/IGrowerBase";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from 'react';
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { SpanPipeTypes } from "roedata/roe_migration/SpanFunctions";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { configurationValid, getGroupedPartsForSystem, isCenterPivot, systemHasPipeType } from '../../components/roe/componentRenderers/Proposal/ProposalHelpers';
import IProject from "../../model/project/IProject";
import DealerDataCtx from "../../userData/DealerDataCtx";
import { IDocumentType, formatDateShort } from '../DocumentGenerationHelpers';
import CustomerDealerTable from '../components/CustomerDealerTable';
import DocumentFirstPageHeader from '../components/DocumentFirstPageHeader';
import Footer from "../components/Footer";
import ForReinkeUseOnlyTable from '../components/ForReinkeUseOnlyTable';
import Header from '../components/Header';
import PurchaseOrderSystemInformationTable from "../components/PurchaseOrderSystemInformationTable";
import PurchaseOrderTermsPriceTable from "../components/PurchaseOrderTermsPriceTable";
import SpanInformationTable from "../components/SpanInformationTable";
import SprinklerPackageInformationTable from "../components/SprinklerPackageInformationTable";
import SystemComponentsTable from "../components/SystemComponentsTable";
import FixedWidthTable, { IRow } from "../components/table/FixedWidthTable";
import { documentStyles } from '../styles';

interface Props {
    systemId: string;
    layoutId: string;
    project: IProject;
    grower: IDbGrower;
}

const PAGE_SIZE = "LETTER";

const IrrigationSystemPurchaseOrder: FC<Props> = (props) => {
    const { dealership: dealer, priceBookName } = React.useContext(DealerDataCtx);
    
    const {systemId, layoutId, project, grower } = props;
    const layout = project.layouts[layoutId!];
    const system = layout.systems[systemId!];

    const groupedSystemParts = getGroupedPartsForSystem(system);
    let miscItems = (system.QuoteProperties && system.QuoteProperties.MiscItems) ?  system.QuoteProperties.MiscItems : [];

    //TODO: implement the following or move to ProposalHelpers if more appropriate there
    let quoteSubmittedAsOrder = false;

    const quote = new QuoteClass(system);

    let messages: {text: string; indent: boolean}[] = [
        {text: t("common-phrases.lsltItIsUnderstoodBy"), indent: false},
        {text: t("common-phrases.lsltDealerLanguage"), indent: false},
        {text: t("proposals.purchase-order.barricades.text"), indent: false},
        {text: t("proposals.purchase-order.barricades-a.text"), indent: true},
        {text: t("proposals.purchase-order.barricades-b.text"), indent: true},
        {text: t("common-phrases.lsltIfTheDealer"), indent: false}
    ];

    let warningMessages: {text: string; indent: boolean}[] = [];

    if (HasSwingArmCorner(quote.System)) warningMessages.push({text: t("common-phrases.lsltSACAcresTerms"), indent: false});
    if (systemHasPipeType(quote, [SpanPipeTypes.Galvanized])) warningMessages.push({text: t("common-phrases.sltGalvanizedCNPipe"), indent: false});
    if (systemHasPipeType(quote, [SpanPipeTypes.Painted])) warningMessages.push({text: t("common-phrases.sltPaintedCNPipe"), indent: false});
    if (isCenterPivot(system)) warningMessages.push({text: t("common-phrases.lsltBarricadesMissingWarning1"), indent: false});

    let termsRows:IRow[] = [{
        cells: [
            {
                widthPc: 40,
                child: <View style={{marginBottom: 10}}><Text>{t("proposals.irrigation-system-purchase-agreement.terms.dealer-signature")}</Text></View>,
                trustCellsOwnFormat: true
            },
            {
                text: "",
                widthPc: 20
            },
            {
                child: <View style={{marginBottom: 10}}><Text>{i18next.format(t('date'), 'capitalize')}</Text></View>,
                widthPc: 40,
                trustCellsOwnFormat: true
            }
        ]
    },
    {
        cells: [
            {
                text: "    ",
                bottomBorder: true,
                widthPc: 40
            },
            {
                text: "",
                widthPc: 20
            },
            {
                text: "     ",
                bottomBorder: true,
                widthPc: 40
            }
        ]
    }];

    return (
        <>
            <Document>
                <Page size={PAGE_SIZE} style={documentStyles.page}>
                    <DocumentFirstPageHeader documentType={IDocumentType.irrigationSystemPurchaseOrder}/>
                    {                    
                        !configurationValid(system) && <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>INCOMPLETE PROPOSAL</Text>
                        </View>
                    }
                    <View style={{paddingLeft: 30, paddingRight: 30 }}>
                        { !priceBookName && <Text style={{fontWeight: "bold", marginTop: 10, marginBottom: 10}}>{t('common-phrases.sltPRICESEFFECTIVE')} {formatDateShort(new Date())}</Text> }
                        { priceBookName && <Text style={{fontWeight: "bold", marginTop: 10, marginBottom: 10, backgroundColor: "#ff0000", color: "#ffffff"}}>PRICE BOOK: {priceBookName} - {t('common-phrases.sltPRICESEFFECTIVE')} {formatDateShort(new Date())}</Text> }
                        <Text>{t('common-phrases.sltPleaseReviewCompleteSys')}</Text>
                    </View>
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        <ForReinkeUseOnlyTable quote={quote} quoteSubmittedAsOrder={quoteSubmittedAsOrder} />
                        <View style={{paddingLeft: 15, paddingRight: 15}}>
                            <CustomerDealerTable systemId={systemId} layoutId={layoutId} project={project} grower={grower} poNumber={quote.System.QuoteProperties.ProposalInformation.PONumber} dealerFirst/>  
                        </View>    
                        {
                            system.SystemProperties.SystemType !== SystemTypes.Ancillary
                            && <PurchaseOrderSystemInformationTable quote={quote}/>
                        }
                        {
                            system.SystemProperties.SystemType !== SystemTypes.Ancillary
                            && <SprinklerPackageInformationTable quote={quote} systemId={systemId} layoutId={layoutId} project={project}/>
                        }
                    </View>
                    <View fixed style={documentStyles.firstPageFooter}/>
                </Page>
                {system.SystemProperties.SystemType !== SystemTypes.Ancillary &&
                system.SystemProperties.SystemType !== SystemTypes.SwingArmRetro &&
                <Page size={PAGE_SIZE} style={[documentStyles.page, documentStyles.nonTitlePage]}>
                    <Header docType={IDocumentType.irrigationSystemPurchaseOrder} dealerName={dealer.name}/>
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        <SpanInformationTable system={system} quote={quote}/>
                    </View>
                    <Footer/>
                </Page>}
                <Page size={PAGE_SIZE} style={[documentStyles.page, documentStyles.nonTitlePage]}>
                    <Header docType={IDocumentType.irrigationSystemPurchaseOrder} dealerName={dealer.name}/>
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        <SystemComponentsTable  docType={IDocumentType.irrigationSystemPurchaseOrder} quote={quote} system={system} miscItems={miscItems} groupedSystemParts={groupedSystemParts} />
                    </View>
                    <Footer/>
                </Page>
                <Page size={PAGE_SIZE} style={[documentStyles.page, documentStyles.nonTitlePage]}>
                    <Header docType={IDocumentType.irrigationSystemPurchaseOrder} dealerName={dealer.name}/>
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltTerms")}</Text>
                         </View>
                         <PurchaseOrderTermsPriceTable miscItems={miscItems} groupedSections={groupedSystemParts.partSections} quote={quote} system={system}  />
                         <View style={{paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
                            {
                                messages.map((m) => {
                                    return <View style={{marginBottom: 5, marginLeft: m.indent ? 30: 0}}>
                                        <Text style={{fontSize: 10}}>{m.text}</Text>
                                    </View>;
                                })
                            }
                            {warningMessages.length > 0 && <View><Text style={{fontWeight: "bold", fontSize: 10}}>{t("common-phrases.sltWarnings")}:</Text></View>}
                            {
                                warningMessages.map((m) => {
                                    return  <Text style={{fontSize: 10}}>- {m.text}</Text>
                                })
                            }
                            <View style={{marginTop: 10, marginBottom: 20, paddingLeft: 30, paddingRight: 30}}><FixedWidthTable data={{rows: termsRows}}/></View>
                            <Text style={{fontSize: 10}}>{t("common-phrases.sltComments1")}</Text>
                         </View>
                    </View>
                    <Footer/>
                </Page>
            </Document>
        </>
    );
};

export default IrrigationSystemPurchaseOrder;