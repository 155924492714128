import { Box, Card, Checkbox, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { useContext, useState } from "react";
import { createNewMultiAction } from "../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import AuthCtx from "../../auth/AuthCtx";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import IDbProject from "../../db/IDbProject";
import { pageStyles } from "../roe/styles";

enum CustomerPriceAmmendmentType {
    listPriceDiscountPercent,
    markupOverDealerCostPercent,
    markupOverDealerCostDollar,
    quotedPriceDollar
}

interface Props {
    proposalSettings?: {
        layoutId: string;
        systemId: string;
        dbPrj: IDbProject;
    }
}

const CustomerSettingsRenderer: React.FC<Props> = (props) => {
    const ns = "dealer-settings.";
    const subns = "customer-settings-options.";
        
    const authState = useContext(AuthCtx);
    const devSettingsState = useContext(DevSettingsCtx);
    let withinProposal = typeof props.proposalSettings !== "undefined";

    const system =  withinProposal ? props.proposalSettings.dbPrj.state.layouts[props.proposalSettings.layoutId].systems[props.proposalSettings.systemId] : undefined;

    const listPriceDiscountPercent = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.listPriceDiscountPercent : devSettingsState.dealerSettings.customer.custom.formState.listPriceDiscountPercent;
    const salesTaxPercent = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.salesTaxPercent : devSettingsState.dealerSettings.customer.custom.formState.salesTaxPercent;
    const systemIsTaxable = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.system.isTaxable : devSettingsState.dealerSettings.customer.custom.formState.systemIsTaxable;
    const freightIsTaxable = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.freight.isTaxable : devSettingsState.dealerSettings.customer.custom.formState.freightIsTaxable;
    const installationIsTaxable = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.installation.isTaxable : devSettingsState.dealerSettings.customer.custom.formState.installationIsTaxable;
    const pivotPadIsTaxable = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.pivotPad.isTaxable : devSettingsState.dealerSettings.customer.custom.formState.pivotPadIsTaxable;
    const tradeinIsTaxable = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.tradeIn.isTaxable : devSettingsState.dealerSettings.customer.custom.formState.tradeinIsTaxable;
    const markupPercentOverDealerCost = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.markupPercentOverDealerCost : devSettingsState.dealerSettings.customer.custom.formState.markupPercentOverDealerCost;
    const markupDollarOverDealerCost = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.markupDollarOverDealerCost : devSettingsState.dealerSettings.customer.custom.formState.markupDollarOverDealerCost;
    const quotedPriceDollar = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.quotedPriceDollar : undefined;
    const onlyPrintTotalPrice = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.onlyPrintTotalPrice : devSettingsState.dealerSettings.customer.custom.formState.onlyPrintTotalPrice;
    const printListPrice = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.printListPrice : devSettingsState.dealerSettings.customer.custom.formState.printListPrice;

    let cpat: CustomerPriceAmmendmentType = CustomerPriceAmmendmentType.listPriceDiscountPercent;
    let num_ = listPriceDiscountPercent;
    if (markupPercentOverDealerCost > 0) {cpat = CustomerPriceAmmendmentType.markupOverDealerCostPercent; num_ = markupPercentOverDealerCost}
    else if (markupDollarOverDealerCost > 0) {cpat = CustomerPriceAmmendmentType.markupOverDealerCostDollar; num_ = markupDollarOverDealerCost}
    else if (typeof quotedPriceDollar !== "undefined" && quotedPriceDollar > 0) {cpat = CustomerPriceAmmendmentType.quotedPriceDollar; num_ = quotedPriceDollar}

    const [customerPriceAmmendmentType, setCustomerPriceAmendmentType] = useState(cpat);
    const [num, setNum] = useState(num_)

    return (
        <Box sx={{ overflow: "auto", padding: 2 }}>
            {
                withinProposal && <Typography style={{marginBottom: 10}}>
                    The following settings will apply to this proposal <em>only</em>. You can modify your defaults from within dealer settings.
                </Typography>
            }
            <Card variant="outlined" style={{ width: "fit-content", marginBottom: 20, padding: 10 }}>
                <Typography sx={pageStyles.cardTitleStyle}>
                    {t(ns + subns + "price-computation")}
                </Typography>

                <Stack direction="row" style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
                    <RadioGroup value={customerPriceAmmendmentType === CustomerPriceAmmendmentType.listPriceDiscountPercent ? "listPrice" :
                        customerPriceAmmendmentType === CustomerPriceAmmendmentType.markupOverDealerCostPercent ? "markupPercentOverDealerCostPc" :
                            customerPriceAmmendmentType === CustomerPriceAmmendmentType.markupOverDealerCostDollar ? "markupPercentOverDealerCostDollar" :
                                "quotedPriceDollar"
                    }
                        onChange={(e) => {
                            if(withinProposal){
                                props.proposalSettings.dbPrj.pushAction(createNewMultiAction([
                                    createNewUpdateSystemPropertyAction(
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.listPriceDiscountPercent",
                                    0,
                                    authState),
                                    createNewUpdateSystemPropertyAction(
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.markupPercentOverDealerCost",
                                    0,
                                    authState),
                                    createNewUpdateSystemPropertyAction(
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.markupDollarOverDealerCost",
                                    0,
                                    authState),
                                    createNewUpdateSystemPropertyAction(
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.quotedPriceDollar",
                                    0,
                                    authState)],
                                    authState));
                            }
                            else {
                                //reset
                                let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                                settings.markupPercentOverDealerCost = 0;
                                settings.markupDollarOverDealerCost = 0;
                                settings.listPriceDiscountPercent = 0;
                                devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                            }
                            setNum(0);

                            if (e.target.value === "listPrice") setCustomerPriceAmendmentType(CustomerPriceAmmendmentType.listPriceDiscountPercent);
                            else if (e.target.value === "markupPercentOverDealerCostPc") setCustomerPriceAmendmentType(CustomerPriceAmmendmentType.markupOverDealerCostPercent);
                            else if (e.target.value === "markupPercentOverDealerCostDollar") setCustomerPriceAmendmentType(CustomerPriceAmmendmentType.markupOverDealerCostDollar);
                            else if (e.target.value === "quotedPriceDollar") setCustomerPriceAmendmentType(CustomerPriceAmmendmentType.quotedPriceDollar);
                        }}
                    >
                        <FormControlLabel value="listPrice" control={<Radio />} label={t(ns + subns + "discount-off-list-price-pc")} />
                        <FormControlLabel value="markupPercentOverDealerCostPc" control={<Radio />} label={t(ns + subns + "markup-over-dealer-cost-pc")} />
                        <FormControlLabel value="markupPercentOverDealerCostDollar" control={<Radio />} label={t(ns + subns + "markup-over-dealer-cost-dollar")} />
                        {
                            withinProposal && <FormControlLabel value="quotedPriceDollar" control={<Radio />} label={t(ns + subns + "quoted-price-dollar")} />
                        }
                    </RadioGroup>
                    <TextField
                        sx={{ marginRight: 5 }}
                        type="number"
                        value={num}
                        onChange={(e) => {
                            setNum(parseFloat(e.target.value));
                        }}
                        onBlur={(e) => {
                            if (withinProposal){
                                //update proposal

                                let lpdpc = 0;
                                let modcp = 0;
                                let modcd = 0;
                                let qpd = 0;

                                if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.listPriceDiscountPercent) {
                                    lpdpc = num;
                                }
                                else if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.markupOverDealerCostPercent) {
                                    modcp = num;
                                }
                                else if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.markupOverDealerCostDollar) {
                                    modcd = num;
                                }
                                else if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.quotedPriceDollar){
                                    qpd = num;
                                }

                                props.proposalSettings.dbPrj.pushAction(
                                    createNewMultiAction([
                                        createNewUpdateSystemPropertyAction(
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.listPriceDiscountPercent",
                                    lpdpc,
                                    authState),
                                    createNewUpdateSystemPropertyAction(
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.markupPercentOverDealerCost",
                                    modcp,
                                    authState),
                                    createNewUpdateSystemPropertyAction(
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.markupDollarOverDealerCost",
                                    modcd,
                                    authState),
                                    createNewUpdateSystemPropertyAction(
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.quotedPriceDollar",
                                    qpd,
                                    authState)
                                    ], authState));
                            }
                            else {
                                //update dev settings
                                let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                                settings.listPriceDiscountPercent = 0;
                                settings.markupPercentOverDealerCost = 0;
                                settings.markupDollarOverDealerCost = 0;
    
                                if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.listPriceDiscountPercent) {
                                    settings.listPriceDiscountPercent = num;
                                }
                                else if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.markupOverDealerCostPercent) {
                                    settings.markupPercentOverDealerCost = num;
                                }
                                else if (customerPriceAmmendmentType === CustomerPriceAmmendmentType.markupOverDealerCostDollar) {
                                    settings.markupDollarOverDealerCost = num;
                                }
                                devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                            }
                        }}
                    />
                </Stack>
            </Card>
            <Card variant="outlined" style={{ marginBottom: 20, padding: 10 }}>
                    <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={printListPrice} onChange={(e) => {
                        if (withinProposal){
                            props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                props.proposalSettings.layoutId,
                                props.proposalSettings.systemId,
                                "QuoteProperties.ProposalInformation.pricing.printListPrice",
                                e.target.checked,
                                authState));
                        }
                        else {
                            let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                            settings.printListPrice = e.target.checked;
                            devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                        }
                    }} />} label={t(ns + subns + "print-list-price")} />
                    <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={onlyPrintTotalPrice} onChange={(e) => {
                        if (withinProposal){
                            props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                props.proposalSettings.layoutId,
                                props.proposalSettings.systemId,
                                "QuoteProperties.ProposalInformation.pricing.onlyPrintTotalPrice",
                                e.target.checked,
                                authState));
                        }
                        else {
                            let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                            settings.onlyPrintTotalPrice = e.target.checked;
                            devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                        }
                    }} />} label={t(ns + subns + "only-print-total-price")} />
            </Card>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <TextField
                    sx={{ marginRight: 5 }}
                    type="number"
                    label={t(ns + subns + "sales-tax-pc")}
                    value={salesTaxPercent}
                    onChange={(e) => {
                        let numVal = parseFloat(e.target.value);//TODO: validate number
                        if (withinProposal){
                            props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                props.proposalSettings.layoutId,
                                props.proposalSettings.systemId,
                                "QuoteProperties.ProposalInformation.pricing.salesTaxPercent",
                                numVal,
                                authState));
                        }
                        else {
                            let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                            settings.salesTaxPercent = numVal;
                            devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                        }
                    }}
                />
            </Box>
            <Card variant="outlined" style={{ marginTop: 20, padding: 10 }}>
                <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={systemIsTaxable} onChange={(e) => {
                    if (withinProposal){
                        props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                            props.proposalSettings.layoutId,
                            props.proposalSettings.systemId,
                            "QuoteProperties.ProposalInformation.pricing.system.isTaxable",
                            e.target.checked,
                            authState));
                    }
                    else {
                        let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                        settings.systemIsTaxable = e.target.checked;
                        devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                    }
                }} />} label={t(ns + subns + "system-taxable")} />
                <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={installationIsTaxable} onChange={(e) => {
                    if (withinProposal){
                        props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                            props.proposalSettings.layoutId,
                            props.proposalSettings.systemId,
                            "QuoteProperties.ProposalInformation.pricing.installation.isTaxable",
                            e.target.checked,
                            authState));
                    }
                    else {
                        let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                        settings.installationIsTaxable = e.target.checked;
                        devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                    }
                }} />} label={t(ns + subns + "installation-taxable")} />
                <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={freightIsTaxable} onChange={(e) => {
                    if (withinProposal){
                        props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                            props.proposalSettings.layoutId,
                            props.proposalSettings.systemId,
                            "QuoteProperties.ProposalInformation.pricing.freight.isTaxable",
                            e.target.checked,
                            authState));
                    }
                    else {
                        let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                        settings.freightIsTaxable = e.target.checked;
                        devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                    }
                }} />} label={t(ns + subns + "freight-taxable")} />
                <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={pivotPadIsTaxable} onChange={(e) => {
                    if (withinProposal){
                        props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                            props.proposalSettings.layoutId,
                            props.proposalSettings.systemId,
                            "QuoteProperties.ProposalInformation.pricing.pivotPad.isTaxable",
                            e.target.checked,
                            authState));
                    }
                    else {
                        let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                        settings.pivotPadIsTaxable = e.target.checked;
                        devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                    }
                }} />} label={t(ns + subns + "pivot-pad-taxable")} />
                <FormControlLabel sx={{ marginRight: 5 }} control={<Checkbox checked={tradeinIsTaxable} onChange={(e) => {
                    if (withinProposal){
                        props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                            props.proposalSettings.layoutId,
                            props.proposalSettings.systemId,
                            "QuoteProperties.ProposalInformation.pricing.tradeIn.isTaxable",
                            e.target.checked,
                            authState));
                    }
                    else {
                        let settings = { ...devSettingsState.dealerSettings.customer.custom.formState };
                        settings.tradeinIsTaxable = e.target.checked;
                        devSettingsState.dealerSettings.customer.custom.setFormState(settings);
                    }
                }} />} label={t(ns + subns + "system-trade-in-taxable")} />
            </Card>
        </Box>
    );
}

export default CustomerSettingsRenderer;