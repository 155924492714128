import styled from "@emotion/styled";
import { AppBar, Box, Card, IconButton, Toolbar } from "@mui/material";
import * as React from "react";
import { FC } from "react";

import "@fontsource/libre-franklin/900.css";
import CloseIcon from "@mui/icons-material/Close";
import ReinkeIcon from "../../components/RdpAppBar/ReinkeIcon";

interface Props {
    title: string;
    children: React.ReactNode;
}

const LogoText = styled.span`
    color: white;
    font-family: "Libre Franklin", sans-serif;
    font-weight: 900;
    size: 14px;
`;

const ToolTitle = styled.span`
    color: white;
    size: 14px;
    margin-left: 20px;
`;

const ToolsPageBase: FC<Props> = (props) => {

    return (
        <div style={{height: '100%'}}>
            <AppBar position="fixed" >
                <Toolbar variant="dense">
                    <ReinkeIcon full />
                    <Box mr={2}>
                        <ToolTitle>{props.title}</ToolTitle>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <IconButton
                        size="large"
                        aria-label="undo"
                        onClick={() => window.close()}
                        color="inherit"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{paddingTop: 50, }}>
            <Card style={{padding: 10, margin: 30}} variant="outlined">
                {props.children}
            </Card>                
            </div>          
        </div>
        
    );
};

export default ToolsPageBase;