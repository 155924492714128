import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select from '@mui/material/Select';
import * as React from "react";
import { disabledColor, pageStyles } from "../roe/styles";

interface Props {
    disabled?: boolean;
    setter: (n?: number) => void;
    currentValue: number;
    choices: number[];
    title: string;
    max?: boolean;
}

export const NumberOfSystemsControl: React.FC<Props> = ({
    disabled, currentValue: nWraps, setter, choices, title, max = false
}) => {
    
    let formInputLabelStyle = {
        ...pageStyles.formInputLabelStyle
    };
    if (disabled) {
        formInputLabelStyle['color'] = disabledColor;
    }
    
    return (   
        <FormControl fullWidth variant="standard" >
            <InputLabel shrink={true} style={{...formInputLabelStyle}}>{title}</InputLabel>
            <Select               
                value={nWraps || 1}
                onChange={ev => {
                    setter(ev.target.value as number)
                }}
                disabled={disabled}
            >
                {
                    max && (
                        <MenuItem key={'max'} value={-1}>Max</MenuItem>
                    )
                }
                {
                    choices.map(x => (
                        <MenuItem key={x} value={x}>{x}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}