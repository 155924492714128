import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import * as React from "react";
import { FC, PropsWithChildren, useState } from "react";

import AuthCtx from "./AuthCtx";
import IAuthState from "./IAuthState";

import { AZURE_B2C_CLIENT_ID, AZURE_B2C_TENANT_NAME } from "../constants";

const msalConfiguration: Configuration = {
  auth: {
      clientId: AZURE_B2C_CLIENT_ID,
      authority: `https://${AZURE_B2C_TENANT_NAME}.b2clogin.com/${AZURE_B2C_TENANT_NAME}.onmicrosoft.com/B2C_1_si`,
      knownAuthorities: [`${AZURE_B2C_TENANT_NAME}.b2clogin.com`],
      redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage"
  }
};

const pca = new PublicClientApplication(msalConfiguration);



const DummyAuthProvider: FC<PropsWithChildren<{}>> = (props) => {
  const [ loggedInUserId, setLoggedInUserId ] = useState<string | undefined>(undefined);
  const [ assumedUserId, setAssumedUserId ] = useState<string | undefined>(undefined);

  const authState: IAuthState = {
    loggedInUserId,
    setLoggedInUserId,
    assumedUserId,
    setAssumedUserId,
  };
  
  return (
    <MsalProvider instance={pca}>
      <AuthCtx.Provider value={authState}>
        {props.children}
      </AuthCtx.Provider>
    </MsalProvider>
  );
};

export default DummyAuthProvider;