import * as React from "react";
import { FC } from "react";
import { SYSTEM_DESIGN_CONSTANTS } from "../SystemDiagram/constants";
import Wheel from "./Wheel";

interface IProps {
    startDistanceInches: number;
    towerHeightInches: number;
    elevationFeet: number;
}

const Tower: FC<IProps> = ({ towerHeightInches, startDistanceInches, elevationFeet }) => {
    const towerWidth = SYSTEM_DESIGN_CONSTANTS.pipe.width;    
    const wheelHeight =  SYSTEM_DESIGN_CONSTANTS.wheel.height;

    return (
        <>
            <path 
                d={`
                    M ${startDistanceInches} ${elevationFeet * 12 + wheelHeight * 0.5 - towerWidth} 
                    l 0 ${towerHeightInches - wheelHeight * 0.5 + towerWidth}`
                } 
                stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
                strokeWidth={towerWidth} />
            <Wheel startDistanceInches={startDistanceInches} elevationFeet={elevationFeet} />
        </>
    );
};

export default Tower;