import { convertLength } from "@turf/turf";
import { spanLengthsFeet } from "rdptypes/reinkeComponents";
import { DisplayLengthUnitBuilder, DisplayLengthUnits } from "../helpers/lengths";

export const getSpanLengths = (units: DisplayLengthUnits) => {
    return spanLengthsFeet.map(x => {
        return {
            originalValue: x,
            convertedValue: convertLength(x, 'feet', units),
            label: createSpanLabel(units, x)
        }
    })
}

export const createSpanLabel = (units: DisplayLengthUnits, lengthFeet: number) => {
    return new DisplayLengthUnitBuilder(lengthFeet, 'feet')
    .convert(units)
    .appendValue(units === 'feet' ? undefined : 1)
    .appendSymbol()
    .toString();
}