import { Text, View } from "@react-pdf/renderer";
import i18next from "i18next";
import * as React from "react";
import { FC } from "react";
import { IDocumentType, getDocTypeStr } from "../DocumentGenerationHelpers";
import { primaryColor } from "../styles";
import ReinkeLogo from "./Logos/ReinkeLogoWhite";
interface Props {
    documentType: IDocumentType | string;
};

const DocumentFirstPageHeader: FC<Props> = (props) => {
    let docType = (typeof props.documentType) === "string" ? props.documentType : getDocTypeStr(props.documentType as IDocumentType);
    return (
        <>
            <View style={{marginBottom: 15, padding: 30, backgroundColor: primaryColor}}>
                <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <View style={{flex: 2}}>
                        <Text style={{color: "white", fontWeight: "bold"}}>{i18next.format(docType, 'uppercase')}</Text>
                    </View>
                    <View style={{flex: 1, alignItems: "flex-end"}}><ReinkeLogo style={{width: 100}}/></View>
                </View>
            </View>
        </>
    );
};

export default DocumentFirstPageHeader;