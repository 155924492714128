import IDealershipData from "./IDealershipData";
import { PriceList } from "./PriceList";
import IDealerDiscountData from "./userData/IDealerDiscountData";
import IDealerPermissions from "./userData/IDealerPermissions";

export default interface IUserData {
    /**
     * Information about the actual user logged in, which may be an administrator who is currently
     * impersonating another user. It is the assumed user, not this logged in user, which determines
     * most permissions throughout the software. However when logging actions, this logged in user needs
     * to be recorded for tracability reasons.
     */
    loggedInUser: ILoggedInUser;
    
    /**
     * Information about the account which is currently being used. The user may not have logged in
     * directly because an administrator may have logged in first and assumed this user. This assumed
     * user is the account which determines most permissions throughout the software. However when
     * logging actions, the logged in user needs to be recorded for tracability reasons.
     */
    assumedUser: IAssumedUser;

    /**
     * Information about the dealership of the assumed user.
     */
    dealership: IDealershipData;

    discounts: IDealerDiscountData;

    permissions: IDealerPermissions;

    priceList: PriceList;

    priceBookName?: string;
}

/**
 * Information about the actual user logged in, which may be an administrator who is currently
 * impersonating another user. It is the assumed user, not this logged in user, which determines
 * most permissions throughout the software. However when logging actions, this logged in user needs
 * to be recorded for tracability reasons.
 */
export interface ILoggedInUser {
    id: string;
    name: string;
    email: string;
    class: UserClass;
    dealershipNumber: number;
}

/**
 * Information about the account which is currently being used. The user may not have logged in
 * directly because an administrator may have logged in first and assumed this user. This assumed
 * user is the account which determines most permissions throughout the software. However when
 * logging actions, the logged in user needs to be recorded for tracability reasons.
 */
export interface IAssumedUser {
    id: string;
    name: string;
    email: string;
    dealershipNumber: number;
}

export enum UserClass {
    dealer = "dealer",
    dealerAdmin = "dealerAdmin",
    territoryAdmin = "territoryAdmin",
    superAdmin = "superAdmin",
}