import { t } from "i18next";
import { EndGunTypes, ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useEffect } from "react";
import Acres from "roedata/roe_migration/Acres";
import { ElectricalFrequencyToText, FeetToMeters, GPMToM3Hour, PSIToBar } from "roedata/roe_migration/CommonFunctions";
import EndGunInfo, { EndGunItem } from "roedata/roe_migration/EndGunInfo";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import * as sysf from "roedata/roe_migration/SystemFunctions";
import IProject from "../../../../../model/project/IProject";
import { pageStyles, secondaryColor } from "../../../styles";
import { getValuesFromFieldDesign } from "../../Proposal/ProposalHelpers";

interface Props {
    sys: ISystemBase;
    systemId: string;
    layoutId: string;
    project: IProject;
};

const SpanIrrigationInformation: FC<Props> = (props) => {


    const [quote, setQuote] = React.useState<QuoteClass>(new QuoteClass(JSON.parse(JSON.stringify(props.sys))));

    useEffect(() => {
       
    }, []);

    const  GetFraction2 = (diameter: number, egt: EndGunTypes): string =>{
        if(diameter === 0){
            return "0";
        }
       try{
        if (egt ===  EndGunTypes.SR100NV) {
            egt = EndGunTypes.SR100;
        }
        if(egt === EndGunTypes.DualP85){
            egt = EndGunTypes.SingleP85
        }
        var egItems = EndGunInfo.filter(x => x.EndGunType === egt && x.Nozzle > diameter * 0.995 && x.Nozzle < diameter * 1.005)
        var eg: EndGunItem = egItems.length > 0 ? egItems[0] : null;
        if(eg){
            if(eg.NozzleNumberText){
                return eg.NozzleNumberText;
            }
            if( Number.isInteger(eg.NozzleNumber)){
                return eg.NozzleNumber.toFixed(0);
            }
    
            return eg.NozzleNumber.toFixed(2);
    
        }else{
            return "";
        }      
       }catch(ex){
        //todo: handle exception
       }
    }

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    formInputLabelStyle["color"] = secondaryColor;

    let valuesFromFieldDesign = getValuesFromFieldDesign(quote, props.systemId, props.layoutId, props.project);
    const acres = new Acres(props.sys, valuesFromFieldDesign);
    const bHasESP = sysf.HasEnergySaverPackage(props.sys);
    const sSystemElevationLoss = props.sys.SprinklerProperties.PeakElevation / 2.31;
    const sSysGPMPSI = props.sys.SprinklerProperties.PumpPressureAtSysGPMForESP;
    const sMaxGPMMainLoss = props.sys.SprinklerProperties.MainlineLossAtMaxGPMForESP;
    const sPumpElevationLoss = - props.sys.SprinklerProperties.PumpElevation / 2.31;

    var sEndPressure, sPSILossAtMaxFlow, sMainLineFactorLoss,  cRightRadius, cRightEG1Radius, cRightEG2Radius, cRightCovWOEG, cRightCovWEG1, cRightCovWEG2, 
    cRightSideGPM, cRightEG1GPMDel, cRightEG2GPMDel, cRightEndPressure, cRightEG1GPMReq, cLeftEG2GPMReq, dLeftEndPressure, dLeftEGPressure, cLeftEGPressure, 
    cLeftBoostedPressure, dLeftEndPressureNonAdjusted, cLeftEG1Nozzle, cLeftEndPressure, cLeftEG2Nozzle, dRightEGPressure, cRightEGPressure, cRightBoostedPressure, 
    dRightEndPressureNonAdjusted, cRightEG1Nozzle, cRightEG2GPMReq, cRightEG2Nozzle, cLeftRadius, cLeftSideGPM, cLeftCovWOEG, cLeftCovWEG1, cLeftCovWEG2, 
    cLeftEG1Radius, cLeftEG1GPMDel, cLeftEG1GPMReq, cLeftEG2Radius, cLeftEG2GPMDel;

    const bDualSided = sysf.IsCenterFeed(props.sys) || sysf.HasPowerTowerEndBoom(props.sys);

    if(quote /*&& quote.SprinklerChartClass.FieldSet.DataValid()*/){
        sEndPressure = props.sys.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure;
        let mmfrp = quote.GetMinMaxFlowRateAndPSILoss();
        sPSILossAtMaxFlow = mmfrp.psiLossAtMaxFlow;
        sMainLineFactorLoss = sMaxGPMMainLoss * ((props.sys.SprinklerProperties.TotalGPM / mmfrp.maxFlow) ^ 1.85);
        cRightRadius = FeetToMeters(spanf.EndingRadius(props.sys, props.sys.FlangedSide,props.sys.FlangedSide.Span[props.sys.FlangedSide.Span.length - 1]));
        cRightEG1Radius = FeetToMeters(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.Radius);
        cRightEG2Radius = FeetToMeters(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.Radius);
        cRightCovWOEG = FeetToMeters(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.CoverageRadiusWithoutEndGun);
        cRightCovWEG1 = FeetToMeters(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.CoverageRadiusWithEndGun);
        cRightCovWEG2 = FeetToMeters(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.CoverageRadiusWithSecondaryEndGun);
        cRightSideGPM = GPMToM3Hour(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SideGPM);
        cRightEG1GPMDel = GPMToM3Hour(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.GPMDelivered);
        cRightEG2GPMDel = GPMToM3Hour(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.GPMDelivered);
        cRightEndPressure = PSIToBar(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure);
        cRightEG1GPMReq = GPMToM3Hour(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.GPMRequired);
        dRightEGPressure = props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.Pressure;
        cRightEGPressure = PSIToBar(dRightEGPressure);
        dRightEndPressureNonAdjusted = props.sys.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure + props.sys.SprinklerProperties.PeakElevation / 2.31;
        cRightBoostedPressure = PSIToBar(dRightEGPressure - dRightEndPressureNonAdjusted);
        cRightEG1Nozzle = GetFraction2(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.NozzleSize, props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.EndGunTypeCalculated);
        cRightEG2GPMReq = GPMToM3Hour(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.GPMRequired);
        cRightEG2Nozzle = GetFraction2(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.NozzleSize, props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.EndGunTypeCalculated);

        if(bDualSided){
            cLeftRadius = FeetToMeters(spanf.EndingRadius(props.sys, props.sys.FlexSide, props.sys.FlexSide.Span[props.sys.FlexSide.Span.length - 1]));
            var flexIP = props.sys.FlexSide.SprinklerChart.IrrigationProperties;           
            dLeftEndPressure = flexIP.ComputedEndPressure;
            cLeftEndPressure = PSIToBar(dLeftEndPressure);
            dLeftEndPressureNonAdjusted = props.sys.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure + props.sys.SprinklerProperties.PeakElevation / 2.31
            cLeftSideGPM =GPMToM3Hour(flexIP.SideGPM);
            cLeftCovWOEG = FeetToMeters(flexIP.CoverageRadiusWithoutEndGun);
            cLeftCovWEG1 = FeetToMeters(flexIP.CoverageRadiusWithEndGun);
            cLeftCovWEG2 = FeetToMeters(flexIP.CoverageRadiusWithSecondaryEndGun);

            var egip = flexIP.EndGunIrrigationProperties;
            dLeftEGPressure = egip.Pressure;
            cLeftEGPressure = PSIToBar(dLeftEGPressure);
            cLeftBoostedPressure = PSIToBar(dLeftEGPressure - dLeftEndPressureNonAdjusted);
            cLeftEG1Radius = FeetToMeters(egip.Radius);
            cLeftEG1Nozzle = GetFraction2(egip.NozzleSize, egip.EndGunTypeCalculated);
            cLeftEG1GPMDel = GPMToM3Hour(egip.GPMDelivered);
            cLeftEG1GPMReq = GPMToM3Hour(egip.GPMRequired);
            egip = flexIP.SecondaryEndGunIrrigationProperties;
            cLeftEG2Radius = FeetToMeters(egip.Radius);
            cLeftEG2Nozzle = GetFraction2(egip.NozzleSize, egip.EndGunTypeCalculated);
            cLeftEG2GPMDel = GPMToM3Hour(egip.GPMDelivered);
            cLeftEG2GPMReq = GPMToM3Hour(egip.GPMRequired);
            cLeftEG2GPMReq = GPMToM3Hour(props.sys.FlexSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.GPMRequired);
            dLeftEndPressure = props.sys.FlexSide.SprinklerChart.IrrigationProperties.ComputedEndPressure;
            cLeftEGPressure = PSIToBar(dLeftEGPressure);
            dLeftEndPressureNonAdjusted = (props.sys.FlexSide.SprinklerChart.IrrigationProperties.ComputedEndPressure + props.sys.SprinklerProperties.PeakElevation / 2.31);
            cLeftBoostedPressure = PSIToBar(dLeftEGPressure - dLeftEndPressureNonAdjusted);
            cLeftEG1Nozzle = GetFraction2(props.sys.FlexSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.NozzleSize, props.sys.FlexSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.EndGunTypeCalculated);
            cLeftEndPressure = PSIToBar(dLeftEndPressure);
            cLeftEG2Nozzle = GetFraction2(props.sys.FlexSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.NozzleSize, props.sys.FlexSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.EndGunTypeCalculated);
        }
       
    }else{
        sEndPressure = 0;
        sMainLineFactorLoss = 0;
    }
    var sMaxGPMPSI = quote ? ( sEndPressure + sPSILossAtMaxFlow + sSystemElevationLoss + quote.BasePressureMod() + sMaxGPMMainLoss + sPumpElevationLoss) : null;
    const dTopOfInletPressureESP = sEndPressure + sPSILossAtMaxFlow + sSystemElevationLoss + sSysGPMPSI - sMaxGPMPSI + sMaxGPMMainLoss - sMainLineFactorLoss;
    const sPSILossAtSysFlow = PSIToBar(props.sys.SprinklerProperties.PivotPressure - sEndPressure - sSystemElevationLoss);
    const dESPRelatedLoss = sPSILossAtMaxFlow - sPSILossAtSysFlow + sSysGPMPSI - sMaxGPMPSI + sMaxGPMMainLoss - sMainLineFactorLoss;
    const dRightEndPressure = props.sys.FlangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure;


    var flangedSideHeaders = [];
    var radiusCells = [];
    var coverageCells = [];
    var sideGPMCells = [];
    var endPressureCells = [];
    var endGunPressureCells = [];
    var endgunNozzleCells = [];

    var flexedSideHeaders = [];
    var flexedRadiusCells = [];
    var flexedCoverageCells = [];
    var flexedSideGPMCells = [];
    var flexEndPressureCells = [];
    var flexEndGunPressureCells = [];
    var flexEndgunNozzleCells = [];

    if(props.sys.FlangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.EndGunTypeCalculated === EndGunTypes.None){
        flangedSideHeaders.push("Flanged Side", "", "");
        radiusCells.push("","Radius", cRightRadius);
        coverageCells.push("", "Coverage", cRightCovWOEG);
        sideGPMCells.push("", "Side GPM", cRightSideGPM);
        endPressureCells.push("", "End Pressure", cRightEndPressure);
    }else if (props.sys.FlangedSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.EndGunTypeCalculated === EndGunTypes.None){
        flangedSideHeaders.push("Flanged Side", "", "", "", "");
        radiusCells.push("","Radius", cRightRadius, "End Gun Radius", cRightEG1Radius);
        coverageCells.push("", "Coverage", cRightCovWOEG, "Coverage With End Gun", cRightCovWEG1);
        sideGPMCells.push("", "Side GPM", cRightSideGPM, "End Gun GPM Delivered", cRightEG1GPMDel);
        endPressureCells.push("", "End Pressure", cRightEndPressure, "End Gun GPM Required", cRightEG1GPMReq);
        if(dRightEndPressure === dRightEGPressure){
            endGunPressureCells.push("", "", "", "End Gun Pressure", cRightEGPressure);
        }else{
            endGunPressureCells.push("", "Boosted Pressure", cRightBoostedPressure, "End Gun Pressure", cRightEGPressure);
        }
        endgunNozzleCells.push("", "", "", "End Gun Nozzle", cRightEG1Nozzle);
    }else{
        flangedSideHeaders.push("Flanged Side","", "", "End Gun Performance", "Primary", "Secondary");
        radiusCells.push("","Radius", cRightRadius, "End Gun Radius", cRightEG1Radius, cRightEG2Radius);
        coverageCells.push("", "Coverage", cRightCovWOEG, "Coverage With End Gun", cRightCovWEG1, cRightCovWEG2);
        sideGPMCells.push("", "Side GPM", cRightSideGPM, "End Gun GPM Delivered", cRightEG1GPMDel, cRightEG2GPMDel);
        endPressureCells.push("", "End Pressure", cRightEndPressure, "End Gun GPM Required", cRightEG1GPMReq, cRightEG2GPMReq);
        if(dRightEndPressure === dRightEGPressure){
            endGunPressureCells.push("", "", "", "End Gun Pressure", cRightEGPressure, cRightEndPressure);
        }else{
            endGunPressureCells.push("", "Boosted Pressure", cRightBoostedPressure, "End Gun Pressure", cRightEGPressure, cRightEndPressure);
        }
        endgunNozzleCells.push("", "", "", "End Gun Nozzle", cRightEG1Nozzle, cRightEG2Nozzle);
    }

    if(bDualSided){
        if(props.sys.FlexSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.EndGunTypeCalculated === EndGunTypes.None){
            flexedSideHeaders.push("Flexed Side", "", "");
            flexedRadiusCells.push("","Radius:", cLeftRadius);
            flexedCoverageCells.push("", "Coverage:", cLeftCovWOEG);
            flexedSideGPMCells.push("", "Side GPM:", cLeftSideGPM);
            flexEndPressureCells.push("", "End Pressure:", cLeftEndPressure);
        }else if (props.sys.FlexSide.SprinklerChart.IrrigationProperties.SecondaryEndGunIrrigationProperties.EndGunTypeCalculated === EndGunTypes.None){
            flexedSideHeaders.push("Flexed Side", "", "", "", "");
            flexedRadiusCells.push("","Radius:", cLeftRadius, "End Gun Radius:", cLeftEG1Radius);
            flexedCoverageCells.push("", "Coverage:", cLeftCovWOEG, "Coverage With End Gun:", cLeftCovWEG1);
            flexedSideGPMCells.push("", "Side GPM:", cLeftSideGPM, "End Gun GPM Delivered:", cLeftEG1GPMDel);
            flexEndPressureCells.push("", "End Pressure:", cLeftEndPressure, "End Gun GPM Required:", cLeftEG1GPMReq);
            if(dLeftEndPressure === dLeftEGPressure){
                flexEndGunPressureCells.push("", "", "", "End Gun Pressure:", cLeftEGPressure);
            }else{
                flexEndGunPressureCells.push("", "Boosted Pressure:", cLeftBoostedPressure, "End Gun Pressure:", cLeftEGPressure);
            }
            flexEndgunNozzleCells.push("", "", "", "End Gun Nozzle:", cLeftEG1Nozzle);
        }else{
            flexedSideHeaders.push("Flexed Side","", "", "End Gun Performance", "Primary", "Secondary");
            flexedRadiusCells.push("","Radius:", cLeftRadius, "End Gun Radius:", cLeftEG1Radius, cLeftEG2Radius);
            flexedCoverageCells.push("", "Coverage:", cLeftCovWOEG, "Coverage With End Gun:", cLeftCovWEG1, cLeftCovWEG2);
            flexedSideGPMCells.push("", "Side GPM:", cLeftSideGPM, "End Gun GPM Delivered:", cLeftEG1GPMDel, cLeftEG2GPMDel);
            flexEndPressureCells.push("", "End Pressure:", cLeftEndPressure, "End Gun GPM Required:", cLeftEG1GPMReq, cLeftEG2GPMReq);
            if(dLeftEndPressure === dLeftEGPressure){
                flexEndGunPressureCells.push("", "", "", "End Gun Pressure:", cLeftEGPressure, cLeftEndPressure);
            }else{
                flexEndGunPressureCells.push("", "Boosted Pressure:", cLeftBoostedPressure, "End Gun Pressure:", cLeftEGPressure, cLeftEndPressure);
            }
            flexEndgunNozzleCells.push("", "", "", "End Gun Nozzle:", cLeftEG1Nozzle, cLeftEG2Nozzle);
        }
    }
    
    return (
            <div>
                <table className="span-irrigation-table">
                    <thead>
                        <tr>
                            <th>System Information</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sysf.HasSwingArmCorner(props.sys) &&
                            <tr>
                                <td></td>
                                <td>{t("common-phrases.sltMaximumSystemGPM")}</td>
                                <td>{GPMToM3Hour(props.sys.SprinklerProperties.MaximumSystemGPM).toFixed(1)}</td>
                            </tr>
                        }
                        {
                            sysf.HasSwingArmCorner(props.sys) &&
                            <tr>
                                <td></td>
                                <td>{t("common-phrases.sltMinTopInletPSIatMaxGPM")}</td>
                                <td>{PSIToBar(props.sys.SprinklerProperties.MaximumTopInletPSI).toFixed(1)}</td>
                            </tr>
                        }
                        {
                            sysf.HasSwingArmCorner(props.sys) &&
                            <tr>
                                <td></td>
                                <td>{t("common-phrases.sltMinimumSystemGPM")}</td>
                                <td>{GPMToM3Hour(props.sys.SprinklerProperties.MinimumSystemGPM).toFixed(1)}</td>
                            </tr>
                        }
                        <tr>
                            <td></td>
                            <td>GPM Per Acre:</td>
                            <td>{GPMToM3Hour(acres.GPMPerAcreGivenGPM(props.sys.SprinklerProperties.TotalGPM, "gpm")).toFixed(1)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>System GPM:</td>
                            <td>{GPMToM3Hour(props.sys.SprinklerProperties.TotalGPM).toFixed(1)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Top of Inlet Pressure:</td>
                            <td>{bHasESP ? PSIToBar(props.sys.SprinklerProperties.PivotPressure).toFixed(1) + " with ESP " + PSIToBar(dTopOfInletPressureESP).toFixed(1) : PSIToBar(props.sys.SprinklerProperties.PivotPressure).toFixed(1)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Base of Inlet Pressure:</td>
                            <td>{quote ? (bHasESP ? PSIToBar(props.sys.SprinklerProperties.PivotPressure + quote.BasePressureMod()).toFixed(1) +  " with ESP " +  PSIToBar(dTopOfInletPressureESP + quote.BasePressureMod()).toFixed(1) : PSIToBar(props.sys.SprinklerProperties.PivotPressure + quote.BasePressureMod()).toFixed(1)) : ""}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Elevation Pressure Loss:</td>
                            <td>{bHasESP ? PSIToBar(props.sys.SprinklerProperties.PeakElevation / 2.31).toFixed(1) +  " ESP Pressure Loss " + PSIToBar(dESPRelatedLoss).toFixed(1) : PSIToBar(props.sys.SprinklerProperties.PeakElevation / 2.31).toFixed(1)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>System Loss:</td>
                            <td>{PSIToBar(props.sys.SprinklerProperties.PivotPressure - dRightEndPressure - props.sys.SprinklerProperties.PeakElevation / 2.31).toFixed(1)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Electrical Frequency:</td>
                            <td>{sysf.FieldSets(props.sys).ControlPanel.DataValid() ?  ElectricalFrequencyToText(props.sys.ControlPanel.ElectricalFrequency) : ""}</td>
                        </tr>
                    </tbody>
                </table>

                <table style={{marginTop: 20}} className="span-irrigation-table">
                    <thead>
                        <tr>{ flangedSideHeaders.map(h => <th style={{textAlign: 'left'}}>{h}</th>)}</tr>
                    </thead>
                    <tbody>
                        <tr>{ radiusCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)}</tr>
                        <tr>{ coverageCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)}</tr>
                        <tr>{ sideGPMCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)} </tr>
                        <tr>{ endPressureCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)} </tr>
                        <tr>{ endGunPressureCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)}</tr>
                        <tr>{ endgunNozzleCells.map(c => <td>{ Number(c) ? Number(c).toFixed(2) : c}</td>)}</tr>
                    </tbody>
                </table>

                <table style={{marginTop: 20}} className="span-irrigation-table">
                    <thead>
                        <tr>{ flexedSideHeaders.map(h => <th style={{textAlign: 'left'}}>{h}</th>)}</tr>
                    </thead>
                    <tbody>
                        <tr>{ flexedRadiusCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c }</td>)}</tr>
                        <tr>{ flexedCoverageCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)}</tr>
                        <tr>{ flexedSideGPMCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)} </tr>
                        <tr>{ flexEndPressureCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)} </tr>
                        <tr>{ flexEndGunPressureCells.map(c => <td>{ Number(c) ? Number(c).toFixed(1) : c}</td>)}</tr>
                        <tr>{ flexEndgunNozzleCells.map(c => <td>{ Number(c) ? Number(c).toFixed(2) : c}</td>)}</tr>
                    </tbody>
                </table>
                
                         
            </div> 
    );
};

export default SpanIrrigationInformation;