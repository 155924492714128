import i18next from "i18next";
import { ISystemBase, SystemTypes, WrapAroundSpanTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import IBoolean from "rdptypes/roe/IBoolean";
import IChoice from "rdptypes/roe/IChoice";
import IComplexChoice from "rdptypes/roe/IComplexChoice";
import INumber from "rdptypes/roe/INumber";
import ISideTable, { IColumn } from "rdptypes/roe/ISideTable";
import * as React from "react";
import { useContext } from "react";
import AuthCtx from "../../../../auth/AuthCtx";
import DevSettingsCtx from "../../../../db/DevSettingsCtx";
import IDbProject from "../../../../db/IDbProject";
import { translationEn } from '../../../../locales/en/translation.json';
import { translationEs } from '../../../../locales/es/translation.json';
import IValidationWorkerState, { ValidationWorkerStatus } from "../../../../workers/ValidationWorker/IValidationWorkerState";
import { SystemValidationResult } from "../../validation/SystemValidator";
import ComponentRenderContext from "../ComponentRenderContext";
import SideTableRenderer from "../SideTableRenderer";

const cols: IColumn[] = [
    {
        title: { "en" : translationEn["common-phrases"]["sltLength"], "es": translationEs["common-phrases"]["sltLength"]},
        arrayPath: "Span",
        cmp: {
            type: "complexChoice",
            fieldPath: "Length",
            title: "",
            options: [
                {  title: {"en": "80'"}, title_meters: {"en": "24.4m"}, values: {"Length": 80, "Extension" : false } },
                {  title: {"en": "99'"}, title_meters: {"en": "30.2m"}, values: {"Length": 99, "Extension" : false}  },
                {  title: {"en": "118'"}, title_meters: {"en": "36.0m"}, values: {"Length": 118,"Extension" : false} },                                
                {  title: {"en": "120'"}, title_meters: {"en": "36.6m"}, values: {"Length": 120,"Extension" : false} },
                {  title: {"en": "122' (118' + 4' ext)"}, title_meters: {"en": "37.2m (36.0m + 1.2m ext)"}, values: {"Length": 118, "Extension" : true} },
                {  title: {"en": "137'"}, title_meters: {"en": "41.8m"}, values: {"Length": 137,"Extension" : false}  },                              
                {  title: {"en": "140'"}, title_meters: {"en": "42.7m"}, values: {"Length": 140,"Extension" : false} },
                {  title: {"en": "141' (137' + 4' ext)"}, title_meters: {"en": "43.0m (41.8m + 1.2m ext)"}, values: {"Length": 137, "Extension" : true}  },
                {  title: {"en": "156'"}, title_meters: {"en": "47.5m"}, values: {"Length": 156,"Extension" : false}  },
                {  title: {"en": "160'"}, title_meters: {"en": "48.8m"}, values: {"Length": 160,"Extension" : false} },
                {  title: {"en": "175'"}, title_meters: {"en": "53.3m"}, values: {"Length": 175,"Extension" : false}  },
                {  title: {"en": "179' (175' + 4' ext)"}, title_meters: {"en": "54.6m (53.3m + 1.2m ext)"}, values: {"Length": 175, "Extension" : true}  },
                {  title: {"en": "180'"}, title_meters: {"en": "54.9m"}, values: {"Length": 180,"Extension" : false} },
                {  title: {"en": "186.7'"}, title_meters: {"en": "56.9m"}, values: {"Length": 186.7,"Extension" : false} },
                {  title: {"en": "194'"}, title_meters: {"en": "59.1m"}, values: {"Length": 194,"Extension" : false} },
                {  title: {"en": "204'"}, title_meters: {"en": "62.2m"}, values: {"Length": 204,"Extension" : false} },
                {  title: {"en": "213'"}, title_meters: {"en": "64.9m"}, values: {"Length": 213,"Extension" : false} },
            ],
            customValueOption: {
                cmp: {
                    type: "number",
                    title: { "en" : translationEn["custom-span-length"], "es": translationEs["custom-span-length"]},
                    fieldPath: "Length",
                    units: "ft",
                    hook: (value: number) => {
                        return [
                            { fieldPath: "Extension", value: false }
                        ]
                    }
                } as INumber                
            },
            maxWidth: 100
        } as IComplexChoice,
    }, {
        title: { "en" : translationEn["common-phrases"]["sltType"], "es": translationEs["common-phrases"]["sltType"]},
        arrayPath: "Tower",
        cmp: {
            type: "choice",
            fieldPath: "TowerType",
            title: "",
            options: [
                { id: "Standard", title: { "en" : translationEn["standard"], "es": translationEs["standard"]}, default: "true" },
                { id: "LowProfile", title: { "en" : translationEn["common-phrases"]["sltLowProfile"], "es": translationEs["common-phrases"]["sltLowProfile"]}},
                { id: "Sugargator", title: { "en" : translationEn["common-phrases"]["sltSugargator"], "es": translationEs["common-phrases"]["sltSugargator"]}},
                { id: "Supergator", title: { "en" : translationEn["common-phrases"]["sltSupergator"], "es": translationEs["common-phrases"]["sltSupergator"]}},
            ],
            enumNameHint: "TowerHeights",
        } as IChoice,
    }, {
        title: { "en" : translationEn["common-phrases"]["sltWrap"], "es": translationEs["common-phrases"]["sltWrap"]},
        arrayPath: "Tower",
        cmp: {
            type: "choice",
            fieldPath: "WrapAroundSpan",
            title: "",
            options: [
                { id: WrapAroundSpanTypes.None, title: "-", default: WrapAroundSpanTypes.None },
                { id: WrapAroundSpanTypes.TenDegree, title: "10°" },
                { id: WrapAroundSpanTypes.NinetyDegree, title: "90°" },
            ],
            enumNameHint: "WrapAroundSpanTypes",
            validate: true
        } as IChoice,
        visible: (sys: ISystemBase) => !(sys.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator
            || sys.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator)
    },
    {
        title: { "en" : translationEn["common-phrases"]["sltDisconnect-Abrv"], "es": translationEs["common-phrases"]["sltDisconnect-Abrv"]},
        arrayPath: "Span",
        cmp: {
            type: "boolean",
            title: "",
            fieldPath: "Disconnecting",
            hook: (value: boolean) => {
                if (value) {
                    return [
                        { fieldPath: "dropSpanStartRelativeToPreviousSpanStart", value: 0 },
                        { fieldPath: "dropSpanEndRelativeToPreviousSpanEnd", value: 0 }
                    ]
                }
            },
            autoChange: false
        } as IBoolean,
    }
];

interface Props {
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    systemType: SystemTypes;
    mapDesignMode?: boolean;
};

const SpansAndTowersTabRenderer: React.FC<Props> = (props) => {
    const authState = useContext(AuthCtx);

    const settings = React.useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;

    const [ vws, setVws ] = React.useState<IValidationWorkerState>({
        status: ValidationWorkerStatus.Validating,
        validate: async (sys, changingFieldPaths, improveScores) => {
            validate(sys);
            return new SystemValidationResult();
        },
        validateCmp: (system, cmp, fieldRoot, pageId) => {
            return getValidationResult(system);
        }
    })

    const system = props.dbPrj.state.layouts[props.layoutId].systems[props.systemId];
    const getValidationResult = (system: ISystemBase) => {
        const validationResult = new SystemValidationResult();
        if (system?.FlangedSide?.Span.length >1){
            //don't always have spans (e.g. creating a brand new system without optimization)
            // Last two spans cannot be wrapping:
            const flangedSpansWithoutEos = (system.FlangedSide?.Span || []).filter(x => !(x.EndBoom || x.SwingArm));
            for (let i = Math.max(flangedSpansWithoutEos.length - 2, 0); i < flangedSpansWithoutEos.length; i++) {
                const fvr = validationResult.getField("FlangedSide.Tower[" + i + "].WrapAroundSpan");
                if (system.FlangedSide?.Tower[i].WrapAroundSpan !== WrapAroundSpanTypes.None) {
                    fvr.severity = "error";
                }
                fvr.badValues.set(WrapAroundSpanTypes.TenDegree, { reasons: [ i18next.t("field-design-drawer.validation.error.wrapSpanTooClose") ]});
                fvr.badValues.set(WrapAroundSpanTypes.NinetyDegree, { reasons: [ i18next.t("field-design-drawer.validation.error.wrapSpanTooClose") ]});
            }
        }
        return validationResult;
    };
    const validate = (system: ISystemBase) => {
        setVws({
            ...vws,
            validationResult: undefined,
            status: ValidationWorkerStatus.Validating
        })
        const validationResult = getValidationResult(system);
        setVws({
            ...vws,
            validationResult,
            status: ValidationWorkerStatus.Validated
        })
    }

    React.useEffect(() => {
        validate(system)
    }, []);

    return (
        <SideTableRenderer
            {...props}
            cmp={{
                type: "sidetable",
                columns: cols,
                showButtons: true,
                cartDisconnectColumnIndex: cols.findIndex(x => "fieldPath" in x.cmp && x.cmp.fieldPath === "Disconnecting")
            } as ISideTable}
            ctx={new ComponentRenderContext(props.dbPrj, props.layoutId, props.systemId, {
                id: ""
            } as any, vws, authState, false)}
        />
    )
}

export default SpansAndTowersTabRenderer;