import { Box } from "@mui/material";
import * as turf from "@turf/turf";
import i18next, { t } from "i18next";
import * as React from "react";
import { FC } from 'react';
import { IrrigatedAreaHelper } from "../../../GeometryHelpers/IrrigatedAreaHelper";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import { DisplayAreaUnitBuilder } from "../../../helpers/areas";
import { DisplayLengthUnitBuilder } from "../../../helpers/lengths";
import { AnnotationType } from "../../../model/project/IAnnotation";
import { IProjectWithId } from "../../../routes/pages/ProjectList";
import { documentStyles } from "../../styles";

interface Props {
    systemIds: string[];
    layoutId: string;
    pwid: IProjectWithId;
}

const FieldSummaryTable: FC<Props> = React.forwardRef((props) => {
    const {systemIds, pwid, layoutId} = props;

    let colWidth = 100/3;
    let oneColWidth = colWidth + "%";
    let twoColWidth = (colWidth * 2) + "%";
    let threeColWidth = (colWidth * 3) + "%";

    let fontSize = "0.8em";
    let cellStyle = {...documentStyles.cell, justifyContent: "left", padding: 2, width: oneColWidth, fontSize};

    const ns = "reports.";

    let rows: JSX.Element[] = [];

    const getCell = (title: string, val: string, width?: string) => {
        return <div style={{ ...cellStyle, width: width ?? oneColWidth}}>
            <strong style={{fontFamily: "Arial Black"}}>{title}</strong>: {val}
        </div>;
    }
    
    const settings = React.useContext(DevSettingsCtx);

    let waterLineLength = 0;
    let electricLineLength = 0;

    let layout = pwid.project.layouts[layoutId!];

    layout.annotations.forEach((annotation) => {
        switch (annotation.type){
            case AnnotationType.canal:
            case AnnotationType.electricLine:
            case AnnotationType.line:
            case AnnotationType.waterLine:
                const line = annotation.lineAnnotation.line;
                const length = turf.length(turf.feature(line), { units: 'feet'});

                if (annotation.type === AnnotationType.electricLine){
                    electricLineLength += length;
                }
                else if (annotation.type === AnnotationType.waterLine){
                    waterLineLength += length;
                }
                break;
        }
    });

    let waterLineLength_ = new DisplayLengthUnitBuilder(waterLineLength, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(0)
        .appendString(" ")
        .appendSymbol()
        .toString();

    let electricLineLength_ = new DisplayLengthUnitBuilder(electricLineLength, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(0)
        .appendString(" ")
        .appendSymbol()
        .toString();

    rows.push(<div style={documentStyles.row}>{...[
            getCell(i18next.format(t('total-systems'), 'capitalize-each'), systemIds.length.toString(), oneColWidth),
            getCell(i18next.format(t('water-line'), 'capitalize-each'), waterLineLength_, oneColWidth),
            getCell(i18next.format(t('electric-line'), 'capitalize-each'), electricLineLength_, oneColWidth)
        ]}
        </div>
    );

    const irrigatedAreaHelper = React.useMemo(() => {
        return new IrrigatedAreaHelper({
            project: pwid.project, layoutId
        })
    }, [ pwid.project, layoutId ]);
    
    let fieldArea = new DisplayAreaUnitBuilder(irrigatedAreaHelper.getFieldAcres(), 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(3)
        .appendString(" ")
        .appendFullName()
        .toString();

    let irrigatedArea = new DisplayAreaUnitBuilder(irrigatedAreaHelper.getIrrigatedAcres(systemIds), 'acres')
        .convert(settings.dealerSettings.display.current.areas)
        .appendValue(3)
        .appendString(" ")
        .appendFullName()
        .toString();

    rows.push(<div style={documentStyles.row}>{...[
            getCell(i18next.format(t(ns + 'field-area'), 'capitalize-each'), fieldArea),
            getCell(i18next.format(t('map-display.irrigated-area'), 'capitalize-each'),irrigatedArea),
        ]}
        </div>
    );

    return <Box style={{border: "1px solid black", marginBottom: 20}}>
        <Box style={documentStyles.heading}>
            <Box style={{...documentStyles.sectionHeader, fontSize: 14}}>{i18next.format(t('field-details'), 'capitalize-each')}</Box>
        </Box>
        <Box style={{paddingLeft: 15, paddingRight: 15}}>
            <Box style={{...documentStyles.table, marginBottom: 0}}>
                {rows}
            </Box>
        </Box>
    </Box>
});

export default FieldSummaryTable;