import { circle, distance } from "@turf/turf";
import { Feature } from "geojson";
import { MapMouseEvent } from "mapbox-gl";
import ISelectModeExtension, { LngLat } from "./ISelectModeExtension";

import createVertex from "./copied-from-mapbox-gl-draw/create_vertex";

function createSupplementaryPointsForCircle(geojson) {
    const { properties, geometry } = geojson;
  
    if (!properties.user_isCircle && !properties.isCircle) return null;
  
    const supplementaryPoints = [];
    const vertices = geometry.coordinates[0].slice(0, -1);
    for (let index = 0; index < vertices.length; index += Math.round((vertices.length / 4))) {
      supplementaryPoints.push(createVertex(properties.id, vertices[index], `0.${index}`, false));
    }
    return supplementaryPoints;
  }

export const CircleSelectModeExtension: ISelectModeExtension = {
    supportsEditingFeature: function (geojson: Feature): boolean {
        return geojson.properties.user_isCircle || geojson.properties.isCircle;
    },

    createSupplementaryPoints: function (geojson: Feature): Feature[] {
        return createSupplementaryPointsForCircle(geojson);
    },

    dragVertex: function (state: any, e: MapMouseEvent, delta: LngLat) {
        const geojson: Feature = state.feature;
        const center = geojson.properties.center;
        const movedVertex = [e.lngLat.lng, e.lngLat.lat];
        const radius = distance(center, movedVertex, {units: 'kilometers'});
        const circleFeature = circle(center, radius);
        (geojson as any).incomingCoords(circleFeature.geometry.coordinates);
        geojson.properties.radiusInKm = radius;
    }
}