import { IEnhProgress } from "../../Progress/IEnhProgress";
import { QuantizedSACOptimizationState } from "../QuantizedSACOptimizationState";

export class ReverseSACOptimizer {
    public readonly State: QuantizedSACOptimizationState;
    
    public constructor(state: QuantizedSACOptimizationState) {
        this.State = state;
    }

    public async Optimize(progress: IEnhProgress): Promise<boolean> {
        progress.SetStatusMessage("Reverse-filling suboptimal solution");
        progress.Report(0);

        let improved = true;
        while (improved) {
            if (await progress.isCancelPending()) {
                return false;
            }

            improved = false;

            for (let iPivotAngle = this.State.iPivotAngleMin; iPivotAngle <= this.State.iPivotAngleMax; iPivotAngle++) {
                const iPivotAnglePrev = this.State.GetPrevPivotAngleIndex(iPivotAngle);
                const iPivotAnglePrev2 = iPivotAnglePrev !== -1 ? this.State.GetPrevPivotAngleIndex(iPivotAnglePrev) : -1;
                const iPivotAngleNext = this.State.GetNextPivotAngleIndex(iPivotAngle);
                const iPivotAngleNext2 = iPivotAngleNext !== -1 ? this.State.GetNextPivotAngleIndex(iPivotAngleNext) : -1;

                let iExtensionAngle = this.State.BestSolution[iPivotAngle - this.State.iPivotAngleMin];
                const iExtensionAnglePrev = iPivotAnglePrev !== -1 ? this.State.BestSolution[iPivotAnglePrev - this.State.iPivotAngleMin] : -1;
                const iExtensionAnglePrev2 = iPivotAnglePrev2 !== -1 ? this.State.BestSolution[iPivotAnglePrev2 - this.State.iPivotAngleMin] : -1;
                const iExtensionAngleNext = iPivotAngleNext !== -1 ? this.State.BestSolution[iPivotAngleNext - this.State.iPivotAngleMin] : -1;
                const iExtensionAngleNext2 = iPivotAngleNext2 !== -1 ? this.State.BestSolution[iPivotAngleNext2 - this.State.iPivotAngleMin] : -1;

                while (iExtensionAngle !== 0
                    && this.State.CheckConstraintsOrder0(iPivotAngle, iExtensionAngle - 1)
                    && (iExtensionAnglePrev === -1 || this.State.CheckConstraintsOrder1(iExtensionAnglePrev, iExtensionAngle - 1))
                    && (iExtensionAngleNext === -1 || this.State.CheckConstraintsOrder1(iExtensionAngle - 1, iExtensionAngleNext))
                    && (iExtensionAnglePrev === -1 || iExtensionAngleNext === -1 || this.State.CheckConstraintsOrder2(iExtensionAnglePrev, iExtensionAngle - 1, iExtensionAngleNext))
                    && (iExtensionAnglePrev2 === -1 || iExtensionAnglePrev === -1 || this.State.CheckConstraintsOrder2(iExtensionAnglePrev2, iExtensionAnglePrev, iExtensionAngle - 1))
                    && (iExtensionAngleNext2 === -1 || iExtensionAngleNext === -1 || this.State.CheckConstraintsOrder2(iExtensionAngle - 1, iExtensionAngleNext, iExtensionAngleNext2)))
                {
                    improved = true;
                    iExtensionAngle--;
                    this.State.BestSolution[iPivotAngle - this.State.iPivotAngleMin] = iExtensionAngle;
                }
            }
        }

        return true;
    }
}