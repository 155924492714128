import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { bbox, buffer, convertLength, Feature, featureCollection, Geometry, GeometryCollection, point } from "@turf/turf";
import DbCtx from "../../db/DbCtx";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { BoundaryHelper } from "../../GeometryHelpers/BoundaryHelper";
import IFieldBoundary from "../../model/project/IFieldBoundary";
import IProject from "../../model/project/IProject";
import { IProjectWithId } from "../../routes/pages/ProjectList";
import Map, { IDrawUpdateExtEvent } from "../Map";
import MapControl from "./MapControl";

interface Props {
    onDrawUpdateExt?: (event: IDrawUpdateExtEvent) => any;
    projects: IProjectWithId[];
}

const createFieldBoundaryFeature = (id: string, fieldBoundary: IFieldBoundary) => {
    let feature = {
        type: "Feature",
        geometry: BoundaryHelper.getPolygon(fieldBoundary),
        id: uuidv4(),
        properties: {
            rdpFeatureType: "fieldBoundary",
            rdpProjectId: id
        },
    } as Feature;
    return feature;
}

const getProjectDrawFeatures = (id: string, project: IProject): Feature[] => {
    const r: Feature[] = [];
    // take the first found field boundary in a layout within the project:
    Object.values(project.layouts).some(layout => {
        if (layout.fieldBoundary) {
            r.push(createFieldBoundaryFeature(id, layout.fieldBoundary));
            return true;
        }
        else {
            return false;
        }
    })
    if (!r.length) {
        // then we need some way to represent the project without a boundary
        if (!project.approximateLocation) {
            // We have no way to know the location of this project
            console.log(`project ${project.name} does not have a field boundary or an approximate location`);
        }
        else {
            const approxLocationBuffer = buffer(
                point(project.approximateLocation),
                300,
                { units: 'feet' }
            )
            r.push(createFieldBoundaryFeature(id, approxLocationBuffer.geometry))
        }
    }
    return r;
}

const getDrawFeatures = (projects: IProjectWithId[]) => {
    return projects
        .flatMap(({ id, project }) => getProjectDrawFeatures(id, project))
        .map(x => {
            x.properties.rdpProjectListMap = true;
            return x;
        });
}
export const getBoundingBox = (features: Feature<Geometry | GeometryCollection>[]) => {
    if (features.length === 0) {
        return [ -180, -90, 180, 90];
    }
    const boundingBox = 
        bbox(
            buffer(
                featureCollection(features),
                convertLength(3000, 'feet', 'meters'), { units: 'meters' }
            )
        )
    
    if (boundingBox.some(v => isNaN(v) || !isFinite(v))) {
        return [ -180, -90, 180, 90];
    }
    return boundingBox;
}

const ProjectsMap: FC<Props> = (props) => {
    const dbState = useContext(DbCtx);
    const projects = props.projects;

    // turf.buffer only supports miles, kilometers, and degrees
    // since mapbox bounds are only allpied on first draw, calculate the initial bounds once here:
    const  [ initialBounds ] = useState(getBoundingBox(getDrawFeatures(projects)));
    const [ drawFeatures, setDrawFeatures ] = useState(getDrawFeatures(projects));
    const devSettingsState = useContext(DevSettingsCtx);

    useEffect(() => {
        setDrawFeatures(getDrawFeatures(projects));
    }, [dbState, projects]);

    return (
            <Map
                // bounds only applies when the map is first drawn. The Map does not respond to
                // changes to its bounds prop.
                bounds={initialBounds as [number, number, number, number]}
                drawMode={"project_select"}
                onDrawUpdateExt={props.onDrawUpdateExt}
                drawFeatures={drawFeatures}
                key={"map_layer_type_" + devSettingsState.mapSettings.layerType} //if map layer settings change re-render this section
            >
                <MapControl 
                    drawFeatures={drawFeatures}
                />
            </Map>
    );
}


export default ProjectsMap;