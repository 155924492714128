import { feature } from "@turf/turf";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { BoundaryHelper } from "../../../../../../GeometryHelpers/BoundaryHelper";
import { ObstacleHelper } from "../../../../../../GeometryHelpers/ObstacleHelper";
import CenterPivotGeometryHelper from "../../../../../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper from "../../../../../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { ImportExportFeature } from "../../../../../../helpers/importExport";
import { AnnotationType } from "../../../../../../model/project/IAnnotation";
import IProject from "../../../../../../model/project/IProject";

interface IArgs {
    project: IProject;
    layoutId?: string;
}

export const projectToImportExportFeatures = ({ project, layoutId }: IArgs) => {
    const features: ImportExportFeature[] = [];

    // project specific:

    // layout specific:
    const layout = layoutId ? project.layouts[layoutId] : undefined;
    if (layout) {
        if (layout.fieldBoundary) {
            features.push(feature(BoundaryHelper.getPolygon(layout.fieldBoundary), {
                importType: 'boundary'
            }))
        }
        if (layout.wetAreaBoundary) {
            features.push(feature(BoundaryHelper.getPolygon(layout.wetAreaBoundary), {
                importType: 'wetAreaBoundary'
            }))
        }
        if (layout.pivotCenterBoundary) {
            features.push(feature(BoundaryHelper.getPolygon(layout.pivotCenterBoundary), {
                importType: 'pivotCenterBoundary'
            }))
        }
        for (const obstacle of layout.obstacles) {
            features.push(feature(ObstacleHelper.getPolygon(obstacle), {
                importType: 'obstacle'
            }))
        }
        for (const obstacle of layout.wheelObstacles) {
            features.push(feature(ObstacleHelper.getPolygon(obstacle), {
                importType: 'wheelObstacle'
            }))
        }
    
        for (const annotation of layout.annotations) {
            switch (annotation.type) {
                case AnnotationType.canal:
                    if (!annotation.lineAnnotation) continue;
                    features.push(feature(annotation.lineAnnotation.line, {
                        importType: 'canal'
                    }))
                    break;
                case AnnotationType.electricLine:
                    if (!annotation.lineAnnotation) continue;
                    features.push(feature(annotation.lineAnnotation.line, {
                        importType: 'electricLine'
                    }))
                    break;
                case AnnotationType.waterLine:
                    if (!annotation.lineAnnotation) continue;
                    features.push(feature(annotation.lineAnnotation.line, {
                        importType: 'waterLine'
                    }))
                    break;
                case AnnotationType.line:
                    if (!annotation.lineAnnotation) continue;
                    features.push(feature(annotation.lineAnnotation.line, {
                        importType: 'line'
                    }))
                    break;
                case AnnotationType.point:
                    if (!annotation.pointAnnotation) continue;
                    features.push(feature(annotation.pointAnnotation.point, {
                        importType: 'point'
                    }))
                    break;
                case AnnotationType.pump:  
                    if (!annotation.pointAnnotation) continue;
                    features.push(feature(annotation.pointAnnotation.point, {
                        importType: 'pump'
                    }))
                    break;
                case AnnotationType.label:
                    if (!annotation.textAnnotation) continue;
                    features.push(feature(annotation.textAnnotation.point, {
                        importType: 'label',
                        label: annotation.textAnnotation.text
                    }))
                    break;
            
                default:
                    console.log("Unkown annotation", annotation.type);
                    break;
            }
        }
    }

    // system specific
    const systems = layout ? Object.entries(layout.systems) : [];
    for (const [systemId, system] of systems) {
        let geometryHelper: LateralGeometryHelper | CenterPivotGeometryHelper;
        switch (system.SystemProperties.SystemType) {
            case SystemTypes.CenterPivot:
                geometryHelper = new CenterPivotGeometryHelper({
                    project: project,
                    layoutId: layoutId!,
                    systemId
                });
                break;
            case SystemTypes.CanalFeedMaxigator:
                case SystemTypes.HoseFeedMaxigator:
                geometryHelper = new LateralGeometryHelper({
                    project: project,
                    layoutId: layoutId!,
                    systemId
                });
                break;
            default:
                console.log("No geometry helper for system type:", system.SystemProperties.SystemType);
                continue;
        }
        features.push(...geometryHelper.getExportFeatures());
    }

    return features;
}