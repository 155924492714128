import {
    CenterPivotTypes,
    ICircleKwikTow as KwikTow,
    SpanTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/poSchema";
import CSection2 from "./CSection2";
import { TireSelector } from "./CommonFunctions";
import * as ohf from "./OtherHelpers";
import PartsAssembler from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as sysf from "./SystemFunctions";
import { strLanguageText } from "./TranslationsLib";
import { Side, Tires } from "./Types";
import { TubesOK, TwoThirtyVoltSinglePhaseOK } from "./Valids.Valids";
export class KwikTowClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(strLanguageText("sltKwikTowSection"));
        return false;
        }*/
        if (!(TwoThirtyVoltSinglePhaseOK(
            this.Quote.System.Circle.SinglePhase230VoltSystem,
            this.Quote.System.SystemProperties.SystemType,
            CenterPivotTypes.E2065,
            this.Quote.System.Circle.EngineDrivenMiniPivot,
            ohf.IsInternational(this.Quote.System.DealerProperties) // TODO Icon - Check this makes sense
        ))) {
            this.Quote.Warnings.Add(strLanguageText("slt230VoltSinglePhase"));
        }
        if (!(TubesOK(this.Quote.System.Circle.KwikTow.Tires.TubesForTires, {
                TireSize: this.Quote.System.Circle.KwikTow.Tires.TireSize,
                TireType: this.Quote.System.Circle.KwikTow.Tires.TireType,
            }))) {
            this.Quote.Warnings.Add(strLanguageText("sltTubesForTires"));
        }
        if (this.Quote.System.Circle.KwikTow.SafetyValve) {
            if (sysf.IsEDMP(this.Quote.System)) {
                this.Quote.Warnings.Add(strLanguageText("sltSafetyValve"));
            }
        }
        this.Quote.Warnings.Add(strLanguageText("sltDontTowDownhill"), WarningLevels.Informational);
    }
    public WritePO = (PO: PartsAssembler) => {
        let iSelectedTire: number;
        let eFirstSpanType: SpanTypes = SpanTypes.E2100;
        let eFirstSpanLength: number = 80;
        let iNumberOfSpans: number = 0;
        if (this.Quote.RightSpansClass.FieldSet.DataValid()) {
            let s: Side = this.Quote.System.FlangedSide;
            iNumberOfSpans = sidef.NumberOfSpans(s);
            if (iNumberOfSpans > 0) {
                eFirstSpanType = s.Span[1 - 1].SpanType;
                eFirstSpanLength = s.Span[1 - 1].Length;
            }
        }
        let kt: KwikTow = this.Quote.System.Circle.KwikTow;
        if ([SpanTypes.S2065G, SpanTypes.PL2065G].indexOf(eFirstSpanType) !== -1) {
            PO.InsertPart(schema.groups.KwikTow_KwikTowPivotTowerAssembly.groupId, "394801SS", 1); // PIVOT TOWER ASY-KWIK TOW-SS
        }
        else {
            PO.InsertPart(schema.groups.KwikTow_KwikTowPivotTowerAssembly.groupId, "394801-G", 1); // PIVOT TOWER ASY-KWIK TOW-GALV
        }
        let t: Tires = kt.Tires;
        iSelectedTire = TireSelector(t.TireSize, t.TireType, t.OneTireMountedInReverse, t.TubesForTires);
        PO.InsertPartByIteration(schema.groups.KwikTow_TireandWheelAssembly.groupId, iSelectedTire, 1);
        if (kt.ExtraPivotAnchors > 0) {
            PO.InsertPart(schema.groups.KwikTow_ExtraPivotAnchor.groupId, "392801-G", kt.ExtraPivotAnchors); // PIVOT ANCHOR-KWIK TOW-GALV
        }
        if (kt.MotorizedCenter) {
            PO.InsertPart(schema.groups.KwikTow_MotorizedKwikTow.groupId, "392871", 1); // POWER KIT-ONE WHEEL-KT PIVOT
        }
        switch (eFirstSpanType) {
            case SpanTypes.E2085:
            case SpanTypes.PL2085G:
                PO.InsertPart(schema.groups.KwikTow_PivotTowerTop.groupId, "396229-G", 1); // PVT TWR TOP-KT-8 5/8-GALV-EII
                break;
            case SpanTypes.A80G:
                PO.InsertPart(schema.groups.KwikTow_PivotTowerTop.groupId, "396724-G", 1); // PIVOT TOWER TOP-KT A80G
                break;
            case SpanTypes.E2065:
            case SpanTypes.E2060:
            case SpanTypes.E2665:
            case SpanTypes.E2660:
            case SpanTypes.E2045:
                PO.InsertPart(schema.groups.KwikTow_PivotTowerTop.groupId, "396329-G", 1); // PIVOT TOWER TOP-KT-6 5/8" EII
                break;
            case SpanTypes.S2065G:
            case SpanTypes.PL2065G:
                PO.InsertPart(schema.groups.KwikTow_PivotTowerTop.groupId, "396329SS", 1); // PIVOT TOWER TOP-KT S2065
                break;
            case SpanTypes.AlumIV:
            case SpanTypes.A60G:
                PO.InsertPart(schema.groups.KwikTow_PivotTowerTop.groupId, "396729-G", 1); // PIVOT TOWER TOP-KT ALUMI 4-G
                break;
        }
        if (!(this.Quote.System.Circle.EngineDrivenMiniPivot) && iNumberOfSpans > 1) {
            switch (eFirstSpanLength) {
                case 118:
                    PO.InsertPart(schema.groups.KwikTow_TowRodKit.groupId, "392805-G", 1); // TOW ROD EXT-118 KT-E88/65/60-G
                    break;
                case 137:
                    PO.InsertPart(schema.groups.KwikTow_TowRodKit.groupId, "392806-G", 1); // TOW ROD EXT-137 KT-E88/65/60-G
                    break;
                case 140:
                    PO.InsertPart(schema.groups.KwikTow_TowRodKit.groupId, "392802-G", 1); // TOW ROD EXT-140 KT-E88/65/60-G
                    break;
                case 156:
                    PO.InsertPart(schema.groups.KwikTow_TowRodKit.groupId, "392807-G", 1); // TOW ROD EXT-156 KT-E88/65/60-G
                    break;
                case 160:
                    PO.InsertPart(schema.groups.KwikTow_TowRodKit.groupId, "392803-G", 1); // TOW ROD EXT-160 KT-E65/60-GALV
                    break;
                case 175:
                    PO.InsertPart(schema.groups.KwikTow_TowRodKit.groupId, "392808-G", 1); // TOW ROD EXT-175 KT-E65/60-GALV
                    break;
                case 180:
                    PO.InsertPart(schema.groups.KwikTow_TowRodKit.groupId, "392804-G", 1); // TOW ROD EXT-180 KT-E65/60-GALV
                    break;
                case 186.7:
                    PO.InsertPart(schema.groups.KwikTow_TowRodKit.groupId, "392810-G", 1); // TOW ROD EXT-186.7 KT-E65/60-G
                    break;
                case 194:
                    PO.InsertPart(schema.groups.KwikTow_TowRodKit.groupId, "392809-G", 1); // TOW ROD EXT-194 KT-E65/60-GALV
                    break;
            }
        }
        if (kt.HydraulicLift) {
            PO.InsertPart(schema.groups.KwikTow_HydraulicLift.groupId, "353349", 1); // HYDRAULIC LIFT OPTION-KWIK TOW
        }
        if (this.Quote.System.Circle.KwikTow.SafetyValve) {
            PO.InsertPart(schema.groups.OptionsandAccessories_SafetyValve.groupId, "352164", 1); // SLEEVE VALVE-6"WTR SHUTDOWN-KT
        }
    }
}