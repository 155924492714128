import { useMsal } from "@azure/msal-react";
import * as React from "react";
import { FC, PropsWithChildren, useContext } from "react";
import AuthCtx from "../auth/AuthCtx";
import ApiClientCtx from "./ApiClientCtx";
import FetchApiClient from "./FetchApiClient";

const ApiClientProvider: FC<PropsWithChildren<{}>> = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const authState = useContext(AuthCtx);

  const account = accounts.filter(x => x.localAccountId === authState.loggedInUserId)[0];

  return (
    <ApiClientCtx.Provider value={new FetchApiClient(instance, account, authState.assumedUserId)}>
      {props.children}
    </ApiClientCtx.Provider>
  );
};

export default ApiClientProvider;