import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { FC, useEffect } from "react";
import IDbProject from "../../../../db/IDbProject";

interface IOnConfirmArgs {
  systemIds: string[];
}

interface Props {
    onClose: () => void;
    open: boolean;
    onConfirm: (args: IOnConfirmArgs) => void;
    layoutId: string;
    project: IDbProject;
}

const SystemSelectDialog: FC<Props> = (props) => {
  let systemIdSystemPairs = Object.entries(props.project.state.layouts[props.layoutId].systems);
  const [ dialogOptions, setDialogOptions ] = React.useState<IOnConfirmArgs>({
    systemIds: systemIdSystemPairs.map(([systemId]) => systemId),
  });

  useEffect(() => {
    systemIdSystemPairs = Object.entries(props.project.state.layouts[props.layoutId].systems);
    setDialogOptions({systemIds: systemIdSystemPairs.map(([systemId]) => systemId)});
}, [props.layoutId, props.project]);

  const handleOnConfirm = () => {   
    props.onConfirm(dialogOptions);
  }

  return (
    <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="select-systems-dialog"
    >
    <DialogTitle id="select-systems-dialog">
        {t("reports.select-systems")}
    </DialogTitle>
    <DialogContent>
        <div style={{marginBottom: "0.3em"}}>{t('reports.which-systems')}</div>
        <div style={{display: "flex", justifyContent: "center"}}><Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {
                systemIdSystemPairs.map(([systemId, system]) => {
                    return (
                        <FormControlLabel
                          label={system.name}
                          control={
                                <Checkbox 
                                    checked={dialogOptions.systemIds.includes(systemId)} 
                                    onChange={(ev) => {
                                        let systemIds = dialogOptions.systemIds.filter(x => x !== systemId);
                                        if (ev.target.checked) {
                                            systemIds.push(systemId);
                                        }
                                        setDialogOptions({ ...dialogOptions, systemIds })
                                    }}
                                />
                            }
                        />
                    )
                })
            }
        </Box></div>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose}>{t('cancel')}</Button>
      <Button onClick={handleOnConfirm} autoFocus>{t('confirm')}</Button>
    </DialogActions>
  </Dialog>
  );
}

export default SystemSelectDialog;