import { Point, Position } from "@turf/turf";
import IAction from "rdptypes/IAction";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import IBoolean from "rdptypes/roe/IBoolean";
import IBooleanGroup from "rdptypes/roe/IBooleanGroup";
import ICard from "rdptypes/roe/ICard";
import IChoice from "rdptypes/roe/IChoice";
import IComponent from "rdptypes/roe/IComponent";
import INumber from "rdptypes/roe/INumber";
import { createNewUpdateSystemPropertyAction } from "../actions/UpdateSystemProperty";
import IAuthState from "../auth/IAuthState";
import { getStaticFieldSettings } from "../db/DevSettingsDbProvider";
import { IDealerCustomerSettings, IDealerPricing } from "../db/IDevSettingsState";
import { ProjectType } from "../model/project/IProject";
import { createNewLayoutAction } from "./NewLayoutAction";
import { createNewSystemActionV2 } from "./NewSystemActionV2";
import { createNewUpdateProjectPropertyAction } from "./UpdateProjectProperty";
import { createSystemDefaultActions } from "./createSystemDefaultActions";

export const getDefaultNewProjectActions = (authState: IAuthState, growerId: string) => {
    const actions: IAction[] = [
        createNewUpdateProjectPropertyAction("name", "New Field", authState),
        // set field settings:
        createNewUpdateProjectPropertyAction("systemClearance", getStaticFieldSettings().minObstacleClearanceFeet, authState),
        createNewUpdateProjectPropertyAction("growerId", growerId, authState),
    ];
    const { layoutId, action } = createNewLayoutAction(authState);
    actions.push(action);

    return {
        layoutId,
        actions
    }
}

export interface IGetDefaultNewProjectLayoutSystemActionsArgs {
    projectType: ProjectType;
    projectName: string;
    growerId: string;
    approximateLocation: Position;
    systemType: SystemTypes;
    centerPivotPoint: Point;
    customerSettings: IDealerCustomerSettings;
    dealerPricing?: IDealerPricing;
}
export interface IGetDefaultNewProjectLayoutSystemActionsResult {
    layoutId: string;
    systemId: string;
    actions: IAction[];
}
export const getDefaultNewProjectLayoutSystemActions = (
    args: IGetDefaultNewProjectLayoutSystemActionsArgs, authState: IAuthState
): IGetDefaultNewProjectLayoutSystemActionsResult => {
    const actions: IAction[] = [];
    
    // create new Project and Layout
    const newProjectActions = getDefaultNewProjectActions(authState, args.growerId);
    actions.push(...(newProjectActions.actions));

    // update project meta:
    actions.push(createNewUpdateProjectPropertyAction("projectType", args.projectType, authState));
    actions.push(createNewUpdateProjectPropertyAction("name", args.projectName, authState));
    actions.push(createNewUpdateProjectPropertyAction("approximateLocation", args.approximateLocation, authState));
    
    // create new system:
    const newSystemAction = createNewSystemActionV2(
        newProjectActions.layoutId, args.systemType, 
        args.customerSettings, args.dealerPricing,
        authState
    );
    actions.push(newSystemAction.action);

    // add system defaults:
    actions.push(...createSystemDefaultActions(
        newProjectActions.layoutId,
        newSystemAction.systemId,
        authState
    ));
    
    // add system pivot location:
    actions.push(createNewUpdateSystemPropertyAction(
        newProjectActions.layoutId,
        newSystemAction.systemId,
        "centerPivot.point",
        args.centerPivotPoint,
        authState
    ));
  
    return {
        layoutId: newProjectActions.layoutId,
        systemId: newSystemAction.systemId,
        actions
    };
}

const createNewSystemDefaultsComponentActions = (
    layoutId: string,
    systemId: string,
    cmp: IComponent,
    authState: IAuthState,
    ): IAction[] => {
        const actions: IAction[] = [];
        switch (cmp.type) {
            case "choice":
                const choice = cmp as IChoice;
                for (const option of choice.options) {
                    if (option.default) {
                        actions.push(createNewUpdateSystemPropertyAction(
                            layoutId,
                            systemId,
                            choice.fieldPath,
                            option.id,
                            authState
                        ));
                    }
                }
                break;
            case "card":
                for (const cmpInner of ((cmp as ICard).children || [])) {
                    actions.push(...createNewSystemDefaultsComponentActions(
                        layoutId,
                        systemId,
                        cmpInner,
                        authState)
                    );
                }
                break;
            case "booleangroup":
                for (const cmpInner of (cmp as IBooleanGroup).components) {
                    actions.push(...createNewSystemDefaultsComponentActions(
                        layoutId,
                        systemId,
                        cmpInner,
                        authState)
                    );
                }
                break;
            case "boolean":
                const booleanCmp = cmp as IBoolean;
                if (booleanCmp.default) {
                    actions.push(createNewUpdateSystemPropertyAction(
                        layoutId,
                        systemId,
                        booleanCmp.fieldPath,
                        true,
                        authState
                    ));
                }
                break;
            case "number":
                const numberCmp = cmp as INumber;
                if (numberCmp.default !== undefined) {
                    actions.push(createNewUpdateSystemPropertyAction(
                        layoutId,
                        systemId,
                        numberCmp.fieldPath,
                        numberCmp.default,
                        authState
                    ));
                }
                break;
            case "text":
                const textCmp = cmp as INumber;
                if (textCmp.default !== undefined) {
                    actions.push(createNewUpdateSystemPropertyAction(
                        layoutId,
                        systemId,
                        textCmp.fieldPath,
                        textCmp.default,
                        authState
                    ));
                }
                break;
        }

        return actions;
}
export { createSystemDefaultActions } from "./createSystemDefaultActions";
