import "@fontsource/libre-franklin/900.css";
import Check from "@mui/icons-material/Check";
import CloudOff from "@mui/icons-material/CloudOff";
import CloudSync from "@mui/icons-material/CloudSync";
import SyncAlt from "@mui/icons-material/SyncAlt";
import { Badge, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import { t } from "i18next";
import * as React from "react";
import { FC, useContext } from "react";
import { SyncStatus } from "../../db/ISyncState";
import SyncStateCtx from "../../db/SyncStateCtx";

dayjs.extend(relativeTime)

interface Props {

}

const SyncMenuItem: FC<Props> = (props) => {

    const syncState = useContext(SyncStateCtx);

    const [syncMenuTarget, setSyncMenuTarget] = React.useState<null | HTMLElement>(null);

    const ns = "nav.sync.";

    const openSyncMenu = (event: React.MouseEvent<HTMLElement>) => {
        setSyncMenuTarget(event.currentTarget);
    };

    const closeSyncMenu = () => {
        setSyncMenuTarget(null);
    };

    const syncNow = () => {
        syncState.beginSync();
        closeSyncMenu();
    };

    let statusText: JSX.Element;
    let color: "info" | "warning" | "success" | "error" | "secondary";
    let badgeContent: JSX.Element | undefined;
    switch (syncState.status) {
        case SyncStatus.NotStarted:
            statusText = <div>{t(ns + "not-yet-synchronized")}</div>;
            color = undefined;
            badgeContent = undefined;
            break;
        case SyncStatus.InProgress:
            if (syncState.progressPercent !== undefined) {
                statusText = <div>{`${t(ns + "sync-in-progress")} (${syncState.progressPercent.toFixed(0)}%)...`}</div>;
                badgeContent = <div>{`${syncState.progressPercent.toFixed(0)}%`}</div>;
            } else {
                statusText = <div>{t(ns + "sync-in-progress") + "..."}</div>;
                badgeContent = <SyncAlt sx={{width: 12}} />;
            }
            color = "secondary";
            break;
        case SyncStatus.Offline:
            statusText = <div>{t(ns + "working-offline")}</div>;
            color = "warning";
            badgeContent = <CloudOff sx={{width: 12}} />;
            break;
        case SyncStatus.Success:
            statusText = <div>{t(ns + "sync-successful")}</div>;
            color = "success";
            badgeContent = <Check sx={{width: 12}} />;
            break;
        case SyncStatus.Error:
            statusText = <div>{t(ns + "sync-failed")}</div>;
            color = "error";
            badgeContent = <div>!</div>;
            break;
    }

    if (syncState.lastSyncSuccess !== undefined) {
        statusText = <div>{statusText}<div>Last successful synchronization {dayjs().to(syncState.lastSyncSuccess)}</div></div>
    }

    return (<>
    <Tooltip title={statusText} placement="bottom">
    <IconButton
        size="large"
        aria-label="synchronization controls"
        aria-haspopup="true"
        onClick={openSyncMenu}
        color="inherit"
    >
        { color && <Badge color={color} badgeContent={badgeContent}>
            <CloudSync />
        </Badge> }
        { !color && <CloudSync /> }
    </IconButton>
        </Tooltip>
                {syncState.status !== SyncStatus.InProgress && <Menu
                    anchorEl={syncMenuTarget}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={!!syncMenuTarget}
                    onClose={closeSyncMenu}
                >
                    <MenuItem onClick={syncNow}>{t(ns + "sync-now")}</MenuItem>
                </Menu>}
        </>        
    );
};

export default SyncMenuItem;