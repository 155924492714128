import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { FC, useState } from "react";

interface Props {
    onClose: (text: string) => void;
    onCancel: () => void;
    open: boolean;
}

const AddLabelDialog: FC<Props> = (props) => {
  const [labelText, setLabelText] = useState("");

  React.useEffect(() => {
    setLabelText("")
  }, [props.open])
  let error = labelText === "";
  const ns = "map-display.";

    return (
      <Dialog onClose={props.onCancel} open={props.open}>
        <DialogTitle>{t(ns + "label-text")}</DialogTitle>
          <DialogContent>
              <DialogContentText>
                {t(ns + "name-label-annotation")}
              </DialogContentText>
              <TextField
                error={error}
                autoFocus
                id="label-text"
                fullWidth
                variant="standard"
                onChange={(e) => {
                  setLabelText(e.target.value);
                }}
              />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onCancel}>{t("cancel")}</Button>
            <Button onClick={() => {
              if (!error) {
                props.onClose(labelText);
                setLabelText("");
              }
            }}>{t("ok")}</Button>
          </DialogActions>
      </Dialog>
    );
  }

  export default AddLabelDialog;