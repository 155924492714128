import { Point } from '@turf/turf';
import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from '../auth/IAuthState';
import { AnnotationType } from '../model/project/IAnnotation';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordProjectChange } from './helpers';

const actionTypeId = "NewTextAnnotation";

interface ActionData extends IActionData {
    layoutId: string;
    point: Point;
    text: string;
    annotationType: AnnotationType;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const layout = state.layouts[data.layoutId];
    if (!layout) return;
    layout.annotations.push({
        type: data.annotationType,
        textAnnotation: {
            point: data.point,
            text: data.text
        }
    });
    recordProjectChange(action, state);
});

export const createNewTextAnnotation = (layoutId: string, type: AnnotationType, point: Point, text: string, authState: IAuthState) => {
    return createAction(
        actionTypeId,
        {
            layoutId,
            point,
            text,
            annotationType: type
        } as ActionData,
        authState);
};