import { Card, CardContent, Typography } from "@mui/material";
import IDbGrower from "rdptypes/api/IGrowerBase";
import * as React from "react";
import { useState } from "react";
import { IProjectWithId } from ".";
import ProjectsMap from "../../../components/ProjectsMap";
import {
    formatDateWithTime,
    formatProjectType,
} from "../../../docGeneration/DocumentGenerationHelpers";
interface Props {
  searchRegex: RegExp | undefined;
  navigateTo: (projectId: string) => void;
  projects: IProjectWithId[];
  growers: { [id: string]: IDbGrower; };
}

const ProjectListMapTab: React.FC<Props> = (props) => {
  const [projectId, setProjectId] = useState<string | undefined>(undefined);
  const selectedProject =
    projectId === undefined
      ? undefined
      : props.projects.find((x) => x.id === projectId);
  return (
    <>
      <Card
        sx={{
          position: "fixed",
          left: 10,
          bottom: 10,
          width: 400,
          zIndex: 99999,
          opacity: 0.85,
        }}
      >
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <b>Field Name:</b>{" "}
            {selectedProject && selectedProject.project.name}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <b>Grower:</b>{" "}
            {selectedProject &&  props.growers[selectedProject.project.growerId].name}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <b>Project Type:</b>{" "}
            {selectedProject &&
              formatProjectType(selectedProject.project.projectType)}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <b>Last Modified:</b>{" "}
            {selectedProject &&
              formatDateWithTime(
                new Date(selectedProject.project.lastModifiedTimestamp)
              )}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <b>Last Modified By:</b>{" "}
            {selectedProject && selectedProject.project.lastModifiedUserId}
          </Typography>
        </CardContent>
      </Card>
      <ProjectsMap
        projects={props.projects}
        onDrawUpdateExt={(event) => {
          // /* @ts-ignore */
          if (event.action === "project_hovered") {
            setProjectId(event.definition.projectId);
          } else if (event.action === "project_selected") {
            if (event.definition.projectId) {
                props.navigateTo(event.definition.projectId);
            }
          }
        }}
      />
    </>
  );
};

export default ProjectListMapTab;
