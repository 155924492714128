import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, Typography } from "@mui/material";
import { PDFViewer } from "@react-pdf/renderer";
import * as React from "react";
import { FC, useContext, useState } from "react";
import DbCtx from "../../../../db/DbCtx";
import DevSettingsCtx from "../../../../db/DevSettingsCtx";
import IDbProject from "../../../../db/IDbProject";
import { IDocumentType, getDocTypeStr } from "../../../../docGeneration/DocumentGenerationHelpers";
import DealerDataCtx from "../../../../userData/DealerDataCtx";
import { pageStyles } from "../../styles";
import { Report } from "../Proposal/ViewProposalRenderer";

interface Props {
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    docType: IDocumentType;
};

const InlineReportRenderer: FC<Props> = (props) => {
    const user = useContext(DealerDataCtx);
    const devSettings = useContext(DevSettingsCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const dbState = useContext(DbCtx);
    const grower = dbState.growers[props.dbPrj.state.growerId];
    const [reportOpen, setReportOpen] = useState(false);

    return (
        <Card variant={"outlined"} >
            <div onClick={() => setReportOpen(!reportOpen)} style={{ padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography sx={pageStyles.cardTitleStyle}>{getDocTypeStr(props.docType)}</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: "flex-end" }}>
                    <div style={{ paddingLeft: 10 }}  >
                        {
                            reportOpen && <ExpandLessIcon />
                        }
                        {
                            !reportOpen && <ExpandMoreIcon />
                        }
                    </div>
                </div>
            </div>
            {
                reportOpen && <PDFViewer height={500} width={785} showToolbar={true}>
                    { /* We have to bridge all conexts that might be needed within the PDF renderers. 
                See https://github.com/facebook/react/issues/17275#issuecomment-550322731 */ }
                    <DealerDataCtx.Provider value={user}>
                        <DevSettingsCtx.Provider value={devSettings}>
                            <Report docType={props.docType} grower={grower} systemId={props.systemId} layoutId={props.layoutId} project={project} />
                        </DevSettingsCtx.Provider>
                    </DealerDataCtx.Provider>
                </PDFViewer>
            }
        </Card>
    );
};

export default InlineReportRenderer;