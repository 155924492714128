import { Font, StyleSheet } from '@react-pdf/renderer';
import FontArial from 'url:./assets/fonts/arial.ttf';
import FontArialBlack from 'url:./assets/fonts/ariblk.ttf';
import FontWingDings from 'url:./assets/fonts/wingdings3.ttf';

Font.register({
    family: "Arial",
    fonts: [
        {
            src: FontArial
        },
        {
            src: FontArialBlack,
            fontWeight: "bold"
        }
    ]
});

Font.register({
    family: "Wingdings",
    fonts: [
        {
            src: FontWingDings
        }
    ]
});

const bodyPadding = 12;
export const primaryColor = "#0158a7";

export const documentStyles = StyleSheet.create({
    page: {
        fontSize: "11px",
        fontFamily: "Arial",
        paddingBottom: 40 //needed to stop overlap with footer
    },
    warrantyPage: {
        fontSize: "9.5px",
        fontFamily: "Arial",
        paddingBottom: 20 //needed to stop overlap with footer
    },
    nonTitlePage: {
        paddingTop: 80 //needed to stop overlap with header
    },
    section: {
        border: "1 solid #0158a7",
        borderColor: "black",
        marginBottom: 10
    },
    table: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginVertical: 1,
        marginBottom: 10
    },
    row:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center"
    },
    cell: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexWrap: 'wrap',
        paddingTop: 2,
        paddingBottom: 2
    },
    heading: {
        fontWeight: "bold",
        fontFamily: "Arial",
        backgroundColor: primaryColor,
        color: "white",
        paddingTop: 3,
        paddingBottom: 3
    },
    sectionHeader: {
        fontFamily: "Arial",
        fontWeight: "bold",
        width: "100%",
        textAlign: "center"
    },
    footer: {
        position: "absolute",
        bottom: 10,
        left: bodyPadding * 3,
        right: bodyPadding * 3,
        padding: bodyPadding,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row"
    },
    firstPageFooter: {
        position: "absolute",
        bottom: 0,
        height: 20,
        width: "100%",
        backgroundColor: primaryColor
    },
    header: {
        position: "absolute",
        top: 0,
        left: 15,
        right: 15,
        padding: 15,
        borderBottom: "1 solid black"
    },
    container: {
        display: "flex",
        flexDirection: 'row',
      },
    column: {
        width: '50%',
        paddingLeft: 5,
        paddingRight:5
    },
    warrantyFooter: {
        position: "absolute",
        paddingLeft: 15,
        paddingRight: 15,
        bottom: 0,
        height: 30,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row"
    },
});