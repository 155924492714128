import * as React from "react";
import { FC } from "react";
import SpanSupport from "../SystemDiagram/SpanSupport";
import SpanTensioner from "../SystemDiagram/SpanTensioner";
import Sprinkler from "../SystemDiagram/Sprinkler";
import { SYSTEM_DESIGN_CONSTANTS } from "../SystemDiagram/constants";
import EndGun from "./EndGun";
import { ISpanDiagramElementProps, createPipeElevationFromXFn } from "./Span";

export interface IEndBoom {
    lengthFeet: number;
    spacingInches?: number
}
interface IProps {
    startDistanceInches: number;
    spanLengthInches: number;
    nozzleSpacingInches: number;
    startHeightInches: number;
    endHeightInches: number;
    elevationStartFeet: number;
    elevationEndFeet: number;
    endGun?: boolean;
    
    lastSpanBeforeEndBoomArgs: ISpanDiagramElementProps;
}

const EndBoom: FC<IProps> = ({ lastSpanBeforeEndBoomArgs, startDistanceInches, spanLengthInches, nozzleSpacingInches, endGun }) => {

    
    const preceedingSpanLengthInches = lastSpanBeforeEndBoomArgs.spanLengthInches;
    // const startDistanceInches = lastSpanBeforeEndBoomArgs.startDistanceInches + preceedingSpanLengthInches;

    // const twicePreceedingTowerDefinition = towerDefinitionFromTowerOrPivot(preceedingSpanTower.preceedingTower);
    // const preceedingTowerDefinition = towerDefinitionFromTowerOrPivot(preceedingSpanTower.tower);
    const preceedingSpanPipeElevationFromX = createPipeElevationFromXFn(
        lastSpanBeforeEndBoomArgs.startDistanceInches, preceedingSpanLengthInches, 
        (lastSpanBeforeEndBoomArgs.elevationStartFeet * 12 + lastSpanBeforeEndBoomArgs.startHeightInches),
        (lastSpanBeforeEndBoomArgs.elevationEndFeet * 12 + lastSpanBeforeEndBoomArgs.endHeightInches));


    const pipeWidth = SYSTEM_DESIGN_CONSTANTS.pipe.width;
    const endBoomLengthInches = spanLengthInches;
    
    const preeceedingSpanElevationDifferencePerInch = 12 * (lastSpanBeforeEndBoomArgs.elevationEndFeet - lastSpanBeforeEndBoomArgs.elevationStartFeet) / preceedingSpanLengthInches;

    const boomPipeElevationFromX = (x: number) => {
        const a = preceedingSpanPipeElevationFromX(startDistanceInches - (x - startDistanceInches)) 
        return a + 1 * preeceedingSpanElevationDifferencePerInch * (x - startDistanceInches)
    }
    const boomTensionerElevationFromX = createPipeElevationFromXFn(
        startDistanceInches - endBoomLengthInches, 2 * endBoomLengthInches,
        preceedingSpanPipeElevationFromX(startDistanceInches - endBoomLengthInches), 
        boomPipeElevationFromX(startDistanceInches + endBoomLengthInches)
    );

    // generate the elements:

    // boom pipe
    const nPipeSectionSegments = SYSTEM_DESIGN_CONSTANTS.sac.nSections;
    const pipeSectionSegmentXLength = endBoomLengthInches / nPipeSectionSegments;    
    let boomPipeSectionsPath = `
        M ${startDistanceInches} ${boomPipeElevationFromX(startDistanceInches)}
    `;    
    for (let i = 0; i < nPipeSectionSegments; i++) {
        const x = startDistanceInches + pipeSectionSegmentXLength * i;
        const y = boomPipeElevationFromX(x);
        boomPipeSectionsPath += ` L ${x} ${y}`
    }
    boomPipeSectionsPath += `
        L ${startDistanceInches + endBoomLengthInches} ${boomPipeElevationFromX(startDistanceInches + endBoomLengthInches)}
    `; 
    const boomPipeElement = <path 
        d={boomPipeSectionsPath} 
        stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
        strokeWidth={pipeWidth} 
        fill="none"
        strokeLinecap='round'
    />

    // sprinklers:
    const sprinklerElements: JSX.Element[] = [];
    if (nozzleSpacingInches !== undefined) {
        const nozzleSpacing = nozzleSpacingInches;
        const firstNozzle = startDistanceInches + 2 * nozzleSpacing;
        const lastNozzle = startDistanceInches + endBoomLengthInches - nozzleSpacing * 0.5;
        const nozzleLocations: number[] = [];
        for (let location = firstNozzle; location <= lastNozzle; location += nozzleSpacing) {
            nozzleLocations.push(location);
        }
        for (let i = 0; i < nozzleLocations.length; i++) {
            const x = nozzleLocations[i];
            const y = boomPipeElevationFromX(x);
            sprinklerElements.push(
                <Sprinkler 
                    key={`sprinkler${i}`} 
                    startDistance={x} 
                    drop={SYSTEM_DESIGN_CONSTANTS.sprinkler.length} 
                    y={y} />
            )
        }
    }

    // boom pipe supports:
    // boom pipe supports previous span:
    const boomPipeSupportElements: JSX.Element[] = [];
    const nsupportsboom = 3;;
    const nsupportsspan = nsupportsboom;
    const supportspacing = endBoomLengthInches / nsupportsboom;    
    const tensionerCoordinates: { x: number, y: number }[] = [{
        x: startDistanceInches - endBoomLengthInches,
        y: preceedingSpanPipeElevationFromX(startDistanceInches - endBoomLengthInches)
    }];
    for (let i = 1; i < nsupportsspan; i++) {
        const xc = startDistanceInches - endBoomLengthInches + supportspacing * i;
        const yc = boomTensionerElevationFromX(xc);

        const x1 = xc - SYSTEM_DESIGN_CONSTANTS.pipe.supportWidth * 0.5;
        const x2 = xc + SYSTEM_DESIGN_CONSTANTS.pipe.supportWidth * 0.5;
        const y1 = preceedingSpanPipeElevationFromX(x1);
        const y2 = preceedingSpanPipeElevationFromX(x2);

        boomPipeSupportElements.push(
            <SpanSupport 
                key={`spansupport-aft-${i}`} 
                x1={x1} y1={y1} 
                x2={x2} y2={y2} 
                xc={xc} yc={yc} />
        )
        tensionerCoordinates.push({
            x: xc,
            y: yc
        })
    }

    // boom pipe supports at tower:
    const midPosition = {
        x: startDistanceInches,
        y: boomTensionerElevationFromX(startDistanceInches)
    }
    const x1 = midPosition.x - SYSTEM_DESIGN_CONSTANTS.pipe.supportWidth * 0.5;
    const x2 = midPosition.x + SYSTEM_DESIGN_CONSTANTS.pipe.supportWidth * 0.5;
    const y1 = preceedingSpanPipeElevationFromX(x1);
    const y2 = boomPipeElevationFromX(x2);
    boomPipeSupportElements.push(
        <SpanSupport key="mid" 
            x1={x1} y1={y1} x2={x2} 
            y2={y2} 
            xc={midPosition.x} yc={midPosition.y} />
    )
    tensionerCoordinates.push(midPosition);

    
    // boom pipe supports on end boom: 
    for (let i = 1; i < nsupportsboom; i++) {
        const xc = startDistanceInches + supportspacing * i;
        const yc = boomTensionerElevationFromX(xc);

        const x1 = xc - SYSTEM_DESIGN_CONSTANTS.pipe.supportWidth * 0.5;
        const x2 = xc + SYSTEM_DESIGN_CONSTANTS.pipe.supportWidth * 0.5;
        const y1 = boomPipeElevationFromX(x1);
        const y2 = boomPipeElevationFromX(x2);
        
        boomPipeSupportElements.push(
            <SpanSupport key={`spansupport-fwd-${i}`} 
                x1={x1} y1={y1} 
                x2={x2} y2={y2} 
                xc={xc} yc={yc} />
        )
        tensionerCoordinates.push({
            x: xc,
            y: yc
        })
    }
    tensionerCoordinates.push({
        x: startDistanceInches + endBoomLengthInches,
        y: boomPipeElevationFromX(startDistanceInches + endBoomLengthInches)
    });

    // boom pipe supports tensioner: 
    const tensionerElements: JSX.Element[] = [];
    for (let i = 0; i < tensionerCoordinates.length - 1; i++) {
        const p1 = tensionerCoordinates[i];
        const p2 = tensionerCoordinates[i + 1];
        tensionerElements.push(
            <SpanTensioner 
                key={i} 
                x1={p1.x} 
                y1={p1.y} 
                x2={p2.x} 
                y2={p2.y} />
        )
    }

    // end gun: 
    let endGunElement: JSX.Element | undefined = undefined;
    if (endGun) {
        endGunElement = (
            <EndGun 
                startDistanceInches={startDistanceInches + endBoomLengthInches} 
                height={boomPipeElevationFromX(startDistanceInches + endBoomLengthInches)} />
        );
    }

    return (
        <>
            {sprinklerElements}
            {boomPipeElement}
            {boomPipeSupportElements}
            {tensionerElements}
            {endGunElement}
        </>
    );
};

export default EndBoom;