import { IHoseDrop as HoseDrop, IPackage as Package, UPipeTypes } from "rdptypes/project/ISprinklers";
import CVSection2 from './CVSection2';
import PartsAssembler from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import { strLanguageText } from "./TranslationsLib";
export class SprinklersHoseDropClass extends CVSection2 {
    
    public Package: Package;
    private get HoseDrop(): HoseDrop {
        return this.Package.HoseDrop;
    }
    // Public Property PackageClass As SprinklersPackageClass
    // Public Property SpansClass As SpansClass


    public DataOK(PA: PartsAssembler, WithWarnings?: boolean, DependencyCheckInProgress?: boolean): void {
        if (WithWarnings) {
            switch (this.HoseDrop.UPipeType) {
                case UPipeTypes.BlackPlasticSingle125:
                case UPipeTypes.BlackPlasticDouble125:
                    if (!this.Package.DevicesDoubled) {
                        this.Quote.Warnings.Add(strLanguageText("lsltBlackPlasticUPipeWarning"), WarningLevels.Informational);
                    }
                    break;
            }
        }
        // Return True
        return;
    }
    
    public WritePO(PO: PartsAssembler): void {
        // pass: nothing to do
    }

}
