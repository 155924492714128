import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField
} from "@mui/material";
import i18next, { t } from "i18next";
import IDbGrower from "rdptypes/api/IGrowerBase";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import IDbProject from "../../../../../../db/IDbProject";
import { ImportExportFeature } from "../../../../../../helpers/importExport";
import ImportExportDialogBase, { IRow } from "../ImportExportDialogBase";
import { ExportProject } from "../common/interfaces";
import { exportToKml } from "./kmlFile";
import { exportToKmz } from "./kmzFile";
import { exportAsRapFile } from "./rapFile";
import { exportAsShapeFile } from "./shapeFile";


enum ExportAsTypes { 'RAP', 'ShapeFile' , 'KML' , 'KMZ' }
const validFilenameRegex = /^[\w\-. ]+$/;

interface IProps {
    features: ImportExportFeature[];    
    onClose: () => void;
    dbPrj: IDbProject;
    layoutId: string;
    grower: IDbGrower;
    projectId: string;
}
const ExportDialog: React.FC<IProps> = ({
    features, onClose, dbPrj, grower, layoutId, projectId
}) => {

    const [exportAsType, setExportAsType] = useState<ExportAsTypes>(ExportAsTypes.RAP);
    const [filename, setFilename] = useState<string>(() => {
        const layout = dbPrj.state.layouts[layoutId];
        const filename = `${grower.name}-${dbPrj.state.name}-${layout.name}`;
        return filename;
    });
    const [isValidFilename, setIsValidFilename] = useState(() => validFilenameRegex.test(filename));
    const aRef = useRef<HTMLAnchorElement>(null);
    

    const onConfirm = (selectedRows: IRow[]) => {
        
        if (!aRef.current) return;

        const importFeatures: ImportExportFeature[] = [];
        for (const importRow of selectedRows) {
            importFeatures.push(importRow.feature);
        }

        const eProject: ExportProject = {
            features: importFeatures,
            projectId,
            growerName: grower.name,
            fieldName: dbPrj.state.layouts[layoutId].name,
            lastModifiedTimestamp: dbPrj.state.lastModifiedTimestamp
        }

        switch (exportAsType) {
            case ExportAsTypes.ShapeFile:
                exportAsShapeFile({ eProject })?.then(b64file => {
                    if (!aRef.current) return;
                    aRef.current.href = b64file;
                    aRef.current.download = filename + ".zip";
                    aRef.current.click();
                    onClose();
                })

                break;
            case ExportAsTypes.RAP:
                exportAsRapFile({ eProject }).then(b64file => {
                    if (!aRef.current) return;
                    aRef.current.href = b64file;
                    aRef.current.download = filename + ".rap";
                    aRef.current.click();
                    onClose();
                })
                break
            case ExportAsTypes.KML:
                const b64file = exportToKml({ eProject });
                aRef.current.href = b64file;
                aRef.current.download = filename + ".kml";
                aRef.current.click();
                onClose();
                break
            case ExportAsTypes.KMZ:
                exportToKmz({ eProject }).then(b64file => {
                    if (!aRef.current) return;
                    aRef.current.href = b64file;
                    aRef.current.download = filename + ".kmz";
                    aRef.current.click();
                    onClose();
                })
                break
            default:
                console.log("TODO: Export as", ExportAsTypes[exportAsType]);
                onClose();
                break;
        }

    };

    useEffect(() => {
        console.log(validFilenameRegex.test(filename))
        setIsValidFilename(validFilenameRegex.test(filename));
    }, [filename])

    const ns = "nav.export.";

    return(
        <ImportExportDialogBase
            title={t(ns + 'export-shapes')}
            features={features}
            onConfirm={onConfirm}
            onCancel={onClose}
            disableConfirm={!isValidFilename}
            type="export"
        >

            <Stack direction={'column'} gap={2}>
                <FormControl>
                    <FormLabel id="export-as-radio-buttons-group-label">{i18next.format(`${t('export')} ${t('as')}`, 'capitalize-each')}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="export-as-radio-buttons-group-label"
                        name="export-as-radio-buttons-group"
                        value={exportAsType}
                        onChange={(ev) => {
                            setExportAsType(parseInt(ev.target.value));
                        }}
                    >
                        <FormControlLabel value={ExportAsTypes.RAP} control={<Radio />} label="RAP" />
                        <FormControlLabel value={ExportAsTypes.ShapeFile} control={<Radio />} label="ShapeFile" />
                        <FormControlLabel value={ExportAsTypes.KML} control={<Radio />} label="KML" />
                        <FormControlLabel value={ExportAsTypes.KMZ} control={<Radio />} label="KMZ" />
                    </RadioGroup>
                </FormControl>
                <TextField
                    id="export-as-filename"
                    label={i18next.format(t('filename'), 'capitalize')}
                    error={!isValidFilename}
                    helperText={isValidFilename ? " " : t(ns + 'invalid-filename') + " [ 0-9 a-Z - _ ]"}
                    value={filename}
                    onChange={(ev => setFilename(ev.target.value))}
                />
            </Stack>
            <a ref={aRef} href="" download="" hidden>{i18next.format(t('download'), 'capitalize')}</a>
        </ImportExportDialogBase>
    )
}

export default ExportDialog;