import { Text, View } from "@react-pdf/renderer";
import i18next, { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import ISystem from "../../model/project/ISystem";
import { formatCents } from "../DocumentGenerationHelpers";
import { documentStyles } from "../styles";
import FixedWidthTable, { IRow } from "./table/FixedWidthTable";

export interface IMiscItemInformationTableRow {
    description: string;
    priceUsdCents: number;
}

export interface Props {
    quote: QuoteClass;
}

const MiscItemsTable: FC<Props> = (props) => {
    let {quote} = props;
    let items = (quote.System as ISystem).QuoteProperties.MiscItems.filter(x => x.description);

    let rows: IRow[] = [{cells: [{
        text: t("common-phrases.sltDescription"),
        header: true,
        widthPc: 50
    },{
        text: t("common-phrases.sltPrice"),
        header: true,
        widthPc: 25
    },{
        text: i18next.format(t('taxable'), 'capitalize') + "?",
        header: true,
        widthPc: 25
    }]}];

    let totalPriceCents = 0;

    items.forEach((item, i)=> {
        totalPriceCents += item.priceCents;
        rows.push({cells: [{
            text: item.description,
            widthPc: 50
        },{
            text: formatCents(item.priceCents),
            widthPc: 25,
            underline: i === items.length - 1
        },{
            text: item.taxable ? i18next.format(t('yes'), 'capitalize'): i18next.format(t('no'), 'capitalize'),
            widthPc: 25
        }]});
    });

    rows.push({cells: [{
        text: "",
        widthPc: 50,
    },{
        text: t("proposal-information.total") + ": " + formatCents(totalPriceCents),
        widthPc: 25,
        header: true
    },{
        text: "",
        widthPc: 25
    }]});

    return (<View style={documentStyles.section}>
            <View style={documentStyles.heading}>
                    <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltMiscItems")}</Text>
            </View>
            <View style={{paddingLeft: 15, paddingRight: 15}}>
                <FixedWidthTable
                    data={{rows}}
                />
            </View>
        </View>
    );
};

export default MiscItemsTable;