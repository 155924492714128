import ClearIcon from '@mui/icons-material/Clear';
import Straighten from "@mui/icons-material/Straighten";
import * as React from "react";

interface Props {
    disabled?: boolean
}
const ClearMeasurementIcon: React.FC<Props> = ({ disabled = false }) => {
    return (
        <>
            <Straighten 
                style={{ color: disabled ? undefined : '#ccc' }} 
                color={disabled ? 'disabled' : undefined}
            />
            <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                <ClearIcon 
                    fontSize="small" 
                    color={disabled ? 'disabled' : 'warning'}
                />
            </div>
        </>
    )
}

export default ClearMeasurementIcon;