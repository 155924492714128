import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { SideEnum } from 'rdptypes/helpers/SideEnum';
import { SystemTypes } from 'rdptypes/project/ISystemBase.AutoGenerated';
import { ISide } from 'rdptypes/project/Types';
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';
import { numberOfSpansExcludingEos, renumberSpanAndTowers, switchSidesIfNeeded } from './helpers/spans';

const actionTypeId = "MoveCart";

interface ActionData extends IActionData {
    layoutId: string;
    systemId: string;
    inDirectionOf: SideEnum;
}

const ACTION_APPLICABLE_TO = [
    SystemTypes.CanalFeedMaxigator, SystemTypes.HoseFeedMaxigator
]

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const sys = state.layouts[data.layoutId].systems[data.systemId];

    if (!ACTION_APPLICABLE_TO.includes(sys.SystemProperties.SystemType)) {
        return;
    }

    let givingSide: ISide;
    let receivingSide: ISide;

    switch (data.inDirectionOf) {
        case SideEnum.Flanged: {
            // then move first flanged span to first flex span
            givingSide = sys.FlangedSide;
            receivingSide = sys.FlexSide;
            break;
        }
        case SideEnum.Flex: {
            // then move first flex span to first flanged span
            givingSide = sys.FlexSide;
            receivingSide = sys.FlangedSide;
            break;
        }    
        default:
            // quietly do nothing
            return;
    }
    
    // we cannot move vart if giving side is empty:
    if (!numberOfSpansExcludingEos(givingSide.Span)) return;
    
    const movingSpan = givingSide.Span.shift();
    const movingTower = givingSide.Tower.shift();
    receivingSide.Span.unshift(movingSpan);
    receivingSide.Tower.unshift(movingTower);
    renumberSpanAndTowers(givingSide);
    renumberSpanAndTowers(receivingSide);
    switchSidesIfNeeded(sys);
    
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});

export const createMoveCartAction = (
    layoutId: string,
    systemId: string,
    inDirectionOf: SideEnum,
    authState: IAuthState) => createAction(
        actionTypeId,
        {
            layoutId,
            systemId,
            inDirectionOf
        } as ActionData,
        authState);