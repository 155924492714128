import IAction from "rdptypes/IAction";
import IAuthState from "../../../../auth/IAuthState";
import { IDrawUpdateExtEvent_DynamicCenterPivotOptimizationResult } from "../../../../components/Map/DynamicCenterPivotOptimizationMode";
import { staticDevSettingsDbProvider } from "../../../../db/DevSettingsDbProvider";
import IDbProject from "../../../../db/IDbProject";
import { handleCreateNewOptimizedSystem } from "../../../../helpers/optimizedSystemHelpers/handleCreateNewOptimizedSystem";

export const handleDynamicCenterPivotResults = (
    event: IDrawUpdateExtEvent_DynamicCenterPivotOptimizationResult,
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
    systemDefaults?: ((layoutId: string, systemId: string, authState: IAuthState) => IAction[])
) => {
    const customerSettings = staticDevSettingsDbProvider.customer.get();
    const dealerPricing = staticDevSettingsDbProvider.dealerPricing.get();
    handleCreateNewOptimizedSystem({
        optimizedSystem: event.definition.optimizedSystem,
        layoutId,
        dbPrj,
        authState,
        systemDefaults,
        spanDefaults: staticDevSettingsDbProvider.span.getUseCustom()
            ? staticDevSettingsDbProvider.span.get()
            : undefined,
        towerDefaults: staticDevSettingsDbProvider.tower.getUseCustom()
            ? staticDevSettingsDbProvider.tower.get()
            : undefined,
        customerSettings,
        dealerPricing
    })
}