import Business from "@mui/icons-material/Business";
import CloseIcon from "@mui/icons-material/Close";
import {
    AppBar,
    Box,
    Dialog,
    IconButton,
    Stack,
    Tab,
    Tabs,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import i18next, { t } from "i18next";
import tabSacOptimizerSettings from "./SacOptimizerSettings";
import tabCustomerSettings from "./TabCustomerSettings";
import tabDealerInformation from "./TabDealerInformation";
import tabDealerLogo from "./TabDealerLogo";
import tabDisplaySettings from "./TabDisplaySettings";
import tabMapSettings from "./TabMapSettings";
import tabOptimizationDefaults from "./TabOptimizationDefaults";
import tabSystemDefaults from "./TabSystemDefaults";

interface Props {
    closeDialog: () => any;
}

const DealerSettingsDialog: React.FC<Props> = (props) => {
    
    const [tabIndex, setTabIndex] = React.useState(0);

    const handleClose = () => {
        props.closeDialog();
    };
    
    const handleSave = () => {};

    const handleTabChange = (_, newTabIndex: number) => {
        setTabIndex(newTabIndex);
    }

    const ns = 'dealer-settings.';

    const tabs = [
        {
            body: tabDealerInformation,
            title: t(ns + "dealer-info")
        },
        {
            body: tabDealerLogo,
            title: t(ns + "dealer-logo")
        },
        {
            body: tabSystemDefaults,
            title: t(ns + "system-defaults")
        },
        {
            body: tabOptimizationDefaults,
            title: t(ns + "optimization-defaults")
        },
        {
            body: tabCustomerSettings,
            title: t(ns + "customer-settings")
        },/*        
        {
            body: tabDealerPricing,
            title: t(ns + "dealer-pricing")
        },*/
        {
            body: tabDisplaySettings,
            title:t(ns + "display-settings")
        },
        {
            body: tabMapSettings,
            title: t(ns + "map-settings")
        },
        {
            body: tabSacOptimizerSettings,
            title: t(ns + "sac-opt-settings")
        }
    ]

    const tabBody = tabs[tabIndex]?.body;

    return (
        <Dialog open={true} fullScreen onClose={handleClose}>
            <AppBar position="fixed">
                <Toolbar variant="dense">
                    <Business />
                    <Typography
                        sx={{ ml: 2, flex: 1 }}
                        variant="h6"
                        component="div"
                    >
                        {i18next.format(t('nav.dealer-settings'), 'capitalize-each')}
                    </Typography>
                    {/* <Tooltip title="Save" placement="bottom">
                        <IconButton
                            aria-label="save"
                            onClick={handleSave}
                            color="inherit"
                        >
                            <SaveIcon />
                        </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Close" placement="bottom">
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            color="inherit"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>

            <Toolbar variant="dense" />

            <DialogContent>
                <Stack 
                    direction="row" 
                    sx={{ display: "flex", flex: 1, height: '100%' }}
                >
                    <Tabs
                        orientation="vertical"
                        value={tabIndex}
                        onChange={handleTabChange}
                    >
                        {
                            tabs.map(x => <Tab key={x.title} label={x.title} />)
                        }
                    </Tabs>
                    <Box sx={{ flex: 1, padding: 2 }}>
                        {tabBody}
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default DealerSettingsDialog;
