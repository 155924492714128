import { EndGunTypes, EndTowerPositioningTypes, GuidanceTypes, HoseFeedTypes, ISystemBase, SystemTypes, TowTypes, TowerHeights, WrapAroundSpanTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IRuleResult } from "rdptypes/roe/IRule";
import { AgriInjectClass } from "./AgriInjectClass";
import CSection2, { SectionIDs } from "./CSection2";
import { CanalFeedClass } from "./CanalFeedClass";
import { CenterPivotClass } from "./CenterPivotClass";
import { CheckValvesClass } from "./CheckValvesClass";
import { ClemonsFiltersClass } from "./ClemonsFiltersClass";
import { ControlPanelClass } from "./ControlPanelClass";
import { CropXClass } from "./CropXClass";
import { EndOfSystemClass } from "./EndOfSystemClass";
import { FlowmeterComponentsClass } from "./FlowmeterComponentsClass";
import { GuidanceClass } from "./GuidanceClass";
import { HeatExchangersClass } from "./HeatExchangersClass";
import { HoseFeedClass } from "./HoseFeedClass";
import { KwikTowClass } from "./KwikTowClass";
import { MainlineValvesClass } from "./MainlineValvesClass";
import { OntracClass } from "./OntracClass";
import { OptionsClass } from "./OptionsClass";
import { RC10Class } from "./RC10Class";
import { SACRetroClass } from "./SACRetroClass";
import * as sidef from "./SideFunctions";
import { FirstDisconnectingSpan, NumberOfSpans } from "./SideFunctions";
import { SpansClass } from "./SpansClass";
import { SprinklerLubeClass } from "./SprinklerLubeClass";
import { SprinklersChartClass } from "./SprinklersChartClass";
import { SprinklersConfigClass } from "./SprinklersConfigClass";
import { SprinklersPackagesClass } from "./SprinklersPackagesClass";
import { SwingArmClass } from "./SwingArmClass";
import * as sysf from "./SystemFunctions";
import { HasEnergySaverPackage, HasSwingArmCorner, IsCenterFeed, IsMaxigator } from "./SystemFunctions";
import { TowersClass } from "./TowersClass";

export class Warnings {
    private warnings: string[] = [];

    public Add = (warning: string) => {
        this.warnings.push(warning);
    };
}

export enum WarningLevels {
    /**
     * Used to indicate a level where no warning exists
     */
    NoLevel,
    /**
     * User should be aware, but not offered as decision
     */
    Informational,
    /**
     * User should evaluate and decide
     */
    Decision,
    /**
     * Unable to commit quote
     */
    QuoteBlock,
    /**
     * Unable to commit section
     */
    SectionBlock,
    /**
     * Unable to proceed
     */
    Critical
}

export const classSelectors: ((quote: QuoteClass) => CSection2)[] = [
    quote => quote.CenterPivotClass,
    quote => quote.ControlPanelClass,
    quote => quote.GuidanceClass,
    quote => quote.HoseFeedClass,
    quote => quote.RightSpansClass,
    quote => quote.RightTowersClass,
    quote => quote.RightEOSClass,
    quote => quote.LeftSpansClass,
    quote => quote.LeftTowersClass,
    quote => quote.LeftEOSClass,
    quote => quote.SwingArmClass,
    quote => quote.OptionsClass,
    quote => quote.SprinklerConfigClass,
    quote => quote.SprinklerChartClass,
    quote => quote.AgriInjectClass,
    quote => quote.CanalFeedClass,
    quote => quote.CheckValvesClass,
    quote => quote.ClemonsFiltersClass,
    quote => quote.CropXClass,
    quote => quote.FlowmeterComponentsClass,
    quote => quote.HeatExchangersClass,
    quote => quote.KwikTowClass,
    quote => quote.MainlineValvesClass,
    quote => quote.OntracClass,
    quote => quote.RC10Class,
    quote => quote.SACRetroClass,
    quote => quote.SprinklerLubeClass,
    quote => quote.SprinklerPackagesClass,
];

export default class QuoteClass {
    public ruleResults: IRuleResult[] = [];

    public Warnings = {
        Add: (message: string, level = WarningLevels.Critical, boldRed = false, id = message) => {
            this.Warnings.AddWithTargets([], message, level, boldRed, id);
        },
        AddWithTargets: (targets: string[], message: string, level = WarningLevels.Critical, boldRed = false, id = message) => {
            this.ruleResults.push({
                id,
                targets,
                severity:
                    (level === WarningLevels.Critical || level === WarningLevels.QuoteBlock || level === WarningLevels.SectionBlock) ? "error"
                    : level === WarningLevels.Decision ? "warning"
                    : "info",
                description: message
            });
        }
    }

    constructor(sys: ISystemBase) {
        this.System = sys;

        const fs = sysf.FieldSets(sys);

        this.CenterPivotClass = new CenterPivotClass();
        this.CenterPivotClass.FieldSet = fs.CenterPivot;
        this.CenterPivotClass.Quote = this;

        this.ControlPanelClass = new ControlPanelClass();
        this.ControlPanelClass.FieldSet = fs.ControlPanel;
        this.ControlPanelClass.Quote = this;

        this.GuidanceClass = new GuidanceClass();
        this.GuidanceClass.FieldSet = fs.Guidance;
        this.GuidanceClass.Quote = this;

        this.HoseFeedClass = new HoseFeedClass();
        this.HoseFeedClass.FieldSet = fs.HoseFeed;
        this.HoseFeedClass.Quote = this;

        this.RightEOSClass = new EndOfSystemClass();
        this.RightEOSClass.FieldSet = fs.FlangedEndOfSystem;
        this.RightEOSClass.Quote = this;
        this.RightEOSClass.Side = sys.FlangedSide;
        this.RightEOSClass.SectionID = SectionIDs.RightEOSID;

        this.RightSpansClass = new SpansClass();
        this.RightSpansClass.FieldSet = fs.FlangedSpans;
        this.RightSpansClass.Quote = this;
        this.RightSpansClass.Side = sys.FlangedSide;
        (this.RightSpansClass as any).bFlangedSide = true;
        (this.RightSpansClass as any).bFlexSide = false;
        this.RightSpansClass.SectionID = SectionIDs.RightSpansID;

        this.RightTowersClass = new TowersClass();
        this.RightTowersClass.FieldSet = fs.FlangedTowers;
        this.RightTowersClass.Quote = this;
        this.RightTowersClass.Side = sys.FlangedSide;
        (this.RightTowersClass as any).bRightSide = true;
        (this.RightTowersClass as any).bLeftSide = false;
        this.RightTowersClass.SectionID = SectionIDs.RightTowersID;

        this.RightSpansClass.EOSClass = this.RightEOSClass;
        this.RightSpansClass.TowersClass = this.RightTowersClass;


        this.LeftEOSClass = new EndOfSystemClass();
        this.LeftEOSClass.FieldSet = fs.FlexEndOfSystem;
        this.LeftEOSClass.Quote = this;
        this.LeftEOSClass.Side = sys.FlexSide;
        this.LeftEOSClass.SectionID = SectionIDs.LeftEOSID;

        this.LeftSpansClass = new SpansClass();
        this.LeftSpansClass.FieldSet = fs.FlexSpans;
        this.LeftSpansClass.Quote = this;
        this.LeftSpansClass.Side = sys.FlexSide;
        (this.LeftSpansClass as any).bFlangedSide = false;
        (this.LeftSpansClass as any).bFlexSide = true;
        this.LeftSpansClass.SectionID = SectionIDs.LeftSpansID;

        this.LeftSpansClass.OtherSideSpansClass = this.RightSpansClass;
        this.RightSpansClass.OtherSideSpansClass = this.LeftSpansClass;

        this.LeftTowersClass = new TowersClass();
        this.LeftTowersClass.FieldSet = fs.FlexTowers;
        this.LeftTowersClass.Quote = this;
        this.LeftTowersClass.Side = sys.FlexSide;
        (this.LeftSpansClass as any).bLeftSide = false;
        (this.LeftSpansClass as any).bLeftSide = true;
        this.LeftTowersClass.SectionID = SectionIDs.LeftTowersID;

        this.LeftSpansClass.EOSClass = this.LeftEOSClass;
        this.LeftSpansClass.TowersClass = this.LeftTowersClass;

        this.SprinklerConfigClass = new SprinklersConfigClass();
        this.SprinklerConfigClass.FieldSet = fs.SprinklerConfig;
        this.SprinklerConfigClass.Quote = this;
        
        this.SprinklerPackagesClass = new SprinklersPackagesClass();
        this.SprinklerPackagesClass.FieldSet = fs.SprinklerProperties;
        this.SprinklerPackagesClass.Quote = this;


        this.SwingArmClass = new SwingArmClass();
        this.SwingArmClass.FieldSet = fs.SwingArm;
        this.SwingArmClass.Quote = this;

        this.OptionsClass = new OptionsClass();
        this.OptionsClass.FieldSet = fs.Options;
        this.OptionsClass.Quote = this;

        this.SprinklerChartClass = new SprinklersChartClass(this);
        this.SprinklerChartClass.FieldSet = fs.SprinklerChart;

        this.AgriInjectClass = new AgriInjectClass();
        this.AgriInjectClass.FieldSet = fs.AgriInject;
        this.AgriInjectClass.Quote = this;

        this.CanalFeedClass = new CanalFeedClass();
        this.CanalFeedClass.FieldSet = fs.CanalFeed;
        this.CanalFeedClass.Quote = this;
        this.CheckValvesClass = new CheckValvesClass();
        this.CheckValvesClass.FieldSet = fs.CheckValves;
        this.CheckValvesClass.Quote = this;
        this.ClemonsFiltersClass = new ClemonsFiltersClass();
        this.ClemonsFiltersClass.FieldSet = fs.ClemonsFilters;
        this.ClemonsFiltersClass.Quote = this;
        this.CropXClass = new CropXClass();
        this.CropXClass.FieldSet = fs.CropX;
        this.CropXClass.Quote = this;
        this.FlowmeterComponentsClass = new FlowmeterComponentsClass();
        this.FlowmeterComponentsClass.FieldSet = fs.FlowmenterComponents;
        this.FlowmeterComponentsClass.Quote = this;
        this.HeatExchangersClass = new HeatExchangersClass();
        this.HeatExchangersClass.FieldSet = fs.HeatExchangers;
        this.HeatExchangersClass.Quote = this;
        this.KwikTowClass = new KwikTowClass();
        this.KwikTowClass.FieldSet = fs.KwikTow;
        this.KwikTowClass.Quote = this;
        this.MainlineValvesClass = new MainlineValvesClass();
        this.MainlineValvesClass.FieldSet = fs.MainlineValveOptions;
        this.MainlineValvesClass.Quote = this;
        this.OntracClass = new OntracClass();
        this.OntracClass.FieldSet = fs.Ontrac;
        this.OntracClass.Quote = this;
        this.RC10Class = new RC10Class();
        this.RC10Class.FieldSet = fs.RC10;
        this.RC10Class.Quote = this;
        this.SACRetroClass = new SACRetroClass();
        this.SACRetroClass.FieldSet = fs.SACRetro;
        this.SACRetroClass.Quote = this;
        this.SprinklerLubeClass = new SprinklerLubeClass();
        this.SprinklerLubeClass.FieldSet = fs.SprinklerLube;
        this.SprinklerLubeClass.Quote = this;
    }

    System: ISystemBase;

    CenterPivotClass: CenterPivotClass;
    ControlPanelClass: ControlPanelClass;
    GuidanceClass: GuidanceClass;
    HoseFeedClass: HoseFeedClass;

    LeftTowersClass: TowersClass;
    RightTowersClass: TowersClass;

    LeftSpansClass: SpansClass;
    RightSpansClass: SpansClass;

    LeftEOSClass: EndOfSystemClass;
    RightEOSClass: EndOfSystemClass;

    SwingArmClass: SwingArmClass;
    OptionsClass: OptionsClass;

    SprinklerConfigClass: SprinklersConfigClass;
    SprinklerPackagesClass: SprinklersPackagesClass;

    SprinklerChartClass: SprinklersChartClass;

    AgriInjectClass: AgriInjectClass;
    CanalFeedClass: CanalFeedClass;
    CheckValvesClass: CheckValvesClass;
    ClemonsFiltersClass: ClemonsFiltersClass;
    CropXClass: CropXClass;
    FlowmeterComponentsClass: FlowmeterComponentsClass;
    HeatExchangersClass: HeatExchangersClass;
    KwikTowClass: KwikTowClass;
    MainlineValvesClass: MainlineValvesClass;
    OntracClass: OntracClass;
    RC10Class: RC10Class;
    SACRetroClass: SACRetroClass;
    SprinklerLubeClass: SprinklerLubeClass;

    RigidSpan = (): number => {
        if (sysf.FieldSets(this.System).SystemType.DataValid()) {
            if (this.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator
                && this.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                return -1;
            }
        }

        if (!IsMaxigator(this.System)) {
            return -1;
        }

        if (IsCenterFeed(this.System)) {
            return 1;
        }

        let iSpanCount = NumberOfSpans(this.System.FlangedSide);
        let iFirstDisconnectingSpan = FirstDisconnectingSpan(this.System.FlangedSide);

        if (iSpanCount < 7) return 1;

        if (iFirstDisconnectingSpan > 0 && iSpanCount >= iFirstDisconnectingSpan) {
            iSpanCount = iFirstDisconnectingSpan - 1;
        }

        return Math.floor(iSpanCount / 2) + 1;
    };
    IsSingleSpanMaxi = (): boolean => this.HoseFeedClass.DataValid() && this.System.Lateral.HoseFeed.SingleSpanMaxi;
    IsDEF = (): boolean => this.HoseFeedClass.DataValid() && (this.System.Lateral.HoseFeed.HoseFeedType !== undefined
        && this.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.DoubleEndFeed);
    IsTowerUL = (): boolean => ((this.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro && this.System.Circle.SACRetro.TowerUL)
        || (this.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && this.System.ControlPanel.TowerUL));
    IsTowable = (): boolean => (
        (this.System.SystemProperties.SystemType === SystemTypes.CenterPivot && this.CenterPivotClass.DataValid() && this.System.Circle.CenterPivot.TowOptions.TowType !== TowTypes.None)
        || (this.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && this.HoseFeedClass.DataValid() && this.System.Lateral.HoseFeed.Towable)
        || (this.System.SystemProperties.SystemType === SystemTypes.KwikTow)
    );
    IsA100_CF200 = (): boolean => 
        this.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator
        && this.HoseFeedClass.DataValid()
        && (this.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.A100
            || this.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200);
    IsCF200 = (): boolean => 
 this.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator
        && this.HoseFeedClass.DataValid()
        && this.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200;
    IsA100_Only = (): boolean => 
        this.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator
        && this.HoseFeedClass.DataValid()
        && this.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.A100;
    HasTowerAutoReverse = (): boolean =>
        this.RightTowersClass.DataValid() && 
        (!sysf.IsCenterFeed(this.System) || this.LeftTowersClass.DataValid())
        && (this.System.FlangedSide.Tower.some(x => x.TowerAutoReverse) || (IsCenterFeed(this.System)
        && this.System.FlexSide.Tower.some(x => x.TowerAutoReverse)));
    IsSACRetro = (): boolean => this.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro;
    HasGPS = (): boolean => (this.ControlPanelClass.DataValid() && this.System.ControlPanel.EndTowerPositioning === EndTowerPositioningTypes.GPS)
        || (this.GuidanceClass.DataValid() && this.System.Lateral.Guidance.GuidanceType === GuidanceTypes.NavigatorGPS)
        || (this.SwingArmClass.DataValid() && HasSwingArmCorner(this.System));
    IsPartialCircle = (): boolean => false; // TODO

    GetMinMaxFlowRateAndPSILoss = (): {maxFlow: number, minFlow: number, psiLossAtMaxFlow: number} | null => {
        let maxFlow = 0;
        let minFlow = 0;
        let psiLossAtMaxFlow = 0;

        if (this.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro) return null;

        let flangedSide = this.System.FlangedSide;
        if (flangedSide.EndOfSystem.EndGun.EndGunTypePrimary != EndGunTypes.None && flangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties){
            minFlow = flangedSide.SprinklerChart.IrrigationProperties.EndGunIrrigationProperties.GPMDelivered;
        }

        let iSACSpan = this.SwingArmSpan();

        if (HasSwingArmCorner(this.System)){
            let iFirstSACOutlet: number;

            flangedSide.SprinklerChart.Outlet.forEach((outlet, i) => {
                if (outlet.SpanNumber === iSACSpan){
                    iFirstSACOutlet = i;
                }
            });

            for (let i = iFirstSACOutlet; i < flangedSide.SprinklerChart.Outlet.length; i++){
                let gpmDelivered = flangedSide.SprinklerChart.Outlet[i].GPMDelivered
                if (HasEnergySaverPackage(this.System)){
                    if ((i - iFirstSACOutlet + 1) % 2 === 1){
                        maxFlow += gpmDelivered;
                    }
                    else {
                        minFlow += gpmDelivered;
                    }
                }
                else {
                    minFlow += gpmDelivered;
                }
            }
        }

        if (this.System.SprinklerProperties){
            let totalgpm = this.System.SprinklerProperties.TotalGPM

            maxFlow += totalgpm;
            minFlow = 0 - minFlow + totalgpm;

            if (maxFlow !== totalgpm){
                for (let i = 0; i < iSACSpan; i++){
                    psiLossAtMaxFlow += this.SprinklerChartClass.RightSprinklersClass.SpanPressureLoss(i, maxFlow);
                }
            }
            else {
                psiLossAtMaxFlow = this.System.SprinklerProperties.PivotPressure - 
                    flangedSide.SprinklerChart.IrrigationProperties.ComputedEndPressure -
                    (this.System.SprinklerProperties.PeakElevation/2.31) 
            }
        }

        return {
            maxFlow,//GPM
            minFlow,//GPM
            psiLossAtMaxFlow
        }
    }

    BasePressureMod = (): number => {
        let BaseMod: number;
        switch (this.System.SystemProperties.SystemType) {
            case SystemTypes.CenterPivot:
                switch (this.System.Circle.CenterPivot.PivotCenterHeight) {
                    case TowerHeights.LowProfile:
                        BaseMod = 4.0;
                        break;
                    case TowerHeights.Standard:
                        BaseMod = 5.5;
                        break;
                    case TowerHeights.Sugargator:
                        BaseMod = 8.0;
                        break;
                }
                break;
            case SystemTypes.KwikTow:
                BaseMod = 5.5;
                break;
            case SystemTypes.HoseFeedMaxigator:
                switch (this.System.Lateral.HoseFeed.HoseFeedType) {
                    case HoseFeedTypes.Sugargator:
                        BaseMod = 8.0;
                        break;
                    default:
                        BaseMod = 5.5;
                        break;
                }
                break;
            case SystemTypes.CanalFeedMaxigator:
                BaseMod = 5.5;
                break;
        }
        return BaseMod;
    }

    SwingArmSpan = (): number => {
        if (sysf.HasSwingArmCorner(this.System)) {
            const ss = sidef.Spans(this.System, this.System.FlangedSide);
            const i = ss.Count - 1;
            if (i < 1) return 0;
            if (ss.Span[i - 1].SwingArm) return i;
            // Simon: Added the following line.
            // In the VB code there is no explicit return if we get this far.
            // VB will fallout the fn returning the default for Integer
            // JS will return undefined.
            // Thus, returning 0 here:
            return 0;
        } else {
            return 0;
        }
    }

    EndOfSystemCount = (): number => {
        switch (this.System.SystemProperties.SystemType) {
            case SystemTypes.CenterPivot:
                if (this.RightEOSClass.FieldSet.DataValid()) return 1;
            case SystemTypes.HoseFeedMaxigator:
                if (sysf.IsCenterFeed(this.System)) {
                    return 2;
                } else if (sysf.HasPowerTowerEndBoom(this.System)) {
                    if (this.IsDEF()) {
                        
                if (this.RightEOSClass.FieldSet.DataValid()) {
                            if (this.RightEOSClass.EndBoomExists()) {
                                return 2;
                            }
                        }
                        return 1;
                    } else {
                        return 2;
                    }
                } else {
                    if (this.IsDEF()) {
                        
                if (this.RightEOSClass.FieldSet.DataValid()) {
                        if (this.RightEOSClass.EndBoomExists()) {
                            return 1;
                        }
                    }
                        return 0;
                    } else {
                        return 1;
                    }
                }
            case SystemTypes.CanalFeedMaxigator:
                if (sysf.IsCenterFeed(this.System)) {
                    return 2;
                } else {
                    return 1;
                }
            case SystemTypes.KwikTow:
                
            if (this.RightEOSClass.FieldSet.DataValid()) return 1;
            case SystemTypes.SwingArmRetro:
                return 1;
        }
        return 0;
    }

    HasTowerAutoStop = (): boolean =>
        this.RightTowersClass.DataValid() && 
        (!sysf.IsCenterFeed(this.System) || this.LeftTowersClass.DataValid())
        && (this.System.FlangedSide.Tower.some(x => x.TowerAutoStop)
        || (sysf.IsCenterFeed(this.System) && this.System.FlexSide.Tower.some(x => x.TowerAutoStop)));

    HasSpanDisc = (): boolean =>
    this.RightSpansClass.DataValid() && 
        (!sysf.IsCenterFeed(this.System) || this.LeftSpansClass.DataValid())
    && (this.System.FlangedSide.Span.some(x => x.Disconnecting)
        || (sysf.IsCenterFeed(this.System) && this.System.FlexSide.Span.some(x => x.Disconnecting)));

        
    HasWrapSpan = (): boolean =>
        this.RightTowersClass.DataValid() && 
        (!sysf.IsCenterFeed(this.System) || this.LeftTowersClass.DataValid())
        && (this.System.FlangedSide.Tower.some(x => (x.WrapAroundSpan ?? WrapAroundSpanTypes.None) !== WrapAroundSpanTypes.None)
    || (sysf.IsCenterFeed(this.System) && this.System.FlexSide.Tower.some(x => (x.WrapAroundSpan ?? WrapAroundSpanTypes.None) !== WrapAroundSpanTypes.None)));
}