import { nanoid } from "nanoid";
import IPutOrderRequest from "rdptypes/api/IPutOrderRequest";
import IProposal from "rdptypes/roe/IProposal";
import * as React from "react";
import { FC, useContext, useState } from "react";
import ApiClientCtx from "../../../../api/ApiClientCtx";
import { ApiRequestStatus } from "../../../../api/ApiRequestState";
import DbCtx from "../../../../db/DbCtx";
import DealerDataCtx from "../../../../userData/DealerDataCtx";
import Spinner from "../../../Spinner";
import roeData from "../../staticDataLoader";
import { createNewUpdateSystemPropertyAction } from "./../../../../actions/UpdateSystemProperty";
import AuthCtx from "./../../../../auth/AuthCtx";
import IDbProject from "./../../../../db/IDbProject";
import ProposalWarningsRenderer from "./ProposalWarningsRenderer";
import ViewProposalRenderer from "./ViewProposalRenderer";

interface Props {
    cmp: IProposal,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
};

const ProposalRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const dbState = useContext(DbCtx);
    const user = useContext(DealerDataCtx);
    const api = useContext(ApiClientCtx);

    const grower = dbState.growers[props.dbPrj.state.growerId];

    const layout = props.dbPrj.state.layouts[props.layoutId!];
    const system = layout.systems[props.systemId!];

    const [creatingQuote, setCreatingQuote] = useState<boolean>(false);

    const warnings = roeData.rules.flatMap(rule => rule.apply(system, user, grower));

    const createQuote = async () => {
        const req: IPutOrderRequest = {};

        setCreatingQuote(true);

        const resp = await api.putOrder(props.systemId!, req);
        if (resp.status === ApiRequestStatus.Success) {
            props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                props.layoutId,
                props.systemId,
                "createQuoteStatus",
                {
                    success: resp.result.success,
                    quoteId: resp.result.success ? resp.result.quoteId : undefined,
                    errorMessage: resp.result.success ? undefined : resp.result.message,
                    timestamp: resp.result.timestamp
                },
                authState));
        } else {
            props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                props.layoutId,
                props.systemId,
                "createQuoteStatus",
                {
                    success: false,
                    errorMessage: resp.error
                },
                authState));
        }

        setCreatingQuote(false);
    };


    const generateProposal = async () => {
        if (!system.createQuoteStatus?.success) {
            await createQuote();
        }

        props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
            props.layoutId,
            props.systemId,
            "proposalGenerated",
            nanoid(),
            authState));
    }

    if (creatingQuote) {
        return <Spinner inline title="Getting quote ID from Reinke"/>;
    } else if (props.dbPrj.state.layouts[props.layoutId].systems[props.systemId].proposalGenerated) {
        return <ViewProposalRenderer
            grower={grower}
            systemId={props.systemId}
            layoutId={props.layoutId}
            project={props.dbPrj.state}
            onDiscardProposal={() => {
                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                    props.layoutId,
                    props.systemId,
                    "proposalGenerated",
                    false,
                    authState));
            }} />
    }

    return (
        <ProposalWarningsRenderer warnings={warnings} onGenerateProposal={generateProposal} />
    );
};

export default ProposalRenderer;