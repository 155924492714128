import { Button, Card, CardContent, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Tooltip } from "@mui/material";
import * as React from "react";
import { FC, useEffect } from "react";

import "@fontsource/libre-franklin/900.css";
import { HelpOutline } from "@mui/icons-material";
import Typography from "@mui/material/Typography/Typography";
import ToolsPageBase from "../toolComponents/ToolPageBase";

interface MainLinePressureLoss{
    style: PipeStyle;
    maxPSI?: number;
    size?: number;
    cFactor?: number;
    calculatedInsideDiameterInches?: number;
    insideDiameterInches?: number;
    pipeLengthFeet?: number;
    irrigationRateGPM?: number;
    psiLoss?: number;
    pivotToPumpElevation?: number;
    pipeMaterial?: PipeMaterial;
    elevationPsiLoss?: number;
    totalPsiLoss?: number;
    velocityFeetPerSecond?: number;
}

enum PipeStyle{
    IPS,
    PIP
}

enum PipeMaterial{
    AcrylonitrileButadieneStyrene,
    Aluminium,
    AsbestosCement,
    AsphaltLining,
    Brass,
    CastIronNew,
    CastIron10YearsOld,
    CastIron20YearsOld,
    CastIron30YearsOld,
    CastIron40YearsOld,
    CastIronAsphaltCoated,
    CastIronBituminousLined,
    CastIronCementLined,
    CastIronSeaCoated,
    CastIronWroughtPlain,
    CementLining,
    Concrete,
    ConcreteLinedSteelForms,
    ConcreteLinedWoodenForms,
    ConcreteOld,
    Copper,
    CorrugatedMetal,
    DuctileIronPipe,
    DuctileIronCementLined,
    Fiber,
    Fiberglass,
    FireHoseRubberLined,
    GalvanizedIron,
    Glass,
    HDPE,
    Lead,
    MetalPipesVerySmooth,
    Plastic,
    Polyethylene,
    Polyvinylchloride,
    SmoothPipes,
    SteelNewUnlined,
    SteelCorrugated,
    SteelInteriorRivetedNoProjecting,
    SteelProjectingGirthHorizontalRivets,
    SteelVitrifiedSpiralRiveted,
    SteelWeldedAndSeamless,
    Tin,
    VitrifiedClay,
    WoodStave,
    WoodenOrMasonryPipeSmooth,
    WroughtIronPlain,
    Custom
}

interface Props {

}

const MainLinePressureLossPage: FC<Props> = (props) => {
    const [mainLinePressureLoss, setMainLinePressureLoss] = React.useState<MainLinePressureLoss>({
        style: PipeStyle.IPS,
        pivotToPumpElevation: 0,
        elevationPsiLoss: 0
    });

    useEffect(() => {
        let cFactor: number;
        switch (mainLinePressureLoss.pipeMaterial){            
            case PipeMaterial.CorrugatedMetal:
            case PipeMaterial.SteelCorrugated:
                cFactor = 60;
            break;
            case PipeMaterial.CastIron40YearsOld:
                cFactor = 73.5;
            break;
            case PipeMaterial.CastIron30YearsOld:
                cFactor = 82.5;
                break;
            case PipeMaterial.CastIron20YearsOld:
                cFactor = 94.5;
                break;            
            case PipeMaterial.SteelVitrifiedSpiralRiveted:
                cFactor = 95;
                break;
            case PipeMaterial.CastIronAsphaltCoated:
            case PipeMaterial.CastIronWroughtPlain:
            case PipeMaterial.SteelProjectingGirthHorizontalRivets:
            case PipeMaterial.SteelWeldedAndSeamless:
            case PipeMaterial.WroughtIronPlain:
                cFactor = 100;
                break;
            case PipeMaterial.ConcreteOld:
                cFactor = 105;
                break;
            case PipeMaterial.CastIron10YearsOld:
            case PipeMaterial.SteelInteriorRivetedNoProjecting:
            case PipeMaterial.VitrifiedClay:
                cFactor = 110;
                break;
            case PipeMaterial.WoodStave:
                cFactor = 115;
                break;
            case PipeMaterial.CastIronSeaCoated:
            case PipeMaterial.Concrete:
            case PipeMaterial.ConcreteLinedWoodenForms:
            case PipeMaterial.DuctileIronCementLined:
            case PipeMaterial.GalvanizedIron:
            case PipeMaterial.WoodenOrMasonryPipeSmooth:
                cFactor = 120;
                break;
            case PipeMaterial.AcrylonitrileButadieneStyrene:
            case PipeMaterial.CastIronNew:
            case PipeMaterial.Glass:
            case PipeMaterial.Tin:
                cFactor = 130;
                break;
            case PipeMaterial.AsphaltLining:
            case PipeMaterial.Brass:
            case PipeMaterial.CementLining:
            case PipeMaterial.Copper:
            case PipeMaterial.FireHoseRubberLined:
            case PipeMaterial.Lead:
            case PipeMaterial.MetalPipesVerySmooth:
                cFactor = 135;
                break;
            case PipeMaterial.Aluminium:
            case PipeMaterial.AsbestosCement:
            case PipeMaterial.CastIronBituminousLined:
            case PipeMaterial.CastIronCementLined:
            case PipeMaterial.ConcreteLinedSteelForms:
            case PipeMaterial.Fiber:
            case PipeMaterial.Plastic:
            case PipeMaterial.Polyethylene:
            case PipeMaterial.SmoothPipes:
                cFactor = 140;
                break;
            case PipeMaterial.SteelNewUnlined:
                cFactor = 145;
                break;
            case PipeMaterial.Fiberglass:
            case PipeMaterial.Polyvinylchloride:
                cFactor = 150;
                break;
            case PipeMaterial.HDPE:
                cFactor = 152;
                break;
            case PipeMaterial.DuctileIronPipe:
                cFactor = 154;
                break;
        }

        setMainLinePressureLoss({...mainLinePressureLoss, cFactor});
    }, [mainLinePressureLoss.pipeMaterial]);

    useEffect(() => {
        let psiLoss: number;
        if (typeof mainLinePressureLoss.cFactor !== "undefined" && typeof mainLinePressureLoss.insideDiameterInches !== "undefined" &&
            typeof mainLinePressureLoss.pipeLengthFeet !== "undefined" && typeof mainLinePressureLoss.irrigationRateGPM !== "undefined"
        )
        {
            //Hazen-Williams equation
            psiLoss = (4.52 * mainLinePressureLoss.pipeLengthFeet * Math.pow(mainLinePressureLoss.irrigationRateGPM, 1.852))/(Math.pow(mainLinePressureLoss.cFactor, 1.852) * Math.pow(mainLinePressureLoss.insideDiameterInches, 4.8704));
        }

        setMainLinePressureLoss({...mainLinePressureLoss, psiLoss});
    }, [mainLinePressureLoss.cFactor, mainLinePressureLoss.insideDiameterInches, mainLinePressureLoss.pipeLengthFeet, mainLinePressureLoss.irrigationRateGPM]);

    useEffect(() => {
        let elevationPsiLoss: number;
        let totalPsiLoss: number;
        if (typeof mainLinePressureLoss.pivotToPumpElevation !== "undefined" && typeof mainLinePressureLoss.psiLoss !== "undefined")
        {
            elevationPsiLoss = (0 - mainLinePressureLoss.pivotToPumpElevation) * 0.432;
            totalPsiLoss = elevationPsiLoss + mainLinePressureLoss.psiLoss;
        }

        setMainLinePressureLoss({...mainLinePressureLoss, elevationPsiLoss, totalPsiLoss});
    }, [mainLinePressureLoss.pivotToPumpElevation, mainLinePressureLoss.psiLoss]);

    useEffect(() => {
        let velocityFeetPerSecond: number;
        if (typeof mainLinePressureLoss.irrigationRateGPM !== "undefined" && typeof mainLinePressureLoss.insideDiameterInches !== "undefined")
        {
            velocityFeetPerSecond = (0.408 * mainLinePressureLoss.irrigationRateGPM)/Math.pow(mainLinePressureLoss.insideDiameterInches,2);
        }

        setMainLinePressureLoss({...mainLinePressureLoss, velocityFeetPerSecond});
    }, [mainLinePressureLoss.irrigationRateGPM, mainLinePressureLoss.insideDiameterInches]);

    useEffect(() => {
        let calculatedInsideDiameter: number;

        if (typeof mainLinePressureLoss.style !== "undefined" && mainLinePressureLoss.maxPSI && mainLinePressureLoss.size){
            if (mainLinePressureLoss.style === PipeStyle.IPS){
                switch (mainLinePressureLoss.maxPSI){
                    case 63:
                        if (mainLinePressureLoss.size === 6) calculatedInsideDiameter = 6.417;
                        else if (mainLinePressureLoss.size === 8) calculatedInsideDiameter = 8.355;
                        else if (mainLinePressureLoss.size === 10) calculatedInsideDiameter = 10.414;
                        else if (mainLinePressureLoss.size === 12) calculatedInsideDiameter = 12.352;
                        break;
                    case 100:
                        if (mainLinePressureLoss.size === 4) calculatedInsideDiameter = 4.28;
                        else if (mainLinePressureLoss.size === 5) calculatedInsideDiameter = 5.291;
                        else if (mainLinePressureLoss.size === 6) calculatedInsideDiameter = 6.301;
                        else if (mainLinePressureLoss.size === 8) calculatedInsideDiameter = 8.205;
                        else if (mainLinePressureLoss.size === 10) calculatedInsideDiameter = 10.226;
                        else if (mainLinePressureLoss.size === 12) calculatedInsideDiameter = 12.158;
                        break;
                    case 125:
                        if (mainLinePressureLoss.size === 3) calculatedInsideDiameter = 3.284;
                        else if (mainLinePressureLoss.size === 4) calculatedInsideDiameter = 4.224;
                        else if (mainLinePressureLoss.size === 5) calculatedInsideDiameter = 5.221;
                        else if (mainLinePressureLoss.size === 6) calculatedInsideDiameter = 6.217;
                        else if (mainLinePressureLoss.size === 8) calculatedInsideDiameter = 8.095;
                        else if (mainLinePressureLoss.size === 10) calculatedInsideDiameter = 10.088;
                        else if (mainLinePressureLoss.size === 12) calculatedInsideDiameter = 11.966;
                        break;
                    case 160:
                        if (mainLinePressureLoss.size === 2) calculatedInsideDiameter = 2.193;
                        else if (mainLinePressureLoss.size === 3) calculatedInsideDiameter = 3.23;
                        else if (mainLinePressureLoss.size === 4) calculatedInsideDiameter = 4.154;
                        else if (mainLinePressureLoss.size === 5) calculatedInsideDiameter = 5.135;
                        else if (mainLinePressureLoss.size === 6) calculatedInsideDiameter = 6.115;
                        else if (mainLinePressureLoss.size === 8) calculatedInsideDiameter = 7.961;
                        else if (mainLinePressureLoss.size === 10) calculatedInsideDiameter = 9.924;
                        else if (mainLinePressureLoss.size === 12) calculatedInsideDiameter = 11.538;
                        break;
                    case 200:
                        if (mainLinePressureLoss.size === 2) calculatedInsideDiameter = 2.149;
                        else if (mainLinePressureLoss.size === 3) calculatedInsideDiameter = 3.116;
                        else if (mainLinePressureLoss.size === 4) calculatedInsideDiameter = 4.072;
                        else if (mainLinePressureLoss.size === 6) calculatedInsideDiameter = 5.993;
                        else if (mainLinePressureLoss.size === 8) calculatedInsideDiameter = 7.805;
                        else if (mainLinePressureLoss.size === 10) calculatedInsideDiameter = 9.728;
                        else if (mainLinePressureLoss.size === 12) calculatedInsideDiameter = 11.538;
                        break;
                }
            }
            else {
                switch (mainLinePressureLoss.maxPSI){
                    case 80:
                        if (mainLinePressureLoss.size === 6) calculatedInsideDiameter = 5.898;
                        else if (mainLinePressureLoss.size === 8) calculatedInsideDiameter = 7.84;
                        else if (mainLinePressureLoss.size === 10) calculatedInsideDiameter = 9.79;
                        else if (mainLinePressureLoss.size === 12) calculatedInsideDiameter = 11.76;
                        else if (mainLinePressureLoss.size === 15) calculatedInsideDiameter = 14.7;
                        else if (mainLinePressureLoss.size === 18) calculatedInsideDiameter = 17.967;
                        else if (mainLinePressureLoss.size === 21) calculatedInsideDiameter = 21.183;
                        else if (mainLinePressureLoss.size === 24) calculatedInsideDiameter = 23.831;
                        else if (mainLinePressureLoss.size === 27) calculatedInsideDiameter = 26.857;
                        break;
                    case 100:
                        if (mainLinePressureLoss.size === 6) calculatedInsideDiameter = 5.84;
                        else if (mainLinePressureLoss.size === 8) calculatedInsideDiameter = 7.762;
                        else if (mainLinePressureLoss.size === 10) calculatedInsideDiameter = 9.702;
                        else if (mainLinePressureLoss.size === 12) calculatedInsideDiameter = 11.642;
                        else if (mainLinePressureLoss.size === 15) calculatedInsideDiameter = 14.55;
                        else if (mainLinePressureLoss.size === 18) calculatedInsideDiameter = 17.789;
                        else if (mainLinePressureLoss.size === 21) calculatedInsideDiameter = 20.971;
                        else if (mainLinePressureLoss.size === 24) calculatedInsideDiameter = 23.593;
                        else if (mainLinePressureLoss.size === 27) calculatedInsideDiameter = 26.589;
                        break;
                    case 125:
                        if (mainLinePressureLoss.size === 6) calculatedInsideDiameter = 5.762;
                        else if (mainLinePressureLoss.size === 8) calculatedInsideDiameter = 7.658;
                        else if (mainLinePressureLoss.size === 10) calculatedInsideDiameter = 9.572;
                        else if (mainLinePressureLoss.size === 12) calculatedInsideDiameter = 11.486;
                        else if (mainLinePressureLoss.size === 15) calculatedInsideDiameter = 14.358;
                        else if (mainLinePressureLoss.size === 18) calculatedInsideDiameter = 17.551;
                        else if (mainLinePressureLoss.size === 21) calculatedInsideDiameter = 20.691;
                        else if (mainLinePressureLoss.size === 24) calculatedInsideDiameter = 23.277;
                        else if (mainLinePressureLoss.size === 27) calculatedInsideDiameter = 26.233;
                        break;
                    case 160:
                        if (mainLinePressureLoss.size === 15) calculatedInsideDiameter = 14.124;
                        else if (mainLinePressureLoss.size === 18) calculatedInsideDiameter = 17.263;
                        break;
                    case 200:
                        if (mainLinePressureLoss.size === 15) calculatedInsideDiameter = 13.842;
                        break;
                }
            }
        }

        setMainLinePressureLoss({...mainLinePressureLoss, calculatedInsideDiameterInches: calculatedInsideDiameter, insideDiameterInches: calculatedInsideDiameter});
      },
        [mainLinePressureLoss.style, mainLinePressureLoss.maxPSI, mainLinePressureLoss.size]
      );

    var supportedMaxPSIValues: number[];
    if (mainLinePressureLoss.style === PipeStyle.IPS){
        supportedMaxPSIValues = [63, 100, 125, 160, 200];
    }
    else {
        supportedMaxPSIValues = [80, 100, 125, 160, 200];
    }

    var supportedPipeSizes: number[] = [];

    switch (mainLinePressureLoss.maxPSI) {
        case 63:
            if (mainLinePressureLoss.style === PipeStyle.IPS){
                supportedPipeSizes = [6, 8, 10, 12];
            }
            break;
        case 80:
            if (mainLinePressureLoss.style === PipeStyle.PIP) {
                supportedPipeSizes = [6, 8, 10, 12, 15, 18, 21, 24, 27];
            }
            break;
        case 100:
            if (mainLinePressureLoss.style === PipeStyle.IPS){
                supportedPipeSizes = [4, 5, 6, 8, 10, 12];
            }
            else {
                supportedPipeSizes = [6, 8, 10, 12, 15, 18, 21, 24, 27];
            }
            break;
        case 125:
            if (mainLinePressureLoss.style === PipeStyle.IPS){
                supportedPipeSizes = [3, 4, 5, 6, 8, 10, 12];
            }
            else {
                supportedPipeSizes = [6, 8, 10, 12, 15, 18, 21, 24, 27];
            }
            break;
        case 160:
            if (mainLinePressureLoss.style === PipeStyle.IPS){
                supportedPipeSizes = [2, 3, 4, 5, 6, 8, 10, 12];
            }
            else {
                supportedPipeSizes = [15, 18];
            }
            break;
        case 200:
            if (mainLinePressureLoss.style === PipeStyle.IPS){
                supportedPipeSizes = [2, 3, 4, 6, 8, 10, 12];
            }
            else {
                supportedPipeSizes = [15];
            }
            break;
    }

    const enumToNumericValues = (enumValue: object) => {
        return Object.keys(enumValue).map(Number).filter(Boolean);
    }

    const formatPipeMaterial = (material: PipeMaterial) => {
        if (material === PipeMaterial.HDPE) return "HDPE";
        if (material === PipeMaterial.CastIron10YearsOld) return "Cast Iron - 10 years old";
        if (material === PipeMaterial.CastIron20YearsOld) return "Cast Iron - 20 years old";
        if (material === PipeMaterial.CastIron30YearsOld) return "Cast Iron - 30 years old";
        if (material === PipeMaterial.CastIron40YearsOld) return "Cast Iron - 40 years old";

        let s_ = PipeMaterial[material].split(/(?=[A-Z])/);
        return s_.join(" ");
    }

    let pipeMaterialOptions: JSX.Element[] = [];
    enumToNumericValues(PipeMaterial).forEach(material => {
        pipeMaterialOptions.push(<MenuItem key={material} value={material}>{formatPipeMaterial(material)}</MenuItem>)
    });

    return (
        <ToolsPageBase title="Main Line Pressure Loss">
             <Card variant="outlined" style={{marginBottom: 10}}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Common Pipe Sizes / Specifications</Typography>
                    <Stack direction="row">
                        <FormControl variant="filled" size="small" style={{minWidth: 150, marginRight: 10}}>
                            <InputLabel >Style</InputLabel>
                            <Select
                                value={mainLinePressureLoss.style}
                                onChange={(ev) => setMainLinePressureLoss({...mainLinePressureLoss, style: +ev.target.value})}
                            >
                            <MenuItem key={PipeStyle.IPS} value={PipeStyle.IPS}>IPS</MenuItem>
                            <MenuItem key={PipeStyle.PIP} value={PipeStyle.PIP}>PIP</MenuItem>
                            </Select>
                        </FormControl> 
                        <FormControl variant="filled" size="small" style={{minWidth: 150, marginRight: 10}}>
                            <InputLabel >Max PSI</InputLabel>
                            <Select
                                value={mainLinePressureLoss.maxPSI}
                                onChange={(ev) => setMainLinePressureLoss({...mainLinePressureLoss, maxPSI: +ev.target.value})}
                            >
                            {supportedMaxPSIValues.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                            </Select>
                        </FormControl> 
                        <FormControl variant="filled" size="small" style={{minWidth: 150, marginRight: 10}}>
                            <InputLabel >Size</InputLabel>
                            <Select
                                value={mainLinePressureLoss.size}
                                onChange={(ev) => setMainLinePressureLoss({...mainLinePressureLoss, size: +ev.target.value})}
                            >
                            {supportedPipeSizes.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <TextField
                            variant="filled"
                            size="small"
                            disabled
                            type="number"
                            label={"Pipe Inside Diameter"}       
                            InputLabelProps={{shrink: mainLinePressureLoss.calculatedInsideDiameterInches} as any}         
                            value={mainLinePressureLoss.calculatedInsideDiameterInches || ""}    
                            InputProps={{ endAdornment: <InputAdornment position="end">in</InputAdornment> }}       
                        />                       
                    </Stack>                  
                </CardContent>
            </Card>
            <Card variant="outlined" style={{marginBottom: 10}}>
                <CardContent>
                    <Stack direction="row" style={{ justifyContent: 'space-between', width: '100%'}}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Pressure Loss Equation <Tooltip title="Fill out the boxes below to calculate PSI loss. If you do not know the pipe inside diameter, use the section above. If your pipe material is not listed, select Custom and enter the C-Factor (roughness coefficient) manually">
                                    <IconButton className="no-print" sx={{color: "info.main"}}>
                                        <HelpOutline />
                                    </IconButton>
                                    </Tooltip></Typography>
                        {
                            typeof mainLinePressureLoss.velocityFeetPerSecond !== "undefined" && 
                            <Typography sx={{ fontSize: 14 }} color={mainLinePressureLoss.velocityFeetPerSecond > 5 ? "red" :"info.main"} gutterBottom>Velocity: {mainLinePressureLoss.velocityFeetPerSecond.toFixed(2)} ft/sec</Typography>
                        }
                    </Stack>
                    <Stack direction="row">
                        <TextField
                                variant="filled"
                                size="small"
                                type="number"
                                style={{marginRight: 10}}
                                label={"Pipe Length"}      
                                onChange={(ev) => setMainLinePressureLoss({...mainLinePressureLoss, pipeLengthFeet: +ev.target.value})}          
                                value={mainLinePressureLoss.pipeLengthFeet}           
                                InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment>, }}       
                            />
                            <TextField
                                variant="filled"
                                size="small"                           
                                type="number"
                                style={{marginRight: 10}}
                                label={"Irrigation Rate"}                
                                value={mainLinePressureLoss.irrigationRateGPM}
                                onChange={(ev) => setMainLinePressureLoss({...mainLinePressureLoss, irrigationRateGPM: +ev.target.value})}         
                                InputProps={{ endAdornment: <InputAdornment position="end">GPM</InputAdornment>, }}         
                            />
                            <FormControl variant="filled" size="small" style={{minWidth: 150, marginRight: 10}}>
                                <InputLabel>Pipe Material</InputLabel>
                                <Select
                                    value={mainLinePressureLoss.pipeMaterial}
                                    onChange={(ev) => setMainLinePressureLoss({...mainLinePressureLoss, pipeMaterial: +ev.target.value})}
                                >
                                    {pipeMaterialOptions}
                                </Select>
                            </FormControl> 
                            <TextField
                                variant="filled"
                                style={{marginRight: 10}}
                                size="small"
                                type="number"
                                disabled={mainLinePressureLoss.pipeMaterial !== PipeMaterial.Custom}
                                label={"C-Factor"}               
                                InputLabelProps={{shrink: mainLinePressureLoss.cFactor} as any}     
                                value={mainLinePressureLoss.cFactor || ""}
                                onChange={(ev) => setMainLinePressureLoss({...mainLinePressureLoss, cFactor: +ev.target.value})}           
                            />
                            <TextField
                                variant="filled"
                                style={{marginRight: 10}}
                                size="small"
                                type="number"
                                label={"Pipe Inside Diameter"}   
                                InputLabelProps={{shrink: mainLinePressureLoss.insideDiameterInches} as any}                 
                                value={mainLinePressureLoss.insideDiameterInches}     
                                onChange={(ev) => setMainLinePressureLoss({...mainLinePressureLoss, insideDiameterInches: +ev.target.value})}
                                InputProps={{ endAdornment: <InputAdornment position="end">in</InputAdornment>, }}           
                            />
                            <TextField
                                variant="filled"
                                size="small"
                                type="number"
                                label={"PSI Loss"}         
                                disabled       
                                InputLabelProps={{shrink: mainLinePressureLoss.psiLoss} as any}   
                                value={typeof mainLinePressureLoss.psiLoss !== "undefined" ? mainLinePressureLoss.psiLoss.toFixed(3): ""}  
                                onChange={(ev) => setMainLinePressureLoss({...mainLinePressureLoss, psiLoss: +ev.target.value})}         
                            />
                    </Stack>                    
                </CardContent>
            </Card>
            <Card variant="outlined" style={{marginBottom: 10}}>
                <CardContent>
                    <TextField
                        variant="filled"
                        style={{marginRight: 10}}
                        size="small"
                        type="number"
                        label={"Pivot to Pump Elevation (+/-)"}                
                        value={mainLinePressureLoss.pivotToPumpElevation}
                        onChange={(ev) => setMainLinePressureLoss({...mainLinePressureLoss, pivotToPumpElevation: +ev.target.value})}           
                    />
                    <TextField
                        variant="filled"
                        style={{marginRight: 10}}
                        size="small"
                        disabled
                        type="number"
                        label={"Elevation PSI Loss"}    
                        InputLabelProps={{shrink: typeof mainLinePressureLoss.elevationPsiLoss !== "undefined"} as any}               
                        value={typeof mainLinePressureLoss.elevationPsiLoss !== "undefined" ? mainLinePressureLoss.elevationPsiLoss.toFixed(3): ""}      
                    />
                    <TextField
                        variant="filled"
                        style={{marginRight: 10}}
                        size="small"
                        type="number"
                        disabled
                        label={"Total PSI Loss"}  
                        InputLabelProps={{shrink: typeof mainLinePressureLoss.totalPsiLoss !== "undefined"} as any}                
                        value={typeof mainLinePressureLoss.totalPsiLoss !== "undefined" ? mainLinePressureLoss.totalPsiLoss.toFixed(3): ""}              
                    />                
                </CardContent>
            </Card>
            <Stack direction="row" style={{justifyContent: 'flex-end'}}>
                <Button  className="no-print" variant="outlined" style={{marginRight: 5}} onClick={() => {window.print();}}>Print</Button>
                <Button  className="no-print" variant="contained" style={{marginRight: 5}} onClick={() => window.close()}>Close</Button>
            </Stack>
        </ToolsPageBase>
    );
};

export default MainLinePressureLossPage;