import { InputAdornment, TableCell, TableRow, TextField } from "@mui/material";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import DollarFormattedInput from "../DollarFormattedInput";

interface Props {
    savePercentage?: (percentage: number) => void;
    saveUsdCents?: (usdCents: number) => void;
    priceComputation: "Price" | "Percent";
    percentage: number | undefined;
    usdCents: number;
    label: string;
    readonly?: boolean;
};

const PercentageTableRow: FC<Props> = (props) => {
    const {savePercentage, percentage, label, readonly, usdCents, priceComputation, saveUsdCents} = props;

    const [stateDollar, setStateDollar] = useState(usdCents ? usdCents/100 : 0);//have to use state here because of dollar formatting component
    const [statePercentage, setStatePercentage] = useState(percentage);

    useEffect(() => {
        setStateDollar(usdCents ? usdCents/100 : 0);
    }, [usdCents]);

    useEffect(() => {
        setStatePercentage(percentage);
    }, [percentage]);

    return (
        <>
            <TableRow>
                <TableCell sx={{textAlign: "right", padding: "3px 16px"}}>{label}</TableCell>
                {priceComputation === "Percent" && <TableCell sx={{textAlign: "center", padding: "3px 16px", width: "140px", minWidth: "60px"}}>
                    <TextField size="small" InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }} value={statePercentage} disabled={readonly} type="number" onChange={(e) => {
                        if (!readonly){
                            let val = parseFloat(e.target.value);
                            if (isNaN(val)) return;
                            setStatePercentage(val);
                        }
                    }} onBlur={(e) => {
                            if (!readonly && savePercentage){
                                let val = parseFloat(e.target.value);
                                if (isNaN(val)) return;
                                setStatePercentage(val);
                                savePercentage(val);
                            }
                        }}
                    />
                </TableCell>}
                <TableCell colSpan={priceComputation === "Percent" ? 1 : 2} sx={{textAlign: "left", padding: "3px 16px"}}>
                    <TextField size="small" value={stateDollar} 
                        disabled={readonly || priceComputation === "Percent"}
                        onChange={(e) => {
                            if (!readonly){
                                let valNum = parseFloat(e.target.value.replace("$", "").replace(",", ""));
                                if (isNaN(valNum)) return;
                                setStateDollar(valNum);
                            }
                        }}
                        onBlur={(e) => {
                            if (!readonly && saveUsdCents){
                                let valNum = parseFloat(e.target.value.replace("$", "").replace(",", ""));
                                if (isNaN(valNum)) return;
                                setStateDollar(valNum);
                                saveUsdCents(valNum*100);
                            }
                        }}
                    InputProps={{
                        inputComponent: DollarFormattedInput as any}}/>
                </TableCell>
            </TableRow>
        </>
    );
};

export default PercentageTableRow;