import { Button, DialogActions, DialogContent, MenuItem, Stack, TextField } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { createNewUpdateProjectPropertyAction } from "../../actions/UpdateProjectProperty";
import AuthCtx from "../../auth/AuthCtx";
import IDbProject from "../../db/IDbProject";
import IDbState from "../../db/IDbState";

interface Props {
    dbPrj: IDbProject;
    dbState: IDbState;

    onConfirm: () => any;
    alternativeBackLabel?: string;
    alternativeNextLabel?: string;

    selectedGrowerId: string | null;
    
    disableChangeGrower?: boolean;
    justRename?: boolean;
};

const ProjectDetails: FC<Props> = ({
    disableChangeGrower = false,
    ...props
}) => {
    const ns = "projects.";

    const authState = useContext(AuthCtx);

    const project = props.dbPrj.state;

    const [name, setName] = useState(project.name);

    const getGrowerIdIfValid = (nextGrowerId: string | null) => {
        if (nextGrowerId && props.dbState.growers[nextGrowerId]) {
            return nextGrowerId
        }
        return null;
    }
    const [growerId, setGrowerId] = useState<string | null>(() => getGrowerIdIfValid(props.selectedGrowerId));

    useEffect(() => {
        setGrowerId(getGrowerIdIfValid(props.selectedGrowerId));
    }, [props.selectedGrowerId, props.dbState])
    
    const [validate, setValidate] = useState(false);

    // const addPrefix = `${t('Add')} `;

    const onConfirm = () => {
        if (name === "" || growerId === undefined || growerId === null) {
            setValidate(true);
            return;
        }

        props.dbPrj.pushAction({
            ...createNewUpdateProjectPropertyAction("name", name, authState),
            undoMode: "block", // Don't allow undoing new project actions
        });
        props.dbPrj.pushAction({
            ...createNewUpdateProjectPropertyAction("growerId", growerId, authState),
            undoMode: "block", // Don't allow undoing new project actions
        });
        props.onConfirm();
    };

    let growerArr = Object.keys(props.dbState.growers).map(x => {return {id: x, name: props.dbState.growers[x].name}});
    growerArr.sort((a, b) => {
        var textA = a.name;
        var textB = b.name;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return (
        <>
            <DialogContent>
                <Stack spacing={2}>
                <TextField
                    error={validate && name === ""}
                    sx={{ width: 300 }}
                    label={i18next.t(ns + "field-name")}
                    variant="outlined"
                    value={name}
                    onChange={ev => setName(ev.target.value)} />
                    {!props.justRename && <Stack direction="row" spacing={2}>
                    <TextField
                        error={validate && (growerId === undefined || growerId === null)}
                        sx={{ width: 300 }}
                        value={growerId}
                        onChange={(ev) => setGrowerId(getGrowerIdIfValid(ev.target.value))}
                        label={i18next.format(i18next.t("grower"), 'capitalize')}
                        select // tell TextField to render select
                        disabled={disableChangeGrower}
                        >
                            {
                                growerArr.map(grower => (
                                    <MenuItem 
                                        key={grower.id} 
                                        value={grower.id}
                                    >
                                        {grower.name}
                                    </MenuItem>)
                                )
                            }
                    </TextField>
                    </Stack>}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm}>{props.alternativeNextLabel ?? i18next.format(i18next.t("next"), 'capitalize')}</Button>
            </DialogActions>
        </>
    );
};

export default ProjectDetails;