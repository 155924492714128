import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IUserData from "rdptypes/IUserData";
import * as React from "react";
import { FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import ApiClientCtx from "../api/ApiClientCtx";
import { ApiRequestStatus } from "../api/ApiRequestState";
import AuthCtx from "../auth/AuthCtx";
import Spinner from "../components/Spinner";
import DealerDataCtx from "./DealerDataCtx";

const DealerDataCtxProvider: FC<PropsWithChildren<{}>> = (props) => {
    const authState = useContext(AuthCtx);
    const api = useContext(ApiClientCtx);

    const [userData, setUserData] = useState<IUserData | undefined>(undefined);
    const [requestStatus, setRequestStatus] = useState<ApiRequestStatus | undefined>(undefined);
    const [requestError, setRequestError] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!authState.loggedInUserId) return;
        if (!authState.assumedUserId) return;

        (async () => {
            setRequestError(undefined);
            setRequestStatus(ApiRequestStatus.InProgress);
            const resp = await api.getMe();
            if (resp.status === ApiRequestStatus.Success) {
                console.log(resp.result!);
                setUserData(resp.result!);
                setRequestStatus(ApiRequestStatus.Success);
            } else {
                setRequestError(resp.error!);
                setRequestStatus(ApiRequestStatus.Error);
            }
        })();
    }, [authState]);

    switch (requestStatus) {
        case ApiRequestStatus.Error:
            return (
                <>
                    <Alert severity="error">
                        <AlertTitle>Error loading user data</AlertTitle>
                        {requestError}
                    </Alert>
                </>
            );
        case ApiRequestStatus.InProgress:
            return (
                <Spinner title="Loading..."/>
            )
        default:
            if (userData && !userData.dealership) {
                return (
                    <>
                        <Alert severity="error">
                            <AlertTitle>Super admin access temporarilly removed</AlertTitle>
                        </Alert>
                    </>
                );
            }

            return (
                <DealerDataCtx.Provider value={userData}>
                    {props.children}
                </DealerDataCtx.Provider>
            );
    }
};

export default DealerDataCtxProvider;