import Box from "@mui/material/Box";
import * as React from "react";
import { useContext } from "react";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import SystemDesigner from "../roe/SystemDesigner";
import CustomDefaultsRadioGroup from "./CustomDefaultsRadioGroup";
import SystemDefaultsValidationWorkerProvider from "./SystemDefaultsValidationWorkerProvider";

const CONSTANTS = {
    title: 'System Defaults'
}

const TabBody: React.FC = () => {
    
    const devSettingsState = useContext(DevSettingsCtx);
    const { dbProject, layoutId, systemId } = devSettingsState.dealerSettings.system.custom;

    return (
        <>
            <CustomDefaultsRadioGroup 
                title={CONSTANTS.title}
                useCustomDefaults={devSettingsState.dealerSettings.system.useCustom}
                setUseCustomDefaults={devSettingsState.dealerSettings.system.setUseCustom}
            />
             {
                devSettingsState.dealerSettings.system.useCustom && (
                    <Box sx={{ overflow: "auto", flex: 1, padding: 2, width: "100%", height: "calc(100% - 75px)", position: "relative" }}>
                        <SystemDefaultsValidationWorkerProvider dbPrj={dbProject} layoutId={layoutId} systemId={systemId}>
                            <SystemDesigner
                                dbPrj={dbProject}
                                layoutId={layoutId}
                                systemId={systemId}
                                ignoreImgTooltips={true}
                                disableStepByStep
                                systemDefaults
                                supportsLiveProposal={false}
                            />
                        </SystemDefaultsValidationWorkerProvider>
                    </Box>
                )
             }   
        </>
    );
}

export default <TabBody />;
