import { Polygon, booleanClockwise } from "@turf/turf";
import { ISegment } from "../model/project/ISegment";

export enum ESegmentClearanceType {
    EquipmentClearance = "equipmentClearance",
    STowerClearance = "sTowerClearance",
    HTowerClearance = "hTowerClearance"
};

export default class SegmentHelper {
    static polygonToSegments = (poly: Polygon, clearance: number | undefined = undefined): ISegment[] => {
        if (poly.coordinates.length < 1) return [];
        const ring = booleanClockwise(poly.coordinates[0])
            ? poly.coordinates[0]
            : [ ...poly.coordinates[0] ].reverse();
        return ring.slice(0, -1).map((x, i): ISegment => {
            return {
                start: x,
                end: ring[i + 1],
                equipmentClearance: clearance,
                sTowerClearanceOffset: 0,
                hTowerClearanceOffset: 0,
            }
        })
    }

    static getClearance = (segment: ISegment, clearanceType: ESegmentClearanceType = ESegmentClearanceType.EquipmentClearance): number => {
        switch (clearanceType) {
            case ESegmentClearanceType.EquipmentClearance:
                return segment.equipmentClearance;
            case ESegmentClearanceType.STowerClearance:
                return segment.sTowerClearanceOffset + segment.equipmentClearance;
            case ESegmentClearanceType.HTowerClearance:
                return segment.hTowerClearanceOffset + segment.equipmentClearance;
        }
    }
    
    static setClearance = (clearance: number, segment: ISegment, clearanceType: ESegmentClearanceType = ESegmentClearanceType.EquipmentClearance): void => {
        switch (clearanceType) {
            case ESegmentClearanceType.EquipmentClearance:
                segment.equipmentClearance = clearance;
                break;
            case ESegmentClearanceType.STowerClearance:
                segment.sTowerClearanceOffset = Math.max(clearance - segment.equipmentClearance, 0);
                break;
            case ESegmentClearanceType.HTowerClearance:
                segment.hTowerClearanceOffset = Math.max(clearance - segment.equipmentClearance, 0);
                break;
        }
    }
    
    static getClearanceOffset = (segment: ISegment, clearanceType: ESegmentClearanceType = ESegmentClearanceType.EquipmentClearance): number => {
        switch (clearanceType) {
            case ESegmentClearanceType.EquipmentClearance:
                return 0;
            case ESegmentClearanceType.STowerClearance:
                return segment.sTowerClearanceOffset;
            case ESegmentClearanceType.HTowerClearance:
                return segment.hTowerClearanceOffset;
        }
    }
    
    static setClearanceOffset = (clearance: number, segment: ISegment, clearanceType: ESegmentClearanceType = ESegmentClearanceType.EquipmentClearance): void => {
        switch (clearanceType) {
            case ESegmentClearanceType.EquipmentClearance:
                // do nothing
                break;
            case ESegmentClearanceType.STowerClearance:
                segment.sTowerClearanceOffset = Math.max(clearance, 0);
                break;
            case ESegmentClearanceType.HTowerClearance:
                segment.hTowerClearanceOffset = Math.max(clearance, 0);
                break;
        }
    }
}