import { ISystemBase } from "../project/ISystemBase.AutoGenerated";
import { ISide } from "../project/Types";

export enum SideEnum {
    Flanged,
    Flex
}

export const getSide = (system: ISystemBase, side: SideEnum): ISide => {
    switch (side) {
        case SideEnum.Flanged:
            return system.FlangedSide;
        case SideEnum.Flex:
            return system.FlexSide;
        default:
            throw new Error(`Side ${side} not supported`);
    }
}

export const getSideModelKeyAccessorString = (side: SideEnum): 'FlangedSide' | 'FlexSide'  => {
    switch (side) {
        case SideEnum.Flanged:
            return 'FlangedSide';
        case SideEnum.Flex:
            return 'FlexSide';
        default:
            throw new Error(`Side ${side} not supported`);
    }
}