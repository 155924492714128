import { Box, Button, Grid } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import DbCtx from "../../../db/DbCtx";
import GrowersFromCSVDialog from "./GrowersFromCSVDialog";

import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';

const ProjectListNoProjectsTab: FC<{}> = (props) => {
  const [csvUploadDialogOpen, setCsvUploadDialogOpen] = React.useState(false);
  const dbState = React.useContext(DbCtx);

  return (
    <>
      <Grid
        direction="column"
        container
        sx={{
          display: "flex",
          padding: "50px",
          justifyContent: "center",
          alignItems: "center",
          color: "#373536",
        }}
      >
        <Box style={{display: "flex", alignItems: "center", marginBottom: "2em", marginTop: "2em"}}>
          <EmojiObjectsOutlinedIcon sx={{mr: 1, fontSize: "2.5em", color: "#1976d2"}} />
          <span style={{fontSize: "1.2em"}}>{t("projects.add-grower")}</span>
        </Box>
        <Button variant="outlined" onClick={() => {setCsvUploadDialogOpen(true);}}>{t("upload-csv")}</Button>
      </Grid>
      <GrowersFromCSVDialog dbState={dbState} open={csvUploadDialogOpen} onClose={(growers) => {
        if (typeof(growers) === "undefined" || growers.length === 0){
          setCsvUploadDialogOpen(false);
        }
        else {
          growers.forEach(async (grower) => {
            const gid = await dbState.newGrower(grower);
          });
          setCsvUploadDialogOpen(false);
        }
      }}/>
    </>
  )
};

export default ProjectListNoProjectsTab;
