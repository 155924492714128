import i18next, { t } from "i18next";
import * as React from "react";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import ListItemCheckbox from "./ListItemCheckbox";

interface Props {
    className?: string;
}

const ClearancesListItem: React.FC<Props> = (props) => {

    const settingsState = React.useContext(DevSettingsCtx).mapSettings;
    const ns = "nav.labels."

    return (
        <ListItemCheckbox
            className={props.className}
            checked={settingsState.showClearances}
            label={i18next.format(t(ns + 'show-clearance'), 'capitalize-each')}
            onClick={() => settingsState.setShowClearances(!settingsState.showClearances)}
        />
    );
};

export default ClearancesListItem;
