import * as React from "react";
import { FC } from "react";
import { SYSTEM_DESIGN_CONSTANTS } from "./constants";

interface IProps {
    startDistance: number;
    y: number;
    drop: number
}

const Sprinkler: FC<IProps> = ({ startDistance, y, drop }) => {
    const pipeWidth = SYSTEM_DESIGN_CONSTANTS.pipe.width;

    return (
        <path 
            d={`
                M ${startDistance} ${y} 
                l 0 ${-drop}
            `} 
            stroke={SYSTEM_DESIGN_CONSTANTS.sprinkler.color}
            strokeWidth={pipeWidth} />
    );
};

export default Sprinkler;