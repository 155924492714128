import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IconButton } from "@mui/material";
import * as React from "react";
import { IProjectWithId } from ".";

interface Props {
    projectWithId: IProjectWithId;
}

const DownloadFieldButton: React.FC<Props> = (props) => {

    const aRef = React.useRef<HTMLAnchorElement>(null);

    const handleClickDownload = () => {
        const link = aRef.current;
        link.download = `${props.projectWithId.id}.json`;
        link.href = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(props.projectWithId.project));
        link.click();
    }

    return (
        <IconButton aria-label="download-field" onClick={handleClickDownload} color="warning">
            <FileDownloadIcon />
            <a ref={aRef} hidden/>
        </IconButton>
    )

}

export default DownloadFieldButton;