import { Image } from '@react-pdf/renderer';
import * as React from "react";
import { FC } from "react";
import DevSettingsCtx from "../../../db/DevSettingsCtx";

interface Props {
    style: any;
}

const DealerLogo: FC<Props> = (props) => {
    //TODO: register fonts and fontweights
    const devSettingsState = React.useContext(DevSettingsCtx);
    const lineArt = new URL("../../assets/pivotLineArt.png", import.meta.url);

    const dealerLogo = devSettingsState.dealerSettings.logo.current ? devSettingsState.dealerSettings.logo.current.logo : lineArt.toString();
    return <Image source={dealerLogo} style={props.style}/>;
};

export default DealerLogo;