import { Box, Stack, Tab, Tabs } from "@mui/material";
import ITabs from "rdptypes/roe/ITabs";
import * as React from "react";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import IDbProject from "../../../db/IDbProject";
import { translateUnit } from "../../../helpers/translation";
import ComponentListRenderer from "./ComponentListRenderer";
import IComponentRenderContext from "./IComponentRenderContext";

interface Props {
    cmp: ITabs,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    ctx: IComponentRenderContext
}

const TabsRenderer: React.FC<Props> = (props) => {

    const settings = React.useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;
    
    const visibleTabs = props.cmp.tabs
    .filter(tab => !props.ctx.editingDealerDefaults || !tab.disableDealerSettings);

    const [ tabIndex, setTabIndex ] = React.useState(0);

    const selectedTab = visibleTabs[tabIndex];

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile

                    value={tabIndex} 
                    onChange={(event: React.SyntheticEvent, newValue: number) => {
                        setTabIndex(newValue); }} 
                    centered
                >
                    {
                        visibleTabs
                        .map(tab => {
                            const title = translateUnit(tab, displaySettings)
                            return (
                                <Tab 
                                    key={title}
                                    label={title} 
                                    wrapped
                                />
                            )
                        })
                    }
                </Tabs>
            </Box>
            <Stack direction="column" style={{ paddingTop: 30}}>
                {
                    selectedTab?.children && (
                        <ComponentListRenderer
                            parentGroupLabel={null}//{parentGroupLabel}
                            oneColumn={true}//{props.cmp.forceOneColumn || false}
                            components={selectedTab.children}
                            dbPrj={props.dbPrj}
                            // ignoreImgTooltips={props.ignoreImgTooltips}
                            layoutId={props.layoutId}
                            // inline={props.cmp.childrenInline}
                            systemId={props.systemId}
                            ctx={props.ctx}
                        />
                    )
                }
            </Stack>
        </>
    )
}

export default TabsRenderer;