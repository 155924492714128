import i18next, { t } from "i18next";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { EDisplayCoordinate } from "../components/DealerSettingsDialog/EDisplayCoordinate";
import { ProjectType } from "../model/project/IProject";
import ISystem, { ProductLine } from "../model/project/ISystem";

export const formatDateLong = (date: Date) => {
    return date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
}

export const formatDateShort = (date: Date) => {
    return date.toLocaleDateString("en-US");
}

export const formatDateWithTime = (date: Date) => {
    return date.toLocaleString("en-US");
}

export const formatProjectType = (projectType: ProjectType | undefined) => {
    if (projectType === undefined) return "";
    switch (projectType) {
        case ProjectType.DesignOnly:
            return i18next.format(t('system'), 'capitalize');
        case ProjectType.LayoutAndDesign:
            return i18next.format(t('field'), 'capitalize');
        default:
            return projectType;
    }
}

export const formatProductLine = (productLine: ProductLine) => {
    if (productLine === ProductLine.e3) return "Electrogator® III";
    if (productLine === ProductLine.e2) return "Electrogator® II";
    if (productLine === ProductLine.edmp) return "Engine-Driven Mini-Pivot";
}

export const formatSystemType = (system: ISystem) => {
    switch (system.SystemProperties.SystemType){
        case SystemTypes.CenterPivot:
            return t("common-phrases.sltCenterPivot");
        case SystemTypes.HoseFeedMaxigator:
            return t("common-phrases.sltHoseFeedMaxigator");
        case SystemTypes.CanalFeedMaxigator:
            return t("common-phrases.sltCanalFeedMaxigator");
        case SystemTypes.KwikTow:
            return t("common-phrases.sltKwikTow");
        case SystemTypes.SwingArmRetro:
            return t("common-phrases.sltSwingArmRetroKit");
    }

    return "Unknown System Type";
}

export const getDocTypeStr = (docType: IDocumentType) => {
    switch (docType)
    {
        case IDocumentType.irrigationSystemProposal:
            return t("common-phrases.sltCustomerQuote");
        case IDocumentType.irrigationSystemPurchaseAgreement:
            return t("common-phrases.sltReinkeIrrigationSysSC");
        case IDocumentType.irrigationSystemPurchaseOrder:
            return  t("common-phrases.sltReinkeIrrigationSysPO");
        case IDocumentType.electricalReport:
            return t("common-phrases.sltElectricalReport");
        case IDocumentType.irrigationSystemPerformanceSummary:
            return t("common-phrases.sltReinkePerformanceSummary");
        /*case IDocumentType.rdpProposal:
            return t("proposals.field-design-report");*/
        case IDocumentType.vriReport:
            return t("proposals.vri-report");
        case IDocumentType.ach:
            return "Electronic Debit / ACH Authorization";
        case IDocumentType.coastCapital:
            return "Finance Application - Coast Capital";
        case IDocumentType.faxedCheque:
            return "Faxed Cheque Authorization";
        case IDocumentType.huntington:
            return "Finance Application - Huntington";
        case IDocumentType.unico:
            return "Insurance Application - UNICO";
    }
}

export enum IDocumentType {
    irrigationSystemProposal,
    irrigationSystemPurchaseAgreement,
    irrigationSystemPurchaseOrder,
    huntington,
    coastCapital,
    unico,
    ach,
    faxedCheque,
    electricalReport,
    irrigationSystemPerformanceSummary,
    //rdpProposal,
    vriReport,
}

export const feetToMeters = (feet: number) => {
    return feet * 0.3048;
}

export const metersToFeet = (meters: number) => {
    return meters / 0.3048;
}

export const acresToHectares = (acres: number) => {
    return acres * 0.404686;
}

export const gallonsToLiters = (gal: number) => {
    return gal * 3.78541;
}

export const litersToGallons = (lit: number) => {
    return lit / 3.78541;
}

export const poundsToKilos = (lbs: number) => {
    return lbs * 0.453592;
}

export const kilosToPounds = (kgs: number) => {
    return kgs / 0.453592;
}


export const gpmToM3ph = (gpm: number) => {
    return gpm * 0.227125;
}

export const m3phToGpm = (m3ph: number) => {
    return m3ph / 0.227125;
}

export const psiToBar = (psi: number) => {
    return psi * 0.0689476;
}

export const barToPsi = (bar: number) => {
    return bar / 0.0689476;
}

export const inchesToMm = (inches: number) => {
    return inches * 25.4;
}

export const squareMetersToAcres = (sm: number) => {
    return sm / 4046.86;
}

export const formatLatLn = (lat: number, lon: number, displayCoordinate: EDisplayCoordinate): {latPart: string, lonPart: string} => {

    var latPart = displayCoordinate === EDisplayCoordinate.DMS ? toDegreesMinutesAndSeconds(lat) : Math.abs(lat).toFixed(5);
    if (displayCoordinate === EDisplayCoordinate.DECIMAL) latPart += "°";
    latPart += lat >= 0 ? ` ${t("north-abbrev")}` : ` ${t("south-abbrev")}`;

    var lonPart = displayCoordinate === EDisplayCoordinate.DMS ? toDegreesMinutesAndSeconds(lon) : Math.abs(lon).toFixed(5);
    if (displayCoordinate === EDisplayCoordinate.DECIMAL) lonPart += "°";
    lonPart += lon >= 0 ? ` ${t("east-abbrev")}` : ` ${t("west-abbrev")}`;

    return {latPart, lonPart};
}

const toDegreesMinutesAndSeconds = (coordinate: number) => {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = ((minutesNotTruncated - minutes) * 60).toFixed(3);

    return degrees + "° " + minutes + "' " + seconds + "\"";
}

export const formatCents = (cents: number) => {
    return (cents / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});
}