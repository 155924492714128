export enum WarningTypes {
    SprinklerCriticalError,
    SprinklerRegularError,
    InvalidData,
    SystemParams,
    NozzlePressureNotMet,
    NozzlePressureExceeded,
    NozzleCritical,
    OutofWater,
    NozzleSpacingNotMet,
    UserStop,
}
export enum WarningSeverity {
    Information,
    UserStop,
    Critical,
}
export enum SpaceDrop {
    Stop,
    Gradual,
    User,
}
export enum SpaceReduction {
    NoneAttempted,
    Trial,
    OkToCommit,
    Commit,
    Committed,
}
export enum NozzlePressureCheck {
    OK,
    TooHigh,
    TooLow,
}
export enum OutletTypes {
    Regular,
    BoomBack,
    Imaginary,
}
export enum RunResults {
    Success,
    UserStop,
    Failure,
}
