import { IEnhProgress } from "./IEnhProgress";

export class ProgressDialog implements IEnhProgress {

    private isCancelled: () => Promise<boolean>;
    private tLastStatusUpdate = Date.now();
    private prevMsg: string | null = null;
    constructor(isCancelled: () => Promise<boolean>) {
        this.isCancelled = isCancelled;
    }

    public isCancelPending(): Promise<boolean> {
        return this.isCancelled();
    }

    private getElapsedSecondsSinceLastStatus = () => {
        const n = Date.now();
        const s = ((n - this.tLastStatusUpdate) / 1000).toFixed(1);
        this.tLastStatusUpdate = n;
        return s;
    }


    SetStatusMessage(msg: string): void {
        if (this.prevMsg) {
            console.log("Status END: " + this.prevMsg + " [" + this.getElapsedSecondsSinceLastStatus() + "s]");
        }
        // throw new Error("Method not implemented.");
        console.log("Status: " + msg);
        this.prevMsg = msg;
    }
    Report(value: number): void {
        // throw new Error("Method not implemented.");
        // console.log("Report: " + value);
    }
    
    public CloseFinished(): void {
        // throw new Error("Method not implemented.");
        // Dispatcher.InvokeAsync(() =>
        // {
        //     finished = true;
        //     Close();
        // });
        if (this.prevMsg) {
            console.log("Status END: " + this.prevMsg + " [" + this.getElapsedSecondsSinceLastStatus() + "s]");
            this.prevMsg = null;
        }
        console.log("Close Finished");
    }
}