import { MetersProjectionHelper } from "../../projection";
import { SACEndGunModel } from "../SAC/SACEndGunModel";
import { SACOptimizationProblem } from "../SAC/SACOptimizationProblem";
import { SACOptimizationSolution } from "../SAC/SACOptimizationSolution";
import { SACOrientation } from "../SAC/SACOrientation";
import { Parallel } from "../Tasks/Parallel";
import PathUtilities, { PathData, PointShape, RingShape } from "../ThinkGeo/PathUtilities";

// This file contains notes, please see 'NOTE' futher down

export const importPathData = (sacProblem: SACOptimizationProblem, pathData: PathData[], metersProjectionHelper: MetersProjectionHelper): SACOptimizationSolution => {
    let iPivotAngleMin: number = Math.floor(sacProblem.PartialPivotAngleDegreesMin * sacProblem.PivotAngleStepCount / 360.0);
    let iPivotAngleMax: number = Math.ceil(sacProblem.PartialPivotAngleDegreesMax * sacProblem.PivotAngleStepCount / 360.0);
    if (sacProblem.PartialPivotAngleDegreesMax <= sacProblem.PartialPivotAngleDegreesMin)
    {
        iPivotAngleMax += sacProblem.PivotAngleStepCount;
    }

    const pathRing = PathUtilities.PathDataToRingShape(
        pathData, 
        sacProblem.PivotCentre,
        metersProjectionHelper
    );

    const alphas: number[] = Array(iPivotAngleMax - iPivotAngleMin + 1).fill(0);

    let maxAlphaInside: number = sacProblem.Model.ModelConstraints.MinExtensionAngleDegrees * Math.PI / 180.0;
    let maxAlphaOutside: number = sacProblem.Model.ModelConstraints.MaxExtensionAngleDegrees * Math.PI / 180.0;
    if (sacProblem.Model.Configuration.Orientation == SACOrientation.Leading)
    {
        maxAlphaInside = 2 * Math.PI - maxAlphaInside;
        maxAlphaOutside = 2 * Math.PI - maxAlphaOutside;
    }
    
    // progressDialog.Progress.SetStatusMessage("Calculating SAC path");
    // var analysisProgress = new ProgressScaling(progressDialog.Progress, 15, 95);
    // analysisProgress.Report(0);

    var counter = 0;
    var n = iPivotAngleMax - iPivotAngleMin + 1;

    Parallel.For(iPivotAngleMin, iPivotAngleMax + 1, (iPivotAngle, ls) =>
    {
        // if (progressDialog.Progress.CancelPending)
        // {
        //     ls.Break();
        //     return;
        // }

        var theta = sacProblem.GetTheta(iPivotAngle);

        if (IsInside(pathRing, sacProblem, theta, maxAlphaOutside))
        {
            alphas[iPivotAngle - iPivotAngleMin] = maxAlphaOutside;
            return;
        }

        if (!IsInside(pathRing, sacProblem, theta, maxAlphaInside))
        {
            alphas[iPivotAngle - iPivotAngleMin] = maxAlphaInside;
                return;
        }

        let a = 0;
        let c = 0;

        const alphaPrev = iPivotAngle > iPivotAngleMin ? alphas[iPivotAngle - 1 - iPivotAngleMin] : 0;
        if (alphaPrev !== 0) {
            if (sacProblem.Model.Configuration.Orientation === SACOrientation.Leading) {
                a = Math.min(alphaPrev + 0.5, maxAlphaInside);
                c = Math.max(alphaPrev - 0.5, maxAlphaOutside);
            }
            else {
                a = Math.max(alphaPrev - 0.5, maxAlphaInside);
                c = Math.min(alphaPrev + 0.5, maxAlphaOutside);
            }
            if (!IsInside(pathRing, sacProblem, theta, a) || IsInside(pathRing, sacProblem, theta, c)) {
                a = maxAlphaInside;
                c = maxAlphaOutside;
            }
        }
        else {
            a = maxAlphaInside;
            c = maxAlphaOutside;
        }

        while (Math.abs(c - a) > 0.005) {
            const b = 0.5 * (a + c);
            if (IsInside(pathRing, sacProblem, theta, b)) {
                a = b;
            }
            else {
                c = b;
            }
        }

        alphas[iPivotAngle - iPivotAngleMin] = 0.5 * (a + c);

        // var counter_ = Interlocked.Increment(ref counter);
        // if (counter_ % 20 == 0)
        // {
        //     analysisProgress.Report(100.0 * counter_ / n);
        // }
    });

    // if (progressDialog.Progress.CancelPending)
    // {
    //     Cancel();
    //     return;
    // }
    
    // progressDialog.Progress.SetStatusMessage("Finalizing SAC coverage");
    // progressDialog.Progress.Report(95);

    const solution = SACOptimizationSolution.FromAlphas(sacProblem, iPivotAngleMin, iPivotAngleMax, alphas);
    
    // NOTE: RDP2 used a seperate function (not the end gun model) to calculate end gun on/offs.
    // Given the end gun model exists, I have chosen to re-use this function here.
    // The fullExtended array is needed for the end gun model, but was not populated in the RDP2
    // import path data fn. So, the fullyExtended array I have created. 
    // For fully extended, I have assumed that the angle must be within the pivot limits, and 
    // that the alpha is at the maxAlpha. 0.005 * 0.5 is used to account for the iterative stop limits
    // used to calculate the alpha above.
    // Another observation, the SAC optimizer (without path data) uses smoothed alphas, this path data
    // version does not (as per RDP2). - Presumably this makes sense if the imported data is
    // already smoothed.
    const fullyExtended = alphas.map((a, idx) => {
        return idx >= iPivotAngleMin && idx <= iPivotAngleMax &&
            Math.abs(a - maxAlphaOutside) < 0.005 * 0.5
    });
    const endGunModel = new SACEndGunModel(sacProblem, iPivotAngleMin, iPivotAngleMax, alphas, fullyExtended);
    const endGuns = endGunModel.GetEndGunData(sacProblem.endGunThrowsMeters, solution.CoverageShape);
    solution.endGuns = endGuns;

    // NOTE: Simon - end guns have been dissallowed for path data imports as the areas they create are quite
    // jagged and lead to many on/offs which cause a very slow UI under the current map/features set up.
    // When the UI is spead up, end guns for path data should be re-addressed
    solution.endGuns = [];
    return solution;
}


const IsInside = (pathRing: RingShape, problem: SACOptimizationProblem, theta: number, alpha: number): boolean => {
    const w = problem.Model.r(theta, alpha).add(problem.Model.w(theta, alpha));
    return pathRing.Contains(new PointShape(problem.PivotCentre.x + w.X, problem.PivotCentre.y + w.Y));
}