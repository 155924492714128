import { AddressAutofillRetrieveResponse } from "@mapbox/search-js-core";
import { AddressAutofill } from "@mapbox/search-js-react";
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import * as countries from "i18n-iso-countries";
import i18next, { t } from "i18next";
import IDbGrower from "rdptypes/api/IGrowerBase";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { IGrowerWithId } from ".";
import IDbState from "../../../db/IDbState";
import DealerDataCtx from "../../../userData/DealerDataCtx";

interface Props {
    open: boolean;
    onClose: (grower?: IDbGrower, existingGrowerId?: string) => any;
    dbState: IDbState;
    gwid?: IGrowerWithId;
    setDeleteGrowerDialogOpen?: (growerId: string) => void;
}

const GrowerDialog: FC<Props> = (props) => {
    const dealer = useContext(DealerDataCtx);

    // We can't do this before the react component is rendered due to the need to load the languages first
    const countryListRef = React.useRef(Object.keys(countries.getAlpha2Codes()).map(alpha2 => ({
        alpha2: alpha2.toLowerCase(),
        label: countries.getName(alpha2, "en")
    })));
    const countryList = countryListRef.current;

    const defaultStateGrower = {
        name: { val: props.gwid ? props.gwid.grower.name : "", error: null },
        farmManagerName: { val: props.gwid ? props.gwid.grower.farmManagerName : "", error: null },
        legalDescription: { val: props.gwid ? props.gwid.grower.legalDescription : "", error: null },
        email: { val: props.gwid ? props.gwid.grower.email : "", error: null },
        phone: { val: props.gwid ? props.gwid.grower.phone : "", error: null },

        shippingAddressLine1: { val: props.gwid ? props.gwid.grower.shippingAddress.line1 : "", error: null },
        shippingAddressLine2: { val: props.gwid ? props.gwid.grower.shippingAddress.line2 : "", error: null },
        shippingAddressCity: { val: props.gwid ? props.gwid.grower.shippingAddress.city : "", error: null },
        shippingAddressState: { val: props.gwid ? props.gwid.grower.shippingAddress.state : "", error: null },
        shippingAddressZip: { val: props.gwid ? props.gwid.grower.shippingAddress.zip : "", error: null },
        shippingAddressCountry: { val: props.gwid ? countries.alpha3ToAlpha2(props.gwid.grower.shippingAddress.country).toLowerCase() : "", error: null },

        differentMailingAddress: { val: props.gwid ? !!props.gwid.grower.mailingAddress : false, error: null },
        mailingAddressLine1: { val: props.gwid ? props.gwid.grower.mailingAddress?.line1 : "", error: null },
        mailingAddressLine2: { val: props.gwid ? props.gwid.grower.mailingAddress?.line2 : "", error: null },
        mailingAddressCity: { val: props.gwid ? props.gwid.grower.mailingAddress?.city : "", error: null },
        mailingAddressState: { val: props.gwid ? props.gwid.grower.mailingAddress?.state : "", error: null },
        mailingAddressZip: { val: props.gwid ? props.gwid.grower.mailingAddress?.zip : "", error: null },
        mailingAddressCountry: { val: props.gwid ? countries.alpha3ToAlpha2(props.gwid.grower.mailingAddress?.country)?.toLowerCase() : "", error: null },

        sharedWithDealership: { val: props.gwid ? props.gwid.grower.sharedWithDealership : undefined, error: null },
    };

    const [grower, setGrower] = useState(defaultStateGrower);

    useEffect(() => {
        if (isValid !== null) {
            validate();
        }
    }, [grower]);

    let valid = null;//null = not validated yet
    //below is to handle current growers who don't have all of the required fields set yet (so not just valid by default)
    if (props.gwid && props.gwid.grower.name && props.gwid.grower.mailingAddress?.line1 && props.gwid.grower.mailingAddress?.city &&
        props.gwid.grower.mailingAddress?.zip && props.gwid.grower.mailingAddress?.state && props.gwid.grower.mailingAddress?.country) valid = true;

    const [isValid, setIsValid] = useState<boolean | null>(valid);

    const style = {
        marginTop: "0.2em"
    }

    const ns = "csv-upload-dialog.";

    const validate = (then?: () => any) => {
        let prevalidateGrower = JSON.parse(JSON.stringify(grower)) as typeof grower;

        //clear all errors

        prevalidateGrower.name.error = null;
        prevalidateGrower.farmManagerName.error = null;
        prevalidateGrower.legalDescription.error = null;
        prevalidateGrower.email.error = null;
        prevalidateGrower.phone.error = null;
        prevalidateGrower.shippingAddressLine1.error = null;
        prevalidateGrower.shippingAddressLine2.error = null;
        prevalidateGrower.shippingAddressCity.error = null;
        prevalidateGrower.shippingAddressState.error = null;
        prevalidateGrower.shippingAddressZip.error = null;
        prevalidateGrower.shippingAddressCountry.error = null;
        prevalidateGrower.differentMailingAddress.error = null;
        prevalidateGrower.mailingAddressLine1.error = null;
        prevalidateGrower.mailingAddressLine2.error = null;
        prevalidateGrower.mailingAddressCity.error = null;
        prevalidateGrower.mailingAddressState.error = null;
        prevalidateGrower.mailingAddressZip.error = null;
        prevalidateGrower.mailingAddressCountry.error = null;
        prevalidateGrower.sharedWithDealership.error = null;

        let isValid_ = true;

        if (prevalidateGrower.name.val === "") {
            prevalidateGrower.name.error = `${t(ns + "grower-name")} ${t(ns + "is-empty")}`;
            isValid_ = false;
        }
        else if (!props.gwid) {
            for (var growerId in props.dbState.growers) {
                if (!props.dbState.growers[growerId].deleted && props.dbState.growers[growerId].name === prevalidateGrower.name.val) {
                    prevalidateGrower.name.error = `${t(ns + "a-grower-with-the-name")} ${prevalidateGrower.name.val} ${t(ns + "already-exists")}`;
                    isValid_ = false;
                }
            }
        }

        if (prevalidateGrower.farmManagerName.val === "" || typeof (prevalidateGrower.farmManagerName.val) === "undefined") {
            prevalidateGrower.farmManagerName.val = undefined;
        }

        if (prevalidateGrower.shippingAddressLine1.val === "" || typeof (prevalidateGrower.shippingAddressLine1.val) === "undefined") {
            prevalidateGrower.shippingAddressLine1.error = `${i18next.format(t('address'), 'capitalize')} ${t(ns + "is-empty")}`;
            isValid_ = false;
        }

        if (prevalidateGrower.shippingAddressCity.val === "" || typeof (prevalidateGrower.shippingAddressCity.val) === "undefined") {
            //TODO: city validation
            prevalidateGrower.shippingAddressCity.error = `${i18next.format(t('city'), 'capitalize')} ${t(ns + "is-empty")}`;
            isValid_ = false;
        }

        if (prevalidateGrower.shippingAddressState.val === "" || typeof (prevalidateGrower.shippingAddressState.val) === "undefined") {
            //TODO: state validation
            prevalidateGrower.shippingAddressState.error = `${i18next.format(t('state'), 'capitalize')} ${t(ns + "is-empty")}`;
            isValid_ = false;
        }

        if (prevalidateGrower.shippingAddressZip.val === "" || typeof (prevalidateGrower.shippingAddressZip.val) === "undefined") {
            //TODO: more zip validation
            prevalidateGrower.shippingAddressZip.error = `${i18next.format(t('zip'), 'capitalize')} ${t(ns + "is-empty")}`;
            isValid_ = false;
        }

        if (prevalidateGrower.shippingAddressCountry.val === "" || typeof (prevalidateGrower.shippingAddressCountry.val) === "undefined") {
            //TODO: more country validation
            prevalidateGrower.shippingAddressCountry.error = `${i18next.format(t('country'), 'capitalize')} ${t(ns + "is-empty")}`;
            isValid_ = false;
        }

        if (prevalidateGrower.differentMailingAddress.val) {
            if (prevalidateGrower.mailingAddressLine1.val === "" || typeof (prevalidateGrower.mailingAddressLine1.val) === "undefined") {
                prevalidateGrower.mailingAddressLine1.error = `${i18next.format(t('address'), 'capitalize')} ${t(ns + "is-empty")}`;
                isValid_ = false;
            }

            if (prevalidateGrower.mailingAddressCity.val === "" || typeof (prevalidateGrower.mailingAddressCity.val) === "undefined") {
                //TODO: city validation
                prevalidateGrower.mailingAddressCity.error = `${i18next.format(t('city'), 'capitalize')} ${t(ns + "is-empty")}`;
                isValid_ = false;
            }

            if (prevalidateGrower.mailingAddressState.val === "" || typeof (prevalidateGrower.mailingAddressState.val) === "undefined") {
                //TODO: state validation
                prevalidateGrower.mailingAddressState.error = `${i18next.format(t('state'), 'capitalize')} ${t(ns + "is-empty")}`;
                isValid_ = false;
            }

            if (prevalidateGrower.mailingAddressZip.val === "" || typeof (prevalidateGrower.mailingAddressZip.val) === "undefined") {
                //TODO: more zip validation
                prevalidateGrower.mailingAddressZip.error = `${i18next.format(t('zip'), 'capitalize')} ${t(ns + "is-empty")}`;
                isValid_ = false;
            }

            if (prevalidateGrower.mailingAddressCountry.val === "" || typeof (prevalidateGrower.mailingAddressCountry.val) === "undefined") {
                //TODO: more country validation
                prevalidateGrower.mailingAddressCountry.error = `${i18next.format(t('country'), 'capitalize')} ${t(ns + "is-empty")}`;
                isValid_ = false;
            }
        }

        if (prevalidateGrower.legalDescription.val === "") {
            prevalidateGrower.legalDescription.val = undefined;
        }
        if (prevalidateGrower.email.val === "") {
            //TODO: email validation
            prevalidateGrower.email.val = undefined;
        }
        if (prevalidateGrower.phone.val === "") {
            prevalidateGrower.phone.val = undefined;
        }

        setGrower(prevalidateGrower);
        setIsValid(isValid_);
        if (isValid_ && then) {
            then();
        }
    }

    const readonly = props.gwid?.grower?.owner !== dealer.assumedUser.id &&  props.gwid?.grower?.sharedWithDealership === "readonly";

    return (
        <Dialog
            open={props.open}
            onClose={() => { props.onClose() }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{
                zIndex: 999 // Allows MapBox search to appear over the top
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {props.gwid ? i18next.format(t('projects.edit-grower'), 'capitalize-each') : i18next.format(t('projects.new-grower'), 'capitalize-each')}
            </DialogTitle>
            <DialogContent>
                <form>
                    <DialogContentText>
                        {t(ns + "please-enter")}
                    </DialogContentText>
                    <TextField
                        disabled={readonly}
                        sx={style}
                        value={grower.name.val || ""}
                        required
                        error={grower.name.error}
                        helperText={grower.name.error}
                        label={t(ns + "grower-name")}
                        autoFocus
                        id="grower-name"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            let newGrower = JSON.parse(JSON.stringify(grower)) as typeof grower;
                            newGrower.name.val = e.target.value;
                            setGrower(newGrower);
                        }}
                    />
                    <TextField
                        disabled={readonly}
                        sx={style}
                        value={grower.farmManagerName.val || ""}
                        error={grower.farmManagerName.error}
                        helperText={grower.farmManagerName.error}
                        label={t(ns + "manager-name")}
                        id="manager-name"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            let newGrower = JSON.parse(JSON.stringify(grower)) as typeof grower;
                            newGrower.farmManagerName.val = e.target.value;
                            setGrower(newGrower);
                        }}
                    />
                    <TextField
                        disabled={readonly}
                        sx={style}
                        value={grower.legalDescription.val || ""}
                        error={grower.legalDescription.error}
                        helperText={grower.legalDescription.error}
                        label={t(ns + "legal-desc")}
                        id="legalDescription"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            let newGrower = JSON.parse(JSON.stringify(grower)) as typeof grower;
                            newGrower.legalDescription.val = e.target.value;
                            setGrower(newGrower);
                        }}
                    />
                    <TextField
                        disabled={readonly}
                        sx={style}
                        value={grower.email.val || ""}
                        error={grower.email.error}
                        helperText={grower.email.error}
                        label={i18next.format(t('email'), 'capitalize')}
                        id="email"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            let newGrower = JSON.parse(JSON.stringify(grower)) as typeof grower;
                            newGrower.email.val = e.target.value;
                            setGrower(newGrower);
                        }}
                    />
                    <TextField
                        disabled={readonly}
                        sx={style}
                        error={grower.phone.error}
                        value={grower.phone.val || ""}
                        helperText={grower.phone.error}
                        label={i18next.format(t('phone'), 'capitalize')}
                        id="phone"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            let newGrower = JSON.parse(JSON.stringify(grower)) as typeof grower;
                            newGrower.phone.val = e.target.value;
                            setGrower(newGrower);
                        }}
                    />


                    <AddressAutofill
                        accessToken="pk.eyJ1IjoiaWNvbnNvZnR3YXJlIiwiYSI6ImNsYXV6MnExZTAwajQzcWxpcHdjanZxYTgifQ.R0vQipvh4nMVTsNezSlamg"
                        options={{
                            streets: false
                        }}
                        onRetrieve={(res: AddressAutofillRetrieveResponse) => {
                            if (!res.features?.length) return;
                            const feature = res.features[0];
                            if (!feature.properties.country_code) return;
                            setGrower(grower => {
                                return ({
                                    ...grower,
                                    shippingAddressCountry: { ...grower.shippingAddressCountry, val: feature.properties.country_code }
                                })
                            });
                        }}
                    >
                        <TextField
                        disabled={readonly}
                            sx={style}
                            value={grower.shippingAddressLine1.val || ""}
                            required
                            error={grower.shippingAddressLine1.error}
                            helperText={grower.shippingAddressLine1.error}
                            label={i18next.format(t('address'), 'capitalize')}
                            id=" shippingAddressLine1"
                            fullWidth
                            variant="standard"
                            autoComplete="shipping address-line1"
                            onChange={(e) => {
                                setGrower(grower => ({
                                    ...grower,
                                    shippingAddressLine1: { ...grower.shippingAddressLine1, val: e.target.value }
                                }));
                            }}
                        />
                    </AddressAutofill>
                    <TextField
                        disabled={readonly}
                        sx={style}
                        value={grower.shippingAddressLine2.val || ""}
                        error={grower.shippingAddressLine2.error}
                        helperText={grower.shippingAddressLine2.error}
                        label={i18next.format(t('address'), 'capitalize') + " 2"}
                        id=" shippingAddressLine2"
                        fullWidth
                        variant="standard"
                        autoComplete="shipping address-line2"
                        onChange={(e) => {
                            setGrower(grower => ({
                                ...grower,
                                shippingAddressLine2: { ...grower.shippingAddressLine2, val: e.target.value }
                            }));
                        }}
                    />
                    <TextField
                        disabled={readonly}
                        sx={style}
                        value={grower.shippingAddressCity.val || ""}
                        required
                        error={grower.shippingAddressCity.error}
                        helperText={grower.shippingAddressCity.error}
                        label={i18next.format(t('city'), 'capitalize')}
                        id=" shippingAddressCity"
                        fullWidth
                        variant="standard"
                        autoComplete="shipping address-level2"
                        onChange={(e) => {
                            setGrower(grower => ({
                                ...grower,
                                shippingAddressCity: { ...grower.shippingAddressCity, val: e.target.value }
                            }));
                        }}
                    />
                    <TextField
                        disabled={readonly}
                        sx={style}
                        value={grower.shippingAddressState.val || ""}
                        required
                        error={grower.shippingAddressState.error}
                        helperText={grower.shippingAddressState.error}
                        label={i18next.format(t('state'), 'capitalize')}
                        id="shippingAddressState"
                        fullWidth
                        variant="standard"
                        autoComplete="shipping address-level1"
                        onChange={(e) => {
                            setGrower(grower => ({
                                ...grower,
                                shippingAddressState: { ...grower.shippingAddressState, val: e.target.value }
                            }));
                        }}
                    />
                    <TextField
                        disabled={readonly}
                        sx={style}
                        value={grower.shippingAddressZip.val || ""}
                        required
                        error={grower.shippingAddressZip.error}
                        helperText={grower.shippingAddressZip.error}
                        label={i18next.format(t('zip'), 'capitalize')}
                        id="shippingAddressZip"
                        fullWidth
                        variant="standard"
                        autoComplete="shipping postal-code"
                        onChange={(e) => {
                            setGrower(grower => ({
                                ...grower,
                                shippingAddressZip: { ...grower.shippingAddressZip, val: e.target.value }
                            }));
                        }}
                    />
                    <Autocomplete
                        disablePortal
                        disabled={readonly}
                        id="shippingAddressCountry"
                        fullWidth
                        options={countryList}
                        renderInput={(params) => <TextField
                            {...params}
                            error={grower.shippingAddressCountry.error}
                            label={i18next.format(t('country'), 'capitalize')}
                            variant="standard"
                            autoComplete="shipping country"
                        />}
                        // Autocomplete goes into uncontrolled mode if we initialise with a default value
                        value={countryList.filter(x => x.alpha2 === grower.shippingAddressCountry.val)[0] ?? ""}
                        onChange={(ev, value) => {
                            setGrower(grower => ({
                                ...grower,
                                shippingAddressCountry: { ...grower.shippingAddressCountry, val: (value as any)?.alpha2 ?? "" }
                            }));
                        }}
                    />

                    <FormControlLabel
                        sx={{ marginTop: "1em" }}
                        control={<Checkbox
                            disabled={readonly}
                            checked={grower.differentMailingAddress.val}
                            onChange={(e) => {
                                setGrower({
                                    ...grower,
                                    differentMailingAddress: { ...grower.differentMailingAddress, val: e.target.checked }
                                });
                            }}
                        />}
                        label="Different mailing address" />
                    {grower.differentMailingAddress.val && <>
                        <AddressAutofill
                            accessToken="pk.eyJ1IjoiaWNvbnNvZnR3YXJlIiwiYSI6ImNsYXV6MnExZTAwajQzcWxpcHdjanZxYTgifQ.R0vQipvh4nMVTsNezSlamg"
                            options={{
                                streets: false
                            }}
                            onRetrieve={(res: AddressAutofillRetrieveResponse) => {
                                if (!res.features?.length) return;
                                const feature = res.features[0];
                                if (!feature.properties.country_code) return;
                                setGrower(grower => {
                                    return ({
                                        ...grower,
                                        mailingAddressCountry: { ...grower.mailingAddressCountry, val: feature.properties.country_code }
                                    })
                                });
                            }}
                        >
                            <TextField
                        disabled={readonly}
                                sx={style}
                                value={grower.mailingAddressLine1.val || ""}
                                required
                                error={grower.mailingAddressLine1.error}
                                helperText={grower.mailingAddressLine1.error}
                                label={i18next.format(t('address'), 'capitalize')}
                                id="mailingAddressLine1"
                                fullWidth
                                variant="standard"
                                autoComplete="billing address-line1"
                                onChange={(e) => {
                                    setGrower(grower => ({
                                        ...grower,
                                        mailingAddressLine1: { ...grower.mailingAddressLine1, val: e.target.value }
                                    }));
                                }}
                            />
                        </AddressAutofill>
                        <TextField
                        disabled={readonly}
                            sx={style}
                            value={grower.mailingAddressLine2.val || ""}
                            error={grower.mailingAddressLine2.error}
                            helperText={grower.mailingAddressLine2.error}
                            label={i18next.format(t('address'), 'capitalize') + " 2"}
                            id="mailingAddressLine2"
                            fullWidth
                            variant="standard"
                            autoComplete="billing address-line2"
                            onChange={(e) => {
                                setGrower(grower => ({
                                    ...grower,
                                    mailingAddressLine2: { ...grower.mailingAddressLine2, val: e.target.value }
                                }));
                            }}
                        />
                        <TextField
                        disabled={readonly}
                            sx={style}
                            value={grower.mailingAddressCity.val || ""}
                            required
                            error={grower.mailingAddressCity.error}
                            helperText={grower.mailingAddressCity.error}
                            label={i18next.format(t('city'), 'capitalize')}
                            id="mailingAddressCity"
                            fullWidth
                            variant="standard"
                            autoComplete="billing address-level2"
                            onChange={(e) => {
                                setGrower(grower => ({
                                    ...grower,
                                    mailingAddressCity: { ...grower.mailingAddressCity, val: e.target.value }
                                }));
                            }}
                        />
                        <TextField
                        disabled={readonly}
                            sx={style}
                            value={grower.mailingAddressState.val || ""}
                            required
                            error={grower.mailingAddressState.error}
                            helperText={grower.mailingAddressState.error}
                            label={i18next.format(t('state'), 'capitalize')}
                            id="mailingAddressState"
                            fullWidth
                            variant="standard"
                            autoComplete="billing address-level1"
                            onChange={(e) => {
                                setGrower(grower => ({
                                    ...grower,
                                    mailingAddressState: { ...grower.mailingAddressState, val: e.target.value }
                                }));
                            }}
                        />
                        <TextField
                        disabled={readonly}
                            sx={style}
                            value={grower.mailingAddressZip.val || ""}
                            required
                            error={grower.mailingAddressZip.error}
                            helperText={grower.mailingAddressZip.error}
                            label={i18next.format(t('zip'), 'capitalize')}
                            id="mailingAddressZip"
                            fullWidth
                            variant="standard"
                            autoComplete="billing postal-code"
                            onChange={(e) => {
                                setGrower(grower => ({
                                    ...grower,
                                    mailingAddressZip: { ...grower.mailingAddressZip, val: e.target.value }
                                }));
                            }}
                        />

                        <Autocomplete
                        disabled={readonly}
                            disablePortal
                            id="mailingAddressCountry"
                            fullWidth
                            options={countryList}
                            renderInput={(params) => <TextField
                                {...params}
                                error={grower.mailingAddressCountry.error}
                                label={i18next.format(t('country'), 'capitalize')}
                                variant="standard"
                                autoComplete="mailing country"
                            />}
                            // Autocomplete goes into uncontrolled mode if we initialise with a default value
                            value={countryList.filter(x => x.alpha2 === grower.mailingAddressCountry.val)[0] ?? ""}
                            onChange={(ev, value) => {
                                setGrower(grower => ({
                                    ...grower,
                                    mailingAddressCountry: { ...grower.mailingAddressCountry, val: (value as any)?.alpha2 ?? "" }
                                }));
                            }}
                        />
                    </>}
                    <FormControlLabel
                        sx={{ marginTop: "1em" }}
                        control={<Checkbox
                            disabled={readonly}
                            checked={!!grower.sharedWithDealership.val}
                            onChange={(e) => {
                                let newGrower = JSON.parse(JSON.stringify(grower)) as typeof grower;
                                newGrower.sharedWithDealership.val = e.target.checked ? "readonly" : undefined;
                                setGrower(newGrower);
                            }}
                        />}
                        label={t(ns + "share-grower")} />
                    {grower.sharedWithDealership.val && <FormControlLabel
                        control={<Checkbox
                            disabled={readonly}
                            checked={grower.sharedWithDealership.val === "readwrite"}
                            onChange={(e) => {
                                let newGrower = JSON.parse(JSON.stringify(grower)) as typeof grower;
                                newGrower.sharedWithDealership.val = e.target.checked ? "readwrite" : "readonly";
                                setGrower(newGrower);
                            }}
                        />}
                        label={t(ns + "allow-dealers")} />}
                </form>
            </DialogContent>
            <DialogActions>
                {
                    props.gwid && <Button color="error"
                    disabled={readonly}
                    onClick={() => {
                        props.setDeleteGrowerDialogOpen(props.gwid.id);
                    }}>
                        {t("delete")}
                    </Button>
                }
                <Button onClick={() => {
                    setGrower(defaultStateGrower);
                    setIsValid(null);
                    props.onClose();
                }}>{t("cancel")}</Button>
                <Button 
                        disabled={readonly}
                        onClick={() => {
                    validate(() => {
                        props.onClose({
                            growerId: "", // This is overwritten by IDbProvider.newGrower / updateGrower anyway
                            owner: props.gwid ? props.gwid.grower.owner : dealer.assumedUser.id,
                            dealershipNumber: props.gwid ? props.gwid.grower.dealershipNumber : dealer.dealership.number,
                            lastModified: Date.now(),

                            name: grower.name.val?.trim(),
                            farmManagerName: grower.farmManagerName.val?.trim(),
                            legalDescription: grower.legalDescription.val?.trim(),
                            email: grower.email.val?.trim(),
                            phone: grower.phone.val?.trim(),


                            shippingAddress: {
                                line1: grower.shippingAddressLine1.val?.trim(),
                                line2: grower.shippingAddressLine2.val?.trim(),
                                city: grower.shippingAddressCity.val?.trim(),
                                state: grower.shippingAddressState.val?.trim(),
                                zip: grower.shippingAddressZip.val?.trim(),
                                country: countries.alpha2ToAlpha3(grower.shippingAddressCountry.val?.trim()?.toUpperCase()),
                            },

                            mailingAddress: grower.differentMailingAddress.val ? {
                                line1: grower.mailingAddressLine1.val?.trim(),
                                line2: grower.mailingAddressLine2.val?.trim(),
                                city: grower.mailingAddressCity.val?.trim(),
                                state: grower.mailingAddressState.val?.trim(),
                                zip: grower.mailingAddressZip.val?.trim(),
                                country: countries.alpha2ToAlpha3(grower.mailingAddressCountry.val?.trim()?.toUpperCase()),
                            } : undefined,

                            sharedWithDealership: grower.sharedWithDealership.val,
                            deleted: false
                        }, props.gwid ? props.gwid.id : undefined);
                        setGrower(defaultStateGrower);
                        setIsValid(null);
                    });
                }}>{t("ok")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default GrowerDialog;