import schema from "../data/poSchema";
import CSection2 from "./CSection2";
import PartsAssembler from "./PartsAssembler";
export class HeatExchangersClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/
    }
    public WritePO = (PO: PartsAssembler) => {
        if (this.Quote.System.HeatExchangers.HE40HPQty > 0) {
            PO.InsertPart(schema.groups.HeatExchangers_HeatExchanger.groupId, "149001", this.Quote.System.HeatExchangers.HE40HPQty); // EXCHANGE-HEAT-24"
            PO.InsertPart(schema.groups.HeatExchangers_PipeFitting.groupId, "149031", this.Quote.System.HeatExchangers.HE40HPQty); // FITTING-PKG PIPE 1 1/4" HE
        }
        if (this.Quote.System.HeatExchangers.HE75HPQty > 0) {
            PO.InsertPart(schema.groups.HeatExchangers_HeatExchanger.groupId, "149002", this.Quote.System.HeatExchangers.HE75HPQty); // EXCHANGE-HEAT-30"
            PO.InsertPart(schema.groups.HeatExchangers_PipeFitting.groupId, "149031", this.Quote.System.HeatExchangers.HE75HPQty); // FITTING-PKG PIPE 1 1/4" HE
        }
        if (this.Quote.System.HeatExchangers.HE95HPQty > 0) {
            PO.InsertPart(schema.groups.HeatExchangers_HeatExchanger.groupId, "149003", this.Quote.System.HeatExchangers.HE95HPQty); // EXCHANGE-HEAT-40"
            PO.InsertPart(schema.groups.HeatExchangers_PipeFitting.groupId, "149031", this.Quote.System.HeatExchangers.HE95HPQty); // FITTING-PKG PIPE 1 1/4" HE
        }
        if (this.Quote.System.HeatExchangers.HE135HPQty > 0) {
            PO.InsertPart(schema.groups.HeatExchangers_HeatExchanger.groupId, "149004", this.Quote.System.HeatExchangers.HE135HPQty); // EXCHANGE-HEAT-48"
            PO.InsertPart(schema.groups.HeatExchangers_PipeFitting.groupId, "149031", this.Quote.System.HeatExchangers.HE135HPQty); // FITTING-PKG PIPE 1 1/4" HE
        }
        if (this.Quote.System.HeatExchangers.HE200HPQty > 0) {
            PO.InsertPart(schema.groups.HeatExchangers_HeatExchanger.groupId, "149005", this.Quote.System.HeatExchangers.HE200HPQty); // EXCHANGE-HEAT-60"
            PO.InsertPart(schema.groups.HeatExchangers_PipeFitting.groupId, "149032", this.Quote.System.HeatExchangers.HE200HPQty); // FITTING-PKG PIPE 1 1/2" HE
        }
        if (this.Quote.System.HeatExchangers.HE270HPQty > 0) {
            PO.InsertPart(schema.groups.HeatExchangers_HeatExchanger.groupId, "149004", this.Quote.System.HeatExchangers.HE270HPQty * 2); // EXCHANGE-HEAT-48"
            PO.InsertPart(schema.groups.HeatExchangers_PipeFitting.groupId, "149031", this.Quote.System.HeatExchangers.HE270HPQty * 2); // FITTING-PKG PIPE 1 1/4" HE
        }
        if (this.Quote.System.HeatExchangers.HE400HPQty > 0) {
            PO.InsertPart(schema.groups.HeatExchangers_HeatExchanger.groupId, "149005", this.Quote.System.HeatExchangers.HE400HPQty * 2); // EXCHANGE-HEAT-60"
            PO.InsertPart(schema.groups.HeatExchangers_PipeFitting.groupId, "149032", this.Quote.System.HeatExchangers.HE400HPQty * 2); // FITTING-PKG PIPE 1 1/2" HE
        }
    }
}