import { Polygon, polygon, unkinkPolygon } from "@turf/turf";
import ISegmentPolygon from "../model/project/ISegmentPolygon";

export default class SegmentPolygonHelper {

    static booleanValidPolygon = (segmentPolygon: ISegmentPolygon): boolean => {
        return segmentPolygon.segments.length >= 3 && unkinkPolygon(SegmentPolygonHelper.getPolygon(segmentPolygon)).features.length === 1;
    }

    static getPolygon = (segmentPolygon: ISegmentPolygon): Polygon => {
        return polygon([ 
            [
                ...segmentPolygon.segments.map(s => s.start),
                segmentPolygon.segments[0].start
            ]
        ]).geometry;
    }

    static isEqual = (sp1?: ISegmentPolygon, sp2?: ISegmentPolygon) => {
        if (sp1 === undefined && sp2 === undefined) return true;
        if (sp1 === undefined && sp2 !== undefined) return false;
        if (sp1 !== undefined && sp2 === undefined) return false;
        if (sp1.segments.length !== sp2.segments.length) return false;
        for (let i = 0; i < sp1.segments.length; i++) {
            const s1 = sp1.segments[i];
            const s2 = sp2.segments[i];
            if (s1.start[0] !== s2.start[0] || s1.start[1] !== s2.start[1]) return false;
            if (s1.end[0] !== s2.end[0] || s1.end[1] !== s2.end[1]) return false;
            if (s1.equipmentClearance !== s2.equipmentClearance) return false;
            if (s1.sTowerClearanceOffset !== s2.sTowerClearanceOffset) return false;
            if (s1.hTowerClearanceOffset !== s2.hTowerClearanceOffset) return false;
        }
        return true;
    }
}