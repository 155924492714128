import { Feature, Point } from "@turf/turf";
import { createNewTextAnnotation } from "../../../../actions/NewTextAnnotationAction";
import IAuthState from "../../../../auth/IAuthState";
import IDbProject from "../../../../db/IDbProject";
import { AnnotationType } from "../../../../model/project/IAnnotation";


export const handleLabel =  (
    features: Feature[],
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
    labelText: string,
) => {
    if (features.length !== 1 && features[0].geometry.type !== "Point") {
        throw new Error(`Unexpected feature created`);
    }
    if (labelText === null){
        throw new Error(`Label annotation text must be set`);
    }
    dbPrj.pushAction(createNewTextAnnotation(
        layoutId,
        AnnotationType.label,
        features[0].geometry as Point,
        labelText,
        authState
    ));
}
