import { InputLabel } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as dayjs from "dayjs";
import { Dayjs } from "dayjs";
import IGetAllowedShipDatesResp from "rdptypes/api/IGetAllowedShipDatesResp";
import IBoolean from "rdptypes/roe/IBoolean";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { createNewUpdateSystemPropertyAction } from "../../../actions/UpdateSystemProperty";
import ApiClientCtx from "../../../api/ApiClientCtx";
import ApiRequestState, { ApiRequestStatus } from "../../../api/ApiRequestState";
import AuthCtx from "../../../auth/AuthCtx";
import IDbProject from "../../../db/IDbProject";
import { getValue } from "../../../helpers/objectPathResolver";
import { translate } from "../../../helpers/translation";
import Spinner from "../../Spinner";
import { pageStyles, secondaryColor } from "../styles";
import IComponentRenderContext from "./IComponentRenderContext";

interface Props {
    cmp: IBoolean,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    parentGroupLabel: string | null;
    ctx: IComponentRenderContext;
};

const ShippingDateRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const api = useContext(ApiClientCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];
    
    const fieldPath = props.ctx.fieldRoot + props.cmp.fieldPath;
    const fvr = props.ctx.vws.validationResult?.getField(fieldPath);
    const errorSeverity = fvr?.severity;

    let showLabel = props.parentGroupLabel !== translate(props.cmp);
    
    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    if (props.parentGroupLabel === null)
    {
        formInputLabelStyle["marginLeft"] = "10px";
        formInputLabelStyle["marginRight"] = "10px";
    }
    else if (props.parentGroupLabel !== translate(props.cmp))
    {
        formInputLabelStyle["color"] = secondaryColor;
    }

    const [ allowedShipDates, setAllowedShipDates ] = useState<ApiRequestState<IGetAllowedShipDatesResp>>({
        status: ApiRequestStatus.InProgress
    });

    const currentValue = getValue(system, fieldPath);

    useEffect(() => {
        (async () => {
            const resp = await api.getAllowedShipDates({ system });
            setAllowedShipDates(resp);
            if (!currentValue) {
                let firstAllowedDate = 
                    resp.status === ApiRequestStatus.Success && resp.result.dates.length ? resp.result.dates[0]
                    : dayjs().startOf("day").add(6, "days").format();
                props.ctx.pushActionAndImproveScores(createNewUpdateSystemPropertyAction(
                    props.layoutId,
                    props.systemId,
                    props.cmp.fieldPath,
                    firstAllowedDate,
                    authState), [], false);
            }
        })();
    }, []);

    const dateShouldBeDisabled = (date: Dayjs) => {
        const now = dayjs().startOf("day");
        const daysDiff = date.diff(now, "days");
        const nextmonth = now.add(1, "months");

        if (allowedShipDates.status === ApiRequestStatus.Success && allowedShipDates.result.dates.length) {
            return allowedShipDates.result.dates.indexOf(date.format("YYYY-MM-DD")) === -1;
        } else {
            // Default options in case the server doesn't give us anything
            return daysDiff < 6 || !(
                (date.month() === now.month() && date.year() === now.year())
                || (date.month() === nextmonth.month() && date.year() == nextmonth.year() && date.date() <= 15)
            );
        }
    }

    if (allowedShipDates.status === ApiRequestStatus.InProgress) {
        return <Spinner title="Retrieving available ship dates" inline/>;
    }

    return (
        <>
            {showLabel && <InputLabel 
                error={errorSeverity === "error" || errorSeverity === "notset"}
                shrink={true} style={{...formInputLabelStyle}}>{translate(props.cmp)}</InputLabel>}
            <DatePicker
                shouldDisableDate={dateShouldBeDisabled}
                sx={{marginLeft: "10px", marginRight: "10px", display: "flex"}}
                value={dayjs(currentValue)}
                disablePast
                onChange={(newDate) => {
                    let val: string;
                    if (newDate && newDate.isValid()){
                        val = newDate.format("YYYY-MM-DD");
                    }
                    else val = undefined;

                    props.ctx.pushActionAndImproveScores(createNewUpdateSystemPropertyAction(
                        props.layoutId,
                        props.systemId,
                        props.cmp.fieldPath,
                        val,
                        authState), [], false);
                }}
                slotProps={{
                  textField: {
                    helperText:
                        allowedShipDates.error || !allowedShipDates.result?.dates
                        ? "Unable to get available shipping dates from Reinke. Please choose a preferred date but this will not be guaranteed."
                        : "This calendar shows the available shipping dates from Reinke."
                  },
                }}
            />
        </>
    );
};

export default ShippingDateRenderer;