import { Divider, FormControl, InputLabel, MenuItem, SelectChangeEvent } from "@mui/material";
import Select from '@mui/material/Select';
import { t } from "i18next";
import { EndOfSystemTypes, SwingArmLengths } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { disabledColor, pageStyles } from "../roe/styles";

interface Props {
    endOfSystemType?: EndOfSystemTypes;
    restrictedEndBoomLengths: number[];
    sacType?: SwingArmLengths;
    onChangeEndOfSystem: (args: {
        endOfSystemType?: EndOfSystemTypes, 
        restrictedEndBoomLengths: number[], 
        sacType?: SwingArmLengths,
        sacIsLeading?: boolean;
    }) => void;
    canHaveSac: boolean;
    disabled?: boolean;
}

const OPTION_CLEAR_FIELD_DUMMY_VALUE = "__OPTION_CLEAR_FIELD__";
const OPTION_CLEAR_FIELD_TITLE = "Clear field...";
const prefix = "roe.end-of-system.";

export const EndOfSystemOptimizationType_Type: React.FC<Props> = ({
    endOfSystemType, restrictedEndBoomLengths, sacType, onChangeEndOfSystem, canHaveSac, disabled = false
}) => {
    
    const handleChangeEndOfSystemType = (event: SelectChangeEvent<string>) => {
        if (event.target.value === OPTION_CLEAR_FIELD_DUMMY_VALUE) {
            onChangeEndOfSystem({ restrictedEndBoomLengths: [], sacType: SwingArmLengths.None, endOfSystemType: undefined })
        }
        else {
            switch (event.target.value as EndOfSystemTypes) {
                case EndOfSystemTypes.EndBoom:
                    onChangeEndOfSystem({ restrictedEndBoomLengths, endOfSystemType: EndOfSystemTypes.EndBoom, sacType: SwingArmLengths.None })
                    break;
                case EndOfSystemTypes.SAC:
                    onChangeEndOfSystem({ endOfSystemType: EndOfSystemTypes.SAC, sacType, restrictedEndBoomLengths: [] })
                    break;
            }
        }
    };

    let formInputLabelStyle = {
        ...pageStyles.formInputLabelStyle
    };
    if (disabled) {
        formInputLabelStyle['color'] = disabledColor;
    }
    
    return (   
        <FormControl fullWidth variant="standard" >
            <InputLabel shrink={true} style={{...formInputLabelStyle}}>{t(prefix + "type")}</InputLabel>
            <Select               
                value={endOfSystemType === undefined ? '' : endOfSystemType}
                onChange={handleChangeEndOfSystemType}
                disabled={disabled}
            >
                <MenuItem key={EndOfSystemTypes.EndBoom} value={EndOfSystemTypes.EndBoom}>                          
                    {t(prefix + "end-boom")}
                </MenuItem>
                {
                    canHaveSac && (
                        <MenuItem key={EndOfSystemTypes.SAC} value={EndOfSystemTypes.SAC}>                          
                            {t(prefix + "swing-arm-corner")}
                        </MenuItem>
                    )
                }
                <Divider />
                <MenuItem 
                    key={OPTION_CLEAR_FIELD_DUMMY_VALUE} 
                    value={OPTION_CLEAR_FIELD_DUMMY_VALUE} 
                    style={{opacity: endOfSystemType === undefined ? 0.5 : 1}} 
                >
                    <em>{OPTION_CLEAR_FIELD_TITLE}</em>
                </MenuItem>
            </Select>
        </FormControl>
    )
}