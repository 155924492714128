import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { AgriInjectTypes, BuckBoostTypes, GeneratorOptions, TowTypes } from 'rdptypes/project/ISystemBase.AutoGenerated';
import { IEndOfSystem, ISpan, ITower } from 'rdptypes/project/Types';
import IAuthState from '../auth/IAuthState';
import { staticDevSettingsDbProvider } from '../db/DevSettingsDbProvider';
import IProject, { ProjectType } from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';

const actionTypeId = "ResetSystem";

interface ActionData extends IActionData {
    layoutId: string;
    systemId: string;
}

const extractSpanData = (span: ISpan[]): ISpan[] => {
    return span.map(x => ({ 
        Disconnecting: x.Disconnecting,
        dropSpanEndRelativeToPreviousSpanEnd: x.dropSpanEndRelativeToPreviousSpanEnd,
        dropSpanStartRelativeToPreviousSpanStart: x.dropSpanStartRelativeToPreviousSpanStart,
        EndBoom: x.EndBoom,
        Length: x.Length,
        SwingArm: x.SwingArm,
        SpanNumber: x.SpanNumber
    }))
}

const extractTowerData = (tower: ITower[]): ITower[] => {
    return tower.map(x => ({
        anticlockwiseWrapAngleRelativeToPreviousSpanDegrees: x.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
        clockwiseWrapAngleRelativeToPreviousSpanDegrees: x.clockwiseWrapAngleRelativeToPreviousSpanDegrees,
        TowerNumber: x.TowerNumber,
        WrapAroundSpan: x.WrapAroundSpan,
        BuckBoost: BuckBoostTypes.aNone,
        Tires: {

        }
    }))
}

const extractEndOfSystem = (eos: IEndOfSystem): IEndOfSystem => {
    return {
        EndGun: {
            EndGunTypePrimary: eos.EndGun.EndGunTypePrimary,
            EndGunTypeSecondary: eos.EndGun.EndGunTypeSecondary,
        },
        EndOfSystemType: eos.EndOfSystemType,
        SwingArmLength: eos.SwingArmLength
    }
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;

    const customerSettings = staticDevSettingsDbProvider.customer.get();
    const dealerPricing = staticDevSettingsDbProvider.dealerPricing.get();
    
    let system = structuredClone(state.layouts[data.layoutId].systems[data.systemId]);
    
    system = {
        ...system,

        proposalGenerated: false,
        SprinklerEngineResultWarnings: undefined,
        // SystemProperties: {}, <-- keep
        // DealerProperties: {}, <-- keep

        MainlineValveOptions: {            
            Valves: {
                MainlineValve: []
            }
        },

        Ontrac: {
            Radio: {},
            SatellitePlus: {
                SatellitePlusUnit: []
            },
            Cell: {}
        },
        
        SprinklerProperties: {
            VRIZones: {
                Zone: []
            }
        },

        Options: {
            GeneratorOption: GeneratorOptions.None
        },

        FlangedSide: {
            Span: extractSpanData(system.FlangedSide.Span),
            Tower: extractTowerData(system.FlangedSide.Tower),
            EndOfSystem: extractEndOfSystem(system.FlangedSide.EndOfSystem),
            Sprinklers: {
                BoomBacks: {},
                Package: []
            },
            SprinklerChart: {
                IrrigationProperties: {
                    EndGunIrrigationProperties: {},
                    SecondaryEndGunIrrigationProperties: {}
                },
                Outlet: []
            }
        },

        FlexSide: {
            Span: extractSpanData(system.FlexSide.Span),
            Tower: extractTowerData(system.FlexSide.Tower),
            EndOfSystem: extractEndOfSystem(system.FlexSide.EndOfSystem),
            Sprinklers: {
                BoomBacks: {},
                Package: []
            },
            SprinklerChart: {
                IrrigationProperties: {
                    EndGunIrrigationProperties: {},
                    SecondaryEndGunIrrigationProperties: {}
                },
                Outlet: []
            }
        },

        AgriInject: {
            AgriInjectType: AgriInjectTypes.None
        },
        SprinklerLube: {},
        HeatExchangers: {},
        ClemonsFilters: {},
        CheckValves: {},
        FlowmeterComponents: {},
        RC10: {},
        Circle: {
            CenterPivot: {
                clockwiseCompassHeadingEnd: system.Circle.CenterPivot.clockwiseCompassHeadingEnd,
                clockwiseCompassHeadingStart: system.Circle.CenterPivot.clockwiseCompassHeadingStart,   
                isPartialPivot: system.Circle.CenterPivot.isPartialPivot,             
                TowOptions: {
                    TowType: state.projectType === ProjectType.DesignOnly ? system.Circle.CenterPivot.TowOptions.TowType : TowTypes.None,
                    Tires: {}
                }
            },
            KwikTow: {
                Tires: {}
            },
            SwingArm: {
                Tires: {}
            },
            SACRetro: {}
        },

        CropX: {},

        Lateral: {
            Tires: {},
            HoseFeed: {},
            CanalFeed: {},
            Guidance: {
                FurrowOptions: {},
                CableOptions: {},
                BuriedWireOptions: {
                    Wire: {}
                }
            },
            WaterFeed: system.Lateral.WaterFeed
        },

        ControlPanel: {
            RemotePanelOptions: {}
        },

        SpanProperties: {},

        TowerProperties: {
            TireProperties: {}
        },

        mcp: {
        },

        
        QuoteProperties: {
            ProposalInformation: {
                pricing: {
                    installation: {
                        isTaxable: customerSettings.installationIsTaxable
                    },
                    freight: {
                        isTaxable: customerSettings.freightIsTaxable
                    },
                    pivotPad: {
                        isTaxable: customerSettings.pivotPadIsTaxable
                    },
                    tradeIn: {
                        isTaxable: customerSettings.tradeinIsTaxable
                    },
                    system: {
                        isTaxable: customerSettings.systemIsTaxable
                    },
                    onlyPrintTotalPrice: customerSettings.onlyPrintTotalPrice,
                    printListPrice: customerSettings.printListPrice,
                    markupPercentOverDealerCost: customerSettings.markupPercentOverDealerCost,
                    markupDollarOverDealerCost: customerSettings.markupDollarOverDealerCost,
                    listPriceDiscountPercent: customerSettings.listPriceDiscountPercent,
                    quotedPriceDollar: 0,
                    salesTaxPercent: customerSettings.salesTaxPercent,
                    deliveryDiscountPercent: dealerPricing ? dealerPricing.deliveryDiscountPercent : undefined,
                    tmDiscountPercent:  0,
                    programDiscountPercent:  dealerPricing ? dealerPricing.programDiscountPercent : undefined
                }
            }
        },

        createQuoteStatus: undefined,
        orderSubmissionStatus: undefined
    }

    state.layouts[data.layoutId].systems[data.systemId] = system;
   
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});


export const createResetSystemAction = (layoutId: string, systemId: string, authState: IAuthState) => {
    const action = createAction(
        actionTypeId,
        {
            layoutId,
            systemId
        } as ActionData,
        authState);
    return action;
};