import { Text, View } from "@react-pdf/renderer";
import * as React from "react";
import { FC } from "react";
import { documentStyles } from "../styles";

interface Props {
};

export const WarrantyPage1: FC<Props> = (props) => {
    return (
        <View style={{padding: 10, paddingTop: 5}}>
            <View style={documentStyles.container}>
                <View style={documentStyles.column}>
                    <Text style={{fontWeight: "bold", textAlign: "center"}}>NEW IRRIGATION SYSTEM LIMITED WARRANTY</Text>
                    <Text style={{marginBottom: 5, textAlign: "justify"}}>Reinke Manufacturing Co., Inc. (Reinke) warrants to the U.S. or
                        Canadian owner (Owner) of the Irrigation System (as hereinafter
                        defined) purchased from Reinke or Reinke’s authorized dealer
                        (Dealer) and properly registered in Reinke’s records as required
                        in Section A.1 below that the Irrigation System manufactured by
                        Reinke will be free from performance or longevity affecting defect
                        in materials and workmanship for the periods specified below, with
                        the stated warranty period beginning as of the date set forth in the
                        Irrigation System’s serial number (the “Effective Date), provided
                        that the Irrigation System is properly maintained and used as
                        designed and that this Limited Warranty is not void due to one or
                        more of the warranty exclusions set forth herein. This Limited
                        Warranty shall apply ONLY to the following Reinke equipment
                        (collectively defined as the “Irrigation System”):
                    </Text>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>1) </Text>
                        <Text style={{textAlign: "justify"}}>All new Reinke Irrigation Systems are warranted for a period of
                            two (2) years from the Effective Date, excluding auxiliary or
                            supplemental equipment described in Section B of this Limited
                            Warranty, and except as otherwise provided in the following
                            paragraphs 2) through 7) below;
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>      
                        <Text>2) </Text>
                        <Text style={{textAlign: "justify"}}>
                            All Reinke RPM Touch Screen, Advanced, Advanced Plus and
                            Connect main control panel assemblies (excluding panel
                            options), as well as Navigator EOS (end of system) GPS are
                            warranted for a period of five (5) years from the Effective Date;
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>3) </Text>
                        <Text style={{textAlign: "justify"}}>
                            All Irrigation System structural components directly
                            manufactured by Reinke are warranted for a period of five (5)
                            years from the Effective Date;
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>4) </Text>
                        <Text style={{textAlign: "justify"}}>
                            Reinke center drive gear motors are warranted for a period of
                            eight (8) years from the Effective Date or 8,000 hours of
                            operation, whichever shall occur first;
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>5) </Text>
                        <Text style={{textAlign: "justify"}}>
                            Reinke wheel drive gears are warranted for a period of ten (10)
                            years from the Effective Date or 10,000 hours of operation,
                            whichever shall occur first, with the exception of Reinke “10/16”
                            wheel drive gears which are warranted for a period of ten (10)
                            years from the Effective Date or 16,000 hours of operation,
                            whichever shall occur first; and Reinke “moderate duty” wheel
                            drive gears which are warranted for a period of six (6) years
                            from the Effective Date or 6,000 hours of operation, whichever
                            shall occur first; PROVIDED, HOWEVER, THAT THE
                            FOREGOING LIMITED WARRANTY SHALL NOT APPLY TO
                            GEAR FAILURE CAUSED IN WHOLE OR IN PART BY A
                            FAILURE TO MAINTAIN THE PROPER GEAR BOX LEVEL OF
                            AN APPROVED LUBRICANT NECESSARY FOR THE
                            PROPER FUNCTIONING OF SUCH GEARS;
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>6) </Text>
                        <Text style={{textAlign: "justify"}}>
                            Reinke span joint water hose (boots) are warranted for a period
                            of ten (10) years from the Effective Date;
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>7) </Text>
                        <Text style={{textAlign: "justify"}}>
                            Reinke’s exclusive V-ring water pipe seals are warranted for a
                            period of twenty five (25) years from the Effective Date; and
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>8) </Text>
                        <Text style={{textAlign: "justify"}}>
                            Subject to the Corrosion Exclusion, the following types of water
                            pipe are warranted against perforation exceeding 1/8 (one
                            eighth) inch in diameter caused solely by corrosion for the
                            following time periods:
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text style={{marginLeft: 5}}>a) </Text>
                        <Text style={{textAlign: "justify", paddingRight: 5}}>
                            Poly-lined steel, aluminum and stainless steel water pipe are
                            each warranted for a period of twenty (20) years from the
                            Effective Date with pipe replaced during the first ten (10)
                            years of the limited warranty at no charge to the Owner, and
                            pipe replaced from year eleven (11) through twenty (20)
                            following such Effective Date on a pro-rated basis. The
                            Owner shall be allowed a credit on the price of replacement
                            pipe as provided in the Reinke parts price list current as of
                            the date the limited warranty claim is submitted with the
                            amount of pro-rated credit to be based on a factor, the
                            numerator of which shall be the number of years remaining in
                            the second ten (10) years of the original limited warranty
                            period, and the denominator of which shall be twenty (20).
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text style={{marginLeft: 5}}>b) </Text>
                        <Text style={{textAlign: "justify", paddingRight: 5}}>
                            Galvanized high strength steel and CN+ water pipe are each
                            warranted for a period of twenty (20) years from the Effective
                            Date on a pro-rated basis with the amount of pro-rated credit
                        </Text>
                    </View>
                </View>
                <View style={documentStyles.column}>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>
                            to be based on a factor, the numerator of which shall be the
                            number of years remaining in the original limited warranty
                            period, and the denominator of which shall be twenty (20).
                        </Text>
                    </View>
                    <Text style={{textAlign: "justify"}}>
                        Upon prior written notice to the Owner, Reinke reserves the right
                        to exclude specified geographic areas from this Limited Warranty
                        against corrosion damage. The Limited Warranty for water pipe
                        types set forth above shall not apply to damage or loss caused in
                        whole or in part by the lack of adhesion of paint on interior surfaces
                        of water pipe or fittings.
                    </Text>
                    <Text style={{fontWeight: "bold", textAlign: "center"}}>CORROSION EXCLUSION</Text>
                    <Text>The foregoing Limited Warranty shall not apply to the following:</Text>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>1) </Text>
                        <Text style={{textAlign: "justify"}}>
                            Poly-lined steel water pipe damaged by corrosion caused in
                            whole or in part by gases entrained in irrigation water.
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>2) </Text>
                        <Text style={{textAlign: "justify"}}>
                            Aluminum water pipe damaged by corrosion caused in whole or
                            in part by (i) wastewater, sludge, livestock manure or any
                            corrosion-causing chemicals in irrigation water, (ii) gases
                            entrained in irrigation water, (iii) acidity of water, as measured
                            by a pH level of 5.0 or less or 8.0 or more, (iv) concentrations of
                            sulfates in water of 500 P.P.M. or greater, (v) concentrations of
                            chlorides in water of 350 P.P.M. or greater, (vi) a Langlier
                            Saturation Index (LSI) of less than 0, or (vii) alteration of the pH
                            level of irrigation water.
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>3) </Text>
                        <Text style={{textAlign: "justify"}}>
                            Stainless steel water pipe damaged by corrosion caused in
                            whole or in part by: (i) gases entrained in irrigation water, (ii)
                            corrosion-causing chemicals or abrasive materials in irrigation
                            water, (iii) acidity of water, as measured by a pH level of 3.0 or
                            less or 10.0 or more, (iv) concentrations of sulfates in the
                            irrigation water of 1,000 P.P.M. or greater (v) concentration of
                            chlorides in the irrigation water of 600 P.P.M. or greater, (vi) a
                            LSI of less than -0.3, or (vii) alteration in the pH level of irrigation
                            water.
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>4) </Text>
                        <Text style={{textAlign: "justify"}}>
                            Galvanized high strength steel or CN+ water pipe damaged by
                            corrosion caused in whole or in part by: (i) wastewater, sludge,
                            livestock manure or any corrosion-causing chemicals in
                            irrigation water, (ii) gases entrained in irrigation water, (iii)
                            acidity of water, as measured by the pH level of the following
                            intensities (a) for galvanized high strength steel water pipe, a
                            pH of 6.0 or less or 8.5 or more, and (b) for CN+ water pipe, a
                            pH of 5.5 or less or 8.5 or more, (iv) concentrations of sulfates
                            of 250 P.P.M. or greater for galvanized high strength steel water
                            pipe and 300 P.P.M. or greater for CN+ water pipe, (v)
                            concentrations of chlorides of 250 P.P.M. or greater for
                            galvanized high strength steel water pipe and CN+ water pipe,
                            (vi) a LSI less than 1.0 for galvanized high strength steel water
                            pipe or a LSI less than 0.3 for CN+ water pipe, or (vii) alteration
                            of the pH level of irrigation water.
                        </Text>
                    </View>
                    <Text style={{fontWeight: "bold", marginTop: 5, textDecoration: "underline", textAlign: "center"}}>A. CONDITIONS TO CLAIM WARRANTY BENEFITS</Text>
                    <Text style={{textAlign: "justify"}}>
                        If the Irrigation System is found to have a performance or longevity
                        affecting defect in materials or workmanship and such defect is
                        within the coverage of this Limited Warranty, such defective
                        component shall be repaired or replaced, at Reinke’s sole option,
                        provided the following conditions are met:
                    </Text>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>1) </Text>
                        <Text style={{textAlign: "justify"}}>
                            A completed warranty registration card, signed by both the
                            Owner (or an authorized representative) and a Dealer, is
                            returned to the Dealer Support Department at Reinke
                            Manufacturing Co., Inc., P.O. Box 566, Deshler, Nebraska
                            68340, U.S.A. within sixty (60) days of the date of delivery of
                            the Irrigation System to the Owner or the transfer of ownership
                            of the Irrigation System to a new Owner.
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>2) </Text>
                        <Text style={{textAlign: "justify"}}>
                            The claimed performance or longevity affecting defect is
                            discovered within the warranty time periods indicated above.
                        </Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>3) </Text>
                        <Text style={{textAlign: "justify"}}>
                            The Irrigation System and any covered components have at all
                            times been installed, maintained and operated within specified design criteria pursuant to the guidelines and directives set forth
                            in the Reinke System Owners/Operators Manual and all other
                            Reinke published specifications. THIS WARRANTY SHALL
                            NOT APPLY IF THE IRRIGATION SYSTEM HAS IN ANY WAY
                            BEEN TAMPERED WITH, ALTERED, REPAIRED OR
                            REPLACED BY ANYONE OTHER THAN REINKE OR A
                            DEALER.
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    );
};

export const WarrantyPage2: FC<Props> = (props) => {
    return (
        <View style={{padding: 10, paddingTop: 5}}>
            <View style={documentStyles.container}>
                <View style={documentStyles.column}>
                    <View style={{display: "flex", flexDirection: "row", paddingRight: 5}}>
                        <Text>4) </Text>
                        <Text style={{textAlign: "justify"}}>
                            Reinke makes no warranty for any Irrigation System component
                            that has been (i) damaged by accident, acts of God, corrosion,
                            the elements, abuse, misuse, misapplication, malicious
                            mischief, vandalism, riots, wars, improper installation, neglect,
                            design, or lack of proper and reasonable maintenance or (ii)
                            modified in connection with the installation of any parts or
                            accessories on the Irrigation System. Reinke makes no
                            warranty for damage to the Irrigation System or any component
                            thereof caused by alterations to the Irrigation System or such
                            component which are not performed in conformity with Reinke’s
                            specifications.
                        </Text>
                    </View>
                    <Text style={{textAlign: "justify"}}>
                        All warranties described herein are for parts only F.O.B. Deshler,
                        Nebraska, U.S.A. No labor or freight allowance is included.
                        Owner shall be solely responsible for payment of all customs
                        duties, importation fees, VAT or other like charges, including, but
                        not limited to, Reinke’s return shipment costs. To be entitled to
                        benefits under this Limited Warranty, Owner must provide notice
                        in writing to a Dealer within thirty (30) days after the failure is
                        discovered or reasonably should have been discovered by Owner,
                        and the Owner shall, at Owner’s cost, deliver the component of
                        the Irrigation System to a Dealer for performance of any repairs or
                        replacements within sixty (60) days after the performance or
                        longevity affecting defect is reported or such shorter time frame
                        as may be required by Reinke. Any repairs or replacements of
                        any component of the Irrigation System not performed by Reinke
                        or a Dealer shall void this Limited Warranty.
                    </Text>
                    <Text style={{fontWeight: "bold", textDecoration: "underline", textAlign: "center"}}>B. TERMS AND CONDITIONS</Text>
                    <Text style={{textAlign: "justify"}}>
                        The Irrigation System covered by this warranty is sold subject to
                        Reinke’s Limited Warranty only. The Irrigation System
                        manufactured by Reinke shall be subject to this Limited Warranty,
                        which the Dealer extends to the Owner of the Irrigation System.
                        The selling Dealer or any other Dealer has no authority to make
                        any modifications or changes to the terms and conditions hereof
                        or to make any other representation, warranty, condition or
                        promise on behalf of Reinke. Reinke makes no warranty covering
                        the repair and replacement of a defect in workmanship and
                        materials in auxiliary or supplemental equipment not directly
                        manufactured by Reinke or on parts or components which may
                        wear out, including but not limited to generators, engines,
                        batteries, clutches, electric drag cords, oil seals, bearings, paint,
                        decals, tires or tubing. Any warranties for such auxiliary or
                        supplemental equipment, parts or components will be those
                        offered by the respective supplier or manufacturer of the
                        equipment, part or component (third-party warranties). Owner
                        shall be solely responsible for compliance with the terms and
                        conditions of any and all third-party warranties. Nothing in this
                        Limited Warranty shall expand, enlarge upon or alter in any way,
                        the third-party warranties. Tire warranties are expressly excluded
                        from this Limited Warranty.
                    </Text>
                    <Text style={{fontWeight: "bold", textDecoration: "underline", textAlign: "center"}}>C. LIMITATIONS OF LIABILITY</Text>
                    <Text>
                        REINKE AND OWNER AGREE THAT, IN CONSIDERATION OF
                        THE LIMITED WARRANTY EXPRESSED HEREIN, ALL OTHER
                        WARRANTIES OTHER THAN TITLE, EITHER EXPRESS OR
                        IMPLIED, WHETHER ARISING UNDER LAW OR IN EQUITY,
                        INCLUDING WITHOUT LIMITATION, THE WARRANTIES OF
                        MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                        PURPOSE, ARE EXCLUDED. FURTHER, THE LIMITED
                        WARRANTY EXPRESSED HEREIN IS MADE SOLELY TO THE
                        OWNER OF THE IRRIGATION SYSTEM AND NOT TO ANY
                        LESSOR OR LESSEE OF THE IRRIGATION SYSTEM
                        DESCRIBED HEREIN. NO WARRANTIES, EXPRESSED OR
                        IMPLIED, OF FITNESS OR MERCHANTABILITY ARE MADE OR
                        ARISE WITH RESPECT TO USED EQUIPMENT, AUXILIARY
                        OR SUPPLEMENTAL EQUIPMENT PURCHASED FROM THIRD
                        PARTY MANUFACTURERS, OR ANY AND ALL PARTS,
                        ACCESSORIES, GOODS AND EQUIPMENT MANUFACTURED
                        BY THIRD PARTIES THAT ARE USED WITH, OR
                        INCORPORATED IN, THE IRRIGATION SYSTEM.
                    </Text>
                </View>
                <View style={documentStyles.column}>
                    <Text style={{marginBottom: 5, textAlign: "justify"}}>
                        NOTWITHSTANDING ANY OTHER PROVISIONS OF THIS
                        LIMITED WARRANTY, IN NO EVENT SHALL REINKE OR ITS
                        DEALER BE LIABLE, WHETHER ARISING UNDER
                        CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
                        LIABILITY OR OTHERWISE, FOR LOSS OF USE OF THE
                        IRRIGATION SYSTEM; ANTICIPATED BUSINESS OR PROFITS
                        (INCLUDING ANTICIPATED LEASE PAYMENTS); CROP
                        DAMAGES; TRANSPORTATION, TOWING OR RELATED
                        EXPENSES DUE TO REPAIRS, NON-OPERATION, REDUCED
                        OPERATION OR INCREASED EXPENSE OF OPERATION;
                        COST OF PURCHASED OR LEASED REPLACEMENT
                        EQUIPMENT; COSTS DUE TO DELAYS OR DEFAULTS IN
                        MAKING DELIVERY OR INSTALLATION OF THE IRRIGATION
                        SYSTEM, REPLACEMENT EQUIPMENT OR ANY
                        COMPONENT THEREOF; COST OF MONEY; LOSS OF USE
                        OF CAPITAL OR REVENUE; LOSS OF OWNER’S TIME; OR
                        FOR ANY CONSEQUENTIAL, INDIRECT, SPECIAL, PUNITIVE
                        OR INCIDENTAL LOSS OR DAMAGE OF ANY NATURE
                        ARISING AT ANY TIME OR FROM ANY CAUSE WHATSOEVER
                        ARISING OUT OF OR IN CONNECTION WITH THE
                        IRRIGATION SYSTEM OR THE USE THEREOF OR ARISING
                        OUT OF A BREACH OF THE PURCHASE CONTRACT OR ANY
                        WARRANTIES ARISING THEREFROM, WHETHER SUCH
                        CLAIMS ARE MADE BY THE ORIGINAL OWNER OR ANY
                        SUBSEQUENT OWNER, LESSOR OR LESSEE OF THE
                        IRRIGATION SYSTEM, OR ANY RELATED SUCCESSOR IN
                        INTEREST THERETO. MANUFACTURER DISCLAIMS ALL
                        LIABILITY FOR ANY MODIFICATIONS MADE TO THE
                        IRRIGATION SYSTEM FROM THE TIME IT LEAVES
                        MANUFACTURER’S CUSTODY. NO CLAIM BY OWNER OF
                        ANY KIND SHALL BE GREATER IN AMOUNT THAN THE NET
                        PURCHASE PRICE OF THE IRRIGATION SYSTEM.
                    </Text>
                    <Text style={{textAlign: "justify"}}>
                        This Limited Warranty and the Limitations of Liability set forth
                        herein shall be governed by and construed in accordance with the
                        laws of the State of Nebraska, U.S.A. exclusive of choice of law
                        rules. In the event any one or more of the provisions contained in
                        this Limited Warranty and Limitations of Liability shall for any
                        reason be held to be invalid, void or unenforceable in any respect,
                        such invalid, void or unenforceable provision shall not in any way
                        affect any other provision herein, but this Limited Warranty and
                        Limitations of Liability shall be construed as if such invalid, void or
                        unenforceable provision had never been contained herein. The
                        limitations and exclusions may not be allowed by some states or
                        provinces, and if, notwithstanding the choice of law provision
                        contained herein, the law of such state or province applies, such
                        limitations and exclusions shall not apply to the extent of
                        applicable state or provincial law.
                    </Text>
                </View>
            </View>
        </View>
    );
};