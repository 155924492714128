import * as React from "react";
import { FC } from "react";

interface Props {
    fill: boolean;
    svgCenter: number;
    radius: number;
};

const Circle: FC<Props> = (props) => {
    return (
        <circle
            cx={props.svgCenter}
            cy={props.svgCenter}
            r={props.radius}
            stroke="black"
            stroke-width="1"
            fill={props.fill? "#1976d2" : "white"}
        />
    );
  };

  export default Circle;