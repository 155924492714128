import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Feature } from "geojson";
import { LngLat, Map, MapMouseEvent } from "mapbox-gl";
import { CircleSelectModeExtension } from "./CircleSelectModeExtension";
import ISelectModeExtension from "./ISelectModeExtension";

import { SectorSelectModeExtension } from "./SectorSelectModeExtension";
import { isOfMetaType } from "./copied-from-mapbox-gl-draw/common_selectors";
import * as Constants from "./copied-from-mapbox-gl-draw/constants";
import createSupplementaryPoints from "./copied-from-mapbox-gl-draw/create_supplementary_points";

interface IDirectSelectMode extends MapboxDraw.DrawCustomMode<any, any> {
    dragVertex: (state: any, e: MapMouseEvent, delta: LngLat) => void;
    fireActionable: (state: any) => void;
    map: Map;
}

const DirectSelectMode = MapboxDraw.modes.direct_select as IDirectSelectMode;
export const ExtDirectSelectMode = { ...DirectSelectMode };
export default ExtDirectSelectMode;

const extensions: ISelectModeExtension[] = [
    CircleSelectModeExtension,
    SectorSelectModeExtension
];

const isVertex = isOfMetaType(Constants.meta.VERTEX);
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT);

ExtDirectSelectMode.onSetup = function(this, opts) {
    const state = DirectSelectMode.onSetup.call(this, opts);
    state.canDragMove = false;
    return state;
}

ExtDirectSelectMode.dragVertex = function(this: MapboxDraw.DrawCustomModeThis, state, e, delta) {
    const geojson: Feature = state.feature;

    for (const ext of extensions) {
        if (ext.supportsEditingFeature(geojson)) {
            ext.dragVertex(state, e, delta);
            return;
        }
    }

    DirectSelectMode.dragVertex(state, e, delta);
};

ExtDirectSelectMode.onFeature = function(this, state, e) {
    // do nothing
};
ExtDirectSelectMode.onMouseMove = function(this, state, e) {
    // copy of mapbox updated to prevent feature moving
  const onVertex = isVertex(e);
  const isMidPoint = isMidpoint(e);

  const isDraggableItem = onVertex || isMidPoint;
  if (isDraggableItem) this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  else this.updateUIClasses({ mouse: Constants.cursors.NONE });
  if (isDraggableItem && state.dragMoving) this.fireUpdate();

  this.stopDragging(state);

  // Skip render
  return true;
};

ExtDirectSelectMode.toDisplayFeatures = function (this: MapboxDraw.DrawCustomModeThis, state, geojson: Feature, display) {
    console.log("ExtDirectSelectMode.toDisplayFeatures")
    
    if (state.featureId === geojson.properties.id) {
        geojson.properties.active = "true";
        display(geojson);

        let supplementaryPoints: Feature[] | undefined;

        for (const ext of extensions) {
            if (ext.supportsEditingFeature(geojson)) {
                supplementaryPoints = ext.createSupplementaryPoints(geojson);
            }
        }

        if (!supplementaryPoints) {
            supplementaryPoints = createSupplementaryPoints(geojson, {
                map: this.map,
                midpoints: true,
                selectedPaths: state.selectedCoordPaths
              }) as Feature[];
        }

        supplementaryPoints.forEach(display);
      } else {
        geojson.properties.active = "false";
        if (geojson.properties.user_parentId === state.featureId) {
            // We don't want to show the clearance for the moving obstacle, 
            // as, unless we also update the clearance polygon, it will become 
            // out of sync with this updating feature.
            // TODO: Create a mode which also updates the clearnce poly
        }
        else {
            display(geojson);
        }
      }
      (this as any).fireActionable(state);
};