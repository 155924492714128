import { FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import { t } from "i18next";
import * as React from "react";
import { useContext } from "react";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { DisplayLengthUnitBuilder } from "../../helpers/lengths";
import PositiveConvertedDecimalField from "../CustomInputComponents/PositiveConvertedDecimalField";
import CustomDefaultsRadioGroup from "./CustomDefaultsRadioGroup";

export interface ISacOptimizerSettingsFormState {
    sacMaxStwrSteeringAngleRateDegreesPerFoot: number;
}

export const CUSTOM_SAC_OPTIMIZER_SETTINGS_DEFAULT_FORM_STATE: ISacOptimizerSettingsFormState = {
    sacMaxStwrSteeringAngleRateDegreesPerFoot: 3.1
}

const TabBody: React.FC = () => {
    
    const devSettingsState = useContext(DevSettingsCtx);
    const displaySettings = devSettingsState.dealerSettings.display.current;
    const { setFormState, formState } = devSettingsState.dealerSettings.sacOptimizer.custom;
    const { 
        sacMaxStwrSteeringAngleRateDegreesPerFoot
    } = formState;

    const setSacMaxStwrSteeringAngleRateDegreesPerFoot = (sacMaxStwrSteeringAngleRateDegreesPerFoot: number) => setFormState({ ...formState, sacMaxStwrSteeringAngleRateDegreesPerFoot });

    const minLimitDegPerFoot = 3.1;
    const maxLimitDegPerFoot = 5.1;
    const minLimitDisplayUnits = displaySettings.lengths === 'feet'
        ? minLimitDegPerFoot
        :  minLimitDegPerFoot * DisplayLengthUnitBuilder.convert(1, displaySettings.lengths, 'feet');
    const maxLimitDisplayUnits = displaySettings.lengths === 'feet'
        ? maxLimitDegPerFoot
        :  maxLimitDegPerFoot * DisplayLengthUnitBuilder.convert(1, displaySettings.lengths, 'feet');

    const ns = "dealer-settings.";
    const subns = "sac-opt-settings-options.";

    return (
        <>
            <CustomDefaultsRadioGroup 
                title={t(ns + "sac-opt-settings")}
                useCustomDefaults={devSettingsState.dealerSettings.sacOptimizer.useCustom}
                setUseCustomDefaults={devSettingsState.dealerSettings.sacOptimizer.setUseCustom}
            />
             {
                devSettingsState.dealerSettings.sacOptimizer.useCustom && (
                    <>
                        {/* Max Steering Angle Rate of Change */}
                        <Box sx={{ overflow: "auto", flex: 1, padding: 2, width: "100%" }}>
                                <FormControl sx={{ m: 1, width: 300 }}>
                                    <PositiveConvertedDecimalField
                                        textFieldProps={{
                                            label: `${t(ns + subns + "max-steering-angle-roc")} °/${t(DisplayLengthUnitBuilder.shortName(displaySettings.lengths))} (${t(ns + subns + "at-stwr")})`,
                                            helperText: `${t(ns + subns + "steering-angle-must-be")} ${minLimitDisplayUnits} ${t("and")} ${maxLimitDisplayUnits} °/${t(DisplayLengthUnitBuilder.shortName(displaySettings.lengths))}`
                                        }}
                                        value={sacMaxStwrSteeringAngleRateDegreesPerFoot}
                                        setValue={setSacMaxStwrSteeringAngleRateDegreesPerFoot}
                                        originalUnit="feet"
                                        displayUnit={displaySettings.lengths}
                                        isValid={(value: number) => {
                                            return value >= 3.1 && value <= 5.1
                                        }}
                                    />
                                </FormControl>
                        </Box>
                    </>
                )
             }   
        </>
    );
}

export default <TabBody />;
