import { IEnhProgress } from "./Progress/IEnhProgress";
import { NewThreadProgressDialogManager } from "./Progress/NewThreadProgressDialogManager";
import { ProgressScaling } from "./Progress/ProgressScaling";
import { Order0SACOptimizer } from "./SAC/Branching/Order0SACOptimizer";
import { ReverseSACOptimizer } from "./SAC/Branching/ReverseSCOptimizer";
import { SmoothingSACOptimizer } from "./SAC/Branching/SmoothingSACOptimizer";
import { ISACOptimizationReducedPartialPivotRDP3 } from "./SAC/ISACOptimizationReducedPartialPivotRDP3";
import { ISACOptimizationResultRDP3 } from "./SAC/ISACOptimizationResultRDP3";
import { ISACOptimizeResult } from "./SAC/ISACOptimizationSolutionRDP3";
import { QuantizedSACOptimizationState } from "./SAC/QuantizedSACOptimizationState";
import { SACCentrePivotType } from "./SAC/SACCentrePivotType";
import { SACOptimizationProblem } from "./SAC/SACOptimizationProblem";
import { Tuple } from "./System/Tuple";

export class SACOptimizer {
    private _problem: SACOptimizationProblem;
    public get Problem() {
        return this._problem;
    }

    public get Validate() { return true; };

    public constructor(problem: SACOptimizationProblem)
    {
        this._problem = problem;
    }

    private async optimize(progressDialog: NewThreadProgressDialogManager): Promise<ISACOptimizationResultRDP3 | undefined> {

        const state = new QuantizedSACOptimizationState(this.Problem);
        progressDialog.Show("Optimizing SAC");

        if (!state.Initialize(new ProgressScaling(progressDialog.Progress, 0, 10))) {
            return undefined;
        }
        
        /* First we try to find a solution that satisfies order-0 constraints only. This can be done easily on multiple
            * threads. In the case of partial pivots, the result can also be used to recommend a smaller partial pivot that
            * satisfies order-0 constraints. */
        const order0Optimizer = new Order0SACOptimizer(state);
        if (!(await order0Optimizer.Optimize(new ProgressScaling(progressDialog.Progress, 10, 30)))) {
            progressDialog.Progress.Report(100);

            // The order-0 optimization failed - maybe because it was canceled?
            if (await progressDialog.Progress.isCancelPending()) {
                return undefined;
            }

            /* For partial pivots, if there is no solution that satisfies order-0 constraints then we recommend
                * automatically reducing the partial pivot angles until there is. */
            const reducedPartialPivotResult = this.FindReducedPartialPivot(state, new ProgressScaling(progressDialog.Progress, 100, 100));
            if (reducedPartialPivotResult !== null) {
                return { reduce: reducedPartialPivotResult };
            }
            else {
                return undefined;
            }
        }

        // console.log("order 0 res")
        // console.log(JSON.stringify(state.BestSolution))

        /* Starting from the order-0 solution, we now run the smoothing SAC optimizer that ensures order-1 and
            * order-2 constraints are also satisfied. Note that this optimizer is much faster than the A* optimizer, but
            * is not guaranteed to find an optimal solution. See comments in SmoothingSACOptimizer for more information. */
        var order1Optimizer = new SmoothingSACOptimizer(state, false);
        if (!(await order1Optimizer.Optimize(new ProgressScaling(progressDialog.Progress, 30, 40)))) {
            return undefined;
        }
        // console.log("order 1 res")
        // console.log(JSON.stringify(state.BestSolution))

        var order2Optimizer = new SmoothingSACOptimizer(state, true);
        if (!(await order2Optimizer.Optimize(new ProgressScaling(progressDialog.Progress, 40, 90)))) {
            return undefined;
        }
        // console.log("order 2 res")
        // console.log(JSON.stringify(state.BestSolution))
        
        var reverseOptimizer = new ReverseSACOptimizer(state);
        if (!(await reverseOptimizer.Optimize(new ProgressScaling(progressDialog.Progress, 90, 100)))) {
            return undefined;
        }

        if (this.Validate && !state.VerifyConstraints(new ProgressScaling(progressDialog.Progress, 100, 100))) {
            throw new Error("SAC constraints verification failed");
        }

        return {
            state
        };
    }

    public async optimizeRDP3(isCancelled: () => Promise<boolean>): Promise<ISACOptimizeResult> {

        const progressDialog = new NewThreadProgressDialogManager(isCancelled);
        try {
            const optimizationResult = await this.optimize(progressDialog);
            
            if (optimizationResult === undefined) {
                return { success: false, canReduce: false };
            }
            else if ('reduce' in optimizationResult) {
                return { success: false, canReduce: true, reduce: optimizationResult.reduce };
                
            }
            else {
                return {
                    success: true,
                    solution: optimizationResult.state.GetSolutionRDP(new ProgressScaling(progressDialog.Progress, 100, 100))
                };
            }
        }
        catch {
            return { success: false, canReduce: false };
        }
        finally {
            progressDialog.Close();
        }
    }
        
    private FindReducedPartialPivot(state: QuantizedSACOptimizationState, progress: IEnhProgress): ISACOptimizationReducedPartialPivotRDP3 | null {
        if (state.Problem.CentrePivotType === SACCentrePivotType.Full) {
            return null;
        }

        progress.SetStatusMessage("Checking whether partial pivot can be reduced");

        let biggestValidRange: Tuple<number, number> | null = null;
        let currentRange: Tuple<number, number> | null = null;
        for (let iPivotAngle = state.iPivotAngleMin; iPivotAngle <= state.iPivotAngleMax; iPivotAngle++) {
            if (state.BestSolution[iPivotAngle -  state.iPivotAngleMin] <= state.iExtensionAngleMaxFeasible) {
                if (currentRange === null) {
                    currentRange = new Tuple<number, number>(iPivotAngle, iPivotAngle);
                }
                else {
                    currentRange = new Tuple<number, number>(currentRange.Item1, iPivotAngle);
                }
            }
            else {
                if (currentRange !== null) {
                    if (biggestValidRange === null
                        || currentRange.Item2 - currentRange.Item1 > biggestValidRange.Item2 - biggestValidRange.Item1) {
                        biggestValidRange = currentRange;
                    }
                    currentRange = null;
                }
            }
        }
        if (currentRange !== null && (biggestValidRange === null
                || currentRange.Item2 - currentRange.Item1 > biggestValidRange.Item2 - biggestValidRange.Item1)) {
            biggestValidRange = currentRange;
        }

        if (biggestValidRange === null || (biggestValidRange.Item1 === state.iPivotAngleMin && biggestValidRange.Item2 === state.iPivotAngleMax)) {
            return null;
        }

        // Round pivot angles inwards by 0.001 degrees to prevent iPivotAngleMin/iPivotAngleMax rounding outwards
        // when the optimization is retried.
        return {
            newPartialPivotAngleDegreesMin: state.Problem.GetTheta(biggestValidRange.Item1) * 180.0 / Math.PI + 0.001,
            newPartialPivotAngleDegreesMax: state.Problem.GetTheta(biggestValidRange.Item2) * 180.0 / Math.PI - 0.001
        };
    }
}