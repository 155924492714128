import { Card, CardContent, CardHeader, Stack, TextField } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import { useContext } from "react";
import DealerDataCtx from "../../userData/DealerDataCtx";


const combineAddress = (lines: (string | undefined)[]) => {
    let combined = "";
    for (const line of lines) {
        if ((line ?? "").trim() === "") {
            continue;
        }
        if (combined !== "") {
            combined += "\n";
        }
        combined += line;
    }
    return combined;
}

const TabBody: React.FC = () => {
    const user = useContext(DealerDataCtx);

    return (
        <>
            <Stack direction="row">
                <Card variant="outlined" sx={{width: 400, marginRight: 2}}>
                    <CardHeader
                        title={i18next.format(i18next.t('dealership'), 'capitalize')}
                    />
                    <CardContent>
                        <Stack
                            direction="column"
                        >
                            <TextField
                                disabled
                                value={user.dealership.number}
                                label={i18next.format(i18next.t('number'), 'capitalize')}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                disabled
                                value={user.dealership.name}
                                label={i18next.format(i18next.t('name'), 'capitalize')}
                                fullWidth

                                margin="normal"
                            />
                            <TextField
                                disabled
                                value={user.dealership.contactName}
                                label={i18next.format(i18next.t('contact'), 'capitalize')}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                disabled
                                value={combineAddress([
                                    user.dealership.addresseeName,
                                    user.dealership.addressLine1,
                                    user.dealership.addressLine2,
                                    user.dealership.addressLine3,
                                    user.dealership.city,
                                    user.dealership.state,
                                    user.dealership.zip,
                                    user.dealership.country
                                ])}
                                label={i18next.format(i18next.t('address'), 'capitalize')}
                                fullWidth
                                multiline
                                rows={8}
                                margin="normal"
                            />
                            <TextField
                                disabled
                                value={user.dealership.telephone}
                                label={i18next.format(i18next.t('phone'), 'capitalize')}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                disabled
                                value={user.dealership.fax}
                                label={i18next.format(i18next.t('fax'), 'capitalize')}
                                fullWidth
                                margin="normal"
                            />
                        </Stack>
                    </CardContent>
                </Card>
                <Card variant="outlined" sx={{width: 400}}>
                    <CardHeader
                        title={i18next.format(i18next.t('territory-manager'), 'capitalize')}
                    />
                    <CardContent>
                        <Stack
                            direction="column"
                        >
                        <TextField
                            disabled
                            value={user.dealership.territoryManagerName}
                            label={i18next.format(i18next.t('name'), 'capitalize')}
                            fullWidth
                            margin="normal"
                        />
                            <TextField
                                disabled
                                value={user.dealership.territoryManagerEmail}
                                label={i18next.format(i18next.t('email'), 'capitalize')}
                                fullWidth
                                margin="normal"
                            />
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </>
    );
}

export default <TabBody />;