import * as React from "react";
import { FC, useContext } from "react";

import { View } from "@react-pdf/renderer";
import { t } from "i18next";
import IDbGrower from "rdptypes/api/IGrowerBase";
import { formatAddress } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import IProject from "../../model/project/IProject";
import DealerDataCtx from "../../userData/DealerDataCtx";
import DealerLogo from "./Logos/DealerLogo";
import FixedWidthTable, { IRow } from "./table/FixedWidthTable";

interface Props {
    grower: IDbGrower;
    dealerFirst?: boolean;
    includeDealerLogo?: boolean;
    poNumber: string;
    project: IProject;
    layoutId: string;
    systemId: string;
};

const CustomerDealerTable: FC<Props> = (props) => {
    const { dealership: dealer } = useContext(DealerDataCtx);
    
    let {dealerFirst, grower} = props;
    let ns = "common-phrases.";

    let fieldName = `${props.project.layouts[props.layoutId].systems[props.systemId].name} (${props.project.name})`;

    let rows: IRow[] = [{
        cells: [
            {
                text: dealerFirst ? t(ns + "sltDealerInfo") : t(ns + "sltCustomerInfo"),
                header: true,
                widthPc: 50
            }, {
                text: dealerFirst ? t(ns + "sltCustomerInfo") : t(ns + "sltDealerInfo"),
                header: true,
                widthPc: 50
            }
        ]
    }, {
        cells: [
            {
                text: dealerFirst ? `PO #: ${props.poNumber ?? ""}` : grower.name,
                widthPc: 50
            }, {
                text: dealerFirst ? grower.name : `PO#: ${props.poNumber ?? ""}`,
                widthPc: 50
            }
        ]
    }];

    if(grower.legalDescription){
        rows.push({
            cells: [
                {
                    text: dealerFirst ? `` : `${t(ns + "sltLegal")} ${grower.legalDescription}`,
                    widthPc: 50
                }, {
                    text: dealerFirst ? `${t(ns + "sltLegal")} ${grower.legalDescription}` : ``,
                    widthPc: 50
                }
            ]
        });
    }
    
    rows.push(...[{
        cells: [
            {
                text: dealerFirst ? `Dealer #: ${dealer.number}` : grower.shippingAddress.line1,
                widthPc: 50
            }, {
                text: dealerFirst ? grower.shippingAddress.line1 : ``,
                widthPc: 50
            }
        ]
    }, {
        cells: [
            {
                text: dealerFirst ? dealer.name : formatAddress({ ...grower.shippingAddress, line1: undefined}),
                widthPc: 50
            }, {
                text: dealerFirst ? formatAddress({ ...grower.shippingAddress, line1: undefined}) : dealer.name,
                widthPc: 50
            }
        ]
    }, {
        cells: [
            {
                text: dealerFirst ? dealer.addressLine1 : grower.email,
                widthPc: 50
            }, {
                text: dealerFirst ? grower.email : dealer.addressLine1,
                widthPc: 50
            }
        ]
    }, {
        cells: [
            {
                text: dealerFirst ? `${dealer.city}, ${dealer.state} ${dealer.zip}, ${dealer.country}` : grower.phone,
                widthPc: 50
            }, {
                text: dealerFirst ? grower.phone : `${dealer.city}, ${dealer.state} ${dealer.zip}, ${dealer.country}`,
                widthPc: 50
            }
        ]
    }, {
        cells: [
            {
                text: dealerFirst ? dealer.telephone : fieldName,
                widthPc: 50
            },
            {
                text: dealerFirst ? fieldName : dealer.telephone,
                widthPc: 50
            }
        ]
    }]);

    if (props.includeDealerLogo){
        rows.push({cells: [
            {
                child: dealerFirst ? <DealerLogo style={{maxHeight: 55, objectFit: "scale-down"}}/> : <View/>,
                widthPc: dealerFirst ? 25 : 50
            },
            {
                child: dealerFirst ? <View/> : <DealerLogo style={{maxHeight: 55, objectFit: "scale-down"}}/>,
                widthPc: dealerFirst ? 50 : 25
            }
        ]});
    }

    return (
        <FixedWidthTable
            data={
                { rows }
            }
        />
    );
};

export default CustomerDealerTable;