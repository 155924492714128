import { Stack } from "@mui/material";
import i18next, { t } from "i18next";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import { getSpansWithoutEndOfSystem } from "rdptypes/helpers/Spans";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import IChoice from "rdptypes/roe/IChoice";
import IComponent from "rdptypes/roe/IComponent";
import IEndOfSystemTypeChoice from "rdptypes/roe/IEndOfSystemTypeChoice";
import * as React from "react";
import AuthCtx from "../../../../../auth/AuthCtx";
import IDrawerTab from "../IDrawerTab";
import EndOfSystemForSide from "./EndOfSystemForSide";


const eosImg = new URL(
    'eos.png?as=webp&width=25',
    import.meta.url
  );

export const PRIMARY_END_GUN_OPTIONS = [
    { id: "None", title: 'None', default: true},  
    { id: "R55i", title: "R55i (Inverted)" },
    { id: "R55", title: "R55" },
    { id: "R75", title: "R75" },
    { id: "SingleP85", title: "P85" },
    { id: "SR75", title: "SR75" },
    { id: "SR100", title: "SR100" },
    { id: "SR100NV", title: "SR100NV" },
    { id: "SimeWing", title: "Sime Wing" },
    { id: "TwinMax", title: "Twin Max" },
    { id: "Twin101Ultra", title: "Twin 101 Ultra" }
];

export const SECONDARY_END_GUN_OPTIONS = [
    { id: "None", title: 'None', default: true},  
    { id: "R55i", title: "R55i (Inverted)" },
    { id: "R55", title: "R55" },
    { id: "R75", title: "R75" }
];

const flangedSidePrimaryEndGun: IChoice = {
    type: "choice",
    fieldPath: "FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
    title: "",
    options: PRIMARY_END_GUN_OPTIONS,
    enumNameHint: "EndGunTypes",
};
const flangedSideSecondaryEndGun: IChoice =  {
    type: "choice",
    fieldPath: "FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary",
    title: "",
    options: SECONDARY_END_GUN_OPTIONS,
    enumNameHint: "EndGunTypes",
};
const flexSidePrimaryEndGun: IChoice = {
    type: "choice",
    fieldPath: "FlexSide.EndOfSystem.EndGun.EndGunTypePrimary",
    title: "",
    options: PRIMARY_END_GUN_OPTIONS,
    enumNameHint: "EndGunTypes",
};
const flexSideSecondaryEndGun: IChoice = {
    type: "choice",
    fieldPath: "FlexSide.EndOfSystem.EndGun.EndGunTypeSecondary",
    title: "",
    options: SECONDARY_END_GUN_OPTIONS,
    enumNameHint: "EndGunTypes",
};

const endOfSystemTab: IDrawerTab =  {
    icon: <img src={eosImg} style={{paddingTop: "5px",paddingBottom: "5px"}}/>,
    getTitle: () => i18next.format(t("end-of-system"), 'capitalize-each'),
    component: ({ dbPrj, layoutId, systemId, projectId }) => {

        const authState = React.useContext(AuthCtx);

        const system = dbPrj.state.layouts[layoutId].systems[systemId];

        let flangedSideComponents: { eos: IComponent, primaryEndgun: IComponent, secondaryEndgun: IComponent } = undefined;
        let flexSideComponents: { eos: IComponent, primaryEndgun: IComponent, secondaryEndgun: IComponent } | undefined = undefined;

        switch (system.SystemProperties.SystemType) {
            case SystemTypes.CanalFeedMaxigator:
            case SystemTypes.HoseFeedMaxigator:
                // lateral system
                // flanged side:
                flangedSideComponents = {
                    eos: {
                        type: 'endOfSystemType',
                        side: SideEnum.Flanged,
                        // title: "Flanged Side"
                    } as IEndOfSystemTypeChoice,
                    primaryEndgun: flangedSidePrimaryEndGun,
                    secondaryEndgun: flangedSideSecondaryEndGun
                };
                if (getSpansWithoutEndOfSystem(system, SideEnum.Flex).length) {
                    // flex side:
                    flexSideComponents = {
                        eos: {
                                type: 'endOfSystemType',
                                side: SideEnum.Flex,
                                // title: "Flex Side"
                        } as IEndOfSystemTypeChoice,
                        primaryEndgun: flexSidePrimaryEndGun,
                        secondaryEndgun: flexSideSecondaryEndGun
                    };
                }
                break;
            case SystemTypes.CenterPivot:
                flangedSideComponents = {
                    eos: {
                        type: 'endOfSystemType',
                        side: SideEnum.Flanged,
                        // title: "Flanged Side"
                    } as IEndOfSystemTypeChoice,
                    primaryEndgun: flangedSidePrimaryEndGun,
                    secondaryEndgun: flangedSideSecondaryEndGun
                };
                break;        
            default:
                break;
        }

        if (!flangedSideComponents) return null;

        return (
            <Stack sx={{ margin: 1 }} spacing={2}>

                <EndOfSystemForSide
                    systemId={systemId}
                    layoutId={layoutId}
                    dbPrj={dbPrj}
                    authState={authState}
                    side={SideEnum.Flanged}
                    components={flangedSideComponents}
                    system={system}
                />

                {/* Flex Side */}
                {
                    flexSideComponents && (
                        <EndOfSystemForSide
                            systemId={systemId}
                            layoutId={layoutId}
                            dbPrj={dbPrj}
                            authState={authState}
                            side={SideEnum.Flex}
                            components={flexSideComponents}
                            system={system}
                        />
                    )
                }
            </Stack>
        )
    }
}

export default endOfSystemTab;