import { FormLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import { FC } from "react";
import { IDealerMapFillColor } from ".";
import { isCustomizableStyle, mergeMapStyles } from "../../LayoutMap/mapSettingsMerger";
import DealerSettingsColorPickerRow from "./DealerSettingsColorPickerRow";

interface Props {
    dealerColors: IDealerMapFillColor[];
    addOrUpdateDealerColor: (color: IDealerMapFillColor) => void;
}

const ns = "dealer-settings.map-settings-options.";
const subns = "map-draw-features.";

const DealerSettingsColorPicker: FC<Props> = (props) => {
    let mergedColors = mergeMapStyles(props.dealerColors).filter(isCustomizableStyle);

    mergedColors.sort((a,b) => {
        let strA = i18next.t(ns + subns + a.id);
        let strB = i18next.t(ns + subns + b.id);
        if (strA > strB) return 1;
        if (strB > strA) return -1;
        return 0;
    });

    return (<>
            <FormLabel>{i18next.t(ns + "map-feature-colors")}</FormLabel>
            <TableContainer>
                <Table sx={{maxWidth: 500}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18next.format(i18next.t('description'), 'capitalize')}</TableCell>
                            <TableCell>{i18next.format(i18next.t('color'), 'capitalize')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        mergedColors.map(x => {
                            let currentdmfc: IDealerMapFillColor = {
                                id: x.id
                            }

                            if (x.paint["fill-color"]){
                                currentdmfc.fillColor = x.paint["fill-color"];
                            }
                            if (x.paint["line-color"]){
                                currentdmfc.lineColor = x.paint["line-color"];
                            }
                            if (x.paint["text-color"]){
                                currentdmfc.textColor =  x.paint["text-color"];
                            }
                            if (x.paint["text-halo-color"]){
                                currentdmfc.textHaloColor = x.paint["text-halo-color"];
                            }
                            if (x.paint["circle-color"]){
                                currentdmfc.circleColor =  x.paint["circle-color"];
                            }

                            return <DealerSettingsColorPickerRow dealerColor={currentdmfc} addOrUpdateDealerColor={(dc) => {
                                props.addOrUpdateDealerColor(dc);
                            }}/>;
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </>        
    );
};

export default DealerSettingsColorPicker;