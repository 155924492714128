import AddIcon from '@mui/icons-material/Add';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { Feature } from '@turf/turf';
import i18next from "i18next";
import * as React from "react";
import { FC } from "react";
import { IMapFeaturePermissions } from "../mapFeaturePermissions";
import AddAnnotationDialog, { ICloseAnnotationResponse } from "./AddAnnotationDialog";
import AddBoundaryDialog, { ICloseBoundaryResponse } from "./AddBoundaryDialog";
import AddObstacleDialog, { ICloseObstacleResponse } from "./AddObstacleDialog";
import AddSystemDialog, { ICloseSystemResponse } from './AddSystemDialog';

type IMapSpeedDialActionResponse = 
    ICloseAnnotationResponse 
    | ICloseBoundaryResponse
    | ICloseObstacleResponse
    | ICloseSystemResponse;

interface Props {
    onAction: (action: IMapSpeedDialActionResponse) => void;
    hasFieldBoundary: boolean;
    permissions: IMapFeaturePermissions;
    
    projectId: string;
    layoutId: string;
    renderAdditionalFeatures: (features: Feature[]) => void;
}

enum EDialog {
    NONE, ANNOTATION, BOUNDARY, OBSTACLE, SYSTEM
}

const MapSpeedDialAddFeature: FC<Props> = (props) => {
    //disable hover open/close for speeddial
    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState<EDialog>(EDialog.NONE);


    return (
        <>
            <SpeedDial
                ariaLabel="Add feature to layout"
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                open={open}
                onClick={() => {setOpen(!open)}}
                onMouseEnter={() => {}}
            >
                {
                    props.hasFieldBoundary && props.permissions.editBoundaries && (
                        <SpeedDialAction
                            key="newSystem"
                            icon={<AddIcon />}
                            tooltipTitle={<span>{i18next.format(i18next.t('system'), 'capitalize')}</span>}
                            tooltipOpen={true}
                            onClick={() => setOpenDialog(EDialog.SYSTEM)}
                        />
                    )
                }
                <SpeedDialAction
                    key="newAnnotation"
                    icon={<AddIcon />}
                    tooltipTitle={<span>{i18next.format(i18next.t('annotation'), 'capitalize')}</span>}
                    tooltipOpen={true}
                    onClick={() => setOpenDialog(EDialog.ANNOTATION)}
                />
                {
                    props.permissions.editObstacles && (
                        <SpeedDialAction
                            key="newObstacle"
                            icon={<AddIcon />}
                            tooltipTitle={<span>{i18next.format(i18next.t('obstacle'), 'capitalize')}</span>}
                            tooltipOpen={true}
                            onClick={() => setOpenDialog(EDialog.OBSTACLE)}
                        />
                    )
                }
                {
                    props.permissions.editBoundaries && (
                        <SpeedDialAction
                            key="newBoundary"
                            icon={<AddIcon />}
                            tooltipTitle={<span>{i18next.format(i18next.t('boundary'), 'capitalize')}</span>}
                            tooltipOpen={true}
                            onClick={() => setOpenDialog(EDialog.BOUNDARY)}
                        />
                    )
                }
            </SpeedDial>
            
            <AddAnnotationDialog
                open={openDialog === EDialog.ANNOTATION}
                onClose={(action) => {
                    setOpenDialog(EDialog.NONE);
                    props.onAction(action);
                }}
                onCancel={() => setOpenDialog(EDialog.NONE)}
            />
            
            <AddBoundaryDialog
                open={openDialog === EDialog.BOUNDARY}
                onClose={(action) => {
                    setOpenDialog(EDialog.NONE);
                    props.onAction(action);
                }}
                onCancel={() => setOpenDialog(EDialog.NONE)}
                hasFieldBoundary={props.hasFieldBoundary}
            />
            
            <AddObstacleDialog
                open={openDialog === EDialog.OBSTACLE}
                onClose={(action) => {
                    setOpenDialog(EDialog.NONE);
                    props.onAction(action);
                }}
                onCancel={() => setOpenDialog(EDialog.NONE)}
            />
            
            <AddSystemDialog
                open={openDialog === EDialog.SYSTEM}
                onClose={(action) => {
                    setOpenDialog(EDialog.NONE);
                    props.onAction(action);
                }}
                onCancel={() => setOpenDialog(EDialog.NONE)}
                renderAdditionalFeatures={props.renderAdditionalFeatures}
                projectId={props.projectId}
                layoutId={props.layoutId}
            />
        </> 
    )
}

export default MapSpeedDialAddFeature;