import { Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import IBoolean from "rdptypes/roe/IBoolean";
import * as React from "react";
import { FC, useContext } from "react";
import { createNewMultiAction } from "../../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../auth/AuthCtx";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import IDbProject from "../../../db/IDbProject";
import { forceRoeDisabled } from "../../../helpers/configrationConstants";
import { getValue } from "../../../helpers/objectPathResolver";
import { translateUnit } from "../../../helpers/translation";
import IComponentRenderContext from "./IComponentRenderContext";

interface Props {
    cmp: IBoolean,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    parentGroupLabel: string | null;
    ctx: IComponentRenderContext;
    small?: boolean;
};

const BooleanRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const trueValue = props.cmp.trueValue !== undefined ? props.cmp.trueValue : true;
    const falseValue = props.cmp.falseValue !== undefined ? props.cmp.falseValue : false;

    const fieldPath = props.ctx.fieldRoot + props.cmp.fieldPath;
    const isChecked = getValue(system, fieldPath) === trueValue;

    const fvr = props.ctx.vws.validationResult?.getField(fieldPath);

    const disabled = props.cmp.validate !== false
      && fvr
      && !fvr.badValues.has(isChecked ? trueValue : falseValue)
      && fvr.badValues.has(isChecked ? falseValue : trueValue);
    let toolTipText = (fvr?.badValues.get(isChecked ? falseValue : trueValue)?.reasons ?? []).join(", ");
    if (toolTipText !== "") {
        toolTipText = "Selecting this option would introduce validation errors: " + toolTipText;
    }
    
    const settings = React.useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;

    const label = <Typography style={{ fontSize: 14 }}>{translateUnit(props.cmp, displaySettings)}</Typography>;
    const labelWithTooltip = toolTipText !== "" ? <Tooltip title={toolTipText}>{label}</Tooltip> : label;

    let checked = getValue(system, fieldPath) === trueValue;
    if (typeof (checked) === "undefined") {
        checked = false;
    }


    return (
        <>
            <FormControlLabel control={<Checkbox
                disabled={forceRoeDisabled && disabled}
                style={{...(props.small ? {paddingLeft: 10, paddingRight: 0} : {
                    padding: 0, paddingLeft: 10, paddingRight: 5, marginLeft: props.parentGroupLabel === null ? 10 : 0,
                }),
                color: (!forceRoeDisabled && disabled) ? "lightgray" : undefined,
                backgroundColor: props.cmp.validate !== false && fvr?.severity ? '#fdeded' : 'unset'}}
                checked={checked}
                title={toolTipText}
                onChange={(ev) => props.ctx.pushActionAndImproveScores(
                    createNewMultiAction(props.ctx.changeFieldRoots.flatMap(fieldRoot => {
                        const actions = [ 
                            createNewUpdateSystemPropertyAction(
                                props.layoutId,
                                props.systemId,
                                fieldRoot + props.cmp.fieldPath,
                                ev.target.checked ? trueValue : falseValue,
                                authState,
                            )
                        ];
                        const hookResult = props.cmp.hook?.(ev.target.checked, system) || [];
                        for (const r of hookResult) {
                            actions.push(
                                createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    fieldRoot + r.fieldPath,
                                    r.value,
                                    authState,
                                )
                            )
                        }
                        return actions;
                    }), 
                    authState),
                    props.ctx.changeFieldRoots.map(fieldRoot => fieldRoot + props.cmp.fieldPath), props.cmp.improveScores || false)}
            />}
                style={{marginBottom: props.cmp.marginBottom ? 15: 0,
                    color: (!forceRoeDisabled && disabled) ? "lightgray" : props.cmp.validate !== false && fvr?.severity ? "#d32f2f" : undefined, marginRight: props.small ? 0 : 'unset'}}
                label={labelWithTooltip} />
        </>
    );
};

export default BooleanRenderer;