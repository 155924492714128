import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
    Box,
    Collapse,
    IconButton,
    ListItem,
} from "@mui/material";
import * as React from "react";

interface Props {
    parentItem: React.ReactNode;
    className?: string;
}
const NestedMenuListItem: React.FC<React.PropsWithChildren<Props>> = ({
    parentItem, children, className
}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <ListItem
                className={className}
                secondaryAction={
                    <IconButton edge="end" onClick={() => setOpen(!open)}>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                }
                disablePadding
            >
                {parentItem}
            </ListItem>

            <Collapse in={open} timeout="auto"  className={className}>
                <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                    {children}
                </Box>
            </Collapse>
        </>
    );
};

export default NestedMenuListItem;