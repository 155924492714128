
interface EndBoomOKDto_PWA {
    IsSpanExt: boolean;
    SpanLength: number;
}

// This function is adabted and simplified from the EndBoomOK function for use in the system optimizer functions
export const isEndboomOk = (EndBoomLength: number, data: EndBoomOKDto_PWA): boolean => {
    switch (EndBoomLength) {
        case 1:
        case 13:
        case 23:
        case 32:
        case 42:
        case 51:
        case 61:
            return true;
        case 85:
            switch (data.SpanLength) {
                case 175:
                    if (data.IsSpanExt) {
                        return false;
                    }
                    return true;
                case 194:
                case 213:
                    return true;
                default:
                    return false;
            }
        case 105:
            switch (data.SpanLength) {
                case 213:
                    return true;
                default:
                    return false;
            }
    }
    return false;
}