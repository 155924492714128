import { ISystemBase, WrapAroundSpanTypes } from "../project/ISystemBase.AutoGenerated";
import { ITower } from "../project/Types";

export enum ECircleDirection { FWD = "FWD", REV = "REV" };
export const getWrapAngle = (system: ISystemBase, tower: ITower, direction: ECircleDirection) => {
    if (!system.Circle.CenterPivot.isPartialPivot || !tower) return undefined;
    if (!tower.WrapAroundSpan || tower.WrapAroundSpan === WrapAroundSpanTypes.None) {
        return undefined;
    }
    else {
        const wrapAngle = direction === ECircleDirection.FWD
            ? tower.clockwiseWrapAngleRelativeToPreviousSpanDegrees || 0
            : tower.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees || 0;
        return wrapAngle;
    }
}