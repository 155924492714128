// ''' <summary>
// ''' VRI_ValveBoxes

import { MAXVRIValveBoxes, MaxVRIZones } from "./CommonConstants";
import * as comf from "./CommonFunctions";
import VRI_ValveBox from "./VRIClass.VRI_ValveBoxes";
import VRI_Zones from "./VRIClass.VRI_Zones";

// ''' </summary>
export default class VRI_ValveBoxes {
    private m_VRIValveBoxes: VRI_ValveBox[] = new Array(MAXVRIValveBoxes);

    public QuarterInchTubingLength: number = 0;

//     Default Public ReadOnly Property Item(ByVal Index As Integer) As VRI_ValveBox
//         Get
//             Return m_VRIValveBoxes(Index)
//         End Get
//     End Property

    constructor(Zones: VRI_Zones) {
        for (let i = 0; i < MAXVRIValveBoxes; i++) {
            this.m_VRIValveBoxes[i] = new VRI_ValveBox();
        }

        for (let i = 0; i < MaxVRIZones; i++) {
            let zone = Zones.Get(i);

            if (zone.InUse && zone.Controlled) {
                let vbi = zone.ValveBoxIndex();
                if (this.m_VRIValveBoxes[vbi].StartZone === 0) this.m_VRIValveBoxes[vbi].StartZone = i;
                this.m_VRIValveBoxes[vbi].EndZone = i;
                this.m_VRIValveBoxes[vbi].TubingLength += zone.Length + 3 * zone.DeviceCount;
            }
        }

        let iValveBoxCount: number = Zones.ValveBoxCount;

        for (let j = 1; j <= iValveBoxCount; j++) {
            let ControlTubingLength: number = 0;
            for (let i = 1; i <= Zones.TowerCount; i++) {
                let ProposedControlTubingLength: number = this.ComputeControlTubingLength(
                    Zones, 
                    Zones.TowerLocations[i], 
                    this.m_VRIValveBoxes[j].StartZone, 
                    this.m_VRIValveBoxes[j].EndZone
                )
                // 'note for below:  Putting the valve box on last tower of pivot means it will sub out the GPS parts, saving money.  So 600 more feet of tube is OK in that case
                if (
                    comf.FloatsAreEqual(ControlTubingLength, 0) || 
                    ProposedControlTubingLength < ControlTubingLength || 
                    ((j === iValveBoxCount) && !Zones.Maxi && i === Zones.TowerCount && ProposedControlTubingLength - 600 < ControlTubingLength)
                ) {
                    this.m_VRIValveBoxes[j].TowerIndex = i;
                    this.m_VRIValveBoxes[j].Tower = Zones.TowerMap[i];
                    this.m_VRIValveBoxes[j].TowerLocation = Zones.TowerLocations[i];
                    ControlTubingLength = ProposedControlTubingLength;
                }
            }
            this.m_VRIValveBoxes[j].TubingLength += ControlTubingLength;
            this.QuarterInchTubingLength += this.m_VRIValveBoxes[j].TubingLength;
        }

    }

    private /* Shared */ ComputeControlTubingLength(Zones: VRI_Zones, ValveLocation: number, StartZone: number, EndZone: number): number {
        let l: number = 0;
        for (let i = StartZone; i <= EndZone; i++) {
            // 'A tower located within a zone won't need extra control tubing to get to the zone tubing.
            if (ValveLocation < Zones.Get(i).StartingLocation || Zones.Get(i).EndingLocation < ValveLocation) {
                let DistanceToStart: number = Math.abs(ValveLocation - Zones.Get(i).StartingLocation);
                let DistanceToEnd: number = Math.abs(ValveLocation - Zones.Get(i).EndingLocation);
                if (DistanceToStart > DistanceToEnd) {
                    l += DistanceToEnd;
                }
                else {
                    l += DistanceToStart;
                }
            }
        }
        return l;
    }

    public Get() {
        return this.m_VRIValveBoxes;
    }
}