import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select from '@mui/material/Select';
import { t } from "i18next";
import * as React from "react";
import { disabledColor, pageStyles } from "../roe/styles";

interface Props {
    disabled?: boolean;
    setMaxNumberOfDrops: (n: number) => void;
    nDrops: number;
}

const ns = "optimization-dialog.";
const subns = "lateral.";

export const DropSpanChoiceControl: React.FC<Props> = ({
    disabled, nDrops, setMaxNumberOfDrops
}) => {
    
    let formInputLabelStyle = {
        ...pageStyles.formInputLabelStyle
    };
    if (disabled) {
        formInputLabelStyle['color'] = disabledColor;
    }
    
    return (   
        <FormControl fullWidth variant="standard" >
            <InputLabel shrink={true} style={{...formInputLabelStyle}}>{t(ns + subns + "max-drop-number")}</InputLabel>
            <Select               
                value={nDrops || 0}
                onChange={ev => setMaxNumberOfDrops(ev.target.value as number)}
                disabled={disabled}
            >
                {
                    [1,2,3].map(x => (
                        <MenuItem key={x} value={x}>{x}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}