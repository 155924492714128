import WaterDrop from '@mui/icons-material/WaterDrop';
import { Tooltip, Typography } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";

interface Props{
    irrigatedAreaString: string;
}

const IrrigatedArea: React.FC<Props> = (props) => {
    if(props.irrigatedAreaString === 'none'){
        return <></>;
    }
    
    return (
        <Tooltip title={i18next.format(t('coverage-area'), 'capitalize-each')} placement="bottom">
            <div className="icon-group">
                <WaterDrop />
                <Typography className="no-text-wrap" variant="subtitle2" component="div" px={0}>
                    {props.irrigatedAreaString}
                </Typography>
            </div>
        </Tooltip>
        
    );
};

export default IrrigatedArea;