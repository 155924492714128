import { ImportExportProject } from "../common/interfaces";
import { importRapFile } from "./rapFile";
import { importShapeFile } from "./shapeFile";

export enum ImportFileType { 
    RAP = "rap",
    ShapeFile = "shapefile"
}

interface IArgs {
    blob: Blob;
    fileType: ImportFileType;
}

export const importFile = ({ blob, fileType }: IArgs): Promise<ImportExportProject> => {
    switch (fileType) {
        case ImportFileType.RAP: {
            return importRapFile({ blob });
        }
        case ImportFileType.ShapeFile: {
            return importShapeFile({ blob });
        }
        default:
            throw new Error("File type not supported: " + ImportFileType[fileType]);
    }
}