import IAction from "rdptypes/IAction";
import { createNewMultiAction } from "../../../../actions/MultiAction";
import IAuthState from "../../../../auth/IAuthState";
import { IDrawUpdateExtEvent_DynamicLateralOptimizationResult } from "../../../../components/Map/DynamicLateralOptimizationMode";
import IDbProject from "../../../../db/IDbProject";
import { createLateralActionsFromOptimizedSystem } from "../../../../helpers/optimizedSystemHelpers/createLateralActionsFromOptimizedSystem";

export const handleDynamicLateralResults = (
    event: IDrawUpdateExtEvent_DynamicLateralOptimizationResult,
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
    systemDefaults?: ((layoutId: string, systemId: string, authState: IAuthState) => IAction[])
) => {
    const { actions } = createLateralActionsFromOptimizedSystem({
        systemDefaults,
        systemType: event.definition.systemType,
        optimizedSystem: event.definition.optimizedSystem,
        layoutId,
        canalFeed: event.definition.canalFeed,
        authState,
        endFeed: event.definition.endFeed
    })
    if (actions.length) {
        dbPrj.pushAction(
            createNewMultiAction(actions, authState)
        )
    }
}