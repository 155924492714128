import * as React from "react";
import { Outlet, useParams } from "react-router-dom";
import { GeometryProvider } from "../../GeometryHelpers/GeometryProvider";
import DbCtx from "../../db/DbCtx";

const Layout = () => {
    const params = useParams();
    const dbCtx = React.useContext(DbCtx);
    const  { projectId, layoutId } = params;
    return (
        <GeometryProvider
            project={dbCtx.projects[projectId]?.state}
            layoutId={layoutId}
        >
            <Outlet />
        </GeometryProvider>
    );
}

export default Layout;