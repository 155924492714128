import { DialogActions, DialogContent, MenuItem, Select, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Point, point } from "@turf/turf";
import * as React from "react";

export interface SimpleDialogProps {
    crntCenter: Point;
    onCancel: () => void;
    onConfirm: (updatedCenter: Point) => void;
}
type LatDir = "N" | "S";
type LonDir = "E" | "W";
const EditPivotCenterDialog = ({ crntCenter, onConfirm, onCancel }: SimpleDialogProps) => {
    const [ coord, setCoord ] = React.useState(() => {
        const latitudeText = crntCenter.coordinates[1].toString().replace("-", "");
        const latSign: LatDir = crntCenter.coordinates[1] < 0 ? "S" : "N";
        const longitudeText = crntCenter.coordinates[0].toString().replace("-", "");
        const lonSign: LonDir = crntCenter.coordinates[0] < 0 ? "W" : "E";
        return {
            latitudeText, latSign, longitudeText, lonSign
        }
    })

    const latitudeNumber = parseFloat(coord.latitudeText) * (coord.latSign === "N" ? 1 : -1);
    const longitudeNumber = parseFloat(coord.longitudeText) * (coord.lonSign === "E" ? 1 : -1);

    const handleCancel = () => {
        onCancel();
    };
    const handleConfirm = () => {
        onConfirm(point([longitudeNumber, latitudeNumber]).geometry);
    };

    const latitudeWarning = !isFinite(latitudeNumber) || latitudeNumber > 90 || latitudeNumber < -90;
    const longitudeWarning = !isFinite(longitudeNumber) || longitudeNumber > 180 || longitudeNumber < -180;

    const latitudeHelperText = latitudeWarning ? "Latitude must be a number between -90 and 90" : " ";
    const longitudeHelperText = longitudeWarning ? "Longitude must be a number between -180 and 180" : " ";

    const canConfirm = () => {
        if (latitudeNumber === crntCenter.coordinates[1] && longitudeNumber === crntCenter.coordinates[0]) {
            return false;
        }
        if (latitudeWarning || longitudeWarning) {
            return false;
        }
        return true;
    };

    return (
        <Dialog onClose={handleCancel} open={true} maxWidth="xs" fullWidth PaperProps={{ sx: { minHeight: 320 } }}>
            <DialogTitle>Set center pivot coordinates</DialogTitle>

            <DialogContent>
                <Stack direction={"column"} gap={5} padding={1}>
                    <Stack direction={"row"} gap={2} maxHeight={56}>
                        <TextField
                            label="Latitude (°)"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={coord.latitudeText}
                            onChange={(ev) => {
                                setCoord({
                                    ...coord,
                                    latitudeText: ev.target.value
                                });
                            }}
                            helperText={latitudeHelperText}
                            fullWidth
                            error={latitudeWarning}
                        />
                        <Select
                            sx={{minWidth: 65}} 
                            value={coord.latSign}
                            onChange={(ev) => {
                                setCoord({
                                    ...coord,
                                    latSign: ev.target.value as LatDir
                                });
                            }}
                        >
                            <MenuItem value="N">N</MenuItem>
                            <MenuItem value="S">S</MenuItem>
                        </Select>
                    </Stack>
                    <Stack direction={"row"} gap={2} maxHeight={56}>
                        <TextField
                            label="Longitude (°)"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={coord.longitudeText}
                            onChange={(ev) => {
                                setCoord({
                                    ...coord,
                                    longitudeText: ev.target.value
                                });
                            }}
                            helperText={longitudeHelperText}
                            fullWidth
                            error={longitudeWarning}
                        />
                        <Select 
                            sx={{minWidth: 65}} 
                            value={coord.lonSign}
                            onChange={(ev) => {
                                setCoord({
                                    ...coord,
                                    lonSign: ev.target.value as LonDir
                                });
                            }}
                        >
                            <MenuItem value="E">E</MenuItem>
                            <MenuItem value="W">W</MenuItem>
                        </Select>
                    </Stack>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleConfirm} disabled={!canConfirm()}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditPivotCenterDialog;
