import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { SideEnum, getSide } from 'rdptypes/helpers/SideEnum';
import { ISpan } from 'rdptypes/project/Types';
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';
import { renumberSpanAndTowers } from './helpers/spans';

const actionTypeId = "SwapSpanTower";

interface ActionData extends IActionData {
    layoutId: string;
    systemId: string;
    side: SideEnum;
    spanNumber1: number;
    spanNumber2: number;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const sys = state.layouts[data.layoutId].systems[data.systemId];
    const side = getSide(sys, data.side);

    const spans = side.Span;

    let endBoom: ISpan | undefined;
    if (spans.length !== 0 && spans[spans.length - 1].EndBoom) {
        endBoom = spans.pop();
    }

    let swingArm: ISpan | undefined;
    if (spans.length !== 0 && spans[spans.length - 1].SwingArm) {
        swingArm = spans.pop();
    }

    const towers = side.Tower;

    const spanAndTowerIndex1 = spans.findIndex(x => x.SpanNumber === data.spanNumber1);
    const spanAndTowerIndex2 = spans.findIndex(x => x.SpanNumber === data.spanNumber2);

    if (spanAndTowerIndex1 === -1 || spanAndTowerIndex2 === -1) return;

    const tempSpan = structuredClone(spans[spanAndTowerIndex1]);
    const tempTower = structuredClone(towers[spanAndTowerIndex1]);
    spans[spanAndTowerIndex1] = structuredClone(spans[spanAndTowerIndex2]);
    towers[spanAndTowerIndex1] = structuredClone(towers[spanAndTowerIndex2]);
    spans[spanAndTowerIndex2] = tempSpan;
    towers[spanAndTowerIndex2] = tempTower;

    if (swingArm) {
        spans.push(swingArm);
    }
    if (endBoom) {
        spans.push(endBoom);
    }

    renumberSpanAndTowers(side);
    
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});

export const createSwapSpanTowerAction = (
    layoutId: string,
    systemId: string,
    side: SideEnum,
    spanNumber1: number,
    spanNumber2: number,
    authState: IAuthState) => createAction(
        actionTypeId,
        {
            layoutId,
            systemId,
            side,
            spanNumber1,
            spanNumber2
        } as ActionData,
        authState);