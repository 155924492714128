import { Units, convertLength } from "@turf/turf";

// BEGIN NOTE: 
//  These strings are kept consistant with turfjs so they can be used like for like with turfjs
export type DisplayLengthUnits = Extract<Units, 'meters' | 'feet' | 'centimeters' | 'inches' | 'millimeters'>;
export const DisplayLengthUnitsMap = { 
    // NOTE: key name must as found in DisplayLengthUnits
    meters: {
        longName: 'meters',
        shortName: 'm',
        value: 'meters' as DisplayLengthUnits,
        symbol: "m"
    }, 
    feet: {
        longName: 'feet',
        shortName: 'ft',
        value: 'feet' as DisplayLengthUnits,
        symbol: "'"
    }, 
    centimeters: {
        longName: 'centimeters',
        shortName: 'cm',
        value: 'centimeters' as DisplayLengthUnits,
        symbol: "cm"
    }, 
    millimeters: {
        longName: 'millimeters',
        shortName: 'mm',
        value: 'millimeters' as DisplayLengthUnits,
        symbol: "mm"
    }, 
    inches: {
        longName: 'inches',
        shortName: 'in',
        value: 'inches' as DisplayLengthUnits,
        symbol: '"'
    }
} as const;
// END NOTE


export class DisplayLengthUnitBuilder {
    static shortName = (unit: DisplayLengthUnits) => DisplayLengthUnitsMap[unit].shortName;
    static longName = (unit: DisplayLengthUnits) => DisplayLengthUnitsMap[unit].longName;
    static symbol = (unit: DisplayLengthUnits) => DisplayLengthUnitsMap[unit].symbol;
    static convert = (value: number, from: DisplayLengthUnits, to: DisplayLengthUnits) => {
        if (from === to) return value;
        if (value < 0) {
            // turf cannot convert negative values!!
            return -convertLength(-value, from, to);
        }
        else {
            return convertLength(value, from, to);
        }
    }

    private _value: number;
    private _unit: DisplayLengthUnits;
    private _formattedString = "";

    constructor(value: number, unit: DisplayLengthUnits) {
        this._value = value;
        this._unit = unit;
    }

    convert = (finalUnit: DisplayLengthUnits) => {
        this._value = DisplayLengthUnitBuilder.convert(this._value, this._unit, finalUnit);
        this._unit = finalUnit;
        return this;
    }

    appendValue = (toFixed?: number) => {
        if (this._unit === "inches" && this._value > 0 && this._value - Math.floor(this._value) === 0.625) {
            this._formattedString += `${Math.floor(this._value)} 5/8`;
            return this;
        }

        let tempValueString: string;
        if (toFixed === undefined) {
            tempValueString = this._value.toString();
        }
        else {
            tempValueString = this._value.toFixed(toFixed);
        }
        this._formattedString += tempValueString;
        return this;
    }

    appendString = (value: string) => {
        this._formattedString += value;
        return this;
    }

    appendShortName = () => {
        this._formattedString += DisplayLengthUnitBuilder.shortName(this._unit);
        return this;
    }
    
    appendLongName = () => {
        this._formattedString += DisplayLengthUnitBuilder.longName(this._unit);
        return this;
    }
    
    appendSymbol = () => {
        this._formattedString += DisplayLengthUnitBuilder.symbol(this._unit);
        return this;
    }

    toString = () => {
        return this._formattedString;
    }

}
