import { SideEnum } from "../../rdptypes/helpers/SideEnum";
import { IRule } from "../../rdptypes/roe/IRule";
import CenterPivotForm from "../roe_migration/AdditionalValidation/CenterPivotForm";
import ControlPanelForm from "../roe_migration/AdditionalValidation/ControlPanelForm";
import EndOfSystemForm from "../roe_migration/AdditionalValidation/EndOfSystemForm";
import GuidanceForm from "../roe_migration/AdditionalValidation/GuidanceForm";
import HoseFeedForm from "../roe_migration/AdditionalValidation/HoseFeedForm";
import KwikTowForm from "../roe_migration/AdditionalValidation/KwikTowForm";
import MainlineValvesUnitForm from "../roe_migration/AdditionalValidation/MainlineValvesUnitForm";
import OptionsForm from "../roe_migration/AdditionalValidation/OptionsForm";
import SACRetroForm from "../roe_migration/AdditionalValidation/SACRetroForm";
import SpansForm from "../roe_migration/AdditionalValidation/SpansForm";
import SpansSingleSpanForm from "../roe_migration/AdditionalValidation/SpansSingleSpanForm";
import SprinklersBoomBackForm from "../roe_migration/AdditionalValidation/SprinklersBoomBackForm";
import SprinklersConfigForm from "../roe_migration/AdditionalValidation/SprinklersConfigForm";
import SprinklersKometPackageForm from "../roe_migration/AdditionalValidation/SprinklersKometPackageForm";
import SprinklersKometRegulatorForm from "../roe_migration/AdditionalValidation/SprinklersKometRegulatorForm";
import SprinklersNelsonPackageForm from "../roe_migration/AdditionalValidation/SprinklersNelsonPackageForm";
import SprinklersNelsonRegulatorForm from "../roe_migration/AdditionalValidation/SprinklersNelsonRegulatorForm";
import SprinklersPackagesForm from "../roe_migration/AdditionalValidation/SprinklersPackagesForm";
import SprinklersSennPackageForm from "../roe_migration/AdditionalValidation/SprinklersSennPackageForm";
import SprinklersSennRegulatorForm from "../roe_migration/AdditionalValidation/SprinklersSennRegulatorForm";
import SwingArmForm from "../roe_migration/AdditionalValidation/SwingArmForm";
import TowersForm from "../roe_migration/AdditionalValidation/TowersForm";
import TowersSingleTowerForm from "../roe_migration/AdditionalValidation/TowersSingleTowerForm";
import PartsAssembler from "../roe_migration/PartsAssembler";
import QuoteClass, { WarningLevels, classSelectors } from "../roe_migration/QuoteClass";
import * as sysf from "../roe_migration/SystemFunctions";

export default {
    rules: [{
        apply: (sys, user, grower, quick = false) => {
            const quote = new QuoteClass(sys);
            const pa = new PartsAssembler(quote);
            for (const cs of classSelectors) {
                const c = cs(quote);
                try {
                    c.DataOK(pa);
                    if (!quick && c.DataValid()) {
                        c.WritePO(pa);
                    }
                } catch (ex) {
                    quote.Warnings.Add(`Unhandled exception: ${ex} - ${ex.stack}`,
                        WarningLevels.Critical);
                    console.error(ex);
                }
            }

            try {
                new CenterPivotForm(quote).validate();
                new KwikTowForm(quote).validate();
                new ControlPanelForm(quote).validate();
                new EndOfSystemForm(quote, SideEnum.Flanged).validate();
                if (sysf.IsDualSided(quote.System)) new EndOfSystemForm(quote, SideEnum.Flex).validate();
                new OptionsForm(quote).validate();
                new SwingArmForm(quote).validate();
                new SpansForm(quote, quote.RightSpansClass).validate();
                if (sysf.IsDualSided(quote.System)) new SpansForm(quote, quote.LeftSpansClass).validate();
                new SpansSingleSpanForm(quote, quote.System.FlangedSide).validate();
                if (sysf.IsDualSided(quote.System)) new SpansSingleSpanForm(quote, quote.System.FlexSide).validate();
                new TowersSingleTowerForm(quote, quote.RightTowersClass).validate();
                if (sysf.IsDualSided(quote.System)) new TowersSingleTowerForm(quote, quote.LeftTowersClass).validate();
                new TowersForm(quote, quote.RightTowersClass).validate();
                if (sysf.IsDualSided(quote.System)) new TowersForm(quote, quote.LeftTowersClass).validate();
                new GuidanceForm(quote).validate();
                new HoseFeedForm(quote).validate();
                new MainlineValvesUnitForm(quote).validate();
                new SprinklersKometPackageForm(quote).validate();
                new SprinklersSennPackageForm(quote).validate();
                new SprinklersNelsonPackageForm(quote).validate();
                new SprinklersConfigForm(quote).validate();
                new SprinklersPackagesForm(quote).validate();
                new SprinklersNelsonRegulatorForm(quote).validate();
                new SprinklersKometRegulatorForm(quote).validate();
                new SprinklersSennRegulatorForm(quote).validate();
                new SprinklersBoomBackForm(quote).validate();
                new SACRetroForm(quote).validate();
            } catch (ex) {
                quote.Warnings.Add(`Unhandled exception: ${ex} - ${ex.stack}`,
                    WarningLevels.Critical);
                console.error(ex);
            }
            return quote.ruleResults;
        }
    }] as IRule[]
}