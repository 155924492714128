import { WarningSeverity, WarningTypes } from "./SprinklerEngineTypes/Enums";
import { SprinklerEngineWarning } from "./SprinklerEngineTypes/SprinklerEngineWarning";

export default class SprinklerEngineException extends Error {
    constructor(
        public WarningLevel: WarningSeverity,
        public WarningType: WarningTypes,
        public context: string,
        public message: string) {
            super(message);
            Object.setPrototypeOf(this, SprinklerEngineException.prototype);
        }

    public GetWarnings = (): SprinklerEngineWarning[] => [
        {
            WarningSeverity: this.WarningLevel,
            WarningType: this.WarningType,
            Context: this.context,
            Message: this.message
        }
    ];
}