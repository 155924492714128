import { Feature, LineString, lineString } from "@turf/turf";
import { createNewLineAnnotation } from "../../../../actions/NewLineAnnotationAction";
import IAuthState from "../../../../auth/IAuthState";
import IDbProject from "../../../../db/IDbProject";
import { AnnotationType } from "../../../../model/project/IAnnotation";


export const handleCanal = (
    features: Feature[],
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
) => {
    if (features.length !== 1 && features[0].geometry.type !== "LineString") {
        throw new Error(`Unexpected feature created`);
    }
    // canals must be a straight line:
    const incommingLineStringPoints = (features[0] as Feature<LineString>).geometry.coordinates;
    if (incommingLineStringPoints.length < 2) {
        throw new Error(`Unexpected feature created`);
    }
    const line = lineString([
        incommingLineStringPoints[0],
        incommingLineStringPoints[incommingLineStringPoints.length - 1],
    ]).geometry
    dbPrj.pushAction(createNewLineAnnotation(
        layoutId,
        AnnotationType.canal,
        line,
        authState
    ));
}
