
export const primaryColor = "#1976d2";
export const secondaryColor = "#424345";
export const disabledColor = "rgba(0, 0, 0, 0.38)";
export const headerFontSize = "18px";

export const pageStyles = {
    formInputLabelStyle: {
        fontWeight: "bold",
        fontSize: headerFontSize
    },
    cardTitleStyle: { 
        fontSize: 14, 
        fontWeight: "bold", 
        color:  primaryColor,
        marginBottom: 2
    },
    cardStyle: {
        outlined: { padding: 10 },
        notOutlined: {
            padding: 10, border: "none", boxShadow: "none"
        }
    }
};