export enum ERdpFeatureType {
    FIELD_BOUNDARY = "fieldBoundary",
    PIVOT_CENTER_BOUNDARY = "pivotCenterBoundary",
    WET_AREA_BOUNDARY = "wetAreaBoundary",
    SPAN_OBSTACLE = "obstacle",
    WHEEL_OBSTACLE = "wheelObstacle",
    CANAL_ANNOTATION = "canal",
    ELECTRIC_LINE_ANNOTATION = "electricline",
    LABEL_ANNOTATION = "label",
    LINE_ANNOTATION = "line",
    POINT_ANNOTATION = "point",
    PUMP_ANNOTATION = "pump",
    WATER_LINE_ANNOTATION = "waterline",
    CENTER_PIVOT_SYSTEM = "system/pivotCenter",
    LATERAL_SYSTEM = "system/lateral",
    WHEEL_TRACKS = "system/wheelTrack"
}