import * as React from "react";
import { FC, useContext } from 'react';
import DbCtx from '../../../db/DbCtx';
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import DetailedSystemContent from "./DetailedSystemContent";
import SimpleSystemContent from "./SimpleSystemContent";

interface Props {
    projectId: string;
    layoutId: string;
    systemIds: string[];
    type: string;
}

const RDPProposalContent: FC<Props> = React.forwardRef((props) => {
    const {systemIds, projectId, layoutId, type} = props;

    const dbState = useContext(DbCtx);
    const devSettings = useContext(DevSettingsCtx);
    const dbProject = dbState.projects[projectId!];
    const project = dbProject.state;

    let content: JSX.Element;

    let systemIds_: string[] = [];
    systemIds.forEach((systemId) => {
        let system = project.layouts[layoutId].systems[systemId];
        if (system && systemIds_.indexOf(systemId) === -1) systemIds_.push(systemId);//sometimes there are lingering old systems that we have deleted and this breaks render
    });

    if (type === "simple"){
        content = <SimpleSystemContent
            grower={dbState.growers[project.growerId]}
            pwid={{id: projectId, project}}
            layoutId={layoutId}
            systemIds={systemIds_}
            insideFullReport={false}
            showLogoInsteadOfMiniMap={true}
        />;
    }
    else {
        content = <DetailedSystemContent   
            grower={dbState.growers[project.growerId]}
            pwid={{id: projectId, project}}
            layoutId={layoutId}
            systemIds={systemIds_}
        />;
    }

    return <>{content}</>;
});

export default RDPProposalContent;