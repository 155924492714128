import { LineString, Point } from "@turf/turf";
import { MetersProjectionHelper } from "../../projection";
import { Vertex } from "../Core/Vertex";
import { jsts } from "../jstsLib";

export interface PathData {
    radius: number;
    angle: number;
}

export class PointShape {
    public X: number;
    public Y: number;

    constructor(x: number = 0, y: number = 0) {
        this.X = x;
        this.Y = y;        
    }

    public static fromXYArray = (arr: number[]) => {
        return new PointShape(arr[0], arr[1]);
    }

    public static equals(p1: PointShape, p2: PointShape) {
        return (p1.X === p2.X && p1.Y === p2.Y);
    }
}

class LineShape {
    private points: PointShape[];
    constructor(points: PointShape[]) {
        this.points = points;
    }
    public Points(): PointShape[] {
        return this.points;
    }
}

class MultiLine {
    public Lines: LineShape[] = [];
}

export class RingShape {
    verticies: Vertex[];
    constructor(verticies: Vertex[]) {
        this.verticies = verticies;
    }

    public Contains(pointShape: PointShape) {
        const ps = this.verticies.map(v => new jsts.geom.Coordinate(v.X, v.Y))
        const ring = new jsts.geom.GeometryFactory().createLinearRing(ps);
        const p = new jsts.geom.GeometryFactory().createPoint(new jsts.geom.Coordinate(pointShape.X, pointShape.Y));
        const pg = new jsts.geom.GeometryFactory().createPolygon(ring);
        return pg.contains(p);
    }
}

export default class PathUtilities {
    public static FastResampleWheelTrack(
        centerInWGS84: Point,
        track_WGS84: LineString[],
        metersProjectionHelper: MetersProjectionHelper
    ): PathData[] {
        const convertToDegrees = 180.0 / Math.PI;
        const convertToRadians = Math.PI / 180.0;
        const twoPi = 2.0 * Math.PI;
        const metersPerDegreeLatitude = 111000.0;
        const degreeLatitudePerMeter = 1.0 / 111000.0;
        const centerInUTM: PointShape = PointShape.fromXYArray(
            metersProjectionHelper.wgs84ToMeters(
                centerInWGS84.coordinates[0],
                centerInWGS84.coordinates[1]
            )
        );
        const trueNorthPointInUTM: PointShape = PointShape.fromXYArray(
            metersProjectionHelper.wgs84ToMeters(
                centerInWGS84.coordinates[0], 
                centerInWGS84.coordinates[1] + 5000.0 * degreeLatitudePerMeter
            )
        );

        const track: MultiLine = new MultiLine();
        for (const gl of track_WGS84) {
            for (let i = 0; i < gl.coordinates.length - 1; i++) {
                const g1 = gl.coordinates[i];
                const g2 = gl.coordinates[i + 1];
                const p1 = metersProjectionHelper.wgs84ToMeters(g1[0], g1[1])
                const p2 = metersProjectionHelper.wgs84ToMeters(g2[0], g2[1])
                track.Lines.push(
                    new LineShape([
                        PointShape.fromXYArray(p1),
                        PointShape.fromXYArray(p2)
                    ])
                )
            }
        }

        const vertexAngles: number[] = [];
        for (let idx = 0; idx < track.Lines.length; idx++) {
            vertexAngles.push(
                (convertToDegrees * this.FindAzimuth(centerInUTM, track.Lines[idx].Points()[0]) + 360.0) % 360.0
            );
        }

        // First point of the first line
        const startPoint: PointShape = track.Lines[0].Points()[0]; // UTM
        const startAngle: number = vertexAngles[0];
        const startRadius: number = this.DistanceTo(centerInUTM, startPoint) / 0.3048; // feet
        // Last point of the last line
        const endPoint: PointShape = track.Lines[track.Lines.length - 1].Points()[1]; // UTM
        const endAngle: number = ((180.0 / Math.PI) * this.FindAzimuth(centerInUTM, endPoint) + 360.0) % 360.0; // degrees
        const endRadius: number = this.DistanceTo(centerInUTM, endPoint) / 0.3048; // feet
        const isFullCircle: boolean = startAngle === endAngle;
        // Inputs are assumed in meters (center & track are in UTM).
        let reportedAngleInDegrees: number = 0.0; // degrees
        let reportedAngleInRadians: number = 0.0;
        // Normalize the North point relative to the center
        const normalizedNorthPointInUTM: PointShape = new PointShape(
            trueNorthPointInUTM.X - centerInUTM.X,
            trueNorthPointInUTM.Y - centerInUTM.Y
        );
        const data: PathData[] = [];

        try {
            for (let idx = 1; idx <= 3600; idx++) {
                reportedAngleInDegrees = idx * 0.1;
                reportedAngleInRadians = reportedAngleInDegrees * convertToRadians;

                const normalizedRotatedPointInUTM: PointShape = new PointShape();
                // ***NOTE: The rotation is counterclockwise so I am adjusting the angle to compensate
                normalizedRotatedPointInUTM.X =
                    normalizedNorthPointInUTM.X * Math.cos(twoPi - reportedAngleInRadians) -
                    normalizedNorthPointInUTM.Y * Math.sin(twoPi - reportedAngleInRadians);
                normalizedRotatedPointInUTM.Y =
                    normalizedNorthPointInUTM.X * Math.sin(twoPi - reportedAngleInRadians) +
                    normalizedNorthPointInUTM.Y * Math.cos(twoPi - reportedAngleInRadians);
                const rotatedPointInUTM: PointShape = new PointShape(
                    normalizedRotatedPointInUTM.X + centerInUTM.X,
                    normalizedRotatedPointInUTM.Y + centerInUTM.Y
                );
                const calculatedAngleInDegrees: number = this.FindAzimuth(centerInUTM, rotatedPointInUTM) * convertToDegrees;

                const linePts: PointShape[] = [];
                linePts.push(centerInUTM);
                linePts.push(rotatedPointInUTM);
                const lineFromCenter: LineShape = new LineShape(linePts);
                const intersections: PointShape[] = [];
                let start = vertexAngles[0];
                let end = Number.NaN;
                for (let idx1 = 0; idx1 < vertexAngles.length; idx1++) {
                    if (idx1 === vertexAngles.length - 1) end = endAngle;
                    else end = vertexAngles[idx1 + 1];

                    if (start > end) {
                        if (start <= calculatedAngleInDegrees || end >= calculatedAngleInDegrees) {
                            const hit: PointShape = this.GetIntersection(lineFromCenter, track.Lines[idx1]);
                            if (hit !== null && !intersections.find((x) => PointShape.equals(hit, x))) intersections.push(hit);
                        }
                    } else {
                        if (start <= calculatedAngleInDegrees && end >= calculatedAngleInDegrees) {
                            const hit: PointShape = this.GetIntersection(lineFromCenter, track.Lines[idx1]);
                            if (hit !== null && !intersections.find((x) => PointShape.equals(hit, x))) intersections.push(hit);
                        }
                    }
                    start = end;
                }

                let currentData: PathData = null;
                // Don't forget about converting the radius to feet.
                if (intersections.length === 0) {
                    currentData = this.InterpolatePathData(
                        reportedAngleInDegrees,
                        startAngle,
                        endAngle,
                        calculatedAngleInDegrees,
                        startRadius,
                        endRadius
                    );
                } else if (intersections.length === 1) {
                    currentData = {
                        angle: reportedAngleInDegrees,
                        radius: this.DistanceTo(centerInUTM, intersections[0]) / 0.3048
                    }
                }
                if (currentData === null) return null;
                else data.push(currentData);
            }
        } catch (ex) {
            // pass
        }

        return data;
    }

    public static LoadPathArchive(ab: ArrayBuffer): PathData[] {
        const dv = new DataView(ab);
        const len = ab.byteLength / 4;
        const pathData: PathData[] = [];
        for (let i = 0; i < len - 1; i += 2) {
            pathData.push({
                radius: dv.getFloat32(i * 4, true),
                angle: dv.getFloat32((i + 1) * 4, true)
            });
        }
        return pathData;
    }
    
    public static PathDataToRingShape(pathData: PathData[], centerInUtm: jsts.geom.Coordinate, metersProjectionHelper: MetersProjectionHelper): RingShape {
        const degreeLatitudePerMeter = (1.0 / 111000.0);

        const centerUtm = new PointShape(centerInUtm.x, centerInUtm.y);
        const centerInWGS84 = PointShape.fromXYArray(
            metersProjectionHelper.metersToWgs84(centerInUtm.x, centerInUtm.y)
        );
        const trueNorthPointInUTM: PointShape = PointShape.fromXYArray(
            metersProjectionHelper.wgs84ToMeters(
                centerInWGS84.X, centerInWGS84.Y + 5000.0 * degreeLatitudePerMeter
            )
        );
        const trueNorthCorrectionInRadians: number = PathUtilities.FindAzimuth(centerUtm, trueNorthPointInUTM);

        const vertices: Vertex[] = [];

        for (var i = 0; i < pathData.length; i++)
        {
            const pd = pathData[i];
            const v = new Vertex(
                centerUtm.X + 0.3048 * pd.radius * Math.sin(pd.angle * Math.PI / 180.0 + trueNorthCorrectionInRadians),
                centerUtm.Y + 0.3048 * pd.radius * Math.cos(pd.angle * Math.PI / 180.0 + trueNorthCorrectionInRadians)
                );
            vertices.push(v);
        }

        vertices.push(vertices[0]);
        return new RingShape(vertices);
    }
    
    private static FindAzimuth(origin: PointShape, target: PointShape): number
    {
        const pi = Math.PI;
        const diffX = target.X - origin.X;
        const diffY = target.Y - origin.Y;
        const rslt = (diffX * diffX) + (diffY * diffY);
        const radius = Math.sqrt(rslt);
        if (radius === 0)
            return 0.0;
        let alpha = 0;
        const i = diffX / radius;
        if ((diffX >= 0) && (diffY >= 0))
            alpha = (pi / 2.0) - (Math.acos(i));
        else if ((diffX < 0) && (diffY >= 0))
            alpha = ((5.0 * pi) / 2.0) - (Math.acos(i));
        else
            alpha = (pi / 2.0) + (Math.acos(i));
        return alpha;
    }

    private static DistanceTo(A: PointShape, B: PointShape): number {
        let result = 0.0;
        const diff_y = (A.Y - B.Y);
        const diff_x = (A.X - B.X);

        result = (diff_x * diff_x) + (diff_y * diff_y);
        return Math.sqrt(result);
    }

    private static GetIntersection(lineAB: LineShape, lineIJ: LineShape): PointShape {
        let intersect: PointShape = null;
        const aX = lineAB.Points()[0].X;
        const aY = lineAB.Points()[0].Y;
        const bX = lineAB.Points()[1].X;
        const bY = lineAB.Points()[1].Y;
        const iX = lineIJ.Points()[0].X;
        const iY = lineIJ.Points()[0].Y;
        const jX = lineIJ.Points()[1].X;
        const jY = lineIJ.Points()[1].Y;

        const ddenom = (aX * iY) - (iX * aY) - (aX * jY) - (bX * iY) + (jX * aY) + (iX * bY) + (bX * jY) - (jX * bY);
        if (ddenom !== 0) {
            // How far along the AB segment are we?
            const dlambda1 = (((aX * iY) - (iX * aY) - (aX * jY) + (jX * aY) + (iX * jY) - (jX * iY)) / ddenom);
            // How far along the IJ segment are we?
            const dlambda2 = (((bX * aY) - (aX * bY) + (aX * iY) - (iX * aY) - (bX * iY) + (iX * bY)) / ddenom);
            if (((0 <= dlambda1) && (dlambda1 <= 1)) && ((0 <= dlambda2) && (dlambda2 <= 1))) {
                intersect = new PointShape();
                intersect.X = aX + (dlambda1 * (bX - aX));
                intersect.Y = aY + (dlambda1 * (bY - aY));
            }
        }
        return intersect;
    }

    private static InterpolatePathData(currentAngle: number, startAngle: number, endAngle: number, calculatedAngle: number, startRadius: number, endRadius: number): PathData {
        let cvgAngle = endAngle - startAngle;
        if (cvgAngle < 0)
            cvgAngle += 360.0;
        const gapAngle = 360.0 - cvgAngle;

        const data: PathData = {
            angle: 0, radius: 0
        };
        data.angle = currentAngle;

        if ((startAngle > endAngle) || (calculatedAngle >= endAngle))
            data.radius = (((calculatedAngle - endAngle) / gapAngle) * (startRadius - endRadius)) + endRadius;
        else {
            if (calculatedAngle <= startAngle) {
                data.radius = ((((calculatedAngle + 360.0) - endAngle) / gapAngle) * (startRadius - endRadius)) + endRadius;
            }
            else
                return null;
        }
        return data;
    }
}
