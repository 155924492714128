import { spanLengthsFeet } from "rdptypes/reinkeComponents";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import { data } from "roedata/roe_migration/SpanMaps";
import { SpanExtOK } from "roedata/roe_migration/Valids.Valids";
import { SpanLengthItem } from "../../components/OptimizeSystemDialog/RestrictSpanLengthsControl";

export const isSpanExtensionsPosibleByLength = (
    eSpanLength: number,
    bHoseFeed: boolean,
    iSpan: number
): boolean => {
    const items = data.filter(x => x.SpanLength === eSpanLength && x.SpanExtension);
    if (!items.length) return false;
    const spanTypes = new Set(items.map(x => x.SpanType));
    for (const spanType of spanTypes) {
        if (
            SpanExtOK(
                true, {
                    SpanType: spanType,
                    SpanLength: eSpanLength,
                    IsA100: false,
                    IsHoseFeed: bHoseFeed,
                    SpanNumber: iSpan
                }
            )
        ) {
            return true;
        }
        if (bHoseFeed) {
            if (
                SpanExtOK(
                    true, {
                        SpanType: spanType,
                        SpanLength: eSpanLength,
                        IsA100: true,
                        IsHoseFeed: bHoseFeed,
                        SpanNumber: iSpan
                    }
                )
            ) {
                return true;
            }
        }
    }
    return false;
}


export const getAvailableSpanLengthsWithExtension = (isFirstSpan: boolean, isCanalFeed: boolean) => {
    const dict: { [totalLength: string ]: SpanLengthItem} = {};
    const baseSpanLengths = spanLengthsFeet;
    for (const spanLength of baseSpanLengths) {
        const totalLength = spanLength + spanf.additionalSpanLength(spanLength);
        if (!(totalLength in dict)) {
            dict[totalLength] = { 
                spanLength: spanLength, 
                extension: false, 
                totalLength
            };
        }
        else {
            if (dict[totalLength].extension) {
                dict[totalLength].extension = false;
                dict[totalLength].spanLength = totalLength
            }
        } 
        
        // in isSpanExtensionsPosibleByLength: we will just choose the least restrictive (not hose feed or first span),
        // the idea here is to list all spans
        const bIsExtPossible = isSpanExtensionsPosibleByLength(spanLength, false, 2);
        if (bIsExtPossible) {
            const extendedSpanLength = spanLength + 4 + spanf.additionalSpanLength(spanLength);
            if (!(extendedSpanLength in dict)) {
                dict[extendedSpanLength] = { 
                    spanLength: spanLength, 
                    extension: true, 
                    totalLength: extendedSpanLength,
                };
            }
        }
    }
    const a =  Object.values(dict).sort((a, b) => b.totalLength - a.totalLength);
    return a;
}