import { Alert, Button } from "@mui/material";
import * as React from "react";
import { FC, useContext } from "react";
import { ESACZoneFactors } from "roedata/roe_migration/SprinklerEngineTypes/ESACZoneFactors";
import { ValidateESAC125DistResults } from "roedata/roe_migration/SprinklerEngineTypes/ValidateESAC125DistResults";
import { DistributionFlowRateLoadCustomOK } from "roedata/roe_migration/Valids.Valids";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";


interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
};

const ESAC125CustomValueLoader: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const [errors, setErrors] = React.useState<string[]>([]);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    var btnEnabled = DistributionFlowRateLoadCustomOK({
        Country: system.DealerProperties.Country, VRISACSpanSelected: system.Circle.SwingArm.VRISACSpan, ZoneControlType: system.Circle.SwingArm.ZoneControlType
    });

    var fileReader;

    const handleFileRead = (e) => {
        const content = fileReader.result;
        var eSACZoneFactors: ESACZoneFactors = new ESACZoneFactors();
        var results: ValidateESAC125DistResults = eSACZoneFactors.Validate125DistValues(content);
        if(results.IsValid){
            var s = "";
            results.Values.forEach((val, i) => {
                s+= `${i + 1},${val}\n`;
            });

            props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                props.layoutId,
                props.systemId,
                "Circle.SwingArm.ESAC125DistributionFlowRateFactors",
                s,
                authState));   
            setErrors([]);
        }else{
            setErrors(results.Errors.map(s => s + ""));
        }
    }

    const onLoadClick = (f: any) => {
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(f);     
    }

    return (
        <>
        {
            btnEnabled &&
            <div>
                <Button  component="label" variant="outlined">Load Custom Values <input onChange={(e) => onLoadClick(e.target.files[0])} accept=".csv" type="file" hidden/></Button>
                {
                    errors.length > 0 &&
                    <Alert severity="error" style={{marginTop: 5}}>
                        {errors.map(e => <p>{e}</p>)}
                    </Alert>
                }
                
            </div>    
        }       
        </>
    );
};

export default ESAC125CustomValueLoader;

