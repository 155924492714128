import * as React from "react";
import { Outlet, useParams } from "react-router-dom";

const System = () => {
    const params = useParams();
    
    return (
        <>
            <Outlet />
        </>
    );
}

export default System;