// Option Infer On
// Imports ReinkeSystems.Data

import { BuckBoostTypes } from "../../rdptypes/project/ISystemBase.AutoGenerated";
import { MotorLocations, MotorTypes } from "./CommonEnums";
import ElectricalCableGaugeHelper from "./ElectricalCableGaugeHelper";
import { Span } from "./Types";

export default class ElectricalMotor {
    public Key: string = undefined;
    public MotorType: MotorTypes = 0; // TODO: Check, do VB enums get initialized as 0
    public Location: MotorLocations = 0; // TODO: Check, do VB enums get initialized as 0
    public AmpDutyCycle: number = 0;
//     ''' <summary>
//     ''' KiloWatts Duty Cycle
//     ''' </summary>
//     ''' <returns></returns>
    public KWDutyCycle: number = 0;
    public MotorCodeValue: number = 0;
    public AllowableVoltageDrop: number = 0;
    public RatedAmperage: number = 0;
    public RatedHP: number = 0;

//     ''' <summary>
//     ''' Other than the Kw for the Air Compressor not being correct, this all looks good.
//     ''' The current for the VRI compressor is 2.4 instead of 1.9 because we have to factor 125% 
//     ''' current on the largest motor for current, and then go to the 60% rating after that, 
//     ''' which would be the 1.9A. If there is a booster pump on the system, then that 125% would 
//     ''' be done on the booster pump, not the Air Compressor because a 2 or 5 Hp booster pump is 
//     ''' larger than a 1.5 air compressor.
//     ''' </summary>
//     ''' <returns></returns>
    public IsMaxConsumer: boolean = false;
    public get LoadAmperage(): number {
        return this.RatedAmperage * 
            (this.IsMaxConsumer ? 1.25 : this.AmpDutyCycle) *
            (this.MotorCodeValue / 9.99) ** (1 / 3);
    }
    public CumulativeAmperage: number = 0;
    public get LoadKilowatts(): number {
        return this.RatedHP * this.MotorCodeValue * 0.3 * (1 - this.AllowableVoltageDrop) ** 2 * this.KWDutyCycle;
    }
    public Span: Span = undefined;
    public BuckBoostType: BuckBoostTypes = BuckBoostTypes.aNone; // TODO: Note, the VB enum is an integer? How does this effect things??
    public CableGaugeHelper = new ElectricalCableGaugeHelper();
    public VoltagePresent: number = 0;
//     ''' <summary>
//     ''' KiloVolt Amps
//     ''' </summary>
//     ''' <returns></returns>
    public KVA: number = 0;
    public IsMaxigator: boolean = false;

    constructor(Key: string, Location: MotorLocations, MotorType: MotorTypes, Span: Span = undefined, isMaxi: boolean = false) {
        this.Key = Key;
        this.Location = Location;
        this.MotorType = MotorType;
        this.Span = Span;
        this.IsMaxigator = isMaxi;
        this.SetMotorsAmpsAndKW()
    }

    private SetMotorsAmpsAndKW() {
        const MotorCodeK: number = 8.99;
        const MotorCodeL: number = 9.99;
        const MotorCodeM: number = 11.19;

        switch (this.Location) {
            case MotorLocations.A100Pump:
            case MotorLocations.PowerTower:
            case MotorLocations.SecondaryPT:
            case MotorLocations.SACAirComp:
            case MotorLocations.SACTower:
            case MotorLocations.BoosterPump:
                this.AmpDutyCycle = 1
                this.KWDutyCycle = 1
                break;
            case MotorLocations.SACSteering:
            case MotorLocations.VRIAirCompPowerTower:
            case MotorLocations.VRIAirCompOuterTower:
            case MotorLocations.DualSprinklerPkAirComp:
            case MotorLocations.WrapJointAirComp:
                this.AmpDutyCycle = 0.6
                this.KWDutyCycle = 0.6
                break;
            case MotorLocations.Tower:
                switch (this.MotorType) {
                    case MotorTypes.TwoThirtyVolt:
                        this.AmpDutyCycle = 0.67
                        this.KWDutyCycle = 0.67
                        break;
                    default:
                        this.AmpDutyCycle = this.IsMaxigator ? 1 : 0.6; // 'Outer Tower AmpDutyCycle adjustment for Maxigator (Laterals)
                        this.KWDutyCycle = this.IsMaxigator ? 0.75 : 0.6; // 'Outer Tower KWDutyCycle adjustment for Maxigator (Laterals)
                        break;
                }
                break;
        }

        // 'NOTE: All Amp, HP, MotorCode, and VoltageDrop are current as of 2021-03-18, BUT
        // '   A100Pump will be 3 options soon with different values.
        switch (this.MotorType) {
            case MotorTypes.A100Pump:
                this.RatedAmperage = 21
                this.RatedHP = 15
                this.MotorCodeValue = MotorCodeK
                this.AllowableVoltageDrop = 0.35
                break;
            case MotorTypes.Low:
            case MotorTypes.Standard:
                this.RatedAmperage = 1.4
                this.RatedHP = 0.75
                this.MotorCodeValue = MotorCodeM
                this.AllowableVoltageDrop = 0.25
                break;
            case MotorTypes.High:
                this.RatedAmperage = 2.2
                this.RatedHP = 1.5
                this.MotorCodeValue = MotorCodeM
                this.AllowableVoltageDrop = 0.25
                break;
            case MotorTypes.TwoThirtyVolt:
                this.RatedAmperage = 6.4
                this.RatedHP = 1
                this.MotorCodeValue = MotorCodeK
                this.AllowableVoltageDrop = 0.25
                break;
            case MotorTypes.SACAirComp:
            case MotorTypes.VRIAirComp:
            case MotorTypes.DualSprinklerPkAirComp:
            case MotorTypes.WrapJointAirComp:
                this.RatedAmperage = 1.9
                this.RatedHP = 1.5
                this.MotorCodeValue = MotorCodeL
                this.AllowableVoltageDrop = 0.25
                break;
            case MotorTypes.TwoHP:
                this.RatedAmperage = 3.25
                this.RatedHP = 2
                this.MotorCodeValue = MotorCodeL
                this.AllowableVoltageDrop = 0.35
                break;
            case MotorTypes.FiveHP:
                this.RatedAmperage = 5.8
                this.RatedHP = 5
                this.MotorCodeValue = MotorCodeL
                this.AllowableVoltageDrop = 0.35
                break;
        }
    }

}