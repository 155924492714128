import { Box } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { FC } from 'react';
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { IrrigatedAreaHelper } from "../../../GeometryHelpers/IrrigatedAreaHelper";
import CenterPivotGeometryHelper from '../../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper';
import LateralGeometryHelper from "../../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { isCenterPivot } from "../../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import { DisplayAreaUnitBuilder } from "../../../helpers/areas";
import { DisplayLengthUnitBuilder } from "../../../helpers/lengths";
import { IProjectWithId } from "../../../routes/pages/ProjectList";
import { documentStyles } from "../../styles";

interface Props {
    systemId: string;
    layoutId: string;
    pwid: IProjectWithId;
}

const SystemSummaryTable: FC<Props> = React.forwardRef((props) => {
    const {systemId, pwid, layoutId} = props;
    
    const systemIrrigatedAreaHelper = React.useMemo(() => {
        const iah = new IrrigatedAreaHelper({
            project: pwid.project, layoutId
        })
        return iah.getSystem(systemId);
    }, [ pwid.project, layoutId, systemId ]);

    let helper: CenterPivotGeometryHelper | LateralGeometryHelper;
    let system = pwid.project.layouts[layoutId].systems[systemId];

    let colWidth = 100/3;
    let oneColWidth = colWidth + "%";
    let twoColWidth = (colWidth * 2) + "%";
    let threeColWidth = (colWidth * 3) + "%";

    let fontSize = "0.8em";
    let cellStyle = {...documentStyles.cell, justifyContent: "left", padding: 2, width: oneColWidth, fontSize};

    const ns = "reports.";

    let rows: JSX.Element[] = [];

    const getCell = (title: string, val: string, width?: string) => {
        return <div style={{ ...cellStyle, width: width ?? oneColWidth}}>
            <strong style={{fontFamily: "Arial Black"}}>{title}</strong>: {val}
        </div>;
    }

    const settings = React.useContext(DevSettingsCtx);

    if (isCenterPivot(system)) {
        helper = new CenterPivotGeometryHelper({
            project: pwid.project,
            layoutId: layoutId!,
            systemId: systemId!
        });

        let spanNumber = 0;
        //don't count end booms and swing arms
        system.FlangedSide.Span.forEach(span => {
            if (!span.EndBoom && !span.SwingArm){
                spanNumber++;
            }
        });

        let endGunAcres = 0;
        systemIrrigatedAreaHelper.centerPivotInfo.endGunInfo.forEach((eg) => {
            let area = 0;
            eg.areas.forEach((a) => {
                area += a;
            });

            endGunAcres += area;
        });

        let systemLength = new DisplayLengthUnitBuilder(helper.systemRadiusFeetIncludingEndboomOrSac, 'feet')
            .convert(settings.dealerSettings.display.current.lengths)
            .appendValue(0)
            .appendString(" ")
            .appendShortName()
            .toString();

        rows.push(<div style={documentStyles.row}>{...[
                getCell(i18next.format(t(ns + 'system-length'), 'capitalize-each'), systemLength),
                getCell(i18next.format(t(ns + 'number-of-spans'), 'capitalize-each'), spanNumber.toString())
            ]}
            </div>
        );

        let irrigatedArea = new DisplayAreaUnitBuilder(systemIrrigatedAreaHelper.irrigatedArea, 'acres')
            .convert(settings.dealerSettings.display.current.areas)
            .appendValue(3)
            .appendString(" ")
            .appendFullName()
            .toString();

        let pivotArea = new DisplayAreaUnitBuilder(systemIrrigatedAreaHelper.centerPivotInfo.pivotAreaAcres, 'acres')
            .convert(settings.dealerSettings.display.current.areas)
            .appendValue(3)
            .appendString(" ")
            .appendFullName()
            .toString();

        let endgunArea = new DisplayAreaUnitBuilder(endGunAcres, 'acres')
            .convert(settings.dealerSettings.display.current.areas)
            .appendValue(3)
            .appendString(" ")
            .appendFullName()
            .toString();

        rows.push(<div style={documentStyles.row}>{...[
                getCell(i18next.format(t('map-display.irrigated-area'), 'capitalize-each'), irrigatedArea),
                getCell(i18next.format(t(ns + 'pivot-area'), 'capitalize-each'), pivotArea),
                getCell(i18next.format(t('end-gun'), 'capitalize-each'), endgunArea)
            ]}
            </div>
        );

        let swingArmArea = HasSwingArmCorner(system) ? new DisplayAreaUnitBuilder(systemIrrigatedAreaHelper.centerPivotInfo.swingArmArea, 'acres')
            .convert(settings.dealerSettings.display.current.areas)
            .appendValue(3)
            .appendString(" ")
            .appendFullName()
            .toString() : "N/A";

        let wrapSpanArea = typeof(systemIrrigatedAreaHelper.centerPivotInfo.wrapSpanAcres) === "undefined" ? "N/A" : new DisplayAreaUnitBuilder(systemIrrigatedAreaHelper.centerPivotInfo.wrapSpanAcres, 'acres')
            .convert(settings.dealerSettings.display.current.areas)
            .appendValue(3)
            .appendString(" ")
            .appendFullName()
            .toString();

        let dropSpanArea = typeof(systemIrrigatedAreaHelper.centerPivotInfo.dropSpanArea) === "undefined" ? "N/A" : new DisplayAreaUnitBuilder(systemIrrigatedAreaHelper.centerPivotInfo.dropSpanArea, 'acres')
            .convert(settings.dealerSettings.display.current.areas)
            .appendValue(3)
            .appendString(" ")
            .appendFullName()
            .toString();

        rows.push(<div style={documentStyles.row}>{...[
                getCell(i18next.format(t('s-tower'), 'capitalize-each'), swingArmArea),
                getCell(i18next.format(t('wrap-span'), 'capitalize-each'), wrapSpanArea),
                getCell(i18next.format(t('drop-span'), 'capitalize-each'), dropSpanArea)
            ]}
            </div>
        );
    }
    else {
        helper = new LateralGeometryHelper({
            project: pwid.project,
            layoutId: layoutId!,
            systemId: systemId!
        });

        let spanNumber = 0;
        //don't count end booms and swing arms
        system.FlangedSide.Span.forEach(span => {
            if (!span.EndBoom && !span.SwingArm){
                spanNumber++;
            }
        });

        let systemLength = system.FlangedSide.Span.map(x => x.Length).reduce((x, y) => x + y, 0);
        if (system.FlexSide && system.FlexSide.Span){
            system.FlexSide.Span.forEach(span => {
                if (!span.EndBoom && !span.SwingArm){
                    spanNumber++;
                }
            });
            
            systemLength += system.FlexSide.Span.map(x => x.Length).reduce((x, y) => x + y, 0);
        }

        let systemLength_ = new DisplayLengthUnitBuilder(systemLength, 'feet')
            .convert(settings.dealerSettings.display.current.lengths)
            .appendValue(0)
            .appendString(" ")
            .appendShortName()
            .toString();

        rows.push(<div style={documentStyles.row}>{...[
                getCell(i18next.format(t(ns + 'system-length'), 'capitalize-each'), systemLength_),
                getCell(i18next.format(t(ns + 'number-of-spans'), 'capitalize-each'), spanNumber.toString())
            ]}
            </div>
        );

        let totalEndgunAcres = 0;
        systemIrrigatedAreaHelper.lateralInfo.flangedEndGunInfo.forEach(egi => {
            egi.areas.forEach(a => totalEndgunAcres += a);
        })
        systemIrrigatedAreaHelper.lateralInfo.flexEndGunInfo.forEach(egi => {
            egi.areas.forEach(a => totalEndgunAcres += a);
        })

        let endgunArea = new DisplayAreaUnitBuilder(totalEndgunAcres, 'acres')
            .convert(settings.dealerSettings.display.current.areas)
            .appendValue(3)
            .appendString(" ")
            .appendFullName()
            .toString();

        let irrigatedArea = new DisplayAreaUnitBuilder(systemIrrigatedAreaHelper.irrigatedArea, 'acres')
            .convert(settings.dealerSettings.display.current.areas)
            .appendValue(3)
            .appendString(" ")
            .appendFullName()
            .toString();

        rows.push(<div style={documentStyles.row}>{...[
                getCell(i18next.format(t('map-display.irrigated-area'), 'capitalize-each'), irrigatedArea),
                getCell(i18next.format(t('end-gun'), 'capitalize-each'), endgunArea)
            ]}
            </div>
        );
    }
    
    if (systemIrrigatedAreaHelper.overlappingArea > 0) {
        let overlappingArea = new DisplayAreaUnitBuilder(systemIrrigatedAreaHelper.overlappingArea, 'acres')
            .convert(settings.dealerSettings.display.current.areas)
            .appendValue(3)
            .appendString(" ")
            .appendFullName()
            .toString();
        let netArea = new DisplayAreaUnitBuilder(systemIrrigatedAreaHelper.irrigatedArea - systemIrrigatedAreaHelper.overlappingArea, 'acres')
            .convert(settings.dealerSettings.display.current.areas)
            .appendValue(3)
            .appendString(" ")
            .appendFullName()
            .toString();
        rows.push(<div style={documentStyles.row}>{...[
                getCell(i18next.format(t('overlapping-irrigated-area'), 'capitalize-each'), overlappingArea),
                getCell(i18next.format(t('net-irrigated-area'), 'capitalize-each'), netArea)
            ]}
            </div>
        );
    }

    return <Box style={{border: "1px solid black", marginBottom: 20}}>
        <Box style={documentStyles.heading}>
            <Box style={{...documentStyles.sectionHeader, fontSize: 14}}>{system.name}</Box>
        </Box>
        <Box style={{paddingLeft: 15, paddingRight: 15}}>
            <Box style={{...documentStyles.table, marginBottom: 0}}>
                {rows}
            </Box>
        </Box>
    </Box>
});

export default SystemSummaryTable;