import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { t } from "i18next";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import { BoomBackDevices, BoomBackTypes, PipeMaterialTypes } from "rdptypes/project/ISprinklers";
import * as React from "react";
import { FC } from "react";
import { sprinklersPrefix } from ".";
import { pageStyles } from "../../styles";
import { getBoomBackDeviceName } from "./helpers/SprinklerHelper";
import { ISprinklerValidator } from "./sprinklerValidator/interfaces";

interface Props {
    side: SideEnum;    
    sprinklerValidator: ISprinklerValidator;
};

const SprinklerBoomBacks: FC<Props> = (props) => {

    const boomBackValidator = props.sprinklerValidator.getSide(props.side).boomBacks;

    let formInputLabelStyle = { ...pageStyles.formInputLabelStyle };

    return (
        <>
            <Stack direction="row" style={{ maxWidth: 900, marginTop: 20 }}>
                <FormControl variant="standard" fullWidth style={{ marginLeft: 10, marginRight: 10 }} error={boomBackValidator.boomBackType.isError}>
                    <InputLabel shrink={true} sx={formInputLabelStyle} >{"Placement"}</InputLabel>
                    <Select
                        displayEmpty
                        renderValue={(selected) => {
                            if (!selected) return "Select";
                            else return selected;
                        }}
                        value={boomBackValidator.boomBackType.value ?? ''}
                        onChange={(ev) => {
                            const BoomBackType = ev.target.value as BoomBackTypes;
                            boomBackValidator.boomBackType.set(BoomBackType);
                        }}
                    >
                        {
                            boomBackValidator.boomBackType.allowableValues.map((key: BoomBackTypes) => (
                                <MenuItem 
                                    key={key} 
                                    value={key}
                                >
                                    {key}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                {(boomBackValidator.boomBackType.value ?? BoomBackTypes.None) !== BoomBackTypes.None &&
                    <FormControl
                        error={boomBackValidator.boomBackDevice.isError}
                        variant="standard" fullWidth style={{ marginLeft: 10, marginRight: 10 }}>
                        <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "boomback-device")}</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getBoomBackDeviceName(selected);
                            }}
                            value={boomBackValidator.boomBackDevice.value ?? ""}
                            onChange={(ev) => boomBackValidator.boomBackDevice.set(ev.target.value as BoomBackDevices)}
                        >
                            {
                                boomBackValidator.boomBackDevice.allowableValues
                                    .map((key) => <MenuItem key={key} value={key}>{getBoomBackDeviceName(key as BoomBackDevices)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                }
                {(boomBackValidator.boomBackType.value ?? BoomBackTypes.None) !== BoomBackTypes.None &&
                    <FormControl
                        error={boomBackValidator.pipeMaterial.isError}
                        variant="standard" fullWidth style={{ marginLeft: 10, marginRight: 10 }}>
                        <InputLabel shrink={true} sx={formInputLabelStyle} >Boom Back Material</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return selected;
                            }}
                            value={boomBackValidator.pipeMaterial.value ?? ""}
                            onChange={(ev) => boomBackValidator.pipeMaterial.set(ev.target.value as PipeMaterialTypes)}
                        >
                            {
                                boomBackValidator.pipeMaterial.allowableValues
                                    .map((key) => (
                                        <MenuItem 
                                            key={key} 
                                            value={key}
                                        >
                                            {t(sprinklersPrefix + "boomback-material." + key)}
                                        </MenuItem>
                                    ))
                            }
                        </Select>
                    </FormControl>
                }
            </Stack>
            {(boomBackValidator.boomBackType.value ?? BoomBackTypes.None) !== BoomBackTypes.None &&
                <Stack direction="row" style={{ maxWidth: 900, marginTop: 20 }}>
                    <FormControl
                        error={boomBackValidator.firstTower.isError}
                        variant="standard" fullWidth style={{ marginLeft: 10, marginRight: 10 }}>
                        <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "first-tower")}</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (selected === -1) return "Select";
                                else return selected;
                            }}
                            disabled={boomBackValidator.boomBackType.value === BoomBackTypes.None}
                            value={boomBackValidator.firstTower.value ?? -1}
                            onChange={(ev) => boomBackValidator.firstTower.set(Number(ev.target.value))}
                        >
                            {
                                boomBackValidator.firstTower.allowableValues.map((key) => <MenuItem key={key} value={key}>{key}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl
                        error={boomBackValidator.qtyPerTower.isError}
                        variant="standard" fullWidth style={{ marginLeft: 10, marginRight: 10 }}>
                        <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "boomback-qty-per-tower")}</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (selected === -1) return "Select";
                                else return selected;
                            }}
                            disabled={boomBackValidator.boomBackType.value !== BoomBackTypes.Standard}
                            value={boomBackValidator.qtyPerTower.value ?? -1}
                            onChange={(ev) => boomBackValidator.qtyPerTower.set(Number(ev.target.value))}
                        >
                            {
                                boomBackValidator.qtyPerTower.allowableValues.map((key) => <MenuItem key={key} value={key}>{key}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControlLabel style={{ minWidth: 290 }} control={<Checkbox
                        style={{ padding: 0, paddingLeft: 10, paddingRight: 5 }}
                        checked={boomBackValidator.valves.value ?? false}
                        disabled={boomBackValidator.boomBackType.value === BoomBackTypes.None}
                        onChange={(ev) => {
                            boomBackValidator.valves.set(ev.target.checked);
                        }}
                    />} label={t(sprinklersPrefix + "boomback-auto-reverse")} />
                </Stack>
            }
        </>
    );
};

export default SprinklerBoomBacks;