import { Drawer } from "@mui/material";
import * as React from "react";
import SystemDiagramView from "../../../components/SystemDiagramView";

interface IProps {
    layoutId: string;
    projectId: string;
    systemId?: string;
    open: boolean;
}
const BottomDrawer: React.FC<IProps> = ({
    layoutId, projectId, systemId, open
}) => {
    return (
        <Drawer
            variant="persistent"
            anchor={"bottom"}
            open={open}
        >
            {systemId && (
                <SystemDiagramView
                    layoutId={layoutId!}
                    projectId={projectId!}
                    systemId={systemId}
                />
            )}
        </Drawer>
    );
};

export default BottomDrawer;