import { Box, FormControlLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { FC, useContext } from "react";
import PriceCalculator from "roedata/roe_migration/PriceCalculator";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import DealerDataCtx from "../../../../userData/DealerDataCtx";
import { getDiscountConfigFromUserData, getGroupedPartsForSystem, proposalSectionIsAncillary } from "../Proposal/ProposalHelpers";
import PercentageTableRow from "./PercentageTableRow";
import PricingTableRow from "./PricingTableRow";

interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
};

const ProposalInformationPricingTableRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const user = useContext(DealerDataCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    let listPriceCentsMinusAncillary = 0;
    let totalListPrice = 0;
    let anyPriceIsNaN = false;

    let groupedParts = getGroupedPartsForSystem(system);
    for (let sectionId in groupedParts.partSections){
        groupedParts.partSections[sectionId].forEach((p) => {
            let cost = p.qty * user.priceList[p.partNumber].unitPriceUsdCents;
            if (!isNaN(cost)){
                totalListPrice += cost;
                if (!proposalSectionIsAncillary(parseInt(sectionId))){
                    listPriceCentsMinusAncillary += cost;
                }
            }
            else {
                anyPriceIsNaN = true;
            }
        });
    }

    //Mainline Valves
    let quote = new QuoteClass(system);
    system.MainlineValveOptions.Valves.MainlineValve.forEach((valve) => {
        let info = quote.MainlineValvesClass.GetMainlineValveInfo(valve, user.priceList);
        let price = info.Price();
        if (!isNaN(price)){
            totalListPrice += price;
        }
        else {
            anyPriceIsNaN = true;
        }
    });

    if (HasSwingArmCorner(quote.System)){
        totalListPrice += quote.SwingArmClass.SpecialOrderPriceCents();
    }

    let pricing = system.QuoteProperties?.ProposalInformation?.pricing;

    let dc = getDiscountConfigFromUserData(user, system);
    let pc = new PriceCalculator(new QuoteClass(system), listPriceCentsMinusAncillary, dc, groupedParts.partSections, user.priceList);

    let systemPrice: number;
    if (pricing.listPriceDiscountPercent > 0){
        systemPrice = totalListPrice - (pricing.listPriceDiscountPercent/100) * totalListPrice;
    }
    else if (pricing.markupPercentOverDealerCost > 0){
        systemPrice = pc.getTotalDealerPriceCents() + (pc.getTotalDealerPriceCents() * (pricing.markupPercentOverDealerCost/100)) 
    }
    else if (pricing.markupDollarOverDealerCost > 0){
        systemPrice = pc.getTotalDealerPriceCents() + (pricing.markupDollarOverDealerCost * 100);
    }
    else if (pricing.quotedPriceDollar > 0){
        systemPrice = pricing.quotedPriceDollar * 100;
    }
    else {
        systemPrice = totalListPrice;
    }

    let salesTaxMultiplier = pricing.salesTaxPercent > 0 ? (pricing.salesTaxPercent/100) : 1;

    let miscItemsCostCents = 0;
    let miscItemsTaxCents = 0;
    let miscItemsTaxable = false;
    system.QuoteProperties?.MiscItems?.forEach((mi) => {
        miscItemsCostCents += mi.priceCents;
        if (mi.taxable){
            miscItemsTaxable = true;
            miscItemsTaxCents += mi.priceCents * salesTaxMultiplier;
        }
    });

    let installation = pricing?.installation?.priceUsdCents ?? 0;
    let freight = pricing?.freight?.priceUsdCents ?? 0;
    let pivotPad = pricing?.pivotPad?.priceUsdCents ?? 0;
    let tradeIn = pricing?.tradeIn?.priceUsdCents ??0;

    let subTotalMinusTax = systemPrice + installation + freight + pivotPad + miscItemsCostCents  - tradeIn;

    let tax = miscItemsTaxCents;

    if (pricing.installation.isTaxable) tax += installation * salesTaxMultiplier;
    if (pricing.freight.isTaxable) tax += freight * salesTaxMultiplier;
    if (pricing.pivotPad.isTaxable) tax += pivotPad * salesTaxMultiplier;
    if (pricing.system.isTaxable) tax += systemPrice * salesTaxMultiplier;
    if (pricing.tradeIn.isTaxable) tax -= tradeIn * salesTaxMultiplier;

    let totalCents = subTotalMinusTax + tax;
    let downPaymentCents = 0;
    let dueOnDeliveryCents = 0;
    if (pricing.paymentComputationMethod === "Percent")
    {
        downPaymentCents = pricing?.downPayment?.percent ? (pricing.downPayment.percent/100) * totalCents : 0;
        dueOnDeliveryCents = pricing?.dueOnDelivery?.percent ? (pricing.dueOnDelivery.percent/100) * totalCents : 0; 
    }
    else if (pricing.paymentComputationMethod === "Price"){
        downPaymentCents = pricing?.downPayment?.usdCents ?? 0;
        dueOnDeliveryCents = pricing?.dueOnDelivery?.usdCents ?? 0;
    }

    const prefix = "proposal-information.";
    let priceComputation = pricing.paymentComputationMethod;

    return (
        <>
            <TableContainer component={Box}>
                <Table sx={{marginBottom: "10px"}}>
                    <TableBody>
                        <PricingTableRow
                            label={`${t("common-phrases.sltListPrice")}${anyPriceIsNaN? "*" : ""}`}
                            priceCents={totalListPrice}
                            canBeTaxable={false}
                            priceReadonly
                        />
                        <PricingTableRow 
                            label={`${t("common-phrases.sltCustomerPrice")}${anyPriceIsNaN? "*" : ""}`}
                            priceCents={systemPrice}
                            canBeTaxable
                            saveIsTaxable={(taxable) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.system.isTaxable",
                                    taxable,
                                    authState));
                            }}
                            isTaxable={pricing.system.isTaxable}
                            priceReadonly
                        />
                        <PricingTableRow 
                            label={"+ " + t("common-phrases.sltInstallation")}
                            priceCents={installation}
                            savePrice={(priceCents) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.installation.priceUsdCents",
                                    priceCents,
                                    authState));
                            }}
                            canBeTaxable
                            saveIsTaxable={(taxable) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.installation.isTaxable",
                                    taxable,
                                    authState));
                            }}
                            isTaxable={pricing.installation.isTaxable}
                        />
                        <PricingTableRow 
                            label={"+ " + t("common-phrases.sltFreight")}
                            priceCents={freight}
                            savePrice={(priceCents) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.freight.priceUsdCents",
                                    priceCents,
                                    authState));
                            }}
                            canBeTaxable
                            saveIsTaxable={(taxable) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.freight.isTaxable",
                                    taxable,
                                    authState));
                            }}
                            isTaxable={pricing.freight.isTaxable}
                        />
                        <PricingTableRow 
                            label={"+ " + t(prefix + "pivot-pads")}
                            priceCents={pivotPad}
                            savePrice={(priceCents) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.pivotPad.priceUsdCents",
                                    priceCents,
                                    authState));
                            }}
                            canBeTaxable
                            saveIsTaxable={(taxable) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.pivotPad.isTaxable",
                                    taxable,
                                    authState));
                            }}
                            isTaxable={pricing.pivotPad.isTaxable}
                        />
                        <PricingTableRow
                            label={"+ " + t(prefix + "misc-items")}
                            priceCents={miscItemsCostCents}
                            canBeTaxable={true}
                            taxableReadonly
                            isTaxable={miscItemsTaxable}
                            priceReadonly
                        />
                        <PricingTableRow 
                            label={"- " + t(prefix + "trade-in")}
                            priceCents={tradeIn}
                            savePrice={(priceCents) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.tradeIn.priceUsdCents",
                                    priceCents,
                                    authState));
                            }}
                            canBeTaxable
                            saveIsTaxable={(taxable) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.tradeIn.isTaxable",
                                    taxable,
                                    authState));
                            }}
                            isTaxable={pricing.tradeIn.isTaxable}
                        />
                        <PricingTableRow
                            label={`= ${t(prefix + "sub-total")}${anyPriceIsNaN? "*" : ""}`}
                            priceCents={subTotalMinusTax}
                            canBeTaxable={false}
                            priceReadonly
                        />
                        <PricingTableRow
                            label={`+ ${t(prefix + "sales-tax")}${anyPriceIsNaN? "*" : ""}`}
                            priceCents={tax}
                            canBeTaxable={false}
                            priceReadonly
                        />
                        <PricingTableRow
                            label={`= ${t(prefix + "total")}${anyPriceIsNaN? "*" : ""}`}
                            priceCents={totalCents}
                            canBeTaxable={false}
                            priceReadonly
                        />
                    </TableBody>
                </Table>
            </TableContainer>
            {
                anyPriceIsNaN && <Typography sx={{ fontSize:"0.8em", textAlign: "right"}}>
                <strong>*Prices are incomplete - price is missing for some components.</strong>       
            </Typography>
            }
            <TableContainer component={Box} sx={{marginTop: "10px"}}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3}>
                            <Typography>Price computation</Typography>
                            <RadioGroup 
                                row
                                value={priceComputation}
                                onChange={(ev) => {
                                    props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                        props.layoutId,
                                        props.systemId,
                                        "QuoteProperties.ProposalInformation.pricing.paymentComputationMethod",
                                        ev.target.value,
                                        authState));
                                }}
                            >
                                <FormControlLabel 
                                    value={"Percent"} 
                                    control={<Radio />} 
                                    label={"Percent"} />
                                <FormControlLabel 
                                    value={"Price"} 
                                    control={<Radio />} 
                                    label={"Price"} />
                            </RadioGroup>
                            </TableCell>
                        </TableRow>
                        <PercentageTableRow 
                            priceComputation={pricing.paymentComputationMethod}
                            label={t("common-phrases.sltDownPayment")}
                            percentage={pricing?.downPayment?.percent ?? 0}
                            saveUsdCents={(usdCents) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.downPayment.usdCents",
                                    usdCents,
                                    authState));
                            }}
                            savePercentage={(percentage) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.downPayment.percent",
                                    percentage,
                                    authState));
                            }}
                            usdCents={downPaymentCents}
                        />
                        <PercentageTableRow 
                            priceComputation={pricing.paymentComputationMethod}
                            label={t("common-phrases.sltDueOnDelivery")}
                            percentage={pricing?.dueOnDelivery?.percent ?? 0}
                            saveUsdCents={(usdCents) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.dueOnDelivery.usdCents",
                                    usdCents,
                                    authState));
                            }}
                            savePercentage={(percentage) => {
                                props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.layoutId,
                                    props.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.dueOnDelivery.percent",
                                    percentage,
                                    authState));
                            }}
                            usdCents={dueOnDeliveryCents}
                        />
                        <PercentageTableRow
                            priceComputation={pricing.paymentComputationMethod}
                            label={t("common-phrases.sltDueOnInstallation")}
                            percentage={Math.round(((totalCents - dueOnDeliveryCents - downPaymentCents)/totalCents)*100)}
                            usdCents={totalCents - dueOnDeliveryCents - downPaymentCents}
                            readonly
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ProposalInformationPricingTableRenderer;