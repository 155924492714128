import * as React from "react";
import { Outlet } from "react-router-dom";

const Root = () => {
    return (
        <>
            <Outlet />
        </>
    );
}

export default Root;