import * as turf from "@turf/turf";
import { Feature, MultiPolygon, Polygon, feature } from "@turf/turf";
import { EndGunTypes, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { customUnion } from "..";
import IProject from "../../model/project/IProject";
import { BoundaryHelper } from "../BoundaryHelper";
import CenterPivotGeometryHelper from "../SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper from "../SystemGeometryHelpers/LateralGeometryHelper";

interface EndGunInfo {
    type: EndGunTypes;
    areas: number[];
    isOnSac: boolean;
    isPrimary: boolean;
}
interface CenterPivotInfo {
    pivotAreaAcres: number;
    endGunInfo: EndGunInfo[];
    swingArmArea: number;
    wrapSpanAcres: number;
    dropSpanArea: number;
    areaPolygon?: turf.helpers.Polygon;
}
const getCenterPivotInfo = (project: IProject, layoutId: string, systemId: string, gh?: CenterPivotGeometryHelper): CenterPivotInfo => {
    const systemGeometryHelper = gh ?? new CenterPivotGeometryHelper({
        project: project,
        layoutId: layoutId,
        systemId
    });
    return {
        pivotAreaAcres: systemGeometryHelper.pivotAreaAcres,
        endGunInfo: systemGeometryHelper.getEndgunAcres,
        swingArmArea: systemGeometryHelper.getSacAreaAcres,
        wrapSpanAcres: systemGeometryHelper.wrapSpanAreaAcres,
        dropSpanArea: systemGeometryHelper.dropSpanAcres,
        areaPolygon: systemGeometryHelper.getAreaPolygon({ includeEndguns: true, includeSAC: true })
    }
}
interface LateralInfo {
    flangedArea: number;
    flexArea: number;
    flangedEndGunInfo: EndGunInfo[];
    flexEndGunInfo: EndGunInfo[];
    flangedDropSpanArea: number;
    flexDropSpanArea: number;
    areaPolygon: turf.helpers.Polygon | turf.helpers.MultiPolygon | null;
}
const getLateralInfo = (project: IProject, layoutId: string, systemId: string, gh?: LateralGeometryHelper): LateralInfo => {
    const system = project.layouts[layoutId].systems[systemId];
    const systemGeometryHelper = gh ?? new LateralGeometryHelper({
        project: project,
        layoutId: layoutId,
        systemId
    });

    const egi = systemGeometryHelper.getEndGunIrrigatedAreaAcres();
    const flangedEndGunInfo: EndGunInfo[] = [];
    if (egi.rigidAcres.primary.length) {
        flangedEndGunInfo.push({
            type: system.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary,
            areas: egi.rigidAcres.primary,
            isOnSac: false,
            isPrimary: true
        })
    }
    if (egi.rigidAcres.secondary.length) {
        flangedEndGunInfo.push({
            type: system.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary,
            areas: egi.rigidAcres.secondary,
            isOnSac: false,
            isPrimary: false
        })
    }
    const flexEndGunInfo: EndGunInfo[] = [];
    if (egi.flexAcres.primary.length) {
        flexEndGunInfo.push({
            type: system.FlexSide.EndOfSystem.EndGun.EndGunTypePrimary,
            areas: egi.flexAcres.primary,
            isOnSac: false,
            isPrimary: true
        })
    }
    if (egi.flexAcres.secondary.length) {
        flexEndGunInfo.push({
            type: system.FlexSide.EndOfSystem.EndGun.EndGunTypeSecondary,
            areas: egi.flexAcres.secondary,
            isOnSac: false,
            isPrimary: false
        })
    }

    const ia = systemGeometryHelper.getIrrigatedAreaPolygons().map(x => x.geometry);
    let tempAp: Feature<Polygon | MultiPolygon> | null = null;
    if (ia.length) {
        for (const a of ia) {
            if (!tempAp) {
                tempAp = feature(a);
            }
            else {
                tempAp = customUnion(tempAp, a);
            }
        }
    }

    return {
        flangedArea: systemGeometryHelper.getFlangedSideMainIrrigatedAreaAcres(),
        flexArea: systemGeometryHelper.getFlexSideMainIrrigatedAreaAcres(),
        flangedEndGunInfo,
        flexEndGunInfo,
        flangedDropSpanArea: systemGeometryHelper.getFlangedSideDropIrrigatedAreaAcres(),
        flexDropSpanArea: systemGeometryHelper.getFlexSideDropIrrigatedAreaAcres(),
        areaPolygon: tempAp ? tempAp.geometry : null
    }

}

export interface SystemIrrigatedInfo {
    centerPivotInfo?: CenterPivotInfo;
    lateralInfo?: LateralInfo;
    irrigatedArea: number;
    overlappingArea: number;
}

export class IrrigatedAreaHelper {
    private _project: IProject;
    private _layoutId: string;
    private _systemIrrigatedInfos: { [systemId: string ]: SystemIrrigatedInfo } = null;
    private _gh: { [ systemId: string ]: CenterPivotGeometryHelper | LateralGeometryHelper } = {};

    // The gh helpers can be passed here if wanted. Doing so may be faster if they are already created
    constructor(args: { project: IProject, layoutId: string, geometryHelpers?: { [ systemId: string ]: CenterPivotGeometryHelper | LateralGeometryHelper } }) {
        this._project = args.project;
        this._layoutId = args.layoutId;
        if (args.geometryHelpers) {
            this._gh = args.geometryHelpers;
        }
    }

    private _getFieldBoundaryPolygon(): Polygon | null { 
        if (!this._project.layouts[this._layoutId].fieldBoundary) {
            return null;
        }
        return BoundaryHelper.getPolygon(this._project.layouts[this._layoutId].fieldBoundary); 
    }

    public getFieldAcres(): number { 
        const fieldBoundary = this._getFieldBoundaryPolygon();
        if (!fieldBoundary) return 0;
        return turf.convertArea(turf.area(fieldBoundary), 'meters', 'acres') 
    }

    public getSystem(systemId: string): SystemIrrigatedInfo {
        if (!this._systemIrrigatedInfos) {
            this._initializeSystems();
        }
        return this._systemIrrigatedInfos[systemId];
    }
    public getIrrigatedAcres(filterSystemIds?: string[]): number {
        let ia = 0;
        const systemIds = filterSystemIds || Object.keys(this._project.layouts[this._layoutId].systems);
        for (const systemId of systemIds) {
            const s = this.getSystem(systemId);
            if (!s) continue;
            ia += (s.irrigatedArea - s.overlappingArea);
        }
        return ia;
    }

    private _initializeSystems() {
        this._systemIrrigatedInfos = {};
        const layout = this._project.layouts[this._layoutId];

        let totalIrrigatedPolygon: Feature<Polygon | MultiPolygon> | null = null;
        const tempSystemIrrigatedInfos: { [systemId: string ]: SystemIrrigatedInfo & { 
            temp?: {
                ap: Polygon | MultiPolygon,
                envelope: Polygon,
                intersections: Feature<Polygon | MultiPolygon>[]
            }
        } } = {}

        for (const [systemId, system] of Object.entries(layout.systems)) {
            let lateralInfo: LateralInfo = null;
            let centerPivotInfo: CenterPivotInfo = null;
            let irrigatedArea = 0;
            let overlappingArea = 0;
            if (system.SystemProperties.SystemType === SystemTypes.CenterPivot) {
                centerPivotInfo = getCenterPivotInfo(this._project, this._layoutId, systemId, this._gh[systemId] as CenterPivotGeometryHelper);
            }
            else if (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) {
                lateralInfo = getLateralInfo(this._project, this._layoutId, systemId, this._gh[systemId] as LateralGeometryHelper);
            }

            const ap = centerPivotInfo ? centerPivotInfo.areaPolygon : lateralInfo.areaPolygon;
            let temp: {
                envelope: Polygon,
                ap: Polygon | MultiPolygon,
                intersections: Feature<Polygon | MultiPolygon>[]
            } = undefined;
            if (ap) {
                temp = {
                    ap,
                    envelope: turf.envelope(ap).geometry,
                    intersections: []
                }
                irrigatedArea = turf.convertArea(turf.area(ap), 'meters', 'acres');
            }

            tempSystemIrrigatedInfos[systemId] = {
                centerPivotInfo,
                lateralInfo,
                irrigatedArea,
                overlappingArea,
                temp
            }
        }
        
        const arr = Object.entries(tempSystemIrrigatedInfos);
        for (let i = 0; i <  arr.length; i++) {
            const [ systemId, system ] = arr[i];
            this._systemIrrigatedInfos[systemId] = {
                centerPivotInfo: system.centerPivotInfo,
                lateralInfo: system.lateralInfo,
                irrigatedArea: system.irrigatedArea,
                overlappingArea: system.overlappingArea
            }
            if (!system.temp) continue;
            const totalOverlappingAreaPolygon = system.temp.intersections.reduce((prev, crnt) => {
                if (prev === null) {
                    return crnt;
                }
                else {
                    return customUnion(crnt, prev);
                }
            }, null);
            if (totalOverlappingAreaPolygon) {
                this._systemIrrigatedInfos[systemId].overlappingArea = turf.convertArea(turf.area(totalOverlappingAreaPolygon), 'meters', 'acres');
            }
            for (let j = i + 1; j < arr.length; j++) {
                const [ comparingSystemId, comparingSystem ] = arr[j];
                if (!comparingSystem.temp) continue;
                if (!turf.booleanDisjoint(system.temp.envelope, comparingSystem.temp.envelope)) {
                    const intersection = turf.intersect(system.temp.ap, comparingSystem.temp.ap);
                    if (intersection) {
                        comparingSystem.temp.intersections.push(intersection);
                    }
                }
            }
        }
    }
}