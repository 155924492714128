import * as React from "react";
import { useContext, useState } from "react";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, MenuItem, Tooltip } from "@mui/material";
import i18next, { t } from "i18next";
import DbCtx from "../../../db/DbCtx";
import DealerDataCtx from "../../../userData/DealerDataCtx";
import CopySystemConfirmationDialog from "./CopySystemConfirmationDialog";

interface IProps {
    projectId?: string;
    onCopy: (newProjectId: string) => void;
    type?: 'button' | 'menuItem';
}

const CopyProjectButton: React.FC<IProps> = ({
    projectId, onCopy, type = 'button'
}) => {
    
    const dbState = useContext(DbCtx);    
    const dealer = useContext(DealerDataCtx);
    const dbPrj = projectId ? dbState.projects[projectId] : undefined;

    const [ showConfirmation, setShowConfirmation ] = useState(false);

    const handleOnClick = () => {
        
        if (dbState.growers[dbPrj?.state?.growerId]?.owner !== dealer.assumedUser.id &&
            dbState.growers[dbPrj?.state?.growerId]?.sharedWithDealership === "readonly") {
                alert("You do not have permission to create fields for this grower.");
                return;
            }

        setShowConfirmation(true);
    }

    const buttonComponent = type === 'menuItem'
        ? (
            <MenuItem 
                onClick={handleOnClick}
                sx={{ color: "inherit" }}
                
            >
                {i18next.format(t('copy-system'), 'capitalize-each')}
            </MenuItem>
        )
        : (
            <Tooltip title={i18next.format(t('copy-system'), 'capitalize-each')} placement="bottom">
                <IconButton
                    onClick={handleOnClick}
                    color="inherit"
                >
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
        )

    return (
        <>
            {buttonComponent}
            {
                showConfirmation && (
                    <CopySystemConfirmationDialog
                        projectId={projectId}
                        onCancel={() => {
                            setShowConfirmation(false)
                        }}
                        onCopy={(newProjectId: string) => {
                            setShowConfirmation(false)
                            onCopy(newProjectId);
                        }}
                    />
                )
            }
        </>
    )
}

export default CopyProjectButton;