import { EndGunTypes } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import { HasEnergySaverPackage, HasSwingArmCorner, IsDualSided, IsEDMP, IsPivotingLateral } from "../SystemFunctions";
import { ControlLineBoosterPumpOptionOk, DualSprinklerPackageControlOk, DualSprinklerPackageOptionOk, EndGunOperationLateralModeOk, VRIOptionOK } from "../Valids.Valids";

export default class SprinklersConfigForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        let sprinklerProperties = this.Quote.System.SprinklerProperties;
        let isPivotingLateral = IsPivotingLateral(this.Quote.System);

        if (!DualSprinklerPackageOptionOk(sprinklerProperties.DualSprinklerPackage, {
            IsPivotingLateral: isPivotingLateral,
        })){
            this.Quote.Warnings.Add(`Only pivoting lateral systems can have a dual sprinkler package.`);
        }

        if (sprinklerProperties.DualSprinklerPackage) {
            if (!DualSprinklerPackageControlOk(sprinklerProperties.DualSprinklerPackageControlType, {
                IsPivotingLateral: isPivotingLateral,
                DualSprinklerPackageOptionChecked: sprinklerProperties.DualSprinklerPackage,
            })){
                this.Quote.Warnings.Add(`Only pivoting lateral systems can have a dual sprinkler package control.`);
            }
        }

        let hasEndGun = false;
        let flangedEos = this.Quote.System.FlangedSide.EndOfSystem;

        if (flangedEos.EndGun.EndGunTypePrimary !== EndGunTypes.None || flangedEos.EndGun.EndGunTypeSecondary !== EndGunTypes.None){
            hasEndGun = true;
        }

        if (IsDualSided(this.Quote.System)){
            let flexEos = this.Quote.System.FlexSide.EndOfSystem;
            if (flexEos.EndGun.EndGunTypePrimary !== EndGunTypes.None || flexEos.EndGun.EndGunTypeSecondary !== EndGunTypes.None){
                hasEndGun = true;
            }
        }

        if (!EndGunOperationLateralModeOk(sprinklerProperties.EndGunOperationLateralMode, {
            HasDualSprinklerPackage: sprinklerProperties.DualSprinklerPackage,
            HasEndGun: hasEndGun,
            IsPivotingLateral: isPivotingLateral,
        })){
            this.Quote.Warnings.Add(`End gun operational mode is invalid - system must be a pivoting lateral with a dual sprinkler package and have an end gun defined.`);
        }

            if (!ControlLineBoosterPumpOptionOk(sprinklerProperties.ControlLineBoosterPump, {
                IsPivotingLateral: isPivotingLateral,
                DualSprinklerPackageOptionChecked: sprinklerProperties.DualSprinklerPackage,
                DualSprinklerPackageControlType: sprinklerProperties.DualSprinklerPackageControlType,
            })){
                this.Quote.Warnings.Add(`A sprinkler control line booster pump can only be used on a pivoting lateral with a water controlled dual sprinkler package.`);
            }

        if (sprinklerProperties.VRIZones && sprinklerProperties.VRIZones.Zone.length){
            //VRI zones enabled
            let towerCount = this.Quote.System.FlangedSide.Tower.length;
            if (IsDualSided(this.Quote.System)) {
                towerCount += this.Quote.System.FlexSide.Tower.length;
            }

            let controlPanel = this.Quote.System.ControlPanel;
            let hasSecondEndGun = false;
            if (this.Quote.System.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypeSecondary && 
                this.Quote.System.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypeSecondary !== EndGunTypes.None){
                hasSecondEndGun = true;
            }

            if (controlPanel && !VRIOptionOK({
                TowerCount: towerCount,
                IsEDMP: IsEDMP(this.Quote.System),
                ElectricalFrequency: controlPanel.ElectricalFrequency, 
                    HasSwingArmCorner: HasSwingArmCorner(this.Quote.System),
                    HasESP: HasEnergySaverPackage(this.Quote.System),
                    PanelModel: controlPanel.PanelModel,
                    EndTowerPositioningType: controlPanel.EndTowerPositioning,
                    SystemType: this.Quote.System.SystemProperties.SystemType,
                    HoseFeedType: this.Quote.System.Lateral?.HoseFeed?.HoseFeedType, 
                    AnyDisconnecting: this.Quote.RightSpansClass.AnyDisconnecting(),
                    HasEndGunSecondary: hasSecondEndGun,
                })){
                this.Quote.Warnings.Add(`Current system configuration does not support VRI zones.`);
            }

        }
    }
}