export default {
    pages: [
        {
            changeRequiresNewSprinklers: false,
            id: "proposal",
            title: {"en" : "Proposal", "es": "Oferta"},
            disableDealerSettings: true,
            position: 17,
            visible: () => true,
            components: [
                {
                    type: "proposal",
                },
            ],
        },
    ],
};