import schema from "../data/poSchema";
import CSection2 from "./CSection2";
import PartsAssembler from "./PartsAssembler";
export class ClemonsFiltersClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/
    }
    public WritePO = (PO: PartsAssembler) => {
        if (this.Quote.System.ClemonsFilters.CF6InchQty > 0) {
            PO.InsertPart(schema.groups.ClemonsFilters_Filters.groupId, "119112", this.Quote.System.ClemonsFilters.CF6InchQty); // FLTR- 6"-475 GPM-3/32 CLEMONS
        }
        if (this.Quote.System.ClemonsFilters.CF8InchQty > 0) {
            PO.InsertPart(schema.groups.ClemonsFilters_Filters.groupId, "119113", this.Quote.System.ClemonsFilters.CF8InchQty); // FLTR- 8"-700 GPM-3/32 CLEMONS
        }
        if (this.Quote.System.ClemonsFilters.CF10InchQty > 0) {
            PO.InsertPart(schema.groups.ClemonsFilters_Filters.groupId, "119114", this.Quote.System.ClemonsFilters.CF10InchQty); // FLTR- 8"-1200 GPM-3/32 CLEMONS
        }
        if (this.Quote.System.ClemonsFilters.CF12InchQty > 0) {
            PO.InsertPart(schema.groups.ClemonsFilters_Filters.groupId, "119115", this.Quote.System.ClemonsFilters.CF12InchQty); // FLTR- 12"-2000GPM-3/32 CLEMONS
        }
    }
}