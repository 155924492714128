import { t } from "i18next";
import { SACRetroSprinklerDropType, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass, { WarningLevels } from "../QuoteClass";
import { NewLastSpanWireOk, OmitStiffenerStabilizerOk, TowerBoxKitOk } from "../Valids.Valids";

export default class SACRetroForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        if (this.Quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro) return;

        let sacRetro = this.Quote.System?.Circle?.SACRetro;

        if (!TowerBoxKitOk(sacRetro.TowerBoxKit, {
            NewLastSpanWireChecked: sacRetro.NewLastSpanWire,
        })){
            this.Quote.Warnings.Add(`"Send new last span wire" cannot be selected if "send tower box" is selected for Swing Arm Retro`);
        }

        if (!NewLastSpanWireOk(sacRetro.NewLastSpanWire, {
            TowerBoxKitChecked: sacRetro.TowerBoxKit,
        })){
            this.Quote.Warnings.Add(`"Send tower box" cannot be selected if "Send new last span wire" is selected for Swing Arm Retro`);
        }

        if (!OmitStiffenerStabilizerOk(sacRetro.OmitStiffenerStabilizer, {
            SACRetroSpanLength: sacRetro.ParentSpanLength,
            SACRetroSpanType: sacRetro.ParentSpanType,
        })){
            this.Quote.Warnings.Add(`"Do not send stiffener-stabilizer" cannot be selected`);
        }

        if (!sacRetro.TowerBoxKit && !sacRetro.NewLastSpanWire) {
            this.Quote.Warnings.Add(t("common-phrases.sltSACRetroOptionsRequiredWarning"));
        }

        if (sacRetro.DropType === SACRetroSprinklerDropType.RigidPVCPoly) {
            this.Quote.Warnings.Add(t("common-phrases.sltSACRetroDropTypePvcPolyWarning"), WarningLevels.Decision);
        }
    }
}