import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    ThemeProvider,
    Typography,
    createTheme,
    styled,
} from "@mui/material";
import { esES as coreEsES } from '@mui/material/locale';
import {
    DataGrid,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridCellEditCommitParams,
    GridColDef,
    GridSelectionModel,
    GridValueOptionsParams,
    esES
} from "@mui/x-data-grid";
import {
    Feature,
    FeatureCollection,
    Geometry,
    GeometryCollection,
    GeometryTypes,
    Point,
    bbox,
    buffer,
    clone,
    envelope,
    featureCollection,
    polygon,
    unkinkPolygon
} from "@turf/turf";
import i18next, { i18n, t } from "i18next";
import * as React from "react";
import { PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import Map from "../../../../../../components/Map";
import DevSettingsCtx from "../../../../../../db/DevSettingsCtx";
import { ImportExportFeature, ImportExportTypes } from "../../../../../../helpers/importExport";
import importDrawStyles from "../Import/importDrawStyles";

interface IProps {
    title: string;
    onRowSelectionChanged?: (selectedRows: IRow[]) => void;


    features: ImportExportFeature[];
    onCancel: () => void;
    onConfirm: (selectedRows: IRow[]) => void;
    disableConfirm?: boolean;
    type: 'import' | 'export'
}

interface IImportFeatureProperties {
    import: boolean;
    importAs?: ImportExportTypes;
    title?: string;
}
type IImportFeature = Feature<Geometry | GeometryCollection, IImportFeatureProperties>;

export interface IRow {
    featureIndex: number;
    feature: ImportExportFeature;
}


type IGeometryTypeImportTypeMap = {
    [geometryType in GeometryTypes]:
        | {
              types: {
                  value: ImportExportTypes;
                  label: string;
                  import?: boolean;
                  export?: boolean;
              }[];
              default: ImportExportTypes;
          }
        | undefined;
}
const createGeometryTypeImportTypeMap = (i18next: i18n): IGeometryTypeImportTypeMap => ({
    Polygon: {
        default: "obstacle",
        types: [
            { value: "boundary", label: i18next.format(t('field-boundary'), 'capitalize-each'), import: true, export: true },
            { value: "pivotCenterBoundary", label: i18next.format(t('pivot-center-boundary'), 'capitalize-each'), import: true, export: true },
            { value: "wetAreaBoundary", label: i18next.format(t('wet-area-boundary'), 'capitalize-each'), import: true, export: true },
            { value: "obstacle", label: i18next.format(t('span-obstacle'), 'capitalize-each'), import: true, export: true },
            { value: "wheelObstacle", label: i18next.format(t('wheel-obstacle'), 'capitalize-each'), import: true, export: true },
            { value: 'mainIrrigatedArea', label: i18next.format(t('main-irrigated-area'), 'capitalize-each'), export: true},
            { value: "sacIrrigatedArea", label: i18next.format(t('sac-irrigated-area'), 'capitalize-each'), export: true },
            { value: "endGunIrrigatedArea", label: i18next.format(t('end-gun-irrigated-area'), 'capitalize-each'), export: true },
            { value: "wrapIrrigatedArea", label: i18next.format(t('wrap-irrigated-area'), 'capitalize-each'), export: true },
            { value: "flexSideIrrigatedArea", label: i18next.format(t('flex-side-irrigated-area'), 'capitalize-each'), export: true },
            { value: "rigidSideIrrigatedArea", label: i18next.format(t('rigid-side-irrigated-area'), 'capitalize-each'), export: true },
        ],
    },
    Point: {
        default: "point",
        types: [
            { value: "pump", label: i18next.format(t('pump'), 'capitalize-each'), import: true, export: true },
            { value: "point", label: i18next.format(t('point'), 'capitalize-each'), import: true, export: true },
            { value: 'label', label: i18next.format(t('label'), 'capitalize-each'), import: true, export: true },
            { value: 'pivotCenter', label: i18next.format(t('pivot-center'), 'capitalize-each'), export: true}
            // { value: "cross", label: "Cross" },
        ],
    },
    LineString: {
        default: "line",
        types: [
            { value: "electricLine", label: i18next.format(t('electric-line'), 'capitalize-each'), import: true, export: true },
            { value: "line", label: i18next.format(t('line'), 'capitalize-each'), import: true, export: true },
            { value: "waterLine", label: i18next.format(t('water-line'), 'capitalize-each'), import: true, export: true },
            { value: "canal", label: i18next.format(t('canal'), 'capitalize-each'), import: true, export: true },
            { value: "feedLine", label: i18next.format(t('lateral-feed-line'), 'capitalize-each'), export: true },
        ],
    },
    GeometryCollection: undefined,
    MultiPolygon: {
        default: "sacIrrigatedArea",
        types: [
            { value: "sacIrrigatedArea", label: i18next.format(t('sac-irrigated-area'), 'capitalize-each'), export: true },
            { value: "endGunIrrigatedArea", label: i18next.format(t('end-gun-irrigated-area'), 'capitalize-each'), export: true },
        ],
    },
    MultiPoint: undefined,
    MultiLineString: undefined,
});

const createRowFromFeature = (featureIndex: number, f: Feature, type: "import" | "export", geometryTypeImportTypeMap: IGeometryTypeImportTypeMap): IRow => {
    const importType = f.properties?.importType;
    const importMapItem = geometryTypeImportTypeMap[f.geometry.type as GeometryTypes];
    const importMapTypes = importMapItem && importMapItem.types.filter(x => type === 'import' ? x.import : x.export);
    let rowImportType: ImportExportTypes | undefined = undefined;
    if (importType && importMapTypes && importMapTypes.find(x => x.value === importType)) {
        rowImportType = importType;
    }
    else if (importMapItem && importMapTypes && importMapTypes.find(x => x.value === importMapItem.default)) {
        rowImportType = importMapItem.default;
    }
    const feature: ImportExportFeature = structuredClone(f);
    if (!f.properties) {
        f.properties = {};
    }
    feature.properties.importType = rowImportType;
    return {
        featureIndex,
        feature
    };
} 
const getRowsFromFeatures = (features: Feature[], type: "import" | "export", geometryTypeImportTypeMap: IGeometryTypeImportTypeMap) => {
    return features.map((f, idx) => createRowFromFeature(idx, f, type, geometryTypeImportTypeMap));
}

const getColumnsFromType = (type: "import" | "export", i18next: i18n, geometryTypeImportTypeMap: IGeometryTypeImportTypeMap) => {
    const columns: GridColDef<IRow>[] = [
        { field: "id", headerName: t('id'), width: 70, valueGetter: (params) => params.row.featureIndex + 1 },
        { field: "type", headerName: i18next.format(t('feature-type'), 'capitalize-each'), width: 130, valueGetter: (params) => params.row.feature.geometry.type },
        {
            field: "importAs",
            headerName: i18next.format(t('as'), 'capitalize'),
            flex: 1,
            editable: true,
            type: "singleSelect",
            valueOptions: (param) => {
                if (!param.row) return [];
                const x = geometryTypeImportTypeMap[param.row.feature.geometry.type];
                if (!x) return [];
                if (type === 'import') {
                    return x.types.filter(x => x.import);
                }
                else {
                    return x.types.filter(x => x.export);
                }
            },
            valueGetter: (params) => {
                if (!params.row) return [];
                const valueOptions = params.colDef.valueOptions as ((params: GridValueOptionsParams<any>) => {
                    value: any;
                    label: string;
                }[]);
                const options = valueOptions(params);
                return options.find(x => x.value === params.row.feature.properties.importType)?.label;
            },
            valueSetter: (params) => {
                params.row.feature.properties.importType = params.value;
                return params.row;
            }
        },
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
        },
    ];
    return columns;
}

const ImportExportDialogBase: React.FC<PropsWithChildren<IProps>> = ({ 
    title, onRowSelectionChanged,
    features, onConfirm, onCancel,
    children, disableConfirm,
    type
}) => {

    const devSettingsState = useContext(DevSettingsCtx);
    let dgTheme = createTheme();
    if (i18next.language === "es") dgTheme = createTheme({}, esES, coreEsES);//TODO: how do we support other languages here?

    const bounds: mapboxgl.LngLatBoundsLike = useMemo(() => {
        if (features.length === 0) {
            return [-180, -90, 180, 90]
        }
        const fc = featureCollection(features) as FeatureCollection;
        const env = envelope(fc);
        const bounds = bbox(buffer(env, 1000, { units: "feet" })) as [number, number, number, number];
        return bounds;
    }, [features]);

    const geometryTypeImportTypeMap = createGeometryTypeImportTypeMap(i18next);
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridSelectionModel>([]);    
    const columns = useMemo(() => getColumnsFromType(type, i18next, geometryTypeImportTypeMap), [type]);
    const [rows, setRows] = useState<IRow[]>(getRowsFromFeatures(features, type, geometryTypeImportTypeMap));
    const [drawFeatures, setDrawFeatures] = useState<Feature[]>([]);

    const updateDrawFeatures = () => {
        const nextFeatures: IImportFeature[] = [];
        for (let i = 0; i < rows.length; i++) {
            const f = clone(rows[i].feature);
            const properties: IImportFeatureProperties = {
                import: rowSelectionModel.includes(rows[i].featureIndex),
                importAs: rows[i].feature.properties.importType,
                title: rows[i].feature.properties.label
            };
            f.properties = properties;
            nextFeatures.push(f as IImportFeature);
        }
        setDrawFeatures(nextFeatures);
    };

    useEffect(() => {
        updateDrawFeatures();
    }, [rows, rowSelectionModel]);

    const importRows = useMemo(() => {
        
        const importRows = rowSelectionModel
            .map((id) => rows.find((x) => x.featureIndex === id))
            .filter((x): x is IRow & { importAs: ImportExportTypes } => x !== undefined && x.feature.properties.importType !== undefined);
           
        return importRows;

    }, [rows, rowSelectionModel, onRowSelectionChanged]);

    useEffect(() => { 
        if (onRowSelectionChanged) {
            onRowSelectionChanged(importRows);
        }
    }, [onRowSelectionChanged, importRows])

    const processCellEditCommit = (params: GridCellEditCommitParams, event, detail) => {
        if (params.field === "importAs") {
            const rowIndex = rows.findIndex((x) => x.featureIndex === params.id);
            if (rowIndex === -1) return;
            if (rows[rowIndex].feature.properties.importType === params.value) return;
            const newRow: IRow = {
                ...rows[rowIndex]
            };
            newRow.feature.properties.importType = params.value
            const newRows = [...rows];
            newRows[rowIndex] = newRow;
            setRows(newRows);
        }
    };


    const featureTransformations = {
        pointsToPolygons: {
            disabled: importRows.filter(x => x.feature.geometry.type === 'Point').length < 3,
            onClick: () => {
                const selectedPoints = importRows.filter(x => x.feature.geometry.type === 'Point');
                if (selectedPoints.length < 3) return;
                try {
                    let nextId = (Math.max(...rows.map(x => x.featureIndex)) | 0) + 1;
                    const nextRows = rows.filter(r => selectedPoints.every(p => p.featureIndex !== r.featureIndex));

                    // // method 1: simple + unkink
                    const coords = selectedPoints.map(x => (x.feature.geometry as Point).coordinates)
                    const p = polygon([
                        [
                            ...coords,
                            coords[0]
                        ]
                    ]);
                    const k = unkinkPolygon(p);
                    for (const kp of k.features) {
                        nextRows.push(
                            createRowFromFeature(nextId, kp, type, geometryTypeImportTypeMap)
                        )
                        nextId++;
                    }
                    // // end method 1

                    
                    // // method 2: concave
                    // const points = selectedPoints.map(x => (x.feature as Feature<Point>));
                    // const fc = featureCollection(points);
                    // const hull = concave(fc);
                    // const polys = (hull.geometry.type === 'MultiPolygon')
                    //     ? hull.geometry.coordinates
                    //     : [ hull.geometry.coordinates ];
                    // for (const ring of polys) {
                    //     const p = polygon(ring);
                    //     nextRows.push(
                    //         createRowFromFeature(nextId, p, type, geometryTypeImportTypeMap)
                    //     )
                    //     nextId++;
                    // }
                    // // end method 2

                    
                    // // method 3: convex
                    // const points = selectedPoints.map(x => (x.feature as Feature<Point>));
                    // const fc = featureCollection(points);
                    // const poly = convex(fc);
                    // nextRows.push(
                    //     createRowFromFeature(nextId, poly, type, geometryTypeImportTypeMap)
                    // )
                    // // end method 3

                    setRows(nextRows);
                }
                catch {
                    console.log("Failed to combine points into polygon");
                }
            }
        }
    }

    return (
        <Dialog onClose={onCancel} open={true} maxWidth="md" fullWidth={true}>
            <DialogTitle>{title}</DialogTitle>

            <DialogContent dividers>
                <Stack direction={"column"} gap={2}>

                    <Stack direction={"row"} gap={2}>
                        <Box mt={2} height={400} flex={1}>
                            <ThemeProvider theme={dgTheme}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={5}
                                    checkboxSelection
                                    editMode="cell"
                                    selectionModel={rowSelectionModel}
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setRowSelectionModel(newSelectionModel);
                                    }}
                                    onCellEditCommit={processCellEditCommit}
                                    disableSelectionOnClick
                                    isRowSelectable={(params) => {
                                        const a = geometryTypeImportTypeMap[params.row.feature.geometry.type];
                                        if (a === undefined) return false;
                                        if (type === 'import') {
                                            return a.types.some(t => t.import)
                                        }
                                        else {
                                            return a.types.some(t => t.export)
                                        }
                                    }}
                                    getRowId={(params) => params.featureIndex}
                                />
                            </ThemeProvider>
                        </Box>
                        <Box mt={2} height={400} flex={1}>
                            <MapDiv>
                                {bounds !== undefined && (
                                    <Map
                                        bounds={bounds}
                                        drawFeatures={drawFeatures}
                                        overideDrawStyles={importDrawStyles}
                                        interactive={false}
                                        key={"map_layer_type_" + devSettingsState.mapSettings.layerType} //if map layer settings change re-render this section
                                    />
                                )}
                            </MapDiv>
                        </Box>
                    </Stack>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography gutterBottom variant="subtitle2" component="div">
                                Feature Transformation
                            </Typography>
                            <Button
                                disabled={featureTransformations.pointsToPolygons.disabled}
                                onClick={featureTransformations.pointsToPolygons.onClick}
                            >
                                Points to Polygon
                            </Button>
                        </CardContent>
                    </Card>

                    <Box mt={2} minHeight={100} flex={1}>
                        {children}
                    </Box>

                </Stack>
            </DialogContent>

            <DialogActions>
                <Button onClick={onCancel}>{t("cancel")}</Button>
                <Button onClick={() => onConfirm(importRows)} disabled={rowSelectionModel.length === 0 || disableConfirm}>
                    {t("confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const MapDiv = styled("div")`
    position: relative;
    width: 100%;
    height: 400px;
`;

export default ImportExportDialogBase;
