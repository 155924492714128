import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from '../auth/IAuthState';
import { setValue } from '../helpers/objectPathResolver';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange } from './helpers';

const actionTypeId = "UpdateLayoutProperty";

interface ActionData extends IActionData {
    layoutId: string;

    property: string;
    value: any;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;

    setValue(state.layouts[data.layoutId], data.property, data.value);
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
});

export const createNewUpdateLayoutPropertyAction =
    (layoutId: string, property: string, value: any, authState: IAuthState) => createAction(
        actionTypeId,
        {
            layoutId,
            property,
            value
        } as ActionData,
        authState);