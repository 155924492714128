import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction } from './helpers';

const actionTypeId = "SetProjectStateAction";

interface ActionData extends IActionData {
    state: IProject;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;

    Object.keys(state).forEach(key => delete state[key]);
    Object.assign(state, data.state);
});

export const setProjectStateAction = (
    authState: IAuthState, 
    state: IProject
) => {
    const action = createAction(
        actionTypeId,
        {
            state
        } as ActionData,
        authState);
    return {
        action
    };
};