import { Card, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { EndOfSystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import IEndOfSystemTypeChoice from "rdptypes/roe/IEndOfSystemTypeChoice";
import * as React from "react";
import { FC } from "react";
import { createSetEndOfSystemAction } from "../../../../actions/SetEndOfSystemAction";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import IComponentRenderContext from "../IComponentRenderContext";
import EndBoomSelect from "./EndBoomSelect";
import EndOfSystemTypeSelect from "./EndOfSystemTypeSelect";
import PathDataControl from "./PathDataControl";
import SwingArmLengthSelect from "./SwingArmLengthSelect";
import { getEndOfSystemValidation } from "./endOfSystemValidator";

interface Props {
    cmp: IEndOfSystemTypeChoice,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    parentGroupLabel: string | null; //either the same as the current component, don't show title; different from parent, do show title in grey; null, not in a group so display in blue
    ctx: IComponentRenderContext;
};

// Note: Swing arm is only appicable to the flanged side
const EndOfSystemTypeChoiceRenderer: FC<Props> = (props) => {

    const authState = React.useContext(AuthCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const side = getSide(system, props.cmp.side);
    const endOfSystemValidation = getEndOfSystemValidation(props.cmp, props.ctx);

    // console.log("endOfSystemValidation", endOfSystemValidation, props.ctx.vws)

    return (
        <Card style={{padding: 10}} variant="outlined">
            <Stack gap={2}>
                <EndOfSystemTypeSelect
                    systemId={props.systemId}
                    side={props.cmp.side}
                    layoutId={props.layoutId}
                    dbPrj={props.dbPrj}
                    ctx={props.ctx}
                    endOfSystemValidation={endOfSystemValidation}
                />

                {
                    side.EndOfSystem.EndOfSystemType === EndOfSystemTypes.EndBoom && (
                        <EndBoomSelect
                            systemId={props.systemId}
                            side={props.cmp.side}
                            layoutId={props.layoutId}
                            dbPrj={props.dbPrj}
                            ctx={props.ctx}
                            endOfSystemValidation={endOfSystemValidation}
                        />
                    )
                }
                

                {
                    props.cmp.side === SideEnum.Flanged && side.EndOfSystem.EndOfSystemType === EndOfSystemTypes.SAC && (
                        <>
                            <SwingArmLengthSelect
                                systemId={props.systemId}
                                side={props.cmp.side}
                                layoutId={props.layoutId}
                                dbPrj={props.dbPrj}
                                ctx={props.ctx}
                                endOfSystemValidation={endOfSystemValidation}
                            />
                            <FormControl>
                                <RadioGroup
                                    name="leading-or-trailing"
                                    value={system.Circle.SwingArm.LeadingSwingArm ? "leading" : "trailing"}
                                    onChange={(ev) => {
                                        const sacIsLeading = ev.target.value === 'leading';
                                        props.ctx.pushActionAndImproveScores(
                                            createSetEndOfSystemAction(
                                                props.layoutId,
                                                props.systemId,
                                                props.cmp.side,
                                                {
                                                    endOfSystemType: EndOfSystemTypes.SAC,
                                                    swingArmLength: getSide(system, props.cmp.side).EndOfSystem.SwingArmLength,
                                                    updateSacIsLeading: {
                                                        sacIsLeading
                                                    } 
                                                },
                                                authState
                                            ), [], true
                                        )
                                    }}
                                >
                                    <Stack direction={'row'}>
                                        <FormControlLabel value="trailing" control={<Radio />} label="Trailing" />
                                        <FormControlLabel value="leading" control={<Radio />} label="Leading" />
                                    </Stack>
                                </RadioGroup>
                            </FormControl>

                            { !props.ctx.editingDealerDefaults && <PathDataControl
                                dbPrj={props.dbPrj}
                                layoutId={props.layoutId}
                                systemId={props.systemId}
                            /> }
                        </>
                    )
                }

            </Stack>
        </Card>
    )
};

export default EndOfSystemTypeChoiceRenderer;