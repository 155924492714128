import { EndOfSystemTypes, ISystemBase } from "../project/ISystemBase.AutoGenerated";
import { ISpan } from "../project/Types";
import { SideEnum, getSide } from "./SideEnum";

export const getSpansWithoutEndOfSystem = (sys: ISystemBase, side: SideEnum): ISpan[] => {
    let spans = getSide(sys, side).Span.slice();
    while (spans.length && (spans[spans.length - 1].EndBoom || spans[spans.length - 1].SwingArm)) {
        spans = spans.slice(0, -1);
    }
    return spans;
}

export const getSpansWithoutSAC = (sys: ISystemBase, side: SideEnum): ISpan[] => {
    const systemSide = getSide(sys, side);
    if (systemSide.EndOfSystem.EndOfSystemType !== EndOfSystemTypes.EndBoom) {
        return getSpansWithoutEndOfSystem(sys, side);
    }
    let spans = systemSide.Span.slice();  
    return spans;
}