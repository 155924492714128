export enum MotorTypes {
    NoMotor,
    Low,
    Standard,
    High,
    TwoHP,
    FiveHP,
    TwoThirtyVolt,
    A100Pump,
    SACAirComp,
    VRIAirComp,
    DualSprinklerPkAirComp,
    WrapJointAirComp
}

export enum MotorLocations {
    PivotCenter,
    PowerTower,
    A100Pump,
    Tower,
    SecondaryPT,
    SACAirComp,
    SACSteering,
    SACTower,
    BoosterPump,
    VRIAirCompPowerTower,
    VRIAirCompOuterTower,
    DualSprinklerPkAirComp,
    WrapJointAirComp
}