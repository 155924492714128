import { Polygon } from '@turf/turf';
import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import SegmentHelper from '../GeometryHelpers/SegmentHelper';
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordProjectChange } from './helpers';

const actionTypeId = "NewWheelObstacle";

interface ActionData extends IActionData {
    layoutId: string,
    polygon: Polygon;
    clearance?: number;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const layout = state.layouts[data.layoutId];
    if (!layout) return;
    layout.wheelObstacles.push({
        segments: SegmentHelper.polygonToSegments(data.polygon, data.clearance)
    });
    recordProjectChange(action, state);
});


export const createNewWheelObstacleAction = (layoutId: string, data: Omit<ActionData, 'layoutId'>, authState: IAuthState) => {
    return createAction(
        actionTypeId,
        {
            layoutId,
            ...data
        } as ActionData,
        authState);
};