import { Checkbox, FormControlLabel, TableCell, TableRow, TextField } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import { FC, useState } from "react";
import DollarFormattedInput from "../DollarFormattedInput";

interface Props {
    savePrice?: (priceCents: number) => void;
    saveIsTaxable?: (isTaxable: boolean) => void;
    priceCents: number | undefined;
    isTaxable?: boolean | undefined;
    canBeTaxable: boolean;
    label: string;
    priceReadonly?: boolean;
    taxableReadonly?: boolean;
};

const PricingTableRow: FC<Props> = (props) => {
    const {isTaxable, priceCents, saveIsTaxable, savePrice, label, priceReadonly, canBeTaxable, taxableReadonly} = props;
    const [priceUSD, setPriceUSD] = useState<number| undefined>(priceCents ? priceCents/100 : 0);

    return (
        <>
            <TableRow>
                <TableCell sx={{textAlign: "right", padding: "3px 16px"}}>{label}</TableCell>
                <TableCell sx={{textAlign: "center", padding: "3px 16px"}}>
                    <TextField size="small" value={(priceReadonly && typeof priceCents !== "undefined") ? priceCents/100 :  typeof priceUSD === "undefined" ? "" : priceUSD} disabled={priceReadonly} InputProps={{
                        inputComponent: DollarFormattedInput as any}} onChange={(e) => {
                            if (!priceReadonly && savePrice){
                                if (e.target.value === ""){
                                    setPriceUSD(undefined);
                                }
                                let val = parseFloat(e.target.value);
                                if (isNaN(val)) return;
                                setPriceUSD( val );
                            }
                        }} onBlur={(e) => {
                            if (!priceReadonly && savePrice){
                                let val = e.target.value;
                                let valNum = parseFloat(val.replace("$", "").replace(",", ""));
                                if (val !== "" && isNaN(valNum)) return;

                                let stateVal = val === "" ? undefined : valNum;
                                let stateValNum = typeof(stateVal) === "undefined" ? 0 : stateVal;
                                setPriceUSD(stateValNum);
                                savePrice(stateValNum * 100);
                            }
                        }}
                    />
                </TableCell>
                <TableCell sx={{textAlign: "left", padding: "3px 16px"}}>
                    {canBeTaxable && <FormControlLabel label={i18next.format(i18next.t("tax"), 'capitalize')} control={<Checkbox disabled={taxableReadonly} checked={isTaxable} onChange={(e) => {
                        if (!taxableReadonly){
                            let checked = e.target.checked;
                            saveIsTaxable(checked);
                        }
                    }}/>}/>}
                </TableCell>
            </TableRow>
        </>
    );
};

export default PricingTableRow;