import { DeviceTypes } from "rdptypes/project/ISprinklers";
import {
    SprinklerManufacturerTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
export const Manufacturer = (deviceType: DeviceTypes): SprinklerManufacturerTypes => {
    switch (deviceType) {
        case DeviceTypes.KometTwisterWhite:
        case DeviceTypes.KometTwisterBlack:
        case DeviceTypes.KometTwisterBlue:
        case DeviceTypes.KometTwisterYellow:
        case DeviceTypes.KometSpray:
        case DeviceTypes.KometSprayPartCircle:
        case DeviceTypes.KometTwisterPartCircle:
            return SprinklerManufacturerTypes.Komet;
            break;
        case DeviceTypes.Senninger12MediumSpacingPlasticImpact:
        case DeviceTypes.Senninger6DoubleWhiteVanePlasticImpact:
        case DeviceTypes.Senninger6DoubleRedVanePlasticImpact:
        case DeviceTypes.SenningerXiWobBlue:
        case DeviceTypes.SenningerXiWobGray:
        case DeviceTypes.SenningerXiWobBlack:
        case DeviceTypes.SenningerXiWobWhite:
        case DeviceTypes.SenningerXcelWobbler:
        case DeviceTypes.SenningerStandardAngleIWobGray:
        case DeviceTypes.SenningerLowAngleIWobWhite:
        case DeviceTypes.SenningerHighAngleIWobBlack:
        case DeviceTypes.SenningerLowAngleIWobBlue:
        case DeviceTypes.SenningerSuperSpray:
        case DeviceTypes.SenningerQuadSpray:
        case DeviceTypes.SenningerLDNSpray:
        case DeviceTypes.Senninger180Fan:
            return SprinklerManufacturerTypes.Senninger;
            break;
        case DeviceTypes.NelsonR3000PartCircle:
        case DeviceTypes.NelsonD3000PartCircle:
        case DeviceTypes.NelsonS3000PartCircle:
        case DeviceTypes.Nelson1518MediumSpacingBrassImpact:
        case DeviceTypes.Nelson15Single3RNBrassImpact:
        case DeviceTypes.Nelson15Double3RNBrassImpact:
        case DeviceTypes.Nelson15DoubleFCNBrassImpact:
        case DeviceTypes.Nelson8Double3RNPlasticImpact:
        case DeviceTypes.Nelson8DoubleFCNPlasticImpact:
        case DeviceTypes.NelsonO3000OrbitorPurple:
        case DeviceTypes.NelsonO3000OrbitorBlack:
        case DeviceTypes.NelsonO3000OrbitorBlue:
        case DeviceTypes.NelsonO3030OrbitorFXBlack:
        case DeviceTypes.NelsonO3030OrbitorFXWhite:
        case DeviceTypes.NelsonS3000SpinnerRed:
        case DeviceTypes.NelsonS3000SpinnerPurple:
        case DeviceTypes.NelsonS3000SpinnerYellow:
        case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure:
        case DeviceTypes.NelsonS3000SpinnerYellowLowPressure:
        case DeviceTypes.NelsonD3000Spray:
        case DeviceTypes.NelsonD3000FCNSpray:
        case DeviceTypes.NelsonR3000RotatorRed:
        case DeviceTypes.NelsonR3000RotatorBlue:
        case DeviceTypes.NelsonR3000FCNRotatorBlue:
        case DeviceTypes.NelsonR3000RotatorGreen:
        case DeviceTypes.NelsonR3000FCNRotatorGreen:
        case DeviceTypes.NelsonR3000RotatorOrange:
        case DeviceTypes.NelsonR3000RotatorBrown:
        case DeviceTypes.NelsonR3000RotatorOlive:
        case DeviceTypes.NelsonR3000RotatorWhite:
        case DeviceTypes.NelsonA3000AcceleratorMaroon:
        case DeviceTypes.NelsonA3000AcceleratorGold:
        case DeviceTypes.NelsonA3000AcceleratorNavy:
        case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
            return SprinklerManufacturerTypes.Nelson;
            break;
        default:
            return SprinklerManufacturerTypes.None;
            break;
    }
}
export const IsKometSpray = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.KometSpray, DeviceTypes.KometSprayPartCircle].indexOf(deviceType) !== -1;
}
export const IsKometTwister = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.KometTwisterWhite, DeviceTypes.KometTwisterBlack, DeviceTypes.KometTwisterBlue, DeviceTypes.KometTwisterYellow, DeviceTypes.KometTwisterPartCircle].indexOf(deviceType) !== -1;
}
export const IsSenningerIWob = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.SenningerStandardAngleIWobGray, DeviceTypes.SenningerLowAngleIWobWhite, DeviceTypes.SenningerHighAngleIWobBlack, DeviceTypes.SenningerLowAngleIWobBlue].indexOf(deviceType) !== -1;
}
export const IsSenningerXiWob = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.SenningerXiWobBlue, DeviceTypes.SenningerXiWobGray, DeviceTypes.SenningerXiWobBlack, DeviceTypes.SenningerXiWobWhite].indexOf(deviceType) !== -1;
}
export const IsSenningerXcel = (deviceType: DeviceTypes): boolean => {
    return deviceType === DeviceTypes.SenningerXcelWobbler;
}
export const IsSenningerSuperSpray = (deviceType: DeviceTypes): boolean => {
    return deviceType === DeviceTypes.SenningerSuperSpray;
}
export const IsSenningerQuadSpray = (deviceType: DeviceTypes): boolean => {
    return deviceType === DeviceTypes.SenningerQuadSpray;
}
export const IsSenningerLDNSpray = (deviceType: DeviceTypes): boolean => {
    return deviceType === DeviceTypes.SenningerLDNSpray;
}
export const IsSenninger180Fan = (deviceType: DeviceTypes): boolean => {
    return deviceType === DeviceTypes.Senninger180Fan;
}
export const IsSenningerPlasticImpact = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.Senninger12MediumSpacingPlasticImpact, DeviceTypes.Senninger6DoubleWhiteVanePlasticImpact, DeviceTypes.Senninger6DoubleRedVanePlasticImpact].indexOf(deviceType) !== -1;
}
export const IsNelsonImpact = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.Nelson1518MediumSpacingBrassImpact, DeviceTypes.Nelson15Single3RNBrassImpact, DeviceTypes.Nelson15Double3RNBrassImpact, DeviceTypes.Nelson15DoubleFCNBrassImpact, DeviceTypes.Nelson8Double3RNPlasticImpact, DeviceTypes.Nelson8DoubleFCNPlasticImpact].indexOf(deviceType) !== -1;
}
export const IsNelsonOrbitor = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.NelsonO3000OrbitorPurple, DeviceTypes.NelsonO3000OrbitorBlack, DeviceTypes.NelsonO3000OrbitorBlue].indexOf(deviceType) !== -1;
}
export const IsNelsonOrbitorFX = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.NelsonO3030OrbitorFXBlack, DeviceTypes.NelsonO3030OrbitorFXWhite].indexOf(deviceType) !== -1;
}
export const IsNelsonSpinner = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.NelsonS3000SpinnerRed, DeviceTypes.NelsonS3000SpinnerPurple, DeviceTypes.NelsonS3000SpinnerYellow].indexOf(deviceType) !== -1;
}
export const IsNelsonSpinnerLowPressure = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.NelsonS3000SpinnerPurpleLowPressure, DeviceTypes.NelsonS3000SpinnerYellowLowPressure].indexOf(deviceType) !== -1;
}
export const IsNelsonRotator = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.NelsonR3000RotatorRed, DeviceTypes.NelsonR3000RotatorBlue, DeviceTypes.NelsonR3000RotatorGreen, DeviceTypes.NelsonR3000RotatorOrange, DeviceTypes.NelsonR3000RotatorBrown, DeviceTypes.NelsonR3000RotatorOlive, DeviceTypes.NelsonR3000RotatorWhite, DeviceTypes.NelsonR3000FCNRotatorBlue, DeviceTypes.NelsonR3000FCNRotatorGreen].indexOf(deviceType) !== -1;
}
export const IsNelsonSpray = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.NelsonD3000Spray, DeviceTypes.NelsonD3000FCNSpray].indexOf(deviceType) !== -1;
}
export const IsNelsonAccelerator = (deviceType: DeviceTypes): boolean => {
    return [DeviceTypes.NelsonA3000AcceleratorMaroon, DeviceTypes.NelsonA3000AcceleratorGold, DeviceTypes.NelsonA3000AcceleratorNavy, DeviceTypes.NelsonA3000AcceleratorNavyLowPressure].indexOf(deviceType) !== -1;
}
