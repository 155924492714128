import { PivotingLateralPivotCenterTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import * as sysf from "../SystemFunctions";
import { IsEDMP } from "../SystemFunctions";
import { BallastKitOk, HDWheelGearOk, PivotCenterOptionOK, PivotCenterWithSSRiserOK, RotateCartOk, SafetyValveOk } from "../Valids.Valids";

export default class HoseFeedForm {

    constructor(private Quote: QuoteClass){
    }

    public validate = (): void => {
        if (sysf.FieldSets(this.Quote.System).HoseFeed.DataValid()){
            let hf = this.Quote.System.Lateral.HoseFeed;

            if (!PivotCenterOptionOK(hf.PivotingLateralPivotCenterType ?? PivotingLateralPivotCenterTypes.None, {HoseFeedType: hf.HoseFeedType})){
                this.Quote.Warnings.Add(`Lateral hose feed pivot center option invalid`);
            }

            if (!PivotCenterWithSSRiserOK(hf.PivotCenterWithSSRiserPipe, {HoseFeedType: hf.HoseFeedType})){
                this.Quote.Warnings.Add(`Lateral hose feed pivot center with SS riser invalid`);
            }

            if (!BallastKitOk(hf.BallastKit, {HoseFeedType: hf.HoseFeedType, ElectricDragCordLength: hf.ElectricDragCordLength})){
                this.Quote.Warnings.Add(`Lateral hose feed ballast kit not OK`);
            }

            if (!RotateCartOk(hf.RotateCart, {HoseFeedType: hf.HoseFeedType})){
                this.Quote.Warnings.Add(`Lateral hose feed rotate cart not OK`);
            }

            if (!HDWheelGearOk(hf.HDWheelGear, {HoseFeedType: hf.HoseFeedType, IsTowable: hf.Towable})){
                this.Quote.Warnings.Add(`Lateral hose feed HD wheel not OK`);
            }

            if (!SafetyValveOk(hf.SafetyValve, {SystemType: this.Quote.System.SystemProperties.SystemType, IsEDMP: IsEDMP(this.Quote.System), HoseFeedType: hf.HoseFeedType})){
                this.Quote.Warnings.Add(`Lateral hose feed safety valve not OK`);
            }
        }
    }
}