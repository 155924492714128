/* 
 * NOTE:
 *
 * Sometimes systems will overlap the boundary if there is 0 buffer, I believe this
 * is due to some optimizers using meters, and the validation check being done in WGS80.
 * Until a time when all optimizers/validation/rendering can be made consistent, the following fix is applied:
 * 
 * A minimum buffer of 1ft is currently used for:
 *  - Boundary clearances
 * Regardless of the users choice
 *  
 * 
 * Workers cannot access localStorage, the functions are required by:
 * - SAC Optimizer (which runs in a worker)
*/

import { Feature, MultiPolygon, Polygon, buffer, polygon } from "@turf/turf";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import CenterPivotGeometryHelper from "../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper from "../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import IProject from "../../model/project/IProject";

const MIN_ALLOWABLE_BUFFER_FEET = 0;

const turfBufferPolygonToPolygons = (poly: Polygon, bufferFeet: number): Polygon[] => {
    // Note, this function is here as a helper as the turf return type inferred from the 
    // input type (Polygon) assumes that the result must be a Polygon.
    // The result may also be a multipolygon or undefined!
    if (bufferFeet === 0) {
        return [poly];
    }
    const bufferResult: Feature<(Polygon | MultiPolygon)> | undefined = buffer(
        poly, 
        bufferFeet,
        { units: 'feet' }
    );
    if (!bufferResult) return [];
    if (bufferResult.geometry.type === 'Polygon') {
        return [ bufferResult.geometry ];
    }
    return bufferResult.geometry.coordinates.map(ring => polygon(ring).geometry);
}

export const applyObstacleClearanceTo = (poly: Polygon, clearanceFeet: number): Polygon[] => {
    // increases the polygon
    // probably no way to result in more or less then 1 polygon,
    // check and return an array of polygons none the less
    const bufferFeet = Math.max(
        clearanceFeet,
        MIN_ALLOWABLE_BUFFER_FEET
    );
    return turfBufferPolygonToPolygons(poly, bufferFeet);
}

export const getExistingSystemPolygons = (project: IProject, layoutId: string, ignoreSystemIds: string[] = []) => {
    const layout = project.layouts[layoutId];
    const existingSystems = Object.keys(layout.systems)
        .filter(systemId => !ignoreSystemIds.includes(systemId))
        .map(systemId => {
            const system = layout.systems[systemId];
            switch (system.SystemProperties.SystemType) {
                case SystemTypes.CenterPivot: {
                    const a = new CenterPivotGeometryHelper(
                        { 
                            project,
                            layoutId,
                            systemId
                        }
                    );
                    return a.getAreaPolygon({includeEndguns: false, includeSAC: true});
                }
                case SystemTypes.CanalFeedMaxigator:
                    case SystemTypes.HoseFeedMaxigator:
                    {
                    const a = new LateralGeometryHelper(
                        { 
                            project,
                            layoutId,
                            systemId: systemId
                        }
                    );
                    return a.getAreaPolygon();
                }
                default: {
                    throw new Error(`Unkown system type: ${system.SystemProperties.SystemType}`);
                }
            }
        })
        .filter((p): p is Polygon => p !== undefined);
    return existingSystems;
}