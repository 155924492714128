import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import i18next, { t } from "i18next";
import { IAdditionalSpanData } from "rdptypes/project/ISprinklerChart";
import * as React from "react";
import { FC, useContext, useEffect } from "react";
import DevSettingsCtx from "../../../../../db/DevSettingsCtx";
import { gpmToM3ph } from "../../../../../docGeneration/DocumentGenerationHelpers";
import { pageStyles, secondaryColor } from "../../../styles";

interface Props {
    additionalSpanData: IAdditionalSpanData[];
    calcLoss: (spanNumber: number) => number | string;
};

const SpanIrrigationTable: FC<Props> = (props) => {

    useEffect(() => {
       
    }, []);

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    formInputLabelStyle["color"] = secondaryColor;

    const settings = useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;

    return (
        <Stack direction="column">
             <TableContainer component={Paper} className="sprinkler-results-table">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell  style={{textAlign: 'center'}}><b>Span</b></TableCell>
                            <TableCell  style={{textAlign: 'center'}}><b>{displaySettings.pressures === "psi" ? t("common-phrases.sltPSI") : i18next.format(t("common-phrases.sltBar"), "capitalize")} Loss</b></TableCell>
                            <TableCell  style={{textAlign: 'center'}}><b>{displaySettings.irrigationRates === "gpm" ? "GPM" : "m3/h"} Req</b></TableCell>
                            <TableCell  style={{textAlign: 'center'}}><b>{displaySettings.irrigationRates === "gpm" ? "GPM" : "m3/h"} Del</b></TableCell>
                            <TableCell  style={{textAlign: 'center'}}><b>Water %</b></TableCell>       
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.additionalSpanData &&
                            props.additionalSpanData.map((asd, i) => 
                                <TableRow  key={i}>
                                    <TableCell  style={{textAlign: 'center'}}><b>{i + 1}</b></TableCell>
                                    <TableCell  style={{textAlign: 'center'}}>{props.calcLoss(i+1)}</TableCell>
                                    <TableCell style={{textAlign: 'center'}}>{asd.GPMRequired ? displaySettings.irrigationRates === "gpm" ? asd.GPMRequired.toFixed(1) : gpmToM3ph(asd.GPMRequired).toFixed(1) : "-"}</TableCell>
                                    <TableCell style={{textAlign: 'center'}}>{asd.GPMDelivered ? displaySettings.irrigationRates === "gpm" ? asd.GPMDelivered.toFixed(1) : gpmToM3ph(asd.GPMDelivered).toFixed(1) : "-"}</TableCell>
                                    <TableCell style={{textAlign: 'center'}}>{asd.GPMDelivered && asd.GPMRequired ? ((asd.GPMDelivered/asd.GPMRequired) * 100).toFixed() : "-"}%</TableCell>
                                </TableRow>
                                )
                            
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
};

export default SpanIrrigationTable;