import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { executeAction, registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordProjectChange } from './helpers';

const actionTypeId = "MultiAction";

export interface MultiActionData extends IActionData {
    actions: IAction[];
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as MultiActionData;

    for (const action of data.actions) {
        executeAction(action, state);
    }

    recordProjectChange(action, state);
});

export const createNewMultiAction = (actions: IAction[], authState: IAuthState) => createAction(
        actionTypeId,
        {
            actions
        } as MultiActionData,
        authState);