import { EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { FloatsAreEqual, Interprolate } from "./CommonFunctions";
import { CalculateGpmRadiusResult, EndGunHelper } from "./EndGunHelper";
import { PressureHelper } from "./EndGunHelper.PressureHelper";
import { EndGunItem } from "./EndGunInfo";

export class GPMAndRadiusEstimate {
    public GPM: number = 0;
    public Radius: number = 9;
    public Pressure: number = 0;
}

export class GPMRadiusHelper {
    private LastResult: CalculateGpmRadiusResult;
    private EGH: EndGunHelper;
    private PH: PressureHelper;
    public EndGunItemsByType: EndGunItem[] = [];
    constructor(EGHelper: EndGunHelper) {
        this.EGH = EGHelper;
        this.PH = new PressureHelper(EGHelper);
    }

    public CalculateGpmRadius = (): CalculateGpmRadiusResult => {
        if (this.LastResult?.NozzleIndex === this.EGH.CurrentNozzleIndex) {
            return this.LastResult;
        }
        let prevGPM: number = 0;
        let iIteration: number = 0;
        let estimate: GPMAndRadiusEstimate = {
            GPM: 1,
            Radius: 0,
            Pressure: 0
        };
        do {
            if (estimate.GPM > 2) {
                prevGPM = estimate.GPM;
            }
            estimate = this.EstimatedGPMAndRadiusFromTable(this.PH.Pressure(estimate.GPM));
            while (estimate.GPM === 0 && this.EGH.CurrentNozzleIndex < this.EGH.Nozzles.length - 1) {
                this.EGH.NextLargerNozzleIndex();
                estimate = this.EstimatedGPMAndRadiusFromTable(this.PH.Pressure(estimate.GPM));
            };
            if (estimate.GPM === 0 && this.EGH.CurrentNozzleIndex > 1) {
                this.EGH.NozzleLimitReached = true;
                this.EGH.PrevSmallerNozzle();
                estimate = this.EstimatedGPMAndRadiusFromTable(this.PH.Pressure(estimate.GPM));
            }
            switch (this.EGH.EndGunType) {
                case EndGunTypes.SingleP85:
                    if (this.EGH.UseDiffuser) {
                        estimate.Radius *= 0.85;
                    }
                    break;
                case EndGunTypes.DualP85:
                    estimate.GPM *= 2;
                    if (this.EGH.UseDiffuser) {
                        estimate.Radius *= 0.85;
                    }
                    break;
            }
            iIteration += 1;
        } while (!(iIteration > 7 || FloatsAreEqual(prevGPM, estimate.GPM)));
        let result: CalculateGpmRadiusResult = {
            GPM: estimate.GPM,
            Radius: estimate.Radius,
            Pressure: estimate.Pressure,
            NozzleIndex: this.EGH.CurrentNozzleIndex,
            EndGunNozzle: null
        };
        this.LastResult = result;
        return result;
    }

    private EstimatedGPMAndRadiusFromTable = (pressure: number): GPMAndRadiusEstimate => {
        if (this.EndGunItemsByType.length === 0) {
            return {
                GPM: 0,
                Radius: 0,
                Pressure: pressure
            };
        }
        if (this.EndGunItemsByType.length === 1) {
            let e: EndGunItem = this.EndGunItemsByType[0];
            return {
                GPM: e.GPM,
                Radius: e.Radius,
                Pressure: pressure
            };
        }
        
        let diameter: number = this.EGH.Nozzles[this.EGH.CurrentNozzleIndex].Diameter;
        const EndGunItemsByPressure= this.EndGunItemsByType.filter((e) =>
            FloatsAreEqual(e.Nozzle, diameter));
        EndGunItemsByPressure.sort((a, b) => a.Pressure - b.Pressure);
        if (EndGunItemsByPressure.length === 0) {
            return {
                GPM: 0,
                Radius: 0,
                Pressure: pressure
            };
        }
        if (EndGunItemsByPressure.length === 1) {
            let e: EndGunItem = EndGunItemsByPressure[0];
            return {
                GPM: e.GPM,
                Radius: e.Radius,
                Pressure: pressure
            };
        }

        let i1: number;
        let i2: number;
        if (pressure < EndGunItemsByPressure[0].Pressure) {
            if (EndGunItemsByPressure[0].GPM === 0) {
                return {
                    GPM: 0,
                    Radius: 0,
                    Pressure: pressure
                };
            }
            i1 = 0;
            i2 = 1;
        } else if (pressure >= EndGunItemsByPressure[EndGunItemsByPressure.length - 1].Pressure) {
            i1 = EndGunItemsByPressure.length - 2;
            i2 = EndGunItemsByPressure.length - 1;
        } else {
            for (let i: number = 0; i <= EndGunItemsByPressure.length - 1; i++) {
                if (pressure >= EndGunItemsByPressure[i].Pressure && pressure < EndGunItemsByPressure[i + 1].Pressure) {
                    i1 = i;
                    i2 = i + 1;
                    break;
                }
            }
        }
        let nz1: EndGunItem = EndGunItemsByPressure[i1];
        let nz2: EndGunItem = EndGunItemsByPressure[i2];
        return {
            GPM: Interprolate(pressure, nz1.Pressure, nz2.Pressure, nz1.GPM, nz2.GPM),
            Radius: Interprolate(pressure, nz1.Pressure, nz2.Pressure, nz1.Radius, nz2.Radius),
            Pressure: pressure
        };
    }
}
