import {
    Alert
} from "@mui/material";
import {
    Feature,
    Geometry,
    LineString,
    Point,
    Polygon,
    bbox,
    featureCollection,
} from "@turf/turf";
import i18next, { t } from "i18next";
import * as React from "react";
import { useContext, useState } from "react";
import SegmentHelper from "../../../../../../GeometryHelpers/SegmentHelper";
import { createNewLineAnnotation } from "../../../../../../actions/NewLineAnnotationAction";
import { createNewObstacleAction } from "../../../../../../actions/NewObstacleAction";
import { createNewPointAnnotation } from "../../../../../../actions/NewPointAnnotationAction";
import { createNewTextAnnotation } from "../../../../../../actions/NewTextAnnotationAction";
import { createNewWheelObstacleAction } from "../../../../../../actions/NewWheelObstacleAction";
import { createNewUpdateLayoutPropertyAction } from "../../../../../../actions/UpdateLayoutProperty";
import AuthCtx from "../../../../../../auth/AuthCtx";
import IAuthState from "../../../../../../auth/IAuthState";
import { IFieldSettings } from "../../../../../../components/DealerSettingsDialog/FieldSettings";
import { MapContext } from "../../../../../../components/Map/mapContext";
import DevSettingsCtx from "../../../../../../db/DevSettingsCtx";
import IDbProject from "../../../../../../db/IDbProject";
import { ImportExportFeature, ImportExportTypes } from "../../../../../../helpers/importExport";
import { AnnotationType } from "../../../../../../model/project/IAnnotation";
import IFieldBoundary from "../../../../../../model/project/IFieldBoundary";
import ImportExportDialogBase, { IRow } from "../ImportExportDialogBase";

const handleImportBoundary = (
    geometry: Geometry, dbPrj: IDbProject, layoutId: string, authState: IAuthState, 
    fieldSettings: IFieldSettings, boundaryType: "fieldBoundary" | "wetAreaBoundary" | "pivotCenterBoundary"
): boolean => {
    if (geometry.type !== "Polygon") return false;
    const boundary: IFieldBoundary = {
        segments: SegmentHelper.polygonToSegments(geometry as Polygon, fieldSettings.minBoundaryClearanceFeet)
    }
    dbPrj.pushAction(createNewUpdateLayoutPropertyAction(layoutId, boundaryType, boundary, authState));
    return true;
};

const handleImportObstacle = (geometry: Geometry, dbPrj: IDbProject, layoutId: string, authState: IAuthState, fieldSettings: IFieldSettings): boolean => {
    if (geometry.type !== "Polygon") return false;
    dbPrj.pushAction(createNewObstacleAction(
        layoutId,
        {
            polygon: geometry as Polygon,
            clearance: fieldSettings.minObstacleClearanceFeet
        },
        authState
    ));
    return true;
}
const handleImportWheelObstacle = (geometry: Geometry, dbPrj: IDbProject, layoutId: string, authState: IAuthState, fieldSettings: IFieldSettings): boolean => {
    if (geometry.type !== "Polygon") return false;
    dbPrj.pushAction(createNewWheelObstacleAction(
        layoutId,
        {
            polygon: geometry as Polygon,
            clearance: fieldSettings.minObstacleClearanceFeet
        },
        authState
    ));
    return true;
}
const handleImportPoint = (geometry: Geometry, dbPrj: IDbProject, layoutId: string, authState: IAuthState, annotationType: AnnotationType): boolean => {
    if (geometry.type !== "Point") return false;
    dbPrj.pushAction(createNewPointAnnotation(
        layoutId,
        annotationType,
        geometry as Point,
        authState
    ));
    return true;
}
const handleImportLine = (geometry: Geometry, dbPrj: IDbProject, layoutId: string, authState: IAuthState, annotationType: AnnotationType): boolean => {
    if (geometry.type !== "LineString") return false;
    dbPrj.pushAction(createNewLineAnnotation(
        layoutId,
        annotationType,
        geometry as LineString,
        authState
    ));
    return true;
}
const handleImportLabel = (geometry: Geometry, dbPrj: IDbProject, layoutId: string, authState: IAuthState, labelAnnotationText: string | undefined): boolean => {
    if (geometry.type !== "Point") return false;
    if (!labelAnnotationText) return false;
    
    dbPrj.pushAction(createNewTextAnnotation(
        layoutId,
        AnnotationType.label,
        geometry as Point,
        labelAnnotationText,
        authState
    ));
    return true;
}


interface IProps {
    features: ImportExportFeature[];    
    onClose: () => void;
    dbPrj: IDbProject;
    layoutId: string;
}
const ImportDialog: React.FC<IProps> = ({
    features, onClose, dbPrj, layoutId
}) => {

    const authState = useContext(AuthCtx);
    const [alerts, setAlerts] = useState<React.ReactNode[]>([]);
    const [disableConfirm, setDisableConfirm] = useState<boolean>(false);
    const fieldSettings = useContext(DevSettingsCtx).fieldSettings.current;
    const map = useContext(MapContext);

    const onConfirm = (selectedRows: IRow[]) => {
        const success: boolean[] = [];
        const importedFeatures: Feature[]  = [];
        for (const importRow of selectedRows) {
            const feature = importRow.feature;
            if (!feature || feature.geometry.type === 'GeometryCollection') {
                success.push(false);
                continue;
            }
            const geometry = feature.geometry as Geometry;
            switch (importRow.feature.properties.importType) {
                case "boundary":
                    success.push(handleImportBoundary(geometry, dbPrj, layoutId, authState, fieldSettings, "fieldBoundary"));
                    break;
                case "wetAreaBoundary":
                    success.push(handleImportBoundary(geometry, dbPrj, layoutId, authState, fieldSettings, "wetAreaBoundary"));
                    break;
                case "pivotCenterBoundary":
                    success.push(handleImportBoundary(geometry, dbPrj, layoutId, authState, fieldSettings, "pivotCenterBoundary"));
                    break;
                case "obstacle":
                    success.push(handleImportObstacle(geometry, dbPrj, layoutId, authState, fieldSettings));
                    break;
                case "wheelObstacle":
                    success.push(handleImportWheelObstacle(geometry, dbPrj, layoutId, authState, fieldSettings));
                    break;
                case "pump":
                    success.push(handleImportPoint(geometry, dbPrj, layoutId, authState, AnnotationType.pump));
                    break;
                case "point":
                    success.push(handleImportPoint(geometry, dbPrj, layoutId, authState, AnnotationType.point));
                    break;
                case "line":
                    success.push(handleImportLine(geometry, dbPrj, layoutId, authState, AnnotationType.line));
                    break;
                case "canal":
                    success.push(handleImportLine(geometry, dbPrj, layoutId, authState, AnnotationType.canal));
                    break;
                case "electricLine":
                    success.push(handleImportLine(geometry, dbPrj, layoutId, authState, AnnotationType.electricLine));
                    break;
                case "waterLine":
                    success.push(handleImportLine(geometry, dbPrj, layoutId, authState, AnnotationType.waterLine));
                    break;
                case "label":
                    success.push(handleImportLabel(geometry, dbPrj, layoutId, authState, importRow.feature.properties.label));
                    break;
                default:
                    success.push(handleImportLabel(geometry, dbPrj, layoutId, authState, importRow.feature.properties.label));
                    break;
            }
            importedFeatures.push(feature);
        }
        if (map && importedFeatures.length) {
            const fc = featureCollection(importedFeatures);
            const env = bbox(fc);
            map.fitBounds(env as [number,number,number,number])
        }
        onClose();
    };

    const onRowSelectionChanged = (selectedRows: IRow[]) => {
        const layout = dbPrj.state.layouts[layoutId];
        const alerts: React.ReactNode[] = [];
        let hasFatalAlert = false;

        const checkBoundaryTypes: ImportExportTypes[] = [
            'boundary', 'wetAreaBoundary', 'pivotCenterBoundary'
        ];
        for (const boundaryType of checkBoundaryTypes) {
            let hasExistingBoundary: boolean;
            let boundaryTypeUserString: string = "";
            switch (boundaryType) {
                case 'boundary':
                    hasExistingBoundary = layout ? !!layout.fieldBoundary : false;
                    boundaryTypeUserString = i18next.format(t('field-boundary'), 'capitalize-each');
                    break;
                case 'wetAreaBoundary':
                    hasExistingBoundary = layout ? !!layout.wetAreaBoundary : false;
                    boundaryTypeUserString = i18next.format(t('wet-area-boundary'), 'capitalize-each');
                    break;
                case 'pivotCenterBoundary':
                    hasExistingBoundary = layout ? !!layout.pivotCenterBoundary : false;
                    boundaryTypeUserString = i18next.format(t('pivot-center-boundary'), 'capitalize-each');
                    break;            
                default:
                    hasExistingBoundary = false;
                    break;
            }
            const importBoundaryCount = selectedRows.filter((x) => x.feature.properties.importType === boundaryType);
    
            if (hasExistingBoundary && importBoundaryCount.length > 0) {
                alerts.push(
                    <Alert key={'alert-existing-boundary'} severity='warning'>
                        This field already contains a {boundaryTypeUserString}. Importing a new {boundaryTypeUserString} will overwrite the existing {boundaryTypeUserString}.
                    </Alert>
                )
            }
            if (importBoundaryCount.length > 1) {
                hasFatalAlert = true;
                alerts.push(
                    <Alert key={'alert-multiple-boundaries'} severity='error'>
                        Only one {boundaryTypeUserString} may be imported.
                    </Alert>
                )
            }

        }

        setAlerts(alerts);
        if (disableConfirm !== hasFatalAlert) {
            setDisableConfirm(hasFatalAlert);
        }
    }

    
    

    return(
        <ImportExportDialogBase
            title="Import Shapes"
            features={features}
            onConfirm={onConfirm}
            onCancel={onClose}
            onRowSelectionChanged={onRowSelectionChanged}
            disableConfirm={disableConfirm}
            type="import"
        >
            {alerts}
        </ImportExportDialogBase>
    )
}

export default ImportDialog;