import { LineString } from '@turf/turf';
import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from '../auth/IAuthState';
import { AnnotationType } from '../model/project/IAnnotation';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordProjectChange } from './helpers';

const actionTypeId = "NewLineAnnotation";

interface ActionData extends IActionData {
    layoutId: string;
    line: LineString;
    annotationType: AnnotationType;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const layout = state.layouts[data.layoutId];
    if (!layout) return;
    layout.annotations.push({
        type: data.annotationType,
        lineAnnotation: {
            line: data.line
        }
    });
    recordProjectChange(action, state);
});

export const createNewLineAnnotation = (layoutId: string, type: AnnotationType, line: LineString, authState: IAuthState) => {
    return createAction(
        actionTypeId,
        {
            layoutId,
            line,
            annotationType: type
        } as ActionData,
        authState);
};