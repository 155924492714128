import { SACDistributionFlowRate, SACZoneControlTypes, SwingArmLengths } from "rdptypes/project/ISystemBase.AutoGenerated";
import { GetOrCreateValue } from "../OtherHelpers";
import { ValidateESAC125DistResults } from "./ValidateESAC125DistResults";
export class ESACZoneFactors {
    private data = new Map<SACZoneControlTypes, Map<SACDistributionFlowRate, Map<SwingArmLengths, Map<number, number>>>>();

    constructor() {
        this.Init();
    }

    public get HasCustom125ValuesLoaded() {
        return this._HasCustom125ValuesLoaded;
    }

    private _HasCustom125ValuesLoaded: boolean = false;

    public SelectFactor = (zct: SACZoneControlTypes, dfr: SACDistributionFlowRate, sal: SwingArmLengths, zone: number): number => {
        try {
            return this.data.get(zct).get(dfr).get(sal).get(zone);
        } catch (ex) {
            throw new Error();
            /* TODO: Throw New Exception($"Error in {NameOf(SelectFactor)} with parameters ({zct}, {dfr}, {sal}, {zone})", ex) */
        }
    }

    public LoadESAC125Factors = (text: String) => {
        let result = this.Validate125DistValues(text);
        if (result.IsValid) {
            this.LoadESAC125FactorsArray(result.Values);
            this._HasCustom125ValuesLoaded = true;
        }
    }

    private LoadESAC125FactorsArray = (factors: number[]) => {
        if (factors.length !== 12) {
            throw new Error();
            /* TODO: Throw New ArgumentException($"{NameOf(LoadESAC125Factors)} requires 12 factor values to associate with {NameOf(SACZoneControlTypes.ESAC125)} zones.") */
        }
        this.LoadESAC125FactorsData(SwingArmLengths.SAC156, factors);
        this.LoadESAC125FactorsData(SwingArmLengths.SAC175, factors);
        this.LoadESAC125FactorsData(SwingArmLengths.SAC194, factors);
        this.LoadESAC125FactorsData(SwingArmLengths.SAC213, factors);
    }

    private LoadESAC125FactorsData = (sal: SwingArmLengths, factors: number[]) => {
        if (factors.length !== 12) {
            throw new Error();
            /* TODO: Throw New ArgumentException($"{NameOf(LoadESAC125Factors)} requires 12 factor values to associate with ESAC125 zones.") */
        }
        try {
            const flowRates = GetOrCreateValue(this.data, SACZoneControlTypes.ESAC125, () => new Map<SACDistributionFlowRate, Map<SwingArmLengths, Map<number, number>>>());

            const salsMedium = GetOrCreateValue(flowRates, SACDistributionFlowRate.Medium, () => new Map<SwingArmLengths, Map<number, number>>());
            const zonesMedium = GetOrCreateValue(salsMedium, sal, () => new Map<number, number>());
            zonesMedium.clear();
            const salsHigh = GetOrCreateValue(flowRates, SACDistributionFlowRate.High, () => new Map<SwingArmLengths, Map<number, number>>());
            const zonesHigh = GetOrCreateValue(salsHigh, sal, () => new Map<number, number>());
            zonesHigh.clear();
            let zone: number = 0;
            factors.forEach((factor) => {
                zone += 1;
                zonesMedium.set(zone, (factor + 1) / 2);
                zonesHigh.set(zone, factor);
            }
            );
        } catch (ex) {
            throw new Error();
            /* TODO: Throw New Exception($"Error in {NameOf(LoadESAC125Factors)} with parameters ({sal}, factors:{{ {String.Join(",", factors)} }}", ex) */
        }
    }

    private InitData = (zct: SACZoneControlTypes, dfr: SACDistributionFlowRate, sal: SwingArmLengths, factors: number[], overwrite: boolean = false) => {
        //try {
            if (zct === SACZoneControlTypes.ESAC060 && factors.length !== 6) {
                throw new Error();
                /* TODO: Throw New ArgumentException($"{zct} requires 6 values for zone factors") */
            }
            if ((zct === SACZoneControlTypes.ESAC120 || zct === SACZoneControlTypes.ESAC125) && factors.length !== 12) {
                throw new Error();
                /* TODO: Throw New ArgumentException($"{zct} requires 12 values for zone factors") */
            }
            const flowRates = GetOrCreateValue(this.data, zct, () => new Map<SACDistributionFlowRate, Map<SwingArmLengths, Map<number, number>>>());
            const sals = GetOrCreateValue(flowRates, dfr, () => new Map<SwingArmLengths, Map<number, number>>());
            const zones = GetOrCreateValue(sals, sal, () => new Map<number, number>());
            if (overwrite) {
                zones.clear();
            }
            let zone: number = 0;
            factors.forEach((factor) => {
                zone += 1;
                zones.set(zone, factor);
            }
            );
        //} catch (ex) {
        //    throw new Error();
            /* TODO: Throw New Exception($"Error in {NameOf(Init)} with parameters ({zct}, {dfr}, {sal}, factors:{{ {String.Join(",", factors)} }}", ex) */
        //}
    }

    public Init = () => {
        this.data.clear();
        this._HasCustom125ValuesLoaded = false;
        this.InitData(SACZoneControlTypes.ESAC060, SACDistributionFlowRate.Low, SwingArmLengths.SAC156, [ 1, 1, 1, 1, 1, 1 ]);
        this.InitData(SACZoneControlTypes.ESAC060, SACDistributionFlowRate.Low, SwingArmLengths.SAC175, [ 1, 1, 1, 1, 1, 1 ]);
        this.InitData(SACZoneControlTypes.ESAC060, SACDistributionFlowRate.Low, SwingArmLengths.SAC194, [ 1, 1, 1, 1, 1, 1 ]);
        this.InitData(SACZoneControlTypes.ESAC060, SACDistributionFlowRate.Low, SwingArmLengths.SAC213, [ 1, 1, 1, 1, 1, 1 ]);
        this.InitData(SACZoneControlTypes.ESAC060, SACDistributionFlowRate.Medium, SwingArmLengths.SAC156, [ 1.04, 1.11, 1.19, 1.28, 1.35, 1.39 ]);
        this.InitData(SACZoneControlTypes.ESAC060, SACDistributionFlowRate.Medium, SwingArmLengths.SAC175, [ 1.03, 1.1, 1.18, 1.25, 1.32, 1.39 ]);
        this.InitData(SACZoneControlTypes.ESAC060, SACDistributionFlowRate.Medium, SwingArmLengths.SAC194, [ 1.05, 1.15, 1.24, 1.3, 1.35, 1.41 ]);
        this.InitData(SACZoneControlTypes.ESAC060, SACDistributionFlowRate.Medium, SwingArmLengths.SAC213, [ 1.05, 1.13, 1.22, 1.3, 1.36, 1.42 ]);
        this.InitData(SACZoneControlTypes.ESAC060, SACDistributionFlowRate.High, SwingArmLengths.SAC156, [ 1, 1, 1, 1, 1, 1 ]); // {1.1, 1.32, 1.53, 1.76, 1.95, 2.07 ])
        this.InitData(SACZoneControlTypes.ESAC060, SACDistributionFlowRate.High, SwingArmLengths.SAC175, [ 1, 1, 1, 1, 1, 1 ]); // {1.09, 1.3, 1.5, 1.7, 1.9, 2.08 ])
        this.InitData(SACZoneControlTypes.ESAC060, SACDistributionFlowRate.High, SwingArmLengths.SAC194, [ 1, 1, 1, 1, 1, 1 ]); // {1.14, 1.4, 1.63, 1.82, 1.97, 2.12 ])
        this.InitData(SACZoneControlTypes.ESAC060, SACDistributionFlowRate.High, SwingArmLengths.SAC213, [ 1, 1, 1, 1, 1, 1 ]); // {1.12, 1.36, 1.59, 1.81, 1.99, 2.14 ])
        this.InitData(SACZoneControlTypes.ESAC120, SACDistributionFlowRate.Low, SwingArmLengths.SAC156, [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SACDistributionFlowRate.Low, SwingArmLengths.SAC175, [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SACDistributionFlowRate.Low, SwingArmLengths.SAC194, [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SACDistributionFlowRate.Low, SwingArmLengths.SAC213, [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SACDistributionFlowRate.Medium, SwingArmLengths.SAC156, [ 1.02, 1.05, 1.09, 1.14, 1.17, 1.21, 1.26, 1.3, 1.34, 1.36, 1.38, 1.4 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SACDistributionFlowRate.Medium, SwingArmLengths.SAC175, [ 1.02, 1.05, 1.09, 1.13, 1.16, 1.19, 1.23, 1.27, 1.31, 1.34, 1.37, 1.41 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SACDistributionFlowRate.Medium, SwingArmLengths.SAC194, [ 1.02, 1.08, 1.13, 1.18, 1.22, 1.26, 1.29, 1.31, 1.34, 1.36, 1.39, 1.42 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SACDistributionFlowRate.Medium, SwingArmLengths.SAC213, [ 1.02, 1.07, 1.11, 1.16, 1.2, 1.24, 1.28, 1.31, 1.35, 1.38, 1.4, 1.43 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SACDistributionFlowRate.High, SwingArmLengths.SAC156, [ 1.03, 1.1, 1.18, 1.27, 1.34, 1.42, 1.51, 1.59, 1.67, 1.72, 1.76, 1.79 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SACDistributionFlowRate.High, SwingArmLengths.SAC175, [ 1.03, 1.1, 1.17, 1.25, 1.31, 1.38, 1.46, 1.53, 1.61, 1.67, 1.74, 1.81 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SACDistributionFlowRate.High, SwingArmLengths.SAC194, [ 1.04, 1.15, 1.25, 1.35, 1.44, 1.52, 1.57, 1.62, 1.68, 1.72, 1.78, 1.84 ]);
        this.InitData(SACZoneControlTypes.ESAC120, SACDistributionFlowRate.High, SwingArmLengths.SAC213, [ 1.04, 1.13, 1.22, 1.31, 1.39, 1.47, 1.55, 1.62, 1.7, 1.75, 1.8, 1.85 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.Low, SwingArmLengths.SAC156, [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.Low, SwingArmLengths.SAC175, [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.Low, SwingArmLengths.SAC194, [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.Low, SwingArmLengths.SAC213, [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.Medium, SwingArmLengths.SAC156, [ 1.02, 1.07, 1.13, 1.19, 1.24, 1.29, 1.35, 1.41, 1.46, 1.5, 1.52, 1.55 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.Medium, SwingArmLengths.SAC175, [ 1.02, 1.07, 1.12, 1.17, 1.22, 1.27, 1.33, 1.38, 1.43, 1.47, 1.51, 1.56 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.Medium, SwingArmLengths.SAC194, [ 1.03, 1.1, 1.17, 1.23, 1.3, 1.35, 1.39, 1.43, 1.47, 1.5, 1.54, 1.58 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.Medium, SwingArmLengths.SAC213, [ 1.03, 1.09, 1.15, 1.21, 1.27, 1.32, 1.38, 1.43, 1.48, 1.52, 1.55, 1.58 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.High, SwingArmLengths.SAC156, [ 1.05, 1.14, 1.26, 1.37, 1.48, 1.59, 1.7, 1.82, 1.93, 2.0, 2.04, 2.09 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.High, SwingArmLengths.SAC175, [ 1.04, 1.14, 1.25, 1.35, 1.45, 1.55, 1.65, 1.76, 1.85, 1.95, 2.03, 2.12 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.High, SwingArmLengths.SAC194, [ 1.06, 1.2, 1.34, 1.47, 1.59, 1.7, 1.77, 1.85, 1.93, 2.01, 2.08, 2.16 ]);
        this.InitData(SACZoneControlTypes.ESAC125, SACDistributionFlowRate.High, SwingArmLengths.SAC213, [ 1.06, 1.18, 1.3, 1.42, 1.53, 1.64, 1.75, 1.86, 1.96, 2.04, 2.1, 2.17 ]);
    }

    public Validate125DistValues = (text: String): ValidateESAC125DistResults => {
        let res = new ValidateESAC125DistResults();
        if (!text) {
            res.Errors.push("Source document cannot be null or empty.");
            res.IsValid = false;
            return res;
        }
        let lines = text.split("\r\n").filter(x => x !== "");
        if (lines.length !== 12) {
            res.Errors.push(`ESAC 12.5 Distribution Factors require 12 elements, but ${lines.length} were provided.`);
            res.Errors.push(`File format may use "," as delimiter and (New Line) as line terminator.`);
            res.IsValid = false;
            return res;
        }
        const factors = new Map<number, number>();
        lines.forEach((line) => {
            let stringValues: string[];
            try {
                stringValues = line.split(",").filter(x => x !== "");
                if (stringValues.length < 2) {
                    res.Errors.push(`Data row must have at least 2 values; '${line}'`);
                    res.IsValid = false;
                }
                let key: number = parseInt(stringValues[0], 10);
                if (isNaN(key)) {
                    res.Errors.push(`Key '${stringValues[0]}' could not be converted to a integer`);
                    res.IsValid = false;
                    return;
                }
                let value: number = parseFloat(stringValues[1]);
                if (isNaN(value)) {
                    res.Errors.push(`Value '${stringValues[1]}' could not be converted to a decimal`);
                    res.IsValid = false;
                    return;
                }
                factors.set(key, value);
            } catch (ex) {
                res.Errors.push(`Unhandled error trying to parse key:'${stringValues[0]}', value:'${stringValues[0]}'\n${ex}`);
                res.IsValid = false;
            }
        });

        for (let i = 1; i <= 12; i++) {
            let val = factors.get(i);
            if (factors !== undefined) {
                res.Values.push(val);
            } else {
                res.Errors.push(`Entry for Zone ${i} was not declared`);
                res.IsValid = false;
            }
        }
        
        return res;
    }
}

