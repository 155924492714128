import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import { Position } from "rdptypes/geoTypes";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthCtx from "../../auth/AuthCtx";
import DbCtx from "../../db/DbCtx";
import { ProjectType } from "../../model/project/IProject";
import ChooseProjectType from "./ChooseProjectType";
import DesignOnlySection from "./DesignOnlySection";
import ProjectDetails from "./ProjectDetails";
import SelectFieldLocation from "./SelectFieldLocation";

interface Props {
    projectId: string;
    closeWizard: () => any;
    selectedGrowerId: string | null;
    defaultFieldAddress?: string;
    defaultFieldApproxLocation?: Position;
    disableChangeGrower?: boolean;
};

enum PageIndex {
    ProjectType = 0,
    ProjectDetails,
    FieldLocation,
    SystemType,
}

const NewProjectWizard: FC<Props> = ({
    disableChangeGrower = false,
    ...props
}) => {
    const navigate = useNavigate();

    const dbState = useContext(DbCtx);
    const authState = useContext(AuthCtx);

    const [activeStep, setActiveStep] = useState(PageIndex.ProjectDetails);

    const dbPrj = dbState.projects[props.projectId];
    const project = dbPrj.state;

    console.log(JSON.stringify(project.approximateLocation));

    return (
        <Dialog
            open={true}
            maxWidth="md"
            fullWidth={true}
            onClose={props.closeWizard}
        >
            <DialogTitle>
                Reinke Design Center
                <IconButton
                    aria-label="close"
                    onClick={props.closeWizard}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <div>               
            {   activeStep === PageIndex.ProjectDetails && <ProjectDetails
                    disableChangeGrower={disableChangeGrower}
                    selectedGrowerId={props.selectedGrowerId}
                    dbState={dbState} dbPrj={dbPrj}
                    onConfirm={() => {
                        setActiveStep(PageIndex.ProjectType);
                    }}
                />}
                {activeStep === PageIndex.ProjectType && <ChooseProjectType dbPrj={dbPrj}
                    onCancel={() => setActiveStep(PageIndex.ProjectDetails)}
                    onClickNext={() => {
                        if (project.projectType === ProjectType.DesignOnly) {
                            setActiveStep(PageIndex.SystemType);
                        } else {
                            setActiveStep(PageIndex.FieldLocation);
                        }
                    }}
                />}
                {activeStep === PageIndex.FieldLocation && <SelectFieldLocation dbPrj={dbPrj}
                    onClickBack={() => setActiveStep(PageIndex.ProjectType)}
                    onClickNext={() => navigate(`/projects/${props.projectId}`)}
                    defaultAddress={props.defaultFieldAddress}
                    defaultPosition={props.defaultFieldApproxLocation}
                />}
                {activeStep === PageIndex.SystemType &&
                <DesignOnlySection
                    dbState={dbState} 
                    dbPrj={dbPrj}
                    onCancel={() => setActiveStep(PageIndex.ProjectType)}
                    onConfirm={(layoutId, systemId) => navigate(`/projects/${props.projectId}/layouts/${layoutId}/systems/${systemId}/design`)}
                />}
            </div>
        </Dialog>
    );
};

export default NewProjectWizard;