import { Document, Page, Text, View } from '@react-pdf/renderer';
import { t } from "i18next";
import IDbGrower from "rdptypes/api/IGrowerBase";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from 'react';
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { configurationValid, customPricingApplied } from '../../components/roe/componentRenderers/Proposal/ProposalHelpers';
import IProject from "../../model/project/IProject";
import DealerDataCtx from "../../userData/DealerDataCtx";
import { IDocumentType } from '../DocumentGenerationHelpers';
import CustomerDealerTable from '../components/CustomerDealerTable';
import DocumentFirstPageHeader from '../components/DocumentFirstPageHeader';
import Footer from "../components/Footer";
import Header from '../components/Header';
import PercentTimerTable from '../components/PercentTimerTable';
import PerformanceSummaryInformationSection from '../components/PerformanceSummaryInformationSection';
import { documentStyles } from '../styles';

interface Props {
    systemId: string;
    layoutId: string;
    project: IProject;
    grower: IDbGrower;
}

const PAGE_SIZE = "LETTER";

const IrrigationSystemPerformanceSummary: FC<Props> = (props) => {  
    const {systemId, layoutId, project, grower } = props;
    const layout = project.layouts[layoutId!];
    const system = layout.systems[systemId!];
    const quote = new QuoteClass(system);

    const { dealership: dealer } = React.useContext(DealerDataCtx);

    return (
        <>
            <Document>
                <Page size={PAGE_SIZE} style={documentStyles.page}>
                    <DocumentFirstPageHeader documentType={IDocumentType.irrigationSystemPerformanceSummary}/>
                    {
                        customPricingApplied(system) && <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>CUSTOM PRICING APPLIED</Text>
                        </View>
                    }
                    {                    
                        !configurationValid(system) && <View style={documentStyles.heading}>
                            <Text style={documentStyles.sectionHeader}>INCOMPLETE PROPOSAL</Text>
                        </View>
                    }
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        <View style={{paddingLeft: 15, paddingRight: 15}}>
                            <CustomerDealerTable project={project} systemId={systemId} layoutId={layoutId} grower={grower} poNumber={quote.System.QuoteProperties.ProposalInformation.PONumber} includeDealerLogo/>
                        </View>
                        {system.SystemProperties.SystemType !== SystemTypes.Ancillary &&
                        system.SystemProperties.SystemType !== SystemTypes.SwingArmRetro &&
                        <PerformanceSummaryInformationSection quote={quote} systemId={systemId} layoutId={layoutId} project={project}/>}
                    </View>
                    <View fixed style={documentStyles.firstPageFooter}/>
                </Page>
                <Page size={PAGE_SIZE} style={[documentStyles.page, documentStyles.nonTitlePage]}>
                    <Header docType={IDocumentType.irrigationSystemPerformanceSummary} dealerName={dealer.name}/>
                    {system.SystemProperties.SystemType !== SystemTypes.Ancillary &&
                    system.SystemProperties.SystemType !== SystemTypes.SwingArmRetro &&
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        <PercentTimerTable quote={quote} depthMode={false} systemId={systemId} layoutId={layoutId} project={project}/>
                        <PercentTimerTable quote={quote} depthMode systemId={systemId} layoutId={layoutId} project={project}/>
                    </View>}
                    <View style={{paddingLeft: 15, paddingRight: 15}}>
                        <View style={documentStyles.section}>
                            <View style={documentStyles.heading}>
                                <Text style={documentStyles.sectionHeader}>{t("common-phrases.sltDisclaimer")}</Text>
                            </View>
                            <View style={{paddingLeft: 15, paddingRight: 15, paddingTop: 10}}>
                                <Text>{t("common-phrases.lsltPerformanceSummaryDisclaimer")}</Text>
                            </View>
                        </View>
                    </View>
                    <Footer/>
                </Page>
            </Document>
        </>
    );
};

export default IrrigationSystemPerformanceSummary;