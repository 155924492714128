import { PressureGaugeTypes, SprinklerManufacturerTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as sysf from "roedata/roe_migration/SystemFunctions";
import ISystem from "../../../../../model/project/ISystem";
import { resetChart, resetPackages } from "./helpers";
import { IFnExecutePropertyValueMap, IPropertyValueMap, ISprinklerValidatorChoiceFieldWithSet, ISprinklerValidatorFieldWithSet } from "./interfaces";

const setSprinklerProperty = (sys: ISystem, property: string, value: any, propertyMap: IPropertyValueMap): IPropertyValueMap => {
    sys.SprinklerProperties[property] = value;
    let newPropertyMap = {
        ...propertyMap
    }
    newPropertyMap["SprinklerProperties." + property] = value;
    newPropertyMap = resetChart(sys, newPropertyMap);

    if (property === "SprinklerEngineCode") {
        sys.FlangedSide.Sprinklers.BoomBacks = {};
        sys.FlexSide.Sprinklers.BoomBacks = {};
        newPropertyMap["FlangedSide.Sprinklers.BoomBacks"] = {};
        newPropertyMap["FlexSide.Sprinklers.BoomBacks"] = {};

        newPropertyMap = resetPackages(sys, newPropertyMap);
    }


    return newPropertyMap;
}

export const sprinklerEngineCodeValidator = (sys: ISystem, executePvm: IFnExecutePropertyValueMap): ISprinklerValidatorChoiceFieldWithSet<SprinklerManufacturerTypes> => {
    const getAllowableValues = () => {
        if (sysf.HasEnergySaverPackage(sys)) {
            return [
                SprinklerManufacturerTypes.Nelson
            ]
        }
        return [
            SprinklerManufacturerTypes.Nelson, SprinklerManufacturerTypes.Senninger, SprinklerManufacturerTypes.Komet
        ]
    }
    const allowableValues = getAllowableValues();
    const value = sys.SprinklerProperties.SprinklerEngineCode;

    const getError = () => {
        if (!value) return true;
        return !allowableValues.includes(value);
    }

    const isError = getError();
    
    const set = (v: SprinklerManufacturerTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            pvm = setSprinklerProperty(clonedSys, "SprinklerEngineCode", v, pvm);
            executePvm(pvm);
        }
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}

export const pressureGaugeValidator = (sys: ISystem, executePvm: IFnExecutePropertyValueMap): ISprinklerValidatorChoiceFieldWithSet<PressureGaugeTypes> => {
    const value = sys.SprinklerProperties.PressureGauge;

    const getAllowableValues = () => Object.keys(PressureGaugeTypes) as PressureGaugeTypes[];
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
    }

    const isError = getError();
    
    const set = (v: PressureGaugeTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            pvm = setSprinklerProperty(clonedSys, "PressureGauge", v, pvm);
            executePvm(pvm);
        }
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}

export const endPressureGaugeValidator = (sys: ISystem, executePvm: IFnExecutePropertyValueMap): ISprinklerValidatorFieldWithSet<boolean> => {
    const value = sys.SprinklerProperties.EndPressureGauge;
    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            pvm = setSprinklerProperty(clonedSys, "EndPressureGauge", v, pvm);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}

export const totalGPMValidator = (sys: ISystem, executePvm: IFnExecutePropertyValueMap): ISprinklerValidatorFieldWithSet<number> => {
    const value = sys.SprinklerProperties.TotalGPM;

    const getError = () => {
        // Simons:
        if (!isFinite(value) || value === null || value < 0) return true;

        // START: SprinklersPackagesClass
        if (value > 5500) {
            // this.Quote.Warnings.Add(strLanguageText("sltSystemGPMMax5500PSI"));
            return true;
        }                
        if (value === 0) {
            // this.Quote.Warnings.Add(strLanguageText("sltSystemGPMCannotBeZero"));
            return true;
        }
        // END: SprinklersPackagesClass

        return false;
    }

    const isError = getError();
    
    const set = (v: number) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            pvm = setSprinklerProperty(clonedSys, "TotalGPM", v, pvm);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}

export const designedEndPressureValidator = (sys: ISystem, executePvm: IFnExecutePropertyValueMap): ISprinklerValidatorFieldWithSet<number> => {
    const value = sys.SprinklerProperties.DesignedEndPressure;

    const getError = () => {
        // Simons:
        if (!isFinite(value) || value === null || value < 0) return true;

        // START: SprinklersPackagesClass (and SprinklersConfigClass)
        if (value > 100) { //'PER ROE-396, ROE-413 Ensure validation in Standard/Metric
            // this.Quote.Warnings.Add(strLanguageText("sltEndPressureMax100PSI"));
            return true;
        }
        else if (sysf.HasSwingArmCorner(sys) && value < 15) { //'ROE-634 - Minimum 15 PSI @ End of System for Swing Arm Systems
            // this.Quote.Warnings.Add(strLanguageText("sltEndPressureMin15PSI"))
            return true;
        }
        else if (value < 3) { //'ROE-634 - Minimum 3 PSI @ End of System
            // this.Quote.Warnings.Add(strLanguageText("sltEndPressureMin3PSI"));
            return true;
        }
        // END: SprinklersPackagesClass (and SprinklersConfigClass)

        return false;
    }

    const isError = getError();
    
    const set = (v: number) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            pvm = setSprinklerProperty(clonedSys, "DesignedEndPressure", v, pvm);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}

export const peakElevationValidator = (sys: ISystem, executePvm: IFnExecutePropertyValueMap): ISprinklerValidatorFieldWithSet<number> => {
    const value = sys.SprinklerProperties.PeakElevation;

    const getError = () => {
        // Simons:
        if (!isFinite(value) || value === null) return true;

        // START: SprinklersPackagesForm
        if (value < 0 || value > 150) {
            // this.Quote.Warnings.Add(strLanguageText("sltElevationMustBeBetween"));
            return true;
        }
        // END: SprinklersPackagesForm

        return false;
    }

    const isError = getError();
    
    const set = (v: number) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            pvm = setSprinklerProperty(clonedSys, "PeakElevation", v, pvm);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}