import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { t } from "i18next";
import * as React from "react";

interface Props {
    title: string;
    useCustomDefaults: boolean;
    setUseCustomDefaults: (value: boolean) => void;
    alternativeDefaultLabelKey?: string;
    alternativeOwnLabelKey?: string;
}

enum ERadioValues {
    CUSTOM = 'custom',
    DEFAULT = 'default'
}

const CustomDefaultsRadioGroup: React.FC<Props> = ({ title, useCustomDefaults, setUseCustomDefaults, alternativeDefaultLabelKey, alternativeOwnLabelKey }) => {

    const handleCustomDefaultsRadioGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === ERadioValues.CUSTOM) {
            setUseCustomDefaults(true);
        }
        else if (event.target.value === ERadioValues.DEFAULT) {
            setUseCustomDefaults(false);
        }
    }
    
    const ns = 'dealer-settings.';

    return (
        <Box>
            <div>{title}</div>
            <FormControl>
                <RadioGroup 
                    row
                    value={useCustomDefaults ? ERadioValues.CUSTOM : ERadioValues.DEFAULT}
                    onChange={handleCustomDefaultsRadioGroup}
                >
                    <FormControlLabel 
                        value={ERadioValues.DEFAULT} 
                        control={<Radio />} 
                        label={t(ns + (alternativeDefaultLabelKey ?? "use-app-defaults"))} />
                    <FormControlLabel 
                        value={ERadioValues.CUSTOM} 
                        control={<Radio />} 
                        label={t(ns + (alternativeOwnLabelKey ?? "define-own-defaults"))} />
                </RadioGroup>
            </FormControl> 
        </Box>
    )
}

export default CustomDefaultsRadioGroup;