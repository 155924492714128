import { Text, View } from "@react-pdf/renderer";
import { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { SpanPipeTypes } from "roedata/roe_migration/SpanFunctions";
import { systemHasESP, systemHasHeavyDutyWheelGear, systemHasNonBasicControlPanel, systemHasPipeType } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import { documentStyles } from "../styles";
import ListItem from "./ListItem";

interface Props {
    quote: QuoteClass;
};

const StandardFeaturesList: FC<Props> = (props) => {
    let {quote} = props;
    let messages: string[] = [];
    let ns = "common-phrases.";
    
    if (systemHasESP(quote)){
        messages.push(t(ns + "sltPatentedEnergySaverPackageImproves"));
    }

    if (quote.IsTowable()){
        messages.push(t(ns + "sltUniqueTowableReinkeGearboxMakes"));
    }

    if (systemHasPipeType(quote, [SpanPipeTypes.Galvanized])){
        messages.push(t(ns + "sltGalvanizedHighTensileStrengthSteel"));
    }

    if (systemHasPipeType(quote, [SpanPipeTypes.Painted])){
        messages.push(t(ns + "sltChromiumNickelSteelAlloyPipelineMaterial"));
    }

    if (systemHasPipeType(quote, [SpanPipeTypes.Aluminum])){
        messages.push(t(ns + "sltMarineGradeAluminumPipelineMaterial"));
    }

    if (systemHasPipeType(quote, [SpanPipeTypes.Stainless])){
        messages.push(t(ns + "sltStainlessSteel304LPipelineMaterial"));
    }

    //default items
    messages.push(t(ns + "sltHighTensileStrengthSteelUtilized"),
        t(ns + "sltHighTensileStrengthSteelAllowsReinkeReduceTower"),
        t(ns + "sltHighTensileStrengthSteelProvidesHighest"),
        t(ns + "sltFrictionReducingFullSweepElbows"),
        t(ns + "sltFullyGusseted18InchPivotBearing"),
        t(ns + "sltPivotFlexJointRelievesStress"),
        t(ns + "sltStainlessSteelRiserGasketSeatMaintains"));

    if (systemHasNonBasicControlPanel(quote)){
        messages.push(t(ns + "sltCorrosionResistantAluminumAlloyMainControlPanelOutlasts"), t(ns + "sltFullyUpgradeableMainControlPanelToMeet"));
    }

    messages.push(t(ns + "sltSimpleEasyToUnderstandControlsMakes"),
        t(ns + "sltAdjustableMainControlPanelMountInsures"),
        t(ns + "sltEyeLevelPressureGaugeProvides"),
        t(ns + "sltComputerDesignedSprinklerPackagesInsure"));

    if (systemHasHeavyDutyWheelGear(quote)){
        messages.push(t(ns + "sltReinkeHeavyDutyWheelGearDesigned"));
    }

    messages.push(t(ns + "sltCenteringRingOnGearboxPrevents"),
        t(ns + "sltDrivelineShieldsProtect"),
        t(ns + "sltReinkeIrrigationDutyMotorsProvide"),
        t(ns + "sltInsulatedTowerBoxesProtect"),
        t(ns + "sltExclusiveMechanicalSafetyInterlockPrevents"),
        t(ns + "sltDoubleJacketedSpanCablesForExtra"),
        t(ns + "sltUniqueVRingPipeFlangeSealReduces"),
        t(ns + "sltReinkeInternalFlexJointIncreases"),
        t(ns + "sltHighStrengthSingleTowerLegsProtect"),
        t(ns + "sltEndBoomsAreSupportedWithTrussRods"));

    if (systemHasPipeType(quote, [SpanPipeTypes.Galvanized])){
        messages.push(t(ns + "sltGalvanizedCNPipe"));
    }

    if (systemHasPipeType(quote, [SpanPipeTypes.Painted])){
        messages.push(t(ns + "sltPaintedCNPipe"));
    }

    return (<View style={documentStyles.section}>
            <View style={documentStyles.heading}>
                <Text style={documentStyles.sectionHeader}>{t(ns + "sltStandardFeatures")}</Text>
            </View>
            <View style={{paddingLeft: 15, paddingRight: 15}}>
        {
            messages.map(x => {
                return <ListItem text={x}/>
            })
        }</View>
    </View>);
};

export default StandardFeaturesList;