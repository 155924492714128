import { StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";
import { FC } from "react";
import { primaryColor } from "../styles";

interface Props {
    text: string;
};

const ListItem: FC<Props> = (props) => {
    let {text} = props;
    
    return (
        <View style={styles.row}>
            <Text style={{fontFamily: "Wingdings", color: primaryColor, fontSize: 8,  marginRight: 3}}>y</Text>
            <Text>{text}</Text>
        </View>
    )
};

const styles = StyleSheet.create({
    row:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: "flex-start"
    }
});

export default ListItem;