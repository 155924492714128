export const SYSTEM_DESIGN_CONSTANTS = {
    pipe: {
        color: 'dimgrey',
        width: 6,
        upperAplitude: 12,
        nSections: 100,
        supportEvery: 205,
        supportAmplitude: 12 * 2,
        supportWidth: 20
    },
    sprinkler: {
        color: 'black',
        length: 12 * 4
    },
    wheel: {
        color: 'black',
        width: 10,
        height: 60,
        offset: 4
    },
    tensioner: {
        width: 3
    },
    pivot: {
        baseWidth: 20 * 12 * 3.5 / 5.5
    },
    sac: {
        upperAmplitude: 6 * 5,
        nSections: 50,
        spanSpacingInches: 40,
        sacSpacingInches: 40
    },
    endBoom: {
        spacingInches: 40
    },
    ground: {
        color: '#98bf64'
    },
    canal: {
        color: "#0000FF"
    },
    sky: {
        color: "#D4E6F1"
    }
}
