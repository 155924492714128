import { SpanTypes } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import * as sidef from "../SideFunctions";
import { SpansClass } from "../SpansClass";
import { IsDualSided, IsEDMP, IsMaxigator } from "../SystemFunctions";
import { AlumIVSupportRodOk, ConduitClipWOSpringOK, GalvanizedCNJointsOptionOk, GalvanizedCNOptionOk, LongSystemAlignmentOk, TheftDeterentClipOK } from "../Valids.Valids";

export default class SpansForm {
    constructor(private Quote: QuoteClass, private CodeBehind: SpansClass) {
    }

    public validate = (): void => {
        let sp = this.Quote.System.SpanProperties;

        const stainlessSteelAnalysis = this.PerformStainlessSteelAnalysis();
        if (stainlessSteelAnalysis.bSSRequired && !sp.StainlessSteelJoints) {
            this.Quote.Warnings.Add(`Stainless steel joints are required fro the selected span types.`);
        }
        if (!stainlessSteelAnalysis.bSSRequired && !stainlessSteelAnalysis.bSSIndeterminate && !stainlessSteelAnalysis.bSSAvailable && sp.StainlessSteelJoints) {
            this.Quote.Warnings.Add(`Stainless steel joints are not available for the selected span types.`);
        }

        let spanTypes: SpanTypes[] = this.Quote.System.FlangedSide.Span.map(x => x.SpanType);
        if (IsDualSided(this.Quote.System)) {
            spanTypes.push(...this.Quote.System.FlexSide.Span.map(x => x.SpanType));
        }

        if (!GalvanizedCNJointsOptionOk(sp.GalvanizedCNJoints, {
            SpanTypeList: spanTypes
        })) {
            this.Quote.Warnings.Add(`Cannot have galvanized CN + span joints with the current setup.`);
        }

        if (!LongSystemAlignmentOk(sp.LongSystemAlignment, {
            SystemType: this.Quote.System.SystemProperties.SystemType,
            HoseFeedType: this.Quote.System.Lateral.HoseFeed.HoseFeedType,
            AnyAluminum: this.AlumExists()
        })) {
            this.Quote.Warnings.Add(`Long system alignment`);
        }

        if (!AlumIVSupportRodOk(sp.AlumIVSupportRod, {
            SystemType: this.Quote.System.SystemProperties.SystemType,
            HoseFeedType: this.Quote.System.Lateral.HoseFeed.HoseFeedType,
            AnyAluminum6: this.Alum6Exists()
        })) {
            this.Quote.Warnings.Add(`Alum IV support rod`);
        }

        const isMaxi = IsMaxigator(this.Quote.System);

        if ((!sp.LongSystemAlignment && !IsMaxigator(this.Quote.System)) && sp.StainlessSteelCable) {
            this.Quote.Warnings.Add(`Stainless Steel Cable should only be available if Long System Alignment or if system is Maxigator.`);
        }

        if (!GalvanizedCNOptionOk(sp.GalvanizedCNPlus, {
            IsReinkeUser: false,
            GrantCNPlus: true, // CN+ permission handled in page.ts
            CNPlusExists: this.CNPlusExists()
        })) {
            this.Quote.Warnings.Add(`Cannot have galvanized CN + spans with the current setup.`);
        }

        if (!ConduitClipWOSpringOK(sp.ConduitClipWOSpringClip, {
            IsEnginDrivenMiniPivot: IsEDMP(this.Quote.System),
            Spacing114Exists: this.spacing114Exists(true),
            Spacing57Exists: this.spacing57Exists(),
        })) {
            this.Quote.Warnings.Add(`Cannot have a conduit clip package with the current setup.`);
        }
        if (!TheftDeterentClipOK(sp.TheftDeterrentClip, {
            IsEnginDrivenMiniPivot: IsEDMP(this.Quote.System),
            Spans: sidef.Spans(this.Quote.System, this.CodeBehind.Side),
        })) {
            this.Quote.Warnings.Add(`Cannot have a theft deterrent clip with the current setup.`);
        }
    }

    private PerformStainlessSteelAnalysis = (): { bSSRequired: boolean, bSSAvailable: boolean, bSSIndeterminate: boolean } => {
        const result = this.CodeBehind.PerformStainlessSteelAnalysis();
        if (this.CodeBehind.OtherSideSpansClass?.FieldSet.DataValid()) {
            const otherSide = this.CodeBehind.OtherSideSpansClass.PerformStainlessSteelAnalysis();
            if (otherSide.bSSRequired) result.bSSRequired = true;
            if (otherSide.bSSIndeterminate) {
                result.bSSIndeterminate = true;
                result.bSSRequired = false;
            }
            if (otherSide.bSSAvailable) result.bSSAvailable = true;
        }
        return result;
    }

    private spacing57Exists = (): boolean => {
        if (this.CodeBehind.Any57()) return true;

        if (this.CodeBehind.OtherSideSpansClass && this.CodeBehind.OtherSideSpansClass.DataValid() && this.CodeBehind.OtherSideSpansClass.Any57()) return true;

        return false;
    }

    private spacing114Exists = (excludeEOS: boolean): boolean => {
        if (this.CodeBehind.Any114(excludeEOS)) return true;

        if (this.CodeBehind.OtherSideSpansClass && this.CodeBehind.OtherSideSpansClass.DataValid() && this.CodeBehind.OtherSideSpansClass.Any114(excludeEOS)) return true;

        return false;
    }

    private AlumExists = () => this.CodeBehind.AnyAluminum() || this.CodeBehind.OtherSideSpansClass.AnyAluminum();

    private Alum6Exists = () => this.CodeBehind.AnyAlum6() || this.CodeBehind.OtherSideSpansClass.AnyAlum6();

    private CNPlusExists = () => this.CodeBehind.Side.Span.some(x => !x.EndBoom && !x.SwingArm &&
        (x.SpanType === SpanTypes.E2665 || x.SpanType === SpanTypes.E2660));

    private DiscExists = () => this.CodeBehind.Side.Span.some(x => x.Disconnecting);
}