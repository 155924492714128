import * as React from "react";
import { FC } from "react";
import SpanSupport from "../SystemDiagram/SpanSupport";
import SpanTensioner from "../SystemDiagram/SpanTensioner";
import Sprinkler from "../SystemDiagram/Sprinkler";
import { SYSTEM_DESIGN_CONSTANTS } from "../SystemDiagram/constants";
import EndGun from "./EndGun";


export interface ISpanDiagramElementProps {
    startDistanceInches: number;
    spanLengthInches: number;
    nozzleSpacingInches: number;
    startHeightInches: number;
    endHeightInches: number;
    elevationStartFeet: number;
    elevationEndFeet: number;
    endGun?: boolean;
}

const findCircle = (x1: number,  y1: number,  x2: number,  y2: number, x3: number, y3: number) => {
    const x12 = (x1 - x2);
    const x13 = (x1 - x3);
 
    const y12 =( y1 - y2);
    const y13 = (y1 - y3);
 
    const y31 = (y3 - y1);
    const y21 = (y2 - y1);
 
    const x31 = (x3 - x1);
    const x21 = (x2 - x1);
 
    //x1^2 - x3^2
    const sx13 = Math.pow(x1, 2) - Math.pow(x3, 2);
 
    // y1^2 - y3^2
    const sy13 = Math.pow(y1, 2) - Math.pow(y3, 2);
 
    const sx21 = Math.pow(x2, 2) - Math.pow(x1, 2);
    const sy21 = Math.pow(y2, 2) - Math.pow(y1, 2);
 
    const f = ((sx13) * (x12)
            + (sy13) * (x12)
            + (sx21) * (x13)
            + (sy21) * (x13))
            / (2 * ((y31) * (x12) - (y21) * (x13)));
    const g = ((sx13) * (y12)
            + (sy13) * (y12)
            + (sx21) * (y13)
            + (sy21) * (y13))
            / (2 * ((x31) * (y12) - (x21) * (y13)));
 
    const c = -(Math.pow(x1, 2)) -
    Math.pow(y1, 2) - 2 * g * x1 - 2 * f * y1;
 
    // eqn of circle be
    // x^2 + y^2 + 2*g*x + 2*f*y + c = 0
    // where centre is (h = -g, k = -f) and radius r
    // as r^2 = h^2 + k^2 - c
    const h = -g;
    const k = -f;
    const sqr_of_r = h * h + k * k - c;
 
    // r is the radius
    const r = Math.sqrt(sqr_of_r);

    return {
        center: { x: h, y: k },
        radius: r
    }
}

const createElevationCurveFromXFactory = (
    x1: number, y1: number,
    x2: number, y2: number,
    amplitude: number
) => {
    const xm = 0.5 * (x1 + x2);
    const ym = 0.5 * (y1 + y2);
    const dy = (y2 - y1);
    const dx = (x2 - x1); 
    const h = Math.sqrt(Math.pow(dy, 2) + Math.pow(dx, 2));
    const cosAlpha = dx / h;
    const sinAlpha = dy / h;
    const xc = xm + amplitude * sinAlpha;
    const yc = ym + amplitude * cosAlpha;
    const circle = findCircle(x1,y1,xc,yc,x2,y2);
    
    const curve = (x: number) => {
        const a = Math.sqrt(circle.radius * circle.radius - (x - circle.center.x) * (x - circle.center.x));
        const y1 = circle.center.y + a;
        const y2 = circle.center.y - a;
        return { y1, y2 };
    }

    return curve;
}

export const createPipeElevationFromXFn = (
    startDistanceInches: number, spanLengthInches: number, 
    beginElevationInches: number, endElevationInches: number) => {
    const pipeCurveAmplitudeInches = SYSTEM_DESIGN_CONSTANTS.pipe.upperAplitude;
    const x1 = startDistanceInches;
    const y1 = beginElevationInches;
    const x2 = startDistanceInches + spanLengthInches;
    const y2 = endElevationInches;
    const curve = createElevationCurveFromXFactory(x1, y1, x2, y2, pipeCurveAmplitudeInches);
    return (x: number) => curve(x).y1;
}

export const createTensionerElevationFromXFn = (
    startDistanceInches: number, spanLengthInches: number,
    beginElevationInches: number, endElevationInches: number) => {
    const pipeCurveAmplitudeInches = -SYSTEM_DESIGN_CONSTANTS.pipe.supportAmplitude;
    const x1 = startDistanceInches;
    const y1 = beginElevationInches;
    const x2 = startDistanceInches + spanLengthInches;
    const y2 = endElevationInches;
    const curve = createElevationCurveFromXFactory(x1, y1, x2, y2, pipeCurveAmplitudeInches);
    return (x: number) => curve(x).y2;
}



const Span: FC<ISpanDiagramElementProps> = ({ 
    spanLengthInches, nozzleSpacingInches, 
    startHeightInches, endHeightInches, 
    startDistanceInches, elevationStartFeet, elevationEndFeet,
    endGun = false
}) => {
    const pipeWidth = SYSTEM_DESIGN_CONSTANTS.pipe.width;

    const pipeElevationFromX = createPipeElevationFromXFn(
        startDistanceInches, spanLengthInches, 
        elevationStartFeet * 12 + startHeightInches, 
        elevationEndFeet * 12 + endHeightInches
    );
    const tensionerElevationFromX = createTensionerElevationFromXFn(
        startDistanceInches, spanLengthInches, 
        elevationStartFeet * 12 + startHeightInches, 
        elevationEndFeet * 12 + endHeightInches
    );

    // generate the elements:
    // pipe:
    const nPipeSectionSegments = SYSTEM_DESIGN_CONSTANTS.pipe.nSections;
    const pipeSectionSegmentXLength = spanLengthInches / (nPipeSectionSegments - 1);
    let pipeSectionsPath = `
        M ${startDistanceInches} ${pipeElevationFromX(startDistanceInches)}
    `;
    for (let i = 0; i < nPipeSectionSegments; i++) {
        const x = startDistanceInches + pipeSectionSegmentXLength * i;
        const y = pipeElevationFromX(x);
        pipeSectionsPath += ` L ${x} ${y}`;
    }
    const pipeElement = <path 
        d={pipeSectionsPath} 
        stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
        strokeWidth={pipeWidth} 
        fill="none"
        strokeLinecap='round'
    />

    // sprinklers:
    const sprinklerElements: JSX.Element[] = [];
    if (nozzleSpacingInches !== undefined) {
        const firstNozzle = startDistanceInches + 2 * nozzleSpacingInches;
        const lastNozzle = startDistanceInches + spanLengthInches - nozzleSpacingInches * 0.5;
        const nozzleLocations: number[] = [];
        for (let location = firstNozzle; location <= lastNozzle; location += nozzleSpacingInches) {
            nozzleLocations.push(location);
        }
        for (let i = 0; i < nozzleLocations.length; i++) {
            const x = nozzleLocations[i];
            const y = pipeElevationFromX(x);
            const sprinklerLength = SYSTEM_DESIGN_CONSTANTS.sprinkler.length;
            sprinklerElements.push(
                <Sprinkler key={i} startDistance={x} y={y} drop={sprinklerLength} />
            )
        }
    }

    // pipe supports:
    const supportElements: JSX.Element[] = [];
    const nsupports = Math.floor(spanLengthInches / SYSTEM_DESIGN_CONSTANTS.pipe.supportEvery);
    const supportspacing = spanLengthInches / nsupports;
    const tensionerCoordinates: { x: number, y: number }[] = [{
        x: startDistanceInches,
        y: tensionerElevationFromX(startDistanceInches)
    }];
    for (let i = 0; i < nsupports; i++) {
        const xc = startDistanceInches + supportspacing * (i + 0.5);
        const yc = tensionerElevationFromX(xc);

        const x1 = xc - SYSTEM_DESIGN_CONSTANTS.pipe.supportWidth * 0.5;
        const x2 = xc + SYSTEM_DESIGN_CONSTANTS.pipe.supportWidth * 0.5;
        const y1 = pipeElevationFromX(x1);
        const y2 = pipeElevationFromX(x2);
        
        supportElements.push(
            <SpanSupport key={i} x1={x1} y1={y1} x2={x2} y2={y2} xc={xc} yc={yc} />
        )
        tensionerCoordinates.push({
            x: xc,
            y: yc
        })
    }
    tensionerCoordinates.push({
        x: startDistanceInches + spanLengthInches,
        y: tensionerElevationFromX(startDistanceInches + spanLengthInches)
    });

    // tensioner
    const tensionerElements: JSX.Element[] = [];
    for (let i = 0; i < tensionerCoordinates.length - 1; i++) {
        const p1 = tensionerCoordinates[i];
        const p2 = tensionerCoordinates[i + 1];
        tensionerElements.push(
            <SpanTensioner 
                key={i} 
                x1={p1.x} 
                y1={p1.y} 
                x2={p2.x} 
                y2={p2.y} />
        )
    }

    // end gun:
    let endGunElement: JSX.Element | undefined = undefined;
    if (endGun) {
        endGunElement = (
            <EndGun
                startDistanceInches={startDistanceInches + spanLengthInches}
                height={elevationEndFeet * 12 + endHeightInches}
            />
        )
    }

    return (
        <g>
            {sprinklerElements}
            {pipeElement}
            {supportElements}
            {tensionerElements}
            {endGunElement}
        </g>
    );
};

export default Span;