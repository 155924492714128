import { BoosterPumpTypes, ElectricalFrequencies, EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { EndGunHelper } from "../EndGunHelper";

export class EndGunRecord {
    public EndGun: EndGunTypes = EndGunTypes.None;
    public BoosterPump: BoosterPumpTypes = BoosterPumpTypes.None;
    public FrequencyHz: ElectricalFrequencies = ElectricalFrequencies.a50;
    public EGH: EndGunHelper;
}

