import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Button, Dialog, Divider, Menu, MenuItem } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { useContext, useState } from "react";
import { createNewUpdateProjectPropertyAction } from "../../../../actions/UpdateProjectProperty";
import { getDefaultNewProjectActions } from "../../../../actions/defaultActions";
import AuthCtx from "../../../../auth/AuthCtx";
import NewProjectWizard from "../../../../components/NewProjectWizard";
import ProjectDetails from "../../../../components/NewProjectWizard/ProjectDetails";
import DeleteProjectMenuItem from "../../../../components/RdpAppBar/DeleteProjectMenuItem";
import DbCtx from "../../../../db/DbCtx";
import IProject, { ProjectState } from "../../../../model/project/IProject";

interface IProps {
    projectId: string;
    navigateToAnotherProject: (projectId: string) => void;
    onDeleteProject: () => void;
    growersActiveProjects: {
        projectId: string;
        project: IProject;
    }[];
}

const ProjectsMenu: React.FC<IProps> = ({
    projectId, navigateToAnotherProject, onDeleteProject, growersActiveProjects
}) => {
    const dbState = useContext(DbCtx);
    const authState = useContext(AuthCtx);

    const dbProject = dbState.projects[projectId];
    const project = dbProject.state;

    const [renameFieldDialogOpen, setRenameFieldDialogOpen] = useState(false);

    const [projectsMenuTarget, setProjectsMenuTarget] = useState<null | HTMLElement>(null);
    const [newProjectId, setNewProjectId] = useState(
        undefined as string | undefined
    );

    // START: Projects logic (aka. fields)
    const openProjectsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setProjectsMenuTarget(event.currentTarget);
    };
    const closeProjectsMenu = () => {
        setProjectsMenuTarget(null);
    };
    // END: Projects logic (aka. fields)

    return (
        <>

            <Button
                endIcon={<ArrowDropDown />}
                onClick={openProjectsMenu}
                sx={{ color: "inherit" }}
            >
                <div style={{overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'none'}}>{project.name}</div>
            </Button>
            <Menu
                keepMounted
                anchorEl={projectsMenuTarget}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={!!projectsMenuTarget}
                onClose={closeProjectsMenu}
            >
                {growersActiveProjects.map((otherProject) => (
                    <MenuItem
                        key={otherProject.projectId}
                        onClick={() => {
                            navigateToAnotherProject(otherProject.projectId);
                            closeProjectsMenu();
                        }}
                        selected={projectId === otherProject.projectId}
                    >
                        {otherProject.project.name}
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem
                    onClick={() => {
                        if (dbState.growers[dbProject.state.growerId]?.owner !== authState.assumedUserId &&
                            dbState.growers[dbProject.state.growerId]?.sharedWithDealership === "readonly") {
                                alert("You do not have permission to create fields for this grower.");
                                return;
                            }

                        closeProjectsMenu();
                        // Open the modal as soon as the new project is created
                        // The project is created with this grower name
                        setNewProjectId(
                            dbState.newProject([
                                ...getDefaultNewProjectActions(authState, dbProject.state.growerId).actions,
                            ])
                        );
                    }}
                >
                    {i18next.format(t("projects.new-field"), 'capitalize-each')}
                </MenuItem>
                <DeleteProjectMenuItem
                    onClick={closeProjectsMenu}
                    deleteProject={onDeleteProject} />
                <MenuItem
                    onClick={() => {
                        closeProjectsMenu();
                        setRenameFieldDialogOpen(true);
                    }}
                >
                    {i18next.format(t('rename'), 'capitalize')} {project.name}
                </MenuItem>
                {newProjectId && newProjectId in dbState.projects && (
                    <NewProjectWizard
                        disableChangeGrower={true}
                        selectedGrowerId={project.growerId}
                        projectId={newProjectId}
                        defaultFieldApproxLocation={project.approximateLocation}
                        closeWizard={() => {
                            dbState.projects[newProjectId].pushAction(createNewUpdateProjectPropertyAction("projectState", ProjectState.Deleted, authState));
                            setNewProjectId(undefined);
                        }} />
                )}
            </Menu>
            <Dialog open={renameFieldDialogOpen}>
                <ProjectDetails alternativeBackLabel={i18next.t("cancel")}
                    selectedGrowerId={project.growerId}
                    justRename
                    dbState={dbState}
                    alternativeNextLabel={i18next.t("save")}
                    onConfirm={() => {
                        setRenameFieldDialogOpen(false);
                    }}
                    dbPrj={dbProject} />
            </Dialog>
        </>
    );
};

export default ProjectsMenu;
