import { Mark } from "@mui/base";
import Slider from "@mui/material/Slider";
import * as React from "react";

interface Props {
  minBearing: number;
  maxBearing: number;
  onChange: (bearing: number) => void;
  bearing: number;
}

const BEARING_STEP = 10;

const BearingSlider: React.FC<Props> = ({
  bearing,
  onChange,
  minBearing,
  maxBearing,
}) => {
  const sliderValues: { bearing: number, marks: Mark }[] = [];
  let i = 0;
  const normalizedMinBearing = (minBearing + 360) % 360;
  const normalizedMaxBearing = (maxBearing + 360) % 360;

  if (normalizedMinBearing === normalizedMaxBearing) {
    // full pivot
    while (i < 360) {
      sliderValues.push({
        bearing: i,
        marks: {
          value: i,
          label: i % 30 === 0 ? `${i}`.padStart(3,'0') + '°' : "" 
        }
      });
      i++;
    }
  }
  else {
    // partial pivot:
    const firstBearing = Math.ceil(normalizedMinBearing);
    const lastBearing = Math.floor(normalizedMaxBearing);
    let iBearing = Math.ceil(normalizedMinBearing / BEARING_STEP) * BEARING_STEP;

    if (firstBearing !== iBearing) {
      sliderValues.push({
        bearing: firstBearing,
        marks: {
          value: i,
          label: '' 
        }
      });
      i++;
    }

    while (iBearing !== lastBearing) {
      sliderValues.push({
        bearing: iBearing,
        marks: {
          value: i,
          label: iBearing % 10 === 0 ? `${iBearing}`.padStart(3,'0') + '°' : ""
        }
      });
      i += 1;    
      iBearing += 1;
      iBearing %= 360;
    }
    
    sliderValues.push({
      bearing: lastBearing,
      marks: {
        value: i,
        label: lastBearing % 10 === 0 ? lastBearing : ""
      }
    });
  }

  const normalizedBearing = (bearing + 360) % 360;
  const found = sliderValues.find(x => x.bearing === normalizedBearing);
  const value = found ? found.marks.value : 0;

  // Slider SX is set here based on the bearing state due to the reason that the long value label is lost at the edges of the slider
  // There is probably a way to do this in pure CSS - I dont know how
  let sliderSx: {} = {
    "&.MuiSlider-markLabel": {
      transform: "none",
    },
  };
  if (found && found.marks.value < i * 0.1) {
    sliderSx["& .MuiSlider-valueLabel"] = {
      left: 0,
      borderBottomLeftRadius: 0,
      "&::before": {
        transform: "translateX(-525%) translate(-50%, 50%) rotate(45deg)"
      },
    };
  } else if (found && found.marks.value > i * 0.9) {
    sliderSx["& .MuiSlider-valueLabel"] = {
      right: 0,
      borderBottomLeftRadius: 0,
      "&::before": {
        transform: "translateX(550%) translate(-50%, 50%) rotate(45deg)"
      },
    };
  } else {
    sliderSx["& .MuiSlider-valueLabel"] = {
      left: "calc(var(--Slider-thumbSize) / 2)",
      borderBottomLeftRadius: 0
    };
  }

  return (
    <Slider
      aria-label="bearing"
      min={0}
      max={i}
      step={1}
      marks={sliderValues.map(x => x.marks)}
      valueLabelDisplay="on"
      track={false}
      value={value}
      onChange={(_, newValue) => {
        const found = sliderValues.find(x => x.marks.value === newValue);
        if (found) {
          onChange(found.bearing);
        }
      }}
      valueLabelFormat={(value) => {
        const found = sliderValues.find(x => x.marks.value === value);
        if (found) {
          return `Bearing ${`${found.bearing}`.padStart(3,'0')}°`;
        }
        return "";
      }}
      sx={sliderSx}
    />
  );
};

export default BearingSlider;
