import { Feature, LineString, Point, Polygon } from "@turf/turf";
import * as JSZip from "jszip";
import prj from "shp-write/src/prj";
import write from "shp-write/src/write";
import { ExportProject } from "../common/interfaces";

interface IArgs {
    eProject: ExportProject;
}
export const exportAsShapeFile = async ({ eProject }: IArgs) => {

    const zip = new JSZip();

    const nameCounts: { [exportType: string]: number } = {};
    for (const feature of eProject.features) {
        const f = convertType(feature as Feature);
        if (!f) continue;
        const name = feature.properties?.importType || 'feature';
        if (!(name in nameCounts)) {
            nameCounts[name] = 0;
        }
        nameCounts[name]++;
        write(
            // field definitions
            f.properties,
            // geometry type
            f.type,
            // geometries
            f.geometries,
            function(err, files) {
                const fileName = `${name}-${nameCounts[name]}`;
                zip.file(fileName + '.shp', files.shp.buffer, { binary: true });
                zip.file(fileName + '.shx', files.shx.buffer, { binary: true });
                zip.file(fileName + '.dbf', files.dbf.buffer, { binary: true });
                zip.file(fileName + '.prj', prj);
            });
    }
    const b64file = await zip.generateAsync({
        compression: 'STORE',
        type: 'base64',

    });

    return 'data:application/zip;base64,' + b64file;
}

function convertType(feature: Feature) {
    let TYPE: 'POINT' | 'POLYLINE' | 'POLYGON' | null;
    switch (feature.geometry.type) {
        case 'Polygon':
            TYPE = 'POLYGON';
            break;
        case 'LineString':
            TYPE = 'POLYLINE';
            break;
        case 'Point':
            TYPE = 'POINT';
            break;    
        default:
            TYPE = null;
            break;
    }
    if (TYPE === null) return;
    const coords = justCoords(feature as Feature<Polygon | LineString | Point>);
    return {
        geometries: (TYPE === 'POLYGON' || TYPE === 'POLYLINE') ? [[coords]] : [coords],
        properties: [feature.properties],
        type: TYPE
    };
}

function justCoords(t: Feature<Polygon | LineString | Point>) {
    if (t.geometry.coordinates[0] !== undefined &&
        t.geometry.coordinates[0][0] !== undefined &&
        t.geometry.coordinates[0][0][0] !== undefined) {
        return t.geometry.coordinates[0];
    } else {
        return t.geometry.coordinates;
    }
}

