import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { DeviceWeights, DropTypes, IPackage, RigidDropBottomFittings, RigidDropMaterialTypes, RigidDropTypes, UPipeMaterialTypes } from "rdptypes/project/ISprinklers";
import { setSprinklerPackage } from ".";
import ISystem from "../../../../../../model/project/ISystem";
import { GetRigidDropBottomFittingOptions, GetRigidDropLengthOptions, GetRigidDropMaterialTypes, GetRigidDropUPipeOptions, GetRigidWeightOptions } from "../../helpers/Rules";
import { getInitialPackageState } from "../../helpers/SprinklerHelper";
import { IFnExecutePropertyValueMap, IPropertyValueMap, ISprinklerPackageRigidDropValidator, ISprinklerValidatorChoiceFieldWithSetAndClear, ISprinklerValidatorFieldWithSet } from "../interfaces";

const dropTypeValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<RigidDropTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.RigidDrop.DropType

    const getAllowableValues = () => {
        return Object.keys(RigidDropTypes) as RigidDropTypes[];
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: RigidDropTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                RigidDrop: {
                    ...sprinklerPackage.RigidDrop,
                    DropType: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).RigidDrop.DropType);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const dropMaterialValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<RigidDropMaterialTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.RigidDrop.DropMaterial

    const getAllowableValues = () => {
        return GetRigidDropMaterialTypes(sprinklerPackage, sys, side === SideEnum.Flex);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: RigidDropMaterialTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                RigidDrop: {
                    ...sprinklerPackage.RigidDrop,
                    DropMaterial: v,
                    UPipeMaterial: null, 
                    BottomFitting: null,
                    DeviceWeight: null
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).RigidDrop.DropMaterial);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const lengthValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<number> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.RigidDrop.Length

    const getAllowableValues = () => {
        return GetRigidDropLengthOptions(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (allowableValues.length === 0) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: number) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                RigidDrop: {
                    ...sprinklerPackage.RigidDrop,
                    Length: v,
                    ReinforcementClamp: false
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).RigidDrop.Length);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const uPipeMaterialValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<UPipeMaterialTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.RigidDrop.UPipeMaterial

    const getAllowableValues = () => {
        return GetRigidDropUPipeOptions(sprinklerPackage, sys, side === SideEnum.Flex);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: UPipeMaterialTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                RigidDrop: {
                    ...sprinklerPackage.RigidDrop,
                    UPipeMaterial: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).RigidDrop.UPipeMaterial);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const deviceWeightValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<DeviceWeights> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.RigidDrop.DeviceWeight;

    const getAllowableValues = () => {
        return GetRigidWeightOptions(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: DeviceWeights) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                RigidDrop: {
                    ...sprinklerPackage.RigidDrop,
                    DeviceWeight: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).RigidDrop.DeviceWeight);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const bottomFittingValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<RigidDropBottomFittings> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.RigidDrop.BottomFitting;

    const getAllowableValues = () => {
        return GetRigidDropBottomFittingOptions(sprinklerPackage, sys, side === SideEnum.Flex);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: RigidDropBottomFittings) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                RigidDrop: {
                    ...sprinklerPackage.RigidDrop,
                    BottomFitting: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).RigidDrop.BottomFitting);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const groundClearanceValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<number> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.RigidDrop.GroundClearance;

    const getError = () => {
        return sprinklerPackage.RigidDrop.DropType === RigidDropTypes.Variable && !value;
    }

    const isError = getError();
    
    const set = (v: number) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                RigidDrop: {
                    ...sprinklerPackage.RigidDrop,
                    GroundClearance: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}
const reinforcementClampValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.RigidDrop.ReinforcementClamp;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                RigidDrop: {
                    ...sprinklerPackage.RigidDrop,
                    ReinforcementClamp: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const allowableValues = [ false, true ];
    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).RigidDrop.ReinforcementClamp);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}

export const rigidDropValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerPackageRigidDropValidator => {
    const options = {
        dropType: dropTypeValidator(sys, side ,packageIdx, executePvm, excludeProperties),
        dropMaterial: dropMaterialValidator(sys, side ,packageIdx, executePvm, excludeProperties),
        length: lengthValidator(sys, side ,packageIdx, executePvm, excludeProperties),
        uPipeMaterial: uPipeMaterialValidator(sys, side ,packageIdx, executePvm, excludeProperties),
        deviceWeight: deviceWeightValidator(sys, side ,packageIdx, executePvm, excludeProperties),
        bottomFitting: bottomFittingValidator(sys, side ,packageIdx, executePvm, excludeProperties),
        groundClearance: groundClearanceValidator(sys, side ,packageIdx, executePvm, excludeProperties),
        reinforcementClamp: reinforcementClampValidator(sys, side ,packageIdx, executePvm, excludeProperties)
    }
    return {
        ...options,
        isError: getSide(sys, side).Sprinklers.Package[packageIdx].Drop === DropTypes.Rigid
            ? Object.values(options).some(x => x.isError)
            : false
    }
}