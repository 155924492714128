import { Feature, Geometry, GeometryCollection } from "@turf/turf";
import * as React from "react";
import { useContext, useEffect } from "react";
import { getBoundingBox } from ".";
import { MapContext } from "../Map/mapContext";


interface IChildProps {
    drawFeatures: Feature<Geometry | GeometryCollection>[];
}
const MapControl: React.FC<IChildProps> = (props) => {

    const mapContext = useContext(MapContext);
    useEffect(() => {
        if (mapContext) {
            const newBounds = getBoundingBox(props.drawFeatures);
            mapContext.fitBounds(newBounds as [number, number, number, number])
        }
    }, [props.drawFeatures]);

    return null;
}

export default MapControl;