import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Position } from "rdptypes/geoTypes";
import * as React from "react";
import { FC, useState } from "react";

export enum DmsMode {
    d,
    dm,
    dms
}

enum NS {
    n,
    s
}

enum EW {
    e,
    w
}

interface Props {
    mode: DmsMode;
    value: Position;
    onChange: (coord: Position) => void;
};

const DmsEditor: FC<Props> = (props) => {
    const [ lon, lat ] = props.value;

    const abslat = Math.abs(lat);
    const abslon = Math.abs(lon);

    const [ latd, setLatd ] = useState(props.mode === DmsMode.d ? abslat.toFixed(4) : Math.floor(abslat).toFixed(0));
    const [ lond, setLond ] = useState(props.mode === DmsMode.d ? abslon.toFixed(4) : Math.floor(abslon).toFixed(0));

    const [ latm, setLatm ] = useState(props.mode === DmsMode.dm ? (60 * (abslat - Math.floor(abslat))).toFixed(2) : Math.floor(60 * (abslat - Math.floor(abslat))).toFixed(0));
    const [ lonm, setLonm ] = useState(props.mode === DmsMode.dm ? (60 * (abslon - Math.floor(abslon))).toFixed(2) : Math.floor(60 * (abslon - Math.floor(abslon))).toFixed(0));

    const [ lats, setLats ] = useState((60 * (60 * (abslat - Math.floor(abslat)) - Math.floor(60 * (abslat - Math.floor(abslat))))).toFixed(0));
    const [ lons, setLons ] = useState((60 * (60 * (abslon - Math.floor(abslon)) - Math.floor(60 * (abslon - Math.floor(abslon))))).toFixed(0));

    const [ ns, setNs ] = useState(lat >= 0 ? NS.n : NS.s);
    const [ ew, setEw ] = useState(lon >= 0 ? EW.e : EW.w);

    const onChange = (latd: string, latm: string, lats: string, ns: NS, lond: string, lonm: string, lons: string, ew: EW) => {
        let newLat = parseFloat(latd);
        if (props.mode === DmsMode.dm || props.mode === DmsMode.dms) {
            newLat += parseFloat(latm) / 60;
        }
        if (props.mode === DmsMode.dms) {
            newLat += parseFloat(lats) / 3600;
        }
        if (ns === NS.s) {
            newLat *= -1;
        }

        let newLon = parseFloat(lond);
        if (props.mode === DmsMode.dm || props.mode === DmsMode.dms) {
            newLon += parseFloat(lonm) / 60;
        }
        if (props.mode === DmsMode.dms) {
            newLon += parseFloat(lons) / 3600;
        }
        if (ew === EW.w) {
            newLon *= -1;
        }

        props.onChange([newLon, newLat]);
    };

    return (
        <>
            <TextField sx={{ml: 2, width: 90}} label="Degrees" value={latd} onChange={e => { setLatd(e.target.value); onChange(e.target.value, latm, lats, ns, lond, lonm, lons, ew); }} />
            { (props.mode === DmsMode.dm || props.mode === DmsMode.dms) && <TextField sx={{width: 80}} label="Minutes" value={latm} onChange={e => { setLatm(e.target.value); onChange(latd, e.target.value, lats, ns, lond, lonm, lons, ew); }} />}
            { props.mode === DmsMode.dms && <TextField sx={{width: 80}} label="Seconds" value={lats} onChange={e => { setLats(e.target.value); onChange(latd, latm, e.target.value, ns, lond, lonm, lons, ew); }} /> }
            <Select
                value={ns}
                onChange={e => {
                    setNs(e.target.value as NS);
                    onChange(latd, latm, lats, e.target.value as NS, lond, lonm, lons, ew);
                }}
            >
                
                <MenuItem value={NS.n}>N</MenuItem>
                <MenuItem value={NS.s}>S</MenuItem>
            </Select>
            <TextField sx={{ml: 2, width: 90}} label="Degrees" value={lond} onChange={e => { setLond(e.target.value); onChange(latd, latm, lats, ns, e.target.value, lonm, lons, ew); }} />
            { (props.mode === DmsMode.dm || props.mode === DmsMode.dms) && <TextField sx={{width: 80}} label="Minutes" value={lonm} onChange={e => { setLonm(e.target.value); onChange(latd, latm, lats, ns, lond, e.target.value, lons, ew); }} /> }
            { props.mode === DmsMode.dms && <TextField sx={{width: 80}} label="Seconds" value={lons} onChange={e => { setLons(e.target.value); onChange(latd, latm, lats, ns, lond, lonm, e.target.value, ew); }} /> }
            <Select
                value={ew}
                onChange={e => {
                    setEw(e.target.value as EW);
                    onChange(latd, latm, lats, ns, lond, lonm, lons, e.target.value as EW);
                }}
            >
                
                <MenuItem value={EW.e}>E</MenuItem>
                <MenuItem value={EW.w}>W</MenuItem>
            </Select>
        </>
    );
};

export default DmsEditor;