import CSection2 from "./CSection2";
import PartsAssembler from "./PartsAssembler";
import { strLanguageText } from "./TranslationsLib";
export class SACRetroClass extends CSection2 {

    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/
        if (this.Quote.System.Circle.SACRetro.LastWheelTrack < 50 || this.Quote.System.Circle.SACRetro.LastWheelTrack > 6000) {
            this.Quote.Warnings.Add(strLanguageText("sltLastWheelTrackMustBeAtLeast"));
        }
    }
    public WritePO = (PO: PartsAssembler) => {
    }
}
