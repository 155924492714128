import FieldSet from "./FieldSet";
import PartsAssembler from "./PartsAssembler";
import QuoteClass from "./QuoteClass";

export default abstract class CSection2 {
    public Quote: QuoteClass;
    public SectionID: number;
    public TranslatedKey: string;

    public FieldSet: FieldSet;
    public DataValid = (): boolean => this.FieldSet.DataValid();

    public abstract DataOK(PA: PartsAssembler, WithWarnings?: boolean, DependencyCheckInProgress?: boolean): void;
    public abstract WritePO(PO: PartsAssembler): void;
}

export enum SectionIDs {
    SystemTypeID,
    BegOfSysID,
    CenterPivotID,
    CanalFeedID,
    KwikTowID,
    HoseFeedID,
    ControlPanelID,
    SpansID,
    RightSpansID,
    LeftSpansID,
    EOSID,
    RightEOSID,
    LeftEOSID,
    GuidanceID,
    RightTowersID,
    LeftTowersID,
    TowersID,
    SACRetroID,
    SwingArmID,
    OptionsID,
    InfoID,
    RightSprinklersID,
    LeftSprinklersID,
    SprinklersConfigID,
    SprinklersChartID,
    AgriInjectID,
    MainlineValvesID,
    AncillaryID,
    SprinklerLubeID,
    ClemonsFiltersID,
    CheckValvesID,
    HeatExchangersID,
    PhaseConvertersID,
    RadioTelemetryID,
    FlowmenterComponentsID,
    RC10ID,
    CropXID
}