import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Stack, Typography } from "@mui/material";
import * as turf from "@turf/turf";
import { LineString, Point } from "@turf/turf";
import i18next from "i18next";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import * as spanf from "roedata/roe_migration/SpanFunctions";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import ISystem from "../../../../model/project/ISystem";
import { SACOptimizationProblem } from "../../../../optimization/centerPivot/sac/SACOptimizer/SAC/SACOptimizationProblem";
import PathUtilities from "../../../../optimization/centerPivot/sac/SACOptimizer/ThinkGeo/PathUtilities";
import { MetersProjectionHelper } from "../../../../optimization/centerPivot/sac/projection";

interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
}

const readAsArrayBuffer = (file: Blob) => {
    return new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (!(reader.result instanceof ArrayBuffer)) {
                reject();
            }
            resolve(reader.result as ArrayBuffer);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
};

interface IArgs {
    pivotCenter: Point;
    guidanceWheelTracks: LineString[];
    systemRadiusFeet: number;
}

const generatePathDataObjectUrl = (system: ISystem): string | null => {
    if (!(system.SystemProperties.SystemType === SystemTypes.CenterPivot && system.sacOptimizerResult?.success)) {
        return null;
    }
    const pivotCenter = system.centerPivot.point;
    const guidanceWheelTracks = system.sacOptimizerResult.geometry.wheelTracksGuidance;
    const systemRadiusFeet = spanf.EndingRadius(
        system,
        system.FlangedSide,
        system.FlangedSide.Span[system.FlangedSide.Span.length - 1]
    )
    const systemLength = turf.convertLength(
        systemRadiusFeet,
        "feet", "meters"
    );  
    const metersProjectionHelper = MetersProjectionHelper.fromWgs80(
        pivotCenter.coordinates[0], 
        pivotCenter.coordinates[1],
        systemLength
    );
    const pd = PathUtilities.FastResampleWheelTrack(
        pivotCenter,
        guidanceWheelTracks,
        metersProjectionHelper
    );
    if (!pd || !pd.length) {
        return null;
    }

    const fa = new Float32Array(pd.flatMap((x) => [x.radius, x.angle]));
    const data = new Blob([fa], { type: "text/plain" });

    // returns a URL you can use as a href
    return window.URL.createObjectURL(data);
};

const PathDataControl: FC<Props> = (props) => {
    const authState = React.useContext(AuthCtx);
    const anchorRef = React.useRef<HTMLAnchorElement>(null);
    const [showExportDialog, setShowExportDialog] = React.useState(false);
    const [showErrorDialog, setShowErrorDialog] = React.useState<"export" | "import" | null>(null);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const handleUploadPathData = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!ev.target.files || ev.target.files.length !== 1) {
            return;
        }

        const file = ev.target.files[0];
        try {
            const ab = await readAsArrayBuffer(file);
            const pathData = PathUtilities.LoadPathArchive(ab);
            if (pathData.length !== SACOptimizationProblem.PivotAngleStepCount) {
                throw new Error("Invalid step count");
                
            }
            else {
                console.log("setting path data", pathData);            
                props.dbPrj.pushAction(
                    createNewUpdateSystemPropertyAction(props.layoutId, props.systemId, "pathData", pathData, authState)
                );
            }
        } catch (e) {
            console.error(e);
            setShowErrorDialog("import");
        }

        ev.target.value = "";
    };

    const handleClearPathData = () => {
        console.log("clearing path data");
        props.dbPrj.pushAction(
            createNewUpdateSystemPropertyAction(props.layoutId, props.systemId, "pathData", undefined, authState)
        );
    };

    const canExport =
        system.SystemProperties.SystemType === SystemTypes.CenterPivot && system.sacOptimizerResult?.success;
    const handleExportPathData = () => {
        setShowExportDialog(true);
    };

    const handleExportDialogCancel = () => {
        setShowExportDialog(false);
    }
    const handleExportDialogConfirm = () => {
        console.log("exporting path data");
        if (canExport) {
            const objectUrl = generatePathDataObjectUrl(system);
            if (objectUrl && anchorRef.current) {
                anchorRef.current.href = objectUrl;
                anchorRef.current.download = "path.dat";
                anchorRef.current.click();
            }
            else {
                setShowErrorDialog("export");
            }
        }
        setShowExportDialog(false);
    }

    const handleErrorDialogOk = () => {
        setShowErrorDialog(null);
    }

    return (
        <FormControl>
            <Typography variant="subtitle2">{i18next.format(i18next.t("path-data-control.title"), 'capitalize-each')}</Typography>
            <Stack direction={"column"} gap={1}>
                <Button
                    variant="contained"
                    component="label"
                    onClick={handleExportPathData}
                    fullWidth
                    disabled={!canExport}
                >
                    {i18next.format(i18next.t("path-data-control.export"), 'capitalize-each')}
                    <a ref={anchorRef} hidden />
                </Button>
                <Stack direction={"row"} gap={1}>
                    <Button 
                        variant="contained" 
                        component="label" 
                        color={
                            (system.pathData && system.pathData.length)
                                ? "warning"
                                : "success"
                        }
                        fullWidth
                    >
                        {
                            system.pathData && system.pathData.length 
                                ? i18next.format(i18next.t("path-data-control.replace"), 'capitalize-each')
                                : i18next.format(i18next.t("path-data-control.import"), 'capitalize-each')
                        }
                        <input type="file" hidden onChange={handleUploadPathData} accept=".dat" />
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={handleClearPathData} 
                        color="error" 
                        fullWidth 
                        disabled={system.pathData === undefined || system.pathData.length === 0}>
                        {i18next.format(i18next.t("path-data-control.clear"), 'capitalize-each')}
                    </Button>
                </Stack>
            </Stack>
            <Dialog
                open={showExportDialog}
                onClose={handleExportDialogCancel}
                aria-labelledby="export-dialog-title"
                aria-describedby="export-dialog-description"
            >
                <DialogTitle id="export-dialog-title">{i18next.format(i18next.t("path-data-control.export-dialog.title"), 'capitalize-each')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="export-dialog-description">
                        {i18next.t("path-data-control.export-dialog.description")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleExportDialogCancel}>
                        {i18next.format(i18next.t("path-data-control.export-dialog.decline"), 'capitalize-each')}
                    </Button>
                    <Button onClick={handleExportDialogConfirm} autoFocus>
                        {i18next.format(i18next.t("path-data-control.export-dialog.agree"), 'capitalize-each')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showErrorDialog !== null}
                onClose={handleErrorDialogOk}
                aria-labelledby="error-dialog-title"
                aria-describedby="error-dialog-description"
            >
                <DialogTitle id="error-dialog-title">{i18next.format(i18next.t("path-data-control.error-dialog.title"), 'capitalize-each')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="error-dialog-description">
                        {
                            showErrorDialog === "import"
                                ? i18next.t("path-data-control.error-dialog.import-description")
                                : i18next.t("path-data-control.error-dialog.export-description")
                        }
                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleErrorDialogOk} autoFocus>
                        {i18next.format(i18next.t("ok"), 'capitalize-each')}
                    </Button>
                </DialogActions>
            </Dialog>
        </FormControl>
    );
};

export default PathDataControl;
