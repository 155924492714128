// Option Infer On
// Imports ReinkeSystems.Data
// Imports WebAPI.Common.Utility

import { MotorLocations, MotorTypes } from "./CommonEnums";
import ElectricalMotor from "./ElectricalMotor";
import { GetEnumDescription_MotorLocations } from "./GetEnumDescription";
import OrderedDictionary from "./OrderedDictionary";
import { Span } from "./Types";


// ''' <summary>
// ''' Holds the collection of motors 
// ''' 
// '''  'NOTE: this would have a CTOR with call to base CTOR 
// '''     OrderedDictionary(Of TKey, TValue).New(capacity As Integer, comparer As IEqualityComparer(Of TKey))
// '''     but since it has no such call, there is no comparer (NOTHING). Why is this an Ordered Dictionary???
// ''' Not sure why this is a Dictionary as it will cause an exception when there's a duplicate.
// ''' The question is then what is the significance of the 'key' and how is it used to identify
// ''' specific instances.
// ''' </summary>
export default class ElectricalMotorsDictionary
    extends OrderedDictionary<string, ElectricalMotor> {
//     ''' <summary>
//     ''' 
//     ''' </summary>
//     ''' <param name="location"></param>
//     ''' <param name="subLocation"></param>
//     ''' <param name="motorType"></param>
//     ''' <param name="Span"></param>
//     ''' <param name="isMaxi"></param>
//     ''' <returns></returns>
    public AddOne(location: MotorLocations, subLocation: number, motorType: MotorTypes,
                    Span: Span = undefined, isMaxi: boolean = false): ElectricalMotor {
        let key = `${GetEnumDescription_MotorLocations(location)}${subLocation === 0 ? "" : " " + subLocation}`
        let m: ElectricalMotor = new ElectricalMotor(key, location, motorType, Span, isMaxi)
        this.Add(key, m);
        return m;
    }

//     ''' <summary>
//     ''' 
//     ''' </summary>
//     ''' <param name="location"></param>
//     ''' <param name="motorType"></param>
//     ''' <param name="qty"></param>
//     ''' <param name="isMaxi"></param>
    public AddMulti(location: MotorLocations, motorType: MotorTypes, qty: number, isMaxi: boolean = false) {
        for (let i = 0; i < qty; i++) {
            this.AddOne(location, i, motorType, undefined, isMaxi);
        }
    }

//     ''' <summary>
//     ''' 
//     ''' </summary>
//     ''' <param name="location"></param>
//     ''' <param name="motorType"></param>
//     ''' <param name="span"></param>
//     ''' <param name="qty"></param>
//     ''' <param name="isMaxi"></param>
    // public AddMulti(location: MotorLocations, motorType: MotorTypes, span: Span, qty: number, isMaxi: boolean = false) {
    // TODO: Note, cannot (easily) overload in JS, modified overloaded fn name
    public AddMulti_WithSpan(location: MotorLocations, motorType: MotorTypes, span: Span, qty: number, isMaxi: boolean = false) {
        for (let i = 0; i < qty; i++) {
            this.AddOne(location, i, motorType, span, isMaxi);
        }
    }

//     ''' <summary>
//     ''' 
//     ''' </summary>
//     ''' <param name="location"></param>
//     ''' <param name="subLocation"></param>
//     ''' <returns></returns>
//     Default Public Overloads Property Item(location As MotorLocations, Optional subLocation As Integer = 0) As ElectricalMotor
//         Get
//             Dim key = $"{location.GetEnumDescription()}{If(subLocation = 0, "", $" {subLocation}")}"
//             Return Item(key)
//         End Get
//         Set(value As ElectricalMotor)
//             Dim key = $"{location.GetEnumDescription()}{If(subLocation = 0, "", $" {subLocation}")}"
//             Item(key) = value
//         End Set
//     End Property

    // This is a replacement function for the VB Item.Get above:
    public get(location: MotorLocations, subLocation = 0): ElectricalMotor {
        let key = `${GetEnumDescription_MotorLocations(location)}${subLocation === 0 ? "" : " " + subLocation}`;
        // Note: VB throws if the key is not found
        if (!this._m.has(key)) {
            throw new Error("KeyNotFound");
            
        }
        return this._m.get(key);
    }

}