import { featureCollection } from "@turf/turf";
import toKml from "tokml";
import { ExportProject } from "../common/interfaces";

interface IArgs {
    eProject: ExportProject;
}

export const exportProjectToKmlString = (eProject: ExportProject) => {
    const fc = featureCollection(eProject.features);
    const kml = toKml(fc, {
        name: 'importType',
        description: 'label'
    }) as string;
    return kml;
}

export const exportToKml = (args: IArgs) => {
    const kml = exportProjectToKmlString(args.eProject);
    const b64file = Buffer.from(kml).toString("base64");
    return 'data:application/vnd;base64,' + b64file;
}