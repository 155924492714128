import IAction from "rdptypes/IAction";
import IAuthState from "../../../../auth/IAuthState";
import { IDrawUpdateExtEvent } from "../../../../components/Map";
import IDbProject from "../../../../db/IDbProject";
import { Interaction } from "../interactionState";
import { ISelectedItem } from "../selectedItem";
import { handleCenterPivotUpdated } from "./handleCenterPivotUpdated";
import { handleChangeOrMove } from "./handleChangeOrMove";
import { handleDynamicCenterPivotResults } from "./handleDynamicCenterPivotResults";
import { handleDynamicLateralResults } from "./handleDynamicLateralResults";
import { handleLateralUpdated } from "./handleLateralUpdated";
import { handleSegmentUpdated } from "./handleSegmentUpdated";
import { handleSystemSelected } from "./handleSystemSelected";

export const handleDrawUpdateExt = (
    interaction: Interaction,
    event: IDrawUpdateExtEvent,
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
    setSelectedItem: (item: ISelectedItem) => void,
    systemDefaults?: ((layoutId: string, systemId: string, authState: IAuthState) => IAction[])
) => {
    console.log(`Interaction: ${Interaction[interaction]} Features updated, event:`, event);

    // If we switch layout during the CenterPivotSelect mode, mapbox will raise an event after the
    // layout has been switch. Handle this here by only reacting to center pivot updates when the
    // interaction mode is Interaction.CenterPivotSelect
    if (event.action === "center_pivot_updated" && interaction === Interaction.CenterPivotSelect) {
        handleCenterPivotUpdated(event, dbPrj, layoutId, authState);
    }
    else if (event.action === "lateral_updated" && interaction === Interaction.LateralSelect) {
        handleLateralUpdated(event, dbPrj, layoutId, authState);
    }
    else if (event.action === 'center_pivot_selected' || event.action === 'lateral_selected') {
        handleSystemSelected(event, setSelectedItem);
    }
    else if (event.action === 'change_coordinates' || event.action === 'move') {
        handleChangeOrMove(event, dbPrj, layoutId, authState);
    }
    else if (event.action === 'dynamic_center_pivot_optimization_result') {
        handleDynamicCenterPivotResults(event, dbPrj, layoutId, authState, systemDefaults);
    }
    else if (event.action === 'dynamic_lateral_optimization_result') {
        handleDynamicLateralResults(event, dbPrj, layoutId, authState, systemDefaults);
    }
    else if (event.action === 'segment_updated') {
        handleSegmentUpdated(event, dbPrj, layoutId, authState);
    }
}