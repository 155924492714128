import { FormGroup } from "@mui/material";
import IBooleanGroup from "rdptypes/roe/IBooleanGroup";
import * as React from "react";
import { FC } from "react";
import IDbProject from "../../../db/IDbProject";
import ComponentListRenderer from "./ComponentListRenderer";
import IComponentRenderContext from "./IComponentRenderContext";

interface Props {
    cmp: IBooleanGroup,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    parentGroupLabel: string | null,
    ctx: IComponentRenderContext,
    ignoreImgTooltips?: boolean,
};

const BooleanGroupRenderer: FC<Props> = (props) => {
    return (
        <>
            <FormGroup>
                <ComponentListRenderer
                    oneColumn={true}
                    parentGroupLabel={props.parentGroupLabel}
                    components={props.cmp.components}
                    dbPrj={props.dbPrj}
                    layoutId={props.layoutId}
                    ignoreImgTooltips={props.ignoreImgTooltips}
                    systemId={props.systemId}
                    ctx={props.ctx} />
            </FormGroup>
        </>
    );
};

export default BooleanGroupRenderer;