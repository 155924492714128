import { Card, CardContent, Typography, styled } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import { useContext } from "react";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import OptimizeCenterPivotForm from "../OptimizeSystemDialog/OptimizeCenterPivotForm";
import OptimizeLateralForm from "../OptimizeSystemDialog/OptimizeLateralForm";
import CustomDefaultsRadioGroup from "./CustomDefaultsRadioGroup";

const StyledCard = styled(Card)({
    marginBottom: 10
})

const TabBody: React.FC = () => {

    const devSettingsState = useContext(DevSettingsCtx);    
    const ns = "dealer-settings.";

    return (
        <>
            <StyledCard>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {i18next.format(i18next.t("roe.center-pivot"), 'capitalize-each')}
                    </Typography>
                    <CustomDefaultsRadioGroup 
                        title={i18next.format(i18next.t(ns + "optimization-defaults"), 'capitalize')}
                        useCustomDefaults={devSettingsState.dealerSettings.optimization.centerPivot.useCustom}
                        setUseCustomDefaults={devSettingsState.dealerSettings.optimization.centerPivot.setUseCustom}
                    />
                    <div>
                        {
                            devSettingsState.dealerSettings.optimization.centerPivot.useCustom && (
                                <OptimizeCenterPivotForm
                                    formState={devSettingsState.dealerSettings.optimization.centerPivot.custom.formState}
                                    setFormState={devSettingsState.dealerSettings.optimization.centerPivot.custom.setFormState}
                                    hasClearancesBeenSet={false}
                                />
                            )
                        }
                    </div>
                </CardContent>
            </StyledCard>
            
            <StyledCard>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {i18next.format(i18next.t("roe.lateral"), 'capitalize-each')}
                    </Typography>
                    <CustomDefaultsRadioGroup 
                        title={i18next.format(i18next.t(ns + "optimization-defaults"), 'capitalize')}
                        useCustomDefaults={devSettingsState.dealerSettings.optimization.lateral.useCustom}
                        setUseCustomDefaults={devSettingsState.dealerSettings.optimization.lateral.setUseCustom}
                    />
                    <div>
                        {
                            devSettingsState.dealerSettings.optimization.lateral.useCustom && (
                                <OptimizeLateralForm
                                    formState={devSettingsState.dealerSettings.optimization.lateral.custom.formState}
                                    setFormState={devSettingsState.dealerSettings.optimization.lateral.custom.setFormState}
                                />
                            )
                        }
                    </div>
                </CardContent>
            </StyledCard>
        </>
    )
}

export default <TabBody />;
