import { CartTypes, HoseFeedTypes, ICircleCenterPivot, ICircleSwingArm, ILateral, ISystemBase, SpanTypes, TowerHeights } from "rdptypes/project/ISystemBase.AutoGenerated";
import { GetTireHeightAdjustment } from "./SideFunctions";
import { HasSwingArmCorner, IsReverseTow } from "./SystemFunctions";
import { Side, Tower } from "./Types";

export const centerPivotHeight = (centerPivot: ICircleCenterPivot) =>
    centerPivot.PivotCenterHeight === TowerHeights.LowProfile ? 9
        : centerPivot.PivotCenterHeight === TowerHeights.Sugargator ? 18
            : 13;

export const canalFeedHeight = (lateral: ILateral) =>
    lateral.CanalFeed.Cart === CartTypes.Sugargator ? 16.75 + GetTireHeightAdjustment(lateral.Tires)
        : 12.75 + GetTireHeightAdjustment(lateral.Tires);

export const hoseFeedHeight = (lateral: ILateral) =>
    lateral.HoseFeed.HoseFeedType === HoseFeedTypes.FourWheelDrive ? 12.6 + GetTireHeightAdjustment(lateral.Tires)
        : lateral.HoseFeed.HoseFeedType === HoseFeedTypes.Sugargator ? 16.6 + GetTireHeightAdjustment(lateral.Tires)
            : 13 + GetTireHeightAdjustment(lateral.Tires);

export const kwikTowHeight = 13;

export const swingArmHeight = (swingArm: ICircleSwingArm) => 12 + GetTireHeightAdjustment(swingArm.Tires);

export const towerHeight = (sys: ISystemBase, side: Side, tower: Tower) => {
    const tireHeightAdjustment = GetTireHeightAdjustment(tower.Tires);

    // Not last tower or if last tower, not a hinge tower
    if (tower.TowerNumber !== side.Tower.length || !HasSwingArmCorner(sys)) {
        if (!IsReverseTow(sys)) {
            switch (tower.TowerType) {
                case TowerHeights.LowProfile:
                    switch (side.Span[tower.TowerNumber - 1].SpanType) {
                        case SpanTypes.A60G:
                            return 8 + tireHeightAdjustment;
                        default:
                            return 9 + tireHeightAdjustment;
                    }
                case TowerHeights.Sugargator:
                    switch (side.Span[tower.TowerNumber - 1].SpanType) {
                        case SpanTypes.A80G:
                            return 16 + tireHeightAdjustment;
                        default:
                            return 18 + tireHeightAdjustment;
                    }
                default:
                    switch (side.Span[tower.TowerNumber - 1].SpanType) {
                        case SpanTypes.AlumIV:
                        case SpanTypes.A60G:
                            return 12 + tireHeightAdjustment;
                        default:
                            return 13 + tireHeightAdjustment;
                    }
            }
        } else {
            return 13 + tireHeightAdjustment;
        }
    } else { // LastTower of SAC
        switch (tower.TowerType) {
            case TowerHeights.LowProfile:
                return 8 + tireHeightAdjustment;
            default:
                return 12 + tireHeightAdjustment;
        }
    }
}