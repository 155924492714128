import { DeviceTypes } from "rdptypes/project/ISprinklers";

export class SelectNozzleResult {
    public PreferredNozzle: number = 0;
    public RegUsed: boolean = false;
    public PressureAtOutlet: number = 0;
    public PressureAtDevice: number = 0;
    public GPMRequired: number = 0;
    public GPMDeliverd: number = 0;
    public GPMMaximum: number = 0;
    public DeviceType: DeviceTypes = DeviceTypes.None;
}

