import * as React from "react";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider
} from "react-router-dom";

import MainLinePressureLossPage from "../tools/pages/MainLinePressureLoss";
import SystemComparisonPage from "../tools/pages/SystemComparison";
import UndergroundWireSizingPage from "../tools/pages/UndergroundWireSizing";
import Layout from "./layouts/Layout";
import Project from "./layouts/Project";
import Root from "./layouts/Root";
import System from "./layouts/System";
import LayoutMapPage from "./pages/LayoutMapPage";
import ProjectList from "./pages/ProjectList";
import RDPProposalPage from "./pages/RDPProposalPage";
import SystemDesign from "./pages/SystemDesign";
import ProjectIndex from "./redirects/ProjectIndex";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                index: true,
                element: <Navigate to="/projects" />,
            },
            {
                path: "projects",
                element: <ProjectList />,
            },
            {
                path: "projects/:projectId",
                element: <Project />,
                children: [
                    {
                        index: true,
                        element: <ProjectIndex />,
                    },
                    {
                        path: "layouts/:layoutId",
                        element: <Layout />,
                        children: [
                            {
                                path: "map",
                                element: <LayoutMapPage />,
                            },
                            {
                                path: "systems/:systemId",
                                element: <System />,
                                children: [
                                    {
                                        path: "design",
                                        element: <SystemDesign />,
                                    }
                                ],
                            },
                            {
                                path: "systems/rdpproposal/:type/:systemId",
                                element: <RDPProposalPage/>
                            },
                        ],
                    },
                ],
            },
            {
                path: "tools/undergroundWireSizing",
                element: <UndergroundWireSizingPage />,
            },
            {
                path: "tools/mainLinePressureLoss",
                element: <MainLinePressureLossPage />,
            },
            {
                path: "tools/systemComparison",
                element: <SystemComparisonPage />,
            }
        ],
    },
]);

const Router = () => {
    return (
        <RouterProvider router={router} />
        );
}

export default Router;