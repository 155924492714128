import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Feature } from "geojson";

const SimpleSelectMode = MapboxDraw.modes.simple_select;
export const ProjectSelectMode = { ...SimpleSelectMode };
export default ProjectSelectMode;

// Notes:
// This extension extends simple_select mode.
// It is intended to handle all interaction with the systems during layout map view
// Two custom event (draw.update_ext) are fired when in this mode

ProjectSelectMode.toDisplayFeatures = function(this: MapboxDraw.DrawCustomModeThis, state, feature: Feature, display) {   
    display(feature)
    this.fireActionable(state);
};


ProjectSelectMode.clickOnFeature = function(this, state, e) {
    const { featureTarget } = e;
    this.map.fire("draw.update_ext", {
        action: "project_selected",
        definition: {
            projectId: featureTarget.properties.user_rdpProjectId
        }
    });
}

ProjectSelectMode.onMouseMove = function(state, e) {
    if (!e.featureTarget || !e.featureTarget.properties) {
        this.map.fire("draw.update_ext", {
            action: "project_hovered",
            definition: {
                projectId: undefined
            }
        });
        return;
    }
    const { user_rdpFeatureType, user_rdpProjectId } = e.featureTarget.properties;
    if (user_rdpFeatureType !== "fieldBoundary" || !user_rdpProjectId) {
        this.map.fire("draw.update_ext", {
            action: "project_hovered",
            definition: {
                projectId: undefined
            }
        });
        return;
    }
    this.map.fire("draw.update_ext", {
        action: "project_hovered",
        definition: {
            projectId: user_rdpProjectId
        }
    });
};
