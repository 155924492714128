import { ISystemBase, SACDistributionFlowRate, SACZoneControlTypes, SwingArmLengths } from "rdptypes/project/ISystemBase.AutoGenerated";

export class SystemRecord {
    public System: ISystemBase;
    public HasSACESP: boolean = false;
    public HasDualSprinklerPackage: boolean = false;
    public IsLateralMove: boolean = false;
    public StartingLocation: number = 0;
    public FixedPivotPressure: number = 0;
    public CalculatePivotPressure: boolean = true;
    public FixedPivotPressureByMaxGPM: number = 0;
    public CalculatePivotPressureByMaxGPM: boolean = true;
    public HasESAC: boolean = false;
    public ZoneControlType: SACZoneControlTypes = SACZoneControlTypes.ESAC060;
    public DistributionFlowRate: SACDistributionFlowRate = SACDistributionFlowRate.Low;
    public SwingArmLength: SwingArmLengths = SwingArmLengths.None;
    public VRISACSpan: boolean = false;
    public DesignedEndPressure: number = 0;
    public LastPossibleOutletNumber: number = 0;

    public Reset = () => {
        this.HasSACESP = false;
        this.HasDualSprinklerPackage = false;
        this.IsLateralMove = false;
        this.StartingLocation = 0;
        this.FixedPivotPressure = 0;
        this.CalculatePivotPressure = true;
        this.FixedPivotPressureByMaxGPM = 0;
        this.CalculatePivotPressureByMaxGPM = true;
        this.LastPossibleOutletNumber = 0;
    }
}

