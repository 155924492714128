// will return elevation profiles by bearing

import * as turf from "@turf/turf";
import IProject from "../../model/project/IProject";
import ISystem from "../../model/project/ISystem";
import { createFeatureFromGeometry } from "../LayoutMap/drawFeaturesFactory";
import { IElevationProfile } from "./interfaces";

import * as spanf from "roedata/roe_migration/SpanFunctions";
import { ILayoutElevation } from "../../GeometryHelpers/GeometryProvider";

const ELEVATION_PROFILE_STEP_FEET = 30;

interface IArgs {
    project: IProject;
    system: ISystem
    elevation: ILayoutElevation;
}

interface IReturn {
    normalizedStartBearing: number;
    minimumElevation: {
        bearing: number;
        elevationFeet: number;
    }
    maximumElevation: {
        bearing: number;
        elevationFeet: number;
    }
    pivotCenter: {
        elevationfeet: number;
    }
    profiles: IElevationProfile[];
    systemLength: number;
}

export const centerPivotElevationProfiler = (args: IArgs): IReturn | undefined => {

    const { system, project, elevation } = args;

    // BEGIN: Check validity
    // we can only extract elevations if the elvation fns are available:
    if (!elevation) {
        return undefined;
    }
    
    // we will only extract an elevation profile if this is a center pivot:
    if (!system.centerPivot) {
        return undefined;
    }
    
    // we will only extract an elevation profile if all span tower lengths are defined:
    if (system.FlangedSide.Span.some((x) => !Number.isFinite(spanf.LengthInFeet(system.FlangedSide, x)))) {
        return undefined;
    }
    
    // we will only extract an elevation profile if the system length is not 0:
    let systemLength = system.FlangedSide.Span.length
        ? spanf.EndingRadius(system, system.FlangedSide, system.FlangedSide.Span.slice(-1)[0])
        : 0;
    if (systemLength === 0) {
        return undefined;
    }
    // END: Check validity


    // normalize and set the inital bearing for the side view:
    const normalizedStartBearing = ( 
        Math.ceil((system.Circle.CenterPivot.isPartialPivot && system.Circle!.CenterPivot.clockwiseCompassHeadingStart) || 0) + 360
    ) % 360;

    // extend system length by 10foot needed by system design
    systemLength += 10;
    
    const center = system.centerPivot!.point;
    const minimumElevation = {
        bearing: 0,
        elevationFeet: Number.MAX_VALUE
    };
    const maximumElevation = {
        bearing: 0,
        elevationFeet: Number.MIN_VALUE
    };
    const profiles: IElevationProfile[] = [];
    let centerPivotElevation: number = undefined;

    for (let bearing = 0; bearing < 360; bearing++) {
        const bearingProfile: IElevationProfile = [];

        for (
            let distanceFeet = 0;
            distanceFeet <= systemLength;
            distanceFeet += ELEVATION_PROFILE_STEP_FEET
        ) {
            const point = turf.destination(
                center,
                distanceFeet,
                bearing,
                { units: "feet" }
            );
            const elevationFeature = elevation.getPoint(point.geometry);
            if (!elevationFeature || elevationFeature.properties.elevationMeters === null) {
                // then we cannot provide a complete profile. Exit
                return;
            }
            const elevationMeters = elevationFeature.properties.elevationMeters;
            const elevationFeet = Math.sign(elevationMeters) * turf.convertLength(Math.abs(elevationMeters), 'meters', 'feet');

            if (distanceFeet === 0 && centerPivotElevation === undefined){
                centerPivotElevation = elevationFeet;
            }

            bearingProfile.push({
                minDistanceFeet: distanceFeet === 0 ? distanceFeet - ELEVATION_PROFILE_STEP_FEET : distanceFeet,
                maxDistanceFeet: distanceFeet + ELEVATION_PROFILE_STEP_FEET,
                elevationFeet,
            });
            if (elevationFeet < minimumElevation.elevationFeet) {
                minimumElevation.bearing = bearing;
                minimumElevation.elevationFeet = elevationFeet;
            }
            if (elevationFeet > maximumElevation.elevationFeet) {
                maximumElevation.bearing = bearing;
                maximumElevation.elevationFeet = elevationFeet;
            }
        }
        profiles.push(bearingProfile);
    }

    return {
        profiles,
        normalizedStartBearing,
        minimumElevation,
        maximumElevation,
        systemLength,
        pivotCenter: {
            elevationfeet: centerPivotElevation
        }
    }
}

export const generateCenterPivotSweepLineFeature = (system: ISystem, length: number, bearing: number) => {
    if (!system.centerPivot) return undefined;
    const center = system.centerPivot.point;
    const end = turf.rhumbDestination(
        center,
        length,
        bearing,
        { units: "feet" }
    );
    const sweepLineString = turf.lineString([
        center.coordinates,
        end.geometry.coordinates,
    ]);
    const sweepLineFeature = createFeatureFromGeometry(
        sweepLineString.geometry,
        "sweepline"
    );
    return [sweepLineFeature];
};