import { IconButton, Tooltip } from "@mui/material";
import i18next from "i18next";
import IAction from "rdptypes/IAction";
import * as React from "react";
import XmlImporter from "roe/XmlImporter";
import { createNewImportSystemAction } from "../../../actions/ImportSystemAction";
import { createNewLayoutAction } from "../../../actions/NewLayoutAction";
import { createNewUpdateProjectPropertyAction } from "../../../actions/UpdateProjectProperty";
import AuthCtx from "../../../auth/AuthCtx";
import IDbState from "../../../db/IDbState";
import { ProjectState, ProjectType } from "../../../model/project/IProject";

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

interface Props {
    dbState: IDbState;
    gid: string;
}

const ImportFieldButton: React.FC<Props> = (props) => {

    const authState = React.useContext(AuthCtx);
    
  const handleImportField = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (props.dbState.growers[props.gid]?.owner !== authState.assumedUserId &&
        props.dbState.growers[props.gid]?.sharedWithDealership === "readonly") {
            alert("You do not have permission to create fields for this grower.");
            return;
        }

    if (!ev.target.files || ev.target.files.length !== 1) {
        console.log("No file selected");
        ev.target.value = "";
        return;
    }

    const file = ev.target.files[0];
    ev.target.value = "";

    if (file.name.toLowerCase().endsWith(".xml")) {
        await importFieldXml(file);
    } else {
        await importFieldJson(file);
    }
  }

  const importFieldXml = async (file: File) => {
    var sys = new XmlImporter().decodeXml(await file.text());


    const actions: IAction[] = [];
    actions.push(
        createNewUpdateProjectPropertyAction(
            "name",
            "Imported - " + file.name,
            authState
        )
    );
    actions.push(
        createNewUpdateProjectPropertyAction(
            "growerId",
            props.gid,
            authState
        )
    );
    actions.push(
        createNewUpdateProjectPropertyAction(
            "projectType",
            ProjectType.DesignOnly,
            authState
        )
    );
    actions.push(
        createNewUpdateProjectPropertyAction(
            "projectState",
            ProjectState.Active,
            authState
        )
    )
    
    const { layoutId, action } = createNewLayoutAction(authState);
    actions.push(action);
    
    const { systemId, action: importSystemAction } = createNewImportSystemAction(layoutId, sys, authState);
    actions.push(importSystemAction);

    const projectId = props.dbState.newProject(actions);
    console.log("Imported as ", projectId)
  }

  const importFieldJson = async (file: File) => {
    let json: any;
    try {
        const r = new Response(file);
        json = await r.json();
    }
    catch {
        console.log("Error reading selected file");
        return;
    }
    console.log(json)
    console.log(Object.keys(props.dbState.projects))
    const actions: IAction[] = [];
    const requiredKeys = [
        "name", "projectType", "layouts"
    ]
    if ("projectType" in json) {
        if (json.projectType === ProjectType.LayoutAndDesign) {
            requiredKeys.push("systemClearance");
        }
    }
    for (const [k,v] of Object.entries(json)) {
        console.log(k,v)
    }
    const keys = Object.keys(json);
    if (requiredKeys.some(rk => !keys.includes(rk))) {
        console.log("The imported JSON is not valid")
        return;
    }
    actions.push(
        createNewUpdateProjectPropertyAction(
            "name",
            "Imported - " + json.name,
            authState
        )
    )
    actions.push(
        createNewUpdateProjectPropertyAction(
            "systemClearance",
            json.systemClearance || 5,
            authState
        )
    )
    actions.push(
        createNewUpdateProjectPropertyAction(
            "growerId",
            props.gid,
            authState
        )
    )
    actions.push(
        createNewUpdateProjectPropertyAction(
            "projectType",
            json.projectType,
            authState
        )
    )
    if (json.approximateLocation) {
        actions.push(
            createNewUpdateProjectPropertyAction(
                "approximateLocation",
                json.approximateLocation,
                authState
            )
        )
    }
    actions.push(
        createNewUpdateProjectPropertyAction(
            "layouts",
            json.layouts,
            authState
        )
    )
    actions.push(
        createNewUpdateProjectPropertyAction(
            "projectState",
            ProjectState.Active,
            authState
        )
    )
    const projectId = props.dbState.newProject(actions);
    console.log("Imported as ", projectId)
  }

    return (
        <Tooltip title={i18next.format(i18next.t("import-field"), 'capitalize-each')} placement="bottom">
            <IconButton component="label" color="warning">
                <FileUploadOutlinedIcon/>
                <input type="file" hidden onChange={handleImportField} accept=".json,.xml" />
            </IconButton>
        </Tooltip>
    );
};

export default ImportFieldButton;
