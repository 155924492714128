import * as turf from "@turf/turf";
import { getEndOfSystemIfValid } from "rdptypes/helpers/EndOfSystem";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import { EndGunTypes, EndOfSystemTypes, SwingArmLengths } from "rdptypes/project/ISystemBase.AutoGenerated";
import { EndGunLocation, getEndGunThrow } from "../../../model/project/IEndGun";
import ISystem from "../../../model/project/ISystem";
import { SACType } from "../../../optimization/centerPivot/sac/SACOptimizer/ThinkGeo/OptimizeUtilities";

type IIsValidSacReturn = {
    isSac: false;
} | {
    isSac: true;
    isValid: false;
    messages: string[];
} | {
    isSac: true;
    isValid: true;
    sacType: SACType;
    endGunThrowsMeters: number[];
}

export const isValidSac = (system: ISystem): IIsValidSacReturn => {
    const eos = getEndOfSystemIfValid(system, SideEnum.Flanged);
    const isSacType = eos && eos.endOfSystemType === EndOfSystemTypes.SAC;
    if (!isSacType) {
        return {
            isSac: false
        }
    }

    const leading = system.Circle?.SwingArm?.LeadingSwingArm || false;

    let sacType: SACType | undefined = undefined;
    switch (system.FlangedSide.EndOfSystem.SwingArmLength) {
        case SwingArmLengths.SAC156:
            sacType = leading ? SACType.MSAC_156_LEADING : SACType.MSAC_156_TRAILING;
                break;
            case SwingArmLengths.SAC175:
                sacType = leading ? SACType.MSAC_175_LEADING : SACType.MSAC_175_TRAILING;
                break;
            case SwingArmLengths.SAC194:
                sacType = leading ? SACType.SAC_LEADING : SACType.SAC_TRAILING;
                break;
            case SwingArmLengths.SAC213:
                sacType = leading ? SACType.SSAC_LEADING : SACType.SSAC_TRAILING;
                break;
            default:
                return {
                    isSac: false
                }
    }
    
    const endGunThrowsMeters: number[] = [];

    if (system.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary !== EndGunTypes.None) {
        const throwFeet = getEndGunThrow(system, SideEnum.Flanged, EndGunLocation.Primary);
        if (throwFeet) {
            endGunThrowsMeters.push(turf.convertLength(throwFeet, "feet", "meters"));
        }
    }
    if (system.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary !== EndGunTypes.None) {
        const throwFeet = getEndGunThrow(system, SideEnum.Flanged, EndGunLocation.Secondary);
        if (throwFeet) {
            endGunThrowsMeters.push(turf.convertLength(throwFeet, "feet", "meters"));
        }
    }

    return {
        isSac: true,
        isValid: true,
        sacType,
        endGunThrowsMeters
    }
}