import { Button, Card, CardActionArea, CardContent, CardMedia, DialogActions, Stack, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { t } from "i18next";
import * as React from "react";
import { FC, useContext } from "react";
import { createNewUpdateProjectPropertyAction } from "../../actions/UpdateProjectProperty";
import AuthCtx from "../../auth/AuthCtx";
import IDbProject from "../../db/IDbProject";
import { ProjectState, ProjectType } from "../../model/project/IProject";

interface Props {
    dbPrj: IDbProject;
    onCancel: () => any;
    onClickNext: () => any;
};

const designCardImage = new URL(
    'designCardImage.png?as=webp&width=345',
    import.meta.url
  );

  const layoutCardImage = new URL(
      'layoutCardImage.png?as=webp&width=345',
      import.meta.url
    );

const ChooseProjectType: FC<Props> = (props) => {
    const ns = "projects.";

    const authState = useContext(AuthCtx);

    const onClickNext = (projectType: ProjectType) => {
        props.dbPrj.pushAction({
            ...createNewUpdateProjectPropertyAction("projectState", ProjectState.Active, authState),
            undoMode: "block", // Don't allow undoing new project actions
        });
        props.dbPrj.pushAction({
            ...createNewUpdateProjectPropertyAction("projectType", projectType, authState),
            undoMode: "block", // Don't allow undoing new project actions
        });
        props.onClickNext();
    };

    return (
        <>
            <DialogContent>
                <Stack direction="row" spacing={2}>
                <Card sx={{ maxWidth: "50%" }}
                    onClick={() => {
                        onClickNext(ProjectType.LayoutAndDesign);
                    }}
                    >
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="250"
                            image={layoutCardImage.toString()}
                            alt="screenshot showing field layout"
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {t(ns + "field-project")}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card sx={{ maxWidth: "50%" }}
                    onClick={() => {
                        onClickNext(ProjectType.DesignOnly);
                    }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="250"
                            image={designCardImage.toString()}
                            alt="screenshot showing system design"
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {t(ns + "system-project")}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                </Stack>
                <DialogActions>
                    <Button onClick={props.onCancel}>{t("back")}</Button>
                </DialogActions>
            </DialogContent>
        </>
    );
};

export default ChooseProjectType;