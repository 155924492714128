import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Feature, Polygon, Position, booleanContains, lineString, midpoint, point } from "@turf/turf";
import { rdpFunctions } from ".";
import { loCustom, ptldCustom } from "../../GeometryHelpers";
import { BoundaryHelper } from "../../GeometryHelpers/BoundaryHelper";
import { ObstacleHelper } from "../../GeometryHelpers/ObstacleHelper";
import SegmentHelper, { ESegmentClearanceType } from "../../GeometryHelpers/SegmentHelper";
import CenterPivotGeometryHelper, { IProperties as ICenterPivotProperties } from "../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper, { IProperties as ILateralProperties } from "../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { getSystemValidity } from "../../GeometryHelpers/SystemGeometryHelpers/getSystemValidity";
import { IGetSystemValidityArgs, SystemValidity } from "../../GeometryHelpers/SystemGeometryHelpers/interfaces";
import { staticDevSettingsDbProvider } from "../../db/DevSettingsDbProvider";
import { DisplayLengthUnitBuilder } from "../../helpers/lengths";
import IFieldBoundary from "../../model/project/IFieldBoundary";
import IObstacle from "../../model/project/IObstacle";
import { ISegment } from "../../model/project/ISegment";
import { IDisplaySettingsFormState } from "../DealerSettingsDialog/TabDisplaySettings";
import { isOfMetaType } from "./copied-from-mapbox-gl-draw/common_selectors";
import * as Constants from "./copied-from-mapbox-gl-draw/constants";
import createVertex from "./copied-from-mapbox-gl-draw/create_vertex";
import doubleClickZoom from './copied-from-mapbox-gl-draw/double_click_zoom';

const DirectSelectMode = MapboxDraw.modes.direct_select;
export const SegmentSelectMode = { ...DirectSelectMode };
export default SegmentSelectMode;

export const SEGMENT_SELECT = "segment_select_mode";

export interface IDrawUpdateExtEvent_SegmentUpdated {
    action: "segment_updated",
    definition: {
        feature: Feature
    }
}
export interface IDrawUpdateExtEvent_SegmentModeEntered {
    action: "segment_select_enter",
    definition: {
        editMode: IEditMode;
    }
}

export type IEditMode = "deleteVerticies" | "verticies" | ESegmentClearanceType;
export interface IOpts {
    featureId: string;
    editMode?: IEditMode;
}
interface IState {
    featureId: string;
    editMode: IEditMode;
    mode: "obstacle" | "boundary";
    equipementClearanceFeature: MapboxDraw.DrawMultiFeature<'MultiPolygon'>;
    sTowerClearanceFeature: MapboxDraw.DrawMultiFeature<'MultiPolygon'>;
    hTowerClearanceFeature: MapboxDraw.DrawMultiFeature<'MultiPolygon'>;
    guideLinesFeature: MapboxDraw.DrawMultiFeature<'MultiLineString'>;
    definition: IDefinition;
    feature: MapboxDraw.DrawPolygon;
    selectedCoordPaths: string[];
    fire_segment_updated?: boolean;
    systemValidityArgsUpdaterFn: ISystemValidityArgsUpdaterFn;
    displaySettings: IDisplaySettingsFormState;
    clearanceType: ESegmentClearanceType;
    systemsMap: { [ systemId: string ]: { layoutId: string, systemValidityArgs: IGetSystemValidityArgs }};
}
type IMode = "obstacle" | "boundary";

type IDefinition = IObstacle | IFieldBoundary;

const getModeFromFeature = (f: MapboxDraw.DrawFeature): IMode | "unknown" => {
    const rdpFeatureType = f.properties.rdpFeatureType as string;
    switch (rdpFeatureType) {
        case "obstacle":
        case "wheelObstacle":
            return "obstacle";
        case "fieldBoundary":
        case "pivotCenterBoundary":
        case "wetAreaBoundary":
            return "boundary";    
        default:
            return "unknown";
    }
}
type ISystemValidityArgsUpdaterFn = (
    (
        incommingArgs: IGetSystemValidityArgs, 
        updatedEquipmentClearancePolygons: Polygon[], 
        updatedSTowerClearancePolygons: Polygon[], 
        updatedHTowerClearancePolygons: Polygon[]
    ) => IGetSystemValidityArgs | undefined
);
const getSystemValidityArgsUpdaterFromFeature = (f: MapboxDraw.DrawFeature, editMode: IEditMode): ISystemValidityArgsUpdaterFn => {
    switch (editMode) {
        case ESegmentClearanceType.EquipmentClearance: 
        case ESegmentClearanceType.STowerClearance: 
        case ESegmentClearanceType.HTowerClearance: {
            const rdpFeatureType = f.properties.rdpFeatureType as string;
            switch (rdpFeatureType) {
                case "obstacle": {
                    const obstacleIndex = f.properties.obstacleIndex as number;
                    return (incommingArgs, updatedEquipmentClearancePolygons, updatedSTowerClearancePolygons, updatedHTowerClearancePolygons) => {
                        const updatedArgs = {
                            ...incommingArgs
                        }
                        updatedArgs.systemClearanceObstacles[obstacleIndex].polygons = updatedEquipmentClearancePolygons;
                        if (incommingArgs.sac) {
                            updatedArgs.sac = { ...incommingArgs.sac }
                            updatedArgs.sac.hTowerClearanceObstacles[obstacleIndex].polygons = updatedHTowerClearancePolygons;
                            updatedArgs.sac.sTowerClearanceObstacles[obstacleIndex].polygons = updatedSTowerClearancePolygons;
                        }
                        return updatedArgs;
                    }
                }
                case "wheelObstacle": {
                    const wheelObstacleIndex = f.properties.wheelObstacleIndex as number;
                    return (incommingArgs, updatedEquipmentClearancePolygons, updatedSTowerClearancePolygons, updatedHTowerClearancePolygons) => {
                        const updatedArgs = {
                            ...incommingArgs
                        }
                        updatedArgs.systemClearanceWheelObstacles[wheelObstacleIndex].polygons = updatedEquipmentClearancePolygons;
                        if (incommingArgs.sac) {
                            updatedArgs.sac = { ...incommingArgs.sac }
                            updatedArgs.sac.hTowerClearanceWheelObstacles[wheelObstacleIndex].polygons = updatedHTowerClearancePolygons;
                            updatedArgs.sac.sTowerClearanceWheelObstacles[wheelObstacleIndex].polygons = updatedSTowerClearancePolygons;
                        }
                        return updatedArgs;
                    }
                }
                case "fieldBoundary": {
                    return (incommingArgs, updatedEquipmentClearancePolygons, updatedSTowerClearancePolygons, updatedHTowerClearancePolygons) => {
                        const updatedArgs = {
                            ...incommingArgs
                        }
                        updatedArgs.systemClearanceBoundaries = updatedEquipmentClearancePolygons;
                        if (incommingArgs.sac) {
                            updatedArgs.sac = { ...incommingArgs.sac }
                            updatedArgs.sac.hTowerClearanceBoundaries = updatedHTowerClearancePolygons;
                            updatedArgs.sac.sTowerClearanceBoundaries = updatedSTowerClearancePolygons;
                        }
                        return updatedArgs;
                    }
                }
                case "pivotCenterBoundary": {
                    return (incommingArgs, updatedEquipmentClearancePolygons, updatedSTowerClearancePolygons, updatedHTowerClearancePolygons) => {
                        const updatedArgs = {
                            ...incommingArgs
                        }
                        updatedArgs.systemClearancePivotCenterBoundaries = updatedEquipmentClearancePolygons;
                        return updatedArgs;
                    }
                }
                case "wetAreaBoundary":
                default:
                    return undefined;
            }    
        }
        default:
            return undefined;
    }
}

const getGeometryHelperFromMode = (mode: IMode) => {
    switch (mode) {
        case "boundary":
            return BoundaryHelper;
        case "obstacle":
            return ObstacleHelper;    
        default:
            throw new Error("Unkown mode " + mode);
            
    }
}

const getGuideLineCoordinates = (state: IState): Position[][] => {

    if (state.editMode === 'verticies') return [];
    const positions: Position[][] = [];
    for (let i = 0; i < state.definition.segments.length; i++) {
        const segment = state.definition.segments[i];
        const clearance = SegmentHelper.getClearance(segment, state.clearanceType);
        const startLine = state.clearanceType === ESegmentClearanceType.EquipmentClearance
            ? lineString([segment.start, segment.end])
            : loCustom(
                lineString([segment.start, segment.end]),
                (SegmentHelper.getClearance(segment, ESegmentClearanceType.EquipmentClearance) || -5) * (state.mode === 'boundary' ? 1 : -1),
                { units: 'feet' }
            )
        const endLine = loCustom(
            lineString([segment.start, segment.end]),
            (clearance || -5) * (state.mode === 'boundary' ? 1 : -1),
            { units: 'feet' }
        )
        const startM = midpoint(startLine.geometry.coordinates[0], startLine.geometry.coordinates[1]).geometry.coordinates;
        const endM = midpoint(endLine.geometry.coordinates[0], endLine.geometry.coordinates[1]).geometry.coordinates;
        positions.push([ startM, endM ])
    }
    return positions;
}

SegmentSelectMode.onSetup = function(this, opts: IOpts) {
    console.log("Segment select mode: opts", opts)
    const _feature = this.getFeature(opts.featureId);
    const mode = getModeFromFeature(_feature);
    if (mode === 'unknown') {
        throw new Error("Cannot enter segment select mode with this feature type");        
    }

    const definition = _feature.properties.definition as IDefinition;
    if (!definition) {
        throw new Error("Cannot enter segment select mode without a valid definition");        
    }
    const state = DirectSelectMode.onSetup.call(this, opts) as IState;
    state.mode = mode;
    state.definition = definition;
    state.editMode = opts.editMode || "verticies";
    state.systemValidityArgsUpdaterFn = getSystemValidityArgsUpdaterFromFeature(_feature, state.editMode);
    state.displaySettings = staticDevSettingsDbProvider.display.get();
    state.clearanceType = (state.editMode === "verticies" || state.editMode === 'deleteVerticies')
        ? ESegmentClearanceType.EquipmentClearance
        : state.editMode;
        
    // add the clearance poly to the features:
    {
        const cmp = getGeometryHelperFromMode(mode).getPolygonMinusClearancePolygon(definition);
        const cmpfDef: GeoJSON.Feature<GeoJSON.MultiPolygon> = {
            type: Constants.geojsonTypes.FEATURE,
            properties: {
                rdpFeatureType: "segment-select-mode-clearance-equipment"
            },
            geometry: {
                type: Constants.geojsonTypes.MULTI_POLYGON,
                coordinates: [],
            }
        }
        if (cmp) {
            cmpfDef.geometry.coordinates = cmp.type === 'MultiPolygon' ? cmp.coordinates : [cmp.coordinates];
        }
        const cmpf = this.newFeature(cmpfDef) as  MapboxDraw.DrawMultiFeature<'MultiPolygon'>;
        this.addFeature(cmpf);    
        state.equipementClearanceFeature = cmpf;
    }
    
    // add the s tower clearance poly to the features:
    {
        const cmp = getGeometryHelperFromMode(mode).getClearancePolygonMinusSTowerClearance(definition);
        const cmpfDef: GeoJSON.Feature<GeoJSON.MultiPolygon> = {
            type: Constants.geojsonTypes.FEATURE,
            properties: {
                rdpFeatureType: "segment-select-mode-clearance-sTower"
            },
            geometry: {
                type: Constants.geojsonTypes.MULTI_POLYGON,
                coordinates: [],
            }
        }
        if (cmp) {
            cmpfDef.geometry.coordinates = cmp.type === 'MultiPolygon' ? cmp.coordinates : [cmp.coordinates];
        }
        const cmpf = this.newFeature(cmpfDef) as  MapboxDraw.DrawMultiFeature<'MultiPolygon'>;
        this.addFeature(cmpf);    
        state.sTowerClearanceFeature = cmpf;
    }
    
    // add the h tower clearance poly to the features:
    {
        const cmp = getGeometryHelperFromMode(mode).getClearancePolygonMinusHTowerClearance(definition);
        const cmpfDef: GeoJSON.Feature<GeoJSON.MultiPolygon> = {
            type: Constants.geojsonTypes.FEATURE,
            properties: {
                rdpFeatureType: "segment-select-mode-clearance-hTower"
            },
            geometry: {
                type: Constants.geojsonTypes.MULTI_POLYGON,
                coordinates: [],
            }
        }
        if (cmp) {
            cmpfDef.geometry.coordinates = cmp.type === 'MultiPolygon' ? cmp.coordinates : [cmp.coordinates];
        }
        const cmpf = this.newFeature(cmpfDef) as  MapboxDraw.DrawMultiFeature<'MultiPolygon'>;
        this.addFeature(cmpf);    
        state.hTowerClearanceFeature = cmpf;
    }

    // add the guide lines to the features:
    const glcmpfDef: GeoJSON.Feature<GeoJSON.MultiLineString> = {
        type: Constants.geojsonTypes.FEATURE,
        properties: {
            rdpFeatureType: "measurement"
        },
        geometry: {
            type: Constants.geojsonTypes.MULTI_LINE_STRING,
            coordinates: getGuideLineCoordinates(state),
        }
    }
    const glcmpf = this.newFeature(glcmpfDef) as  MapboxDraw.DrawMultiFeature<'MultiLineString'>;
    this.addFeature(glcmpf);    
    state.guideLinesFeature = glcmpf;

    
    const mapFeatures = this._ctx.store._features as { [ fid: string ]: MapboxDraw.DrawFeature; };
    const systemsMap: { [ systemId: string ]: { layoutId: string, systemValidityArgs: IGetSystemValidityArgs }} = {};
    for (const fid in mapFeatures) {
        const f = mapFeatures[fid];
        const fProps = f.properties as (ILateralProperties | ICenterPivotProperties);
        const { systemId, layoutId } = fProps;
        const project = rdpFunctions(this).getProject();
        const system = project?.layouts[layoutId]?.systems[systemId];
        if (!system) {
            continue;
        }
        if (!(systemId in systemsMap)) {
            let gh: LateralGeometryHelper | CenterPivotGeometryHelper | null = null;
            if ("isLateral" in fProps) {
                gh = new LateralGeometryHelper({ project, systemId, layoutId });
            }
            else if ("isCenterPivot" in fProps) {
                gh = new CenterPivotGeometryHelper({ project, systemId, layoutId });
            }
            if (!gh) continue;
            const systemValidityArgs = gh.getSystemValidityArgs();
            
            systemsMap[systemId] = {
                layoutId,
                systemValidityArgs
            }
        }
    }

    state.systemsMap = systemsMap;

    this.map.fire("draw.update_ext", {
        action: "segment_select_enter",
        definition: {
            editMode: state.editMode
        }
    } as IDrawUpdateExtEvent_SegmentModeEntered);
    return state;
}

SegmentSelectMode.toDisplayFeatures = function(this, state: IState, geojson, push) {
    if (geojson.type !== 'Feature') return;
    if (
        (
            geojson.properties.user_rdpFeatureType === "obstacleClearance" ||
            geojson.properties.user_rdpFeatureType === "boundaryClearance"
        ) && 
        geojson.properties.user_parentId === state.featureId
    ) return;

    const mp = rdpFunctions(this).getMapPermissions();
    if (
        state.featureId === geojson.properties.id && (
            (state.mode === 'boundary' && mp.editBoundaries) ||
            (state.mode === 'obstacle' && mp.editObstacles)
        )
    ) {
      geojson.properties.active = Constants.activeStates.ACTIVE;
      push(geojson);

      const definition = state.definition;

      for (let i = 0; i < definition.segments.length; i++) {
        const segment = definition.segments[i];

        switch (state.editMode) {
            case "verticies": {       
                // start points:
                const v = createVertex(state.featureId, segment.start, `0.${i}.0`, false);
                push(v);
        
                // mid points:
                const m = createVertex(
                    state.featureId, 
                    midpoint(segment.start, segment.end).geometry.coordinates,
                    `0.${i}.1`,
                    false
                );
                m.properties.meta = Constants.meta.MIDPOINT
                push(m);
                break;
            }     
            case "deleteVerticies": {       
                // start points:
                const v = createVertex(state.featureId, segment.start, `0.${i}.0`, false);
                v.properties.delete = true;
                push(v);
                break;
            }        
            case ESegmentClearanceType.EquipmentClearance: 
            case ESegmentClearanceType.STowerClearance:
            case ESegmentClearanceType.HTowerClearance: {
                const clearance = SegmentHelper.getClearance(segment, state.clearanceType);
                // clearance points:
                const line = loCustom(
                    lineString([segment.start, segment.end]),
                    (clearance || -5) * (state.mode === 'boundary' ? 1 : -1),
                    { units: 'feet' }
                )
                const c = createVertex(
                    state.featureId, 
                    midpoint(line.geometry.coordinates[0], line.geometry.coordinates[1]).geometry.coordinates,
                    `0.${i}.2`,
                    false
                );
                if (state.editMode === ESegmentClearanceType.EquipmentClearance) {
                    c.properties.user_label = new DisplayLengthUnitBuilder(clearance || 0, 'feet')
                        .convert(state.displaySettings.lengths)
                        .appendValue(1)
                        .appendString(" ")
                        .appendShortName()
                        .toString();
                }
                else if (state.editMode === ESegmentClearanceType.STowerClearance || state.editMode === ESegmentClearanceType.HTowerClearance) {
                    const mainClearance = SegmentHelper.getClearance(segment, ESegmentClearanceType.EquipmentClearance);
                    c.properties.user_label = new DisplayLengthUnitBuilder(clearance - mainClearance, 'feet')
                        .convert(state.displaySettings.lengths)
                        .appendValue(1)
                        .appendString(" ")
                        .appendShortName()
                        .toString();
                    c.properties.user_label += "\n" + " (" + new DisplayLengthUnitBuilder(clearance, 'feet')
                        .convert(state.displaySettings.lengths)
                        .appendValue(1)
                        .appendString(" ")
                        .appendShortName()
                        .toString() + ")";
                }
                push(c);
                break;
            }         
            default:
                break;
        }

      }
    } else {
      geojson.properties.active = Constants.activeStates.INACTIVE;
      push(geojson);
    }
    this.fireActionable(state);
  };

const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT);
const isVertex = (e: MapboxDraw.MapMouseEvent) => isOfMetaType(Constants.meta.VERTEX)(e) && !e.featureTarget.properties.delete;
const isDeleteVertex = (e: MapboxDraw.MapMouseEvent) => isOfMetaType(Constants.meta.VERTEX)(e) && e.featureTarget.properties.delete;

SegmentSelectMode.onTouchStart = SegmentSelectMode.onMouseDown = function(this, state: IState, e) {
    if (isMidpoint(e)) {
        this.startDragging(state, e);
        const about = e.featureTarget.properties;

        const selectedSegmentIndexString = about.coord_path.split(".")[1];
        if (!selectedSegmentIndexString) return;
        const selectedSegmentIndex = parseInt(selectedSegmentIndexString);
        handleAddMidPoint(state, selectedSegmentIndex, [e.lngLat.lng, e.lngLat.lat]);
        const p = getGeometryHelperFromMode(state.mode).getPolygon(state.definition);
        state.feature.incomingCoords(p.coordinates);
        this.fireUpdate();
        state.selectedCoordPaths = [about.coord_path];        
    }
    else if (isVertex(e)) {
        return this.onVertex(state, e);
    }
    else if (isDeleteVertex(e)) {
        const about = e.featureTarget.properties;

        const selectedSegmentIndexString = about.coord_path.split(".")[1];
        if (!selectedSegmentIndexString) return;
        const selectedSegmentIndex = parseInt(selectedSegmentIndexString);
        state.selectedCoordPaths = [];        
        handleDeletePoint(state, selectedSegmentIndex, [e.lngLat.lng, e.lngLat.lat]);
        handleUpdatePolygons(this, state);
        state.fire_segment_updated = true;
        return this.onVertex(state, e);
    }
};

const handleUpdatePolygons = (that: MapboxDraw.DrawCustomModeThis & MapboxDraw.DrawCustomMode<any, any>, state: IState) => {
    const p = getGeometryHelperFromMode(state.mode).getPolygon(state.definition);
    state.feature.incomingCoords(p.coordinates);

    const bp = getGeometryHelperFromMode(state.mode).getPolygonMinusClearancePolygon(state.definition);
    if (bp) {
        state.equipementClearanceFeature.incomingCoords(bp.type === 'MultiPolygon' ? bp.coordinates : [ bp.coordinates ]);
    }
    else {
        state.equipementClearanceFeature.incomingCoords([]);
    }
    
    const stbp = getGeometryHelperFromMode(state.mode).getClearancePolygonMinusSTowerClearance(state.definition);
    if (stbp) {
        state.sTowerClearanceFeature.incomingCoords(stbp.type === 'MultiPolygon' ? stbp.coordinates : [ stbp.coordinates ]);
    }
    else {
        state.sTowerClearanceFeature.incomingCoords([]);
    }
    
    const htbp = getGeometryHelperFromMode(state.mode).getClearancePolygonMinusHTowerClearance(state.definition);
    if (htbp) {
        state.hTowerClearanceFeature.incomingCoords(htbp.type === 'MultiPolygon' ? htbp.coordinates : [ htbp.coordinates ]);
    }
    else {
        state.hTowerClearanceFeature.incomingCoords([]);
    }

    state.guideLinesFeature.incomingCoords(getGuideLineCoordinates(state));

    // update the system validities:
    if (state.systemValidityArgsUpdaterFn) {
        const crntClearanceBoundaries = getGeometryHelperFromMode(state.mode).getClearancePolygons(state.definition, ESegmentClearanceType.EquipmentClearance);
        const crntSTowerClearanceBoundaries = getGeometryHelperFromMode(state.mode).getClearancePolygons(state.definition, ESegmentClearanceType.STowerClearance);
        const crntHTowerClearanceBoundaries = getGeometryHelperFromMode(state.mode).getClearancePolygons(state.definition, ESegmentClearanceType.HTowerClearance);
    
        const validityMap: { [ systemId: string ]: { validity: SystemValidity } } = {};
        for (const sid in state.systemsMap) {
            const sm = state.systemsMap[sid];
            const validity = getSystemValidity(
                sm.layoutId,
                sid,
                state.systemValidityArgsUpdaterFn(sm.systemValidityArgs, crntClearanceBoundaries, crntSTowerClearanceBoundaries, crntHTowerClearanceBoundaries)
            )
            validityMap[sid] = { validity };
        }
        const mapFeatures = that._ctx.store._features as { [ fid: string ]: MapboxDraw.DrawFeature; };
        for (const fid in mapFeatures) {
            const f = mapFeatures[fid];
            const fProps = f.properties as (ILateralProperties | ICenterPivotProperties);
            const { systemId } = fProps;
            if (systemId) {
                const f = that.getFeature(fid);
                let validity: SystemValidity = 'unknown';
                if (systemId in validityMap) {
                    validity = validityMap[systemId].validity;
                }
                if (f.properties.validity !== validity) {
                    f.setProperty("validity", validity);
                    f.changed();
                }
            }
        }
    }
}

SegmentSelectMode.onDrag = function(this, state: IState, e) {
    if (isDeleteVertex(e)) {
        return;
    }
    const { selectedCoordPaths } = state;
    const { lngLat } = e;
    if (selectedCoordPaths.length !== 1) return;

    const selectedSegmentIndexString = selectedCoordPaths[0].split(".")[1];
    if (!selectedSegmentIndexString) return;
    const selectedSegmentIndex = parseInt(selectedSegmentIndexString);
    
    switch (selectedCoordPaths[0].split(".")[2]) {
        case "0":
            handleStartPoint(state, selectedSegmentIndex, [ lngLat.lng, lngLat.lat ]);
            break;
        case "1":
            handleStartPoint(state, selectedSegmentIndex + 1, [ lngLat.lng, lngLat.lat ]);
            break;
        case "2":
            handleClearancePoint(state, selectedSegmentIndex, [ lngLat.lng, lngLat.lat ]);
            break;
        default:
            return;
    }
    
    handleUpdatePolygons(this, state);

    state.fire_segment_updated = true;
}

SegmentSelectMode.onStop = function(this, state: IState) {
    if (state.equipementClearanceFeature) {        
        this.deleteFeature(state.equipementClearanceFeature.id.toString());
    }
    if (state.sTowerClearanceFeature) {        
        this.deleteFeature(state.sTowerClearanceFeature.id.toString());
    }
    if (state.hTowerClearanceFeature) {        
        this.deleteFeature(state.hTowerClearanceFeature.id.toString());
    }
    if (state.guideLinesFeature) {        
        this.deleteFeature(state.guideLinesFeature.id.toString());
    }
    doubleClickZoom.enable(this);
    this.clearSelectedCoordinates();
    if (state.fire_segment_updated) {
        this.map.fire("draw.update_ext", {
            action: "segment_updated",
            definition: {
                feature: state.feature.toGeoJSON()
            }
        } as IDrawUpdateExtEvent_SegmentUpdated);
        state.fire_segment_updated = false;
    }
};

const handleStartPoint = (state: IState, selectedSegmentIndex: number, position: Position) => {
    state.definition.segments[selectedSegmentIndex].start = position;
    state.definition.segments[
        selectedSegmentIndex === 0
            ? state.definition.segments.length - 1
            : selectedSegmentIndex - 1
    ].end = position;
}

const handleDeletePoint = (state: IState, selectedSegmentIndex: number, position: Position) => {
    if (state.definition.segments.length < 4) {
        //cannot delete a vertex
        return;
    }
    const cpy = structuredClone(state.definition.segments);
    const leftIndex = selectedSegmentIndex === 0 ? cpy.length - 1 : selectedSegmentIndex - 1;
    const rightIndex = selectedSegmentIndex === cpy.length - 1 ? 0 : selectedSegmentIndex + 1;
    const leftSegment = cpy[leftIndex];
    const rightSegment = cpy[rightIndex];
    console.log(cpy.length, leftIndex, selectedSegmentIndex, rightIndex)
    leftSegment.end = rightSegment.start;
    cpy.splice(selectedSegmentIndex, 1);
    state.definition.segments = cpy
}

const handleAddMidPoint = (state: IState, selectedSegmentIndex: number, position: Position) => {
    const oldSegment = { ...state.definition.segments[selectedSegmentIndex] };
    const newLeftSegment: ISegment = {
        start: oldSegment.start,
        end: position,
        equipmentClearance: oldSegment.equipmentClearance,
        sTowerClearanceOffset: oldSegment.sTowerClearanceOffset,
        hTowerClearanceOffset: oldSegment.hTowerClearanceOffset
    }
    const newRightSegment: ISegment = {
        start: position,
        end: oldSegment.end,
        equipmentClearance: oldSegment.equipmentClearance,
        sTowerClearanceOffset: oldSegment.sTowerClearanceOffset,
        hTowerClearanceOffset: oldSegment.hTowerClearanceOffset
    }
    state.definition.segments = [
        ...state.definition.segments.slice(0, selectedSegmentIndex),
        newLeftSegment,
        newRightSegment,
        ...state.definition.segments.slice(selectedSegmentIndex + 1),
    ]
}
const handleClearancePoint = (state: IState, selectedSegmentIndex: number, position: Position) => {
    const segment = state.definition.segments[selectedSegmentIndex];
    const poly = getGeometryHelperFromMode(state.mode).getPolygon(state.definition);
    // i.e. if its an obstacle, set to zero if point is in obstacle,
    // else its a boundary, so set to zero if we are outside the boundary
    if (booleanContains(poly, point(position)) === (state.mode === 'obstacle')) {
        SegmentHelper.setClearance(0, segment, state.clearanceType);
    }
    else {
        const segmentLine = lineString([segment.start, segment.end]);
        const distance = Math.round(ptldCustom(position, segmentLine, { units: 'feet' }) * 10) / 10;
        SegmentHelper.setClearance(distance, segment, state.clearanceType);
    }
}