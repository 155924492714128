import { Point, area, distance, ellipse, sector } from "@turf/turf";
import { IOptimizedSystem } from "../centerPivot";
import { OPTIMIZATION_CONSTANTS } from "../centerPivot/constants";
import { sumOptimizedSpans } from "../spans/optimizeSpansCommon";


export const areaOfSystem = (system: IOptimizedSystem) => {
    const mainSector: { radius: number, b1: number, b2: number } = {
        radius: 0, b1: system.clockwiseCompassHeadingStart, b2: system.clockwiseCompassHeadingEnd
    }
    const wrapSectors: { radius: number, b1: number, b2: number }[] = [];
    for (const s of system.spanTowers) {
        const l = s.spanLength + (s.extension ? 4 : 0);
        mainSector.radius += l;
        if (s.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees) {
            wrapSectors.push({ radius: 0, b1: 0, b2: s.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees })
        }
        if (s.clockwiseWrapAngleRelativeToPreviousSpanDegrees) {
            wrapSectors.push({ radius: 0, b1: 0, b2: s.clockwiseWrapAngleRelativeToPreviousSpanDegrees })
        }
        for (const ws of wrapSectors) {
            ws.radius += l;
        }
    }
    if (system.endBoom) {
        mainSector.radius += system.endBoom;
        for (const ws of wrapSectors) {
            ws.radius += system.endBoom;
        }
    }

    let a = 0; // m2
    if (mainSector.radius > 0) {
        if (mainSector.b1 !== undefined && mainSector.b2 !== undefined) {
            a += area(
                sector(
                    system.center,
                    mainSector.radius,
                    mainSector.b1 || 0,
                    mainSector.b2 || 0,
                    { units: 'feet', steps: OPTIMIZATION_CONSTANTS.SECTOR_STEPS }
                )
            )
        }
        else {
            a += area(
                ellipse(
                    system.center,
                    mainSector.radius,
                    mainSector.radius,
                    { units: 'feet', steps: OPTIMIZATION_CONSTANTS.SECTOR_STEPS }
                )
            )
        }
    }
    for (const ws of wrapSectors) {
        if (ws.radius > 0) {
            a += area(
                sector(
                    system.center,
                    ws.radius,
                    ws.b1 || 0,
                    ws.b2 || 0,
                    { units: 'feet', steps: OPTIMIZATION_CONSTANTS.SECTOR_STEPS }
                )
            )
        }
    }
    return Math.round(a * 10) / 10;  
}
export const costOfSystem = (system: IOptimizedSystem | undefined) => {
    if (!system) return 0;
    let centerCost = 2; // pivot
    const spanFootCost = sumOptimizedSpans({ 
        spans: system.spanTowers.map(x => ({ spanLength: x.spanLength, spanExtension: x.extension, spanAndExtensionLength: x.spanLength + (x.extension ? 4 : 0)})),
        endBoom: system.endBoom
    }) * 0.1;
    const spanTowerCost = system.spanTowers.length * 1;
    return centerCost + spanFootCost + spanTowerCost;
}

export const compareCostPerAcre = (a_cost: number, a_area: number, b_cost: number, b_area: number, cpaRatio: number): ('A' | 'B') => {
    if (b_area === 0) return "A";
    if (a_area === 0) return "B";
    // console.log("compareCostPerAcre cpa", cpaRatio);
    const a_costPerAcre = a_cost / a_area;
    const b_costPerAcre = b_cost / b_area;
    // console.log("compareCostPerAcre", a_area > b_area, a_costPerAcre, b_costPerAcre)
    if (a_area > b_area) {
        return a_costPerAcre < cpaRatio * b_costPerAcre ? 'A' : 'B';
    }
    else {
        return b_costPerAcre < cpaRatio * a_costPerAcre ? 'B' : 'A';
    }
}

export const costPerAcre = (system: IOptimizedSystem) => {
    const cost = costOfSystem(system)
    const area = areaOfSystem(system)
    const costPerAcre = cost / area;
    return costPerAcre;
}

export const isCostPerAcreImprovementValid = (optimizedSystems: IOptimizedSystem[], incommingSystem: IOptimizedSystem, cpaRatio: number) => {
    // console.log("isCostPerAcreImprovementValid cpa", cpaRatio);
    if (!optimizedSystems.length) return true;
    if (!incommingSystem) return false;
    const a = costPerAcreOverSystems(optimizedSystems);
    const b = costPerAcre(incommingSystem);
    return b / a < cpaRatio;
}

export const costPerAcreOverSystems = (optimizedSystems: IOptimizedSystem[]) => {
    if (!optimizedSystems.length) return 0;

    const a = optimizedSystems.reduce((min, s) => Math.min(min, costPerAcre(s)), Number.MAX_VALUE);
    // const a = optimizedSystems.map(s => costPerAcre(s)).reduce((s, x) => s + x, 0) / optimizedSystems.length;
    return a;
}


export const compareSystems = (
    systemA: IOptimizedSystem | undefined, 
    systemB: IOptimizedSystem | undefined,
    cpaRatio: number,
    boundaryAngles: { p: Point, angle: number }[]
) => {
    if (!systemB) return systemA;
    if (!systemA) return systemB;
    const a_cost = costOfSystem(systemA)
    const a_area = areaOfSystem(systemA)
    const b_cost = costOfSystem(systemB)
    const b_area = areaOfSystem(systemB)
    if (b_cost && b_area && Math.abs(a_cost / b_cost - 1) < 0.01 && Math.abs(a_area / b_area - 1) < 0.01) {
        // when the same size, prioritise closest to boundary by angle:
        let a_angle: { d: number, angle: number } = { d: Number.MAX_VALUE, angle: 360 };
        let b_angle: { d: number, angle: number } = { d: Number.MAX_VALUE, angle: 360 };
        for (let i = 0; i < boundaryAngles.length; i++) {
            const ba = boundaryAngles[i];
            const a_d = distance(systemA.center, ba.p);
            const b_d = distance(systemB.center, ba.p);
            if (a_d < a_angle.d) a_angle = { d: a_d, angle: ba.angle }
            if (b_d < b_angle.d) b_angle = { d: b_d, angle: ba.angle }
        }
        if (b_angle.angle < a_angle.angle) {
            return systemB;
        }
        return systemA;
    }
    if (compareCostPerAcre(a_cost, a_area, b_cost, b_area, cpaRatio) === 'A') {
        return systemA;
    }
    else {
        return systemB;
    }
}