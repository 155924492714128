import DeleteIcon from '@mui/icons-material/Delete';
import RenameIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Chip, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import DbCtx from "../../../../../db/DbCtx";
import DeleteSystemConfirmationDialog from "../../../SystemDesign/DeleteSystemConfirmationDialog";
import RenameSystemDialog from "./RenameSystemDialog";

interface Props {
    onDelete: () => void;
    systemId: string;
    layoutId: string;
    projectId: string;
}

const MapSpeedDialSystemSelected: FC<Props> = (props) => {
    //disable hover open/close for speeddial
    const [open, setOpen] = React.useState(true);

    const dbCtx = React.useContext(DbCtx);
    const system = dbCtx.projects[props.projectId].state.layouts[props.layoutId].systems[props.systemId];
    
    const [ showConfirmation, setShowConfirmation ] = React.useState(false);
    const [ renameSystemDialogOpen, setRenameSystemDialogOpen ] = React.useState(false);

    const handleOnClickDelete = () => {
        setShowConfirmation(true);
    }
    const handleOnClickRename = () => {
        setRenameSystemDialogOpen(true);
    }

    return <>
        <SpeedDial
            ariaLabel="Edit feature"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
            open={open}
            onClick={() => {setOpen(!open)}}
            onMouseEnter={() => {}}
        >
            <SpeedDialAction
                key="rename"
                icon={<RenameIcon />}
                tooltipTitle={<span>{i18next.format(t('rename'), 'capitalize')}</span>}
                tooltipOpen={true}
                onClick={handleOnClickRename}
            />      
            <SpeedDialAction
                key="delete"
                icon={<DeleteIcon />}
                tooltipTitle={<span>{i18next.format(t('delete'), 'capitalize')}</span>}
                tooltipOpen={true}
                onClick={handleOnClickDelete}
            /> 
        </SpeedDial>
        <Chip 
            sx={{ position: 'absolute', bottom: 28, right: 80 }}
            label={system.name} 
            color="primary" 
        />   
        {
            showConfirmation && (
                <DeleteSystemConfirmationDialog
                    fromSystemDesign={false}
                    projectId={props.projectId}
                    layoutId={props.layoutId}
                    systemId={props.systemId}
                    onCancel={() => {
                        setShowConfirmation(false)
                    }}
                    onDelete={() => {
                        setShowConfirmation(false)
                        props.onDelete();
                    }}
                />
            )
        }
        {
            renameSystemDialogOpen  && (
                <RenameSystemDialog
                    projectId={props.projectId}
                    layoutId={props.layoutId}
                    systemId={props.systemId}
                    onClose={() => setRenameSystemDialogOpen(false)}
                />
            )
        }
    </>;
}

export default MapSpeedDialSystemSelected;