import * as React from "react";
import { FC } from "react";
import { SYSTEM_DESIGN_CONSTANTS } from "./constants";

interface IProps {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}

const SpanTensioner: FC<IProps> = ({ x1, y1, x2, y2 }) => {
    const tensionerWidth = SYSTEM_DESIGN_CONSTANTS.tensioner.width;

    return (
        <path 
            d={`
                M ${x1} ${y1} 
                L ${x2} ${y2}
            `} 
            stroke={SYSTEM_DESIGN_CONSTANTS.pipe.color} 
            strokeWidth={tensionerWidth} />
    );
};

export default SpanTensioner;