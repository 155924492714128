import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import i18next, { t } from "i18next";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import CenterPivotGeometryHelper from "../../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper from "../../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { getSystemValidity } from "../../../GeometryHelpers/SystemGeometryHelpers/getSystemValidity";
import { IGetSystemValidityArgs } from "../../../GeometryHelpers/SystemGeometryHelpers/interfaces";
import DbCtx from "../../../db/DbCtx";
import { ProjectType } from "../../../model/project/IProject";

interface Props {
    projectId: string;
    layoutId: string;
    systemId: string;
}
const ForceRedirectDialog: React.FC<Props> = ({
    projectId, layoutId, systemId
}) => {
    
  const dbState = React.useContext(DbCtx);
  const navigate = useNavigate();

  const [redirectTo] = React.useState<string | undefined>(() => {
    const project = dbState.projects[projectId]?.state;
    if (!project) return;
    
    const system = project.layouts[layoutId!]?.systems[systemId!];
    if (!system || project.projectType !== ProjectType.LayoutAndDesign) {
      return;
    }
    
    let svArgs: IGetSystemValidityArgs | undefined = undefined;
    switch (system.SystemProperties.SystemType) {
        case SystemTypes.CenterPivot: {        
            const centerPivotGeometryHelper = new CenterPivotGeometryHelper({
                layoutId,
                systemId, 
                project 
            });
            svArgs = centerPivotGeometryHelper.getSystemValidityArgs();
            break;
        }
        case SystemTypes.CanalFeedMaxigator:     
        case SystemTypes.HoseFeedMaxigator: {
            const lateralGeometryHelper = new LateralGeometryHelper({
                layoutId,
                systemId,
                project 
            });
            svArgs = lateralGeometryHelper.getSystemValidityArgs();
            break;
        }
    }
    const redirectUrl = `/projects/${projectId}/layouts/${layoutId}/map`;
    if(!svArgs) {
        // this should not happen??
        return redirectUrl;
    } 
    const validity = getSystemValidity(layoutId, systemId, svArgs);
    return !(validity === 'valid' || validity === 'warning') ? redirectUrl : undefined;
  });

    return (
        <Dialog open={!!redirectTo} aria-labelledby="force-redirect-dialog-title" aria-describedby="force-redirect-dialog-description">
            <DialogTitle id="force-redirect-dialog-title">
                {i18next.format(t('system-design-redirect-dialog.title'), 'capitalize-each')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="force-redirect-dialog-description">
                    {t('system-design-redirect-dialog.body')}                    
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => navigate(redirectTo)}
                    autoFocus
                >
                    {i18next.format(t('ok'), 'capitalize-each')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ForceRedirectDialog;
