import styled from "@emotion/styled";
import { Icon } from "@mui/material";
import * as React from "react";
import { FC } from "react";

const StyledImg = styled.img`
    display: flex;
    height: inherit;
`;

const whiteIcon = new URL('../../assets/reinkeLogo/whiteIcon.svg', import.meta.url);
const whiteFull = new URL('../../assets/reinkeLogo/whiteFull.svg', import.meta.url);

const ReinkeIcon: FC<{full: boolean}> = (props) => {
    return (
        <Icon sx={{ width: "auto", textAlign: "center", mr: props.full ? 1 : 0 }}>
            <StyledImg src={props.full ? whiteFull.toString() : whiteIcon.toString()} />
        </Icon>
    );
};

export default ReinkeIcon;