import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import IProject from "../../../model/project/IProject";
import CenterPivotDiagram from "../SystemDiagramV2/CenterPivotSystemDiagram";
import LateralDiagram from "../SystemDiagramV2/LateralSystemDiagram";
import { IElevationProfile } from "../interfaces";

interface IProps {
    project: IProject;
    layoutId: string;
    systemId: string;
    minElevationFeet: number;
    maxElevationFeet: number;
    elevationProfile: IElevationProfile;
    zoom: boolean;
    justSystemDiagram?: {
        partWidthFeet: number,
        widthInPixels: number    
    };
}

const SystemDiagram: FC<IProps> = ({
    project, layoutId, systemId, ...rest 
}) => {
    const system = project.layouts[layoutId]?.systems[systemId];
    if (!system) return null;
    switch (system.SystemProperties.SystemType) {
        case SystemTypes.CenterPivot:
            return (
                <CenterPivotDiagram
                    system={system}
                    {...rest}
                />
            )
        case SystemTypes.HoseFeedMaxigator:
        case SystemTypes.CanalFeedMaxigator:
            return (
                <LateralDiagram
                    project={project}
                    layoutId={layoutId}
                    systemId={systemId}
                    {...rest}
                />
            )
        default:
            return null;
    }
};

export default SystemDiagram;