import { ICircleCenterPivot, TowerHeights } from "rdptypes/project/ISystemBase.AutoGenerated";
import { ITower } from "rdptypes/project/Types";

export const towerDefinitionFromTowerOrPivot = (
    towerOrPivot: ITower | ICircleCenterPivot
) => {
    const height = (towerOrPivot as ITower)?.TowerType ?? (towerOrPivot as ICircleCenterPivot)?.PivotCenterHeight;
    
    switch (height) {
        case TowerHeights.LowProfile:
            return {
                heightFeet: 5,
            };
        case TowerHeights.Sugargator:
            return {
                heightFeet: 14,
            };
        case TowerHeights.Supergator:
            return {
                heightFeet: 20,
            };
        case TowerHeights.Standard:
        default:
            return {
                heightFeet: 9,
            };
    }
};