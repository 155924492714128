import { ISacOptimizerSettingsFormState } from "../../../../../components/DealerSettingsDialog/SacOptimizerSettings";
import { Vector2 } from "../Numerics/Vector2";
import { SACModelConfiguration } from "./SACModelConfiguration";
import { SACModelConstraints } from "./SACModelConstraints";
import { SACOrientation } from "./SACOrientation";

import { jsts } from "../jstsLib";

export class SACModel {
    public ModelConstraints: SACModelConstraints;
    public Configuration: SACModelConfiguration;

    public constructor(configuration: SACModelConfiguration, settings: ISacOptimizerSettingsFormState) {
        this.Configuration = configuration;
        this.ModelConstraints = new SACModelConstraints(settings);
    }

    public dr_dtheta_Length(alpha: number, dalpha_dtheta: number): number {
        const plen = this.Configuration.PivotSpanLengthMetres;
        const qlen = this.Configuration.SwingSpanLengthMetres;

        const z = qlen * (1 + dalpha_dtheta);

        return Math.sqrt(plen * plen + z * z - 2 * plen * z * Math.cos(alpha));
    }

    public g(alpha: number): number {
        const plen = this.Configuration.PivotSpanLengthMetres;
        const qlen = this.Configuration.SwingSpanLengthMetres;

        return -plen * qlen * Math.sin(alpha);
    }

    public h(dr_dtheta_Length: number): number {
        const qlen = this.Configuration.SwingSpanLengthMetres;
        return dr_dtheta_Length * qlen;
    }

    public beta(alpha: number, dalpha_dtheta: number): number {
        var dr_dtheta_Length = this.dr_dtheta_Length(alpha, dalpha_dtheta);
        var g = this.g(alpha);
        var h = this.h(dr_dtheta_Length);
        var m = g / h;

        const dotw = 0.5 * this.Configuration.WheelWidthMetre * (
            this.Configuration.SwingSpanLengthMetres * (1 + dalpha_dtheta) - this.Configuration.PivotSpanLengthMetres * Math.cos(alpha));

        if (g < 0) {
            if (dotw < 0) {
                return this.Configuration.Orientation === SACOrientation.Leading ? Math.acos(-m) : -Math.acos(-m);
            }
            else {
                return this.Configuration.Orientation === SACOrientation.Leading ? -Math.acos(-m) : Math.acos(-m);
            }
        }
        else {
            if (dotw < 0) {
                return this.Configuration.Orientation === SACOrientation.Leading ? -Math.acos(m) : Math.acos(m);
            }
            else {
                return this.Configuration.Orientation === SACOrientation.Leading ? Math.acos(m) : -Math.acos(m);
            }
        }
    }

    public CheckSpeedConstraint(alpha: number, dalpha_dtheta: number): boolean {
        const dr_dtheta_Length = this.dr_dtheta_Length(alpha, dalpha_dtheta);
        const lenp = this.Configuration.PivotSpanLengthMetres;
        return dr_dtheta_Length <= this.ModelConstraints.MaxStwrHtwrSpeedRatio * lenp;
    }

    public CheckSteeringAngleConstraint(alpha: number, dalpha_dtheta: number): boolean {
        const steeringAngleDegrees = this.beta(alpha, dalpha_dtheta) * 180.0 / Math.PI;
        return steeringAngleDegrees >= this.ModelConstraints.MinSteeringAngleDegrees &&
                    steeringAngleDegrees <= this.ModelConstraints.MaxSteeringAngleDegrees;
    }

    public p(theta: number): Vector2 {
        return new Vector2(
                    this.Configuration.PivotSpanLengthMetres * Math.sin(theta),
                    this.Configuration.PivotSpanLengthMetres * Math.cos(theta)
                    );
    }

    public q(theta: number, alpha: number): Vector2 {
        return new Vector2(
            -this.Configuration.SwingSpanLengthMetres * Math.sin(theta + alpha),
            -this.Configuration.SwingSpanLengthMetres * Math.cos(theta + alpha)
            );
    }

    public r(theta: number, alpha: number): Vector2 {
        return this.p(theta).add(this.q(theta, alpha));
    }

    public w(theta: number, alpha: number): Vector2 {
        return new Vector2(
            -0.5 * this.Configuration.WheelWidthMetre * Math.cos(theta + alpha),
            0.5 * this.Configuration.WheelWidthMetre * Math.sin(theta + alpha)
            );
    }

    public b(theta: number, alpha: number): Vector2 {
        return new Vector2(
                -this.Configuration.EndBoomLengthMetres * Math.sin(theta + alpha),
                -this.Configuration.EndBoomLengthMetres * Math.cos(theta + alpha)
                );
    }

    // /// <summary>
    // /// Assumes the end gun throw is in line with the vector r, not q. This is what RDP
    // /// has always done.
    // /// </summary>
    // /// <param name="theta"></param>
    // /// <param name="alpha"></param>
    // /// <param name="endGunThrow"></param>
    // /// <returns></returns>
    public EndOfEndGun(theta: number, alpha: number, endGunThrow: number): Vector2 {
        const b = this.b(theta, alpha);
        const bg = Vector2.Multiply(b, (b.Length() + endGunThrow) / b.Length());
        return this.r(theta, alpha).add(bg);
    }

    public EndOfEndBoom(theta: number, alpha: number): Vector2 {
        return this.r(theta, alpha).add(this.b(theta, alpha));
    }

    private collision: jsts.geom.Geometry | null = null;
    private collisionWithSwingSpan: jsts.geom.Geometry | null = null;

    // /// <summary>
    // /// 
    // /// </summary>
    // /// <param name="theta"></param>
    // /// <param name="alpha"></param>
    // /// <param name="bufferRadians">Normally set to half the pivot angle spacing, adds padding to the SAC collision polygon
    // /// to ensure that thin obstacles such as poles are not missed.</param>
    // /// <param name="includeSwingSpan"></param>
    // /// <returns></returns>
    public GetCollisionPolygon(theta: number, alpha: number, bufferRadians: number, includeSwingSpan: boolean): jsts.geom.Geometry
    {
        const FeetToMetres = 0.3048;

        if (this.collision === null) {
            var profileStepLength = this.Configuration.SwingSpanLengthMetres / (this.Configuration.ProfileMetres.length + 1);
            const points: (jsts.geom.Coordinate)[] = [];

            var htwrToEeb = this.Configuration.SwingSpanLengthMetres + this.Configuration.EndBoomLengthMetres;

            var cpToEebMax = this.Configuration.PivotSpanLengthMetres + htwrToEeb;
            var cpToStwrMax = this.Configuration.PivotSpanLengthMetres + this.Configuration.SwingSpanLengthMetres;

            // EEB
            points.push(new jsts.geom.Coordinate(cpToEebMax * Math.sin(0.5 * bufferRadians), -htwrToEeb));
            points.unshift(new jsts.geom.Coordinate(-cpToEebMax * Math.sin(0.5 * bufferRadians), -htwrToEeb));

            // STWR edge
            points.push(new jsts.geom.Coordinate(0.5 * this.Configuration.StwrWidthMetres + cpToStwrMax * Math.sin(0.5 * bufferRadians), -this.Configuration.SwingSpanLengthMetres));
            points.unshift(new jsts.geom.Coordinate(-(0.5 * this.Configuration.StwrWidthMetres + cpToStwrMax * Math.sin(0.5 * bufferRadians)), -this.Configuration.SwingSpanLengthMetres));

            // STWR center
            points.push(new jsts.geom.Coordinate(cpToStwrMax * Math.sin(0.5 * bufferRadians), -this.Configuration.SwingSpanLengthMetres));
            points.unshift(new jsts.geom.Coordinate(-cpToStwrMax * Math.sin(0.5 * bufferRadians), -this.Configuration.SwingSpanLengthMetres));

            // use slice of points else collision poly will be created with the swingSpan points added bellow
            this.collision = new jsts.geom.GeometryFactory().createLineString(points.slice());

            // HTWR
            // collisionWithSwingSpan includes a thin swing span which is needed to avoid span obstacle collisions but is not normally required
            // for boundary obstacles (although certain very irregular boundaries are not supported by this assumption).
            // Simon 2024.07.03:
            // As per call today, SAC H-Tower is updated to assume a zero width:
            points.push(new jsts.geom.Coordinate(0, 0));
            points.unshift(new jsts.geom.Coordinate(0, 0));
            this.collisionWithSwingSpan = new jsts.geom.GeometryFactory().createLineString(points);

            /*
            // HTWR
            // We used to include the "belly" width of the swing span, but this caused undesirable behaviour where the SAC
            // would retract more than expected when close to the field boundary. The old belly width code is left here for reference in case
            // it is needed in the future.
            points.AddLast(new GeoAPI.Geometries.Coordinate(0, -30 * FeetToMetres));
            
            for (var i = 1; i < Configuration.ProfileMetres.Length - 1; i++)
            {
                if (Configuration.ProfileMetres[i - 1] <= Configuration.ProfileMetres[i] &&
                    Configuration.ProfileMetres[i + 1] < Configuration.ProfileMetres[i])
                {
                    // Belly
                    points.AddLast(new GeoAPI.Geometries.Coordinate(-0.5 * Configuration.ProfileMetres[i], -(i + 1) * profileStepLength));
                    points.AddFirst(new GeoAPI.Geometries.Coordinate(0.5 * Configuration.ProfileMetres[i], -(i + 1) * profileStepLength));
                }
            }*/
        }


        const alphatrans = new jsts.geom.util.AffineTransformation()
            .rotate(-alpha)
            .translate(0, this.Configuration.PivotSpanLengthMetres)
            .rotate(-theta);

        if (includeSwingSpan) {
            if (this.collisionWithSwingSpan === null) throw new Error("collisionWithSwingSpan is null");   
            return alphatrans.transform(this.collisionWithSwingSpan);         
        }
        else {
            if (this.collision === null) throw new Error("collision is null");
            return alphatrans.transform(this.collision);    
        }
    }
}