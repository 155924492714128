import {
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import { Stack } from "@mui/system";
import i18next, { t } from "i18next";
import { EndGunTypes, EndOfSystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { DisplayLengthUnitBuilder } from "../../helpers/lengths";
import PositiveConvertedDecimalField from "../CustomInputComponents/PositiveConvertedDecimalField";
import { disabledColor, pageStyles, secondaryColor } from "../roe/styles";
import { DropSpanChoiceControl } from "./DropSpanChoiceControl";
import EndGunType, { EndGunTypeThrowSlider } from "./EndGunType";
import { EndOfSystemOptimizationType } from "./EndOfSystemOptimizationType";
import { PartialSpanLengthItem, RestrictSpanLengthsControl } from "./RestrictSpanLengthsControl";

export interface IOptimizeLateralFormState {
    // non-optimization parameters:
    canalFeed: boolean;
    canalWidthFeet: number;
    distanceFromCanalCenterToFwdSide: number;
    distanceFromCanalCenterToAftSide: number;
    endFeed: boolean;
    pivotingLateral?: boolean;

    // optimization parameters
    optimizeSystem: boolean;
    restrictSpanLengths: boolean;
    restrictedSpanLengths: PartialSpanLengthItem[];
    allowDropSpans: boolean;
    maxNumberOfDropSpans: number;

    endOfSystemTypeFwd?: EndOfSystemTypes.EndBoom;
    restrictedEndBoomLengthsFwd: number[];
    endOfSystemTypeAft?: EndOfSystemTypes.EndBoom;
    restrictedEndBoomLengthsAft: number[];

    primaryEndGun: EndGunTypes;
    secondaryEndGun: EndGunTypes;
    primaryEndGunThrow?: number;
    secondaryEndGunThrow?: number;
}

export const OPTIMIZATION_LATERAL_FORM_STATE_DEFAULT: IOptimizeLateralFormState = {
    canalFeed: false,
    canalWidthFeet: 8,
    distanceFromCanalCenterToFwdSide: 17,
    distanceFromCanalCenterToAftSide: 17,
    endFeed: false,

    optimizeSystem: false,
    restrictSpanLengths: false,
    restrictedSpanLengths: [],
    allowDropSpans: false,
    maxNumberOfDropSpans: 1,

    endOfSystemTypeAft: undefined,
    restrictedEndBoomLengthsAft: [],
    endOfSystemTypeFwd: undefined,
    restrictedEndBoomLengthsFwd: [],

    primaryEndGun: EndGunTypes.None,
    secondaryEndGun: EndGunTypes.None,
};

interface Props {
    formState: IOptimizeLateralFormState;
    setFormState: (updatedFormState: IOptimizeLateralFormState) => void;
}

const COLS_FULL = 12;
const COLS_LEFT_FULL = 4;
const COLS_LEFT_PARTIAL_1 = 1;
const COLS_LEFT_PARTIAL_2 = COLS_LEFT_FULL - COLS_LEFT_PARTIAL_1;
const COLS_RIGHT = COLS_FULL - COLS_LEFT_FULL;
const prefix = "roe.end-of-system.";

const OptimizeLateralForm: React.FC<Props> = ({ formState, setFormState }) => {
    const settings = React.useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;

    const {
        canalFeed,
        canalWidthFeet,
        distanceFromCanalCenterToFwdSide,
        distanceFromCanalCenterToAftSide,

        endFeed,

        optimizeSystem,
        restrictSpanLengths,
        restrictedSpanLengths,
        allowDropSpans,
        maxNumberOfDropSpans,
        restrictedEndBoomLengthsFwd,
        endOfSystemTypeFwd,
        restrictedEndBoomLengthsAft,
        endOfSystemTypeAft,

        primaryEndGun,
        secondaryEndGun,
        primaryEndGunThrow,
        secondaryEndGunThrow,
    } = formState;
    const pivotingLateral = formState.pivotingLateral || false;

    const setCanalFeed = (canalFeed: boolean) => setFormState({ ...formState, canalFeed });
    const setCanalWidthFeet = (canalWidthFeet: number) => setFormState({ ...formState, canalWidthFeet });
    const setDistanceFromCanalCenterToSide = (distanceFromCanalCenterToSide: number) =>
        setFormState({ ...formState, distanceFromCanalCenterToAftSide: distanceFromCanalCenterToSide, distanceFromCanalCenterToFwdSide: distanceFromCanalCenterToSide });
    const distanceFromCanalCenterToSide = (distanceFromCanalCenterToFwdSide === distanceFromCanalCenterToAftSide)
        ? distanceFromCanalCenterToFwdSide
        : undefined;

    const setOptimizeSystem = (optimizeSystem: boolean) => setFormState({ ...formState, optimizeSystem });
    const setRestrictSpanLengths = (restrictSpanLengths: boolean) =>
        setFormState({ ...formState, restrictSpanLengths });
    const setEndFeed = (endFeed: boolean) => setFormState({ 
        ...formState, 
        endFeed,
        pivotingLateral: endFeed ? pivotingLateral : false,
        optimizeSystem: (endFeed && pivotingLateral) ? false : formState.optimizeSystem
    });
    const setAllowDropSpans = (allowDropSpans: boolean) => setFormState({ ...formState, allowDropSpans });
    const setMaxNumberOfDropSpans = (maxNumberOfDropSpans: number) =>
        setFormState({ ...formState, maxNumberOfDropSpans });
    const setRestrictedSpanLengths = (restrictedSpanLengths: PartialSpanLengthItem[]) =>
        setFormState({ ...formState, restrictedSpanLengths });

    const setPrimaryEndGun = (v: EndGunTypes) => setFormState({ ...formState, primaryEndGun: v });
    const setSecondaryEndGun = (v: EndGunTypes) => setFormState({ ...formState, secondaryEndGun: v });
    const setPrimaryEndGunThrow = (v?: number) => setFormState({ ...formState, primaryEndGunThrow: v });
    const setSecondaryEndGunThrow = (v?: number) => setFormState({ ...formState, secondaryEndGunThrow: v });

    const setEndOfSystemFwd = (args: { endOfSystemType?: EndOfSystemTypes; restrictedEndBoomLengths: number[] }) => {
        setFormState({
            ...formState,
            restrictedEndBoomLengthsFwd: args.restrictedEndBoomLengths,
            endOfSystemTypeFwd:
                args.endOfSystemType === EndOfSystemTypes.EndBoom ? EndOfSystemTypes.EndBoom : undefined,
            restrictedEndBoomLengthsAft: args.restrictedEndBoomLengths,
            endOfSystemTypeAft:
                args.endOfSystemType === EndOfSystemTypes.EndBoom ? EndOfSystemTypes.EndBoom : undefined,
        });
    };

    const setPivotingLateral = (v: boolean) => {
        setFormState({
            ...formState,
            pivotingLateral: v,
            canalFeed: v ? false : formState.canalFeed,
            endFeed: v ? true : formState.endFeed,
            optimizeSystem: v ? false : formState.optimizeSystem
        })
    }

    const ns = "optimization-dialog.";
    const subns = "lateral.";

    let formInputLabelStyle = {
        ...pageStyles.formInputLabelStyle,
        color: secondaryColor
    };
    
    const setBEndOfSystem = (b: boolean) => {
        const t = b ? EndOfSystemTypes.EndBoom : undefined;
        setEndOfSystemFwd({ endOfSystemType: t, restrictedEndBoomLengths: [] });
    }
    
    const canHavePivotingLateral = endFeed;
    const canHaveCanalFeed = !endFeed || (endFeed && !pivotingLateral);
    const canalSectionDisabled = !canHaveCanalFeed || !canalFeed;
    const disableOptimizeSystem = endFeed && pivotingLateral;

    return (
        <Stack spacing={1}>
            <Card variant="outlined" style={pageStyles.cardStyle.outlined}>
                <Grid container columns={COLS_FULL} spacing={1}>
                    <Grid item xs={COLS_FULL / 3}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink={true} style={{...formInputLabelStyle}} id="system-type-label">
                                {i18next.format(i18next.t(ns + subns + "system-type"),"capitalize-each")}
                            </InputLabel>
                            <Select 
                                variant="standard"
                                labelId="system-type-label"
                                id="system-type-select"
                                value={pivotingLateral ? "pivotingLateral" : "lateral"}
                                onChange={(ev) => setPivotingLateral(ev.target.value === "pivotingLateral")}                            
                            >
                                <MenuItem value={"lateral"}>{i18next.format(i18next.t("roe.lateral"),"capitalize-each")}</MenuItem>
                                <MenuItem value={"pivotingLateral"}>{i18next.format(i18next.t("roe.pivoting-lateral"),"capitalize-each")}</MenuItem>
                            </Select> 
                        </FormControl>
                    </Grid>
                    <Grid item xs={COLS_FULL / 3}>
                        <FormControl variant="standard" fullWidth disabled={pivotingLateral}>
                            <InputLabel shrink={true} style={{...formInputLabelStyle}} id="feed-type-label">
                                {i18next.format(i18next.t(ns + subns + "feed-type"),"capitalize-each")}
                            </InputLabel>
                            <Select 
                                variant="standard"
                                labelId="feed-type-label"
                                id="feed-type-select"
                                value={canalFeed ? "canalFeed" : "hoseFeed"}
                                onChange={(ev) => setCanalFeed(ev.target.value === "canalFeed")} 
                            
                            >
                                <MenuItem value={"hoseFeed"}>{i18next.format(i18next.t("roe.hose-feed"),"capitalize-each")}</MenuItem>
                                <MenuItem value={"canalFeed"}>{i18next.format(i18next.t("roe.canal-feed"),"capitalize-each")}</MenuItem>
                            </Select> 
                        </FormControl>
                    </Grid>
                    <Grid item xs={COLS_FULL / 3}>
                        <FormControl variant="standard" fullWidth disabled={pivotingLateral}>
                            <InputLabel shrink={true} style={{...formInputLabelStyle}} id="feed-location-label">
                                {i18next.format(i18next.t(ns + subns + "feed-location"),"capitalize-each")}
                            </InputLabel>
                            <Select 
                                variant="standard"
                                labelId="feed-location-label"
                                id="feed-location-select"
                                value={endFeed ? "endFeed" : "centerFeed"}
                                onChange={(ev) => setEndFeed(ev.target.value === "endFeed")} 
                            
                            >
                                <MenuItem value={"centerFeed"}>{i18next.format(i18next.t("roe.center-feed"),"capitalize-each")}</MenuItem>
                                <MenuItem value={"endFeed"}>{i18next.format(i18next.t("roe.end-feed"),"capitalize-each")}</MenuItem>
                            </Select> 
                        </FormControl>
                    </Grid>
                </Grid>
            </Card>

            <Card variant="outlined" style={pageStyles.cardStyle.outlined}>
                <Grid container columns={3} alignItems={"flex-end"} columnSpacing={1}>
                    <Grid item xs={1}>
                        <FormControl>
                            <PositiveConvertedDecimalField
                                textFieldProps={{
                                    disabled: canalSectionDisabled,
                                    label: `${i18next.format(t(ns + subns + "canal-width"), "capitalize")} (${t(
                                        DisplayLengthUnitBuilder.shortName(displaySettings.lengths)
                                    )})`,
                                }}
                                value={canalWidthFeet}
                                setValue={setCanalWidthFeet}
                                originalUnit="feet"
                                displayUnit={displaySettings.lengths}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth variant="standard" disabled={canalSectionDisabled}>
                            <InputLabel
                                shrink={true}
                                style={{
                                    ...formInputLabelStyle,
                                    color: !canalSectionDisabled ? "inherit" : disabledColor,
                                }}
                            >{`${i18next.format(t(ns + subns + "distance-canal-center-to-system"), "capitalize")} (${t(
                                DisplayLengthUnitBuilder.shortName(displaySettings.lengths)
                            )})`}</InputLabel>
                            <Select
                                value={distanceFromCanalCenterToSide || 0}
                                onChange={(ev) => {
                                    const v = ev.target.value as number;
                                    setDistanceFromCanalCenterToSide(v);
                                }}
                            >
                                {[13, 17, 19, 21, 23].map((x) => (
                                    <MenuItem key={x} value={x}>
                                        {
                                            (new DisplayLengthUnitBuilder(x, "feet"))
                                                .convert(displaySettings.lengths)
                                                .appendValue(1)
                                                .toString()
                                        }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Card>

            <FormControl fullWidth>
                <FormControlLabel
                    label={i18next.t(ns + "optimize-system")}
                    disabled={disableOptimizeSystem}
                    control={
                        <Checkbox
                            checked={optimizeSystem}
                            onChange={(ev) => setOptimizeSystem(ev.target.checked)}
                        />
                    }
                />
            </FormControl>

            {/* optimization */}
            <FormControl disabled={disableOptimizeSystem || !optimizeSystem}>
                <Card variant="outlined" style={pageStyles.cardStyle.outlined}>
                    <Grid container columns={COLS_FULL} spacing={1}>
                        <Grid item xs={COLS_LEFT_FULL}>
                            <FormControlLabel
                                label={i18next.t(ns + "choose-span-lengths")}
                                control={
                                    <Checkbox
                                        checked={restrictSpanLengths}
                                        onChange={(ev) => setRestrictSpanLengths(ev.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={COLS_RIGHT}>
                            <RestrictSpanLengthsControl
                                restrictSpanLengths={restrictSpanLengths && optimizeSystem}
                                restrictedSpanLengths={restrictedSpanLengths}
                                onChangeRestrictedSpanLengths={setRestrictedSpanLengths}
                                options={{
                                    type: "Span"
                                }}
                            />
                        </Grid>

                        <Grid item xs={COLS_LEFT_FULL}>
                            <FormControlLabel
                                label={i18next.t(ns + subns + "allow-drop-spans")}
                                control={
                                    <Checkbox
                                        checked={allowDropSpans}
                                        onChange={(ev) => setAllowDropSpans(ev.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={COLS_RIGHT}>
                            <DropSpanChoiceControl
                                nDrops={maxNumberOfDropSpans}
                                setMaxNumberOfDrops={setMaxNumberOfDropSpans}
                                disabled={!allowDropSpans || !optimizeSystem}
                            />
                        </Grid>
                        
                        
                        <Grid item xs={COLS_LEFT_FULL} minHeight={56}>
                            <FormControlLabel
                                label={i18next.format(i18next.t(prefix + "end-boom"), "capitalize")}
                                control={
                                    <Checkbox
                                        checked={endOfSystemTypeFwd === EndOfSystemTypes.EndBoom}
                                        onChange={(ev) => setBEndOfSystem(ev.target.checked)}
                                    />
                                }
                            />         
                        </Grid>
                        <Grid item xs={COLS_RIGHT}>
                            <EndOfSystemOptimizationType
                                endOfSystemType={endOfSystemTypeFwd}
                                restrictedEndBoomLengths={restrictedEndBoomLengthsFwd}
                                onChangeEndOfSystem={setEndOfSystemFwd}
                                canHaveSac={false}
                                disabled={!optimizeSystem}
                                showTypeChoice={false}
                            />
                        </Grid>
                        

                        <Grid item xs={COLS_FULL}>
                            <FormControlLabel
                                label={t("common-phrases.sltPrimaryEndGun")}
                                control={
                                    <Checkbox
                                        checked={primaryEndGun !== EndGunTypes.None}
                                        onChange={(ev) => setPrimaryEndGun(ev.target.checked ? EndGunTypes.SR100 : EndGunTypes.None)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={COLS_LEFT_PARTIAL_1} />
                        <Grid item xs={COLS_LEFT_PARTIAL_2}>
                            <EndGunType
                                type={primaryEndGun}
                                setType={(v) => setPrimaryEndGun(v)}
                                throwFeet={primaryEndGunThrow}
                                setThrowFeet={setPrimaryEndGunThrow}
                                disabled={!optimizeSystem || primaryEndGun === EndGunTypes.None}
                            />
                        </Grid>
                        <Grid item xs={COLS_RIGHT} sx={{ minHeight: 60 }}>
                            <EndGunTypeThrowSlider
                                type={primaryEndGun}
                                throwFeet={primaryEndGunThrow}
                                setThrowFeet={setPrimaryEndGunThrow}
                                disabled={!optimizeSystem || primaryEndGun === EndGunTypes.None}
                            />
                        </Grid>

                        <Grid item xs={COLS_FULL}>
                            <FormControlLabel
                                label={t("common-phrases.sltSecondaryEndGun")}
                                control={
                                    <Checkbox
                                        checked={secondaryEndGun !== EndGunTypes.None}
                                        onChange={(ev) => setSecondaryEndGun(ev.target.checked ? EndGunTypes.R55 : EndGunTypes.None)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={COLS_LEFT_PARTIAL_1} />
                        <Grid item xs={COLS_LEFT_PARTIAL_2}>
                            <EndGunType
                                type={secondaryEndGun}
                                setType={(v) => setSecondaryEndGun(v)}
                                throwFeet={secondaryEndGunThrow}
                                setThrowFeet={setSecondaryEndGunThrow}
                                isSecondary
                                disabled={!optimizeSystem || secondaryEndGun === EndGunTypes.None}
                            />
                        </Grid>
                        <Grid item xs={COLS_RIGHT} sx={{ minHeight: 60 }}>
                            <EndGunTypeThrowSlider
                                type={secondaryEndGun}
                                throwFeet={secondaryEndGunThrow}
                                setThrowFeet={setSecondaryEndGunThrow}
                                disabled={!optimizeSystem || secondaryEndGun === EndGunTypes.None}
                            />
                        </Grid>

                        <Grid item xs={COLS_FULL}>
                            <Typography variant="subtitle1">{t(ns + subns + "end-gun-note")}</Typography>
                        </Grid>
                    </Grid>

                </Card>

            </FormControl>
        </Stack>
    );
};

export default OptimizeLateralForm;
