import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { v4 as uuidv4 } from 'uuid';
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange } from './helpers';

const actionTypeId = "DuplicateLayout";

interface ActionOptions {
    boundaries: boolean;
    obstacles: boolean;
    systems: boolean | string[]; // if string[] then systemId array
    annotations: boolean;
}

interface ActionData extends IActionData {
    newLayoutId: string;
    copiedLayoutId: string;    
    options?: ActionOptions;
    newSystemIds?: string[];
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;

    const options = data.options || {
        boundaries: true,
        obstacles: true,
        systems: true,
        annotations: true
    };

    let newLayoutNumber = 0;
    let newLayoutName: string;
    do {
        newLayoutNumber++;
        newLayoutName = `Layout ${newLayoutNumber}`;
    } while (Object.values(state.layouts).some(x => x.name === newLayoutName));

    let copiedLayout = structuredClone(state.layouts[data.copiedLayoutId]);

    state.layouts[data.newLayoutId] = {
        name: newLayoutName,
        lastModifiedTimestamp: 0, // Updated to the actual timestamp in recordLayoutChange
        lastModifiedUserId: "", // Updated to the actual user ID in recordLayoutChange
        
        systems: { },
        obstacles: [],
        wheelObstacles: [],
        annotations: [],
        measurements: []
    };

    if (options.boundaries) {
        state.layouts[data.newLayoutId].fieldBoundary = copiedLayout.fieldBoundary;
        state.layouts[data.newLayoutId].pivotCenterBoundary = copiedLayout.pivotCenterBoundary;
        state.layouts[data.newLayoutId].wetAreaBoundary = copiedLayout.wetAreaBoundary;
    }
    if (options.obstacles) {
        state.layouts[data.newLayoutId].obstacles = [ ...copiedLayout.obstacles ];
        state.layouts[data.newLayoutId].wheelObstacles = [ ...copiedLayout.wheelObstacles ];
    }

    let iSystem = 0;
    for (const systemId of Object.keys(copiedLayout.systems)) {
        if ((typeof options.systems === 'boolean' && options.systems)
            || (typeof options.systems !== 'boolean' && options.systems.indexOf(systemId) !== -1)) {
            const newSystem = copiedLayout.systems[systemId];

            newSystem.proposalGenerated = false;
            newSystem.orderSubmissionStatus = undefined;
            newSystem.createQuoteStatus = undefined;
            newSystem.QuoteProperties.ProposalInformation.requestedShippingDate = undefined;

            state.layouts[data.newLayoutId].systems[data.newSystemIds ? data.newSystemIds[iSystem] : systemId] = newSystem;
        }
        iSystem++;
    }
    if (options.annotations) {
        state.layouts[data.newLayoutId].annotations = [ ...copiedLayout.annotations ];
    }

    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.newLayoutId);
});

export const duplicateLayoutAction = (
    authState: IAuthState, 
    copiedLayoutId: string,
    nSystems: number,
    options?: ActionOptions
) => {
    const newLayoutId = uuidv4();
    
    const newSystemIds: string[] = [];
    for (let i = 0; i < nSystems; i++) {
        newSystemIds.push(uuidv4());
    }

    const action = createAction(
        actionTypeId,
        {
            newLayoutId,
            copiedLayoutId,
            options,
            newSystemIds,
        } as ActionData,
        authState);
    return {
        newLayoutId,
        action
    };
};