import { BlobWriter, TextReader, ZipWriter } from "@zip.js/zip.js";
import { ExportProject } from "../common/interfaces";
import { exportProjectToKmlString } from "./kmlFile";
import { blobToBase64 } from "./rapFile";

interface IArgs {
    eProject: ExportProject;
}

export const exportToKmz = async (args: IArgs) => {
    const kml = exportProjectToKmlString(args.eProject);
    const zipFileWriter = new BlobWriter();
    const kmlReader = new TextReader(kml);
    const zipWriter = new ZipWriter(zipFileWriter);
    await zipWriter.add("layer.kml", kmlReader);
    await zipWriter.close();

    const zipFileBlob = await zipFileWriter.getData();
    const b64file = await blobToBase64(zipFileBlob)
    return b64file;
}