import { RigidDropMaterialTypes } from "rdptypes/project/ISprinklers";
import {
    BoosterPumpTypes,
    CollectorRingTypes,
    Countries,
    CouplerTypes,
    EndGunTypes,
    EndTowerPositioningTypes,
    GearDriveTypes,
    PanelModels,
    SACConductorOptions,
    SACGPSGuidanceBaseOptions,
    SACPathCreatorType,
    SACPipeTypes,
    SACRetroSpanTypes,
    SACRetroTireTypes,
    SACZoneControlTypes,
    SpanTypes,
    SprinklerValveTypes,
    SwingArmLengths,
    SystemTypes,
    TireSizes,
    TowerBoxOverloads,
    TowerHeights
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/poSchema";
import CSection2 from "./CSection2";
import { TireSelector } from "./CommonFunctions";
import * as ohf from "./OtherHelpers";
import PartsAssembler from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as spanf from "./SpanFunctions";
import { ESACZoneFactors } from './SprinklerEngineTypes/ESACZoneFactors';
import * as sysf from "./SystemFunctions";
import { strLanguageText } from "./TranslationsLib";
import { EndGun, Tires } from "./Types";
import { ConductorOptionOk, ExtraGPSDataCableOK, GPSBaseConvertOK, SwingArmPipeCoatingOK, TireSizeSACOK, TubesOK } from "./Valids.Valids";
export class SwingArmClass extends CSection2 {
    public RequireNoChargeReinkeSACPath = (): boolean => {
        if (this.Quote.RightTowersClass.AnyWrapAroundSpans()) {
            return true;
        }
        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro) {
            switch (this.Quote.System.Circle.SACRetro.ParentSpanType) {
                case SACRetroSpanTypes.E80E100E6580E680:
                case SACRetroSpanTypes.AlumIII:
                case SACRetroSpanTypes.Valley:
                case SACRetroSpanTypes.ValleyWithDrop:
                case SACRetroSpanTypes.Zimmatic:
                    return true;
                    break;
            }
        }
        return false;
    }

public SACPathCreatorPartNum = (): string => {
    if (!(this.FieldSet.DataValid())) {
        return "";
    }
    if (this.Quote.System.Circle.SwingArm.SACPathCreator !== SACPathCreatorType.Reinke) {
        return "";
    }
    if (this.RequireNoChargeReinkeSACPath()) {
        return "357062";
    }
    else {
        return "357060";
    }
}
public SACPathCreatorPartDesc = (): string => {
    if (!(this.FieldSet.DataValid())) {
        return "";
    }
    if (this.Quote.System.Circle.SwingArm.SACPathCreator !== SACPathCreatorType.Reinke) {
        return "";
    }
    if (this.RequireNoChargeReinkeSACPath()) {
        return "NAVIGATOR GPS PATH FILE RETRO";
    }
    else {
        return "NAVIGATOR GPS PATH FILE NEW";
    }
}

public SpecialOrderPriceCents = (): number => {
    if (!(this.FieldSet.DataValid())) {
        return 0;
    }
    if (this.Quote.System.Circle.SwingArm.SACPathCreator !== SACPathCreatorType.Reinke) {
        return 0;
    }
    if (this.RequireNoChargeReinkeSACPath()) {
        return 0;
    }
    else {
        return 25000;
    }
}
public ComputeSwingArmType = (): SpanTypes => {
        switch (this.Quote.System.Circle.SwingArm.PipeCoating) {
            case SACPipeTypes.GalvanizedCNPlus:
            case SACPipeTypes.PaintedCNPlus:
                return SpanTypes.E2660;
            default:
                return SpanTypes.E2060;
        }
    }
    public GetPipeModel = (cModel: string) => {
        switch (this.Quote.System.Circle.SwingArm.PipeCoating) {
            case SACPipeTypes.GalvanizedCNPlus:
            case SACPipeTypes.PaintedCNPlus:
                if (strLanguageText("sltE2660").indexOf(cModel) === -1) {
                    if (cModel === "") {
                        cModel = strLanguageText("sltE2660");
                    }
                    else {
                        cModel += "/" + strLanguageText("sltE2660");
                    }
                }
                break;
            case SACPipeTypes.Galvanized:
                if (strLanguageText("sltE2060").indexOf(cModel) === -1) {
                    if (cModel === "") {
                        cModel = strLanguageText("sltE2060");
                    }
                    else {
                        cModel += "/" + strLanguageText("sltE2060");
                    }
                }
                break;
        }

        return cModel;
    }

    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): boolean => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/
        let SACSpan: number = this.Quote.SwingArmSpan();
        if (SACSpan < 0) {
            this.Quote.Warnings.Add(this.TranslatedKey);
            return false;
        }
        let eSwingArmLength: SwingArmLengths = this.Quote.System.FlangedSide.EndOfSystem.SwingArmLength;
        let bGalvanizedCNPlus: boolean;
        let sys = this.Quote.System;
        let eSystemType: SystemTypes = sys.SystemProperties.SystemType;
        let eParentSpanType: SpanTypes;
        let dLastWheelTrack: number;
        let eHingeTowerTireSize: TireSizes;
        let bSmallTireOnLastTower: boolean;
        let bLowSpeedOnLastTower: boolean;
        let IsControlPanelVRISACSpanCompliant = false;
        switch (eSystemType) {
            case SystemTypes.CenterPivot:
            case SystemTypes.KwikTow:
                bGalvanizedCNPlus = sys.SpanProperties.GalvanizedCNPlus;
                dLastWheelTrack = spanf.EndingLocation(this.Quote.System, sys.FlangedSide, sys.FlangedSide.Span[sidef.NumberOfSpans(sys.FlangedSide) - 1]);
                eParentSpanType = sys.FlangedSide.Span[sidef.NumberOfSpans(sys.FlangedSide) - 1].SpanType;
                eHingeTowerTireSize = sys.FlangedSide.Tower[sidef.NumberOfTowers(sys.FlangedSide) - 1].Tires.TireSize;
                break;
            case SystemTypes.SwingArmRetro:
                bGalvanizedCNPlus = false;
                dLastWheelTrack = sys.Circle.SACRetro.LastWheelTrack;
                bLowSpeedOnLastTower = sys.Circle.SACRetro.HingeTowerGearRatio === GearDriveTypes.Low;
                bSmallTireOnLastTower = sys.Circle.SACRetro.HingeTireType !== SACRetroTireTypes.Other;
                IsControlPanelVRISACSpanCompliant = sys.Circle.SACRetro.IsControlPanelVRISACSpanCompliant;
                break;
        }
        let sa = sys.Circle.SwingArm;
        if (!(ExtraGPSDataCableOK(sa.ExtraGPSDataCable, sa.GPSBase, dLastWheelTrack))) {
            this.Quote.Warnings.Add(strLanguageText("sltExtraGPSDataCable"));
            return false;
        }
        if (!(SwingArmPipeCoatingOK(sa.PipeCoating, {
            ParentSpanType: eParentSpanType,
            HasGalvanizedCNPlus: bGalvanizedCNPlus,
            SystemType: eSystemType,
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltPipeCoating"));
            return false;
        }
        let t = sa.Tires;
        if (eSystemType !== SystemTypes.SwingArmRetro) {
            if (!(TireSizeSACOK(t.TireSize, eHingeTowerTireSize, eSwingArmLength))) {
                this.Quote.Warnings.AddWithTargets(["Circle.SwingArm.Tires.TireSize"], strLanguageText("sltTireSize"));
                return false;
            }
        }
        else {
            switch (t.TireSize) {
                case TireSizes.a11x225:
                    if (!((bSmallTireOnLastTower || bLowSpeedOnLastTower))) {
                        this.Quote.Warnings.AddWithTargets(["Circle.SwingArm.Tires.TireSize"], strLanguageText("sltTireSize"));
                        return false;
                    }
                    break;
                case TireSizes.a149x24:
                    if (eSwingArmLength === SwingArmLengths.SAC213) {
                        this.Quote.Warnings.AddWithTargets(["Circle.SwingArm.Tires.TireSize"], strLanguageText("sltTireSize"));
                        return false;
                    }
                    break;
            }
        }
        if (!(TubesOK(t.TubesForTires, {
            TireSize: t.TireSize,
            TireType: t.TireType,
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltTubesForTires"));
            return false;
        }
        let eCollectorRing: CollectorRingTypes;
        let PanelModel = PanelModels.RPMBasic;
        let EndTowerPositioningType = EndTowerPositioningTypes.None;
        if (this.Quote.ControlPanelClass.FieldSet.DataValid()) {
            eCollectorRing = this.Quote.System.ControlPanel.CollectorRing;
            PanelModel = this.Quote.System.ControlPanel.PanelModel;
            EndTowerPositioningType = this.Quote.System.ControlPanel.EndTowerPositioning;
        }
        else {
            eCollectorRing = CollectorRingTypes.a10Ring;
            PanelModel = PanelModels.RPMAdvanced;
            if (IsControlPanelVRISACSpanCompliant) {
                PanelModel = PanelModels.RPMPreferred;
                EndTowerPositioningType = EndTowerPositioningTypes.GPS;
            }
        }

        if (this.Quote.RightSpansClass.FieldSet.DataValid()) {
            const EndGun2Type = sys.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary
            if (!ConductorOptionOk(sa.SACConductorOption, {
                PanelModel: PanelModel,
                EndTowerPositioningType: EndTowerPositioningType,
                EndGun2Type: EndGun2Type,
            })) {
                this.Quote.Warnings.Add(strLanguageText("sltSACConductorOption")) // "Minimum Conductor Count Incorrect"
                return false;
            }
        }

        if (sa.ControlLineBoosterESP) {
            if (!(sa.EnergySaverPackage)) {
                this.Quote.Warnings.Add(strLanguageText("sltControlLineBoosterESP"));
                return false;
            }
        }
        if (sa.AirCompressor && (sa.ControlLineBooster || sa.ControlLineBoosterESP)) {
            this.Quote.Warnings.Add(strLanguageText("sltAirCompressor"));
            return false;
        }
        if (!(GPSBaseConvertOK(sa.GPSBaseConvert, {
            SACGPSGuidanceBaseOption: sa.GPSBase, Country: this.Quote.System.DealerProperties.Country
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltGPSBaseConvert"));
            return false;
        }
        if (sa.GPSBase === SACGPSGuidanceBaseOptions.NoBase) {
            this.Quote.Warnings.Add(strLanguageText("sltGPSLineOfSight"), WarningLevels.Decision);
        }
        if (eSystemType === SystemTypes.SwingArmRetro) {
            this.Quote.Warnings.Add(strLanguageText("sltNoSprinklers"), WarningLevels.Informational);
        }

        if (sa.ZoneControlType === SACZoneControlTypes.ESAC125) {
            let factors: string = this.Quote.System.Circle.SwingArm.ESAC125DistributionFlowRateFactors;
            let ezf = new ESACZoneFactors();
            if (!(ezf.Validate125DistValues(factors).IsValid)) {
                this.Quote.Warnings.Add(strLanguageText("sltDistributionAreaFactorsNeeded"), WarningLevels.Critical, true);
            }
        }
        return true;
    }
    public WritePO = (PO: PartsAssembler) => {
        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.Ancillary) return;

        const sys = this.Quote.System;
        const uPipePackageOnSACResult = sysf.RequiresUPipePackageOnSAC(sys);
        const sa = sys.Circle.SwingArm;
        const eSwingArmLength = sys.FlangedSide.EndOfSystem.SwingArmLength;
        if (sys.FlangedSide.EndOfSystem.HingeTowerOnly) {
            // The following entire Select Block is a duplicate of below.
            // For "Hinge Tower Only" it is only necessary to add Clamp Kits when required.
            switch (sa.ZoneControlType) {
                case SACZoneControlTypes.ESAC060:
                    if (uPipePackageOnSACResult.Required) {
                        switch (eSwingArmLength) {
                            case SwingArmLengths.SAC156:
                                // ROE-824 - U-Pipes for ESAC and VRI - Disable 156 and 175 temporarily
                                // PO.InsertRecord(POSections.SwingArm, POSprinklerHarness, 105, 1) '356890	DROP CLAMP KIT 6 ZONE E-MSAC
                                throw new Error("ROE-824 - Disabled");
                            case SwingArmLengths.SAC175:
                                // ROE-824 - U-Pipes for ESAC and VRI - Disable 156 and 175 temporarily
                                throw new Error("ROE-824 - Disabled");
                            // PO.InsertRecord(POSections.SwingArm, POSprinklerHarness, 104, 1) '356889	DROP CLAMP KIT 6 ZONE E-MSSAC
                            case SwingArmLengths.SAC194:
                                if (uPipePackageOnSACResult.RigidDropsOnSACResult.RigidDropMaterialType === RigidDropMaterialTypes.Galvanized) {
                                    PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356881", 1);  // CLAMP KT-6 ZN U-PIPE G ESAC
                                } else {
                                    PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356882", 1);  // CLAMP KT-6 ZN U-PIPE P ESAC
                                }
                                break;
                            case SwingArmLengths.SAC213:
                                if (uPipePackageOnSACResult.RigidDropsOnSACResult.RigidDropMaterialType === RigidDropMaterialTypes.Galvanized) {
                                    PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356883", 1);  // CLAMP KT-6 ZN U-PIPE G ESSAC
                                } else {
                                    PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356884", 1);  // CLAMP KT-6 ZN U-PIPE P ESSAC
                                }
                                break;
                            default:
                                throw new Error(`Invalid selection '${sys.FlangedSide.EndOfSystem.SwingArmLength}'`);
                        }
                    }
                    break;
                case SACZoneControlTypes.ESAC120:
                case SACZoneControlTypes.ESAC125:
                    if (uPipePackageOnSACResult.Required) {
                        switch (eSwingArmLength) {
                            case SwingArmLengths.SAC156:
                                // ROE-824 - U-Pipes for ESAC and VRI - Disable 156 and 175 temporarily
                                // PO.InsertRecord(POSections.SwingArm, POSprinklerHarness, 106, 1) '356891	DROP CLAMP KIT 12 ZONE E-MSAC
                                throw new Error("ROE-824 - Disabled");
                            case SwingArmLengths.SAC175:
                                // 'ROE-824 - U-Pipes for ESAC and VRI - Disable 156 and 175 temporarily
                                //'PO.InsertRecord(POSections.SwingArm, POSprinklerHarness, 107, 1) '356892	DROP CLAMP KIT 12 ZONE E-MSSAC
                                throw new Error("ROE-824 - Disabled");
                            case SwingArmLengths.SAC194:
                                if (uPipePackageOnSACResult.RigidDropsOnSACResult.RigidDropMaterialType === RigidDropMaterialTypes.Galvanized) {
                                    PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356877", 1);  // CLAMP KT-12 ZN U-PIPE G ESAC
                                } else {
                                    PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356878", 1);  // CLAMP KT-12 ZN U-PIPE P ESAC
                                }
                                break;
                            case SwingArmLengths.SAC213:
                                if (uPipePackageOnSACResult.RigidDropsOnSACResult.RigidDropMaterialType === RigidDropMaterialTypes.Galvanized) {
                                    PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356879", 1);  // CLAMP KT-12 ZN U-PIPE G ESSAC
                                } else {
                                    PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356880", 1);  // CLAMP KT-12 ZN U-PIPE P ESSAC
                                }
                                break;
                            default:
                                throw new Error(`Invalid selection '${sys.FlangedSide.EndOfSystem.SwingArmLength}'`);
                        }
                    }
                default:
                    throw new Error(`Logic not provided for '${sa.ZoneControlType}'`);
            }
            return;
        }

        let iCenterDrive: GearDriveTypes;
        let eTowerType: TowerHeights;
        let eCouplerType: CouplerTypes;
        let bTheftDeterrentClips: boolean;
        let bWithoutSpring: boolean;
        let IsControlPanelVRISACSpanCompliant: boolean;
        const systemType = sys.SystemProperties.SystemType;
        switch (this.Quote.System.SystemProperties.SystemType) {
            case SystemTypes.SwingArmRetro:
                switch (this.Quote.System.Circle.SACRetro.HingeTowerGearRatio) {
                    case GearDriveTypes.Standard:
                        iCenterDrive = GearDriveTypes.High;
                        break;
                    case GearDriveTypes.Low:
                        iCenterDrive = GearDriveTypes.Standard;
                        break;
                }
                eTowerType = this.Quote.System.Circle.SACRetro.HingeTowerType;
                eCouplerType = this.Quote.System.Circle.SACRetro.Coupler;
                IsControlPanelVRISACSpanCompliant = this.Quote.System.Circle.SACRetro.IsControlPanelVRISACSpanCompliant;
                break;
            case SystemTypes.CenterPivot:
            case SystemTypes.KwikTow:
                if (this.Quote.RightTowersClass.FieldSet.DataValid()) {
                    eCouplerType = this.Quote.System.TowerProperties.Coupler;
                    bTheftDeterrentClips = this.Quote.System.SpanProperties.TheftDeterrentClip;
                    bWithoutSpring = this.Quote.System.SpanProperties.ConduitClipWOSpringClip;
                    eTowerType = this.Quote.System.FlangedSide.Tower[sidef.NumberOfTowers(this.Quote.System.FlangedSide) - 1].TowerType;
                    switch (this.Quote.System.FlangedSide.Tower[sidef.NumberOfTowers(this.Quote.System.FlangedSide) - 1].CenterDrive) {
                        case GearDriveTypes.Standard:
                            iCenterDrive = GearDriveTypes.High;
                            break;
                        case GearDriveTypes.Low:
                            iCenterDrive = GearDriveTypes.Standard;
                            break;
                    }
                }
                break;
        }
        if (this.Quote.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro) {
            PO.InsertPart(schema.groups.OptionsandAccessories_WarrantyPacket.groupId, "352799", 1); // PACKET WARR SAC RETRO
            let sacr = this.Quote.System.Circle.SACRetro;
            switch (sacr.ParentSpanType) {
                case SACRetroSpanTypes.E65E60E665E660:
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerRetroKit.groupId, "394959", 1); // RETRO E60 SYS-EII H TWR UHMW
                    break;
                case SACRetroSpanTypes.E80E100E6580E680:
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerRetroKit.groupId, "352620", 1); // RETRO KIT-SAC-H TWR-E80/100
                    break;
                case SACRetroSpanTypes.AlumIII:
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerRetroKit.groupId, "352622", 1); // RETRO-A3 SYS-E60 H TWR ROLLER
                    break;
                case SACRetroSpanTypes.AlumIV:
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerRetroKit.groupId, "352617", 1); // RETRO A4 TWR - E60 H TWR UHMW
                    break;
                case SACRetroSpanTypes.E2065E2060E2665E2660:
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerRetroKit.groupId, "394968", 1); // RETRO EII SYS-EII H TWR UHMW
                    break;
                case SACRetroSpanTypes.Valley:
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerRetroKit.groupId, "394957", 1); // RETRO-VAL H TWR UHMW
                    break;
                case SACRetroSpanTypes.Zimmatic:
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerRetroKit.groupId, "394958", 1); // RETRO-ZIM H TWR UHMW
                    break;
                case SACRetroSpanTypes.ValleyWithDrop:
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerRetroKit.groupId, "394956", 1); // RETRO-VAL H TWR LAST PIPE DROP
                    break;
            }
            if (sacr.NewLastSpanWire) {
                switch (sacr.ParentSpanLength) {
                    case 80:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352624", 1); // SPAN WIRE 80 SPAN-RETROFIT-SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352624-H", 1); // SPAN WIRE-80FT SP-RETRO-SAC #8
                        }
                        break;
                    case 83:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352636", 1); // SPAN WIRE-83F LAST SPAN A3/SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352636-H", 1); // SPAN WIRE-83FT LAST-A3/SAC #8
                        }
                        break;
                    case 99:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352625", 1); // SPAN WIRE 99 SPAN-RETROFIT-SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352625-H", 1); // SPAN WIRE-99FT SP-RETRO-SAC #8
                        }
                        break;
                    case 103:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352637", 1); // SPAN WIRE-103F LST SPAN A3/SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352637-H", 1); // SPAN WIRE-103FT LAST-A3/SAC #8
                        }
                        break;
                    case 118:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352626", 1); // SPAN WIRE 118 SPN-RETROFIT-SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352626-H", 1); // SPAN WIRE-118FT SP-RETRO-SAC#8
                        }
                        break;
                    case 123:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352638", 1); // SPAN WIRE-123F LST SPAN A3/SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352638-H", 1); // SPAN WIRE-123FT LAST-A3/SAC #8
                        }
                        break;
                    case 124:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352633", 1); // SPAN WIRE-124 SPN RETRFIT-SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352633-H", 1); // SPAN WIRE-124FT SP-RETRO-SAC#8
                        }
                        break;
                    case 137:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352627", 1); // SPAN WIRE 137 SPN-RETROFIT-SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352627-H", 1); // SPAN WIRE-137FT SP-RETRO-SAC#8
                        }
                        break;
                    case 140:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "392680", 1); // SPAN CABLE-140FT LAST-SAC SPAN
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "392680-H", 1); // SPAN CABLE-140FT LAST-SAC #8
                        }
                        break;
                    case 143:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352639", 1); // SPAN WIRE-143F LST SPAN A3/SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352639-H", 1); // SPAN WIRE-143FT LAST-A3/SAC #8
                        }
                        break;
                    case 144:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352634", 1); // SPAN WIRE-144 SPN RETRFIT-SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352634-H", 1); // SPAN WIRE-144FT SP-RETRO-SAC#8
                        }
                        break;
                    case 156:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352628", 1); // SPAN WIRE 156 SPN-RETROFIT-SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352628-H", 1); // SPAN WIRE-156FT SP-RETRO-SAC#8
                        }
                        break;
                    case 160:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "392685", 1); // SPAN CABLE-160FT LAST-SAC SPAN
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "392685-H", 1); // SPAN CABLE-160FT LAST-SAC #8
                        }
                        break;
                    case 164:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352632", 1); // SPAN WIRE-164 SPN RETROFIT-SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352632-H", 1); // SPAN WIRE-164FT SP-RETRO-SAC#8
                        }
                        break;
                    case 175:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352629", 1); // SPAN WIRE 175 SPN-RETROFIT-SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352629-H", 1); // SPAN WIRE-175FT SP-RETRO-SAC#8
                        }
                        break;
                    case 180:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "392691", 1); // SPAN CABLE-180FT LAST-SAC SPAN
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "392691-H", 1); // SPAN CABLE-180FT LAST-SAC #8
                        }
                        break;
                    case 186.7:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "392692", 1); // SPAN CABLE-186.7 LAST SAC SPAN
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "392692-H", 1); // SPAN CABLE-186.7 LAST SAC #8
                        }
                        break;
                    case 194:
                        if (sacr.SACCableGauge === 10) {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352635", 1); // SPAN WIRE 194 SPN RETROFT-SAC
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_SpanCableAssembly.groupId, "352635-H", 1); // SPAN WIRE-194FT SP-RETRO-SAC#8
                        }
                        break;
                }
            }
            switch (sacr.HingeTowerBoxOverloads) {
                case TowerBoxOverloads.HeavyDuty:
                    PO.InsertPart(schema.groups.SwingArmCorner_HingeTowerTowerBox.groupId, "352762-H", 1); // TOWER BOX-H SAC-H D-UL/C
                    break;
                case TowerBoxOverloads.None:
                    PO.InsertPart(schema.groups.SwingArmCorner_HingeTowerTowerBox.groupId, "352762-U", 1); // TOWER BOX-H SAC-NO OL-UL/C
                    break;
            }
            if (sacr.TowerBoxKit) {
                if ([SACRetroSpanTypes.Valley, SACRetroSpanTypes.ValleyWithDrop, SACRetroSpanTypes.Zimmatic].indexOf(sacr.ParentSpanType) !== -1) {
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerRetroKit.groupId, "352691", 1); // TOWER BX KT-RETRO-SAC V & Z
                }
                else {
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerRetroKit.groupId, "352690", 1); // TOWER BOX KT-RETRO-REINKE SAC
                }
            }
            if (!(sacr.OmitStiffenerStabilizer) && [SACRetroSpanTypes.E2065E2060E2665E2660, SACRetroSpanTypes.E65E60E665E660].indexOf(sacr.ParentSpanType) !== -1 && sacr.ParentSpanLength !== 194) {
                PO.InsertPart(schema.groups.TowerAssemblies_StiffenerStabilizers.groupId, "394373", 1); // STIFFENER STAB OPT 6" / 6-5/8"
            }
        }
        let PanelModel: PanelModels = PanelModels.RPMBasic;
        let EndTowerPositioningType: EndTowerPositioningTypes = EndTowerPositioningTypes.None;
        if (sysf.FieldSets(this.Quote.System).ControlPanel.DataValid()) {
            PanelModel = sys.ControlPanel.PanelModel;
            EndTowerPositioningType = sys.ControlPanel.EndTowerPositioning;
        } else {
            if (IsControlPanelVRISACSpanCompliant) { // This passes mirrors values from SACRetro
                PanelModel = PanelModels.RPMPreferred;
                EndTowerPositioningType = EndTowerPositioningTypes.GPS;
            }
        }
        let hasVRI: boolean;
        if (sysf.FieldSets(this.Quote.System).SprinklerConfig.DataValid()) {
            hasVRI = ohf.VRIZonesCount(sys.SprinklerProperties.VRIZones) > 0;
        }
        let EndGun2Type: EndGunTypes = EndGunTypes.None;
        if (sysf.FieldSets(this.Quote.System).FlangedEndOfSystem.DataValid()) {
            EndGun2Type = sys.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary;
        }

        switch (sa.SACConductorOption) {
            case SACConductorOptions.SpanWire10C:
                switch (eSwingArmLength) {
                    case SwingArmLengths.SAC156:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmSpanCable.groupId, "393622", 1); // WIRE 10C-156' MSAC-166.5'
                        break;
                    case SwingArmLengths.SAC175:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmSpanCable.groupId, "393623", 1); // WIRE 10C-175' MSSAC-185.5'
                        break;
                    case SwingArmLengths.SAC194:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmSpanCable.groupId, "393624", 1); // WIRE 10C-194' SAC-203'
                        break;
                    case SwingArmLengths.SAC213:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmSpanCable.groupId, "393625", 1); // WIRE 10C-213' SSAC-222'
                        break;
                }
                break;
            case SACConductorOptions.SpanWire11C:
                switch (eSwingArmLength) {
                    case SwingArmLengths.SAC156:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmSpanCable.groupId, "393622-X", 1); // WIRE 11C-156' MSAC-166.5'
                        break;
                    case SwingArmLengths.SAC175:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmSpanCable.groupId, "393623-X", 1); // WIRE 11C-175' MSSAC-185.5'
                        break;
                    case SwingArmLengths.SAC194:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmSpanCable.groupId, "393624-X", 1); // WIRE 11C-194' SAC-203'
                        break;
                    case SwingArmLengths.SAC213:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmSpanCable.groupId, "393625-X", 1); // WIRE 11C-213' SWG SSAC-222'
                        break;
                }
                break;
            case SACConductorOptions.SpanWire14C:
                switch (eSwingArmLength) {
                    case SwingArmLengths.SAC156:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmSpanCable.groupId, "393622-14", 1); // WIRE-14C-156' MSAC-166.5'
                        break;
                    case SwingArmLengths.SAC175:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmSpanCable.groupId, "393623-14", 1); // WIRE-14C-175' MSAC-185.5'
                        break;
                    case SwingArmLengths.SAC194:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmSpanCable.groupId, "393624-14", 1); // WIRE-14C-194' SAC-203'
                        break;
                    case SwingArmLengths.SAC213:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmSpanCable.groupId, "393625-14", 1); // WIRE-14C-213' SAC-222'
                        break;
                }
                break;
        }

        let endGun: EndGun = this.Quote.System.FlangedSide.EndOfSystem.EndGun;
        let hasEndGun: boolean = sysf.FieldSets(this.Quote.System).FlangedEndOfSystem.DataValid() && !(!endGun) && (endGun.EndGunTypePrimary !== EndGunTypes.None || endGun.EndGunTypeSecondary !== EndGunTypes.None);
        PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxHardwareKit.groupId, "395620", 1); // S-BOX RPM PANEL MOUNT/HARDWARE
        PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxHardwareKit.groupId, "395621", 1); // TWR BOX-RAIL MOUNT 4-BOX TWRS
        switch (sa.ZoneControlType) {
            case SACZoneControlTypes.ESAC060:
                if (sa.VRISACSpan) {
                    PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxValveKit.groupId, "353376", 1); // BOX-VALVE-VRI SAC KIT
                    if (sa.SprinklerValveType === SprinklerValveTypes.Poly) {
                        switch (eSwingArmLength) {
                            case SwingArmLengths.SAC156:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "351998", 1); // HARNESS-31-POLY SPR CTRL-MSAC
                                break;
                            case SwingArmLengths.SAC175:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "351999", 1); // HARNESS-37-POLY SPR CTRL-MSSAC
                                break;
                            case SwingArmLengths.SAC194:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "352000", 1); // HARNESS-47-POLY SPR CTRL-SAC
                                break;
                            case SwingArmLengths.SAC213:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "352001", 1); // HARNESS-55-POLY SPR CTRL-SSAC
                                break;
                            default:
                                throw new Error(`SAC length ${eSwingArmLength} not supported`);
                        }
                    } else {
                        switch (eSwingArmLength) {
                            case SwingArmLengths.SAC156:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354268", 1); // HARNESS-31-SPR CTRL-MSAC KIT
                                break;
                            case SwingArmLengths.SAC175:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354269", 1); // HARNESS-37-SPR CTRL-MSSAC KIT
                                break;
                            case SwingArmLengths.SAC194:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354270", 1); // HARNESS-47-SPR CTRL-SAC KIT
                                break;
                            case SwingArmLengths.SAC213:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354271", 1); // HARNESS-55-SPR CTRL-SSAC KIT
                                break;
                            default:
                                throw new Error(`SAC length ${eSwingArmLength} not supported`);
                        }
                    }
                }
                else {
                    if (hasEndGun) {
                        PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxValveKit.groupId, "353530", 1); // BOX-SAC VALVE ESAC
                    }
                    else {
                        PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxValveKit.groupId, "353509", 1); // BOX-SAC VALVE W/OUT ENDGUN
                    }
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmTowerBox.groupId, "351111", 1); // S-BOX RPM ESAC OPT
                    if (sa.SprinklerValveType === SprinklerValveTypes.Poly) {
                        switch (eSwingArmLength) {
                            case SwingArmLengths.SAC156:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354277", 1); // HARNESS-22-SPR CTRL POLY-MSAC
                                break;
                            case SwingArmLengths.SAC175:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354279", 1); // HARNESS-25-POLY SPR CTRL-MSSAC
                                break;
                            case SwingArmLengths.SAC194:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354283", 1); // HARNESS-30-POLY CTRL-SAC 114
                                break;
                            case SwingArmLengths.SAC213:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354281", 1); // HARNESS-34-POLY SPR CTRL-SSAC
                                break;
                            default:
                                throw new Error(`SAC length ${eSwingArmLength} not supported`);
                        }
                    } else {
                        switch (eSwingArmLength) {
                            case SwingArmLengths.SAC156:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354273", 1); // HARNESS-22-SPR CTRL-MSAC
                                break;
                            case SwingArmLengths.SAC175:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354274", 1); // HARNESS-25-SPR CTRL-MSSAC
                                break;
                            case SwingArmLengths.SAC194:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354275", 1); // HARNESS-30-SPR CTRL-SAC
                                break;
                            case SwingArmLengths.SAC213:
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354276", 1); // HARNESS-34-SPR CTRL-SSAC
                                break;
                            default:
                                throw new Error(`SAC length ${eSwingArmLength} not supported`);
                        }
                    }
                }

                if (uPipePackageOnSACResult.Required) {
                    switch (eSwingArmLength) {
                        case SwingArmLengths.SAC156:
                            // ROE-824 - U-Pipes for ESAC and VRI - Disable 156 and 175 temporarily
                            // PO.InsertRecord(POSections.SwingArm, POSprinklerHarness, 105, 1) '356890	DROP CLAMP KIT 6 ZONE E-MSAC
                            throw new Error("ROE-824 - Disabled");
                        case SwingArmLengths.SAC175:
                            // ROE-824 - U-Pipes for ESAC and VRI - Disable 156 and 175 temporarily
                            // PO.InsertRecord(POSections.SwingArm, POSprinklerHarness, 104, 1) '356889	DROP CLAMP KIT 6 ZONE E-MSSAC
                            throw new Error("ROE-824 - Disabled");
                        case SwingArmLengths.SAC194:
                            if (uPipePackageOnSACResult.RigidDropsOnSACResult.RigidDropMaterialType === RigidDropMaterialTypes.Galvanized) {
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356881", 1);  // CLAMP KT-6 ZN U-PIPE G ESAC
                            } else {
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356882", 1);  // CLAMP KT-6 ZN U-PIPE P ESAC
                            }
                            break;
                        case SwingArmLengths.SAC213:
                            if (uPipePackageOnSACResult.RigidDropsOnSACResult.RigidDropMaterialType === RigidDropMaterialTypes.Galvanized) {
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356883", 1);  // CLAMP KT-6 ZN U-PIPE G ESSAC
                            } else {
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356884", 1);  // CLAMP KT-6 ZN U-PIPE P ESSAC
                            }
                            break;
                        default:
                            throw new Error(`Invalid selection '${eSwingArmLength}'`);
                    }
                }

                break;
            case SACZoneControlTypes.ESAC120:
            case SACZoneControlTypes.ESAC125:
                if (sa.VRISACSpan) {
                    PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxValveKit.groupId, "353376", 1); // BOX-VALVE-VRI SAC KIT
                }
                else {
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmTowerBox.groupId, "351111", 1); // S-BOX RPM ESAC OPT
                    PO.InsertPart(schema.groups.SwingArmCorner_SwingArmTowerBox.groupId, "351112", 1); // S BOX RPM ESAC SLAVE OPT
                    if (hasEndGun) {
                        PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxValveKit.groupId, "353530", 1); // BOX-SAC VALVE ESAC
                    }
                    else {
                        PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxValveKit.groupId, "353509", 1); // BOX-SAC VALVE W/OUT ENDGUN
                    }
                    PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxValveKit.groupId, "353509", 1); // BOX-SAC VALVE W/OUT ENDGUN
                }

                if (sa.SprinklerValveType === SprinklerValveTypes.Poly) {
                    switch (eSwingArmLength) {
                        case SwingArmLengths.SAC156:
                            PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "351998", 1); // HARNESS-31-POLY SPR CTRL-MSAC
                            break;
                        case SwingArmLengths.SAC175:
                            PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "351999", 1); // HARNESS-37-POLY SPR CTRL-MSSAC
                            break;
                        case SwingArmLengths.SAC194:
                            PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "352000", 1); // HARNESS-47-POLY SPR CTRL-SAC
                            break;
                        case SwingArmLengths.SAC213:
                            PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "352001", 1); // HARNESS-55-POLY SPR CTRL-SSAC
                            break;
                        default:
                            throw new Error(`SAC length ${eSwingArmLength} not supported`);
                    }
                } else {
                    switch (eSwingArmLength) {
                        case SwingArmLengths.SAC156:
                            PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354268", 1); // HARNESS-31-SPR CTRL-MSAC KIT
                            break;
                        case SwingArmLengths.SAC175:
                            PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354269", 1); // HARNESS-37-SPR CTRL-MSSAC KIT
                            break;
                        case SwingArmLengths.SAC194:
                            PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354270", 1); // HARNESS-47-SPR CTRL-SAC KIT
                            break;
                        case SwingArmLengths.SAC213:
                            PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "354271", 1); // HARNESS-55-SPR CTRL-SSAC KIT
                            break;
                        default:
                            throw new Error(`SAC length ${eSwingArmLength} not supported`);
                    }
                }


                if (uPipePackageOnSACResult.Required) {
                    switch (eSwingArmLength) {
                        case SwingArmLengths.SAC156:
                            // ROE-824 - U-Pipes for ESAC and VRI - Disable 156 and 175 temporarily
                            // PO.InsertRecord(POSections.SwingArm, POSprinklerHarness, 106, 1) '356891	DROP CLAMP KIT 12 ZONE E-MSAC
                            throw new Error("ROE-824 - Disabled");
                        case SwingArmLengths.SAC175:
                            // ROE-824 - U-Pipes for ESAC and VRI - Disable 156 and 175 temporarily
                            // PO.InsertRecord(POSections.SwingArm, POSprinklerHarness, 107, 1) '356892	DROP CLAMP KIT 12 ZONE E-MSSAC
                            throw new Error("ROE-824 - Disabled");
                        case SwingArmLengths.SAC194:
                            if (uPipePackageOnSACResult.RigidDropsOnSACResult.RigidDropMaterialType === RigidDropMaterialTypes.Galvanized) {
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356877", 1);  // CLAMP KT-12 ZN U-PIPE G ESAC
                            } else {
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356878", 1);  // CLAMP KT-12 ZN U-PIPE P ESAC
                            }
                            break;
                        case SwingArmLengths.SAC213:
                            if (uPipePackageOnSACResult.RigidDropsOnSACResult.RigidDropMaterialType === RigidDropMaterialTypes.Galvanized) {
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356879", 1);  // CLAMP KT-12 ZN U-PIPE G ESSAC
                            } else {
                                PO.InsertPart(schema.groups.SwingArmCorner_SprinklerControlHarness.groupId, "356880", 1);  // CLAMP KT-12 ZN U-PIPE P ESSAC
                            }
                            break;
                        default:
                            throw new Error(`Invalid selection '${eSwingArmLength}'`);
                    }
                }

                break;
            default:
                console.error(`SAC zone control type ${sa.ZoneControlType} not supported`);
                break;
        }
        switch (eTowerType) {
            case TowerHeights.Standard:
                switch (iCenterDrive) {
                    case GearDriveTypes.Standard:
                        PO.InsertPart(schema.groups.SwingArmCorner_CenterGearAssembly.groupId, "392495", 1); // HELICAL GR STD SPD SAC ESAC
                        break;
                    case GearDriveTypes.High:
                        PO.InsertPart(schema.groups.SwingArmCorner_CenterGearAssembly.groupId, "392483", 1); // HELICAL GR HI SPD SAC ESAC
                        break;
                }
                break;
            case TowerHeights.Sugargator:
                switch (iCenterDrive) {
                    case GearDriveTypes.Standard:
                        PO.InsertPart(schema.groups.SwingArmCorner_CenterGearAssembly.groupId, "392497", 1); // GEARDRV-SG SAC STD SPD EII ESA
                        break;
                    case GearDriveTypes.High:
                        PO.InsertPart(schema.groups.SwingArmCorner_CenterGearAssembly.groupId, "392496", 1); // GEARDRV-SG SAC HI SPD EII ESAC
                        break;
                }
                break;
        }
        if (eCouplerType === CouplerTypes.CXCoupler) {
            switch (iCenterDrive) {
                case GearDriveTypes.Standard:
                    PO.InsertPart(schema.groups.SwingArmCorner_WheelGearSACOption.groupId, "352036", 1); // GEAR OPT-NT-765 C-X PLR SAC
                    break;
                case GearDriveTypes.High:
                    PO.InsertPart(schema.groups.SwingArmCorner_WheelGearSACOption.groupId, "352036", 1); // GEAR OPT-NT-765 C-X PLR SAC
                    break;
            }
        }
        else {
            switch (iCenterDrive) {
                case GearDriveTypes.Standard:
                    PO.InsertPart(schema.groups.SwingArmCorner_WheelGearSACOption.groupId, "352038", 1); // GEAR OPT-NT 765 SAC
                    break;
                case GearDriveTypes.High:
                    PO.InsertPart(schema.groups.SwingArmCorner_WheelGearSACOption.groupId, "352038", 1); // GEAR OPT-NT 765 SAC
                    break;
            }
        }
        switch (eSwingArmLength) {
            case SwingArmLengths.SAC156:
                switch (sa.PipeCoating) {
                    case SACPipeTypes.Galvanized:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396950", 1); // MSAC-156/51-E2060
                        break;
                    case SACPipeTypes.GalvanizedCNPlus:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396952", 1); // MSAC-156/51-E2660G
                        break;
                    case SACPipeTypes.PaintedCNPlus:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396951", 1); // MSAC-156/51-E2660
                        break;
                }
                break;
            case SwingArmLengths.SAC175:
                switch (sa.PipeCoating) {
                    case SACPipeTypes.Galvanized:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396953", 1); // MSSAC-175/61-E2060
                        break;
                    case SACPipeTypes.GalvanizedCNPlus:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396955", 1); // MSSAC-175/61-E2660G
                        break;
                    case SACPipeTypes.PaintedCNPlus:
                        PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396954", 1); // MSSAC-175/61-E2660
                        break;
                }
                break;
            case SwingArmLengths.SAC194:
                switch (eTowerType) {
                    case TowerHeights.Standard:
                        switch (sa.PipeCoating) {
                            case SACPipeTypes.Galvanized:
                                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396923-G", 1); // SAC-194/85-57- 6-EII-S TWR-G
                                break;
                            case SACPipeTypes.GalvanizedCNPlus:
                                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "397409", 1); // SAC-194/85-57 STWR E2660G
                                break;
                            case SACPipeTypes.PaintedCNPlus:
                                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396924-G", 1); // SAC-194/85-57-6-CT-EII-S TWR-G
                                break;
                        }
                        break;
                    case TowerHeights.Sugargator:
                        switch (sa.PipeCoating) {
                            case SACPipeTypes.Galvanized:
                                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396937", 1); // SAC E2060 W/S-TWR SG
                                break;
                            case SACPipeTypes.GalvanizedCNPlus:
                                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396942", 1); // SAC-E2660G W/S-TWR SG
                                break;
                            case SACPipeTypes.PaintedCNPlus:
                                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396938", 1); // SAC E2660 W/S-TWR SG
                                break;
                        }
                        break;
                }
                break;
            case SwingArmLengths.SAC213:
                switch (eTowerType) {
                    case TowerHeights.Standard:
                        switch (sa.PipeCoating) {
                            case SACPipeTypes.Galvanized:
                                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396931-G", 1); // SSAC-213/105-57-6-EII-STWR-G
                                break;
                            case SACPipeTypes.GalvanizedCNPlus:
                                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "397408", 1); // SSAC-213/105-57 STWR E2660G
                                break;
                            case SACPipeTypes.PaintedCNPlus:
                                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396932-G", 1); // SSAC-213/105-57-6-CT-EII-STWR
                                break;
                        }
                        break;
                    case TowerHeights.Sugargator:
                        switch (sa.PipeCoating) {
                            case SACPipeTypes.Galvanized:
                                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396939", 1); // SSAC E2060 W/S-TWR SG
                                break;
                            case SACPipeTypes.GalvanizedCNPlus:
                                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396941", 1); // SSAC E2660G W/S-TWR SG
                                break;
                            case SACPipeTypes.PaintedCNPlus:
                                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmCornerAssembly.groupId, "396940", 1); // SSAC E2660 W/S-TWR SG
                                break;
                        }
                        break;
                }
                break;
        }
        if (!(sysf.IsEDMP(this.Quote.System))) {
            switch (eSwingArmLength) {
                case SwingArmLengths.SAC156:
                    if (bTheftDeterrentClips) {
                        if (bWithoutSpring) {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397643", 1); // CONDUIT TDC COUPLERS 156'-57"
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397609", 1); // CONDUIT TDC PKG 156'-57"
                        }
                        PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397661", 1); // CONDUIT TDC PKG 51' EB 57"
                    }
                    else {
                        if (bWithoutSpring) {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397275", 1); // CONDUIT CLP COUPLERS 156'-57"
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397217", 1); // CONDUIT CLIP PKG 156'-57"
                        }
                        PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397293", 1); // CONDUIT CLIP PKG 51' EB 57"
                    }
                    break;
                case SwingArmLengths.SAC175:
                    if (bTheftDeterrentClips) {
                        if (bWithoutSpring) {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397645", 1); // CONDUIT TDC COUPLERS 175'-57"
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397613", 1); // CONDUIT TDC PKG 175'-57"
                        }
                        PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397663", 1); // CONDUIT TDC PKG 61' EB 57"
                    }
                    else {
                        if (bWithoutSpring) {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397277", 1); // CONDUIT CLP COUPLERS 175'-57"
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397224", 1); // CONDUIT CLIP PKG 175'-57"
                        }
                        PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397295", 1); // CONDUIT CLIP PKG 61' EB 57"
                    }
                    break;
                case SwingArmLengths.SAC194:
                    if (bTheftDeterrentClips) {
                        if (bWithoutSpring) {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397646", 1); // CONDUIT TDC COUPLERS 194'-57"
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397617", 1); // CONDUIT TDC PKG 194'-57"
                        }
                        PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397664", 1); // CONDUIT TDC PKG 85' EB 57"
                    }
                    else {
                        if (bWithoutSpring) {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397278", 1); // CONDUIT CLP COUPLERS 194'-57"
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397230", 1); // CONDUIT CLIP PKG 194'-57"
                        }
                        PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397296", 1); // CONDUIT CLIP PKG 85' EB 57"
                    }
                    break;
                case SwingArmLengths.SAC213:
                    if (bTheftDeterrentClips) {
                        if (bWithoutSpring) {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397647", 1); // CONDUIT TDC COUPLERS 213'-57"
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397620", 1); // CONDUIT TDC PKG 213'-57"
                        }
                        PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397665", 1); // CONDUIT TDC PKG 105' EB 57"
                    }
                    else {
                        if (bWithoutSpring) {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397279", 1); // CONDUIT CLP COUPLERS 213'-57"
                        }
                        else {
                            PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397234", 1); // CONDUIT CLIP PKG 213'-57"
                        }
                        PO.InsertPart(schema.groups.SwingArmCorner_ConduitClips.groupId, "397297", 1); // CONDUIT CLIP PKG 105' EB 57"
                    }
                    break;
            }
        }
        if (eTowerType === TowerHeights.Sugargator) {
            if (sa.EnergySaverPackage) {
                PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxHardwareKit.groupId, "352859", 1); // SAC TWR HARDWARE SG ESP
            }
            else {
                PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxHardwareKit.groupId, "352858", 1); // SAC TWR HARDWARE SG
            }
        }
        else {
            if (sa.EnergySaverPackage) {
                PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxHardwareKit.groupId, "352855", 1); // SAC TWR HARDWARE ESP
            }
            else {
                PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxHardwareKit.groupId, "352854", 1); // SAC TWR HARDWARE
            }
        }
        if (sa.LeadingSwingArm) {
            PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxLeadingSACKit.groupId, "352853", 1); // S BOX RPM STEER BOX/HDW LEAD
        }
        else {
            PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxLeadingSACKit.groupId, "352852", 1); // S BOX RPM STEER/LIMIT BOX/HDW
        }
        PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceAntenna.groupId, "357042", 1); // SAC GPS ANT OPTION-TRIMBLE
        switch (sa.GPSBase) {
            case SACGPSGuidanceBaseOptions.CoaxBase:
                PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "357002", 1); // GPS BASE OPTION
                break;
            case SACGPSGuidanceBaseOptions.RadioBase:
                PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "357002", 1); // GPS BASE OPTION
                switch (this.Quote.System.DealerProperties.Country) {
                    case Countries.UnitedStates:
                    case Countries.Australia:
                        PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "355420", 1); // RADIO KIT SAC MINI MM2
                        break;
                    case Countries.NewZealand:
                        PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "355417NZ", 1); // RAD/ANT SAC GPS BASE-NO RADIO
                        break;
                    default:
                        break;
                }
                break;
            case SACGPSGuidanceBaseOptions.SingleSystemFreewaveRadioBase:
                PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "357001", 1); // GPS BASE OPTION-RADIO ONLY
                PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "351109", 1); // S BOX RPM RADIO GPS GUID OPT
                PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "351109A", 1); // S BOX RPM RADIO HARDWARE
                break;
            case SACGPSGuidanceBaseOptions.NoBaseFreewave:
                switch (this.Quote.System.DealerProperties.Country) {
                    case Countries.UnitedStates:
                        PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "351109", 1); // S BOX RPM RADIO GPS GUID OPT
                        PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "351109A", 1); // S BOX RPM RADIO HARDWARE
                        break;
                    case Countries.Australia:
                        PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "351109", 1); // S BOX RPM RADIO GPS GUID OPT
                        PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "351109A", 1); // S BOX RPM RADIO HARDWARE
                        break;
                    case Countries.NewZealand:
                        PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "351109NZ", 1); // S BOX RPM NO RADIO GPS GUIDOPT
                        PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "351109A", 1); // S BOX RPM RADIO HARDWARE
                        break;
                    default:
                        break;
                }
                if (sa.GPSBaseConvert) {
                    switch (this.Quote.System.DealerProperties.Country) {
                        case Countries.UnitedStates:
                            PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "355417", 1); // RAD/ANT SAC GPS BASE FGR-2
                            break;
                        case Countries.Australia:
                            PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "355417", 1); // RAD/ANT SAC GPS BASE FGR-2
                            break;
                        case Countries.NewZealand:
                            PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "355417NZ", 1); // RAD/ANT SAC GPS BASE-NO RADIO
                            break;
                        default:
                            break;
                    }
                }
                break;
            case SACGPSGuidanceBaseOptions.NoBase:
                switch (this.Quote.System.DealerProperties.Country) {
                    case Countries.UnitedStates:
                    case Countries.Australia:
                        PO.InsertPart(schema.groups.SwingArmCorner_SACGuidanceBase.groupId, "355414", 1); // RADIO/ANT KIT - S BOX - GPS
                        break;
                    case Countries.NewZealand:
                        break;
                    default:
                        break;
                }
                break;
            case SACGPSGuidanceBaseOptions.NoBaseNonReinke:
                PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxGuidanceOption.groupId, "351110", 1); // S BOX RPM GPS SCRAMBLED NO OPT
                break;
        }
        if (sa.GPSBase !== SACGPSGuidanceBaseOptions.NoBaseNonReinke) {
            PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxGuidanceOption.groupId, "351104", 1); // S-BOX RPM GPS GUIDANCE NO OPT
        }
        if (sa.ExtraGPSDataCable) {
            PO.InsertPart(schema.groups.SwingArmCorner_ExtraGPSDataCable.groupId, "357020", 1); // CABLE 2 PAIR GPS SAC-2000' SPL
        }
        if (sa.SteeringMotorCover || (this.Quote.System.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && this.Quote.System.TowerProperties.UMCCoverKit)) {
            PO.InsertPart(schema.groups.SwingArmCorner_SteeringMotorCover.groupId, "352465", 1); // COVER KIT-UMC PS CENTER DRIVE
        }
        let iSelectedTire: number;
        let t: Tires = sa.Tires;
        iSelectedTire = TireSelector(t.TireSize, t.TireType, t.OneTireMountedInReverse, t.TubesForTires, false, false, true);
        PO.InsertPartByIteration(schema.groups.SwingArmCorner_TireandWheelAssembly.groupId, iSelectedTire, 1);
        if (sa.AirCompressor) {
            if (this.Quote.System.FlangedSide.EndOfSystem.EndGun.BoosterPump === BoosterPumpTypes.None) {
                PO.InsertPart(schema.groups.SwingArmCorner_SwingArmTowerBox.groupId, "351108", 1); // S BOX RPM AIR/BP RELAY OPT
            }
            PO.InsertPart(schema.groups.SwingArmCorner_SACSBoxValveKit.groupId, "351105", 1); // S BOX RPM AIR COMPRESSOR OPT
            PO.InsertPart(schema.groups.SwingArmCorner_AirCompressor.groupId, "352936", 1); // AIR COMP-OPT-JENNY- ESAC
        }
        if (sa.ControlLineBooster) {
            PO.InsertPart(schema.groups.SwingArmCorner_ControlLineBoosterPump.groupId, "352655", 1); // S BOX RPM CONTROL LINE-BP OPT
        }
        if (sa.ControlLineBoosterESP) {
            PO.InsertPart(schema.groups.SwingArmCorner_ControlLineBoosterPumpESP.groupId, "352614", 1); // CONTROL LINE BOOST PUMP F/ESP
        }
    }
}