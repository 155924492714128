import MapboxGeocoder, { Result } from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import * as React from "react";
import { FC, useEffect, useRef } from "react";
import DevSettingsCtx from "../db/DevSettingsCtx";
import { formatLatLn } from "../docGeneration/DocumentGenerationHelpers";
import { Position } from "../model/geoTypes";
import { EDisplayCoordinate } from "./DealerSettingsDialog/EDisplayCoordinate";

interface Props {
    onGeocoderResult: (point: Position) => any;
    defaultAddress?: string;
    defaultPosition?: Position;
}

interface ILatLng { lat: number, lng: number };
const tryGetLatLngGeneric = (query: string): ILatLng[] => {
    // Match anything which looks like
    // decimal degrees coordinate pair.
    // const matches = query.match(
    //     /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
    // );
    const regex1 = /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i;
    let matches = query.match(regex1);
    if (!matches) {
        return [];
    }
     
    const coord1 = Number(matches[1]);
    const coord2 = Number(matches[2]);
    const geocodes: ILatLng[] = [];
     
    if (coord1 < -90 || coord1 > 90) {
        // must be lng, lat
        geocodes.push({ lng: coord1, lat: coord2 });
    }
     
    if (coord2 < -90 || coord2 > 90) {
        // must be lat, lng
        geocodes.push({ lat: coord1, lng: coord2 });
    }
     
    if (geocodes.length === 0) {
        // else could be either lng, lat or lat, lng
        geocodes.push({ lng: coord1, lat: coord2 });
        geocodes.push({ lat: coord1, lng: coord2 });
    }
    return geocodes;
}
const tryGetLatLngRdpDecimal = (query: string): ILatLng[] => {
    const regex1 = /^[ ]*(\d+\.?\d*)[ °]*([N,S])[ ]*(\d+\.?\d*)[ °]*([W,E])[ ]*$/i;
    let matches = query.match(regex1);
    if (!matches) {
        return [];
    }
     
    const lat = Number(matches[1]);
    const latDir = matches[2] as "N" | "S";
    const lng = Number(matches[3]);
    const lngDir = matches[4] as "E" | "W";

    return [
        {
            lat: lat * (latDir === "N" ? 1 : -1),
            lng: lng * (lngDir === "E" ? 1 : -1)
        }
    ]
}
const tryGetLatLngRdpDms = (query: string): ILatLng[] => {
    const regex1 = /^[ ]*(\d+)[ °]*(\d+)[ ']*(\d+\.?\d*)[ "]*([N,S])[ ]*(\d+)[ °]*(\d+)[ ']*(\d+\.?\d*)[ "]*([W,E])$/i;
    let matches = query.match(regex1);
    if (!matches) {
        return [];
    }
     
    const latD = Number(matches[1]);
    const latM = Number(matches[2]);
    const latS = Number(matches[3]);
    const latDir = matches[4] as "N" | "S";
    const lngD = Number(matches[5]);
    const lngM = Number(matches[6]);
    const lngS = Number(matches[7]);
    const lngDir = matches[8] as "E" | "W";

    const lat = latD + latM / 60 + latS/3600;
    const lng = lngD + lngM / 60 + lngS/3600;
    return [
        {
            lat: lat * (latDir === "N" ? 1 : -1),
            lng: lng * (lngDir === "E" ? 1 : -1)
        }
    ]
}

/* Given a query in the form "lng, lat" or "lat, lng"
* returns the matching geographic coordinate(s)
* as search results in carmen geojson format,
* https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */
const coordinatesGeocoder = function (query: string, displayCoordinate: EDisplayCoordinate) {
    // Match anything which looks like
    // decimal degrees coordinate pair.
    // const matches = query.match(
    //     /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
    // );

    let coords = tryGetLatLngGeneric(query);
    if (!coords.length) {
        coords = tryGetLatLngRdpDecimal(query);
    }
    if (!coords.length) {
        coords = tryGetLatLngRdpDms(query);
    }
    if (!coords.length) {
        return null;
    }

    // expect -90 <= lat <= 90 : -180 <= lng <= 180
    coords = coords.filter(c => {
        if (c.lat > 90 || c.lat < -90) return false;
        if (c.lng > 180 || c.lng < -180) return false;
        return true;
    })
    if (!coords.length) {
        return null;
    }
     
    function coordinateFeature(lng: number, lat: number) {
        const ppf = formatLatLn(lat, lng, displayCoordinate);
        const place_name = `${ppf.latPart} ${ppf.lonPart}`;
        return {
            center: [lng, lat],
            geometry: {
            type: 'Point',
            coordinates: [lng, lat]
            },
            place_name: place_name,
            place_type: ['coordinate'],
            properties: {},
            type: 'Feature'
        };
    }
     
    return coords.map(x => coordinateFeature(x.lng, x.lat));
};

const ReactMapboxGeocoder: FC<Props> = ({onGeocoderResult, defaultAddress, defaultPosition}) => {
    const container: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
    const geocoder: React.MutableRefObject<MapboxGeocoder | null> = useRef(null);
    const devSettingsState = React.useContext(DevSettingsCtx);

    useEffect(() => {
        if (geocoder.current) return; // initialize only once
        geocoder.current = new MapboxGeocoder({
            accessToken: "pk.eyJ1IjoiaWNvbnNvZnR3YXJlIiwiYSI6ImNsYXV6MnExZTAwajQzcWxpcHdjanZxYTgifQ.R0vQipvh4nMVTsNezSlamg",
            localGeocoder: (q: string) => coordinatesGeocoder(q, devSettingsState.dealerSettings.display.current.coordinates),
            reverseGeocode: true
        });
        geocoder.current.addTo(container.current!);
        geocoder.current.on("result", ({ result }: { result: Result }) => {
            onGeocoderResult(result.center as Position);
        });

        if (defaultAddress){
            geocoder.current.query(defaultAddress);
            //TODO: close menu after (this is proving to be a bit tricky!)
        }

        if (defaultPosition){
            onGeocoderResult(defaultPosition);
        }
    });

    return (
        <span ref={container}>
        </span>
    );
}

export default ReactMapboxGeocoder;