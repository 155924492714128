import { MenuItem } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import IDbProject from "../../../../../../db/IDbProject";
import { ImportExportFeature } from "../../../../../../helpers/importExport";
import { projectToImportExportFeatures } from "./projectToImportExportFeatures";

interface IProps {
    dbPrj: IDbProject;
    layoutId: string;
    onClick: (features: ImportExportFeature[]) => void;
}

const ExportMenuItem: React.FC<IProps> = ({ 
    dbPrj, layoutId, onClick
}) => {
    
    const handleMenuItemClick = () => {
        const features = projectToImportExportFeatures({ project: dbPrj.state, layoutId });
        onClick(features);
    }

    return (
        <>
            <MenuItem
                component="label"
                onClick={handleMenuItemClick}
            >
                {i18next.format(t('export'), 'capitalize')}
            </MenuItem>
        </>
    )
}


export default ExportMenuItem;