import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Divider, IconButton, Menu, Tooltip } from "@mui/material";
import i18next from "i18next";
import IDbGrower from "rdptypes/api/IGrowerBase";
import * as React from "react";
import { FC, useState } from "react";
import IDbProject from "../../../../../db/IDbProject";
import { ImportExportFeature } from '../../../../../helpers/importExport';
import ExportDialog from "./Export/ExportDialog";
import ExportMenuItem from "./Export/ExportMenuItem";
import ImportDialog from "./Import/ImportDialog";
import ImportMenuItem from "./Import/ImportMenuItem";
import ShareFieldDialog from "./Share";
import ShareFieldMenuItem from "./Share/ShareFieldMenuItem";
import { ImportExportProject } from "./common/interfaces";

interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    grower: IDbGrower;
    projectId: string;
}

const ImportMenu: FC<Props> = ({
    dbPrj, layoutId, grower, projectId
}) => {

    const [importExportMenuTarget, setImportExportMenuTarget] = React.useState<null | HTMLElement>(null);
    const [importFeatures, setImportFeatures] = useState<ImportExportFeature[] | undefined>(undefined);
    const [exportFeatures, setExportFeatures] = useState<ImportExportFeature[] | undefined>(undefined);
    const [shareField, setShareField] = useState(false);

    const openImportExportMenu = (event: React.MouseEvent<HTMLElement>) => {
        setImportExportMenuTarget(event.currentTarget);
    };
    const closeImportExportMenu = () => {
        setImportExportMenuTarget(null);
    };

    const handleOnImportMenuClick = (ieProject: ImportExportProject) => {
        setImportFeatures(ieProject.features);
        closeImportExportMenu();
    }
    const handleOnImportMenuCancel = () => {
        setImportFeatures(undefined);
        closeImportExportMenu();
    }
    const handleOnImportDialogClose = () => {
        setImportFeatures(undefined);
        closeImportExportMenu();
    }
    
    const handleOnExportMenuClick = (features: ImportExportFeature[]) => {
        setExportFeatures(features);
        closeImportExportMenu();
    }
    const handleOnExportDialogClose = () => {
        setExportFeatures(undefined);
        closeImportExportMenu();
    }
    
    const handleOnShareFieldMenuClick = () => {
        setShareField(true);
        closeImportExportMenu();
    }
    const handleOnShareFieldDialogClose = () => {
        setShareField(false);
        closeImportExportMenu();
    }

    return (
        <>
            <Tooltip title={i18next.format(`${i18next.t("import")}/${i18next.t("export")}`, 'capitalize-each')} placement="bottom">
                <IconButton
                    size="large"
                    aria-label="import/export"
                    aria-haspopup="true"
                    onClick={openImportExportMenu}
                    color="inherit"
                >
                    <ImportExportIcon />
                </IconButton>
            </Tooltip>

            <Menu
                anchorEl={importExportMenuTarget}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={!!importExportMenuTarget}
                onClose={closeImportExportMenu}
            >
                <ImportMenuItem
                    onClick={handleOnImportMenuClick}
                    onCancel={handleOnImportMenuCancel}
                />
                <ExportMenuItem
                    dbPrj={dbPrj}
                    layoutId={layoutId}
                    onClick={handleOnExportMenuClick}
                />
                <Divider />
                <ShareFieldMenuItem
                    dbPrj={dbPrj}
                    layoutId={layoutId}
                    onClick={handleOnShareFieldMenuClick}
                />
            </Menu>
            
            {
                importFeatures && (
                    <ImportDialog 
                        features={importFeatures} 
                        onClose={handleOnImportDialogClose}
                        dbPrj={dbPrj}
                        layoutId={layoutId}
                    />
                )
            }
            
            {
                exportFeatures && (
                    <ExportDialog 
                        features={exportFeatures} 
                        onClose={handleOnExportDialogClose}
                        dbPrj={dbPrj}
                        layoutId={layoutId}
                        grower={grower}
                        projectId={projectId}
                    />
                )
            }
            
            {
                shareField && (
                    <ShareFieldDialog 
                        onConfirm={handleOnShareFieldDialogClose}
                        onCancel={handleOnShareFieldDialogClose}
                    />
                )
            }


        </>
    );
};

export default ImportMenu;