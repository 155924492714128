import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { v4 as uuidv4 } from 'uuid';
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange } from './helpers';

const actionTypeId = "NewLayout";

interface ActionData extends IActionData {
    layoutId: string;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;

    let newLayoutNumber = 0;
    let newLayoutName: string;
    do {
        newLayoutNumber++;
        newLayoutName = `Layout ${newLayoutNumber}`;
    } while (Object.values(state.layouts).some(x => x.name === newLayoutName));

    state.layouts[data.layoutId] = {
        name: newLayoutName,

        systems: {},

        obstacles: [],
        annotations: [],
        wheelObstacles: [],
        measurements: [],

        lastModifiedTimestamp: 0, // Updated to the actual timestamp in recordLayoutChange
        lastModifiedUserId: "", // Updated to the actual user ID in recordLayoutChange
    };
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
});

export const createNewLayoutAction = (authState: IAuthState) => {
    const layoutId = uuidv4();
    const action = createAction(
        actionTypeId,
        {
            layoutId
        } as ActionData,
        authState);
    return {
        layoutId,
        action
    };
};