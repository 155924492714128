import {
    CSSObject,
    Divider,
    Drawer,
    IconButton,
    Stack,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";
import * as React from "react";
import { useContext, useEffect, useState } from "react";

import SystemDesignIcon from '@mui/icons-material/AttachMoney';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import i18next, { t } from "i18next";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { GeometryCtx } from "../../../../GeometryHelpers/GeometryProvider";
import { SystemValidity } from "../../../../GeometryHelpers/SystemGeometryHelpers/interfaces";
import AuthCtx from "../../../../auth/AuthCtx";
import { isCenterPivot } from "../../../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import IDbProject from "../../../../db/IDbProject";
import ISystem from "../../../../model/project/ISystem";
import IDrawerTab from "./IDrawerTab";
import SystemInformationCard from "./SystemInformationCard";
import centerPivotTab from "./centerPivotTab";
import endOfSystemTab from "./endOfSystemTab";
import lateralTab from "./lateralTab";
import spansAndTowersTab from "./spansAndTowersTab";
import warningsTab, { hasWarnings } from "./warningsTab";

const LEFT_DRAWER_WIDTH_MINI = 48;
const LEFT_DRAWER_WIDTH_FULL = 450;

const leftDrawerOpenedFullMixin = (theme: Theme): CSSObject => ({
    width: LEFT_DRAWER_WIDTH_FULL,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});
const leftDrawerBottomOpenedMixin = (
    theme: Theme,
    bottomDrawerHeight: number
): CSSObject => ({
    height: `calc(100% - ${bottomDrawerHeight}px)`,
    transition: theme.transitions.create("height", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
});
const leftDrawerOpenedFullAndBottomDrawerOpenMixin = (
    theme: Theme,
    bottomDrawerHeight: number
): CSSObject => ({
    width: LEFT_DRAWER_WIDTH_FULL,
    height: `calc(100% - ${bottomDrawerHeight}px)`,
    transition: theme.transitions.create(["height", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const StyledDrawer = styled(Drawer, {
    shouldForwardProp: (prop) =>
        prop !== "bottomDrawerOpen" &&
        prop !== "drawerWidth" &&
        prop !== "bottomDrawerHeight",
})<{
    bottomDrawerOpen: boolean;
    drawerWidth: "mini" | "full";
    bottomDrawerHeight: number;
}>(({ theme, bottomDrawerOpen, drawerWidth, bottomDrawerHeight }) => ({
    ...(bottomDrawerOpen &&
        drawerWidth === "full" && {
            ...leftDrawerOpenedFullAndBottomDrawerOpenMixin(
                theme,
                bottomDrawerHeight
            ),
            "& .MuiDrawer-paper": leftDrawerOpenedFullAndBottomDrawerOpenMixin(
                theme,
                bottomDrawerHeight
            ),
        }),
    ...(bottomDrawerOpen &&
        drawerWidth !== "full" && {
            ...leftDrawerBottomOpenedMixin(theme, bottomDrawerHeight),
            "& .MuiDrawer-paper": leftDrawerBottomOpenedMixin(
                theme,
                bottomDrawerHeight
            ),
        }),
    ...(!bottomDrawerOpen &&
        drawerWidth === "full" && {
            ...leftDrawerOpenedFullMixin(theme),
            "& .MuiDrawer-paper": leftDrawerOpenedFullMixin(theme),
        }),
}));

const createDrawerTabs = (system: ISystem): IDrawerTab[] => {
    const tabs: IDrawerTab[] = [];
    if (!system) return tabs;
    if (!system.proposalGenerated) {
        if (system.SystemProperties?.SystemType === SystemTypes.CenterPivot) {
            tabs.push(centerPivotTab);
        }
        else {
            tabs.push(lateralTab);
        }
        tabs.push(
            spansAndTowersTab,
            endOfSystemTab
        );
    }
    tabs.push(warningsTab);
    return tabs;
}

interface IProps {
    open: boolean;
    isBottomDrawerOpen: boolean;
    navigateToSystemDesign: () => void;
    setLeftDrawerWidth: (width: number) => void;
    bottomDrawerHeight: number;

    systemId?: string;
    layoutId: string;
    dbPrj: IDbProject;
    projectId: string;
}

const LeftDrawer: React.FC<IProps> = ({
    open,
    isBottomDrawerOpen,
    navigateToSystemDesign,
    setLeftDrawerWidth,
    bottomDrawerHeight,
    systemId,
    layoutId,
    dbPrj,
    projectId
}) => {
    const authState = useContext(AuthCtx);
    const geometryCtx = useContext(GeometryCtx);
    const [selectedLeftDrawerTabIndex, setSelectedLeftDrawerTabIndex] =
        useState<undefined | number>(
            () => {
                const system = dbPrj.state.layouts[layoutId]?.systems[systemId];
                let tabIndex = 0;
                if (system && isCenterPivot(system) && system.FlangedSide.Span.length === 0) {
                    tabIndex = 1; // Spans and towers index
                }
                return tabIndex;
            }
        );
    const systemValidity: SystemValidity = geometryCtx.system.geometryHelpers[systemId]?.systemValidity ?? "unknown";

    useEffect(() => {
        if (!open) {
            setLeftDrawerWidth(0);
        } else {
            setLeftDrawerWidth(
                selectedLeftDrawerTabIndex === undefined
                    ? LEFT_DRAWER_WIDTH_MINI
                    : LEFT_DRAWER_WIDTH_FULL
            );
        }
    }, [open, selectedLeftDrawerTabIndex]);

    const leftDrawerTabs = createDrawerTabs(dbPrj.state.layouts[layoutId]?.systems[systemId]);
        
    const TabBody = systemId && (selectedLeftDrawerTabIndex !== undefined) 
        ? leftDrawerTabs[selectedLeftDrawerTabIndex].component
        : null;

    return (
        <StyledDrawer
            variant="persistent"
            anchor={"left"}
            open={open}
            bottomDrawerOpen={isBottomDrawerOpen}
            drawerWidth={
                selectedLeftDrawerTabIndex === undefined ? "mini" : "full"
            }
            bottomDrawerHeight={bottomDrawerHeight}
        >
            <Toolbar variant="dense" />

            <Stack 
                direction={"row"} 
                flexGrow={1} 
                overflow={'hidden'} 
            >
                <Stack direction={"column"}>
                    {leftDrawerTabs.map((ldt, idx) => {
                        return (
                            <Tooltip
                                key={ldt.getTitle()}
                                title={ldt.getTitle()}
                                placement="right"
                            >
                                <IconButton
                                    color={(idx === leftDrawerTabs.length - 1 && hasWarnings({ dbPrj, layoutId, systemId }))
                                        ? "warning"
                                        : "inherit"
                                    }
                                    aria-label={ldt.getTitle()}
                                    component="label"
                                    onClick={() =>
                                        setSelectedLeftDrawerTabIndex(idx)
                                    }
                                >
                                    {ldt.icon}
                                </IconButton>
                            </Tooltip>
                        );
                    })}

                    <Divider />

                    <Tooltip title={i18next.format(t("system-design"), 'capitalize-each')} placement="right">
                        <IconButton
                            aria-label={i18next.format(t("system-design"), 'capitalize-each')}
                            component="label"
                            onClick={navigateToSystemDesign}
                            disabled={!(systemValidity === "valid" || systemValidity === "warning")}
                        >
                            <SystemDesignIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
                {selectedLeftDrawerTabIndex !== undefined && (
                    <Stack direction={"column"} sx={{ flexGrow: 1, width: LEFT_DRAWER_WIDTH_FULL - LEFT_DRAWER_WIDTH_MINI }}>
                        {/* Title + button */}
                        <Stack direction={"row"}>
                            <Typography
                                variant="h6"
                                component="div"
                                p={2}
                                sx={{ flex: 1 }}
                            >
                                <strong>
                                    {
                                        leftDrawerTabs[
                                            selectedLeftDrawerTabIndex
                                        ].getTitle()
                                    }
                                </strong>
                            </Typography>
                            <Tooltip
                                title="minimise"
                                placement="right"
                                sx={{ alignSelf: "center" }}
                            >
                                <IconButton
                                    color="primary"
                                    aria-label="minimise"
                                    component="label"
                                    onClick={() =>
                                        setSelectedLeftDrawerTabIndex(undefined)
                                    }
                                >
                                    <KeyboardArrowLeftIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>

                        {/* Tab Body */}
                        <Stack flexGrow={1} sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                            {TabBody && <TabBody dbPrj={dbPrj} authState={authState} layoutId={layoutId} systemId={systemId!} projectId={projectId} />}
                        </Stack>

                        {/* Field + system information */}
                        <Stack sx={{ marginRight: 2, marginTop: 2, marginBottom: 2 }}>
                            <SystemInformationCard 
                                project={dbPrj.state}
                                layoutId={layoutId}
                                systemId={systemId}
                            />
                        </Stack>

                    </Stack>
                )}
            </Stack>

        </StyledDrawer>
    );
};

export default LeftDrawer;