import { Snackbar } from "@mui/material";
import IComponent from "rdptypes/roe/IComponent";
import * as React from "react";
import { FC, ReactElement, useState } from "react";
import IDbProject from "../../../db/IDbProject";
import { getImgSource } from "../../../images/ImagePathHelper";
import IComponentRenderContext from "./IComponentRenderContext";
import { getComponentRenderer } from "./componentRendererFactory";

interface Props {
    components: IComponent[],
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    disabled?: boolean,
    disabledOptionIds?: string[],
    oneColumn: boolean,
    parentGroupLabel: string | null,
    inline?: boolean;
    ctx: IComponentRenderContext;
    ignoreImgTooltips?: boolean;
};

const NR_OF_COLS = 2;

const ComponentListRenderer: FC<Props> = (props) => {
    const [ openImgSource, setOpenImgSource ] = useState<string | null>(null);
    var cols: IComponent[][] = [];
    cols[0] = [];
    cols[1] = [];

    props.components.forEach((c, i) => {
        if(c.layoutHint !== "fullWidth"){
            if(c.layoutHint === "primary" || !c.layoutHint){
                cols[0].push(c);
            }else{
                cols[1].push(c);
            }
        }  
    });

    const handleTooltipOpen = (src?: any) => {
        if(src && !props.ignoreImgTooltips){
            console.log(src);
            setOpenImgSource(src);
        }
    }

    const handleTooltipClose = (src?: any) => {
        if(src && !props.ignoreImgTooltips){
            setOpenImgSource(null);
        }     
    }

    const getComponent = (cmp: IComponent, i: number): ReactElement => {
        const imgSource = cmp.type !== "choice" ? getImgSource((cmp as any).fieldPath) : null;
        
        let system = props.dbPrj.state.layouts[props.layoutId].systems[props.systemId];
        if ((!cmp.visible || cmp.visible(system))
        && !(cmp.disableDealerSettings && props.ctx.editingDealerDefaults)){
            const cr = getComponentRenderer(cmp, props.dbPrj, props.layoutId, props.systemId, props.ctx, props.parentGroupLabel, false, props.ignoreImgTooltips);
            if (cr) {                
                let style = {marginBottom: cmp.type === "boolean" ? 0 : 15};
                return (<div onMouseEnter={() => handleTooltipOpen(imgSource)} onMouseLeave={() => handleTooltipClose(imgSource)}  key={i} style={style}>
                    {cr}
                    {
                        openImgSource && !props.ignoreImgTooltips &&
                        <Snackbar
                            open={openImgSource !== null && openImgSource !== undefined}
                            autoHideDuration={6000}
                            onClose={() => {setOpenImgSource(null); }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}                
                        >
                            <div style={{marginTop: 50, marginRight: 20}}><img src={openImgSource} /></div>
                        </Snackbar>
                    }
                    </div>);
            }
        }
    }
    
    return (
        <>
            {!props.oneColumn && !props.inline && 
                    cols.map(col => <div style={{minWidth: 250, width: '100%'}}>
                    { col.map((cmp, i) => {
                        return getComponent(cmp, i);                  
                    }) }
                    </div>)  
            }
            {
                props.oneColumn && !props.inline &&
                props.components.map((cmp, i) => {
                    return getComponent(cmp, i);     
                })
            }
            {
                props.inline && cols.map((col, i) => <div style={{minWidth: 250, width: '45%', display: "inline-block", verticalAlign: "middle", marginLeft: i > 0 ? 10: 0}}>
                    { col.map((cmp, i) => {
                        return getComponent(cmp, i);                  
                    }) }
                </div>)
            }
        </>
    );
};

export default ComponentListRenderer;