import i18next, { t } from "i18next";
import * as React from "react";
import SpansAndTowersTabRenderer from "../../../../../components/roe/componentRenderers/SideRenderer/SpansAndTowersTabRenderer";
import IDrawerTab from "../IDrawerTab";

const towerImg = new URL(
    'tower.png?as=webp&width=25',
    import.meta.url
  );

const spansAndTowersTab: IDrawerTab = {
    icon: <img src={towerImg}/>,
    getTitle: () => i18next.format(t("spans-and-towers"), 'capitalize-each'),
    component: (props) => {
        const { dbPrj, layoutId, systemId } = props;
        const layout = dbPrj.state.layouts[layoutId];
        if (!layout) return null;
        const system = layout.systems[systemId];
        if (!system) return null;

        return (
            <SpansAndTowersTabRenderer
                mapDesignMode={true}
                dbPrj={props.dbPrj}
                layoutId={props.layoutId}
                systemId={props.systemId}
                systemType={system.SystemProperties.SystemType}
            />
        )
    }
}

export default spansAndTowersTab;