import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { FC } from "react";

interface Props {
    open: boolean;
    onConfirm: () => any;
    onClose: () => any;
    text: string;
    title: string;
}

const ConfirmationDialog: FC<Props> = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>{t("no")}</Button>
          <Button onClick={props.onConfirm} autoFocus>
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    );
};

export default ConfirmationDialog;