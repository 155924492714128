import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import * as React from "react";
import { FC, useState } from "react";
import { createNewUpdateSystemPropertyAction } from "../../../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../../../auth/AuthCtx";
import DbCtx from "../../../../../db/DbCtx";

interface Props {
  layoutId: string;
  projectId: string;
  systemId: string;
  onClose: () => void;
}

const RenameSystemDialog: FC<Props> = (props) => {
  const authState = React.useContext(AuthCtx);
  const dbState = React.useContext(DbCtx);

  const dbProject = dbState.projects[props.projectId];
  const project = dbProject.state;
  const layout = project.layouts[props.layoutId];
  const system = layout.systems[props.systemId];

    const [newSystemName, setNewSystemName] = useState("");
    const getNewSystemName = () => newSystemName.trim();

    let errorText = "";
    if (getNewSystemName() === system.name) {
      errorText = "New system name must not be the same as the current name.";
    }
    else if (
      Object.entries(layout.systems)
        .filter(([id,_]) => id !== props.layoutId)
        .map(([_, x]) => x.name)
        .includes(getNewSystemName())
    ) {
      errorText = "New system name must be unique within this field.";
    }
    else if (getNewSystemName().length > 80) {
      errorText = "System name must not be more then 80 characters long."
    }

    const hasError = errorText !== "";

    return (
      <Dialog onClose={props.onClose} open={true} fullWidth>
        <DialogTitle>Rename System</DialogTitle>
          <DialogContent>
              <DialogContentText>
                Current system name: {system.name}
              </DialogContentText>
              <TextField
                error={hasError}
                autoFocus
                id="label-text"
                fullWidth
                variant="standard"
                onChange={(e) => {
                  setNewSystemName(e.target.value);
                }}
                helperText={errorText || " "}
              />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button 
              disabled={hasError || getNewSystemName() === ""}
              onClick={() => {
                dbProject.pushAction(
                  createNewUpdateSystemPropertyAction(
                    props.layoutId,
                    props.systemId,
                    "name",
                    getNewSystemName(),
                    authState
                  )
                )
                props.onClose();
            }}>OK</Button>
          </DialogActions>
      </Dialog>
    );
  }

  export default RenameSystemDialog;