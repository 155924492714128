import * as React from "react";
import { useContext, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";

import { useParams } from "react-router-dom";

import { createNewUpdateSystemPropertyAction } from "../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../auth/AuthCtx";
import DbCtx from "../../../db/DbCtx";

interface Props {
    projectId?: string;
    layoutId?: string;
    systemId?: string;
}

const SystemNameField: React.FC<Props> = React.forwardRef((props) => {
    
    const authState = useContext(AuthCtx);
    const dbState = useContext(DbCtx);    
    let { projectId, layoutId, systemId } = useParams();

    if (props && typeof(props.projectId) !== "undefined" && typeof(props.layoutId) !== "undefined" && typeof(props.systemId) !== "undefined"){
        projectId = props.projectId;
        layoutId = props.layoutId;
        systemId = props.systemId;
    }

    const dbPrj = dbState.projects[projectId!];
    const project = dbPrj.state;
    const layout = project.layouts[layoutId!];
    const system = layout.systems[systemId!];

    const [ isEditing, setIsEditing ] = useState(false);
    const [ newName, setNewName ] = useState<string | undefined>(undefined);
    
    const handleCancel = () => {
        setNewName(undefined);
        setIsEditing(false);
    }
    const handleOK = () => {
        if (newName && newName !== system.name) {
            dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                layoutId!,
                systemId!,
                "name",
                newName,
                authState
            ));
        }
        setNewName(undefined);
        setIsEditing(false);
    }

    return (
        <>
            <Typography  ml={3} style={{fontSize: '0.875rem'}}>
                {dbState.growers[project.growerId].name}
            </Typography>
            <Typography  ml={3} style={{fontSize: '0.875rem'}}>
                {project.name}
            </Typography>
            <Typography  ml={3} style={{fontSize: '0.875rem'}}>
                {layout.name}
            </Typography>
            <Typography  ml={3} style={{fontSize: '0.875rem'}}>
                {system.name}
            </Typography>
            <IconButton sx={{ color: "inherit" }} onClick={() => setIsEditing(true)}>
                <EditIcon />
            </IconButton>
            <Dialog open={isEditing} onClose={handleCancel}>
                <DialogTitle>Change system name</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="System Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        defaultValue={system.name}
                        onChange={(ev) => setNewName(ev.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleOK}>OK</Button>
                </DialogActions>
            </Dialog>
        </>
    )
});

export default SystemNameField;