import { Position } from "rdptypes/geoTypes";
import ILayout from "./ILayout";

export enum ProjectType {
    /** RDP and ROE features */
    LayoutAndDesign = "LayoutAndDesign",

    /** ROE features only */
    DesignOnly = "DesignOnly"
}

export enum ProjectState {
    Active = "Active",
    Deleted = "Deleted"
}

/**
 * Represents the state of a project within RDP. Each project represents a single field for a single
 * grower, but it can have any number of different layouts being considered, each of which having
 * any number of systems.
 */
export default interface IProject {
    projectState: ProjectState;
    projectType?: ProjectType;

    name: string;
    growerId: string;

    /**
     * The approximate field location used for caching basemap tiles. Required for layout (RDP)
     * projects, undefined for design only (ROE) projects.
     */
    approximateLocation?: Position;
    
    layouts: { [layoutId: string]: ILayout };

    lastModifiedTimestamp: number;
    lastModifiedUserId: string;

    // This field is used as the boundary clearance applied to all
    // systems in this project.
    systemClearance: number;
}