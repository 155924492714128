import { Feature, Polygon } from "@turf/turf";
import SegmentHelper from "../../../../GeometryHelpers/SegmentHelper";
import { createNewUpdateLayoutPropertyAction } from "../../../../actions/UpdateLayoutProperty";
import IAuthState from "../../../../auth/IAuthState";
import { IFieldSettings } from "../../../../components/DealerSettingsDialog/FieldSettings";
import IDbProject from "../../../../db/IDbProject";
import IPivotCenterBoundary from "../../../../model/project/IPivotCenterBoundary";


export const handlePivotCenterBoundary =  (
    features: Feature[],
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
    fieldSettings: IFieldSettings,
) => {
    if (features.length !== 1 && features[0].geometry.type !== "Polygon") {
        throw new Error(`Unexpected feature created`);
    }
    const pivotCenterBoundary: IPivotCenterBoundary = {
        segments: SegmentHelper.polygonToSegments(features[0].geometry as Polygon, fieldSettings.minBoundaryClearanceFeet)
    }
    dbPrj.pushAction(createNewUpdateLayoutPropertyAction(
        layoutId,
        "pivotCenterBoundary",
        pivotCenterBoundary,
        authState
    ));
}
