import { Snackbar, SnackbarContent } from "@mui/material";
import IAction from "rdptypes/IAction";
import * as React from "react";
import { PropsWithChildren, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { createNewMultiAction } from "../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import AuthCtx from "../../auth/AuthCtx";
import IAuthState from "../../auth/IAuthState";
import DbCtx from "../../db/DbCtx";
import IDbState from "../../db/IDbState";
import { IEndGunWorkerInputMessage, IProjectUpdate, handleEndGuns } from "./common";
import { IProjectsMap } from "./utils/findModifiedSystems";
import { IEndGunWorkerOutputMessage_End, IEndGunWorkerOutputMessage_Result, IEndGunWorkerOutputMessage_Start } from "./worker";

const projectMapFromDbState = (dbState: IDbState): IProjectsMap => {
    const m: IProjectsMap = {};
        Object.entries(dbState.projects).forEach(([k,v]) => {
            m[k] = structuredClone(v.state)
        })
        return m;
}

const handleUpdates = (updates: IProjectUpdate[], authState: IAuthState, dbState: IDbState) => {
    console.log("received result")
    for (const p of updates) {
        const actions: IAction[] = [];
        for (const l of p.layouts) {
            for (const s of l.systems) {
                if (s.centerPivotOnOffs) {
                    actions.push(
                        createNewUpdateSystemPropertyAction(
                            l.layoutId, s.systemId, "endGuns.centerPivotOnOffs", s.centerPivotOnOffs, authState
                        )
                    )
                }
                if (s.lateralOnOffsFlanged) {
                    actions.push(
                        createNewUpdateSystemPropertyAction(
                            l.layoutId, s.systemId, "endGuns.lateralOnOffsFlanged", s.lateralOnOffsFlanged, authState
                        )
                    )
                }
                if (s.lateralOnOffsFlex) {
                    actions.push(
                        createNewUpdateSystemPropertyAction(
                            l.layoutId, s.systemId, "endGuns.lateralOnOffsFlex", s.lateralOnOffsFlex, authState
                        )
                    )
                }
            }
        }
        dbState.projects[p.projectId].pushAction({
            ...createNewMultiAction(
                actions, authState
            ),
            undoMode: "include", // Include this in undo
        })
    }
}

const useWorker = true;
const EndGunWorkerContext: React.FC<PropsWithChildren> = ({ 
    children
}) => {

    const dbState = React.useContext(DbCtx);
    const authState = React.useContext(AuthCtx);

    const [ running, setRunning ] = useState(false);
    const [previousProjects, setPreviousProjects] = useState<IProjectsMap>();
    const [ worker, setWorker ] = useState<Worker>(null);
    const currentMessageId = React.useRef(uuidV4());
    const [snackWorkers, setSnackWorkers] = useState(0);

    React.useEffect(() => {
        const w = new Worker(
            new URL("./worker.ts", import.meta.url),
            { type: "module" }
        )
        setWorker(w);
    
        // Clean up the worker when the component unmounts
        return () => {
          w.terminate();
        };
      }, []); // Run this effect only once when the component mounts

    React.useEffect(() => {
        currentMessageId.current = uuidV4();
        const projects = projectMapFromDbState(dbState);
        const workerArgs: IEndGunWorkerInputMessage = {
            type: 'input',
            payload: {
                previousProjects,
                projects: projectMapFromDbState(dbState),
                id: currentMessageId.current
            }
        };
        if (useWorker) {
            if (!worker) return;
            worker.onmessage = (
                ev: MessageEvent<IEndGunWorkerOutputMessage_Result | IEndGunWorkerOutputMessage_End | IEndGunWorkerOutputMessage_Start>
            ) => {
                console.log("EndGunWorkerContext Received a message", ev.data, currentMessageId.current)
                if (ev.data.type === "result" && ev.data.payload.id === currentMessageId.current) {
                    console.log("id match")
                    handleUpdates(ev.data.payload.result, authState, dbState);
                    setPreviousProjects(ev.data.payload.projects);
                }
                else if (ev.data.type === 'end') {
                    setRunning(false);
                }
                else if (ev.data.type === 'start') {
                    setRunning(true);
                }
            };
            console.log("posting run to sac worker", workerArgs)
            worker.postMessage(workerArgs);
        }
        else {
            const updates = handleEndGuns(workerArgs, () => setRunning(true));
            handleUpdates(updates, authState, dbState);
        }
        setPreviousProjects(projects);

    }, [dbState]);

    return (
        <>
            {children}
            <Snackbar
                open={running}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{
                    marginTop: 5
                }}
            >
                <SnackbarContent 
                    style={{
                        backgroundColor:'gray',
                    }}
                    message="End gun optimization in progress"
                />
            </Snackbar>
        </>
    );
}

export default EndGunWorkerContext;