import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import * as React from "react";
import { FC, PropsWithChildren, useState } from "react";
import FeatureNotEnabled from "../components/FeatureNotEnabled";
import CommonDialogsCtx from "./CommonDialogsCtx";

const CommonDialogsCtxProvider: FC<PropsWithChildren<{}>> = (props) => {
    const [ showingFeatureNotEnabled, setShowingFeatureNotEnabled ] = useState(false);

    return (
        <CommonDialogsCtx.Provider value={{
            showFeatureNotEnabled: (() => setShowingFeatureNotEnabled(true))
        }}>
            {props.children}

            <Dialog
                open={showingFeatureNotEnabled}
                onClose={() => setShowingFeatureNotEnabled(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <FeatureNotEnabled />
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setShowingFeatureNotEnabled(false)}>Close</Button>
                </DialogActions>
            </Dialog>

        </CommonDialogsCtx.Provider>
    )
};

export default CommonDialogsCtxProvider;