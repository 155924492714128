import { DeviceTypes, IPackage, RegulatorTypes } from "../../../rdptypes/project/ISprinklers";
import { SprinklerManufacturerTypes } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import { HasSwingArmCorner, IsDualSided } from "../SystemFunctions";
import { PSIOptionKometOk, RegsAsNeededOptionKometOk, RegulatorTypeOptionKometOk } from "../Valids.Valids";

export default class SprinklersKometRegulatorForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        let sprinklerProps = this.Quote.System.SprinklerProperties;
        if (sprinklerProps.SprinklerEngineCode !== SprinklerManufacturerTypes.Komet) return;

        this.validatePackages(this.Quote.System.FlangedSide.Sprinklers.Package, "Flanged");
        if (IsDualSided(this.Quote.System) && this.Quote.System.FlexSide.Sprinklers.Package){
            this.validatePackages(this.Quote.System.FlexSide.Sprinklers.Package, "Flex");
        }
    }

    validatePackages = (pkgs: IPackage[], side: string) => {
        if (typeof(pkgs) !== "undefined"){
            for (let i = 0; i < pkgs.length; i++) {
                const pkg = pkgs[i];
                
                if (pkg.Device === DeviceTypes.None){
                    break;//packages get created even if we don't actually add them
                }

                
                if ((pkg.Regulator.RegulatorType ?? RegulatorTypes.None) !== RegulatorTypes.None) {
                    if (!RegulatorTypeOptionKometOk(pkg.Regulator.RegulatorType, {
                        DeviceType: pkg.Device,
                        HasSAC: HasSwingArmCorner(this.Quote.System),
                    })) {
                        this.Quote.Warnings.Add(`Komet regulator type is not OK for ${side.toLowerCase()} package ${i + 1}`);
                    }
                    
                    if (!pkg.Regulator.Pressure || !PSIOptionKometOk(pkg.Regulator.Pressure, {
                        PackageNumber: pkg.PackageNumber,
                        DeviceType: pkg.Device,
                        DropType: pkg.Drop,
                        RegulatorType: pkg.Regulator.RegulatorType,
                    })){
                        this.Quote.Warnings.Add(`Selected PSI option is not OK for ${side.toLowerCase()} Komet sprinkler package ${i + 1}`);
                    }
                }


                if (!RegsAsNeededOptionKometOk(pkg.Regulator.AsNeeded, {
                    RegulatorType: pkg.Regulator.RegulatorType, 
                    RegulatorPressure: pkg.Regulator.Pressure,
                    DeviceType: pkg.Device,
                    HasSAC: HasSwingArmCorner(this.Quote.System),
                    })){
                    this.Quote.Warnings.Add(`"Use only when needed" cannot be selected for current ${side.toLowerCase()} Komet sprinkler package ${i + 1} configuration`);
                }

                if (pkg.EndingLocation <= 0) {
                    return;
                }
            }
        }
    }
}