export default {
    pages: [
        {
            id: "sendOrder",
            title: "Send Order",
            disableDealerSettings: true,
            position: 18,
            visible: () => true,
            components: [
                {
                    type: "sendOrder",
                },
            ],
        },
    ],
};