import IComponentRenderContext from "../../../../components/roe/componentRenderers/IComponentRenderContext";
import { SystemValidationResult } from "../../../../components/roe/validation/SystemValidator";
import IDbProject from "../../../../db/IDbProject";
import { ValidationWorkerStatus } from "../../../../workers/ValidationWorker/IValidationWorkerState";

export const createAlwaysValidComponentRendererCtx = (dbPrj: IDbProject): IComponentRenderContext => (
    {
        editingDealerDefaults: false,
        vws: {
            status: ValidationWorkerStatus.Validating,
            validate: async (sys, changingFieldPaths, improveScores) => new SystemValidationResult(),
            validateCmp: (system, cmp, fieldRoot, pageId) => new SystemValidationResult(),
            validationResult: new SystemValidationResult()
        },
        fieldRoot: "",
        changeFieldRoots: [""],
        pushActionAndImproveScores: (action, changingFieldPaths, improveScores) => dbPrj.pushAction(action)
    }
)