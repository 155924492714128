import { EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";

const EndGunInfo: EndGunItem[] = [
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 15, GPM: 18.8, Radius: 29.45 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 20, GPM: 21.6, Radius: 31 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 25, GPM: 24.3, Radius: 33.325 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 30, GPM: 26.7, Radius: 34.1 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 35, GPM: 29, Radius: 34.875 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 40, GPM: 31.2, Radius: 35.65 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 45, GPM: 33.1, Radius: 36.425 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 50, GPM: 34.9, Radius: 36.425 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 55, GPM: 36.5, Radius: 37.2 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 60, GPM: 37.9, Radius: 37.975 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 15, GPM: 23.5, Radius: 29.45 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 20, GPM: 27, Radius: 31.775 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 25, GPM: 30.3, Radius: 34.1 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 30, GPM: 33.4, Radius: 34.875 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 35, GPM: 36.2, Radius: 35.65 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 40, GPM: 38.9, Radius: 36.425 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 45, GPM: 41.3, Radius: 37.2 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 50, GPM: 43.4, Radius: 37.2 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 55, GPM: 45.4, Radius: 37.975 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 60, GPM: 47.1, Radius: 37.975 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 15, GPM: 28, Radius: 28.675 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 20, GPM: 32.1, Radius: 31 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 25, GPM: 36.1, Radius: 32.55 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 30, GPM: 39.7, Radius: 34.1 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 35, GPM: 43.1, Radius: 34.875 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 40, GPM: 46.2, Radius: 36.425 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 45, GPM: 49, Radius: 37.2 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 50, GPM: 51.6, Radius: 37.2 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 55, GPM: 54, Radius: 37.975 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 60, GPM: 56, Radius: 37.975 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 15, GPM: 33, Radius: 28.675 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 20, GPM: 38, Radius: 31 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 25, GPM: 42.6, Radius: 32.55 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 30, GPM: 47, Radius: 34.1 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 35, GPM: 51, Radius: 34.875 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 40, GPM: 54.8, Radius: 35.65 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 45, GPM: 58.3, Radius: 36.425 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 50, GPM: 61.4, Radius: 37.2 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 55, GPM: 64.3, Radius: 37.2 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 60, GPM: 66.9, Radius: 37.2 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 15, GPM: 36.7, Radius: 27.9 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 20, GPM: 42.2, Radius: 30.225 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 25, GPM: 47.3, Radius: 31.775 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 30, GPM: 52, Radius: 33.325 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 35, GPM: 56.5, Radius: 34.1 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 40, GPM: 60.6, Radius: 35.65 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 45, GPM: 64.3, Radius: 36.425 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 50, GPM: 67.7, Radius: 36.425 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 55, GPM: 70.7, Radius: 37.2 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 60, GPM: 73.4, Radius: 37.2 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 15, GPM: 46, Radius: 27.125 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 20, GPM: 52.9, Radius: 29.45 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 25, GPM: 59.3, Radius: 31 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 30, GPM: 65.2, Radius: 32.55 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 35, GPM: 70.8, Radius: 33.325 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 40, GPM: 75.8, Radius: 34.875 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 45, GPM: 80.5, Radius: 35.65 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 50, GPM: 84.7, Radius: 35.65 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 55, GPM: 88.4, Radius: 36.425 },
    { EndGunType: EndGunTypes.R55i, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 60, GPM: 91.7, Radius: 36.425 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 15, GPM: 18.8, Radius: 33.2 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 20, GPM: 21.6, Radius: 35.69 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 25, GPM: 24.3, Radius: 37.35 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 30, GPM: 26.7, Radius: 38.18 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 35, GPM: 29, Radius: 39.01 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 40, GPM: 31.2, Radius: 39.84 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 45, GPM: 33.1, Radius: 39.84 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 50, GPM: 34.9, Radius: 39.84 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 55, GPM: 36.5, Radius: 39.84 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 60, GPM: 37.9, Radius: 39.84 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 15, GPM: 23.5, Radius: 33.2 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 20, GPM: 27, Radius: 35.69 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 25, GPM: 30.3, Radius: 38.18 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 30, GPM: 33.4, Radius: 39.01 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 35, GPM: 36.2, Radius: 39.84 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 40, GPM: 38.9, Radius: 40.67 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 45, GPM: 41.3, Radius: 41.5 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 50, GPM: 43.4, Radius: 41.5 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 55, GPM: 45.4, Radius: 41.5 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 60, GPM: 47.1, Radius: 41.5 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 15, GPM: 28, Radius: 33.2 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 20, GPM: 32.1, Radius: 35.69 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 25, GPM: 36.1, Radius: 38.18 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 30, GPM: 39.7, Radius: 39.01 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 35, GPM: 43.1, Radius: 40.67 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 40, GPM: 46.2, Radius: 41.5 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 45, GPM: 49, Radius: 42.33 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 50, GPM: 51.6, Radius: 42.33 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 55, GPM: 54, Radius: 42.33 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 60, GPM: 56, Radius: 42.33 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 15, GPM: 33, Radius: 33.2 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 20, GPM: 38, Radius: 36.52 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 25, GPM: 42.6, Radius: 39.01 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 30, GPM: 47, Radius: 39.84 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 35, GPM: 51, Radius: 40.67 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 40, GPM: 54.8, Radius: 41.5 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 45, GPM: 58.3, Radius: 42.33 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 50, GPM: 61.4, Radius: 43.16 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 55, GPM: 64.3, Radius: 43.16 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 65.0000, Nozzle: 0.507812, Pressure: 60, GPM: 66.9, Radius: 43.16 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 15, GPM: 36.7, Radius: 33.2 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 20, GPM: 42.2, Radius: 36.52 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 25, GPM: 47.3, Radius: 39.84 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 30, GPM: 52, Radius: 40.67 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 35, GPM: 56.5, Radius: 41.5 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 40, GPM: 60.6, Radius: 42.33 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 45, GPM: 64.3, Radius: 43.16 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 50, GPM: 67.7, Radius: 43.99 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 55, GPM: 70.7, Radius: 43.99 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 70.0000, Nozzle: 0.546875, Pressure: 60, GPM: 73.4, Radius: 43.99 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 15, GPM: 46, Radius: 33.2 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 20, GPM: 52.9, Radius: 36.52 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 25, GPM: 59.3, Radius: 39.84 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 30, GPM: 65.2, Radius: 40.67 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 35, GPM: 70.8, Radius: 41.5 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 40, GPM: 75.8, Radius: 42.33 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 45, GPM: 80.5, Radius: 43.99 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 50, GPM: 84.7, Radius: 44.82 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 55, GPM: 88.4, Radius: 44.82 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 80.0000, Nozzle: 0.625, Pressure: 60, GPM: 91.7, Radius: 44.82 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 90.0000, Nozzle: 0.703125, Pressure: 15, GPM: 52.8, Radius: 34.03 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 90.0000, Nozzle: 0.703125, Pressure: 20, GPM: 60.6, Radius: 37.35 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 90.0000, Nozzle: 0.703125, Pressure: 25, GPM: 68, Radius: 39.84 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 90.0000, Nozzle: 0.703125, Pressure: 30, GPM: 74.8, Radius: 41.5 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 90.0000, Nozzle: 0.703125, Pressure: 35, GPM: 81.1, Radius: 42.33 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 90.0000, Nozzle: 0.703125, Pressure: 40, GPM: 87, Radius: 43.16 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 90.0000, Nozzle: 0.703125, Pressure: 45, GPM: 92.3, Radius: 44.82 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 90.0000, Nozzle: 0.703125, Pressure: 50, GPM: 97.2, Radius: 44.82 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 90.0000, Nozzle: 0.703125, Pressure: 55, GPM: 101.5, Radius: 45.65 },
    { EndGunType: EndGunTypes.R55, NozzleNumberText: "", NozzleNumber: 90.0000, Nozzle: 0.703125, Pressure: 60, GPM: 105.4, Radius: 46.48 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 40, GPM: 30, Radius: 47.538 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 45, GPM: 31.7, Radius: 48.372 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 50, GPM: 33.6, Radius: 49.206 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 55, GPM: 35.3, Radius: 49.206 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 60, GPM: 36.8, Radius: 49.206 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 40, GPM: 34.6, Radius: 49.206 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 45, GPM: 36.8, Radius: 50.04 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 50, GPM: 38.8, Radius: 50.874 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 55, GPM: 40.7, Radius: 51.708 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 60, GPM: 42.7, Radius: 51.708 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 40, GPM: 39.7, Radius: 50.874 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 45, GPM: 42, Radius: 51.708 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 50, GPM: 44.4, Radius: 52.542 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 55, GPM: 46.6, Radius: 53.376 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 60, GPM: 48.8, Radius: 54.21 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 64.0000, Nozzle: 0.5, Pressure: 40, GPM: 44.9, Radius: 54.21 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 64.0000, Nozzle: 0.5, Pressure: 45, GPM: 47.6, Radius: 55.044 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 64.0000, Nozzle: 0.5, Pressure: 50, GPM: 50.2, Radius: 55.878 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 64.0000, Nozzle: 0.5, Pressure: 55, GPM: 52.7, Radius: 56.712 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 64.0000, Nozzle: 0.5, Pressure: 60, GPM: 55, Radius: 57.546 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 68.0000, Nozzle: 0.53125, Pressure: 40, GPM: 50.6, Radius: 54.21 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 68.0000, Nozzle: 0.53125, Pressure: 45, GPM: 53.7, Radius: 55.044 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 68.0000, Nozzle: 0.53125, Pressure: 50, GPM: 56.5, Radius: 55.878 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 68.0000, Nozzle: 0.53125, Pressure: 55, GPM: 59.2, Radius: 56.712 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 68.0000, Nozzle: 0.53125, Pressure: 60, GPM: 61.9, Radius: 56.712 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 72.0000, Nozzle: 0.5625, Pressure: 40, GPM: 56.4, Radius: 53.376 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 72.0000, Nozzle: 0.5625, Pressure: 45, GPM: 59.7, Radius: 54.21 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 72.0000, Nozzle: 0.5625, Pressure: 50, GPM: 63.1, Radius: 54.21 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 72.0000, Nozzle: 0.5625, Pressure: 55, GPM: 66.1, Radius: 55.044 },
    { EndGunType: EndGunTypes.R75, NozzleNumberText: "", NozzleNumber: 72.0000, Nozzle: 0.5625, Pressure: 60, GPM: 69.2, Radius: 55.878 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/32", NozzleNumber: 11.0000, Nozzle: 0.34375, Pressure: 20, GPM: 15.4, Radius: 42.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/32", NozzleNumber: 11.0000, Nozzle: 0.34375, Pressure: 30, GPM: 18.8, Radius: 48 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/32", NozzleNumber: 11.0000, Nozzle: 0.34375, Pressure: 40, GPM: 21.8, Radius: 52.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/32", NozzleNumber: 11.0000, Nozzle: 0.34375, Pressure: 50, GPM: 24.2, Radius: 56.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/32", NozzleNumber: 11.0000, Nozzle: 0.34375, Pressure: 60, GPM: 26.5, Radius: 60 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/32", NozzleNumber: 11.0000, Nozzle: 0.34375, Pressure: 70, GPM: 28.6, Radius: 63.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/32", NozzleNumber: 11.0000, Nozzle: 0.34375, Pressure: 80, GPM: 30.6, Radius: 66.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/32", NozzleNumber: 11.0000, Nozzle: 0.34375, Pressure: 90, GPM: 32.6, Radius: 68.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/32", NozzleNumber: 11.0000, Nozzle: 0.34375, Pressure: 100, GPM: 34.4, Radius: 71.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "3/8", NozzleNumber: 12.0000, Nozzle: 0.375, Pressure: 20, GPM: 18.2, Radius: 44 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "3/8", NozzleNumber: 12.0000, Nozzle: 0.375, Pressure: 30, GPM: 22.3, Radius: 49.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "3/8", NozzleNumber: 12.0000, Nozzle: 0.375, Pressure: 40, GPM: 26, Radius: 54.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "3/8", NozzleNumber: 12.0000, Nozzle: 0.375, Pressure: 50, GPM: 28.8, Radius: 59.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "3/8", NozzleNumber: 12.0000, Nozzle: 0.375, Pressure: 60, GPM: 31.5, Radius: 62.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "3/8", NozzleNumber: 12.0000, Nozzle: 0.375, Pressure: 70, GPM: 34, Radius: 65.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "3/8", NozzleNumber: 12.0000, Nozzle: 0.375, Pressure: 80, GPM: 36.4, Radius: 68.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "3/8", NozzleNumber: 12.0000, Nozzle: 0.375, Pressure: 90, GPM: 38.6, Radius: 72 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "3/8", NozzleNumber: 12.0000, Nozzle: 0.375, Pressure: 100, GPM: 40.7, Radius: 74.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "13/32", NozzleNumber: 13.0000, Nozzle: 0.40625, Pressure: 20, GPM: 21.7, Radius: 45.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "13/32", NozzleNumber: 13.0000, Nozzle: 0.40625, Pressure: 30, GPM: 26.4, Radius: 52 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "13/32", NozzleNumber: 13.0000, Nozzle: 0.40625, Pressure: 40, GPM: 30.4, Radius: 56.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "13/32", NozzleNumber: 13.0000, Nozzle: 0.40625, Pressure: 50, GPM: 33.8, Radius: 60.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "13/32", NozzleNumber: 13.0000, Nozzle: 0.40625, Pressure: 60, GPM: 37.2, Radius: 64.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "13/32", NozzleNumber: 13.0000, Nozzle: 0.40625, Pressure: 70, GPM: 40.1, Radius: 68.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "13/32", NozzleNumber: 13.0000, Nozzle: 0.40625, Pressure: 80, GPM: 42.8, Radius: 71.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "13/32", NozzleNumber: 13.0000, Nozzle: 0.40625, Pressure: 90, GPM: 45.4, Radius: 74.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "13/32", NozzleNumber: 13.0000, Nozzle: 0.40625, Pressure: 100, GPM: 47.9, Radius: 77.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "7/16", NozzleNumber: 14.0000, Nozzle: 0.4375, Pressure: 20, GPM: 23.9, Radius: 47.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "7/16", NozzleNumber: 14.0000, Nozzle: 0.4375, Pressure: 30, GPM: 29.6, Radius: 52.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "7/16", NozzleNumber: 14.0000, Nozzle: 0.4375, Pressure: 40, GPM: 34.4, Radius: 58.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "7/16", NozzleNumber: 14.0000, Nozzle: 0.4375, Pressure: 50, GPM: 38.5, Radius: 63.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "7/16", NozzleNumber: 14.0000, Nozzle: 0.4375, Pressure: 60, GPM: 42.5, Radius: 67.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "7/16", NozzleNumber: 14.0000, Nozzle: 0.4375, Pressure: 70, GPM: 46, Radius: 71.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "7/16", NozzleNumber: 14.0000, Nozzle: 0.4375, Pressure: 80, GPM: 49.3, Radius: 74.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "7/16", NozzleNumber: 14.0000, Nozzle: 0.4375, Pressure: 90, GPM: 52.3, Radius: 76.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "7/16", NozzleNumber: 14.0000, Nozzle: 0.4375, Pressure: 100, GPM: 55.1, Radius: 80 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "15/32", NozzleNumber: 15.0000, Nozzle: 0.46875, Pressure: 20, GPM: 27.4, Radius: 48 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "15/32", NozzleNumber: 15.0000, Nozzle: 0.46875, Pressure: 30, GPM: 33.9, Radius: 55.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "15/32", NozzleNumber: 15.0000, Nozzle: 0.46875, Pressure: 40, GPM: 39.4, Radius: 60.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "15/32", NozzleNumber: 15.0000, Nozzle: 0.46875, Pressure: 50, GPM: 44.1, Radius: 64.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "15/32", NozzleNumber: 15.0000, Nozzle: 0.46875, Pressure: 60, GPM: 48.6, Radius: 69.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "15/32", NozzleNumber: 15.0000, Nozzle: 0.46875, Pressure: 70, GPM: 52.5, Radius: 72.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "15/32", NozzleNumber: 15.0000, Nozzle: 0.46875, Pressure: 80, GPM: 56.2, Radius: 76.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "15/32", NozzleNumber: 15.0000, Nozzle: 0.46875, Pressure: 90, GPM: 60, Radius: 80 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "15/32", NozzleNumber: 15.0000, Nozzle: 0.46875, Pressure: 100, GPM: 63.2, Radius: 82.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "1/2", NozzleNumber: 16.0000, Nozzle: 0.5, Pressure: 20, GPM: 31.1, Radius: 49.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "1/2", NozzleNumber: 16.0000, Nozzle: 0.5, Pressure: 30, GPM: 38.4, Radius: 56.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "1/2", NozzleNumber: 16.0000, Nozzle: 0.5, Pressure: 40, GPM: 44.6, Radius: 62.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "1/2", NozzleNumber: 16.0000, Nozzle: 0.5, Pressure: 50, GPM: 50.1, Radius: 67.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "1/2", NozzleNumber: 16.0000, Nozzle: 0.5, Pressure: 60, GPM: 55.1, Radius: 71.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "1/2", NozzleNumber: 16.0000, Nozzle: 0.5, Pressure: 70, GPM: 59.6, Radius: 75.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "1/2", NozzleNumber: 16.0000, Nozzle: 0.5, Pressure: 80, GPM: 63.8, Radius: 78.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "1/2", NozzleNumber: 16.0000, Nozzle: 0.5, Pressure: 90, GPM: 67.6, Radius: 81.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "1/2", NozzleNumber: 16.0000, Nozzle: 0.5, Pressure: 100, GPM: 71.2, Radius: 84.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "17/32", NozzleNumber: 17.0000, Nozzle: 0.53125, Pressure: 20, GPM: 34.9, Radius: 51.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "17/32", NozzleNumber: 17.0000, Nozzle: 0.53125, Pressure: 30, GPM: 43.1, Radius: 58.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "17/32", NozzleNumber: 17.0000, Nozzle: 0.53125, Pressure: 40, GPM: 50.2, Radius: 64 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "17/32", NozzleNumber: 17.0000, Nozzle: 0.53125, Pressure: 50, GPM: 56.3, Radius: 68.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "17/32", NozzleNumber: 17.0000, Nozzle: 0.53125, Pressure: 60, GPM: 61.8, Radius: 73.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "17/32", NozzleNumber: 17.0000, Nozzle: 0.53125, Pressure: 70, GPM: 66.9, Radius: 77.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "17/32", NozzleNumber: 17.0000, Nozzle: 0.53125, Pressure: 80, GPM: 71.7, Radius: 80.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "17/32", NozzleNumber: 17.0000, Nozzle: 0.53125, Pressure: 90, GPM: 75.9, Radius: 84 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "17/32", NozzleNumber: 17.0000, Nozzle: 0.53125, Pressure: 100, GPM: 80, Radius: 87.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "9/16", NozzleNumber: 18.0000, Nozzle: 0.5625, Pressure: 20, GPM: 39.3, Radius: 52.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "9/16", NozzleNumber: 18.0000, Nozzle: 0.5625, Pressure: 30, GPM: 48.4, Radius: 60 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "9/16", NozzleNumber: 18.0000, Nozzle: 0.5625, Pressure: 40, GPM: 56.2, Radius: 65.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "9/16", NozzleNumber: 18.0000, Nozzle: 0.5625, Pressure: 50, GPM: 62.8, Radius: 71.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "9/16", NozzleNumber: 18.0000, Nozzle: 0.5625, Pressure: 60, GPM: 69, Radius: 75.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "9/16", NozzleNumber: 18.0000, Nozzle: 0.5625, Pressure: 70, GPM: 74.7, Radius: 79.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "9/16", NozzleNumber: 18.0000, Nozzle: 0.5625, Pressure: 80, GPM: 80, Radius: 83.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "9/16", NozzleNumber: 18.0000, Nozzle: 0.5625, Pressure: 90, GPM: 85, Radius: 86.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "9/16", NozzleNumber: 18.0000, Nozzle: 0.5625, Pressure: 100, GPM: 89.6, Radius: 89.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "19/32", NozzleNumber: 19.0000, Nozzle: 0.59375, Pressure: 20, GPM: 43.4, Radius: 53.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "19/32", NozzleNumber: 19.0000, Nozzle: 0.59375, Pressure: 30, GPM: 53.5, Radius: 60.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "19/32", NozzleNumber: 19.0000, Nozzle: 0.59375, Pressure: 40, GPM: 62.2, Radius: 67.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "19/32", NozzleNumber: 19.0000, Nozzle: 0.59375, Pressure: 50, GPM: 69.5, Radius: 72.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "19/32", NozzleNumber: 19.0000, Nozzle: 0.59375, Pressure: 60, GPM: 76.4, Radius: 77.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "19/32", NozzleNumber: 19.0000, Nozzle: 0.59375, Pressure: 70, GPM: 82.6, Radius: 81.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "19/32", NozzleNumber: 19.0000, Nozzle: 0.59375, Pressure: 80, GPM: 88.4, Radius: 85.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "19/32", NozzleNumber: 19.0000, Nozzle: 0.59375, Pressure: 90, GPM: 93.7, Radius: 88.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "19/32", NozzleNumber: 19.0000, Nozzle: 0.59375, Pressure: 100, GPM: 98.8, Radius: 92 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "5/8", NozzleNumber: 20.0000, Nozzle: 0.625, Pressure: 20, GPM: 47.7, Radius: 54.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "5/8", NozzleNumber: 20.0000, Nozzle: 0.625, Pressure: 30, GPM: 58.7, Radius: 62.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "5/8", NozzleNumber: 20.0000, Nozzle: 0.625, Pressure: 40, GPM: 68.1, Radius: 68.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "5/8", NozzleNumber: 20.0000, Nozzle: 0.625, Pressure: 50, GPM: 76.2, Radius: 74.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "5/8", NozzleNumber: 20.0000, Nozzle: 0.625, Pressure: 60, GPM: 83.9, Radius: 79.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "5/8", NozzleNumber: 20.0000, Nozzle: 0.625, Pressure: 70, GPM: 90.6, Radius: 83.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "5/8", NozzleNumber: 20.0000, Nozzle: 0.625, Pressure: 80, GPM: 96.9, Radius: 87.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "5/8", NozzleNumber: 20.0000, Nozzle: 0.625, Pressure: 90, GPM: 102, Radius: 90.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "5/8", NozzleNumber: 20.0000, Nozzle: 0.625, Pressure: 100, GPM: 108, Radius: 94.4 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "21/32", NozzleNumber: 21.0000, Nozzle: 0.65625, Pressure: 20, GPM: 51.3, Radius: 55.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "21/32", NozzleNumber: 21.0000, Nozzle: 0.65625, Pressure: 30, GPM: 64.9, Radius: 63.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "21/32", NozzleNumber: 21.0000, Nozzle: 0.65625, Pressure: 40, GPM: 76.5, Radius: 69.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "21/32", NozzleNumber: 21.0000, Nozzle: 0.65625, Pressure: 50, GPM: 84.7, Radius: 75.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "21/32", NozzleNumber: 21.0000, Nozzle: 0.65625, Pressure: 60, GPM: 93, Radius: 80 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "21/32", NozzleNumber: 21.0000, Nozzle: 0.65625, Pressure: 70, GPM: 101, Radius: 84 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "21/32", NozzleNumber: 21.0000, Nozzle: 0.65625, Pressure: 80, GPM: 108, Radius: 88 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "21/32", NozzleNumber: 21.0000, Nozzle: 0.65625, Pressure: 90, GPM: 114, Radius: 91.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "21/32", NozzleNumber: 21.0000, Nozzle: 0.65625, Pressure: 100, GPM: 121, Radius: 95.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/16", NozzleNumber: 22.0000, Nozzle: 0.6875, Pressure: 20, GPM: 55.5, Radius: 56 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/16", NozzleNumber: 22.0000, Nozzle: 0.6875, Pressure: 30, GPM: 70.5, Radius: 64.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/16", NozzleNumber: 22.0000, Nozzle: 0.6875, Pressure: 40, GPM: 82.3, Radius: 71.2 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/16", NozzleNumber: 22.0000, Nozzle: 0.6875, Pressure: 50, GPM: 91.9, Radius: 76.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/16", NozzleNumber: 22.0000, Nozzle: 0.6875, Pressure: 60, GPM: 101, Radius: 81.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/16", NozzleNumber: 22.0000, Nozzle: 0.6875, Pressure: 70, GPM: 109, Radius: 85.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/16", NozzleNumber: 22.0000, Nozzle: 0.6875, Pressure: 80, GPM: 116, Radius: 89.6 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/16", NozzleNumber: 22.0000, Nozzle: 0.6875, Pressure: 90, GPM: 123, Radius: 92.8 },
    { EndGunType: EndGunTypes.SingleP85, NozzleNumberText: "11/16", NozzleNumber: 22.0000, Nozzle: 0.6875, Pressure: 100, GPM: 130, Radius: 96 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.4000, Nozzle: 0.4, Pressure: 40, GPM: 27, Radius: 58.8252 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.4000, Nozzle: 0.4, Pressure: 50, GPM: 30, Radius: 63.5628 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.4000, Nozzle: 0.4, Pressure: 60, GPM: 33, Radius: 66.7212 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.4000, Nozzle: 0.4, Pressure: 70, GPM: 36, Radius: 69.09 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.4000, Nozzle: 0.4, Pressure: 80, GPM: 39, Radius: 71.8536 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.4500, Nozzle: 0.45, Pressure: 40, GPM: 35, Radius: 63.168 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.4500, Nozzle: 0.45, Pressure: 50, GPM: 39, Radius: 68.6952 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.4500, Nozzle: 0.45, Pressure: 60, GPM: 42, Radius: 72.6432 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.4500, Nozzle: 0.45, Pressure: 70, GPM: 45, Radius: 76.5912 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.4500, Nozzle: 0.45, Pressure: 80, GPM: 49, Radius: 81.7236 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 30, GPM: 37, Radius: 62.3784 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 40, GPM: 43, Radius: 67.5108 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 50, GPM: 48, Radius: 73.4328 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 60, GPM: 53, Radius: 78.1704 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 70, GPM: 57, Radius: 82.908 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 80, GPM: 61, Radius: 87.6456 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 30, GPM: 45, Radius: 63.168 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 40, GPM: 52, Radius: 71.064 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 50, GPM: 59, Radius: 76.986 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 60, GPM: 64, Radius: 82.1184 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 70, GPM: 69, Radius: 87.2508 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 80, GPM: 74, Radius: 92.3832 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 30, GPM: 55, Radius: 65.142 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 40, GPM: 63, Radius: 75.012 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 50, GPM: 70, Radius: 80.1444 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 60, GPM: 77, Radius: 86.856 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 70, GPM: 83, Radius: 91.5936 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 80, GPM: 89, Radius: 96.3312 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 30, GPM: 64, Radius: 67.9056 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 40, GPM: 74, Radius: 78.1704 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 50, GPM: 83, Radius: 83.6976 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 60, GPM: 91, Radius: 90.0144 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 70, GPM: 98, Radius: 95.9364 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 80, GPM: 105, Radius: 101.0688 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 30, GPM: 75, Radius: 71.8536 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 40, GPM: 87, Radius: 80.5392 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 50, GPM: 95, Radius: 86.856 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 60, GPM: 104, Radius: 93.5676 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 70, GPM: 113, Radius: 100.2792 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 80, GPM: 121, Radius: 105.0168 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 30, GPM: 87, Radius: 73.8276 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 40, GPM: 98, Radius: 84.0924 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 50, GPM: 109, Radius: 90.804 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 60, GPM: 120, Radius: 96.726 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 70, GPM: 129, Radius: 102.648 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 80, GPM: 138, Radius: 108.1752 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 30, GPM: 99, Radius: 75.8016 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 40, GPM: 112, Radius: 87.2508 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 50, GPM: 123, Radius: 93.5676 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 60, GPM: 136, Radius: 100.2792 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 70, GPM: 147, Radius: 106.596 },
    { EndGunType: EndGunTypes.SR75, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 80, GPM: 158, Radius: 111.7284 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 40, GPM: 47, Radius: 75.4068 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 50, GPM: 50, Radius: 80.934 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 60, GPM: 55, Radius: 84.882 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 70, GPM: 60, Radius: 88.83 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 80, GPM: 64, Radius: 92.778 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 90, GPM: 68, Radius: 96.726 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5000, Nozzle: 0.5, Pressure: 100, GPM: 72, Radius: 100.674 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 40, GPM: 57, Radius: 79.7496 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 50, GPM: 64, Radius: 84.882 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 60, GPM: 69, Radius: 89.6196 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 70, GPM: 75, Radius: 93.9624 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 80, GPM: 79, Radius: 97.9104 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 90, GPM: 83, Radius: 101.8584 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 100, GPM: 87, Radius: 105.8064 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 40, GPM: 66, Radius: 84.0924 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 50, GPM: 74, Radius: 88.83 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 60, GPM: 81, Radius: 94.752 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 70, GPM: 88, Radius: 98.7 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 80, GPM: 94, Radius: 102.648 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 90, GPM: 100, Radius: 106.596 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6000, Nozzle: 0.6, Pressure: 100, GPM: 106, Radius: 110.544 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 40, GPM: 78, Radius: 87.6456 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 50, GPM: 87, Radius: 92.778 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 60, GPM: 96, Radius: 98.7 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 70, GPM: 103, Radius: 103.8324 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 80, GPM: 110, Radius: 107.7804 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 90, GPM: 117, Radius: 111.7284 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.6500, Nozzle: 0.65, Pressure: 100, GPM: 123, Radius: 115.6764 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 40, GPM: 91, Radius: 90.804 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 50, GPM: 100, Radius: 96.726 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 60, GPM: 110, Radius: 102.648 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 70, GPM: 120, Radius: 108.57 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 80, GPM: 128, Radius: 112.518 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 90, GPM: 135, Radius: 116.466 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7000, Nozzle: 0.7, Pressure: 100, GPM: 143, Radius: 120.414 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 40, GPM: 103, Radius: 94.752 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 50, GPM: 115, Radius: 101.0688 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 60, GPM: 126, Radius: 106.596 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 70, GPM: 136, Radius: 111.7284 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 80, GPM: 146, Radius: 116.466 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 90, GPM: 155, Radius: 120.8088 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.7500, Nozzle: 0.75, Pressure: 100, GPM: 163, Radius: 124.7568 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 40, GPM: 118, Radius: 98.7 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 50, GPM: 130, Radius: 104.622 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 60, GPM: 143, Radius: 110.544 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 70, GPM: 155, Radius: 116.466 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 80, GPM: 165, Radius: 120.414 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 90, GPM: 175, Radius: 124.362 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8000, Nozzle: 0.8, Pressure: 100, GPM: 185, Radius: 128.31 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8500, Nozzle: 0.85, Pressure: 40, GPM: 134, Radius: 101.0688 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8500, Nozzle: 0.85, Pressure: 50, GPM: 150, Radius: 107.7804 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8500, Nozzle: 0.85, Pressure: 60, GPM: 164, Radius: 113.7024 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8500, Nozzle: 0.85, Pressure: 70, GPM: 177, Radius: 119.2296 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8500, Nozzle: 0.85, Pressure: 80, GPM: 189, Radius: 123.9672 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8500, Nozzle: 0.85, Pressure: 90, GPM: 201, Radius: 128.7048 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.8500, Nozzle: 0.85, Pressure: 100, GPM: 212, Radius: 132.6528 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.9000, Nozzle: 0.9, Pressure: 40, GPM: 152, Radius: 103.4376 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.9000, Nozzle: 0.9, Pressure: 50, GPM: 165, Radius: 110.544 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.9000, Nozzle: 0.9, Pressure: 60, GPM: 182, Radius: 116.466 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.9000, Nozzle: 0.9, Pressure: 70, GPM: 197, Radius: 122.388 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.9000, Nozzle: 0.9, Pressure: 80, GPM: 210, Radius: 128.31 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.9000, Nozzle: 0.9, Pressure: 90, GPM: 223, Radius: 132.258 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 0.9000, Nozzle: 0.9, Pressure: 100, GPM: 235, Radius: 136.206 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 1.0000, Nozzle: 1, Pressure: 50, GPM: 204, Radius: 118.44 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 1.0000, Nozzle: 1, Pressure: 60, GPM: 224, Radius: 124.7568 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 1.0000, Nozzle: 1, Pressure: 70, GPM: 243, Radius: 133.4424 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 1.0000, Nozzle: 1, Pressure: 80, GPM: 258, Radius: 139.7592 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 1.0000, Nozzle: 1, Pressure: 90, GPM: 274, Radius: 142.9176 },
    { EndGunType: EndGunTypes.SR100, NozzleNumberText: "", NozzleNumber: 1.0000, Nozzle: 1, Pressure: 100, GPM: 289, Radius: 146.8656 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 30, GPM: 35, Radius: 63.9576 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 40, GPM: 40, Radius: 71.8536 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 50, GPM: 45, Radius: 77.7756 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 60, GPM: 50, Radius: 83.6976 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 70, GPM: 53, Radius: 88.83 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 80, GPM: 57, Radius: 93.5676 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 90, GPM: 61, Radius: 97.9104 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 100, GPM: 64, Radius: 101.4636 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 30, GPM: 48, Radius: 71.064 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 40, GPM: 55, Radius: 79.3548 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 50, GPM: 62, Radius: 84.0924 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 60, GPM: 67, Radius: 90.4092 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 70, GPM: 73, Radius: 96.3312 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 80, GPM: 78, Radius: 101.4636 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 90, GPM: 83, Radius: 106.2012 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 100, GPM: 87, Radius: 110.544 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 30, GPM: 62, Radius: 76.1964 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 40, GPM: 72, Radius: 85.2768 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 50, GPM: 80, Radius: 91.5936 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 60, GPM: 88, Radius: 97.5156 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 70, GPM: 95, Radius: 103.4376 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 80, GPM: 102, Radius: 108.9648 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 90, GPM: 108, Radius: 114.0972 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 100, GPM: 114, Radius: 118.44 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 30, GPM: 79, Radius: 81.7236 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 40, GPM: 91, Radius: 91.9884 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 50, GPM: 102, Radius: 98.7 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 60, GPM: 111, Radius: 105.0168 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 70, GPM: 120, Radius: 110.544 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 80, GPM: 129, Radius: 116.0712 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 90, GPM: 136, Radius: 121.5984 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 100, GPM: 144, Radius: 126.336 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 30, GPM: 97, Radius: 87.2508 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 40, GPM: 112, Radius: 97.5156 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 50, GPM: 126, Radius: 105.0168 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 60, GPM: 138, Radius: 111.3336 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 70, GPM: 148, Radius: 117.2556 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 80, GPM: 159, Radius: 121.9932 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 90, GPM: 168, Radius: 125.9412 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 100, GPM: 178, Radius: 130.284 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 30, GPM: 118, Radius: 91.1988 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 40, GPM: 136, Radius: 100.674 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 50, GPM: 152, Radius: 108.1752 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 60, GPM: 166, Radius: 115.2816 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 70, GPM: 180, Radius: 121.2036 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 80, GPM: 192, Radius: 125.5464 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 90, GPM: 204, Radius: 130.6788 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 100, GPM: 215, Radius: 134.6268 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 30, GPM: 140, Radius: 95.9364 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 40, GPM: 162, Radius: 105.8064 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 50, GPM: 181, Radius: 114.492 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 60, GPM: 198, Radius: 121.9932 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 70, GPM: 214, Radius: 127.5204 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 80, GPM: 229, Radius: 135.4164 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 90, GPM: 242, Radius: 140.154 },
    { EndGunType: EndGunTypes.SimeWing, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 100, GPM: 256, Radius: 143.7072 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.3900, Nozzle: 0.39, Pressure: 30, GPM: 24, Radius: 58.4304 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.3900, Nozzle: 0.39, Pressure: 40, GPM: 28, Radius: 64.3524 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.3900, Nozzle: 0.39, Pressure: 50, GPM: 31, Radius: 69.8796 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.3900, Nozzle: 0.39, Pressure: 60, GPM: 34, Radius: 75.4068 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.3900, Nozzle: 0.39, Pressure: 70, GPM: 37, Radius: 80.934 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.3900, Nozzle: 0.39, Pressure: 80, GPM: 40, Radius: 85.2768 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.3900, Nozzle: 0.39, Pressure: 90, GPM: 42, Radius: 89.6196 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.3900, Nozzle: 0.39, Pressure: 100, GPM: 44, Radius: 92.778 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4300, Nozzle: 0.43, Pressure: 30, GPM: 29, Radius: 61.5888 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4300, Nozzle: 0.43, Pressure: 40, GPM: 34, Radius: 68.6952 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4300, Nozzle: 0.43, Pressure: 50, GPM: 38, Radius: 74.2224 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4300, Nozzle: 0.43, Pressure: 60, GPM: 42, Radius: 79.7496 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4300, Nozzle: 0.43, Pressure: 70, GPM: 45, Radius: 84.882 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4300, Nozzle: 0.43, Pressure: 80, GPM: 48, Radius: 89.6196 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4300, Nozzle: 0.43, Pressure: 90, GPM: 51, Radius: 93.9624 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4300, Nozzle: 0.43, Pressure: 100, GPM: 54, Radius: 97.1208 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 30, GPM: 35, Radius: 63.9576 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 40, GPM: 40, Radius: 71.8536 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 50, GPM: 45, Radius: 77.7756 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 60, GPM: 50, Radius: 83.6976 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 70, GPM: 53, Radius: 88.83 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 80, GPM: 57, Radius: 93.5676 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 90, GPM: 61, Radius: 97.9104 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 100, GPM: 64, Radius: 101.4636 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5100, Nozzle: 0.51, Pressure: 30, GPM: 41, Radius: 67.5108 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5100, Nozzle: 0.51, Pressure: 40, GPM: 47, Radius: 76.1964 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5100, Nozzle: 0.51, Pressure: 50, GPM: 53, Radius: 81.7236 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5100, Nozzle: 0.51, Pressure: 60, GPM: 58, Radius: 87.2508 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5100, Nozzle: 0.51, Pressure: 70, GPM: 63, Radius: 92.778 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5100, Nozzle: 0.51, Pressure: 80, GPM: 67, Radius: 97.9104 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5100, Nozzle: 0.51, Pressure: 90, GPM: 71, Radius: 102.2532 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5100, Nozzle: 0.51, Pressure: 100, GPM: 75, Radius: 106.2012 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 30, GPM: 48, Radius: 71.064 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 40, GPM: 55, Radius: 79.3548 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 50, GPM: 62, Radius: 84.0924 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 60, GPM: 67, Radius: 90.4092 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 70, GPM: 73, Radius: 96.3312 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 80, GPM: 78, Radius: 101.4636 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 90, GPM: 83, Radius: 106.2012 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 100, GPM: 87, Radius: 110.544 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5900, Nozzle: 0.59, Pressure: 30, GPM: 55, Radius: 73.8276 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5900, Nozzle: 0.59, Pressure: 40, GPM: 63, Radius: 82.5132 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5900, Nozzle: 0.59, Pressure: 50, GPM: 71, Radius: 88.83 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5900, Nozzle: 0.59, Pressure: 60, GPM: 77, Radius: 94.752 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5900, Nozzle: 0.59, Pressure: 70, GPM: 84, Radius: 100.2792 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5900, Nozzle: 0.59, Pressure: 80, GPM: 89, Radius: 105.0168 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5900, Nozzle: 0.59, Pressure: 90, GPM: 95, Radius: 109.7544 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.5900, Nozzle: 0.59, Pressure: 100, GPM: 100, Radius: 114.0972 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 30, GPM: 62, Radius: 76.1964 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 40, GPM: 72, Radius: 85.2768 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 50, GPM: 80, Radius: 91.5936 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 60, GPM: 88, Radius: 97.5156 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 70, GPM: 95, Radius: 103.4376 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 80, GPM: 102, Radius: 108.9648 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 90, GPM: 108, Radius: 114.0972 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 100, GPM: 114, Radius: 118.44 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6700, Nozzle: 0.67, Pressure: 30, GPM: 70, Radius: 79.3548 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6700, Nozzle: 0.67, Pressure: 40, GPM: 81, Radius: 88.83 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6700, Nozzle: 0.67, Pressure: 50, GPM: 91, Radius: 95.5416 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6700, Nozzle: 0.67, Pressure: 60, GPM: 99, Radius: 101.0688 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6700, Nozzle: 0.67, Pressure: 70, GPM: 107, Radius: 106.9908 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6700, Nozzle: 0.67, Pressure: 80, GPM: 115, Radius: 112.518 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6700, Nozzle: 0.67, Pressure: 90, GPM: 122, Radius: 116.8608 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.6700, Nozzle: 0.67, Pressure: 100, GPM: 128, Radius: 121.9932 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 30, GPM: 79, Radius: 81.7236 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 40, GPM: 91, Radius: 91.9884 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 50, GPM: 102, Radius: 98.7 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 60, GPM: 111, Radius: 105.0168 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 70, GPM: 120, Radius: 110.544 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 80, GPM: 129, Radius: 116.0712 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 90, GPM: 136, Radius: 121.5984 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 100, GPM: 144, Radius: 126.336 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 30, GPM: 97, Radius: 87.2508 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 40, GPM: 112, Radius: 97.5156 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 50, GPM: 126, Radius: 105.0168 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 60, GPM: 138, Radius: 111.3336 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 70, GPM: 148, Radius: 117.2556 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 80, GPM: 159, Radius: 121.9932 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 90, GPM: 168, Radius: 125.9412 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 100, GPM: 178, Radius: 130.284 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 30, GPM: 118, Radius: 91.1988 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 40, GPM: 136, Radius: 100.674 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 50, GPM: 152, Radius: 108.1752 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 60, GPM: 166, Radius: 115.2816 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 70, GPM: 180, Radius: 121.2036 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 80, GPM: 192, Radius: 125.5464 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 90, GPM: 204, Radius: 130.6788 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 100, GPM: 215, Radius: 134.6268 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 30, GPM: 140, Radius: 95.9364 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 40, GPM: 162, Radius: 105.8064 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 50, GPM: 181, Radius: 114.492 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 60, GPM: 198, Radius: 121.9932 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 70, GPM: 214, Radius: 127.5204 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 80, GPM: 229, Radius: 135.4164 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 90, GPM: 242, Radius: 140.154 },
    { EndGunType: EndGunTypes.TwinMax, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 100, GPM: 256, Radius: 143.7072 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 30, GPM: 35, Radius: 76.1964 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 40, GPM: 40, Radius: 81.3288 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 50, GPM: 45, Radius: 84.0924 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 60, GPM: 50, Radius: 86.4612 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 70, GPM: 54, Radius: 90.4092 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 80, GPM: 57, Radius: 92.778 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 90, GPM: 61, Radius: 95.9364 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.4700, Nozzle: 0.47, Pressure: 100, GPM: 64, Radius: 98.7 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 30, GPM: 48, Radius: 76.986 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 40, GPM: 55, Radius: 84.0924 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 50, GPM: 62, Radius: 88.4352 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 60, GPM: 67, Radius: 91.9884 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 70, GPM: 73, Radius: 95.5416 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 80, GPM: 78, Radius: 99.4896 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 90, GPM: 83, Radius: 103.4376 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.5500, Nozzle: 0.55, Pressure: 100, GPM: 87, Radius: 106.2012 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 30, GPM: 62, Radius: 77.7756 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 40, GPM: 71, Radius: 86.856 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 50, GPM: 80, Radius: 92.778 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 60, GPM: 87, Radius: 97.5156 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 70, GPM: 94, Radius: 101.8584 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 80, GPM: 101, Radius: 106.596 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 90, GPM: 107, Radius: 110.9388 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.6300, Nozzle: 0.63, Pressure: 100, GPM: 113, Radius: 114.0972 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 30, GPM: 78, Radius: 78.5652 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 40, GPM: 90, Radius: 90.0144 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 50, GPM: 101, Radius: 97.5156 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 60, GPM: 111, Radius: 103.4376 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 70, GPM: 119, Radius: 108.57 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 80, GPM: 128, Radius: 113.3076 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 90, GPM: 135, Radius: 118.0452 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7100, Nozzle: 0.71, Pressure: 100, GPM: 143, Radius: 121.2036 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 30, GPM: 97, Radius: 79.3548 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 40, GPM: 112, Radius: 93.1728 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 50, GPM: 125, Radius: 102.2532 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 60, GPM: 137, Radius: 108.9648 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 70, GPM: 148, Radius: 114.8868 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 80, GPM: 158, Radius: 120.414 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 90, GPM: 168, Radius: 124.7568 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.7900, Nozzle: 0.79, Pressure: 100, GPM: 177, Radius: 128.31 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 30, GPM: 117, Radius: 80.5392 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 40, GPM: 135, Radius: 95.9364 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 50, GPM: 151, Radius: 106.596 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 60, GPM: 165, Radius: 114.0972 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 70, GPM: 178, Radius: 120.414 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 80, GPM: 191, Radius: 126.336 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 90, GPM: 202, Radius: 131.0736 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.8700, Nozzle: 0.87, Pressure: 100, GPM: 213, Radius: 135.0216 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 30, GPM: 139, Radius: 81.3288 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 40, GPM: 161, Radius: 99.0948 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 50, GPM: 180, Radius: 110.9388 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 60, GPM: 197, Radius: 119.2296 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 70, GPM: 212, Radius: 126.336 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 80, GPM: 227, Radius: 132.258 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 90, GPM: 241, Radius: 137.3904 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 0.9400, Nozzle: 0.94, Pressure: 100, GPM: 254, Radius: 141.7332 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.0200, Nozzle: 1.02, Pressure: 30, GPM: 164, Radius: 82.1184 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.0200, Nozzle: 1.02, Pressure: 40, GPM: 190, Radius: 102.2532 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.0200, Nozzle: 1.02, Pressure: 50, GPM: 212, Radius: 115.6764 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.0200, Nozzle: 1.02, Pressure: 60, GPM: 232, Radius: 125.1516 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.0200, Nozzle: 1.02, Pressure: 70, GPM: 251, Radius: 132.258 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.0200, Nozzle: 1.02, Pressure: 80, GPM: 268, Radius: 138.9696 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.0200, Nozzle: 1.02, Pressure: 90, GPM: 284, Radius: 144.8916 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.0200, Nozzle: 1.02, Pressure: 100, GPM: 300, Radius: 148.8396 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.1000, Nozzle: 1.1, Pressure: 30, GPM: 189, Radius: 82.5132 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.1000, Nozzle: 1.1, Pressure: 40, GPM: 219, Radius: 105.0168 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.1000, Nozzle: 1.1, Pressure: 50, GPM: 245, Radius: 120.0192 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.1000, Nozzle: 1.1, Pressure: 60, GPM: 268, Radius: 130.6788 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.1000, Nozzle: 1.1, Pressure: 70, GPM: 289, Radius: 138.9696 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.1000, Nozzle: 1.1, Pressure: 80, GPM: 309, Radius: 146.076 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.1000, Nozzle: 1.1, Pressure: 90, GPM: 328, Radius: 151.998 },
    { EndGunType: EndGunTypes.Twin101Ultra, NozzleNumberText: "", NozzleNumber: 1.1000, Nozzle: 1.1, Pressure: 100, GPM: 346, Radius: 156.3408 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 25, GPM: 23.6, Radius: 40.866 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 30, GPM: 26, Radius: 43.368 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 35, GPM: 28, Radius: 44.202 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 52.0000, Nozzle: 0.40625, Pressure: 40, GPM: 30, Radius: 45.036 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 25, GPM: 27.3, Radius: 42.534 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 30, GPM: 29.8, Radius: 44.202 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 35, GPM: 32.4, Radius: 45.87 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 56.0000, Nozzle: 0.4375, Pressure: 40, GPM: 34.6, Radius: 46.704 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 25, GPM: 31.2, Radius: 44.202 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 30, GPM: 34.1, Radius: 45.036 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 35, GPM: 36.9, Radius: 45.87 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 60.0000, Nozzle: 0.46875, Pressure: 40, GPM: 39.7, Radius: 46.704 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 64.0000, Nozzle: 0.5, Pressure: 25, GPM: 35.4, Radius: 45.87 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 64.0000, Nozzle: 0.5, Pressure: 30, GPM: 38.8, Radius: 47.538 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 64.0000, Nozzle: 0.5, Pressure: 35, GPM: 42, Radius: 49.206 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 64.0000, Nozzle: 0.5, Pressure: 40, GPM: 44.9, Radius: 49.206 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 68.0000, Nozzle: 0.53125, Pressure: 25, GPM: 39.8, Radius: 45.87 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 68.0000, Nozzle: 0.53125, Pressure: 30, GPM: 43.7, Radius: 47.538 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 68.0000, Nozzle: 0.53125, Pressure: 35, GPM: 47.2, Radius: 49.206 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 68.0000, Nozzle: 0.53125, Pressure: 40, GPM: 50.6, Radius: 50.04 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 72.0000, Nozzle: 0.5625, Pressure: 25, GPM: 44.4, Radius: 46.704 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 72.0000, Nozzle: 0.5625, Pressure: 30, GPM: 48.8, Radius: 48.372 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 72.0000, Nozzle: 0.5625, Pressure: 35, GPM: 52.6, Radius: 50.04 },
    { EndGunType: EndGunTypes.R75LP, NozzleNumberText: "", NozzleNumber: 72.0000, Nozzle: 0.5625, Pressure: 40, GPM: 56.4, Radius: 50.874 },
];

export default EndGunInfo;

export interface EndGunItem {
    EndGunType: EndGunTypes;
    NozzleNumberText: string;
    NozzleNumber: number;
    Nozzle: number;
    Pressure: number;
    GPM: number;
    Radius: number;
}