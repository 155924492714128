import { DeviceTypes, IPackage, RegulatorTypes } from "../../../rdptypes/project/ISprinklers";
import { SprinklerManufacturerTypes } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import { HasSwingArmCorner, IsDualSided } from "../SystemFunctions";
import { PSIOptionSenningerOk, RegsAsNeededOptionSenningerOk, RegulatorTypeOptionSenningerOk } from "../Valids.Valids";

export default class SprinklersSennRegulatorForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        let sprinklerProps = this.Quote.System.SprinklerProperties;
        if (sprinklerProps.SprinklerEngineCode !== SprinklerManufacturerTypes.Senninger) return;

        this.validatePackages(this.Quote.System.FlangedSide.Sprinklers.Package, "Flanged");
        if (IsDualSided(this.Quote.System) && this.Quote.System.FlexSide.Sprinklers.Package){
            this.validatePackages(this.Quote.System.FlexSide.Sprinklers.Package, "Flex");
        }
    }

    validatePackages = (pkgs: IPackage[], side: string) => {
        if (typeof(pkgs) !== "undefined"){
            for (let i = 0; i < pkgs.length; i++) {
                const pkg = pkgs[i];
                
                if (pkg.Device === DeviceTypes.None){
                    break;//packages get created even if we don't actually add them
                }

                if ((pkg.Regulator.RegulatorType ?? RegulatorTypes.None) !== RegulatorTypes.None) {
                    if (!RegulatorTypeOptionSenningerOk(pkg.Regulator.RegulatorType, {
                        HasSAC: HasSwingArmCorner(this.Quote.System),
                        DeviceType: pkg.Device,
                    })) {
                        this.Quote.Warnings.Add(`Senninger regulator type is not OK for ${side.toLowerCase()} package ${i + 1}`);
                    }

                    if (!pkg.Regulator.Pressure || !PSIOptionSenningerOk(pkg.Regulator.Pressure, {
                        RegulatorType: pkg.Regulator.RegulatorType,
                        DeviceType: pkg.Device,
                        PackageNumber: pkg.PackageNumber,
                        DropType: pkg.Drop,
                    })){
                        this.Quote.Warnings.Add(`Selected PSI option is not OK for ${side.toLowerCase()} Senninger sprinkler package ${i + 1}`);
                    }
                }

                if (!RegsAsNeededOptionSenningerOk(pkg.Regulator.AsNeeded, {
                    RegulatorType: pkg.Regulator.RegulatorType,
                    HasSAC: HasSwingArmCorner(this.Quote.System),
                    DeviceType: pkg.Device,
                    RegulatorPressure: pkg.Regulator.Pressure,
                })){
                    this.Quote.Warnings.Add(`"Use only when needed" cannot be selected for current ${side.toLowerCase()} Senninger sprinkler package ${i  +1} configuration`);
                }

                if (pkg.EndingLocation <= 0) {
                    return;
                }
            }
        }
    }
}