import {
    BaseInletSizes,
    CF200Pumps,
    DragHoseStandardLengths,
    GearDriveTypes,
    HoseAndCouplerKitTypes,
    HoseFeedTypes,
    ISystemBase,
    InletTypes,
    JackKitTypes,
    PivotingLateralPivotCenterTypes,
    SpanTypes,
    SystemTypes,
    TireSizes,
    WaterFeedTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import { enableJackKit } from '../SystemHelpers';
import schema from "../data/poSchema";
import CSection2 from "./CSection2";
import { TireSelector } from "./CommonFunctions";
import PartsAssembler from "./PartsAssembler";
import QuoteClass, { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as spanf from "./SpanFunctions";
import { idxCableConductors } from "./SpanFunctions";
import * as sysf from "./SystemFunctions";
import { strLanguageText } from "./TranslationsLib";
import { Side, Span, Tires } from "./Types";
import { BaseInletsOK, CenterLiftOK, HoseAndCouplersOK, HoseFeedGearDriveOK, HoseLengthOK, HydraulicLiftOK, HydraulicPumpOK, InletTypeOk, PivotKitOK, PowerTowerEndBoomOK, ReverseTowOK, SingleSpanMaxiOK, TireSizesOk, TowableOK, TubesOK, WaterFeedOK } from "./Valids.Valids";
import { StringFormat } from "./VbCompat";
export class HoseFeedClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        let q: QuoteClass = this.Quote;
        let sys: ISystemBase = q.System;
        const hf = sys.Lateral.HoseFeed;
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(strLanguageText("sltHoseFeedSection"));
        return false;
        }*/
        if (hf.HoseFeedType === HoseFeedTypes.PivotingLateral && hf.PivotingLateralPivotCenterType === PivotingLateralPivotCenterTypes.None) {
            this.Quote.Warnings.Add(strLanguageText("sltPivotingLateralPivotCenterType"));
        }
        let eWaterFeed: WaterFeedTypes = sys.Lateral.WaterFeed;
        if (!(TireSizesOk(sys.Lateral.Tires.TireSize, { HoseFeedType: hf.HoseFeedType }))) {
            this.Quote.Warnings.AddWithTargets(["Lateral.Tires.TireSize"],strLanguageText("sltTireSize"));
        }
        if (!(SingleSpanMaxiOK(hf.SingleSpanMaxi, {
            HoseFeedType: hf.HoseFeedType,
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltSingleSpanMaxi"));
        }
        if (!(HoseFeedGearDriveOK(sys.Lateral.GearDrive, hf.HoseFeedType))) {
            this.Quote.Warnings.Add(strLanguageText("sltCenterDrive"));
        }
        if (!(BaseInletsOK(hf.BaseInletSize, { HoseFeedType: hf.HoseFeedType, HoseAndCouplerKitType: hf.HoseAndCouplerKit, InletType: hf.InletType }))) {
            this.Quote.Warnings.AddWithTargets(["Lateral.HoseFeed.BaseInletSize"], strLanguageText("sltHoseDiameter"));
        }
        if (!(InletTypeOk(hf.InletType, { HoseFeedType: hf.HoseFeedType, Country: sys.DealerProperties.Country }))) {
            this.Quote.Warnings.Add(strLanguageText("sltPivotingLateral"));
        }
        if (!(WaterFeedOK(eWaterFeed, {
            HoseFeedType: hf.HoseFeedType, IsSingleSpanMaxi: hf.SingleSpanMaxi
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltWaterFeed"));
        }
        if (!(HoseAndCouplersOK(hf.HoseAndCouplerKit, {
            HoseFeedType: hf.HoseFeedType,
            InletType: hf.InletType,
            BaseInletSize: hf.BaseInletSize
        }))) {
            this.Quote.Warnings.AddWithTargets(["Lateral.HoseFeed.HoseAndCouplerKit"],strLanguageText("sltHoseAndCouplers"));
        }

        let centerLiftAfterImproveScores = hf.CenterLift;
        if (!(CenterLiftOK(hf.CenterLift, {
            HoseFeedType: hf.HoseFeedType,
            WaterFeedType: eWaterFeed,
            EndingLocation: sidef.NumberOfSpans(sys.FlangedSide) > 0
                ? spanf.EndingLocation(this.Quote.System, sys.FlangedSide, sys.FlangedSide.Span[sidef.NumberOfSpans(sys.FlangedSide) - 1])
                : 0,
        }))) {
            centerLiftAfterImproveScores = !centerLiftAfterImproveScores;
            this.Quote.Warnings.AddWithTargets(["Lateral.HoseFeed.CenterLift"],strLanguageText("sltCenterLift"));
        }

        let hydralicLiftAfterImproveScores = hf.HydraulicLift;
        if (!(HydraulicLiftOK(hf.HydraulicLift, hf.HoseFeedType, eWaterFeed, centerLiftAfterImproveScores, hf.PivotOption, hf.Towable))) {
            hydralicLiftAfterImproveScores = !hydralicLiftAfterImproveScores;
            this.Quote.Warnings.AddWithTargets(["Lateral.HoseFeed.HydraulicLift"],strLanguageText("sltHydraulicCylinderKit"));
        }

        if (!(HydraulicPumpOK(hf.HydraulicPumpKit, hydralicLiftAfterImproveScores, centerLiftAfterImproveScores))) {
            this.Quote.Warnings.AddWithTargets(["Lateral.HoseFeed.HydraulicPumpKit"],strLanguageText("sltHydraulicPumpKit"));
        }
        if (!(PivotKitOK(hf.PivotOption, {
            HoseFeedType: hf.HoseFeedType,
            WaterFeedType: eWaterFeed,
            HasCenterLift: centerLiftAfterImproveScores
        }))) {
            this.Quote.Warnings.AddWithTargets(["Lateral.HoseFeed.PivotOption"], strLanguageText("sltPivotKit"));
        }
        if (!(TowableOK(hf.Towable, {
            WaterFeed: eWaterFeed, TireSize: sys.Lateral.Tires.TireSize, HoseFeedType: hf.HoseFeedType
        }))) {
            this.Quote.Warnings.AddWithTargets(["Lateral.HoseFeed.Towable"], strLanguageText("sltTowable"));
        }
        if (hf.Towable) {
            this.Quote.Warnings.Add(strLanguageText("sltDontTowDownhill"), WarningLevels.Informational);
        }
        if (!(ReverseTowOK(sys.SystemProperties.ReverseTow, hf.Towable, hf.HoseFeedType))) {
            this.Quote.Warnings.Add(strLanguageText("sltReverseTow"));
        }
        let t: Tires = sys.Lateral.Tires;
        if (!(TubesOK(t.TubesForTires, {
            TireSize: t.TireSize,
            TireType: t.TireType,
        }))) {
            this.Quote.Warnings.Add(strLanguageText("sltTubesForTires"));
        }
        switch (hf.HoseAndCouplerKit) {
            case HoseAndCouplerKitTypes.HardHoseAdapter:
            case HoseAndCouplerKitTypes.CouplersOnly:
            case HoseAndCouplerKitTypes.HoseWithCouplers:
                if (hf.HardHoseAdapters < 1 || hf.HardHoseAdapters > 9) {
                    this.Quote.Warnings.Add(strLanguageText("sltOnly19Allowed"));
                }
                if (hf.HoseFeedType === HoseFeedTypes.PivotingLateral && hf.HoseAndCouplerKit === HoseAndCouplerKitTypes.HardHoseAdapter) {
                    this.Quote.Warnings.Add(strLanguageText("sltHardHoseAdapterNotSoldInPairs"), WarningLevels.Decision);
                }
                break;
            default:
                if (hf.HardHoseAdapters !== undefined && hf.HardHoseAdapters !== 0) {
                    //don't worry about this here - support default hard hose adapter quantity of 1 but check elsewhere that HoseAndCouplerKitTypes is not None (wherever we insert PO record)
                    //this.Quote.Warnings.Add(strLanguageText("sltHardHoseAdapter"));
                }
                break;
        }
         // The UI doesn't show the hose feed length option if hose is not selected
        if (hf.HoseAndCouplerKit === HoseAndCouplerKitTypes.HoseWithCouplers) {
            if (!(HoseLengthOK(hf.DragHoseStandardLength, {
                HoseFeedType: hf.HoseFeedType,
                HoseAndCouplers: hf.HoseAndCouplerKit,
                BaseInlets: hf.BaseInletSize,
                InletType: hf.InletType,
            }))) {
                this.Quote.Warnings.Add(strLanguageText("sltHoseLength"));
            }
            if (hf.DragHoseNonStandardLength > 0 && hf.DragHoseStandardLength !== DragHoseStandardLengths.NonStandard) {
                this.Quote.Warnings.Add(strLanguageText("sltHoseQuantity"));
            }
        }
        if (!(PowerTowerEndBoomOK(hf.PowerTowerEndBoom, {
            BaseInletSize: hf.BaseInletSize,
            WaterFeedType: eWaterFeed,
            HoseFeedType: hf.HoseFeedType,
        }))) {
            this.Quote.Warnings.AddWithTargets(
                ["Lateral.HoseFeed.PowerTowerEndBoom"],
                strLanguageText("sltPowerTowerEnd"));
        }
        if (hf.ElectricDragCordLength > 0 && (hf.PivotOption || hf.CenterLift || [HoseFeedTypes.A100, HoseFeedTypes.CF200].indexOf(hf.HoseFeedType) !== -1)) {
            this.Quote.Warnings.Add(strLanguageText("sltElectricDragCord"));
        }
        if (hf.JunctionBoxWithPlug) {
            if (hf.ElectricDragCordLength === 0) {
                this.Quote.Warnings.Add(strLanguageText("sltJunctionBoxWithPlug"));
            }
        }
        if (hf.JunctionBoxWithoutPlug > 0) {
            if (!(hf.JunctionBoxWithPlug)) {
                this.Quote.Warnings.Add(strLanguageText("sltJunctionBoxWithOutPlug"));
            }
        }
        if (sys.Lateral.Tires.TireSize === TireSizes.a136x38) {
            this.Quote.Warnings.Add(strLanguageText("sltDragHoseWith136x38Tire"), WarningLevels.Decision);
        }
        switch (hf.HoseFeedType) {
            case HoseFeedTypes.Standard:
            case HoseFeedTypes.DoubleEndFeed:
                switch (hf.BaseInletSize) {
                    case BaseInletSizes.a4:
                        if (hf.DragHoseNonStandardLength > 400) {
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong1"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong2a"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong3"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong4"), WarningLevels.Decision);
                        }
                        break;
                    case BaseInletSizes.a5:
                        if (hf.DragHoseNonStandardLength > 260) {
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong1"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong2a"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong3"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong4"), WarningLevels.Decision);
                        }
                        break;
                }
                break;
            default:
                switch (hf.BaseInletSize) {
                    case BaseInletSizes.a4:
                        if (hf.DragHoseNonStandardLength > 560) {
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong1"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong2b"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong3"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong4"), WarningLevels.Decision);
                        }
                        break;
                    case BaseInletSizes.a5:
                        if (hf.DragHoseNonStandardLength > 420) {
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong1"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong2b"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong3"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong4"), WarningLevels.Decision);
                        }
                        break;
                    case BaseInletSizes.a6:
                        if (hf.DragHoseNonStandardLength > 300) {
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong1"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong2b"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong3"), WarningLevels.Decision);
                            this.Quote.Warnings.Add(strLanguageText("sltDragHoseTooLong4"), WarningLevels.Decision);
                        }
                        break;
                }
                break;
        }
        this.Quote.Warnings.Add(strLanguageText("sltMaxiSlopeWarning"), WarningLevels.Informational);
        if (hf.SafetyValve) {
            if (sysf.IsEDMP(this.Quote.System) || [HoseFeedTypes.A100, HoseFeedTypes.CF200].indexOf(hf.HoseFeedType) !== -1) {
                this.Quote.Warnings.Add(strLanguageText("sltSafetyValve"));
            }
        }
        if (sysf.FieldSets(this.Quote.System).SprinklerConfig.DataValid()) {
            let baseInletPressure: number = sys.SprinklerProperties.PivotPressure + q.BasePressureMod();
            if ([HoseAndCouplerKitTypes.HoseWithCouplers, HoseAndCouplerKitTypes.CouplersOnly].indexOf(sys.Lateral.HoseFeed.HoseAndCouplerKit) !== -1 && baseInletPressure < 45) {
                this.Quote.Warnings.Add(StringFormat(strLanguageText("sltHoseFeedSoftHoseMin45PSIRequired"), `${45 - baseInletPressure}`), WarningLevels.Decision);
            }
        }
    }
    public WritePO = (PO: PartsAssembler) => {
        let eFirstRightSpanType: SpanTypes = SpanTypes.E2100;
        let eFirstLeftSpanType: SpanTypes = SpanTypes.E2100;
        let eFirstSpanLength: number;
        let sFirstSpanLengthInFeet: number;
        let eLastRightSpanLength: number;
        let bJackKit: boolean;
        let bSafetyValve: boolean;
        let iEndBoom: number;
        let iSecEndBoom: number;
        let b14C: boolean = false;
        let TireQty: number;
        let iSelectedTire: number;
        let iTopQty: number;
        if (this.Quote.RightSpansClass.FieldSet.DataValid()) {
            let s: Side = this.Quote.System.FlangedSide;
            let i: number = sidef.NumberOfSpans(s);
            if (i > 0) {
                let sp: Span = s.Span[1 - 1];
                eFirstRightSpanType = sp.SpanType;
                if (spanf.CableConductors(this.Quote.System, this.Quote.System.FlangedSide, sp) === idxCableConductors.cc14) {
                    b14C = true;
                }
                eFirstSpanLength = sp.Length;
                sFirstSpanLengthInFeet = sp.Length;
                eLastRightSpanLength = s.Span[i - 1].Length;
            }
        }
        if (this.Quote.LeftSpansClass.FieldSet.DataValid()) {
            if (sidef.NumberOfSpans(this.Quote.System.FlexSide) > 0) {
                eFirstLeftSpanType = this.Quote.System.FlexSide.Span[1 - 1].SpanType;
                if (spanf.CableConductors(this.Quote.System, this.Quote.System.FlexSide, this.Quote.System.FlexSide.Span[1 - 1]) === idxCableConductors.cc14) {
                    b14C = true;
                }
            }
        }
        bJackKit = false;
        bSafetyValve = false;
        if (this.Quote.OptionsClass.FieldSet.DataValid()) {
            if (enableJackKit(this.Quote.System) && this.Quote.System.Options.JackKit !== JackKitTypes.None) {
                bJackKit = true;
            }
            bSafetyValve = this.Quote.System.Lateral.HoseFeed.SafetyValve;
        }
        if (this.Quote.RightEOSClass.FieldSet.DataValid()) {
            iSecEndBoom = this.Quote.RightEOSClass.EndBoomLength();
        }
        else {
            iSecEndBoom = 1;
        }
        if (this.Quote.LeftEOSClass.FieldSet.DataValid()) {
            iEndBoom = this.Quote.LeftEOSClass.EndBoomLength();
        }
        else {
            iEndBoom = 1;
        }
        switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
            case HoseFeedTypes.Standard:
                if (this.Quote.System.Lateral.WaterFeed === WaterFeedTypes.CenterFeed && (this.Quote.System.Lateral.DropFlexSide)) {
                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "396576-G", 1); // TWR ASY-MAXI-CNTR FD-DROP SPAN
                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "396577", 1); // JOINT-SPAN-DISC/WINCH-CNTR FD
                }
                else {
                    if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                        PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "394900", 1); // MASTER TWR-REV ON HOSE
                    }
                    else {
                        PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "395810-G", 1); // TOWER ASY-MASTER-MAXI-EII
                    }
                }
                break;
            case HoseFeedTypes.DoubleEndFeed:
                PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "395810-G", 1); // TOWER ASY-MASTER-MAXI-EII
                break;
            case HoseFeedTypes.A100:
                PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "395950", 1);  // TOWER ASY-A100-MAXI
                break;
            case HoseFeedTypes.CF200:
                PO.InsertPart(schema.groups.HoseFeed_FlangedTowerTop.groupId, "396044", 1);  // PRIMING PUMP KIT-CF200
                PO.InsertPart(schema.groups.HoseFeed_FlangedTowerTop.groupId, "352533", 1);  // BOX-LAT RELAY-CF200
                break;
            case HoseFeedTypes.FourWheelDrive:
                if (this.Quote.System.Lateral.WaterFeed === WaterFeedTypes.CenterFeed && (this.Quote.System.Lateral.DropFlexSide)) {
                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "396576-G", 1); // TWR ASY-MAXI-CNTR FD-DROP SPAN
                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "396577", 1); // JOINT-SPAN-DISC/WINCH-CNTR FD
                }
                else {
                    switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                        case BaseInletSizes.a4:
                        case BaseInletSizes.a5:
                        case BaseInletSizes.a6:
                            if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                                PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "394900", 1); // MASTER TWR-REV ON HOSE
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "395810-G", 1); // TOWER ASY-MASTER-MAXI-EII
                            }
                            break;
                        case BaseInletSizes.a8:
                            if (this.Quote.HoseFeedClass.FieldSet.DataValid()) {
                                if (!(this.Quote.System.Lateral.HoseFeed.SafetyValve)) {
                                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "395822", 1); // PWR TWR-8" MAXI W/O VALVE-EII
                                }
                                else {
                                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "395821", 1); // PWR TWR-8" MAXI W/8" VALVE EII
                                }
                            }
                            break;
                    }
                }
                break;
            case HoseFeedTypes.Sugargator:
                if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "394910", 1); // MASTER TOWER-SUGAR-REV ON HOSE
                }
                else {
                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "395831-G", 1); // POWER TWR ASY-SUGAR MAXI-EII
                }
                break;
            case HoseFeedTypes.PivotingLateral:
                PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "395970", 1); // TOWER BASE-PIVOTING-LATERAL
                PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "395996", 1); // PROX SWITCH KIT
                break;
        }
        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.DoubleEndFeed) {
            PO.InsertPart(schema.groups.HoseFeed_SecondaryPowerTowerAssembly.groupId, "395812-G", 1); // PWR TWR-SECOND-DBL END FD-EII
        }
        switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
            case HoseFeedTypes.FourWheelDrive:
            case HoseFeedTypes.DoubleEndFeed:
            case HoseFeedTypes.Sugargator:
            case HoseFeedTypes.PivotingLateral:
                TireQty = 2;
                break;
            default:
                TireQty = 1;
                break;
        }
        switch (this.Quote.System.SystemProperties.SystemType) {
            case SystemTypes.HoseFeedMaxigator:
                if (this.Quote.HoseFeedClass.FieldSet.DataValid()) {
                    if (this.Quote.System.Lateral.HoseFeed.SafetyValve) {
                        switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
                            case HoseFeedTypes.FourWheelDrive:
                                if (this.Quote.System.Lateral.HoseFeed.BaseInletSize !== BaseInletSizes.a8) {
                                    PO.InsertPart(schema.groups.OptionsandAccessories_SafetyValve.groupId, "352163", 1); // SLEEVE VALVE-6"WTR SHUTDN-MAXI
                                }
                                break;
                            case HoseFeedTypes.Sugargator:
                                PO.InsertPart(schema.groups.OptionsandAccessories_SafetyValve.groupId, "352167", 1); // VALVE-6" WTR SHUTDN-MAXI-SG
                                break;
                            case HoseFeedTypes.PivotingLateral:
                                switch (this.Quote.System.Lateral.HoseFeed.InletType) {
                                    case InletTypes.AutoReverseOverHose:
                                        PO.InsertPart(schema.groups.OptionsandAccessories_SafetyValve.groupId, "352165", 1); // VALVE-SLEEVE-W/PRESS SW-6"
                                        break;
                                    case InletTypes.StandardHose:
                                        switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                                            case BaseInletSizes.a6:
                                            case BaseInletSizes.a5:
                                            case BaseInletSizes.a45:
                                            case BaseInletSizes.a4:
                                                PO.InsertPart(schema.groups.OptionsandAccessories_SafetyValve.groupId, "352165", 1); // VALVE-SLEEVE-W/PRESS SW-6"
                                                break;
                                            case BaseInletSizes.a8:
                                                PO.InsertPart(schema.groups.OptionsandAccessories_SafetyValve.groupId, "352168", 1); // VALVE-SLEEVE-W/PRESS SW-8"
                                                break;
                                        }
                                        break;
                                    case InletTypes.DualHose:
                                        switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                                            case BaseInletSizes.a6:
                                            case BaseInletSizes.a5:
                                            case BaseInletSizes.a45:
                                            case BaseInletSizes.a4:
                                                PO.InsertPart(schema.groups.OptionsandAccessories_SafetyValve.groupId, "352165", 1); // VALVE-SLEEVE-W/PRESS SW-6"
                                                break;
                                            case BaseInletSizes.a8:
                                                PO.InsertPart(schema.groups.OptionsandAccessories_SafetyValve.groupId, "352168", 1); // VALVE-SLEEVE-W/PRESS SW-8"
                                                break;
                                        }
                                        break;
                                    default:
                                        PO.InsertPart(schema.groups.OptionsandAccessories_SafetyValve.groupId, "352163", 1); // SLEEVE VALVE-6"WTR SHUTDN-MAXI
                                        break;
                                }
                                break;
                            default:
                                PO.InsertPart(schema.groups.OptionsandAccessories_SafetyValve.groupId, "352163", 1); // SLEEVE VALVE-6"WTR SHUTDN-MAXI
                                break;
                        }
                    }
                }
                break;
            case SystemTypes.KwikTow:
                if (this.Quote.System.Lateral.HoseFeed.SafetyValve) {
                    PO.InsertPart(schema.groups.OptionsandAccessories_SafetyValve.groupId, "352164", 1); // SLEEVE VALVE-6"WTR SHUTDOWN-KT
                }
                break;
        }
        let t: Tires = this.Quote.System.Lateral.Tires;
        iSelectedTire = TireSelector(t.TireSize, t.TireType, t.OneTireMountedInReverse, t.TubesForTires);
        PO.InsertPartByIteration(schema.groups.HoseFeed_TireandWheelAssembly.groupId, iSelectedTire, TireQty);
        let useDropSpanOption: boolean = this.Quote.System.Lateral.HoseFeed.BaseInletSize !== BaseInletSizes.a8 && !(b14C) && [HoseFeedTypes.Standard, HoseFeedTypes.FourWheelDrive].indexOf(this.Quote.System.Lateral.HoseFeed.HoseFeedType) !== -1 && this.Quote.System.Lateral.WaterFeed === WaterFeedTypes.CenterFeed && this.Quote.System.Lateral.DropFlexSide;
        if (useDropSpanOption) {
            if (this.Quote.System.Lateral.ButterflyValves) {
                PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "396578", 1); // JOINT-SPAN-DISC-CNTR FD W/VLVS
            }
            else {
                PO.InsertPart(schema.groups.HoseFeed_ReceiverJointTowerTop.groupId, "396579", 1); // JOINT-SPAN-DISC-CNTR FD EII
            }
        }
        switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
            case HoseFeedTypes.FourWheelDrive:
            case HoseFeedTypes.Sugargator:
                if (this.Quote.System.Lateral.HoseFeed.HoseAndCouplerKit === HoseAndCouplerKitTypes.HardHoseAdapter) {
                    switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                        case BaseInletSizes.a4:
                        case BaseInletSizes.a5:
                        case BaseInletSizes.a6:
                            if (!((this.Quote.System.Lateral.HoseFeed.CenterLift || this.Quote.System.Lateral.HoseFeed.Towable || this.Quote.System.Lateral.HoseFeed.PivotOption))) {
                                if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                                    PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "394902", 1); // TWR BASE-4WD-NT-REV ON HOSE
                                }
                                else {
                                    PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395825", 1); // TWR BASE-6"-4WD NON TOW MAXI
                                }
                            }
                            else {
                                if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                                    PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "394903", 1); // TWR BASE-4WD-TOW-REV ON HOSE
                                }
                                else {
                                    PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395839-G", 1); // TOWER BASE-4WD MAXI-6"-GALV
                                }
                            }
                            break;
                        case BaseInletSizes.a8:
                            if (!((this.Quote.System.Lateral.HoseFeed.CenterLift || this.Quote.System.Lateral.HoseFeed.Towable || this.Quote.System.Lateral.HoseFeed.PivotOption))) {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395826", 1); // TWR BASE-8"-4WD NON TOW MAXI
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395849-G", 1); // TOWER BASE-8" 4WD MAXI-GALV
                            }
                            break;
                    }
                }
                else {
                    switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                        case BaseInletSizes.a4:
                            if (!((this.Quote.System.Lateral.HoseFeed.CenterLift || this.Quote.System.Lateral.HoseFeed.Towable || this.Quote.System.Lateral.HoseFeed.PivotOption))) {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395823", 1); // TWR BASE-4"-4WD NON TOW MAXI
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395837-G", 1); // TOWER BASE-4WD MAXI-4"-GALV
                            }
                            break;
                        case BaseInletSizes.a5:
                            if (!((this.Quote.System.Lateral.HoseFeed.CenterLift || this.Quote.System.Lateral.HoseFeed.Towable || this.Quote.System.Lateral.HoseFeed.PivotOption))) {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395824", 1); // TWR BASE-5"-4WD NON TOW MAXI
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395838-G", 1); // TOWER BASE-4WD MAXI-5"-GALV
                            }
                            break;
                        case BaseInletSizes.a6:
                            if (!((this.Quote.System.Lateral.HoseFeed.CenterLift || this.Quote.System.Lateral.HoseFeed.Towable || this.Quote.System.Lateral.HoseFeed.PivotOption))) {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395825", 1); // TWR BASE-6"-4WD NON TOW MAXI
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395839-G", 1); // TOWER BASE-4WD MAXI-6"-GALV
                            }
                            break;
                        case BaseInletSizes.a8:
                            if (!((this.Quote.System.Lateral.HoseFeed.CenterLift || this.Quote.System.Lateral.HoseFeed.Towable || this.Quote.System.Lateral.HoseFeed.PivotOption))) {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395826", 1); // TWR BASE-8"-4WD NON TOW MAXI
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395849-G", 1); // TOWER BASE-8" 4WD MAXI-GALV
                            }
                            break;
                    }
                }
                break;
            case HoseFeedTypes.Standard:
                if (this.Quote.System.Lateral.HoseFeed.HoseAndCouplerKit === HoseAndCouplerKitTypes.HardHoseAdapter) {
                    if (!(bJackKit)) {
                        if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                            PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "394901", 1); // TWR BASE-2WD-REV ON HOSE
                        }
                        else {
                            PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395844-G", 1); // TOWER BASE OPT-MAXI-6" INLETS
                        }
                    }
                    else {
                        PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395845-G", 1); // TOWER BASE-MAXI-6" W/JK BRKTS
                    }
                }
                else {
                    switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                        case BaseInletSizes.a4:
                            if (!(bJackKit)) {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395840-G", 1); // TOWER BASE OPT-MAXI-4"-GALV
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395841-G", 1); // TOWER BASE-MAXI-4" W/JK BRKTS
                            }
                            break;
                        case BaseInletSizes.a5:
                            if (!(bJackKit)) {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395842-G", 1); // TOWER BASE OPT-MAXI-5" INLETS
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395843-G", 1); // TOWER BASE-MAXI-5" W/JK BRKTS
                            }
                            break;
                        case BaseInletSizes.a6:
                            if (!(bJackKit)) {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395844-G", 1); // TOWER BASE OPT-MAXI-6" INLETS
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395845-G", 1); // TOWER BASE-MAXI-6" W/JK BRKTS
                            }
                            break;
                    }
                }
                break;
            case HoseFeedTypes.DoubleEndFeed:
                if (this.Quote.System.Lateral.HoseFeed.HoseAndCouplerKit === HoseAndCouplerKitTypes.HardHoseAdapter) {
                    if (!(bJackKit)) {
                        PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395844-G", 2); // TOWER BASE OPT-MAXI-6" INLETS
                    }
                    else {
                        PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395845-G", 2); // TOWER BASE-MAXI-6" W/JK BRKTS
                    }
                }
                else {
                    switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                        case BaseInletSizes.a4:
                            if (!(bJackKit)) {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395840-G", 2); // TOWER BASE OPT-MAXI-4"-GALV
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395841-G", 2); // TOWER BASE-MAXI-4" W/JK BRKTS
                            }
                            break;
                        case BaseInletSizes.a5:
                            if (!(bJackKit)) {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395842-G", 2); // TOWER BASE OPT-MAXI-5" INLETS
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395843-G", 2); // TOWER BASE-MAXI-5" W/JK BRKTS
                            }
                            break;
                        case BaseInletSizes.a6:
                            if (!(bJackKit)) {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395844-G", 2); // TOWER BASE OPT-MAXI-6" INLETS
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395845-G", 2); // TOWER BASE-MAXI-6" W/JK BRKTS
                            }
                            break;
                    }
                }
                break;
            case HoseFeedTypes.A100:
                PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "395953", 1); // TWR BASE OPT-6"-A100 MAXI
                break;
        }
        switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
            case HoseFeedTypes.Standard:
            case HoseFeedTypes.DoubleEndFeed:
                switch (this.Quote.System.Lateral.GearDrive) {
                    case GearDriveTypes.Standard:
                        PO.InsertPart(schema.groups.HoseFeed_PowerTowerGears.groupId, "392128", 1); // CD OPT-STD SPD-HEL6.5'-2WD MAX
                        break;
                    case GearDriveTypes.High:
                        PO.InsertPart(schema.groups.HoseFeed_PowerTowerGears.groupId, "392129", 1); // CD OPT-HI SPD-HEL6.5'-2WD MAXI
                        break;
                }
                break;
            case HoseFeedTypes.A100:
                PO.InsertPart(schema.groups.HoseFeed_PowerTowerGears.groupId, "395951", 1); // CD OPT-2WD A100 MAXI
                break;
            case HoseFeedTypes.CF200:
                PO.InsertPart(schema.groups.HoseFeed_PowerTowerGears.groupId, "392156", 1);  // HEL GEAR OPT STD SPD CF200
                break;
            case HoseFeedTypes.PivotingLateral:
                switch (this.Quote.System.Lateral.GearDrive) {
                    case GearDriveTypes.Standard:
                        PO.InsertPart(schema.groups.ControlPanel_VFDPivotingLateral.groupId, "350816", 1); // PLC-VFD PANEL-PIVOTING LATERAL
                        if (this.Quote.System.Lateral.HoseFeed.RotateCart) {
                            PO.InsertPart(schema.groups.HoseFeed_PowerTowerGears.groupId, "395974", 1); // CD OPTION-40:1-ROTATE CART
                        }
                        else {
                            PO.InsertPart(schema.groups.HoseFeed_PowerTowerGears.groupId, "395975", 1); // GEAR OPT-40:1-PIVOTING LATERAL
                        }
                        break;
                }
                break;
            default:
                switch (this.Quote.System.Lateral.GearDrive) {
                    case GearDriveTypes.Standard:
                        PO.InsertPart(schema.groups.HoseFeed_PowerTowerGears.groupId, "396451", 1); // HELICAL GEAR OPT-STD SPD-4WD M
                        break;
                    case GearDriveTypes.High:
                        PO.InsertPart(schema.groups.HoseFeed_PowerTowerGears.groupId, "396452", 1); // HELICAL GEAR OPT-HI SPD-4WD MX
                        break;
                }
                break;
        }
        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.DoubleEndFeed) {
            switch (this.Quote.System.Lateral.GearDrive) {
                case GearDriveTypes.Standard:
                    PO.InsertPart(schema.groups.HoseFeed_SecondaryPowerTowerGears.groupId, "392132", 1); // CD OPT-STD SPD-HEL-2WD MAXI
                    break;
                case GearDriveTypes.High:
                    PO.InsertPart(schema.groups.HoseFeed_SecondaryPowerTowerGears.groupId, "392133", 1); // CD OPT-HI SPD-HEL-2WD MAXI
                    break;
            }
        }
        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.A100) {
            PO.InsertPart(schema.groups.HoseFeed_A100Pump.groupId, "395955", 1);  // PUMP/SCREEN-15 HP,650 GPM-A100
        }
        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200) {
            switch (this.Quote.System.Lateral.HoseFeed.CF200Pump) {
                case CF200Pumps.Hp15:
                    PO.InsertPart(schema.groups.HoseFeed_A100Pump.groupId, "358891", 1);  // PUMP PANEL KIT 15HP-CF200
                    PO.InsertPart(schema.groups.HoseFeed_A100Pump.groupId, "396050", 1);  // CORNELL 15HP-4RB PUMP
                    break;
                case CF200Pumps.Hp20:
                    PO.InsertPart(schema.groups.HoseFeed_A100Pump.groupId, "358892", 1);  // PUMP PANEL KIT 20/25HP-CF200
                    PO.InsertPart(schema.groups.HoseFeed_A100Pump.groupId, "396051", 1);  // CORNELL 20HP-4RB PUMP
                    break;
                case CF200Pumps.Hp25:
                    PO.InsertPart(schema.groups.HoseFeed_A100Pump.groupId, "358892", 1);  // PUMP PANEL KIT 20/25HP-CF200
                    PO.InsertPart(schema.groups.HoseFeed_A100Pump.groupId, "396052", 1);  // CORNELL 25HP-4RB PUMP
                    break;
            }
        }
        switch (this.Quote.System.Lateral.WaterFeed) {
            case WaterFeedTypes.EndFeed:
                if (!(this.Quote.System.Lateral.HoseFeed.PowerTowerEndBoom)) {
                    if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                        switch (eFirstRightSpanType) {
                            case SpanTypes.E2060:
                            case SpanTypes.E2065:
                            case SpanTypes.E2660:
                            case SpanTypes.E2665:
                                if (this.Quote.System.Lateral.HoseFeed.PivotCenterWithSSRiserPipe) {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396024SS", 1); // PIVOT JOINT ASY 6-5/8 SS EII
                                }
                                else {
                                    if (this.Quote.RightSpansClass.FieldSet.DataValid() && this.Quote.System.SpanProperties.GalvanizedCNJoints) {
                                        PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396594", 1); // JOINT PIVOT E2665G
                                    }
                                    else {
                                        if ([SpanTypes.E2665, SpanTypes.E2660].indexOf(eFirstRightSpanType) !== -1) {
                                            PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396594", 1); // JOINT PIVOT E2665G
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396324", 1); // JOINT PIVOT E2065
                                        }
                                    }
                                }
                                break;
                            case SpanTypes.S2065G:
                                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396024SS", 1); // PIVOT JOINT ASY 6-5/8 SS EII
                                break;
                            case SpanTypes.AlumIV:
                                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396725", 1); // PIVOT JOINT ASSY-ALUMI 4
                                break;
                            case SpanTypes.A80G:
                                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "397025", 1); // PIVOT JOINT ASY-A80-G
                                break;
                            case SpanTypes.E2085:
                                if (this.Quote.System.Lateral.HoseFeed.PivotCenterWithSSRiserPipe) {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396137", 1); // PIVOT JOINT ASY PL2085
                                }
                                else {
                                    PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396225", 1); // PIVOT JOINT ASSY 8-5/8 EII
                                }
                                break;
                            case SpanTypes.PL2065G:
                                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396549", 1); // PIVOT JOINT ASSY PL2065
                                break;
                            case SpanTypes.PL2085G:
                                PO.InsertPart(schema.groups.CenterPivot_PivotJointAssembly.groupId, "396137", 1); // PIVOT JOINT ASY PL2085
                                break;
                        }
                    }
                    else {
                        if (this.Quote.System.Lateral.HoseFeed.BaseInletSize === BaseInletSizes.a8) {
                            PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395827", 1); // ELBOW OPT-8 5/8" MAXI W/O EB
                        }
                        else {
                            PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395860-G", 1); // ELBOW OPTI-MAXI-W/O EB-EII
                        }
                    }
                }
                else {
                    switch (iEndBoom) {
                        case 85:
                        case 105:
                            PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395851", 1); // WYE OPTION-85FT & 105FT END BM
                            break;
                        default:
                            PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395850", 1); // WYE OPTION-13FT TO 61FT END BM
                            break;
                    }
                }
                break;
            case WaterFeedTypes.CenterFeed:
                if (this.Quote.System.Lateral.HoseFeed.BaseInletSize === BaseInletSizes.a8) {
                    switch (eFirstLeftSpanType) {
                        case SpanTypes.A80G:
                        case SpanTypes.A60G:
                        case SpanTypes.AlumIV:
                            if (b14C) {
                                PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395922-14", 1); // WYE-OPT-8" WYE-14C-MAXI
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395922", 1); // WYE OPT-8" WYE-MAXI
                            }
                            break;
                        default:
                            if (b14C) {
                                PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395923-14", 1); // WYE OPT-8 5/8" WYE-14C-MAXI
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395923", 1); // WYE OPT-8 5/8" WYE-MAXI
                            }
                            break;
                    }
                }
                else {
                    switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
                        case HoseFeedTypes.Sugargator:
                            if (this.Quote.System.Lateral.ButterflyValves) {
                                if (b14C) {
                                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395945-14", 1); // WYE-W/6" VALVES-14C--SUGAR LIN
                                }
                                else {
                                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395945", 1); // WYE-W/6" BUTRFLY VLV-SUGAR LIN
                                }
                            }
                            else {
                                if (b14C) {
                                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395944-14", 1); // WYE-6 5/8"-14C-SUGAR LINEAR
                                }
                                else {
                                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395944", 1); // WYE-OPT-6 5/8" SUGAR LINEAR
                                }
                            }
                            break;
                        default:
                            if (this.Quote.System.Lateral.ButterflyValves) {
                                if (b14C) {
                                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395921-14", 1); // WYE-14C-W/6" BTTRFLY VLV-MAXI
                                }
                                else {
                                    if (!(useDropSpanOption)) {
                                        PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395921", 1); // WYE-W/6" BUTTERFLY VALVES-MAXI
                                    }
                                }
                            }
                            else {
                                if (b14C) {
                                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395920-14", 1); // WYE-OPT-6 5/8" WYE-14C-MAXI
                                }
                                else {
                                    if (!(useDropSpanOption)) {
                                        PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395920", 1); // WYE-OPT-6 5/8" WYE-MAXI
                                    }
                                }
                            }
                            break;
                    }
                }
                break;
        }
        iTopQty = this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.DoubleEndFeed ? 2 : 1;
        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType !== HoseFeedTypes.PivotingLateral) {
            if (this.Quote.System.Lateral.HoseFeed.BaseInletSize === BaseInletSizes.a8) {
                switch (eFirstRightSpanType) {
                    case SpanTypes.E2085:
                    case SpanTypes.PL2085G:
                    case SpanTypes.S2085G:
                        PO.InsertPart(schema.groups.HoseFeed_FlangedTowerTop.groupId, "395853-G", iTopQty); // EXT-FLG WYE-8 5/8-CANAL FD-EII
                        break;
                    case SpanTypes.PL2065G:
                        PO.InsertPart(schema.groups.HoseFeed_FlangedTowerTop.groupId, "395913PL", iTopQty); // TOWER TOP REDUCER-S2065PL-MAXI
                        break;
                    default:
                        PO.InsertPart(schema.groups.HoseFeed_FlangedTowerTop.groupId, "395854-G", iTopQty); // WYE EXT-REDUCR-8"-6 5/8-CF-EII
                        break;
                }
            }
            else {
                switch (eFirstRightSpanType) {
                    case SpanTypes.PL2065G:
                        if (this.Quote.System.Lateral.ButterflyValves) {
                            PO.InsertPart(schema.groups.HoseFeed_FlangedTowerTop.groupId, "395933", iTopQty); // TWR TOP-6" BTERFLY VLV-MAXI-PL
                        }
                        else {
                            PO.InsertPart(schema.groups.HoseFeed_FlangedTowerTop.groupId, "395912PL", iTopQty); // TOWER TOP OPTION-S2065PL MAXI
                        }
                        break;
                    case SpanTypes.S2065G:
                        if (this.Quote.System.Lateral.ButterflyValves) {
                            PO.InsertPart(schema.groups.HoseFeed_FlangedTowerTop.groupId, "395911", iTopQty); // TWR TOP-6" BUTTERFLY VLV-MAXI
                        }
                        else {
                            PO.InsertPart(schema.groups.HoseFeed_FlangedTowerTop.groupId, "395910SS", iTopQty); // TOWER TOP OPT-6 5/8" MAXI
                        }
                        break;
                    default:
                        if (this.Quote.System.Lateral.ButterflyValves) {
                            if (!(useDropSpanOption)) {
                                PO.InsertPart(schema.groups.HoseFeed_FlangedTowerTop.groupId, "395911", iTopQty); // TWR TOP-6" BUTTERFLY VLV-MAXI
                            }
                        }
                        else {
                            if (!(useDropSpanOption)) {
                                PO.InsertPart(schema.groups.HoseFeed_FlangedTowerTop.groupId, "395910", iTopQty); // TOWER TOP OPT-6 5/8" MAXI
                            }
                        }
                        break;
                }
            }
        }
        if (this.Quote.System.Lateral.WaterFeed === WaterFeedTypes.CenterFeed) {
            if (this.Quote.System.Lateral.HoseFeed.BaseInletSize === BaseInletSizes.a8) {
                switch (eFirstLeftSpanType) {
                    case SpanTypes.A80G:
                        PO.InsertPart(schema.groups.HoseFeed_ReceiverJointTowerTop.groupId, "395935", 1); // JOINT-MAXI-A80G
                        break;
                    case SpanTypes.A60G:
                    case SpanTypes.AlumIV:
                        PO.InsertPart(schema.groups.HoseFeed_ReceiverJointTowerTop.groupId, "395936", 1); // JOINT-MAXI-A80G TO A4
                        break;
                    case SpanTypes.E2085:
                    case SpanTypes.PL2085G:
                    case SpanTypes.S2085G:
                        PO.InsertPart(schema.groups.HoseFeed_ReceiverJointTowerTop.groupId, "395937-G", 1); // JOINT-MAXI-8 5/8" EII
                        break;
                    case SpanTypes.S2065G:
                        PO.InsertPart(schema.groups.HoseFeed_ReceiverJointTowerTop.groupId, "395938SS", 1); // JOINT-MAXI-8 5/8" WYE-S2065G
                        break;
                    case SpanTypes.PL2065G:
                        PO.InsertPart(schema.groups.HoseFeed_ReceiverJointTowerTop.groupId, "395938PL", 1); // JOINT-MAXI-8 5/8" WYE-S2065PL
                        break;
                    default:
                        PO.InsertPart(schema.groups.HoseFeed_ReceiverJointTowerTop.groupId, "395938-G", 1); // JOINT OPT-MAXI-8 5/8" TO 6 5/8
                        break;
                }
            }
            else {
                switch (eFirstLeftSpanType) {
                    case SpanTypes.S2065G:
                        PO.InsertPart(schema.groups.HoseFeed_ReceiverJointTowerTop.groupId, "395930SS", 1); // JOINT-6 5/8" MAXI-STNLS-EII
                        break;
                    case SpanTypes.A60G:
                    case SpanTypes.AlumIV:
                        PO.InsertPart(schema.groups.HoseFeed_ReceiverJointTowerTop.groupId, "395931", 1); // JOINT-6" MAXI-ALUM
                        break;
                    case SpanTypes.PL2065G:
                        PO.InsertPart(schema.groups.HoseFeed_ReceiverJointTowerTop.groupId, "395932", 1); // JOINT-S2065PL CENTER FEED-MAXI
                        break;
                    default:
                        if (!(useDropSpanOption)) {
                            PO.InsertPart(schema.groups.HoseFeed_ReceiverJointTowerTop.groupId, "396597", 1); // JOINT-6 5/8" MAXI E2665G
                        }
                        break;
                }
            }
        }
        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
            switch (this.Quote.System.Lateral.HoseFeed.InletType) {
                case InletTypes.AutoReverseOverHose:
                    switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                        case BaseInletSizes.a4:
                            PO.InsertPartByIteration(schema.groups.HoseFeed_PowerTowerAssembly.groupId, this.SelectPivotingLateralPivotCenterPart(this.Quote.System.Lateral.HoseFeed.PivotingLateralPivotCenterType, this.Quote.System.Lateral.HoseFeed.BaseInletSize, this.Quote.System.Lateral.HoseFeed.PivotCenterWithSSRiserPipe), 1);
                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395984", 1); // REVERSE ON HOSE KIT-4" PIV MAX
                            break;
                        case BaseInletSizes.a45:
                        case BaseInletSizes.a5:
                            PO.InsertPartByIteration(schema.groups.HoseFeed_PowerTowerAssembly.groupId, this.SelectPivotingLateralPivotCenterPart(this.Quote.System.Lateral.HoseFeed.PivotingLateralPivotCenterType, this.Quote.System.Lateral.HoseFeed.BaseInletSize, this.Quote.System.Lateral.HoseFeed.PivotCenterWithSSRiserPipe), 1);
                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395986", 1); // HOSE SLEEVE KIT
                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395985", 1); // REVERSE ON HOSE KIT-4.5" & 5"
                            break;
                        case BaseInletSizes.a6:
                        case BaseInletSizes.a8:
                            break;
                    }
                    break;
                case InletTypes.StandardHose:
                    switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                        case BaseInletSizes.a4:
                        case BaseInletSizes.a45:
                        case BaseInletSizes.a5:
                            PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "396002", 1); // SINGLE 6" INLET-PIV LAT
                            if (this.Quote.System.Lateral.HoseFeed.SafetyValve) {
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "396001", 1); // CROSSPIPE/SHORT RISER-6" PL
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "396000", 1); // CROSSPIPE/LONG RISER-6" PL
                            }
                            PO.InsertPartByIteration(schema.groups.HoseFeed_PowerTowerAssembly.groupId, this.SelectPivotingLateralPivotCenterPart(this.Quote.System.Lateral.HoseFeed.PivotingLateralPivotCenterType, this.Quote.System.Lateral.HoseFeed.BaseInletSize, this.Quote.System.Lateral.HoseFeed.PivotCenterWithSSRiserPipe), 1);
                            break;
                        case BaseInletSizes.a6:
                            PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "396002", 1); // SINGLE 6" INLET-PIV LAT
                            PO.InsertPartByIteration(schema.groups.HoseFeed_PowerTowerAssembly.groupId, this.SelectPivotingLateralPivotCenterPart(this.Quote.System.Lateral.HoseFeed.PivotingLateralPivotCenterType, this.Quote.System.Lateral.HoseFeed.BaseInletSize, this.Quote.System.Lateral.HoseFeed.PivotCenterWithSSRiserPipe), 1);
                            if (this.Quote.System.Lateral.HoseFeed.SafetyValve) {
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "396001", 1); // CROSSPIPE/SHORT RISER-6" PL
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "396000", 1); // CROSSPIPE/LONG RISER-6" PL
                            }
                            break;
                        case BaseInletSizes.a8:
                            PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "396012", 1); // SINGLE 8" INLET-PIV LAT
                            PO.InsertPartByIteration(schema.groups.HoseFeed_PowerTowerAssembly.groupId, this.SelectPivotingLateralPivotCenterPart(this.Quote.System.Lateral.HoseFeed.PivotingLateralPivotCenterType, this.Quote.System.Lateral.HoseFeed.BaseInletSize, this.Quote.System.Lateral.HoseFeed.PivotCenterWithSSRiserPipe), 1);
                            if (this.Quote.System.Lateral.HoseFeed.SafetyValve) {
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "396011", 1); // CROSSPIPE/SHORT RISER-8"-PL
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "396010", 1); // CROSSPIPE/LONG RISER-8" PIV LT
                            }
                            break;
                    }
                    break;
                case InletTypes.DualHose:
                    switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                        case BaseInletSizes.a4:
                        case BaseInletSizes.a45:
                        case BaseInletSizes.a5:
                        case BaseInletSizes.a6:
                            PO.InsertPartByIteration(schema.groups.HoseFeed_PowerTowerAssembly.groupId, this.SelectPivotingLateralPivotCenterPart(this.Quote.System.Lateral.HoseFeed.PivotingLateralPivotCenterType, this.Quote.System.Lateral.HoseFeed.BaseInletSize, this.Quote.System.Lateral.HoseFeed.PivotCenterWithSSRiserPipe), 1);
                            PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "396003", 1); // DUAL 6" INLETS-PIVOT LAT
                            if (this.Quote.System.Lateral.HoseFeed.SafetyValve) {
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "396001", 1); // CROSSPIPE/SHORT RISER-6" PL
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "396000", 1); // CROSSPIPE/LONG RISER-6" PL
                            }
                            break;
                        case BaseInletSizes.a8:
                            PO.InsertPart(schema.groups.HoseFeed_BaseInlets.groupId, "396013", 1); // DUAL 8" INLETS-PIV LAT
                            PO.InsertPartByIteration(schema.groups.HoseFeed_PowerTowerAssembly.groupId, this.SelectPivotingLateralPivotCenterPart(this.Quote.System.Lateral.HoseFeed.PivotingLateralPivotCenterType, this.Quote.System.Lateral.HoseFeed.BaseInletSize, this.Quote.System.Lateral.HoseFeed.PivotCenterWithSSRiserPipe), 1);
                            if (this.Quote.System.Lateral.HoseFeed.SafetyValve) {
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "396011", 1); // CROSSPIPE/SHORT RISER-8"-PL
                            }
                            else {
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "396010", 1); // CROSSPIPE/LONG RISER-8" PIV LT
                            }
                            break;
                    }
                    break;
            }
        }
        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.DoubleEndFeed) {
            switch (iSecEndBoom) {
                case 1:
                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395865-G", 1); // ELBOW OPT-OUTER TWR-MAXI-EII
                    break;
                case 85:
                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395851", 1); // WYE OPTION-85FT & 105FT END BM
                    break;
                default:
                    PO.InsertPart(schema.groups.HoseFeed_PowerTowerElbowWyeAssembly.groupId, "395850", 1); // WYE OPTION-13FT TO 61FT END BM
                    break;
            }
        }
        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType !== HoseFeedTypes.PivotingLateral) {
            if (this.Quote.System.Lateral.HoseFeed.HoseAndCouplerKit === HoseAndCouplerKitTypes.HardHoseAdapter) {
                switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                    case BaseInletSizes.a4:
                        if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                            PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "394904", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER-6" TO 4" REV ON HOSE
                        }
                        else {
                            PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "352173", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER OPT-4" POLY PIPE-MAXI
                        }
                        break;
                    case BaseInletSizes.a5:
                        if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                            PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "394905", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER-6" TO 5" REV ON HOSE
                        }
                        else {
                            PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "352170", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER OPT-5" POLY PIPE-MAXI
                        }
                        break;
                    case BaseInletSizes.a6:
                        if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                            PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "394909", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-6"REV ON POLY HOSE
                        }
                        else {
                            PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "352171", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER OPT-6" POLY PIPE-MAXI
                        }
                        break;
                    case BaseInletSizes.a8:
                        PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "352172", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER OPT-8" POLYPIPE-MAXI
                        break;
                }
            }
            if (this.Quote.System.Lateral.HoseFeed.HoseAndCouplerKit === HoseAndCouplerKitTypes.HoseWithCouplers && this.Quote.System.Lateral.HoseFeed.DragHoseStandardLength !== DragHoseStandardLengths.NonStandard) {
                switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                    case BaseInletSizes.a4:
                        switch (this.Quote.System.Lateral.HoseFeed.DragHoseStandardLength) {
                            case DragHoseStandardLengths.a260:
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352187", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4" DRAG HOSE W/CPLRS-260'
                                break;
                            case DragHoseStandardLengths.a330:
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352191", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4" DRAG HOSE W/CPLRS 330'
                                break;
                            case DragHoseStandardLengths.a520:
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352186", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4" DRAG HOSE W/CPLRS-520'
                                break;
                        }
                        break;
                    case BaseInletSizes.a5:
                        switch (this.Quote.System.Lateral.HoseFeed.DragHoseStandardLength) {
                            case DragHoseStandardLengths.a165:
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352189", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 5" DRAG HOSE W/CPLRS-165'
                                break;
                            case DragHoseStandardLengths.a220:
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352192", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 5" DRAG HOSE W/CPLRS 220'
                                break;
                            case DragHoseStandardLengths.a330:
                                PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352188", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 5" DRAG HOSE W/CPLRS-330'
                                break;
                        }
                        break;
                }
            }
            if ((this.Quote.System.Lateral.HoseFeed.HoseAndCouplerKit === HoseAndCouplerKitTypes.HoseWithCouplers && this.Quote.System.Lateral.HoseFeed.DragHoseStandardLength === DragHoseStandardLengths.NonStandard) || (this.Quote.System.Lateral.HoseFeed.HoseAndCouplerKit === HoseAndCouplerKitTypes.CouplersOnly)) {
                switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                    case BaseInletSizes.a4:
                        PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352199", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-4 IN HOSE-WHITE
                        break;
                    case BaseInletSizes.a5:
                        PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352201", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-5 IN HOSE-WHITE
                        break;
                    case BaseInletSizes.a6:
                        PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352217", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-6 IN HOSE
                        break;
                }
            }
        }
        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
            switch (this.Quote.System.Lateral.HoseFeed.InletType) {
                case InletTypes.AutoReverseOverHose:
                    switch (this.Quote.System.Lateral.HoseFeed.HoseAndCouplerKit) {
                        case HoseAndCouplerKitTypes.CouplersOnly:
                            switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                                case BaseInletSizes.a4:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352199", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-4 IN HOSE-WHITE
                                    break;
                                case BaseInletSizes.a45:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352200", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-4 1/2" DRAG HOSE
                                    break;
                                case BaseInletSizes.a5:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352201", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-5 IN HOSE-WHITE
                                    break;
                            }
                            break;
                    }
                    break;
                case InletTypes.StandardHose:
                    switch (this.Quote.System.Lateral.HoseFeed.HoseAndCouplerKit) {
                        case HoseAndCouplerKitTypes.HoseWithCouplers:
                            switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                                case BaseInletSizes.a4:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395976", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // HOSE KIT-4" DRAG HOSE
                                    switch (this.Quote.System.Lateral.HoseFeed.DragHoseStandardLength) {
                                        case DragHoseStandardLengths.a260:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352187", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4" DRAG HOSE W/CPLRS-260'
                                            break;
                                        case DragHoseStandardLengths.a330:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352191", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4" DRAG HOSE W/CPLRS 330'
                                            break;
                                        case DragHoseStandardLengths.a520:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352186", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4" DRAG HOSE W/CPLRS-520'
                                            break;
                                        case DragHoseStandardLengths.NonStandard:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352199", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-4 IN HOSE-WHITE
                                            break;
                                    }
                                    break;
                                case BaseInletSizes.a45:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395977", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // HOSE KIT-4.5", 5" DRAG HOSE
                                    switch (this.Quote.System.Lateral.HoseFeed.DragHoseStandardLength) {
                                        case DragHoseStandardLengths.a330:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352151", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4 1/2" DRAG HOSE W/CPLRS-330'
                                            break;
                                        case DragHoseStandardLengths.a520:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352152", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4 1/2" DRAG HOSE W/CPLRS-520'
                                            break;
                                        case DragHoseStandardLengths.NonStandard:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352200", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-4 1/2" DRAG HOSE
                                            break;
                                    }
                                    break;
                                case BaseInletSizes.a5:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395977", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // HOSE KIT-4.5", 5" DRAG HOSE
                                    switch (this.Quote.System.Lateral.HoseFeed.DragHoseStandardLength) {
                                        case DragHoseStandardLengths.a165:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352189", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 5" DRAG HOSE W/CPLRS-165'
                                            break;
                                        case DragHoseStandardLengths.a220:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352192", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 5" DRAG HOSE W/CPLRS 220'
                                            break;
                                        case DragHoseStandardLengths.a330:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352188", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 5" DRAG HOSE W/CPLRS-330'
                                            break;
                                        case DragHoseStandardLengths.NonStandard:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352201", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-5 IN HOSE-WHITE
                                            break;
                                    }
                                    break;
                                case BaseInletSizes.a6:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395978", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // HOSE KIT-6" DRAG HOSE
                                    switch (this.Quote.System.Lateral.HoseFeed.DragHoseStandardLength) {
                                        case DragHoseStandardLengths.NonStandard:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352217", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-6 IN HOSE
                                            break;
                                    }
                                    break;
                            }
                            break;
                        case HoseAndCouplerKitTypes.CouplersOnly:
                            switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                                case BaseInletSizes.a4:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395976", 1); // HOSE KIT-4" DRAG HOSE
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352199", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-4 IN HOSE-WHITE
                                    break;
                                case BaseInletSizes.a45:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395977", 1); // HOSE KIT-4.5", 5" DRAG HOSE
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352200", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-4 1/2" DRAG HOSE
                                    break;
                                case BaseInletSizes.a5:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395977", 1); // HOSE KIT-4.5", 5" DRAG HOSE
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352201", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-5 IN HOSE-WHITE
                                    break;
                                case BaseInletSizes.a6:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395978", 1); // HOSE KIT-6" DRAG HOSE
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352217", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-6 IN HOSE
                                    break;
                            }
                            break;
                        case HoseAndCouplerKitTypes.HardHoseAdapter:
                            switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                                case BaseInletSizes.a4:
                                    PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "395980", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // POLY HOSE KIT-4" PIV LAT
                                    break;
                                case BaseInletSizes.a5:
                                    PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "395981", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // POLY HOSE KIT-5" PIV LAT
                                    break;
                                case BaseInletSizes.a6:
                                    PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "395982", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // POLY HOSE KIT-6" PIV LAT
                                    break;
                                case BaseInletSizes.a8:
                                    PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "352172", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER OPT-8" POLYPIPE-MAXI
                                    break;
                            }
                            break;
                    }
                    break;
                case InletTypes.DualHose:
                    switch (this.Quote.System.Lateral.HoseFeed.HoseAndCouplerKit) {
                        case HoseAndCouplerKitTypes.None:
                            break;
                        case HoseAndCouplerKitTypes.HoseWithCouplers:
                            switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                                case BaseInletSizes.a4:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395976", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // HOSE KIT-4" DRAG HOSE
                                    switch (this.Quote.System.Lateral.HoseFeed.DragHoseStandardLength) {
                                        case DragHoseStandardLengths.a260:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352187", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4" DRAG HOSE W/CPLRS-260'
                                            break;
                                        case DragHoseStandardLengths.a330:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352191", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4" DRAG HOSE W/CPLRS 330'
                                            break;
                                        case DragHoseStandardLengths.a520:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352186", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4" DRAG HOSE W/CPLRS-520'
                                            break;
                                        case DragHoseStandardLengths.NonStandard:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352199", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-4 IN HOSE-WHITE
                                            break;
                                    }
                                    break;
                                case BaseInletSizes.a45:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395977", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // HOSE KIT-4.5", 5" DRAG HOSE
                                    switch (this.Quote.System.Lateral.HoseFeed.DragHoseStandardLength) {
                                        case DragHoseStandardLengths.a330:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352151", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4 1/2" DRAG HOSE W/CPLRS-330'
                                            break;
                                        case DragHoseStandardLengths.a520:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352152", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4 1/2" DRAG HOSE W/CPLRS-520'
                                            break;
                                        case DragHoseStandardLengths.NonStandard:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352200", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-4 1/2" DRAG HOSE
                                            break;
                                    }
                                    break;
                                case BaseInletSizes.a5:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395977", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // HOSE KIT-4.5", 5" DRAG HOSE
                                    switch (this.Quote.System.Lateral.HoseFeed.DragHoseStandardLength) {
                                        case DragHoseStandardLengths.a165:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352189", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 5" DRAG HOSE W/CPLRS-165'
                                            break;
                                        case DragHoseStandardLengths.a220:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352192", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 5" DRAG HOSE W/CPLRS 220'
                                            break;
                                        case DragHoseStandardLengths.a330:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352188", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 5" DRAG HOSE W/CPLRS-330'
                                            break;
                                        case DragHoseStandardLengths.NonStandard:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352201", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-5 IN HOSE-WHITE
                                            break;
                                    }
                                    break;
                                case BaseInletSizes.a6:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395978", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // HOSE KIT-6" DRAG HOSE
                                    switch (this.Quote.System.Lateral.HoseFeed.DragHoseStandardLength) {
                                        case DragHoseStandardLengths.NonStandard:
                                            PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352217", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-6 IN HOSE
                                            break;
                                    }
                                    break;
                            }
                            break;
                        case HoseAndCouplerKitTypes.CouplersOnly:
                            switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                                case BaseInletSizes.a4:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395976", 1); // HOSE KIT-4" DRAG HOSE
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352199", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-4 IN HOSE-WHITE
                                    break;
                                case BaseInletSizes.a45:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395977", 1); // HOSE KIT-4.5", 5" DRAG HOSE
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352200", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-4 1/2" DRAG HOSE
                                    break;
                                case BaseInletSizes.a5:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395977", 1); // HOSE KIT-4.5", 5" DRAG HOSE
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352201", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-5 IN HOSE-WHITE
                                    break;
                                case BaseInletSizes.a6:
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "395978", 1); // HOSE KIT-6" DRAG HOSE
                                    PO.InsertPart(schema.groups.HoseFeed_HoseandCouplers.groupId, "352217", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER KIT-6 IN HOSE
                                    break;
                                case BaseInletSizes.a8:
                                    break;
                            }
                            break;
                        case HoseAndCouplerKitTypes.HardHoseAdapter:
                            switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                                case BaseInletSizes.a4:
                                    PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "395980", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // POLY HOSE KIT-4" PIV LAT
                                    break;
                                case BaseInletSizes.a45:
                                    break;
                                case BaseInletSizes.a5:
                                    PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "395981", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // POLY HOSE KIT-5" PIV LAT
                                    break;
                                case BaseInletSizes.a6:
                                    PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "395982", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // POLY HOSE KIT-6" PIV LAT
                                    break;
                                case BaseInletSizes.a8:
                                    PO.InsertPart(schema.groups.HoseFeed_HardHoseAdapter.groupId, "352172", this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // COUPLER OPT-8" POLYPIPE-MAXI
                                    break;
                            }
                            break;
                    }
                    break;
            }
        }
        if (this.Quote.System.Lateral.HoseFeed.DragHoseNonStandardLength > 0
            && (this.Quote.System.Lateral.HoseFeed.HoseAndCouplerKit ?? HoseAndCouplerKitTypes.None) !== HoseAndCouplerKitTypes.None) {
            switch (this.Quote.System.Lateral.HoseFeed.BaseInletSize) {
                case BaseInletSizes.a4:
                    PO.InsertPart(schema.groups.HoseFeed_Hose.groupId, "352158", this.Quote.System.Lateral.HoseFeed.DragHoseNonStandardLength * this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // HOSE-4 IN DRAG-MAXI-PER FT
                    break;
                case BaseInletSizes.a45:
                    PO.InsertPart(schema.groups.HoseFeed_Hose.groupId, "352150", this.Quote.System.Lateral.HoseFeed.DragHoseNonStandardLength * this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // 4 1/2" DRAG HOSE, PER FT
                    break;
                case BaseInletSizes.a5:
                    PO.InsertPart(schema.groups.HoseFeed_Hose.groupId, "352169", this.Quote.System.Lateral.HoseFeed.DragHoseNonStandardLength * this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // HOSE-5 IN DRAG-MAXI-PER FT
                    break;
                case BaseInletSizes.a6:
                    PO.InsertPart(schema.groups.HoseFeed_Hose.groupId, "352210", this.Quote.System.Lateral.HoseFeed.DragHoseNonStandardLength * this.Quote.System.Lateral.HoseFeed.HardHoseAdapters); // HOSE-6 IN DRAG-MAXI-PER FT
                    break;
            }
        }

        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200) {
            if (this.Quote.System.Lateral.HoseFeed.SingleSpanMaxi) { // These are irrespective of Towable or not
                PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "396041", 1);  // TOWER ASY-CF200-SINGLE SPAN
            } else {
                PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "396040", 1);  // TOWER ASY-CF200-MULTI SPAN
            }
            if (this.Quote.System.Lateral.HoseFeed.Towable) {
                PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "396042", 1);  // TOWER BASE-CF200 W/JACK BRKT
                if (this.Quote.System.Lateral.HoseFeed.SingleSpanMaxi) {
                    PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "396045", 1);  // TOW KIT-PWR TWR-CF200 SINGLE
                } else {
                    if (eFirstSpanLength === 156) PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "396046", 1);  // TOW KIT ASY-156FT SPAN CF200
                    if (eFirstSpanLength === 175) PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "396047", 1);  // TOW KIT ASY-175FT SPAN CF200
                }
            } else {
                PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "396043", 1);  // TOWER BASE-CF200 W/O JACK BRKT
            }
        }

        if (this.Quote.System.Lateral.HoseFeed.Towable) {
            if (this.Quote.System.Lateral.HoseFeed.SingleSpanMaxi) {
                switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
                    case HoseFeedTypes.A100:
                        PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "395954", 1); // TOW KIT-PWR TWR-A100 MAXI
                        break;
                    default:
                        PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "392864", 1); // TOW KIT OPT-POWER TWR-MINI MAX
                        break;
                }
            }
            else {
                switch (eFirstSpanLength) {
                    case 160:
                    case 175:
                        if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                            PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "394908", 1); // TOW KIT-175' SPAN-REV ON HOSE
                        }
                        else {
                            PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "352211", 1); // TOW KIT ASY-175FT SPAN-MAXI
                        }
                        break;
                    case 180:
                    case 186.7:
                    case 194:
                        break;
                    default:
                        if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                            PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "394907", 1); // TOW KIT-156' SPAN-REV ON HOSE
                        }
                        else {
                            PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "352209", 1); // TOW KIT ASY-156FT SPAN-MAXI
                        }
                        break;
                }
            }
            if (this.Quote.System.Lateral.HoseFeed.CenterLift) {
                if (this.Quote.System.Lateral.HoseFeed.SingleSpanMaxi) {
                    PO.InsertPart(schema.groups.HoseFeed_TowBarCenterLiftLateral.groupId, "352242", 1); // TOW BAR-CEN LIFT LATERAL
                }
                else {
                    if (sFirstSpanLengthInFeet <= 156) {
                        PO.InsertPart(schema.groups.HoseFeed_TowBarCenterLiftLateral.groupId, "352243", 1); // TOW BAR-156' CEN LIFT LATERAL
                    }
                    else {
                        PO.InsertPart(schema.groups.HoseFeed_TowBarCenterLiftLateral.groupId, "352244", 1); // TOW BAR-175' CEN LIFT-LATERAL
                    }
                }
            }
        }
        if (this.Quote.System.SystemProperties.ReverseTow && this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.DoubleEndFeed) {
            switch (eLastRightSpanLength) {
                case 160:
                case 175:
                    PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "352211", 1); // TOW KIT ASY-175FT SPAN-MAXI
                    break;
                case 180:
                case 186.7:
                case 194:
                    PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "352212", 1); // TOW KIT ASY-194FT SPAN-MAXI
                    break;
                default:
                    PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "352209", 1); // TOW KIT ASY-156FT SPAN-MAXI
                    break;
            }
        }
        if (this.Quote.System.Lateral.HoseFeed.PivotOption) {
            PO.InsertPart(schema.groups.HoseFeed_PivotKit.groupId, "352197", 1); // PIVOT KIT-MAXIGATOR
        }
        if (this.Quote.System.Lateral.HoseFeed.HydraulicLift) {
            if (!(this.Quote.System.Lateral.HoseFeed.HydraulicPumpKit)) {
                PO.InsertPart(schema.groups.HoseFeed_HydraulicLiftCylinders.groupId, "353350", 1); // HYDRAULIC LIFT OPTION-4WD MAXI
            }
            else {
                PO.InsertPart(schema.groups.HoseFeed_HydraulicLiftCylinders.groupId, "353348", 1); // CYL OPT-3"X 8"-CEN LIFT LINEAR
            }
        }
        if (this.Quote.System.Lateral.HoseFeed.HydraulicPumpKit) {
            PO.InsertPart(schema.groups.ControlPanel_ElectricLiftKit.groupId, "350937", 1); // ELECT KIT-CEN LIFT LINEAR
            if (!(this.Quote.System.Lateral.HoseFeed.CenterLift)) {
                PO.InsertPart(schema.groups.HoseFeed_HydraulicPump.groupId, "353352", 1); // HYD PUMP-4WD LIFT KIT-MAXI
            }
            else {
                PO.InsertPart(schema.groups.HoseFeed_HydraulicPump.groupId, "395809", 1); // CENTER LIFT-PIVOTING LINEAR
            }
        }
        if (this.Quote.System.Lateral.HoseFeed.ElectricDragCordLength > 0) {
            PO.InsertPart(schema.groups.HoseFeed_ElectricDragCord.groupId, "352570", this.Quote.System.Lateral.HoseFeed.ElectricDragCordLength); // ELECTRIC DRAG CORD-PER FT
            switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
                case HoseFeedTypes.FourWheelDrive:
                case HoseFeedTypes.Sugargator:
                    if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                        PO.InsertPart(schema.groups.HoseFeed_ElectricDragCordHardwareKit.groupId, "394906", 1); // DRAG CORD KIT-REV ON HOSE
                    }
                    else {
                        PO.InsertPart(schema.groups.HoseFeed_ElectricDragCordHardwareKit.groupId, "352195-G", 1); // HARDWARE KIT-DRAG CABLE-4WD MX
                    }
                    break;
                case HoseFeedTypes.PivotingLateral:
                    PO.InsertPart(schema.groups.HoseFeed_ElectricDragCordHardwareKit.groupId, "395989", 1); // DRAG CORD KIT-PIVOTING LATERAL
                    break;
                default:
                    if (this.Quote.System.Lateral.HoseFeed.InletType === InletTypes.ReverseOnHose) {
                        PO.InsertPart(schema.groups.HoseFeed_ElectricDragCordHardwareKit.groupId, "394906", 1); // DRAG CORD KIT-REV ON HOSE
                    }
                    else {
                        PO.InsertPart(schema.groups.HoseFeed_ElectricDragCordHardwareKit.groupId, "352198", 1); // HARDWARE KIT-DRAG CABLE-MAXI
                    }
                    break;
            }
        }
        if (this.Quote.System.Lateral.HoseFeed.JunctionBoxWithPlug) {
            PO.InsertPart(schema.groups.HoseFeed_JunctionBoxWPlug.groupId, "351010", 1); // MAXI JCT BOX W/30 AMP PLUG
        }
        if (this.Quote.System.Lateral.HoseFeed.JunctionBoxWithoutPlug > 0) {
            PO.InsertPart(schema.groups.HoseFeed_JunctionBoxWOPlug.groupId, "351011", this.Quote.System.Lateral.HoseFeed.JunctionBoxWithoutPlug); // MAXI JCT BOX W/O PLUG
        }
        switch (this.Quote.System.Lateral.HoseFeed.HoseFeedType) {
            case HoseFeedTypes.Standard:
                if (this.Quote.System.Lateral.HoseFeed.Towable || this.Quote.System.Lateral.HoseFeed.PivotOption) {
                    PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352147", 1); // WHEEL GEAR-TNT-REINKE-BRZ
                }
                else {
                    if (this.Quote.System.Lateral.HoseFeed.HDWheelGear) {
                        PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352034", 1); // WHEEL GEAR-765 2WD MAXI
                    }
                    else {
                        PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352131", 1); // REINKE GR OPT 2.5" XHD NON TOW
                    }
                }
                break;
            case HoseFeedTypes.DoubleEndFeed:
                if (this.Quote.System.Lateral.HoseFeed.Towable) {
                    PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352147", 1); // WHEEL GEAR-TNT-REINKE-BRZ
                }
                break;
            case HoseFeedTypes.FourWheelDrive:
            case HoseFeedTypes.Sugargator:
                if (this.Quote.System.Lateral.HoseFeed.Towable || this.Quote.System.Lateral.HoseFeed.PivotOption || this.Quote.System.Lateral.HoseFeed.CenterLift) {
                    PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352147", 1); // WHEEL GEAR-TNT-REINKE-BRZ
                    PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352131", 1); // REINKE GR OPT 2.5" XHD NON TOW
                }
                else {
                    if (this.Quote.System.Lateral.HoseFeed.HDWheelGear) {
                        PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352039", 1); // WHEEL GEAR (4) 765
                    }
                    else {
                        PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352131", 2); // REINKE GR OPT 2.5" XHD NON TOW
                    }
                }
                break;
            case HoseFeedTypes.A100:
                if (this.Quote.System.Lateral.HoseFeed.Towable) {
                    PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352147", 1);  // WHEEL GEAR-TNT-REINKE-BRZ
                }
                else {
                    PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352131", 1); // REINKE GR OPT 2.5" XHD NON TOW
                }
                break;
            case HoseFeedTypes.PivotingLateral:
                if (this.Quote.System.Lateral.HoseFeed.Towable) {
                    PO.InsertPart(schema.groups.HoseFeed_TowKitAndCable.groupId, "395987", 1); // TOW KIT-PIVOTING LATERAL
                    PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352147", 2); // WHEEL GEAR-TNT-REINKE-BRZ
                }
                else {
                    if (this.Quote.System.Lateral.HoseFeed.HDWheelGear) {
                        PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352039", 1); // WHEEL GEAR (4) 765
                    }
                    else {
                        PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352131", 2); // REINKE GR OPT 2.5" XHD NON TOW
                    }
                }
                break;
            case HoseFeedTypes.CF200:
                if (this.Quote.System.Lateral.HoseFeed.Towable) {
                    PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352147", 1);  // WHEEL GEAR-TNT-REINKE-BRZ
                }
                else {
                    PO.InsertPart(schema.groups.HoseFeed_HoseFeedWheelGear.groupId, "352131", 1); // REINKE GR OPT 2.5" XHD NON TOW
                }
                break;
        }
        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
            if (this.Quote.System.Lateral.HoseFeed.RotateCart) {
                PO.InsertPart(schema.groups.ControlPanel_VFDPivotingLateral.groupId, "350818", 1); // PENDANT RECEPTACLE KIT PIV LAT
            }
            else {
                PO.InsertPart(schema.groups.ControlPanel_VFDPivotingLateral.groupId, "350821", 1); // PIV LAT STD MTR VFD KIT
            }
            if (this.Quote.System.Lateral.HoseFeed.BallastKit) {
                PO.InsertPart(schema.groups.HoseFeed_PowerTowerAssembly.groupId, "395988", 1); // BALLAST SUPPORT KIT-PIVOT-LAT
            }
        }
    }
    private SelectPivotingLateralPivotCenterPart = (PivotCenterType: PivotingLateralPivotCenterTypes, BaseInletSize: BaseInletSizes, PivotCenterWithSSRiserPipe: boolean): number => {
        switch (PivotCenterType) {
            case PivotingLateralPivotCenterTypes.E2065:
                if (BaseInletSize === BaseInletSizes.a8) {
                    if (PivotCenterWithSSRiserPipe) {
                        return 402;
                    }
                    else {
                        return 401;
                    }
                }
                else {
                    if (PivotCenterWithSSRiserPipe) {
                        return 404;
                    }
                    else {
                        return 403;
                    }
                }
                break;
            case PivotingLateralPivotCenterTypes.A80G:
                if (BaseInletSize === BaseInletSizes.a8) {
                    if (PivotCenterWithSSRiserPipe) {
                        return 406;
                    }
                    else {
                        return 405;
                    }
                }
                else {
                    if (PivotCenterWithSSRiserPipe) {
                        return 408;
                    }
                    else {
                        return 407;
                    }
                }
                break;
            case PivotingLateralPivotCenterTypes.E2085:
                if (BaseInletSize === BaseInletSizes.a8) {
                    if (PivotCenterWithSSRiserPipe) {
                        return 410;
                    }
                    else {
                        return 409;
                    }
                }
                else {
                    if (PivotCenterWithSSRiserPipe) {
                        return 412;
                    }
                    else {
                        return 411;
                    }
                }
                break;
            default:
                // new ArgumentException(, $"{NameOf(PivotCenterType)}")
                throw new Error();
                break;
        }
    }
}