import DiscountConfig from "../mapics/DiscountConfig";

export default class DiscountCalculator {
    constructor(private discountConfig: DiscountConfig) {
    }

    /**
     * Apply Program Discount
     * @param {number} price Full List Price
     * @returns {number} Price with Standard Discount applied
     */
    public GetStandardDiscountPrice(price: number): number {
        return price - (price*(this.discountConfig.StandardDiscount/100));
    }

    /**
     * Apply Program Discount based on chained discounts applied as follows:
     * Standard Discount -->
     * Program Discount -->
     * @param {number} price Full List Price
     * @returns {number} Price with Program Discount applied
     */
    public GetProgramDiscountPrice(price: number): number {
        const p = this.GetStandardDiscountPrice(price);
        return p - (p * (this.discountConfig.ProgramDiscount / 100));
    }

    /**
     * Apply Delivery Discount based on chained discounts applied as follows:
     * Standard Discount -->
     * Program Discount -->
     * Delivery Discount -->
     * @param {number} price Full List Price
     * @returns {number} Price with Delivery Discount applied
     */
    public GetDeliveryDiscountPrice(price: number): number {
        const p = this.GetProgramDiscountPrice(price);
        return  p - (p * (this.discountConfig.DeliveryDiscount / 100));
    }

    /**
     * Apply Territory Manager Discount based on chained discounts applied as follows:
     * Standard Discount -->
     * Program Discount -->
     * Delivery Discount -->
     * Territory Manager Discount -->
     * @param {number} price Full List Price
     * @returns {number} Price with Territory Manager Discount applied
     */
    public GetTerritoryManagerDiscountPrice(price: number): number {
        const p = this.GetDeliveryDiscountPrice(price);
        return  p - (p * (this.discountConfig.TerritoryManagerDiscount / 100));
    }

    /**
     * Apply Cash Discount based on chained discounts applied as follows:
     * Standard Discount -->
     * Program Discount -->
     * Delivery Discount -->
     * Territory Manager Discount -->
     * Cash Discount -->
     * @param {number} price Full List Price
     * @param {number} rebate Applies the rebate between calculation of TerritoryManager discount and Cash Discount
     * @returns {number} Price with Cash Discount applied
     */
    public GetCashDiscountPrice(price: number, rebate: number = null): number {
        let p = this.GetTerritoryManagerDiscountPrice(price);
        if (rebate !== null){
            p -= rebate;
        }
        return  p - (p * (this.discountConfig.CashDiscount / 100));
    }

    /**
     * Computation is absed on the following equation: 
     * =100-(100*(1-B2/100)*(1-C2/100)*(1-D2/100)*(1-E2/100)*(1-G2/100))
     * @returns {number} Trade Discount Percent
     */
    public GetTradeDiscountPercent(): number {
        return Math.round(100 - (100 * (1-(this.discountConfig.StandardDiscount/100)) * (1-(this.discountConfig.ProgramDiscount/100)) * (1-(this.discountConfig.DeliveryDiscount/100)) * (1-(this.discountConfig.TerritoryManagerDiscount/100))));
    }

}