import { EndOfSystemTypes, ISystemBase, SwingArmLengths, SystemTypes } from "../project/ISystemBase.AutoGenerated";
import { getEndBoomLength } from "./EndBoom";
import { SideEnum, getSide } from "./SideEnum";

export const getSwingArmAndEndBoomLengthFeet = (sal: SwingArmLengths) => {
    let endBoomLengthFeet: number;
    let swingArmLengthFeet: number;

    switch (sal) {
        case SwingArmLengths.SAC156:
            swingArmLengthFeet = 156;
            endBoomLengthFeet = 51;
            break;
        case SwingArmLengths.SAC175:
            swingArmLengthFeet = 175;
            endBoomLengthFeet = 61;
            break;
        case SwingArmLengths.SAC194:
            swingArmLengthFeet = 194;
            endBoomLengthFeet = 85;
            break;
        case SwingArmLengths.SAC213:
            swingArmLengthFeet = 213;
            endBoomLengthFeet = 105;
            break;
        default:
            return undefined;
    }

    return {
        endBoomLengthFeet,
        swingArmLengthFeet
    }
}

export interface IEndOfSystemHelper_EndBoom {
    endOfSystemType: EndOfSystemTypes.EndBoom,
    endBoomLength: number
}
export interface IEndOfSystemHelper_SAC {
    endOfSystemType: EndOfSystemTypes.SAC,
    endBoomLength: number,
    swingArmLength: number,
    isLeading: boolean,
}
export type IEndOfSystemHelper = IEndOfSystemHelper_SAC | IEndOfSystemHelper_EndBoom | undefined;

export const getEndOfSystemIfValid = (sys: ISystemBase, side: SideEnum): IEndOfSystemHelper => {
    const systemSide = getSide(sys, side);
    switch (systemSide.EndOfSystem.EndOfSystemType) {
        case EndOfSystemTypes.EndBoom: {
            // only valid if an end boom length is defined:
            const endBoomLength = getEndBoomLength(sys, side);
            if (endBoomLength === undefined) return undefined;
            
            return {
                endOfSystemType: EndOfSystemTypes.EndBoom,
                endBoomLength
            }
        }
        case EndOfSystemTypes.SAC: {
            // only valid for center pivots:
            if (sys.SystemProperties.SystemType !== SystemTypes.CenterPivot) return undefined;
            // only valid for flanged side:
            if (side !== SideEnum.Flanged) return undefined;
            
            const swingArmCorner = systemSide.EndOfSystem.SwingArmLength;
            // only valid if a swing arm corner is defined:
            if (!swingArmCorner) return undefined;
            // only valid if swing arm corner is not none:
            if (swingArmCorner === SwingArmLengths.None) return undefined;

            // only valid if an end boom length is defined:
            const endBoomLength = getEndBoomLength(sys, side);
            if (endBoomLength === undefined) return undefined;
            
            // only valid if the end boom length matches the expected end boom length:
            const expectedLengths = getSwingArmAndEndBoomLengthFeet(swingArmCorner);
            if (endBoomLength !== expectedLengths.endBoomLengthFeet) return undefined;
            
            // if we make it here, we have a valid SAC:
            const isLeading = sys.Circle?.SwingArm?.LeadingSwingArm || false;
            return {
                endOfSystemType: EndOfSystemTypes.SAC,
                swingArmLength: expectedLengths.swingArmLengthFeet,
                endBoomLength: expectedLengths.endBoomLengthFeet,
                isLeading
            }
            
        }
        default:
            return undefined;
    }
}