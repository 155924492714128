import { ISystemBase, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "sacRetro",
            title: "SAC Retro",
            position: 2,
            disableDealerSettings: true,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType === SystemTypes.SwingArmRetro,
            components: [
                
                {
                    type: "choice",
                    fieldPath: "Circle.SACRetro.SACCableGauge",
                    title: "Span Cable",
                    options: [
                        { id: 10, title: "#10" },
                        { id: 8, title: "#8" },                  
                    ],
                },
                {
                    type: "choice",
                    fieldPath: "Circle.SACRetro.HingeTowerBoxOverloads",
                    title: "H-Box Overloads",
                    options: [
                        { id: "HeavyDuty", title: "Heavy Duty" },
                        { id: "None", title: "None" },
                    ],
                    enumNameHint: "TowerBoxOverloads",
                },
                {
                    type: "choice",
                    fieldPath: "Circle.SACRetro.Coupler",
                    title: "Coupler",
                    options: [
                        { id: "HiTorque", title: 'Hi-Torque'},
                        { id: "CXCoupler", title: 'C-X Coupler'},
                    ],
                    enumNameHint: "CouplerTypes",
                },   
                {
                    type: "choice",
                    fieldPath: "Circle.SACRetro.ParentSpanLength",
                    title: "Last Span Length",
                    options: [
                        { id: "80", title: "80'"},
                        { id: "83", title: "83'"},
                        { id: "99", title: "99'"},
                        { id: "103", title: "103'"},
                        { id: "118", title: "118'"},
                        { id: "123", title: "123'"},
                        { id: "124", title: "124'"},
                        { id: "137", title: "137'"},
                        { id: "140", title: "140'"},
                        { id: "143", title: "143'"},
                        { id: "144", title: "144'"},
                        { id: "156", title: "156'"},
                        { id: "160", title: "160'"},
                        { id: "164", title: "164'"},
                        { id: "175", title: "175'"},
                        { id: "180", title: "180'"},
                        { id: "186.7", title: "186.7'"},
                        { id: "194", title: "194'"},
                    ],
                },
                
                {
                    type: "complexChoice",
                    title:  "New Span Wire",
                    options: [
                        { title:  "Send Tower Box Kit (Keep exisiting wire)", values: {
                            "Circle.SACRetro.TowerBoxKit": true,
                            "Circle.SACRetro.NewLastSpanWire": false,
                        } },
                        { title:  "Send New Last Span Wire (last Span Length was changed)", values: {
                            "Circle.SACRetro.TowerBoxKit": false,
                            "Circle.SACRetro.NewLastSpanWire": true,
                        } },
                    ],
                },  
                {
                    type: "choice",
                    fieldPath: "Circle.SACRetro.ParentSpanType",
                    title: "Last Span Type",
                    options: [
                        { id: "E65E60E665E660", title: 'E65/E60/E665/E660/A80G'},
                        { id: "E2065E2060E2665E2660", title: 'E2065/E2060/E2665/E2660'},
                        { id: "E80E100E6580E680", title: 'E80/E100/E6580/E680'},
                        { title: "Alum III", id: 'AlumIII'},
                        { title: "Alum IV", id: 'AlumIV'},
                        { title: "Valley system", id: 'Valley'},
                        { title: "Valley span with 3\" drop", id: 'ValleyWithDrop'},
                        { title: "Zimmatic system", id: 'Zimmatic'},
                    ],
                    enumNameHint: "SACRetroSpanTypes",
                },
                        
                {
                    type: "number",
                    fieldPath: "Circle.SACRetro.LastWheelTrack",
                    title: "Last Wheel Track",
                    default: 0,
                    units: "ft"
                },
                {
                    type: "choice",
                    fieldPath: "Circle.SACRetro.HingeTowerGearRatio",
                    title: "H-Tower Ratio",
                    options: [
                        { id: "Standard", title: "Standard (40:1 or 50:1)" },
                        { id: "Low", title: "Low (60:1 or 80:1)" },
                    ],
                    enumNameHint: "GearDriveTypes",
                }, 
                {
                    type: "choice",
                    fieldPath: "Circle.SACRetro.HingeTowerType",
                    title: "H-Tower Type",
                    options: [
                        { id: "Standard", title: "Standard" },
                        { id: "Sugargator", title: "Sugargator" },
                    ],
                    enumNameHint: "TowerHeights",
                },
                {
                    type: "choice",
                    fieldPath: "Circle.SACRetro.HingeTireType",
                    title: "H-Tower Tire",
                    options: [
                        { id: "Other", title: "Other" },
                        { id: "t11x225", title: "11 x 22.5" },
                        { id: "t112x24", title: "11.2 x 24" },
                    ],
                    enumNameHint: "SACRetroTireTypes",
                },
                
                {
                    type: "choice",
                    fieldPath: "Circle.SACRetro.DropType",
                    title: "Sprinkler Drop Type to be Used",
                    options: [
                        { id: "None", title: "None" },
                        { id: "RigidGalvanized", title: "Rigid ( Galvanized )" },
                        { id: "RigidPVCPoly", title: "Rigid ( PVC or Polyethylene )" },
                        { id: "RigidCombo", title: "Rigid ( Combo PVC/Hose or Tube/Hose )" },
                        { id: "Hose", title: "Hose Drops - Includes All Sub Types" },            
                    ],
                    enumNameHint: "SACRetroSprinklerDropType",
                },
                {
                    type: "booleangroup",
                    layoutHint: "secondary",
                    components: [
                        { type: "boolean", title: "Has RPM Preferred with GPS", fieldPath: "Circle.SACRetro.IsControlPanelVRISACSpanCompliant"},                    ],
                }, 
            ],
        },
    ],
};