import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { ISystemBase } from 'rdptypes/project/ISystemBase.AutoGenerated';
import { v4 as uuidv4 } from 'uuid';
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';

const actionTypeId = "ImportSystem";

interface ActionData extends IActionData {
    layoutId: string;

    systemId: string;

    importingSystem: ISystemBase;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;

    state.layouts[data.layoutId].systems[data.systemId] = {
        name: `Imported System`,

        lastModifiedTimestamp: 0, // Updated to the actual timestamp in recordSystemChange
        lastModifiedUserId: "", // Updated to the actual user ID in recordLayoutChange

        mcp: {
        },

        ...data.importingSystem
    } as any;
   
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});


export const createNewImportSystemAction = (layoutId: string, importingSystem: ISystemBase, authState: IAuthState) => {
    const systemId = uuidv4();
    const action = createAction(
        actionTypeId,
        {
            layoutId,
            systemId,
            importingSystem
        } as ActionData,
        authState);
    return {
        systemId,
        action
    };
};