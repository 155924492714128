import { SpanTypes } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import { TowersClass } from "../TowersClass";
import { UMCCoverKitOk, WheelGearOK } from "../Valids.Valids";

export enum idxNSA {
    nsaNone,
    nsaSome,
    nsaAll,
}

export default class TowersForm {
    constructor(private Quote: QuoteClass, private CodeBehind: TowersClass) {
    }

    public validate = (): void => {
        if (!this.CodeBehind.Side.Span.length) return;

        let nsaE2045: idxNSA;
        let iMaxTowers = this.CodeBehind.Side.Span.length - 1;

        if (this.CodeBehind.Side.Span[0].SpanType === SpanTypes.E2045){
            nsaE2045 = idxNSA.nsaAll;
        }
        else if (this.CodeBehind.Side.Span[iMaxTowers].SpanType === SpanTypes.E2045){
            nsaE2045 = idxNSA.nsaSome;
        }
        else {
            nsaE2045 = idxNSA.nsaNone;
        }

        if (!WheelGearOK(this.Quote.System.TowerProperties.WheelGear, nsaE2045 === idxNSA.nsaNone)) {
            this.Quote.Warnings.Add(`Tower wheel gear cannot be Moderate Duty because there are no spans of type E2045 in the system.`);
        }

        // TODO check
        /*if (this.Quote.System.TowerProperties.WheelGear === WheelGearSeries.SevenFourtyFive && !WheelGear745OK(this.Quote.IsTowable(), IsReverseTow(this.Quote.System))){
            this.Quote.Warnings.Add(`Tower wheel gear cannot be 745 Series because the system is towable.`);
        }*/

        if (!UMCCoverKitOk(this.Quote.System.TowerProperties.UMCCoverKit, {
            IsA100: this.Quote.IsA100_CF200(),
        })){
            this.Quote.Warnings.Add(`A100 systems must have the UMC Cover Kit selected (Tower Configuration)`);
        }
    }
}