import { Alert, Box, Stack, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { useContext, useState } from "react";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import AuthCtx from "../../auth/AuthCtx";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import IDbProject from "../../db/IDbProject";
import DealerDataCtx from "../../userData/DealerDataCtx";

interface Props {
    proposalSettings?: {
        layoutId: string;
        systemId: string;
        dbPrj: IDbProject;
    }
}

interface ValidStatuses {
    programDiscountPercentValid: boolean;
    deliveryDiscountPercentValid: boolean;
    tmDiscountPercentValid: boolean;
  }
  

const DealerPricingRenderer: React.FC<Props> = (props) => {
    const ns = "dealer-settings.";
    const subns = "dealer-pricing-options.";

    const [validStatuses, setValidStatuses] = useState<ValidStatuses>({
        programDiscountPercentValid: true,
        deliveryDiscountPercentValid: true,
        tmDiscountPercentValid: true
    });
        
    const authState = useContext(AuthCtx);
    const devSettingsState = useContext(DevSettingsCtx);
    const user = useContext(DealerDataCtx);
    let withinProposal = typeof props.proposalSettings !== "undefined";

    const system =  withinProposal ? props.proposalSettings.dbPrj.state.layouts[props.proposalSettings.layoutId].systems[props.proposalSettings.systemId] : undefined;

    let programDiscountPercent = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.programDiscountPercent : devSettingsState.dealerSettings.dealerPricing.custom?.formState?.programDiscountPercent;
    let deliveryDiscountPercent = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.deliveryDiscountPercent : devSettingsState.dealerSettings.dealerPricing.custom?.formState?.deliveryDiscountPercent;
    let tmDiscountPercent = withinProposal ? system.QuoteProperties.ProposalInformation.pricing.tmDiscountPercent : devSettingsState.dealerSettings.dealerPricing.custom?.formState?.tmDiscountPercent;

    //in case we have no defaults
    if (typeof programDiscountPercent === "undefined"){
        programDiscountPercent = user.discounts.program;
    }
    if (typeof deliveryDiscountPercent === "undefined"){
        deliveryDiscountPercent = user.discounts.delivery;
    }
    if (typeof tmDiscountPercent === "undefined"){
        tmDiscountPercent = user.discounts.tm;
    }

    const [tmValue, setTmValue] = useState(tmDiscountPercent);
    const [deliveryValue, setDeliveryValue] = useState(deliveryDiscountPercent);
    const [programValue, setProgramValue] = useState(programDiscountPercent);

    return (
        <Box sx={{ overflow: "auto", padding: 2 }}>
            {
                withinProposal && <Typography style={{marginBottom: 10}}>
                    The following settings will apply to this proposal <em>only</em>. You can modify your defaults from within dealer settings.
                </Typography>
            }
            <Stack spacing={2} style={{maxWidth: 200, marginBottom: 10}}>
                <TextField
                    type="number"
                    label={t(ns + subns + "program-discount-pc")}
                    value={programValue}
                    error={!validStatuses.programDiscountPercentValid}
                    onChange={(e) => {
                        var status = { ...validStatuses };
                        let numVal = parseFloat(e.target.value);//TODO: validate number
                        setProgramValue(numVal);
                        if (numVal > user.discounts.program){
                            status.programDiscountPercentValid = false;
                        }
                        else {
                            status.programDiscountPercentValid = true;
                            if (withinProposal){
                                props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.programDiscountPercent",
                                    numVal,
                                    authState));
                            }
                            else {
                                let settings = { ...devSettingsState.dealerSettings.dealerPricing.custom.formState };
                                settings.programDiscountPercent = numVal;
                                devSettingsState.dealerSettings.dealerPricing.custom.setFormState(settings);
                            }
                        }
                        setValidStatuses(status);
                    }}
                />
                <TextField
                    type="number"
                    label={t(ns + subns + "delivery-discount-pc")}
                    value={deliveryValue}
                    error={!validStatuses.deliveryDiscountPercentValid}
                    onChange={(e) => {
                        var status = { ...validStatuses };
                        let numVal = parseFloat(e.target.value);//TODO: validate number
                        setDeliveryValue(numVal);
                        if (numVal > user.discounts.delivery){
                            status.deliveryDiscountPercentValid = false;
                        }
                        else {
                            status.deliveryDiscountPercentValid = true;
                            if (withinProposal){
                                props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.deliveryDiscountPercent",
                                    numVal,
                                    authState));
                            }
                            else {
                                let settings = { ...devSettingsState.dealerSettings.dealerPricing.custom.formState };
                                settings.deliveryDiscountPercent = numVal;
                                devSettingsState.dealerSettings.dealerPricing.custom.setFormState(settings);
                            }
                        }
                        
                        setValidStatuses(status);
                    }}
                />
                <TextField
                    type="number"
                    label={t(ns + subns + "tm-discount-pc")}
                    value={tmValue}
                    error={!validStatuses.tmDiscountPercentValid}
                    onChange={(e) => {
                        var status = { ...validStatuses };
                        let numVal = parseFloat(e.target.value);//TODO: validate number
                        setTmValue(numVal);
                        if (numVal > user.discounts.tm){
                            status.tmDiscountPercentValid = false;
                        }
                        else {
                            status.tmDiscountPercentValid = true;
                            if (withinProposal){
                                props.proposalSettings.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                    props.proposalSettings.layoutId,
                                    props.proposalSettings.systemId,
                                    "QuoteProperties.ProposalInformation.pricing.tmDiscountPercent",
                                    numVal,
                                    authState));
                            }
                            else {
                                let settings = { ...devSettingsState.dealerSettings.dealerPricing.custom.formState };
                                settings.tmDiscountPercent = numVal;
                                devSettingsState.dealerSettings.dealerPricing.custom.setFormState(settings);
                            }
                        }

                        setValidStatuses(status);
                    }}
                />

            </Stack>
            {
                (!validStatuses.programDiscountPercentValid || !validStatuses.deliveryDiscountPercentValid || !validStatuses.tmDiscountPercentValid) && <Typography>
                    Your changes have not been saved because of the following error(s):
                </Typography>
            }
            {
                !validStatuses.programDiscountPercentValid && <Alert style={{marginTop: 10}} severity="error">Program discount cannot be higher than {user.discounts.program}%</Alert>
            }
            {
                !validStatuses.deliveryDiscountPercentValid && <Alert style={{marginTop: 10}} severity="error">Delivery discount cannot be higher than {user.discounts.delivery}%</Alert>
            }
            {
                !validStatuses.tmDiscountPercentValid && <Alert style={{marginTop: 10}} severity="error">TM discount cannot be higher than {user.discounts.tm}%</Alert>
            }
        </Box>
    );
}

export default DealerPricingRenderer;