import { LineString, Point } from "@turf/helpers";
import i18next, { t } from "i18next";

export default interface IAnnotation {
    lineAnnotation?: ILineAnnotation;
    pointAnnotation?: IPointAnnotation;
    textAnnotation?: ITextAnnotation;
    type: AnnotationType;
}

export interface IPointAnnotation {
    point: Point;
}

export interface ITextAnnotation {
    point: Point;
    text: string;
}

export interface ILineAnnotation {
    line: LineString;
}

export enum AnnotationType {  
    canal,
    //cross,
    electricLine,
    label,
    line,
    point,
    pump,
    waterLine
}

export function annotationTypeToString(type: AnnotationType, translate: boolean): string {
    //be careful here because what we return here is sometimes used directly to draw the features
    //and translating breaks things!
    if (type === AnnotationType.electricLine) {
        if (translate) {return i18next.format(`${t("electric-line")}`, 'capitalize-each');}
        return "Electric line";
    }

    if (type === AnnotationType.label) {
        if (translate) {return i18next.format(`${t("label")}`, 'capitalize-each')}
        return "Label";
    }
    
    if (type === AnnotationType.line) {
        if (translate) {return i18next.format(`${t("line")}`, 'capitalize-each')}
        return "Line";
    };

    if (type === AnnotationType.canal) {
        if (translate) {return i18next.format(`${t("canal")}`, 'capitalize-each')}
        return "Canal";
    };

    if (type === AnnotationType.point) {
        if (translate) {return i18next.format(`${t("point")}`, 'capitalize-each')}
        return "Point";
    }

    if (type === AnnotationType.pump) {
        if (translate) {return i18next.format(`${t("pump")}`, 'capitalize-each')}
        return "Pump";
    }

    if (type === AnnotationType.waterLine) {
        if (translate) {return i18next.format(`${t("water-line")}`, 'capitalize-each')}
        return "Water line";
    }

    throw new Error(`Unexpected annotation type`);
}