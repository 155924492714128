import { DeviceTypes, IPackage } from "../../../rdptypes/project/ISprinklers";
import { SprinklerManufacturerTypes } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import { AnyPolyLineSpans, DeterminePackageTypeFromDeviceType } from "../OtherHelpers";
import QuoteClass from "../QuoteClass";
import { HasSprinklerPolyValve, IsDualSided, IsMaxigator } from "../SystemFunctions";
import { NormalSpacingOk, SprinklerNelsonDropsOptionOK, UseNelson3030Ok, UseNelsonAD3030MTOk } from "../Valids.Valids";

export default class SprinklersNelsonPackageForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        let sprinklerProps = this.Quote.System.SprinklerProperties;
        if (sprinklerProps.SprinklerEngineCode !== SprinklerManufacturerTypes.Nelson) return;

        this.validatePackages(this.Quote.System.FlangedSide.Sprinklers.Package, "Flanged");
        if (IsDualSided(this.Quote.System) && this.Quote.System.FlexSide.Sprinklers.Package){
            this.validatePackages(this.Quote.System.FlexSide.Sprinklers.Package, "Flex");
        }
    }

    validatePackages = (pkgs: IPackage[], side: string) => {
        if (typeof(pkgs) !== "undefined"){
            let anyPolylinedSpans = AnyPolyLineSpans(this.Quote.System);
            for (let i = 0; i < pkgs.length; i++) {
                const pkg = pkgs[i];
                
                if (pkg.Device === DeviceTypes.None){
                    break;//packages get created even if we don't actually add them
                }
                let idxSprinkler = DeterminePackageTypeFromDeviceType(pkg);

                if (!NormalSpacingOk(pkg.NormalSpacing, {
                    IsMaxigator: IsMaxigator(this.Quote.System),
                    Manufacturer: SprinklerManufacturerTypes.Nelson,
                    Sprinkler: DeterminePackageTypeFromDeviceType(pkg),
                    DropType: pkg.Drop,
                })) {
                    this.Quote.Warnings.Add(`Normal spacing is not ok for ${side.toLowerCase()} Nelson sprinkler package ${i + 1}.`);
                }

                if (!SprinklerNelsonDropsOptionOK(pkg.Drop, {
                    SelectedSprinkler: idxSprinkler,
                    AnyPolyLinedSpans: anyPolylinedSpans,
                    NozzleClipType: pkg.NozzleClip,
                    RegulatorType: pkg.Regulator.RegulatorType,
                    RegulatorPressure: pkg.Regulator.Pressure,
                    UseNelsonAD3030MT: pkg.UseNelsonAD3030MT,
                    UseNelson3030: pkg.UseNelson3030,
                    HasSprinklerPolyValve: HasSprinklerPolyValve(this.Quote.System),
                    })){
                    this.Quote.Warnings.Add(`${side} sprinkler package ${i + 1} drop options not OK.`);
                }

                if (!UseNelson3030Ok(pkg.UseNelson3030, {
                    Sprinkler: idxSprinkler,
                })){
                    this.Quote.Warnings.Add(`"Use Nelson 3030 Series" cannot be selected for ${side.toLowerCase()} Nelson sprinkler package ${i + 1}.`);
                }

                if (!UseNelsonAD3030MTOk(pkg.UseNelsonAD3030MT, {
                    Sprinkler: idxSprinkler,
                    UseNelson3030: pkg.UseNelson3030,
                })){
                    this.Quote.Warnings.Add(`"Use Nelson A/D303MT Series" cannot be selected for ${side.toLowerCase()} Nelson sprinkler package ${i + 1} - check that "Use Nelson 3030 Series" is selected and that you have chosen a compatible package type.`);
                }

                if (pkg.EndingLocation <= 0) {
                    return;
                }
            }
        }
    }
}