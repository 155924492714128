import IBoolean from "rdptypes/roe/IBoolean";
import IBooleanGroup from "rdptypes/roe/IBooleanGroup";
import ICalculator from "rdptypes/roe/ICalculator";
import ICard from "rdptypes/roe/ICard";
import IChoice from "rdptypes/roe/IChoice";
import IComplexChoice from "rdptypes/roe/IComplexChoice";
import IComponent from "rdptypes/roe/IComponent";
import IDate from "rdptypes/roe/IDate";
import IDivider from "rdptypes/roe/IDivider";
import IEndOfSystemTypeChoice from "rdptypes/roe/IEndOfSystemTypeChoice";
import IMainlineValveCode from "rdptypes/roe/IMainlineValveCode";
import INote from "rdptypes/roe/INote";
import INumber from "rdptypes/roe/INumber";
import IPrecisionEndGunAcres from "rdptypes/roe/IPrecisionEndGunAcres";
import IProductLineChoice from "rdptypes/roe/IProductLineChoice";
import ISendOrder from "rdptypes/roe/ISendOrder";
import ISideTable from "rdptypes/roe/ISideTable";
import ITabs from "rdptypes/roe/ITabs";
import IText from "rdptypes/roe/IText";
import IVRIZones from "rdptypes/roe/IVRIZones";
import * as React from "react";
import IDbProject from "../../../db/IDbProject";
import { IDocumentType } from "../../../docGeneration/DocumentGenerationHelpers";
import BooleanGroupRenderer from "./BooleanGroupRenderer";
import BooleanRenderer from "./BooleanRenderer";
import BoxRenderer from "./BoxRenderer";
import CalculatorRenderer from "./CalculatorRenderer";
import CardRenderer from "./CardRenderer";
import ComplexChoiceRenderer from "./ComplexChoiceRenderer";
import DividerRenderer from "./DividerRenderer";
import ESAC125CustomValueLoader from "./ESAC/ESAC125CustomValueLoader";
import EndOfSystemTypeChoiceRenderer from "./EndOfSystemTypeChoiceRenderer";
import GeneralTableRenderer from "./GeneralTableRenderer";
import IComponentRenderContext from "./IComponentRenderContext";
import InlineReportRenderer from "./InlineReportRenderer";
import MainlineValveCodeRenderer from "./MainlineValveCodeRenderer";
import McpVoltageCalculatorRenderer from "./McpVoltageCalculatorRenderer";
import MiscItemsRenderer from "./MiscItemsRenderer";
import MultiChoiceRenderer from "./MultiChoiceRenderer";
import NestedChoiceRenderer from "./NestedChoiceRenderer";
import NoteRenderer from "./NoteRenderer";
import NumberRenderer from "./NumberRenderer";
import PrecisionEndGunAcresRenderer from "./PrecisionEndGunAcresRenderer";
import ProductLineChoiceRenderer from "./ProductLineChoiceRenderer";
import ProposalRenderer from "./Proposal";
import ProposalInformationPricingTableRenderer from "./ProposalInformationPricingTableRenderer";
import ProposalInformationDiscountsSectionRenderer from "./ProposalInformationsDiscountsSectionRenderer";
import SendOrderRenderer from "./SendOrder";
import ShippingDateRenderer from "./ShippingDateRenderer";
import SideTableRenderer from "./SideTableRenderer";
import Sprinklers from "./Sprinklers";
import TabsRenderer from "./TabsRenderer";
import TextRenderer from "./TextRenderer";
import VRIRenderer from "./VRIRenderer";

export const getComponentRenderer = (
    cmp: IComponent,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    ctx: IComponentRenderContext,
    parentGroupLabel: string | null, //either the same as the current component, don't show title; different from parent, do show title in grey; null, not in a group so display in blue
    small?: boolean,
    ignoreImgTooltips?: boolean
    ): JSX.Element => {
    switch (cmp.type) {
        case "complexChoice":
            if ((cmp as IComplexChoice).options.some(opt => opt.nestedOptions)) {
                return <NestedChoiceRenderer parentGroupLabel={parentGroupLabel} cmp={cmp as IComplexChoice} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx}/>;
            } else {
                return <ComplexChoiceRenderer small={small} parentGroupLabel={parentGroupLabel} cmp={cmp as IComplexChoice} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx}/>;
            }
        case "choice":
            return <MultiChoiceRenderer small={small} parentGroupLabel={parentGroupLabel} cmp={cmp as IChoice} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx}/>;
        case "divider":
            return <DividerRenderer cmp={cmp as IDivider} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} />;
        case "booleangroup":
            return <BooleanGroupRenderer parentGroupLabel={parentGroupLabel} cmp={cmp as IBooleanGroup} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx} ignoreImgTooltips={ignoreImgTooltips}/>;
        case "boolean":
            return <BooleanRenderer small={small} parentGroupLabel={parentGroupLabel} cmp={cmp as IBoolean} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx} />;
        case "shippingDate":
            return <ShippingDateRenderer parentGroupLabel={parentGroupLabel} cmp={cmp as IDate} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx} />;
        case "number":
            return <NumberRenderer small={small} parentGroupLabel={parentGroupLabel} cmp={cmp as INumber} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx} />;
        case "text":
            return <TextRenderer small={small} parentGroupLabel={parentGroupLabel} cmp={cmp as IText} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx} />;
        case "productLineChoice":
            return <ProductLineChoiceRenderer cmp={cmp as IProductLineChoice} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} />;
        case "note":
            return <NoteRenderer cmp={cmp as INote}/>;
        case "card":
            return <CardRenderer cmp={cmp as ICard} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx} ignoreImgTooltips={ignoreImgTooltips} />;
        case "box":
            return <BoxRenderer cmp={cmp as ICard} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx} ignoreImgTooltips={ignoreImgTooltips} />;
        case "sprinklers":
            return <Sprinklers dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx} />;
        case "proposal":
            return <ProposalRenderer cmp={cmp as IProductLineChoice} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} />;
        case "endOfSystemType":
            return <EndOfSystemTypeChoiceRenderer parentGroupLabel={parentGroupLabel} cmp={cmp as IEndOfSystemTypeChoice} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx} />;
        case "vri":
            return <VRIRenderer cmp={cmp as IVRIZones} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId}/>;
        case "miscItems":
            return <MiscItemsRenderer dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} />;
        case "proposalInformationPricingTable":
            return <ProposalInformationPricingTableRenderer dbPrj={dbPrj} layoutId={layoutId} systemId={systemId}/>;
        case "proposalInformationDiscountsSection":
            return <ProposalInformationDiscountsSectionRenderer dbPrj={dbPrj} layoutId={layoutId} systemId={systemId}/>;
        case "esac125CustomValueLoader":
            return <ESAC125CustomValueLoader dbPrj={dbPrj} layoutId={layoutId} systemId={systemId}/>
        case "sidetable":
            return <SideTableRenderer cmp={cmp as ISideTable} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx}/>;
        case "generaltable":
            return <GeneralTableRenderer cmp={cmp as ISideTable} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx}/>;
        case "mcpVoltageCalculator":
            return <McpVoltageCalculatorRenderer cmp={cmp as ISideTable} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} ctx={ctx}/>;
        case "sendOrder":
            return <SendOrderRenderer cmp={cmp as ISendOrder} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} />;
        case "electricalReportRenderer": 
            return <InlineReportRenderer dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} docType={IDocumentType.electricalReport}/>;
        case "tabs":
            return <TabsRenderer dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} cmp={cmp as ITabs} ctx={ctx} />;
        case "mainlineValveCode":
            return <MainlineValveCodeRenderer small={small} parentGroupLabel={parentGroupLabel} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} cmp={cmp as IMainlineValveCode} ctx={ctx} />
        case "calculator":
            return <CalculatorRenderer small={small} parentGroupLabel={parentGroupLabel} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} cmp={cmp as ICalculator} ctx={ctx} />
        case "precisionEndGunAcres":
            return <PrecisionEndGunAcresRenderer small={small} parentGroupLabel={parentGroupLabel} ctx={ctx} dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} cmp={cmp as IPrecisionEndGunAcres} />;
        default:
            return <></>
    }
};
