import * as React from "react";
import { useContext, useState } from "react";

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton, MenuItem, Tooltip } from "@mui/material";
import i18next, { t } from "i18next";
import DbCtx from "../../../db/DbCtx";
import DeleteSystemConfirmationDialog from "./DeleteSystemConfirmationDialog";

interface IProps {
    projectId?: string;
    layoutId?: string;
    systemId?: string;
    onDelete: () => void;
    type?: 'button' | 'menuItem';
}

const DeleteSystemButton: React.FC<IProps> = ({
    projectId, layoutId, systemId, onDelete, type = 'button'
}) => {
    
    const dbState = useContext(DbCtx);    
    const dbPrj = projectId ? dbState.projects[projectId] : undefined;
    const disabled = !(systemId && layoutId && dbPrj);

    const [ showConfirmation, setShowConfirmation ] = useState(false);

    const handleOnClick = () => {
        setShowConfirmation(true);
    }

    const buttonComponent = type === 'menuItem'
        ? (
            <MenuItem 
                onClick={handleOnClick}
                sx={{ color: "inherit" }}
                disabled={disabled}
                
            >
                {i18next.format(t('delete-system'), 'capitalize-each')}
            </MenuItem>
        )
        : (
            <Tooltip title={i18next.format(t('delete-system'), 'capitalize-each')} placement="bottom">
                <IconButton 
                    onClick={handleOnClick}
                    sx={{ color: "inherit" }}
                    disabled={disabled}
                    
                >             
                        <DeleteForeverIcon/>         
                </IconButton>
            </Tooltip>
        )

    return (
        <>
            {buttonComponent}
            {
                showConfirmation && (
                    <DeleteSystemConfirmationDialog
                        fromSystemDesign
                        projectId={projectId}
                        layoutId={layoutId}
                        systemId={systemId}
                        onCancel={() => {
                            setShowConfirmation(false)
                        }}
                        onDelete={() => {
                            setShowConfirmation(false)
                            onDelete();
                        }}
                    />
                )
            }
        </>
    )
}

export default DeleteSystemButton;