import CropSquare from "@mui/icons-material/CropSquare";
import { Stack, Tooltip, Typography } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { IrrigatedAreaHelper } from "../../GeometryHelpers/IrrigatedAreaHelper";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { DisplayAreaUnitBuilder } from "../../helpers/areas";
import IProject from "../../model/project/IProject";
import IrrigatedAreaIcon from "../RdpAppBar/IrrigatedAreaIcon";


interface Props {
    project: IProject;
    layoutId: string;
}

const FieldInformationAppBarItem: React.FC<Props> = ({ project, layoutId }) => {

    const displaySettings = React.useContext(DevSettingsCtx).dealerSettings.display.current;

    const irrigatedAreaHelper = React.useMemo(() => {
        return new IrrigatedAreaHelper({
            project: project, layoutId
        })
    }, [ JSON.stringify(project), layoutId ]);

    const getFieldInformation = () => {
        let fieldBoundaryAreaString = "-";
        if (irrigatedAreaHelper.getFieldAcres()) {
            fieldBoundaryAreaString = new DisplayAreaUnitBuilder(irrigatedAreaHelper.getFieldAcres(), 'acres')
                .convert(displaySettings.areas)
                .appendValue(2)
                .appendString(" ")
                .appendLongName()
                .toString();
        }

        let irrigatedAreaString = "-";
        if (irrigatedAreaHelper.getIrrigatedAcres() > 0) {
            irrigatedAreaString = new DisplayAreaUnitBuilder(irrigatedAreaHelper.getIrrigatedAcres(), 'acres')
                .convert(displaySettings.areas)
                .appendValue(2)
                .appendString(" ")
                .appendLongName()
                .toString();
        }

        return {
            fieldBoundaryAreaString,
            irrigatedAreaString
        }
    }
    const fieldInformation = getFieldInformation();
    return (
        <Stack direction={'row'} paddingLeft={2} spacing={2}>  
            <Tooltip title={i18next.format(t('field-boundary'), 'capitalize-each')} placement="bottom">
                <div className="icon-group">
                    <CropSquare />
                    <Typography className="no-text-wrap" variant="subtitle2" component="div" px={0}>
                        {fieldInformation.fieldBoundaryAreaString}
                    </Typography>
                </div>  
            </Tooltip>         
            
            <IrrigatedAreaIcon irrigatedAreaString={fieldInformation.irrigatedAreaString} />                
        </Stack>
    );
}

export default FieldInformationAppBarItem;