import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange } from './helpers';

const actionTypeId = "DeleteSystem";

interface ActionData extends IActionData {
    layoutId: string;
    systemId: string;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;

    delete state.layouts[data.layoutId].systems[data.systemId];
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
});


export const deleteSystemAction = (layoutId: string, systemId: string, authState: IAuthState) => {
    const action = createAction(
        actionTypeId,
        {
            layoutId,
            systemId
        } as ActionData,
        authState);
    return action;
};