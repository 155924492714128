import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { t } from "i18next";
import { DeviceWeights, IPackage, RigidDropBottomFittings, RigidDropMaterialTypes, RigidDropTypes, UPipeMaterialTypes } from "rdptypes/project/ISprinklers";
import { ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useContext, useEffect } from "react";
import { sprinklersPrefix } from "..";
import DevSettingsCtx from "../../../../../db/DevSettingsCtx";
import { feetToMeters, metersToFeet } from "../../../../../docGeneration/DocumentGenerationHelpers";
import { getImgSource } from "../../../../../images/ImagePathHelper";
import { pageStyles } from "../../../styles";
import { ReinforcementClampEnabled } from "../helpers/Rules";
import { getDeviceWeightTypeLabel, getDropBottomFittingLabel, getRigidDropMaterialTypeLabel, getRigidDropTypeLabel, getUPipeMaterialTypeLabel } from "../helpers/SprinklerHelper";
import { ISprinklerPackageValidator } from "../sprinklerValidator/interfaces";

interface Props {
    package: IPackage;
    system: ISystemBase;
    isFlex: boolean;
    handleTooltipOpen: (src?: string) => void;
    handleTooltipClose: () => void;
    packageValidator: ISprinklerPackageValidator;
};

const RigidDropOptions: FC<Props> = (props) => {

    useEffect(() => {    }, []);

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};  
    const settings = useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;


    return (
        <>
                <Stack direction="row" style={{marginTop: 10, marginLeft: 10}}>
                    <FormControl variant="standard" fullWidth error={props.packageValidator.rigidDrop.dropType.isError} >
                        <InputLabel shrink sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.length")}</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getRigidDropTypeLabel(selected, t);
                            }}                                                 
                            value={props.packageValidator.rigidDrop.dropType.value || ""}
                            onChange={(ev) => props.packageValidator.rigidDrop.dropType.set(ev.target.value as RigidDropTypes)}
                        >
                            {
                                Object.keys(RigidDropTypes).map((key: RigidDropTypes) => 
                                    <MenuItem key={key} value={key}>{getRigidDropTypeLabel(key, t)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>               
                    <FormControl variant="standard" fullWidth style={{marginRight: 10}} error={props.packageValidator.rigidDrop.dropMaterial.isError}>
                        <InputLabel shrink sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.rigid-drop-type")}</InputLabel>
                        <Select
                        displayEmpty
                        renderValue={(selected) => {
                            if (!selected) return "Select";
                            else return getRigidDropMaterialTypeLabel(selected);
                        }}                           
                            value={props.packageValidator.rigidDrop.dropMaterial.value || ""}
                            onChange={(ev) =>  props.packageValidator.rigidDrop.dropMaterial.set(ev.target.value as RigidDropMaterialTypes)}
                        >
                            {
                                props.packageValidator.rigidDrop.dropMaterial.allowableValues.map((key) => 
                                    <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("RigidDrop.DropMaterial", key.toString()))} onMouseLeave={props.handleTooltipClose}
                                    key={key} value={key}>{getRigidDropMaterialTypeLabel(key)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" fullWidth error={props.packageValidator.rigidDrop.length.isError} 
                        disabled={props.packageValidator.rigidDrop.length.allowableValues.length === 0} style={{marginRight: 10}}>
                            <InputLabel shrink sx={formInputLabelStyle} >{t(sprinklersPrefix + (displaySettings.lengths === "feet" ? "package.drop-length" : "package.drop-length-m"))} </InputLabel>
                            <Select
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected) return "Select";
                                    else if (displaySettings.lengths === "feet") return selected;
                                    else return feetToMeters(selected).toFixed(1);
                                }}                          
                                value={props.packageValidator.rigidDrop.length.value || 0}
                                onChange={(ev) => props.packageValidator.rigidDrop.length.set(Number(ev.target.value))}
                            >
                                {
                                    props.packageValidator.rigidDrop.length.allowableValues
                                        .map((key) =>  <MenuItem key={key} value={key}>{displaySettings.lengths === "feet" ? `${key}'` : `${feetToMeters(key).toFixed(1)}m`}</MenuItem>)
                                }
                            </Select>
                        </FormControl>  
                    <FormControl variant="standard" fullWidth style={{marginRight: 10}} error={props.packageValidator.rigidDrop.uPipeMaterial.isError}>
                        <InputLabel shrink sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.u-pipe")}</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getUPipeMaterialTypeLabel(selected);
                            }}                           
                            value={props.packageValidator.rigidDrop.uPipeMaterial.value || ""}
                            onChange={(ev) => props.packageValidator.rigidDrop.uPipeMaterial.set(ev.target.value as UPipeMaterialTypes)}
                        >
                            {
                                props.packageValidator.rigidDrop.uPipeMaterial.allowableValues.map((key) => 
                                    <MenuItem key={key} value={key}>{getUPipeMaterialTypeLabel(key)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>      
                    <FormControl variant="standard" fullWidth style={{marginRight: 10}} error={props.packageValidator.rigidDrop.deviceWeight.isError} >
                        <InputLabel shrink sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.device-weight")}</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getDeviceWeightTypeLabel(selected);
                            }}                         
                            value={props.packageValidator.rigidDrop.deviceWeight.value || ""}
                            onChange={(ev) => props.packageValidator.rigidDrop.deviceWeight.set(ev.target.value as DeviceWeights)}
                        >
                            {
                                props.packageValidator.rigidDrop.deviceWeight.allowableValues.map((key) => 
                                    <MenuItem key={key} value={key}>{getDeviceWeightTypeLabel(key as DeviceWeights)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>         
                    <FormControl fullWidth variant="standard" error={props.packageValidator.rigidDrop.bottomFitting.isError}>
                        <InputLabel shrink sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.drop-bottom-fitting")}</InputLabel>
                        <Select
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) return "Select";
                                else return getDropBottomFittingLabel(selected, displaySettings);
                            }}                           
                            value={props.packageValidator.rigidDrop.bottomFitting.value || ""}
                            onChange={(ev) => props.packageValidator.rigidDrop.bottomFitting.set(ev.target.value as RigidDropBottomFittings)}
                        >
                            {
                                props.packageValidator.rigidDrop.bottomFitting.allowableValues.map((key) => 
                                    <MenuItem key={key} value={key}>{getDropBottomFittingLabel(key, displaySettings)}</MenuItem>)
                            }
                        </Select>
                    </FormControl>                        
                </Stack> 
                <Stack direction="row" style={{marginLeft: 10}}>
                    <TextField
                            error={props.packageValidator.rigidDrop.groundClearance.isError}
                            InputLabelProps={{sx:formInputLabelStyle, shrink: true}}
                            fullWidth
                            style={{marginTop: 10}}
                            disabled={props.packageValidator.rigidDrop.dropType.value === RigidDropTypes.Fixed}
                            variant="standard"
                            label={t(sprinklersPrefix + (displaySettings.lengths === "feet" ? "package.preferred-ground-clearance" : "package.preferred-ground-clearance-m"))}
                            type="number"
                            value={
                                displaySettings.lengths === "feet" 
                                    ? props.packageValidator.rigidDrop.groundClearance.value 
                                    : feetToMeters(props.packageValidator.rigidDrop.groundClearance.value)
                            }
                            onChange={(ev) => {
                                let val = Number(ev.target.value);
                                if (displaySettings.lengths === "meters"){
                                    val = metersToFeet(val);
                                }
                                props.packageValidator.rigidDrop.groundClearance.set(val);
                            }}
                        />
                        <FormControlLabel onMouseEnter={() => props.handleTooltipOpen(getImgSource("RigidDrop.ReinforcementClamp"))} onMouseLeave={props.handleTooltipClose}
                            style={{minWidth: 290, marginTop: 10}} control={<Checkbox 
                            style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                            disabled={!ReinforcementClampEnabled(props.package)}
                            checked={props.packageValidator.rigidDrop.reinforcementClamp.value || false}                              
                            onChange={(ev) => props.packageValidator.rigidDrop.reinforcementClamp.set(ev.target.checked)}
                        />} label={t(sprinklersPrefix + (displaySettings.lengths === "feet" ? "package.reinforcement-clamp" : "package.reinforcement-clamp-m"))} />
                </Stack>           
        </>
    );
};

export default RigidDropOptions;