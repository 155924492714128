import { LineString, lineString } from '@turf/turf';
import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordProjectChange } from './helpers';

const actionTypeId = "NewMeasurementAction";

interface ActionData extends IActionData {
    layoutId: string;
    line: LineString;
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const layout = state.layouts[data.layoutId];
    if (!layout) return;
    const reversedLine = lineString([ ...data.line.coordinates].reverse());

    layout.measurements.push({
        line: reversedLine.geometry
    });
    recordProjectChange(action, state);
});

export const createNewMeasurement = (layoutId: string, line: LineString, authState: IAuthState) => {
    return createAction(
        actionTypeId,
        {
            layoutId,
            line
        } as ActionData,
        authState);
};