export default interface IMCP {
    wireMaterial?: WireMaterial;
    wireGauge?: WireGauge;
    lengthFt?: number;
}

export enum WireMaterial {
    Aluminium = "Aluminium",
    Copper = "Copper"
}

export enum WireGauge {
    AWG_14 = "AWG_14",
    AWG_12 = "AWG_12",
    AWG_10 = "AWG_10",
    AWG_8 = "AWG_8",
    AWG_6 = "AWG_6",
    AWG_4 = "AWG_4",
    AWG_2 = "AWG_2",
    AWG_1 = "AWG_1",
    AWG_0 = "AWG_0",
    AWG_00 = "AWG_00",
    AWG_000 = "AWG_000",
    AWG_0000 = "AWG_0000",
    MCM_250 = "MCM_250",
    MCM_300 = "MCM_300",
    MCM_350 = "MCM_350",
    MCM_400 = "MCM_400",
    MCM_450 = "MCM_450",
    MCM_500 = "MCM_500",
    MCM_600 = "MCM_600",
    MCM_750 = "MCM_750",
    MCM_1000 = "MCM_1000"
}

    