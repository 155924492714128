export interface IFieldSettings {
    minBoundaryClearanceFeet: number;
    minObstacleClearanceFeet: number;
    // minLengthOfLateralSideFeet: number;
    // minClearanceFromCartPathToBoundaryFeet: number;
    // minDistanceFromLateralSidesToBoundaryFeet: number;
    // minDistanceFromCartPathEndpointsToBoundaryFeet: number;
    // defaultDistanceFromCanalToLateralPrimaryCoverageFeet: number;
}

export const CUSTOM_FIELD_SETTINGS_DEFAULT_FORM_STATE: IFieldSettings = {
    minBoundaryClearanceFeet: 5,
    minObstacleClearanceFeet: 5,
    // minLengthOfLateralSideFeet: 100, 
    // minClearanceFromCartPathToBoundaryFeet: 5,
    // minDistanceFromLateralSidesToBoundaryFeet: 5,
    // minDistanceFromCartPathEndpointsToBoundaryFeet: 5,
    // defaultDistanceFromCanalToLateralPrimaryCoverageFeet: 6
}