import { Text, View } from "@react-pdf/renderer";
import i18next, { t } from "i18next";
import { OptionsReplaceSystemEnum, PaymentTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useContext } from "react";
import DiscountCalculator from "roedata/roe_migration/DiscountCalculator";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { getDiscountConfigFromUserData, getFactorData, getGPSSurveyData } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import { VERSION } from "../../constants";
import { formatPaymentTermsType } from "../../helpers/system";
import ISystem from "../../model/project/ISystem";
import DealerDataCtx from "../../userData/DealerDataCtx";
import { formatDateShort } from "../DocumentGenerationHelpers";
import { documentStyles } from "../styles";
import FixedWidthTable, { ICell, IRow } from "./table/FixedWidthTable";

interface Props {
    quote: QuoteClass;
    quoteSubmittedAsOrder: boolean;
};

const ForReinkeUseOnlyTable: FC<Props> = (props) => {
    const dealership = useContext(DealerDataCtx);

    let {quote, quoteSubmittedAsOrder} = props;
    let system = (quote.System as ISystem);
    let quoteId = system?.QuoteProperties?.ProposalInformation?.PONumber ?? "N/A";

    let ptt: string | undefined | PaymentTypes = system.QuoteProperties?.ProposalInformation?.paymentTerms ? system.QuoteProperties?.ProposalInformation?.paymentTerms.toString() : undefined;
    if (typeof(ptt) === "string"){
        ptt = PaymentTypes[ptt];
    }

    let orse: string | undefined | OptionsReplaceSystemEnum = system.Options?.ReplaceSystem ? system.Options?.ReplaceSystem.toString() : undefined;
    if (typeof(orse) === "string"){
        orse = OptionsReplaceSystemEnum[orse];
    }

    let systemReplacesStr = "N/A";
    if (typeof(orse) !== "undefined"){
        systemReplacesStr = orse.toString().replace("_", "");

        if (system.Options?.ReplaceComments){
            systemReplacesStr += " (" + system.Options.ReplaceComments +")";
        }
    }

    let svt: string | undefined = system.QuoteProperties?.ProposalInformation?.shipVia ? system.QuoteProperties?.ProposalInformation?.shipVia.toString() : undefined;

    let shippingOptionsStr = "N/A";
    if (typeof(svt) !== "undefined"){
        shippingOptionsStr = svt;
    }

    let requestedShippingDate: string | undefined = system.QuoteProperties?.ProposalInformation?.requestedShippingDate;
    if (typeof(requestedShippingDate) !== "undefined"){
        let parts = requestedShippingDate.split("-");
        let date = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
        requestedShippingDate = formatDateShort(date);
    }
    else {
        requestedShippingDate = "N/A";
    }

    const discountConfig = getDiscountConfigFromUserData(dealership, quote.System as ISystem);
    let dc = new DiscountCalculator(discountConfig);

    const ns = "common-phrases."

    let program = "";
    if ((system.QuoteProperties.ProposalInformation.paymentTerms === PaymentTypes.ReinkeCoastCapital || system.QuoteProperties.ProposalInformation.paymentTerms === PaymentTypes.ReinkeHuntington) 
            && system.QuoteProperties.ProposalInformation.reinkeFinanceInterestBuyDown){
        program = t(ns + "sltInterestRateBuyDown");
    }

    let rows: IRow[] = [{
        cells: [
            {
                text: t(ns + "sltDealerPOnum") + ":",
                widthPc: 30
            },
            {
                text: t(ns + "sltGL") + ": ",
                widthPc: 30
            },
            {
                text: t(ns + "sltSerialnum") + ":",
                widthPc: 30
            }
        ]
    }, 
    {
        cells: [
            {
                text: t(ns + "sltDateReceived") + ":",
                widthPc: 30
            },
            {
                text: t(ns + "sltPriceDate") + ":",
                widthPc: 30
            },
            {
                text: t(ns + "sltRequestedShipDate") + ": " + requestedShippingDate,
                widthPc: 30
            },
        ]
    }, 
    {
        cells: [
            {
                text: t(ns + "sltTradePc") + ":",
                widthPc: 30
            },
            {
                text: t(ns + "sltCash") + ": " +  ((typeof(ptt) === "undefined" || ptt !== PaymentTypes.Cash) ? i18next.format(t('no'), 'capitalize') : i18next.format(t('yes'), 'capitalize')),
                widthPc: 30
            },
            {
                text: t(ns + "sltProgram") + ": " + program,
                widthPc: 30
            },
        ]
    }, 
    {
        cells: [
            {
                text: t(ns + "sltReplaces2") + ": " + systemReplacesStr,
                widthPc: 30
            },
            {
                text: t(ns + "sltTerms") + ": " + (typeof(ptt) === "undefined" ? "N/A" : formatPaymentTermsType(ptt)),
                widthPc: 30
            },
            {
                text: t(ns + "sltShippingNotes") + ": " + shippingOptionsStr,
                widthPc: 30
            },
        ]
    },
    {
        cells: [
            {
                text: t(ns + "sltSubmittedElectronically") + ": " + (quoteSubmittedAsOrder ? i18next.format(t('true'), 'capitalize') : i18next.format(t('false'), 'capitalize')),
                widthPc: 30
            },
            {
                text: `RDC ${quoteSubmittedAsOrder ? t(ns + "sltOrder") : t(ns + "sltQuote")} # : ` + (system.orderSubmissionStatus?.quoteId ?? system.createQuoteStatus?.quoteId ?? ""),
                widthPc: 30
            },
            {
                text: t(ns + "sltSoldBy") + ": " + dealership.assumedUser.name,
                widthPc: 30
            },
        ]
    }];

    let cells = [
        {
            text: `${t(ns + "sltRoeVersion")}: ${VERSION}`,
            widthPc: 30
        },
        {
            text: `${t(ns + "sltDiscountPattern")}:${discountConfig.StandardDiscount}-${discountConfig.ProgramDiscount}-${discountConfig.DeliveryDiscount}-${discountConfig.TerritoryManagerDiscount} (${dc.GetTradeDiscountPercent()}%)`,
            widthPc: 30
        }
    ];


    rows.push({cells});

    let fd = getFactorData(quote);
    if (fd){
        rows.push(
            {
                cells:[{
                    text: fd,
                    widthPc: 60
                }]
            }
        );
    }


    let gpsTable: JSX.Element;
    let gpsInfo = getGPSSurveyData(quote);
    if (typeof(gpsInfo) !== "undefined"){
        let widthPc = typeof(gpsInfo.endLoc) === "undefined" ? 12.5 : 10;
        let gpsCells: ICell[] = [{
            text: t(ns + "sltNetwork") + ":",
            textAlignRight: true,
            widthPc
        },
        {
            text: gpsInfo.network,
            widthPc,
            underline: true
        },
        {
            text: t(ns + "sltSite") + ":",
            textAlignRight: true,
            widthPc
        },
        {
            text: gpsInfo.site,
            widthPc,
            underline: true
        },
        {
            text: t(ns + "sltBaseLoc") + ":",
            textAlignRight: true,
            widthPc
        },
        {
            text: gpsInfo.baseLoc,
            widthPc,
            underline: true
        }];

        if (typeof(gpsInfo.endLoc) === "undefined"){
            gpsCells.push({
                text: t(ns + "sltPivotLoc") + ":",
                textAlignRight: true,
                widthPc
            },
            {
                text: gpsInfo.startLoc,
                widthPc,
                underline: true
            });
        }
        else {
            gpsCells.push({
                text: t(ns + "sltStartLoc") + ":",
                textAlignRight: true,
                widthPc
            },
            {
                text: gpsInfo.startLoc,
                widthPc,
                underline: true
            },
            {
                text: t(ns + "sltEndLoc") + ":",
                textAlignRight: true,
                widthPc
            },
            {
                text: gpsInfo.endLoc,
                widthPc,
                underline: true
            });

        }

        gpsTable = <FixedWidthTable
            data={{
                rows: [{
                    cells: gpsCells
                }]
            }}
        
        />
    }

    return (<View style={[documentStyles.section, {marginTop: 10, marginBottom: 0}]}>
            <View style={documentStyles.heading}>
                <Text style={[documentStyles.sectionHeader]}>{t(ns + "sltForRMCIUseOnly")}</Text>
            </View>
            <View style={{paddingLeft: 15, paddingRight: 15}}>
                <FixedWidthTable
                    data={
                        { rows }
                    }
                />
                {gpsTable}
            </View>
        </View>
    );
};

export default ForReinkeUseOnlyTable;