import { Card, FormControl, InputAdornment, TextField } from "@mui/material";
import { t } from "i18next";
import IMcpVoltageCalculator from "rdptypes/roe/IMcpVoltageCalculator";
import * as React from "react";
import { FC } from "react";
import ElectricalCalculator from "roedata/roe_migration/ElectricalCalculator";
import IDbProject from "../../../../db/IDbProject";
import { pageStyles } from "../../styles";
import IComponentRenderContext from "../IComponentRenderContext";

interface Props {
    cmp: IMcpVoltageCalculator;
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
    ctx: IComponentRenderContext;
    small?: boolean;
};

const McpVoltageCalculatorRenderer: FC<Props> = (props) => {
    const prefix = "roe.mcp.";

    const dbPrj = props.dbPrj;
    const project = dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const ec = new ElectricalCalculator(system, undefined);
    ec.AutoGauge();

    const eosVoltageError = ec.FlangedSideData.Motors.getByIndex(ec.FlangedSideData.Motors.Count - 1).VoltagePresent <
        system.ControlPanel.MinVoltage;
    
    return (        
        <Card variant="outlined">
            <FormControl sx={{ padding: 2 }} fullWidth>
                <TextField
                    InputLabelProps={{sx:{...pageStyles.formInputLabelStyle}, shrink: true}}
                    variant="standard"
                    size="small"
                    fullWidth
                    label={t(prefix + "full-load-amps")}
                    type={"number"}
                    value={(Math.ceil(ec.FullLoadAmps * 10) / 10)}
                    InputProps={{
                        endAdornment:<InputAdornment position="end">A</InputAdornment> ,
                    }}
                />
            </FormControl>
            <FormControl sx={{ padding: 2 }} fullWidth>
                <TextField
                    InputLabelProps={{sx:{...pageStyles.formInputLabelStyle}, shrink: true}}
                    variant="standard"
                    size="small"
                    fullWidth
                    label={t(prefix + "voltage-at-main-panel")}
                    type={"number"}
                    value={(ec.SourceVoltage - ec.RemotePanelVoltageLoss).toFixed(0)}
                    InputProps={{
                        endAdornment:<InputAdornment position="end">V</InputAdornment> ,
                    }}
                />
            </FormControl>
            <FormControl sx={{ padding: 2 }} fullWidth>
                <TextField
                    InputLabelProps={{sx:{...pageStyles.formInputLabelStyle}, shrink: true}}
                    error={eosVoltageError}
                    variant="standard"
                    size="small"
                    fullWidth
                    label="Voltage at End of System"
                    type={"number"}
                    value={(ec.FlangedSideData.Motors.getByIndex(ec.FlangedSideData.Motors.Count - 1).VoltagePresent).toFixed(0)}
                    InputProps={{
                        sx: {color: eosVoltageError ? "red": undefined},
                        endAdornment:<InputAdornment position="end">V</InputAdornment> ,
                    }}
                />
            </FormControl>
        </Card>          
    )
}

export default McpVoltageCalculatorRenderer;