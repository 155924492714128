export class TwoWayList<T> {

    private initialized = false;
    private iCenter: number = 0;

    private equal: T;
    private greater: T[] = [];
    private less: T[] = [];

    readonly def: () => T;

    public constructor(def: () => T, equal?: T) {
        this.def = def;
        if (equal) {
            this.equal = equal;
        }
        else {
            this.equal = def();
        }
    }

    public getIndex(i: number): T {
        if (!this.initialized) {
            return this.def();
        }
        if (i > this.iCenter) {
            const iList = i - this.iCenter - 1;
            if (iList >= this.greater.length) {
                return this.def();
            }
            return this.greater[iList];
        }
        else if (i < this.iCenter) {
            const iList = this.iCenter - i - 1;
            if (iList >= this.less.length) {
                return this.def();
            }
            return this.less[iList];
        }
        else
        {
            return this.equal;
        }
    }

    public setIndex(i: number, value: T) {
        if (!this.initialized) {
            this.iCenter = i;
            this.initialized = true;
        }
        if (i > this.iCenter) {
            const iList = i - this.iCenter - 1;
            // if (iList >= this.greater.length) {
            //     for (let i = 0; i < iList - this.greater.length + 1; i++) {
            //         this.greater.push(this.def());
            //     }
            // }
            while (iList >= this.greater.length) {
                this.greater.push(this.def());
            }
            this.greater[iList] = value;
        }
        else if (i < this.iCenter) {
            const iList = this.iCenter - i - 1;
            // if (iList >= this.less.length) {
            //     for (let i = 0; i < iList - this.less.length + 1; i++) {
            //         this.less.push(this.def());
            //     }
            // }
            while (iList >= this.less.length) {
                this.less.push(this.def());
            }
            this.less[iList] = value;
        }
        else {
            this.equal = value;
        }
    }
}