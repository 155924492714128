import { Divider } from "@mui/material";
import IDivider from "rdptypes/roe/IDivider";
import * as React from "react";
import { FC } from "react";
import IDbProject from "../../../db/IDbProject";

interface Props {
    cmp: IDivider,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
};

const DividerRenderer: FC<Props> = (props) => {
    return (
        <>
            <Divider textAlign="left">{props.cmp.title}</Divider>
        </>
    );
};

export default DividerRenderer;