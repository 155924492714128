import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MenuItemData, NestedDropdown } from "mui-nested-menu";
import IComplexChoice, { IComplexChoiceOption } from "rdptypes/roe/IComplexChoice";
import * as React from "react";
import { FC, useContext } from "react";
import { createNewMultiAction } from "../../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../auth/AuthCtx";
import IDbProject from '../../../db/IDbProject';
import { getValue } from "../../../helpers/objectPathResolver";
import { translate } from "../../../helpers/translation";
import { pageStyles, primaryColor, secondaryColor } from "../styles";
import IComponentRenderContext from "./IComponentRenderContext";

interface Props {
  cmp: IComplexChoice,
  dbPrj: IDbProject,
  layoutId: string,
  systemId: string,
  ctx: IComponentRenderContext,
  parentGroupLabel: string | null, //either the same as the current component, don't show title; different from parent, do show title in grey; null, not in a group so display in blue
};

const NestedChoiceRenderer: FC<Props> = (props) => {
  const authState = useContext(AuthCtx);
  const project = props.dbPrj.state;
  const layout = project.layouts[props.layoutId];
  const system = layout.systems[props.systemId];

  let showLabel = props.parentGroupLabel !== props.cmp.title;

  // We use JSON.stringify(cmp.options) to identify this component. The validator must use the same.
  const fieldPath = props.ctx.fieldRoot + JSON.stringify(props.cmp.options);

  const fvr = props.ctx.vws.validationResult?.getField(fieldPath);
  let errorSeverity = fvr?.severity;

    /*const disabled = fvr?.badValues.has(option.id);
    let toolTipText = (fvr?.badValues.get(option.id)?.reasons ?? []).join(", ");
    if (toolTipText !== "") {
      toolTipText = "Selecting this option would introduce validation errors: " + toolTipText;
    }*/
    
    /*let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    if (!errorSeverity){
      formInputLabelStyle["color"] = primaryColor;
    }
    if (props.parentGroupLabel === null)
    {
        formInputLabelStyle["marginLeft"] = "10px";
        formInputLabelStyle["marginRight"] = "10px";
    }
    else if (!errorSeverity && props.parentGroupLabel !== props.cmp.title)
    {
        formInputLabelStyle["color"] = secondaryColor;
    }
    
    let sx = showLabel ? (props.parentGroupLabel === null ? {marginLeft: "10px", marginRight: "10px"} :{}) : {
      '& .MuiSelect-select .notranslate::after':  translate(props.cmp)
          ? {
              content: `"${ translate(props.cmp)}"`,
              opacity: 0.42,
            }
          : {},
  };*/

  const allLeafPaths: string[] = [];
  const allLeafOptions: IComplexChoiceOption[] = [];

  const populateAllLeafs = (opts: IComplexChoiceOption[], pathPrefix: string) => {
    for (const opt of opts) {
      const path = pathPrefix + translate(opt);
      if (opt.nestedOptions) {
        populateAllLeafs(opt.nestedOptions, path + " ");
      } else {
        allLeafPaths.push(path);
        allLeafOptions.push(opt);
      }
    }
  }
  populateAllLeafs(props.cmp.options, "");

  
  let options = [ ...props.cmp.options ];
  if (props.ctx.editingDealerDefaults) {
    const allUndefined = {};
    for (const opt of allLeafOptions) {
      for (const key of Object.keys(opt.values)) {
        allUndefined[key] = undefined;
      }
    }
    const noDefaultOption: IComplexChoiceOption = {
      title: "Select (No Default)",
      values: allUndefined
    };
    options = [ noDefaultOption, ...options];
    allLeafOptions.push(noDefaultOption);
    allLeafPaths.push(translate(noDefaultOption));
  }


  const currentValue = allLeafOptions.findIndex(opt => 
    Object.keys(opt.values).every(fieldPath => 
      getValue(system, props.ctx.fieldRoot + fieldPath) === opt.values[fieldPath]));

  const selectOption = (selectedOption: IComplexChoiceOption) => {
    props.ctx.pushActionAndImproveScores(createNewMultiAction(props.ctx.changeFieldRoots.flatMap(fieldRoot =>
      Object.keys(selectedOption.values).map(fieldPath =>
      createNewUpdateSystemPropertyAction(
      props.layoutId,
      props.systemId,
      fieldRoot + fieldPath,
      selectedOption.values[fieldPath],
      authState
    ))), authState),
      props.ctx.changeFieldRoots.flatMap(fieldRoot =>
        Object.keys(selectedOption.values).map(fieldPath =>fieldRoot + fieldPath)),
      true);
  };

  const allChildrenDisabled = (opt: IComplexChoiceOption): boolean => 
    opt.values ? fvr?.badValues.has(JSON.stringify(opt.values))
    : opt.nestedOptions.every(allChildrenDisabled);

  const buildMenuItem = (opt: IComplexChoiceOption): MenuItemData => ({
    label: translate(opt),
    callback: opt.values ? () => selectOption(opt) : undefined,
    items: opt.nestedOptions?.map(buildMenuItem),
    disabled: allChildrenDisabled(opt)
  });

  if (props.cmp.title) {
    let sx = showLabel ? (props.parentGroupLabel === null ? {marginLeft: "10px", marginRight: "10px"} :{}) : {
      '& .MuiSelect-select .notranslate::after':  translate(props.cmp)
          ? {
              content: `"${ translate(props.cmp)}"`,
              opacity: 0.42,
            }
          : {},
    };
    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    if (!errorSeverity){
      formInputLabelStyle["color"] = primaryColor;
    }
    if (props.parentGroupLabel === null)
    {
        formInputLabelStyle["marginLeft"] = "10px";
        formInputLabelStyle["marginRight"] = "10px";
    }
    else if (!errorSeverity && props.parentGroupLabel !== props.cmp.title)
    {
        formInputLabelStyle["color"] = secondaryColor;
    }
      return (    
      <FormControl variant="standard" sx={{ m: 1, width: '100%', margin: 0, maxWidth: props.cmp.maxWidth }} error={!!errorSeverity}>
        <InputLabel shrink={true} style={{...formInputLabelStyle, paddingBottom: 2}}>{translate(props.cmp)}</InputLabel>
        <NestedDropdown      
          ButtonProps={{
            sx: {
              ...sx, 
              whiteSpace: "nowrap",
              textTransform: "none",
              color: errorSeverity !== undefined ? "red" : "rgba(0,0,0,0.87)",
              paddingTop: '20px', 
              fontSize: "16px",
              borderRadius: 0,
              boxShadow: "inset 0 -2px 2px -2px rgba(0, 0, 0, 1)",//various styling hacks to make this component look like our other MUI components
              marginBottom: "2px",
              lineHeight: "1.4",
              marginLeft: "10px",
              width: "95%",
              justifyContent: "space-between",
              paddingLeft: 0
            },
            endIcon: <ArrowDropDownIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>
          }}
            menuItemsData={{
              label: currentValue === -1 ? "Select" : allLeafPaths[currentValue],
              items: options.map(buildMenuItem),
              sx: {whiteSpace: "nowrap", 
              fontSize: "16px",}
            }}
          />

      </FormControl>
    )
  }
  else {
    return (
      <NestedDropdown
        ButtonProps={{sx: {
          whiteSpace: "nowrap",
          textTransform: "none",
          color: errorSeverity !== undefined ? "red" : "rgba(0,0,0,0.87)",
          fontSize: "16px",
          borderRadius: 0,
          boxShadow: "inset 0 -2px 2px -2px rgba(0, 0, 0, 1)",//various styling hacks to make this component look like our other MUI components
          marginRight: "2px",
          marginBottom: "2px",
          lineHeight: "1.4",
          marginLeft: "2px"
        },
        endIcon: <ArrowDropDownIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>}}
        menuItemsData={{
          label: currentValue === -1 ? "Select" : allLeafPaths[currentValue],
          items: options.map(buildMenuItem),
          sx: {whiteSpace: "nowrap",
          fontSize: "16px",}
        }}
      />
    )
  }
}

export default NestedChoiceRenderer;