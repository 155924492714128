import { Text, View } from "@react-pdf/renderer";
import i18next from "i18next";
import * as React from "react";
import { FC } from "react";
import { IDocumentType, getDocTypeStr } from "../DocumentGenerationHelpers";
import { documentStyles } from "../styles";
import DealerLogo from "./Logos/DealerLogo";
import ReinkeLogo from "./Logos/ReinkeLogoBlue";

interface Props {
    docType: IDocumentType;
    dealerName: string;
};

const Header: FC<Props> = (props) => {
    return (
        <View fixed style={documentStyles.header}>
                <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <View style={{flex: 4}}>
                        <Text style={{fontWeight: "bold"}}>{i18next.format( getDocTypeStr(props.docType), 'uppercase')}</Text>
                    </View>
                    <View style={{flex: 1, alignItems: "flex-end"}}>
                        <DealerLogo style={{maxHeight: 55, objectFit: "scale-down"}}/>
                    </View>
                    <View style={{flex: 1, alignItems: "flex-end"}}>
                        <ReinkeLogo style={{width: 80}}/>
                    </View>
                </View>
        </View>
    );
};

export default Header;