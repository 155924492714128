import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Feature } from "geojson";

import { lineDistance } from "@turf/turf";
import { staticDevSettingsDbProvider } from "../../db/DevSettingsDbProvider";

interface IDrawLineStringMode extends MapboxDraw.DrawCustomMode<any, any> {
}

const DrawLineStringMode = MapboxDraw.modes.draw_line_string as IDrawLineStringMode;
export const ExtDrawLineStringMode = { ...DrawLineStringMode };
export default ExtDrawLineStringMode;

ExtDrawLineStringMode.toDisplayFeatures = function (this: MapboxDraw.DrawCustomModeThis, state, geojson: Feature, display) {
    const isActiveLine = geojson.properties!.id === state.line.id;

    if (isActiveLine){
        let lastpoint: GeoJSON.Position | null = null;
        let coords =(geojson.geometry as any).coordinates;
        if (coords && coords.length) {
            if (coords.length === 1)
            {
                //some weird wrapper that contains all of the points
                coords = coords[0];
            }
    
            lastpoint = coords[coords.length-1];
    
            if (lastpoint?.length === 2){
                let lastpointPx = this.map.project([lastpoint![0], lastpoint![1]]);
                lastpointPx.x += 30;
                lastpointPx.y += 30;
    
                let newlnglat = this.map.unproject(lastpointPx);
                let labelFeature = {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [newlnglat.lng, newlnglat.lat]
                    } as GeoJSON.Point,
                    properties: {
                        user_rdpFeatureType: "label"
                    },
                } as Feature;
    
                const { lengths } = staticDevSettingsDbProvider.display.get();
                labelFeature.properties!["user_title"] = lineDistance(geojson, {units: lengths}).toFixed(2) + " " + lengths;
                display(labelFeature);
            }
        }
    }

    (DrawLineStringMode as any).toDisplayFeatures(state, geojson, display);
};