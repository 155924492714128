import styled from "@emotion/styled";
import {
    Button,
    InputAdornment,
    MenuItem,
    Select as MuiSelect,
    TableCell as MuiTableCell,
    TextField as MuiTextField,
    Paper,
    Stack,
    Table, TableBody,
    TableContainer, TableHead, TableRow
} from "@mui/material";
import * as React from "react";
import { FC } from "react";

import "@fontsource/libre-franklin/900.css";
import ToolsPageBase from "../toolComponents/ToolPageBase";

interface Props {

}
const TextField = styled(MuiTextField)`
    font-size: 14px;
    div{
        input {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
`;

const Select = styled(MuiSelect)`
    font-size: 14px;
    div{
        padding-top: 5px;
        padding-bottom: 5px;
    }
`;

const TableCell = styled(MuiTableCell)`
        padding: 5px; 
`;

const TableCellStrong = styled(TableCell)`
    border-bottom: 1px solid;
`;

enum SystemType {
    CenterPivot,
    LateralMove,
    WheelLines,
    TravellingGun,
    FloodIrrig
}

enum EnergySourceType {
    Electricity = "Electricity",
    Diesel = "Diesel",
    Gasoline = "Gasoline", 
    NaturalGas = "Natural Gas",
    Propane = "Propane"
}

enum EndGunBoosterPumpValue{
    None = "None", 
    Hp2 = "2 Hp",
    Hp5 = "5 Hp",
}

const getSystemTypeName = (type: SystemType) => {
    switch(type){
        case SystemTypes.CenterPivot:
            return "Center Pivot (70-95%)";
        case SystemType.LateralMove:
            return "Lateral Move (70-95%)";
        case SystemType.FloodIrrig:
            return "Flood Irrig. (40-90%)";
        case SystemType.WheelLines:
            return "Wheel Lines (60-80%)";
        case SystemType.TravellingGun:
            return "Travelling Gun (55-75%)";
    }
}

const getLabourHoursFromSystemType= (type: SystemType) => {
    switch(type){
        case SystemTypes.CenterPivot:
            return 0.75;
        case SystemType.LateralMove:
            return 1;
        case SystemType.FloodIrrig:
            return 1.9;
        case SystemType.WheelLines:
            return 2.5;
        case SystemType.TravellingGun:
            return 2;
    }
}

const SystemComparisonPage: FC<Props> = (props) => {

    const [reinkeSystemType, setReinkeSystemType] = React.useState<SystemType>(SystemTypes.CenterPivot);
    const [otherSystemType, setOtherSystemType] = React.useState<SystemType>(SystemTypes.CenterPivot);
    const [renkieWaterApplEfc, setRenkieWaterApplEfc] = React.useState<number>(95);
    const [otherWaterApplEfc, setOtherWaterApplEfc] = React.useState<number>(95);
    const [renkieDepthOfWaterRequired, setRenkieDepthOfWaterRequired] = React.useState<number>(0);
    const [otherDepthOfWaterRequired, setOtherDepthOfWaterRequired] = React.useState<number>(0);

    var reinkeSystemTypes: SystemType[] = [SystemTypes.CenterPivot, SystemType.LateralMove];
    var otherSystemTypes: SystemType[] = [SystemType.FloodIrrig, SystemType.TravellingGun, SystemType.WheelLines, SystemTypes.CenterPivot, SystemType.LateralMove];
    var energySourceTypes: EnergySourceType[] = [EnergySourceType.Electricity, EnergySourceType.Diesel, EnergySourceType.Gasoline, EnergySourceType.NaturalGas, EnergySourceType.Propane];
    var endGunBoosterPumpHpValues: EndGunBoosterPumpValue[] = [EndGunBoosterPumpValue.None, EndGunBoosterPumpValue.Hp2, EndGunBoosterPumpValue.Hp5]

    return (
        <ToolsPageBase title="System Comparison">
        <TableContainer component={Paper} style={{marginBottom: 20, marginTop: 20}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell  align="left"></TableCell>
                            <TableCell align="left"><b>Reinke System</b></TableCell>
                            <TableCell align="left"><b>Other System</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Type of System (application efficiency)</TableCell>
                            <TableCell align="left">                               
                                <Select
                                    value={reinkeSystemType}
                                    onChange={(ev) => setReinkeSystemType((ev.target.value) as SystemType)}
                                >
                                    {reinkeSystemTypes.map(v => <MenuItem key={v} value={v}>{getSystemTypeName(v)}</MenuItem>)}
                                </Select>
                            </TableCell>
                            <TableCell align="left">
                                <Select
                                        value={otherSystemType}
                                        onChange={(ev) => setOtherSystemType((ev.target.value) as SystemType)}
                                    >
                                    {otherSystemTypes.map(v => <MenuItem key={v} value={v}>{getSystemTypeName(v)}</MenuItem>)}
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Water Application efficiency</TableCell>
                            <TableCell align="left">
                                <TextField variant="filled"  size="small" type="number"  
                                    value={renkieWaterApplEfc}
                                    onChange={(ev) => setRenkieWaterApplEfc(+ev.target.value)}  
                                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>, }}                     
                                />
                            </TableCell>
                            <TableCell align="left">
                                <TextField  variant="filled"  size="small" type="number"  
                                    value={otherWaterApplEfc}
                                    onChange={(ev) => setOtherWaterApplEfc(+ev.target.value)}    
                                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>, }}                     
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Depth of Water Required by Crop (annually)</TableCell>
                            <TableCell align="left">
                                <TextField  variant="filled"  size="small"  type="number"  
                                    value={renkieDepthOfWaterRequired}  
                                    onChange={(ev) => setRenkieDepthOfWaterRequired(+ev.target.value)}
                                    InputProps={{ endAdornment: <InputAdornment position="end">in.</InputAdornment>, }}                     
                                />
                            </TableCell>
                            <TableCell align="left">
                                <TextField variant="filled"  size="small"  type="number"  
                                    value={otherDepthOfWaterRequired}
                                    onChange={(ev) => setOtherDepthOfWaterRequired(+ev.target.value)}  
                                    InputProps={{ endAdornment: <InputAdornment position="end">in.</InputAdornment>, }}                     
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Actual Depth of Water Pumped (annually)</TableCell>
                            <TableCell align="left">{Math.round((renkieDepthOfWaterRequired * 100 / renkieWaterApplEfc) * 100) / 100} in.</TableCell>
                            <TableCell align="left">{Math.round((otherDepthOfWaterRequired * 100 / otherWaterApplEfc) * 100) / 100} in.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Numbers of Acres to be Irrigated</TableCell>
                            <TableCell align="left">
                                <TextField variant="filled"  size="small"  type="number"    
                                        InputProps={{ endAdornment: <InputAdornment position="end">Ac.</InputAdornment>, }}                     
                                />
                            </TableCell>
                            <TableCell align="left">
                                <TextField variant="filled" size="small" type="number"    
                                    InputProps={{ endAdornment: <InputAdornment position="end">Ac.</InputAdornment>, }}                     
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">GPM of Water Source</TableCell>
                            <TableCell align="left">
                                <TextField  variant="filled"  size="small"  type="number"    
                                        InputProps={{ endAdornment: <InputAdornment position="end">GPM</InputAdornment>, }}                     
                                />
                            </TableCell>
                            <TableCell align="left">
                                <TextField  variant="filled"  size="small"  type="number"    
                                    InputProps={{ endAdornment: <InputAdornment position="end">GPM</InputAdornment>, }}                     
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Pump List (dynamic or draw down distance)</TableCell>
                            <TableCell align="left">
                                <TextField  variant="filled"  size="small"   type="number"    
                                        InputProps={{ endAdornment: <InputAdornment position="end">ft.</InputAdornment>, }}                     
                                />
                            </TableCell>
                            <TableCell align="left">
                                <TextField  variant="filled"  size="small" type="number"    
                                    InputProps={{ endAdornment: <InputAdornment position="end">ft.</InputAdornment>, }}                     
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Required Pump Discharge Pressure</TableCell>
                            <TableCell align="left">
                                <TextField variant="filled" size="small" type="number"    
                                        InputProps={{ endAdornment: <InputAdornment position="end">psi</InputAdornment>, }}                     
                                />
                            </TableCell>
                            <TableCell align="left">
                                <TextField  variant="filled"  size="small"  type="number"    
                                    InputProps={{ endAdornment: <InputAdornment position="end">psi</InputAdornment>, }}                     
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Total Dynamic Head of Pump Station</TableCell>
                            <TableCell align="left">0 Hd ft.</TableCell>
                            <TableCell align="left">0 Hd ft.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Annual Hours of Operation</TableCell>
                            <TableCell align="left">0 hrs.</TableCell>
                            <TableCell align="left">0 hrs.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Horsepower Required</TableCell>
                            <TableCell align="left">0 hp.</TableCell>
                            <TableCell align="left">0 hp.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCellStrong align="left"><b>Estimated Annual Gallons of water pumped</b></TableCellStrong>
                            <TableCellStrong align="left"><b>0 gals.</b></TableCellStrong>
                            <TableCellStrong align="left"><b>0 gals.</b></TableCellStrong>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Type of Energy Source</TableCell>
                            <TableCell align="left">                               
                                <Select
                                    value={EnergySourceType.Electricity}
                                    onChange={(ev) => {}}
                                >
                                    {energySourceTypes.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                                </Select>
                            </TableCell>
                            <TableCell align="left">
                                <Select
                                        value={EnergySourceType.Electricity}
                                        onChange={(ev) => {}}
                                    >
                                     {energySourceTypes.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Cost per Unit of Energy Used</TableCell>
                            <TableCell align="left">
                                <TextField  variant="filled" size="small" type="number" />
                            </TableCell>
                            <TableCell align="left">
                                <TextField variant="filled"  size="small" type="number"  />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Number of Center Drives (include power twr)</TableCell>
                            <TableCell align="left">
                                <TextField  variant="filled" size="small" type="number" />
                            </TableCell>
                            <TableCell align="left">
                                <TextField variant="filled"  size="small" type="number"  />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Horsepower of End Gun Booster Pump</TableCell>
                            <TableCell align="left">                               
                                <Select
                                    value={EndGunBoosterPumpValue.None}
                                    onChange={(ev) => {}}
                                >
                                    {endGunBoosterPumpHpValues.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                                </Select>
                            </TableCell>
                            <TableCell align="left">
                                <Select
                                        value={EndGunBoosterPumpValue.None}
                                        onChange={(ev) => {}}
                                    >
                                     {endGunBoosterPumpHpValues.map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)}
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Annual Water Energy Costs</TableCell>
                            <TableCell align="left">0</TableCell>
                            <TableCell align="left">0</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Annual Irrigation System Energy Costs</TableCell>
                            <TableCell align="left">0</TableCell>
                            <TableCell align="left">0</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCellStrong align="left"><b>Estimated Annual Energy Costs</b></TableCellStrong>
                            <TableCellStrong align="left"><b>0</b></TableCellStrong>
                            <TableCellStrong align="left"><b>0</b></TableCellStrong>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Labor Hours per Acre</TableCell>
                            <TableCell align="left">{getLabourHoursFromSystemType(reinkeSystemType)} hrs./Ac.</TableCell>
                            <TableCell align="left">{getLabourHoursFromSystemType(otherSystemType)} hrs./Ac.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Per Hour Labor Rate</TableCell>
                            <TableCell align="left">
                                <TextField variant="filled"  size="small" type="number"  />
                            </TableCell>
                            <TableCell align="left">0</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCellStrong align="left"><b>Estimated Annual Labor Costs</b></TableCellStrong>
                            <TableCellStrong align="left"><b>0</b></TableCellStrong>
                            <TableCellStrong align="left"><b>0</b></TableCellStrong>
                        </TableRow>
                        <TableRow>
                            <TableCellStrong colSpan={3} align="left"></TableCellStrong>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left"><b>Annual Water Savings</b></TableCell>
                            <TableCell align="left"><b>0 gals.</b></TableCell>
                            <TableCell align="left"><b>0 gals.</b></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left"><b>Annual Energy Savings</b></TableCell>
                            <TableCell align="left"><b>0</b></TableCell>
                            <TableCell align="left"><b>0</b></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left"><b>Annual Labor Savings</b></TableCell>
                            <TableCell align="left"><b>0</b></TableCell>
                            <TableCell align="left"><b>0</b></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left"><b>Estimated Annual Savings</b></TableCell>
                            <TableCell align="left"><b>0</b></TableCell>
                            <TableCell align="left"><b>0</b></TableCell>
                        </TableRow>
                    </TableBody>                   
                </Table>
            </TableContainer>
            <p>Calculations based on criteria published in the "1982 Irrigation Pumping Plant Performance Handbook".</p>
            <p>Assumes pump efficiencies of 75%. Horsepower has not been derated.</p>
            <p>Labor hours per Acre provided by North Dakota State University in a publication written by Tom Scherer, Extension Agricultural Engineer, titled "Selecting a Sprinkler Irrigation System", revised 1998.</p>
            <p>System efficiencies are found in University of Nebraska Cooperative Extension publication # EC98-788-S. Chapter 8, titled "Irrigation Efficiencies" page 8.5 by Bill Kranz, Extension Irrigation Specialist.	</p>
            <Stack direction="row" style={{justifyContent: 'flex-end'}}>
                <Button variant="outlined" style={{marginRight: 5}}>Print</Button>
                <Button variant="contained" style={{marginRight: 5}} onClick={() => window.close()}>Close</Button>
            </Stack>
        </ToolsPageBase>
    );


};

export default SystemComparisonPage;