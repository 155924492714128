import i18n, { t } from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import { translationEn } from '../../pwa/locales/en/translation.json';
import { translationEs } from '../../pwa/locales/es/translation.json';

const resources = {
    en: {
        translation: translationEn
    },
    es: {
        translation: translationEs
    }
};

i18n
  .use(LanguageDetector)
  .init({
    resources: resources,
    debug: true,
    //lng: "es",  if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export enum LanguageIDs {
    English,
    Spanish
}

export const strLanguageText = (OriginalPhrase: string): string => t("common-phrases." + OriginalPhrase);