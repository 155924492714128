import { ISystemBase, SystemTypes } from "../../../../rdptypes/project/ISystemBase.AutoGenerated";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "kwikTow",
            title: "Kwik Tow",
            disableDealerSettings: true,
            position: 2,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType === SystemTypes.KwikTow,
            components: [              
                        {
                            type: "choice",
                            fieldPath: "Circle.KwikTow.Tires.TireSize",
                            title: "Tire Size",
                            options: [
                                { id: "a11x225", title: "11 x 22.5" },
                                { id: "a112x24", title: "11.2 x 24" },
                                { id: "a149x24", title: "14.9 x 24" },
                            ],
                            enumNameHint: "TireSizes",
                            autoChange: false
                        },
                        {
                            type: "choice",
                            fieldPath: "Circle.KwikTow.Tires.TireType",
                            title: "Tire Type",
                            options: [
                                { id: "Recaps", title: "Recaps" },
                                { id: "FourPly", title: "4-Ply" },
                                { id: "NonDirectional", title: "Non-Directional" },
                            ],
                            enumNameHint: "TireTypes",
                            autoChange: true,
                        },  
                        {
                            type: "booleangroup",
                            layoutHint: "secondary",
                            components: [
                                { type: "boolean", title: "Reverse Tow", fieldPath: "SystemProperties.ReverseTow"},
                                { type: "boolean", title: "Motorized Center", fieldPath: "Circle.KwikTow.MotorizedCenter"},
                                { type: "boolean", title: "Hydraulic Lift", fieldPath: "Circle.KwikTow.HydraulicLift"},
                                { type: "boolean", title: "Safety Valve", fieldPath: "Circle.KwikTow.SafetyValve"},
                                { type: "boolean", title: "Tubes For Tires", fieldPath: "Circle.KwikTow.Tires.TubesForTires"},
                                { type: "boolean", title: "One Tire Reversed", fieldPath: "Circle.KwikTow.Tires.OneTireMountedInReverse"},
                            ],
                        },  
                        {
                            type: "number",
                            fieldPath: "Circle.KwikTow.ExtraPivotAnchors",
                            title: "Extra Pivot Anchors",
                        },  
                        {
                            type: "number",
                            fieldPath: "Circle.KwikTow.PercentOfCircle",
                            title: "Percent of Circle Irrigated",
                        },                         
            ],
        },
    ],
};