export class Parallel {
    static Invoke(...actions: (() => void)[]): void {
        actions.forEach(x => x());
    }

    static For(fromInclusive: number, toExclusive: number, body: (i: number, loopState: any) => void): void {
        let didBreak = false;
        let loopState = {
            Break: () => {
                didBreak = true;
            }
        };
        for (let i = fromInclusive; i < toExclusive && !didBreak; i++) {
            body(i, loopState);
        }
    }
}

