import { swingArmLengthsFeet } from "rdptypes/reinkeComponents";
import { DisplayLengthUnitBuilder, DisplayLengthUnits } from "../helpers/lengths";

export const getSwingArmLengths = (units: DisplayLengthUnits) => {
    return swingArmLengthsFeet.map(x => {
        let label: string;
        if (units === 'feet' || x.feet === undefined || x.enbdoomFeet === undefined) {
            label = x.label;
        }
        else {
            const valueString1 = new DisplayLengthUnitBuilder(x.feet, 'inches')
                .convert(units)
                .appendValue(1)
                .appendSymbol()
                .toString();
            const valueString2 = new DisplayLengthUnitBuilder(x.enbdoomFeet, 'inches')
                .convert(units)
                .appendValue(1)
                .appendSymbol()
                .toString();
            label = x.conversionLabel(valueString1, valueString2);
        }
        return {
            originalValue: x.value,
            label,
            shortLabel: x.shortLabel
        }
    })
}