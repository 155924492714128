import { Checkbox, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, Select, Stack } from "@mui/material";
import { D3000Plates, DeviceTypes, DropTypes, IPackage, LDNChemPadTypes, LDNPadTypes, LDNTypes, SuperSprayPadTypes, idxWobbler, idxXiWob } from "rdptypes/project/ISprinklers";
import { ISystemBase, SprinklerManufacturerTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import { BubbleClipLEPAOk, BubblerShroudOK, FCNOptionOk, SprinklerConverterOk, TrashBusterBodyOptionOk } from "roedata/roe_migration/Valids.Valids";
import { idxRSNPlates } from "roedata/roe_migration/Valids.dto.Valids";
import { getImgSource } from "../../../../../images/ImagePathHelper";
import { pageStyles } from "../../../styles";
import {
    D3000SelectEnabled,
    DeviceTypeIWob,
    DeviceTypeXiWob, GetXiWobTypes,
    SenningerPlatesRequired
} from "../helpers/Rules";
import { getD3000PlateLabel, getIWobTypeLabel, getLDNChemPadTypeLabel, getLDNPadTypeLabel, getLDNTypeLabel, getRSNPlatesLabel, getSuperSprayLabel, getXiWobTypeLabel } from "../helpers/SprinklerHelper";
import { ISprinklerPackageValidator } from "../sprinklerValidator/interfaces";

interface Props {
    package: IPackage;
    system: ISystemBase;
    handleTooltipOpen: (src?: string) => void;
    handleTooltipClose: () => void

    packageValidator: ISprinklerPackageValidator;
};

const PlateOptions: FC<Props> = (props) => {

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    const nrOfD3000Plates = props.package.D3000.Plate3 ? 3 : props.package.D3000.Plate2 ? 2 : props.package.D3000.Plate1 ? 1 : 0;
    var trashBusterBodyOptionOk = TrashBusterBodyOptionOk(true, {DeviceType: props.package.Device, UseNelson3030: props.package.UseNelson3030, 
        D3000Plate: props.package.D3000.Plate1, D3000OptionCountChecked: nrOfD3000Plates});
    var fCNOptionOk = FCNOptionOk(true, {RegulatorType: props.package.Regulator.RegulatorType,
        TrashBusterBodyOptionChecked: props.package.TrashBusterBody, DropType: props.package.Drop,
        RigidDropMaterialType: props.package.Drop === DropTypes.Rigid ? props.package.RigidDrop.DropMaterial : null});
    var sprinklerConverterOk = SprinklerConverterOk(true, {IsD3000: D3000SelectEnabled(props.package.Device),  DropType: props.package.Drop, 
        RegulatorPressure: props.package.Regulator.Pressure, UseNelson3030: props.package.UseNelson3030, BubblerClipLEPAChecked: props.package.D3000.BubblerClipLEPA});
    var bubbleClipLEPAOk = BubbleClipLEPAOk(true, {IsD3000:  D3000SelectEnabled(props.package.Device), DropType: props.package.Drop, CountChecked: nrOfD3000Plates,
        IsBubbleWideTanChecked: props.package.D3000.Plate1 === D3000Plates.Tan || props.package.D3000.Plate2 === D3000Plates.Tan || props.package.D3000.Plate3 === D3000Plates.Tan,
        UseNelson3030: props.package.UseNelson3030, SprinklerConverterChecked: props.package.D3000.SprinklerConverter});
    var bubblerShroudOK = BubblerShroudOK(true, {IsLDN: props.package.Device === DeviceTypes.SenningerLDNSpray, LDNType: props.package.LDN.LDNType,
        LDNPadType: props.package.LDN.Pad, LDNChemPadType: props.package.LDN.ChemPad});

    return (
        <>
            {
                props.system.SprinklerProperties!.SprinklerEngineCode === SprinklerManufacturerTypes.Nelson &&
                    <Stack direction="row">
                            <FormControl variant="standard"  fullWidth style={{marginTop: 10, marginLeft: 10, marginRight: 10}} 
                                error={props.packageValidator.plate.plateType.isError} 
                                disabled={props.packageValidator.plate.plateType.allowableValues.length === 0} 
                            >
                                <InputLabel shrink={true} sx={formInputLabelStyle} >R/S/A/O3000 Plate</InputLabel>
                                <Select     
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (selected === -1) return "Select";
                                        else return getRSNPlatesLabel(selected);
                                    }}                          
                                    value={
                                        (props.packageValidator.plate.plateType.value === undefined || props.packageValidator.plate.plateType.value === null)
                                            ? -1
                                            : props.packageValidator.plate.plateType.value
                                    }
                                    onChange={(ev) => props.packageValidator.plate.plateType.set(ev.target.value as idxRSNPlates)}
                                >
                                    {
                                        props.packageValidator.plate.plateType.allowableValues.map((key) => 
                                            <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("RSAO3000Plate", key.toString()))} onMouseLeave={props.handleTooltipClose} key={key} value={key}>{getRSNPlatesLabel(key)}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" fullWidth style={{marginTop: 10, marginLeft: 10, marginRight: 10}} error={props.packageValidator.plate.d3000.plates.isError} disabled={!D3000SelectEnabled(props.package.Device)} >
                                <InputLabel shrink={true} sx={formInputLabelStyle} >D3000 Plate</InputLabel>
                                <Select                          
                                    value={props.packageValidator.plate.d3000.plates.value} 
                                    renderValue={(selected) => {
                                        let str = "";
                                        selected.filter(x => x !== null).map(x => str += getD3000PlateLabel(x)).join(', ');
                                        if (str === "") return "Select";
                                        return str;                                    
                                    }}
                                    multiple
                                    displayEmpty
                                    onChange={(ev) => props.packageValidator.plate.d3000.plates.set(ev.target.value as D3000Plates[])}
                                >
                                    {
                                        props.packageValidator.plate.d3000.plates.allowableValues.map((key) => 
                                            <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("D3000.Plate", key.toString()))} onMouseLeave={props.handleTooltipClose} key={key} value={key}>
                                                <Checkbox checked={key === props.package.D3000.Plate1 || key === props.package.D3000.Plate2 || key === props.package.D3000.Plate3} />
                                                <ListItemText primary={getD3000PlateLabel(key)} />
                                            </MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <Stack style={{width: '100%', marginLeft: 10, marginRight: 10}}>
                                <FormControlLabel onMouseEnter={() => props.handleTooltipOpen(getImgSource("D3000.SprinklerConverter"))} onMouseLeave={props.handleTooltipClose}
                                    control={<Checkbox 
                                    style={{padding: 0, paddingLeft: 10, paddingRight: 5}} 
                                    disabled={!sprinklerConverterOk}                            
                                    checked={props.packageValidator.plate.d3000.sprinklerConverter.value || false}
                                    onChange={(ev) => props.packageValidator.plate.d3000.sprinklerConverter.set(ev.target.checked)}
                                />} label={`Sprinkler Converter`} />
                                <FormControlLabel onMouseEnter={() => props.handleTooltipOpen(getImgSource("D3000.BubblerClipLEPA"))} onMouseLeave={props.handleTooltipClose}
                                    control={<Checkbox 
                                    style={{padding: 0, paddingLeft: 10, paddingRight: 5}}   
                                    disabled={!bubbleClipLEPAOk}                          
                                    checked={props.packageValidator.plate.d3000.bubblerClipLEPA.value || false}
                                    onChange={(ev) => props.packageValidator.plate.d3000.bubblerClipLEPA.set(ev.target.checked)}
                                />} label={`Bubbler Clip LEPA`} />
                                <FormControlLabel onMouseEnter={() => props.handleTooltipOpen(getImgSource("D3000.TrashBusterBody"))} onMouseLeave={props.handleTooltipClose}
                                    disabled={!trashBusterBodyOptionOk} control={<Checkbox 
                                    style={{padding: 0, paddingLeft: 10, paddingRight: 5}}                             
                                    checked={props.packageValidator.plate.d3000.trashBusterBody.value || false}
                                    onChange={(ev) => props.packageValidator.plate.d3000.trashBusterBody.set(ev.target.checked)}
                                />} label={`Trash Buster Body`} />
                                <FormControlLabel onMouseEnter={() => props.handleTooltipOpen(getImgSource("D3000.isFCN3000"))} 
                                    onMouseLeave={props.handleTooltipClose} control={<Checkbox 
                                    style={{padding: 0, paddingLeft: 10, paddingRight: 5}}                                   
                                    disabled={!fCNOptionOk}      
                                    checked={props.packageValidator.plate.d3000.isFCN3000.value || false}                      
                                    onChange={(ev) => props.packageValidator.plate.d3000.isFCN3000.set(ev.target.checked)}
                                />} label={`3000 Flow Control`} />
                            </Stack>
                    </Stack>   
            }
            {
                props.system.SprinklerProperties!.SprinklerEngineCode === SprinklerManufacturerTypes.Senninger && SenningerPlatesRequired(props.package.Device) &&
                    <Stack direction="row" style={{width: '100%', marginLeft: 10, marginRight: 10}}>
                        <Stack style={{width: '100%'}}>
                            <FormControl variant="standard"  fullWidth style={{marginTop: 10, marginLeft: 10, marginRight: 10}} 
                                disabled={props.package.Device !== DeviceTypes.SenningerLDNSpray}
                                error={props.packageValidator.plate.ldn.ldnType.isError}>
                                <InputLabel shrink={true} sx={formInputLabelStyle} >LDN Type</InputLabel>
                                <Select             
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) return "Select";
                                        else return getLDNTypeLabel(selected);
                                    }}                 
                                    value={props.packageValidator.plate.ldn.ldnType.value || ""} 
                                    onChange={(ev) => props.packageValidator.plate.ldn.ldnType.set(ev.target.value as LDNTypes)}>
                                    {
                                        props.packageValidator.plate.ldn.ldnType.allowableValues.map((key) => 
                                            <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("LDN.LDNType", key.toString()))} onMouseLeave={props.handleTooltipClose} key={key} value={key}>{getLDNTypeLabel(key)}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <FormControl variant="standard"  fullWidth style={{marginTop: 10, marginLeft: 10, marginRight: 10}} 
                                disabled={props.package.Device !== DeviceTypes.SenningerLDNSpray}
                                error={props.packageValidator.plate.ldn.pad.isError}>
                                <InputLabel shrink={true} sx={formInputLabelStyle} >LDN Pad Type</InputLabel>
                                <Select
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) return "Select";
                                        else return getLDNPadTypeLabel(selected);
                                    }}                           
                                    value={props.packageValidator.plate.ldn.pad.value || ""} 
                                    onChange={(ev) => props.packageValidator.plate.ldn.pad.set(ev.target.value as LDNPadTypes)}>
                                    {
                                        props.packageValidator.plate.ldn.pad.allowableValues.map((key) => 
                                            <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("LDN.Pad", key.toString()))} onMouseLeave={props.handleTooltipClose} key={key} value={key}>{getLDNPadTypeLabel(key)}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <FormControl variant="standard"  fullWidth style={{marginTop: 10, marginLeft: 10, marginRight: 10}} 
                                disabled={props.package.Device !== DeviceTypes.SenningerLDNSpray}
                                error={props.packageValidator.plate.ldn.chemPad.isError}>
                                <InputLabel shrink={true} sx={formInputLabelStyle} >LDN Chem Pad Type</InputLabel>
                                <Select
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) return "Select";
                                        else return getLDNChemPadTypeLabel(selected);
                                    }}                       
                                    value={props.packageValidator.plate.ldn.chemPad.value || ""} 
                                    onChange={(ev) => props.packageValidator.plate.ldn.chemPad.set(ev.target.value as LDNChemPadTypes)}>
                                    {
                                        props.packageValidator.plate.ldn.chemPad.allowableValues.map((key) => 
                                            <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("LDN.ChemPad", key.toString()))} onMouseLeave={props.handleTooltipClose} key={key} value={key}>{getLDNChemPadTypeLabel(key)}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <FormControlLabel style={{marginTop: 10}} control={<Checkbox 
                                    style={{padding: 0, paddingLeft: 20, paddingRight: 5}}   
                                    disabled={!bubblerShroudOK}                          
                                    checked={props.packageValidator.plate.ldn.bubblerShroud.value || false}
                                    onChange={(ev) => props.packageValidator.plate.ldn.bubblerShroud.set(ev.target.checked)}
                                />} label={`Shround`} />
                        </Stack>
                        <Stack style={{width: '100%', marginLeft: 10, marginRight: 10}}>
                            <FormControl variant="standard"  fullWidth style={{marginTop: 10, marginLeft: 10, marginRight: 10}} 
                                disabled={!DeviceTypeIWob(props.package.Device)} >
                                <InputLabel shrink={true} sx={formInputLabelStyle} >Xcel Wobbler Type</InputLabel>
                                <Select
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) return "Select";
                                        else return getIWobTypeLabel(selected);
                                    }}                               
                                    value={props.packageValidator.plate.ldn.wobbler.value || ""} 
                                    onChange={(ev) => props.packageValidator.plate.ldn.wobbler.set(ev.target.value as idxWobbler)}>
                                    {
                                        props.packageValidator.plate.ldn.wobbler.allowableValues.map((key: idxWobbler) => 
                                            <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("XcelWobblerType", key.toString()))} onMouseLeave={props.handleTooltipClose} key={key} value={key}>{getIWobTypeLabel(key)}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <FormControl variant="standard"  fullWidth style={{marginTop: 10, marginLeft: 10, marginRight: 10}} disabled={!DeviceTypeXiWob(props.package.Device) || GetXiWobTypes(props.package).length === 0} >
                                <InputLabel shrink={true} sx={formInputLabelStyle} >I-Wob Type</InputLabel>
                                <Select
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) return "Select";
                                        else return getXiWobTypeLabel(selected);
                                    }}                       
                                    value={props.packageValidator.plate.ldn.xiWob.value || ""} 
                                    onChange={(ev) => props.packageValidator.plate.ldn.xiWob.set(ev.target.value as idxXiWob)}>
                                    {
                                        props.packageValidator.plate.ldn.xiWob.allowableValues.map((key) => 
                                            <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("XiWobType", key.toString()))} onMouseLeave={props.handleTooltipClose} key={key} value={key}>{getXiWobTypeLabel(key)}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            <FormControlLabel style={{marginTop: 30}} control={<Checkbox 
                                    style={{padding: 0, paddingLeft: 20, paddingRight: 5}}   
                                    disabled={props.package.Device !== DeviceTypes.SenningerXcelWobbler}                          
                                    checked={props.packageValidator.plate.ldn.wobblerType605Blue.value || false}
                                />} label={`Xcel Wobbler Type: 605 Blue`} />
                        </Stack>
                        <Stack style={{width: '100%', marginLeft: 10, marginRight: 20}}>
                            <FormControl variant="standard"  fullWidth style={{marginTop: 10, marginLeft: 10, marginRight: 10}} 
                                disabled={props.package.Device !== DeviceTypes.SenningerSuperSpray}
                                error={props.packageValidator.plate.ldn.superSprayPad.isError}>
                                <InputLabel shrink={true} sx={formInputLabelStyle} >Super Spray Pad</InputLabel>
                                <Select
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) return "Select";
                                        else return getSuperSprayLabel(selected);
                                    }}                       
                                    value={props.packageValidator.plate.ldn.superSprayPad.value || ""} 
                                    onChange={(ev) => props.packageValidator.plate.ldn.superSprayPad.set(ev.target.value as SuperSprayPadTypes)}>
                                    {
                                        props.packageValidator.plate.ldn.superSprayPad.allowableValues.map((key) => 
                                            <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("SuperSprayPad", key.toString()))} onMouseLeave={props.handleTooltipClose} key={key} value={key}>{getSuperSprayLabel(key)}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Stack>
                    </Stack>
            } 
        </>
    );
};

export default PlateOptions;