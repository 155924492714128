import AddIcon from "@mui/icons-material/Add";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    Button,
    Divider,
    Fab,
    IconButton,
    InputBase,
    Select,
    Stack,
    Toolbar,
    Tooltip
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import i18next, { t } from "i18next";
import IDbGrower from "rdptypes/api/IGrowerBase";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createNewUpdateProjectPropertyAction } from "../../../actions/UpdateProjectProperty";
import { getDefaultNewProjectActions } from "../../../actions/defaultActions";
import AuthCtx from "../../../auth/AuthCtx";
import ConfirmationDialog from "../../../components/General/ConfirmationDialog";
import NewProjectWizard from "../../../components/NewProjectWizard";
import RdpAppBar from "../../../components/RdpAppBar";
import { formatAddress } from "../../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import DbCtx from "../../../db/DbCtx";
import IProject, { ProjectState } from "../../../model/project/IProject";
import GrowerListTab from "./GrowerListTab";
import GrowerDialog from "./NewGrowerDialog";
import ProjectListMapTab from "./ProjectListMapTab";
import ProjectListNoProjectsTab from "./ProjectListNoProjectsTab";
import ProjectListTableTab from "./ProjectListTableTab";

// From:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
// Escaping reg exp strings
function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export interface IProjectWithId {
  id: string;
  project: IProject;
}

export interface IGrowerWithId {
  id: string;
  grower: IDbGrower;
}

export enum Level {
  grower,
  field
}

const ProjectList: FC<any> = (props) => {
  const navigate = useNavigate();

  const dbState = useContext(DbCtx);
  const authState = useContext(AuthCtx);

  const [ showMapTab, setShowMapTab ] = useState(false);

  const [searchParams] = useSearchParams();
  const growerIdFromParam = searchParams.get("grower");

  const [newProjectId, setNewProjectId] = useState(
    undefined as string | undefined
  );

  const [growerModalOpen, setGrowerModalOpen] = useState<{open: boolean, gwid: IGrowerWithId | undefined}>({open: false, gwid: undefined});
  const [deleteGrowerModalOpen, setDeleteGrowerModalOpen] = useState<{open: boolean, gwid: IGrowerWithId | undefined}>({open: false, gwid: undefined});

  const [ searchString, setSearchString ] = useState<string>("");
  const searchRegex = new RegExp(escapeRegExp(searchString), "i");
  const ns = "projects.";

  const navigateTo = (projectId?: string, layoutId?: string, systemId?: string) => {
    if (systemId && layoutId){
      navigate(`/projects/${projectId}/layouts/${layoutId}/systems/${systemId}/design`);
    }
    else if (layoutId){
      navigate(`/projects/${projectId}/layouts/${layoutId}/map`);
    }
    else if (projectId){
      navigate(`/projects/${projectId}`);
    }
    else {
      navigate("/projects");
    }
  }

  const activeProjects = {...dbState.projects};
  for (const id in activeProjects) {
    if (activeProjects[id].state.projectState !== ProjectState.Active) {
      delete activeProjects[id];
    }
  }
  
  const filterProjects = (growerId: string | null): IProjectWithId[] => Object.entries(dbState.projects)
      .filter(([_, project]) => {
        const projGrower = dbState.growers[project.state.growerId];
        let projGrowerName = projGrower ? projGrower.name : "";

      if (growerId && growerId in dbState.growers){
        const dbGrowerName = dbState.growers[growerId!].name;
        if (projGrowerName !== dbGrowerName){
          return false;
        }
      } else {
        if (projGrower?.deleted) {
          // If not querying by groweId then hide projects for deleted growers
          return false;
        }
      }

      const projectName = project.state.name;
      return searchRegex.test(projGrowerName) || searchRegex.test(projectName);
    }).map(([ id, dbProject ]) => ({ id, project: dbProject.state }));

  const filterGrowers = (growerId: string | null): IGrowerWithId[] => {
    const filteredGrowers = Object.entries(dbState.growers)
    .filter(([id, grower]) => {
      if (growerId && growerId !== id){
        return false;
      }
      return searchRegex.test(grower.name);
    }).map(([ id, dbGrower ]) => ({ id, grower: dbGrower }));
    return filteredGrowers;
  }

  const highlightedText = (text: string) => {
    if (!searchRegex) {
      return text;
    }
    const match = searchRegex.exec(text);
    if (!match || text === undefined) {
      return text;
    }
    return (
      <>
        <span>{text.slice(0, match.index)}</span>
        <b style={{ color: "#1976d2" }}>{match[0]}</b>
        <span>{text.slice(match.index + match[0].length)}</span>
      </>
    );
  };

  const filteredProjectsWithIds = filterProjects(growerIdFromParam);
  const filteredGrowersWithIds = filterGrowers(growerIdFromParam).sort((a, b) => a.grower.name.localeCompare(b.grower.name));

  let growerAddress: string = undefined;
  let grower = growerIdFromParam && dbState.growers[growerIdFromParam] ? dbState.growers[growerIdFromParam] : undefined;
  
  if (typeof(grower) !== "undefined" && grower.shippingAddress) {

    growerAddress = formatAddress(grower.shippingAddress);
  }

  const level = growerIdFromParam ? Level.field : Level.grower;

  let tabContent: JSX.Element;
  if (Object.keys(dbState.growers).length === 0) {
    tabContent = <ProjectListNoProjectsTab/>;
  }
  else {
    if (showMapTab) {
      tabContent = <ProjectListMapTab 
          growers={dbState.growers}
          searchRegex={searchRegex}
          navigateTo={navigateTo}
          projects={filteredProjectsWithIds}
        />
    }
    else {
      if (level === Level.grower){
          tabContent = <GrowerListTab
          highlightText={highlightedText}
          searchRegex={searchRegex}
          selectGrower={(growerId) => {
            navigate("/projects?grower=" + growerId);
          }}
          growers={filteredGrowersWithIds}
          setGrowerDialogOpen={(id: string) => {
            let gwid: IGrowerWithId = {
              id,
              grower: dbState.growers[id]
            }

            setGrowerModalOpen({open: true, gwid});
          }}
        />
      }
      else if (level === Level.field){
        tabContent = <ProjectListTableTab
            clearSearch={() => setSearchString("")}
            gwid={growerIdFromParam && dbState.growers[growerIdFromParam] ? {id: growerIdFromParam, grower: dbState.growers[growerIdFromParam]} : undefined}
            highlightText={highlightedText}
            dbState={dbState} 
            searching={searchString !== ""}
            navigateTo={navigateTo}
            projects={filteredProjectsWithIds}
            setGrowerDialogOpen={(id: string) => {
              let gwid: IGrowerWithId = {
                id,
                grower: dbState.growers[id]
              }

              setGrowerModalOpen({open: true, gwid});
            }}
          />
      }
    }
  }

  return (
    <>
      <RdpAppBar
        showUndo={false}
        leftContent={
          <>
            <Button 
              onClick={() => setShowMapTab(false)}
              sx={{ 
                color: "inherit", 
                backgroundColor: !showMapTab ? "darkblue" : 'inherit'
              }}
            >
              {t("list")}
            </Button>
            <Button 
              onClick={() => setShowMapTab(true)}
              sx={{ 
                color: "inherit",
                backgroundColor: showMapTab ? "darkblue" : 'inherit'
              }}
            >
              {t("map")}
            </Button>
              <Stack direction="row" spacing={3} sx={{alignItems:"center"}}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder={`${t(ns + "search-by")} ${i18next.format(t('csv-upload-dialog.grower-name'), 'lowercase')}...`}
                      inputProps={{ "aria-label": "search" }}
                      onChange={(ev) => setSearchString(ev.target.value)}
                      value={searchString}
                      style={{fontSize: '0.875rem'}}
                    />              
                    <Divider sx={{ height: 'auto', m: 0.5 }} orientation="vertical" />
                    <Tooltip title="Clear Search" placement="bottom">
                      <span>
                        <IconButton 
                          aria-label="clear-search"
                          disabled={!searchString || searchString === ''}
                          sx={{ p: '10px', color: 'inherit' }}
                          onClick={() => setSearchString("")}
                        >
                          <ClearIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Search>
              </Stack>
          </>
        }
      />
      <Toolbar variant="dense" />
      <Box p={2}>
        {tabContent}
      </Box>
      <Fab
        color="primary"
        variant="extended"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
        onClick={() => {
          if (level === Level.field){
            if (dbState.growers[growerIdFromParam]?.owner !== authState.assumedUserId &&
                dbState.growers[growerIdFromParam]?.sharedWithDealership === "readonly") {
                    alert("You do not have permission to create fields for this grower.");
                    return;
                }

            // Open the modal as soon as the new project is created
            setNewProjectId(
              dbState.newProject(getDefaultNewProjectActions(authState, growerIdFromParam).actions)
            );
          }
          else {
            setGrowerModalOpen({open: true, gwid: undefined});
          }
        }}
      >
        <AddIcon sx={{ mr: 1 }} />
          {level === Level.field ? t(ns + "new-field") : t(ns + "new-grower")}
      </Fab>

      {newProjectId && newProjectId in dbState.projects && (
        <NewProjectWizard
          selectedGrowerId={growerIdFromParam}
          projectId={newProjectId}
          disableChangeGrower={true}
          defaultFieldAddress={growerAddress}
          closeWizard={() => {
            dbState.projects[newProjectId].pushAction(createNewUpdateProjectPropertyAction("projectState", ProjectState.Deleted, authState));
            setNewProjectId(undefined);
            setGrowerModalOpen({open: false, gwid: growerModalOpen.gwid ?? undefined});
          }}
        />
      )}

      {growerModalOpen.open && (
        <GrowerDialog
          gwid={growerModalOpen.gwid ?? undefined}
          setDeleteGrowerDialogOpen={(id: string) => {
            setGrowerModalOpen({open: false, gwid: growerModalOpen.gwid ?? undefined });
            setDeleteGrowerModalOpen({open: true, gwid: growerModalOpen.gwid ?? undefined});
          }}
          dbState={dbState}
          open
          onClose={async(grower: IDbGrower, existingGrowerId? : string) => {
            if (grower){
              if (existingGrowerId){
                const gid = await dbState.updateGrower(grower, existingGrowerId);
              }
              else {
                const gid = await dbState.newGrower(grower);

                if (gid){
                  let url = "/projects?grower=" + gid;
                  navigate(url);
                }
              }

            }
            setGrowerModalOpen({open: false, gwid: growerModalOpen.gwid ?? undefined});
          }}
        />

      )}
      {
        deleteGrowerModalOpen.open && (
          <ConfirmationDialog
            onClose={() => {
              setDeleteGrowerModalOpen({open: false, gwid: growerModalOpen.gwid ?? undefined});
              setGrowerModalOpen({open: true, gwid: growerModalOpen.gwid ?? undefined });
            }}
            open
            onConfirm={async() => {
              if (typeof deleteGrowerModalOpen.gwid !== "undefined"){
                await dbState.deleteGrower(deleteGrowerModalOpen.gwid.grower, deleteGrowerModalOpen.gwid.id, true);
                navigate("/projects");
                setDeleteGrowerModalOpen({open: false, gwid: undefined});
              }
            }}
            text="Are you sure you would like to delete this grower?"
            title="Delete grower"
          />
        )
      }
    </>
  );
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  display: 'flex'
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "30ch",
      "&:focus": {
        width: "30ch",
      },
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  color: "white",
  "& .MuiInputBase-input": {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

export default ProjectList;
