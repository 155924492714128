import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import { FC, useContext } from "react";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import { IMiscItem } from "../../../../model/project/ISystem";
import MiscItemRow from "./MiscItemRow";

interface Props {
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
};

const MiscItemsRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    let initialRowCount = 10;

    const getMiscItemRows = (): {rowItems: IMiscItem[], miscItems: IMiscItem[]} => {
        let miscItems: IMiscItem[] = [];
        if (system.QuoteProperties && system.QuoteProperties.MiscItems){
            miscItems = [...system.QuoteProperties.MiscItems];
        }

        let rowItems: IMiscItem[] = [];

        miscItems.forEach((item) => {
            rowItems.push(item);
        });
    
        if (rowItems.length < initialRowCount){
            let count = rowItems.length;
            for (let i = count; i < initialRowCount; i++){
                rowItems.push(null);
            }
        }
        return {rowItems, miscItems}
    }
    
    let items = getMiscItemRows();

    /*props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
        props.layoutId,
        props.systemId,
        "QuoteProperties.MiscItems",
        [],
        authState));*/

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{}} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>{i18next.format(i18next.t("description"), 'capitalize')}</TableCell>
                            <TableCell>{i18next.format(i18next.t("price"), 'capitalize')}</TableCell>
                            <TableCell>{i18next.format(i18next.t("taxable"), 'capitalize')}?</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.rowItems.map((x, idx) => {
                            return <MiscItemRow 
                            key={x?.id ?? idx}
                                idx={idx}
                                deleteMiscItem={(idx) => {
                                    let miscItems = [...items.miscItems];
                                    let existingMiscItem = miscItems[idx];
                                    if (existingMiscItem){
                                        miscItems.splice(idx, 1);
                                    }

                                    props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                        props.layoutId,
                                        props.systemId,
                                        "QuoteProperties.MiscItems",
                                        miscItems,
                                        authState));
                                }}
                                saveMiscItem={(item, idx) => {
                                    let miscItems = [...items.miscItems];
                                    let existingMiscItem = miscItems[idx];
                                    if (existingMiscItem){
                                        existingMiscItem.description = item.description;
                                        existingMiscItem.priceCents = item.priceCents;
                                        existingMiscItem.taxable = item.taxable;
                                    }
                                    else {
                                        miscItems.push(item);
                                    }

                                    props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
                                        props.layoutId,
                                        props.systemId,
                                        "QuoteProperties.MiscItems",
                                        miscItems,
                                        authState));
                                }}
                                miscItem={x}
                            />
                        })}
                    </TableBody>
                </Table>
                
            </TableContainer>
        </>
    );
};

export default MiscItemsRenderer;