import { MenuItem } from "@mui/material";
import * as React from "react";
import IDbProject from "../../../../../../db/IDbProject";

interface IProps {
    dbPrj: IDbProject;
    layoutId: string;
    onClick: () => void;
}

const ShareFieldMenuItem: React.FC<IProps> = ({ 
    dbPrj, layoutId, onClick
}) => {
    
    const handleMenuItemClick = () => {
        onClick();
    }

    return (
        <>
            <MenuItem
                component="label"
                onClick={handleMenuItemClick}
            >
                Share Field
            </MenuItem>
        </>
    )
}


export default ShareFieldMenuItem;