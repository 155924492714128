import { SACOrientation } from "./SACOrientation";

export class SACModelConfiguration {
    public InchesToMetres = 0.0254;
    public StwrWidthMetres = 210 * this.InchesToMetres;
    public WheelWidthMetre = 169.5 * this.InchesToMetres;
    public PivotRotationRateDegreesPerSecond = 1;
    
    public SwingSpanLengthMetres: number;
    public EndBoomLengthMetres: number;
    public ProfileMetres: number[];
    public Orientation: SACOrientation;
    public PivotSpanLengthMetres: number;

    constructor(
        swingSpanLengthMetres: number, 
        endBoomLengthMetres: number, 
        profileMetres: number[],
        orientation: SACOrientation,
        pivotSpanLengthMetres: number
    ) {
        this.SwingSpanLengthMetres = swingSpanLengthMetres;
        this.EndBoomLengthMetres = endBoomLengthMetres;
        this.ProfileMetres = profileMetres;
        this.Orientation = orientation;
        this.PivotSpanLengthMetres = pivotSpanLengthMetres;
    }
}