// Option Infer On
// Imports ReinkeSystems.Data

import { BuckBoostTypes } from "../../rdptypes/project/ISystemBase.AutoGenerated";
import { MaxAmpsFor10GWire, MaxAmpsFor12GWire } from "./CommonConstants";
import { MotorLocations } from "./CommonEnums";
import ElectricalCableGaugeHelper from "./ElectricalCableGaugeHelper";
import ElectricalMotor from "./ElectricalMotor";
import ElectricalMotorsDictionary from "./ElectricalMotorsDictionary";
import * as spanf from "./SpanFunctions";
import { strLanguageText } from "./TranslationsLib";
import { Side, Span } from "./Types";
import * as vbCompat from "./VbCompat";

export default class ElectricalCalculatorSide {

    private _PowerTowerAmps: number = 0;
    private _TotalKilowatts: number = 0;
    private readonly _IsSinglePhase: boolean = false;
    private readonly _MinVoltage: number = 0;

    public Motors: ElectricalMotorsDictionary = new ElectricalMotorsDictionary();

    constructor(IsSinglePhase: boolean, MinVoltage: number, private side: Side) {
        this._IsSinglePhase = IsSinglePhase
        this._MinVoltage = MinVoltage
    }

    public get FullLoadAmps(): number {
        if (this.Motors.Count > 0) {
            return this.Motors.getByIndex(0).CumulativeAmperage;
        }
        return 0;
    }

    public get PowerTowerAmps(): number {
        return this._PowerTowerAmps;
    }

    public get TotalKilowatts(): number {
        return this._TotalKilowatts;
    }

    public TotalKilowattsCalc() {
        this._TotalKilowatts = 0;
        for (let i = 0; i < this.Motors.Count; i++) {
            this._TotalKilowatts += this.Motors.getByIndex(i).LoadKilowatts;
        }
    }

    public TotalAmpsCalc() {
        this._PowerTowerAmps = 0;

        let Items: number = this.Motors.Count - 1;
        for (let i = 0; i <= Items; i++) {
            let m: ElectricalMotor = this.Motors.getByIndex(i);
            switch (m.Location) {
                case MotorLocations.PowerTower:
                case MotorLocations.VRIAirCompPowerTower:
                    this._PowerTowerAmps += m.LoadAmperage;
                    break;
            }
        }

        // 'NOTE: This is where CumulativeAmperage is rolled up to the first motor.
        // '   Basically saying what is the Amperage at motor location going down the system.
        for (let i = Items; i >= 0; i--) {
            let m: ElectricalMotor = this.Motors.getByIndex(i);
            m.CumulativeAmperage = m.LoadAmperage;
            if (i < Items) {
                m.CumulativeAmperage += this.Motors.getByIndex(i + 1).CumulativeAmperage;
                if (m.BuckBoostType !== BuckBoostTypes.aNone) {
                    // 'MsgBox(.CumulativeAmperage)
                    m.CumulativeAmperage *= 1.025;
                    // 'MsgBox(.CumulativeAmperage)
                }
            }
        }
    }

    public VoltageCalc() {
        let PrevSpan: Span = undefined;
        let sngPrevVoltage: number = 0;
        for (let i = 0; i < this.Motors.Count; i++) {
            // 'For Each m As Motor in Motors.Values
            let m: ElectricalMotor = this.Motors.getByIndex(i);
            if (i > 0) { // 'Would use For Each if not for this i > 0 condition ???
                if (m.Span !== undefined && m.Span !== PrevSpan) {
                    let l: number = spanf.LengthInFeet(this.side, m.Span);
                    let sngVoltageLoss: number;
                    if (this._IsSinglePhase) 
                        sngVoltageLoss = l * m.CableGaugeHelper.OhmsPerFoot * m.CumulativeAmperage * 2;
                    else
                        sngVoltageLoss = l * m.CableGaugeHelper.OhmsPerFoot * m.CumulativeAmperage * 1.7320508075688772; //'Sqrt(3)
                    m.VoltagePresent = sngPrevVoltage - sngVoltageLoss;
                }
                else {
                    m.VoltagePresent = sngPrevVoltage;
                }
            }
            // 'Figure KVA before BuckBoost application
            if (this._IsSinglePhase)
                m.KVA = (m.VoltagePresent * m.CumulativeAmperage) / 1000;
            else
                m.KVA = (m.VoltagePresent * m.CumulativeAmperage * 1.7320508075688772) / 1000;
            if (m.BuckBoostType !== BuckBoostTypes.aNone) m.VoltagePresent *= 1.1;
            PrevSpan = m.Span
            sngPrevVoltage = m.VoltagePresent
        }
    }

    private UndervoltOccuring(): number {
        let Items: number = this.Motors.Count - 1;
        for (let i = Items; i >= 0; i--) {
            if (this.Motors.getByIndex(i).VoltagePresent < this._MinVoltage) return i;
        }
        return -1;
    }

    public AutoGaugeCalc(FusedAmps: number): string {
        let Items: number = this.Motors.Count - 1;
        let BuckBoostLocation: number = 0;

        if (Items < 0) return "";

        // 'Change wire size based on fused amps. A cable must be able to handle the fused amps.
        // 'Also get BuckBoost location
        for (let i = 0; i <= Items; i++) {
            let m: ElectricalMotor = this.Motors.getByIndex(i);
            let g: ElectricalCableGaugeHelper = m.CableGaugeHelper;
            switch (g.Gauge) {
                case 12:
                    if (FusedAmps > MaxAmpsFor12GWire) {
                        if (!g.IncreaseGauge()) return strLanguageText("lsltHighAmpsOnCable");
                        g.SetFloor();
                        if (FusedAmps > MaxAmpsFor10GWire) {
                            if (!g.IncreaseGauge()) return strLanguageText("lsltHighAmpsOnCable");
                            g.SetFloor();
                        }
                        this.VoltageCalc();
                    }
                    break;
                case 10:
                    if (FusedAmps > MaxAmpsFor10GWire) {
                        if (!g.IncreaseGauge()) return strLanguageText("lsltHighAmpsOnCable");
                        g.SetFloor();
                        this.VoltageCalc();
                    }
                    break;
            }
            if (m.BuckBoostType !== BuckBoostTypes.aNone) BuckBoostLocation = m.Span.SpanNumber;
        }

        // 'Do we have an undervolt condition?
        if (this.UndervoltOccuring() > -1) {
            // 'First, eliminate any 12G on the system 
            for (let i = 0; i <= Items; i++) {
                let m: ElectricalMotor = this.Motors.getByIndex(i);
                if (m.CableGaugeHelper.Gauge === 12 && m.CableGaugeHelper.IncreaseGauge()) {
                    this.VoltageCalc();
                    if (this.Motors.getByIndex(Items).VoltagePresent >= this._MinVoltage) break;
                }
            }
        }

        // 'Do we still have an undervolt condition?
        if (this.UndervoltOccuring() > -1) {
            // 'Second, eliminate any 10G on the system
            for (let i = 0; i <= Items; i++) {
                let m: ElectricalMotor = this.Motors.getByIndex(i);
                if (m.CableGaugeHelper.Gauge === 10 && m.CableGaugeHelper.IncreaseGauge()) {
                    this.VoltageCalc();
                    if (this.Motors.getByIndex(Items).VoltagePresent >= this._MinVoltage) break;
                }
            }
        }

        // 'We have maxed out the gauge.  Do we still have an undervolt condition?
        let z: number = this.UndervoltOccuring();
        if (z > -1) {
            let m: ElectricalMotor = this.Motors.getByIndex(z);
            if (m.Span === undefined) { // 'This shouldn't happen, but just in case
                return vbCompat.StringFormat(strLanguageText("sltBBMsg3"), m.Key);
            }
            if (BuckBoostLocation > 0) {
                if (BuckBoostLocation > m.Span.SpanNumber) {
                    return vbCompat.StringFormat(strLanguageText("sltBBMsgL1"), m.Key);
                }
                else {
                    return vbCompat.StringFormat(strLanguageText("sltBBMsgL2"), m.Key);
                }
            }
            return vbCompat.StringFormat(strLanguageText("sltBBMsg3"), m.Key);
        }

        // 'Working from the end to the beginning, see if we can use a smaller gauge
        for (let i = Items; i >= 0; i--) {
            let g: ElectricalCableGaugeHelper = this.Motors.getByIndex(i).CableGaugeHelper;
            if (g.DecreaseGauge()) {
                this.VoltageCalc();
                if (this.UndervoltOccuring() > -1) {
                    g.IncreaseGauge()
                    this.VoltageCalc();
                    return "";
                }
            }
        }

        // 'Determine if there is an overvolt condition
        for (let i = 0; i <= Items; i++) {
            let m: ElectricalMotor = this.Motors.getByIndex(i);
            if (m.VoltagePresent > 500) {
                if (m.Span === undefined) { //'This shouldn't happen, but just in case
                    return vbCompat.StringFormat(strLanguageText("sltVoltageTooHigh"), m.Key);
                }
                else {
                    return vbCompat.StringFormat(strLanguageText("sltVoltageTooHigh"), m.Span.SpanNumber);
                }
            }
        }

        return "";
    }

}