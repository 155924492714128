import { convertLength, point, bbox, Polygon, MultiPolygon, multiPolygon, booleanDisjoint, polygon, Point } from "@turf/turf";
import { polylabelExtended } from "./polylabelExtended";
import * as utm from "utm";
import { customDifference } from "../../../GeometryHelpers";

interface IArgs {
    boundary: Polygon;
    obstacles: Polygon[];
    centerBoundary?: Polygon;
}

const PRECISION = 0.1;

export interface IFullPivotOptimizerResults {
    center: Point;
    radiusFeet: number;
}
const optimize = (args: IArgs): IFullPivotOptimizerResults | undefined => {

    let holedPolygon: Polygon | MultiPolygon = args.boundary;
    for (const o of args.obstacles) {
        const diff = customDifference(holedPolygon, o);
        if (diff === null) {
            // there is no field polygon left
            return undefined;
        }
        holedPolygon = diff.geometry;
    }

    const holedMultipolygon = holedPolygon.type === 'MultiPolygon' 
                                ? holedPolygon
                                : multiPolygon([ holedPolygon.coordinates ]).geometry;

    const [ minX, minY, maxX, maxY ] = bbox(args.boundary); // bbox extent in minX, minY, maxX, maxY order
    const bboxCenter = [ 0.5 * (minX + maxX), 0.5 * (minY + maxY) ];
    const bboxCenterUtm = utm.fromLatLon(bboxCenter[1], bboxCenter[0]);

    let bestUtm: undefined | { northing: number, easting: number, radiusMeters: number } = undefined;

    let centerBoundaryCoordiatesUtm: number[][][] | undefined = undefined;
    if (args.centerBoundary) {
        centerBoundaryCoordiatesUtm = [];
        for (const ring of args.centerBoundary.coordinates) {
            const ringUtm: number[][] = [];
            for (const coord of ring) {
                const utmPoint = utm.fromLatLon(coord[1], coord[0], bboxCenterUtm.zoneNum);
                ringUtm.push([ utmPoint.easting, utmPoint.northing ])
            }
            centerBoundaryCoordiatesUtm.push(ringUtm);
        }
    }

    for (const polygonCoordinates of holedMultipolygon.coordinates) {

        if (args.centerBoundary && booleanDisjoint(polygon(polygonCoordinates), args.centerBoundary)) {
            // console.log("Ignoring this part of multipolygon, center pivot boundary does not lay within")
            continue;
        }
        
        const polygonCoordinatesUtm = polygonCoordinates.map(ring => {
            return ring.map(coord => {
                const utmPoint = utm.fromLatLon(coord[1], coord[0], bboxCenterUtm.zoneNum);
                return [ utmPoint.easting, utmPoint.northing ];
            });
        });
        
        const labeled = polylabelExtended({
            polygon: polygonCoordinatesUtm,
            precision: PRECISION,
            centerBoundary: centerBoundaryCoordiatesUtm
        });

        if (!labeled) continue;

        const radiusMeters = labeled['distance'] as number;
        if (!bestUtm || bestUtm.radiusMeters < radiusMeters) {
            const centerCoordinatesUtm = labeled.slice(0, 2);
            bestUtm = {
                radiusMeters,
                northing: centerCoordinatesUtm[1],
                easting: centerCoordinatesUtm[0]
            }
        }
    }

    if (!bestUtm) return undefined;

    const centerLatLon = utm.toLatLon(bestUtm.easting, bestUtm.northing, bboxCenterUtm.zoneNum, bboxCenterUtm.zoneLetter);

    // Note: Sometimes the polygons slight overlap the boundary.
    // This might be due to using UTM coordinates for algo and WGS80 elsewhere in the application.
    // Temporarily, reduce the best radius by 1m
    let conservativeRadius = bestUtm.radiusMeters - 1;
    if (conservativeRadius < 0) conservativeRadius = 0;

    return {
        center: point([ centerLatLon.longitude, centerLatLon.latitude ]).geometry,
        radiusFeet: convertLength(conservativeRadius, 'meters', 'feet'),
    };
}

export default optimize;