export default interface ISyncState {
    status: SyncStatus;
    firstSync: boolean;
    lastSyncSuccess?: number;
    progressPercent?: number;

    beginSync(): void;
}

export enum SyncStatus {
    NotStarted,
    InProgress,
    Success,
    Offline,
    Error
}