import { Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { t } from "i18next";
import { IRuleResult } from "rdptypes/roe/IRule";
import * as React from "react";
import { FC, useState } from "react";
import ProposalWarnings from "./ProposalWarnings";

interface Props {
    warnings: IRuleResult[];
    onGenerateProposal: () => void;
};

const ProposalWarningsRenderer: FC<Props> = (props) => {
    const [ackWarnings, setAckWarnings] = useState(false);
    return (
        <>
            <Stack sx={{ width: '100%'}} style={{paddingRight: 25}} spacing={2}>
                <ProposalWarnings warnings={props.warnings} />
                {props.warnings.some(warning => warning.severity === "warning")
                && !props.warnings.some(warning => warning.severity === "error" || warning.severity === "denied")
                && <FormControlLabel control={<Checkbox checked={ackWarnings} onChange={(e) => {
                setAckWarnings(e.target.checked);
            }} />} label="I acknowledge these warnings and want to generate a proposal anyway" />}
                {!props.warnings.some(warning => warning.severity === "error" || warning.severity === "denied") && <Button variant="contained" sx={{width: "300px"}} disabled={props.warnings.some(warning => warning.severity === "warning") && !ackWarnings} onClick={() => {props.onGenerateProposal();}}>Generate Proposal</Button> }
                {props.warnings.some(warning => warning.severity === "error" || warning.severity === "denied") && <div style={{marginTop: 10}}>{t("proposal-information.critical-errors-on-proposal-generation")}</div> }
            </Stack>
        </>
    );
};

export default ProposalWarningsRenderer;