import { IOutlet } from "rdptypes/project/ISprinklerChart";
import { DeviceTypes, IPackage, RegulatorPressures, RegulatorTypes } from "rdptypes/project/ISprinklers";
import { Span } from "../Types";

export class OutletRecord {
    public Outlet: IOutlet;
    public Span: Span;
    public Package: IPackage;

    public SpanNumber: number = 0;
    public PipeLocation: number = 0;
    public Radius: number = 0;
    public FurrowArmAdjustment: number = 0;
    public get ActualOutletRadius() {
        return this.Radius + this.FurrowArmAdjustment;
    }

    public HookPipeLocation: number = 0;
    public HookRadius: number = 0;
    public PressureAtOutlet: number = 0;
    public PressureAtDevice: number = 0;
    public GPMRequired: number = 0;
    public GPMDelivered: number = 0;
    public GPMReqNonAdjusted: number = 0;
    public GPMMaximum: number = 0;
    public DownstreamGPM: number = 0;
    public DownstreamGPMDelivered: number = 0;
    public IsTowerBoomBackOutlet: boolean = false;
    public PackageNumber: number = 0;
    public AbsoluteHeight: number = 0;
    public DeviceType: DeviceTypes = DeviceTypes.None;
    public UseNelson3030: boolean = false;
    public UseNelsonAD3030MT: boolean = false;
    public DeviceDoubled: boolean = false;
    public RegulatorPSI: RegulatorPressures = RegulatorPressures.a6;
    public RegulatorType: RegulatorTypes = RegulatorTypes.None;
    public IsESPOutlet: boolean = false;
    public IsInUse: boolean = false;
    public NozzleUsed: number = 0;
    public MaximumSpacingSetting: number = 0;
    public OutletNumber: number = 0;
    public IsSACOutlet: boolean = false;
    public IsEndBoomOutlet: boolean = false;
    public SACOutletNumber: number = 0;
    public SACZoneNumber: number = 0;
    public SACDistributionFactor: number = 0;
    public SACDistributionGPM: number = 0;
    public SACDistributionDownstreamGPM: number = 0;

    public Reset = () => {
        this.DownstreamGPM = 0;
        this.PressureAtOutlet = 0;
        this.PressureAtDevice = 0;
        this.GPMRequired = 0;
        this.GPMDelivered = 0;
        this.GPMReqNonAdjusted = 0;
        this.GPMMaximum = 0;
        this.DownstreamGPM = 0;
        this.DownstreamGPMDelivered = 0;
        this.FurrowArmAdjustment = 0;
        this.DeviceType = DeviceTypes.None;
        this.UseNelson3030 = false;
        this.UseNelsonAD3030MT = false;
        this.DeviceDoubled = false;
        this.RegulatorPSI = RegulatorPressures.a6;
        this.RegulatorType = RegulatorTypes.None;
        this.IsESPOutlet = false;
        this.IsInUse = false;
        this.NozzleUsed = 0;
        this.MaximumSpacingSetting = 0;
        this.SACOutletNumber = 0;
        this.SACZoneNumber = 0;
        this.SACDistributionFactor = 0;
        this.SACDistributionGPM = 0;
        this.SACDistributionDownstreamGPM = 0;
    }

}

