import { SideEnum } from "rdptypes/helpers/SideEnum";
import { EndGunAcresMethods, EndGunTypes, ISystemBase, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "endOfSystem",
            title:  { "en" :"End of System", "es": "Final del Sistema"},
            position: 6,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType !== SystemTypes.Ancillary,
            components: [
                {
                    type: "endOfSystemType",
                    side: SideEnum.Flanged
                },
                {
                    type: "card",
                    title: "Primary End Gun",
                    children:[
                        {
                            type: "booleangroup",
                            components: [{
                                type: "choice",
                                fieldPath: "FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary",
                                title:  { "en" :"End Gun", "es": "Tipo de Cañón Final"},
                                options: [
                                    { id: "None", title:  { "en" :'None', "es": "Ninguno"}, },  
                                    { id: "R55i", title: "R55i (Inverted)" },
                                    { id: "R55", title: "R55" },
                                    { id: "R75", title: "R75" },
                                    { id: "SingleP85", title: "P85" },
                                    { id: "SR75", title: "SR75" },
                                    { id: "SR100", title: "SR100" },
                                    { id: "SR100NV", title: "SR100NV" },
                                    { id: "SimeWing", title: "Sime Wing" },
                                    { id: "TwinMax", title: "Twin Max" },
                                    { id: "Twin101Ultra", title: "Twin 101 Ultra" }
                                ],
                                enumNameHint: "EndGunTypes",
                            }],
                            includeOnlyValidationRules: []
                        },           
                        {
                            visible: (sys: ISystemBase) => sys.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary !== "None",
                            type: "choice",
                            fieldPath: "FlangedSide.EndOfSystem.EndGun.Valve",
                            includeOnlyValidationRules: [],
                            title:  { "en" :"Valve", "es": "Valvula"},
                            options: [
                                { id: "None", title:  { "en" :'None', "es": "Ninguno"}, },      
                                { id: "Manual", title:  { "en" :'2" Manual', "es": 'Manual de 2"'}, title_centimeters:  { "en" :'5.08cm Manual', "es": 'Manual de 5.08cm'}},           
                                { id: "Diaphragm", title:  { "en" :'2" Aquamatic', "es": 'Aquamatic de 2"'}, title_centimeters:  { "en" :'5.08cm Aquamatic', "es": 'Aquamatic de 5.08cm'}},           
                                { id: "Reinke", title:  { "en" :'2" Reinke', "es": '2" Reinke'}, title_centimeters:  { "en" :'5.08cm Reinke', "es": '5.08cm Reinke'}},           
                                { id: "Auto800", title: 'Nelson 800'},           
                                { id: "Auto1000", title: 'Nelson 1000'},         
                            ],
                            enumNameHint: "ValveTypes",
                        },  
                        {
                            visible: (sys: ISystemBase) => sys.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary !== "None",
                            type: "choice",
                            fieldPath: "FlangedSide.EndOfSystem.EndGun.BoosterPump",
                            includeOnlyValidationRules: [],
                            title:  { "en" :"Booster Pump", "es": "Bomba Impulsora"},
                            options: [
                                { id: "None", title:  { "en" :'None', "es": "Ninguno"}, },      
                                { id: "TwoHP", title: '2 HP'},      
                                { id: "FiveHP", title: '5 HP'},      
                            ],
                            enumNameHint: "BoosterPumpTypes",
                        },  
                    ]
                },
                {
                    type: "card",
                    title: "Secondary End Gun",
                    children:[
                        {
                            type: "booleangroup",
                            components: [{
                                type: "choice",
                                fieldPath: "FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary",
                                title: { "en" : "End Gun", "es": "Tipo de Cañón Final"},
                                options: [
                                    { id: "None", title:  { "en" :'None', "es": "Ninguno"}, },  
                                    { id: "R55i", title: "R55i (Inverted)" },
                                    { id: "R55", title: "R55" },
                                    { id: "R75", title: "R75" }
                                ],
                                enumNameHint: "EndGunTypes",
                                includeOnlyValidationRules: []
                            }],
                        }, 
                        {
                            visible: (sys: ISystemBase) => sys.FlangedSide.EndOfSystem.EndGun.EndGunTypeSecondary !== "None",
                            type: "choice",
                            fieldPath: "FlangedSide.EndOfSystem.EndGun.SecondaryValve",
                            includeOnlyValidationRules: [],
                            title:  { "en" :"Valve", "es": "Valvula"},
                            options: [
                                { id: "None", title:  { "en" :'None', "es": "Ninguno"}, },      
                                { id: "Manual", title:  { "en" :'2" Manual', "es": 'Manual de 2"'}, title_centimeters:  { "en" :'5.08cm Manual', "es": 'Manual de 5.08cm'}},           
                                { id: "Diaphragm", title:  { "en" :'2" Aquamatic', "es": 'Aquamatic de 2"'}, title_centimeters:  { "en" :'5.08cm Aquamatic', "es": 'Aquamatic de 5.08cm'}},           
                                { id: "Reinke", title:  { "en" :'2" Reinke', "es": '2" Reinke'}, title_centimeters:  { "en" :'5.08cm Reinke', "es": '5.08cm Reinke'}},        
                                { id: "Auto800", title: 'Nelson 800'},           
                                { id: "Auto1000", title: 'Nelson 1000'},           
                            ],
                            enumNameHint: "ValveTypes",
                        }, 
                    ]
                },     
                {
                    type: "card",
                    title: "End Gun Irrigated Acres",
                    disableDealerSettings: true,
                    visible: (sys: ISystemBase) =>
                        (sys.FlangedSide.EndOfSystem.EndGun.EndGunTypePrimary ?? EndGunTypes.None) !== EndGunTypes.None 
                        && (!(sys as any).centerPivot && !(sys as any).lateral),//is a field design if either of these are set
                    children:[
                        {
                            type: "choice",
                            fieldPath: "FlangedSide.EndOfSystem.EndGun.EndGunAcresMethod",
                            title: { "en" : "Method", "es": "Method"},
                            options: [
                                { id: "Traditional", title:  { "en" :'Traditional', "es": "Traditional"}, },  
                                { id: "Precision", title: "Precision"},
                            ],
                            enumNameHint: "EndGunAcresMethods",
                            includeOnlyValidationRules: [],
                        },
                        {
                            visible: (sys: ISystemBase) => sys.FlangedSide.EndOfSystem.EndGun.EndGunAcresMethod === EndGunAcresMethods.Traditional,
                            type: "number",
                            fieldPath: "FlangedSide.EndOfSystem.EndGun.CornersWatered",
                            title:  { "en" :"Corners Watered", "es": "Corners Watered"},
                            default: 4,
                            min: 0,
                            max: 4,
                            required: true
                        },
                        {
                            visible: (sys: ISystemBase) => sys.FlangedSide.EndOfSystem.EndGun.EndGunAcresMethod === EndGunAcresMethods.Traditional,
                            type: "number",
                            fieldPath: "FlangedSide.EndOfSystem.EndGun.SidesWatered",
                            title:  { "en" :"Sides Watered", "es": "Sides Watered"},
                            default: 0,
                            min: 0,
                            max: 4,
                            required: true
                        },
                        {
                            visible: (sys: ISystemBase) => sys.FlangedSide.EndOfSystem.EndGun.EndGunAcresMethod === EndGunAcresMethods.Precision,
                            type: "precisionEndGunAcres",
                            fieldPath: "FlangedSide.EndOfSystem.EndGun.PercentOfArc"
                        }, 
                    ]
                },     
                {
                    type: "card",
                    children: [
                        {
                            type: "choice",
                            fieldPath: "FlangedSide.EndOfSystem.EndLight",
                            title:  { "en" :"End Light","es": "Luz Final"},
                            options: [
                                { id: "None", title:  { "en" :'None', "es": "Ninguno"}, },                                            
                                { id: "Standard", title:  { "en" :'Standard', "es": "Estándar" }},                                        
                                { id: "StrobeClear", title:  { "en" :'Strobe Clear', "es": "Estrobo - Borran" }},
                                { id: "StrobeAmber", title:  { "en" :'Strobe Amber', "es": "Estrobo - Ámbar" }},    
                                { id: "StrobeBlue", title:  { "en" :'Strobe Blue', "es": "Estrobo - Azul" }},   
                                { id: "StrobeGreen", title:  { "en" :'Strobe Green', "es": "Estrobo - Verde" }},   
                                { id: "StrobeRed", title:  { "en" :'Strobe Red', "es": "Estrobo - Rojo" }},   
                                { id: "Strobe400Clear", title:  { "en" :'Strobe 400 Clear',  "es": "Estrobo 400 - Borran" }},   
                                { id: "Strobe400Amber", title: { "en" : 'Strobe 400 Amber', "es": "Estrobo 400 - Ámbar"  }},   
                                { id: "Strobe400Blue", title:  { "en" :'Strobe 400 Blue' , "es": "Estrobo 400 - Azul"}},   
                                { id: "Strobe400Green", title: { "en" : 'Strobe 400 Green', "es": "Estrobo 400 - Verde" }},   
                                { id: "Strobe400Red", title:  { "en" :'Strobe 400 Red' , "es": "Estrobo 400 - Rojo" }},                             
                            ],
                            enumNameHint: "LightTypes",
                        },
                        { type: "boolean", title:  { "en" :"Constant On Kit", "es": "Kit Prendido Cte. "}, fieldPath: "FlangedSide.EndOfSystem.EndLightConstantOn"},
                    ]
                },                    
                {
                    type: "booleangroup",
                    layoutHint: "secondary",
                    components: [
                        { type: "boolean", title: "Vertical Booster Pump Mount", fieldPath: "FlangedSide.EndOfSystem.VerticalBoosterMount"},
                        { type: "boolean", title:  { "en" :"Stainless Steel Mounts and Fittings", "es": "Acero Inoxidable Monta"}, fieldPath: "FlangedSide.EndOfSystem.StainlessSteelMounts"},
                        { type: "boolean", title:  { "en" :"Strainer - Last Tower Top", "es": "Colador - Cabezal de última Torre"}, fieldPath: "FlangedSide.EndOfSystem.Strainer"},
                        { type: "boolean", title:  { "en" :"Extra Reinke End Sign", "es": "Letrero/placa Final Extra Reinke"}, fieldPath: "FlangedSide.EndOfSystem.ExtraReinkeEndSign"},
                    ],
                }
                
            ],
        },
    ]
};