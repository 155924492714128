import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Feature } from "@turf/turf";
import i18next, { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import { IInteractionState, Interaction } from "../../../interactionState";
import { AddOptimizedSystem } from "./AddOptimizedSystem";

export type ICloseSystemResponse =
    | {
          interaction: Interaction.DrawPivotSystem
      }
    | {
          interactionState: IInteractionState
      };

interface Props {
    projectId: string;
    layoutId: string;
    renderAdditionalFeatures: (features: Feature[]) => void;
    onClose: (args: ICloseSystemResponse) => void;
    onCancel: () => void;
    open: boolean;
}


const AddSystemDialog: FC<Props> = (props) => {
    const [optimizeSystemType, setOptimizeSystemType] = React.useState<"centerPivot" | "lateral" | undefined>(undefined);
  
    const ns = "map-display.";

    if (optimizeSystemType) {
        return (
          <AddOptimizedSystem 
            projectId={props.projectId}
            layoutId={props.layoutId}
            optimizeSystemType={optimizeSystemType}
            renderAdditionalFeatures={props.renderAdditionalFeatures}
            onClose={(args) => {
                setOptimizeSystemType(undefined);
                props.onClose(args);
            }}
            onCancel={() => {
                setOptimizeSystemType(undefined);
                props.onCancel();
            }}
          />
        );
    }

    return (
      <Dialog onClose={() => props.onCancel()} open={props.open}>
        <DialogTitle>{i18next.format(`${t(ns + "select-system-type")}`, 'capitalize-each')}</DialogTitle>
        <List>
          <ListItem>
            <ListItemButton
              autoFocus
              onClick={() => setOptimizeSystemType("centerPivot")}
            >
              <ListItemText sx={{ textAlign: "center" }} primary={i18next.format(`${t("center-pivot")}`, 'capitalize-each')} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              autoFocus
              onClick={() => setOptimizeSystemType("lateral")}
            >
              <ListItemText sx={{ textAlign: "center" }} primary={i18next.format(`${t("lateral")}`, 'capitalize-each')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
    );
  }

  export default AddSystemDialog;