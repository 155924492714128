const WateringDiameters: WateringDiameter[] = [
    { DevCode: 192, Nozzle1: 0.2, Pressure: 10, Height: 7, Diameter: 37.3 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 10, Height: 8, Diameter: 38.7 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 10, Height: 10, Diameter: 41.3 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 10, Height: 11, Diameter: 42.7 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 10, Height: 12, Diameter: 42.7 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 22.5 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 29.3 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 37.3 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 38.7 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 41.3 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 42.7 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 45.3 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 46.7 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 46.9 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 24 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 31 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 40 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 42 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 46 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 48 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 51.5 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 51.5 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 51.5 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 25.7 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 32.8 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 40 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 42 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 48 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 53.6 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 53.6 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 53.6 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 25.7 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 32.8 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 54.9 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 57.2 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 57.2 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 24.3 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 33.2 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 49.4 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 50.8 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 51.8 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 52.7 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 54.8 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 54.8 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 16.1 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 22.6 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 29 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 29.3 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 29.7 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 30 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 30.3 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 30.7 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 31 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 31.3 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 31.7 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 32 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 10, Height: 1, Diameter: 17.1 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 10, Height: 2, Diameter: 23.6 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 10, Height: 4, Diameter: 30.7 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 10, Height: 5, Diameter: 31.3 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 10, Height: 7, Diameter: 32.7 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 10, Height: 8, Diameter: 33.3 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 34 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 10, Height: 10, Diameter: 34.7 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 10, Height: 11, Diameter: 35.3 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 10, Height: 12, Diameter: 36 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 10, Height: 1, Diameter: 19.2 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 10, Height: 2, Diameter: 25.6 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 10, Height: 4, Diameter: 33.3 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 10, Height: 5, Diameter: 34.7 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 40 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 42 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 48 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 53.6 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 53.6 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 53.6 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 25.2 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 32.6 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 40 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 42 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 48 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 53.9 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 53.9 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 53.9 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 25.5 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 32.7 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 40 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 42 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 48 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 53.7 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 53.7 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 53.7 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 17.6 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 23.8 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 30 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 32 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 34 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 36 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 37.4 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 38.7 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 39.6 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 41.6 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 41.6 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 41.6 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 15, Height: 1, Diameter: 20.6 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 15, Height: 2, Diameter: 27.3 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 15, Height: 4, Diameter: 35.3 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 15, Height: 5, Diameter: 36.7 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 15, Height: 7, Diameter: 39.3 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 15, Height: 8, Diameter: 40.7 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 41.8 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 15, Height: 10, Diameter: 43.3 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 15, Height: 11, Diameter: 43.7 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 15, Height: 12, Diameter: 43.7 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 15, Height: 1, Diameter: 25.7 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 15, Height: 2, Diameter: 32.8 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 15, Height: 4, Diameter: 40.7 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 15, Height: 5, Diameter: 41.3 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 15, Height: 6, Diameter: 42 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 15, Height: 7, Diameter: 42.7 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 15, Height: 8, Diameter: 43.3 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 15, Height: 9, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 15, Height: 10, Diameter: 44.7 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 15, Height: 11, Diameter: 45.3 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 15, Height: 12, Diameter: 46 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 28.2 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 35.1 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 43.3 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 44.7 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 47.3 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 48.7 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 51.3 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 52.2 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 52.2 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 29.5 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 36.8 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 45.3 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 46.7 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 49.3 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 50.7 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 53.3 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 54.5 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 54.5 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 31.5 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 38.7 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 46 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 48 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 54 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 55.7 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 58.9 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 58.9 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 58.9 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 32.2 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 40.1 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 54 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 56 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 57.9 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 61.3 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 61.3 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 61.3 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 32 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 40 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 54 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 56 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 57.9 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 60.25 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 60.25 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 60.25 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 30.5 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 38.2 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 46 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 48.7 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 51.3 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 54 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 56.1 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 57.9 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 60.25 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 60.25 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 60.25 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 60.25 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 20.25 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 27.7 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 34 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 35.3 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 36.7 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 38 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 39.1 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 40.2 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 41 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 43 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 43 },
    { DevCode: 192, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 43 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 20, Height: 1, Diameter: 25 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 20, Height: 2, Diameter: 31.5 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 20, Height: 4, Diameter: 38.7 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 20, Height: 5, Diameter: 39.3 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 40 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 20, Height: 7, Diameter: 40.7 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 20, Height: 8, Diameter: 41.3 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 20, Height: 10, Diameter: 42.7 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 20, Height: 11, Diameter: 43.3 },
    { DevCode: 192, Nozzle1: 0.16, Pressure: 20, Height: 12, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 20, Height: 1, Diameter: 29.9 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 20, Height: 2, Diameter: 37 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 20, Height: 4, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 20, Height: 5, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 20, Height: 6, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 20, Height: 7, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 20, Height: 8, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 20, Height: 9, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 20, Height: 10, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 20, Height: 11, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.2, Pressure: 20, Height: 12, Diameter: 44 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 33.2 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 40.6 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 48.7 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 50.7 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 51.3 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 52.7 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 53.3 },
    { DevCode: 192, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 54 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 34.8 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 42.4 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 54 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 57.7 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 59.2 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 62.8 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 62.8 },
    { DevCode: 192, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 62.8 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 35.5 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 42.8 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 54 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 57.7 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 59.2 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 62.7 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 62.7 },
    { DevCode: 192, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 62.7 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 35 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 42.5 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 54 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 57.7 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 59.2 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 62.8 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 62.8 },
    { DevCode: 192, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 62.8 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 34.8 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 42.4 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 54 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 57.7 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 59.2 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 62.8 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 62.8 },
    { DevCode: 192, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 62.8 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 34.4 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 42.2 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 52 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 54 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 57.7 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 59.2 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 62.9 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 62.9 },
    { DevCode: 192, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 62.9 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 15.7 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 22.4 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 29 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 30 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 31 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 33 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 34 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 35 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 36 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 37 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 38 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 10, Height: 1, Diameter: 16.7 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 10, Height: 2, Diameter: 23.3 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 10, Height: 4, Diameter: 31.3 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 10, Height: 5, Diameter: 32.7 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 34 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 10, Height: 7, Diameter: 35.3 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 10, Height: 8, Diameter: 36.7 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 38 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 10, Height: 10, Diameter: 39.3 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 10, Height: 11, Diameter: 40.7 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 10, Height: 12, Diameter: 41.2 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 10, Height: 1, Diameter: 18.5 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 10, Height: 2, Diameter: 25.3 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 10, Height: 4, Diameter: 34 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 10, Height: 5, Diameter: 36 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 10, Height: 7, Diameter: 40 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 10, Height: 8, Diameter: 42 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 10, Height: 10, Diameter: 45.5 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 10, Height: 11, Diameter: 45.5 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 10, Height: 12, Diameter: 45.5 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 20.4 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 27.2 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 36 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 38 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 42 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 47.4 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 47.4 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 47.4 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 21.8 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 28.9 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 38 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 40 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 42 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 46 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 49.7 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 49.7 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 49.7 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 23.5 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 30.8 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 40 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 42 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 46 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 48 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 51.8 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 51.8 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 51.8 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 23.7 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 30.9 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 40 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 42 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 46 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 48 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 51.6 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 51.6 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 51.6 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 22.9 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 29.5 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 38.7 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 41.3 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 46.6 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 49.3 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 50.25 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 50.25 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 50.25 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 50.25 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 21.6 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 27.8 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 37.3 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 40.7 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 46.6 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 50.7 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 50.25 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 50.25 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 50.25 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 50.25 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 17.6 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 23.8 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 30 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 32.7 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 35.3 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 39.5 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 40.9 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 41.8 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 43.9 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 43.9 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 43.9 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 15, Height: 1, Diameter: 19.2 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 15, Height: 2, Diameter: 25.6 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 15, Height: 4, Diameter: 34.7 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 15, Height: 5, Diameter: 37.3 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 40 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 15, Height: 7, Diameter: 41.6 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 15, Height: 8, Diameter: 43.1 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 15, Height: 10, Diameter: 46.1 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 15, Height: 11, Diameter: 46.1 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 15, Height: 12, Diameter: 46.1 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 15, Height: 1, Diameter: 22.1 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 15, Height: 2, Diameter: 29 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 15, Height: 4, Diameter: 38.7 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 15, Height: 5, Diameter: 41.3 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 15, Height: 7, Diameter: 45.7 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 15, Height: 8, Diameter: 47.3 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 15, Height: 9, Diameter: 48.4 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 15, Height: 10, Diameter: 50.6 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 15, Height: 11, Diameter: 50.6 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 15, Height: 12, Diameter: 50.6 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 25.2 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 32.6 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 42 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 47.8 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 49.4 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 50.5 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 52.7 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 52.7 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 52.7 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 25.2 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 32.6 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 42.7 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 45.3 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 49.9 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 51.6 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 52.7 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 55 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 55 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 55 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 26.5 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 34.2 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 44.7 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 47.3 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 52 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 53.7 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 54.9 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 57.2 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 57.2 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 57.2 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 27 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 34.5 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 45.3 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 48.7 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 52 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 54.1 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 55.8 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 59.4 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 59.4 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 59.4 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 59.4 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 25.9 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 32.9 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 48 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 52 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 54 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 55.8 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 59.3 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 59.3 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 59.3 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 59.3 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 24.6 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 31.3 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 42.7 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 47.3 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 52 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 54 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 55.8 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 59.3 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 59.3 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 59.3 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 59.3 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 19.4 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 25.7 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 34.7 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 37.3 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 40 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 41.2 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 42.4 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 43.2 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 43.9 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 45.5 },
    { DevCode: 193, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 45.5 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 20, Height: 1, Diameter: 20.8 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 20, Height: 2, Diameter: 27.4 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 34 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 20, Height: 4, Diameter: 36.7 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 20, Height: 5, Diameter: 39.3 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 42 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 20, Height: 7, Diameter: 43.3 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 20, Height: 8, Diameter: 44.5 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 45.3 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 20, Height: 10, Diameter: 46.1 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 20, Height: 11, Diameter: 47.7 },
    { DevCode: 193, Nozzle1: 0.16, Pressure: 20, Height: 12, Diameter: 47.7 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 20, Height: 1, Diameter: 24.4 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 20, Height: 2, Diameter: 31.2 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 20, Height: 4, Diameter: 40 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 20, Height: 5, Diameter: 42 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 20, Height: 6, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 20, Height: 7, Diameter: 45.3 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 20, Height: 8, Diameter: 46.6 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 20, Height: 9, Diameter: 47.5 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 20, Height: 10, Diameter: 49.8 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 20, Height: 11, Diameter: 49.8 },
    { DevCode: 193, Nozzle1: 0.2, Pressure: 20, Height: 12, Diameter: 49.8 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 27.2 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 34.6 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 43.3 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 44.7 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 46 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 47.3 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 48.7 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 49.7 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 51.3 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 52 },
    { DevCode: 193, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 52 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 28.5 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 36.2 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 46 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 48 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 51.5 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 52.9 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 56.5 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 56.5 },
    { DevCode: 193, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 56.5 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 29.8 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 37.9 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 48 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 50 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 53.6 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 55.1 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 56.1 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 58.8 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 58.8 },
    { DevCode: 193, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 58.8 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 30 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 38 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 48 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 50 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 53.6 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 55.1 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 56.1 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 58.7 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 58.7 },
    { DevCode: 193, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 58.7 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 30.5 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 38.2 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 48.7 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 51.3 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 54 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 55.6 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 57.2 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 58.3 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 60.9 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 60.9 },
    { DevCode: 193, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 60.9 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 28.9 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 36.5 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 47.3 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 50.7 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 54 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 55.6 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 57.2 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 58.3 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 60.9 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 60.9 },
    { DevCode: 193, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 60.9 },
    { DevCode: 181, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 58 },
    { DevCode: 181, Nozzle1: 0.16, Pressure: 20, Height: 12, Diameter: 60 },
    { DevCode: 181, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 62 },
    { DevCode: 181, Nozzle1: 0.2, Pressure: 20, Height: 12, Diameter: 64 },
    { DevCode: 181, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 64 },
    { DevCode: 181, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 64 },
    { DevCode: 181, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 66 },
    { DevCode: 181, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 66 },
    { DevCode: 181, Nozzle1: 0.4, Pressure: 20, Height: 12, Diameter: 66 },
    { DevCode: 181, Nozzle1: 0.44, Pressure: 20, Height: 12, Diameter: 68 },
    { DevCode: 181, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 68 },
    { DevCode: 181, Nozzle1: 0.14, Pressure: 25, Height: 12, Diameter: 61 },
    { DevCode: 181, Nozzle1: 0.16, Pressure: 25, Height: 12, Diameter: 63 },
    { DevCode: 181, Nozzle1: 0.18, Pressure: 25, Height: 12, Diameter: 65 },
    { DevCode: 181, Nozzle1: 0.2, Pressure: 25, Height: 12, Diameter: 66 },
    { DevCode: 181, Nozzle1: 0.24, Pressure: 25, Height: 12, Diameter: 67 },
    { DevCode: 181, Nozzle1: 0.28, Pressure: 25, Height: 12, Diameter: 67 },
    { DevCode: 181, Nozzle1: 0.32, Pressure: 25, Height: 12, Diameter: 68 },
    { DevCode: 181, Nozzle1: 0.36, Pressure: 25, Height: 12, Diameter: 68 },
    { DevCode: 181, Nozzle1: 0.4, Pressure: 25, Height: 12, Diameter: 68 },
    { DevCode: 181, Nozzle1: 0.44, Pressure: 25, Height: 12, Diameter: 69 },
    { DevCode: 181, Nozzle1: 0.5, Pressure: 25, Height: 12, Diameter: 70 },
    { DevCode: 181, Nozzle1: 0.14, Pressure: 30, Height: 12, Diameter: 64 },
    { DevCode: 181, Nozzle1: 0.16, Pressure: 30, Height: 12, Diameter: 66 },
    { DevCode: 181, Nozzle1: 0.18, Pressure: 30, Height: 12, Diameter: 68 },
    { DevCode: 181, Nozzle1: 0.2, Pressure: 30, Height: 12, Diameter: 68 },
    { DevCode: 181, Nozzle1: 0.24, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 181, Nozzle1: 0.28, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 181, Nozzle1: 0.32, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 181, Nozzle1: 0.36, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 181, Nozzle1: 0.4, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 181, Nozzle1: 0.44, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 181, Nozzle1: 0.5, Pressure: 30, Height: 12, Diameter: 72 },
    { DevCode: 3, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 56 },
    { DevCode: 3, Nozzle1: 0.16, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 3, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 60 },
    { DevCode: 3, Nozzle1: 0.2, Pressure: 15, Height: 12, Diameter: 62 },
    { DevCode: 3, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 64 },
    { DevCode: 3, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 66 },
    { DevCode: 3, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 68 },
    { DevCode: 3, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 70 },
    { DevCode: 3, Nozzle1: 0.4, Pressure: 15, Height: 12, Diameter: 70 },
    { DevCode: 3, Nozzle1: 0.44, Pressure: 15, Height: 12, Diameter: 70 },
    { DevCode: 3, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 72 },
    { DevCode: 3, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 60 },
    { DevCode: 3, Nozzle1: 0.16, Pressure: 20, Height: 12, Diameter: 62 },
    { DevCode: 3, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 64 },
    { DevCode: 3, Nozzle1: 0.2, Pressure: 20, Height: 12, Diameter: 66 },
    { DevCode: 3, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 68 },
    { DevCode: 3, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 68 },
    { DevCode: 3, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 70 },
    { DevCode: 3, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 72 },
    { DevCode: 3, Nozzle1: 0.4, Pressure: 20, Height: 12, Diameter: 72 },
    { DevCode: 3, Nozzle1: 0.44, Pressure: 20, Height: 12, Diameter: 72 },
    { DevCode: 3, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 74 },
    { DevCode: 3, Nozzle1: 0.14, Pressure: 25, Height: 12, Diameter: 62 },
    { DevCode: 3, Nozzle1: 0.16, Pressure: 25, Height: 12, Diameter: 64 },
    { DevCode: 3, Nozzle1: 0.18, Pressure: 25, Height: 12, Diameter: 66 },
    { DevCode: 3, Nozzle1: 0.2, Pressure: 25, Height: 12, Diameter: 68 },
    { DevCode: 3, Nozzle1: 0.24, Pressure: 25, Height: 12, Diameter: 70 },
    { DevCode: 3, Nozzle1: 0.28, Pressure: 25, Height: 12, Diameter: 70 },
    { DevCode: 3, Nozzle1: 0.32, Pressure: 25, Height: 12, Diameter: 72 },
    { DevCode: 3, Nozzle1: 0.36, Pressure: 25, Height: 12, Diameter: 74 },
    { DevCode: 3, Nozzle1: 0.4, Pressure: 25, Height: 12, Diameter: 74 },
    { DevCode: 3, Nozzle1: 0.44, Pressure: 25, Height: 12, Diameter: 74 },
    { DevCode: 3, Nozzle1: 0.5, Pressure: 25, Height: 12, Diameter: 76 },
    { DevCode: 3, Nozzle1: 0.14, Pressure: 30, Height: 12, Diameter: 64 },
    { DevCode: 3, Nozzle1: 0.16, Pressure: 30, Height: 12, Diameter: 66 },
    { DevCode: 3, Nozzle1: 0.18, Pressure: 30, Height: 12, Diameter: 68 },
    { DevCode: 3, Nozzle1: 0.2, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 3, Nozzle1: 0.24, Pressure: 30, Height: 12, Diameter: 72 },
    { DevCode: 3, Nozzle1: 0.28, Pressure: 30, Height: 12, Diameter: 72 },
    { DevCode: 3, Nozzle1: 0.32, Pressure: 30, Height: 12, Diameter: 74 },
    { DevCode: 3, Nozzle1: 0.36, Pressure: 30, Height: 12, Diameter: 76 },
    { DevCode: 3, Nozzle1: 0.4, Pressure: 30, Height: 12, Diameter: 76 },
    { DevCode: 3, Nozzle1: 0.44, Pressure: 30, Height: 12, Diameter: 76 },
    { DevCode: 3, Nozzle1: 0.5, Pressure: 30, Height: 12, Diameter: 78 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 32 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 37 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 44.7 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 47.3 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 51.3 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 52.7 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 55.3 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 56.7 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 20, Height: 1, Diameter: 34 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 20, Height: 2, Diameter: 39 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 20, Height: 4, Diameter: 46.7 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 20, Height: 7, Diameter: 53.3 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 20, Height: 8, Diameter: 54.7 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 20, Height: 10, Diameter: 57.3 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 20, Height: 11, Diameter: 58.7 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 20, Height: 12, Diameter: 60 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 36 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 41 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 48.7 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 51.3 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 54 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 55.3 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 56.7 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 59.3 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 60.7 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 62 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 20, Height: 1, Diameter: 38 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 20, Height: 2, Diameter: 42 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 20, Height: 4, Diameter: 49.3 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 20, Height: 5, Diameter: 52.7 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 20, Height: 7, Diameter: 56.7 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 20, Height: 8, Diameter: 57.3 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 20, Height: 10, Diameter: 58.7 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 20, Height: 11, Diameter: 59.3 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 20, Height: 12, Diameter: 60 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 42 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 45 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 51.3 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 54.7 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 59.3 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 60.7 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 63.3 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 64.7 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 66 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 44 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 46 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 51.3 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 54.7 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 60 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 62 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 64 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 66 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 68 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 69.9 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 44 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 47 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 53.3 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 56.7 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 60 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 62 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 64 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 66 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 68 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 70 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 72 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 44 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 48 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 52 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 60 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 64 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 66 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 68 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 70 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 72 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 74 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 76 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 20, Height: 1, Diameter: 48 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 20, Height: 2, Diameter: 50 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 20, Height: 3, Diameter: 52 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 20, Height: 4, Diameter: 55.3 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 20, Height: 5, Diameter: 58.7 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 20, Height: 6, Diameter: 62 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 20, Height: 7, Diameter: 65.3 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 20, Height: 8, Diameter: 68.7 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 20, Height: 9, Diameter: 72 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 20, Height: 10, Diameter: 74.8 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 20, Height: 11, Diameter: 78.5 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 20, Height: 12, Diameter: 78.5 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 20, Height: 1, Diameter: 46 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 20, Height: 2, Diameter: 49 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 20, Height: 3, Diameter: 52 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 20, Height: 4, Diameter: 55.3 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 20, Height: 5, Diameter: 58.7 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 20, Height: 6, Diameter: 62 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 20, Height: 7, Diameter: 65.3 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 20, Height: 8, Diameter: 68.7 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 20, Height: 9, Diameter: 72 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 20, Height: 10, Diameter: 74.8 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 20, Height: 11, Diameter: 78.5 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 20, Height: 12, Diameter: 78.5 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 44 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 47 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 53.3 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 56.7 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 60 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 63.3 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 66.7 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 70 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 72.7 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 76.4 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 76.4 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 25, Height: 1, Diameter: 37 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 25, Height: 2, Diameter: 41 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 25, Height: 3, Diameter: 45 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 25, Height: 4, Diameter: 47.4 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 25, Height: 5, Diameter: 49.7 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 25, Height: 6, Diameter: 52 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 25, Height: 7, Diameter: 53.3 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 25, Height: 8, Diameter: 54.7 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 25, Height: 9, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 25, Height: 10, Diameter: 57.3 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 25, Height: 11, Diameter: 58.6 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 25, Height: 12, Diameter: 59.8 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 25, Height: 1, Diameter: 39 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 25, Height: 2, Diameter: 43 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 25, Height: 3, Diameter: 47 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 25, Height: 4, Diameter: 49.7 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 25, Height: 5, Diameter: 52.3 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 25, Height: 6, Diameter: 55 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 25, Height: 7, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 25, Height: 8, Diameter: 57 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 25, Height: 9, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 25, Height: 10, Diameter: 59 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 25, Height: 11, Diameter: 60 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 25, Height: 12, Diameter: 61 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 25, Height: 1, Diameter: 40 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 25, Height: 2, Diameter: 44.5 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 25, Height: 3, Diameter: 49 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 25, Height: 4, Diameter: 51.7 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 25, Height: 5, Diameter: 54.3 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 25, Height: 6, Diameter: 57 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 25, Height: 7, Diameter: 58.3 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 25, Height: 8, Diameter: 59.7 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 25, Height: 9, Diameter: 61 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 25, Height: 10, Diameter: 62.3 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 25, Height: 11, Diameter: 63.7 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 25, Height: 12, Diameter: 65 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 25, Height: 1, Diameter: 42 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 25, Height: 2, Diameter: 46 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 25, Height: 3, Diameter: 50 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 25, Height: 4, Diameter: 52.7 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 25, Height: 5, Diameter: 55.4 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 25, Height: 6, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 25, Height: 7, Diameter: 59.4 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 25, Height: 8, Diameter: 60.7 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 25, Height: 9, Diameter: 62 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 25, Height: 10, Diameter: 63.2 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 25, Height: 11, Diameter: 64.2 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 25, Height: 12, Diameter: 65.1 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 25, Height: 1, Diameter: 44 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 25, Height: 2, Diameter: 47.5 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 25, Height: 3, Diameter: 51 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 25, Height: 4, Diameter: 53.7 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 25, Height: 5, Diameter: 56.4 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 25, Height: 6, Diameter: 59 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 25, Height: 7, Diameter: 61 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 25, Height: 8, Diameter: 63 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 25, Height: 9, Diameter: 65 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 25, Height: 10, Diameter: 66.6 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 25, Height: 11, Diameter: 68 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 25, Height: 12, Diameter: 69.1 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 25, Height: 1, Diameter: 45 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 25, Height: 2, Diameter: 48 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 25, Height: 3, Diameter: 51 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 25, Height: 4, Diameter: 54 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 25, Height: 5, Diameter: 57 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 25, Height: 6, Diameter: 60 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 25, Height: 7, Diameter: 62.4 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 25, Height: 8, Diameter: 64.7 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 25, Height: 9, Diameter: 67 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 25, Height: 10, Diameter: 68.9 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 25, Height: 11, Diameter: 70.7 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 25, Height: 12, Diameter: 72.2 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 25, Height: 1, Diameter: 46 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 25, Height: 2, Diameter: 49.5 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 25, Height: 3, Diameter: 53 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 25, Height: 4, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 25, Height: 5, Diameter: 59 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 25, Height: 6, Diameter: 62 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 25, Height: 7, Diameter: 64.4 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 25, Height: 8, Diameter: 66.7 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 25, Height: 9, Diameter: 69 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 25, Height: 10, Diameter: 71 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 25, Height: 11, Diameter: 72.7 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 25, Height: 12, Diameter: 74.3 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 25, Height: 1, Diameter: 48 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 25, Height: 2, Diameter: 51.5 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 25, Height: 3, Diameter: 55 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 25, Height: 4, Diameter: 58.7 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 25, Height: 5, Diameter: 62.4 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 25, Height: 6, Diameter: 66 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 25, Height: 7, Diameter: 68 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 25, Height: 8, Diameter: 70 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 25, Height: 9, Diameter: 72 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 25, Height: 10, Diameter: 74 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 25, Height: 11, Diameter: 75.8 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 25, Height: 12, Diameter: 77.3 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 25, Height: 1, Diameter: 51 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 25, Height: 2, Diameter: 53 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 25, Height: 3, Diameter: 55 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 25, Height: 4, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 25, Height: 5, Diameter: 61 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 25, Height: 6, Diameter: 64 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 25, Height: 7, Diameter: 67.3 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 25, Height: 8, Diameter: 70.7 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 25, Height: 9, Diameter: 74 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 25, Height: 10, Diameter: 76.4 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 25, Height: 11, Diameter: 79.1 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 25, Height: 12, Diameter: 80.5 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 25, Height: 1, Diameter: 51 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 25, Height: 2, Diameter: 52.5 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 25, Height: 3, Diameter: 54 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 25, Height: 4, Diameter: 57.3 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 25, Height: 5, Diameter: 60.7 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 25, Height: 6, Diameter: 64 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 25, Height: 7, Diameter: 67.3 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 25, Height: 8, Diameter: 70.7 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 25, Height: 9, Diameter: 74 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 25, Height: 10, Diameter: 76.4 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 25, Height: 11, Diameter: 79.1 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 25, Height: 12, Diameter: 80.6 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 25, Height: 1, Diameter: 49 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 25, Height: 2, Diameter: 50.5 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 25, Height: 3, Diameter: 52 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 25, Height: 4, Diameter: 55.7 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 25, Height: 5, Diameter: 59.4 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 25, Height: 6, Diameter: 63 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 25, Height: 7, Diameter: 66.3 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 25, Height: 8, Diameter: 69.7 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 25, Height: 9, Diameter: 73 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 25, Height: 10, Diameter: 75.4 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 25, Height: 11, Diameter: 78 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 25, Height: 12, Diameter: 78.6 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 30, Height: 1, Diameter: 42 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 30, Height: 2, Diameter: 45 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 30, Height: 3, Diameter: 48 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 30, Height: 4, Diameter: 50 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 30, Height: 5, Diameter: 52 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 30, Height: 6, Diameter: 54 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 30, Height: 7, Diameter: 55.3 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 30, Height: 8, Diameter: 56.7 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 30, Height: 9, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 30, Height: 10, Diameter: 59.3 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 30, Height: 11, Diameter: 60.5 },
    { DevCode: 182, Nozzle1: 0.14, Pressure: 30, Height: 12, Diameter: 61.5 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 30, Height: 1, Diameter: 44 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 30, Height: 2, Diameter: 47 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 30, Height: 3, Diameter: 50 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 30, Height: 4, Diameter: 52.7 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 30, Height: 5, Diameter: 55.3 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 30, Height: 6, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 30, Height: 7, Diameter: 58.7 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 30, Height: 8, Diameter: 59.3 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 30, Height: 9, Diameter: 60 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 30, Height: 10, Diameter: 60.7 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 30, Height: 11, Diameter: 61.3 },
    { DevCode: 182, Nozzle1: 0.16, Pressure: 30, Height: 12, Diameter: 62 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 30, Height: 1, Diameter: 44 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 30, Height: 2, Diameter: 48 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 30, Height: 3, Diameter: 52 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 30, Height: 4, Diameter: 54.7 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 30, Height: 5, Diameter: 57.3 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 30, Height: 6, Diameter: 60 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 30, Height: 7, Diameter: 61.3 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 30, Height: 8, Diameter: 62.7 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 30, Height: 9, Diameter: 64 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 30, Height: 10, Diameter: 65.3 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 30, Height: 11, Diameter: 66.7 },
    { DevCode: 182, Nozzle1: 0.18, Pressure: 30, Height: 12, Diameter: 67.9 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 30, Height: 1, Diameter: 46 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 30, Height: 2, Diameter: 50 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 30, Height: 3, Diameter: 54 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 30, Height: 4, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 30, Height: 5, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 30, Height: 6, Diameter: 60 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 30, Height: 7, Diameter: 62 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 30, Height: 8, Diameter: 64 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 30, Height: 9, Diameter: 66 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 30, Height: 10, Diameter: 67.7 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 30, Height: 11, Diameter: 69.1 },
    { DevCode: 182, Nozzle1: 0.2, Pressure: 30, Height: 12, Diameter: 70.1 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 30, Height: 1, Diameter: 46 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 30, Height: 2, Diameter: 50 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 30, Height: 3, Diameter: 54 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 30, Height: 4, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 30, Height: 5, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 30, Height: 6, Diameter: 60 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 30, Height: 7, Diameter: 62.7 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 30, Height: 8, Diameter: 65.3 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 30, Height: 9, Diameter: 68 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 30, Height: 10, Diameter: 69.8 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 30, Height: 11, Diameter: 71.2 },
    { DevCode: 182, Nozzle1: 0.24, Pressure: 30, Height: 12, Diameter: 72.2 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 30, Height: 1, Diameter: 46 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 30, Height: 2, Diameter: 50 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 30, Height: 3, Diameter: 54 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 30, Height: 4, Diameter: 56.7 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 30, Height: 5, Diameter: 59.3 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 30, Height: 6, Diameter: 62 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 30, Height: 7, Diameter: 64.7 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 30, Height: 8, Diameter: 67.3 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 30, Height: 9, Diameter: 70 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 30, Height: 10, Diameter: 71.8 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 30, Height: 11, Diameter: 73.3 },
    { DevCode: 182, Nozzle1: 0.28, Pressure: 30, Height: 12, Diameter: 74.4 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 30, Height: 1, Diameter: 48 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 30, Height: 2, Diameter: 52 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 30, Height: 3, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 30, Height: 4, Diameter: 58.7 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 30, Height: 5, Diameter: 61.3 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 30, Height: 6, Diameter: 64 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 30, Height: 7, Diameter: 66.7 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 30, Height: 8, Diameter: 69.3 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 30, Height: 9, Diameter: 72 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 30, Height: 10, Diameter: 73.9 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 30, Height: 11, Diameter: 75.4 },
    { DevCode: 182, Nozzle1: 0.32, Pressure: 30, Height: 12, Diameter: 76.5 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 30, Height: 1, Diameter: 52 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 30, Height: 2, Diameter: 55 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 30, Height: 3, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 30, Height: 4, Diameter: 61.3 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 30, Height: 5, Diameter: 64.7 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 30, Height: 6, Diameter: 68 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 30, Height: 7, Diameter: 70 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 30, Height: 8, Diameter: 72 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 30, Height: 9, Diameter: 74 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 30, Height: 10, Diameter: 76 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 30, Height: 11, Diameter: 77.5 },
    { DevCode: 182, Nozzle1: 0.36, Pressure: 30, Height: 12, Diameter: 78.6 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 30, Height: 1, Diameter: 54 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 30, Height: 2, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 30, Height: 3, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 30, Height: 4, Diameter: 60.7 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 30, Height: 5, Diameter: 63.3 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 30, Height: 6, Diameter: 66 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 30, Height: 7, Diameter: 69.3 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 30, Height: 8, Diameter: 72.7 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 30, Height: 9, Diameter: 76 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 30, Height: 10, Diameter: 78 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 30, Height: 11, Diameter: 79.7 },
    { DevCode: 182, Nozzle1: 0.4, Pressure: 30, Height: 12, Diameter: 82.5 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 30, Height: 1, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 30, Height: 2, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 30, Height: 3, Diameter: 56 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 30, Height: 4, Diameter: 59.3 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 30, Height: 5, Diameter: 62.7 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 30, Height: 6, Diameter: 66 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 30, Height: 7, Diameter: 69.3 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 30, Height: 8, Diameter: 72.7 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 30, Height: 9, Diameter: 76 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 30, Height: 10, Diameter: 78 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 30, Height: 11, Diameter: 79.6 },
    { DevCode: 182, Nozzle1: 0.44, Pressure: 30, Height: 12, Diameter: 82.6 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 30, Height: 1, Diameter: 54 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 30, Height: 2, Diameter: 54 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 30, Height: 3, Diameter: 54 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 30, Height: 4, Diameter: 58 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 30, Height: 5, Diameter: 62 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 30, Height: 6, Diameter: 66 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 30, Height: 7, Diameter: 69.3 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 30, Height: 8, Diameter: 72.7 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 30, Height: 9, Diameter: 76 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 30, Height: 10, Diameter: 78 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 30, Height: 11, Diameter: 79.6 },
    { DevCode: 182, Nozzle1: 0.5, Pressure: 30, Height: 12, Diameter: 80.7 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 28 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 29 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 30 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 32 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 34 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 38 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 46.1 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 15, Height: 1, Diameter: 28 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 15, Height: 2, Diameter: 30 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 15, Height: 4, Diameter: 34 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 15, Height: 5, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 15, Height: 7, Diameter: 40.7 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 15, Height: 8, Diameter: 43.3 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 15, Height: 10, Diameter: 48.5 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 15, Height: 11, Diameter: 50.4 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 15, Height: 12, Diameter: 50.4 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 30 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 32 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 38 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 42.7 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 45.3 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 50.6 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 52.6 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 52.6 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 15, Height: 1, Diameter: 32 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 15, Height: 2, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 15, Height: 4, Diameter: 40.7 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 15, Height: 5, Diameter: 41.3 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 15, Height: 6, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 15, Height: 7, Diameter: 44.7 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 15, Height: 8, Diameter: 47.3 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 15, Height: 10, Diameter: 52.7 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 15, Height: 11, Diameter: 54.7 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 15, Height: 12, Diameter: 54.7 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 51.3 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 52.7 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 55.3 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 56.7 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 38 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 53.3 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 54.7 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 57.3 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 58.7 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 60 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 38 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 43 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 49.3 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 50.7 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 54.7 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 57.3 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 62.7 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 65.3 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 65.6 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 38 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 41 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 46.7 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 49.3 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 54.7 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 57.3 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 62.7 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 65.3 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 65.7 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 15, Height: 1, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 15, Height: 2, Diameter: 39 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 15, Height: 4, Diameter: 44.7 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 15, Height: 5, Diameter: 47.3 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 15, Height: 7, Diameter: 53.3 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 15, Height: 8, Diameter: 56.7 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 15, Height: 10, Diameter: 63.2 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 15, Height: 11, Diameter: 65.7 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 15, Height: 12, Diameter: 65.7 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 15, Height: 1, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 15, Height: 2, Diameter: 39 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 15, Height: 4, Diameter: 44.7 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 15, Height: 5, Diameter: 47.3 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 15, Height: 7, Diameter: 53.3 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 15, Height: 8, Diameter: 56.7 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 15, Height: 10, Diameter: 63.2 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 15, Height: 11, Diameter: 65.8 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 15, Height: 12, Diameter: 65.8 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 38 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 42.7 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 45.3 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 51.3 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 54.7 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 61.1 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 63.6 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 63.6 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 30 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 31 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 34.7 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 37.3 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 41.3 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 42.7 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 45.3 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 46.7 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 20, Height: 1, Diameter: 30 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 20, Height: 2, Diameter: 33 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 20, Height: 4, Diameter: 38 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 20, Height: 5, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 20, Height: 7, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 20, Height: 8, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 20, Height: 10, Diameter: 49.9 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 20, Height: 11, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 20, Height: 12, Diameter: 52.3 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 32 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 34 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 38.7 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 41.3 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 54.5 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 20, Height: 1, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 20, Height: 2, Diameter: 39 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 20, Height: 4, Diameter: 43.3 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 20, Height: 5, Diameter: 44.7 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 20, Height: 6, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 20, Height: 7, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 20, Height: 8, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 20, Height: 10, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 20, Height: 11, Diameter: 56 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 20, Height: 12, Diameter: 56.6 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 46.7 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 53.3 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 54.7 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 57.3 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 58.7 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 60 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 45 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 49.3 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 50.7 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 54.7 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 57.3 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 62.4 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 65.3 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 65.3 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 57.3 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 60.7 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 64 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 66.6 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 69.7 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 69.7 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 45 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 48.7 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 51.3 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 58 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 62 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 66 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 68.6 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 71.9 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 71.9 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 20, Height: 1, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 20, Height: 2, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 20, Height: 4, Diameter: 48.7 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 20, Height: 5, Diameter: 51.3 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 20, Height: 6, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 20, Height: 7, Diameter: 57.3 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 20, Height: 8, Diameter: 60.7 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 20, Height: 9, Diameter: 64 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 20, Height: 10, Diameter: 66.5 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 20, Height: 11, Diameter: 69.8 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 20, Height: 12, Diameter: 69.8 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 20, Height: 1, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 20, Height: 2, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 20, Height: 4, Diameter: 47.3 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 20, Height: 5, Diameter: 50.7 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 20, Height: 6, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 20, Height: 7, Diameter: 56.7 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 20, Height: 8, Diameter: 59.3 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 20, Height: 10, Diameter: 64.4 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 20, Height: 11, Diameter: 67.3 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 20, Height: 12, Diameter: 67.6 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 41 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 45.3 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 48.7 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 55.3 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 58.7 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 64.4 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 67.6 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 67.6 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 25, Height: 1, Diameter: 30 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 25, Height: 2, Diameter: 32 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 25, Height: 3, Diameter: 34 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 25, Height: 4, Diameter: 36.7 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 25, Height: 5, Diameter: 39.3 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 25, Height: 6, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 25, Height: 7, Diameter: 43.3 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 25, Height: 8, Diameter: 44.7 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 25, Height: 9, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 25, Height: 10, Diameter: 47.3 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 25, Height: 11, Diameter: 48.5 },
    { DevCode: 183, Nozzle1: 0.14, Pressure: 25, Height: 12, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 25, Height: 1, Diameter: 34 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 25, Height: 2, Diameter: 35 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 25, Height: 3, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 25, Height: 4, Diameter: 38 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 25, Height: 5, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 25, Height: 6, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 25, Height: 7, Diameter: 44 },
    { DevCode: 184, Nozzle1: 0.14, Pressure: 25, Height: 12, Diameter: 61 },
    { DevCode: 184, Nozzle1: 0.16, Pressure: 25, Height: 12, Diameter: 63 },
    { DevCode: 184, Nozzle1: 0.18, Pressure: 25, Height: 12, Diameter: 65 },
    { DevCode: 184, Nozzle1: 0.2, Pressure: 25, Height: 12, Diameter: 66 },
    { DevCode: 184, Nozzle1: 0.24, Pressure: 25, Height: 12, Diameter: 67 },
    { DevCode: 184, Nozzle1: 0.28, Pressure: 25, Height: 12, Diameter: 67 },
    { DevCode: 184, Nozzle1: 0.32, Pressure: 25, Height: 12, Diameter: 68 },
    { DevCode: 184, Nozzle1: 0.36, Pressure: 25, Height: 12, Diameter: 68 },
    { DevCode: 184, Nozzle1: 0.4, Pressure: 25, Height: 12, Diameter: 68 },
    { DevCode: 184, Nozzle1: 0.44, Pressure: 25, Height: 12, Diameter: 69 },
    { DevCode: 184, Nozzle1: 0.5, Pressure: 25, Height: 12, Diameter: 70 },
    { DevCode: 184, Nozzle1: 0.14, Pressure: 30, Height: 12, Diameter: 64 },
    { DevCode: 184, Nozzle1: 0.16, Pressure: 30, Height: 12, Diameter: 66 },
    { DevCode: 184, Nozzle1: 0.18, Pressure: 30, Height: 12, Diameter: 68 },
    { DevCode: 184, Nozzle1: 0.2, Pressure: 30, Height: 12, Diameter: 68 },
    { DevCode: 184, Nozzle1: 0.24, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 184, Nozzle1: 0.28, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 184, Nozzle1: 0.32, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 184, Nozzle1: 0.36, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 184, Nozzle1: 0.4, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 184, Nozzle1: 0.44, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 184, Nozzle1: 0.5, Pressure: 30, Height: 12, Diameter: 72 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 32 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 37 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 44.7 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 47.3 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 51.3 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 52.7 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 55.3 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 56.7 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 20, Height: 1, Diameter: 34 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 20, Height: 2, Diameter: 39 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 20, Height: 4, Diameter: 46.7 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 20, Height: 7, Diameter: 53.3 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 20, Height: 8, Diameter: 54.7 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 20, Height: 10, Diameter: 57.3 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 20, Height: 11, Diameter: 58.7 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 20, Height: 12, Diameter: 60 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 36 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 41 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 48.7 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 51.3 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 54 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 55.3 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 56.7 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 59.3 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 60.7 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 62 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 20, Height: 1, Diameter: 38 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 20, Height: 2, Diameter: 42 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 20, Height: 4, Diameter: 49.3 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 20, Height: 5, Diameter: 52.7 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 20, Height: 7, Diameter: 56.7 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 20, Height: 8, Diameter: 57.3 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 20, Height: 10, Diameter: 58.7 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 20, Height: 11, Diameter: 59.3 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 20, Height: 12, Diameter: 60 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 42 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 45 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 51.3 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 54.7 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 59.3 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 60.7 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 63.3 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 64.7 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 66 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 44 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 46 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 51.3 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 54.7 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 25, Height: 8, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 25, Height: 9, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 25, Height: 10, Diameter: 49.5 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 25, Height: 11, Diameter: 50.6 },
    { DevCode: 183, Nozzle1: 0.16, Pressure: 25, Height: 12, Diameter: 52.2 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 25, Height: 1, Diameter: 34 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 25, Height: 2, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 25, Height: 3, Diameter: 38 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 25, Height: 4, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 25, Height: 5, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 25, Height: 6, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 25, Height: 7, Diameter: 46.7 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 25, Height: 8, Diameter: 49.3 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 25, Height: 9, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 25, Height: 10, Diameter: 53.7 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 25, Height: 11, Diameter: 54.9 },
    { DevCode: 183, Nozzle1: 0.18, Pressure: 25, Height: 12, Diameter: 56.5 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 25, Height: 1, Diameter: 36 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 25, Height: 2, Diameter: 38 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 25, Height: 3, Diameter: 40 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 25, Height: 4, Diameter: 42.7 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 25, Height: 5, Diameter: 45.3 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 25, Height: 6, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 25, Height: 7, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 25, Height: 8, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 25, Height: 9, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 25, Height: 10, Diameter: 55.8 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 25, Height: 11, Diameter: 57.1 },
    { DevCode: 183, Nozzle1: 0.2, Pressure: 25, Height: 12, Diameter: 58.7 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 25, Height: 1, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 25, Height: 2, Diameter: 45 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 25, Height: 3, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 25, Height: 4, Diameter: 49.3 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 25, Height: 5, Diameter: 50.7 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 25, Height: 6, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 25, Height: 7, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 25, Height: 8, Diameter: 56 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 25, Height: 9, Diameter: 58 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 25, Height: 10, Diameter: 59.9 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 25, Height: 11, Diameter: 62 },
    { DevCode: 183, Nozzle1: 0.24, Pressure: 25, Height: 12, Diameter: 63 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 25, Height: 1, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 25, Height: 2, Diameter: 47 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 25, Height: 3, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 25, Height: 4, Diameter: 51.3 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 25, Height: 5, Diameter: 52.7 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 25, Height: 6, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 25, Height: 7, Diameter: 56.7 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 25, Height: 8, Diameter: 59.3 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 25, Height: 9, Diameter: 62 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 25, Height: 10, Diameter: 64.1 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 25, Height: 11, Diameter: 67.3 },
    { DevCode: 183, Nozzle1: 0.28, Pressure: 25, Height: 12, Diameter: 67.3 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 25, Height: 1, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 25, Height: 2, Diameter: 47 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 25, Height: 3, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 25, Height: 4, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 25, Height: 5, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 25, Height: 6, Diameter: 56 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 25, Height: 7, Diameter: 59.3 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 25, Height: 8, Diameter: 62.7 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 25, Height: 9, Diameter: 66 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 25, Height: 10, Diameter: 68.2 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 25, Height: 11, Diameter: 71.7 },
    { DevCode: 183, Nozzle1: 0.32, Pressure: 25, Height: 12, Diameter: 71.7 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 25, Height: 1, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 25, Height: 2, Diameter: 47 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 25, Height: 3, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 25, Height: 4, Diameter: 52.7 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 25, Height: 5, Diameter: 55.3 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 25, Height: 6, Diameter: 58 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 25, Height: 7, Diameter: 60.7 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 25, Height: 8, Diameter: 63.3 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 25, Height: 9, Diameter: 66 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 25, Height: 10, Diameter: 68.1 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 25, Height: 11, Diameter: 69.7 },
    { DevCode: 183, Nozzle1: 0.36, Pressure: 25, Height: 12, Diameter: 71.7 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 25, Height: 1, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 25, Height: 2, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 25, Height: 3, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 25, Height: 4, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 25, Height: 5, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 25, Height: 6, Diameter: 56 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 25, Height: 7, Diameter: 58.7 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 25, Height: 8, Diameter: 61.3 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 25, Height: 9, Diameter: 64 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 25, Height: 10, Diameter: 66.1 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 25, Height: 11, Diameter: 67.6 },
    { DevCode: 183, Nozzle1: 0.4, Pressure: 25, Height: 12, Diameter: 69.6 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 25, Height: 1, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 25, Height: 2, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 25, Height: 3, Diameter: 48 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 25, Height: 4, Diameter: 50 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 25, Height: 5, Diameter: 52 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 25, Height: 6, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 25, Height: 7, Diameter: 57.3 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 25, Height: 8, Diameter: 60.7 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 25, Height: 9, Diameter: 64 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 25, Height: 10, Diameter: 66 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 25, Height: 11, Diameter: 67.6 },
    { DevCode: 183, Nozzle1: 0.44, Pressure: 25, Height: 12, Diameter: 69.6 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 25, Height: 1, Diameter: 42 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 25, Height: 2, Diameter: 44 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 25, Height: 3, Diameter: 46 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 25, Height: 4, Diameter: 48.7 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 25, Height: 5, Diameter: 51.3 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 25, Height: 6, Diameter: 54 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 25, Height: 7, Diameter: 57.3 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 25, Height: 8, Diameter: 60.7 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 25, Height: 9, Diameter: 64 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 25, Height: 10, Diameter: 66 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 25, Height: 11, Diameter: 67.5 },
    { DevCode: 183, Nozzle1: 0.5, Pressure: 25, Height: 12, Diameter: 69.6 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 36 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 40 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 44.7 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 45.3 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 47.3 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 48.7 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 51.3 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 52.7 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 15, Height: 1, Diameter: 38 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 15, Height: 2, Diameter: 42 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 46 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 15, Height: 4, Diameter: 46.7 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 15, Height: 5, Diameter: 47.3 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 15, Height: 7, Diameter: 49.3 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 15, Height: 8, Diameter: 50.7 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 15, Height: 10, Diameter: 53.3 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 15, Height: 11, Diameter: 54.7 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 15, Height: 12, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 40 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 44 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 49.3 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 50.7 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 53.3 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 54.7 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 57.3 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 58.7 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 15, Height: 2, Diameter: 46 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 15, Height: 4, Diameter: 51.3 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 15, Height: 5, Diameter: 52.7 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 15, Height: 6, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 15, Height: 7, Diameter: 55.3 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 15, Height: 8, Diameter: 56.7 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 15, Height: 10, Diameter: 59.3 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 15, Height: 11, Diameter: 60.7 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 15, Height: 12, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 46 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 49 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 53.3 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 54.7 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 57.3 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 58.7 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 61.3 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 62.7 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 48 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 59.3 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 60.7 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 63.3 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 64.7 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 48 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 51 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 55.3 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 56.7 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 69.8 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 53 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 57.3 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 58.7 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 62.7 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 65.3 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 70.7 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 73.3 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 74.1 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 15, Height: 1, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 15, Height: 2, Diameter: 53 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 15, Height: 3, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 15, Height: 4, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 15, Height: 5, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 15, Height: 6, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 15, Height: 7, Diameter: 64.7 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 15, Height: 8, Diameter: 67.3 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 15, Height: 9, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 15, Height: 10, Diameter: 72.7 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 15, Height: 11, Diameter: 75.3 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 15, Height: 12, Diameter: 76.3 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 15, Height: 1, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 15, Height: 2, Diameter: 53 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 15, Height: 3, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 15, Height: 4, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 15, Height: 5, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 15, Height: 6, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 15, Height: 7, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 15, Height: 8, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 15, Height: 9, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 15, Height: 10, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 15, Height: 11, Diameter: 72 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 15, Height: 12, Diameter: 74 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 48 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 72 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 74 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 42 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 45 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 48.7 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 51.3 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 52.7 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 55.3 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 56.7 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 20, Height: 1, Diameter: 46 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 20, Height: 2, Diameter: 48 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 20, Height: 4, Diameter: 50.7 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 20, Height: 5, Diameter: 51.3 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 20, Height: 7, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 20, Height: 8, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 20, Height: 10, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 20, Height: 11, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 20, Height: 12, Diameter: 63.1 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 48 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 53.3 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 54.7 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 57.3 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 58.7 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 61.3 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 62.7 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 20, Height: 1, Diameter: 48 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 20, Height: 2, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 20, Height: 3, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 20, Height: 4, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 20, Height: 5, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 20, Height: 6, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 20, Height: 7, Diameter: 59.3 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 20, Height: 8, Diameter: 60.7 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 20, Height: 10, Diameter: 63.3 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 20, Height: 11, Diameter: 64.7 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 20, Height: 12, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 61.3 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 62.7 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 65.3 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 66.7 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 57.3 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 58.7 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 71.7 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 59.3 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 60.7 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 72 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 73.9 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 57 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 61.3 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 62.7 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 72 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 74 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 76 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 20, Height: 1, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 20, Height: 2, Diameter: 57 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 20, Height: 3, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 20, Height: 4, Diameter: 61.3 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 20, Height: 5, Diameter: 62.7 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 20, Height: 6, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 20, Height: 7, Diameter: 66.7 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 20, Height: 8, Diameter: 69.3 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 20, Height: 9, Diameter: 72 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 20, Height: 10, Diameter: 74.7 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 20, Height: 11, Diameter: 77.3 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 20, Height: 12, Diameter: 78.3 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 20, Height: 1, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 20, Height: 2, Diameter: 59 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 20, Height: 3, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 20, Height: 4, Diameter: 63.3 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 20, Height: 5, Diameter: 64.7 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 20, Height: 6, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 20, Height: 7, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 20, Height: 8, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 20, Height: 9, Diameter: 72 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 20, Height: 10, Diameter: 74 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 20, Height: 11, Diameter: 76 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 20, Height: 12, Diameter: 78 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 59 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 63.3 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 64.7 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 72 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 74 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 76 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 78 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 25, Height: 1, Diameter: 44 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 25, Height: 2, Diameter: 47 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 25, Height: 3, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 25, Height: 4, Diameter: 50.7 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 25, Height: 5, Diameter: 51.3 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 25, Height: 6, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 25, Height: 7, Diameter: 53.3 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 25, Height: 8, Diameter: 54.7 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 25, Height: 9, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 25, Height: 10, Diameter: 57.3 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 25, Height: 11, Diameter: 58.7 },
    { DevCode: 2, Nozzle1: 0.14, Pressure: 25, Height: 12, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 25, Height: 1, Diameter: 46 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 25, Height: 2, Diameter: 49 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 25, Height: 3, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 25, Height: 4, Diameter: 52.7 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 25, Height: 5, Diameter: 53.3 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 25, Height: 6, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 25, Height: 7, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 25, Height: 8, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 25, Height: 9, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 25, Height: 10, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 25, Height: 11, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.16, Pressure: 25, Height: 12, Diameter: 65.1 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 25, Height: 1, Diameter: 50 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 25, Height: 2, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 25, Height: 3, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 25, Height: 4, Diameter: 55.3 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 25, Height: 5, Diameter: 56.7 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 25, Height: 6, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 25, Height: 7, Diameter: 59.3 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 25, Height: 8, Diameter: 60.7 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 25, Height: 9, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 25, Height: 10, Diameter: 63.3 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 25, Height: 11, Diameter: 64.7 },
    { DevCode: 2, Nozzle1: 0.18, Pressure: 25, Height: 12, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 25, Height: 1, Diameter: 52 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 25, Height: 2, Diameter: 53 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 25, Height: 3, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 25, Height: 4, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 25, Height: 5, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 25, Height: 6, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 25, Height: 7, Diameter: 61.3 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 25, Height: 8, Diameter: 62.7 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 25, Height: 9, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 25, Height: 10, Diameter: 65.3 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 25, Height: 11, Diameter: 66.7 },
    { DevCode: 2, Nozzle1: 0.2, Pressure: 25, Height: 12, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 25, Height: 1, Diameter: 54 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 25, Height: 2, Diameter: 55 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 25, Height: 3, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 25, Height: 4, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 25, Height: 5, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 25, Height: 6, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 25, Height: 7, Diameter: 63.3 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 25, Height: 8, Diameter: 64.7 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 25, Height: 9, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 25, Height: 10, Diameter: 67.3 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 25, Height: 11, Diameter: 68.7 },
    { DevCode: 2, Nozzle1: 0.24, Pressure: 25, Height: 12, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 25, Height: 1, Diameter: 56 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 25, Height: 2, Diameter: 57 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 25, Height: 3, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 25, Height: 4, Diameter: 59.3 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 25, Height: 5, Diameter: 60.7 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 25, Height: 6, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 25, Height: 7, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 25, Height: 8, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 25, Height: 9, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 25, Height: 10, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 25, Height: 11, Diameter: 72 },
    { DevCode: 2, Nozzle1: 0.28, Pressure: 25, Height: 12, Diameter: 73.8 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 25, Height: 1, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 25, Height: 2, Diameter: 59 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 25, Height: 3, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 25, Height: 4, Diameter: 61.3 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 25, Height: 5, Diameter: 62.7 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 25, Height: 6, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 25, Height: 7, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 25, Height: 8, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 25, Height: 9, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 25, Height: 10, Diameter: 72 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 25, Height: 11, Diameter: 74 },
    { DevCode: 2, Nozzle1: 0.32, Pressure: 25, Height: 12, Diameter: 76 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 25, Height: 1, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 25, Height: 2, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 25, Height: 3, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 25, Height: 4, Diameter: 63.3 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 25, Height: 5, Diameter: 64.7 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 25, Height: 6, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 25, Height: 7, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 25, Height: 8, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 25, Height: 9, Diameter: 72 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 25, Height: 10, Diameter: 74 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 25, Height: 11, Diameter: 76 },
    { DevCode: 2, Nozzle1: 0.36, Pressure: 25, Height: 12, Diameter: 78 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 25, Height: 1, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 25, Height: 2, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 25, Height: 3, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 25, Height: 4, Diameter: 63.3 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 25, Height: 5, Diameter: 64.7 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 25, Height: 6, Diameter: 66 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 25, Height: 7, Diameter: 68.7 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 25, Height: 8, Diameter: 71.3 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 25, Height: 9, Diameter: 74 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 25, Height: 10, Diameter: 76.6 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 25, Height: 11, Diameter: 79.3 },
    { DevCode: 2, Nozzle1: 0.4, Pressure: 25, Height: 12, Diameter: 80.3 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 25, Height: 1, Diameter: 58 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 25, Height: 2, Diameter: 61 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 25, Height: 3, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 25, Height: 4, Diameter: 65.3 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 25, Height: 5, Diameter: 66.7 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 25, Height: 6, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 25, Height: 7, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 25, Height: 8, Diameter: 72 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 25, Height: 9, Diameter: 74 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 25, Height: 10, Diameter: 76 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 25, Height: 11, Diameter: 78 },
    { DevCode: 2, Nozzle1: 0.44, Pressure: 25, Height: 12, Diameter: 80 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 25, Height: 1, Diameter: 60 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 25, Height: 2, Diameter: 62 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 25, Height: 3, Diameter: 64 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 25, Height: 4, Diameter: 65.3 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 25, Height: 5, Diameter: 66.7 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 25, Height: 6, Diameter: 68 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 25, Height: 7, Diameter: 70 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 25, Height: 8, Diameter: 72 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 25, Height: 9, Diameter: 74 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 25, Height: 10, Diameter: 76 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 25, Height: 11, Diameter: 78 },
    { DevCode: 2, Nozzle1: 0.5, Pressure: 25, Height: 12, Diameter: 80 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 32 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 37 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 44 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 46 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 15, Height: 1, Diameter: 36 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 15, Height: 2, Diameter: 41 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 46 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 15, Height: 4, Diameter: 47.3 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 15, Height: 5, Diameter: 48.7 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 15, Height: 7, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 15, Height: 8, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 15, Height: 10, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 15, Height: 11, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 15, Height: 12, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 38 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 43 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 53.3 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 52.7 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 15, Height: 1, Diameter: 40 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 15, Height: 2, Diameter: 44 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 15, Height: 4, Diameter: 50.7 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 15, Height: 5, Diameter: 53.3 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 15, Height: 6, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 15, Height: 7, Diameter: 55.3 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 15, Height: 8, Diameter: 54.7 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 15, Height: 10, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 15, Height: 11, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 15, Height: 12, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 46 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 52.7 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 55.3 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 57.3 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 56.7 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 47 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 52.7 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 55.3 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 47 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 52.7 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 55.3 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 15, Height: 2, Diameter: 47 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 15, Height: 4, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 15, Height: 5, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 15, Height: 7, Diameter: 58.7 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 15, Height: 8, Diameter: 59.3 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 15, Height: 10, Diameter: 60.7 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 15, Height: 11, Diameter: 61.3 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 15, Height: 12, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 15, Height: 2, Diameter: 47 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 15, Height: 4, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 15, Height: 5, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 15, Height: 7, Diameter: 59.3 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 15, Height: 8, Diameter: 60.7 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 15, Height: 10, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 15, Height: 11, Diameter: 64.7 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 15, Height: 12, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 47 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 59.3 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 60.7 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 64.7 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 38 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 42 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 47.3 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 48.7 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 51.3 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 52.7 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 55.3 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 56.7 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 20, Height: 1, Diameter: 42 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 20, Height: 2, Diameter: 45 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 20, Height: 4, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 20, Height: 5, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 20, Height: 7, Diameter: 54.7 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 20, Height: 8, Diameter: 55.3 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 20, Height: 10, Diameter: 56.7 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 20, Height: 11, Diameter: 57.3 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 20, Height: 12, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 44 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 47 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 56.7 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 57.3 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 58.7 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 59.3 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 20, Height: 1, Diameter: 46 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 20, Height: 2, Diameter: 49 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 20, Height: 3, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 20, Height: 4, Diameter: 54.7 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 20, Height: 5, Diameter: 57.3 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 20, Height: 6, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 20, Height: 7, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 20, Height: 8, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 20, Height: 10, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 20, Height: 11, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 20, Height: 12, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 51 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 60.7 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 61.3 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 62.7 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 64 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 53 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 64.7 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 67.3 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 68.7 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 53 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 64.7 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 67.3 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 68.7 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 53 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 64.7 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 67.3 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 68.7 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 20, Height: 1, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 20, Height: 2, Diameter: 53 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 20, Height: 3, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 20, Height: 4, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 20, Height: 5, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 20, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 20, Height: 7, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 20, Height: 8, Diameter: 64.7 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 20, Height: 9, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 20, Height: 10, Diameter: 67.3 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 20, Height: 11, Diameter: 68.7 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 20, Height: 12, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 20, Height: 1, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 20, Height: 2, Diameter: 53 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 20, Height: 3, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 20, Height: 4, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 20, Height: 5, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 20, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 20, Height: 7, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 20, Height: 8, Diameter: 64.7 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 20, Height: 9, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 20, Height: 10, Diameter: 67.3 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 20, Height: 11, Diameter: 68.7 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 20, Height: 12, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 51 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 56.7 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 59.3 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 64.7 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 67.3 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 68.7 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 25, Height: 1, Diameter: 42 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 25, Height: 2, Diameter: 45 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 25, Height: 3, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 25, Height: 4, Diameter: 49.3 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 25, Height: 5, Diameter: 50.7 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 25, Height: 6, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 25, Height: 7, Diameter: 54 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 25, Height: 8, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 25, Height: 9, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 25, Height: 10, Diameter: 59.8 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 25, Height: 11, Diameter: 61.2 },
    { DevCode: 186, Nozzle1: 0.14, Pressure: 25, Height: 12, Diameter: 63.1 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 25, Height: 1, Diameter: 46 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 25, Height: 2, Diameter: 49 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 25, Height: 3, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 25, Height: 4, Diameter: 53.3 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 25, Height: 5, Diameter: 54.7 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 25, Height: 6, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 25, Height: 7, Diameter: 57.3 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 25, Height: 8, Diameter: 58.7 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 25, Height: 9, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 25, Height: 10, Diameter: 61.3 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 25, Height: 11, Diameter: 62.7 },
    { DevCode: 186, Nozzle1: 0.16, Pressure: 25, Height: 12, Diameter: 64 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 25, Height: 1, Diameter: 48 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 25, Height: 2, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 25, Height: 3, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 25, Height: 4, Diameter: 56.7 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 25, Height: 5, Diameter: 57.3 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 25, Height: 6, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 25, Height: 7, Diameter: 59.3 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 25, Height: 8, Diameter: 60.7 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 25, Height: 9, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 25, Height: 10, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 25, Height: 11, Diameter: 64.7 },
    { DevCode: 186, Nozzle1: 0.18, Pressure: 25, Height: 12, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 25, Height: 1, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 25, Height: 2, Diameter: 53 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 25, Height: 3, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 25, Height: 4, Diameter: 57.3 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 25, Height: 5, Diameter: 58.7 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 25, Height: 6, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 25, Height: 7, Diameter: 61.3 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 25, Height: 8, Diameter: 62.7 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 25, Height: 9, Diameter: 64 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 25, Height: 10, Diameter: 65.3 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 25, Height: 11, Diameter: 66.7 },
    { DevCode: 186, Nozzle1: 0.2, Pressure: 25, Height: 12, Diameter: 68 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 25, Height: 1, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 25, Height: 2, Diameter: 55 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 25, Height: 3, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 25, Height: 4, Diameter: 59.3 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 25, Height: 5, Diameter: 60.7 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 25, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 25, Height: 7, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 25, Height: 8, Diameter: 64.7 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 25, Height: 9, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 25, Height: 10, Diameter: 67.3 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 25, Height: 11, Diameter: 68.7 },
    { DevCode: 186, Nozzle1: 0.24, Pressure: 25, Height: 12, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 25, Height: 1, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 25, Height: 2, Diameter: 55 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 25, Height: 3, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 25, Height: 4, Diameter: 59.3 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 25, Height: 5, Diameter: 60.7 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 25, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 25, Height: 7, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 25, Height: 8, Diameter: 64.7 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 25, Height: 9, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 25, Height: 10, Diameter: 67.3 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 25, Height: 11, Diameter: 68.7 },
    { DevCode: 186, Nozzle1: 0.28, Pressure: 25, Height: 12, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 25, Height: 1, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 25, Height: 2, Diameter: 55 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 25, Height: 3, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 25, Height: 4, Diameter: 59.3 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 25, Height: 5, Diameter: 60.7 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 25, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 25, Height: 7, Diameter: 63.3 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 25, Height: 8, Diameter: 64.7 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 25, Height: 9, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 25, Height: 10, Diameter: 67.3 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 25, Height: 11, Diameter: 68.7 },
    { DevCode: 186, Nozzle1: 0.32, Pressure: 25, Height: 12, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 25, Height: 1, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 25, Height: 2, Diameter: 55 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 25, Height: 3, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 25, Height: 4, Diameter: 59.3 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 25, Height: 5, Diameter: 60.7 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 25, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 25, Height: 7, Diameter: 64 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 25, Height: 8, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 25, Height: 9, Diameter: 68 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 25, Height: 10, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 25, Height: 11, Diameter: 72 },
    { DevCode: 186, Nozzle1: 0.36, Pressure: 25, Height: 12, Diameter: 73.8 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 25, Height: 1, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 25, Height: 2, Diameter: 55 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 25, Height: 3, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 25, Height: 4, Diameter: 59.3 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 25, Height: 5, Diameter: 60.7 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 25, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 25, Height: 7, Diameter: 64 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 25, Height: 8, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 25, Height: 9, Diameter: 68 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 25, Height: 10, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 25, Height: 11, Diameter: 72 },
    { DevCode: 186, Nozzle1: 0.4, Pressure: 25, Height: 12, Diameter: 73.8 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 25, Height: 1, Diameter: 52 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 25, Height: 2, Diameter: 55 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 25, Height: 3, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 25, Height: 4, Diameter: 59.3 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 25, Height: 5, Diameter: 60.7 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 25, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 25, Height: 7, Diameter: 64 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 25, Height: 8, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 25, Height: 9, Diameter: 68 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 25, Height: 10, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 25, Height: 11, Diameter: 72 },
    { DevCode: 186, Nozzle1: 0.44, Pressure: 25, Height: 12, Diameter: 73.8 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 25, Height: 1, Diameter: 50 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 25, Height: 2, Diameter: 53 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 25, Height: 3, Diameter: 56 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 25, Height: 4, Diameter: 58 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 25, Height: 5, Diameter: 60 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 25, Height: 6, Diameter: 62 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 25, Height: 7, Diameter: 64 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 25, Height: 8, Diameter: 66 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 25, Height: 9, Diameter: 68 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 25, Height: 10, Diameter: 70 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 25, Height: 11, Diameter: 72 },
    { DevCode: 186, Nozzle1: 0.5, Pressure: 25, Height: 12, Diameter: 73.8 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 10, Height: 1, Diameter: 20.2 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 10, Height: 2, Diameter: 26.1 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 10, Height: 4, Diameter: 33.3 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 10, Height: 5, Diameter: 34.7 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 10, Height: 7, Diameter: 37.3 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 10, Height: 8, Diameter: 38.7 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 10, Height: 10, Diameter: 41.3 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 10, Height: 11, Diameter: 42.1 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 10, Height: 12, Diameter: 42.1 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 26.1 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 33 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 40 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 41 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 42 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 43 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 44 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 45 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 47 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 48 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 49 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 29.1 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 36.6 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 44 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 45.3 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 46.7 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 48 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 49.3 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 50.7 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 53.3 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 54.7 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 55.5 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 31.8 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 38.9 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 46 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 47 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 48 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 49 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 50 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 51 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 53 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 55 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 32.7 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 39.3 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 46 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 47.3 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 48.7 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 51.3 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 52.7 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 55.3 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 56.7 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 56.9 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 33.9 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 39.9 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 46 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 48 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 50 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 34.9 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 41.5 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 48 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 49.3 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 50.7 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 53.3 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 54.7 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 57.3 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 58.7 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 58.9 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 35.1 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 41.5 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 48 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 49 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 50 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 51 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 53 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 55 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 57 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 15, Height: 1, Diameter: 25 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 15, Height: 2, Diameter: 31.5 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 15, Height: 4, Diameter: 40.3 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 15, Height: 5, Diameter: 42.7 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 15, Height: 6, Diameter: 45 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 15, Height: 7, Diameter: 46.8 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 15, Height: 8, Diameter: 48.3 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 15, Height: 9, Diameter: 51.2 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 15, Height: 10, Diameter: 51.2 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 15, Height: 11, Diameter: 51.2 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 15, Height: 12, Diameter: 51.2 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 32.4 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 40.2 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 48.7 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 49.3 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 50.7 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 51.3 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 52.7 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 53.3 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 35.2 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 42.6 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 51.3 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 52.7 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 55.3 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 56.7 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 59.3 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 60.7 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 60.9 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 37.7 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 44.8 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 52.7 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 53.3 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 54.7 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 55.3 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 56.7 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 57.3 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 38.4 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 45.2 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 53.3 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 54.7 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 57.3 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 58.7 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 61.3 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 62.7 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 62.7 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 39.6 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 45.8 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 60 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 62 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 64 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 64.7 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 64.7 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 64.7 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 38.9 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 45.4 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 60 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 62 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 64 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 64.9 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 64.9 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 64.9 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 40.7 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 46.3 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 53.3 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 54.7 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 20, Height: 1, Diameter: 27.5 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 20, Height: 2, Diameter: 33.8 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 20, Height: 3, Diameter: 40 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 20, Height: 4, Diameter: 42 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 20, Height: 5, Diameter: 44 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 20, Height: 6, Diameter: 46 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 20, Height: 7, Diameter: 47.4 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 20, Height: 8, Diameter: 48.7 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 20, Height: 9, Diameter: 51.7 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 20, Height: 10, Diameter: 51.7 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 20, Height: 11, Diameter: 51.7 },
    { DevCode: 11, Nozzle1: 0.11, Pressure: 20, Height: 12, Diameter: 51.7 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 35.5 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 42.8 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 51.3 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 52.7 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 55.3 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 56.7 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 59.3 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 60.4 },
    { DevCode: 11, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 60.4 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 37.8 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 44.9 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 52 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 53.3 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 54.7 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 57.3 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 58.7 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 61.3 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 62.5 },
    { DevCode: 11, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 62.5 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 43.3 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 48.6 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 54.7 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 55.3 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 40.9 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 47.5 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 55.3 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 56.7 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 59.3 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 60.7 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 63.3 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 64.5 },
    { DevCode: 11, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 64.5 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 43.5 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 48.8 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 55.3 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 56.7 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 41.9 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 47.9 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 56 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 58 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 60 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 62 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 64 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 66 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 66.5 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 66.5 },
    { DevCode: 11, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 66.5 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 42.1 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 48.1 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 54 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 55.7 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 57.3 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 59 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 60.7 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 62.3 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 64 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 65.4 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 65.4 },
    { DevCode: 11, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 65.4 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 10, Height: 1, Diameter: 17.1 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 10, Height: 2, Diameter: 23.6 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 10, Height: 4, Diameter: 31 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 10, Height: 5, Diameter: 32 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 10, Height: 6, Diameter: 33 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 10, Height: 7, Diameter: 34 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 10, Height: 8, Diameter: 35 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 10, Height: 10, Diameter: 37 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 10, Height: 11, Diameter: 38 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 10, Height: 12, Diameter: 39 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 19.7 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 26.8 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 36 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 38 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 42 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 44 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 47.9 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 47.9 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 47.9 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 22 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 30 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 39.7 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 41.3 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 43 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 44.7 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 46.3 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 49.7 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 51.3 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 51.5 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 22.3 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 30.1 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 40.3 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 42.7 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 45 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 47.3 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 49.7 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 53.7 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 53.7 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 53.7 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 23.5 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 30.8 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 40 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 42 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 46 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 48 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 51.8 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 51.8 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 51.8 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 24 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 31 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 40.3 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 42.7 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 45 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 47.3 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 49.7 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 52.7 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 52.7 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 52.7 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 24.4 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 31.2 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 40 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 42 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 46 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 48 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 51.3 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 51.3 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 51.3 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 25.9 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 32.9 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 40 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 41.3 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 42.7 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 45.3 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 46.7 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 49.3 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 50.7 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 51.2 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 15, Height: 1, Diameter: 21 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 15, Height: 2, Diameter: 27.5 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 15, Height: 4, Diameter: 35.3 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 15, Height: 5, Diameter: 36.7 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 15, Height: 7, Diameter: 39.3 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 15, Height: 8, Diameter: 40.7 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 15, Height: 9, Diameter: 41.7 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 15, Height: 10, Diameter: 43.3 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 15, Height: 11, Diameter: 43.6 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 15, Height: 12, Diameter: 43.6 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 26 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 34 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 43.3 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 44.7 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 47.3 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 48.7 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 51.3 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 52.7 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 52.8 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 27.2 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 35.6 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 45.3 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 46.7 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 49.3 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 50.7 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 53.3 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 54.7 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 55.1 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 27.5 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 35.8 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 46 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 48 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 52 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 53.7 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 54.9 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 57.3 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 57.3 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 57.3 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 27.6 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 34.8 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 44.3 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 46.7 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 49 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 50.9 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 52.6 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 55.8 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 55.8 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 55.8 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 55.8 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 28.4 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 35.2 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 44.7 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 47.3 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 52 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 53.6 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 56.7 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 56.7 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 56.7 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 56.7 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 29.9 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 37 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 46 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 48 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 52 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 53.6 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 56.7 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 56.7 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 56.7 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 30.1 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 37.1 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 46 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 48 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 52 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 53.5 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 56.6 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 56.6 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 56.6 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 20, Height: 1, Diameter: 22.7 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 20, Height: 2, Diameter: 29.4 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 20, Height: 3, Diameter: 36 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 20, Height: 4, Diameter: 37.7 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 20, Height: 5, Diameter: 39.3 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 20, Height: 6, Diameter: 41 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 20, Height: 7, Diameter: 42.2 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 20, Height: 8, Diameter: 43.4 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 20, Height: 9, Diameter: 44.3 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 20, Height: 10, Diameter: 46.4 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 20, Height: 11, Diameter: 46.4 },
    { DevCode: 12, Nozzle1: 0.11, Pressure: 20, Height: 12, Diameter: 46.4 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 27.8 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 35.9 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 45.7 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 47.3 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 49 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 50.5 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 51.9 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 52.9 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 55.5 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 55.5 },
    { DevCode: 12, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 55.5 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 29.5 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 37.8 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 47.7 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 51 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 52.5 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 54 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 55.1 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 57.7 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 57.7 },
    { DevCode: 12, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 57.7 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 30.5 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 38.2 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 47.7 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 51 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 52.6 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 54 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 55.1 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 57.5 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 57.5 },
    { DevCode: 12, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 57.5 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 32.2 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 39.1 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 48 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 50 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 53.6 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 55 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 58.3 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 58.3 },
    { DevCode: 12, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 58.3 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 34.1 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 41.1 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 49.3 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 50.7 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 53.3 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 54.7 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 57.3 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 58.2 },
    { DevCode: 12, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 58.2 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 32.6 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 40.3 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 49.3 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 50.7 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 53.3 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 54.7 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 57.3 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 58.5 },
    { DevCode: 12, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 58.5 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 34.6 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 41.3 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 49.3 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 50.7 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 53.3 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 54.7 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 57.3 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 58.1 },
    { DevCode: 12, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 58.1 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 10, Height: 1, Diameter: 19.4 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 10, Height: 2, Diameter: 25.7 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 10, Height: 4, Diameter: 32.7 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 10, Height: 5, Diameter: 33.3 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 10, Height: 6, Diameter: 34 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 10, Height: 7, Diameter: 34.7 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 10, Height: 8, Diameter: 35.3 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 10, Height: 10, Diameter: 36.7 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 10, Height: 11, Diameter: 37.3 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 10, Height: 12, Diameter: 38 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 22.9 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 29.5 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 37.3 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 38.7 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 41.3 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 42.7 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 45.3 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 46.7 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 46.7 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 24.1 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 30.6 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 37 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 38.3 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 39.7 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 41 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 42.3 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 43.7 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 45 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 46.3 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 47.6 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 47.6 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 24.6 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 31.3 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 38.7 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 39.3 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 40.7 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 41.3 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 42.7 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 43.3 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 24.8 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 30.25 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 39 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 40 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 41 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 43 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 45 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 46 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 47 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 25.4 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 32.2 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 39 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 40.3 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 41.7 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 43 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 44.3 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 45.7 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 47 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 48.3 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 49.7 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 49.9 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 26.1 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 33 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 40 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 41.7 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 43.3 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 45 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 46.7 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 48.3 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 51.7 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 52.3 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 52.3 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 26.3 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 33.1 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 40 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 41.3 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 42.7 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 45.3 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 46.7 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 49.3 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 50.7 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 51 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 15, Height: 1, Diameter: 23.7 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 15, Height: 2, Diameter: 29.8 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 15, Height: 4, Diameter: 37 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 15, Height: 5, Diameter: 38 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 15, Height: 7, Diameter: 40 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 15, Height: 8, Diameter: 41 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 15, Height: 9, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 15, Height: 10, Diameter: 43 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 15, Height: 11, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 15, Height: 12, Diameter: 44.4 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 27.8 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 33.9 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 40.7 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 41.3 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 42.7 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 43.3 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 44.7 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 45.3 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 46 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 28.7 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 35.4 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 28.1 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 34.5 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 41 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 41.7 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 42.3 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 43 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 43.7 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 44.3 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 45 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 45.7 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 46.3 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 47 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 29.7 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 36.9 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 45 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 46 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 47 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 48 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 49 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 51 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 52 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 53 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 31 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 37.5 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 45 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 46 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 47 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 48 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 49 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 51 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 52 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 53 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 29.7 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 36.9 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 45.3 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 46.7 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 49.3 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 50.7 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 53.3 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 54.4 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 54.4 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 30.6 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 37.3 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 45.7 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 47.3 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 49 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 50.7 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 52.3 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 55.3 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 55.3 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 55.3 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 20, Height: 1, Diameter: 26 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 20, Height: 2, Diameter: 32 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 20, Height: 4, Diameter: 38.7 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 20, Height: 5, Diameter: 39.3 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 20, Height: 6, Diameter: 40 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 20, Height: 7, Diameter: 40.7 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 20, Height: 8, Diameter: 41.3 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 20, Height: 10, Diameter: 42.7 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 20, Height: 11, Diameter: 43.3 },
    { DevCode: 14, Nozzle1: 0.11, Pressure: 20, Height: 12, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 29.6 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 35.8 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 43 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 46 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 47 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 48 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 49 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 50 },
    { DevCode: 14, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 50.4 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 29.7 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 35.9 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 43.3 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 44.7 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 46 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 47.3 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 48.6 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 50 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 51.3 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 51.5 },
    { DevCode: 14, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 51.5 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 30.8 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 37.4 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 44.3 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 44.7 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 45.3 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 45.7 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 46 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 46.3 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 46.7 },
    { DevCode: 14, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 47 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 32.5 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 39.3 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 46.3 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 46.7 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 47 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 47.3 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 47.7 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 48 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 48.3 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 48.7 },
    { DevCode: 14, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 49 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 32.4 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 39.2 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 47 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 48 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 49 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 50 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 51 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 53 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 54 },
    { DevCode: 14, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 54.9 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 32.4 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 39.2 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 47.3 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 48.7 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 51.3 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 52.7 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 55.3 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 56 },
    { DevCode: 14, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 56 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 32.2 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 39.1 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 48.3 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 50.7 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 53 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 54.6 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 56.1 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 59.4 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 59.4 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 59.4 },
    { DevCode: 14, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 59.4 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 3.2 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 6.1 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 9 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 9.8 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 10.7 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 11.5 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 12.3 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 13.2 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 14 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 14 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 14 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 14 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 5.2 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 9.6 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 14 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 15 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 16 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 17 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 18 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 19 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 20 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 20 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 20 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 20 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 6.6 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 12.3 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 19.5 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 21 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 22.5 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 24 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 25.5 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 27 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 27 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 27 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 8.1 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 14.5 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 21 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 23.2 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 25.3 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 27.5 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 29.7 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 31.8 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 8.5 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 15.2 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 24 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 26 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 30 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 32 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 8.8 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 15.9 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 23 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 25 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 27 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 29 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 31 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 33 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 35 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 35 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 35 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 35 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 9.2 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 16.6 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 26.3 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 28.7 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 31 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 33.3 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 35.7 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 9.6 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 17.3 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 25 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 27.3 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 29.7 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 34.3 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 36.7 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 39 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 39 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 39 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 39 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 10 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 17.5 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 25 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 26.7 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 28.3 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 31.7 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 33.3 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 35 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 35 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 35 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 35 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 4.4 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 8.2 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 12 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 12.8 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 13.7 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 14.5 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 15.3 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 16.2 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 17 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 17.8 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 18.7 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 19.5 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 7.3 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 13.2 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 19 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 19.8 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 20.7 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 21.5 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 22.3 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 23.2 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 24 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 24.8 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 25.7 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 26.5 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 9.6 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 16.8 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 25.5 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 27 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 28.5 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 30 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 31.5 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 33 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 34.5 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 37.5 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 11.6 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 19.8 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 29.8 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 31.7 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 33.5 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 35.3 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 37.2 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 39 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 40.8 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 42.7 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 44.4 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 12.4 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 21.2 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 32 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 34 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 40 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 44 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 46 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 47.8 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 13.3 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 22.6 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 34 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 36 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 40 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 42 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 46 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 48 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 14.6 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 24.3 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 36.2 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 38.3 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 40.5 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 42.7 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 44.8 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 47 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 49.2 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 51.3 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 53.2 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 14.1 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 23.6 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 33 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 35.5 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 40.5 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 43 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 45.5 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 50.5 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 53 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 54.4 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 14.5 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 22.7 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 31 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 33.5 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 36 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 38.5 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 41 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 43.5 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 48.5 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 51 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 51.6 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 6.2 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 11.1 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 16 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 16.7 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 17.3 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 18 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 18.7 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 19.3 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 20 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 20.7 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 21.3 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 22 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 10 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 17 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 24 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 24.8 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 25.7 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 26.5 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 27.3 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 28.2 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 29 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 29.8 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 30.7 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 31.5 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 12 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 20 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 29.7 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 31.3 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 33 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 34.7 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 36.3 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 39.7 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 41.2 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 41.9 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 15 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 24.5 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 36 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 40 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 42 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 44 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 46 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 48 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 50 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 50.7 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 15.9 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 25.5 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 35 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 37.3 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 39.7 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 42 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 44.3 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 46.7 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 49 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 51.3 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 53.7 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 54 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 16.4 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 26.2 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 38.7 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 41.3 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 46.7 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 49.3 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 54.5 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 57.3 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 57.3 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 17.7 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 27.9 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 40.8 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 43.7 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 46.5 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 49.3 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 52.2 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 55 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 57.7 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 60.5 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 60.5 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 17.7 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 27.9 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 41 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 44 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 47 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 50 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 53 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 58.8 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 61.6 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 61.6 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 18.2 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 28.1 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 40.7 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 43.3 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 48.7 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 51.3 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 56.7 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 59.3 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 59.4 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 7.2 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 12.6 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 18 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 18.8 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 19.7 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 20.5 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 21.3 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 22.2 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 23 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 23.6 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 24.3 },
    { DevCode: 213, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 25 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 12 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 20 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 28 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 28.8 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 29.7 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 30.5 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 31.3 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 32.2 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 33 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 33.8 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 34.7 },
    { DevCode: 213, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 35.5 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 14.5 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 23.3 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 33.8 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 35.7 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 37.5 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 39.3 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 41.2 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 43 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 44.4 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 45.6 },
    { DevCode: 213, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 47.1 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 17.7 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 27.9 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 40 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 42 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 44 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 46 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 48 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 50 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 51.7 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 53 },
    { DevCode: 213, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 54.7 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 19.6 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 30.3 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 41 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 43 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 45 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 47 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 49 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 51 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 53 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 54.9 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 56.2 },
    { DevCode: 213, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 58 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 20.1 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 31 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 44.5 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 47 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 49.5 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 52 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 54.5 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 57 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 59 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 60.5 },
    { DevCode: 213, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 62.3 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 21.5 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 32.8 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 46.7 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 54.7 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 57.3 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 62.2 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 63.7 },
    { DevCode: 213, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 65.6 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 21.5 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 32.8 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 46.7 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 54.7 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 57.3 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 62.2 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 63.7 },
    { DevCode: 213, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 65.6 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 22 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 33 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 47 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 50 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 53 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 56 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 59 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 64.3 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 65.8 },
    { DevCode: 213, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 67.7 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 4 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 8 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 12 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 13.5 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 15 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 16.5 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 18 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 19.5 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 21 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 21 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 21 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 21 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 5.6 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 10.8 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 16 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 18 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 20 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 22 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 24 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 26 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 6.7 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 12.8 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 21.3 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 23.7 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 26 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 28.3 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 30.7 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 33 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 33 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 33 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 33 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 7.4 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 13.7 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 22.5 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 25 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 27.5 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 30 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 32.5 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 35 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 35 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 35 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 35 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 8.5 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 15.2 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 24.3 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 26.7 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 29 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 31.3 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 33.7 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 8.8 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 15.4 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 24.3 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 26.7 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 29 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 31.3 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 33.7 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 9.1 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 15.6 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 24.5 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 27 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 29.5 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 32 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 34.5 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 37 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 37 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 37 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 37 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 9.9 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 16.4 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 23 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 25.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 28 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 30.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 33 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 35.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 38 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 38 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 38 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 38 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 10.3 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 17.1 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 26.7 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 29.3 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 34.7 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 37.3 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 40 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 40 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 40 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 40 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 4.9 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 9.5 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 14 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 15.7 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 17.3 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 19 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 20.7 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 22.3 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 24 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 25.7 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 27.3 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 28 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 7 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 13.5 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 20 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 21.8 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 23.7 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 25.5 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 27.3 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 29.2 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 31 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 32.8 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 34.7 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 36.2 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 8.5 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 15.7 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 23 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 25.2 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 27.3 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 29.5 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 31.7 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 33.8 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 38.2 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 40.3 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 41.7 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 9.6 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 16.8 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 26.3 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 28.7 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 31 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 33.3 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 35.7 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 38 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 40.3 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 42.7 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 43.4 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 10.8 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 18.4 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 28.2 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 30.3 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 32.5 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 34.7 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 36.8 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 39 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 41.2 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 43.3 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 44.4 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 11.6 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 19.3 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 27 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 29 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 31 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 33 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 35 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 37 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 39 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 41 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 43 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 44.2 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 12 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 20 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 30.2 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 32.3 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 34.5 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 36.7 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 38.8 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 41 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 43.2 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 45.3 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 46.4 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 12.8 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 20.9 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 29 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 31.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 34 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 36.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 39 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 41.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 46.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 49 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 49.7 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 13.3 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 21.6 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 32.7 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 35.3 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 40.7 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 43.3 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 48.7 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 51.3 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 51.9 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 6 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 11.5 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 17 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 18.7 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 20.3 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 22 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 23.7 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 25.3 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 27 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 28 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 29.3 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 30 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 8.1 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 15.5 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 23 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 24.8 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 26.7 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 28.5 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 30.3 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 32.2 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 34 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 35.2 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 36.9 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 37.7 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 8.8 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 16.4 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 24 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 26.3 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 28.7 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 31 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 33.3 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 35.7 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 39.5 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 41.2 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 42.1 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 10.8 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 18.4 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 26 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 28.5 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 31 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 33.5 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 38.5 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 41 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 42.8 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 44.5 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 45.3 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 11.6 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 19.3 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 27 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 29.3 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 31.7 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 36.3 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 38.7 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 41 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 42.9 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 44.5 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 45.2 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 12 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 20 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 30.5 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 33 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 35.5 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 38 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 40.5 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 45 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 46.6 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 47.5 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 14.1 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 22.5 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 31 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 33.3 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 35.7 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 40.3 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 42.7 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 45 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 47.2 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 49.6 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 49.6 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 14.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 23.3 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 34.8 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 37.7 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 40.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 43.3 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 46.2 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 49 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 50.25 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 54 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 54 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 14.5 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 23.3 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 35.2 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 38.3 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 41.5 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 44.7 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 47.8 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 51 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 53.5 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 56.2 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 56.2 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 6.7 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 12.8 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 19 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 20.7 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 22.3 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 24 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 25.7 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 27.3 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 29 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 29.6 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 30.6 },
    { DevCode: 216, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 31.5 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 9.2 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 17.1 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 25 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 26.8 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 28.7 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 30.5 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 32.3 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 34.2 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 36.9 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 38 },
    { DevCode: 216, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 39.1 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 10 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 18 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 26 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 27.8 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 29.7 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 31.5 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 33.3 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 35.2 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 37 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 37.9 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 39.1 },
    { DevCode: 216, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 40.2 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 11.6 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 19.8 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 28 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 30.5 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 33 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 35.5 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 38 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 40.5 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 43 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 44.2 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 45.5 },
    { DevCode: 216, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 47.1 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 12.8 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 20.9 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 29 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 31.5 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 34 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 36.5 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 39 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 41.5 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 44 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 45.4 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 46.6 },
    { DevCode: 216, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 48.2 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 13.7 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 22.3 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 31 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 33.5 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 38.5 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 41 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 43.5 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 46 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 47.4 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 48.7 },
    { DevCode: 216, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 50.4 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 15 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 24 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 33 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 35.7 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 38.3 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 41 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 43.7 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 46.3 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 50.6 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 51.9 },
    { DevCode: 216, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 53.6 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 15.9 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 25.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 35 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 37.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 40 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 42.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 45 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 47.5 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 50 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 51.6 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 53 },
    { DevCode: 216, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 54.7 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 16.8 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 26.4 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 36 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 39 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 42 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 48 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 51 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 55.8 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 57.2 },
    { DevCode: 216, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 59.1 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 3.7 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 6.8 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 10 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 10.3 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 10.7 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 11 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 11.3 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 11.7 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 12 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 12 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 12 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 12 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 5.6 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 9.8 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 14 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 15 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 16 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 17 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 18 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 19 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 20 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 20 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 20 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 20 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 7.2 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 12.6 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 19 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 20 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 21 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 22 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 23 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 24 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 24 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 24 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 24 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 9.4 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 15.7 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 23 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 24 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 25 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 26 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 27 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 10.3 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 17.1 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 25.3 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 26.7 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 29.3 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 30.7 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 32 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 32 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 32 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 10.3 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 17.1 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 25.7 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 27.3 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 29 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 30.7 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 32.3 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 10.3 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 17.1 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 25.7 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 27.3 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 29 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 30.7 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 32.3 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 11.5 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 18.7 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 27.7 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 29.3 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 31 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 32.7 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 34.3 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 11.5 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 18.7 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 27.7 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 29.3 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 31 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 32.7 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 34.3 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 5.2 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 9.6 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 14 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 14.3 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 14.7 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 15 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 15.3 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 15.7 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 16 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 16.3 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 16.7 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 17 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 9.1 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 15.6 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 22 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 23 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 24 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 25 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 26 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 27 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 28 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 29 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 30 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 31 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 10.8 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 18.4 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 27.7 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 29.3 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 31 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 32.7 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 34.3 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 37.7 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 39.3 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 41 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 13.3 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 21.6 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 31.7 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 33.3 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 35 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 36.7 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 38.3 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 41.7 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 43.3 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 45 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 14.5 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 23.3 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 33.7 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 35.3 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 37 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 38.7 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 40.3 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 43.7 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 45.3 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 47 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 15.5 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 24.7 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 35.7 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 37.3 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 40.7 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 42.3 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 45.7 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 47.3 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 49 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 16.4 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 26.2 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 37.7 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 39.3 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 41 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 42.7 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 44.3 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 47.7 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 49.3 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 51 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 16.4 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 26.2 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 38 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 40 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 42 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 44 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 46 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 50 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 52 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 17.7 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 27.9 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 40 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 42 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 46 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 48 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 52 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 54 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 56 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 6.9 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 12.5 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 18 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 18.7 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 19.3 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 20 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 20.7 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 21.3 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 22 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 22.7 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 23.3 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 24 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 12 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 20 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 29 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 30 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 31 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 32 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 33 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 34 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 35 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 37 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 14.6 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 24.3 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 35.3 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 36.7 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 39.3 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 40.7 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 42 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 43.3 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 44.7 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 46 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 18.7 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 29.3 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 41.3 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 42.7 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 45.3 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 46.7 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 49.3 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 50.7 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 52 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 19.6 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 30.8 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 43.7 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 45.3 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 47 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 48.7 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 50.3 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 53.7 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 55.3 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 57 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 21 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 32.5 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 45.7 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 47.3 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 49 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 50.7 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 52.3 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 55.7 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 57.3 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 59 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 22.5 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 34.3 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 46 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 47.7 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 49.3 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 51 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 52.7 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 54.3 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 57.7 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 59.3 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 61 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 22.5 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 34.3 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 46 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 48.3 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 50.7 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 53 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 55.3 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 57.7 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 62.3 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 64.7 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 65.9 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 24 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 50 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 52 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 54 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 56 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 58 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 62 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 64 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 65.9 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 8.8 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 15.4 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 22 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 22.7 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 23.3 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 24 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 24.7 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 25.3 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 26 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 26.7 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 27.3 },
    { DevCode: 224, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 28 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 14.1 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 23.1 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 33.3 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 34.7 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 36 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 37.3 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 38.7 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 40 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 41.3 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 42.3 },
    { DevCode: 224, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 43.8 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 17.7 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 27.9 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 39.7 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 41.3 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 44.7 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 46.3 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 48 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 49.6 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 50.9 },
    { DevCode: 224, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 52.5 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 22 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 34 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 47.7 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 51 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 52.7 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 54.3 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 57.7 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 59.3 },
    { DevCode: 224, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 61 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 23 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 35.5 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 50 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 52 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 54 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 56 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 58 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 62 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 63.6 },
    { DevCode: 224, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 65.6 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 24.5 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 37.2 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 52 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 54 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 58 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 60 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 64 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 65.8 },
    { DevCode: 224, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 67.8 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 25 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 37.5 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 52 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 54 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 58 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 60 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 64 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 65.8 },
    { DevCode: 224, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 67.7 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 26.5 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 39.3 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 52 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 54 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 56 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 58 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 60 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 62 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 64 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 66 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 68 },
    { DevCode: 224, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 69.9 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 28.1 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 41 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 54 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 55.7 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 57.3 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 59 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 60.7 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 62.3 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 64 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 65.7 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 67.3 },
    { DevCode: 224, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 69 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 2.7 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 5.3 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 8 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 9.3 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 10.7 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 12 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 13.3 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 14.7 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 16 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 16 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 16 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 16 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 4 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 8 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 12 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 13.7 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 15.3 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 17 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 18.7 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 20.3 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 22 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 22 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 22 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 22 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 4.7 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 9.3 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 14 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 16 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 18 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 20 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 22 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 24 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 26 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 26 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 26 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 5.3 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 10.7 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 16 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 18.3 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 20.7 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 25.3 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 27.7 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 6 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 12 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 20 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 22 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 26 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 28 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 6.3 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 12.2 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 20.3 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 22.7 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 25 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 27.3 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 29.7 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 6.3 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 12.2 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 20.3 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 22.7 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 25 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 27.3 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 29.7 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 13.5 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 22 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 24 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 26 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 28 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 13.5 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 22.3 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 24.7 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 27 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 29.3 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 31.7 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 4 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 8 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 12 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 13 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 14 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 15 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 16 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 17 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 18 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 19 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 20 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 21 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 6 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 12 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 18 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 19.7 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 21.3 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 23 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 24.7 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 26.3 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 28 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 29.7 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 31.3 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 33 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 7.3 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 14.7 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 22 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 24 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 26 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 34 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 36 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 38 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 40 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 8 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 16 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 26.3 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 28.7 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 31 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 33.3 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 35.7 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 38 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 40.3 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 42.7 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 44.9 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 9.1 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 17.6 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 28.3 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 30.7 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 33 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 35.3 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 37.7 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 42.3 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 44.7 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 46.7 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 9.8 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 18.9 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 30.3 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 32.7 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 35 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 37.3 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 39.7 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 44.3 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 46.7 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 49 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 9.8 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 18.9 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 30.7 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 33.3 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 38.7 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 41.3 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 46.7 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 49.3 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 50.25 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 10.5 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 20.3 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 32.3 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 34.7 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 37 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 39.3 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 41.7 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 46.3 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 48.7 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 51 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 10.5 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 20.3 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 32.7 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 35.3 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 40.7 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 43.3 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 48.7 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 51.3 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 53.7 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 4.7 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 9.3 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 14 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 15.3 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 16.7 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 18 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 19.3 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 20.7 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 22 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 22.8 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 23.9 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 24.5 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 8 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 16 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 24 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 26 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 28 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 30 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 34 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 36 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 37.2 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 39 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 40 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 9.3 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 18.7 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 30.3 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 32.7 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 35 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 37.3 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 39.7 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 42 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 43.4 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 45.5 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 46.7 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 10.7 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 21.3 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 34.7 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 37.3 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 40 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 42.7 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 45.3 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 49.6 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 52.1 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 53.4 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 11.9 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 23 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 37 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 40 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 43 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 46 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 49 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 53.9 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 56.4 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 57.7 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 11.9 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 23 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 37.3 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 40.7 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 47.3 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 50.7 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 56 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 58.6 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 60 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 12.6 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 24.3 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 39 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 42 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 45 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 48 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 51 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 56 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 58.6 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 60 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 12.6 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 24.3 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 39.3 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 42.7 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 49.3 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 52.7 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 58.1 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 60.7 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 62.2 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 13.4 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 25.7 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 41 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 44 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 47 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 50 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 53 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 58.1 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 60.7 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 62.2 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 6 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 12 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 18 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 19.3 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 20.7 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 22 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 23.3 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 24.7 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 26 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 26.5 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 27.4 },
    { DevCode: 223, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 28.2 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 8.7 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 17.3 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 26 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 28.3 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 30.7 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 33 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 35.3 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 37.7 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 40 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 40.8 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 42.1 },
    { DevCode: 223, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 43.4 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 10.7 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 21.3 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 34.7 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 37.3 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 40 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 42.7 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 45.3 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 48 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 49 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 50.5 },
    { DevCode: 223, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 52.1 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 12.6 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 24.3 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 36 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 39 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 42 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 48 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 51 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 55.2 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 56.9 },
    { DevCode: 223, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 58.6 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 12.6 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 24.3 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 36 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 39.3 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 42.7 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 46 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 49.3 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 52.7 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 57.2 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 59 },
    { DevCode: 223, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 60.8 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 13.4 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 25.7 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 41.3 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 44.7 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 51.3 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 54.7 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 59.3 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 61.1 },
    { DevCode: 223, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 63 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 14.1 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 27 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 40 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 43 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 46 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 49 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 52 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 55 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 59.3 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 61.1 },
    { DevCode: 223, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 63 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 14.1 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 27 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 40 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 43 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 46 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 49 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 52 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 55 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 59.3 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 61.1 },
    { DevCode: 223, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 63 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 14.8 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 28.4 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 45 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 48 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 51 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 54 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 57 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 61.3 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 63.2 },
    { DevCode: 223, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 65.2 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 3.3 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 6.7 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 10 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 11.5 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 13 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 14.5 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 16 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 17.5 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 19 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 19 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 19 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 19 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 5 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 10 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 15 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 17.2 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 19.3 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 21.5 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 23.7 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 25.8 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 5.7 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 11.3 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 17 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 19.5 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 22 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 24.5 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 27 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 29.5 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 32 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 32 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 32 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 6.3 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 12.7 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 22 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 25 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 31 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 34 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 37 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 37 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 37 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 37 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 6.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 13.3 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 23.3 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 26.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 33.3 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 36.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 40 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 40 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 40 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 40 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 14 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 21 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 24.5 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 28 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 31.5 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 35 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 38.5 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 42 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 42 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 42 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 42 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 14 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 21 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 24.7 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 28.3 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 35.7 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 39.3 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 43 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 43 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 43 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 43 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 7.3 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 14.7 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 26 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 30 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 34 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 38 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 42 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 46 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 46 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 46 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 46 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 7.3 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 14.7 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 26.2 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 30.3 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 34.5 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 38.7 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 42.8 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 47 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 47 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 47 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 47 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 4.3 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 8.7 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 13 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 14.3 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 15.7 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 17 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 18.3 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 19.7 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 21 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 22.3 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 23.7 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 24.8 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 6 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 12 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 18 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 20.3 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 22.7 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 25 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 27.3 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 29.7 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 34.3 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 36.7 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 37.8 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 7.3 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 14.7 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 22 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 24.5 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 27 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 29.5 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 32 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 34.5 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 37 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 39.5 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 42 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 43.7 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 8.7 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 17.3 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 28.7 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 31.3 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 34 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 36.7 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 39.3 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 44.7 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 47.3 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 49.6 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 8.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 17.3 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 29.2 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 32.3 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 35.5 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 38.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 41.8 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 45 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 48.2 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 51.3 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 53.2 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 8.7 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 17.3 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 29.5 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 33 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 36.5 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 40 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 43.5 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 47 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 50.5 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 54 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 55.5 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 9.3 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 18.7 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 31.5 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 35 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 38.5 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 42 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 45.5 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 49 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 52.5 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 56 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 57.9 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 9.7 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 19.3 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 29 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 32.7 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 36.3 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 43.7 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 47.3 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 51 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 54.7 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 58.3 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 60.2 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 10 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 20 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 33.7 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 37.3 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 41 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 44.7 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 48.3 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 55.7 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 59.3 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 60.25 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 4.7 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 9.3 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 14 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 16 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 18 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 20 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 22 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 24 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 26 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 26.9 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 28.2 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 28.9 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 6.3 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 12.7 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 19 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 22 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 25 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 28 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 31 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 34 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 37 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 38.3 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 40.1 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 41.1 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 7.7 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 15.3 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 23 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 26.2 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 29.3 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 32.5 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 35.7 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 38.8 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 42 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 43.4 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 45.5 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 46.7 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 9 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 18 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 27 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 30.2 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 33.3 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 36.5 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 39.7 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 42.8 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 46 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 47.6 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 49.9 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 51.2 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 9.3 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 18.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 31.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 35.3 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 42.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 46.3 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 51.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 54.2 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 55.6 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 10 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 20 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 30 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 33.7 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 37.3 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 41 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 44.7 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 48.3 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 53.8 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 56.4 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 57.8 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 10.3 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 20.7 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 31 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 34.8 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 38.7 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 42.5 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 46.3 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 50.2 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 55.9 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 58.6 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 60 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 10.7 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 21.3 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 35.8 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 39.7 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 43.5 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 47.3 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 51.2 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 55 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 56.9 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 59.6 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 61.2 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 11.3 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 22.7 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 37.7 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 41.3 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 45 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 48.7 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 52.3 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 57.9 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 60.7 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 62.3 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 5.7 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 11.3 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 17 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 19 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 21 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 23 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 25 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 27 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 29 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 29.6 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 30.5 },
    { DevCode: 211, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 31.5 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 7.3 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 14.7 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 22 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 25 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 28 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 31 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 34 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 37 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 40 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 40.8 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 42.1 },
    { DevCode: 211, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 43.4 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 8.7 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 17.3 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 26 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 29 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 32 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 35 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 38 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 41 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 44 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 44.9 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 46.3 },
    { DevCode: 211, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 47.8 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 10 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 20 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 30 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 33.2 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 36.3 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 39.5 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 42.7 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 45.8 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 50 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 51.6 },
    { DevCode: 211, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 53.2 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 10.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 21.3 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 35.3 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 38.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 42 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 45.3 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 48.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 53 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 54.7 },
    { DevCode: 211, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 56.5 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 11.3 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 22.7 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 34 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 37.7 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 41.3 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 48.7 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 52.3 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 57.1 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 58.9 },
    { DevCode: 211, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 60.8 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 11.7 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 23.3 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 35 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 38.7 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 42.3 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 46 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 49.7 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 53.3 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 57 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 58.1 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 60 },
    { DevCode: 211, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 61.9 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 12 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 24 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 36 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 39.7 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 43.3 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 47 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 50.7 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 54.3 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 59.2 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 61 },
    { DevCode: 211, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 63 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 12.7 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 25.3 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 41.7 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 45.3 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 49 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 52.7 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 56.3 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 61.2 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 63.1 },
    { DevCode: 211, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 65.2 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 2.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 5.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 8 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 8.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 8.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 9 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 9.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 9.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 10 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 10 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 10 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 10 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 4.3 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 8.7 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 13 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 14 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 15 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 16 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 17 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 18 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 19 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 19 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 19 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 19 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 5.3 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 10.7 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 16 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 17.3 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 18.7 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 20 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 21.3 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 22.7 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 6.7 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 13.3 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 21.3 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 22.7 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 25.3 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 26.7 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 6.7 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 13.3 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 21.8 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 23.7 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 25.5 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 27.3 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 29.2 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 31 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 31 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 31 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 31 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 7.3 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 14.7 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 26 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 30 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 32 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 7.3 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 14.7 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 26 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 30 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 32 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 8 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 16 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 26.2 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 28.3 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 30.5 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 32.7 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 34.8 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 37 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 37 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 37 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 37 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 8 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 16 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 25.7 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 27.3 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 29 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 30.7 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 32.3 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 3.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 7.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 11 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 11.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 12.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 13 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 13.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 14.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 15 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 15.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 16.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 17 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 5 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 10 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 15 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 16.5 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 18 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 19.5 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 21 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 22.5 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 25.5 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 27 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 28.3 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 7.3 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 14.7 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 22 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 23.3 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 24.7 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 26 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 27.3 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 28.7 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 30 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 31.3 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 32.7 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 8 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 16 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 26 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 28 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 30 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 32 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 38 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 40 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 42 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 8.7 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 17.3 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 28.2 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 30.3 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 32.5 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 34.7 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 36.8 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 39 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 41.2 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 43.3 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 45.5 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 9.3 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 18.7 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 30 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 32 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 36 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 38 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 42 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 44 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 46 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 10 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 20 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 32 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 38 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 40 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 44 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 46 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 48 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 10.7 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 21.3 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 34.2 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 36.3 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 38.5 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 40.7 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 42.8 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 45 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 47.2 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 49.3 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 51.5 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 10 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 20 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 32 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 38 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 40 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 44 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 46 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 48 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 4.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 8.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 13 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 13.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 13.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 14 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 14.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 14.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 15 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 15.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 15.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 16 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 6.3 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 12.7 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 19 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 20 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 21 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 22 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 23 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 25 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 25.9 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 27 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 27.8 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 8.7 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 17.3 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 26 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 27 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 28 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 29 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 30 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 31 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 32 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 33 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 35 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 10 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 20 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 30 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 32 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 36 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 38 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 40 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 42 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 43.4 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 45.5 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 46.7 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 10 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 20 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 30 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 32.7 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 35.3 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 40.7 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 43.3 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 46 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 47.6 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 49.9 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 51.2 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 11.3 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 22.7 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 36.5 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 39 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 41.5 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 44 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 46.5 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 49 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 50.7 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 53.1 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 54.5 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 11.3 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 22.7 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 36.8 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 39.7 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 42.5 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 45.3 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 48.2 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 51 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 52.8 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 55.3 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 56.7 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 11.3 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 22.7 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 36.5 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 39 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 41.5 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 44 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 46.5 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 49 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 50.7 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 53.1 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 54.5 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 12 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 24 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 39 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 42 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 45 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 48 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 51 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 55.9 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 58.6 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 60 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 5 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 10 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 15 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 15.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 15.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 16 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 16.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 16.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 17 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 17.3 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 17.7 },
    { DevCode: 212, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 18 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 7.7 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 15.3 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 23 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 24.2 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 25.3 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 26.5 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 27.7 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 28.8 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 30 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 30.6 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 31.6 },
    { DevCode: 212, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 32.6 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 10 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 20 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 30 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 31.3 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 32.7 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 35.3 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 36.7 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 38 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 38.8 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 40 },
    { DevCode: 212, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 41.3 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 11.3 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 22.7 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 34 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 36.3 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 38.7 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 41 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 43.3 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 45.7 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 48 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 49 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 50.5 },
    { DevCode: 212, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 52.1 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 12.7 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 25.3 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 40 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 42 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 44 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 46 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 48 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 50 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 51 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 52.6 },
    { DevCode: 212, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 54.3 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 13.3 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 26.7 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 40 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 42.3 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 44.7 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 47 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 49.3 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 51.7 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 55.1 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 56.8 },
    { DevCode: 212, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 58.6 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 13.3 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 26.7 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 40 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 43 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 46 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 49 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 52 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 55 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 59.2 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 61 },
    { DevCode: 212, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 63 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 13.3 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 26.7 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 40 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 42.7 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 45.3 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 50.7 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 53.3 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 57.1 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 58.9 },
    { DevCode: 212, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 60.8 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 14 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 28 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 45 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 48 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 51 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 54 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 57 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 61.2 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 63.1 },
    { DevCode: 212, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 65.2 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 4 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 8 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 5.3 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 10.7 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 16 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 16.7 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 17.3 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 18 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 18.7 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 19.3 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 5.7 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 11.3 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 17 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 17.8 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 18.7 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 19.5 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 20.3 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 21.2 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 6 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 141, Nozzle1: 0.125, Pressure: 30, Height: 12, Diameter: 80 },
    { DevCode: 141, Nozzle1: 0.1875, Pressure: 30, Height: 12, Diameter: 84 },
    { DevCode: 141, Nozzle1: 0.25, Pressure: 30, Height: 12, Diameter: 84 },
    { DevCode: 141, Nozzle1: 0.296875, Pressure: 30, Height: 12, Diameter: 82 },
    { DevCode: 141, Nozzle1: 0.125, Pressure: 40, Height: 12, Diameter: 82 },
    { DevCode: 141, Nozzle1: 0.1875, Pressure: 40, Height: 12, Diameter: 88 },
    { DevCode: 141, Nozzle1: 0.25, Pressure: 40, Height: 12, Diameter: 90 },
    { DevCode: 141, Nozzle1: 0.296875, Pressure: 40, Height: 12, Diameter: 88 },
    { DevCode: 141, Nozzle1: 0.125, Pressure: 50, Height: 12, Diameter: 86 },
    { DevCode: 141, Nozzle1: 0.1875, Pressure: 50, Height: 12, Diameter: 92 },
    { DevCode: 141, Nozzle1: 0.25, Pressure: 50, Height: 12, Diameter: 96 },
    { DevCode: 141, Nozzle1: 0.296875, Pressure: 50, Height: 12, Diameter: 94 },
    { DevCode: 141, Nozzle1: 0.125, Pressure: 80, Height: 12, Diameter: 88 },
    { DevCode: 141, Nozzle1: 0.1875, Pressure: 80, Height: 12, Diameter: 94 },
    { DevCode: 141, Nozzle1: 0.25, Pressure: 80, Height: 12, Diameter: 102 },
    { DevCode: 141, Nozzle1: 0.296875, Pressure: 80, Height: 12, Diameter: 100 },
    { DevCode: 142, Nozzle1: 0.1875, Pressure: 30, Height: 12, Diameter: 78 },
    { DevCode: 142, Nozzle1: 0.25, Pressure: 30, Height: 12, Diameter: 74 },
    { DevCode: 142, Nozzle1: 0.296875, Pressure: 30, Height: 12, Diameter: 72 },
    { DevCode: 142, Nozzle1: 0.1875, Pressure: 40, Height: 12, Diameter: 82 },
    { DevCode: 142, Nozzle1: 0.25, Pressure: 40, Height: 12, Diameter: 78 },
    { DevCode: 142, Nozzle1: 0.296875, Pressure: 40, Height: 12, Diameter: 76 },
    { DevCode: 142, Nozzle1: 0.1875, Pressure: 50, Height: 12, Diameter: 86 },
    { DevCode: 142, Nozzle1: 0.25, Pressure: 50, Height: 12, Diameter: 82 },
    { DevCode: 142, Nozzle1: 0.296875, Pressure: 50, Height: 12, Diameter: 80 },
    { DevCode: 142, Nozzle1: 0.1875, Pressure: 80, Height: 12, Diameter: 86 },
    { DevCode: 142, Nozzle1: 0.25, Pressure: 80, Height: 12, Diameter: 84 },
    { DevCode: 142, Nozzle1: 0.296875, Pressure: 80, Height: 12, Diameter: 82 },
    { DevCode: 151, Nozzle1: 0.03, Pressure: 30, Height: 12, Diameter: 80 },
    { DevCode: 151, Nozzle1: 0.05, Pressure: 30, Height: 12, Diameter: 82 },
    { DevCode: 151, Nozzle1: 0.08, Pressure: 30, Height: 12, Diameter: 82 },
    { DevCode: 151, Nozzle1: 0.1, Pressure: 30, Height: 12, Diameter: 82 },
    { DevCode: 151, Nozzle1: 0.03, Pressure: 40, Height: 12, Diameter: 82 },
    { DevCode: 151, Nozzle1: 0.05, Pressure: 40, Height: 12, Diameter: 84 },
    { DevCode: 151, Nozzle1: 0.08, Pressure: 40, Height: 12, Diameter: 86 },
    { DevCode: 151, Nozzle1: 0.1, Pressure: 40, Height: 12, Diameter: 86 },
    { DevCode: 151, Nozzle1: 0.03, Pressure: 50, Height: 12, Diameter: 86 },
    { DevCode: 151, Nozzle1: 0.05, Pressure: 50, Height: 12, Diameter: 88 },
    { DevCode: 151, Nozzle1: 0.08, Pressure: 50, Height: 12, Diameter: 90 },
    { DevCode: 151, Nozzle1: 0.1, Pressure: 50, Height: 12, Diameter: 90 },
    { DevCode: 151, Nozzle1: 0.03, Pressure: 80, Height: 12, Diameter: 88 },
    { DevCode: 151, Nozzle1: 0.05, Pressure: 80, Height: 12, Diameter: 90 },
    { DevCode: 151, Nozzle1: 0.08, Pressure: 80, Height: 12, Diameter: 92 },
    { DevCode: 151, Nozzle1: 0.1, Pressure: 80, Height: 12, Diameter: 94 },
    { DevCode: 152, Nozzle1: 0.05, Pressure: 30, Height: 12, Diameter: 80 },
    { DevCode: 152, Nozzle1: 0.08, Pressure: 30, Height: 12, Diameter: 76 },
    { DevCode: 152, Nozzle1: 0.1, Pressure: 30, Height: 12, Diameter: 76 },
    { DevCode: 152, Nozzle1: 0.05, Pressure: 40, Height: 12, Diameter: 82 },
    { DevCode: 152, Nozzle1: 0.08, Pressure: 40, Height: 12, Diameter: 78 },
    { DevCode: 152, Nozzle1: 0.1, Pressure: 40, Height: 12, Diameter: 80 },
    { DevCode: 152, Nozzle1: 0.05, Pressure: 50, Height: 12, Diameter: 84 },
    { DevCode: 152, Nozzle1: 0.08, Pressure: 50, Height: 12, Diameter: 82 },
    { DevCode: 152, Nozzle1: 0.1, Pressure: 50, Height: 12, Diameter: 82 },
    { DevCode: 152, Nozzle1: 0.05, Pressure: 80, Height: 12, Diameter: 86 },
    { DevCode: 152, Nozzle1: 0.08, Pressure: 80, Height: 12, Diameter: 84 },
    { DevCode: 152, Nozzle1: 0.1, Pressure: 80, Height: 12, Diameter: 86 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 13.7 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 20.25 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 29 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 29.3 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 29.7 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 30 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 30.3 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 30.7 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 31 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 31.3 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 31.7 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 32 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 10, Height: 1, Diameter: 14.3 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 10, Height: 2, Diameter: 22.2 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 10, Height: 4, Diameter: 30.7 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 10, Height: 5, Diameter: 31.3 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 10, Height: 7, Diameter: 32.7 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 10, Height: 8, Diameter: 33.3 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 34 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 10, Height: 10, Diameter: 34.7 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 10, Height: 11, Diameter: 35.3 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 10, Height: 12, Diameter: 36 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 10, Height: 1, Diameter: 15.7 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 10, Height: 2, Diameter: 23.8 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 10, Height: 4, Diameter: 33.3 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 10, Height: 5, Diameter: 34.7 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 10, Height: 7, Diameter: 37.3 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 10, Height: 8, Diameter: 38.7 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 10, Height: 10, Diameter: 41.3 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 10, Height: 11, Diameter: 42.7 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 10, Height: 12, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 17 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 25.5 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 36 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 38 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 48 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 50 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 50.2 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 18.4 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 27.2 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 38 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 40 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 50 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 52 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 52.4 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 19.8 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 28.9 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 40 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 48 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 52 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 54 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 54.5 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 20.1 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 29.1 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 40 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 48 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 52 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 54 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 54.2 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 18.7 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 27.4 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 38.7 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 41.3 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 46.7 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 49.3 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 51.7 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 54.5 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 54.5 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 54.5 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 18 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 26 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 36.7 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 39.3 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 44.7 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 47.3 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 49.2 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 51.7 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 51.7 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 51.7 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 13.4 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 20.7 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 30 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 32 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 35.3 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 36.4 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 37.6 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 38.7 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 40.4 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 40.4 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 15, Height: 1, Diameter: 16 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 15, Height: 2, Diameter: 24 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 15, Height: 4, Diameter: 34 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 15, Height: 5, Diameter: 36 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 15, Height: 7, Diameter: 39.6 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 15, Height: 8, Diameter: 40.8 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 15, Height: 10, Diameter: 43.2 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 15, Height: 11, Diameter: 44.9 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 15, Height: 12, Diameter: 44.9 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 15, Height: 1, Diameter: 18.7 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 15, Height: 2, Diameter: 27.4 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 15, Height: 4, Diameter: 38 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 15, Height: 5, Diameter: 40 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 15, Height: 6, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 15, Height: 7, Diameter: 43.7 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 15, Height: 8, Diameter: 45.1 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 15, Height: 9, Diameter: 46.4 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 15, Height: 10, Diameter: 47.6 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 15, Height: 11, Diameter: 49.4 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 15, Height: 12, Diameter: 49.4 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 21.2 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 30.6 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 47.9 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 49.4 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 50.7 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 52.1 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 53.9 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 53.9 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 21.5 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 30.8 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 47.9 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 49.4 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 50.7 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 52 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 53.8 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 53.8 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 23 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 32.5 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 43.3 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 44.7 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 47.3 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 48.7 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 51.3 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 52.7 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 53.7 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 23.3 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 32.7 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 49.9 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 51.6 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 52.9 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 54.2 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 55.9 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 55.9 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 22.2 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 31.1 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 47.9 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 49.4 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 50.7 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 51.9 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 53.6 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 53.6 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 20.25 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 29.7 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 40.7 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 43.3 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 47.9 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 49.5 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 50.7 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 53.5 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 53.5 },
    { DevCode: 191, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 53.5 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 15 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 22.5 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 30 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 32 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 34 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 36 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 37.1 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 37.9 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 38.8 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 39.6 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 40.5 },
    { DevCode: 191, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 41.6 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 20, Height: 1, Diameter: 17.7 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 20, Height: 2, Diameter: 25.8 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 34 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 20, Height: 4, Diameter: 36 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 20, Height: 5, Diameter: 38 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 40 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 20, Height: 7, Diameter: 41.2 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 20, Height: 8, Diameter: 42.2 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 43.1 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 20, Height: 10, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 20, Height: 11, Diameter: 44.9 },
    { DevCode: 191, Nozzle1: 0.16, Pressure: 20, Height: 12, Diameter: 46.1 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 20, Height: 1, Diameter: 20.5 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 20, Height: 2, Diameter: 29.2 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 20, Height: 4, Diameter: 40 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 20, Height: 5, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 20, Height: 6, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 20, Height: 7, Diameter: 45.3 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 20, Height: 8, Diameter: 46.4 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 20, Height: 9, Diameter: 47.4 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 20, Height: 10, Diameter: 48.4 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 20, Height: 11, Diameter: 49.3 },
    { DevCode: 191, Nozzle1: 0.2, Pressure: 20, Height: 12, Diameter: 50.5 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 23.3 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 32.7 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 49.4 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 50.7 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 51.7 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 52.8 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 55 },
    { DevCode: 191, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 55 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 24.4 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 34.2 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 45.3 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 46.7 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 49.3 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 50.7 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 51.7 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 52.8 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 54.7 },
    { DevCode: 191, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 55 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 25.9 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 36 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 47.3 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 48.7 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 51.3 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 52.7 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 53.9 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 54.9 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 56.7 },
    { DevCode: 191, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 57.2 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 26.3 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 36.1 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 47.3 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 48.7 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 51.3 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 52.7 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 53.9 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 54.9 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 56.7 },
    { DevCode: 191, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 57.2 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 24.8 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 34.4 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 46 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 48 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 51.5 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 52.9 },
    { DevCode: 191, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 53.9 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 19 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 21 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 23 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 6.3 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 12.7 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 20.2 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 21.3 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 22.5 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 23.7 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 24.8 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 6.7 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 13.3 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 21 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 25 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 7.3 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 14.7 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 22.7 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 23.3 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 24.7 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 25.3 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 6, Height: 1, Diameter: 7.3 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 6, Height: 2, Diameter: 14.7 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 6, Height: 4, Diameter: 22.7 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 6, Height: 5, Diameter: 23.3 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 6, Height: 7, Diameter: 24.7 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 6, Height: 8, Diameter: 25.3 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 6, Height: 10, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 6, Height: 11, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 6, Height: 12, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 4.7 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 9.3 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 14 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 14.7 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 15.3 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 16 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 16.7 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 17.3 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 18 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 18.7 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 19.3 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 5.7 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 11.3 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 17 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 17.8 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 18.7 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 19.5 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 20.3 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 21.2 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 22.8 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 23.7 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 24.5 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 6 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 18 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 19 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 21 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 23 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 25 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 27 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 6.3 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 12.7 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 19 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 20.5 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 23.5 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 25 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 26.5 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 28 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 29.5 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 31 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 32.5 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 7 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 14 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 21 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 22.5 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 25.5 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 27 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 28.5 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 30 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 31.5 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 33 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 34.5 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 7.7 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 15.3 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 23 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 24.2 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 25.3 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 26.5 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 27.7 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 28.8 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 30 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 31.2 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 32.3 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 33.5 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 8.3 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 16.7 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 25 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 26.2 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 27.3 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 28.5 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 29.7 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 30.8 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 33.2 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 34.3 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 35.5 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 10, Height: 1, Diameter: 8.3 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 10, Height: 2, Diameter: 16.7 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 10, Height: 3, Diameter: 25 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 10, Height: 4, Diameter: 26.2 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 10, Height: 5, Diameter: 27.3 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 10, Height: 6, Diameter: 28.5 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 10, Height: 7, Diameter: 29.7 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 10, Height: 8, Diameter: 30.8 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 10, Height: 10, Diameter: 33.2 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 10, Height: 11, Diameter: 34.3 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 10, Height: 12, Diameter: 35.5 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 5 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 10 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 15 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 15.8 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 16.7 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 17.5 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 18.3 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 19.2 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 20.7 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 21.7 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 22.2 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 5.7 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 11.3 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 17 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 18.2 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 19.3 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 20.5 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 21.7 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 22.8 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 24.8 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 26.7 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 6.7 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 13.3 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 21 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 23 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 25 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 26.9 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 28 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 28.9 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 7 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 14 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 21 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 22.5 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 25.5 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 27 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 28.5 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 30 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 31 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 32.5 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 33.4 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 7.7 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 15.3 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 23 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 24.5 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 27.5 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 29 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 30.5 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 32 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 33.1 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 34.7 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 35.6 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 8.3 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 16.7 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 25 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 26.2 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 27.3 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 28.5 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 29.7 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 30.8 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 32 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 33.1 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 34.3 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 35.5 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 8.7 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 17.3 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 27.3 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 28.7 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 30 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 31.3 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 32.7 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 34 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 35.2 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 36.7 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 37.8 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 15, Height: 1, Diameter: 8.7 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 15, Height: 2, Diameter: 17.3 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 15, Height: 3, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 15, Height: 4, Diameter: 27.7 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 15, Height: 5, Diameter: 29.3 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 15, Height: 6, Diameter: 31 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 15, Height: 7, Diameter: 32.7 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 15, Height: 8, Diameter: 34.3 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 15, Height: 9, Diameter: 36 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 15, Height: 10, Diameter: 37.2 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 15, Height: 11, Diameter: 39 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 15, Height: 12, Diameter: 40 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 5.3 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 10.7 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 16 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 16.7 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 17.3 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 18 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 18.7 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 19.3 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 20.4 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 21 },
    { DevCode: 217, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 21.7 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 6 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 12 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 18 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 19 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 21 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 23 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 24.5 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 25.3 },
    { DevCode: 217, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 26.1 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 6.7 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 13.3 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 20 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 21 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 23 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 24 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 25 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 26.5 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 27.4 },
    { DevCode: 217, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 28.2 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 7.3 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 14.7 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 22 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 23.3 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 24.7 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 27.3 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 28.7 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 30 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 30.6 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 31.6 },
    { DevCode: 217, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 32.6 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 8.3 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 16.7 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 25 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 26.2 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 27.3 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 28.5 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 29.7 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 30.8 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 32 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 32.6 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 33.7 },
    { DevCode: 217, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 34.7 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 8.7 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 17.3 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 26 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 27.3 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 28.7 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 30 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 31.3 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 32.7 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 34 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 34.7 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 35.8 },
    { DevCode: 217, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 36.9 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 9 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 18 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 27 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 28.5 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 30 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 31.5 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 33 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 34.5 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 36 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 36.7 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 37.9 },
    { DevCode: 217, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 39.1 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 20, Height: 1, Diameter: 9 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 20, Height: 2, Diameter: 18 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 20, Height: 3, Diameter: 27 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 20, Height: 4, Diameter: 28.8 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 20, Height: 5, Diameter: 30.7 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 20, Height: 6, Diameter: 32.5 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 20, Height: 7, Diameter: 34.3 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 20, Height: 8, Diameter: 36.2 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 20, Height: 9, Diameter: 38 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 20, Height: 10, Diameter: 38.8 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 20, Height: 11, Diameter: 40 },
    { DevCode: 217, Nozzle1: 0.4, Pressure: 20, Height: 12, Diameter: 41.3 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 2.7 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 5.3 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 8 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 9.5 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 11 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 12.5 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 14 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 15.5 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 17 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 17 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 17 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 17 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 3.3 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 6.7 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 10 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 12.3 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 14.7 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 17 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 19.3 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 21.7 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 24 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 24 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 24 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 24 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 4 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 8 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 14.5 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 17 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 19.5 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 22 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 24.5 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 27 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 27 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 27 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 4 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 8 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 14.7 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 17.3 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 20 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 22.7 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 25.3 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 4 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 8 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 14.7 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 17.3 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 20 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 22.7 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 25.3 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 4.3 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 8.7 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 13 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 15.7 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 18.3 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 21 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 23.7 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 26.3 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 29 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 29 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 29 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 29 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 4.7 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 9.3 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 14 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 16.5 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 19 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 21.5 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 24 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 26.5 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 29 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 29 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 29 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 29 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 4.7 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 9.3 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 14 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 16.7 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 19.3 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 22 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 24.7 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 27.3 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 5 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 10 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 15 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 17.5 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 20 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 22.5 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 25 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 27.5 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 3 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 6 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 9 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 10.5 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 13.5 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 15 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 16.5 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 18 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 19.5 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 21 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 21.3 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 4 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 8 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 14.3 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 16.7 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 19 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 21.3 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 23.7 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 26 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 28.3 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 30.7 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 30.7 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 4.7 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 9.3 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 14 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 16.7 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 19.3 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 22 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 24.7 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 27.3 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 32.7 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 35.3 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 35.4 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 5.3 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 10.7 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 16 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 18.3 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 20.7 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 23 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 25.3 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 27.7 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 32.3 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 34.7 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 35.4 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 5.3 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 10.7 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 16 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 18.5 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 21 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 23.5 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 26 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 28.5 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 31 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 33.5 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 36.6 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 5.7 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 11.3 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 17 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 19.3 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 21.7 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 24 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 26.3 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 28.7 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 31 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 33.3 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 35.7 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 36.6 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 6 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 18 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 20.3 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 22.7 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 25 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 27.3 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 29.7 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 34.3 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 36.7 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 37.8 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 6 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 18 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 20.5 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 23 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 25.5 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 30.5 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 33 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 35.5 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 38 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 39 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 6 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 18 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 20.5 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 23 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 25.5 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 30.5 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 33 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 35.5 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 38 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 39 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 3.3 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 6.7 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 10 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 11.8 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 13.7 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 15.5 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 17.3 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 19.2 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 21 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 21.7 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 22.8 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 23.4 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 4.7 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 9.3 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 14 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 16.5 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 19 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 21.5 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 24 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 26.5 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 29 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 30.25 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 32.2 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 5.3 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 10.7 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 16 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 18.5 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 21 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 23.5 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 26 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 28.5 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 31 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 32.1 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 33.6 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 34.5 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 5.3 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 10.7 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 16 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 18.8 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 21.7 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 24.5 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 27.3 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 30.2 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 33 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 34.1 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 35.8 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 36.7 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 5.7 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 11.3 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 17 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 19.7 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 22.3 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 25 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 27.7 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 30.3 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 33 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 34.1 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 35.8 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 36.7 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 6 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 18 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 20.7 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 23.3 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 26 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 28.7 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 31.3 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 34 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 35.2 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 36.9 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 37.8 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 6 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 18 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 20.8 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 23.7 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 26.5 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 29.3 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 32.2 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 35 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 36.2 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 38 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 38.9 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 6.7 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 13.3 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 20 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 22.7 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 25.3 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 30.7 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 33.3 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 36 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 37.2 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 39 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 40 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 7.3 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 14.7 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 22 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 24.3 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 26.7 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 29 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 31.3 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 33.7 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 36 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 37.2 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 39 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 40 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 4 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 8 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 13.8 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 15.7 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 17.5 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 19.3 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 21.2 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 23 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 23.5 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 24.2 },
    { DevCode: 214, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 25 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 5.3 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 10.7 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 16 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 18.5 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 21 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 23.5 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 26 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 28.5 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 31 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 31.6 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 32.6 },
    { DevCode: 214, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 33.7 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 6 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 18 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 20.3 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 22.7 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 25 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 27.3 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 29.7 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 32 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 32.6 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 33.7 },
    { DevCode: 214, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 34.7 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 6 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 18 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 21 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 24 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 27 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 33 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 36 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 36.7 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 37.9 },
    { DevCode: 214, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 39.1 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 6 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 12 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 18 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 20.8 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 23.7 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 26.5 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 29.3 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 32.2 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 35 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 35.7 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 36.8 },
    { DevCode: 214, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 38 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 6.3 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 12.7 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 19 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 22 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 25 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 31 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 34 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 37 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 37.7 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 38.9 },
    { DevCode: 214, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 40.2 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 6.7 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 13.3 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 20 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 22.8 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 25.7 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 28.5 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 31.3 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 34.2 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 37 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 37.7 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 38.9 },
    { DevCode: 214, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 40.2 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 7.3 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 14.7 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 22 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 24.7 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 27.3 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 30 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 32.7 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 35.3 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 38 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 38.8 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 40 },
    { DevCode: 214, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 41.3 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 7.7 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 15.3 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 23 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 25.5 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 28 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 30.5 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 33 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 35.5 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 38 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 38.8 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 40 },
    { DevCode: 214, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 41.3 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 5 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 9 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 13 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 14 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 15 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 16 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 17 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 5 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 10 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 16 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 17 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 19 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 20 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 21 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 22 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 22 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 22 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 22 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 6 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 12 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 19.3 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 20.7 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 22 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 23.3 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 24.7 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 13 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 21.3 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 22.7 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 25.3 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 26.7 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 13 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 21.3 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 22.7 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 25.3 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 26.7 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 13 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 21.3 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 22.7 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 25.3 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 26.7 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 13 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 21.7 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 23.3 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 25 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 26.7 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 28.3 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 8 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 15 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 23 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 24 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 25 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 27 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 8 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 15 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 23 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 24 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 25 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 27 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 6 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 11 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 16 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 17 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 19 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 20 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 21 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 23 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 24 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 25 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 6 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 12 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 19.7 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 21.3 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 23 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 24.7 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 26.3 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 29 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 31 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 7 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 14 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 21 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 22.5 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 24 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 25.5 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 27 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 28.5 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 31.5 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 33 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 34 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 7 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 14 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 22 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 23.8 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 25.7 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 27.5 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 29.3 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 31.2 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 33 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 34.5 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 37 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 7 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 14 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 22 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 23.6 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 25.3 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 27 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 28.6 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 30.3 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 33.5 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 35 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 8 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 16 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 25.3 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 26.7 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 29.3 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 30.7 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 33.5 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 35 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 8 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 16 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 25.3 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 26.7 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 29.3 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 30.7 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 33.5 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 35 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 9 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 27 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 28.2 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 29.3 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 30.5 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 31.7 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 32.8 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 34 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 35 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 37 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 9 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 29 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 31 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 32 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 33 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 34 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 35 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 37 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 6 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 11 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 17 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 18.3 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 19.7 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 21 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 22.3 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 23.7 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 25 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 26.5 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 27 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 8 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 15 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 23 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 24.5 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 27.5 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 29 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 30.5 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 32 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 33 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 34 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 35 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 8 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 16 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 24 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 25.8 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 27.6 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 29.5 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 31.3 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 33.1 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 35 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 36.5 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 38 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 39 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 8 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 16 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 24 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 32 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 34 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 37.5 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 39 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 40 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 9 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 17 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 27.7 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 29.3 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 31 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 32.7 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 34.3 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 37.5 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 39 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 40 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 9 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 27.7 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 29.3 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 31 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 32.7 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 34.3 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 37.5 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 39 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 40 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 9 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 29.7 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 31.3 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 33 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 34.7 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 36.3 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 39.5 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 41 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 42 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 9 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 29.7 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 31.3 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 33 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 34.7 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 36.3 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 39.5 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 41 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 42 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 10 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 21 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 33.3 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 34.7 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 37.3 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 38.7 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 40 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 41.5 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 43 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 44 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 6 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 12 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 19.5 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 21 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 22.5 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 24 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 25.5 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 27 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 28.5 },
    { DevCode: 215, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 29 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 8 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 15 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 23 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 25.2 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 27.3 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 29.5 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 31.7 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 33.8 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 37.5 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 39 },
    { DevCode: 215, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 40 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 9 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 17 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 32 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 34 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 38 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 39.5 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 41 },
    { DevCode: 215, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 42 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 9 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 17 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 26 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 28.3 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 30.7 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 33 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 35.3 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 37.7 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 40 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 41.5 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 43 },
    { DevCode: 215, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 44 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 9 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 32 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 34 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 38 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 40 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 41.5 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 43 },
    { DevCode: 215, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 44 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 9 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 18 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 28 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 32 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 34 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 38 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 40 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 41.5 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 43 },
    { DevCode: 215, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 44 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 10 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 19 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 29 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 31.2 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 33.3 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 35.5 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 37.7 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 39.8 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 43.5 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 45 },
    { DevCode: 215, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 46 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 10 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 20 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 32 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 34 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 36 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 38 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 40 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 43.5 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 45 },
    { DevCode: 215, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 46 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 10 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 20 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 30 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 32.3 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 34.7 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 39.3 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 41.7 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 44 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 45.5 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 47 },
    { DevCode: 215, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 48 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 6, Height: 1, Diameter: 17.3 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 6, Height: 2, Diameter: 20.3 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 6, Height: 3, Diameter: 23.3 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 6, Height: 4, Diameter: 24.4 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 6, Height: 5, Diameter: 25.6 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 6, Height: 6, Diameter: 26.7 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 6, Height: 7, Diameter: 26.4 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 6, Height: 8, Diameter: 26.2 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 6, Height: 10, Diameter: 26 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 6, Height: 11, Diameter: 26 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 6, Height: 12, Diameter: 26 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 18.7 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 21.7 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 24.7 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 26.2 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 27.8 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 29.3 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 29.6 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 29.8 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 30 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 30 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 30 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 20 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 23 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 28 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 30 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 32.7 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 33.3 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 22 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 25 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 28 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 30.7 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 33.3 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 37.3 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 38.7 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 20 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 25 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 30 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 32 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 37.3 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 38.7 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 20 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 25 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 30 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 32 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 36.7 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 37.3 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 20 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 25 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 30 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 32 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 36.7 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 37.3 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 18 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 23 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 28 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 30.7 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 33.3 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 10, Height: 1, Diameter: 20 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 10, Height: 2, Diameter: 23 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 10, Height: 4, Diameter: 27.3 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 10, Height: 5, Diameter: 28.7 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 10, Height: 6, Diameter: 30 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 10, Height: 7, Diameter: 30.7 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 10, Height: 8, Diameter: 31.3 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 10, Height: 10, Diameter: 32.7 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 10, Height: 11, Diameter: 33.3 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 10, Height: 12, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 26 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 29 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 33.3 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 34.7 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 36.7 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 37.3 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 38.7 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 39.3 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 32 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 33 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 41.3 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 42.7 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 45.3 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 46.7 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 48 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 35 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 38.7 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 41.3 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 45.3 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 46.7 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 49.3 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 50.7 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 52 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 35 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 38.7 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 41.3 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 45.3 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 46.7 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 49.3 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 50.7 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 52 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 32 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 35 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 40.7 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 43.3 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 30 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 33 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 38.7 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 41.3 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 44.7 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 45.3 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 30 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 38.7 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 39.3 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 42 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 44 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 15, Height: 1, Diameter: 24 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 15, Height: 2, Diameter: 26 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 15, Height: 4, Diameter: 30 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 15, Height: 5, Diameter: 32 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 15, Height: 7, Diameter: 35.3 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 15, Height: 8, Diameter: 36.7 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 15, Height: 10, Diameter: 39.3 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 15, Height: 11, Diameter: 40.7 },
    { DevCode: 1, Nozzle1: 0.1, Pressure: 15, Height: 12, Diameter: 41.9 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 32 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 35 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 42 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 44.7 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 45.3 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 46.7 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 47.3 },
    { DevCode: 1, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 48 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 37 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 40.7 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 43.3 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 48 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 50 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 54 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 56 },
    { DevCode: 1, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 56.9 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 36 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 42.7 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 45.3 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 50 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 52 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 56 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 58 },
    { DevCode: 1, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 59 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 39 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 42.7 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 45.3 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 50 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 52 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 56 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 58 },
    { DevCode: 1, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 59 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 40 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 44 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 49.3 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 50.7 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 53.3 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 54.7 },
    { DevCode: 1, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 56 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 44 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 49.3 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 50.7 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 53.3 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 54.7 },
    { DevCode: 1, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 56 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 34 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 38 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 43.3 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 44.7 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 48 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 50 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 54 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 56 },
    { DevCode: 1, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 56.9 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 6, Height: 1, Diameter: 21 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 6, Height: 2, Diameter: 23.8 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 6, Height: 3, Diameter: 26.7 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 6, Height: 4, Diameter: 28.2 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 6, Height: 5, Diameter: 29.8 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 6, Height: 6, Diameter: 31.3 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 6, Height: 7, Diameter: 30.9 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 6, Height: 8, Diameter: 30.4 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 6, Height: 10, Diameter: 30 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 6, Height: 11, Diameter: 30 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 6, Height: 12, Diameter: 30 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 6, Height: 1, Diameter: 22 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 6, Height: 2, Diameter: 24.5 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 27 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 6, Height: 4, Diameter: 28.7 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 6, Height: 5, Diameter: 30.3 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 6, Height: 7, Diameter: 31.7 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 6, Height: 8, Diameter: 31.3 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 31 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 6, Height: 10, Diameter: 31 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 6, Height: 11, Diameter: 31 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 6, Height: 12, Diameter: 31 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 23 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 25.2 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 27.3 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 29.1 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 30.9 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 32.7 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 32.4 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 32.2 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 32 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 32 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 32 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 25 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 26.5 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 28 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 30 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 32 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 28 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 28.5 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 29 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 31.3 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 33.7 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 36 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 36.3 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 36.7 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 37 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 37 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 37 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 37 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 30 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 30.5 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 31 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 37 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 39.7 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 39.3 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 39 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 39 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 39 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 39 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 31 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 31.5 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 32 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 34.7 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 37.3 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 34.5 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 35 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 37.3 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 39.7 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 42 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 42 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 42 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 42 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 42 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 42 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 42 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 32 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 33 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 36 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 38 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 41.3 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 42.7 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 44 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 44 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 44 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 44 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 10, Height: 1, Diameter: 25 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 10, Height: 2, Diameter: 27 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 10, Height: 3, Diameter: 29 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 10, Height: 4, Diameter: 31.3 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 10, Height: 5, Diameter: 33.7 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 10, Height: 7, Diameter: 36.7 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 10, Height: 8, Diameter: 37.3 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 10, Height: 9, Diameter: 38 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 10, Height: 10, Diameter: 38 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 10, Height: 11, Diameter: 38 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 10, Height: 12, Diameter: 38 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 10, Height: 1, Diameter: 28 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 10, Height: 2, Diameter: 30 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 10, Height: 4, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 10, Height: 5, Diameter: 36 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 10, Height: 7, Diameter: 38.7 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 10, Height: 8, Diameter: 39.3 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 10, Height: 10, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 10, Height: 11, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 10, Height: 12, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 31 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 33 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 36.7 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 38.3 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 40.7 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 41.3 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 42 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 42 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 42 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 36.5 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 39 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 40.7 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 42.3 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 45 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 46 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 47 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 47 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 47 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 47 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 37 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 40.5 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 44 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 45.3 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 46.7 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 48 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 48.7 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 49.3 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 50 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 50 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 41 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 43.5 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 46 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 47.3 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 48.7 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 50.7 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 51.3 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 52 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 52 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 52 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 43 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 46 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 49 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 50.3 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 51.7 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 53 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 53.3 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 53.7 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 54 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 54 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 42 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 44.5 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 47 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 48 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 49 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 51.3 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 52.7 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 54 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 54 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 44 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 48 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 48.7 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 49.3 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 51 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 52 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 53 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 53 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 53 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 53 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 15, Height: 1, Diameter: 32 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 15, Height: 2, Diameter: 33 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 15, Height: 4, Diameter: 35.7 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 15, Height: 5, Diameter: 37.3 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 15, Height: 7, Diameter: 39.7 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 15, Height: 8, Diameter: 40.3 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 15, Height: 9, Diameter: 41 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 15, Height: 10, Diameter: 41.7 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 15, Height: 11, Diameter: 42.3 },
    { DevCode: 10, Nozzle1: 0.1, Pressure: 15, Height: 12, Diameter: 43 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 15, Height: 1, Diameter: 34 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 15, Height: 2, Diameter: 37 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 15, Height: 4, Diameter: 41.7 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 15, Height: 5, Diameter: 43.3 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 45 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 15, Height: 7, Diameter: 45.7 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 15, Height: 8, Diameter: 46.3 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 47 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 15, Height: 10, Diameter: 47 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 15, Height: 11, Diameter: 47 },
    { DevCode: 10, Nozzle1: 0.12, Pressure: 15, Height: 12, Diameter: 47 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 37 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 40 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 43 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 44.3 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 45.7 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 47 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 48.3 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 49.7 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 51 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 51 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 51 },
    { DevCode: 10, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 51 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 46.5 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 49 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 50.7 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 52.3 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 54 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 54.3 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 54.7 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 55 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 55 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 55 },
    { DevCode: 10, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 55 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 46 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 49.5 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 53 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 54 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 55 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 56 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 56.7 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 57.3 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 58 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 58 },
    { DevCode: 10, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 47 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 50 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 53 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 54.3 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 55.7 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 57 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 57.7 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 58.3 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 59 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 59 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 59 },
    { DevCode: 10, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 59 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 48 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 51.5 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 55 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 56.3 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 57.7 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 59 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 59.3 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 59.7 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 60 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 60 },
    { DevCode: 10, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 60 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 47 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 50 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 53 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 54.3 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 55.7 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 57 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 58 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 59 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 60 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 60 },
    { DevCode: 10, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 60 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 46 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 50 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 54 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 55 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 56 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 57 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 57.7 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 58.3 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 59 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 59 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 59 },
    { DevCode: 10, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 59 },
    { DevCode: 13, Nozzle1: 0.1, Pressure: 6, Height: 12, Diameter: 32 },
    { DevCode: 13, Nozzle1: 0.12, Pressure: 6, Height: 12, Diameter: 36 },
    { DevCode: 13, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 36 },
    { DevCode: 13, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 40 },
    { DevCode: 13, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 43 },
    { DevCode: 13, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 45 },
    { DevCode: 13, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 44 },
    { DevCode: 13, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 45 },
    { DevCode: 13, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 43 },
    { DevCode: 13, Nozzle1: 0.1, Pressure: 10, Height: 12, Diameter: 40 },
    { DevCode: 13, Nozzle1: 0.12, Pressure: 10, Height: 12, Diameter: 44 },
    { DevCode: 13, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 44 },
    { DevCode: 13, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 48 },
    { DevCode: 13, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 52 },
    { DevCode: 13, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 55 },
    { DevCode: 13, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 55 },
    { DevCode: 13, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 51 },
    { DevCode: 13, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 52 },
    { DevCode: 13, Nozzle1: 0.1, Pressure: 15, Height: 12, Diameter: 42 },
    { DevCode: 13, Nozzle1: 0.12, Pressure: 15, Height: 12, Diameter: 46 },
    { DevCode: 13, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 50 },
    { DevCode: 13, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 54 },
    { DevCode: 13, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 13, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 13, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 55 },
    { DevCode: 13, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 55 },
    { DevCode: 13, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 55 },
    { DevCode: 175, Nozzle1: 0.07, Pressure: 30, Height: 12, Diameter: 67 },
    { DevCode: 175, Nozzle1: 0.09, Pressure: 30, Height: 12, Diameter: 70 },
    { DevCode: 175, Nozzle1: 0.07, Pressure: 40, Height: 12, Diameter: 68 },
    { DevCode: 175, Nozzle1: 0.09, Pressure: 40, Height: 12, Diameter: 72 },
    { DevCode: 175, Nozzle1: 0.07, Pressure: 50, Height: 12, Diameter: 69 },
    { DevCode: 175, Nozzle1: 0.09, Pressure: 50, Height: 12, Diameter: 74 },
    { DevCode: 175, Nozzle1: 0.07, Pressure: 60, Height: 12, Diameter: 70 },
    { DevCode: 175, Nozzle1: 0.09, Pressure: 60, Height: 12, Diameter: 76 },
    { DevCode: 176, Nozzle1: 0.1, Pressure: 30, Height: 12, Diameter: 71 },
    { DevCode: 176, Nozzle1: 0.12, Pressure: 30, Height: 12, Diameter: 72 },
    { DevCode: 176, Nozzle1: 0.1, Pressure: 40, Height: 12, Diameter: 72 },
    { DevCode: 176, Nozzle1: 0.12, Pressure: 40, Height: 12, Diameter: 74 },
    { DevCode: 176, Nozzle1: 0.1, Pressure: 50, Height: 12, Diameter: 73 },
    { DevCode: 176, Nozzle1: 0.12, Pressure: 50, Height: 12, Diameter: 76 },
    { DevCode: 176, Nozzle1: 0.1, Pressure: 60, Height: 12, Diameter: 74 },
    { DevCode: 176, Nozzle1: 0.12, Pressure: 60, Height: 12, Diameter: 78 },
    { DevCode: 177, Nozzle1: 0.13, Pressure: 30, Height: 12, Diameter: 71 },
    { DevCode: 177, Nozzle1: 0.18, Pressure: 30, Height: 12, Diameter: 72 },
    { DevCode: 177, Nozzle1: 0.13, Pressure: 40, Height: 12, Diameter: 72 },
    { DevCode: 177, Nozzle1: 0.18, Pressure: 40, Height: 12, Diameter: 74 },
    { DevCode: 177, Nozzle1: 0.13, Pressure: 50, Height: 12, Diameter: 73 },
    { DevCode: 177, Nozzle1: 0.18, Pressure: 50, Height: 12, Diameter: 76 },
    { DevCode: 177, Nozzle1: 0.13, Pressure: 60, Height: 12, Diameter: 74 },
    { DevCode: 177, Nozzle1: 0.18, Pressure: 60, Height: 12, Diameter: 78 },
    { DevCode: 178, Nozzle1: 0.13, Pressure: 30, Height: 12, Diameter: 66 },
    { DevCode: 178, Nozzle1: 0.18, Pressure: 30, Height: 12, Diameter: 63 },
    { DevCode: 178, Nozzle1: 0.13, Pressure: 40, Height: 12, Diameter: 67 },
    { DevCode: 178, Nozzle1: 0.18, Pressure: 40, Height: 12, Diameter: 64 },
    { DevCode: 178, Nozzle1: 0.13, Pressure: 50, Height: 12, Diameter: 68 },
    { DevCode: 178, Nozzle1: 0.18, Pressure: 50, Height: 12, Diameter: 65 },
    { DevCode: 178, Nozzle1: 0.13, Pressure: 60, Height: 12, Diameter: 67 },
    { DevCode: 178, Nozzle1: 0.18, Pressure: 60, Height: 12, Diameter: 64 },
    { DevCode: 171, Nozzle1: 0.07, Pressure: 30, Height: 12, Diameter: 73 },
    { DevCode: 171, Nozzle1: 0.09, Pressure: 30, Height: 12, Diameter: 78 },
    { DevCode: 171, Nozzle1: 0.07, Pressure: 40, Height: 12, Diameter: 76 },
    { DevCode: 171, Nozzle1: 0.09, Pressure: 40, Height: 12, Diameter: 81 },
    { DevCode: 171, Nozzle1: 0.07, Pressure: 50, Height: 12, Diameter: 79 },
    { DevCode: 171, Nozzle1: 0.09, Pressure: 50, Height: 12, Diameter: 84 },
    { DevCode: 171, Nozzle1: 0.07, Pressure: 60, Height: 12, Diameter: 82 },
    { DevCode: 171, Nozzle1: 0.09, Pressure: 60, Height: 12, Diameter: 87 },
    { DevCode: 172, Nozzle1: 0.1, Pressure: 30, Height: 12, Diameter: 80 },
    { DevCode: 172, Nozzle1: 0.12, Pressure: 30, Height: 12, Diameter: 84 },
    { DevCode: 172, Nozzle1: 0.1, Pressure: 40, Height: 12, Diameter: 83 },
    { DevCode: 172, Nozzle1: 0.12, Pressure: 40, Height: 12, Diameter: 87 },
    { DevCode: 172, Nozzle1: 0.1, Pressure: 50, Height: 12, Diameter: 86 },
    { DevCode: 172, Nozzle1: 0.12, Pressure: 50, Height: 12, Diameter: 90 },
    { DevCode: 172, Nozzle1: 0.1, Pressure: 60, Height: 12, Diameter: 89 },
    { DevCode: 172, Nozzle1: 0.12, Pressure: 60, Height: 12, Diameter: 93 },
    { DevCode: 173, Nozzle1: 0.13, Pressure: 30, Height: 12, Diameter: 80 },
    { DevCode: 173, Nozzle1: 0.18, Pressure: 30, Height: 12, Diameter: 84 },
    { DevCode: 173, Nozzle1: 0.13, Pressure: 40, Height: 12, Diameter: 83 },
    { DevCode: 173, Nozzle1: 0.18, Pressure: 40, Height: 12, Diameter: 87 },
    { DevCode: 173, Nozzle1: 0.13, Pressure: 50, Height: 12, Diameter: 86 },
    { DevCode: 173, Nozzle1: 0.18, Pressure: 50, Height: 12, Diameter: 90 },
    { DevCode: 173, Nozzle1: 0.13, Pressure: 60, Height: 12, Diameter: 89 },
    { DevCode: 173, Nozzle1: 0.18, Pressure: 60, Height: 12, Diameter: 93 },
    { DevCode: 174, Nozzle1: 0.13, Pressure: 30, Height: 12, Diameter: 74 },
    { DevCode: 174, Nozzle1: 0.18, Pressure: 30, Height: 12, Diameter: 73 },
    { DevCode: 174, Nozzle1: 0.13, Pressure: 40, Height: 12, Diameter: 77 },
    { DevCode: 174, Nozzle1: 0.18, Pressure: 40, Height: 12, Diameter: 75 },
    { DevCode: 174, Nozzle1: 0.13, Pressure: 50, Height: 12, Diameter: 80 },
    { DevCode: 174, Nozzle1: 0.18, Pressure: 50, Height: 12, Diameter: 76 },
    { DevCode: 174, Nozzle1: 0.13, Pressure: 60, Height: 12, Diameter: 81 },
    { DevCode: 174, Nozzle1: 0.18, Pressure: 60, Height: 12, Diameter: 76 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 8 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 10 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 12 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 16 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 20 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 24 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 14 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 14 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 21 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 26 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 10 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 13 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 16 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 18 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 15 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 20 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 26 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 29 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 17 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 23 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 29 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 33 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 18 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 25 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 31 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 35 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 33 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 37 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 10 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 14 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 18 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 22 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 22 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 28 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 33 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 21 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 23 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 36 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 41 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 25 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 44 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 11 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 15 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 20 },
    { DevCode: 294, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 24 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 24 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 31 },
    { DevCode: 294, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 36 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 20 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 29 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 294, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 24 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 40 },
    { DevCode: 294, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 45 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 26 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 35 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 42 },
    { DevCode: 294, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 48 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 9 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 11 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 31 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 22 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 23 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 27 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 33 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 12 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 15 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 18 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 27 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 32 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 18 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 22 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 26 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 25 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 30 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 32 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 41 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 45 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 35 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 39 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 20 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 24 },
    { DevCode: 291, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 28 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 27 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 291, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 41 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 33 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 291, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 47 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 35 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 39 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 291, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 38 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 43 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 291, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 6 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 10 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 12 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 16 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 20 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 24 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 13 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 14 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 15 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 25 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 8 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 12 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 15 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 18 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 13 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 18 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 24 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 29 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 15 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 21 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 34 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 16 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 30 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 35 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 18 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 25 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 37 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 10 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 14 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 18 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 22 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 22 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 28 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 33 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 18 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 26 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 22 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 36 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 41 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 24 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 44 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 11 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 15 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 20 },
    { DevCode: 255, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 24 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 24 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 31 },
    { DevCode: 255, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 36 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 20 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 29 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 256, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 24 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 40 },
    { DevCode: 256, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 45 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 26 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 35 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 42 },
    { DevCode: 256, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 48 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 9 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 11 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 22 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 23 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 27 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 12 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 15 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 18 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 27 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 32 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 18 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 22 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 26 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 25 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 30 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 32 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 41 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 45 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 35 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 39 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 20 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 24 },
    { DevCode: 251, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 28 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 27 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 251, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 41 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 33 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 252, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 47 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 35 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 39 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 252, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 38 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 43 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 252, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 13 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 13 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 15 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 21 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 34 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 15 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 21 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 34 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 18 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 26 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 18 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 26 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 20 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 29 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 253, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 20 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 29 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 254, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 10, Height: 1, Diameter: 19 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 10, Height: 2, Diameter: 26 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 10, Height: 3, Diameter: 33 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 10, Height: 4, Diameter: 34.7 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 10, Height: 5, Diameter: 36.3 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 10, Height: 7, Diameter: 39.7 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 10, Height: 8, Diameter: 41.3 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 10, Height: 10, Diameter: 43 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 10, Height: 11, Diameter: 43 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 10, Height: 12, Diameter: 43 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 20 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 27 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 35.7 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 37.3 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 40.7 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 42.3 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 44 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 44 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 44 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 21 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 28 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 36.7 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 38.3 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 41.7 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 43.3 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 45 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 45 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 45 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 45 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 10, Height: 1, Diameter: 22 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 10, Height: 2, Diameter: 29 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 10, Height: 4, Diameter: 37.7 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 10, Height: 5, Diameter: 39.3 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 10, Height: 6, Diameter: 41 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 10, Height: 7, Diameter: 42.7 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 10, Height: 8, Diameter: 44.3 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 10, Height: 10, Diameter: 46 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 10, Height: 11, Diameter: 46 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 10, Height: 12, Diameter: 46 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 10, Height: 1, Diameter: 23 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 10, Height: 2, Diameter: 30 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 10, Height: 3, Diameter: 37 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 10, Height: 4, Diameter: 38.7 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 10, Height: 5, Diameter: 40.3 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 10, Height: 6, Diameter: 42 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 10, Height: 7, Diameter: 43.7 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 10, Height: 8, Diameter: 45.3 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 10, Height: 9, Diameter: 47 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 10, Height: 10, Diameter: 47 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 10, Height: 11, Diameter: 47 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 10, Height: 12, Diameter: 47 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 15, Height: 1, Diameter: 22 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 15, Height: 2, Diameter: 27 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 15, Height: 3, Diameter: 32.4 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 15, Height: 4, Diameter: 34.5 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 15, Height: 5, Diameter: 36.7 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 15, Height: 6, Diameter: 38.8 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 15, Height: 7, Diameter: 41.1 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 15, Height: 8, Diameter: 43.4 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 15, Height: 9, Diameter: 45.6 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 15, Height: 10, Diameter: 45.6 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 15, Height: 11, Diameter: 45.6 },
    { DevCode: 6, Nozzle1: 0.1, Pressure: 15, Height: 12, Diameter: 45.6 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 23 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 28 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 33.4 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 35.5 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 37.7 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 39.8 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 42.1 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 44.4 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 46.6 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 46.6 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 46.6 },
    { DevCode: 6, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 46.6 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 24 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 29 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 34.4 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 36.5 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 38.7 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 40.8 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 43.1 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 45.4 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 47.6 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 47.6 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 47.6 },
    { DevCode: 6, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 47.6 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 15, Height: 1, Diameter: 25 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 15, Height: 2, Diameter: 30 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 15, Height: 3, Diameter: 35.4 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 15, Height: 4, Diameter: 37.5 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 15, Height: 5, Diameter: 39.7 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 15, Height: 6, Diameter: 41.8 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 15, Height: 7, Diameter: 44.1 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 15, Height: 8, Diameter: 46.4 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 15, Height: 9, Diameter: 48.6 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 15, Height: 10, Diameter: 48.6 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 15, Height: 11, Diameter: 48.6 },
    { DevCode: 6, Nozzle1: 0.22, Pressure: 15, Height: 12, Diameter: 48.6 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 15, Height: 1, Diameter: 26 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 15, Height: 2, Diameter: 31 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 15, Height: 3, Diameter: 36.4 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 15, Height: 4, Diameter: 38.5 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 15, Height: 5, Diameter: 40.7 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 15, Height: 6, Diameter: 42.8 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 15, Height: 7, Diameter: 45.1 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 15, Height: 8, Diameter: 47.4 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 15, Height: 9, Diameter: 49.6 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 15, Height: 10, Diameter: 49.6 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 15, Height: 11, Diameter: 49.6 },
    { DevCode: 6, Nozzle1: 0.26, Pressure: 15, Height: 12, Diameter: 49.6 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 10, Height: 1, Diameter: 25 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 10, Height: 2, Diameter: 32 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 10, Height: 4, Diameter: 39.7 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 10, Height: 5, Diameter: 41.3 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 10, Height: 6, Diameter: 43 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 10, Height: 7, Diameter: 43.9 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 10, Height: 8, Diameter: 44.7 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 10, Height: 9, Diameter: 45.6 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 10, Height: 10, Diameter: 45.6 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 10, Height: 11, Diameter: 45.6 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 10, Height: 12, Diameter: 45.6 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 26 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 33 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 39 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 40.7 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 42.3 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 44.9 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 45.7 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 46.6 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 46.6 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 46.6 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 46.6 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 27 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 34 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 40 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 41.7 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 43.3 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 45 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 45.9 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 46.7 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 47.6 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 47.6 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 47.6 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 47.6 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 10, Height: 1, Diameter: 28 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 10, Height: 2, Diameter: 35 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 10, Height: 3, Diameter: 41 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 10, Height: 4, Diameter: 42.7 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 10, Height: 5, Diameter: 44.3 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 10, Height: 7, Diameter: 46.9 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 10, Height: 8, Diameter: 47.7 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 10, Height: 9, Diameter: 48.6 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 10, Height: 10, Diameter: 48.6 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 10, Height: 11, Diameter: 48.6 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 10, Height: 12, Diameter: 48.6 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 10, Height: 1, Diameter: 29 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 10, Height: 2, Diameter: 36 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 10, Height: 3, Diameter: 42 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 10, Height: 4, Diameter: 43.7 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 10, Height: 5, Diameter: 45.3 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 10, Height: 6, Diameter: 47 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 10, Height: 7, Diameter: 47.9 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 10, Height: 8, Diameter: 48.7 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 10, Height: 9, Diameter: 49.6 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 10, Height: 10, Diameter: 49.6 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 10, Height: 11, Diameter: 49.6 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 10, Height: 12, Diameter: 49.6 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 15, Height: 1, Diameter: 26 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 15, Height: 2, Diameter: 33 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 15, Height: 3, Diameter: 39.2 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 15, Height: 4, Diameter: 41.5 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 15, Height: 5, Diameter: 43.7 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 15, Height: 7, Diameter: 47.8 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 15, Height: 8, Diameter: 49.6 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 15, Height: 9, Diameter: 50.25 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 15, Height: 10, Diameter: 50.25 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 15, Height: 11, Diameter: 50.25 },
    { DevCode: 7, Nozzle1: 0.1, Pressure: 15, Height: 12, Diameter: 50.25 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 27 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 34 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 40.2 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 42.5 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 44.7 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 47 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 48.8 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 50.6 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 52.4 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 52.4 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 52.4 },
    { DevCode: 7, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 52.4 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 28 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 35 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 41.2 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 43.5 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 45.7 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 49.8 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 51.6 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 53.4 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 53.4 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 53.4 },
    { DevCode: 7, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 53.4 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 15, Height: 1, Diameter: 29 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 15, Height: 2, Diameter: 36 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 15, Height: 3, Diameter: 42.2 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 15, Height: 4, Diameter: 44.5 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 15, Height: 5, Diameter: 46.7 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 15, Height: 6, Diameter: 49 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 15, Height: 7, Diameter: 50.8 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 15, Height: 8, Diameter: 52.6 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 15, Height: 9, Diameter: 54.4 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 15, Height: 10, Diameter: 54.4 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 15, Height: 11, Diameter: 54.4 },
    { DevCode: 7, Nozzle1: 0.22, Pressure: 15, Height: 12, Diameter: 54.4 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 15, Height: 1, Diameter: 30 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 15, Height: 2, Diameter: 37 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 15, Height: 3, Diameter: 43.2 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 15, Height: 4, Diameter: 45.5 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 15, Height: 5, Diameter: 47.7 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 15, Height: 7, Diameter: 51.8 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 15, Height: 8, Diameter: 53.6 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 15, Height: 9, Diameter: 55.4 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 15, Height: 10, Diameter: 55.4 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 15, Height: 11, Diameter: 55.4 },
    { DevCode: 7, Nozzle1: 0.26, Pressure: 15, Height: 12, Diameter: 55.4 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 10, Height: 1, Diameter: 16.5 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 10, Height: 2, Diameter: 23.5 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 10, Height: 3, Diameter: 29.5 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 10, Height: 4, Diameter: 31.7 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 10, Height: 5, Diameter: 33.3 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 10, Height: 6, Diameter: 34.5 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 10, Height: 7, Diameter: 35.3 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 10, Height: 8, Diameter: 36.2 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 10, Height: 9, Diameter: 37 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 10, Height: 10, Diameter: 37 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 10, Height: 11, Diameter: 37 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 10, Height: 12, Diameter: 37 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 17.5 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 24.5 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 30.5 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 32.7 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 34.3 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 35.5 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 36.3 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 37.2 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 38 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 38 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 38 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 38 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 18.5 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 25.5 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 31.5 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 33.7 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 35.3 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 36.5 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 37.3 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 38.2 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 39 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 39 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 39 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 39 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 10, Height: 1, Diameter: 19.5 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 10, Height: 2, Diameter: 26.5 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 10, Height: 3, Diameter: 32.5 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 10, Height: 4, Diameter: 34.7 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 10, Height: 5, Diameter: 36.3 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 10, Height: 6, Diameter: 37.5 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 10, Height: 7, Diameter: 38.3 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 10, Height: 8, Diameter: 39.2 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 10, Height: 10, Diameter: 40 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 10, Height: 11, Diameter: 40 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 10, Height: 12, Diameter: 40 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 10, Height: 1, Diameter: 20.5 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 10, Height: 2, Diameter: 27.5 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 10, Height: 3, Diameter: 33.5 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 10, Height: 4, Diameter: 35.7 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 10, Height: 5, Diameter: 37.3 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 10, Height: 6, Diameter: 38.5 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 10, Height: 7, Diameter: 39.3 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 10, Height: 8, Diameter: 40.2 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 10, Height: 9, Diameter: 41 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 10, Height: 10, Diameter: 41 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 10, Height: 11, Diameter: 41 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 10, Height: 12, Diameter: 41 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 15, Height: 1, Diameter: 23 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 15, Height: 2, Diameter: 30 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 15, Height: 4, Diameter: 37.5 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 15, Height: 5, Diameter: 39 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 15, Height: 6, Diameter: 40.6 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 15, Height: 7, Diameter: 41.9 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 15, Height: 8, Diameter: 43.3 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 15, Height: 9, Diameter: 44.6 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 15, Height: 10, Diameter: 44.6 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 15, Height: 11, Diameter: 44.6 },
    { DevCode: 5, Nozzle1: 0.1, Pressure: 15, Height: 12, Diameter: 44.6 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 24 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 31 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 37 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 38.5 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 40 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 41.6 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 42.9 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 44.3 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 45.6 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 45.6 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 45.6 },
    { DevCode: 5, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 45.6 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 25 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 32 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 39.5 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 41 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 42.6 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 43.9 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 45.3 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 46.6 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 46.6 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 46.6 },
    { DevCode: 5, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 46.6 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 15, Height: 1, Diameter: 26 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 15, Height: 2, Diameter: 33 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 15, Height: 3, Diameter: 39 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 15, Height: 4, Diameter: 40.5 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 15, Height: 5, Diameter: 42 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 15, Height: 6, Diameter: 43.6 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 15, Height: 7, Diameter: 44.9 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 15, Height: 8, Diameter: 46.3 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 15, Height: 9, Diameter: 47.6 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 15, Height: 10, Diameter: 47.6 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 15, Height: 11, Diameter: 47.6 },
    { DevCode: 5, Nozzle1: 0.22, Pressure: 15, Height: 12, Diameter: 47.6 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 15, Height: 1, Diameter: 27 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 15, Height: 2, Diameter: 34 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 15, Height: 4, Diameter: 41.5 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 15, Height: 5, Diameter: 43 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 15, Height: 6, Diameter: 44.6 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 15, Height: 7, Diameter: 45.9 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 15, Height: 8, Diameter: 47.3 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 15, Height: 9, Diameter: 48.6 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 15, Height: 10, Diameter: 48.6 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 15, Height: 11, Diameter: 48.6 },
    { DevCode: 5, Nozzle1: 0.26, Pressure: 15, Height: 12, Diameter: 48.6 },
    { DevCode: 9, Nozzle1: 0.1, Pressure: 10, Height: 12, Diameter: 45 },
    { DevCode: 9, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 46.2 },
    { DevCode: 9, Nozzle1: 0.17, Pressure: 10, Height: 12, Diameter: 47.5 },
    { DevCode: 9, Nozzle1: 0.2, Pressure: 10, Height: 12, Diameter: 48.7 },
    { DevCode: 9, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 10, Height: 1, Diameter: 22 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 10, Height: 2, Diameter: 29 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 10, Height: 4, Diameter: 36 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 10, Height: 5, Diameter: 35 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 10, Height: 6, Diameter: 35 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 10, Height: 7, Diameter: 35 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 10, Height: 8, Diameter: 35 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 10, Height: 9, Diameter: 35 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 10, Height: 10, Diameter: 35 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 10, Height: 11, Diameter: 35 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 10, Height: 12, Diameter: 35 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 23 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 30 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 37 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 37 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 36 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 36 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 36 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 36 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 36 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 24 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 31 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 38 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 38 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 37 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 37 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 37 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 37 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 37 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 37 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 37 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 37 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 10, Height: 1, Diameter: 25 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 10, Height: 2, Diameter: 32 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 10, Height: 3, Diameter: 39 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 10, Height: 4, Diameter: 39 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 10, Height: 5, Diameter: 38 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 10, Height: 7, Diameter: 38 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 10, Height: 8, Diameter: 38 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 10, Height: 9, Diameter: 38 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 10, Height: 10, Diameter: 38 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 10, Height: 11, Diameter: 38 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 10, Height: 12, Diameter: 38 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 10, Height: 1, Diameter: 26 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 10, Height: 2, Diameter: 33 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 10, Height: 3, Diameter: 40 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 10, Height: 4, Diameter: 40 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 10, Height: 5, Diameter: 39 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 10, Height: 7, Diameter: 39 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 10, Height: 8, Diameter: 39 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 10, Height: 9, Diameter: 39 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 10, Height: 10, Diameter: 39 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 10, Height: 11, Diameter: 39 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 10, Height: 12, Diameter: 39 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 15, Height: 1, Diameter: 25 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 15, Height: 2, Diameter: 32 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 15, Height: 3, Diameter: 39 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 15, Height: 4, Diameter: 39.8 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 15, Height: 5, Diameter: 40.1 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 15, Height: 6, Diameter: 41 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 15, Height: 7, Diameter: 41.5 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 15, Height: 8, Diameter: 41.5 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 15, Height: 9, Diameter: 41.5 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 15, Height: 10, Diameter: 41.5 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 15, Height: 11, Diameter: 41.5 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 15, Height: 12, Diameter: 41.5 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 26 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 33 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 40.8 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 41.1 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 42 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 42.5 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 42.5 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 42.5 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 42.5 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 42.5 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 42.5 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 27 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 34 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 41 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 41.8 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 42.1 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 43 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 43.5 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 43.5 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 43.5 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 43.5 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 43.5 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 43.5 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 15, Height: 1, Diameter: 28 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 15, Height: 2, Diameter: 35 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 15, Height: 4, Diameter: 42.8 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 15, Height: 5, Diameter: 43.1 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 15, Height: 7, Diameter: 44.5 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 15, Height: 8, Diameter: 44.5 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 15, Height: 9, Diameter: 44.5 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 15, Height: 10, Diameter: 44.5 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 15, Height: 11, Diameter: 44.5 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 15, Height: 12, Diameter: 44.5 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 15, Height: 1, Diameter: 29 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 15, Height: 2, Diameter: 36 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 15, Height: 3, Diameter: 43 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 15, Height: 4, Diameter: 43.8 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 15, Height: 5, Diameter: 44.1 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 15, Height: 6, Diameter: 45 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 15, Height: 7, Diameter: 45.5 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 15, Height: 8, Diameter: 45.5 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 15, Height: 9, Diameter: 45.5 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 15, Height: 10, Diameter: 45.5 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 15, Height: 11, Diameter: 45.5 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 15, Height: 12, Diameter: 45.5 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 20, Height: 1, Diameter: 28 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 20, Height: 2, Diameter: 35 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 20, Height: 4, Diameter: 43.7 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 20, Height: 5, Diameter: 45.3 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 20, Height: 6, Diameter: 47 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 20, Height: 7, Diameter: 48 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 20, Height: 8, Diameter: 48 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 20, Height: 9, Diameter: 48 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 20, Height: 10, Diameter: 48 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 20, Height: 11, Diameter: 48 },
    { DevCode: 234, Nozzle1: 0.1, Pressure: 20, Height: 12, Diameter: 48 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 29 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 36 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 43 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 44.7 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 46.3 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 49 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 49 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 49 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 49 },
    { DevCode: 234, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 49 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 30 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 37 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 45.7 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 47.3 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 49 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 50 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 50 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 50 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 50 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 50 },
    { DevCode: 234, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 50 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 20, Height: 1, Diameter: 31 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 20, Height: 2, Diameter: 38 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 20, Height: 3, Diameter: 45 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 20, Height: 4, Diameter: 46.7 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 20, Height: 5, Diameter: 48.3 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 20, Height: 7, Diameter: 51 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 20, Height: 8, Diameter: 51 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 20, Height: 9, Diameter: 51 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 20, Height: 10, Diameter: 51 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 20, Height: 11, Diameter: 51 },
    { DevCode: 234, Nozzle1: 0.22, Pressure: 20, Height: 12, Diameter: 51 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 20, Height: 1, Diameter: 32 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 20, Height: 2, Diameter: 39 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 20, Height: 4, Diameter: 47.7 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 20, Height: 6, Diameter: 51 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 20, Height: 7, Diameter: 52 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 20, Height: 8, Diameter: 52 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 20, Height: 10, Diameter: 52 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 20, Height: 11, Diameter: 52 },
    { DevCode: 234, Nozzle1: 0.26, Pressure: 20, Height: 12, Diameter: 52 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 10, Height: 1, Diameter: 17 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 10, Height: 2, Diameter: 24 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 10, Height: 3, Diameter: 31 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 10, Height: 4, Diameter: 32 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 10, Height: 5, Diameter: 33 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 10, Height: 6, Diameter: 34 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 10, Height: 7, Diameter: 35 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 10, Height: 8, Diameter: 35 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 10, Height: 9, Diameter: 35 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 10, Height: 10, Diameter: 35 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 10, Height: 11, Diameter: 35 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 10, Height: 12, Diameter: 35 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 18 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 25 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 33 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 34 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 35 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 36 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 36 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 36 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 36 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 19 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 26 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 33 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 34 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 35 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 37 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 37 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 37 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 37 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 37 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 37 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 10, Height: 1, Diameter: 20 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 10, Height: 2, Diameter: 27 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 10, Height: 4, Diameter: 35 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 10, Height: 5, Diameter: 36 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 10, Height: 6, Diameter: 37 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 10, Height: 7, Diameter: 38 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 10, Height: 8, Diameter: 38 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 10, Height: 9, Diameter: 38 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 10, Height: 10, Diameter: 38 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 10, Height: 11, Diameter: 38 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 10, Height: 12, Diameter: 38 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 10, Height: 1, Diameter: 21 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 10, Height: 2, Diameter: 28 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 10, Height: 4, Diameter: 36 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 10, Height: 5, Diameter: 37 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 10, Height: 7, Diameter: 39 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 10, Height: 8, Diameter: 39 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 10, Height: 9, Diameter: 39 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 10, Height: 10, Diameter: 39 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 10, Height: 11, Diameter: 39 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 10, Height: 12, Diameter: 39 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 15, Height: 1, Diameter: 27.5 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 15, Height: 2, Diameter: 34.5 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 15, Height: 3, Diameter: 41.5 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 15, Height: 4, Diameter: 42.7 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 15, Height: 5, Diameter: 43.8 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 15, Height: 6, Diameter: 45 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 15, Height: 7, Diameter: 46 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 15, Height: 8, Diameter: 46 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 15, Height: 9, Diameter: 46 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 15, Height: 10, Diameter: 46 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 15, Height: 11, Diameter: 46 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 15, Height: 12, Diameter: 46 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 28.5 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 35.5 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 42.5 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 43.7 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 44.8 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 47 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 47 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 47 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 47 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 47 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 47 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 29.5 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 36.5 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 43.5 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 44.7 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 45.8 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 47 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 48 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 48 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 48 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 48 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 48 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 15, Height: 1, Diameter: 30.5 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 15, Height: 2, Diameter: 37.5 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 15, Height: 3, Diameter: 44.5 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 15, Height: 4, Diameter: 45.7 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 15, Height: 5, Diameter: 46.8 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 15, Height: 7, Diameter: 49 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 15, Height: 8, Diameter: 49 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 15, Height: 9, Diameter: 49 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 15, Height: 10, Diameter: 49 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 15, Height: 11, Diameter: 49 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 15, Height: 12, Diameter: 49 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 15, Height: 1, Diameter: 31.5 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 15, Height: 2, Diameter: 38.5 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 15, Height: 3, Diameter: 45.5 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 15, Height: 4, Diameter: 46.7 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 15, Height: 5, Diameter: 47.8 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 15, Height: 6, Diameter: 49 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 15, Height: 7, Diameter: 50 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 15, Height: 8, Diameter: 50 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 15, Height: 10, Diameter: 50 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 15, Height: 11, Diameter: 50 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 15, Height: 12, Diameter: 50 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 20, Height: 1, Diameter: 38 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 20, Height: 2, Diameter: 45 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 20, Height: 3, Diameter: 52 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 20, Height: 4, Diameter: 53.3 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 20, Height: 5, Diameter: 54.7 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 20, Height: 6, Diameter: 56 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 20, Height: 7, Diameter: 57 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 20, Height: 8, Diameter: 57 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 20, Height: 9, Diameter: 57 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 20, Height: 10, Diameter: 57 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 20, Height: 11, Diameter: 57 },
    { DevCode: 232, Nozzle1: 0.1, Pressure: 20, Height: 12, Diameter: 57 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 39 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 46 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 53 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 54.3 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 55.7 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 57 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 58 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 58 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 58 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 58 },
    { DevCode: 232, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 58 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 40 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 47 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 54 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 55.3 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 56.7 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 58 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 59 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 59 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 59 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 59 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 59 },
    { DevCode: 232, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 59 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 20, Height: 1, Diameter: 41 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 20, Height: 2, Diameter: 48 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 20, Height: 3, Diameter: 55 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 20, Height: 4, Diameter: 56.3 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 20, Height: 5, Diameter: 57.7 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 20, Height: 6, Diameter: 59 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 20, Height: 7, Diameter: 60 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 20, Height: 8, Diameter: 60 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 20, Height: 10, Diameter: 60 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 20, Height: 11, Diameter: 60 },
    { DevCode: 232, Nozzle1: 0.22, Pressure: 20, Height: 12, Diameter: 60 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 20, Height: 1, Diameter: 42 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 20, Height: 2, Diameter: 49 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 20, Height: 3, Diameter: 56 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 20, Height: 4, Diameter: 57.3 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 20, Height: 5, Diameter: 58.7 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 20, Height: 6, Diameter: 60 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 20, Height: 7, Diameter: 61 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 20, Height: 8, Diameter: 61 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 20, Height: 9, Diameter: 61 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 20, Height: 10, Diameter: 61 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 20, Height: 11, Diameter: 61 },
    { DevCode: 232, Nozzle1: 0.26, Pressure: 20, Height: 12, Diameter: 61 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 10, Height: 1, Diameter: 17 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 10, Height: 2, Diameter: 24 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 10, Height: 3, Diameter: 31 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 10, Height: 4, Diameter: 32.3 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 10, Height: 5, Diameter: 33.7 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 10, Height: 6, Diameter: 35 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 10, Height: 7, Diameter: 36 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 10, Height: 8, Diameter: 36 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 10, Height: 10, Diameter: 36 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 10, Height: 12, Diameter: 36 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 18 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 25 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 33.3 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 34.7 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 37 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 37 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 37 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 37 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 37 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 37 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 19 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 26 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 33 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 34.3 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 35.7 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 37 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 38 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 38 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 38 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 38 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 38 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 38 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 10, Height: 1, Diameter: 20 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 10, Height: 2, Diameter: 27 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 10, Height: 4, Diameter: 35.3 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 10, Height: 5, Diameter: 36.7 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 10, Height: 7, Diameter: 39 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 10, Height: 8, Diameter: 39 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 10, Height: 9, Diameter: 39 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 10, Height: 10, Diameter: 39 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 10, Height: 11, Diameter: 39 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 10, Height: 12, Diameter: 39 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 10, Height: 1, Diameter: 21 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 10, Height: 2, Diameter: 28 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 10, Height: 4, Diameter: 36.3 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 10, Height: 5, Diameter: 37.7 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 10, Height: 7, Diameter: 40 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 10, Height: 8, Diameter: 40 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 10, Height: 10, Diameter: 40 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 10, Height: 11, Diameter: 40 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 10, Height: 12, Diameter: 40 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 15, Height: 1, Diameter: 23.5 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 15, Height: 2, Diameter: 30.5 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 15, Height: 3, Diameter: 37.5 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 15, Height: 4, Diameter: 38.8 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 15, Height: 5, Diameter: 40.2 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 15, Height: 6, Diameter: 41.5 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 15, Height: 7, Diameter: 42.5 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 15, Height: 8, Diameter: 42.5 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 15, Height: 9, Diameter: 42.5 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 15, Height: 10, Diameter: 42.5 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 15, Height: 11, Diameter: 42.5 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 15, Height: 12, Diameter: 42.5 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 24.5 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 31.5 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 38.5 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 39.8 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 41.2 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 42.5 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 43.5 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 43.5 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 43.5 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 43.5 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 43.5 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 43.5 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 25.5 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 32.5 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 39.5 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 40.8 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 42.2 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 43.5 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 44.5 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 44.5 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 44.5 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 44.5 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 44.5 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 44.5 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 15, Height: 1, Diameter: 26.5 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 15, Height: 2, Diameter: 33.5 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 15, Height: 3, Diameter: 40.5 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 15, Height: 4, Diameter: 41.8 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 15, Height: 5, Diameter: 43.2 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 15, Height: 6, Diameter: 44.5 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 15, Height: 7, Diameter: 45.5 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 15, Height: 8, Diameter: 45.5 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 15, Height: 9, Diameter: 45.5 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 15, Height: 10, Diameter: 45.5 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 15, Height: 11, Diameter: 45.5 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 15, Height: 12, Diameter: 45.5 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 15, Height: 1, Diameter: 27.5 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 15, Height: 2, Diameter: 34.5 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 15, Height: 3, Diameter: 41.5 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 15, Height: 4, Diameter: 42.8 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 15, Height: 5, Diameter: 44.2 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 15, Height: 6, Diameter: 45.5 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 15, Height: 7, Diameter: 46.5 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 15, Height: 8, Diameter: 46.5 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 15, Height: 9, Diameter: 46.5 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 15, Height: 10, Diameter: 46.5 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 15, Height: 11, Diameter: 46.5 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 15, Height: 12, Diameter: 46.5 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 20, Height: 1, Diameter: 30 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 20, Height: 2, Diameter: 37 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 20, Height: 4, Diameter: 45.3 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 20, Height: 5, Diameter: 46.7 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 20, Height: 7, Diameter: 49 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 20, Height: 8, Diameter: 49 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 20, Height: 10, Diameter: 49 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 20, Height: 11, Diameter: 49 },
    { DevCode: 233, Nozzle1: 0.1, Pressure: 20, Height: 12, Diameter: 49 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 31 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 38 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 45 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 46.3 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 47.7 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 49 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 50 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 50 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 50 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 50 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 50 },
    { DevCode: 233, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 50 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 32 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 39 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 47.3 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 48.7 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 51 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 51 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 51 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 51 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 51 },
    { DevCode: 233, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 51 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 20, Height: 1, Diameter: 33 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 20, Height: 2, Diameter: 40 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 20, Height: 3, Diameter: 47 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 20, Height: 4, Diameter: 48.3 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 20, Height: 5, Diameter: 49.7 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 20, Height: 6, Diameter: 51 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 20, Height: 7, Diameter: 52 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 20, Height: 8, Diameter: 52 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 20, Height: 10, Diameter: 52 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 20, Height: 11, Diameter: 52 },
    { DevCode: 233, Nozzle1: 0.22, Pressure: 20, Height: 12, Diameter: 52 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 20, Height: 1, Diameter: 34 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 20, Height: 2, Diameter: 41 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 20, Height: 4, Diameter: 49.3 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 20, Height: 5, Diameter: 50.7 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 20, Height: 7, Diameter: 53 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 20, Height: 8, Diameter: 53 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 20, Height: 9, Diameter: 53 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 20, Height: 10, Diameter: 53 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 20, Height: 11, Diameter: 53 },
    { DevCode: 233, Nozzle1: 0.26, Pressure: 20, Height: 12, Diameter: 53 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 10, Height: 1, Diameter: 26 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 10, Height: 2, Diameter: 33 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 10, Height: 3, Diameter: 40 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 10, Height: 4, Diameter: 41.3 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 10, Height: 5, Diameter: 42.7 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 10, Height: 7, Diameter: 45 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 10, Height: 8, Diameter: 45 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 10, Height: 9, Diameter: 45 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 10, Height: 10, Diameter: 45 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 10, Height: 11, Diameter: 45 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 10, Height: 12, Diameter: 45 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 27 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 34 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 41 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 42.3 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 43.7 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 45 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 46 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 46 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 46 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 46 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 46 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 28 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 35 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 42 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 43.3 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 44.7 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 47 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 47 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 47 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 47 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 47 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 47 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 10, Height: 1, Diameter: 29 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 10, Height: 2, Diameter: 36 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 10, Height: 3, Diameter: 43 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 10, Height: 4, Diameter: 44.3 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 10, Height: 5, Diameter: 45.7 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 10, Height: 6, Diameter: 47 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 10, Height: 7, Diameter: 48 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 10, Height: 8, Diameter: 48 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 10, Height: 10, Diameter: 48 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 10, Height: 11, Diameter: 48 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 10, Height: 12, Diameter: 48 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 10, Height: 1, Diameter: 30 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 10, Height: 2, Diameter: 37 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 10, Height: 3, Diameter: 44 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 10, Height: 4, Diameter: 45.3 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 10, Height: 5, Diameter: 46.7 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 10, Height: 6, Diameter: 48 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 10, Height: 7, Diameter: 49 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 10, Height: 8, Diameter: 49 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 10, Height: 9, Diameter: 49 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 10, Height: 10, Diameter: 49 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 10, Height: 11, Diameter: 49 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 10, Height: 12, Diameter: 49 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 15, Height: 1, Diameter: 28.5 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 15, Height: 2, Diameter: 33.5 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 15, Height: 3, Diameter: 42.5 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 15, Height: 4, Diameter: 43.8 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 15, Height: 5, Diameter: 45.2 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 15, Height: 6, Diameter: 46.5 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 15, Height: 7, Diameter: 47.5 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 15, Height: 8, Diameter: 47.5 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 15, Height: 9, Diameter: 47.5 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 15, Height: 10, Diameter: 47.5 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 15, Height: 11, Diameter: 47.5 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 15, Height: 12, Diameter: 47.5 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 29.5 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 34.5 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 43.5 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 44.8 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 46.2 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 47.5 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 48.5 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 48.5 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 48.5 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 48.5 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 48.5 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 48.5 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 30.5 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 35.5 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 44.5 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 45.8 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 47.2 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 48.5 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 49.5 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 49.5 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 49.5 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 49.5 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 49.5 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 49.5 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 15, Height: 1, Diameter: 31.5 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 15, Height: 2, Diameter: 36.5 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 15, Height: 3, Diameter: 45.5 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 15, Height: 4, Diameter: 46.8 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 15, Height: 5, Diameter: 48.2 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 15, Height: 6, Diameter: 49.5 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 15, Height: 7, Diameter: 50.5 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 15, Height: 8, Diameter: 50.5 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 15, Height: 9, Diameter: 50.5 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 15, Height: 10, Diameter: 50.5 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 15, Height: 11, Diameter: 50.5 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 15, Height: 12, Diameter: 50.5 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 15, Height: 1, Diameter: 32.5 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 15, Height: 2, Diameter: 37.5 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 15, Height: 3, Diameter: 46.5 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 15, Height: 4, Diameter: 47.8 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 15, Height: 5, Diameter: 49.2 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 15, Height: 6, Diameter: 50.5 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 15, Height: 7, Diameter: 51.5 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 15, Height: 8, Diameter: 51.5 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 15, Height: 9, Diameter: 51.5 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 15, Height: 10, Diameter: 51.5 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 15, Height: 11, Diameter: 51.5 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 15, Height: 12, Diameter: 51.5 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 20, Height: 1, Diameter: 31 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 20, Height: 2, Diameter: 38 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 20, Height: 3, Diameter: 45 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 20, Height: 4, Diameter: 46.3 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 20, Height: 5, Diameter: 47.7 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 20, Height: 6, Diameter: 49 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 20, Height: 7, Diameter: 50 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 20, Height: 8, Diameter: 50 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 20, Height: 9, Diameter: 50 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 20, Height: 10, Diameter: 50 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 20, Height: 11, Diameter: 50 },
    { DevCode: 231, Nozzle1: 0.1, Pressure: 20, Height: 12, Diameter: 50 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 32 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 39 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 46 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 47.3 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 48.7 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 50 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 51 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 51 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 51 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 51 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 51 },
    { DevCode: 231, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 51 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 33 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 40 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 47 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 48.3 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 49.7 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 51 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 52 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 52 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 52 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 52 },
    { DevCode: 231, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 52 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 20, Height: 1, Diameter: 34 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 20, Height: 2, Diameter: 41 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 20, Height: 3, Diameter: 48 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 20, Height: 4, Diameter: 49.3 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 20, Height: 5, Diameter: 50.7 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 20, Height: 7, Diameter: 53 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 20, Height: 8, Diameter: 53 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 20, Height: 9, Diameter: 53 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 20, Height: 10, Diameter: 53 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 20, Height: 11, Diameter: 53 },
    { DevCode: 231, Nozzle1: 0.22, Pressure: 20, Height: 12, Diameter: 53 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 20, Height: 1, Diameter: 35 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 20, Height: 2, Diameter: 42 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 20, Height: 3, Diameter: 49 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 20, Height: 4, Diameter: 50.3 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 20, Height: 5, Diameter: 51.7 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 20, Height: 6, Diameter: 53 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 20, Height: 7, Diameter: 54 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 20, Height: 8, Diameter: 54 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 20, Height: 10, Diameter: 54 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 20, Height: 11, Diameter: 54 },
    { DevCode: 231, Nozzle1: 0.26, Pressure: 20, Height: 12, Diameter: 54 },
    { DevCode: 184, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 58 },
    { DevCode: 184, Nozzle1: 0.16, Pressure: 20, Height: 12, Diameter: 60 },
    { DevCode: 184, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 62 },
    { DevCode: 184, Nozzle1: 0.2, Pressure: 20, Height: 12, Diameter: 64 },
    { DevCode: 184, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 64 },
    { DevCode: 184, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 64 },
    { DevCode: 184, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 66 },
    { DevCode: 184, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 66 },
    { DevCode: 184, Nozzle1: 0.4, Pressure: 20, Height: 12, Diameter: 66 },
    { DevCode: 184, Nozzle1: 0.44, Pressure: 20, Height: 12, Diameter: 68 },
    { DevCode: 184, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 68 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 60 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 62 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 64 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 66 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 68 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 69.9 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 44 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 47 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 53.3 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 56.7 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 60 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 62 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 64 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 66 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 68 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 70 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 72 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 44 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 48 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 52 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 60 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 64 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 66 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 68 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 70 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 72 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 74 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 76 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 20, Height: 1, Diameter: 48 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 20, Height: 2, Diameter: 50 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 20, Height: 3, Diameter: 52 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 20, Height: 4, Diameter: 55.3 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 20, Height: 5, Diameter: 58.7 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 20, Height: 6, Diameter: 62 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 20, Height: 7, Diameter: 65.3 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 20, Height: 8, Diameter: 68.7 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 20, Height: 9, Diameter: 72 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 20, Height: 10, Diameter: 74.8 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 20, Height: 11, Diameter: 78.5 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 20, Height: 12, Diameter: 78.5 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 20, Height: 1, Diameter: 46 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 20, Height: 2, Diameter: 49 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 20, Height: 3, Diameter: 52 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 20, Height: 4, Diameter: 55.3 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 20, Height: 5, Diameter: 58.7 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 20, Height: 6, Diameter: 62 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 20, Height: 7, Diameter: 65.3 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 20, Height: 8, Diameter: 68.7 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 20, Height: 9, Diameter: 72 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 20, Height: 10, Diameter: 74.8 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 20, Height: 11, Diameter: 78.5 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 20, Height: 12, Diameter: 78.5 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 44 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 47 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 50 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 53.3 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 56.7 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 60 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 63.3 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 66.7 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 70 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 72.7 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 76.4 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 76.4 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 25, Height: 1, Diameter: 37 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 25, Height: 2, Diameter: 41 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 25, Height: 3, Diameter: 45 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 25, Height: 4, Diameter: 47.4 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 25, Height: 5, Diameter: 49.7 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 25, Height: 6, Diameter: 52 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 25, Height: 7, Diameter: 53.3 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 25, Height: 8, Diameter: 54.7 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 25, Height: 9, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 25, Height: 10, Diameter: 57.3 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 25, Height: 11, Diameter: 58.6 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 25, Height: 12, Diameter: 59.8 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 25, Height: 1, Diameter: 39 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 25, Height: 2, Diameter: 43 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 25, Height: 3, Diameter: 47 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 25, Height: 4, Diameter: 49.7 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 25, Height: 5, Diameter: 52.3 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 25, Height: 6, Diameter: 55 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 25, Height: 7, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 25, Height: 8, Diameter: 57 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 25, Height: 9, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 25, Height: 10, Diameter: 59 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 25, Height: 11, Diameter: 60 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 25, Height: 12, Diameter: 61 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 25, Height: 1, Diameter: 40 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 25, Height: 2, Diameter: 44.5 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 25, Height: 3, Diameter: 49 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 25, Height: 4, Diameter: 51.7 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 25, Height: 5, Diameter: 54.3 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 25, Height: 6, Diameter: 57 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 25, Height: 7, Diameter: 58.3 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 25, Height: 8, Diameter: 59.7 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 25, Height: 9, Diameter: 61 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 25, Height: 10, Diameter: 62.3 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 25, Height: 11, Diameter: 63.7 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 25, Height: 12, Diameter: 65 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 25, Height: 1, Diameter: 42 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 25, Height: 2, Diameter: 46 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 25, Height: 3, Diameter: 50 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 25, Height: 4, Diameter: 52.7 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 25, Height: 5, Diameter: 55.4 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 25, Height: 6, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 25, Height: 7, Diameter: 59.4 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 25, Height: 8, Diameter: 60.7 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 25, Height: 9, Diameter: 62 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 25, Height: 10, Diameter: 63.2 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 25, Height: 11, Diameter: 64.2 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 25, Height: 12, Diameter: 65.1 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 25, Height: 1, Diameter: 44 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 25, Height: 2, Diameter: 47.5 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 25, Height: 3, Diameter: 51 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 25, Height: 4, Diameter: 53.7 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 25, Height: 5, Diameter: 56.4 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 25, Height: 6, Diameter: 59 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 25, Height: 7, Diameter: 61 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 25, Height: 8, Diameter: 63 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 25, Height: 9, Diameter: 65 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 25, Height: 10, Diameter: 66.6 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 25, Height: 11, Diameter: 68 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 25, Height: 12, Diameter: 69.1 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 25, Height: 1, Diameter: 45 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 25, Height: 2, Diameter: 48 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 25, Height: 3, Diameter: 51 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 25, Height: 4, Diameter: 54 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 25, Height: 5, Diameter: 57 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 25, Height: 6, Diameter: 60 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 25, Height: 7, Diameter: 62.4 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 25, Height: 8, Diameter: 64.7 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 25, Height: 9, Diameter: 67 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 25, Height: 10, Diameter: 68.9 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 25, Height: 11, Diameter: 70.7 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 25, Height: 12, Diameter: 72.2 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 25, Height: 1, Diameter: 46 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 25, Height: 2, Diameter: 49.5 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 25, Height: 3, Diameter: 53 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 25, Height: 4, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 25, Height: 5, Diameter: 59 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 25, Height: 6, Diameter: 62 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 25, Height: 7, Diameter: 64.4 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 25, Height: 8, Diameter: 66.7 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 25, Height: 9, Diameter: 69 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 25, Height: 10, Diameter: 71 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 25, Height: 11, Diameter: 72.7 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 25, Height: 12, Diameter: 74.3 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 25, Height: 1, Diameter: 48 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 25, Height: 2, Diameter: 51.5 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 25, Height: 3, Diameter: 55 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 25, Height: 4, Diameter: 58.7 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 25, Height: 5, Diameter: 62.4 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 25, Height: 6, Diameter: 66 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 25, Height: 7, Diameter: 68 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 25, Height: 8, Diameter: 70 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 25, Height: 9, Diameter: 72 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 25, Height: 10, Diameter: 74 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 25, Height: 11, Diameter: 75.8 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 25, Height: 12, Diameter: 77.3 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 25, Height: 1, Diameter: 51 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 25, Height: 2, Diameter: 53 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 25, Height: 3, Diameter: 55 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 25, Height: 4, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 25, Height: 5, Diameter: 61 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 25, Height: 6, Diameter: 64 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 25, Height: 7, Diameter: 67.3 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 25, Height: 8, Diameter: 70.7 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 25, Height: 9, Diameter: 74 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 25, Height: 10, Diameter: 76.4 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 25, Height: 11, Diameter: 79.1 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 25, Height: 12, Diameter: 80.5 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 25, Height: 1, Diameter: 51 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 25, Height: 2, Diameter: 52.5 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 25, Height: 3, Diameter: 54 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 25, Height: 4, Diameter: 57.3 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 25, Height: 5, Diameter: 60.7 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 25, Height: 6, Diameter: 64 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 25, Height: 7, Diameter: 67.3 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 25, Height: 8, Diameter: 70.7 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 25, Height: 9, Diameter: 74 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 25, Height: 10, Diameter: 76.4 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 25, Height: 11, Diameter: 79.1 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 25, Height: 12, Diameter: 80.6 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 25, Height: 1, Diameter: 49 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 25, Height: 2, Diameter: 50.5 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 25, Height: 3, Diameter: 52 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 25, Height: 4, Diameter: 55.7 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 25, Height: 5, Diameter: 59.4 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 25, Height: 6, Diameter: 63 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 25, Height: 7, Diameter: 66.3 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 25, Height: 8, Diameter: 69.7 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 25, Height: 9, Diameter: 73 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 25, Height: 10, Diameter: 75.4 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 25, Height: 11, Diameter: 78 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 25, Height: 12, Diameter: 78.6 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 30, Height: 1, Diameter: 42 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 30, Height: 2, Diameter: 45 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 30, Height: 3, Diameter: 48 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 30, Height: 4, Diameter: 50 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 30, Height: 5, Diameter: 52 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 30, Height: 6, Diameter: 54 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 30, Height: 7, Diameter: 55.3 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 30, Height: 8, Diameter: 56.7 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 30, Height: 9, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 30, Height: 10, Diameter: 59.3 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 30, Height: 11, Diameter: 60.5 },
    { DevCode: 185, Nozzle1: 0.14, Pressure: 30, Height: 12, Diameter: 61.5 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 30, Height: 1, Diameter: 44 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 30, Height: 2, Diameter: 47 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 30, Height: 3, Diameter: 50 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 30, Height: 4, Diameter: 52.7 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 30, Height: 5, Diameter: 55.3 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 30, Height: 6, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 30, Height: 7, Diameter: 58.7 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 30, Height: 8, Diameter: 59.3 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 30, Height: 9, Diameter: 60 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 30, Height: 10, Diameter: 60.7 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 30, Height: 11, Diameter: 61.3 },
    { DevCode: 185, Nozzle1: 0.16, Pressure: 30, Height: 12, Diameter: 62 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 30, Height: 1, Diameter: 44 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 30, Height: 2, Diameter: 48 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 30, Height: 3, Diameter: 52 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 30, Height: 4, Diameter: 54.7 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 30, Height: 5, Diameter: 57.3 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 30, Height: 6, Diameter: 60 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 30, Height: 7, Diameter: 61.3 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 30, Height: 8, Diameter: 62.7 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 30, Height: 9, Diameter: 64 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 30, Height: 10, Diameter: 65.3 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 30, Height: 11, Diameter: 66.7 },
    { DevCode: 185, Nozzle1: 0.18, Pressure: 30, Height: 12, Diameter: 67.9 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 30, Height: 1, Diameter: 46 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 30, Height: 2, Diameter: 50 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 30, Height: 3, Diameter: 54 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 30, Height: 4, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 30, Height: 5, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 30, Height: 6, Diameter: 60 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 30, Height: 7, Diameter: 62 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 30, Height: 8, Diameter: 64 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 30, Height: 9, Diameter: 66 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 30, Height: 10, Diameter: 67.7 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 30, Height: 11, Diameter: 69.1 },
    { DevCode: 185, Nozzle1: 0.2, Pressure: 30, Height: 12, Diameter: 70.1 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 30, Height: 1, Diameter: 46 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 30, Height: 2, Diameter: 50 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 30, Height: 3, Diameter: 54 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 30, Height: 4, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 30, Height: 5, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 30, Height: 6, Diameter: 60 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 30, Height: 7, Diameter: 62.7 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 30, Height: 8, Diameter: 65.3 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 30, Height: 9, Diameter: 68 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 30, Height: 10, Diameter: 69.8 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 30, Height: 11, Diameter: 71.2 },
    { DevCode: 185, Nozzle1: 0.24, Pressure: 30, Height: 12, Diameter: 72.2 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 30, Height: 1, Diameter: 46 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 30, Height: 2, Diameter: 50 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 30, Height: 3, Diameter: 54 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 30, Height: 4, Diameter: 56.7 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 30, Height: 5, Diameter: 59.3 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 30, Height: 6, Diameter: 62 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 30, Height: 7, Diameter: 64.7 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 30, Height: 8, Diameter: 67.3 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 30, Height: 9, Diameter: 70 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 30, Height: 10, Diameter: 71.8 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 30, Height: 11, Diameter: 73.3 },
    { DevCode: 185, Nozzle1: 0.28, Pressure: 30, Height: 12, Diameter: 74.4 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 30, Height: 1, Diameter: 48 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 30, Height: 2, Diameter: 52 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 30, Height: 3, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 30, Height: 4, Diameter: 58.7 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 30, Height: 5, Diameter: 61.3 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 30, Height: 6, Diameter: 64 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 30, Height: 7, Diameter: 66.7 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 30, Height: 8, Diameter: 69.3 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 30, Height: 9, Diameter: 72 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 30, Height: 10, Diameter: 73.9 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 30, Height: 11, Diameter: 75.4 },
    { DevCode: 185, Nozzle1: 0.32, Pressure: 30, Height: 12, Diameter: 76.5 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 30, Height: 1, Diameter: 52 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 30, Height: 2, Diameter: 55 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 30, Height: 3, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 30, Height: 4, Diameter: 61.3 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 30, Height: 5, Diameter: 64.7 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 30, Height: 6, Diameter: 68 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 30, Height: 7, Diameter: 70 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 30, Height: 8, Diameter: 72 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 30, Height: 9, Diameter: 74 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 30, Height: 10, Diameter: 76 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 30, Height: 11, Diameter: 77.5 },
    { DevCode: 185, Nozzle1: 0.36, Pressure: 30, Height: 12, Diameter: 78.6 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 30, Height: 1, Diameter: 54 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 30, Height: 2, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 30, Height: 3, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 30, Height: 4, Diameter: 60.7 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 30, Height: 5, Diameter: 63.3 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 30, Height: 6, Diameter: 66 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 30, Height: 7, Diameter: 69.3 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 30, Height: 8, Diameter: 72.7 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 30, Height: 9, Diameter: 76 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 30, Height: 10, Diameter: 78 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 30, Height: 11, Diameter: 79.7 },
    { DevCode: 185, Nozzle1: 0.4, Pressure: 30, Height: 12, Diameter: 82.5 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 30, Height: 1, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 30, Height: 2, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 30, Height: 3, Diameter: 56 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 30, Height: 4, Diameter: 59.3 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 30, Height: 5, Diameter: 62.7 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 30, Height: 6, Diameter: 66 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 30, Height: 7, Diameter: 69.3 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 30, Height: 8, Diameter: 72.7 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 30, Height: 9, Diameter: 76 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 30, Height: 10, Diameter: 78 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 30, Height: 11, Diameter: 79.6 },
    { DevCode: 185, Nozzle1: 0.44, Pressure: 30, Height: 12, Diameter: 82.6 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 30, Height: 1, Diameter: 54 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 30, Height: 2, Diameter: 54 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 30, Height: 3, Diameter: 54 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 30, Height: 4, Diameter: 58 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 30, Height: 5, Diameter: 62 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 30, Height: 6, Diameter: 66 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 30, Height: 7, Diameter: 69.3 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 30, Height: 8, Diameter: 72.7 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 30, Height: 9, Diameter: 76 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 30, Height: 10, Diameter: 78 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 30, Height: 11, Diameter: 79.6 },
    { DevCode: 185, Nozzle1: 0.5, Pressure: 30, Height: 12, Diameter: 80.7 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 3.2 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 6.1 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 9 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 9.8 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 10.7 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 11.5 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 12.3 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 13.2 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 14 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 14 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 14 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 14 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 5.2 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 9.6 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 14 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 15 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 16 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 17 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 18 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 19 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 20 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 20 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 20 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 20 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 6.6 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 12.3 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 19.5 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 21 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 22.5 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 24 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 25.5 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 27 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 27 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 27 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 8.1 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 14.5 },
    { DevCode: 187, Nozzle1: 0.3125, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.3203125, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.328125, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.3359375, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.34375, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.3515625, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.359375, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.3671875, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.375, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.3828125, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.390625, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.09375, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 187, Nozzle1: 0.1015625, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 187, Nozzle1: 0.109375, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 187, Nozzle1: 0.1171875, Pressure: 10, Height: 6, Diameter: 41 },
    { DevCode: 187, Nozzle1: 0.125, Pressure: 10, Height: 6, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.1328125, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.140625, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 187, Nozzle1: 0.1484375, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 187, Nozzle1: 0.15625, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 187, Nozzle1: 0.1640625, Pressure: 10, Height: 6, Diameter: 47 },
    { DevCode: 187, Nozzle1: 0.171875, Pressure: 10, Height: 6, Diameter: 48 },
    { DevCode: 187, Nozzle1: 0.1796875, Pressure: 10, Height: 6, Diameter: 49 },
    { DevCode: 187, Nozzle1: 0.1875, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.1953125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.203125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2109375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.21875, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2265625, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.234375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2421875, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.25, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2578125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.265625, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2734375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.28125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2890625, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.296875, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.3046875, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.3125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.3203125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.328125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.3359375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.34375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.3515625, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.359375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.3671875, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.3828125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.390625, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.09375, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.1015625, Pressure: 10, Height: 9, Diameter: 38 },
    { DevCode: 187, Nozzle1: 0.109375, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 187, Nozzle1: 0.1171875, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 187, Nozzle1: 0.125, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 187, Nozzle1: 0.1328125, Pressure: 10, Height: 9, Diameter: 41 },
    { DevCode: 187, Nozzle1: 0.140625, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.1484375, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 187, Nozzle1: 0.15625, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.1640625, Pressure: 10, Height: 9, Diameter: 45 },
    { DevCode: 187, Nozzle1: 0.171875, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 187, Nozzle1: 0.1796875, Pressure: 10, Height: 9, Diameter: 47 },
    { DevCode: 187, Nozzle1: 0.1875, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 187, Nozzle1: 0.1953125, Pressure: 10, Height: 9, Diameter: 49 },
    { DevCode: 187, Nozzle1: 0.203125, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2109375, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.21875, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2265625, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.234375, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2421875, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.25, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2578125, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.265625, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2734375, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.28125, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2890625, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.296875, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.3046875, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.3125, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.3203125, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.328125, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.3359375, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.34375, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 187, Nozzle1: 0.3515625, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 187, Nozzle1: 0.359375, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 187, Nozzle1: 0.3671875, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 187, Nozzle1: 0.375, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 187, Nozzle1: 0.3828125, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 187, Nozzle1: 0.390625, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 187, Nozzle1: 0.09375, Pressure: 15, Height: 1, Diameter: 30 },
    { DevCode: 187, Nozzle1: 0.1015625, Pressure: 15, Height: 1, Diameter: 31 },
    { DevCode: 187, Nozzle1: 0.109375, Pressure: 15, Height: 1, Diameter: 32 },
    { DevCode: 187, Nozzle1: 0.1171875, Pressure: 15, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.125, Pressure: 15, Height: 1, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.1328125, Pressure: 15, Height: 1, Diameter: 37 },
    { DevCode: 187, Nozzle1: 0.140625, Pressure: 15, Height: 1, Diameter: 38 },
    { DevCode: 187, Nozzle1: 0.1484375, Pressure: 15, Height: 1, Diameter: 39 },
    { DevCode: 187, Nozzle1: 0.15625, Pressure: 15, Height: 1, Diameter: 40 },
    { DevCode: 187, Nozzle1: 0.1640625, Pressure: 15, Height: 1, Diameter: 40 },
    { DevCode: 187, Nozzle1: 0.171875, Pressure: 15, Height: 1, Diameter: 41 },
    { DevCode: 187, Nozzle1: 0.1796875, Pressure: 15, Height: 1, Diameter: 41 },
    { DevCode: 187, Nozzle1: 0.1875, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.1953125, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.203125, Pressure: 15, Height: 1, Diameter: 43 },
    { DevCode: 187, Nozzle1: 0.2109375, Pressure: 15, Height: 1, Diameter: 43 },
    { DevCode: 187, Nozzle1: 0.21875, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.2265625, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.234375, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.2421875, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.25, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.2578125, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.265625, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.2734375, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.28125, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.2890625, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.296875, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.3046875, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.3125, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.3203125, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.328125, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.3359375, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.34375, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.3515625, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.359375, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.3671875, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.375, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.3828125, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.390625, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.09375, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 187, Nozzle1: 0.1015625, Pressure: 15, Height: 3, Diameter: 41 },
    { DevCode: 187, Nozzle1: 0.109375, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 187, Nozzle1: 0.1171875, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.125, Pressure: 15, Height: 3, Diameter: 46 },
    { DevCode: 187, Nozzle1: 0.1328125, Pressure: 15, Height: 3, Diameter: 47 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 21 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 23.2 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 25.3 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 27.5 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 29.7 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 31.8 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 8.5 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 15.2 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 24 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 26 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 30 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 32 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 8.8 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 15.9 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 23 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 25 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 27 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 29 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 31 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 33 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 35 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 35 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 35 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 35 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 9.2 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 16.6 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 26.3 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 28.7 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 31 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 33.3 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 35.7 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 9.6 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 17.3 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 25 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 27.3 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 29.7 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 34.3 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 36.7 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 39 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 39 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 39 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 39 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 10 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 17.5 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 25 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 26.7 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 28.3 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 31.7 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 33.3 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 35 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 35 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 35 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 35 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 4.4 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 8.2 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 12 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 12.8 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 13.7 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 14.5 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 15.3 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 16.2 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 17 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 17.8 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 18.7 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 19.5 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 7.3 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 13.2 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 19 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 19.8 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 20.7 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 21.5 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 22.3 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 23.2 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 24 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 24.8 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 25.7 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 26.5 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 9.6 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 16.8 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 25.5 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 27 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 28.5 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 30 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 31.5 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 33 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 34.5 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 37.5 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 11.6 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 19.8 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 29.8 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 31.7 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 33.5 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 35.3 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 37.2 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 39 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 40.8 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 42.7 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 44.4 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 12.4 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 21.2 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 32 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 34 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 40 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 44 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 46 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 47.8 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 13.3 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 22.6 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 34 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 36 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 40 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 42 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 46 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 48 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 14.6 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 24.3 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 36.2 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 38.3 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 40.5 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 42.7 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 44.8 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 47 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 49.2 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 51.3 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 53.2 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 14.1 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 23.6 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 33 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 35.5 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 40.5 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 43 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 45.5 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 50.5 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 53 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 54.4 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 14.5 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 22.7 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 31 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 33.5 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 36 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 38.5 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 41 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 43.5 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 48.5 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 51 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 51.6 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 6.2 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 11.1 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 16 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 16.7 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 17.3 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 18 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 18.7 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 19.3 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 20 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 20.7 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 21.3 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 22 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 10 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 17 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 24 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 24.8 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 25.7 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 26.5 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 27.3 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 28.2 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 29 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 29.8 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 30.7 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 31.5 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 12 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 20 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 29.7 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 31.3 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 33 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 34.7 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 36.3 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 39.7 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 41.2 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 41.9 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 15 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 24.5 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 36 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 40 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 42 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 44 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 46 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 48 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 50 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 50.7 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 15.9 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 25.5 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 35 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 37.3 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 39.7 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 42 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 44.3 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 46.7 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 49 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 51.3 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 53.7 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 54 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 16.4 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 26.2 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 38.7 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 41.3 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 46.7 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 49.3 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 54.5 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 57.3 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 57.3 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 17.7 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 27.9 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 40.8 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 43.7 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 46.5 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 49.3 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 52.2 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 55 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 57.7 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 60.5 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 60.5 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 17.7 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 27.9 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 41 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 44 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 47 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 50 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 53 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 58.8 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 61.6 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 61.6 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 18.2 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 28.1 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 40.7 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 43.3 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 48.7 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 51.3 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 56.7 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 59.3 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 59.4 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 7.2 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 12.6 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 18 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 18.8 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 19.7 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 20.5 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 21.3 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 22.2 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 23 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 23.6 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 24.3 },
    { DevCode: 219, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 25 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 12 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 20 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 28 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 28.8 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 29.7 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 30.5 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 31.3 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 32.2 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 33 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 33.8 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 34.7 },
    { DevCode: 219, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 35.5 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 14.5 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 23.3 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 33.8 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 35.7 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 37.5 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 39.3 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 41.2 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 43 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 44.4 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 45.6 },
    { DevCode: 219, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 47.1 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 17.7 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 27.9 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 40 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 42 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 44 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 46 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 48 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 50 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 51.7 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 53 },
    { DevCode: 219, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 54.7 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 19.6 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 30.3 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 41 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 43 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 45 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 47 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 49 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 51 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 53 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 54.9 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 56.2 },
    { DevCode: 219, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 58 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 20.1 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 31 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 44.5 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 47 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 49.5 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 52 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 54.5 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 57 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 59 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 60.5 },
    { DevCode: 219, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 62.3 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 21.5 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 32.8 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 46.7 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 54.7 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 57.3 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 62.2 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 63.7 },
    { DevCode: 219, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 65.6 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 21.5 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 32.8 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 46.7 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 54.7 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 57.3 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 62.2 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 63.7 },
    { DevCode: 219, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 65.6 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 22 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 33 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 47 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 50 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 53 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 56 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 59 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 64.3 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 65.8 },
    { DevCode: 219, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 67.7 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 4 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 8 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 12 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 13.5 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 15 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 16.5 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 18 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 19.5 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 21 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 21 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 21 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 21 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 5.6 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 10.8 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 16 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 18 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 20 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 22 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 24 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 26 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 6.7 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 12.8 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 21.3 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 23.7 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 26 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 28.3 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 30.7 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 33 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 33 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 33 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 33 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 7.4 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 13.7 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 22.5 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 25 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 27.5 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 30 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 32.5 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 35 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 35 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 35 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 35 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 8.5 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 15.2 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 24.3 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 26.7 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 29 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 31.3 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 33.7 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 8.8 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 15.4 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 24.3 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 26.7 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 29 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 31.3 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 33.7 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 9.1 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 15.6 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 24.5 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 27 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 29.5 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 32 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 34.5 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 37 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 37 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 37 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 37 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 9.9 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 16.4 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 23 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 25.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 28 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 30.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 33 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 35.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 38 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 38 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 38 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 38 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 10.3 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 17.1 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 26.7 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 29.3 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 34.7 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 37.3 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 40 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 40 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 40 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 40 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 4.9 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 9.5 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 14 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 15.7 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 17.3 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 19 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 20.7 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 22.3 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 24 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 25.7 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 27.3 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 28 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 7 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 13.5 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 20 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 21.8 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 23.7 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 25.5 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 27.3 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 29.2 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 31 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 32.8 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 34.7 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 36.2 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 8.5 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 15.7 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 23 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 25.2 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 27.3 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 29.5 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 31.7 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 33.8 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 38.2 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 40.3 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 41.7 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 9.6 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 16.8 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 26.3 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 28.7 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 31 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 33.3 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 35.7 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 38 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 40.3 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 42.7 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 43.4 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 10.8 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 18.4 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 28.2 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 30.3 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 32.5 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 34.7 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 36.8 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 39 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 41.2 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 43.3 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 44.4 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 11.6 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 19.3 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 27 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 29 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 31 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 33 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 35 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 37 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 39 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 41 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 43 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 44.2 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 12 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 20 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 30.2 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 32.3 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 34.5 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 36.7 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 38.8 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 41 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 43.2 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 45.3 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 46.4 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 12.8 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 20.9 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 29 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 31.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 34 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 36.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 39 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 41.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 46.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 49 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 49.7 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 13.3 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 21.6 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 32.7 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 35.3 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 40.7 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 43.3 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 48.7 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 51.3 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 51.9 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 6 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 11.5 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 17 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 18.7 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 20.3 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 22 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 23.7 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 25.3 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 27 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 28 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 29.3 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 30 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 8.1 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 15.5 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 23 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 24.8 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 26.7 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 28.5 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 30.3 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 32.2 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 34 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 35.2 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 36.9 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 37.7 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 8.8 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 16.4 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 24 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 26.3 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 28.7 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 31 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 33.3 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 35.7 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 39.5 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 41.2 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 42.1 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 10.8 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 18.4 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 26 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 28.5 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 31 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 33.5 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 38.5 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 41 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 42.8 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 44.5 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 45.3 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 11.6 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 19.3 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 27 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 29.3 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 31.7 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 36.3 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 38.7 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 41 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 42.9 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 44.5 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 45.2 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 12 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 20 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 30.5 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 33 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 35.5 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 38 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 40.5 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 45 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 46.6 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 47.5 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 14.1 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 22.5 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 31 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 33.3 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 35.7 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 40.3 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 42.7 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 45 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 47.2 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 49.6 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 49.6 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 14.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 23.3 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 34.8 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 37.7 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 40.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 43.3 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 46.2 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 49 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 50.25 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 54 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 54 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 14.5 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 23.3 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 35.2 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 38.3 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 41.5 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 44.7 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 47.8 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 51 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 53.5 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 56.2 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 56.2 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 6.7 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 12.8 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 19 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 20.7 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 22.3 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 24 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 25.7 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 27.3 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 29 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 29.6 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 30.6 },
    { DevCode: 221, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 31.5 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 9.2 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 17.1 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 25 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 26.8 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 28.7 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 30.5 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 32.3 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 34.2 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 36.9 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 38 },
    { DevCode: 221, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 39.1 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 10 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 18 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 26 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 27.8 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 29.7 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 31.5 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 33.3 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 35.2 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 37 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 37.9 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 39.1 },
    { DevCode: 221, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 40.2 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 11.6 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 19.8 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 28 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 30.5 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 33 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 35.5 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 38 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 40.5 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 43 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 44.2 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 45.5 },
    { DevCode: 221, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 47.1 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 12.8 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 20.9 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 29 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 31.5 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 34 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 36.5 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 39 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 41.5 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 44 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 45.4 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 46.6 },
    { DevCode: 221, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 48.2 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 13.7 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 22.3 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 31 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 33.5 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 38.5 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 41 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 43.5 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 46 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 47.4 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 48.7 },
    { DevCode: 221, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 50.4 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 15 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 24 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 33 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 35.7 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 38.3 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 41 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 43.7 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 46.3 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 50.6 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 51.9 },
    { DevCode: 221, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 53.6 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 15.9 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 25.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 35 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 37.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 40 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 42.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 45 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 47.5 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 50 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 51.6 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 53 },
    { DevCode: 221, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 54.7 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 16.8 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 26.4 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 36 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 39 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 42 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 48 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 51 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 55.8 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 57.2 },
    { DevCode: 221, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 59.1 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 3.3 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 6.7 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 10 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 11.5 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 13 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 14.5 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 16 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 17.5 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 19 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 19 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 19 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 19 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 5 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 10 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 15 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 17.2 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 19.3 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 21.5 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 23.7 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 25.8 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 5.7 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 11.3 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 17 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 19.5 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 22 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 24.5 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 27 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 29.5 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 32 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 32 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 32 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 6.3 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 12.7 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 22 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 25 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 31 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 34 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 37 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 37 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 37 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 37 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 6.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 13.3 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 23.3 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 26.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 33.3 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 36.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 40 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 40 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 40 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 40 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 14 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 21 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 24.5 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 28 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 31.5 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 35 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 38.5 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 42 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 42 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 42 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 42 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 14 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 21 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 24.7 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 28.3 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 35.7 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 39.3 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 43 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 43 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 43 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 43 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 7.3 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 14.7 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 26 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 30 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 34 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 38 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 42 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 46 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 46 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 46 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 46 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 7.3 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 14.7 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 26.2 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 30.3 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 34.5 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 38.7 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 42.8 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 47 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 47 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 47 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 47 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 4.3 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 8.7 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 13 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 14.3 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 15.7 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 17 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 18.3 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 19.7 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 21 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 22.3 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 23.7 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 24.8 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 6 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 12 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 18 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 20.3 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 22.7 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 25 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 27.3 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 29.7 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 34.3 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 36.7 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 37.8 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 7.3 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 14.7 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 22 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 24.5 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 27 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 29.5 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 32 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 34.5 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 37 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 39.5 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 42 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 43.7 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 8.7 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 17.3 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 28.7 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 31.3 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 34 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 36.7 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 39.3 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 44.7 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 47.3 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 49.6 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 8.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 17.3 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 29.2 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 32.3 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 35.5 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 38.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 41.8 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 45 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 48.2 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 51.3 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 53.2 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 8.7 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 17.3 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 29.5 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 33 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 36.5 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 40 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 43.5 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 47 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 50.5 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 54 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 55.5 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 9.3 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 18.7 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 31.5 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 35 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 38.5 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 42 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 45.5 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 49 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 52.5 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 56 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 57.9 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 9.7 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 19.3 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 29 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 32.7 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 36.3 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 43.7 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 47.3 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 51 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 54.7 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 58.3 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 60.2 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 10 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 20 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 33.7 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 37.3 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 41 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 44.7 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 48.3 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 55.7 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 59.3 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 60.25 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 4.7 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 9.3 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 14 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 16 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 18 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 20 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 22 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 24 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 26 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 26.9 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 28.2 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 28.9 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 6.3 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 12.7 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 19 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 22 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 25 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 28 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 31 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 34 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 37 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 38.3 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 40.1 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 41.1 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 7.7 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 15.3 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 23 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 26.2 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 29.3 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 32.5 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 35.7 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 38.8 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 42 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 43.4 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 45.5 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 46.7 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 9 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 18 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 27 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 30.2 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 33.3 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 36.5 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 39.7 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 42.8 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 46 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 47.6 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 49.9 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 51.2 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 9.3 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 18.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 31.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 35.3 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 42.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 46.3 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 51.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 54.2 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 55.6 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 10 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 20 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 30 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 33.7 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 37.3 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 41 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 44.7 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 48.3 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 53.8 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 56.4 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 57.8 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 10.3 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 20.7 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 31 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 34.8 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 38.7 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 42.5 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 46.3 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 50.2 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 55.9 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 58.6 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 60 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 10.7 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 21.3 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 35.8 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 39.7 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 43.5 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 47.3 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 51.2 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 55 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 56.9 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 59.6 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 61.2 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 11.3 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 22.7 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 37.7 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 41.3 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 45 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 48.7 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 52.3 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 57.9 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 60.7 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 62.3 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 5.7 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 11.3 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 17 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 19 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 21 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 23 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 25 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 27 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 29 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 29.6 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 30.5 },
    { DevCode: 218, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 31.5 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 7.3 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 14.7 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 22 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 25 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 28 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 31 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 34 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 37 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 40 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 40.8 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 42.1 },
    { DevCode: 218, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 43.4 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 8.7 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 17.3 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 26 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 29 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 32 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 35 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 38 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 41 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 44 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 44.9 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 46.3 },
    { DevCode: 218, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 47.8 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 10 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 20 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 30 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 33.2 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 36.3 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 39.5 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 42.7 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 45.8 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 50 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 51.6 },
    { DevCode: 218, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 53.2 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 10.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 21.3 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 35.3 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 38.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 42 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 45.3 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 48.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 53 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 54.7 },
    { DevCode: 218, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 56.5 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 11.3 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 22.7 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 34 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 37.7 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 41.3 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 48.7 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 52.3 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 56 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 57.1 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 58.9 },
    { DevCode: 218, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 60.8 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 11.7 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 23.3 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 35 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 38.7 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 42.3 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 46 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 49.7 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 53.3 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 57 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 58.1 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 60 },
    { DevCode: 218, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 61.9 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 12 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 24 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 36 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 39.7 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 43.3 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 47 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 50.7 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 54.3 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 58 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 59.2 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 61 },
    { DevCode: 218, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 63 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 12.7 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 25.3 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 41.7 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 45.3 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 49 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 52.7 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 56.3 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 61.2 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 63.1 },
    { DevCode: 218, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 65.2 },
    { DevCode: 102, Nozzle1: 0.125, Pressure: 30, Height: 12, Diameter: 80 },
    { DevCode: 102, Nozzle1: 0.1875, Pressure: 30, Height: 12, Diameter: 84 },
    { DevCode: 102, Nozzle1: 0.25, Pressure: 30, Height: 12, Diameter: 84 },
    { DevCode: 102, Nozzle1: 0.296875, Pressure: 30, Height: 12, Diameter: 82 },
    { DevCode: 102, Nozzle1: 0.125, Pressure: 40, Height: 12, Diameter: 82 },
    { DevCode: 102, Nozzle1: 0.1875, Pressure: 40, Height: 12, Diameter: 88 },
    { DevCode: 102, Nozzle1: 0.25, Pressure: 40, Height: 12, Diameter: 90 },
    { DevCode: 102, Nozzle1: 0.296875, Pressure: 40, Height: 12, Diameter: 88 },
    { DevCode: 102, Nozzle1: 0.125, Pressure: 50, Height: 12, Diameter: 86 },
    { DevCode: 102, Nozzle1: 0.1875, Pressure: 50, Height: 12, Diameter: 92 },
    { DevCode: 102, Nozzle1: 0.25, Pressure: 50, Height: 12, Diameter: 96 },
    { DevCode: 102, Nozzle1: 0.296875, Pressure: 50, Height: 12, Diameter: 94 },
    { DevCode: 102, Nozzle1: 0.125, Pressure: 80, Height: 12, Diameter: 88 },
    { DevCode: 102, Nozzle1: 0.1875, Pressure: 80, Height: 12, Diameter: 94 },
    { DevCode: 102, Nozzle1: 0.25, Pressure: 80, Height: 12, Diameter: 102 },
    { DevCode: 102, Nozzle1: 0.296875, Pressure: 80, Height: 12, Diameter: 100 },
    { DevCode: 107, Nozzle1: 0.1875, Pressure: 30, Height: 12, Diameter: 78 },
    { DevCode: 107, Nozzle1: 0.25, Pressure: 30, Height: 12, Diameter: 74 },
    { DevCode: 107, Nozzle1: 0.296875, Pressure: 30, Height: 12, Diameter: 72 },
    { DevCode: 107, Nozzle1: 0.1875, Pressure: 40, Height: 12, Diameter: 82 },
    { DevCode: 107, Nozzle1: 0.25, Pressure: 40, Height: 12, Diameter: 78 },
    { DevCode: 107, Nozzle1: 0.296875, Pressure: 40, Height: 12, Diameter: 76 },
    { DevCode: 107, Nozzle1: 0.1875, Pressure: 50, Height: 12, Diameter: 86 },
    { DevCode: 107, Nozzle1: 0.25, Pressure: 50, Height: 12, Diameter: 82 },
    { DevCode: 107, Nozzle1: 0.296875, Pressure: 50, Height: 12, Diameter: 80 },
    { DevCode: 107, Nozzle1: 0.1875, Pressure: 80, Height: 12, Diameter: 86 },
    { DevCode: 107, Nozzle1: 0.25, Pressure: 80, Height: 12, Diameter: 84 },
    { DevCode: 107, Nozzle1: 0.296875, Pressure: 80, Height: 12, Diameter: 82 },
    { DevCode: 108, Nozzle1: 0.03, Pressure: 30, Height: 12, Diameter: 80 },
    { DevCode: 108, Nozzle1: 0.05, Pressure: 30, Height: 12, Diameter: 82 },
    { DevCode: 108, Nozzle1: 0.08, Pressure: 30, Height: 12, Diameter: 82 },
    { DevCode: 108, Nozzle1: 0.1, Pressure: 30, Height: 12, Diameter: 82 },
    { DevCode: 108, Nozzle1: 0.03, Pressure: 40, Height: 12, Diameter: 82 },
    { DevCode: 108, Nozzle1: 0.05, Pressure: 40, Height: 12, Diameter: 84 },
    { DevCode: 108, Nozzle1: 0.08, Pressure: 40, Height: 12, Diameter: 86 },
    { DevCode: 108, Nozzle1: 0.1, Pressure: 40, Height: 12, Diameter: 86 },
    { DevCode: 108, Nozzle1: 0.03, Pressure: 50, Height: 12, Diameter: 86 },
    { DevCode: 108, Nozzle1: 0.05, Pressure: 50, Height: 12, Diameter: 88 },
    { DevCode: 108, Nozzle1: 0.08, Pressure: 50, Height: 12, Diameter: 90 },
    { DevCode: 108, Nozzle1: 0.1, Pressure: 50, Height: 12, Diameter: 90 },
    { DevCode: 108, Nozzle1: 0.03, Pressure: 80, Height: 12, Diameter: 88 },
    { DevCode: 108, Nozzle1: 0.05, Pressure: 80, Height: 12, Diameter: 90 },
    { DevCode: 108, Nozzle1: 0.08, Pressure: 80, Height: 12, Diameter: 92 },
    { DevCode: 108, Nozzle1: 0.1, Pressure: 80, Height: 12, Diameter: 94 },
    { DevCode: 109, Nozzle1: 0.05, Pressure: 30, Height: 12, Diameter: 80 },
    { DevCode: 109, Nozzle1: 0.08, Pressure: 30, Height: 12, Diameter: 76 },
    { DevCode: 109, Nozzle1: 0.1, Pressure: 30, Height: 12, Diameter: 76 },
    { DevCode: 109, Nozzle1: 0.05, Pressure: 40, Height: 12, Diameter: 82 },
    { DevCode: 109, Nozzle1: 0.08, Pressure: 40, Height: 12, Diameter: 78 },
    { DevCode: 109, Nozzle1: 0.1, Pressure: 40, Height: 12, Diameter: 80 },
    { DevCode: 109, Nozzle1: 0.05, Pressure: 50, Height: 12, Diameter: 84 },
    { DevCode: 109, Nozzle1: 0.08, Pressure: 50, Height: 12, Diameter: 82 },
    { DevCode: 109, Nozzle1: 0.1, Pressure: 50, Height: 12, Diameter: 82 },
    { DevCode: 109, Nozzle1: 0.05, Pressure: 80, Height: 12, Diameter: 86 },
    { DevCode: 109, Nozzle1: 0.08, Pressure: 80, Height: 12, Diameter: 84 },
    { DevCode: 109, Nozzle1: 0.1, Pressure: 80, Height: 12, Diameter: 86 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 5 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 9 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 13 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 14 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 15 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 16 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 17 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 5 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 10 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 16 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 17 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 19 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 20 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 21 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 22 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 22 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 22 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 22 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 6 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 12 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 19.3 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 20.7 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 22 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 23.3 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 24.7 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 13 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 21.3 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 22.7 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 25.3 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 26.7 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 13 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 21.3 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 22.7 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 25.3 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 26.7 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 13 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 21.3 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 22.7 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 25.3 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 26.7 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 7 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 13 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 20 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 21.7 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 23.3 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 25 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 26.7 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 28.3 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 8 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 15 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 23 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 24 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 25 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 27 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 8 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 15 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 23 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 24 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 25 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 27 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 6 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 11 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 16 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 17 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 19 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 20 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 21 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 23 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 24 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 25 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 6 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 12 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 19.7 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 21.3 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 23 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 24.7 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 26.3 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 29 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 31 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 7 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 14 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 21 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 22.5 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 24 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 25.5 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 27 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 28.5 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 31.5 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 33 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 34 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 7 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 14 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 22 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 23.8 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 25.7 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 27.5 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 29.3 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 31.2 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 33 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 34.5 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 37 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 7 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 14 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 22 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 23.6 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 25.3 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 27 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 28.6 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 30.3 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 33.5 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 35 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 8 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 16 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 25.3 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 26.7 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 29.3 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 30.7 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 33.5 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 35 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 8 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 16 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 25.3 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 26.7 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 29.3 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 30.7 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 33.5 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 35 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 9 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 27 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 28.2 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 29.3 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 30.5 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 31.7 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 32.8 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 34 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 35 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 37 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 9 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 29 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 31 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 32 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 33 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 34 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 35 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 37 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 6 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 11 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 17 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 18.3 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 19.7 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 21 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 22.3 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 23.7 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 25 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 26.5 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 27 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 8 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 15 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 23 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 24.5 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 27.5 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 29 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 30.5 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 32 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 33 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 34 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 35 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 8 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 16 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 24 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 25.8 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 27.6 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 29.5 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 31.3 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 33.1 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 35 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 36.5 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 38 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 39 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 8 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 16 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 24 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 32 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 34 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 37.5 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 39 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 40 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 9 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 17 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 27.7 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 29.3 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 31 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 32.7 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 34.3 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 37.5 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 39 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 40 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 9 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 27.7 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 29.3 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 31 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 32.7 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 34.3 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 37.5 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 39 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 40 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 9 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 29.7 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 31.3 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 33 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 34.7 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 36.3 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 39.5 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 41 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 42 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 9 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 29.7 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 31.3 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 33 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 34.7 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 36.3 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 39.5 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 41 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 42 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 10 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 21 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 33.3 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 34.7 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 37.3 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 38.7 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 40 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 41.5 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 43 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 44 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 6 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 12 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 19.5 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 21 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 22.5 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 24 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 25.5 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 27 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 28.5 },
    { DevCode: 222, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 29 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 8 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 15 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 23 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 25.2 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 27.3 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 29.5 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 31.7 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 33.8 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 37.5 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 39 },
    { DevCode: 222, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 40 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 9 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 17 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 32 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 34 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 38 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 39.5 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 41 },
    { DevCode: 222, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 42 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 9 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 17 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 26 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 28.3 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 30.7 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 33 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 35.3 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 37.7 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 40 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 41.5 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 43 },
    { DevCode: 222, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 44 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 9 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 32 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 34 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 38 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 40 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 41.5 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 43 },
    { DevCode: 222, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 44 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 9 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 18 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 28 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 32 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 34 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 38 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 40 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 41.5 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 43 },
    { DevCode: 222, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 44 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 10 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 19 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 29 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 31.2 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 33.3 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 35.5 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 37.7 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 39.8 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 43.5 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 45 },
    { DevCode: 222, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 46 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 10 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 20 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 32 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 34 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 36 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 38 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 40 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 43.5 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 45 },
    { DevCode: 222, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 46 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 10 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 20 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 30 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 32.3 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 34.7 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 39.3 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 41.7 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 44 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 45.5 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 47 },
    { DevCode: 222, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 48 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 8 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 10 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 12 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 16 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 20 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 24 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 14 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 14 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 21 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 26 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 10 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 13 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 16 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 18 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 15 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 20 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 26 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 29 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 17 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 23 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 29 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 33 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 18 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 25 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 31 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 35 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 33 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 37 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 10 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 14 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 18 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 22 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 22 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 28 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 33 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 21 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 23 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 36 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 41 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 25 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 44 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 11 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 15 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 20 },
    { DevCode: 293, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 24 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 24 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 31 },
    { DevCode: 293, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 36 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 20 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 29 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 293, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 24 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 40 },
    { DevCode: 293, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 45 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 26 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 35 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 42 },
    { DevCode: 293, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 48 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 9 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 11 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 31 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 22 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 23 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 27 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 33 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 12 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 15 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 18 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 27 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 32 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 18 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 22 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 26 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 25 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 30 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 32 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 41 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 45 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 35 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 39 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 20 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 24 },
    { DevCode: 292, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 28 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 27 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 292, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 41 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 33 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 292, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 47 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 35 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 39 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 292, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 38 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 43 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 292, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 9 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 11 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 22 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 23 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 27 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 12 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 15 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 18 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 27 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 32 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 18 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 22 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 26 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 25 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 30 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 32 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 41 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 45 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 35 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 39 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 20 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 24 },
    { DevCode: 261, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 28 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 27 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 261, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 41 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 33 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 262, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 47 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 35 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 39 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 262, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 38 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 43 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 262, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 30 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 30 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 33 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 263, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 47 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 33 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 264, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 47 },
    { DevCode: 8, Nozzle1: 0.1, Pressure: 10, Height: 12, Diameter: 45 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 8 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 10 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 12 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 16 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 20 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 24 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 14 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 14 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 21 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 26 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 10 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 13 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 16 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 18 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 15 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 20 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 26 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 29 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 17 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 23 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 29 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 33 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 18 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 25 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 31 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 35 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 33 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 37 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 10 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 14 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 18 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 22 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 22 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 28 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 33 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 21 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 23 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 36 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 41 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 25 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 44 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 11 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 15 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 20 },
    { DevCode: 295, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 24 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 24 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 31 },
    { DevCode: 295, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 36 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 20 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 29 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 295, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 24 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 40 },
    { DevCode: 295, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 45 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 26 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 35 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 42 },
    { DevCode: 295, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 48 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 9 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 11 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 31 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 22 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 23 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 27 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 33 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 12 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 15 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 18 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 27 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 32 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 18 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 22 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 26 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 25 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 30 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 32 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 41 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 45 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 35 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 39 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 20 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 24 },
    { DevCode: 297, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 28 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 27 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 297, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 41 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 33 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 298, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 47 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 35 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 39 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 298, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 38 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 43 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 298, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 9 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 11 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 22 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 23 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 27 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 12 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 15 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 18 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 27 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 32 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 18 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 22 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 26 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 25 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 30 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 32 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 41 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 45 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 35 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 39 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 20 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 24 },
    { DevCode: 265, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 28 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 27 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 265, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 41 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 33 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 266, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 47 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 35 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 39 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 266, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 38 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 43 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 266, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 30 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 30 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 33 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 267, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 47 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 33 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 268, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 47 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 6, Height: 1, Diameter: 3.2 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 6, Height: 2, Diameter: 6.1 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 6, Height: 3, Diameter: 9 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 6, Height: 4, Diameter: 9.8 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 6, Height: 5, Diameter: 10.7 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 6, Height: 6, Diameter: 11.5 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 6, Height: 7, Diameter: 12.3 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 6, Height: 8, Diameter: 13.2 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 6, Height: 9, Diameter: 14 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 6, Height: 10, Diameter: 14 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 6, Height: 11, Diameter: 14 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 6, Height: 12, Diameter: 14 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 6, Height: 1, Diameter: 5.2 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 6, Height: 2, Diameter: 9.6 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 6, Height: 3, Diameter: 14 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 6, Height: 4, Diameter: 15 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 6, Height: 5, Diameter: 16 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 6, Height: 6, Diameter: 17 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 6, Height: 7, Diameter: 18 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 6, Height: 8, Diameter: 19 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 6, Height: 9, Diameter: 20 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 6, Height: 10, Diameter: 20 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 6, Height: 11, Diameter: 20 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 6, Height: 12, Diameter: 20 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 6, Height: 1, Diameter: 6.6 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 6, Height: 2, Diameter: 12.3 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 6, Height: 4, Diameter: 19.5 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 6, Height: 5, Diameter: 21 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 6, Height: 6, Diameter: 22.5 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 6, Height: 7, Diameter: 24 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 6, Height: 8, Diameter: 25.5 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 6, Height: 10, Diameter: 27 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 6, Height: 11, Diameter: 27 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 6, Height: 12, Diameter: 27 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 6, Height: 1, Diameter: 8.1 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 6, Height: 2, Diameter: 14.5 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 21 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 6, Height: 4, Diameter: 23.2 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 6, Height: 5, Diameter: 25.3 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 27.5 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 6, Height: 7, Diameter: 29.7 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 6, Height: 8, Diameter: 31.8 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 6, Height: 1, Diameter: 8.5 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 6, Height: 2, Diameter: 15.2 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 6, Height: 3, Diameter: 22 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 6, Height: 4, Diameter: 24 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 6, Height: 5, Diameter: 26 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 6, Height: 7, Diameter: 30 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 6, Height: 8, Diameter: 32 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 6, Height: 10, Diameter: 34 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 6, Height: 11, Diameter: 34 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 6, Height: 12, Diameter: 34 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 6, Height: 1, Diameter: 8.8 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 6, Height: 2, Diameter: 15.9 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 6, Height: 3, Diameter: 23 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 6, Height: 4, Diameter: 25 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 6, Height: 5, Diameter: 27 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 6, Height: 6, Diameter: 29 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 6, Height: 7, Diameter: 31 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 6, Height: 8, Diameter: 33 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 6, Height: 9, Diameter: 35 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 6, Height: 10, Diameter: 35 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 6, Height: 11, Diameter: 35 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 6, Height: 12, Diameter: 35 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 6, Height: 1, Diameter: 9.2 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 6, Height: 2, Diameter: 16.6 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 6, Height: 4, Diameter: 26.3 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 6, Height: 5, Diameter: 28.7 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 6, Height: 6, Diameter: 31 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 6, Height: 7, Diameter: 33.3 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 6, Height: 8, Diameter: 35.7 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 6, Height: 9, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 6, Height: 10, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 6, Height: 11, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 6, Height: 12, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 6, Height: 1, Diameter: 9.6 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 6, Height: 2, Diameter: 17.3 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 6, Height: 3, Diameter: 25 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 6, Height: 4, Diameter: 27.3 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 6, Height: 5, Diameter: 29.7 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 6, Height: 7, Diameter: 34.3 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 6, Height: 8, Diameter: 36.7 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 6, Height: 9, Diameter: 39 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 6, Height: 10, Diameter: 39 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 6, Height: 11, Diameter: 39 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 6, Height: 12, Diameter: 39 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 6, Height: 1, Diameter: 10 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 6, Height: 2, Diameter: 17.5 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 6, Height: 3, Diameter: 25 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 6, Height: 4, Diameter: 26.7 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 6, Height: 5, Diameter: 28.3 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 6, Height: 7, Diameter: 31.7 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 6, Height: 8, Diameter: 33.3 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 6, Height: 9, Diameter: 35 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 6, Height: 10, Diameter: 35 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 6, Height: 11, Diameter: 35 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 6, Height: 12, Diameter: 35 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 10, Height: 1, Diameter: 4.4 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 10, Height: 2, Diameter: 8.2 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 10, Height: 3, Diameter: 12 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 10, Height: 4, Diameter: 12.8 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 10, Height: 5, Diameter: 13.7 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 10, Height: 6, Diameter: 14.5 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 10, Height: 7, Diameter: 15.3 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 10, Height: 8, Diameter: 16.2 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 10, Height: 9, Diameter: 17 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 10, Height: 10, Diameter: 17.8 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 10, Height: 11, Diameter: 18.7 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 10, Height: 12, Diameter: 19.5 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 10, Height: 1, Diameter: 7.3 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 10, Height: 2, Diameter: 13.2 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 10, Height: 3, Diameter: 19 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 10, Height: 4, Diameter: 19.8 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 10, Height: 5, Diameter: 20.7 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 10, Height: 6, Diameter: 21.5 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 10, Height: 7, Diameter: 22.3 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 10, Height: 8, Diameter: 23.2 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 10, Height: 9, Diameter: 24 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 10, Height: 10, Diameter: 24.8 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 10, Height: 11, Diameter: 25.7 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 10, Height: 12, Diameter: 26.5 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 10, Height: 1, Diameter: 9.6 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 10, Height: 2, Diameter: 16.8 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 10, Height: 4, Diameter: 25.5 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 10, Height: 5, Diameter: 27 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 10, Height: 6, Diameter: 28.5 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 10, Height: 7, Diameter: 30 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 10, Height: 8, Diameter: 31.5 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 10, Height: 9, Diameter: 33 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 10, Height: 10, Diameter: 34.5 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 10, Height: 11, Diameter: 36 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 10, Height: 12, Diameter: 37.5 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 10, Height: 1, Diameter: 11.6 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 10, Height: 2, Diameter: 19.8 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 10, Height: 4, Diameter: 29.8 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 10, Height: 5, Diameter: 31.7 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 33.5 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 10, Height: 7, Diameter: 35.3 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 10, Height: 8, Diameter: 37.2 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 39 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 10, Height: 10, Diameter: 40.8 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 10, Height: 11, Diameter: 42.7 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 10, Height: 12, Diameter: 44.4 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 10, Height: 1, Diameter: 12.4 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 10, Height: 2, Diameter: 21.2 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 10, Height: 4, Diameter: 32 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 10, Height: 5, Diameter: 34 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 10, Height: 7, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 10, Height: 8, Diameter: 40 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 10, Height: 10, Diameter: 44 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 10, Height: 11, Diameter: 46 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 10, Height: 12, Diameter: 47.8 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 10, Height: 1, Diameter: 13.3 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 10, Height: 2, Diameter: 22.6 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 10, Height: 4, Diameter: 34 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 10, Height: 5, Diameter: 36 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 10, Height: 7, Diameter: 40 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 10, Height: 8, Diameter: 42 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 10, Height: 10, Diameter: 46 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 10, Height: 11, Diameter: 48 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 10, Height: 1, Diameter: 14.6 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 10, Height: 2, Diameter: 24.3 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 10, Height: 4, Diameter: 36.2 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 10, Height: 5, Diameter: 38.3 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 10, Height: 6, Diameter: 40.5 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 10, Height: 7, Diameter: 42.7 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 10, Height: 8, Diameter: 44.8 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 10, Height: 9, Diameter: 47 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 10, Height: 10, Diameter: 49.2 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 10, Height: 11, Diameter: 51.3 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 10, Height: 12, Diameter: 53.2 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 10, Height: 1, Diameter: 14.1 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 10, Height: 2, Diameter: 23.6 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 10, Height: 3, Diameter: 33 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 10, Height: 4, Diameter: 35.5 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 10, Height: 5, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 10, Height: 6, Diameter: 40.5 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 10, Height: 7, Diameter: 43 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 10, Height: 8, Diameter: 45.5 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 10, Height: 10, Diameter: 50.5 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 10, Height: 11, Diameter: 53 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 10, Height: 12, Diameter: 54.4 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 10, Height: 1, Diameter: 14.5 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 10, Height: 2, Diameter: 22.7 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 10, Height: 3, Diameter: 31 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 10, Height: 4, Diameter: 33.5 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 10, Height: 5, Diameter: 36 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 10, Height: 6, Diameter: 38.5 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 10, Height: 7, Diameter: 41 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 10, Height: 8, Diameter: 43.5 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 10, Height: 10, Diameter: 48.5 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 10, Height: 11, Diameter: 51 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 10, Height: 12, Diameter: 51.6 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 15, Height: 1, Diameter: 6.2 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 15, Height: 2, Diameter: 11.1 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 15, Height: 3, Diameter: 16 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 15, Height: 4, Diameter: 16.7 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 15, Height: 5, Diameter: 17.3 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 15, Height: 6, Diameter: 18 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 15, Height: 7, Diameter: 18.7 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 15, Height: 8, Diameter: 19.3 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 15, Height: 9, Diameter: 20 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 15, Height: 10, Diameter: 20.7 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 15, Height: 11, Diameter: 21.3 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 15, Height: 12, Diameter: 22 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 15, Height: 1, Diameter: 10 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 15, Height: 2, Diameter: 17 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 15, Height: 3, Diameter: 24 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 15, Height: 4, Diameter: 24.8 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 15, Height: 5, Diameter: 25.7 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 15, Height: 6, Diameter: 26.5 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 15, Height: 7, Diameter: 27.3 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 15, Height: 8, Diameter: 28.2 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 15, Height: 9, Diameter: 29 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 15, Height: 10, Diameter: 29.8 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 15, Height: 11, Diameter: 30.7 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 15, Height: 12, Diameter: 31.5 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 15, Height: 1, Diameter: 12 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 15, Height: 2, Diameter: 20 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 15, Height: 4, Diameter: 29.7 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 15, Height: 5, Diameter: 31.3 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 15, Height: 6, Diameter: 33 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 15, Height: 7, Diameter: 34.7 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 15, Height: 8, Diameter: 36.3 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 15, Height: 10, Diameter: 39.7 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 15, Height: 11, Diameter: 41.2 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 15, Height: 12, Diameter: 41.9 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 15, Height: 1, Diameter: 15 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 15, Height: 2, Diameter: 24.5 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 15, Height: 4, Diameter: 36 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 15, Height: 5, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 40 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 15, Height: 7, Diameter: 42 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 15, Height: 8, Diameter: 44 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 46 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 15, Height: 10, Diameter: 48 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 15, Height: 11, Diameter: 50 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 15, Height: 12, Diameter: 50.7 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 15, Height: 1, Diameter: 15.9 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 15, Height: 2, Diameter: 25.5 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 15, Height: 3, Diameter: 35 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 15, Height: 4, Diameter: 37.3 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 15, Height: 5, Diameter: 39.7 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 15, Height: 6, Diameter: 42 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 15, Height: 7, Diameter: 44.3 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 15, Height: 8, Diameter: 46.7 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 15, Height: 9, Diameter: 49 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 15, Height: 10, Diameter: 51.3 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 15, Height: 11, Diameter: 53.7 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 15, Height: 12, Diameter: 54 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 15, Height: 1, Diameter: 16.4 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 15, Height: 2, Diameter: 26.2 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 15, Height: 4, Diameter: 38.7 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 15, Height: 5, Diameter: 41.3 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 15, Height: 7, Diameter: 46.7 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 15, Height: 8, Diameter: 49.3 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 15, Height: 10, Diameter: 54.5 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 15, Height: 11, Diameter: 57.3 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 15, Height: 12, Diameter: 57.3 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 15, Height: 1, Diameter: 17.7 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 15, Height: 2, Diameter: 27.9 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 15, Height: 4, Diameter: 40.8 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 15, Height: 5, Diameter: 43.7 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 15, Height: 6, Diameter: 46.5 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 15, Height: 7, Diameter: 49.3 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 15, Height: 8, Diameter: 52.2 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 15, Height: 9, Diameter: 55 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 15, Height: 10, Diameter: 57.7 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 15, Height: 11, Diameter: 60.5 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 15, Height: 12, Diameter: 60.5 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 15, Height: 1, Diameter: 17.7 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 15, Height: 2, Diameter: 27.9 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 15, Height: 4, Diameter: 41 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 15, Height: 5, Diameter: 44 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 15, Height: 6, Diameter: 47 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 15, Height: 7, Diameter: 50 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 15, Height: 8, Diameter: 53 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 15, Height: 10, Diameter: 58.8 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 15, Height: 11, Diameter: 61.6 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 15, Height: 12, Diameter: 61.6 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 15, Height: 1, Diameter: 18.2 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 15, Height: 2, Diameter: 28.1 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 15, Height: 4, Diameter: 40.7 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 15, Height: 5, Diameter: 43.3 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 15, Height: 7, Diameter: 48.7 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 15, Height: 8, Diameter: 51.3 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 15, Height: 10, Diameter: 56.7 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 15, Height: 11, Diameter: 59.3 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 15, Height: 12, Diameter: 59.4 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 20, Height: 1, Diameter: 7.2 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 20, Height: 2, Diameter: 12.6 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 20, Height: 3, Diameter: 18 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 20, Height: 4, Diameter: 18.8 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 20, Height: 5, Diameter: 19.7 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 20, Height: 6, Diameter: 20.5 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 20, Height: 7, Diameter: 21.3 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 20, Height: 8, Diameter: 22.2 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 20, Height: 9, Diameter: 23 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 20, Height: 10, Diameter: 23.6 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 20, Height: 11, Diameter: 24.3 },
    { DevCode: 220, Nozzle1: 0.09, Pressure: 20, Height: 12, Diameter: 25 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 20, Height: 1, Diameter: 12 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 20, Height: 2, Diameter: 20 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 20, Height: 3, Diameter: 28 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 20, Height: 4, Diameter: 28.8 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 20, Height: 5, Diameter: 29.7 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 20, Height: 6, Diameter: 30.5 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 20, Height: 7, Diameter: 31.3 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 20, Height: 8, Diameter: 32.2 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 20, Height: 9, Diameter: 33 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 20, Height: 10, Diameter: 33.8 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 20, Height: 11, Diameter: 34.7 },
    { DevCode: 220, Nozzle1: 0.14, Pressure: 20, Height: 12, Diameter: 35.5 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 20, Height: 1, Diameter: 14.5 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 20, Height: 2, Diameter: 23.3 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 20, Height: 4, Diameter: 33.8 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 20, Height: 5, Diameter: 35.7 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 20, Height: 6, Diameter: 37.5 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 20, Height: 7, Diameter: 39.3 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 20, Height: 8, Diameter: 41.2 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 20, Height: 9, Diameter: 43 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 20, Height: 10, Diameter: 44.4 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 20, Height: 11, Diameter: 45.6 },
    { DevCode: 220, Nozzle1: 0.18, Pressure: 20, Height: 12, Diameter: 47.1 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 20, Height: 1, Diameter: 17.7 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 20, Height: 2, Diameter: 27.9 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 20, Height: 4, Diameter: 40 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 20, Height: 5, Diameter: 42 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 44 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 20, Height: 7, Diameter: 46 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 20, Height: 8, Diameter: 48 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 50 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 20, Height: 10, Diameter: 51.7 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 20, Height: 11, Diameter: 53 },
    { DevCode: 220, Nozzle1: 0.24, Pressure: 20, Height: 12, Diameter: 54.7 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 20, Height: 1, Diameter: 19.6 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 20, Height: 2, Diameter: 30.3 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 20, Height: 3, Diameter: 41 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 20, Height: 4, Diameter: 43 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 20, Height: 5, Diameter: 45 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 20, Height: 6, Diameter: 47 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 20, Height: 7, Diameter: 49 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 20, Height: 8, Diameter: 51 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 20, Height: 9, Diameter: 53 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 20, Height: 10, Diameter: 54.9 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 20, Height: 11, Diameter: 56.2 },
    { DevCode: 220, Nozzle1: 0.28, Pressure: 20, Height: 12, Diameter: 58 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 20, Height: 1, Diameter: 20.1 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 20, Height: 2, Diameter: 31 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 20, Height: 3, Diameter: 42 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 20, Height: 4, Diameter: 44.5 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 20, Height: 5, Diameter: 47 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 20, Height: 6, Diameter: 49.5 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 20, Height: 7, Diameter: 52 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 20, Height: 8, Diameter: 54.5 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 20, Height: 9, Diameter: 57 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 20, Height: 10, Diameter: 59 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 20, Height: 11, Diameter: 60.5 },
    { DevCode: 220, Nozzle1: 0.32, Pressure: 20, Height: 12, Diameter: 62.3 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 20, Height: 1, Diameter: 21.5 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 20, Height: 2, Diameter: 32.8 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 20, Height: 4, Diameter: 46.7 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 20, Height: 7, Diameter: 54.7 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 20, Height: 8, Diameter: 57.3 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 20, Height: 10, Diameter: 62.2 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 20, Height: 11, Diameter: 63.7 },
    { DevCode: 220, Nozzle1: 0.36, Pressure: 20, Height: 12, Diameter: 65.6 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 20, Height: 1, Diameter: 21.5 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 20, Height: 2, Diameter: 32.8 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 20, Height: 4, Diameter: 46.7 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 20, Height: 6, Diameter: 52 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 20, Height: 7, Diameter: 54.7 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 20, Height: 8, Diameter: 57.3 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 20, Height: 9, Diameter: 60 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 20, Height: 10, Diameter: 62.2 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 20, Height: 11, Diameter: 63.7 },
    { DevCode: 220, Nozzle1: 0.42, Pressure: 20, Height: 12, Diameter: 65.6 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 20, Height: 1, Diameter: 22 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 20, Height: 2, Diameter: 33 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 20, Height: 3, Diameter: 44 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 20, Height: 4, Diameter: 47 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 20, Height: 5, Diameter: 50 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 20, Height: 6, Diameter: 53 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 20, Height: 7, Diameter: 56 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 20, Height: 8, Diameter: 59 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 20, Height: 9, Diameter: 62 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 20, Height: 10, Diameter: 64.3 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 20, Height: 11, Diameter: 65.8 },
    { DevCode: 220, Nozzle1: 0.5, Pressure: 20, Height: 12, Diameter: 67.7 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 8 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 10 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 12 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 16 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 20 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 24 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 14 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 26 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 14 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 18 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 28 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 21 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 26 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 30 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 10 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 13 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 16 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 18 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 15 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 20 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 26 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 29 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 17 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 23 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 29 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 33 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 18 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 25 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 31 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 35 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 33 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 37 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 10 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 14 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 18 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 22 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 22 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 28 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 33 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 21 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 28 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 23 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 36 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 41 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 25 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 38 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 44 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 11 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 15 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 20 },
    { DevCode: 296, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 24 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 24 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 31 },
    { DevCode: 296, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 36 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 20 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 29 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 296, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 42 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 24 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 40 },
    { DevCode: 296, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 45 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 26 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 35 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 42 },
    { DevCode: 296, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 48 },
    { DevCode: 271, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 9 },
    { DevCode: 271, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 11 },
    { DevCode: 271, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 271, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 271, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 271, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 271, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 271, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 271, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 271, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 271, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 271, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 31 },
    { DevCode: 271, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 22 },
    { DevCode: 271, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 271, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 271, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 271, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 23 },
    { DevCode: 271, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 27 },
    { DevCode: 271, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 33 },
    { DevCode: 271, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 273, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 12 },
    { DevCode: 273, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 15 },
    { DevCode: 273, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 18 },
    { DevCode: 273, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 273, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 273, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 273, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 273, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 273, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 273, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 273, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 273, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 273, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 27 },
    { DevCode: 273, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 273, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 273, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 273, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 32 },
    { DevCode: 273, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 273, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 273, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 9 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 11 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 22 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 23 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 27 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 12 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 15 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 18 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 27 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 32 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 18 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 22 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 26 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 25 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 30 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 32 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 41 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 45 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 35 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 39 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 20 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 24 },
    { DevCode: 257, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 28 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 27 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 257, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 41 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 33 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 258, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 47 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 35 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 39 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 258, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 38 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 43 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 258, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 272, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 9 },
    { DevCode: 272, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 11 },
    { DevCode: 272, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 272, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 272, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 272, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 272, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 24 },
    { DevCode: 272, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 272, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 272, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 272, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 272, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 31 },
    { DevCode: 272, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 22 },
    { DevCode: 272, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 272, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 272, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 272, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 23 },
    { DevCode: 272, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 27 },
    { DevCode: 272, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 33 },
    { DevCode: 272, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 272, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 12 },
    { DevCode: 272, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 15 },
    { DevCode: 272, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 18 },
    { DevCode: 272, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 272, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 272, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 272, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 272, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 272, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 272, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 272, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 272, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 272, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 27 },
    { DevCode: 272, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 272, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 272, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 272, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 32 },
    { DevCode: 272, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 272, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 272, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 6, Height: 1.5, Diameter: 9 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 6, Height: 3, Diameter: 11 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 6, Height: 6, Diameter: 13 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 6, Height: 9, Diameter: 15 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 6, Height: 1.5, Diameter: 16 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 6, Height: 3, Diameter: 19 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 6, Height: 6, Diameter: 23 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 6, Height: 9, Diameter: 27 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 6, Height: 1.5, Diameter: 20 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 6, Height: 6, Diameter: 28 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 6, Height: 9, Diameter: 32 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 6, Height: 1.5, Diameter: 22 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 6, Height: 3, Diameter: 26 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 6, Height: 9, Diameter: 34 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 6, Height: 1.5, Diameter: 23 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 6, Height: 3, Diameter: 27 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 10, Height: 1.5, Diameter: 12 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 10, Height: 3, Diameter: 15 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 10, Height: 6, Diameter: 18 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 10, Height: 9, Diameter: 22 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 10, Height: 1.5, Diameter: 20 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 10, Height: 6, Diameter: 28 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 10, Height: 9, Diameter: 32 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 10, Height: 1.5, Diameter: 24 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 10, Height: 6, Diameter: 32 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 10, Height: 1.5, Diameter: 27 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 10, Height: 6, Diameter: 36 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 10, Height: 1.5, Diameter: 32 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 15, Height: 1.5, Diameter: 15 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 15, Height: 3, Diameter: 18 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 15, Height: 6, Diameter: 22 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 15, Height: 9, Diameter: 26 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 15, Height: 1.5, Diameter: 25 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 15, Height: 3, Diameter: 29 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 15, Height: 6, Diameter: 34 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 15, Height: 9, Diameter: 38 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 15, Height: 1.5, Diameter: 30 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 15, Height: 6, Diameter: 39 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 15, Height: 9, Diameter: 43 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 15, Height: 1.5, Diameter: 32 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 15, Height: 6, Diameter: 41 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 15, Height: 9, Diameter: 45 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 15, Height: 1.5, Diameter: 35 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 15, Height: 3, Diameter: 39 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 15, Height: 6, Diameter: 44 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 20, Height: 1.5, Diameter: 16 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 20, Height: 3, Diameter: 20 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 20, Height: 6, Diameter: 24 },
    { DevCode: 259, Nozzle1: 0.04, Pressure: 20, Height: 9, Diameter: 28 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 20, Height: 1.5, Diameter: 27 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 20, Height: 3, Diameter: 32 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 20, Height: 6, Diameter: 37 },
    { DevCode: 259, Nozzle1: 0.08, Pressure: 20, Height: 9, Diameter: 41 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 20, Height: 1.5, Diameter: 33 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 20, Height: 3, Diameter: 38 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 20, Height: 6, Diameter: 43 },
    { DevCode: 259, Nozzle1: 0.12, Pressure: 20, Height: 9, Diameter: 47 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 20, Height: 1.5, Diameter: 35 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 20, Height: 3, Diameter: 39 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 20, Height: 6, Diameter: 45 },
    { DevCode: 259, Nozzle1: 0.16, Pressure: 20, Height: 9, Diameter: 49 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 20, Height: 1.5, Diameter: 38 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 20, Height: 3, Diameter: 43 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 20, Height: 6, Diameter: 48 },
    { DevCode: 259, Nozzle1: 0.24, Pressure: 20, Height: 9, Diameter: 52 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 6, Height: 1, Diameter: 8.2 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 20, Height: 1, Diameter: 8.4 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 6, Height: 1, Diameter: 8.7 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 10, Height: 1, Diameter: 8.8 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 6, Height: 1, Diameter: 8.9 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 15, Height: 1, Diameter: 9.2 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 6, Height: 1, Diameter: 9.3 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 10, Height: 1, Diameter: 9.5 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 10, Height: 1, Diameter: 10 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 15, Height: 1, Diameter: 10.4 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 20, Height: 1, Diameter: 10.4 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 10, Height: 1, Diameter: 10.6 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 10, Height: 1, Diameter: 10.8 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 15, Height: 1, Diameter: 11.2 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 10, Height: 1, Diameter: 11.4 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 20, Height: 1, Diameter: 11.7 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 15, Height: 1, Diameter: 11.8 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 15, Height: 1, Diameter: 12.5 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 20, Height: 1, Diameter: 12.6 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 15, Height: 1, Diameter: 12.9 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 15, Height: 1, Diameter: 13.4 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 20, Height: 1, Diameter: 13.5 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 20, Height: 1, Diameter: 13.9 },
    { DevCode: 31, Nozzle1: 0.0625, Pressure: 6, Height: 3, Diameter: 15.3 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 20, Height: 1, Diameter: 15.4 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 10, Height: 3, Diameter: 18.8 },
    { DevCode: 31, Nozzle1: 0.0625, Pressure: 6, Height: 6, Diameter: 19.1 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 6, Height: 3, Diameter: 19.1 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 6, Height: 3, Diameter: 21.5 },
    { DevCode: 31, Nozzle1: 0.0625, Pressure: 6, Height: 9, Diameter: 21.8 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 15, Height: 3, Diameter: 22.1 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 6, Height: 3, Diameter: 23.1 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 10, Height: 6, Diameter: 23.5 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 10, Height: 3, Diameter: 23.5 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 6, Height: 6, Diameter: 23.9 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 6, Height: 3, Diameter: 24.4 },
    { DevCode: 31, Nozzle1: 0.0625, Pressure: 6, Height: 12, Diameter: 24.8 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 20, Height: 3, Diameter: 24.8 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 6, Height: 3, Diameter: 25.7 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 10, Height: 3, Diameter: 26.4 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 6, Height: 3, Diameter: 26.7 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 6, Height: 6, Diameter: 26.9 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 10, Height: 9, Diameter: 26.9 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 6, Height: 9, Diameter: 27.3 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 15, Height: 6, Diameter: 27.6 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 15, Height: 3, Diameter: 27.6 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 6, Height: 3, Diameter: 27.9 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 10, Height: 3, Diameter: 28.5 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 6, Height: 6, Diameter: 29 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 10, Height: 6, Diameter: 29.4 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 10, Height: 12, Diameter: 29.9 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 6, Height: 12, Diameter: 30.3 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 6, Height: 6, Diameter: 30.5 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 6, Height: 9, Diameter: 30.7 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 15, Height: 3, Diameter: 31 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 20, Height: 6, Diameter: 31 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 20, Height: 3, Diameter: 31 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 15, Height: 9, Diameter: 31.5 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 10, Height: 3, Diameter: 31.6 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 6, Height: 6, Diameter: 32.1 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 10, Height: 3, Diameter: 32.8 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 6, Height: 9, Diameter: 33 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 10, Height: 6, Diameter: 33.1 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 6, Height: 6, Diameter: 33.4 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 15, Height: 3, Diameter: 33.4 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 10, Height: 9, Diameter: 33.6 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 6, Height: 12, Diameter: 33.7 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 10, Height: 3, Diameter: 34.4 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 15, Height: 12, Diameter: 34.5 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 15, Height: 6, Diameter: 34.5 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 6, Height: 9, Diameter: 34.8 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 20, Height: 3, Diameter: 34.9 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 6, Height: 6, Diameter: 35 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 15, Height: 3, Diameter: 35.2 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 20, Height: 9, Diameter: 35.4 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 10, Height: 6, Diameter: 35.7 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 6, Height: 12, Diameter: 36 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 10, Height: 12, Diameter: 36.6 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 6, Height: 9, Diameter: 36.7 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 15, Height: 3, Diameter: 37.1 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 10, Height: 6, Diameter: 37.5 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 20, Height: 3, Diameter: 37.6 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 6, Height: 12, Diameter: 37.8 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 10, Height: 9, Diameter: 37.8 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 6, Height: 9, Diameter: 38.1 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 20, Height: 12, Diameter: 38.4 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 15, Height: 3, Diameter: 38.5 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 15, Height: 6, Diameter: 38.8 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 20, Height: 6, Diameter: 38.8 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 15, Height: 9, Diameter: 39.4 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 20, Height: 3, Diameter: 39.5 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 10, Height: 6, Diameter: 39.6 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 6, Height: 12, Diameter: 39.7 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 6, Height: 9, Diameter: 39.9 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 15, Height: 3, Diameter: 40.4 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 10, Height: 9, Diameter: 40.7 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 10, Height: 12, Diameter: 40.8 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 6, Height: 12, Diameter: 41.1 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 10, Height: 6, Diameter: 41.1 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 20, Height: 3, Diameter: 41.7 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 15, Height: 6, Diameter: 41.9 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 15, Height: 12, Diameter: 42.4 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 10, Height: 9, Diameter: 42.8 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 6, Height: 12, Diameter: 42.9 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 10, Height: 6, Diameter: 43.1 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 20, Height: 3, Diameter: 43.3 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 20, Height: 6, Diameter: 43.6 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 10, Height: 12, Diameter: 43.7 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 15, Height: 6, Diameter: 44.1 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 15, Height: 9, Diameter: 44.3 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 20, Height: 9, Diameter: 44.3 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 10, Height: 9, Diameter: 45.1 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 20, Height: 3, Diameter: 45.4 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 10, Height: 12, Diameter: 45.8 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 15, Height: 6, Diameter: 46.4 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 10, Height: 9, Diameter: 46.9 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 20, Height: 6, Diameter: 47 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 15, Height: 12, Diameter: 47.3 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 20, Height: 12, Diameter: 47.3 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 15, Height: 9, Diameter: 47.7 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 10, Height: 12, Diameter: 48.1 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 15, Height: 6, Diameter: 48.2 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 10, Height: 9, Diameter: 49.1 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 20, Height: 6, Diameter: 49.5 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 20, Height: 9, Diameter: 49.8 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 10, Height: 12, Diameter: 49.9 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 15, Height: 9, Diameter: 50.3 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 15, Height: 6, Diameter: 50.5 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 15, Height: 12, Diameter: 50.7 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 10, Height: 12, Diameter: 52.1 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 20, Height: 6, Diameter: 52.2 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 20, Height: 12, Diameter: 52.8 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 15, Height: 9, Diameter: 53 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 15, Height: 12, Diameter: 53.3 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 20, Height: 9, Diameter: 53.7 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 20, Height: 6, Diameter: 54.2 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 15, Height: 9, Diameter: 55 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 15, Height: 12, Diameter: 56 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 20, Height: 9, Diameter: 56.5 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 20, Height: 12, Diameter: 56.7 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 20, Height: 6, Diameter: 56.8 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 15, Height: 9, Diameter: 57.6 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 31, Nozzle1: 0.265625, Pressure: 20, Height: 12, Diameter: 59.5 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 20, Height: 9, Diameter: 59.5 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 15, Height: 12, Diameter: 60.6 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 20, Height: 9, Diameter: 61.8 },
    { DevCode: 31, Nozzle1: 0.3125, Pressure: 20, Height: 12, Diameter: 62.5 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 20, Height: 12, Diameter: 64.8 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 6, Height: 1, Diameter: 15.5 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 10, Height: 1, Diameter: 20.3 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 15, Height: 1, Diameter: 24 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 20, Height: 1, Diameter: 28.1 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 6, Height: 2, Diameter: 21.5 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 10, Height: 2, Diameter: 26.3 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 15, Height: 2, Diameter: 30.3 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 20, Height: 2, Diameter: 34.1 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 6, Height: 3, Diameter: 27.5 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 10, Height: 3, Diameter: 32.3 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 15, Height: 3, Diameter: 36.6 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 20, Height: 3, Diameter: 40.1 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 6, Height: 4, Diameter: 29 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 10, Height: 4, Diameter: 34 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 15, Height: 4, Diameter: 38.5 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 20, Height: 4, Diameter: 42.3 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 6, Height: 5, Diameter: 30.5 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 10, Height: 5, Diameter: 35.8 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 15, Height: 5, Diameter: 40.5 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 20, Height: 5, Diameter: 44.4 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 6, Height: 6, Diameter: 31.9 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 10, Height: 6, Diameter: 37.6 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 15, Height: 6, Diameter: 42.5 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 20, Height: 6, Diameter: 46.6 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 6, Height: 7, Diameter: 32.9 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 10, Height: 7, Diameter: 38.7 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 15, Height: 7, Diameter: 43.8 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 20, Height: 7, Diameter: 48 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 6, Height: 8, Diameter: 33.9 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 10, Height: 8, Diameter: 39.8 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 15, Height: 8, Diameter: 45.1 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 20, Height: 8, Diameter: 49.5 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 6, Height: 9, Diameter: 34.9 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 10, Height: 9, Diameter: 41 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 15, Height: 9, Diameter: 46.4 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 20, Height: 9, Diameter: 50.9 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 6, Height: 10, Diameter: 35.9 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 10, Height: 10, Diameter: 42 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 15, Height: 10, Diameter: 47 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 20, Height: 10, Diameter: 51.3 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 6, Height: 11, Diameter: 36.4 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 10, Height: 11, Diameter: 42.5 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 15, Height: 11, Diameter: 47.5 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 20, Height: 11, Diameter: 51.7 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 6, Height: 12, Diameter: 36.9 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 10, Height: 12, Diameter: 42.5 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 15, Height: 12, Diameter: 48 },
    { DevCode: 20, Nozzle1: 0.078125, Pressure: 20, Height: 12, Diameter: 52 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 6, Height: 1, Diameter: 17.2 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 10, Height: 1, Diameter: 22.3 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 15, Height: 1, Diameter: 26 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 20, Height: 1, Diameter: 30.5 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 6, Height: 2, Diameter: 23.2 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 10, Height: 2, Diameter: 28.3 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 15, Height: 2, Diameter: 32.4 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 20, Height: 2, Diameter: 36.5 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 6, Height: 3, Diameter: 29.2 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 10, Height: 3, Diameter: 34.3 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 15, Height: 3, Diameter: 38.8 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 20, Height: 3, Diameter: 42.5 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 6, Height: 4, Diameter: 30.7 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 10, Height: 4, Diameter: 36.1 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 15, Height: 4, Diameter: 40.9 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 20, Height: 4, Diameter: 44.8 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 6, Height: 5, Diameter: 32.3 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 10, Height: 5, Diameter: 38 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 15, Height: 5, Diameter: 43 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 20, Height: 5, Diameter: 47.1 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 6, Height: 6, Diameter: 33.9 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 10, Height: 6, Diameter: 39.8 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 15, Height: 6, Diameter: 45.1 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 20, Height: 6, Diameter: 49.4 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 6, Height: 7, Diameter: 35 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 10, Height: 7, Diameter: 41 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 15, Height: 7, Diameter: 46.5 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 20, Height: 7, Diameter: 51 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 6, Height: 8, Diameter: 36 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 10, Height: 8, Diameter: 42.3 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 15, Height: 8, Diameter: 47.9 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 20, Height: 8, Diameter: 52.5 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 6, Height: 9, Diameter: 37 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 10, Height: 9, Diameter: 43.5 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 15, Height: 9, Diameter: 49.3 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 6, Height: 10, Diameter: 38 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 10, Height: 10, Diameter: 44.5 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 15, Height: 10, Diameter: 50 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 20, Height: 10, Diameter: 54.7 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 6, Height: 11, Diameter: 39 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 10, Height: 11, Diameter: 45.5 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 15, Height: 11, Diameter: 50.5 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 20, Height: 11, Diameter: 55.4 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 6, Height: 12, Diameter: 40 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 10, Height: 12, Diameter: 46 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 15, Height: 12, Diameter: 51 },
    { DevCode: 20, Nozzle1: 0.125, Pressure: 20, Height: 12, Diameter: 56 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 6, Height: 1, Diameter: 18.5 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 10, Height: 1, Diameter: 24 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 15, Height: 1, Diameter: 28 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 20, Height: 1, Diameter: 32.5 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 6, Height: 2, Diameter: 24.5 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 10, Height: 2, Diameter: 30 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 15, Height: 2, Diameter: 34.3 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 20, Height: 2, Diameter: 38.5 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 6, Height: 3, Diameter: 30.5 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 10, Height: 3, Diameter: 35.9 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 15, Height: 3, Diameter: 40.6 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 20, Height: 3, Diameter: 44.5 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 6, Height: 4, Diameter: 32.2 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 10, Height: 4, Diameter: 37.8 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 15, Height: 4, Diameter: 42.7 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 20, Height: 4, Diameter: 46.9 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 6, Height: 5, Diameter: 33.8 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 10, Height: 5, Diameter: 39.8 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 15, Height: 5, Diameter: 44.9 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 6, Height: 6, Diameter: 35.5 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 10, Height: 6, Diameter: 41.7 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 15, Height: 6, Diameter: 47.2 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 20, Height: 6, Diameter: 51.7 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 6, Height: 7, Diameter: 36.6 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 10, Height: 7, Diameter: 43 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 15, Height: 7, Diameter: 48.6 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 20, Height: 7, Diameter: 53.3 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 6, Height: 8, Diameter: 37.7 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 10, Height: 8, Diameter: 44.3 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 15, Height: 8, Diameter: 50.1 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 20, Height: 8, Diameter: 54.9 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 6, Height: 9, Diameter: 38.8 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 10, Height: 9, Diameter: 45.6 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 15, Height: 9, Diameter: 51.6 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 20, Height: 9, Diameter: 56.5 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 6, Height: 10, Diameter: 39.9 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 10, Height: 10, Diameter: 47 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 15, Height: 10, Diameter: 53 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 20, Height: 10, Diameter: 57.4 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 6, Height: 11, Diameter: 41 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 10, Height: 11, Diameter: 48.5 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 15, Height: 11, Diameter: 54 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 20, Height: 11, Diameter: 58.2 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 6, Height: 12, Diameter: 42 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 10, Height: 12, Diameter: 49 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 15, Height: 12, Diameter: 55 },
    { DevCode: 20, Nozzle1: 0.1796875, Pressure: 20, Height: 12, Diameter: 59 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 6, Height: 1, Diameter: 19.4 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 10, Height: 1, Diameter: 25 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 15, Height: 1, Diameter: 30 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 20, Height: 1, Diameter: 33 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 6, Height: 2, Diameter: 25.4 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 10, Height: 2, Diameter: 31 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 15, Height: 2, Diameter: 35.9 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 20, Height: 2, Diameter: 39.4 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 6, Height: 3, Diameter: 31.4 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 10, Height: 3, Diameter: 37 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 15, Height: 3, Diameter: 41.8 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 20, Height: 3, Diameter: 45.8 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 6, Height: 4, Diameter: 33.1 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 10, Height: 4, Diameter: 39 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 15, Height: 4, Diameter: 44.1 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 20, Height: 4, Diameter: 48.3 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 6, Height: 5, Diameter: 34.8 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 10, Height: 5, Diameter: 41 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 15, Height: 5, Diameter: 46.4 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 20, Height: 5, Diameter: 50.8 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 6, Height: 6, Diameter: 36.5 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 10, Height: 6, Diameter: 43 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 15, Height: 6, Diameter: 48.7 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 20, Height: 6, Diameter: 53.3 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 6, Height: 7, Diameter: 37.7 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 10, Height: 7, Diameter: 44.3 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 15, Height: 7, Diameter: 50.3 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 20, Height: 7, Diameter: 55 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 6, Height: 8, Diameter: 38.8 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 10, Height: 8, Diameter: 45.6 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 15, Height: 8, Diameter: 51.7 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 20, Height: 8, Diameter: 56.6 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 6, Height: 9, Diameter: 39.9 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 10, Height: 9, Diameter: 46.9 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 15, Height: 9, Diameter: 53.1 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 20, Height: 9, Diameter: 58.2 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 6, Height: 10, Diameter: 41 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 10, Height: 10, Diameter: 48 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 15, Height: 10, Diameter: 54 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 20, Height: 10, Diameter: 58.5 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 6, Height: 11, Diameter: 42 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 10, Height: 11, Diameter: 49 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 15, Height: 11, Diameter: 54.5 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 20, Height: 11, Diameter: 58.8 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 6, Height: 12, Diameter: 43 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 15, Height: 12, Diameter: 55 },
    { DevCode: 20, Nozzle1: 0.2265625, Pressure: 20, Height: 12, Diameter: 59 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 6, Height: 1, Diameter: 20 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 10, Height: 1, Diameter: 26 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 15, Height: 1, Diameter: 30.9 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 20, Height: 1, Diameter: 34 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 6, Height: 2, Diameter: 26 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 10, Height: 2, Diameter: 32 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 15, Height: 2, Diameter: 36.8 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 20, Height: 2, Diameter: 40.4 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 6, Height: 3, Diameter: 32.1 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 10, Height: 3, Diameter: 37.7 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 15, Height: 3, Diameter: 42.7 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 20, Height: 3, Diameter: 46.8 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 6, Height: 4, Diameter: 33.8 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 10, Height: 4, Diameter: 39.7 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 15, Height: 4, Diameter: 45 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 20, Height: 4, Diameter: 49.3 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 6, Height: 5, Diameter: 35.5 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 10, Height: 5, Diameter: 41.8 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 15, Height: 5, Diameter: 47.3 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 20, Height: 5, Diameter: 51.9 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 6, Height: 6, Diameter: 37.3 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 10, Height: 6, Diameter: 43.8 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 15, Height: 6, Diameter: 49.6 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 20, Height: 6, Diameter: 54.4 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 6, Height: 7, Diameter: 38.5 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 10, Height: 7, Diameter: 45.2 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 15, Height: 7, Diameter: 51.1 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 20, Height: 7, Diameter: 56 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 6, Height: 8, Diameter: 39.6 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 10, Height: 8, Diameter: 46.5 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 15, Height: 8, Diameter: 52.6 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 20, Height: 8, Diameter: 57.7 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 6, Height: 9, Diameter: 40.7 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 10, Height: 9, Diameter: 47.9 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 15, Height: 9, Diameter: 54.2 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 20, Height: 9, Diameter: 59.4 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 6, Height: 10, Diameter: 41.7 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 10, Height: 10, Diameter: 49 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 15, Height: 10, Diameter: 55 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 20, Height: 10, Diameter: 60 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 6, Height: 11, Diameter: 42.6 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 10, Height: 11, Diameter: 50 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 15, Height: 11, Diameter: 55.5 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 20, Height: 11, Diameter: 60.5 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 6, Height: 12, Diameter: 43.5 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 10, Height: 12, Diameter: 51 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 15, Height: 12, Diameter: 56 },
    { DevCode: 20, Nozzle1: 0.265625, Pressure: 20, Height: 12, Diameter: 61 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 6, Height: 1, Diameter: 20.1 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 10, Height: 1, Diameter: 26.5 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 15, Height: 1, Diameter: 31.6 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 20, Height: 1, Diameter: 34.9 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 6, Height: 2, Diameter: 26.4 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 10, Height: 2, Diameter: 32.5 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 15, Height: 2, Diameter: 37.6 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 20, Height: 2, Diameter: 41.3 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 6, Height: 3, Diameter: 32.7 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 10, Height: 3, Diameter: 38.5 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 15, Height: 3, Diameter: 43.6 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 20, Height: 3, Diameter: 47.7 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 6, Height: 4, Diameter: 34.5 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 10, Height: 4, Diameter: 40.5 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 15, Height: 4, Diameter: 46 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 20, Height: 4, Diameter: 50.3 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 6, Height: 5, Diameter: 36.3 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 10, Height: 5, Diameter: 42.6 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 15, Height: 5, Diameter: 48.3 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 20, Height: 5, Diameter: 52.9 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 6, Height: 6, Diameter: 38.1 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 10, Height: 6, Diameter: 44.7 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 15, Height: 6, Diameter: 50.7 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 20, Height: 6, Diameter: 55.5 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 6, Height: 7, Diameter: 39.3 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 10, Height: 7, Diameter: 46.1 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 15, Height: 7, Diameter: 52.3 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 20, Height: 7, Diameter: 57.2 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 6, Height: 8, Diameter: 40.4 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 10, Height: 8, Diameter: 47.5 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 15, Height: 8, Diameter: 53.8 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 20, Height: 8, Diameter: 58.9 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 6, Height: 9, Diameter: 41.6 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 10, Height: 9, Diameter: 48.9 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 15, Height: 9, Diameter: 55.3 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 20, Height: 9, Diameter: 60.6 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 6, Height: 10, Diameter: 42.1 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 10, Height: 10, Diameter: 50 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 15, Height: 10, Diameter: 56 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 20, Height: 10, Diameter: 60.8 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 6, Height: 11, Diameter: 42.6 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 10, Height: 11, Diameter: 50 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 15, Height: 11, Diameter: 56.5 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 20, Height: 11, Diameter: 60.9 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 6, Height: 12, Diameter: 43 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 15, Height: 12, Diameter: 57 },
    { DevCode: 20, Nozzle1: 0.3125, Pressure: 20, Height: 12, Diameter: 61 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 6, Height: 1, Diameter: 21 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 10, Height: 1, Diameter: 27 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 15, Height: 1, Diameter: 32 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 20, Height: 1, Diameter: 36 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 6, Height: 2, Diameter: 27.1 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 10, Height: 2, Diameter: 33 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 15, Height: 2, Diameter: 38.1 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 20, Height: 2, Diameter: 42.2 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 6, Height: 3, Diameter: 33.2 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 10, Height: 3, Diameter: 39.1 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 15, Height: 3, Diameter: 44.2 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 20, Height: 3, Diameter: 48.4 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 6, Height: 4, Diameter: 35 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 10, Height: 4, Diameter: 41.2 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 15, Height: 4, Diameter: 46.6 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 20, Height: 4, Diameter: 51 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 6, Height: 5, Diameter: 36.8 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 10, Height: 5, Diameter: 43.3 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 15, Height: 5, Diameter: 49 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 20, Height: 5, Diameter: 53.7 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 6, Height: 6, Diameter: 38.6 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 10, Height: 6, Diameter: 45.4 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 15, Height: 6, Diameter: 51.4 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 20, Height: 6, Diameter: 56.3 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 6, Height: 7, Diameter: 39.8 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 10, Height: 7, Diameter: 46.8 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 15, Height: 7, Diameter: 53 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 20, Height: 7, Diameter: 58 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 6, Height: 8, Diameter: 41 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 10, Height: 8, Diameter: 48.2 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 15, Height: 8, Diameter: 54.6 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 20, Height: 8, Diameter: 59.8 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 6, Height: 9, Diameter: 42.2 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 10, Height: 9, Diameter: 49.6 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 15, Height: 9, Diameter: 56.2 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 20, Height: 9, Diameter: 61.5 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 6, Height: 10, Diameter: 43.2 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 10, Height: 10, Diameter: 50 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 15, Height: 10, Diameter: 57 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 20, Height: 10, Diameter: 62 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 6, Height: 11, Diameter: 44.1 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 10, Height: 11, Diameter: 51 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 15, Height: 11, Diameter: 57.5 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 20, Height: 11, Diameter: 62.5 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 6, Height: 12, Diameter: 45 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 10, Height: 12, Diameter: 52 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 20, Nozzle1: 0.3515625, Pressure: 20, Height: 12, Diameter: 63 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 6, Height: 1, Diameter: 21.8 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 10, Height: 1, Diameter: 28 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 15, Height: 1, Diameter: 33.1 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 20, Height: 1, Diameter: 36.9 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 6, Height: 2, Diameter: 27.8 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 10, Height: 2, Diameter: 34 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 15, Height: 2, Diameter: 39.1 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 20, Height: 2, Diameter: 43.1 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 6, Height: 3, Diameter: 33.8 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 10, Height: 3, Diameter: 39.8 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 15, Height: 3, Diameter: 45.1 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 20, Height: 3, Diameter: 49.3 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 6, Height: 4, Diameter: 35.6 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 10, Height: 4, Diameter: 42 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 15, Height: 4, Diameter: 47.5 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 20, Height: 4, Diameter: 52 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 6, Height: 5, Diameter: 37.5 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 10, Height: 5, Diameter: 44.1 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 15, Height: 5, Diameter: 50 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 20, Height: 5, Diameter: 54.7 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 6, Height: 6, Diameter: 39.3 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 10, Height: 6, Diameter: 46.2 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 15, Height: 6, Diameter: 52.4 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 20, Height: 6, Diameter: 57.4 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 6, Height: 7, Diameter: 40.5 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 10, Height: 7, Diameter: 47.6 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 15, Height: 7, Diameter: 54 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 20, Height: 7, Diameter: 59.2 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 6, Height: 8, Diameter: 41.8 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 10, Height: 8, Diameter: 49 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 15, Height: 8, Diameter: 55.6 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 20, Height: 8, Diameter: 60.9 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 6, Height: 9, Diameter: 43 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 10, Height: 9, Diameter: 50.5 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 15, Height: 9, Diameter: 57.2 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 20, Height: 9, Diameter: 62.6 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 6, Height: 10, Diameter: 44 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 10, Height: 10, Diameter: 51.5 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 15, Height: 10, Diameter: 57.5 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 20, Height: 10, Diameter: 63 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 6, Height: 11, Diameter: 45 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 10, Height: 11, Diameter: 52.5 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 15, Height: 11, Diameter: 57.8 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 20, Height: 11, Diameter: 63.5 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 6, Height: 12, Diameter: 46 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 10, Height: 12, Diameter: 53 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 20, Nozzle1: 0.40625, Pressure: 20, Height: 12, Diameter: 64 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 10, Height: 1, Diameter: 10.1 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 10, Height: 1, Diameter: 10.7 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 15, Height: 1, Diameter: 10.9 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 20, Height: 1, Diameter: 10.9 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 10, Height: 1, Diameter: 11.3 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 10, Height: 1, Diameter: 11.9 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 15, Height: 1, Diameter: 11.9 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 15, Height: 1, Diameter: 12.5 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 20, Height: 1, Diameter: 12.5 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 10, Height: 1, Diameter: 12.6 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 15, Height: 1, Diameter: 13.2 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 20, Height: 1, Diameter: 13.5 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 15, Height: 1, Diameter: 13.8 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 20, Height: 1, Diameter: 14.1 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 15, Height: 1, Diameter: 14.4 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 20, Height: 1, Diameter: 14.9 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 20, Height: 1, Diameter: 15.5 },
    { DevCode: 30, Nozzle1: 0.0625, Pressure: 6, Height: 3, Diameter: 15.8 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 20, Height: 1, Diameter: 16.4 },
    { DevCode: 30, Nozzle1: 0.0625, Pressure: 6, Height: 6, Diameter: 19.3 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 10, Height: 3, Diameter: 19.5 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 6, Height: 3, Diameter: 19.9 },
    { DevCode: 30, Nozzle1: 0.0625, Pressure: 6, Height: 9, Diameter: 21.7 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 6, Height: 3, Diameter: 22.4 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 15, Height: 3, Diameter: 23.1 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 10, Height: 6, Diameter: 23.9 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 6, Height: 6, Diameter: 24.3 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 6, Height: 3, Diameter: 24.3 },
    { DevCode: 30, Nozzle1: 0.0625, Pressure: 6, Height: 12, Diameter: 24.7 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 10, Height: 3, Diameter: 24.7 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 6, Height: 3, Diameter: 25.6 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 20, Height: 3, Diameter: 26 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 10, Height: 9, Diameter: 26.9 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 6, Height: 3, Diameter: 27 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 6, Height: 9, Diameter: 27.4 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 6, Height: 6, Diameter: 27.5 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 10, Height: 3, Diameter: 27.8 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 6, Height: 3, Diameter: 28.1 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 15, Height: 6, Diameter: 28.2 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 15, Height: 3, Diameter: 29.1 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 6, Height: 3, Diameter: 29.5 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 6, Height: 6, Diameter: 29.7 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 10, Height: 12, Diameter: 29.9 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 10, Height: 3, Diameter: 30.1 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 10, Height: 6, Diameter: 30.2 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 6, Height: 12, Diameter: 30.4 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 6, Height: 9, Diameter: 30.9 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 6, Height: 6, Diameter: 31.3 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 10, Height: 3, Diameter: 31.7 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 15, Height: 9, Diameter: 31.8 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 20, Height: 6, Diameter: 31.9 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 15, Height: 3, Diameter: 32.9 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 20, Height: 3, Diameter: 32.9 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 6, Height: 6, Diameter: 33.1 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 6, Height: 9, Diameter: 33.4 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 10, Height: 3, Diameter: 33.5 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 6, Height: 12, Diameter: 33.9 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 10, Height: 9, Diameter: 34 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 10, Height: 6, Diameter: 34.1 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 6, Height: 6, Diameter: 34.4 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 15, Height: 12, Diameter: 34.8 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 10, Height: 3, Diameter: 34.9 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 6, Height: 9, Diameter: 35.3 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 15, Height: 3, Diameter: 35.5 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 15, Height: 6, Diameter: 35.6 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 20, Height: 9, Diameter: 35.9 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 6, Height: 6, Diameter: 36.1 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 6, Height: 12, Diameter: 36.4 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 10, Height: 3, Diameter: 36.6 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 10, Height: 6, Diameter: 36.9 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 10, Height: 12, Diameter: 37 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 20, Height: 3, Diameter: 37.1 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 6, Height: 9, Diameter: 37.3 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 15, Height: 3, Diameter: 37.5 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 6, Height: 12, Diameter: 38.3 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 10, Height: 9, Diameter: 38.4 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 6, Height: 9, Diameter: 38.8 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 10, Height: 6, Diameter: 38.9 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 20, Height: 12, Diameter: 38.9 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 15, Height: 3, Diameter: 39.6 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 15, Height: 9, Diameter: 40.1 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 20, Height: 3, Diameter: 40.1 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 20, Height: 6, Diameter: 40.2 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 6, Height: 12, Diameter: 40.3 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 15, Height: 6, Diameter: 40.3 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 6, Height: 9, Diameter: 40.7 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 10, Height: 6, Diameter: 41.1 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 15, Height: 3, Diameter: 41.2 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 10, Height: 12, Diameter: 41.4 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 10, Height: 9, Diameter: 41.5 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 6, Height: 12, Diameter: 41.8 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 20, Height: 3, Diameter: 42.3 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 10, Height: 6, Diameter: 42.7 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 15, Height: 12, Diameter: 43.1 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 15, Height: 3, Diameter: 43.2 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 15, Height: 6, Diameter: 43.5 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 6, Height: 12, Diameter: 43.7 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 10, Height: 9, Diameter: 43.8 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 10, Height: 12, Diameter: 44.5 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 20, Height: 3, Diameter: 44.7 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 10, Height: 6, Diameter: 44.8 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 15, Height: 9, Diameter: 45.3 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 6, Height: 3, Diameter: 26.2 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 20, Height: 1, Diameter: 26.3 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 20, Height: 1, Diameter: 26.7 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 20, Height: 1, Diameter: 27.2 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 20, Height: 1, Diameter: 27.5 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 10, Height: 3, Diameter: 27.8 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 20, Height: 1, Diameter: 28 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 10, Height: 3, Diameter: 28.8 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 6, Height: 6, Diameter: 29.1 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 10, Height: 3, Diameter: 29.5 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 6, Height: 6, Diameter: 30.1 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 10, Height: 3, Diameter: 30.1 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 10, Height: 3, Diameter: 30.4 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 10, Height: 3, Diameter: 30.8 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 6, Height: 6, Diameter: 30.9 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 10, Height: 3, Diameter: 31 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 10, Height: 3, Diameter: 31.4 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 6, Height: 6, Diameter: 31.5 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 6, Height: 6, Diameter: 31.8 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 15, Height: 3, Diameter: 32 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 6, Height: 6, Diameter: 32.2 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 6, Height: 6, Diameter: 32.5 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 6, Height: 6, Diameter: 32.8 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 15, Height: 3, Diameter: 33.1 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 6, Height: 9, Diameter: 33.2 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 15, Height: 3, Diameter: 34 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 6, Height: 9, Diameter: 34.4 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 15, Height: 3, Diameter: 34.6 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 10, Height: 6, Diameter: 34.9 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 15, Height: 3, Diameter: 35 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 6, Height: 12, Diameter: 35.3 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 6, Height: 9, Diameter: 35.3 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 15, Height: 3, Diameter: 35.4 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 20, Height: 3, Diameter: 35.4 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 15, Height: 3, Diameter: 35.7 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 6, Height: 9, Diameter: 36 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 10, Height: 6, Diameter: 36.1 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 15, Height: 3, Diameter: 36.1 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 6, Height: 9, Diameter: 36.4 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 6, Height: 12, Diameter: 36.5 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 20, Height: 3, Diameter: 36.6 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 6, Height: 9, Diameter: 36.8 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 6, Height: 9, Diameter: 37.1 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 10, Height: 6, Diameter: 37.1 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 6, Height: 12, Diameter: 37.4 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 6, Height: 9, Diameter: 37.5 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 6, Height: 1, Diameter: 14.5 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 6, Height: 1, Diameter: 15.8 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 6, Height: 1, Diameter: 16.8 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 6, Height: 1, Diameter: 17.4 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 6, Height: 1, Diameter: 17.9 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 6, Height: 1, Diameter: 18.4 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 6, Height: 1, Diameter: 18.7 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 10, Height: 1, Diameter: 19 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 6, Height: 1, Diameter: 19.1 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 10, Height: 1, Diameter: 20.5 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 10, Height: 1, Diameter: 21.7 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 10, Height: 1, Diameter: 22.4 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 10, Height: 1, Diameter: 23 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 15, Height: 1, Diameter: 23 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 10, Height: 1, Diameter: 23.5 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 10, Height: 1, Diameter: 23.9 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 10, Height: 1, Diameter: 24.4 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 15, Height: 1, Diameter: 24.7 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 15, Height: 1, Diameter: 26 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 20, Height: 1, Diameter: 26.2 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 6, Height: 3, Diameter: 26.5 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 15, Height: 1, Diameter: 26.8 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 15, Height: 1, Diameter: 27.4 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 6, Height: 3, Diameter: 27.8 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 20, Height: 1, Diameter: 28 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 15, Height: 1, Diameter: 28.1 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 15, Height: 1, Diameter: 28.5 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 6, Height: 3, Diameter: 28.8 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 15, Height: 1, Diameter: 29.1 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 6, Height: 3, Diameter: 29.4 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 20, Height: 1, Diameter: 29.5 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 6, Height: 3, Diameter: 29.9 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 6, Height: 3, Diameter: 30.4 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 20, Height: 1, Diameter: 30.4 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 6, Height: 3, Diameter: 30.7 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 10, Height: 3, Diameter: 31 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 6, Height: 3, Diameter: 31.1 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 20, Height: 1, Diameter: 31.1 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 20, Height: 1, Diameter: 31.7 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 6, Height: 6, Diameter: 32 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 20, Height: 1, Diameter: 32.3 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 10, Height: 3, Diameter: 32.5 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 20, Height: 1, Diameter: 32.9 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 6, Height: 6, Diameter: 33.4 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 10, Height: 3, Diameter: 33.7 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 10, Height: 3, Diameter: 34.4 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 6, Height: 6, Diameter: 34.6 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 15, Height: 3, Diameter: 35 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 6, Height: 6, Diameter: 35.4 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 10, Height: 3, Diameter: 35.5 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 6, Height: 9, Diameter: 35.6 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 10, Height: 3, Diameter: 35.9 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 6, Height: 6, Diameter: 36 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 10, Height: 3, Diameter: 36.4 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 6, Height: 6, Diameter: 36.6 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 15, Height: 3, Diameter: 36.7 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 6, Height: 6, Diameter: 37 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 6, Height: 9, Diameter: 37.3 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 6, Height: 12, Diameter: 37.4 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 10, Height: 6, Diameter: 37.4 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 6, Height: 6, Diameter: 37.5 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 20, Height: 3, Diameter: 38.2 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 6, Height: 9, Diameter: 38.6 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 15, Height: 3, Diameter: 38.8 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 10, Height: 6, Diameter: 39.1 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 6, Height: 12, Diameter: 39.4 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 15, Height: 3, Diameter: 39.4 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 6, Height: 9, Diameter: 39.5 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 20, Height: 3, Diameter: 40 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 6, Height: 9, Diameter: 40.1 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 15, Height: 3, Diameter: 40.1 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 10, Height: 6, Diameter: 40.5 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 15, Height: 3, Diameter: 40.5 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 6, Height: 12, Diameter: 40.7 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 6, Height: 9, Diameter: 40.8 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 15, Height: 3, Diameter: 41.1 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 6, Height: 9, Diameter: 41.2 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 10, Height: 6, Diameter: 41.4 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 20, Height: 3, Diameter: 41.5 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 6, Height: 12, Diameter: 41.6 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 10, Height: 9, Diameter: 41.7 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 6, Height: 9, Diameter: 41.8 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 10, Height: 6, Diameter: 42.1 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 6, Height: 12, Diameter: 42.2 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 15, Height: 6, Diameter: 42.2 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 20, Height: 3, Diameter: 42.4 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 10, Height: 6, Diameter: 42.8 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 6, Height: 12, Diameter: 42.9 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 20, Height: 3, Diameter: 43.1 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 6, Height: 12, Diameter: 43.3 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 10, Height: 6, Diameter: 43.3 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 10, Height: 9, Diameter: 43.6 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 20, Height: 3, Diameter: 43.7 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 10, Height: 12, Diameter: 43.8 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 6, Height: 12, Diameter: 43.9 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 10, Height: 6, Diameter: 43.9 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 15, Height: 6, Diameter: 44.1 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 20, Height: 3, Diameter: 44.3 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 20, Height: 3, Diameter: 44.9 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 10, Height: 9, Diameter: 45.2 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 15, Height: 6, Diameter: 45.7 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 10, Height: 12, Diameter: 46 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 20, Height: 6, Diameter: 46.1 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 10, Height: 9, Diameter: 46.2 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 15, Height: 6, Diameter: 46.8 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 10, Height: 9, Diameter: 46.9 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 15, Height: 9, Diameter: 47 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 15, Height: 6, Diameter: 47.5 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 10, Height: 12, Diameter: 47.6 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 10, Height: 9, Diameter: 47.7 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 10, Height: 9, Diameter: 48.2 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 15, Height: 6, Diameter: 48.2 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 20, Height: 6, Diameter: 48.2 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 10, Height: 12, Diameter: 48.6 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 15, Height: 6, Diameter: 48.8 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 10, Height: 9, Diameter: 48.9 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 15, Height: 9, Diameter: 49.2 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 10, Height: 12, Diameter: 49.3 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 15, Height: 12, Diameter: 49.4 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 15, Height: 6, Diameter: 49.5 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 20, Height: 6, Diameter: 49.9 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 10, Height: 12, Diameter: 50.1 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 10, Height: 12, Diameter: 50.6 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 15, Height: 9, Diameter: 51 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 20, Height: 6, Diameter: 51.1 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 10, Height: 12, Diameter: 51.3 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 20, Height: 9, Diameter: 51.3 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 15, Height: 12, Diameter: 51.6 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 20, Height: 6, Diameter: 51.9 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 15, Height: 9, Diameter: 52.1 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 20, Height: 6, Diameter: 52.7 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 15, Height: 9, Diameter: 52.9 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 20, Height: 6, Diameter: 53.3 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 15, Height: 12, Diameter: 53.7 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 20, Height: 9, Diameter: 53.7 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 15, Height: 9, Diameter: 53.8 },
    { DevCode: 21, Nozzle1: 0.078125, Pressure: 20, Height: 12, Diameter: 54 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 20, Height: 6, Diameter: 54 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 15, Height: 9, Diameter: 54.4 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 15, Height: 12, Diameter: 54.8 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 15, Height: 9, Diameter: 55.2 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 15, Height: 12, Diameter: 55.6 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 20, Height: 9, Diameter: 55.7 },
    { DevCode: 21, Nozzle1: 0.125, Pressure: 20, Height: 12, Diameter: 56.4 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 15, Height: 12, Diameter: 56.5 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 20, Height: 9, Diameter: 56.9 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 15, Height: 12, Diameter: 57.1 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 20, Height: 9, Diameter: 57.8 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 15, Height: 12, Diameter: 57.9 },
    { DevCode: 21, Nozzle1: 0.1796875, Pressure: 20, Height: 12, Diameter: 58.7 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 20, Height: 9, Diameter: 58.7 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 20, Height: 9, Diameter: 59.4 },
    { DevCode: 21, Nozzle1: 0.2265625, Pressure: 20, Height: 12, Diameter: 59.9 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 20, Height: 9, Diameter: 60.3 },
    { DevCode: 21, Nozzle1: 0.265625, Pressure: 20, Height: 12, Diameter: 60.8 },
    { DevCode: 21, Nozzle1: 0.3125, Pressure: 20, Height: 12, Diameter: 61.7 },
    { DevCode: 21, Nozzle1: 0.3515625, Pressure: 20, Height: 12, Diameter: 62.4 },
    { DevCode: 21, Nozzle1: 0.40625, Pressure: 20, Height: 12, Diameter: 63.4 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 6, Height: 1, Diameter: 11.2 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 6, Height: 1, Diameter: 12 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 6, Height: 1, Diameter: 12.6 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 6, Height: 1, Diameter: 13.1 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 6, Height: 1, Diameter: 13.4 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 6, Height: 1, Diameter: 13.7 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 6, Height: 1, Diameter: 13.9 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 6, Height: 1, Diameter: 14.2 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 10, Height: 1, Diameter: 15.8 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 10, Height: 1, Diameter: 16.8 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 10, Height: 1, Diameter: 17.5 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 10, Height: 1, Diameter: 18.1 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 10, Height: 1, Diameter: 18.4 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 10, Height: 1, Diameter: 18.8 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 10, Height: 1, Diameter: 19 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 10, Height: 1, Diameter: 19.4 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 15, Height: 1, Diameter: 20 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 15, Height: 1, Diameter: 21.1 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 15, Height: 1, Diameter: 22 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 15, Height: 1, Diameter: 22.6 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 15, Height: 1, Diameter: 23 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 6, Height: 3, Diameter: 23.2 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 15, Height: 1, Diameter: 23.4 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 20, Height: 1, Diameter: 23.4 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 15, Height: 1, Diameter: 23.7 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 6, Height: 3, Diameter: 24 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 15, Height: 1, Diameter: 24.1 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 6, Height: 3, Diameter: 24.6 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 20, Height: 1, Diameter: 24.6 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 6, Height: 3, Diameter: 25.1 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 6, Height: 3, Diameter: 25.4 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 20, Height: 1, Diameter: 25.6 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 6, Height: 3, Diameter: 25.7 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 6, Height: 3, Diameter: 25.9 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 20, Height: 3, Diameter: 37.6 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 10, Height: 6, Diameter: 37.7 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 6, Height: 12, Diameter: 38.1 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 10, Height: 6, Diameter: 38.2 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 20, Height: 3, Diameter: 38.3 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 10, Height: 6, Diameter: 38.6 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 20, Height: 3, Diameter: 38.7 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 6, Height: 12, Diameter: 38.8 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 6, Height: 12, Diameter: 39.2 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 20, Height: 3, Diameter: 39.2 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 10, Height: 6, Diameter: 39.4 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 6, Height: 12, Diameter: 39.5 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 20, Height: 3, Diameter: 39.5 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 6, Height: 12, Diameter: 39.9 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 10, Height: 9, Diameter: 39.9 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 20, Height: 3, Diameter: 40 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 15, Height: 6, Diameter: 40.1 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 10, Height: 9, Diameter: 41.3 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 15, Height: 6, Diameter: 41.6 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 10, Height: 12, Diameter: 42.3 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 10, Height: 9, Diameter: 42.4 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 15, Height: 6, Diameter: 42.7 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 10, Height: 9, Diameter: 43.1 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 15, Height: 6, Diameter: 43.4 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 10, Height: 9, Diameter: 43.6 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 15, Height: 6, Diameter: 43.9 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 10, Height: 12, Diameter: 44 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 10, Height: 9, Diameter: 44.1 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 15, Height: 6, Diameter: 44.4 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 10, Height: 9, Diameter: 44.5 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 20, Height: 6, Diameter: 44.5 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 15, Height: 6, Diameter: 44.8 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 10, Height: 9, Diameter: 45 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 10, Height: 12, Diameter: 45.1 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 15, Height: 6, Diameter: 45.3 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 10, Height: 12, Diameter: 45.8 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 15, Height: 9, Diameter: 45.9 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 20, Height: 6, Diameter: 46 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 10, Height: 12, Diameter: 46.3 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 10, Height: 12, Diameter: 46.8 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 10, Height: 12, Diameter: 47.2 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 20, Height: 6, Diameter: 47.3 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 15, Height: 9, Diameter: 47.5 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 10, Height: 12, Diameter: 47.7 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 20, Height: 6, Diameter: 48.1 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 20, Height: 6, Diameter: 48.6 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 15, Height: 9, Diameter: 48.8 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 15, Height: 12, Diameter: 48.9 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 20, Height: 6, Diameter: 49.2 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 15, Height: 9, Diameter: 49.6 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 20, Height: 6, Diameter: 49.7 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 15, Height: 9, Diameter: 50.2 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 20, Height: 6, Diameter: 50.2 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 15, Height: 12, Diameter: 50.5 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 15, Height: 9, Diameter: 50.8 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 20, Height: 9, Diameter: 50.8 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 15, Height: 9, Diameter: 51.2 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 15, Height: 12, Diameter: 51.8 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 15, Height: 9, Diameter: 51.8 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 15, Height: 12, Diameter: 52.6 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 20, Height: 9, Diameter: 52.6 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 15, Height: 12, Diameter: 53.2 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 15, Height: 12, Diameter: 54.1 },
    { DevCode: 22, Nozzle1: 0.078125, Pressure: 20, Height: 12, Diameter: 54.1 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 15, Height: 12, Diameter: 54.5 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 20, Height: 9, Diameter: 54.9 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 15, Height: 12, Diameter: 55.1 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 20, Height: 9, Diameter: 55.6 },
    { DevCode: 22, Nozzle1: 0.125, Pressure: 20, Height: 12, Diameter: 55.9 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 20, Height: 9, Diameter: 56.2 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 20, Height: 9, Diameter: 56.7 },
    { DevCode: 22, Nozzle1: 0.1796875, Pressure: 20, Height: 12, Diameter: 57.3 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 20, Height: 9, Diameter: 57.3 },
    { DevCode: 22, Nozzle1: 0.2265625, Pressure: 20, Height: 12, Diameter: 58.2 },
    { DevCode: 22, Nozzle1: 0.265625, Pressure: 20, Height: 12, Diameter: 59.2 },
    { DevCode: 22, Nozzle1: 0.3125, Pressure: 20, Height: 12, Diameter: 59.8 },
    { DevCode: 22, Nozzle1: 0.3515625, Pressure: 20, Height: 12, Diameter: 60.3 },
    { DevCode: 22, Nozzle1: 0.40625, Pressure: 20, Height: 12, Diameter: 60.9 },
    { DevCode: 30, Nozzle1: 0.0625, Pressure: 6, Height: 1, Diameter: 5.8 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 10, Height: 1, Diameter: 6.5 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 6, Height: 1, Diameter: 6.7 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 6, Height: 1, Diameter: 7.4 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 15, Height: 1, Diameter: 7.7 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 6, Height: 1, Diameter: 8.1 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 10, Height: 1, Diameter: 8.3 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 6, Height: 1, Diameter: 8.6 },
    { DevCode: 30, Nozzle1: 0.078125, Pressure: 20, Height: 1, Diameter: 8.8 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 6, Height: 1, Diameter: 9 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 10, Height: 1, Diameter: 9.4 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 6, Height: 1, Diameter: 9.5 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 15, Height: 1, Diameter: 9.7 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 6, Height: 1, Diameter: 9.9 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 20, Height: 9, Diameter: 45.3 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 20, Height: 6, Diameter: 45.4 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 15, Height: 6, Diameter: 45.9 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 10, Height: 9, Diameter: 46.2 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 20, Height: 3, Diameter: 46.5 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 10, Height: 12, Diameter: 46.8 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 10, Height: 9, Diameter: 48.1 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 15, Height: 12, Diameter: 48.3 },
    { DevCode: 30, Nozzle1: 0.125, Pressure: 20, Height: 12, Diameter: 48.3 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 15, Height: 6, Diameter: 48.5 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 20, Height: 3, Diameter: 48.8 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 15, Height: 9, Diameter: 49 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 20, Height: 6, Diameter: 49.1 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 10, Height: 12, Diameter: 49.2 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 15, Height: 6, Diameter: 50.4 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 10, Height: 9, Diameter: 50.5 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 10, Height: 12, Diameter: 51.1 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 20, Height: 9, Diameter: 51.1 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 15, Height: 9, Diameter: 51.7 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 20, Height: 6, Diameter: 51.8 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 15, Height: 12, Diameter: 52 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 15, Height: 6, Diameter: 52.9 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 10, Height: 12, Diameter: 53.5 },
    { DevCode: 30, Nozzle1: 0.1796875, Pressure: 20, Height: 12, Diameter: 54.1 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 15, Height: 9, Diameter: 54.6 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 15, Height: 12, Diameter: 54.7 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 20, Height: 6, Diameter: 54.7 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 20, Height: 9, Diameter: 55.3 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 15, Height: 9, Diameter: 56.7 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 20, Height: 6, Diameter: 56.9 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 15, Height: 12, Diameter: 57.6 },
    { DevCode: 30, Nozzle1: 0.2265625, Pressure: 20, Height: 12, Diameter: 58.3 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 20, Height: 9, Diameter: 58.3 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 15, Height: 9, Diameter: 59.6 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 15, Height: 12, Diameter: 59.7 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 20, Height: 6, Diameter: 59.7 },
    { DevCode: 30, Nozzle1: 0.265625, Pressure: 20, Height: 12, Diameter: 61.3 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 20, Height: 9, Diameter: 61.6 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 15, Height: 12, Diameter: 62.6 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 20, Height: 9, Diameter: 64.1 },
    { DevCode: 30, Nozzle1: 0.3125, Pressure: 20, Height: 12, Diameter: 64.6 },
    { DevCode: 30, Nozzle1: 0.3515625, Pressure: 20, Height: 12, Diameter: 67.1 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 20, Height: 9, Diameter: 67.2 },
    { DevCode: 30, Nozzle1: 0.40625, Pressure: 20, Height: 12, Diameter: 70.2 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 10, Height: 1, Diameter: 6.2 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 6, Height: 1, Diameter: 6.3 },
    { DevCode: 31, Nozzle1: 0.1796875, Pressure: 6, Height: 1, Diameter: 7.1 },
    { DevCode: 31, Nozzle1: 0.0625, Pressure: 6, Height: 1, Diameter: 7.3 },
    { DevCode: 31, Nozzle1: 0.078125, Pressure: 15, Height: 1, Diameter: 7.3 },
    { DevCode: 31, Nozzle1: 0.2265625, Pressure: 6, Height: 1, Diameter: 7.7 },
    { DevCode: 31, Nozzle1: 0.125, Pressure: 10, Height: 1, Diameter: 7.7 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 20, Height: 9, Diameter: 64.8 },
    { DevCode: 31, Nozzle1: 0.40625, Pressure: 20, Height: 12, Diameter: 67.8 },
    { DevCode: 31, Nozzle1: 0.3515625, Pressure: 20, Height: 1, Diameter: 145 },
    { DevCode: 33, Nozzle1: 0.0625, Pressure: 6, Height: 1, Diameter: 6.1 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 6, Height: 1, Diameter: 6.5 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 6, Height: 1, Diameter: 7.1 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 10, Height: 1, Diameter: 7.4 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 6, Height: 1, Diameter: 7.6 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 6, Height: 1, Diameter: 8 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 10, Height: 1, Diameter: 8 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 6, Height: 1, Diameter: 8.2 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 15, Height: 1, Diameter: 8.4 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 6, Height: 1, Diameter: 8.8 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 10, Height: 1, Diameter: 9 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 10, Height: 1, Diameter: 9.4 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 6, Height: 1, Diameter: 9.6 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 20, Height: 1, Diameter: 9.6 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 10, Height: 1, Diameter: 9.8 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 15, Height: 1, Diameter: 9.9 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 10, Height: 1, Diameter: 10.2 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 10, Height: 1, Diameter: 10.4 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 15, Height: 1, Diameter: 10.8 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 10, Height: 1, Diameter: 11 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 20, Height: 1, Diameter: 11 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 15, Height: 1, Diameter: 11.1 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 15, Height: 1, Diameter: 11.6 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 15, Height: 1, Diameter: 11.9 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 20, Height: 1, Diameter: 12.1 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 15, Height: 1, Diameter: 12.4 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 20, Height: 1, Diameter: 12.7 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 15, Height: 1, Diameter: 13 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 20, Height: 1, Diameter: 13.2 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 20, Height: 1, Diameter: 13.6 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 20, Height: 1, Diameter: 14.1 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 20, Height: 1, Diameter: 14.4 },
    { DevCode: 33, Nozzle1: 0.0625, Pressure: 6, Height: 3, Diameter: 17.1 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 6, Height: 3, Diameter: 19.9 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 10, Height: 3, Diameter: 21.4 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 6, Height: 3, Diameter: 21.5 },
    { DevCode: 33, Nozzle1: 0.0625, Pressure: 6, Height: 6, Diameter: 21.9 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 6, Height: 3, Diameter: 22.6 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 6, Height: 3, Diameter: 23.4 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 6, Height: 3, Diameter: 24.2 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 6, Height: 3, Diameter: 24.8 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 10, Height: 3, Diameter: 24.8 },
    { DevCode: 33, Nozzle1: 0.0625, Pressure: 6, Height: 9, Diameter: 25.4 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 15, Height: 3, Diameter: 25.4 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 6, Height: 6, Diameter: 25.5 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 6, Height: 3, Diameter: 25.6 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 10, Height: 3, Diameter: 26.8 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 10, Height: 6, Diameter: 27.4 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 20, Height: 9, Diameter: 58.1 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 15, Height: 12, Diameter: 59.2 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 20, Height: 12, Diameter: 59.2 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 20, Height: 9, Diameter: 60.2 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 20, Height: 12, Diameter: 61.1 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 20, Height: 9, Diameter: 61.7 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 20, Height: 12, Diameter: 63.2 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 20, Height: 9, Diameter: 63.7 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 20, Height: 12, Diameter: 64.7 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 20, Height: 12, Diameter: 66.7 },
    { DevCode: 32, Nozzle1: 0.0625, Pressure: 6, Height: 1, Diameter: 5.3 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 6, Height: 1, Diameter: 6.4 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 6, Height: 1, Diameter: 6.8 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 10, Height: 1, Diameter: 6.8 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 15, Height: 1, Diameter: 7.1 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 6, Height: 1, Diameter: 7.4 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 6, Height: 1, Diameter: 7.4 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 10, Height: 1, Diameter: 7.6 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 6, Height: 1, Diameter: 7.7 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 6, Height: 1, Diameter: 8.1 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 10, Height: 1, Diameter: 8.8 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 20, Height: 1, Diameter: 8.8 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 6, Height: 1, Diameter: 9 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 10, Height: 1, Diameter: 9.4 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 15, Height: 1, Diameter: 9.5 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 20, Height: 1, Diameter: 9.7 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 15, Height: 1, Diameter: 10.1 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 10, Height: 1, Diameter: 10.5 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 10, Height: 1, Diameter: 10.7 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 20, Height: 1, Diameter: 10.7 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 15, Height: 1, Diameter: 10.9 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 10, Height: 1, Diameter: 11.2 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 15, Height: 1, Diameter: 11.3 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 10, Height: 1, Diameter: 11.7 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 20, Height: 1, Diameter: 11.8 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 15, Height: 1, Diameter: 11.9 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 20, Height: 1, Diameter: 12.2 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 15, Height: 1, Diameter: 12.7 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 20, Height: 1, Diameter: 12.8 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 15, Height: 1, Diameter: 13 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 20, Height: 1, Diameter: 13 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 20, Height: 1, Diameter: 13.5 },
    { DevCode: 32, Nozzle1: 0.0625, Pressure: 6, Height: 3, Diameter: 15.3 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 6, Height: 3, Diameter: 18.4 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 10, Height: 3, Diameter: 18.8 },
    { DevCode: 32, Nozzle1: 0.0625, Pressure: 6, Height: 6, Diameter: 19.6 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 6, Height: 3, Diameter: 20.2 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 6, Height: 3, Diameter: 21.4 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 15, Height: 3, Diameter: 22.1 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 6, Height: 3, Diameter: 22.4 },
    { DevCode: 32, Nozzle1: 0.0625, Pressure: 6, Height: 9, Diameter: 22.6 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 10, Height: 3, Diameter: 22.6 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 6, Height: 3, Diameter: 23.3 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 6, Height: 6, Diameter: 23.4 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 6, Height: 3, Diameter: 24.1 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 10, Height: 6, Diameter: 24.1 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 10, Height: 3, Diameter: 24.8 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 20, Height: 3, Diameter: 24.8 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 6, Height: 3, Diameter: 25 },
    { DevCode: 32, Nozzle1: 0.0625, Pressure: 6, Height: 12, Diameter: 25.6 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 6, Height: 6, Diameter: 25.8 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 10, Height: 3, Diameter: 26.4 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 15, Height: 3, Diameter: 26.5 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 6, Height: 9, Diameter: 27.1 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 6, Height: 6, Diameter: 27.4 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 10, Height: 3, Diameter: 27.5 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 10, Height: 9, Diameter: 27.8 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 15, Height: 6, Diameter: 28.2 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 6, Height: 6, Diameter: 28.6 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 10, Height: 3, Diameter: 28.7 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 10, Height: 6, Diameter: 28.8 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 15, Height: 3, Diameter: 29.1 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 10, Height: 3, Diameter: 29.6 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 6, Height: 9, Diameter: 29.7 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 20, Height: 3, Diameter: 29.7 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 6, Height: 6, Diameter: 29.8 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 6, Height: 12, Diameter: 30.1 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 6, Height: 6, Diameter: 30.7 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 10, Height: 3, Diameter: 30.7 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 10, Height: 12, Diameter: 30.8 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 15, Height: 3, Diameter: 30.9 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 6, Height: 9, Diameter: 31.6 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 10, Height: 6, Diameter: 31.7 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 20, Height: 6, Diameter: 31.7 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 6, Height: 6, Diameter: 31.9 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 15, Height: 3, Diameter: 32.3 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 15, Height: 9, Diameter: 32.6 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 6, Height: 12, Diameter: 32.7 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 20, Height: 3, Diameter: 32.7 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 6, Height: 9, Diameter: 32.9 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 10, Height: 9, Diameter: 33.3 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 10, Height: 6, Diameter: 33.7 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 15, Height: 3, Diameter: 33.7 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 15, Height: 6, Diameter: 33.8 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 6, Height: 9, Diameter: 34.4 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 6, Height: 12, Diameter: 34.6 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 15, Height: 3, Diameter: 34.7 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 20, Height: 3, Diameter: 34.8 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 10, Height: 6, Diameter: 35.1 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 6, Height: 9, Diameter: 35.5 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 15, Height: 12, Diameter: 35.6 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 6, Height: 12, Diameter: 35.9 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 15, Height: 3, Diameter: 36 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 20, Height: 3, Diameter: 36.2 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 10, Height: 12, Diameter: 36.3 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 10, Height: 9, Diameter: 36.6 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 20, Height: 9, Diameter: 36.6 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 10, Height: 6, Diameter: 36.7 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 6, Height: 9, Diameter: 36.8 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 15, Height: 6, Diameter: 37.2 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 6, Height: 12, Diameter: 37.4 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 10, Height: 6, Diameter: 37.8 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 20, Height: 3, Diameter: 37.8 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 20, Height: 6, Diameter: 38 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 6, Height: 12, Diameter: 38.5 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 10, Height: 9, Diameter: 38.9 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 15, Height: 9, Diameter: 39 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 20, Height: 3, Diameter: 39 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 10, Height: 6, Diameter: 39.3 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 15, Height: 6, Diameter: 39.5 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 10, Height: 12, Diameter: 39.6 },
    { DevCode: 32, Nozzle1: 0.078125, Pressure: 20, Height: 12, Diameter: 39.6 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 6, Height: 12, Diameter: 39.8 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 10, Height: 9, Diameter: 40.5 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 20, Height: 3, Diameter: 40.5 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 15, Height: 6, Diameter: 41.2 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 20, Height: 6, Diameter: 41.8 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 10, Height: 12, Diameter: 41.9 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 15, Height: 12, Diameter: 42 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 10, Height: 9, Diameter: 42.3 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 15, Height: 9, Diameter: 42.9 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 15, Height: 6, Diameter: 43 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 10, Height: 12, Diameter: 43.5 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 10, Height: 9, Diameter: 43.6 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 20, Height: 9, Diameter: 43.8 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 15, Height: 6, Diameter: 44.3 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 20, Height: 6, Diameter: 44.4 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 10, Height: 12, Diameter: 45.3 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 10, Height: 9, Diameter: 45.3 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 15, Height: 9, Diameter: 45.6 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 15, Height: 12, Diameter: 45.9 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 20, Height: 6, Diameter: 46.3 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 10, Height: 12, Diameter: 46.6 },
    { DevCode: 32, Nozzle1: 0.125, Pressure: 20, Height: 12, Diameter: 46.8 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 15, Height: 9, Diameter: 47.5 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 20, Height: 9, Diameter: 48.2 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 10, Height: 12, Diameter: 48.3 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 20, Height: 6, Diameter: 48.3 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 15, Height: 12, Diameter: 48.6 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 15, Height: 9, Diameter: 49.6 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 20, Height: 6, Diameter: 49.8 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 15, Height: 12, Diameter: 50.5 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 15, Height: 9, Diameter: 51.2 },
    { DevCode: 32, Nozzle1: 0.1796875, Pressure: 20, Height: 12, Diameter: 51.2 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 20, Height: 9, Diameter: 51.2 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 20, Height: 6, Diameter: 51.7 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 15, Height: 12, Diameter: 52.6 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 15, Height: 9, Diameter: 53.1 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 20, Height: 9, Diameter: 53.4 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 15, Height: 12, Diameter: 54.2 },
    { DevCode: 32, Nozzle1: 0.2265625, Pressure: 20, Height: 12, Diameter: 54.2 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 20, Height: 9, Diameter: 55.7 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 15, Height: 12, Diameter: 56.1 },
    { DevCode: 32, Nozzle1: 0.265625, Pressure: 20, Height: 12, Diameter: 56.4 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 20, Height: 9, Diameter: 57.5 },
    { DevCode: 32, Nozzle1: 0.3125, Pressure: 20, Height: 12, Diameter: 58.7 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 20, Height: 9, Diameter: 59.7 },
    { DevCode: 32, Nozzle1: 0.3515625, Pressure: 20, Height: 12, Diameter: 60.5 },
    { DevCode: 32, Nozzle1: 0.40625, Pressure: 20, Height: 12, Diameter: 62.7 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 6, Height: 6, Diameter: 27.5 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 10, Height: 3, Diameter: 28.2 },
    { DevCode: 33, Nozzle1: 0.0625, Pressure: 6, Height: 12, Diameter: 28.4 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 20, Height: 3, Diameter: 28.8 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 6, Height: 6, Diameter: 28.9 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 10, Height: 3, Diameter: 29.2 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 6, Height: 9, Diameter: 29.4 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 15, Height: 3, Diameter: 29.5 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 6, Height: 6, Diameter: 30 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 10, Height: 3, Diameter: 30.2 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 6, Height: 6, Diameter: 31 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 10, Height: 3, Diameter: 31 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 10, Height: 9, Diameter: 31.7 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 6, Height: 9, Diameter: 31.8 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 6, Height: 6, Diameter: 31.8 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 10, Height: 6, Diameter: 31.8 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 15, Height: 3, Diameter: 31.8 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 6, Height: 12, Diameter: 32.4 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 15, Height: 6, Diameter: 32.5 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 6, Height: 6, Diameter: 32.8 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 20, Height: 3, Diameter: 33.4 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 6, Height: 9, Diameter: 33.5 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 15, Height: 3, Diameter: 33.5 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 10, Height: 6, Diameter: 34.4 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 6, Height: 9, Diameter: 34.6 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 15, Height: 3, Diameter: 34.6 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 10, Height: 12, Diameter: 34.7 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 6, Height: 12, Diameter: 34.8 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 6, Height: 9, Diameter: 35.9 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 15, Height: 3, Diameter: 35.9 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 10, Height: 6, Diameter: 36.1 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 20, Height: 3, Diameter: 36.1 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 6, Height: 12, Diameter: 36.5 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 6, Height: 9, Diameter: 36.8 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 10, Height: 9, Diameter: 36.8 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 15, Height: 3, Diameter: 36.8 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 20, Height: 6, Diameter: 36.8 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 10, Height: 6, Diameter: 37.4 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 6, Height: 12, Diameter: 37.6 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 15, Height: 9, Diameter: 37.6 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 15, Height: 6, Diameter: 37.7 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 6, Height: 9, Diameter: 37.9 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 20, Height: 3, Diameter: 37.9 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 15, Height: 3, Diameter: 38 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 10, Height: 6, Diameter: 38.7 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 6, Height: 12, Diameter: 38.9 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 20, Height: 3, Diameter: 39.2 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 10, Height: 9, Diameter: 39.7 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 10, Height: 6, Diameter: 39.7 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 6, Height: 12, Diameter: 39.8 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 10, Height: 12, Diameter: 39.8 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 15, Height: 12, Diameter: 40.6 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 20, Height: 3, Diameter: 40.6 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 15, Height: 6, Diameter: 40.8 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 6, Height: 12, Diameter: 40.9 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 10, Height: 6, Diameter: 41 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 20, Height: 3, Diameter: 41.7 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 10, Height: 9, Diameter: 41.8 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 20, Height: 9, Diameter: 42.6 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 10, Height: 12, Diameter: 42.7 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 20, Height: 6, Diameter: 42.7 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 15, Height: 6, Diameter: 42.9 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 20, Height: 3, Diameter: 43 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 10, Height: 9, Diameter: 43.2 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 15, Height: 9, Diameter: 43.6 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 15, Height: 6, Diameter: 44.4 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 10, Height: 12, Diameter: 44.8 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 10, Height: 9, Diameter: 44.8 },
    { DevCode: 33, Nozzle1: 0.078125, Pressure: 20, Height: 12, Diameter: 45.6 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 10, Height: 9, Diameter: 45.9 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 10, Height: 12, Diameter: 46.2 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 20, Height: 6, Diameter: 46.2 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 15, Height: 12, Diameter: 46.6 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 15, Height: 6, Diameter: 47.1 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 15, Height: 9, Diameter: 47.2 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 10, Height: 9, Diameter: 47.4 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 10, Height: 12, Diameter: 47.8 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 15, Height: 6, Diameter: 48.6 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 20, Height: 6, Diameter: 48.6 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 10, Height: 12, Diameter: 48.9 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 20, Height: 9, Diameter: 49.4 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 15, Height: 9, Diameter: 49.6 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 15, Height: 12, Diameter: 50.2 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 20, Height: 6, Diameter: 50.3 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 10, Height: 12, Diameter: 50.4 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 15, Height: 9, Diameter: 51.3 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 20, Height: 6, Diameter: 52.1 },
    { DevCode: 33, Nozzle1: 0.125, Pressure: 20, Height: 12, Diameter: 52.4 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 15, Height: 12, Diameter: 52.6 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 15, Height: 9, Diameter: 53.1 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 20, Height: 9, Diameter: 53.4 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 20, Height: 6, Diameter: 53.4 },
    { DevCode: 33, Nozzle1: 0.265625, Pressure: 15, Height: 12, Diameter: 54.3 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 15, Height: 9, Diameter: 54.5 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 20, Height: 6, Diameter: 55.1 },
    { DevCode: 33, Nozzle1: 0.3125, Pressure: 15, Height: 12, Diameter: 56.1 },
    { DevCode: 33, Nozzle1: 0.40625, Pressure: 15, Height: 9, Diameter: 56.2 },
    { DevCode: 33, Nozzle1: 0.2265625, Pressure: 20, Height: 9, Diameter: 56.2 },
    { DevCode: 33, Nozzle1: 0.1796875, Pressure: 20, Height: 12, Diameter: 56.4 },
    { DevCode: 33, Nozzle1: 0.3515625, Pressure: 15, Height: 12, Diameter: 57.5 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 6, Height: 1, Diameter: 15.5 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 10, Height: 1, Diameter: 20.3 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 15, Height: 1, Diameter: 24 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 20, Height: 1, Diameter: 28.1 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 6, Height: 2, Diameter: 21.5 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 10, Height: 2, Diameter: 26.3 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 15, Height: 2, Diameter: 30.3 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 20, Height: 2, Diameter: 34.1 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 6, Height: 3, Diameter: 27.5 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 10, Height: 3, Diameter: 32.3 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 15, Height: 3, Diameter: 36.6 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 20, Height: 3, Diameter: 40.1 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 6, Height: 4, Diameter: 29 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 10, Height: 4, Diameter: 34 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 15, Height: 4, Diameter: 38.5 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 20, Height: 4, Diameter: 42.3 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 6, Height: 5, Diameter: 30.5 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 10, Height: 5, Diameter: 35.8 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 15, Height: 5, Diameter: 40.5 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 20, Height: 5, Diameter: 44.4 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 6, Height: 6, Diameter: 31.9 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 10, Height: 6, Diameter: 37.6 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 15, Height: 6, Diameter: 42.5 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 20, Height: 6, Diameter: 46.6 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 6, Height: 7, Diameter: 32.9 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 10, Height: 7, Diameter: 38.7 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 15, Height: 7, Diameter: 43.8 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 20, Height: 7, Diameter: 48 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 6, Height: 8, Diameter: 33.9 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 10, Height: 8, Diameter: 39.8 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 15, Height: 8, Diameter: 45.1 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 20, Height: 8, Diameter: 49.5 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 6, Height: 9, Diameter: 34.9 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 10, Height: 9, Diameter: 41 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 15, Height: 9, Diameter: 46.4 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 20, Height: 9, Diameter: 50.9 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 6, Height: 10, Diameter: 35.9 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 10, Height: 10, Diameter: 42 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 15, Height: 10, Diameter: 47 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 20, Height: 10, Diameter: 51.3 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 6, Height: 11, Diameter: 36.4 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 10, Height: 11, Diameter: 42.5 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 15, Height: 11, Diameter: 47.5 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 20, Height: 11, Diameter: 51.7 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 6, Height: 12, Diameter: 36.9 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 10, Height: 12, Diameter: 42.5 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 15, Height: 12, Diameter: 48 },
    { DevCode: 26, Nozzle1: 0.078125, Pressure: 20, Height: 12, Diameter: 52 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 6, Height: 1, Diameter: 17.2 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 10, Height: 1, Diameter: 22.3 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 15, Height: 1, Diameter: 26 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 20, Height: 1, Diameter: 30.5 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 6, Height: 2, Diameter: 23.2 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 10, Height: 2, Diameter: 28.3 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 15, Height: 2, Diameter: 32.4 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 20, Height: 2, Diameter: 36.5 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 6, Height: 3, Diameter: 29.2 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 10, Height: 3, Diameter: 34.3 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 15, Height: 3, Diameter: 38.8 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 20, Height: 3, Diameter: 42.5 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 6, Height: 4, Diameter: 30.7 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 10, Height: 4, Diameter: 36.1 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 15, Height: 4, Diameter: 40.9 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 20, Height: 4, Diameter: 44.8 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 6, Height: 5, Diameter: 32.3 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 10, Height: 5, Diameter: 38 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 15, Height: 5, Diameter: 43 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 20, Height: 5, Diameter: 47.1 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 6, Height: 6, Diameter: 33.9 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 10, Height: 6, Diameter: 39.8 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 15, Height: 6, Diameter: 45.1 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 20, Height: 6, Diameter: 49.4 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 6, Height: 7, Diameter: 35 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 10, Height: 7, Diameter: 41 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 15, Height: 7, Diameter: 46.5 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 20, Height: 7, Diameter: 51 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 6, Height: 8, Diameter: 36 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 10, Height: 8, Diameter: 42.3 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 15, Height: 8, Diameter: 47.9 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 20, Height: 8, Diameter: 52.5 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 6, Height: 9, Diameter: 37 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 10, Height: 9, Diameter: 43.5 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 15, Height: 9, Diameter: 49.3 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 20, Height: 9, Diameter: 54 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 6, Height: 10, Diameter: 38 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 10, Height: 10, Diameter: 44.5 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 15, Height: 10, Diameter: 50 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 20, Height: 10, Diameter: 54.7 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 6, Height: 11, Diameter: 39 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 10, Height: 11, Diameter: 45.5 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 15, Height: 11, Diameter: 50.5 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 20, Height: 11, Diameter: 55.4 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 6, Height: 12, Diameter: 40 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 10, Height: 12, Diameter: 46 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 15, Height: 12, Diameter: 51 },
    { DevCode: 26, Nozzle1: 0.125, Pressure: 20, Height: 12, Diameter: 56 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 6, Height: 1, Diameter: 18.5 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 10, Height: 1, Diameter: 24 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 15, Height: 1, Diameter: 28 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 20, Height: 1, Diameter: 32.5 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 6, Height: 2, Diameter: 24.5 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 10, Height: 2, Diameter: 30 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 15, Height: 2, Diameter: 34.3 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 20, Height: 2, Diameter: 38.5 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 6, Height: 3, Diameter: 30.5 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 10, Height: 3, Diameter: 35.9 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 15, Height: 3, Diameter: 40.6 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 20, Height: 3, Diameter: 44.5 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 6, Height: 4, Diameter: 32.2 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 10, Height: 4, Diameter: 37.8 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 15, Height: 4, Diameter: 42.7 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 20, Height: 4, Diameter: 46.9 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 6, Height: 5, Diameter: 33.8 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 10, Height: 5, Diameter: 39.8 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 15, Height: 5, Diameter: 44.9 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 20, Height: 5, Diameter: 49.3 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 6, Height: 6, Diameter: 35.5 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 10, Height: 6, Diameter: 41.7 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 15, Height: 6, Diameter: 47.2 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 20, Height: 6, Diameter: 51.7 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 6, Height: 7, Diameter: 36.6 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 10, Height: 7, Diameter: 43 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 15, Height: 7, Diameter: 48.6 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 20, Height: 7, Diameter: 53.3 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 6, Height: 8, Diameter: 37.7 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 10, Height: 8, Diameter: 44.3 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 15, Height: 8, Diameter: 50.1 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 20, Height: 8, Diameter: 54.9 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 6, Height: 9, Diameter: 38.8 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 10, Height: 9, Diameter: 45.6 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 15, Height: 9, Diameter: 51.6 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 20, Height: 9, Diameter: 56.5 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 6, Height: 10, Diameter: 39.9 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 10, Height: 10, Diameter: 47 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 15, Height: 10, Diameter: 53 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 20, Height: 10, Diameter: 57.4 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 6, Height: 11, Diameter: 41 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 10, Height: 11, Diameter: 48.5 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 15, Height: 11, Diameter: 54 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 20, Height: 11, Diameter: 58.2 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 6, Height: 12, Diameter: 42 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 10, Height: 12, Diameter: 49 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 15, Height: 12, Diameter: 55 },
    { DevCode: 26, Nozzle1: 0.1796875, Pressure: 20, Height: 12, Diameter: 59 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 6, Height: 1, Diameter: 19.4 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 10, Height: 1, Diameter: 25 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 15, Height: 1, Diameter: 30 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 20, Height: 1, Diameter: 33 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 6, Height: 2, Diameter: 25.4 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 10, Height: 2, Diameter: 31 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 15, Height: 2, Diameter: 35.9 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 20, Height: 2, Diameter: 39.4 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 6, Height: 3, Diameter: 31.4 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 10, Height: 3, Diameter: 37 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 15, Height: 3, Diameter: 41.8 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 20, Height: 3, Diameter: 45.8 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 6, Height: 4, Diameter: 33.1 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 10, Height: 4, Diameter: 39 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 15, Height: 4, Diameter: 44.1 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 20, Height: 4, Diameter: 48.3 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 6, Height: 5, Diameter: 34.8 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 10, Height: 5, Diameter: 41 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 15, Height: 5, Diameter: 46.4 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 20, Height: 5, Diameter: 50.8 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 6, Height: 6, Diameter: 36.5 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 10, Height: 6, Diameter: 43 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 15, Height: 6, Diameter: 48.7 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 20, Height: 6, Diameter: 53.3 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 6, Height: 7, Diameter: 37.7 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 10, Height: 7, Diameter: 44.3 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 15, Height: 7, Diameter: 50.3 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 20, Height: 7, Diameter: 55 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 6, Height: 8, Diameter: 38.8 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 10, Height: 8, Diameter: 45.6 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 15, Height: 8, Diameter: 51.7 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 20, Height: 8, Diameter: 56.6 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 6, Height: 9, Diameter: 39.9 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 10, Height: 9, Diameter: 46.9 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 15, Height: 9, Diameter: 53.1 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 20, Height: 9, Diameter: 58.2 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 6, Height: 10, Diameter: 41 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 10, Height: 10, Diameter: 48 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 15, Height: 10, Diameter: 54 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 20, Height: 10, Diameter: 58.5 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 6, Height: 11, Diameter: 42 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 10, Height: 11, Diameter: 49 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 15, Height: 11, Diameter: 54.5 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 20, Height: 11, Diameter: 58.8 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 6, Height: 12, Diameter: 43 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 15, Height: 12, Diameter: 55 },
    { DevCode: 26, Nozzle1: 0.2265625, Pressure: 20, Height: 12, Diameter: 59 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 6, Height: 1, Diameter: 20 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 10, Height: 1, Diameter: 26 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 15, Height: 1, Diameter: 30.9 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 20, Height: 1, Diameter: 34 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 6, Height: 2, Diameter: 26 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 10, Height: 2, Diameter: 32 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 15, Height: 2, Diameter: 36.8 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 20, Height: 2, Diameter: 40.4 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 6, Height: 3, Diameter: 32.1 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 10, Height: 3, Diameter: 37.7 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 15, Height: 3, Diameter: 42.7 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 20, Height: 3, Diameter: 46.8 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 6, Height: 4, Diameter: 33.8 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 10, Height: 4, Diameter: 39.7 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 15, Height: 4, Diameter: 45 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 20, Height: 4, Diameter: 49.3 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 6, Height: 5, Diameter: 35.5 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 10, Height: 5, Diameter: 41.8 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 15, Height: 5, Diameter: 47.3 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 20, Height: 5, Diameter: 51.9 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 6, Height: 6, Diameter: 37.3 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 10, Height: 6, Diameter: 43.8 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 15, Height: 6, Diameter: 49.6 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 20, Height: 6, Diameter: 54.4 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 6, Height: 7, Diameter: 38.5 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 10, Height: 7, Diameter: 45.2 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 15, Height: 7, Diameter: 51.1 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 20, Height: 7, Diameter: 56 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 6, Height: 8, Diameter: 39.6 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 10, Height: 8, Diameter: 46.5 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 15, Height: 8, Diameter: 52.6 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 20, Height: 8, Diameter: 57.7 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 6, Height: 9, Diameter: 40.7 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 10, Height: 9, Diameter: 47.9 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 15, Height: 9, Diameter: 54.2 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 20, Height: 9, Diameter: 59.4 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 6, Height: 10, Diameter: 41.7 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 10, Height: 10, Diameter: 49 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 15, Height: 10, Diameter: 55 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 20, Height: 10, Diameter: 60 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 6, Height: 11, Diameter: 42.6 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 10, Height: 11, Diameter: 50 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 15, Height: 11, Diameter: 55.5 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 20, Height: 11, Diameter: 60.5 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 6, Height: 12, Diameter: 43.5 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 10, Height: 12, Diameter: 51 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 15, Height: 12, Diameter: 56 },
    { DevCode: 26, Nozzle1: 0.265625, Pressure: 20, Height: 12, Diameter: 61 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 6, Height: 1, Diameter: 20.1 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 10, Height: 1, Diameter: 26.5 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 15, Height: 1, Diameter: 31.6 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 20, Height: 1, Diameter: 34.9 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 6, Height: 2, Diameter: 26.4 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 10, Height: 2, Diameter: 32.5 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 15, Height: 2, Diameter: 37.6 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 20, Height: 2, Diameter: 41.3 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 6, Height: 3, Diameter: 32.7 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 10, Height: 3, Diameter: 38.5 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 15, Height: 3, Diameter: 43.6 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 20, Height: 3, Diameter: 47.7 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 6, Height: 4, Diameter: 34.5 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 10, Height: 4, Diameter: 40.5 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 15, Height: 4, Diameter: 46 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 20, Height: 4, Diameter: 50.3 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 6, Height: 5, Diameter: 36.3 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 10, Height: 5, Diameter: 42.6 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 15, Height: 5, Diameter: 48.3 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 20, Height: 5, Diameter: 52.9 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 6, Height: 6, Diameter: 38.1 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 10, Height: 6, Diameter: 44.7 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 15, Height: 6, Diameter: 50.7 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 20, Height: 6, Diameter: 55.5 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 6, Height: 7, Diameter: 39.3 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 10, Height: 7, Diameter: 46.1 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 15, Height: 7, Diameter: 52.3 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 20, Height: 7, Diameter: 57.2 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 6, Height: 8, Diameter: 40.4 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 10, Height: 8, Diameter: 47.5 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 15, Height: 8, Diameter: 53.8 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 20, Height: 8, Diameter: 58.9 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 6, Height: 9, Diameter: 41.6 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 10, Height: 9, Diameter: 48.9 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 15, Height: 9, Diameter: 55.3 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 20, Height: 9, Diameter: 60.6 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 6, Height: 10, Diameter: 42.1 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 10, Height: 10, Diameter: 50 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 15, Height: 10, Diameter: 56 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 20, Height: 10, Diameter: 60.8 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 6, Height: 11, Diameter: 42.6 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 10, Height: 11, Diameter: 50 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 15, Height: 11, Diameter: 56.5 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 20, Height: 11, Diameter: 60.9 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 6, Height: 12, Diameter: 43 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 15, Height: 12, Diameter: 57 },
    { DevCode: 26, Nozzle1: 0.3125, Pressure: 20, Height: 12, Diameter: 61 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 6, Height: 1, Diameter: 21 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 10, Height: 1, Diameter: 27 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 15, Height: 1, Diameter: 32 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 20, Height: 1, Diameter: 36 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 6, Height: 2, Diameter: 27.1 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 10, Height: 2, Diameter: 33 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 15, Height: 2, Diameter: 38.1 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 20, Height: 2, Diameter: 42.2 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 6, Height: 3, Diameter: 33.2 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 10, Height: 3, Diameter: 39.1 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 15, Height: 3, Diameter: 44.2 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 20, Height: 3, Diameter: 48.4 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 6, Height: 4, Diameter: 35 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 10, Height: 4, Diameter: 41.2 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 15, Height: 4, Diameter: 46.6 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 20, Height: 4, Diameter: 51 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 6, Height: 5, Diameter: 36.8 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 10, Height: 5, Diameter: 43.3 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 15, Height: 5, Diameter: 49 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 20, Height: 5, Diameter: 53.7 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 6, Height: 6, Diameter: 38.6 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 10, Height: 6, Diameter: 45.4 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 15, Height: 6, Diameter: 51.4 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 20, Height: 6, Diameter: 56.3 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 6, Height: 7, Diameter: 39.8 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 10, Height: 7, Diameter: 46.8 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 15, Height: 7, Diameter: 53 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 20, Height: 7, Diameter: 58 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 6, Height: 8, Diameter: 41 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 10, Height: 8, Diameter: 48.2 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 15, Height: 8, Diameter: 54.6 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 20, Height: 8, Diameter: 59.8 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 6, Height: 9, Diameter: 42.2 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 10, Height: 9, Diameter: 49.6 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 15, Height: 9, Diameter: 56.2 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 20, Height: 9, Diameter: 61.5 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 6, Height: 10, Diameter: 43.2 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 10, Height: 10, Diameter: 50 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 15, Height: 10, Diameter: 57 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 20, Height: 10, Diameter: 62 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 6, Height: 11, Diameter: 44.1 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 10, Height: 11, Diameter: 51 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 15, Height: 11, Diameter: 57.5 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 20, Height: 11, Diameter: 62.5 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 6, Height: 12, Diameter: 45 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 10, Height: 12, Diameter: 52 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 26, Nozzle1: 0.3515625, Pressure: 20, Height: 12, Diameter: 63 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 6, Height: 1, Diameter: 21.8 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 10, Height: 1, Diameter: 28 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 15, Height: 1, Diameter: 33.1 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 20, Height: 1, Diameter: 36.9 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 6, Height: 2, Diameter: 27.8 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 10, Height: 2, Diameter: 34 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 15, Height: 2, Diameter: 39.1 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 20, Height: 2, Diameter: 43.1 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 6, Height: 3, Diameter: 33.8 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 10, Height: 3, Diameter: 39.8 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 15, Height: 3, Diameter: 45.1 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 20, Height: 3, Diameter: 49.3 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 6, Height: 4, Diameter: 35.6 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 10, Height: 4, Diameter: 42 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 15, Height: 4, Diameter: 47.5 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 20, Height: 4, Diameter: 52 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 6, Height: 5, Diameter: 37.5 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 10, Height: 5, Diameter: 44.1 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 15, Height: 5, Diameter: 50 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 20, Height: 5, Diameter: 54.7 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 6, Height: 6, Diameter: 39.3 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 10, Height: 6, Diameter: 46.2 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 15, Height: 6, Diameter: 52.4 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 20, Height: 6, Diameter: 57.4 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 6, Height: 7, Diameter: 40.5 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 10, Height: 7, Diameter: 47.6 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 15, Height: 7, Diameter: 54 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 20, Height: 7, Diameter: 59.2 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 6, Height: 8, Diameter: 41.8 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 10, Height: 8, Diameter: 49 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 15, Height: 8, Diameter: 55.6 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 20, Height: 8, Diameter: 60.9 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 6, Height: 9, Diameter: 43 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 10, Height: 9, Diameter: 50.5 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 15, Height: 9, Diameter: 57.2 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 20, Height: 9, Diameter: 62.6 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 6, Height: 10, Diameter: 44 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 10, Height: 10, Diameter: 51.5 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 15, Height: 10, Diameter: 57.5 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 20, Height: 10, Diameter: 63 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 6, Height: 11, Diameter: 45 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 10, Height: 11, Diameter: 52.5 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 15, Height: 11, Diameter: 57.8 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 20, Height: 11, Diameter: 63.5 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 6, Height: 12, Diameter: 46 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 10, Height: 12, Diameter: 53 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 15, Height: 12, Diameter: 58 },
    { DevCode: 26, Nozzle1: 0.40625, Pressure: 20, Height: 12, Diameter: 64 },
    { DevCode: 27, Nozzle1: 0.09375, Pressure: 10, Height: 12, Diameter: 43.4 },
    { DevCode: 27, Nozzle1: 0.1015625, Pressure: 10, Height: 12, Diameter: 43.7 },
    { DevCode: 27, Nozzle1: 0.109375, Pressure: 10, Height: 12, Diameter: 44 },
    { DevCode: 27, Nozzle1: 0.1171875, Pressure: 10, Height: 12, Diameter: 45.2 },
    { DevCode: 27, Nozzle1: 0.125, Pressure: 10, Height: 12, Diameter: 46.4 },
    { DevCode: 27, Nozzle1: 0.1328125, Pressure: 10, Height: 12, Diameter: 48.2 },
    { DevCode: 27, Nozzle1: 0.140625, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 27, Nozzle1: 0.1484375, Pressure: 10, Height: 12, Diameter: 49.8 },
    { DevCode: 27, Nozzle1: 0.15625, Pressure: 10, Height: 12, Diameter: 49.6 },
    { DevCode: 27, Nozzle1: 0.1640625, Pressure: 10, Height: 12, Diameter: 49.7 },
    { DevCode: 27, Nozzle1: 0.171875, Pressure: 10, Height: 12, Diameter: 49.8 },
    { DevCode: 27, Nozzle1: 0.1796875, Pressure: 10, Height: 12, Diameter: 49.9 },
    { DevCode: 27, Nozzle1: 0.1875, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 27, Nozzle1: 0.1953125, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 27, Nozzle1: 0.203125, Pressure: 10, Height: 12, Diameter: 50 },
    { DevCode: 27, Nozzle1: 0.21875, Pressure: 10, Height: 12, Diameter: 50.4 },
    { DevCode: 27, Nozzle1: 0.21875, Pressure: 10, Height: 12, Diameter: 50.8 },
    { DevCode: 27, Nozzle1: 0.2265625, Pressure: 10, Height: 12, Diameter: 51.1 },
    { DevCode: 27, Nozzle1: 0.234375, Pressure: 10, Height: 12, Diameter: 51.4 },
    { DevCode: 27, Nozzle1: 0.2421875, Pressure: 10, Height: 12, Diameter: 51.6 },
    { DevCode: 27, Nozzle1: 0.25, Pressure: 10, Height: 12, Diameter: 51.8 },
    { DevCode: 27, Nozzle1: 0.2578125, Pressure: 10, Height: 12, Diameter: 52 },
    { DevCode: 27, Nozzle1: 0.265625, Pressure: 10, Height: 12, Diameter: 52.2 },
    { DevCode: 27, Nozzle1: 0.2734375, Pressure: 10, Height: 12, Diameter: 53.3 },
    { DevCode: 27, Nozzle1: 0.28125, Pressure: 10, Height: 12, Diameter: 54.4 },
    { DevCode: 27, Nozzle1: 0.2890625, Pressure: 10, Height: 12, Diameter: 52.7 },
    { DevCode: 27, Nozzle1: 0.296875, Pressure: 10, Height: 12, Diameter: 51.2 },
    { DevCode: 27, Nozzle1: 0.3046875, Pressure: 10, Height: 12, Diameter: 51.2 },
    { DevCode: 27, Nozzle1: 0.3125, Pressure: 10, Height: 12, Diameter: 51.2 },
    { DevCode: 27, Nozzle1: 0.3203125, Pressure: 10, Height: 12, Diameter: 51 },
    { DevCode: 27, Nozzle1: 0.328125, Pressure: 10, Height: 12, Diameter: 50.8 },
    { DevCode: 27, Nozzle1: 0.3359375, Pressure: 10, Height: 12, Diameter: 51.2 },
    { DevCode: 27, Nozzle1: 0.34375, Pressure: 10, Height: 12, Diameter: 51.6 },
    { DevCode: 27, Nozzle1: 0.3515625, Pressure: 10, Height: 12, Diameter: 51.4 },
    { DevCode: 27, Nozzle1: 0.359375, Pressure: 10, Height: 12, Diameter: 51.2 },
    { DevCode: 27, Nozzle1: 0.3671875, Pressure: 10, Height: 12, Diameter: 51.1 },
    { DevCode: 27, Nozzle1: 0.375, Pressure: 10, Height: 12, Diameter: 51 },
    { DevCode: 27, Nozzle1: 0.3828125, Pressure: 10, Height: 12, Diameter: 51 },
    { DevCode: 27, Nozzle1: 0.390625, Pressure: 10, Height: 12, Diameter: 51 },
    { DevCode: 27, Nozzle1: 0.3984375, Pressure: 10, Height: 12, Diameter: 51 },
    { DevCode: 27, Nozzle1: 0.40625, Pressure: 10, Height: 12, Diameter: 51 },
    { DevCode: 187, Nozzle1: 0.140625, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 187, Nozzle1: 0.1484375, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 187, Nozzle1: 0.15625, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 187, Nozzle1: 0.1640625, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 187, Nozzle1: 0.171875, Pressure: 15, Height: 3, Diameter: 49 },
    { DevCode: 187, Nozzle1: 0.1796875, Pressure: 15, Height: 3, Diameter: 49 },
    { DevCode: 187, Nozzle1: 0.1875, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.1953125, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.203125, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2109375, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.21875, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2265625, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.234375, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2421875, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.25, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2578125, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.265625, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.2734375, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.28125, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.2890625, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.296875, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.3046875, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.3125, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.3203125, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.328125, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.3359375, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.34375, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.3515625, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.359375, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.3671875, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.375, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.3828125, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.390625, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.09375, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 187, Nozzle1: 0.1015625, Pressure: 15, Height: 6, Diameter: 47 },
    { DevCode: 187, Nozzle1: 0.109375, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 187, Nozzle1: 0.1171875, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.125, Pressure: 15, Height: 6, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.1328125, Pressure: 15, Height: 6, Diameter: 53 },
    { DevCode: 187, Nozzle1: 0.140625, Pressure: 15, Height: 6, Diameter: 54 },
    { DevCode: 187, Nozzle1: 0.1484375, Pressure: 15, Height: 6, Diameter: 55 },
    { DevCode: 187, Nozzle1: 0.15625, Pressure: 15, Height: 6, Diameter: 56 },
    { DevCode: 187, Nozzle1: 0.1640625, Pressure: 15, Height: 6, Diameter: 56 },
    { DevCode: 187, Nozzle1: 0.171875, Pressure: 15, Height: 6, Diameter: 57 },
    { DevCode: 187, Nozzle1: 0.1796875, Pressure: 15, Height: 6, Diameter: 57 },
    { DevCode: 187, Nozzle1: 0.1875, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.1953125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.203125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.2109375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.21875, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.2265625, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.234375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.2421875, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.25, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.2578125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.265625, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.2734375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.28125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.2890625, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.296875, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.3046875, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.3125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.3203125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.328125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.3359375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.34375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.3515625, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.359375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.3671875, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.3828125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.390625, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.09375, Pressure: 15, Height: 9, Diameter: 44 },
    { DevCode: 187, Nozzle1: 0.1015625, Pressure: 15, Height: 9, Diameter: 46 },
    { DevCode: 1187, Nozzle1: 0.375, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.3828125, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.390625, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.09375, Pressure: 15, Height: 6, Diameter: 46 },
    { DevCode: 1187, Nozzle1: 0.1015625, Pressure: 15, Height: 6, Diameter: 47 },
    { DevCode: 1187, Nozzle1: 0.109375, Pressure: 15, Height: 6, Diameter: 48 },
    { DevCode: 1187, Nozzle1: 0.1171875, Pressure: 15, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.125, Pressure: 15, Height: 6, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.1328125, Pressure: 15, Height: 6, Diameter: 53 },
    { DevCode: 1187, Nozzle1: 0.140625, Pressure: 15, Height: 6, Diameter: 54 },
    { DevCode: 1187, Nozzle1: 0.1484375, Pressure: 15, Height: 6, Diameter: 55 },
    { DevCode: 1187, Nozzle1: 0.15625, Pressure: 15, Height: 6, Diameter: 56 },
    { DevCode: 1187, Nozzle1: 0.1640625, Pressure: 15, Height: 6, Diameter: 56 },
    { DevCode: 1187, Nozzle1: 0.171875, Pressure: 15, Height: 6, Diameter: 57 },
    { DevCode: 1187, Nozzle1: 0.1796875, Pressure: 15, Height: 6, Diameter: 57 },
    { DevCode: 1187, Nozzle1: 0.1875, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.1953125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.203125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.2109375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.21875, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.2265625, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.234375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.2421875, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.25, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.2578125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.265625, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.2734375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.28125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.2890625, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.296875, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.3046875, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.3125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.3203125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.328125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.3359375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.34375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.3515625, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.359375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.3671875, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.375, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.3828125, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.390625, Pressure: 15, Height: 6, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.09375, Pressure: 15, Height: 9, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.1015625, Pressure: 15, Height: 9, Diameter: 46 },
    { DevCode: 1187, Nozzle1: 0.109375, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 1187, Nozzle1: 0.1171875, Pressure: 15, Height: 9, Diameter: 49 },
    { DevCode: 1187, Nozzle1: 0.125, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.1328125, Pressure: 15, Height: 9, Diameter: 51 },
    { DevCode: 1187, Nozzle1: 0.140625, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.1484375, Pressure: 15, Height: 9, Diameter: 53 },
    { DevCode: 1187, Nozzle1: 0.15625, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 1187, Nozzle1: 0.1640625, Pressure: 15, Height: 9, Diameter: 55 },
    { DevCode: 1187, Nozzle1: 0.171875, Pressure: 15, Height: 9, Diameter: 55 },
    { DevCode: 1187, Nozzle1: 0.1796875, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 1187, Nozzle1: 0.1875, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 1187, Nozzle1: 0.1953125, Pressure: 15, Height: 9, Diameter: 57 },
    { DevCode: 1187, Nozzle1: 0.203125, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.2109375, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.21875, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.2265625, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.234375, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.2421875, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.25, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.2578125, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.265625, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.2734375, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 1187, Nozzle1: 0.28125, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.109375, Pressure: 15, Height: 9, Diameter: 48 },
    { DevCode: 187, Nozzle1: 0.1171875, Pressure: 15, Height: 9, Diameter: 49 },
    { DevCode: 187, Nozzle1: 0.125, Pressure: 15, Height: 9, Diameter: 50 },
    { DevCode: 187, Nozzle1: 0.1328125, Pressure: 15, Height: 9, Diameter: 51 },
    { DevCode: 187, Nozzle1: 0.140625, Pressure: 15, Height: 9, Diameter: 52 },
    { DevCode: 187, Nozzle1: 0.1484375, Pressure: 15, Height: 9, Diameter: 53 },
    { DevCode: 187, Nozzle1: 0.15625, Pressure: 15, Height: 9, Diameter: 54 },
    { DevCode: 187, Nozzle1: 0.1640625, Pressure: 15, Height: 9, Diameter: 55 },
    { DevCode: 187, Nozzle1: 0.171875, Pressure: 15, Height: 9, Diameter: 55 },
    { DevCode: 187, Nozzle1: 0.1796875, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 187, Nozzle1: 0.1875, Pressure: 15, Height: 9, Diameter: 56 },
    { DevCode: 187, Nozzle1: 0.1953125, Pressure: 15, Height: 9, Diameter: 57 },
    { DevCode: 187, Nozzle1: 0.203125, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.2109375, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.21875, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.2265625, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.234375, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.2421875, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.25, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.2578125, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.265625, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.2734375, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.28125, Pressure: 15, Height: 9, Diameter: 58 },
    { DevCode: 187, Nozzle1: 0.2890625, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 187, Nozzle1: 0.296875, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 187, Nozzle1: 0.3046875, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 187, Nozzle1: 0.3125, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 187, Nozzle1: 0.3203125, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 187, Nozzle1: 0.328125, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 187, Nozzle1: 0.3359375, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 187, Nozzle1: 0.34375, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 187, Nozzle1: 0.3515625, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 187, Nozzle1: 0.359375, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 187, Nozzle1: 0.3671875, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 187, Nozzle1: 0.375, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 187, Nozzle1: 0.3828125, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 187, Nozzle1: 0.390625, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 1187, Nozzle1: 0.09375, Pressure: 10, Height: 1, Diameter: 22 },
    { DevCode: 1187, Nozzle1: 0.1015625, Pressure: 10, Height: 1, Diameter: 23 },
    { DevCode: 1187, Nozzle1: 0.109375, Pressure: 10, Height: 1, Diameter: 24 },
    { DevCode: 1187, Nozzle1: 0.1171875, Pressure: 10, Height: 1, Diameter: 25 },
    { DevCode: 1187, Nozzle1: 0.125, Pressure: 10, Height: 1, Diameter: 26 },
    { DevCode: 1187, Nozzle1: 0.1328125, Pressure: 10, Height: 1, Diameter: 27 },
    { DevCode: 1187, Nozzle1: 0.140625, Pressure: 10, Height: 1, Diameter: 28 },
    { DevCode: 1187, Nozzle1: 0.1484375, Pressure: 10, Height: 1, Diameter: 29 },
    { DevCode: 1187, Nozzle1: 0.15625, Pressure: 10, Height: 1, Diameter: 30 },
    { DevCode: 1187, Nozzle1: 0.1640625, Pressure: 10, Height: 1, Diameter: 31 },
    { DevCode: 1187, Nozzle1: 0.171875, Pressure: 10, Height: 1, Diameter: 32 },
    { DevCode: 1187, Nozzle1: 0.1796875, Pressure: 10, Height: 1, Diameter: 33 },
    { DevCode: 1187, Nozzle1: 0.1875, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.1953125, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.203125, Pressure: 10, Height: 1, Diameter: 35 },
    { DevCode: 1187, Nozzle1: 0.2109375, Pressure: 10, Height: 1, Diameter: 35 },
    { DevCode: 1187, Nozzle1: 0.21875, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.2265625, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.234375, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.2421875, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.25, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.2578125, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.265625, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.2734375, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.28125, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.2890625, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.296875, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.3046875, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.3125, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.3203125, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.328125, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.3359375, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.34375, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.3515625, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.359375, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.3671875, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.375, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.3828125, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.390625, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.09375, Pressure: 10, Height: 3, Diameter: 22 },
    { DevCode: 1187, Nozzle1: 0.1015625, Pressure: 10, Height: 3, Diameter: 23 },
    { DevCode: 1187, Nozzle1: 0.109375, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 1187, Nozzle1: 0.1171875, Pressure: 10, Height: 3, Diameter: 25 },
    { DevCode: 1187, Nozzle1: 0.125, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 1187, Nozzle1: 0.1328125, Pressure: 10, Height: 3, Diameter: 27 },
    { DevCode: 1187, Nozzle1: 0.140625, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 1187, Nozzle1: 0.1484375, Pressure: 10, Height: 3, Diameter: 29 },
    { DevCode: 1187, Nozzle1: 0.15625, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 1187, Nozzle1: 0.1640625, Pressure: 10, Height: 3, Diameter: 31 },
    { DevCode: 1187, Nozzle1: 0.171875, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 1187, Nozzle1: 0.1796875, Pressure: 10, Height: 3, Diameter: 33 },
    { DevCode: 1187, Nozzle1: 0.1875, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.1953125, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.203125, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 1187, Nozzle1: 0.2109375, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 1187, Nozzle1: 0.21875, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.2265625, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.234375, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.2421875, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.25, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.2578125, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.265625, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.2734375, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.28125, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.2890625, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.296875, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.3046875, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.3125, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.3203125, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.328125, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.3359375, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.34375, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.3515625, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.359375, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.3671875, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.375, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.3828125, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.390625, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.09375, Pressure: 10, Height: 6, Diameter: 38 },
    { DevCode: 1187, Nozzle1: 0.1015625, Pressure: 10, Height: 6, Diameter: 39 },
    { DevCode: 1187, Nozzle1: 0.109375, Pressure: 10, Height: 6, Diameter: 40 },
    { DevCode: 1187, Nozzle1: 0.1171875, Pressure: 10, Height: 6, Diameter: 41 },
    { DevCode: 1187, Nozzle1: 0.125, Pressure: 10, Height: 6, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.1328125, Pressure: 10, Height: 6, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.140625, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 1187, Nozzle1: 0.1484375, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 1187, Nozzle1: 0.15625, Pressure: 10, Height: 6, Diameter: 46 },
    { DevCode: 1187, Nozzle1: 0.1640625, Pressure: 10, Height: 6, Diameter: 47 },
    { DevCode: 1187, Nozzle1: 0.171875, Pressure: 10, Height: 6, Diameter: 48 },
    { DevCode: 1187, Nozzle1: 0.1796875, Pressure: 10, Height: 6, Diameter: 49 },
    { DevCode: 1187, Nozzle1: 0.1875, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.1953125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.203125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2109375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.21875, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2265625, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.234375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2421875, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.25, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2578125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.265625, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2734375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.28125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2890625, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.296875, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.3046875, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.3125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.3203125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.328125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.3359375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.34375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.3515625, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.359375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.3671875, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.375, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.3828125, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.390625, Pressure: 10, Height: 6, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.09375, Pressure: 10, Height: 9, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.1015625, Pressure: 10, Height: 9, Diameter: 38 },
    { DevCode: 1187, Nozzle1: 0.109375, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 1187, Nozzle1: 0.1171875, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 1187, Nozzle1: 0.125, Pressure: 10, Height: 9, Diameter: 40 },
    { DevCode: 1187, Nozzle1: 0.1328125, Pressure: 10, Height: 9, Diameter: 41 },
    { DevCode: 1187, Nozzle1: 0.140625, Pressure: 10, Height: 9, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.1484375, Pressure: 10, Height: 9, Diameter: 43 },
    { DevCode: 1187, Nozzle1: 0.15625, Pressure: 10, Height: 9, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.1640625, Pressure: 10, Height: 9, Diameter: 45 },
    { DevCode: 1187, Nozzle1: 0.171875, Pressure: 10, Height: 9, Diameter: 46 },
    { DevCode: 1187, Nozzle1: 0.1796875, Pressure: 10, Height: 9, Diameter: 47 },
    { DevCode: 1187, Nozzle1: 0.1875, Pressure: 10, Height: 9, Diameter: 48 },
    { DevCode: 1187, Nozzle1: 0.1953125, Pressure: 10, Height: 9, Diameter: 49 },
    { DevCode: 1187, Nozzle1: 0.203125, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2109375, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.21875, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2265625, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.234375, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2421875, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.25, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2578125, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.265625, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2734375, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.28125, Pressure: 10, Height: 9, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2890625, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.296875, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.3046875, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.3125, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.3203125, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.328125, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.3359375, Pressure: 10, Height: 9, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.34375, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 1187, Nozzle1: 0.3515625, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 1187, Nozzle1: 0.359375, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 1187, Nozzle1: 0.3671875, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 1187, Nozzle1: 0.375, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 1187, Nozzle1: 0.3828125, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 1187, Nozzle1: 0.390625, Pressure: 10, Height: 9, Diameter: 54 },
    { DevCode: 1187, Nozzle1: 0.09375, Pressure: 15, Height: 1, Diameter: 30 },
    { DevCode: 1187, Nozzle1: 0.1015625, Pressure: 15, Height: 1, Diameter: 31 },
    { DevCode: 1187, Nozzle1: 0.109375, Pressure: 15, Height: 1, Diameter: 32 },
    { DevCode: 1187, Nozzle1: 0.1171875, Pressure: 15, Height: 1, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.125, Pressure: 15, Height: 1, Diameter: 36 },
    { DevCode: 1187, Nozzle1: 0.1328125, Pressure: 15, Height: 1, Diameter: 37 },
    { DevCode: 1187, Nozzle1: 0.140625, Pressure: 15, Height: 1, Diameter: 38 },
    { DevCode: 1187, Nozzle1: 0.1484375, Pressure: 15, Height: 1, Diameter: 39 },
    { DevCode: 15, Nozzle1: 0.09375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1015625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.109375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1171875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1328125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.140625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1484375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.15625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1640625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.171875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1796875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1953125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.203125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.2109375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.21875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.2265625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.234375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.2421875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.25, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.2578125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.265625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.2734375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.28125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.2890625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.296875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3046875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3203125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.328125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3359375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.34375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3515625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.359375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3671875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3828125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.390625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.09375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1015625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.109375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1171875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1328125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.140625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1484375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.15625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1640625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.171875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1796875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.1953125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.203125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.2109375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.21875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.2265625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.234375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.2421875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.25, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.2578125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.265625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.2734375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.28125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.2890625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.296875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3046875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3203125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.328125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3359375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.34375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3515625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.359375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3671875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.3828125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 15, Nozzle1: 0.390625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.09375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1015625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.109375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1171875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1328125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.140625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1484375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.15625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1640625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.171875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1796875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1953125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.203125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.2109375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.21875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.2265625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.234375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.2421875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.25, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.2578125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.265625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.2734375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.28125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.2890625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.296875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3046875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3203125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.328125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3359375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.34375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3515625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.359375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3671875, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.375, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3828125, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.390625, Pressure: 6, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.09375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1015625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.109375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1171875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1328125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.140625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1484375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.15625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1640625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.171875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1796875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.1953125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.203125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.2109375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.21875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.2265625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.234375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.2421875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.25, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.2578125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.265625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.2734375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.28125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.2890625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.296875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3046875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3203125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.328125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3359375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.34375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3515625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.359375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3671875, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.375, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.3828125, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 1015, Nozzle1: 0.390625, Pressure: 10, Height: 12, Diameter: 54 },
    { DevCode: 187, Nozzle1: 0.09375, Pressure: 10, Height: 1, Diameter: 22 },
    { DevCode: 187, Nozzle1: 0.1015625, Pressure: 10, Height: 1, Diameter: 23 },
    { DevCode: 187, Nozzle1: 0.109375, Pressure: 10, Height: 1, Diameter: 24 },
    { DevCode: 187, Nozzle1: 0.1171875, Pressure: 10, Height: 1, Diameter: 25 },
    { DevCode: 187, Nozzle1: 0.125, Pressure: 10, Height: 1, Diameter: 26 },
    { DevCode: 187, Nozzle1: 0.1328125, Pressure: 10, Height: 1, Diameter: 27 },
    { DevCode: 187, Nozzle1: 0.140625, Pressure: 10, Height: 1, Diameter: 28 },
    { DevCode: 187, Nozzle1: 0.1484375, Pressure: 10, Height: 1, Diameter: 29 },
    { DevCode: 187, Nozzle1: 0.15625, Pressure: 10, Height: 1, Diameter: 30 },
    { DevCode: 187, Nozzle1: 0.1640625, Pressure: 10, Height: 1, Diameter: 31 },
    { DevCode: 187, Nozzle1: 0.171875, Pressure: 10, Height: 1, Diameter: 32 },
    { DevCode: 187, Nozzle1: 0.1796875, Pressure: 10, Height: 1, Diameter: 33 },
    { DevCode: 187, Nozzle1: 0.1875, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.1953125, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.203125, Pressure: 10, Height: 1, Diameter: 35 },
    { DevCode: 187, Nozzle1: 0.2109375, Pressure: 10, Height: 1, Diameter: 35 },
    { DevCode: 187, Nozzle1: 0.21875, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.2265625, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.234375, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.2421875, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.25, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.2578125, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.265625, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.2734375, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.28125, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.2890625, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.296875, Pressure: 10, Height: 1, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.3046875, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.3125, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.3203125, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.328125, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.3359375, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.34375, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.3515625, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.359375, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.3671875, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.375, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.3828125, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.390625, Pressure: 10, Height: 1, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.09375, Pressure: 10, Height: 3, Diameter: 22 },
    { DevCode: 187, Nozzle1: 0.1015625, Pressure: 10, Height: 3, Diameter: 23 },
    { DevCode: 187, Nozzle1: 0.109375, Pressure: 10, Height: 3, Diameter: 24 },
    { DevCode: 187, Nozzle1: 0.1171875, Pressure: 10, Height: 3, Diameter: 25 },
    { DevCode: 187, Nozzle1: 0.125, Pressure: 10, Height: 3, Diameter: 26 },
    { DevCode: 187, Nozzle1: 0.1328125, Pressure: 10, Height: 3, Diameter: 27 },
    { DevCode: 187, Nozzle1: 0.140625, Pressure: 10, Height: 3, Diameter: 28 },
    { DevCode: 187, Nozzle1: 0.1484375, Pressure: 10, Height: 3, Diameter: 29 },
    { DevCode: 187, Nozzle1: 0.15625, Pressure: 10, Height: 3, Diameter: 30 },
    { DevCode: 187, Nozzle1: 0.1640625, Pressure: 10, Height: 3, Diameter: 31 },
    { DevCode: 187, Nozzle1: 0.171875, Pressure: 10, Height: 3, Diameter: 32 },
    { DevCode: 187, Nozzle1: 0.1796875, Pressure: 10, Height: 3, Diameter: 33 },
    { DevCode: 187, Nozzle1: 0.1875, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.1953125, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 187, Nozzle1: 0.203125, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 187, Nozzle1: 0.2109375, Pressure: 10, Height: 3, Diameter: 35 },
    { DevCode: 187, Nozzle1: 0.21875, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.2265625, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.234375, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.2421875, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.25, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.2578125, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.265625, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.2734375, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.28125, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.2890625, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.296875, Pressure: 10, Height: 3, Diameter: 36 },
    { DevCode: 187, Nozzle1: 0.3046875, Pressure: 10, Height: 3, Diameter: 34 },
    { DevCode: 1187, Nozzle1: 0.15625, Pressure: 15, Height: 1, Diameter: 40 },
    { DevCode: 1187, Nozzle1: 0.1640625, Pressure: 15, Height: 1, Diameter: 40 },
    { DevCode: 1187, Nozzle1: 0.171875, Pressure: 15, Height: 1, Diameter: 41 },
    { DevCode: 1187, Nozzle1: 0.1796875, Pressure: 15, Height: 1, Diameter: 41 },
    { DevCode: 1187, Nozzle1: 0.1875, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.1953125, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.203125, Pressure: 15, Height: 1, Diameter: 43 },
    { DevCode: 1187, Nozzle1: 0.2109375, Pressure: 15, Height: 1, Diameter: 43 },
    { DevCode: 1187, Nozzle1: 0.21875, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.2265625, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.234375, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.2421875, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.25, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.2578125, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.265625, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.2734375, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.28125, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.2890625, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.296875, Pressure: 15, Height: 1, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.3046875, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.3125, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.3203125, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.328125, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.3359375, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.34375, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.3515625, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.359375, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.3671875, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.375, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.3828125, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.390625, Pressure: 15, Height: 1, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.09375, Pressure: 15, Height: 3, Diameter: 40 },
    { DevCode: 1187, Nozzle1: 0.1015625, Pressure: 15, Height: 3, Diameter: 41 },
    { DevCode: 1187, Nozzle1: 0.109375, Pressure: 15, Height: 3, Diameter: 42 },
    { DevCode: 1187, Nozzle1: 0.1171875, Pressure: 15, Height: 3, Diameter: 44 },
    { DevCode: 1187, Nozzle1: 0.125, Pressure: 15, Height: 3, Diameter: 46 },
    { DevCode: 1187, Nozzle1: 0.1328125, Pressure: 15, Height: 3, Diameter: 47 },
    { DevCode: 1187, Nozzle1: 0.140625, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 1187, Nozzle1: 0.1484375, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 1187, Nozzle1: 0.15625, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 1187, Nozzle1: 0.1640625, Pressure: 15, Height: 3, Diameter: 48 },
    { DevCode: 1187, Nozzle1: 0.171875, Pressure: 15, Height: 3, Diameter: 49 },
    { DevCode: 1187, Nozzle1: 0.1796875, Pressure: 15, Height: 3, Diameter: 49 },
    { DevCode: 1187, Nozzle1: 0.1875, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.1953125, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.203125, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2109375, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.21875, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2265625, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.234375, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2421875, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.25, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2578125, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.265625, Pressure: 15, Height: 3, Diameter: 50 },
    { DevCode: 1187, Nozzle1: 0.2734375, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.28125, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.2890625, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.296875, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.3046875, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.3125, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.3203125, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.328125, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.3359375, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.34375, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.3515625, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.359375, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.3671875, Pressure: 15, Height: 3, Diameter: 52 },
    { DevCode: 1187, Nozzle1: 0.2890625, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 1187, Nozzle1: 0.296875, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 1187, Nozzle1: 0.3046875, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 1187, Nozzle1: 0.3125, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 1187, Nozzle1: 0.3203125, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 1187, Nozzle1: 0.328125, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 1187, Nozzle1: 0.3359375, Pressure: 15, Height: 9, Diameter: 60 },
    { DevCode: 1187, Nozzle1: 0.34375, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 1187, Nozzle1: 0.3515625, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 1187, Nozzle1: 0.359375, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 1187, Nozzle1: 0.3671875, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 1187, Nozzle1: 0.375, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 1187, Nozzle1: 0.3828125, Pressure: 15, Height: 9, Diameter: 62 },
    { DevCode: 1187, Nozzle1: 0.390625, Pressure: 15, Height: 9, Diameter: 62 },
];

export default WateringDiameters;

export interface WateringDiameter {
    DevCode: number;
    Nozzle1: number;
    Pressure: number;
    Height: number;
    Diameter: number;
}