import { BoomBackTypes, D3000Plates, DeviceTypes, DropTypes, HoseDropWeights, LDNChemPadTypes, LDNPadTypes, LDNTypes, NozzleClipTypes, IPackage as Package, RegulatorLocations, RegulatorPressures, RegulatorTypes, RigidDropMaterialTypes, RigidDropTypes, UPipeFittings, UPipeMaterialTypes, UPipeReaches, UPipeTypes, idxSprinkler } from "rdptypes/project/ISprinklers";
import {
    BaseInletSizes,
    BoosterPumpTypes,
    CanalFeedGenerators,
    CartTypes,
    CenterPivotTypes,
    ControlFunctionOptions,
    Countries,
    DualSprinklerPackageControlTypes,
    ElectricalFrequencies,
    EndGunTypes,
    EndOfSystemTypes,
    EndTowerPositioningTypes,
    GPSBaseOptions,
    GearDriveTypes,
    GeneratorFrameTypes,
    GeneratorModels,
    GeneratorOptions,
    GuidanceLocations,
    GuidanceTypes,
    HoseAndCouplerKitTypes,
    HoseFeedTypes,
    InletTypes,
    LightTypes,
    PanelModels,
    PaymentTypes,
    PipeBottomFittingTypes,
    PipeSizes,
    PivotingLateralPivotCenterTypes,
    Pressures,
    Pumps,
    RC10FacePlateUpgrade,
    RC10ModemBaseModels,
    RC10MountingLocations,
    RC10Types,
    RiserPipeTypes,
    SACGPSGuidanceBaseOptions,
    SACRetroSpanTypes,
    SACZoneControlTypes,
    SpanTypes,
    SprinklerManufacturerTypes,
    SwingArmLengths,
    SystemCategories,
    SystemTypes,
    TireSizes,
    TireTypes,
    TowTypes,
    TowerHeights,
    ValveMaterials,
    ValveSizes,
    WaterFeedTypes,
    WheelGearSeries,
    WrapAroundSpanTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import { SectionIDs } from "./CSection2";
import { SpansClass } from "./SpansClass";
import { SprinklersPackageClass } from "./SprinklersPackageClass";
import { RigidDropsOnSACResult } from "./SystemFunctions";
import TowerRebateOffer from './TowerRebateOffer';
import { TowersClass } from "./TowersClass";
import { Side, TSpans as Spans } from "./Types";

export class DeviceWeightOkayDto {
    public HoseDropWeight: HoseDropWeights;
    public DeviceType: DeviceTypes;
    public UseNelson3030: boolean;
    public RegulatorType: RegulatorTypes;
    public RegulatorLocation: RegulatorLocations;
    public RegulatorUseAsNeeded: boolean;
}

export class DeviceWeightOnRigidOkayDto {
    public DeviceType: DeviceTypes;
    public RigidDropMaterialType: RigidDropMaterialTypes;
}

export class UPipeTypeOKDto {
    public IsPolyOnly: boolean;
    public UPipeReach: UPipeReaches;
    public UPipeFitting: UPipeFittings;
    public Is2045: boolean;
    public AnyAlum: boolean;
    public HasSAC: boolean;
    public PackageCoversSAC: boolean;
}

export class UPipeFittingOKDto {
    public UPipeReach: UPipeReaches;
}

export class UPipeReachOKDto {
    public IsPolyOnly: boolean;
}

export class UseKometTrussRodSlingsOkDto {
    public UPipeType: UPipeTypes;
}

export class BubblerShroudOKDto {
    public IsLDN: boolean;
    public LDNType: LDNTypes;
    public LDNPadType: LDNPadTypes;
    public LDNChemPadType: LDNChemPadTypes;
}

export class ControlLineBoosterPumpOkDto {
    public ZoneControlType: SACZoneControlTypes;
    public VRISACSpanSelected: boolean;
}

export class AirCompressorOkDto {
    public ZoneControlType: SACZoneControlTypes;
    public ControlLineBoosterSelected: boolean;
    public ControlLineBoosterESPSelected: boolean;
    public VRISACSpanSelected: boolean;
}

export class DropClampKitOkDto {
    public HasAirCompressor: boolean;
    public HasVRI: boolean;
    public HasApplicableRigidDropsOnSAC: RigidDropsOnSACResult;
}

export class VRISACSpanOptionOkDto {
    public ZoneControlType: SACZoneControlTypes;
    public SystemType: SystemTypes;
    public ControlPanelDataValid: boolean;
    public PanelModel?: PanelModels;
    public EndTowerPositioning?: EndTowerPositioningTypes;
    public Country: Countries;
}

export class ConductorOptionOkDto {
    public PanelModel: PanelModels;
    public EndTowerPositioningType: EndTowerPositioningTypes;
    public EndGun2Type: EndGunTypes;
}

export class DistributionFlowRateOKDto {
    public ZoneControlType: SACZoneControlTypes;
    public VRISACSpanSelected: boolean;
    public Country: Countries;
}

export class DistributionFlowRateLoadCustomOKDto {
    public ZoneControlType: SACZoneControlTypes;
    public VRISACSpanSelected: boolean;
    public Country: Countries;
}

export class ZoneControlTypeOKDto {
    public Country: Countries;
}

export class CollectorReelICIIOptionCountOkDto {
    constructor (
        public HasPivotAutoReverseOption: boolean,
        public HasPivotAutoStopOption: boolean,
        public HasBoosterPumpOrElectricValve: boolean,
        public HasPivotLight: boolean
    ) { }

    public OptionCount = (): number => {
        let i: number = 0;
        if (this.HasPivotAutoReverseOption) {
            i += 1;
        }
        if (this.HasPivotAutoStopOption) {
            i += 1;
        }
        if (this.HasBoosterPumpOrElectricValve) {
            i += 1;
        }
        if (this.HasPivotLight) {
            i += 1;
        }
        return i;
    }

    public FormattedMessage = (): string => {
        let opts: string[] = [];
        if (this.HasPivotAutoReverseOption) {
            opts.push("Pivot Auto Reverse");
        }
        if (this.HasPivotAutoStopOption) {
            opts.push("Pivot Auto Stop");
        }
        if (this.HasBoosterPumpOrElectricValve) {
            opts.push("Booster Pump or Electric Valve");
        }
        if (this.HasPivotLight) {
            opts.push("Pivot Light");
        }
        let message: string = "The 11 Ring ICII Collector Reel supports a maximum of 2 optional conductors. ";
        message += `Options ${opts.join(", ")} are selected. `;
        message += "To continue, unselect unnecessary options before selecting essential options. ";
        message += "Alternatively select a 'United' Collector Reel to support more options. ";
        return message;
    }
}

export class GuidanceTypeOKDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public GuidanceLocation: GuidanceLocations;
    public SingleSpanMaxi: boolean;
    public IsTowable: boolean;
    public PivotingKit: boolean;
}

export class GuidanceLocationOKDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public GuidanceType: GuidanceTypes;
    public IsSingleSpanMaxi: boolean;
}

export class WinchOptionOkDto {
    public SystemType: SystemTypes;
    public GuidanceType: GuidanceTypes;
    public HoseFeedType: HoseFeedTypes;
    public GuidanceLocation: GuidanceLocations;
    public HasFurrowWheelOption: boolean;
}

export class SolarPoweredOKDto {
    public GuidanceType: GuidanceTypes;
}


export class TheftDeterentClipOKDto {
    public Spans: Spans;
    public IsEnginDrivenMiniPivot: boolean;
}

export class ConduitClipWOSpringOKDto {
    public Spacing57Exists: boolean;
    public Spacing114Exists: boolean;
    public IsEnginDrivenMiniPivot: boolean;
}

export class GalvanizedCNOptionOkDto {
    public IsReinkeUser: boolean;
    public CNPlusExists: boolean;
    public GrantCNPlus: boolean;
}

export class TowerDetailsDto {
    constructor(
        public TowerType: TowerHeights,
        public WrapAroundSpan: WrapAroundSpanTypes,
        public IsSpanDisconnecting: boolean
    
    ) { }

    public IsDropOrWrap = (): boolean => {
        return this.IsSpanDisconnecting || [WrapAroundSpanTypes.TenDegree, WrapAroundSpanTypes.NinetyDegree].indexOf(this.WrapAroundSpan) !== -1;
    }
}

export class TowerTypeOKDto {
    constructor(
        public Is230Volt: boolean,
        public IsEnginDrivenMiniPivot: boolean,
        public IsSingleSpanMaxigator: boolean,
        public IsSpanDisconnecting: boolean,
        public IsTowable: boolean,
        public IsTowerReverseTow: boolean,
        public SpanCount: number,
        public SpanLength: number,
        public SpanType: SpanTypes,
        public SystemType: SystemTypes,
        public HoseFeedType: HoseFeedTypes,
        public TowerHeightSource: TowerHeights | undefined,
        public TowerNext: TowerDetailsDto,
        public TowerNumber: number,
        public TowerPrevious: TowerDetailsDto,
        public WrapAroundSpan: WrapAroundSpanTypes
    ) { }

    public IsDropOrWrap = (): boolean => {
        return this.IsSpanDisconnecting || [WrapAroundSpanTypes.TenDegree, WrapAroundSpanTypes.NinetyDegree].indexOf(this.WrapAroundSpan) !== -1;
    }
}

export class TireTypeOKDto {
    public TowerHeight: TowerHeights;
    public PowerTowerTireSize: TireTypes;
    public TireSize: TireSizes;
    public IsA100: boolean;
    public IsSingleSpanMaxigator: boolean;
    public IsSACTower: boolean;
    public IsInternational: boolean;
}

export class TwelveLugsOKDto {
    public TowerHeight: TowerHeights;
    public TireSize: TireSizes;
    public TireType: TireTypes;
}

export class AgriTracOKDto {
    public TowerHeight: TowerHeights;
    public SpanType: SpanTypes;
    public IsTowable: boolean;
    public TireSize: TireSizes;
    public TireType: TireTypes;
}

export class HeavyDutyLegsOKDto {
    public TowerHeight: TowerHeights;
    public SpanLength: number;
    public SpanType: SpanTypes;
}

export class MaxigatorGuidanceOptionOkDto {
    public HasOuterTowerGuidance: boolean;
    public IsGuidanceValid: boolean;
}

export class TowerCrossBraceOKDto {
    public TowerHeight: TowerHeights;
    public SpanType: SpanTypes;
    public IsA100: boolean;
}

export class StiffenerTrussToPipeOKDto {
    public TowerHeight: TowerHeights;
    public SpanLength: number;
    public SpanType: SpanTypes;
    public SystemType: SystemTypes;
    public HosefeedType: HoseFeedTypes;
    public IsA100: boolean;
    public IsMaxi: boolean;
    public IsSingleSpanMaxigator: boolean;
}

export class StiffenerStabilizersOKDto {
    public TowerHeight: TowerHeights;
    public SpanLength: number;
    public SpanType: SpanTypes;
    public IsMaxi: boolean;
    public IsHeavyDutyByChoice: boolean;
    public IsStiffenerTrussToPipe: boolean;
}

export class ThreeWheelTowerOKDto {
    public TowerHeight: TowerHeights;
    public SpanType: SpanTypes;
    public IsTowable: boolean;
    public IsEDMP: boolean;
    public IsAgriTrac: boolean;
}

export class ThreeWheelFlexTowerOKDto {
    public IsThreeWheelTower: boolean;
}

export class WrapAroundSpanOKDto {
    public SystemType: SystemTypes;
    public TowerHeight: TowerHeights;
    public PanelModel: PanelModels;
    public EndTowerPositioning: EndTowerPositioningTypes;
    public PrevSpanType: SpanTypes;
    public NextSpanType?: SpanTypes;
    public Is230Volt: boolean;
    public IsEDMP: boolean;
    public IsNextSpanDisconnecting?: boolean;
    public IsTowable: boolean;
    public TowerCount: number;
    public TowerNumber: number;
    public clockwiseWrapAngleRelativeToPreviousSpanDegrees: number;
    public anticlockwiseWrapAngleRelativeToPreviousSpanDegrees: number;
}

export class EndOfSystemTypeOptionOKDto {
    public Towers: TowersClass;
    public SystemType: SystemTypes;
    public TowerHeightSource?: TowerHeights;
    public SwingArmAllowed: boolean;
    public PowerTowerEndBoomOptionSelected: boolean;
}

export class EndBoomOKDto {
    public EndOfSystemType: EndOfSystemTypes;
    public IsSpanExt: boolean;
    public PowerTowerEndBoom: boolean;
    public SpanLength: number;
    public SpanType: SpanTypes;
    public SwingArmLength: SwingArmLengths;
}

export class SpanDisconnectOptionOKDto {
    public Is230Volt: boolean;
    public IsKwikTow: boolean;
    public IsHoseFeed: boolean;
    public IsEndFeed: boolean;
    public IsDEF: boolean;
    public HasEndGunSecondary: boolean;
    public HasVRI: boolean;
    public PreviousSpanType: SpanTypes; // Can or Should this be nullable? 
    public SpanType: SpanTypes;
    public SpanNumber: number;
    public PreviousTowerHeight?: TowerHeights;
    public TowerHeight: TowerHeights;
    public NextTowerHeight?: TowerHeights;

}

export class InletTypeOkDto {
    public HoseFeedType: HoseFeedTypes;
    public Country: Countries;
}

export class CF200PumpOptionOkDto {
    public HoseFeedType: HoseFeedTypes;
}

export class BallastKitOkDto {
    public HoseFeedType: HoseFeedTypes;
    public ElectricDragCordLength: number;
}

export class ChemTankMountKitOkDto {
}

export class RotateCartOkDto {
    public HoseFeedType: HoseFeedTypes;
}

export class HDWheelGearOkDto {
    public HoseFeedType: HoseFeedTypes;
    public IsTowable: boolean;
}

export class SafetyValveOkDto {
    public SystemType: SystemTypes;
    public HoseFeedType?: HoseFeedTypes;
    public IsEDMP: boolean;
}

export class LongPLSystemOkDto {
    public GuidanceType: GuidanceTypes;
    public HoseFeedType?: HoseFeedTypes;
    public IsSingleSpanMaxi: boolean;
}

export class SpanTypeOKDto {
    public SpanNumber: number;
    public SpanCount: number;
    public SystemType: SystemTypes;
    public CenterPivotType: CenterPivotTypes;
    public HoseFeedType: HoseFeedTypes;
    public PivotingLateralPivotCenterType: PivotingLateralPivotCenterTypes;
    public HoseFeedBaseInlets: BaseInletSizes;
    public IsSingleSpanMaxi: boolean;
    public IsEDMP: boolean;
    public IsReverseTow: boolean;
    public Is230Volt: boolean;
    public IsFlexSide: boolean;
    public PreviousSpanType: SpanTypes;
    public IsOppositeSideStainless: boolean;
    public IsReversedSpan: boolean;
}

export class SpanLengthOKDto {
    public SpanType: SpanTypes;
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public SpanNumber: number;
    public IsSingleSpanMaxi: boolean;
    public IsFlangedSide: boolean;
    public IsKwikTow: boolean;
    public IsDeny213Use: boolean;
    public IsPivotingLateral: boolean;
    public IsTowable: boolean;
}

export class TireSizeTowersOKDto {
    public PowerTowerTireSize: TireSizes;
    public SpanType: SpanTypes;
    public SpanLength: number;
    public TowerCenterDrive: GearDriveTypes;
    public WheelGearType: WheelGearSeries;
    public IsA100: boolean;
    public IsSingleSpanMaxigator: boolean;
}

export class SprinklerSenningerPackageTypeOptionOKDto {
    public IsAnyPolyLinedSpans: boolean;
    public IsA100: boolean;
}

export class SprinklerSenningerNozzleClipOptionOKDto {
    public SelectedSprinkler: idxSprinkler;
    public IsESP: boolean;
}

export class SprinklerSenningerDropsOptionOKDto {
    public SelectedSprinkler: idxSprinkler;
    public IsAnyPolyLinedSpans: boolean;
    public RegulatorPressure: RegulatorPressures;
    public NozzleClipType: NozzleClipTypes;
    public HasSprinklerPolyValve: boolean;
}

export class SprinklerNelsonDropsOptionOKDto {
    public AnyPolyLinedSpans: boolean;
    public NozzleClipType: NozzleClipTypes;
    public RegulatorType: RegulatorTypes;
    public RegulatorPressure: RegulatorPressures;
    public SelectedSprinkler: idxSprinkler;
    public UseNelson3030: boolean;
    public UseNelsonAD3030MT: boolean;
    public HasSprinklerPolyValve: boolean;
}

export class RSN3000OptionOKDto {
    public DeviceType: DeviceTypes;
    public DropType: DropTypes;
    public PivotCenterHeight: TowerHeights;
    public RegulatorPressure: RegulatorPressures;
    public RegulatorType: RegulatorTypes;
    public SystemType: SystemTypes;
    public IsA3000: boolean;
    public IsO3000: boolean;
    public IsR3000: boolean;
    public IsS3000: boolean;
    // public IsD3000: boolean;
    public UseNelson3030: boolean;
    public HoseDropGroundClearance: number;
    public RigidDropGroundClearance: number;
    public RigidDropType: RigidDropTypes;
    public SprinklerConverterChecked: boolean;
}

export class RC10EndofSystemGPSOkDto {
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
    public EndTowerPositionType: EndTowerPositioningTypes;
    public RC10MountingLocation: RC10MountingLocations;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
    public IsRetro: boolean;
}

export class RC10MountingLocationOkDto {
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
    public EndTowerPositioningType: EndTowerPositioningTypes;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
    public IsRetro: boolean;
}

export class OutletSpacingOKDto {
    public SpanType: SpanTypes;
    public SpanLength: number;
    public Spans: Spans;
}

export class GearDriveOKDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public SpanType: SpanTypes;
    public IsSingleSpanMaxi: boolean;
    public Is230Volt: boolean;
    // public IsA100: boolean;
    // public IsPivotingLateral: boolean;
}

export class TowerAutoReverseOKDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public WrapAroundSpanType: WrapAroundSpanTypes;
    public IsEDMP: boolean;
    public HasTowerAutoStop: boolean;
}

export class TowerAutoStopOKDto {
    public HasTowerAutoReverse: boolean;
    public IsEDMP: boolean;
    public WrapAroundSpanType: WrapAroundSpanTypes;
    public IsA100: boolean;
}

export class SpanExtOKDto {
    public SpanType: SpanTypes;
    public SpanLength: number;
    public IsA100: boolean;
    public IsHoseFeed: boolean;
    public SpanNumber: number;
}

export class PivotCenterOptionOKDto {
    public HoseFeedType: HoseFeedTypes;
}

export class VRIOptionOKDto {
    public IsEDMP: boolean;
    public HasESP: boolean;
    public HasEndGunSecondary: boolean;
    public AnyDisconnecting: boolean;
    public PanelModel?: PanelModels;
    public EndTowerPositioningType?: EndTowerPositioningTypes;
    public SystemType: SystemTypes;
    public HoseFeedType?: HoseFeedTypes;
    public ElectricalFrequency?: ElectricalFrequencies;
    public TowerCount: number;
    public HasSwingArmCorner: boolean;
}

// Rules implemented in page instead
/*export class CanalFeedGeneratorsOkDto {
    public Pump: Pumps;
}*/

export class GeneratorOptionOKDto {
/**
 * Factor if frequency should enable or disable Generator Options.
 * Reinke provides only 60Hz generators, where international may
 * require a 50hz system.
 */
public ElectricalFrequency: ElectricalFrequencies;
public SystemType: SystemTypes;
public HoseFeedType?: HoseFeedTypes;
public HasGenStart: boolean;
public IsEDMP: boolean;
public Country: Countries;
}

export class GeneratorFrameTypeOkDto {
    public GeneratorOption: GeneratorOptions;
    public HasControlPanelOption: boolean;
}

export class GeneratorModelOkDto {
    public GeneratorOption: GeneratorOptions;
    public Country: Countries;
    public MinimumKW: number;
    public GeneratorFrameType: GeneratorFrameTypes;
    public HasEngDigitalPanel: boolean;
    public IsPivotingLateral: boolean;
}

export class GeneratorSlideRailsOptionOkDto {
    public GeneratorOption: GeneratorOptions;
}

export class ControlPanelOptionOKDto {
    public GeneratorOption: GeneratorOptions;
    public GeneratorModel: GeneratorModels;
    public Country: Countries;
}

export class PivotLightConstantOnOKDto {
    public PivotLightType: LightTypes;
    public SystemtType: SystemTypes;
    public HoseFeedType?: HoseFeedTypes;
}

export class ReinkeSignOptionOkDto {
    public IsA100: boolean;
}

export class PivotLightOptionOkDto {
    public IsEDMP: boolean;
}

export class FuelTankOptionOkDto {
    public GeneratorOption: GeneratorOptions;
    public SystemType: SystemTypes;
    public IsEDMP: boolean;
}

export class RC10PumpControlOkDto {
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
    public MountingLocation: RC10MountingLocations;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
    public EndTowerPositioning: EndTowerPositioningTypes;
    public IsRetro: boolean;
}

export class RC10EndGunControlOkDto {
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
    public MountingLocation: RC10MountingLocations;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
    public RC10Type: RC10Types;
    public EndTowerPositioning: EndTowerPositioningTypes;
    public IsRetro: boolean;
}

export class RC10AuxControlOkDto {
    public PanelModel: PanelModels;
    public MountingLocation: RC10MountingLocations;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
    public RC10Type: RC10Types;
    public EndTowerPositioning: EndTowerPositioningTypes;
    public IsRetro: boolean;
}

export class RC10SpeedControlOkDto {
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
    public MountingLocation: RC10MountingLocations;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
    public RC10Type: RC10Types;
    public EndTowerPositioning: EndTowerPositioningTypes;
    public IsRetro: boolean;
}

export class RC10ChemControlOkDto {
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
    public MountingLocation: RC10MountingLocations;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
    public IsParentChecked: boolean;
    public RC10Type: RC10Types;
    public EndTowerPositioning: EndTowerPositioningTypes;
    public IsRetro: boolean;
}

export class RC10StartRelayOkDto {
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
    public EndTowerPositioningType: EndTowerPositioningTypes;
    public MountingLocation: RC10MountingLocations;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
    public HasPowerRelay: boolean;
    public HasLowPressureShutdownSwitch: boolean;
    public HasPowerAutoRestart: boolean;
    public HasPowerAutoRestartKit: boolean;
    public EndTowerPositioning: EndTowerPositioningTypes;
    public IsRetro: boolean;
}

export class RC10PowerRelayOkDto {
    public EndTowerPositioningType: EndTowerPositioningTypes;
    public MountingLocation: RC10MountingLocations;
    public PanelModel: PanelModels;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
    public HasStartRelay: boolean;
    public EndTowerPositioning: EndTowerPositioningTypes;
    public IsRetro: boolean;
}

export class CanalEngineOKDto {
    public IsInternational: boolean;
    public Pump: Pumps;
    public Generator: CanalFeedGenerators;
    public CartType: CartTypes;
}

export class TubesOKDto {
    public TireSize: TireSizes;
    public TireType: TireTypes;
}

export class GalvanizedCNJointsOptionOkDto {
    public SpanTypeList: SpanTypes[];
}

export class LongSystemAlignmentOkDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public AnyAluminum: boolean;
}

export class AlumIVSupportRodOkDto {
    public SystemType: SystemTypes;
    public HoseFeedType: HoseFeedTypes;
    public AnyAluminum6: boolean;
}

export class HoseAndCouplersOKDto {
    public HoseFeedType: HoseFeedTypes;
    public InletType: InletTypes;
    public BaseInletSize: BaseInletSizes;
}

export class SingleSpanMaxiOKDto {
    public HoseFeedType: HoseFeedTypes;
}

export class BaseInletsOKDto {
    public HoseFeedType: HoseFeedTypes;
    public HoseAndCouplerKitType: HoseAndCouplerKitTypes;
    public InletType: InletTypes;
}

export class HoseLengthOKDto {
    public HoseFeedType: HoseFeedTypes;
    public HoseAndCouplers: HoseAndCouplerKitTypes;
    public BaseInlets: BaseInletSizes;
    public InletType: InletTypes;
}

export class JackKitOptionOKDto {
    public IsA100: boolean;
    public IsTowable: boolean;
    public InletType?: InletTypes;
}

export class DualSprinklerPackageOptionOkDto {
    public IsPivotingLateral: boolean;
}

export class EndGunOperationLateralModeOkDto {
    public IsPivotingLateral: boolean;
    public HasDualSprinklerPackage: boolean;
    public HasEndGun: boolean;
}

export class DualSprinklerPackageControlOkDto {
    public IsPivotingLateral: boolean;
    public DualSprinklerPackageOptionChecked: boolean;
}

export class ControlLineBoosterPumpOptionOkDto {
    public IsPivotingLateral: boolean;
    public DualSprinklerPackageOptionChecked: boolean;
    public DualSprinklerPackageControlType: DualSprinklerPackageControlTypes;
}

export class RegulatorTypeOptionOkDto {
    public DeviceType: DeviceTypes;
    public HasSAC: boolean;
}

export class PSIOptionOkDto {
    public RegulatorType: RegulatorTypes;
    public DeviceType: DeviceTypes;
    public DropType: DropTypes;
    public PackageNumber: number;
}

export class ThreadOptionOkDto {
    public RegulatorType: RegulatorTypes;
    public DeviceType: DeviceTypes;
    public RegulatorPressure: RegulatorPressures;
    public UseNelsonAD3030MT: boolean;
}

export class RegsAsNeededOptionOkDto {
    public RegulatorType: RegulatorTypes;
    public DeviceType: DeviceTypes;
    public RegulatorPressure: RegulatorPressures;
    public HasSAC: boolean;
}

export class MaleRingLockOkDto {
    public CenterPivotType: CenterPivotTypes;
    public PipeBottomFittingType: PipeBottomFittingTypes;
}

export class PipeBottomAdapterOkDto {
    public PipeBottomFittingType: PipeBottomFittingTypes;
    public IsAdapterChecked: boolean;
    public IsAdapter8to6Checked: boolean;
}

export class DropWeightOptionOkDto {
    public AnyAlum: boolean;
    public AnyPoly: boolean;
    public IsIWob: boolean;
    public DeviceType: DeviceTypes;
}

export class WeightBottomFittingOptionOkDto {
    public AnyPoly: boolean;
    public IsNoWeightBottomFittingOK: boolean;
    public HasFemaleConnection: boolean;
    public HasMaleConnection: boolean;
    public IsIWob: boolean;
    public HoseDropWeight: HoseDropWeights;
}

export class HoseTopFittingOptionOkDto {
    public HasRegsOnTop: boolean;
    public UPipeFitting: UPipeFittings;
}

export class MainlineValveMaterialOKDto {
    public ValveSize: ValveSizes;
}

export class MainlineValveAdapterOKDto {
    public ValveSize: ValveSizes;
    public ValveMaterial: ValveMaterials;
}

export class MainlineValveControlFunctionOKDto {
    public ValvePressure: Pressures;
}

export class MainlineValvePipeSizeOKDto {
    public ControlFunctionOption: ControlFunctionOptions;
}

export class MainlineValveRateOfFlowOKDto {
    public PipeSize: PipeSizes;
    public ControlFunctionOption: ControlFunctionOptions;
}

export class SolenoidOptionOKDto {
    public ValveSize: ValveSizes;
}

export class RC10ModemOkDto {
    public PanelModel: PanelModels;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
    public Country: Countries;
}

export class PackageTypeOptionKometOKDto {
    public AnyPolyLinedSpans: boolean;
}

export class SprinklerKometDropsOptionOkDto {
    public Sprinkler: idxSprinkler;
    public AnyPolyLinedSpans: boolean;
    public NozzleClipType: NozzleClipTypes;
    public HasSprinklerPolyValve: boolean;
}

export class DropOptionOkDto {
    public Sprinkler: idxSprinkler;
    public DropType: DropTypes;
}

export class FittingOptionsOkDto {
    public Sprinkler: idxSprinkler;
    public DropType: DropTypes;
    public SprinklerManufacturerType: SprinklerManufacturerTypes;
    public UseNelson3030: boolean;
}

export class FittingOptionOKDto {
    public PackageClass: SprinklersPackageClass;
    public Package: Package;
    public SpansClass: SpansClass;
    public RegulatorType: RegulatorTypes;
    public UseStainlessSteelMountsAndFittings: boolean;
}

export class DropNextToEndGunOkDto {
    public Package: Package;
}

export class For6InchValveOKDto {
    public RiserPipeType: RiserPipeTypes;
    public CenterPivotType: CenterPivotTypes;
    public PivotCenter: TowerHeights;
    public TowType: TowTypes;
    public PipeBottomFittingType: PipeBottomFittingTypes;
    public Is6x19Checked: boolean;
    public Is8x23Checked: boolean;
    public HasVerticalFlowmeter: boolean;
}

export class For6x19FlangeDeviceOKDto {
    public RiserPipeType: RiserPipeTypes;
    public CenterPivotType: CenterPivotTypes;
    public PivotCenter: TowerHeights;
    public TowType: TowTypes;
    public PipeBottomFittingType: PipeBottomFittingTypes;
    public Is8x23Checked: boolean;
    public Is6InchValveChecked: boolean;
    public HasVerticalFlowmeter: boolean;
}

export class For8x23FlangeDeviceOKDto {
    public RiserPipeType: RiserPipeTypes;
    public CenterPivotType: CenterPivotTypes;
    public PivotCenter: TowerHeights;
    public TowType: TowTypes;
    public PipeBottomFittingType: PipeBottomFittingTypes;
    public Is6x19Checked: boolean;
    public Is6InchValveChecked: boolean;
    public HasVerticalFlowmeter: boolean;
}

export class PivotRiserPipeOKDto {
    public PivotCenter: TowerHeights;
    public PipeBottomFittingType: PipeBottomFittingTypes;
    public CenterPivotType: CenterPivotTypes;
}

export class TowableOKDto {
    public WaterFeed: WaterFeedTypes;
    public TireSize: TireSizes;
    public HoseFeedType: HoseFeedTypes;
}

export class JunctionBoxWithPlugOkDto {
    public ElectricDragCord: number;
}

export class WaterFeedOKDto {
    public HoseFeedType: HoseFeedTypes;
    public IsSingleSpanMaxi: boolean;
}

export class TireSizesOkDto {
    public HoseFeedType: HoseFeedTypes;
}

export class PowerTowerEndBoomOKDto {
    public BaseInletSize: BaseInletSizes;
    public WaterFeedType: WaterFeedTypes;
    public HoseFeedType: HoseFeedTypes;
}

export class RC10OptionOkDto {
    public SystemType: SystemTypes;
    public HoseFeedType?: HoseFeedTypes;
}

export class CropXOptionOkDto {
}

export class ProbelessIrrigationSchedulingOptionOkDto {
    // public QtySoilProbeV25: number;
    // public QtySoilProbeV35: number;
    public QtySoilProbeV40: number;
    public QtyAntennaCableV40: number;
    public QtyPulseAntennaV40: number;
    public QtyInstallationKitV40: number;
    public QtyBatteryPackReplacementV40: number;
    public QtyAntennaExtension3mCable: number;
    // public QtyInstallKitV25: number;
    // public QtyInstallKitV35: number;
}

export class NetworkProviderOptionOkDto {
    public Country: Countries;
    public HasProbelessIrrigationScheduling: boolean;
    // public QtySoilProbeV25: number;
    // public QtySoilProbeV35: number;
    public QtySoilProbeV40: number;
    public QtyAntennaCableV40: number;
    public QtyPulseAntennaV40: number;
    public QtyInstallationKitV40: number;
    public QtyBatteryPackReplacementV40: number;
    public QtyAntennaExtension3mCable: number;
    // public QtyInstallKitV25: number;
    // public QtyInstallKitV35: number;
}

export class RC10PowerAutoRestartOKDto {
    public IsRetro: boolean;
    public HasSystemPowerAutoRestart: boolean;
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
}

export class RC10SwingArmCornerOKDto {
    public IsRetro: boolean;
    public HasSystemSwingArmCorner: boolean;
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
}

export class RC10LowPressureShutdownSwitchOKDto {
    public IsRetro: boolean;
    public HasSystemLowPressureShutdownSwitch: boolean;
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
}

export class RC10PowerAutoRestartPanelKitOkDto {
    public IsRetro: boolean;
    public HasLowPressureShutdownSwitch: boolean;
    public SystemCategory: SystemCategories;
    public Panelmodel: PanelModels;
    public RC10MountingLocation: RC10MountingLocations;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
    public HasPowerAutoRestart: boolean;
}

export class RC10PressureTransOkDto {
    public ModemBaseModel?: RC10ModemBaseModels;
    public MountingLocation: RC10MountingLocations;
    public ControlPanelType: PanelModels;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
}

export class SystemCategoryOkDto {
    public IsRetro: boolean;
    public SystemType: SystemTypes;
}

export class RC10ControlPanelTypeOKDto {
    public IsRetro: boolean;
    public SystemCategory: SystemCategories;
    public SystemPanelModel: PanelModels;
}

export class RPMStandardWithAutoReverseOkDto {
    public IsRetro: boolean;
    public HasTowerAutoReverse: boolean;
    public PanelModel: PanelModels;
    public HasPivotAutoReverse: boolean;
}

export class RC10FacePlateUpgradesOkDto {
    public IsRetro: boolean;
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
    public HasRPMStandardWithAutoReverse: boolean;
}

export class RC10PositioningDeviceOKDto {
    public IsRetro: boolean;
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
    public SystemEndTowerPositioningType: EndTowerPositioningTypes;
}

export class RC10TypeOkDto {
    public PanelModel: PanelModels;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
}

export class BubbleClipLEPAOkDto {
    public IsD3000: boolean;
    public DropType: DropTypes;
    public UseNelson3030: boolean;
    public CountChecked: number;
    public IsBubbleWideTanChecked: boolean;
    public SprinklerConverterChecked: boolean;
}

export class SprinklerConverterOkDto {
    public IsD3000: boolean;
    public DropType: DropTypes;
    public RegulatorPressure: RegulatorPressures;
    public UseNelson3030: boolean;
    public BubblerClipLEPAChecked: boolean;
}

export class D3000OptionOkDto {
    public IsD3000: boolean;
    public DropType: DropTypes;
    public NozzleClipType: NozzleClipTypes;
    public CountChecked: number;
    public BubblerClipLEPAChecked: boolean;
    public SprinklerConverterChecked: boolean;
    public D3000PlatesChecked: D3000Plates[];
    public RegulatorType: RegulatorTypes;
    public RegulatorPressure: RegulatorPressures;
}

export class FCNOptionOkDto {
    public RegulatorType: RegulatorTypes;
    public DropType: DropTypes;
    public TrashBusterBodyOptionChecked: boolean;
    public RigidDropMaterialType?: RigidDropMaterialTypes;
}

export class TrashBusterBodyOptionOkDto {
    public DeviceType: DeviceTypes;
    public D3000Plate: D3000Plates;
    public UseNelson3030: boolean;
    public D3000OptionCountChecked: number;
}

export class EndGunValveOKDto {
    public BoosterPumpType: BoosterPumpTypes;
    public EndGunType: EndGunTypes;
    public SecEndGunType: EndGunTypes;
    public ElectricEOSValid: boolean;
    public EvaluateSecondary: boolean;
}

export class BoosterPumpOkDto {
    public IsElectricEOSValid: boolean;
    public Is230Volt: boolean;
    public IsBigGunIsPrimary: boolean;
    public NumChecked: number;
    public DependentSideSpanType: SpanTypes;
    public HoseFeedType: HoseFeedTypes;
}

export class DropTypeOptionOkDto {
    public DeviceType: DeviceTypes;
    public IsIWob: boolean;
    public IsO3000: boolean;
    public AnyPoly: boolean;
    public IsXiWob: boolean;
    public AnyAlum: boolean;
    public IsKometTwister: boolean;
    public HasSAC: boolean;
    public HasVRI: boolean;
    public HasSACAirCompressor: boolean;
    public PackageCoversSAC: boolean;
}

export class UPipeOptionOkDto {
    public RigidDropMaterialType: RigidDropMaterialTypes;
    public AnyPoly: boolean;
    public AnyAlum: boolean;
    public RigidDropLength: number;
    public HasSAC: boolean;
    public PackageCoversSAC: boolean;
}

export class DropBottomFittingOptionOkDto {
    public AnyPoly: boolean;
    public AnyAlum: boolean;
    public UPipeMaterialType: UPipeMaterialTypes;
}

export class DropLengthOptionOkDto {
    public RigidDropType: RigidDropTypes;
    public RigidDropMaterialType: RigidDropMaterialTypes;
}

export class StainlessSteelMountsOKDto {
    public DependentSideSpanType: SpanTypes;
    public IsA100: boolean;
    public EndBoomSpanLength: number;
    public EndOfSystemType: EndOfSystemTypes;
}

export class CenterLiftOKDto {
    public HoseFeedType: HoseFeedTypes;
    public WaterFeedType: WaterFeedTypes;
    public EndingLocation: number;
}

export class GuidanceGPSBaseOKDto {
    public GuidanceType: GuidanceTypes;
    public Country: Countries;
    public HoseFeedType?: HoseFeedTypes;
    public GuidanceLocation: GuidanceLocations;
}

export class UMCCoverKitOkDto {
    public IsA100: boolean;
}

export class CattleGuardsOkDto {
    public IsA100: boolean;
    public SectionID: SectionIDs;
}

export class TermsOptionOkDto {
    public IsInternational: boolean;

}

export class RebateProgramOkDto {
    // public IsInternational: boolean;
    public TowerRebateProgramChecked: boolean;
}

export class TowerRebateProgramOkDto {
    public IsInternational: boolean;
    public RebateChecked: boolean;
    public PaymentType: PaymentTypes;
    public IsNationalAccountOrder: boolean;
    public InterestRateBuyDownChecked: boolean;
    public TowerRebateOffer: TowerRebateOffer;
}

export class InterestRateBuyDownOkDto {
    public IsInternational: boolean;
    public RebateChecked: boolean;
    public PaymentType: PaymentTypes;
    public TowerRebateProgramChecked: boolean;
}

export class InterestFreeOkDto {
    public PaymentType: PaymentTypes;
    public RebateChecked: boolean;
}

export class LowInterestOkDto {
    public PaymentType: PaymentTypes;
    public RebateChecked: boolean;
    public InterestFreeChecked: boolean;
}

export class RebateTypeOkDto {
    public RebateChecked: boolean;
    public TowerRebateProgramChecked: boolean;
}

export class PriceComputationOptionOkDto {
    public IsNationalAccountOrder: boolean;
    public IsAssociatedToOrder: boolean;
}

export class LDNPadTypeOptionOkDto {
    public IsLDN: boolean;
    public HasHoseDropWeight: boolean;
    public LDNType: LDNTypes;
    // public LDNChemPadType: LDNChemPadTypes;
}

export class LDNChemPadTypeOptionOkDto {
    public IsLDN: boolean;
    public HasHoseDrop: boolean;
    public HasHoseDropWeight: boolean;
    public LDNType: LDNTypes;
    public LDNPadType: LDNPadTypes;
}

export class AquaValvesOKDto {
    public WrapAroundSpanType: WrapAroundSpanTypes;
}

export class TowerControlLineBoosterPumpOKDto {
    public WrapAroundSpanType: WrapAroundSpanTypes;
    public AquaValvesChecked: boolean;
    public AirCompressorChecked: boolean;
}

export class TowerAirCompressorOkDto {
    public WrapAroundSpanType: WrapAroundSpanTypes;
    public AquaValvesChecked: boolean;
    public ControlLineBoosterPumpChecked: boolean;
}

export class EndOfSystemOKDto {
    public SwingArmAllowed: boolean;
    public SystemType: SystemTypes;
}

export class HingeTowerOnlyOptionOkDto {
    public EndOfSystemType: EndOfSystemTypes;
}

export class StrobeColorOptionOkDto {
    public PivotLightType: LightTypes;
    public HingeTowerOnly: boolean;
}

export class EndLightOptionOkDto {
    public EndOfSystemType: EndOfSystemTypes;
    public HingeTowerOnly: boolean;
    public HasPowerTowerEndBoom: boolean;
    public IsEDMP: boolean;
}

export class SwingArmLengthOKDto {
    public EndOfSystemType: EndOfSystemTypes;
    public HingeTowerHeight: TowerHeights;
}

export class EndLightConstantOnOKDto {
    public PivotLightType: LightTypes; // Not sure why ROE used a different type here
    public EndOfSystemType: EndOfSystemTypes;
}

export class TowerBoxKitOkDto {
    public NewLastSpanWireChecked: boolean;
}

export class NewLastSpanWireOkDto {
    public TowerBoxKitChecked: boolean;
}

export class OmitStiffenerStabilizerOkDto {
    public SACRetroSpanType: SACRetroSpanTypes;
    public SACRetroSpanLength: number;
}

export class GPSCoverageStudyPivotOkDto {
    public SACGPSGuidanceBaseOption: SACGPSGuidanceBaseOptions;
}

export class GPSCoverageStudyLateralOkDto extends GuidanceGPSBaseOKDto {
    public GPSBaseOption: GPSBaseOptions;
}

// Rules implemented in page instead
/*export class PumpOKDto {
    public WaterFeedType: WaterFeedTypes;
    public CartType: CartTypes;
    public ButterflyValesChecked: boolean;
    public CanalFeedEngine: CanalFeedEngines;
    public Generator: CanalFeedGenerators;
    public IsInternational: boolean;
}*/

export class MonitorOptionsOkDto {
    public SystemCategory: SystemCategories;
    public PanelModel: PanelModels;
    public RC10FacePlateUpgrade: RC10FacePlateUpgrade;
    public ModemBaseModel?: RC10ModemBaseModels;
    public MountingLocation: RC10MountingLocations;
    public HasPowerRelay: boolean;
    public IsRetro: boolean;
}

export class NormalSpacingOkDto {
    public IsMaxigator: boolean;
    public Sprinkler: idxSprinkler;
    public DropType: DropTypes;
    public Manufacturer: SprinklerManufacturerTypes;
}

export class ValidateExtraConductorCountDto {
    public SystemType: SystemTypes;
    public HoseFeedType?: HoseFeedTypes;
    public SpanCableGauge: number;
    public Side: Side;
    public CurrentSpanNumber: number;
    public PreviousSpanExtraConductors: number;
}

export class ExtraConductorCountValidationResult {
    public ResultFlag: ExtraConductorCountValidationFlag;
    public ExtraConductorUpperLimit: number;
}

export enum ExtraConductorCountValidationFlag {
    OK,
    NotSupportedHoseFeedA100CF200,
    ExtraConductorsExceedsUpperLimit,
    ExtraConductorsExceedsPreviousSpan,
}

export class GPSBaseOKDto {
    public Country: Countries;
}

export class GPSBaseConvertOKDto {
    public SACGPSGuidanceBaseOption: SACGPSGuidanceBaseOptions;
    public Country: Countries;
}

export class PivotKitOKDto {
    public HoseFeedType: HoseFeedTypes;
    public WaterFeedType: WaterFeedTypes;
    public HasCenterLift: boolean;
}

export class BoomBackOptionOkDto {
    public AnyLowProfiles: boolean;
    public AnyPoly: boolean;
}

export class BoomBackDeviceOptionOkDto {
    public BoomBackType: BoomBackTypes;
    public SprinklerManufacturerType: SprinklerManufacturerTypes;
}

export class BoomBackMaterialOptionOkDto {
    public BoomBackType: BoomBackTypes;
    public Country: Countries;
    public AnyAlum: boolean;
    public HasValvesAndControls: boolean;
}

export class UseNelson3030OkDto {
    public Sprinkler: idxSprinkler;
}

export class UseNelsonAD3030MTOkDto {
    public Sprinkler: idxSprinkler;
    public UseNelson3030: boolean;
}

export class TowTypeOkDto {
    public CenterPivotType: CenterPivotTypes;
    public PivotCenterHeight: TowerHeights;
    public RiserPipeType: RiserPipeTypes;
    public PipeBottomFittingType: PipeBottomFittingTypes;
    public HasVerticalFlowmeter: boolean;
}

export class PipeBottomFittingOKDto {
    public CenterPivotType: CenterPivotTypes;
    public PivotCenterHeight: TowerHeights;
}

export class ValveTypeOptionOkDto {
    public HasHoseDropsInAnyPackage: boolean;
}

export class SwingArmPipeCoatingOKDto {
    public ParentSpanType: SpanTypes;
    public HasGalvanizedCNPlus: boolean;
    public SystemType: SystemTypes;
}

// Rules implemented in page instead
/*export class AgriInjectTypeOKDto {
    public ElectricalFrequency: ElectricalFrequencies;
}

export class AgriInjectUnitTypeOkDto {
    public ElectricalFrequency: ElectricalFrequencies;
    public AgriInjectType: AgriInjectTypes;
}

export class AgriInjectTankSizeOkDto {
    public AgriInjectType: AgriInjectTypes;
}

export class AgriInjectGallonsPerHourOkDto {
    public AgriInjectType: AgriInjectTypes;
    public UnitType: UnitTypes;
    public TankSize: TankTypes;
}*/

export enum idxRSNPlates{
    rsnU4Blue = 0,
    rsnU6White = 1,
    rsnD4Green = 2,
    rsnD6Red = 3,
    rsnD6Purple = 4,
    rsnD8Yellow = 5,
    rsnD8Orange = 6,
    rsnD10Brown = 7,
    rsnD4Maroon = 8,
    rsnD6Gold = 9,
    rsnU6Navy = 10,
    rsn9GBlack = 11,
    rsn9GBlue = 12,
    rsn18GPurple = 13,
    rsnD10Olive = 14,
    rsn9GWhite = 15,
}

export enum PressureDevices {
    None,
    Transducer_0to25,
    Transducer_0to200,
    Switch_4_5Psi,
    Switch_20Psi,
}