import { PairingHeap, PairingHeapNode } from "../../Optimization/PairingHeap";
import { IEnhProgress } from "../../Progress/IEnhProgress";
import { IComparable } from "../../System/IComparable";
import { QuantizedSACOptimizationState } from "../QuantizedSACOptimizationState";
import { SACCentrePivotType } from "../SACCentrePivotType";

class NumberWithCompare extends Number implements IComparable<Number> {
    CompareTo(other: Number) {
        return this.valueOf() - other.valueOf();
    }
}

export class SmoothingSACOptimizer {
    public readonly State: QuantizedSACOptimizationState;
    readonly order2: boolean;
    
    public constructor(state: QuantizedSACOptimizationState, order2: boolean) {
        this.State = state;
        this.order2 = order2;
    }

    priorityQueue: PairingHeap<NumberWithCompare, number> | null = null;
    priorityQueueNodes: (PairingHeapNode<NumberWithCompare, number> | null)[] | null = null;

    public async Optimize(progress: IEnhProgress): Promise<boolean> {
        progress.SetStatusMessage("Applying order-1" + (this.order2 ? " and order-2" : "") + " constraints");
        progress.Report(0);

        const iExtensionAngles = [ ...this.State.BestSolution ];
        
        this.priorityQueue = new PairingHeap<NumberWithCompare, number>();
        this.priorityQueueNodes = Array<null>(this.State.iPivotAngleMax - this.State.iPivotAngleMin + 1).fill(null);
        for (let iPivotAngle = this.State.iPivotAngleMin; iPivotAngle <= this.State.iPivotAngleMax; iPivotAngle++) {
            this.priorityQueueNodes[iPivotAngle - this.State.iPivotAngleMin] =
                this.priorityQueue.Insert(new NumberWithCompare(-this.State.BestSolution[iPivotAngle - this.State.iPivotAngleMin]), iPivotAngle);
        }
        
        while (!this.priorityQueue.IsEmpty) {
            if (await progress.isCancelPending()) {
                return false;
            }

            const iPivotAngle = this.priorityQueue.First;
            this.priorityQueue.RemoveFirst();
            this.priorityQueueNodes[iPivotAngle - this.State.iPivotAngleMin] = null;

            const iPivotAnglePrev = this.State.GetPrevPivotAngleIndex(iPivotAngle);
            const iPivotAngleNext = this.State.GetNextPivotAngleIndex(iPivotAngle);

            let iExtensionAnglePrev = iPivotAnglePrev !== -1 ? iExtensionAngles[iPivotAnglePrev - this.State.iPivotAngleMin] : -1;
            let iExtensionAngleNext = iPivotAngleNext !== -1 ? iExtensionAngles[iPivotAngleNext - this.State.iPivotAngleMin] : -1;

            const changedPivotAngles = new Set<number>();

            let iExtensionAngle = iExtensionAngles[iPivotAngle - this.State.iPivotAngleMin];
            if (iExtensionAnglePrev !== -1 && this.State.constraintsOrder1CacheFwd[iExtensionAnglePrev].iExtensionAngleMin > iExtensionAngle) {
                iExtensionAngle = this.State.constraintsOrder1CacheFwd[iExtensionAnglePrev].iExtensionAngleMin;
                iExtensionAngles[iPivotAngle - this.State.iPivotAngleMin] = iExtensionAngle;
                changedPivotAngles.add(iPivotAngle);
            }
            if (iExtensionAngleNext !== -1 && this.State.constraintsOrder1CacheRev[iExtensionAngleNext].iExtensionAngleMin > iExtensionAngle) {
                iExtensionAngle = this.State.constraintsOrder1CacheRev[iExtensionAngleNext].iExtensionAngleMin;
                iExtensionAngles[iPivotAngle - this.State.iPivotAngleMin] = iExtensionAngle;
                changedPivotAngles.add(iPivotAngle);
            }

            while (iExtensionAngle <= this.State.iExtensionAngleMaxFeasible) {
                if (!this.State.CheckConstraintsOrder0(iPivotAngle, iExtensionAngle)) {
                    iExtensionAngle++;
                    iExtensionAngles[iPivotAngle - this.State.iPivotAngleMin] = iExtensionAngle;
                    changedPivotAngles.add(iPivotAngle);
                    continue;
                }
                    
                if (this.order2 && iExtensionAnglePrev !== -1 && iExtensionAngleNext !== -1) {
                    const steeringRate = this.State.GetSteeringRateDegreesPerMetre(iExtensionAnglePrev, iExtensionAngle, iExtensionAngleNext);
                    if (Math.abs(steeringRate) > this.State.Problem.Model.ModelConstraints.MaxSteeringRateDegreesPerMetre) {
                        const steeringRateIncr = this.State.GetSteeringRateDegreesPerMetre(iExtensionAnglePrev, iExtensionAngle + 1, iExtensionAngleNext);
                        if (steeringRateIncr / steeringRate < 1) {
                            iExtensionAngle++;
                            iExtensionAngles[iPivotAngle - this.State.iPivotAngleMin] = iExtensionAngle;
                            changedPivotAngles.add(iPivotAngle);
                            continue;
                        }
                        else {
                            /* This is the only place in this algorithm where we can introduce a suboptimal result or failure to find a
                                * valid solution.  It might be that reducing the other side's extension angle is the right thing to do, but
                                * it is extremely computationally expensive to find out! */

                            var canReducePrev = iExtensionAnglePrev < this.State.iExtensionAngleMaxFeasible;
                            var canReduceNext = iExtensionAngleNext < this.State.iExtensionAngleMaxFeasible;

                            if (!canReducePrev && !canReduceNext) {
                                // This should never happen
                                return false;
                            }

                            var collisionPrev = this.State.CheckConstraintsOrder0(iPivotAnglePrev, iExtensionAnglePrev + 1);
                            var collisionNext = this.State.CheckConstraintsOrder0(iPivotAngleNext, iExtensionAngleNext + 1);

                            if (!canReduceNext) {
                                iExtensionAnglePrev = iExtensionAngles[iPivotAnglePrev - this.State.iPivotAngleMin]++;
                                changedPivotAngles.add(iPivotAnglePrev);
                            } else if (!canReducePrev) {
                                iExtensionAngleNext = iExtensionAngles[iPivotAngleNext - this.State.iPivotAngleMin]++;
                                changedPivotAngles.add(iPivotAngleNext);
                            }
                            else if (collisionNext && !collisionPrev) {
                                // Note that this is not guaranteed to maintain a complete or optimal solution
                                // because it may be better to "jump" an obstacle 
                                iExtensionAnglePrev = iExtensionAngles[iPivotAnglePrev - this.State.iPivotAngleMin]++;
                                changedPivotAngles.add(iPivotAnglePrev);
                            }
                            else if (!collisionNext && collisionPrev) {
                                // Note that this is not guaranteed to maintain a complete or optimal solution
                                // because it may be better to "jump" an obstacle 
                                iExtensionAngleNext = iExtensionAngles[iPivotAngleNext - this.State.iPivotAngleMin]++;
                                changedPivotAngles.add(iPivotAngleNext);
                            }
                            else {
                                // It's not obvious which side to reduce, apply smoothing
                                if (!this.Smooth(iPivotAngle, iExtensionAngles, changedPivotAngles)) {
                                    // Smoothing hits an obstacle in both directions. It's pretty hopeless but try
                                    // to reduce both sides in the vain hope that we can jump it.
                                    iExtensionAngles[iPivotAnglePrev - this.State.iPivotAngleMin]--;
                                    iExtensionAngles[iPivotAngleNext - this.State.iPivotAngleMin]++;
                                    changedPivotAngles.add(iPivotAnglePrev);
                                    changedPivotAngles.add(iPivotAngleNext);
                                }

                                iExtensionAngle = iExtensionAngles[iPivotAngle - this.State.iPivotAngleMin];
                                iExtensionAnglePrev = iExtensionAngles[iPivotAnglePrev - this.State.iPivotAngleMin];
                                iExtensionAngleNext = iExtensionAngles[iPivotAngleNext - this.State.iPivotAngleMin];
                            }
                            
                            continue;
                        }
                    }
                }

                break;
            }

            if (iExtensionAngle === this.State.iExtensionAngleMaxFeasible + 1) {
                return false;
                /*throw new Exception(string.Format("There is no valid extension angle for the SAC at pivot angle {0:0.0} degrees",
                    optimizer.GetTheta(iPivotAngle) * 180.0 / Math.PI));*/
            }

            for (const iChangedPivotAngle of changedPivotAngles) {
                let e = new NumberWithCompare(-(iExtensionAngles[iChangedPivotAngle - this.State.iPivotAngleMin]));
                if (this.priorityQueueNodes[iChangedPivotAngle - this.State.iPivotAngleMin] === null)
                {
                    this.priorityQueueNodes[iChangedPivotAngle - this.State.iPivotAngleMin] = this.priorityQueue.Insert(e, iChangedPivotAngle);
                }
                else {
                    const k = this.priorityQueueNodes[iChangedPivotAngle - this.State.iPivotAngleMin];
                    if (k !== null) {
                        this.priorityQueue.DecreaseKey(k, e);
                    }
                }

                var i = this.State.GetPrevPivotAngleIndex(iChangedPivotAngle);
                if (i !== -1)
                {
                    e = new NumberWithCompare(-(iExtensionAngles[i - this.State.iPivotAngleMin]));
                    if (this.priorityQueueNodes[i - this.State.iPivotAngleMin] === null)
                    {
                        this.priorityQueueNodes[i - this.State.iPivotAngleMin] = this.priorityQueue.Insert(e, i);
                    }
                    else {
                        const k = this.priorityQueueNodes[i - this.State.iPivotAngleMin];
                        if (k !== null) {
                            this.priorityQueue.DecreaseKey(k, e);
                        }
                    }
                }

                i = this.State.GetNextPivotAngleIndex(iChangedPivotAngle);
                if (i !== -1) {
                    e = new NumberWithCompare(-(iExtensionAngles[i - this.State.iPivotAngleMin]));
                    if (this.priorityQueueNodes[i - this.State.iPivotAngleMin] === null) {
                        this.priorityQueueNodes[i - this.State.iPivotAngleMin] = this.priorityQueue.Insert(e, i);
                    }
                    else {
                        const k = this.priorityQueueNodes[i - this.State.iPivotAngleMin];
                        if (k !== null) {
                            this.priorityQueue.DecreaseKey(k, e);
                        }
                    }
                }
            }
        }

        this.State.BestSolution = iExtensionAngles;
        return true;
    }

    Smooth(iPivotAngle: number, iExtensionAngles: number[], changedPivotAngles: Set<number>): boolean {
        const iPivotAnglePrev = this.State.GetPrevPivotAngleIndex(iPivotAngle);
        const iPivotAngleNext = this.State.GetNextPivotAngleIndex(iPivotAngle);

        let iExtensionAnglePrev = iExtensionAngles[iPivotAnglePrev - this.State.iPivotAngleMin];
        let iExtensionAngle = iExtensionAngles[iPivotAngle - this.State.iPivotAngleMin];
        let iExtensionAngleNext = iExtensionAngles[iPivotAngleNext - this.State.iPivotAngleMin];

        const iExtensionAnglePrevOrig = iExtensionAnglePrev;
        const iExtensionAngleNextOrig = iExtensionAngleNext;

        let steeringRate = this.State.GetSteeringRateDegreesPerMetre(iExtensionAnglePrev, iExtensionAngle, iExtensionAngleNext);
        let steeringRateIncr = this.State.GetSteeringRateDegreesPerMetre(iExtensionAnglePrev, iExtensionAngle + 1, iExtensionAngleNext);

        while (!(Math.abs(steeringRate) <= this.State.Problem.Model.ModelConstraints.MaxSteeringRateDegreesPerMetre
            || steeringRateIncr / steeringRate < 1)) {
            const firstTryDirection = iExtensionAnglePrev < iExtensionAngleNext ? -1 : 1;

            let iWalkExtensionAngles = [ ...iExtensionAngles ];
            let iWalkChangedPivotAngles = new Set<number>();
            let iAngleFirstTry: number = iPivotAngle + firstTryDirection; 
            // Simon 2024.04.15: Fix bug in RDPv1 where code is
            // accessing out of bounds index
            if (this.State.Problem.CentrePivotType === SACCentrePivotType.Full) {
                if (iAngleFirstTry === this.State.iPivotAngleMin - 1) {
                    iAngleFirstTry = this.State.iPivotAngleMax;
                }
                else if (iAngleFirstTry === this.State.iPivotAngleMax + 1) {
                    iAngleFirstTry = this.State.iPivotAngleMin;
                }
            }
            if ((iAngleFirstTry - this.State.iPivotAngleMin) < 0 || (iAngleFirstTry - this.State.iPivotAngleMin) >= iWalkExtensionAngles.length) {
                throw new Error("Index out of bounds, first try");
            }
            iWalkChangedPivotAngles.add(iAngleFirstTry);
            iWalkExtensionAngles[iAngleFirstTry - this.State.iPivotAngleMin]++;


            if (!this.Walk(iPivotAngle + firstTryDirection, iWalkExtensionAngles, firstTryDirection, iWalkChangedPivotAngles)/* ||
                !Walk(iPivotAngle + firstTryDirection, iWalkExtensionAngles, 1, iWalkChangedPivotAngles)*/)
            {
                iWalkExtensionAngles = [ ...iExtensionAngles ];
                iWalkChangedPivotAngles = new Set<number>();
                // Simon 2024.04.15: Fix bug in RDPv1 where code is
                // accessing out of bounds index
                let iAngleSecondTry: number = iPivotAngle - firstTryDirection;                
                if (this.State.Problem.CentrePivotType === SACCentrePivotType.Full) {
                    if (iAngleSecondTry === this.State.iPivotAngleMin - 1) {
                        iAngleSecondTry = this.State.iPivotAngleMax;
                    }
                    else if (iAngleSecondTry === this.State.iPivotAngleMax + 1) {
                        iAngleSecondTry = this.State.iPivotAngleMin;
                    }
                }
                if ((iAngleSecondTry - this.State.iPivotAngleMin) < 0 || (iAngleSecondTry - this.State.iPivotAngleMin) >= iWalkExtensionAngles.length) {
                    throw new Error("Index out of bounds, second try");
                }
                // End fix: Simon
                iWalkChangedPivotAngles.add(iAngleSecondTry);
                iWalkExtensionAngles[iAngleSecondTry - this.State.iPivotAngleMin]++;

                if (!this.Walk(iPivotAngle - firstTryDirection, iWalkExtensionAngles, -firstTryDirection, iWalkChangedPivotAngles)/* ||
                !Walk(iPivotAngle - firstTryDirection, iWalkExtensionAngles, 1, iWalkChangedPivotAngles)*/) {
                    return false;
                }
            }
            

            for (const i of iWalkChangedPivotAngles)
            {
                changedPivotAngles.add(i);
                iExtensionAngles[i - this.State.iPivotAngleMin] = iWalkExtensionAngles[i - this.State.iPivotAngleMin];
            }

            iExtensionAnglePrev = iExtensionAngles[iPivotAnglePrev - this.State.iPivotAngleMin];
            iExtensionAngle = iExtensionAngles[iPivotAngle - this.State.iPivotAngleMin];
            iExtensionAngleNext = iExtensionAngles[iPivotAngleNext - this.State.iPivotAngleMin];

            steeringRate = this.State.GetSteeringRateDegreesPerMetre(iExtensionAnglePrev, iExtensionAngle, iExtensionAngleNext);
            steeringRateIncr = this.State.GetSteeringRateDegreesPerMetre(iExtensionAnglePrev, iExtensionAngle + 1, iExtensionAngleNext);
        }

        return true;
    }


    Walk(iPivotAngle: number, iExtensionAngles: number[], direction: number, changedPivotAngles: Set<number>): boolean {
        if (this.State.Problem.CentrePivotType === SACCentrePivotType.Full) {
            if (iPivotAngle === this.State.iPivotAngleMin - 1) {
                iPivotAngle = this.State.iPivotAngleMax;
            }
            else if (iPivotAngle === this.State.iPivotAngleMax + 1) {
                iPivotAngle = this.State.iPivotAngleMin;
            }
        }

        const iPivotAnglePrev = this.State.GetPrevPivotAngleIndex(iPivotAngle);
        const iPivotAngleNext = this.State.GetNextPivotAngleIndex(iPivotAngle);

        if (iPivotAnglePrev === -1) {
            return true;
        }

        if (iPivotAngleNext === -1) {
            return true;
        }
        
        let iExtensionAnglePrev = iExtensionAngles[iPivotAnglePrev - this.State.iPivotAngleMin];
        const iExtensionAngle = iExtensionAngles[iPivotAngle - this.State.iPivotAngleMin];
        let iExtensionAngleNext = iExtensionAngles[iPivotAngleNext - this.State.iPivotAngleMin];

        let steeringRate = this.State.GetSteeringRateDegreesPerMetre(iExtensionAnglePrev, iExtensionAngle, iExtensionAngleNext);
        let steeringRateIncr = this.State.GetSteeringRateDegreesPerMetre(iExtensionAnglePrev, iExtensionAngle + 1, iExtensionAngleNext);

        if (Math.abs(steeringRate) <= this.State.Problem.Model.ModelConstraints.MaxSteeringRateDegreesPerMetre
            || steeringRateIncr / steeringRate < 1) {
            return true;
        }

        while (!(Math.abs(steeringRate) <= this.State.Problem.Model.ModelConstraints.MaxSteeringRateDegreesPerMetre
            || steeringRateIncr / steeringRate < 1)) {
            if (direction === -1)
            {
                iExtensionAnglePrev = iExtensionAngles[iPivotAnglePrev - this.State.iPivotAngleMin]++;
                changedPivotAngles.add(iPivotAnglePrev);
                if (iExtensionAnglePrev === this.State.iExtensionAngleMaxFeasible ||
                    !this.State.CheckConstraintsOrder0(iPivotAnglePrev, iExtensionAnglePrev)) {
                    return false;
                }
            }
            else {
                iExtensionAngleNext = iExtensionAngles[iPivotAngleNext - this.State.iPivotAngleMin]++;
                changedPivotAngles.add(iPivotAngleNext);
                if (iExtensionAngleNext === this.State.iExtensionAngleMaxFeasible ||
                    !this.State.CheckConstraintsOrder0(iPivotAngleNext, iExtensionAngleNext)) {
                    return false;
                }
            }

            steeringRate = this.State.GetSteeringRateDegreesPerMetre(iExtensionAnglePrev, iExtensionAngle, iExtensionAngleNext);
            steeringRateIncr = this.State.GetSteeringRateDegreesPerMetre(iExtensionAnglePrev, iExtensionAngle + 1, iExtensionAngleNext);
        }

        if (direction === -1) {
            return this.Walk(iPivotAngle - 1, iExtensionAngles, -1, changedPivotAngles);
        }
        else {
            return this.Walk(iPivotAngle + 1, iExtensionAngles, 1, changedPivotAngles);
        }
    }
}