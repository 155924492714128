import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useContext } from "react";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import CustomDefaultsRadioGroup from "./CustomDefaultsRadioGroup";

import i18next, { t } from "i18next";
import { DisplayAreaUnits, DisplayAreaUnitsMap } from "../../helpers/areas";
import { DisplayIrrigationRateUnits, DisplayIrrigationRateUnitsMap } from "../../helpers/irrigationRates";
import { DisplayLengthUnits, DisplayLengthUnitsMap } from "../../helpers/lengths";
import { DisplayPressureUnits, DisplayPressureUnitsMap } from "../../helpers/pressures";
import { DisplayVolumeUnits, DisplayVolumeUnitsMap } from "../../helpers/volumes";
import { DisplayWeightsUnits, DisplayWeightsUnitsMap } from "../../helpers/weights";
import { EDisplayCoordinate } from "./EDisplayCoordinate";

export interface IDisplaySettingsFormState {
    coordinates: EDisplayCoordinate;
    areas: DisplayAreaUnits;
    lengths: DisplayLengthUnits;
    minorLengths: DisplayLengthUnits;
    pressures: DisplayPressureUnits;
    irrigationRates: DisplayIrrigationRateUnits;
    volumes: DisplayVolumeUnits;
    weights: DisplayWeightsUnits;
}

export const CUSTOM_DISPLAY_SETTINGS_DEFAULT_FORM_STATE: IDisplaySettingsFormState = {
    coordinates: EDisplayCoordinate.DECIMAL,
    areas: 'acres',
    lengths: 'feet',
    minorLengths: 'inches',
    pressures: 'psi',
    irrigationRates: "gpm",
    volumes: "gallons",
    weights: "pounds"
}

const TabBody: React.FC = () => {
    
    const devSettingsState = useContext(DevSettingsCtx);
    const { setFormState, formState } = devSettingsState.dealerSettings.display.custom;
    const { 
        coordinates, areas, lengths, minorLengths, pressures, irrigationRates, volumes, weights
    } = formState;

    const setCoordinates = (coordinate: EDisplayCoordinate) => setFormState({ ...formState, coordinates: coordinate });
    const setAreas = (areas: DisplayAreaUnits) => setFormState({ ...formState, areas });
    const setLengths = (lengths: DisplayLengthUnits) => setFormState({ ...formState, lengths });
    const setMinorLengths = (minorLengths: DisplayLengthUnits) => setFormState({ ...formState, minorLengths });
    const setPressures = (pressures: DisplayPressureUnits) => setFormState({...formState, pressures});
    const setRates = (rates: DisplayIrrigationRateUnits) => setFormState({...formState, irrigationRates: rates});
    const setVolumes = (volumes: DisplayVolumeUnits) => setFormState({...formState, volumes});
    const setWeights = (weights: DisplayWeightsUnits) => setFormState({...formState, weights});

    const ns = "dealer-settings.";
    const subns = "display-settings-options.";

    return (
        <>
            <CustomDefaultsRadioGroup 
                title={i18next.t(ns + "display-settings")}
                useCustomDefaults={devSettingsState.dealerSettings.display.useCustom}
                setUseCustomDefaults={devSettingsState.dealerSettings.display.setUseCustom}
            />
             {
                devSettingsState.dealerSettings.display.useCustom && (
                    <>
                        {/* Coordinates */}
                        <Box sx={{ overflow: "auto", flex: 1, padding: 2, width: "100%" }}>
                            <FormControl>
                                <FormLabel id="display-coordinates-label">{i18next.t(ns + subns + "coordinates")}</FormLabel>
                                <RadioGroup 
                                    aria-labelledby="display-coordinates-label"
                                    row
                                    value={coordinates}
                                    onChange={x => setCoordinates(x.target.value as EDisplayCoordinate)}
                                >
                                    <FormControlLabel 
                                        value={EDisplayCoordinate.DECIMAL} 
                                        control={<Radio />} 
                                        label={i18next.t(ns + subns + "decimal-degrees")} />
                                    <FormControlLabel 
                                        value={EDisplayCoordinate.DMS} 
                                        control={<Radio />} 
                                        label={i18next.t(ns + subns + "degrees-minutes-seconds")} />
                                </RadioGroup>
                            </FormControl>                             
                        </Box>

                        {/* Areas */}
                        <Box sx={{ overflow: "auto", flex: 1, padding: 2, width: "100%" }}>
                            <FormControl>
                                <FormLabel id="display-areas-label">{i18next.t(ns + subns + "areas")}</FormLabel>
                                <RadioGroup 
                                    aria-labelledby="display-areas-label"
                                    row
                                    value={areas}
                                    onChange={x => setAreas(x.target.value as DisplayAreaUnits)}
                                >
                                    <FormControlLabel 
                                        value={DisplayAreaUnitsMap.acres.value}
                                        control={<Radio />} 
                                        label={i18next.format(t('acres'), 'capitalize')} />
                                    <FormControlLabel 
                                        value={DisplayAreaUnitsMap.hectares.value}
                                        control={<Radio />} 
                                        label={i18next.format(t("hectares"), 'capitalize')} />
                                </RadioGroup>
                            </FormControl>                             
                        </Box>
                        
                        {/* Lengths */}
                        <Box sx={{ overflow: "auto", flex: 1, padding: 2, width: "100%" }}>
                            <FormControl>
                                <FormLabel id="display-lengths-label">{i18next.t(ns + subns + "lengths")}</FormLabel>
                                <RadioGroup 
                                    aria-labelledby="display-lengths-label"
                                    row
                                    value={lengths}
                                    onChange={x => setLengths(x.target.value as DisplayLengthUnits)}
                                >
                                    <FormControlLabel 
                                        value={DisplayLengthUnitsMap.feet.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t("feet"), 'capitalize')} />
                                    <FormControlLabel 
                                        value={DisplayLengthUnitsMap.meters.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t("meters"), 'capitalize')} />
                                </RadioGroup>
                            </FormControl>                             
                        </Box>
                        
                        {/* Minor Lengths */}
                        <Box sx={{ overflow: "auto", flex: 1, padding: 2, width: "100%" }}>
                            <FormControl>
                                <FormLabel id="display-minor-lengths-label">{i18next.t(ns + subns + "minor-lengths")}</FormLabel>
                                <RadioGroup 
                                    aria-labelledby="display-minor-lengths-label"
                                    row
                                    value={minorLengths}
                                    onChange={x => setMinorLengths(x.target.value as DisplayLengthUnits)}
                                >
                                    <FormControlLabel 
                                        value={DisplayLengthUnitsMap.inches.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t("inches"), 'capitalize')} />
                                    <FormControlLabel 
                                        value={DisplayLengthUnitsMap.centimeters.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t("centimeters"), 'capitalize')} />
                                    <FormControlLabel 
                                        value={DisplayLengthUnitsMap.millimeters.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t("millimeters"), 'capitalize')} />
                                </RadioGroup>
                            </FormControl>                             
                        </Box>

                        {/* Pressures */}
                        <Box sx={{ overflow: "auto", flex: 1, padding: 2, width: "100%" }}>
                            <FormControl>
                                <FormLabel id="display-pressures-label">{i18next.t(ns + subns + "pressures")}</FormLabel>
                                <RadioGroup 
                                    aria-labelledby="display-pressures-label"
                                    row
                                    value={pressures}
                                    onChange={x => setPressures(x.target.value as DisplayPressureUnits)}
                                >
                                    <FormControlLabel 
                                        value={DisplayPressureUnitsMap.psi.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t("common-phrases.sltPSI"), 'capitalize')} />
                                    <FormControlLabel 
                                        value={DisplayPressureUnitsMap.bar.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t("common-phrases.sltBar"), 'capitalize')} />
                                </RadioGroup>
                            </FormControl>                             
                        </Box>

                        {/* Irrigation Rates */}
                        <Box sx={{ overflow: "auto", flex: 1, padding: 2, width: "100%" }}>
                            <FormControl>
                                <FormLabel id="display-rates-label">{i18next.t(ns + subns + "irrigation-rates")}</FormLabel>
                                <RadioGroup 
                                    aria-labelledby="display-rates-label"
                                    row
                                    value={irrigationRates}
                                    onChange={x => setRates(x.target.value as DisplayIrrigationRateUnits)}
                                >
                                    <FormControlLabel 
                                        value={DisplayIrrigationRateUnitsMap.gpm.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t("common-phrases.sltGPM"), 'uppercase')} />
                                    <FormControlLabel 
                                        value={DisplayIrrigationRateUnitsMap.cmh.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t("common-phrases.sltCMH"))} />
                                </RadioGroup>
                            </FormControl>                             
                        </Box>

                        {/* Volumes */}
                        <Box sx={{ overflow: "auto", flex: 1, padding: 2, width: "100%" }}>
                            <FormControl>
                                <FormLabel id="display-volumes-label">{i18next.t(ns + subns + "volumes")}</FormLabel>
                                <RadioGroup 
                                    aria-labelledby="display-volumes-label"
                                    row
                                    value={volumes}
                                    onChange={x => setVolumes(x.target.value as DisplayVolumeUnits)}
                                >
                                    <FormControlLabel 
                                        value={DisplayVolumeUnitsMap.gallons.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t(DisplayVolumeUnitsMap.gallons.fullName), "capitalize")} />
                                    <FormControlLabel 
                                        value={DisplayVolumeUnitsMap.liters.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t(DisplayVolumeUnitsMap.liters.fullName), "capitalize")} />
                                </RadioGroup>
                            </FormControl>                             
                        </Box>

                        {/* Weights */}
                        <Box sx={{ overflow: "auto", flex: 1, padding: 2, width: "100%" }}>
                            <FormControl>
                                <FormLabel id="display-weights-label">{i18next.t(ns + subns + "weights")}</FormLabel>
                                <RadioGroup 
                                    aria-labelledby="display-weights-label"
                                    row
                                    value={weights}
                                    onChange={x => setWeights(x.target.value as DisplayWeightsUnits)}
                                >
                                    <FormControlLabel 
                                        value={DisplayWeightsUnitsMap.pounds.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t(DisplayWeightsUnitsMap.pounds.fullName), "capitalize")} />
                                    <FormControlLabel 
                                        value={DisplayWeightsUnitsMap.kilos.value} 
                                        control={<Radio />} 
                                        label={i18next.format(t(DisplayWeightsUnitsMap.kilos.fullName), "capitalize")} />
                                </RadioGroup>
                            </FormControl>                             
                        </Box>
                    </>
                )
             }   
        </>
    );
}

export default <TabBody />;
