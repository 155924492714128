import { IMiscItem, IQuoteProperties, PaymentTypes, PriceComputationMethods, RebateTypes } from "rdptypes/project/ISystemBase.AutoGenerated";

export default interface IOldQuoteProperties {
    ShipVia: string,
    CustomerPriceInfo: {
        PriceComputationMethod: PriceComputationMethods,
        ListPriceDiscountPercent?: number,
        DealerPriceMarkupPercent?: number,
        DealerPriceAbsoluteMarkup?: number,
        DealerDeterminedPrice?: number,
        SalesTax?: number,
        SystemTaxable?: boolean;
        InstallationTaxable?: boolean;
        FreightTaxable?: boolean;
        PivotPadTaxable?: boolean;
        TradeInTaxable?: boolean;
        MiscTaxable?: boolean; //We don't need this because each MiscItem has a "taxable" flag on it
        ListPriceOnCustomerReports: boolean,
        TotalPriceOnlyOnCustomerReports?: boolean;
        RebateTaxable?: boolean; // Rebate no longer supported (as per call 11/04/2024)
        TowerRebateTaxable?: boolean; // Do we have this concept? - No - tower rebate has been disabled for 2024 as per line 5122 of Valids.vb (TowerRebateProgramOk(args)) 
        //so have been unable to test current functionality BUT tower rebate will need to be re-added later
    },
    CustomerPaymentInfo: {
        PaymentComputationMethod: "Percent" | "Price", // Do we have this concept?
        PercentDown: number,
        PercentDelivery?: number;
        DownPayment?: number;
        DeliveryPayment?: number;
        PaymentType: PaymentTypes,
        OtherTermDescription?: string; // Do we have this concept? - No - ROE used to support PaymentTypes.Other but according to InfoPaymentForm.vb line 220 the OtherOption box is no longer visible
        MidAmericaRiskManagers?: boolean;
        Rebate?: boolean; // Do we have this concept? - No - Rebate no longer supported (as per call 11/04/2024)
        PerTowerRebateAmount?: number; // Do we have this concept? - Not currently - towerRebate will need to be added later but disabled for 2024
        RebateMonth?: string; // Do we have this concept? - No - Rebate no longer supported (as per call 11/04/2024)
        PerTowerRebateAmount2?: number;// Do we have this concept? - Not currently - towerRebate will need to be added later but disabled for 2024
        RebateMonth2?: string;// Do we have this concept? - No - Rebate no longer supported (as per call 11/04/2024)
        RebateType: RebateTypes, // Do we have this concept? - No - Rebate no longer supported (as per call 11/04/2024)
        InterestFree?: boolean; // Do we have this concept? - No - according to InfoPaymentForm.vb line 220 this is no longer visible
        InterestFreeDate: string, // Do we have this concept? - No - according to InfoPaymentForm.vb line 220 this is no longer visible
        LowInterest?: boolean; // Do we have this concept? - No - according to InfoPaymentForm.vb line 220 this is no longer visible
        TowerRebateProgram?: boolean; // Do we have this concept? - No - tower rebate has been disabled for 2024 as per line 5122 of Valids.vb (TowerRebateProgramOk(args)) so have been unable to test current functionality
        InterestRateBuyDown: boolean;
    },
    DealerInfo: {
        Number: number
    },
    MiscItems: IMiscItem[],
    DiscountConfig: {
        StandardDiscount: number
        ProgramDiscount: number,
        DeliveryDiscount?: number,
        TerritoryManagerDiscount?: number,
        CashDiscount: number,
        ClemonsFiltersDiscount: number,
        CheckValvesDiscount: number,
        HeatExchangersDiscount: number,
        MainlineValveDiscount: number,
        SprinklerLubeDiscount: number,
        AgriInjectDiscount: number,
        RadioTelemetryDiscount: number,
        RC10Discount: number,
        RC10RetroDiscount: number,
        CropXDiscount: number,
        FlowmeterComponentsDiscount: number,
        IsInternational: boolean,
        IsNationalAccountOrder: boolean,
    },
    CustomerName: string,
    ManagerName?: string,
    Address1: string,
    Address2City: string,
    Address2State: string,
    Address2Zip: number,
    Address2Country: string,
    MailingAddress1?: string,
    MailingAddress2?: string,
    Email?: string,
    Phone?: string,
    Legal?: string,
    TradeInNotes?: string,
    Comments?: string,
    FactoryComments?: string,
    InstallationPrice: number,
    FreightPrice?: number,
    PivotPadPrice?: number,
    TradeInPrice?: number,
    PONumber?: string,
    RequestedShipDate: string, // TODO check format. Maybe we can fix this with an xml2js option
    Salesperson: string,
    NationalAccountOrder: boolean,
}

export const convertShipDate = (shipDate: string): string | undefined => {
    const match = /^en-US(\d{2})\/(\d{2})\/(\d{4})$/;
    if (match) {
        return `${match[3]}-${match[1]}-${match[2]}`;
    } else {
        return undefined;
    }
}

export const convertQuoteProperties = (old: IOldQuoteProperties): IQuoteProperties => ({
    MiscItems: old?.MiscItems,
    ProposalInformation: {
        customerComments: old?.Comments ?? "",
        tradeinNotes: old?.TradeInNotes,
        factoryNotes: old?.FactoryComments,
        PONumber: old?.PONumber ?? "",
        pricing: {
            installation: {
                priceUsdCents: old?.InstallationPrice,
                isTaxable: old?.CustomerPriceInfo?.InstallationTaxable,
            },
            freight: {
                priceUsdCents: old?.FreightPrice,
                isTaxable: old?.CustomerPriceInfo?.FreightTaxable,
            },
            pivotPad: {
                priceUsdCents: old?.PivotPadPrice,
                isTaxable: old?.CustomerPriceInfo?.PivotPadTaxable,
            },
            tradeIn: {
                priceUsdCents: old?.TradeInPrice,
                isTaxable: old?.CustomerPriceInfo?.TradeInTaxable,
            },
            system: {
                isTaxable: old?.CustomerPriceInfo?.SystemTaxable,
            },
            downPayment: {
                percent: old?.CustomerPaymentInfo?.PercentDown,
                usdCents: old?.CustomerPaymentInfo?.DownPayment
            },
            paymentComputationMethod: old?.CustomerPaymentInfo.PaymentComputationMethod,
            dueOnDelivery: {
                percent: old?.CustomerPaymentInfo?.PercentDelivery,
                usdCents: old?.CustomerPaymentInfo?.DeliveryPayment
            },
            listPriceDiscountPercent: old?.CustomerPriceInfo?.PriceComputationMethod === PriceComputationMethods.ListPricePercentageDiscount ? old?.CustomerPriceInfo?.ListPriceDiscountPercent : 0,
            markupPercentOverDealerCost: old?.CustomerPriceInfo?.PriceComputationMethod === PriceComputationMethods.DealerPricePercentageMarkup ? old?.CustomerPriceInfo?.DealerPriceMarkupPercent : 0,
            markupDollarOverDealerCost: old?.CustomerPriceInfo?.PriceComputationMethod === PriceComputationMethods.DealerPriceAbsoluteMarkup ? old?.CustomerPriceInfo?.DealerPriceAbsoluteMarkup : 0,
            quotedPriceDollar: old?.CustomerPriceInfo?.PriceComputationMethod === PriceComputationMethods.DealerDetermined ? old?.CustomerPriceInfo?.DealerDeterminedPrice : 0,
            salesTaxPercent: old?.CustomerPriceInfo?.SalesTax,
            programDiscountPercent: old?.DiscountConfig?.ProgramDiscount,
            tmDiscountPercent: old?.DiscountConfig?.ProgramDiscount,
            deliveryDiscountPercent: old?.DiscountConfig?.DeliveryDiscount,
            printListPrice: old?.CustomerPriceInfo?.ListPriceOnCustomerReports,
            onlyPrintTotalPrice: old?.CustomerPriceInfo?.TotalPriceOnlyOnCustomerReports,
        },
        paymentTerms: old?.CustomerPaymentInfo?.PaymentType,
        hasMidAmericaRiskInsurance: old?.CustomerPaymentInfo?.MidAmericaRiskManagers,
        shipVia: old?.ShipVia ?? "",
        requestedShippingDate: convertShipDate(old?.RequestedShipDate),
        isNationalAccountOrder: old?.NationalAccountOrder,
        reinkeFinanceInterestBuyDown: old?.CustomerPaymentInfo?.InterestRateBuyDown
    }
});