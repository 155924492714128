import { BoosterPumpTypes, ElectricalFrequencies } from "rdptypes/project/ISystemBase.AutoGenerated";
import { EndGunHelper } from "./EndGunHelper";

export class PressureHelper {
    private EGH: EndGunHelper;
    constructor(EGHelper: EndGunHelper) {
        this.EGH = EGHelper;
    }

    Pressure = (GPM: number): number => {
        let dPowerFactor: number = this.EGH.FrequencyHz === ElectricalFrequencies.a60 ? 1 : 0.833333;
        let CalcPressure: (x: number) => number = (base: number) =>
            this.EGH.SystemEndPressure + (base * dPowerFactor);

        switch (this.EGH.BoosterPumpType) {
            case BoosterPumpTypes.TwoHP:
                if (GPM >= 170) {
                    this.EGH.BoosterPumpLimitExceeded = true;
                    this.EGH.PrevSmallerNozzle();
                }
                if (GPM < 100) return CalcPressure(33.5);
                else if (GPM < 110) return CalcPressure(33);
                else if (GPM < 120) return CalcPressure(32);
                else if (GPM < 130) return CalcPressure(31.5);
                else if (GPM < 140) return CalcPressure(30);
                else if (GPM < 150) return CalcPressure(28.5);
                else if (GPM < 155) return CalcPressure(27.5);
                else if (GPM < 160) return CalcPressure(26.5);
                else if (GPM < 165) return CalcPressure(25.5);
                else return CalcPressure(24.5);
            case BoosterPumpTypes.FiveHP:
                if (GPM >= 240) {
                    this.EGH.BoosterPumpLimitExceeded = true;
                    this.EGH.PrevSmallerNozzle();
                }
                if (GPM < 100) return CalcPressure(38.5);
                else if (GPM < 100) return CalcPressure(38.5);
                else if (GPM < 130) return CalcPressure(39);
                else if (GPM < 140) return CalcPressure(38.5);
                else if (GPM < 150) return CalcPressure(38);
                else if (GPM < 155) return CalcPressure(37.625);
                else if (GPM < 160) return CalcPressure(37.25);
                else if (GPM < 165) return CalcPressure(36.875);
                else if (GPM < 170) return CalcPressure(36.5);
                else if (GPM < 175) return CalcPressure(36);
                else if (GPM < 180) return CalcPressure(35.5);
                else if (GPM < 185) return CalcPressure(35);
                else if (GPM < 190) return CalcPressure(34.5);
                else if (GPM < 200) return CalcPressure(33);
                else if (GPM < 210) return CalcPressure(32);
                else if (GPM < 215) return CalcPressure(31);
                else if (GPM < 220) return CalcPressure(30);
                else if (GPM < 225) return CalcPressure(29);
                else if (GPM < 230) return CalcPressure(28);
                else if (GPM < 235) return CalcPressure(27);
                else return CalcPressure(26);
            default:
                if (GPM < 500) return this.EGH.SystemEndPressure;
                else return 0;
        }
    }

}

