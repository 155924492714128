import { Text, View } from "@react-pdf/renderer";
import * as React from "react";
import { FC } from "react";
import { documentStyles, primaryColor } from "../styles";
import ReinkeLogo from "./Logos/ReinkeLogoWhite";

interface Props {
};

const WarrantySummary: FC<Props> = (props) => {
    let messages: {[id: string]: {items: {title: string, subtitle?: string, bullets?: string[][]}[]}[]} = {};

    messages["Water Pipe"] = [
        {
            items: [{
                title: "V-Ring Seal",
                subtitle: "Twenty-Five (25) years"
            }, {
                title: "Span Joint Water Hose (Boots)",
                subtitle: "Ten (10) years"
            }]
        },
        {
            items: [{
                title: "Structural failure due to corrosion",
                subtitle: "Twenty (20) years",
                bullets: [
                    ["Galvanized Steel", "Aluminium", "Poly-Lined"],
                    ["CN+", "Stainless Steel", ""]
                ]
            }]
        }
    ];

    messages["Drive Train"] = [
        {
            items: [{
                title: "Wheel Drive Gears",
                bullets: [
                    ["Reinke 10/16: Ten (10) years or 16,000 hours of operation, whichever shall occur first."],
                    ["Standard Duty: Ten (10) years or 10,000 hours of operation, whichever shall occur first."],
                    ["Moderate Duty: Six (6) years or 6,000 hours of operation, whichever shall occur first."]
                ]
            }]
        },
        {
            items: [{
                title: "Center Drive Gear Motors",
                subtitle: "Eight (8) years or 8,000 hours of operation, whichever shall occur first."
            }]
        }
    ];

    messages["Structural Components"] = [
        {
            items: [{
                title: "Manufactured directly by Reinke Manufacturing Co., Inc.",
                subtitle: "Five (5) years"
            }]
        }
    ];

    messages["Precision Technology"] = [
        {
            items: [{
                    title: "RPM Advanced Panel",
                    subtitle: "Five (5) years"
                },
                {
                    title: "RPM Advanced Plus™ Panel",
                    subtitle: "Five (5) years"
                },
                {
                    title: "RPM Touch Screen Panel",
                    subtitle: "Five (5) years"
                }
            ]
        },
        {
            items: [{
                    title: "RPM Connect™ Panel",
                    subtitle: "Five (5) years"
                },
                {
                    title: "Navigator™ EOS GPS",
                    subtitle: "Five (5) years"
                },
                {
                    title: "Remote Management Products",
                    subtitle: "Two (2) years"
                }
            ]
        }
    ];

    let boxes: JSX.Element[] = [];

    Object.entries(messages).forEach(([key, val]) => {
        boxes.push(
            <View style={documentStyles.section}>
                <View style={[documentStyles.heading, {marginBottom: 10}]}>
                        <Text style={documentStyles.sectionHeader}>{key}</Text>
                </View>{
                    val.map((v) => {
                        let row1Contents: JSX.Element[] = [];
                        let row2Contents: JSX.Element[] = [];

                        let bullets: JSX.Element[] = [];

                        v.items.forEach((i, idx) => {
                            const flex = idx === 0 ? 1 : v.items.length === 2 ? 2 : 1;
                            row1Contents.push(<View style={{display: "flex", flexDirection: "row", alignItems: "center", flex}}>
                                <Text style={{fontFamily: "Wingdings", color: primaryColor, fontSize: 8,  marginRight: 3}}>y</Text>
                                <Text>{i.title}</Text>
                            </View>);

                            if (i.subtitle){
                                row2Contents.push(<View style={{flex}}>
                                    <Text style={{marginLeft: 15}}>{i.subtitle}</Text>
                                </View>)
                            }

                            if (i.bullets){
                                i.bullets.forEach((bblock) => {
                                    let bCells: JSX.Element[] = [];
                                    bblock.forEach((cell) => {
                                        bCells.push(<View style={{display: "flex", flexDirection: "row", alignItems: "center", flex, paddingLeft: 15}}>
                                            {cell !== "" && <Text style={{fontFamily: "Wingdings", color: primaryColor, fontSize: 8,  marginRight: 3}}>¬</Text>}
                                            {cell !== "" && <Text>{cell}</Text>}
                                        </View>);
                                    });
                                    bullets.push(<View style={{display: "flex", flexDirection: "row"}}>{bCells}</View>);
                                });
                            }
                        });

                        return <View style={{marginBottom: 10, paddingLeft: 15, paddingRight: 15}}>
                            <View style={{display: "flex", flexDirection: "row"}}>
                                {row1Contents}
                            </View>
                            {
                                row2Contents.length > 0 && 
                                <View style={{display: "flex", flexDirection: "row"}}>
                                    {row2Contents}
                                </View>
                            }
                            {
                                bullets.length > 0 && <View style={{display: "flex", flexDirection: "column"}}>
                                    {bullets}
                                </View>
                            }
                        </View>

                    })
                }
            </View>
        );
    });

    return (
        <View>
            <View style={{marginBottom: 15, padding: 30, backgroundColor: primaryColor}}>
                <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <View style={{flex: 2, display: "flex", flexDirection: "column"}}>
                        <Text style={{color: "white", fontWeight: "bold"}}>SUMMARY OF NEW SYSTEM LIMITED WARRANTY</Text>
                        <Text style={{color: "white", fontWeight: "bold"}}>United States & Canada</Text>
                    </View>
                    <View style={{flex: 1, alignItems: "flex-end"}}><ReinkeLogo style={{width: 100}}/></View>
                </View>
            </View>
            <View style={{paddingLeft: 15, paddingRight: 15}}>
                <Text style={{marginBottom: 10}}>Reinke Irrigation Systems have a standard two (2) year limited warranty and extended warranties on certain
                        primary components as listed below:</Text>
                {boxes}
                <Text style={{textAlign: "center", padding: 15, marginTop: 50}}>Note: Certain conditions and/or exclusions apply. This summary is subject to the entire warranty
                    provisions of the attached Reinke New Irrigation System Limited Warranty.</Text>
                <View style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 15}}>
                    <Text>Effective Date: 11/1/2022</Text>
                    <Text>P/N 119562 Rev D</Text>
                </View>
            </View>
        </View>
    );
};

export default WarrantySummary;