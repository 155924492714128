import * as shapefile from "shapefile";
import { ImportExportFeature } from "../../../../../../helpers/importExport";
import { ImportExportProject } from "../common/interfaces";

interface IArgs {
    blob: Blob;    
}

const readAsArrayBuffer = (file: Blob) => {
    return new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
            
        reader.onload = () => {
            if (!(reader.result instanceof ArrayBuffer)) {
                reject();
            }
            resolve(reader.result as ArrayBuffer);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    })
}

export const importShapeFile = async ({ blob }: IArgs): Promise<ImportExportProject> => {

    const ab = await readAsArrayBuffer(blob);
    
    const ret: ImportExportProject = {
        features: []
    };
    
    const source = await shapefile.open(ab)
    
    let result = await source.read();
    while (!result.done) {
        ret.features.push(result.value as ImportExportFeature);
        result = await source.read();
    }  
    
    return ret;
}