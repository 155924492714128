import {
    Alert,
    AlertTitle,
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";

import IDealershipData from "rdptypes/IDealershipData";
import { IAssumedUser } from "rdptypes/IUserData";
import IGetUsersICanAssumeResp from "rdptypes/api/IGetUsersICanAssumeResp";
import { useEffect, useState } from "react";
import ApiClientCtx from "../../api/ApiClientCtx";
import ApiRequestState, { ApiRequestStatus } from "../../api/ApiRequestState";
import AuthCtx from "../../auth/AuthCtx";
import Spinner from "../Spinner";

interface Props {
    closeDialog: () => any;
}

const LogInAsDialog: React.FC<Props> = (props) => {
    const auth = React.useContext(AuthCtx);
    const api = React.useContext(ApiClientCtx);
    const [reqState, setReqState] = useState<ApiRequestState<IGetUsersICanAssumeResp>>({
        status: ApiRequestStatus.InProgress,
    });

    const [selectedDealership, setSelectedDealership] = useState<IDealershipData | undefined>(undefined);
    const [selectedUser, setSelectedUser] = useState<IAssumedUser | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const resp = await api.getUsersICanAssume();
            if (resp.status === ApiRequestStatus.Success && resp.result.allowAssumeDealerships.length === 1) {
                setSelectedDealership(resp.result.allowAssumeDealerships[0]);
            }
            setReqState(resp);
        })();
    }, []);

    return (
        <Dialog
            open={true}
            onClose={props.closeDialog}
            fullWidth={true}
            maxWidth="sm"
            >
            <DialogTitle>Log in as another user</DialogTitle>
            <DialogContent>
                {reqState.status === ApiRequestStatus.InProgress && (
                    <>
                    <DialogContentText>
                        <Spinner title="Loading the list of users that you can log in as" inline/>
                    </DialogContentText>
                    </>
                )}
                {reqState.status === ApiRequestStatus.Error && (
                    <Alert severity="error">
                        <AlertTitle>Error loading the list of users that you can log in as.</AlertTitle>
                        <>{reqState.error}</>
                    </Alert>
                )}
                {reqState.status === ApiRequestStatus.Success && (
                    <>
                        <Autocomplete
                        autoFocus
                        fullWidth
                        options={reqState.result.allowAssumeDealerships.sort((a, b) => a.number - b.number)}
                        getOptionLabel={x => `${x.name} (${x.number})`}
                        renderInput={(params) => <TextField {...params} label="Dealership" margin="normal"/>}
                                value={selectedDealership}
                                onChange={(ev, value) => {
                                    setSelectedUser(undefined);
                                    setSelectedDealership(value);
                                }}
                            />

                            <Autocomplete
                            autoFocus
                            disabled={selectedDealership === undefined}
                            fullWidth
                            options={reqState.result.allowAssumeUsers
                                    .filter(x => x.dealershipNumber === selectedDealership?.number)
                                    .sort((a, b) => a.name.localeCompare(b.name))}
                            getOptionLabel={x => `${x.name} (${x.email})`}
                            renderInput={(params) => <TextField {...params} label="User" margin="normal"/>}
                                    value={selectedUser}
                                    onChange={(ev, value) => {
                                        setSelectedUser(value);
                                    }}
                                />
                    </>
                )}
                <DialogActions>
                <Button
                    disabled={selectedUser === undefined}
                    onClick={() => {
                        props.closeDialog();
                        auth.setAssumedUserId(selectedUser.id);
                    }}
                >Log In</Button>
                <Button
                    onClick={() => {
                        props.closeDialog();
                    }}
                >Cancel</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default LogInAsDialog;
