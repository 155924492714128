import { DeviceTypes, IPackage } from "../../../rdptypes/project/ISprinklers";
import { SprinklerManufacturerTypes } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import { AnyPolyLineSpans, DeterminePackageTypeFromDeviceType } from "../OtherHelpers";
import QuoteClass from "../QuoteClass";
import { HasSprinklerPolyValve, IsDualSided, IsMaxigator } from "../SystemFunctions";
import { NormalSpacingOk, PackageTypeOptionKometOK, SprinklerKometDropsOptionOk } from "../Valids.Valids";

export default class SprinklersKometPackageForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        let sprinklerProps = this.Quote.System.SprinklerProperties;
        if (sprinklerProps.SprinklerEngineCode !== SprinklerManufacturerTypes.Komet) return;

        this.validatePackages(this.Quote.System.FlangedSide.Sprinklers.Package, "Flanged");
        if (IsDualSided(this.Quote.System) && this.Quote.System.FlexSide.Sprinklers.Package){
            this.validatePackages(this.Quote.System.FlexSide.Sprinklers.Package, "Flex");
        }
    }

    validatePackages = (pkgs: IPackage[], side: string) => {
        if (typeof(pkgs) !== "undefined"){
            let anyPolylinedSpans = AnyPolyLineSpans(this.Quote.System);
            for (let i = 0; i < pkgs.length; i++) {
                const pkg = pkgs[i];

                if (pkg.Device === DeviceTypes.None){
                    break;//packages get created even if we don't actually add them
                }

                let idxSprinkler = DeterminePackageTypeFromDeviceType(pkg);

                if (!NormalSpacingOk(pkg.NormalSpacing, {
                    IsMaxigator: IsMaxigator(this.Quote.System),
                    Manufacturer: SprinklerManufacturerTypes.Komet,
                    Sprinkler: DeterminePackageTypeFromDeviceType(pkg),
                    DropType: pkg.Drop,
                })) {
                    this.Quote.Warnings.Add(`Normal spacing is not ok for Komet ${side.toLocaleLowerCase()} sprinkler package ${i + 1}.`);
                }
        
                if (!PackageTypeOptionKometOK(idxSprinkler, {
                    AnyPolyLinedSpans: anyPolylinedSpans
                })){
                    this.Quote.Warnings.Add(`${side} sprinkler package ${i + 1} cannot be a Komet sprinkler.`);
                }
        
                if (!SprinklerKometDropsOptionOk(pkg.Drop, {
                    Sprinkler: idxSprinkler,
                    AnyPolyLinedSpans: anyPolylinedSpans,
                    NozzleClipType: pkg.NozzleClip,
                    HasSprinklerPolyValve: HasSprinklerPolyValve(this.Quote.System),
                })){
                    this.Quote.Warnings.Add(`${side} sprinkler package ${i + 1} drop options not OK.`);
                }

                if (pkg.EndingLocation <= 0) {
                    return;
                }
            }
        }
    }
}