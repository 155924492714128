import { EndOfSystemTypes, ISystemBase, SACGPSGuidanceBaseOptions, SACPipeTypes, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IRule } from "rdptypes/roe/IRule";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "sac",
            title: "Swing Arm Corner",
            position: 7,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType === SystemTypes.SwingArmRetro
                || (sys.SystemProperties.SystemType === SystemTypes.CenterPivot
                    && sys.FlangedSide.EndOfSystem.EndOfSystemType === EndOfSystemTypes.SAC),
            components: [

                {
                    type: "complexChoice",
                    title: {"en" :"Tires", "es": "Neumático"},
                    includeOnlyValidationRules:[],
                    options: [
                        { title: "14.9 x 24", nestedOptions: [
                            { title: { "en" :"4-Ply", "es": "4 Capas" }, values: { "Circle.SwingArm.Tires.TireSize": "a149x24", "Circle.SwingArm.Tires.TireType": "FourPly" } },
                            { title: "Non-Directional", values: { "Circle.SwingArm.Tires.TireSize": "a149x24", "Circle.SwingArm.Tires.TireType": "NonDirectional" } },
                            { title: "Radial", values: { "Circle.SwingArm.Tires.TireSize": "a149x24", "Circle.SwingArm.Tires.TireType": "Radial" } },
                            { title: "Mach II", values: { "Circle.SwingArm.Tires.TireSize": "a149x24", "Circle.SwingArm.Tires.TireType": "MachII" } },
                            { title: "Turf", values: { "Circle.SwingArm.Tires.TireSize": "a149x24", "Circle.SwingArm.Tires.TireType": "Turf" } },
                        ] },
                        { title: "16.9 x 24", nestedOptions: [
                            { title: { "en" :"6-Ply", "es": "6 Capas" }, values: { "Circle.SwingArm.Tires.TireSize": "a169x24", "Circle.SwingArm.Tires.TireType": "SixPly" } },
                            { title: "Turf", values: { "Circle.SwingArm.Tires.TireSize": "a169x24", "Circle.SwingArm.Tires.TireType": "Turf" } },
                        ] },
                        { title: "320/85R38", nestedOptions: [
                            { title: "Radial", values: { "Circle.SwingArm.Tires.TireSize": "a32085R38", "Circle.SwingArm.Tires.TireType": "Radial" } },
                        ] },
                        { title: "13.6 x 38", nestedOptions: [
                            { title: { "en" :"6-Ply", "es": "6 Capas" }, values: { "Circle.SwingArm.Tires.TireSize": "a136x38", "Circle.SwingArm.Tires.TireType": "SixPly" } },
                            { title: "Mach II", values: { "Circle.SwingArm.Tires.TireSize": "a136x38", "Circle.SwingArm.Tires.TireType": "MachII" } },
                        ] },
                        { title: "380/80R38", nestedOptions: [
                            { title: "Radial", values: { "Circle.SwingArm.Tires.TireSize": "a38080R38", "Circle.SwingArm.Tires.TireType": "Radial" } },
                        ] },
                    ],
                },
                {
                    type: "choice",
                    fieldPath: "Circle.SwingArm.SACConductorOption",
                    title: "Conductor Option",
                    options: [
                        { id: "SpanWire10C", title: '10C SAC Span Wire'},
                        { id: "SpanWire11C", title: '11C SAC Span Wire'},
                        { id: "SpanWire14C", title: '14C SAC Span Wire'},
                    ],
                    enumNameHint: "SACConductorOptions",
                },
                {
                    type: "choice",
                    fieldPath: "Circle.SwingArm.PipeCoating",
                    title: "Pipe Coating",
                    options: [
                        { id: "Galvanized", title: 'Galvanized'},
                        { id: "PaintedCNPlus", title: 'CN + Painted'},
                        { id: "GalvanizedCNPlus", title: 'CN + Galvanized'},
                    ],
                    enumNameHint: "SACPipeTypes",
                },
                {
                    type: "choice",
                    fieldPath: "Circle.SwingArm.SprinklerValveType",
                    title: "Valve Type",
                    options: [
                        { id: "Poly", title: 'Poly'},
                        { id: "ReinkeSealMatic", title: 'Reinke Seal Matic'},
                    ],
                    enumNameHint: "SACPipeTypes",
                },
                {
                    type: "complexChoice",
                    title: "Zone Control Type",
                    validate: false,
                    options: [
                        { title: "ESAC 6.0", nestedOptions: [
                            { title: "Low", values: { "Circle.SwingArm.ZoneControlType": "ESAC060", "Circle.SwingArm.DistributionFlowRate": "Low" } },
                            { title: "Medium", values: { "Circle.SwingArm.ZoneControlType": "ESAC060", "Circle.SwingArm.DistributionFlowRate": "Medium" } },
                        ] },
                        { title: "ESAC 12.0", nestedOptions: [
                            { title: "Low", values: { "Circle.SwingArm.ZoneControlType": "ESAC120", "Circle.SwingArm.DistributionFlowRate": "Low" } },
                            { title: "Medium", values: { "Circle.SwingArm.ZoneControlType": "ESAC120", "Circle.SwingArm.DistributionFlowRate": "Medium" } },
                            { title: "High", values: { "Circle.SwingArm.ZoneControlType": "ESAC120", "Circle.SwingArm.DistributionFlowRate": "High" } },
                        ] },
                        { title: "ESAC 12.5 (SAC PATH)", nestedOptions: [
                            { title: "Medium", values: { "Circle.SwingArm.ZoneControlType": "ESAC125", "Circle.SwingArm.DistributionFlowRate": "Medium" } },
                            { title: "High", values: { "Circle.SwingArm.ZoneControlType": "ESAC125", "Circle.SwingArm.DistributionFlowRate": "High" } },
                        ] }
                    ],
                },
                {
                    type: "esac125CustomValueLoader",
                    disableDealerSettings: true,
                },
                {
                    type: "choice",
                    fieldPath: "Circle.SwingArm.SACPathCreator",
                    title: "SAC Path Created By",
                    options: [
                        { id: "Dealer", title: 'Dealer ($0)'},
                        { id: "Reinke", title: 'Reinke ($250)'},
                    ],
                    enumNameHint: "SACPathCreatorType",
                },
                {
                    type: "card",
                    children: [
                        {
                            type: "choice",
                            fieldPath: "Circle.SwingArm.GPSBase",
                            title: "Base Options",
                            options: [
                                { id: "CoaxBase", title: 'Single System - Hardwired Base (Includes 2000\' Data Cable)'},
                                { id: "RadioBase", title: 'Single System - Hardwired Base with Freewave Radio (Includes 2000\' Data Cable)'},
                                { id: "SingleSystemFreewaveRadioBase", title: 'Single System - Freewave Radio Base (No Data Cable)'},
                                { id: "NoBaseFreewave", title: 'No Base - Sharing Existing Freewave Network'},
                                { id: "NoBase", title: 'No Base - Sharing Existing Digi Network'},
                                { id: "NoBaseNonReinke", title: 'No Base - Sharing Existing Non-Reinke Base'},
                            ],
                            enumNameHint: "SACGPSGuidanceBaseOptions",
                            validate: false,
                        },
                        { type: "boolean", title: "Extra GPS Data Cable (2000' Spool)", fieldPath: "Circle.SwingArm.ExtraGPSDataCable"},
                        { type: "boolean", title: "Reinke Hardwired Base to Radio Base - Conversion Kit", fieldPath: "Circle.SwingArm.GPSBaseConvert"},
                    ]
                },
                {
                    type: "card",
                    
                    visible: (sys: ISystemBase) =>  sys.Circle.SwingArm.GPSBase === SACGPSGuidanceBaseOptions.RadioBase || 
                                                    sys.Circle.SwingArm.GPSBase === SACGPSGuidanceBaseOptions.NoBaseFreewave || 
                                                    sys.Circle.SwingArm.GPSBase === SACGPSGuidanceBaseOptions.SingleSystemFreewaveRadioBase,
                    children: [
                        {
                            type: "choice",
                            fieldPath: "Circle.SwingArm.GPSCoverageStudyCompleted",
                            title: "GPS Coverage Study",
                            options: [
                                { id: false, title: "Needed"},
                                { id: true, title: "Completed"},
                            ],

                            // Don't validate because required text boxes will probably not yet be filled in
                           validate: false
                        },
                        {                           
                            type: "text",
                            fieldPath: "Circle.SwingArm.GPSCoverageStudyNetworkName",
                            title: "Network Name",
                            multiline: true,
                            required: true,
                        },
                        {                           
                            type: "text",
                            fieldPath: "Circle.SwingArm.GPSCoverageStudySiteName",
                            title: "Site Name",
                            multiline: true,
                            required: true,
                        },
                        {
                            visible: (sys: ISystemBase) => sys.Circle.SwingArm.GPSCoverageStudyCompleted === false,
                            type: "text",
                            fieldPath: "Circle.SwingArm.GPSCoverageStudyBaseLocation",
                            title: "Base Location (LAT/LON)",
                            multiline: true,
                            required: true,
                        },
                        {
                            visible: (sys: ISystemBase) => sys.Circle.SwingArm.GPSCoverageStudyCompleted === false,
                            type: "text",
                            fieldPath: "Circle.SwingArm.GPSCoverageStudyPivotLocation",
                            title: "Pivot Location (LAT/LON)",
                            multiline: true,
                            required: true,
                            
                        },
                    ],
                },
                {
                    type: "booleangroup",
                    layoutHint: "secondary",
                    components: [
                        { type: "boolean", title: "Tubes for Tires", fieldPath: "Circle.SwingArm.Tires.TubesForTires"},
                        { type: "boolean", title: "Steering Motor Cover", fieldPath: "Circle.SwingArm.SteeringMotorCover"},
                    ],
                }, 
                {
                    type: "booleangroup",
                    layoutHint: "secondary",
                    components: [
                        { type: "boolean", title: "Control Line Booster Pump", fieldPath: "Circle.SwingArm.ControlLineBooster"},
                        { type: "boolean", title: "Air Compressor", fieldPath: "Circle.SwingArm.AirCompressor"},
                        { type: "boolean", title: "VRI SAC Span", fieldPath: "Circle.SwingArm.VRISACSpan"},
                    ],
                }, 
            ],
        },
    ],
    rules: [
        {
            apply: (system, user, grower, quick) =>
            system.Circle.SwingArm.PipeCoating === SACPipeTypes.GalvanizedCNPlus && !user.permissions.enableCNPlus ? [{
                id: "sac.galvanizedCnPlus.notEnabled",
                description: `The Galvanized CN+ Pipe feature is not currently activated for your dealership. To request access, please email reinkedesigncenter@reinke.com or call 402-365-7251.`,
                severity: "error",
                targets: [
                    "Circle.SwingArm.PipeCoating"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) => (system.SystemProperties.SystemType === SystemTypes.SwingArmRetro
                || (system.SystemProperties.SystemType === SystemTypes.CenterPivot && system.FlangedSide.EndOfSystem.EndOfSystemType === EndOfSystemTypes.SAC))
                && !user.permissions.enableSwingArm ? [{
                id: "RadioDenied",
                description: "Ordering of swing arm corners is not currently activated for your dealership.",
                severity: "denied",
                targets: []
            }] : []
        },
    ] as IRule[]
};