import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as countries from "i18n-iso-countries";
import i18next, { t } from "i18next";
import { parse } from "papaparse";
import IDbGrower from "rdptypes/api/IGrowerBase";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { primaryColor } from "../../../components/roe/styles";
import IDbState from "../../../db/IDbState";
import DealerDataCtx from "../../../userData/DealerDataCtx";

interface Props {
    open: boolean;
    onClose: (growers?: IDbGrower[]) => any;
    dbState: IDbState;
}

const growersCsv = new URL(
    '../../files/growers.csv?as=webp',
    import.meta.url
);

const growersEsCsv = new URL(
  '../../files/growers-es.csv?as=webp',
  import.meta.url
);

const GrowersFromCSVDialog: FC<Props> = (props) => {
  const user = useContext(DealerDataCtx);

  const [decoding, setDecoding] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [growers, setDecodedGrowers] = useState<IDbGrower[]>([]);

  const ns = "csv-upload-dialog.";
 
  const handleFile = (files: FileList, e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptedTypes = ['application/vnd.ms-excel', 'text/csv'];
    if (!acceptedTypes.includes(files[0].type)) {
      setErrors([t(ns + "invalid-file-type")]);
      setDecoding(false);
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      parse(files[0], {
          complete: (results) => {
              decodeData(results.data);
              setDecoding(false);
              return;
          },
          error: (error) => {
            setErrors([`Error: ${error.message}`]);
            setDecoding(false);
            return;
          }
      });
    }

    reader.readAsArrayBuffer(files[0]);
  }

  const fieldMissing = (index: number, row: string[]): boolean => {
    let missing = false;
    if (index > row.length - 1 || row[index].trim().length === 0) {
        missing = true;
    }
    return missing;
  }

  const decodeData = (data: string[][]) => {
    let errors: string[] = [];
    let warnings: string[] = [];

    let decodedGrowers: IDbGrower[] = [];

    const CSV_COLUMNS = {
      GROWER_NAME: 0,
      MANAGER_NAME: 1,
      LEGAL_DESCRIPTION: 2,
      EMAIL_ADDRESS: 3,
      PHONE_NUMBER: 4,
      SHIPPING_ADDRESS_LINE1: 5,
      SHIPPING_ADDRESS_LINE2: 6,
      SHIPPING_ADDRESS_CITY: 7,
      SHIPPING_ADDRESS_STATE: 8,
      SHIPPING_ADDRESS_ZIP: 9,
      SHIPPING_ADDRESS_COUNTRY: 10,
      MAILING_ADDRESS_LINE1: 11,
      MAILING_ADDRESS_LINE2: 12,
      MAILING_ADDRESS_CITY: 13,
      MAILING_ADDRESS_STATE: 14,
      MAILING_ADDRESS_ZIP: 15,
      MAILING_ADDRESS_COUNTRY: 16,
    };

    data.forEach((row, rowIdx) => {
      let finished = row.every(x => x==="");
      //skip header row
      if (rowIdx !== 0 && !finished){
        let grower: IDbGrower = {
          growerId: "", // This is overwritten by IDbProvider.newGrower / updateGrower anyway
          owner: user.assumedUser.id,
          dealershipNumber: user.dealership.number,
          sharedWithDealership: undefined, // Don't share by default
          deleted: false,
          lastModified: Date.now(),
          name: "",
          mailingAddress: {
            line1: "",
            line2: "",
            city: "",
            state: "",
            zip: "",
            country: ""
          },
          shippingAddress: {
            line1: "",
            line2: "",
            city: "",
            state: "",
            zip: "",
            country: ""
          }
        };
        
        let rowValid = true;
        for (let cellIdx = 0; cellIdx < row.length; cellIdx++){
          let value = row[cellIdx].trim();

          if (cellIdx === CSV_COLUMNS.GROWER_NAME){
            if (fieldMissing(cellIdx, row)){
              errors.push(`${t(ns + "grower-name")} ${t(ns + "is-missing-from-row")} ${rowIdx + 1}.`);
              rowValid = false;
            }
            else {
              for (var growerId in props.dbState.growers) {
                if (!props.dbState.growers[growerId].deleted && props.dbState.growers[growerId].name === value){
                  warnings.push(`${t(ns + "a-grower-with-the-name")} ${value} ${t(ns + "already-exists")}. ${t(ns + "row")} ${rowIdx + 1} ${t(ns + "will-be-skipped")}.`);
                  rowValid = false;
                }
              }

              let existingGrowerIdx = decodedGrowers.findIndex(x => x.name === value);
              if (existingGrowerIdx !== -1){
                warnings.push(`${t(ns + "a-grower-with-the-name")} ${value} was already found in this file. ${t(ns + "row")} ${rowIdx + 1} ${t(ns + "will-be-skipped")}.`);
                rowValid = false;
              }

              grower.name = value;
            }
          }

          if (cellIdx === CSV_COLUMNS.MANAGER_NAME && !fieldMissing(cellIdx, row)){
            grower.farmManagerName = value;
          }

          if (cellIdx === CSV_COLUMNS.LEGAL_DESCRIPTION && !fieldMissing(cellIdx, row)){
            grower.legalDescription = value;
          }
          
          if (cellIdx === CSV_COLUMNS.EMAIL_ADDRESS && !fieldMissing(cellIdx, row)){
            //TODO: validate email
            grower.email = value;
          }

          if (cellIdx === CSV_COLUMNS.PHONE_NUMBER && !fieldMissing(cellIdx, row)){
            //TODO: validate phone
            grower.phone = value;
          }

          if (cellIdx === CSV_COLUMNS.SHIPPING_ADDRESS_LINE1){
            if (fieldMissing(cellIdx, row)){
              errors.push(`${t(ns + "grower-address")} ${t(ns + "is-missing-from-row")} ${rowIdx + 1}.`);
              rowValid = false;
            }
            else {
              grower.shippingAddress.line1 = value;
            }
          }

          if (cellIdx === CSV_COLUMNS.SHIPPING_ADDRESS_LINE2){
            if (fieldMissing(cellIdx, row)){
              errors.push(`${t(ns + "grower-address")} 2 ${t(ns + "is-missing-from-row")} ${rowIdx + 1}.`);
              rowValid = false;
            }
            else {
              grower.shippingAddress.line2 = value;
            }
          }

          if (cellIdx === CSV_COLUMNS.SHIPPING_ADDRESS_CITY){
            if (fieldMissing(cellIdx, row)){
              errors.push(`${t(ns + "grower-city")} ${t(ns + "is-missing-from-row")} ${rowIdx + 1}.`);
              rowValid = false;
            }
            else {
              grower.shippingAddress.city = value;
            }
          }

          if (cellIdx === CSV_COLUMNS.SHIPPING_ADDRESS_STATE){
            //TODO: validate zip
            if (fieldMissing(cellIdx, row)){
              errors.push(`${t(ns + "grower-zip")} ${t(ns + "is-missing-from-row")} ${rowIdx + 1}.`);
              rowValid = false;
            }
            else {
              grower.shippingAddress.state = value;
            }
          }

          if (cellIdx === CSV_COLUMNS.SHIPPING_ADDRESS_COUNTRY){
            //TODO: validate city
            if (fieldMissing(cellIdx, row)){
              errors.push(`${t(ns + "grower-country")} ${t(ns + "is-missing-from-row")} ${rowIdx + 1}.`);
              rowValid = false;
            }
              else if (!countries.alpha3ToAlpha2(value)) {
                errors.push(`${t(ns + "grower-country")} is not valid on row ${rowIdx + 1}. You should use a three-letter country code such as USA.`);
                rowValid = false;
              } else {
              grower.shippingAddress.country = value;
            }
          }

          if (cellIdx === CSV_COLUMNS.SHIPPING_ADDRESS_ZIP){
            //TODO: validate state
            if (fieldMissing(cellIdx, row)){
              errors.push(`${t(ns + "grower-state")} ${t(ns + "is-missing-from-row")} ${rowIdx + 1}.`);
              rowValid = false;
            }
            else {
              grower.shippingAddress.zip = value;
            }
          }

          if (cellIdx === CSV_COLUMNS.MAILING_ADDRESS_LINE1 && !fieldMissing(cellIdx, row)){
            grower.mailingAddress.line1 = value;
          }

          if (cellIdx === CSV_COLUMNS.MAILING_ADDRESS_LINE2){
            if (fieldMissing(cellIdx, row) && !!grower.mailingAddress.line1) {
              errors.push(`${t(ns + "grower-address")} 2 ${t(ns + "is-missing-from-row")} ${rowIdx + 1}.`);
              rowValid = false;
            }
            else {
              grower.mailingAddress.line2 = value;
            }
          }

          if (cellIdx === CSV_COLUMNS.MAILING_ADDRESS_CITY){
            if (fieldMissing(cellIdx, row) && !!grower.mailingAddress.line1) {
              errors.push(`${t(ns + "grower-city")} ${t(ns + "is-missing-from-row")} ${rowIdx + 1}.`);
              rowValid = false;
            }
            else {
              grower.mailingAddress.city = value;
            }
          }

          if (cellIdx === CSV_COLUMNS.MAILING_ADDRESS_STATE){
            //TODO: validate zip
            if (fieldMissing(cellIdx, row) && !!grower.mailingAddress.line1) {
              errors.push(`${t(ns + "grower-zip")} ${t(ns + "is-missing-from-row")} ${rowIdx + 1}.`);
              rowValid = false;
            }
            else {
              grower.mailingAddress.state = value;
            }
          }

          if (cellIdx === CSV_COLUMNS.MAILING_ADDRESS_COUNTRY){
            if (fieldMissing(cellIdx, row) && !!grower.mailingAddress.line1) {
              errors.push(`${t(ns + "grower-country")} ${t(ns + "is-missing-from-row")} ${rowIdx + 1}.`);
              rowValid = false;
            }
            else if (!countries.alpha3ToAlpha2(value)) {
              errors.push(`${t(ns + "grower-country")} is not valid on row ${rowIdx + 1}. You should use a three-letter country code such as USA.`);
              rowValid = false;
            } else {
              grower.mailingAddress.country = value;
            }
          }

          if (cellIdx === CSV_COLUMNS.MAILING_ADDRESS_ZIP){
            //TODO: validate state
            if (fieldMissing(cellIdx, row) && !!grower.mailingAddress.line1) {
              errors.push(`${t(ns + "grower-state")} ${t(ns + "is-missing-from-row")} ${rowIdx + 1}.`);
              rowValid = false;
            }
            else {
              grower.mailingAddress.zip = value;
            }
          }
        }

        if (rowValid){
          decodedGrowers.push(grower);
        }
      }
    });

    setErrors(errors);
    setWarnings(warnings);
    setDecoding(false);
    setDecodedGrowers(decodedGrowers);
  }

  const cleanup = () => {
    setErrors([]);
    setWarnings([]);
    setDecoding(false);
    setDecodedGrowers([]);
  }

  let url = i18next.language == "es" ? growersEsCsv : growersCsv;//TODO - update if any other languages

  return (
      <Dialog
          open={props.open}
          onClose={() => {props.onClose()}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">
          {t(ns + "title")}
      </DialogTitle>
      <DialogContent>
            <DialogContentText sx={{marginBottom: "0.5em"}}>
              {i18next.format(t('please'), 'capitalize')} <Link style={{color: primaryColor}} to={url} target="_blank" download>{t("download")}</Link> {t(ns + "body")}
            </DialogContentText>
            <input onClick={(e) => {(e.target as any).value = null;}} style={{marginBottom: "0.5em", fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`, color: "rgba(0, 0, 0, 0.6)"}} onChange={(e) => {setDecoding(true); handleFile((e.target as any).files, e);}} type="file" name="growers-csv-upload" accept="application/vnd.ms-excel,text/csv,.csv"></input>
            {
              decoding && <span>{t(ns + "decoding")}</span>
            }
            {
              !decoding && growers.length > 0 && <Alert sx={{marginBottom: "0.5em"}} severity="success">{t(ns + "about-to")}{growers.length} {growers.length > 1 ? t("growers") : t("grower") }</Alert>
            }
            {
              !decoding && errors.length > 0 && <Alert sx={{marginBottom: "0.5em"}} severity="error">{t(ns + "following-errors")}<ul>{errors.map((x, i) => {return <li key={"error_" + i}>{x}</li>})}</ul></Alert>
            }
            {
              !decoding && warnings.length > 0 && <Alert sx={{marginBottom: "0.5em"}} severity="warning">{t(ns + "following-warnings")}<ul>{warnings.map((x, i) => {return <li key={"warning_" + i}>{x}</li>})}</ul></Alert>
            }

        </DialogContent>
        <DialogActions>
        <Button disabled={decoding}  onClick={() => {
            props.onClose();
            cleanup();
          }}>{t("cancel")}</Button>
          <Button disabled={decoding || errors.length > 0} onClick={() => {
            if (!errors.length){
              props.onClose(growers);
              cleanup();
            }
          }}>{t("ok")}</Button>
        </DialogActions>
    </Dialog>
  );
};

export default GrowersFromCSVDialog;