import { SACCentrePivotType } from "./SACCentrePivotType";
import { SACModel } from "./SACModel";
import { SACObstacleConstraints } from "./SACObstacleConstraints";

interface IArgs {
    Model: SACModel;
    CentrePivotType?: SACCentrePivotType;
    PivotCentre: jsts.geom.Coordinate;
    ParentSystemPolygon: jsts.geom.Geometry;
    ObstacleConstraints: SACObstacleConstraints;
    PartialPivotAngleDegreesMin?: number;
    PartialPivotAngleDegreesMax?: number;
    endGunThrowsMeters: number[];
}

export class SACOptimizationProblem {
    static PivotAngleStepCount = 3600;
    public PivotAngleStepCount = SACOptimizationProblem.PivotAngleStepCount;

    public get PivotAngleSpacingRadians() {
        return 2 * Math.PI / this.PivotAngleStepCount;
    }

    public GetTheta(iPivotAngle: number) {
        return iPivotAngle * this.PivotAngleSpacingRadians;
    }

    public Model: SACModel;
    public CentrePivotType?: SACCentrePivotType;
    public PivotCentre: jsts.geom.Coordinate;
    public ParentSystemPolygon: jsts.geom.Geometry;
    public ObstacleConstraints: SACObstacleConstraints;

    /// <summary>
    /// Ignored unless CentrePivotType === SACCentrePivotType.Partial
    /// </summary>
    public PartialPivotAngleDegreesMin: number;

    /// <summary>
    /// Ignored unless CentrePivotType === SACCentrePivotType.Partial
    /// </summary>
    public PartialPivotAngleDegreesMax: number;

    public endGunThrowsMeters: number[];


    public constructor(args: IArgs) {
        this.Model = args.Model;        
        this.CentrePivotType = args.CentrePivotType;
        this.PivotCentre = args.PivotCentre;
        this.ParentSystemPolygon = args.ParentSystemPolygon;
        this.ObstacleConstraints = args.ObstacleConstraints;
        this.PartialPivotAngleDegreesMin = args.PartialPivotAngleDegreesMin || 0;
        this.PartialPivotAngleDegreesMax = args.PartialPivotAngleDegreesMax || 0;
        this.endGunThrowsMeters = args.endGunThrowsMeters;
    }
}