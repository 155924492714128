export class Vector2 {
    public X: number;
    public Y: number;
    constructor(x: number, y: number) {
        this.X = x;
        this.Y = y;
    }

    public add(b: Vector2) {
        return new Vector2(this.X + b.X, this.Y + b.Y);
    }

    public subtract(b: Vector2) {
        return new Vector2(this.X - b.X, this.Y - b.Y);
    }

    public Length() {
        return Math.sqrt(this.X * this.X + this.Y * this.Y);
    }

    public static Multiply(vector: Vector2, scalar: number) {
        return new Vector2(vector.X * scalar, vector.Y * scalar);
    }
}