import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { SideEnum, getSide } from 'rdptypes/helpers/SideEnum';
import { WrapAroundSpanTypes } from 'rdptypes/project/ISystemBase.AutoGenerated';
import { ISpan, ITower } from 'rdptypes/project/Types';
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { createSpanAndTower } from './AddSpan';
import { createSetSpanTowersAction } from './SetSpans';
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';
import { renumberSpanAndTowers, switchSidesIfNeeded } from './helpers/spans';

const actionTypeId = "SetSpanTowersV2";

// NOTE: The set spans action has been superceeded by V2.
// The V1 action has been left so to not crash existing DBs
// As the action is no longer imported anywhere, the registor does not execute
// Bellow it is imported so to register the executer
const dummy = typeof createSetSpanTowersAction

export interface ISetSpanDataV2 {
    span: ISpan;
    tower: ITower;
}
interface ActionData extends IActionData {
    layoutId: string;
    systemId: string;
    forwardSpans: ISetSpanDataV2[];
    aftSpans?: ISetSpanDataV2[];
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const sys = state.layouts[data.layoutId].systems[data.systemId];

    const flangedSide = getSide(sys, SideEnum.Flanged);
    const flexSide = getSide(sys, SideEnum.Flex);

    // flanged side
    {
        let flangedSideEndBoom: ISpan | undefined;
        if (flangedSide.Span.length !== 0 && flangedSide.Span[flangedSide.Span.length - 1].EndBoom) {
            flangedSideEndBoom = flangedSide.Span.pop();
        }
        let flangedSideSwingArm: ISpan | undefined;
        if (flangedSide.Span.length !== 0 && flangedSide.Span[flangedSide.Span.length - 1].SwingArm) {
            flangedSideSwingArm = flangedSide.Span.pop();
        }
    
        flangedSide.Tower = []
        flangedSide.Span = []
        data.forwardSpans.forEach((x, idx) => {
            const newId = idx + 1;
            const { span, tower } = x;
            const newSpanAndTower = createSpanAndTower(newId, x.span, x.tower);
            const disconnecting = span.dropSpanStartRelativeToPreviousSpanStart !== undefined || 
                span.dropSpanEndRelativeToPreviousSpanEnd !== undefined;
            flangedSide.Span.push({
                ...newSpanAndTower.span,
                Disconnecting: disconnecting,
                dropSpanEndRelativeToPreviousSpanEnd: span.dropSpanEndRelativeToPreviousSpanEnd,
                dropSpanStartRelativeToPreviousSpanStart: span.dropSpanStartRelativeToPreviousSpanStart
            }); 
            flangedSide.Tower.push({
                ...newSpanAndTower.tower,
                WrapAroundSpan: tower.WrapAroundSpan || WrapAroundSpanTypes.None,
                clockwiseWrapAngleRelativeToPreviousSpanDegrees: tower.clockwiseWrapAngleRelativeToPreviousSpanDegrees,
                anticlockwiseWrapAngleRelativeToPreviousSpanDegrees: tower.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
            }); 
        })
        if (flangedSideSwingArm) {
            flangedSide.Span.push(flangedSideSwingArm);
        }
        if (flangedSideEndBoom) {
            flangedSide.Span.push(flangedSideEndBoom);
        }
    }
    
    // flex side
    if (sys.lateral) {
        let flexSideEndBoom: ISpan | undefined;
        if (flexSide.Span.length !== 0 && flexSide.Span[flexSide.Span.length - 1].EndBoom) {
            flexSideEndBoom = flexSide.Span.pop();
        }
        let flexSideSwingArm: ISpan | undefined;
        if (flexSide.Span.length !== 0 && flexSide.Span[flexSide.Span.length - 1].SwingArm) {
            flexSideSwingArm = flexSide.Span.pop();
        }
    
        flexSide.Tower = []
        flexSide.Span = []
        if (data.aftSpans) {
            data.aftSpans.forEach((x, idx) => {
                const newId = idx + 1;
                const { span, tower } = x;
                const newSpanAndTower = createSpanAndTower(newId, x.span, x.tower);
                const disconnecting = span.dropSpanStartRelativeToPreviousSpanStart !== undefined || 
                    span.dropSpanEndRelativeToPreviousSpanEnd !== undefined;
                flexSide.Span.push({
                    ...newSpanAndTower.span,
                    Disconnecting: disconnecting,
                    dropSpanEndRelativeToPreviousSpanEnd: span.dropSpanEndRelativeToPreviousSpanEnd,
                    dropSpanStartRelativeToPreviousSpanStart: span.dropSpanStartRelativeToPreviousSpanStart
                }); 
                flexSide.Tower.push({
                    ...newSpanAndTower.tower,
                    WrapAroundSpan: tower.WrapAroundSpan || WrapAroundSpanTypes.None,
                    clockwiseWrapAngleRelativeToPreviousSpanDegrees: tower.clockwiseWrapAngleRelativeToPreviousSpanDegrees,
                    anticlockwiseWrapAngleRelativeToPreviousSpanDegrees: tower.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
                }); 
            })
        }
        if (flexSideSwingArm) {
            flexSide.Span.push(flexSideSwingArm);
        }
        if (flexSideEndBoom) {
            flexSide.Span.push(flexSideEndBoom);
        }

        sys.flexIsFwd = false;
    }

    renumberSpanAndTowers(flangedSide);
    renumberSpanAndTowers(flexSide);
    switchSidesIfNeeded(sys);
    
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});

export const createSetSpanTowersActionV2 = (
    layoutId: string,
    systemId: string,
    data: {
        forwardSpans: ISetSpanDataV2[],
        aftSpans?: ISetSpanDataV2[]
    },
    authState: IAuthState) => createAction(
        actionTypeId,
        {
            layoutId,
            systemId,
            ...data
        } as ActionData,
        authState);