import { SideEnum } from "rdptypes/helpers/SideEnum";
import { createNewMultiAction } from "../../../../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../../../../actions/UpdateSystemProperty";
import IAuthState from "../../../../../auth/IAuthState";
import IDbProject from "../../../../../db/IDbProject";
import ISystem from "../../../../../model/project/ISystem";
import IComponentRenderContext from "../../IComponentRenderContext";
import { IPropertyValueMap, ISprinklerValidator } from "./interfaces";
import { boomBackValidator } from "./sprinklerBoomBacks";
import { designedEndPressureValidator, endPressureGaugeValidator, peakElevationValidator, pressureGaugeValidator, sprinklerEngineCodeValidator, totalGPMValidator } from "./sprinklerOptions";
import { packagesValidator } from "./sprinklerPackages";

const sprinklerValidatorBase = (
    args: { sys: ISystem,  executePvm: (pvm: IPropertyValueMap) => void, excludeProperties: string[] }
): ISprinklerValidator => {
    const { sys, executePvm, excludeProperties } = args;
    const options = {
        sprinklerEngineCode: sprinklerEngineCodeValidator(sys, executePvm),
        pressureGauge: pressureGaugeValidator(sys, executePvm),
        endPressureGauge: endPressureGaugeValidator(sys, executePvm),
        totalGPM: totalGPMValidator(sys, executePvm),
        designedEndPressure: designedEndPressureValidator(sys, executePvm),
        peakElevation: peakElevationValidator(sys, executePvm),
    };
    const generateCopyFromFlangedBoomBacks = () => {
        const generateCanCopy = () => {
            const flangedSide = sys.FlangedSide?.Sprinklers?.BoomBacks;
            const flexSide = sys.FlexSide?.Sprinklers?.BoomBacks;
            if (!flangedSide) return false;
            if (!flexSide) return true;
            return (
                flangedSide.BoomBackType !== flexSide.BoomBackType ||
                flangedSide.Device !== flexSide.Device ||
                flangedSide.FirstTower !== flexSide.FirstTower ||
                flangedSide.PipeMaterial !== flexSide.PipeMaterial ||
                flangedSide.QtyPerTower !== flexSide.QtyPerTower ||
                flangedSide.Valves !== flexSide.Valves
            )
        }
        const canCopy = generateCanCopy();
        const copy = () => {
            if (!canCopy) return;
            const flangedSide = structuredClone(sys.FlangedSide?.Sprinklers?.BoomBacks);
            const pvm: IPropertyValueMap = {
                "FlexSide.Sprinklers.BoomBacks": flangedSide
            };
            executePvm(pvm);
        }
        return {
            copy,
            canCopy
        }
    }
    const generateCopyFromFlangedPackage = (index: number) => {
        const generateCanCopy = () => {
            const flangedSide = sys.FlangedSide?.Sprinklers?.Package[index];
            const flexSide = sys.FlexSide?.Sprinklers?.Package[index];
            if (!flangedSide) return false;
            if (!flexSide) return true;
            return true;
        }
        const canCopy = generateCanCopy();
        const copy = () => {
            if (!canCopy) return;
            const flangedSide = structuredClone(sys.FlangedSide?.Sprinklers?.Package[index]);
            const pvm: IPropertyValueMap = {};
            pvm["FlexSide.Sprinklers.Package[" + index + "]"] = flangedSide;
            executePvm(pvm);
        }
        return {
            copy,
            canCopy
        }
    }
    
    return {
        options: {
            ...options,
            isError: Object.values(options).some(x => x.isError)
        },
        getSide: (sideEnum: SideEnum) => ({
            boomBacks: boomBackValidator(sys, sideEnum, executePvm),
            packages: packagesValidator(sys, sideEnum, executePvm, excludeProperties)
        }),
        copyFromFlanged: {
            boomBacks: generateCopyFromFlangedBoomBacks(),
            package: [0, 1, 2].map(x => generateCopyFromFlangedPackage(x))
        }
    }
}

export const generateSprinklerValidator = (
    args: { dbPrj: IDbProject, layoutId: string, systemId: string, authState: IAuthState, ctx: IComponentRenderContext }
): ISprinklerValidator => {

    const sys = args.dbPrj.state.layouts[args.layoutId].systems[args.systemId];
    let excludeProperties: string[] = [];

    const executePvm = (pvm: IPropertyValueMap) => {
        const actions = Object.entries(pvm).map(([ property, value ]) => (
            createNewUpdateSystemPropertyAction(
                args.layoutId,
                args.systemId,
                property,
                value,
                args.authState
            )
        ));
        if (actions.length) {
            args.ctx.pushActionAndImproveScores(
                createNewMultiAction(
                    actions,
                    args.authState
                ), 
                [], 
                false
            );
        }
        excludeProperties = [];
    }
    return sprinklerValidatorBase({ sys, executePvm, excludeProperties: excludeProperties });
}
export const generateSprinklerImprover = (
    args: { sys: ISystem,  executePvm: (pvm: IPropertyValueMap) => void, excludeProperties: string[] }
): ISprinklerValidator => sprinklerValidatorBase(args);