import { Coord, Feature, LineString, MultiPolygon, Point, Polygon, Position, Units, bearing, booleanContains, booleanDisjoint, destination, difference, distance, feature, featureCollection, intersect, lineIntersect, lineString, point, polygon, truncate, union } from "@turf/turf";

export const loCustom = (line: LineString | Feature<LineString>, distance: number, options: { units: Units } = { units: 'kilometers' }) => {
    // turf line offset does not do what you think:
    // https://github.com/Turfjs/turf/issues/2278
    // return lineOffset(line, distance, options)

    const incommingLine = line.type === 'Feature' 
        ? line.geometry
        : line;

    if (incommingLine.coordinates.length !== 2) {
        throw new Error("n points = " + incommingLine.coordinates.length);
        
    }
    
    const ib = bearing(incommingLine.coordinates[0], incommingLine.coordinates[1]) + 90;
    const p1 = destination(incommingLine.coordinates[0], distance, ib, { units: options.units })
    const p2 = destination(incommingLine.coordinates[1], distance, ib, { units: options.units })
    const l = lineString([ p1.geometry.coordinates, p2.geometry.coordinates ])
    
    // console.log(
    //     distance,
    //     featureCollection([
    //         feature(line),
    //         lineOffset(line, distance, options),
    //         l
    //     ])
    // )
    
    return l;

}

export const poCustom = (point: Point | Feature<Point>, distance: number, options: { units: Units } = { units: 'kilometers'}) => {
    
}

// custom version of turf pointToLineDistance to force distances to be considered perpendicular interms of bearing.
export const ptldCustom = (coord: Coord, line: LineString | Feature<LineString>, options: { units: Units } = { units: 'kilometers'}) => {
    const incommingLine = line.type === 'Feature' 
        ? line.geometry
        : line;
    let incommingPosition: Position;
    if ('type' in coord) {
        if (coord.type === 'Feature') {
            incommingPosition = coord.geometry.coordinates;
        }
        else {
            incommingPosition = coord.coordinates;
        }
    }
    else {
        incommingPosition = coord;
    }

    if (incommingLine.coordinates.length !== 2) {
        throw new Error("n points = " + incommingLine.coordinates.length);
    }

    // bearing of incomming line:
    const _bearing1 = bearing(incommingLine.coordinates[0], incommingLine.coordinates[1]);
    // extend icomming line:
    const line1 = lineString([
        destination(incommingLine.coordinates[0], -1, _bearing1).geometry.coordinates, 
        destination(incommingLine.coordinates[1], 1, _bearing1).geometry.coordinates
    ]);

    // bearing of perpendicular line:
    const _bearing2 = _bearing1 + 90;
    // get a distance to the line and double to be safe:
    const _distance = distance(coord, incommingLine.coordinates[0]) * 2;
    if (_distance === 0) return 0;
    
    // calculate perpendicular line:
    const distForPerp = _distance < 0.1 ? 0.1 : _distance;
    const p1 = destination(coord, -distForPerp, _bearing2);
    const p2 = destination(coord, distForPerp, _bearing2);
    const perpLine = lineString([
        p1.geometry.coordinates, p2.geometry.coordinates
    ]);
    
    const _intersect = lineIntersect(line1, perpLine);
    if (!_intersect) {
        throw new Error("No intersect");
    }
    if (_intersect.features.length !== 1) {
        const f: Feature[] = [];
        f.push(point(incommingPosition))
        f.push(perpLine)
        f.push(line1)
        console.log("intersect", _intersect, featureCollection(f))
        throw new Error("Not one intersect");
    }
    const _distance2 = distance(_intersect.features[0], coord, options);
    return _distance2;
}

// custom version of turf along to force end points to be on the same bearing.
export const alongCustom = (line: LineString | Feature<LineString, {
    [name: string]: any;
}>, distance: number, options?: {
    units?: Units;
}) => {
    const incommingLine = line.type === 'Feature' 
        ? line.geometry
        : line;
    if (incommingLine.coordinates.length !== 2) {
        throw new Error("n points = " + incommingLine.coordinates.length);
        
    }
    const _bearing1 = bearing(incommingLine.coordinates[0], incommingLine.coordinates[1]);
    return destination(incommingLine.coordinates[0], distance, _bearing1, options)
}

export const lineSliceAlongCustom = (line: LineString | Feature<LineString, {
    [name: string]: any;
}>, startDist: number, stopDist: number, options?: {
    units?: Units;
}) => {
    const incommingLine = line.type === 'Feature' 
        ? line.geometry
        : line;
    if (incommingLine.coordinates.length !== 2) {
        throw new Error("n points = " + incommingLine.coordinates.length);
        
    }
    const _bearing1 = bearing(incommingLine.coordinates[0], incommingLine.coordinates[1]);
    const p1 = destination(incommingLine.coordinates[0], startDist, _bearing1, options);
    const p2 = destination(incommingLine.coordinates[0], stopDist, _bearing1, options);
    return lineString([ p1.geometry.coordinates, p2.geometry.coordinates ]);
}

// Note, boolean contains (and similar) only consider the outer ring of polygons, hence holed
// polygons can produce the wrong contains boolean answer:
// https://github.com/Turfjs/turf/issues/1697
export const booleanContainsCustom = (poly1: Feature<Polygon | MultiPolygon>, poly2: Feature<Polygon>) => {
    // return booleanContains(poly1, poly2);
    const outerRings: Position[][] = [];
    const innerRings: Position[][] = [];
    const mpRings = poly1.geometry.type === "MultiPolygon"
        ? poly1.geometry.coordinates
        : [ poly1.geometry.coordinates ];
    for (const pring of mpRings) {
        outerRings.push(...pring.slice(0, 1))
        innerRings.push(...pring.slice(1))
    }
    if (outerRings.some(o => !booleanContains(polygon([o]), poly2))) return false;
    if (innerRings.some(o => !booleanDisjoint(polygon([o]), poly2))) return false;
    const diff = difference(poly2, poly1);
    return diff === null;
}

// NOTE: turf union sometimes throws, using the truncate sugestion in this github issue
// seems to prevent this:
// https://github.com/Turfjs/turf/issues/2310
export const customUnion = (
    poly1: Polygon | MultiPolygon | Feature<Polygon | MultiPolygon>,
    poly2: Polygon | MultiPolygon | Feature<Polygon | MultiPolygon>
) => {
    const isKnownError = (e: any) => {
        if (!(e instanceof Error)) return false;
        if (e.message === 'Each LinearRing of a Polygon must have 4 or more Positions.') return true;
        if (e.message.startsWith('Unable to find segment')) return true;
        if (e.message === 'Maximum call stack size exceeded') return true;
        if (e.message.startsWith('Unable to complete output ring starting at')) return true;
        return false;
    }
    try {
        return union(poly1, poly2);
    }
    catch (e) {
        if (isKnownError(e)) {
            console.log("Failed to union without trucation");
        }
        else throw e;
        
    }
    for (let precision = 8; precision >= 6; precision--) {
        try {
            return union(truncate(poly1, { precision }), truncate(poly2, { precision }));
        }
        catch (e) {
            if (isKnownError(e)) {
                console.log("Failed to union with trucation precision", precision);
            }
            else throw e;
        }
    }
    console.log("args", featureCollection([
        poly1.type === 'Feature' ? poly1 : feature(poly1),
        poly2.type === 'Feature' ? poly2 : feature(poly2),
    ]))
    throw new Error("Custom union failed");    
}


// NOTE: turf difference sometimes throws, using the truncate sugestion in this github issue
// seems to prevent this:
// https://github.com/Turfjs/turf/issues/2310
export const customDifference = (
    poly1: Polygon | MultiPolygon | Feature<Polygon | MultiPolygon>,
    poly2: Polygon | MultiPolygon | Feature<Polygon | MultiPolygon>
) => {
    const isKnownError = (e: any) => {
        if (!(e instanceof Error)) return false;
        if (e.message === 'Each LinearRing of a Polygon must have 4 or more Positions.') return true;
        if (e.message.startsWith('Unable to find segment')) return true;
        if (e.message === 'Maximum call stack size exceeded') return true;
        if (e.message.startsWith('Unable to complete output ring starting at')) return true;
        return false;
    }
    try {
        return difference(poly1, poly2);
    }
    catch (e) {
        if (isKnownError(e)) {
            console.log("Failed to difference without trucation");
        }
        else throw e;
        
    }
    for (let precision = 8; precision >= 6; precision--) {
        try {
            return difference(truncate(poly1, { precision }), truncate(poly2, { precision }));
        }
        catch (e) {
            if (isKnownError(e)) {
                console.log("Failed to difference with trucation precision", precision);
            }
            else throw e;
        }
    }
    console.log("args", featureCollection([
        poly1.type === 'Feature' ? poly1 : feature(poly1),
        poly2.type === 'Feature' ? poly2 : feature(poly2),
    ]))
    throw new Error("Custom difference failed");    
}

// NOTE: turf intersect sometimes throws, using the truncate sugestion in this github issue
// seems to prevent this:
// https://github.com/Turfjs/turf/issues/2310
export const customIntersect = (
    poly1: Polygon | MultiPolygon | Feature<Polygon | MultiPolygon>,
    poly2: Polygon | MultiPolygon | Feature<Polygon | MultiPolygon>
) => {
    const isKnownError = (e: any) => {
        if (!(e instanceof Error)) return false;
        if (e.message === 'Each LinearRing of a Polygon must have 4 or more Positions.') return true;
        if (e.message.startsWith('Unable to find segment')) return true;
        if (e.message === 'Maximum call stack size exceeded') return true;
        if (e.message.startsWith('Unable to complete output ring starting at')) return true;
        return false;
    }
    try {
        return intersect(poly1, poly2);
    }
    catch (e) {
        if (isKnownError(e)) {
            console.log("Failed to intersect without trucation");
        }
        else throw e;
        
    }
    for (let precision = 8; precision >= 6; precision--) {
        try {
            return intersect(truncate(poly1, { precision }), truncate(poly2, { precision }));
        }
        catch (e) {
            if (isKnownError(e)) {
                console.log("Failed to intersect with trucation precision", precision);
            }
            else throw e;
        }
    }
    console.log("args", featureCollection([
        poly1.type === 'Feature' ? poly1 : feature(poly1),
        poly2.type === 'Feature' ? poly2 : feature(poly2),
    ]))
    throw new Error("Custom intersect failed");    
}