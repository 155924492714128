import { Feature, Point } from "@turf/turf";
import { createNewPointAnnotation } from "../../../../actions/NewPointAnnotationAction";
import IAuthState from "../../../../auth/IAuthState";
import IDbProject from "../../../../db/IDbProject";
import { AnnotationType } from "../../../../model/project/IAnnotation";


export const handlePump =  (
    features: Feature[],
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
) => {
    if (features.length !== 1 && features[0].geometry.type !== "Point") {
        throw new Error(`Unexpected feature created`);
    }
    dbPrj.pushAction(createNewPointAnnotation(
        layoutId,
        AnnotationType.pump,
        features[0].geometry as Point,
        authState
    ));
}
