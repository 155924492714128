import { Feature } from "@turf/turf";
import IAction from "rdptypes/IAction";
import IAuthState from "../../../../auth/IAuthState";
import { IFieldSettings } from "../../../../components/DealerSettingsDialog/FieldSettings";
import { IMapContext } from "../../../../components/Map/mapContext";
import IDbProject from "../../../../db/IDbProject";
import { IInteractionState, Interaction } from "../interactionState";
import { handleBoundary } from "./handleBoundary";
import { handleCanal } from "./handleCanal";
import { handleDrawLateralSystem } from "./handleDrawLateralSystem";
import { handleDrawPivotSystem } from "./handleDrawPivotSystem";
import { handleElectricLine } from "./handleElectricLine";
import { handleLabel } from "./handleLabel";
import { handleLine } from "./handleLine";
import { handleMeasure } from "./handleMeasure";
import { handleObstacle } from "./handleObstacle";
import { handlePivotCenterBoundary } from "./handlePivotCenterBoundary";
import { handlePoint } from "./handlePoint";
import { handlePump } from "./handlePump";
import { handleWaterLine } from "./handleWaterLine";
import { handleWetAreaBoundary } from "./handleWetAreaBoundary";
import { handleWheelObstacle } from "./handleWheelObstacle";


export const handleDrawCreate = async (
    interactionState: IInteractionState,
    features: Feature[],
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
    mapContext: IMapContext,
    fieldSettings: IFieldSettings,
    systemDefaults?: ((layoutId: string, systemId: string, authState: IAuthState) => IAction[])
) => {
    const interaction = interactionState.interactionType;
    console.log(`Interaction: ${Interaction[interaction]} Features created: ${JSON.stringify(features)}`);

    switch (interactionState.interactionType) {
        case Interaction.Measure:
            handleMeasure(features, dbPrj, layoutId, authState);
            break;
        case Interaction.DrawElectricLineAnnotation:
            handleElectricLine(features, dbPrj, layoutId, authState);
            break;
        case Interaction.DrawWaterLineAnnotation:
            handleWaterLine(features, dbPrj, layoutId, authState);
            break;
        case Interaction.DrawLineAnnotation:
            handleLine(features, dbPrj, layoutId, authState);
            break;
        case Interaction.DrawCanalAnnotation: 
            handleCanal(features, dbPrj, layoutId, authState);
            break;
        case Interaction.DrawPumpAnnotation:
            handlePump(features, dbPrj, layoutId, authState);
            break;
        case Interaction.DrawPointAnnotation:
            handlePoint(features, dbPrj, layoutId, authState);
            break;
        case Interaction.DrawLabelAnnotation:
            handleLabel(features, dbPrj, layoutId, authState, interactionState.text);
            break;
        case Interaction.DrawObstacle:
            handleObstacle(features, dbPrj, layoutId, authState, fieldSettings);
            break;
        case Interaction.DrawWheelObstacle:
            handleWheelObstacle(features, dbPrj, layoutId, authState, fieldSettings);
            break;
        case Interaction.DrawBoundary:
            handleBoundary(features, dbPrj, layoutId, authState, fieldSettings);
            break;
        case Interaction.DrawWetAreaBoundary:
            handleWetAreaBoundary(features, dbPrj, layoutId, authState);
            break;
        case Interaction.DrawPivotCenterBoundary:
            handlePivotCenterBoundary(features, dbPrj, layoutId, authState, fieldSettings);
            break;
        case Interaction.DrawPivotSystem:
            await handleDrawPivotSystem(features, dbPrj, layoutId, authState, mapContext, interactionState, systemDefaults);
            break;
        case Interaction.DrawLateralSystem: {
            handleDrawLateralSystem(features, dbPrj, layoutId, authState, interactionState, systemDefaults);
            break;
        }
    }
}
