import IPriceListEntry from "rdptypes/roe/IPriceListEntry";
import DiscountConfig from "../mapics/DiscountConfig";
import DiscountCalculator from "./DiscountCalculator";
import { POSections } from "./POConstants";
import QuoteClass from "./QuoteClass";
import { HasSwingArmCorner } from "./SystemFunctions";

export interface ISectionGroupedParts {
    [sectionId: number]: {
        qty: number, 
        partNumber: string
    }[];
}

export default class PriceCalculator {
    //slimmed down version of existing vb PriceCalculator
    private discountCalculator: DiscountCalculator;
    constructor(private quote: QuoteClass, 
        private listPriceCentsMinusAncillaryMinusSpecialOrder: number, 
        private discountConfig: DiscountConfig, 
        private sectionParts: ISectionGroupedParts,
        private priceList: { [partNumber: string]: IPriceListEntry }) {
        this.discountCalculator = new DiscountCalculator(discountConfig);
    }

    public getSystemDealerPriceCents = (specificSectionCents: number = null) => {
        if (specificSectionCents !== null){
            return this.discountCalculator.GetTerritoryManagerDiscountPrice(specificSectionCents);
        }
        return this.discountCalculator.GetTerritoryManagerDiscountPrice(this.listPriceCentsMinusAncillaryMinusSpecialOrder);
    }

    public getNonDiscountedSpecialOrderListPriceCents = () => {
        if (!HasSwingArmCorner(this.quote.System)){
            return 0;
        }
        return this.quote.SwingArmClass.SpecialOrderPriceCents();
    }

    public getDiscountBasedOnSection = (section: POSections) => {
        let cents = this.getTotalPriceCentsForSection(section);

        //apply MainLine Valve discount to valves not included in parts list
        if (section === POSections.MainlineValves){
            this.quote.System.MainlineValveOptions.Valves.MainlineValve.forEach((valve) => {
                let info = this.quote.MainlineValvesClass.GetMainlineValveInfo(valve, this.priceList);
                if (!isNaN(info.Price())){
                    cents += info.Price();
                }
            });
        }

        return (cents - (cents * (this.getDiscountSectionValue(section) / 100)));
    }

    public getTotalDealerPriceCents = () => {
        return this.getSystemDealerPriceCents() + 
            this.getDiscountBasedOnSection(POSections.AgriInject) + 
            this.getDiscountBasedOnSection(POSections.MainlineValves) + 
            this.getDiscountBasedOnSection(POSections.RC10) + 
            this.getDiscountBasedOnSection(POSections.RC10Retro) + 
            this.getDiscountBasedOnSection(POSections.CropX) + 
            this.getDiscountBasedOnSection(POSections.ClemonsFilters) + 
            this.getDiscountBasedOnSection(POSections.SprinklerLube) + 
            this.getDiscountBasedOnSection(POSections.CheckValves) + 
            this.getDiscountBasedOnSection(POSections.HeatExchangers) + 
            this.getDiscountBasedOnSection(POSections.FlowmeterComponentsPartsPricing) + 
            this.getNonDiscountedSpecialOrderListPriceCents();
    }

    public getTotalPriceCentsForSection = (sectionId: POSections) => {
        let listPriceCents = 0;
        for (let sid in this.sectionParts){
            if (parseInt(sid) === sectionId){
                this.sectionParts[sid].forEach((p) => {
                    let cost = this.priceList && p.partNumber in this.priceList ? p.qty * this.priceList[p.partNumber].unitPriceUsdCents : NaN;
                    if (!isNaN(cost)){
                        listPriceCents += cost;
                    }
                });
            }
        }

        return listPriceCents;
    }

    public getDiscountSectionValue = (sectionId: POSections) => {
        switch(sectionId){
            case POSections.AgriInject:
                return this.discountConfig.AgriInjectDiscount;
            case POSections.CheckValves:
                return this.discountConfig.CheckValvesDiscount;
            case POSections.ClemonsFilters:
                return this.discountConfig.ClemonsFiltersDiscount;
            case POSections.CropX:
                return this.discountConfig.CropXDiscount;
            case POSections.FlowmeterComponentsPartsPricing:
                return this.discountConfig.FlowmeterComponentsDiscount;
            case POSections.HeatExchangers:
                return this.discountConfig.HeatExchangersDiscount;
            case POSections.MainlineValves:
                return this.discountConfig.MainlineValveDiscount;
            case POSections.RC10:
                return this.discountConfig.RC10Discount;
            case POSections.RC10Retro:
                return this.discountConfig.RC10RetroDiscount;
            case POSections.SprinklerLube:
                return this.discountConfig.SprinklerLubeDiscount;
            case POSections.RadioTelemetry:
                return this.discountConfig.RadioTelemetryDiscount;
            default:
                return this.discountConfig.MainlineValveDiscount;//this is what is returned in BillOfMaterials.vb (would've expected 0?)
        }
    }
}