import IGrowerBase from "rdptypes/api/IGrowerBase";
import IComponent from "rdptypes/roe/IComponent";
import * as React from "react";
import { PropsWithChildren, useContext, useState } from "react";
import AuthCtx from "../../auth/AuthCtx";
import { SystemValidationResult, validateComponent } from "../../components/roe/validation/SystemValidator";
import IDbProject from "../../db/IDbProject";
import ISystem from "../../model/project/ISystem";
import DealerDataCtx from "../../userData/DealerDataCtx";
import IValidationWorkerState, { ValidationWorkerStatus } from "./IValidationWorkerState";
import ValidationWorkerCtx from "./ValidationWorkerCtx";
import { getComponents } from "./manager";

interface Props {
    dbPrj: IDbProject;
    grower: IGrowerBase;
    layoutId: string;
    systemId: string;
}

const SingleThreadValidationWorkerProvider: React.FC<PropsWithChildren<Props>> = (props) => {
    const authState = useContext(AuthCtx);
    const user = useContext(DealerDataCtx);

    const validateCmp = (
        system: ISystem,
        cmp: IComponent,
        fieldRoot: string,
        pageId: string,
    ) => {
        const validationResult = new SystemValidationResult();
        validateComponent(cmp, {
            system,
            user,
            grower: props.grower,
            fieldRoot: fieldRoot,
            pageId: pageId,
            result: validationResult,
            fast: false,
        });
        return validationResult;
    }

    const validate = async (
        system: ISystem,
        changingFieldPaths: string[],
        mainPageId: string) => {

        const cmps = getComponents(system, mainPageId);
        const combinedValidationResult = new SystemValidationResult();
        for (const cmp of cmps) {
            validateComponent(cmp.cmp, {
                system,
                user,
                grower: props.grower,
                fieldRoot: cmp.fieldRoot,
                pageId: cmp.pageId,
                result: combinedValidationResult,
                fast: true
            });
            
        }

        console.log(`Validation finished`);
        setVws({
            status: ValidationWorkerStatus.Validated,
            validate,
            validateCmp,
            validationResult: combinedValidationResult
        });

        return combinedValidationResult;
    }

    const [vws, setVws] = useState<IValidationWorkerState>({
        status: ValidationWorkerStatus.Validated,
        validate,
        validateCmp,
    });

    return (
        <ValidationWorkerCtx.Provider value={vws}>
            {props.children}
        </ValidationWorkerCtx.Provider>
    );
}

export default SingleThreadValidationWorkerProvider;