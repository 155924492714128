import ILayout from "../../../../model/project/ILayout";

export interface IMapFeaturePermissions {
    editBoundaries: boolean;
    editObstacles: boolean;
    addSystem: boolean;
    editSystem: (systemId: string) => boolean;
}
export const getMapFeaturePermissions = (layout: ILayout | undefined): IMapFeaturePermissions => {
    if (!layout) {
        return {
            addSystem: false,
            editSystem: (systemId) => false,
            editObstacles: false,
            editBoundaries: false
        }
    }

    const hasSystemWithProposal = Object.values(layout.systems).some(x => x.proposalGenerated);

    return {
        editBoundaries: !hasSystemWithProposal,
        editObstacles: !hasSystemWithProposal,
        addSystem: true,
        editSystem: (systemId: string) => layout.systems[systemId]
            ? !layout.systems[systemId].proposalGenerated
            : false
    }
}