import { IDealerMapFillColor } from "../DealerSettingsDialog/TabMapSettings";
import mapDrawStyles from "./mapDrawStyles";

type GetElementType<T extends any[]> = T extends (infer U)[] ? U : never;
type IMapStyle = GetElementType<typeof mapDrawStyles>;

export const isCustomizableStyle = (x: IMapStyle) => {
    return (
        x.paint && (
            x.paint["fill-color"] || x.paint["line-color"] || 
            x.paint["text-color"] || x.paint["text-halo-color"] ||
            x.paint["circle-color"]
        ) 
    )
}

export const mergeMapStyles = (dealerSettingsColors: IDealerMapFillColor[]): {
    id: string;
    type: string;
    filter?: (string | (string | boolean)[] | boolean)[];
    paint?: {
        'fill-color'?: string;
        'fill-outline-color'?: string;
        'fill-opacity'?: number;
        'circle-radius'?: number;
        'circle-color'?: string;
        'line-color'?: string;
        'line-dasharray'?: number[];
        'line-width'?: number;
    };
    layout?: {
        'line-cap'?: string;
        'line-join'?: string;
        'visibility'?: string; 
        'text-field'?: string[]; 
        'text-font'?: string[]; 
        'text-radial-offset'?: number; 
        'text-justify'?: string; 
        'text-rotate'?: {'type'?: string; 'property'?: string}; 
        'text-anchor'?: string; 
        "text-max-width"?: number;
    };
}[] => {
    //spread operator ... here is insufficient - mapDrawStyles get overwritten, hence the JSON.parse/stringify
    // udpated to use structuredClone to keep ts typings
    const cmds = structuredClone(mapDrawStyles);
    const filtered: IMapStyle[] = [];
    const original: IMapStyle[] = [];
    for (const x of cmds) {
        if (isCustomizableStyle(x)) {
            filtered.push(x);
        }
        else {
            original.push(x);
        }
    }
    // console.log("filtered", filtered)
    // console.log("original", original)

    dealerSettingsColors.forEach((dsc) => {
        let mdsIdx = filtered.findIndex(x => x.id === dsc.id);
        if (mdsIdx !== -1) {
            let mds = filtered[mdsIdx];
            if (mds && mds.paint){
                if (dsc.fillColor){
                    mds.paint["fill-color"] = dsc.fillColor;
                }
                if (dsc.lineColor){
                    mds.paint["line-color"] = dsc.lineColor;
                }
                if (dsc.textColor){
                    mds.paint["text-color"] = dsc.textColor;
                }
                if (dsc.textHaloColor){
                    mds.paint["text-halo-color"] = dsc.textHaloColor;
                }
                if (dsc.circleColor){
                    mds.paint["circle-color"] = dsc.circleColor;
                }
            }
        }

    });

    return [
        ...original,
        ...filtered
    ];
}