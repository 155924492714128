import { DeviceTypes, IPackage, IRegulator, IPackage as Package, RegulatorTypes } from "rdptypes/project/ISprinklers";
import {
    ISprinklerProperties
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import CSection2 from "./CSection2";
import { RegulatorPressure } from "./CommonFunctions";
import PartsAssembler from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as spanf from "./SpanFunctions";
import { SpansClass } from "./SpansClass";
import { SprinklersBoomBackClass } from './SprinklersBoomBackClass';
import { SprinklersPackageClass } from './SprinklersPackageClass';
import * as sysf from "./SystemFunctions";
import { strLanguageText } from "./TranslationsLib";
import { Side } from "./Types";
export class SprinklersPackagesClass extends CSection2 {
    
    public Package: IPackage;
    public SpansClass: SpansClass;
    public Side: Side;
    private get Packages() {
        return this.Side.Sprinklers;
    }

    private CheckPackage(SideKey: "R" | "L", PackageNumber: number, WithWarnings: boolean): boolean {
        const pc = new SprinklersPackageClass(/* this.Quote, SideKey, PackageNumber, Nothing */);
        pc.Quote = this.Quote;
        pc.SideKey = SideKey;
        pc.PackageNumber = PackageNumber;
        return pc.DataOKWithReturn(null, WithWarnings);
    }
    private CheckBoomBacks(tag: "R" | "L", WithWarnings: boolean): boolean {
        const SprinklerBoomBack = new SprinklersBoomBackClass();
        SprinklerBoomBack.Quote = this.Quote;
        SprinklerBoomBack.SideKey = tag;
        return SprinklerBoomBack.DataOKWithReturn(null, WithWarnings)
    }

    public DataOK(PA: PartsAssembler, WithWarnings?: boolean, DependencyCheckInProgress?: boolean): void {
        let bEOS = false;
        let sLastEnd = 0;

        const sp: ISprinklerProperties = this.Quote.System.SprinklerProperties;

        if (sp.TotalGPM > 5500) {
            this.Quote.Warnings.Add(strLanguageText("sltSystemGPMMax5500PSI"));
            return;
        }

        if (sp.TotalGPM === 0) {
            this.Quote.Warnings.Add(strLanguageText("sltSystemGPMCannotBeZero"));
            return;
        }

        // 'NOTE: This code is the same as in SprinklersPackagesForm.vb, however we do NOT compare value adjusted by PSIToBar() method.
        if (sp.DesignedEndPressure > 100) { //'PER ROE-396, ROE-413 Ensure validation in Standard/Metric
            this.Quote.Warnings.Add(strLanguageText("sltEndPressureMax100PSI"));
            return;
        }
        else if (sysf.HasSwingArmCorner(this.Quote.System) && sp.DesignedEndPressure < 15) { //'ROE-634 - Minimum 15 PSI @ End of System for Swing Arm Systems
            this.Quote.Warnings.Add(strLanguageText("sltEndPressureMin15PSI"))
            return;
        }
        else if (sp.DesignedEndPressure < 3) { //'ROE-634 - Minimum 3 PSI @ End of System
            this.Quote.Warnings.Add(strLanguageText("sltEndPressureMin3PSI"));
            return;
        }

        const iPackageCount: number = this.Quote.System.FlangedSide.Sprinklers.Package.length;
        if (iPackageCount === 0) {
            // Enforced by UI
            //this.Quote.Warnings.Add(strLanguageText("sltMustDesignPackage"));
            return;
        }
        for (let i = 0; i < iPackageCount; i++) {
            if (!this.CheckPackage("R", i + 1, WithWarnings)) return;
            const pkg: Package = this.Quote.System.FlangedSide.Sprinklers.Package[i];
            const reg: IRegulator = pkg.Regulator;
            if (
                reg.RegulatorType !== RegulatorTypes.None &&
                (RegulatorPressure(reg.Pressure) + 5) > sp.DesignedEndPressure
            ) {
                this.Quote.Warnings.Add(`Flange Side Sprinkler Package (${i}): ${strLanguageText("sltEndPressureBelowRegulatorRequirements")}`, WarningLevels.Informational);
            }

            if (bEOS) {
                if (pkg.Device !== DeviceTypes.None) {
                    this.Quote.Warnings.Add(strLanguageText("sltAPackageCannotBePlaced"))
                    return;
                }
            }
            else {
                bEOS = pkg.EndingLocation === 0 && (pkg.Device !== DeviceTypes.None);
                if (pkg.EndingLocation !== 0) {
                    if (pkg.EndingLocation <= sLastEnd) {
                        this.Quote.Warnings.Add(strLanguageText("sltThePackagesMustBePlaced"));
                        return;
                    }
                    sLastEnd = pkg.EndingLocation
                }
            }
        }
        if (!bEOS) {
            this.Quote.Warnings.Add(strLanguageText("sltTheLastPackageMustBeConfigured"));
            return;
        }

        if (!this.CheckBoomBacks("R", WithWarnings)) return;

        if (sysf.IsDualSided(this.Quote.System)) {
            bEOS = false;
            const iPackageCount: number = this.Quote.System.FlexSide.Sprinklers.Package.length;
            if (iPackageCount === 0) {
                // Enforced by UI
                //this.Quote.Warnings.Add(strLanguageText("sltMustDesignPackage"));
                return;
            }
            for (let i = 0; i < iPackageCount; i++) {
                if (!this.CheckPackage("L", i + 1, WithWarnings)) return;
                const pkg: Package = this.Quote.System.FlexSide.Sprinklers.Package[i];
                const reg: IRegulator = pkg.Regulator;

                if (
                    reg.RegulatorType !== RegulatorTypes.None &&
                    (RegulatorPressure(reg.Pressure) + 5) > sp.DesignedEndPressure
                ) {
                    this.Quote.Warnings.Add(`Flex Side Sprinkler Package (${i}): ${strLanguageText("sltEndPressureBelowRegulatorRequirements")}`, WarningLevels.Informational);
                }

                if (bEOS) {
                    if (pkg.Device !== DeviceTypes.None) {
                        this.Quote.Warnings.Add(strLanguageText("sltAPackageCannotBePlaced"));
                        return;
                    }
                } 
                else {
                    bEOS = (pkg.EndingLocation === 0) && (pkg.Device !== DeviceTypes.None);
                    if (pkg.EndingLocation !== 0) {
                        if (pkg.EndingLocation <= sLastEnd) {
                            this.Quote.Warnings.Add(strLanguageText("sltThePackagesMustBePlaced"));
                            return;
                        }
                        sLastEnd = pkg.EndingLocation;
                    }
                }
            }
            if (!bEOS) {
                this.Quote.Warnings.Add(strLanguageText("sltTheLastPackageMustBeConfigured"));
                return;
            }

            if (!this.CheckBoomBacks("L", WithWarnings)) return;
        }
            
        // return true;
        return;
    }
    public WritePO(PO: PartsAssembler): void {
        // pass: nothing to do
    }

    private DetermineStartSpan() {
        let iStart: number;
        if (this.Package.PackageNumber === 1) {
            iStart = 1
        } 
        else {
            iStart = this.SpanFromLocation(this.Packages[this.Package.PackageNumber - 1].EndingLocation + 0.01);
        }
        return iStart;
    }
    private DetermineStopSpan() {
        let iEnd: number;
        if (this.Package.EndingLocation === 0) {
            iEnd = sidef.NumberOfSpans(this.Side);
        } 
        else {
            iEnd = this.SpanFromLocation(this.Package.EndingLocation);
        }
        return iEnd;
    }

    private SpanFromLocation(l: number): number {
        if (l <= 30) {
            return 1;
        }
        const k: number = sidef.NumberOfSpans(this.Side) - 1;
        for (let i = 0; i <= k; i++) {    
            
            if (
                l >= spanf.StartingLocation(this.Quote.System, this.Side, this.Side.Span[i]) &&
                l <= spanf.EndingLocation(this.Quote.System, this.Side, this.Side.Span[i])
            ) {
                return i;
            }
        }
        // Should never get here
        return k;
    }
}
