import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Stack,
    Typography
} from "@mui/material";
import i18next, { t } from "i18next";
import { EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { getThrowRangeFromEndGunType } from "../../model/project/IEndGun";
import { PRIMARY_END_GUN_OPTIONS, SECONDARY_END_GUN_OPTIONS } from "../../routes/pages/LayoutMapPage/LeftDrawer/endOfSystemTab";
import { disabledColor, pageStyles } from "../roe/styles";

interface Props {
    isSecondary?: boolean;
    type: EndGunTypes;
    setType: (endGun: EndGunTypes) => void;
    throwFeet?: number;
    setThrowFeet: (value?: number) => void;
    disabled?: boolean;
}

export const EndGunThrowSlider: React.FC<{ min: number, max: number, value: number, setValue: (value: number)=> void, disabled?: boolean }> = ({ min, max, value, setValue, disabled = false }) => {

    const [internalValue, setInternalValue] = React.useState(value);
    React.useEffect(() => {
        setInternalValue(value);
    }, [value])
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setInternalValue(newValue as number);
    };

    const handleSliderBlur = (event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
        setValue(value as number)
    }
    
    const marks: { value: number, label: string }[] = [
        { value: min, label: min.toString() },
        { value: max, label: max.toString() },
    ];

    let sx = {};
    if (disabled) {
        sx['color'] = disabledColor;
    }
    return (
        <FormControl sx={{ alignItems: 'flex-start', margin: 0, width: "100%" }} disabled={disabled}>
            <Typography variant="subtitle2" sx={sx}>
                <b>{i18next.format(t("throw"), "capitalize-each")}:</b> {`${value} ${t("ft")}`}
            </Typography>
            <Stack direction={'row'} sx={{ width: "100%", alignItems: 'center' }}>
                <div style={{ ...sx, paddingRight: 10, flexGrow: 0, width: 30, flexShrink: 0, textAlign: 'right' }}>{marks[0].label}</div>
                <Slider
                    value={internalValue}
                    onChange={handleSliderChange}
                    onChangeCommitted={handleSliderBlur}
                    aria-labelledby="input-slider"
                    min={min}
                    max={max}
                    valueLabelDisplay="auto"
                    // marks={marks}
                    step={1}
                    sx={{ flexGrow: 1 }}
                    disabled={disabled}
                />
                <div style={{ ...sx, paddingLeft: 10, flexGrow: 0, width: 30, flexShrink: 0 }}>{marks[1].label}</div>
            </Stack>
        </FormControl>
        )
}

interface Props2 {
    type: EndGunTypes;
    throwFeet?: number;
    setThrowFeet: (value?: number) => void;
    disabled?: boolean;
}
export const EndGunTypeThrowSlider: React.FC<Props2> = ({ type, throwFeet = 0, setThrowFeet, disabled = false }) => {

    const { throwRange, throwAvg } = React.useMemo(() => {
        const throwRange = getThrowRangeFromEndGunType(type);
        const throwAvg = throwRange ? Math.round((throwRange.radiusOfThrowFeet.min + throwRange.radiusOfThrowFeet.max) * 0.5) : 0;
        return {
            throwAvg, throwRange
        }
    }, [ type ]);

    React.useEffect(() => {
        if (!throwRange || !throwAvg) {
            setThrowFeet(0);
        }
        else {
            setThrowFeet(throwAvg);
        }
    }, [ throwAvg, throwRange ]);

    let formInputLabelStyle = {
        ...pageStyles.formInputLabelStyle
    };
    if (disabled) {
        formInputLabelStyle['color'] = disabledColor;
    }

    if (!throwRange || type === EndGunTypes.None) return null;

    return (
        <EndGunThrowSlider 
            min={throwRange.radiusOfThrowFeet.min} 
            max={throwRange.radiusOfThrowFeet.max} 
            value={throwFeet} 
            setValue={setThrowFeet}
            disabled={disabled}
        />
    );
};


const EndGunType: React.FC<Props> = ({ isSecondary = false, setType, type, throwFeet = 0, setThrowFeet, disabled = false }) => {

    let formInputLabelStyle = {
        ...pageStyles.formInputLabelStyle
    };
    if (disabled) {
        formInputLabelStyle['color'] = disabledColor;
    }

    return (
        <FormControl variant="standard" fullWidth disabled={disabled}>
            <InputLabel shrink={true} style={{...formInputLabelStyle}} id="end-gun-type-label">{i18next.format(t("common-phrases.sltType"), "capitalize-each")}</InputLabel>
            <Select 
                labelId="end-gun-type-label"
                sx={{ marginLeft: 1 }}
                value={type}
                onChange={(ev) => {
                    setType(ev.target.value as EndGunTypes);
                }}
            >
                {
                    (isSecondary ? SECONDARY_END_GUN_OPTIONS : PRIMARY_END_GUN_OPTIONS).map(opt => {
                        return (
                            <MenuItem key={opt.id} value={opt.id}>
                                {opt.title}
                            </MenuItem>
                        );
                    })
                }
            </Select>
        </FormControl>
    );
};

export default EndGunType;
