import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Stack } from "@mui/material";
import i18next, { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import ILayout from "../../../../model/project/ILayout";

interface IOnConfirmArgs {
    boundaries: boolean;
    obstacles: boolean;
    systemIds: string[];
    annotations: boolean;
}

interface Props {
    open: boolean;
    onConfirm: (args: IOnConfirmArgs) => any;
    onClose: () => any;
    layout: ILayout;
}

const NewLayoutDialog: FC<Props> = (props) => {

    const systemIdSystemPairs = Object.entries(props.layout.systems);
    const [ dialogOptions, setDialogOptions ] = React.useState<IOnConfirmArgs>({
        boundaries: true,
        obstacles: true,
        systemIds: systemIdSystemPairs.map(([systemId]) => systemId),
        annotations: true
    });

    const handleOnConfirm = () => {   
        props.onConfirm(dialogOptions);
    }

    const ns = "nav.layouts.";

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="copy-layout-dialog"
        >
        <DialogTitle id="copy-layout-dialog">
            {t(ns + "copy-to-new-layout")}...
        </DialogTitle>
        <DialogContent>
            <Stack direction={'column'}>
                <FormControl>
                    <FormControlLabel 
                        control={
                            <Checkbox
                                checked={dialogOptions.boundaries} 
                                onChange={(ev) => setDialogOptions({ ...dialogOptions, boundaries: ev.target.checked })}
                            />
                        } 
                        label={i18next.format(t('boundaries'), 'capitalize')}
                    />
                    <FormControlLabel 
                        control={
                            <Checkbox
                                checked={dialogOptions.obstacles} 
                                onChange={(ev) => setDialogOptions({ ...dialogOptions, obstacles: ev.target.checked })}
                            />
                        } 
                        label={i18next.format(t('obstacles'), 'capitalize')}
                    />
                    <FormControlLabel
                        label={i18next.format(t('systems'), 'capitalize')}
                        control={
                            <Checkbox
                                disabled={!systemIdSystemPairs.length}
                                checked={systemIdSystemPairs.length && (dialogOptions.systemIds.length === systemIdSystemPairs.length)}
                                indeterminate={dialogOptions.systemIds.length && dialogOptions.systemIds.length !== systemIdSystemPairs.length}
                                onChange={(ev) => {
                                    if (ev.target.checked) {
                                        setDialogOptions({ ...dialogOptions, systemIds: systemIdSystemPairs.map(([systemId]) => systemId) })
                                    }
                                    else {
                                        setDialogOptions({ ...dialogOptions, systemIds: [] })
                                    }
                                }}
                            />
                        }
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                        {
                            systemIdSystemPairs.map(([systemId, system]) => {
                                return (
                                    <FormControlLabel
                                      label={system.name}
                                      control={
                                            <Checkbox 
                                                checked={dialogOptions.systemIds.includes(systemId)} 
                                                onChange={(ev) => {
                                                    let systemIds = dialogOptions.systemIds.filter(x => x !== systemId);
                                                    if (ev.target.checked) {
                                                        systemIds.push(systemId);
                                                    }
                                                    setDialogOptions({ ...dialogOptions, systemIds })
                                                }}
                                            />
                                        }
                                    />
                                )
                            })
                        }
                    </Box>
                    <FormControlLabel 
                        control={
                            <Checkbox
                                checked={dialogOptions.annotations} 
                                onChange={(ev) => setDialogOptions({ ...dialogOptions, annotations: ev.target.checked })}
                            />
                        } 
                        label={i18next.format(t('annotations'), 'capitalize')}
                    />
                </FormControl>
            </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>{t('cancel')}</Button>
          <Button onClick={handleOnConfirm} autoFocus>{t('confirm')}</Button>
        </DialogActions>
      </Dialog>
    );
};

export default NewLayoutDialog;