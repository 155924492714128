import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import * as turf from "@turf/turf";
import { t } from "i18next";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { GeometryCtx } from "../../GeometryHelpers/GeometryProvider";
import DbCtx from "../../db/DbCtx";
import LayoutMap from "../LayoutMap";
import BearingSlider from "./BearingSlider";
import OffsetSlider from "./OffsetSlider";
import SystemDiagram from "./SystemDiagram";
import { centerPivotElevationProfiler, generateCenterPivotSweepLineFeature } from "./centerPivotElevationProfiler";
import { IElevationProfile } from "./interfaces";
import { generateLateralSweepLineFeature, lateralElevationProfiler } from "./lateralElevationProfiler";


interface IElevationState {
    profiles: IElevationProfile[];
    minimum: {
        elevationFeet: number;
    };
    maximum: {
        elevationFeet: number;
    };
    system: {
        totalRunningLengthFeet: number;
        feedLineLengthFeet?: number;
    };
}


interface IProps {
    projectId: string;
    layoutId: string;
    systemId: string;
    justSystemDiagram?: {
        partWidthFeet: number,
        widthInPixels: number    
    };
}

const SystemDiagramView: React.FC<IProps> = ({
    projectId,
    layoutId,
    systemId,
    justSystemDiagram
}) => {
    const [bearingOrOffset, setBearingOrOffset] = useState<number>(0);
    const [elevationState, setElevationState] = useState<
        IElevationState | undefined
    >(undefined);
    const [zoom, setZoom] = useState(true);
    const geometryState = useContext(GeometryCtx);

    const dbState = useContext(DbCtx);
    const dbPrj = dbState.projects[projectId!];
    const project = dbPrj.state;
    const layout = project.layouts[layoutId!];
    const system = layout.systems[systemId!];
    useEffect(() => {
        if (!geometryState || !geometryState.elevation || !system) {
            setElevationState(undefined);
            return;
        }

        if (system.SystemProperties.SystemType === SystemTypes.CenterPivot) {
            const profilerResult = centerPivotElevationProfiler({
                system, project, elevation: geometryState?.elevation, 
            })
            if (profilerResult) {
                setElevationState({
                    system: {
                        totalRunningLengthFeet: profilerResult.systemLength
                    },
                    profiles: profilerResult.profiles,
                    minimum: profilerResult.minimumElevation,
                    maximum: profilerResult.maximumElevation
                })
                setBearingOrOffset(profilerResult.normalizedStartBearing);
            }
            else {
                setElevationState(undefined);
            }
        }
        else if (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator
            || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) {
            const profilerResult = lateralElevationProfiler({
                systemId, layoutId, project, elevation: geometryState?.elevation
            })
            if (profilerResult) {
                setElevationState({
                    system: {
                        totalRunningLengthFeet: profilerResult.systemLength,
                        feedLineLengthFeet: profilerResult.normalizedEndOffsetFeet
                    },
                    profiles: profilerResult.profiles,
                    minimum: profilerResult.minimumElevation,
                    maximum: profilerResult.maximumElevation
                })
                setBearingOrOffset(profilerResult.normalizedStartOffsetFeet);
            }
            else {
                setElevationState(undefined);
            }
        }
    }, [system, geometryState, geometryState?.elevation, dbState]);


    let elevationProfile: IElevationProfile | undefined = undefined;
    if (elevationState) {
        if (system.SystemProperties.SystemType === SystemTypes.CenterPivot && elevationState.profiles.length === 360) {
            elevationProfile = elevationState.profiles[
                (bearingOrOffset + 360) % 360
            ]
        }
        else if (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator
            || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) {
            elevationProfile = elevationState.profiles[bearingOrOffset]
        }
    }
    const hasElevationProfile = elevationProfile !== undefined;


    if (!system) return null;
    
    let sweepLine: turf.Feature[] | undefined = undefined;
    if (elevationState) {
        if (system.SystemProperties.SystemType === SystemTypes.CenterPivot) {
            sweepLine = generateCenterPivotSweepLineFeature(system, elevationState.system.totalRunningLengthFeet, bearingOrOffset);
        }
        else if (system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator
            || system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator) { 
            sweepLine = generateLateralSweepLineFeature(
                systemId, project, layoutId,
                bearingOrOffset);
        }
    }

    if (justSystemDiagram){
        return <Box>
            {elevationProfile && elevationState && (
                <SystemDiagram
                    project={project}
                    layoutId={layoutId}
                    systemId={systemId}
                    minElevationFeet={
                        elevationState.minimum.elevationFeet
                    }
                    maxElevationFeet={
                        elevationState.maximum.elevationFeet
                    }
                    elevationProfile={elevationProfile}
                    zoom={false}
                    justSystemDiagram={justSystemDiagram}
                />
            )}
        </Box>;
    }

    return (
        <Box
            sx={{
                display: "flex",
                height: 300,
                overflow: "hidden",
                width: "100%",
            }}
        >
            <Card variant="outlined" sx={{ flex: 1 }}>
                <CardContent>
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <Typography
                            variant="h5"
                            component="div"
                            sx={{ paddingRight: 2, flex: 1 }}
                        >
                            {t("side-view")}
                        </Typography>
                        <IconButton
                            size="large"
                            aria-label={zoom ? "zoom out" : "zoom in"}
                            disabled={!hasElevationProfile}
                            onClick={() => setZoom(!zoom)}
                            color="inherit"
                            sx={{ p: 0 }}
                        >
                            {zoom ? <ZoomOutIcon /> : <ZoomInIcon />}
                        </IconButton>
                    </Box>
                    <Box sx={{ height: 200 }}>
                        {elevationProfile && elevationState && (
                            <SystemDiagram
                                project={project}
                                layoutId={layoutId}
                                systemId={systemId}
                                minElevationFeet={
                                    elevationState.minimum.elevationFeet
                                }
                                maxElevationFeet={
                                    elevationState.maximum.elevationFeet
                                }
                                elevationProfile={elevationProfile}
                                zoom={zoom}
                            />
                        )}
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            {system.SystemProperties.SystemType === SystemTypes.CenterPivot && (
                                <BearingSlider
                                    minBearing={
                                        (system.Circle.CenterPivot.isPartialPivot && system.Circle!.CenterPivot!
                                            .clockwiseCompassHeadingStart) || 0
                                    }
                                    maxBearing={
                                        (system.Circle.CenterPivot.isPartialPivot && system.Circle!.CenterPivot!
                                            .clockwiseCompassHeadingEnd) || 360
                                    }
                                    bearing={bearingOrOffset}
                                    onChange={setBearingOrOffset}
                                />
                            )}
                            {(system.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator
                            || system.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator)
                             && elevationState && elevationState.system.feedLineLengthFeet && (
                                <OffsetSlider
                                    offset={bearingOrOffset}
                                    maxOffset={elevationState.system.feedLineLengthFeet}
                                    onChange={setBearingOrOffset}
                                />
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <Box sx={{ width: 300, position: "relative" }}>
                <LayoutMap
                    projectId={projectId!}
                    layoutId={layoutId!}
                    additionalFeatures={sweepLine}
                    interactive={false}
                    activeSystemIds={[systemId!]}
                />
            </Box>
        </Box>
    );
};

export default SystemDiagramView;
