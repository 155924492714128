import { nanoid } from "nanoid";
import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import IAuthState from "../auth/IAuthState";
import IProject from "../model/project/IProject";

export const createAction = (actionTypeId: string, data: IActionData, authState: IAuthState) => ({
    id: nanoid(),
    actionTypeId,
    data,
    timestamp: Date.now(),
    userId: authState.currentUser?.userId
} as IAction);

export const recordProjectChange = (action: IAction, state: IProject) => {
    state.lastModifiedTimestamp = action.timestamp;
    state.lastModifiedUserId = action.userId;
}

export const recordLayoutChange = (action: IAction, state: IProject, layoutId: string) => {
    const layout = state.layouts[layoutId];
    layout.lastModifiedTimestamp = action.timestamp;
    layout.lastModifiedUserId = action.userId;
}

export const recordSystemChange = (action: IAction, state: IProject, layoutId: string, systemId: string) => {
    const system = state.layouts[layoutId].systems[systemId];
    system.lastModifiedTimestamp = action.timestamp;
    system.lastModifiedUserId = action.userId;
}