import SegmentPolygonHelper from "../../../GeometryHelpers/SegmentPolygonHelper";
import IDbProject from "../../../db/IDbProject";
import ILayout from "../../../model/project/ILayout";
import { ProjectType } from "../../../model/project/IProject";
import { SACType } from "../../../optimization/centerPivot/sac/SACOptimizer/ThinkGeo/OptimizeUtilities";
import { hasSystemChanged } from "./hasSystemChanged";
import { shouldGenerateSac } from "./shouldGenerateSac";

interface IProjectsMapIncomming {
    [projectId: string]: IDbProject | undefined;
}
export interface IProjectsMap {
    [projectId: string]: IDbProject;
}
type IFindModifiedSystemsItem = {
    projectId: string;
    layoutId: string;
    systemId: string;

} & (
    { shouldClear: true } |
    { shouldClear: false, sacType: SACType, endGunThrowsMeters: number[] }
)

const isLayoutEqual = (layout1: ILayout, layout2: ILayout) => {
    if (!SegmentPolygonHelper.isEqual(layout1.fieldBoundary, layout2.fieldBoundary)) return false;
    if (!SegmentPolygonHelper.isEqual(layout1.pivotCenterBoundary, layout2.pivotCenterBoundary)) return false;
    if (!SegmentPolygonHelper.isEqual(layout1.wetAreaBoundary, layout2.wetAreaBoundary)) return false;
    if (layout1.obstacles.length !== layout2.obstacles.length) return false;
    for (let i = 0; i < layout1.obstacles.length; i++) {
        if (!SegmentPolygonHelper.isEqual(layout1.obstacles[i], layout2.obstacles[i])) return false;
    }
    if (layout1.wheelObstacles.length !== layout2.wheelObstacles.length) return false;
    for (let i = 0; i < layout1.wheelObstacles.length; i++) {
        if (!SegmentPolygonHelper.isEqual(layout1.wheelObstacles[i], layout2.wheelObstacles[i])) return false;
    }
    return true;
}

export const findModifiedSystems = (previousProjects: IProjectsMapIncomming, incomingProjects: IProjectsMap): IFindModifiedSystemsItem[] => {
    const items: IFindModifiedSystemsItem[] = [];
    for (const projectId of Object.keys(incomingProjects)) {
        const incommingProject = incomingProjects[projectId].state;
        if (incommingProject.projectType !== ProjectType.LayoutAndDesign) continue;
        const previousProject = previousProjects[projectId]?.state;
        for (const layoutId of Object.keys(incommingProject.layouts)) {
            const incommingLayout = incommingProject.layouts[layoutId];
            const previousLayout = previousProject?.layouts[layoutId];
            const isPreviousLayoutEqual = previousLayout === undefined ? false : isLayoutEqual(previousLayout, incommingLayout);
            const forceUpdate = (
                previousLayout && (
                    Object.keys(incommingLayout.systems).some(systemId => {
                        const incommingSystem = incommingLayout.systems[systemId];
                        const previousSystem = previousLayout?.systems[systemId];
                        return hasSystemChanged(previousSystem, incommingSystem);
                    })
                )
            )

            for (const systemId of Object.keys(incommingLayout.systems)) {
                const incommingSystem = incommingLayout.systems[systemId];
                // if the last layout was different, set the previous system to undefined. This will force should
                // generate to force sac recalc
                const previousSystem = isPreviousLayoutEqual ? previousLayout?.systems[systemId] : undefined;
                const shouldGenerate = shouldGenerateSac(previousSystem, incommingSystem, forceUpdate);
                if (shouldGenerate.shouldGenerate) {
                    items.push({
                        projectId,
                        layoutId,
                        systemId,
                        shouldClear: false,
                        sacType: shouldGenerate.sacType,
                        endGunThrowsMeters: shouldGenerate.endGunThrowsMeters
                    })
                }
                else if(shouldGenerate.shouldClear) {
                    items.push({
                        projectId,
                        layoutId,
                        systemId,
                        shouldClear: true
                    })
                }
            }
        }
    }
    return items;
}
