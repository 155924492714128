import { EndGunAreaContainer, EndGunDataContainer } from "../Objects/EndGunDataContainer";
import { SACOptimizationProblem } from "./SACOptimizationProblem";

import { jsts } from "../jstsLib";
import { Vector2 } from "../Numerics/Vector2";

export class SACEndGunModel {
    readonly problem: SACOptimizationProblem;
    readonly iPivotAngleMin: number
    readonly iPivotAngleMax: number;
    readonly alphas: number[];
    readonly fullyExtended: boolean[];

    public constructor(problem: SACOptimizationProblem,
        iPivotAngleMin: number, iPivotAngleMax: number,
        alphas: number[],
        fullyExtended: boolean[])
    {
        this.problem = problem;
        this.iPivotAngleMin = iPivotAngleMin;
        this.iPivotAngleMax = iPivotAngleMax;
        this.alphas = alphas;
        this.fullyExtended = fullyExtended;
    }
    
    // RDP3 only needs to know about the segments. GetEndGunDataSegments has been
    // extracted from GetEndGunData:
    public GetEndGunDataSegments(endGunThrowsMetres: number[], coverageShape: jsts.geom.Geometry): IEndGunSolution[] {
        if (endGunThrowsMetres.length === 0) return [];

        const segments = endGunThrowsMetres.map(endGunThrowMetres => this.GetEndGunOnSegments(endGunThrowMetres, coverageShape));
        this.ResolveOverlappingSegments(segments);
        //MergeWrapSegments(segments);

        return segments.map((sln, idx) => ({
            segments: sln.filter(s => s.iOnAngle !== s.iOffAngle).map(s => ({
                ...s,
                isCircle: s.iOnAngle === 0 && s.iOffAngle === (this.problem.PivotAngleStepCount - 1)
            })),
            throwMeters: endGunThrowsMetres[idx]
        }));
    }

    public GetEndGunData(endGunThrowsMetres: number[], coverageShape: jsts.geom.Geometry): EndGunDataContainer[] {
        if (endGunThrowsMetres.length === 0) return [];

        const segments = this.GetEndGunDataSegments(endGunThrowsMetres, coverageShape);

        const containers = endGunThrowsMetres.map((endGunThrowMetres, iEndGun) => {
            const x = new EndGunDataContainer();
            x.CalculatedThrow = endGunThrowMetres;
            x.CalculatedThrowUnit = "meters";
            x.EndGunAreas = segments[iEndGun].segments.map(segment => this.GetArea(segment, endGunThrowMetres));
            return x;
        });

        return containers;
    }
    

    // void MergeWrapSegments(List<Tuple<int, int>>[] segments)
    // {
    //     for (var iEndGun = 0; iEndGun < segments.Length; iEndGun++)
    //     {
    //         var ia = segments[iEndGun].FindIndex(s => s.Item1 === iPivotAngleMin);
    //         var ib = segments[iEndGun].FindIndex(s => s.Item2 === iPivotAngleMax);
    //         if (ia !== -1 && ib !== -1 && ia !== ib)
    //         {
    //             segments[iEndGun][ia] = new Tuple<int, int>(segments[iEndGun][ib].Item1, segments[iEndGun][ia].Item2);
    //             segments[iEndGun].RemoveAt(ib);
    //         }
    //     }   
    // }

    ResolveOverlappingSegments(segments: IEndGunSegment[][]): void {
        for (let iEndGun = 0; iEndGun < segments.length - 1; iEndGun++) {
            for (let jEndGun = iEndGun + 1; jEndGun < segments.length; jEndGun++) {
                for (let iSegment = 0; iSegment < segments[iEndGun].length; iSegment++) {
                    for (let jSegment = 0; jSegment < segments[jEndGun].length; jSegment++) {
                        const a = segments[iEndGun][iSegment];
                        const b = segments[jEndGun][jSegment];
 
                        if (a.iOnAngle <= b.iOnAngle && a.iOffAngle >= b.iOffAngle) {
                            segments[jEndGun].splice(jSegment, 1);
                            jSegment--;
                        }
                        else if (a.iOnAngle > b.iOnAngle && a.iOffAngle < b.iOffAngle) {
                            segments[jEndGun][jSegment] = {
                                iOnAngle: b.iOnAngle,
                                iOffAngle: a.iOnAngle
                            }
                            segments[jEndGun].push({
                                iOnAngle: a.iOffAngle,
                                iOffAngle: b.iOffAngle
                            });
                        }
                        else if (a.iOnAngle <= b.iOnAngle && a.iOffAngle < b.iOffAngle && a.iOffAngle > b.iOnAngle) {
                            segments[jEndGun][jSegment] = {
                                iOnAngle: a.iOffAngle,
                                iOffAngle: b.iOffAngle
                            };
                        }
                        else if (a.iOnAngle > b.iOnAngle && a.iOffAngle >= b.iOffAngle && a.iOnAngle < b.iOffAngle) {
                            segments[jEndGun][jSegment] = {
                                iOnAngle: b.iOnAngle,
                                iOffAngle: a.iOnAngle
                            }; 
                        }
                    }
                }
            }
        }
    }

    IsEndGunOn(endGunThrowMetres: number, iPivotAngle: number, coverageShape: jsts.geom.Geometry): boolean {

        const theta = this.problem.GetTheta(iPivotAngle);
        const alpha = this.alphas[iPivotAngle - this.iPivotAngleMin];
        // START: Simon RDP3 update, since the endguns can now be on at any time, this can create 
        // end gun slithers. An arbitrary limit of the swing arm must be extended
        // by at least 0.6 * Pi has been added to prevent these slithers
        if (Math.abs(Math.PI - alpha) > Math.PI * 0.4) return false;
        // END
        const ep = this.problem.Model.p(theta);
        const eeb = this.problem.Model.EndOfEndBoom(theta, alpha);

        // dont allow end gun on if the end of the end boom is inside the pivot
        if (eeb.Length() < ep.Length()) {
            return false;
        }
        
        // dont allow end gun on if the end gun throw overlaps
        // the coverage area. To achieve this, a 10cm buffer is added
        // to the start of the end gun throw away from the end boom
        const eeg = this.problem.Model.EndOfEndGun(theta, alpha, endGunThrowMetres);        
        const b = this.problem.Model.b(theta, alpha);
        const bg = Vector2.Multiply(b, (b.Length() + 0.1) / b.Length());
        const eebWithBuffer = this.problem.Model.r(theta, alpha).add(bg);
        let line = new jsts.geom.GeometryFactory().createLineString([
            new jsts.geom.Coordinate(eebWithBuffer.X, eebWithBuffer.Y),
            new jsts.geom.Coordinate(eeg.X, eeg.Y)
        ])
        line = new jsts.geom.util.AffineTransformation(
            1.0, 0.0, this.problem.PivotCentre.x,
            0.0, 1.0, this.problem.PivotCentre.y
        ).transform(line);

        if (line.intersects(coverageShape)) {
            return false;
        }

        // const p = new jsts.geom.GeometryFactory().createPoint(
        //     new jsts.geom.Coordinate(
        //         eeg.X + this.problem.PivotCentre.x,
        //         eeg.Y + this.problem.PivotCentre.y
        //     )
        // );

        // if (!this.problem.ObstacleConstraints.WetBoundary.contains(p)) {
        //     return false;
        // }

        // for (const obstacle of this.problem.ObstacleConstraints.SpanObstacles) {
        //     if (obstacle.contains(p)) {
        //         return false;
        //     }
        // }

        // NOTE: Simon - RDP 2 test the end of end gun point. This has been upgraded to consider the
        // end gun throw line
        if (!this.problem.ObstacleConstraints.WetBoundary.contains(line)) {
            return false;
        }

        for (const obstacle of this.problem.ObstacleConstraints.WetAreaObstacles) {
            if (obstacle.intersects(line)) {
                return false;
            }
        }

        return true;
    }


    public GetEndGunOnSegments(endGunThrowMetres: number, coverageShape: jsts.geom.Geometry): IEndGunSegment[]
    {
        const ret: IEndGunSegment[] = [];

        let currentSegment: IEndGunSegment | null = null;

        for (let iPivotAngle = this.iPivotAngleMin; iPivotAngle <= this.iPivotAngleMax; iPivotAngle++) {
            if (this.IsEndGunOn(endGunThrowMetres, iPivotAngle, coverageShape)) {
                if (currentSegment === null) {
                    currentSegment = {
                        iOnAngle: iPivotAngle, 
                        iOffAngle: iPivotAngle
                    };
                }
                else {
                    currentSegment = {
                        iOnAngle: currentSegment.iOnAngle, 
                        iOffAngle: iPivotAngle
                    };
                }
            }
            else {
                if (currentSegment !== null) {
                    ret.push(currentSegment);
                    // yield currentSegment;
                    currentSegment = null;
                }
            }
        }

        if (currentSegment !== null) {
            ret.push(currentSegment);
            // yield currentSegment;
        }
        return ret;
    }

    GetArea(segment: IEndGunSegment, endGunThrowMetres: number): EndGunAreaContainer {
        const egArea = new EndGunAreaContainer();

        egArea.CenterLatitude = this.problem.PivotCentre.y;
        egArea.CenterLongitude = this.problem.PivotCentre.x;

        for (let i = segment.iOnAngle; i <= segment.iOffAngle; i++) {
            var p1 = this.problem.Model.EndOfEndBoom(
                this.problem.GetTheta(i),
                this.alphas[i - this.iPivotAngleMin]
            );
            
            var p2 = this.problem.Model.EndOfEndGun(
                this.problem.GetTheta(i),
                this.alphas[i - this.iPivotAngleMin],
                endGunThrowMetres);
            
            egArea.positions.push({
                start: p1,
                end: p2
            })

        }
        
        return egArea;
    }
}

export interface IEndGunSegment {
    iOnAngle: number;
    iOffAngle: number;
}

export interface IEndGunSegmentWithType {
    iOnAngle: number;
    iOffAngle: number;
    isCircle: boolean;
}

export interface IEndGunSolution {
    segments: IEndGunSegmentWithType[];
    throwMeters: number;
}