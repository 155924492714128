import { Button, Card, Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import { DropTypes, IPackage, NozzleClipTypes, OutletValveTypes, RegulatorPressures, RegulatorTypes, ThreadTypes, idxSprinkler } from "rdptypes/project/ISprinklers";
import { ISystemBase, SprinklerManufacturerTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useContext } from "react";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { RegsAsNeededOptionKometOk, RegsAsNeededOptionNelsonOk } from "roedata/roe_migration/Valids.Valids";
import { sprinklersPrefix } from ".";
import DevSettingsCtx from "../../../../db/DevSettingsCtx";
import { getImgSource } from "../../../../images/ImagePathHelper";
import { pageStyles } from "../../styles";
import FittingOptions from "./Drops/FittingOptions";
import HoseDropOptions from "./Drops/HoseDropOptions";
import RigidDropOptions from "./Drops/RigidDropOptions";
import PlateOptions from "./Plates/PlateOptions";
import { PlatesRequired } from "./helpers/Rules";
import { SpacingEnumToDouble, descriptionFromSpacingClass, getDropTypeLabel, getNozzleClipTypeLabel, getPackageTypeLabel, getRegulatorPressureLabel, getRegulatorTypeOptions, getThreadTypesLabel, getValveTypeName } from "./helpers/SprinklerHelper";
import { ISprinklerValidator } from "./sprinklerValidator/interfaces";

interface Props {
    system: ISystemBase;
    handleTooltipOpen: (src?: string) => void;
    handleTooltipClose: () => void;

    sprinklerValidator: ISprinklerValidator;
    side: SideEnum;
    package: IPackage;

    index: number;
};

export interface SprinklerPackage {
    package: IPackage;  
    isFlexPackage: boolean;
}

const SprinklerPackageComponent: FC<Props> = (props) => {
    const packageValidator = props.sprinklerValidator.getSide(props.side).packages[props.index];
    const [ editingPackageEnd, setEditingPackageEnd ] = React.useState<string | undefined>(undefined);
    React.useEffect(() => {
        setEditingPackageEnd(packageValidator.endingLocation.value > 0 ? `${packageValidator.endingLocation.value}` : undefined)
    }, [packageValidator.endingLocation.value])
    const lastRenderEditingPackageEnd = React.useRef(editingPackageEnd);
     // TODO use this for automatically focussing on package end
    const justEnabledPackageEnd = lastRenderEditingPackageEnd === undefined && editingPackageEnd !== undefined;
    lastRenderEditingPackageEnd.current = editingPackageEnd;

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};

    const sprinklerPackage = props.package;

    const isKomet = props.system.SprinklerProperties.SprinklerEngineCode === SprinklerManufacturerTypes.Komet;
    const settings = useContext(DevSettingsCtx);
    const displaySettings = settings.dealerSettings.display.current;

    const nozzleClipOptions = packageValidator.nozzleClip.allowableValues;

    var nozzleClipImgSourceId = props.system.SprinklerProperties.SprinklerEngineCode === SprinklerManufacturerTypes.Nelson ? "NelsonNozzleClip" :
    props.system.SprinklerProperties.SprinklerEngineCode === SprinklerManufacturerTypes.Komet ? "KometNozzleClip" : "SenningerNozzleClip";
    
    const availableDropTypes = packageValidator.drop.allowableValues;
    const showSecondNozzleGPM = (packageValidator.nozzleClip.value ?? NozzleClipTypes.None) !== NozzleClipTypes.None;
    const showThirdNozzleGPM = (packageValidator.nozzleClip.value ?? NozzleClipTypes.None) === NozzleClipTypes.Triple;

    return (
        <>
            <Stack direction="column" style={{ paddingTop: 30}}>        
                {
                    props.side === SideEnum.Flex && (
                        <Button
                            sx={{ maxWidth: 300, marginBottom: 3 }}
                            disabled={!props.sprinklerValidator.copyFromFlanged.package[props.index].canCopy}
                            onClick={props.sprinklerValidator.copyFromFlanged.package[props.index].copy}
                        >
                            {t("roe.sprinklers.copy-from-flanged-side-package")} {(props.index + 1)}
                        </Button>
                    )
                }      
                <Stack direction="row">
                    <FormControlLabel control={<Checkbox 
                        style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                        checked={editingPackageEnd !== undefined}
                        onChange={(ev) => {
                            if (ev.target.checked) {
                                setEditingPackageEnd("");
                            } else {
                                packageValidator.endingLocation.set(0);
                                setEditingPackageEnd(undefined);
                            }
                        }}
                    />} style={{fontSize: 20}} label={`This package ends before the end of the system`} />
                    { editingPackageEnd !== undefined && <TextField
                            style={{fontSize: 20}}
                            InputLabelProps={{sx:{fontSize: 20}, shrink: true}}
                            variant="standard"
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">after</InputAdornment>,
                                endAdornment: <InputAdornment position="end">{t("feet")}</InputAdornment>
                            }}
                            value={editingPackageEnd}
                            onChange={(ev) => setEditingPackageEnd(ev.target.value)}
                            onBlur={(ev) => {
                                const v = parseFloat(editingPackageEnd);
                                if (!(isNaN(v) || !isFinite(v) || v <= 0)) {
                                    packageValidator.endingLocation.set(v);
                                    setEditingPackageEnd(`${v}`);
                                } else {
                                    setEditingPackageEnd("");
                                }
                            }}
                        /> }

                </Stack>
                

                <>
                
                <Card style={{ marginBottom: 20, marginRight: 20, marginTop: 20, padding: 10, maxWidth: 900 }} variant={"outlined"}>
                    <Typography sx={pageStyles.cardTitleStyle}>Sprinklers</Typography>
                    <Stack direction="row">
                        <Stack direction="column" style={{width: 500, marginLeft: 10, marginRight: 10}}>
                            <FormControl variant="standard"  fullWidth error={packageValidator.packageType.isError} >
                                <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.package-type")}</InputLabel>
                                <Select
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (selected === -1) return "Select";
                                        else return getPackageTypeLabel(selected, t);
                                    }}                                
                                    value={packageValidator.packageType.value ?? -1}
                                    onChange={(ev) => {
                                        packageValidator.packageType.set(ev.target.value as idxSprinkler);
                                    }} >
                                    {
                                        packageValidator.packageType.allowableValues.map((key) => 
                                            <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("Sprinklers.PackageType", key.toString()))} 
                                            onMouseLeave={props.handleTooltipClose} key={key} value={key}>{getPackageTypeLabel(key, t)}</MenuItem>) 
                                    }
                                </Select>
                            </FormControl>
                            {
                                props.system.SprinklerProperties && props.system.SprinklerProperties!.SprinklerEngineCode === SprinklerManufacturerTypes.Nelson &&
                                <>
                                    <FormControlLabel onMouseEnter={() => props.handleTooltipOpen(getImgSource("UseNelson3030"))} onMouseLeave={props.handleTooltipClose}
                                     style={{marginTop: 10}} control={<Checkbox 
                                        style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                                        disabled={packageValidator.packageType.value === idxSprinkler.NelsonO3030OrbitorFX}
                                        checked={packageValidator.useNelson3030.value ?? false}
                                        onChange={(ev) => packageValidator.useNelson3030.set(ev.target.checked)}
                                    />} label={`Use Nelson 3030 Series`} />
                                    <FormControlLabel onMouseEnter={() => props.handleTooltipOpen(getImgSource("UseNelsonAD3030MT"))} onMouseLeave={props.handleTooltipClose}
                                        control={<Checkbox 
                                        style={{padding: 0, paddingLeft: 10, paddingRight: 5}}
                                        disabled={
                                            (
                                                packageValidator.packageType.value !== idxSprinkler.NelsonA3000Accelerator && 
                                                packageValidator.packageType.value !== idxSprinkler.NelsonD3000Spray
                                            ) || !packageValidator.useNelson3030.value
                                        }
                                        checked={packageValidator.useNelsonAD3030MT.value ?? false}
                                        onChange={(ev) =>packageValidator.useNelsonAD3030MT.set(ev.target.checked)}
                                    />} label={`Use Nelson A/D3030MT Series`} />
                                </>
                            }

                        </Stack>
                    </Stack>   
                </Card>
                    
                <Card style={{ marginBottom: 20, marginRight: 20, padding: 10, maxWidth: 900 }} variant={"outlined"}>
                    <Typography sx={pageStyles.cardTitleStyle}>Regulators</Typography>
                    <Stack direction="row" style={{width: 400, marginLeft: 10, marginRight: 10}}>
                        <FormControl variant="standard"  fullWidth disabled={!packageValidator.packageType.value} error={packageValidator.packageType.value && packageValidator.regulators.regulatorType.isError}>
                            <InputLabel shrink={true} sx={formInputLabelStyle}>{t(sprinklersPrefix + "package.regulator-type")}</InputLabel>
                            <Select
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected) return "Select";
                                    else return getRegulatorTypeOptions(selected, t);
                                }}                    
                                value={packageValidator.regulators.regulatorType.value || ""}
                                onChange={(ev) => packageValidator.regulators.regulatorType.set(ev.target.value as RegulatorTypes)}
                            >
                                {
                                    packageValidator.regulators.regulatorType.allowableValues.map(key => 
                                        <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("Regulator.RegulatorType", key.toString()))} onMouseLeave={props.handleTooltipClose}
                                        key={key} value={key}>{getRegulatorTypeOptions(key, t)}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Stack> 
                    {
                        ((packageValidator.regulators.regulatorType.value ?? RegulatorTypes.None) !== RegulatorTypes.None) && (
                            <>
                                <Stack direction="row" style={{marginLeft: 10, marginRight: 10, gap: 20, maxWidth: 400}}>
                                    <FormControl variant="standard"  fullWidth style={{marginTop: 10}} 
                                        disabled={(packageValidator.regulators.regulatorType.value ?? RegulatorTypes.None) === RegulatorTypes.None} 
                                        error={packageValidator.regulators.pressure.isError}
                                    >
                                        <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.regulator-pressure")}</InputLabel>
                                        <Select
                                            displayEmpty
                                            renderValue={(selected) => {
                                                if (!selected) return "Select";
                                                else return getRegulatorPressureLabel(selected, displaySettings);
                                            }}
                                            value={packageValidator.regulators.pressure.value || ""}
                                            onChange={(ev) => packageValidator.regulators.pressure.set(ev.target.value as RegulatorPressures)}
                                        >
                                            {
                                                packageValidator.regulators.pressure.allowableValues.map((key) => 
                                                    <MenuItem key={key} value={key}>{getRegulatorPressureLabel(key, displaySettings)}</MenuItem>) 
                                            }
                                        </Select>
                                    </FormControl>
                                    {
                                        props.system.SprinklerProperties.SprinklerEngineCode === SprinklerManufacturerTypes.Nelson &&
                                        <FormControl variant="standard"  fullWidth style={{marginTop: 10}} 
                                            disabled={
                                                (packageValidator.regulators.regulatorType.value ?? RegulatorTypes.None) === RegulatorTypes.None ||
                                                packageValidator.regulators.thread.allowableValues.length === 0
                                            } 
                                            error={packageValidator.regulators.thread.isError}>
                                            <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.thread-type")}</InputLabel>
                                            <Select           
                                                displayEmpty
                                                renderValue={(selected) => {
                                                    if (!selected) return "Select";
                                                    else return getThreadTypesLabel(selected);
                                                }}                 
                                                value={packageValidator.regulators.thread.value || ""}
                                                onChange={(ev) => packageValidator.regulators.thread.set(ev.target.value as ThreadTypes)}
                                            >
                                                {
                                                    packageValidator.regulators.thread.allowableValues.map((key) => 
                                                        <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("Regulator.Thread", key.toString()))} 
                                                        onMouseLeave={props.handleTooltipClose} key={key} value={key}>{getThreadTypesLabel(key)}</MenuItem>) 
                                                }
                                            </Select>
                                        </FormControl>
                                    }
                                </Stack>
                                <Stack direction="row" style={{width: 400, marginLeft: 10, marginRight: 10}}>
                                    <FormControlLabel 
                                        style={{
                                            width: '100%', 
                                            marginTop: 10, 
                                            marginLeft: 10, 
                                            marginRight: 10, 
                                            maxHeight: 48,
                                            color: packageValidator.regulators.asNeeded.isError 
                                                    ? "#d32f2f" 
                                                    : undefined
                                        }} 
                                        control={<Checkbox
                                            style={{
                                                padding: 0, 
                                                paddingLeft: 0, 
                                                paddingRight: 5,
                                                marginLeft: 0,
                                                backgroundColor: packageValidator.regulators.asNeeded.isError ? '#fdeded' : 'unset'}
                                            }
                                            checked={packageValidator.regulators.asNeeded.value || false}
                                            disabled={
                                                isKomet 
                                                    ? !RegsAsNeededOptionKometOk(true, {
                                                        RegulatorType: packageValidator.regulators.regulatorType.value,
                                                        HasSAC: HasSwingArmCorner(props.system),
                                                        DeviceType: sprinklerPackage.Device,
                                                        RegulatorPressure: packageValidator.regulators.pressure.value,
                                                    }) 
                                                    : !RegsAsNeededOptionNelsonOk(true, {
                                                        RegulatorType: packageValidator.regulators.regulatorType.value,
                                                        HasSAC: HasSwingArmCorner(props.system),
                                                        DeviceType: sprinklerPackage.Device,
                                                        RegulatorPressure: packageValidator.regulators.pressure.value,
                                                    })
                                            }
                                            onChange={(ev) => packageValidator.regulators.asNeeded.set(ev.target.checked)}
                                        />}
                                        label={t(sprinklersPrefix + "package.use-only-when-needed")} />
                                    
                                </Stack>
                            </>
                        )
                    }
                </Card>
                <Card style={{ marginBottom: 20, marginRight: 20, padding: 10, maxWidth: 900 }} variant={"outlined"}>
                    <Typography sx={pageStyles.cardTitleStyle}>Additional Nozzle Sets</Typography>
                    <Stack direction="row" style={{marginLeft: 10, marginRight: 10}}>
                            {
                                <FormControl variant="standard"  
                                style={{width: 300}}
                                    disabled={packageValidator.nozzleClip.allowableValues.length === 0} 
                                    error={packageValidator.nozzleClip.isError}>
                                    <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.nozzle-clip")}</InputLabel>
                                    <Select
                                        displayEmpty
                                        renderValue={(selected) => {
                                            if (!selected) return "Select";
                                            else return getNozzleClipTypeLabel(selected);
                                        }}                            
                                        value={packageValidator.nozzleClip.value || ""}
                                        onChange={(ev) => packageValidator.nozzleClip.set(ev.target.value as NozzleClipTypes)}
                                    >
                                        {
                                            packageValidator.nozzleClip.allowableValues.map((key) => 
                                                <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource(nozzleClipImgSourceId, key.toString()))} 
                                                onMouseLeave={props.handleTooltipClose} key={key} value={key}>{getNozzleClipTypeLabel(key)}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            }
                            {
                                showSecondNozzleGPM &&
                                <TextField
                                    error={packageValidator.secondNozzleGPM.isError}
                                    style={{width: 200, marginLeft: 10}}
                                    disabled={packageValidator.nozzleClip.value === NozzleClipTypes.None || nozzleClipOptions.length === 0}
                                    InputLabelProps={{sx:formInputLabelStyle, shrink: true}}
                                    variant="standard"
                                    label={t(sprinklersPrefix + "package.second-nozzle-gpm")}
                                    type="number"
                                    value={packageValidator.secondNozzleGPM.value}
                                    onChange={(ev) => packageValidator.secondNozzleGPM.set(Number(ev.target.value))}
                                />
                            }
                            {
                                showThirdNozzleGPM &&
                                <TextField
                                    error={packageValidator.thirdNozzleGPM.isError}
                                    style={{width: 200, marginLeft: 10}}
                                    InputLabelProps={{sx:formInputLabelStyle, shrink: true}}
                                    variant="standard"
                                    label={t(sprinklersPrefix + "package.third-nozzle-gpm")}
                                    type="number"
                                    value={packageValidator.thirdNozzleGPM.value}
                                    onChange={(ev) => packageValidator.thirdNozzleGPM.set(Number(ev.target.value))}
                                />
                            }
                        </Stack>
                </Card>

                <Card style={{ marginBottom: 20, marginRight: 20, padding: 10, maxWidth: 1200 }} variant={"outlined"}>
                    <Typography sx={pageStyles.cardTitleStyle}>Drops</Typography>
                    <Stack direction="row" style={{maxWidth: 900, justifyContent: 'space-evenly'}}>    
                            <FormControl variant="standard"  fullWidth style={{ width: '100%', marginLeft: 10, marginRight: 10 }} 
                            error={packageValidator.drop.isError}>
                                <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.drop-type")}</InputLabel>
                                <Select
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) return "Select";
                                        else return getDropTypeLabel(selected);
                                    }}                  
                                    value={packageValidator.drop.value || ""}
                                    onChange={(ev) => packageValidator.drop.set(ev.target.value as DropTypes)}
                                >
                                    {
                                        availableDropTypes.map((key) => 
                                            <MenuItem key={key} value={key}>{getDropTypeLabel(key)}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            {/*<FormControlLabel style={{width: '100%', marginLeft: 10, marginRight: 10, maxHeight: 48}} control={<Checkbox
                                style={{padding: 0, paddingLeft: 0, paddingRight: 5, marginLeft: 0 }}
                                checked={sprinklerPackage.NormalSpacing}
                                disabled={dropSpacingDisabled}
                                onChange={(ev) => { updatePackage({...sprinklerPackage, NormalSpacing: ev.target.checked})}}
                            />}
                            label={t(sprinklersPrefix + "package.drop-spacing")} />*/}
                    
                        
                    </Stack>
                    {
                        packageValidator.drop.value === DropTypes.None &&
                        <FittingOptions package={sprinklerPackage} handleTooltipOpen={props.handleTooltipOpen} handleTooltipClose={props.handleTooltipClose}
                            system={props.system} flexedSide={props.side === SideEnum.Flex} 
                            packageValidator={packageValidator}
                        />          
                    }
                    {
                        packageValidator.drop.value === DropTypes.Rigid &&
                        <RigidDropOptions package={sprinklerPackage} handleTooltipOpen={props.handleTooltipOpen} handleTooltipClose={props.handleTooltipClose}
                            system={props.system} isFlex={props.side === SideEnum.Flex}
                            packageValidator={packageValidator}
                        />
                    }
                    {
                        packageValidator.drop.value === DropTypes.Hose &&
                        <HoseDropOptions package={sprinklerPackage} handleTooltipOpen={props.handleTooltipOpen} handleTooltipClose={props.handleTooltipClose}
                            system={props.system} isFlex={props.side === SideEnum.Flex}
                            packageValidator={packageValidator}
                        />
                    }
                </Card> 
                {
                    packageValidator.valve.showSection && (
                        <Card style={{ marginBottom: 20, marginRight: 20, padding: 10, maxWidth: 900 }} variant={"outlined"}>
                            <Typography sx={pageStyles.cardTitleStyle}>Valves</Typography>
                            
                            <Stack direction="column" style={{width: 400, marginLeft: 10, marginRight: 10}}>
                                <FormControl variant="standard"  fullWidth style={{marginTop: 10}} error={packageValidator.valve.isError}>
                                    <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + "package.valve-type")}</InputLabel>
                                    <Select    
                                        displayEmpty
                                        renderValue={(selected) => {
                                            if (!selected) return "Select";
                                            else return getValveTypeName(selected, displaySettings);
                                        }}                               
                                        value={packageValidator.valve.value || ""}
                                        onChange={(ev) => packageValidator.valve.set(ev.target.value as OutletValveTypes)}
                                    >
                                        {
                                            packageValidator.valve.allowableValues.map((key) => 
                                                <MenuItem onMouseEnter={() => props.handleTooltipOpen(getImgSource("ValveType", key.toString()))} 
                                                onMouseLeave={props.handleTooltipClose} key={key} value={key}>{getValveTypeName(key as OutletValveTypes, displaySettings)}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Card> 
                    )

                }                        
                    {
                        PlatesRequired(sprinklerPackage.Device) && (
                            <Card style={{ marginBottom: 20, marginRight: 20, padding: 10, maxWidth: 900 }} variant={"outlined"}>
                                <Typography sx={pageStyles.cardTitleStyle}>Plate</Typography>
                                <PlateOptions 
                                    handleTooltipOpen={props.handleTooltipOpen} 
                                    handleTooltipClose={props.handleTooltipClose} 
                                    package={sprinklerPackage} 
                                    system={props.system}
                                    packageValidator={packageValidator}
                                />
                            </Card> 
                        )
                    }
                    
                    {
                        !packageValidator.spacing.isDisabled && (
                            <Card style={{ marginBottom: 20, marginRight: 20, padding: 10, maxWidth: 900 }} variant={"outlined"}>
                                <Typography sx={pageStyles.cardTitleStyle}>{t(sprinklersPrefix + "package.sprinkler-spacing")}</Typography>
                                <Stack direction="row"> 
                                    <Stack direction="column" style={{width: 200, marginLeft: 10, marginRight: 10}}> 
                                    <FormControl variant="standard"  fullWidth  error={packageValidator.spacing.isError}>
                                            <InputLabel shrink={true} sx={formInputLabelStyle} >{t(sprinklersPrefix + (displaySettings.lengths === "feet" ? "package.spacing-feet" : "package.spacing-meters"))}</InputLabel>
                                            <Select
                                                displayEmpty
                                                renderValue={(selected) => {
                                                    if (!selected) return "Select";
        
                                                    let opts = packageValidator.spacing.allowableSpacingClassValues;
                                                    let str = "";
                                                    opts.forEach((opt) => {
                                                        if (SpacingEnumToDouble(opt.value) === selected){
                                                            str = descriptionFromSpacingClass(opt, displaySettings.lengths === "feet" ? "feet" : "meters");
                                                        }
                                                    });
                                                    return str;
                                                }}
                                                value={packageValidator.spacing.value ?? 0}
                                                onChange={(ev) => packageValidator.spacing.set(Number(ev.target.value))}
                                            >
                                                {
                                                    packageValidator.spacing.allowableSpacingClassValues.map((key) =>  
                                                        <MenuItem key={key.value} value={SpacingEnumToDouble(key.value)}>
                                                            {descriptionFromSpacingClass(key, displaySettings.lengths === "feet" ? "feet" : "meters")}
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                        </Stack> 
                                </Stack>   
                            </Card>
                        )
                    }
                </>
            </Stack>
        </>
    );

    
};

export default SprinklerPackageComponent;