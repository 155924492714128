import { Card, CardActionArea, CardContent, CardMedia, Stack, Typography } from "@mui/material";
import IProductLineChoice from "rdptypes/roe/IProductLineChoice";
import * as React from "react";
import { FC, useContext } from "react";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import { formatProductLine } from "../../../../docGeneration/DocumentGenerationHelpers";
import { ProductLine } from "../../../../model/project/ISystem";

interface Props {
    cmp: IProductLineChoice,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
};

const e2CardImage = new URL(
    'e2CardImage.jpg?as=webp&width=345',
    import.meta.url
  );

  const e3CardImage = new URL(
      'e3CardImage.jpg?as=webp&width=345',
      import.meta.url
    );

  const edmpCardImage = new URL(
      'edmpCardImage.jpg?as=webp&width=345',
      import.meta.url
    );

const ProductLineChoiceRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const setProductLine = (newProductLine: ProductLine) => {
        props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
            props.layoutId,
            props.systemId,
            "productLine",
            newProductLine,
            authState));
    };

    return (
        <>
            <Stack direction="row" spacing={2}>
                <Card sx={{
                    maxWidth: 345,
                    backgroundColor: system.productLine === ProductLine.e3 ? "lightblue" : undefined
                }}
                    onClick={() => setProductLine(ProductLine.e3)}
                >
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image={e3CardImage.toString()}
                            alt={"Photograph of " + formatProductLine(ProductLine.e3)}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {formatProductLine(ProductLine.e3)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                The latest generation of the Reinke Electrogator, an industry standard since 1968.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>

                <Card sx={{
                    maxWidth: 345,
                    backgroundColor: system.productLine === ProductLine.e2 ? "lightblue" : undefined
                }}
                    onClick={() => setProductLine(ProductLine.e2)}
                >
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image={e2CardImage.toString()}
                            alt={"Photograph of " + formatProductLine(ProductLine.e2)}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {formatProductLine(ProductLine.e2)} (Legacy)
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Our previous generation {formatProductLine(ProductLine.e2)} products are still available in selected markets.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>

                <Card sx={{
                    maxWidth: 345,
                    backgroundColor: system.productLine === ProductLine.edmp ? "lightblue" : undefined
                }}
                    onClick={() => setProductLine(ProductLine.edmp)}
                >
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image={edmpCardImage.toString()}
                            alt={"Photograph of " + formatProductLine(ProductLine.e2)}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {formatProductLine(ProductLine.e2)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                A single-span system powered by an engine. Ideal for small fields and areas where power access is limited.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Stack>
        </>
    );
};

export default ProductLineChoiceRenderer;