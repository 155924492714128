import {
    EndTowerPositioningTypes,
    PanelModels,
    IRC10 as RC10,
    RC10FacePlateUpgrade,
    RC10ModemBaseModels,
    RC10MountingLocations,
    RC10Types,
    SystemCategories
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/poSchema";
import CSection2 from "./CSection2";
import { RC10PanelModelsToPanelModel } from "./CommonFunctions";
import PartsAssembler from "./PartsAssembler";
import { ResultPanelModel } from "./Valids.Valids";
export class RC10Class extends CSection2 {
    public WritePO = (PO: PartsAssembler) => {
        let sys = this.Quote.System;
        let IsRetro: boolean = !(this.Quote.ControlPanelClass.FieldSet.DataValid());
        let IsMaxi: boolean;
        if (this.DataValid()) {
            let r = sys.RC10;
            IsMaxi = r.SystemCategory === SystemCategories.LateralMoveMaxi;
            let hasRPMStandardWithAutoReverse = r.HasRPMStandardWithAutoReverse;
            let controlPanelOption = ResultPanelModel(RC10PanelModelsToPanelModel(r.RC10ControlPanel), r.FacePlateOption);
            switch (r.FacePlateOption) {
                case RC10FacePlateUpgrade.None:
                    break;
                case RC10FacePlateUpgrade.RPMStandardWithAutoReverse:
                    PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160804", 1); // FACE PLATE-RPM STD TAR-RETRO
                    hasRPMStandardWithAutoReverse = true;
                    break;
                case RC10FacePlateUpgrade.AdvancedFaceplate:
                    PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160802", 1); // FACE PLATE-RPM ADV-RETRO
                    break;
                case RC10FacePlateUpgrade.AdvancedPlusFaceplate:
                    PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "161014", 1); // FACEPLATE ADV + 4.3" RETRO
                    break;
                case RC10FacePlateUpgrade.ConnectFacePlate:
                    PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "161015", 1); // FACEPLATE CONNECT RETRO
                    break;
                case RC10FacePlateUpgrade.TouchscreenFaceplate:
                    PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160809", 1); // FACE PLATE RPM PREF TOUCH RET
                    break;
            }
            switch (controlPanelOption) {
                case PanelModels.RPMBasic:
                    switch (r.MountingLocation) {
                        case RC10MountingLocations.PivotCenter:
                            break;
                        case RC10MountingLocations.OuterTower:
                            this.OuterTowerPositioningDeviceRC10i(PO);
                            if (r.StartRelayKit) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350880", 1); // RC10I START RELAY OPTION
                                if ((!(r.LowPressureShutdownSwitch)) && (r.SwingArmCorner) && (!(r.PowerAutoRestart))) {
                                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350891", 1); // RC10I START RELAY KIT RPM SAC
                                }
                            }
                            if (r.SystemCategory === SystemCategories.CenterPivot) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351539", 1); // RCOM IND SPAN MOUNT KIT RC10I
                            }
                            if (r.Rain) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350882", 1); // RC10I RAIN GAUGE-SPAN MOUNT
                            }
                            if ((r.Rain || r.Humidity)) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350893", 1); // KIT-WEATHER STN-MCP-RC10I
                            }
                            break;
                    }
                    if (r.AutoRestartPanelKit) {
                        PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160912", 1); // RESTART-RPM STD-RETRO
                    }
                    this.ModemBaseModelRC10i(PO, r.FacePlateOption);
                    this.MonitorsRC10i(PO);
                    this.ControlsRC10i(PO);
                    this.PressureDevicesRC10i(PO);
                    this.PowerRC10i(PO);
                    break;
                case PanelModels.RPMStandard:
                    switch (r.MountingLocation) {
                        case RC10MountingLocations.PivotCenter:
                            if (r.SystemCategory === SystemCategories.LateralMoveMaxi) {
                                PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "351541", 1); // RC10I LATERAL MCP MOUNT KIT
                            }
                            if (r.StartRelayKit && !(r.SwingArmCorner)) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350880", 1); // RC10I START RELAY OPTION
                            }
                            if (r.StartRelayKit && r.SwingArmCorner) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350880", 1); // RC10I START RELAY OPTION
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350891", 1); // RC10I START RELAY KIT RPM SAC
                            }
                            if (hasRPMStandardWithAutoReverse && r.FacePlateOption === RC10FacePlateUpgrade.TouchscreenFaceplate) {
                                PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160809", 1); // FACE PLATE RPM PREF TOUCH RET
                            }
                            this.PivotCenterStd_StdwAutoReverse_Advanced(PO);
                            break;
                        case RC10MountingLocations.OuterTower:
                            this.OuterTowerPositioningDeviceRC10i(PO);
                            if (r.StartRelayKit) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350880", 1); // RC10I START RELAY OPTION
                                if ((!(r.LowPressureShutdownSwitch)) && (r.SwingArmCorner) && (!(r.PowerAutoRestart))) {
                                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350891", 1); // RC10I START RELAY KIT RPM SAC
                                }
                            }
                            if (r.SystemCategory === SystemCategories.CenterPivot) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351539", 1); // RCOM IND SPAN MOUNT KIT RC10I
                            }
                            if (r.Rain) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350882", 1); // RC10I RAIN GAUGE-SPAN MOUNT
                            }
                            if ((r.Rain || r.Humidity)) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350893", 1); // KIT-WEATHER STN-MCP-RC10I
                            }
                            break;
                    }
                    if (r.SystemCategory === SystemCategories.LateralMoveMaxi) {
                        PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350870", 1); // REINCLOUD IND GPS OPTION RC10I
                    }
                    if (hasRPMStandardWithAutoReverse && IsRetro && r.FacePlateOption === RC10FacePlateUpgrade.TouchscreenFaceplate) {
                        PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160809", 1); // FACE PLATE RPM PREF TOUCH RET
                    }
                    this.AutoRestartPanelKit_Std_StdwAutoReverse(PO);
                    this.ModemBaseModelRC10i(PO, r.FacePlateOption);
                    this.MonitorsRC10i(PO);
                    this.ControlsRC10i(PO);
                    this.PressureDevicesRC10i(PO);
                    this.PowerRC10i(PO);
                    break;
                case PanelModels.RPMAdvanced:
                    switch (r.MountingLocation) {
                        case RC10MountingLocations.PivotCenter:
                            if (r.SystemCategory === SystemCategories.LateralMoveMaxi) {
                                PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "351541", 1); // RC10I LATERAL MCP MOUNT KIT
                            }
                            else {
                                if (IsRetro && r.EndOfSystemGPSPanelKit) {
                                    PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160970", 1); // GPS CDW MCP KIT- RETRO
                                }
                            }
                            if (r.StartRelayKit) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350880", 1); // RC10I START RELAY OPTION
                            }
                            if (!(r.PositioningDeviceOption === EndTowerPositioningTypes.None)) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350892", 1); // RCOM IND NAV GPS CBL MCP-RC10I
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350895", 1); // KIT-INSTALL EXISTING EOS CABLE
                            }
                            switch (r.FacePlateOption) {
                                case RC10FacePlateUpgrade.TouchscreenFaceplate:
                                    PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160809", 1); // FACE PLATE RPM PREF TOUCH RET
                                    break;
                            }
                            this.PivotCenterStd_StdwAutoReverse_Advanced(PO);
                            break;
                        case RC10MountingLocations.OuterTower:
                            if (r.StartRelayKit) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350880", 1); // RC10I START RELAY OPTION
                            }
                            this.OuterTowerPositioningDeviceRC10i(PO);
                            if (r.SystemCategory === SystemCategories.CenterPivot) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351539", 1); // RCOM IND SPAN MOUNT KIT RC10I
                            }
                            if (r.Rain) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350882", 1); // RC10I RAIN GAUGE-SPAN MOUNT
                            }
                            if ((r.Rain || r.Humidity)) {
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350893", 1); // KIT-WEATHER STN-MCP-RC10I
                            }
                            break;
                    }
                    if (r.SystemCategory === SystemCategories.LateralMoveMaxi) {
                        PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350870", 1); // REINCLOUD IND GPS OPTION RC10I
                    }
                    if (IsRetro) {
                        if (r.FacePlateOption === RC10FacePlateUpgrade.TouchscreenFaceplate) {
                            PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160809", 1); // FACE PLATE RPM PREF TOUCH RET
                        }
                    }
                    switch (r.FacePlateOption) {
                        case RC10FacePlateUpgrade.TouchscreenFaceplate:
                            PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160809", 1); // FACE PLATE RPM PREF TOUCH RET
                            break;
                    }
                    this.ModemBaseModelRC10i(PO, r.FacePlateOption);
                    this.MonitorsRC10i(PO);
                    this.ControlsRC10i(PO);
                    this.PressureDevicesRC10i(PO);
                    this.PowerRC10i(PO);
                    if (r.AutoRestartPanelKit) {
                        PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160912", 1); // RESTART-RPM STD-RETRO
                    }
                    break;
                case PanelModels.RPMAdvancedPlus:
                    if (IsRetro) {
                        if (r.EndOfSystemGPSPanelKit) {
                            if (r.SystemCategory === SystemCategories.CenterPivot) {
                                PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160970", 1); // GPS CDW MCP KIT- RETRO
                            }
                            else {
                                PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "167025", 1); // GPS RESOLVER OPTION MAXI RETRO
                            }
                        }
                    }
                    if (IsRetro) {
                        switch (r.ModemBaseModel) {
                            case RC10ModemBaseModels.Verizon:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350834", 1); // RETRO VZN RC10DV ADV +
                                break;
                            case RC10ModemBaseModels.Att:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350830", 1); // RETRO GSM RC10DG ADV +
                                break;
                            case RC10ModemBaseModels.Satellite:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350828", 1); // RETRO SAT RC10DS ADV +
                                break;
                            case RC10ModemBaseModels.Rogers:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351007", 1); // RETRO AUS/CAN/MEX RC10D ADV+
                                break;
                            case RC10ModemBaseModels.International:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351023", 1); // RETRO A-PAC INT RC10D ADV+
                                break;
                            case RC10ModemBaseModels.International2:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351024", 1); // RETRO EUROPE INT RC10D ADV+
                                break;
                            case RC10ModemBaseModels.International3:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351070", 1);  // RETRO CASAMX INT RC10D ADV+
                                break;
                        }
                    }
                    else {
                        switch (r.ModemBaseModel) {
                            case RC10ModemBaseModels.Verizon:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "350833", 1); // VZN RC10DV ADV +
                                break;
                            case RC10ModemBaseModels.Att:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "350829", 1); // GSM RC10DG ADV +
                                break;
                            case RC10ModemBaseModels.Satellite:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "350827", 1); // SAT RC10DS ADV +
                                break;
                            case RC10ModemBaseModels.Rogers:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "351037", 1); // AUS/CAN/MEX RC10D ADV+
                                break;
                            case RC10ModemBaseModels.International:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "351031", 1); // A-PAC INT RC10D ADV+
                                break;
                            case RC10ModemBaseModels.International2:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "351032", 1); // EUROPE INT RC10D ADV+
                                break;
                            case RC10ModemBaseModels.International3:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "351071", 1);  // CASAMX INT RC10D ADV+
                                break;
                        }
                    }
                    if (IsRetro) {
                        if (r.Temperature) {
                            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350913", 1); // TEMPERATURE PROBE-RPM ADV/PREF
                        }
                    }
                    break;
                case PanelModels.RPMConnect:
                    switch (r.MountingLocation) {
                        case RC10MountingLocations.PivotCenter:
                            break;
                        case RC10MountingLocations.OuterTower:
                            break;
                    }
                    if (IsRetro) {
                        if (r.EndOfSystemGPSPanelKit) {
                            if (r.SystemCategory === SystemCategories.CenterPivot) {
                                PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160970", 1); // GPS CDW MCP KIT- RETRO
                            }
                            else {
                                PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "167025", 1); // GPS RESOLVER OPTION MAXI RETRO
                            }
                        }
                    }
                    if (IsRetro) {
                        switch (r.ModemBaseModel) {
                            case RC10ModemBaseModels.Verizon:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350834", 1); // RETRO VZN RC10DV ADV +
                                break;
                            case RC10ModemBaseModels.Att:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350830", 1); // RETRO GSM RC10DG ADV +
                                break;
                            case RC10ModemBaseModels.Satellite:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350828", 1); // RETRO SAT RC10DS ADV +
                                break;
                            case RC10ModemBaseModels.Rogers:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351007", 1); // RETRO AUS/CAN/MEX RC10D ADV+
                                break;
                            case RC10ModemBaseModels.International:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351023", 1); // RETRO A-PAC INT RC10D ADV+
                                break;
                            case RC10ModemBaseModels.International2:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351024", 1); // RETRO EUROPE INT RC10D ADV+
                                break;
                            case RC10ModemBaseModels.International3:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351070", 1);  // RETRO CASAMX INT RC10D ADV+
                                break;
                        }
                    }
                    else {
                        switch (r.ModemBaseModel) {
                            case RC10ModemBaseModels.Verizon:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "350833", 1); // VZN RC10DV ADV +
                                break;
                            case RC10ModemBaseModels.Att:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "350829", 1); // GSM RC10DG ADV +
                                break;
                            case RC10ModemBaseModels.Satellite:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "350827", 1); // SAT RC10DS ADV +
                                break;
                            case RC10ModemBaseModels.Rogers:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "351037", 1); // AUS/CAN/MEX RC10D ADV+
                                break;
                            case RC10ModemBaseModels.International:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "351031", 1); // A-PAC INT RC10D ADV+
                                break;
                            case RC10ModemBaseModels.International2:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "351032", 1); // EUROPE INT RC10D ADV+
                                break;
                            case RC10ModemBaseModels.International3:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "351071", 1);  // CASAMX INT RC10D ADV+
                                break;
                        }
                    }
                    if (IsRetro) {
                        if (r.Temperature) {
                            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350913", 1); // TEMPERATURE PROBE-RPM ADV/PREF
                        }
                    }
                    break;
                case PanelModels.RPMPreferred:
                    if (IsRetro) {
                        if (r.EndOfSystemGPSPanelKit) {
                            if (r.SystemCategory === SystemCategories.CenterPivot) {
                                PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160970", 1); // GPS CDW MCP KIT- RETRO
                            }
                            else {
                                PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "167025", 1); // GPS RESOLVER OPTION MAXI RETRO
                            }
                        }
                    }
                    if (IsRetro) {
                        switch (r.ModemBaseModel) {
                            case RC10ModemBaseModels.Verizon:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350847", 1); // RETRO VZN RC10DV PREFERRED
                                break;
                            case RC10ModemBaseModels.Att:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350843", 1); // RETRO GSM RC10DG PREFERRED
                                break;
                            case RC10ModemBaseModels.Satellite:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350841", 1); // RETRO SAT RC10DS PREFERRED
                                break;
                            case RC10ModemBaseModels.Rogers:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351038", 1); // RETRO AUS/CAN/MEX RC10D PREF
                                break;
                            case RC10ModemBaseModels.International:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351035", 1); // RC10D-PREF NZ/ASIA/ZA RETRO
                                break;
                            case RC10ModemBaseModels.International2:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351036", 1); // RETRO EU INT RC10D PREF
                                break;
                            case RC10ModemBaseModels.International3:
                                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351073", 1);  // RETRO CASAMX INT RC10D PREF
                                break;
                        }
                    }
                    else {
                        switch (r.ModemBaseModel) {
                            case RC10ModemBaseModels.Verizon:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "350846", 1); // VZN RC10DV PREFERRED
                                break;
                            case RC10ModemBaseModels.Att:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "350842", 1); // GSM RC10DG PREFERRED
                                break;
                            case RC10ModemBaseModels.Satellite:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "350840", 1); // SAT RC10DS PREFERRED
                                break;
                            case RC10ModemBaseModels.Rogers:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "351039", 1); // AUS/CAN/MEX RC10D PREFERRED
                                break;
                            case RC10ModemBaseModels.International:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "351033", 1); // RC10D-PREFERRED NZ/ASIA/ZA
                                break;
                            case RC10ModemBaseModels.International2:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "351034", 1); // EUROPE INT RC10D PREFERRED
                                break;
                            case RC10ModemBaseModels.International3:
                                PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "351072", 1);  // CASAMX INT RC10D PREFERRED
                                break;
                        }
                    }
                    if (r.Humidity) {
                        if (IsRetro) {
                            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350215", 1); // RCOM HUMIDITY RETRO RC10D
                        }
                        else {
                            PO.InsertPart(schema.groups.ControlPanel_RC10Components.groupId, "350214", 1); // REINCLOUD HUMIDITY RC10D
                        }
                    }
                    if (IsRetro) {
                        if (r.Temperature) {
                            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350913", 1); // TEMPERATURE PROBE-RPM ADV/PREF
                        }
                    }
                    break;
            }
            if (sys.RC10.FacePlateOption === RC10FacePlateUpgrade.AdvancedPlusFaceplate || sys.RC10.FacePlateOption === RC10FacePlateUpgrade.ConnectFacePlate || sys.RC10.FacePlateOption === RC10FacePlateUpgrade.TouchscreenFaceplate) {
                PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "161020", 1); // RETRO SURGE PROTECTOR
            }
        }
    }
    private AutoRestartPanelKit_Std_StdwAutoReverse = (PO: PartsAssembler) => {
        let r = this.Quote.System.RC10;
        let bRetro: boolean = !(this.Quote.ControlPanelClass.FieldSet.DataValid());
        if (r.LowPressureShutdownSwitch && bRetro) {
            if (r.AutoRestartPanelKit && r.SwingArmCorner) {
                PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160946", 1); // RESTART-RPM STD SAC-RETRO
            }
            if (r.AutoRestartPanelKit && !(r.SwingArmCorner)) {
                PO.InsertPart(schema.groups.RC10Retro_RC10RetroComponents.groupId, "160912", 1); // RESTART-RPM STD-RETRO
            }
        }
    }
    private PowerRC10i = (PO: PartsAssembler) => {
        let bRetro: boolean = !(this.Quote.ControlPanelClass.FieldSet.DataValid());
        if (this.Quote.System.RC10.PowerRelay) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350216", 1); // REINCLOUD IND POWER RELAY OPT
        }
    }
    private PivotCenterStd_StdwAutoReverse_Advanced = (PO: PartsAssembler) => {
        let r = this.Quote.System.RC10;
        if ((!(r.ModemBaseModel === RC10ModemBaseModels.Satellite))) {
            if ((r.Rain || r.Wind || r.Humidity)) {
                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350893", 1); // KIT-WEATHER STN-MCP-RC10I
            }
        }
        if ((r.Rain || r.Wind)) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350894", 1); // HARDWARE-WIND/RAIN-MCP-RC10I
        }
        if (r.SystemCategory === SystemCategories.CenterPivot && [RC10FacePlateUpgrade.AdvancedPlusFaceplate, RC10FacePlateUpgrade.ConnectFacePlate].indexOf(r.FacePlateOption) === -1) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351540", 1); // RCOM IND PIV CEN MNT KIT RC10I
        }
        if (r.Rain) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350886", 1); // RAIN GAUGE OPT-MCP-RC10I
        }
        if (r.Wind) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "353420", 1); // WIND VANE-RPM PREF
        }
    }
    private OuterTowerPositioningDeviceRC10i = (PO: PartsAssembler) => {
        if (!(this.Quote.System.RC10.PositioningDeviceOption === EndTowerPositioningTypes.GPS)) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350870", 1); // REINCLOUD IND GPS OPTION RC10I
        }
        else {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350890", 1); // RCOM IND NAV GPS CBL EOS-RC10I
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350895", 1); // KIT-INSTALL EXISTING EOS CABLE
        }
    }
    private MonitorsRC10i = (PO: PartsAssembler) => {
        if (this.Quote.System.RC10.Temperature) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350913", 1); // TEMPERATURE PROBE-RPM ADV/PREF
        }
        if (this.Quote.System.RC10.Humidity) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350212", 1); // REINCLOUD IND HUMIDITY RC10I
        }
        if (this.Quote.System.RC10.RainWindMountingKit) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350894", 1); // HARDWARE-WIND/RAIN-MCP-RC10I
        }
    }
    private ModemBaseModelRC10i = (PO: PartsAssembler, Panel: RC10FacePlateUpgrade) => {
        if ([RC10FacePlateUpgrade.ConnectFacePlate, RC10FacePlateUpgrade.AdvancedPlusFaceplate].indexOf(Panel) !== -1) {
            switch (this.Quote.System.RC10.ModemBaseModel) {
                case RC10ModemBaseModels.Verizon:
                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350834", 1); // RETRO VZN RC10DV ADV +
                    break;
                case RC10ModemBaseModels.Att:
                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350830", 1); // RETRO GSM RC10DG ADV +
                    break;
                case RC10ModemBaseModels.Satellite:
                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350828", 1); // RETRO SAT RC10DS ADV +
                    break;
                case RC10ModemBaseModels.Rogers:
                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350832", 1); // RETRO GSM INTL RC10DGW ADV+
                    break;
                case RC10ModemBaseModels.International:
                    break;
                case RC10ModemBaseModels.International2:
                    break;
                case RC10ModemBaseModels.International3:
                    break;
            }
        }
        else {
            switch (this.Quote.System.RC10.ModemBaseModel) {
                case RC10ModemBaseModels.Verizon:
                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350866", 1); // REINCLOUD IND VZN RC10IV
                    break;
                case RC10ModemBaseModels.Att:
                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350862", 1); // REINCLOUD IND GSM RC10IG
                    break;
                case RC10ModemBaseModels.Satellite:
                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350860", 1); // REINCLOUD IND SAT RC10IS
                    break;
                case RC10ModemBaseModels.Rogers:
                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350878", 1); // REINCLD IND AUS/CAN/MEX RC10I
                    break;
                case RC10ModemBaseModels.International:
                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351041", 1); // REINCLOUD IND A-PAC RC10I
                    break;
                case RC10ModemBaseModels.International2:
                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351042", 1); // REINCLOUD IND EUROPE RC10I
                    break;
                case RC10ModemBaseModels.International3:
                    PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "351043", 1);  // REINCLOUD IND CASAMX RC10I
                    break;
            }
        }
    }
    private ControlsRC10i = (PO: PartsAssembler) => {
        if (!(this.SuppressPartNumber(".EndGunControl"))) {
            if (this.Quote.System.RC10.EndGunControl) {
                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350884", 1); // RC10I END GUN/AUX RELAY OPTION
            }
        }
        if (!(this.SuppressPartNumber(".AuxControl"))) {
            if (this.Quote.System.RC10.AuxControl) {
                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350884", 1); // RC10I END GUN/AUX RELAY OPTION
            }
        }
        if (!(this.SuppressPartNumber(".SpeedControl"))) {
            if (this.Quote.System.RC10.SpeedControl) {
                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350881", 1); // RC10I SPEED RELAY OPTION
            }
        }
        if (!(this.SuppressPartNumber(".PumpControl"))) {
            if (this.Quote.System.RC10.PumpControl) {
                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350885", 1); // RC10I PUMP RELAY OPTION
            }
            if (this.Quote.System.RC10.ChemControl) {
                PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350883", 1); // RC10I CHEM RELAY OPTION
            }
        }
    }
    private PressureDevicesRC10i = (PO: PartsAssembler) => {
        if (this.Quote.System.RC10.PressureTransducer025) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350876", 1); // REINCLOUD 25 PSI TRANS RC10I
        }
        if (this.Quote.System.RC10.PressureTransducer0200) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350874", 1); // REINCLOUD IND PSI TRANS RC10I
        }
        if (this.Quote.System.RC10.PressureSwitch45Psi) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350872", 1); // REINCLOUD IND PSI SW RC10I
        }
        if (this.Quote.System.RC10.PressureSwitch20Psi) {
            PO.InsertPart(schema.groups.RC10_RC10Components.groupId, "350852", 1); // REINCLOUD IND PRS SW 20PSI
        }
    }
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        
    }
    public SuppressPartNumber = (cb: string): boolean => {
        let r: RC10 = this.Quote.System.RC10;
        if ([".AuxControl", ".EndGunControl", ".SpeedControl", ".PumpControl"].indexOf(cb) !== -1 && r.MountingLocation === RC10MountingLocations.OuterTower && r.ControllerType === RC10Types.RC10i) {
            return true;
        }
        return false;
    }
}