import { ESegmentClearanceType } from "../../GeometryHelpers/SegmentHelper";

const defaultStyles = [
    /*{
      'id': 'gl-draw-polygon-fill-inactive',
      'type': 'fill',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static']
      ],
      'paint': {
        'fill-color': '#3bb2d0',
        'fill-outline-color': '#3bb2d0',
        'fill-opacity': 0.1
      }
    },*/
    {
      'id': 'gl-draw-polygon-fill-active',
      'type': 'fill',
      'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
      'paint': {
        'fill-color': '#fbb03b',
        'fill-outline-color': '#fbb03b',
        'fill-opacity': 0.1
      }
    },
    {
      'id': 'gl-draw-polygon-midpoint',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'midpoint']],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#fff'
      }
    },
    /*{
      'id': 'gl-draw-polygon-stroke-inactive',
      'type': 'line',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static']
      ],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#3bb2d0',
        'line-width': 2
      }
    },*/
    {
      'id': 'gl-draw-polygon-stroke-active',
      'type': 'line',
      'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#fbb03b',
        'line-dasharray': [0.2, 2],
        'line-width': 2
      }
    },
    /*{
      'id': 'gl-draw-line-inactive',
      'type': 'line',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'LineString'],
        ['!=', 'mode', 'static']
      ],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#3bb2d0',
        'line-width': 2
      }
    },*/
    {
      'id': 'gl-draw-line-active',
      'type': 'line',
      'filter': ['all',
        ['==', '$type', 'LineString'],
        ['==', 'active', 'true']
      ],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#fbb03b',
        'line-dasharray': [0.2, 2],
        'line-width': 2
      }
    },
    {
      'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
      'type': 'circle',
      'filter': ['all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static']
      ],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#fff'
      }
    },
    /*{
      'id': 'gl-draw-polygon-and-line-vertex-inactive',
      'type': 'circle',
      'filter': ['all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static']
      ],
      'paint': {
        'circle-radius': 3,
        'circle-color': '#fbb03b'
      }
    },
    {
      'id': 'gl-draw-point-point-stroke-inactive',
      'type': 'circle',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['!=', 'mode', 'static']
      ],
      'paint': {
        'circle-radius': 5,
        'circle-opacity': 1,
        'circle-color': '#fff'
      }
    },
    {
      'id': 'gl-draw-point-inactive',
      'type': 'circle',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['!=', 'mode', 'static']
      ],
      'paint': {
        'circle-radius': 3,
        'circle-color': '#3bb2d0'
      }
    },
    {
      'id': 'gl-draw-point-stroke-active',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],
        ['==', 'active', 'true'],
        ['!=', 'meta', 'midpoint']
      ],
      'paint': {
        'circle-radius': 7,
        'circle-color': '#fff'
      }
    },
    {
      'id': 'gl-draw-point-active',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'true']],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#fbb03b'
      }
    },*/
    /*{
      'id': 'gl-draw-polygon-fill-static',
      'type': 'fill',
      'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
      'paint': {
        'fill-color': '#404040',
        'fill-outline-color': '#404040',
        'fill-opacity': 0.1
      }
    },
    {
      'id': 'gl-draw-polygon-stroke-static',
      'type': 'line',
      'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#404040',
        'line-width': 2
      }
    },
    {
      'id': 'gl-draw-line-static',
      'type': 'line',
      'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#404040',
        'line-width': 2
      }
    },
    {
      'id': 'gl-draw-point-static',
      'type': 'circle',
      'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#404040'
      }
    }*/
  ];

const mapDrawStyles = [
    ...defaultStyles,
    // static RDP features
    {
      "id": "rdp-electric-line",
      "type": "line",
      "filter": ["all", ["==", "user_rdpFeatureType", "electricline"]],
      "paint": {
          "line-color": "#eb34e8",
          "line-width": 3
      },
    },
    {
      "id": "rdp-label-point",
      "type": "circle",
      "filter": ["all", ["==", "user_rdpFeatureType", "label"]],
      "layout": {
        "visibility": "none"
      }
    },
    {
      "id": "rdp-label-text",
      "type": "symbol",
      "filter": ["all", ["==", "user_rdpFeatureType", "label"]],
      'layout': {
          // get the title name from the source's "title" property
          'text-field': ['get', 'user_title'],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
          ]
        },
        "paint": {
          "text-halo-width": 0.3,
          "text-halo-color": "black",
          "text-color": "white",
       }
    },
    {
      "id": "rdp-display-label",
      "type": "symbol",
      'layout': {
          'text-field': ['get', 'display_label'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'text-rotate': {
            "type": "identity",
            "property": "rotation"
          },
          'text-anchor': [ 
            'case', 
            ['==', ['get', 'textAnchor'], null ],
            "bottom",
            ['get', 'textAnchor']
          ],
          "text-allow-overlap": true,
          "text-max-width": 100,
          "text-size": {
              "stops": [
                  [0, 0],
                  [12, 0],
                  [16, 18]
              ]
          }
        },
        "paint": {
          "text-halo-width": 0.3,
          "text-halo-color": "black",
          "text-color": "white",
       }
    },
    {
      "id": "rdp-water-line",
      "type": "line",
      "filter": ["all", ["==", "user_rdpFeatureType", "waterline"]],
      "paint": {
          "line-color": "#0dd7de",
          "line-width": 3
      },
    },
    {
      "id": "rdp-canal",
      "type": "line",
      "filter": ["all", ["==", "user_rdpFeatureType", "canal"]],
      "paint": {
          "line-color": "#0dd7de",
          "line-width": 5
      },
    },
    {
      "id": "rdp-line",
      "type": "line",
      "filter": ["all", ["==", "user_rdpFeatureType", "line"]],
      "paint": {
          "line-color": "#0cad2a",
          "line-width": 3
      },
    },
    {
      "id": "rdp-pump",
      "type": "circle",
      "filter": ["all", ["==", "user_rdpFeatureType", "pump"]],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#0514e6'
      }
    },
    {
      "id": "rdp-point",
      "type": "circle",
      "filter": ["all", ["==", "user_rdpFeatureType", "point"]],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#b700ff'
      }
    },
    {
        "id": "rdp-obstacle-outline",
        "type": "line",
        "filter": ["all", ["==", "user_rdpFeatureType", "obstacle"]],
        "paint": {
            "line-color": "#ff0000",
            "line-width": 3
        },
    },
    {
        "id": "rdp-wheelObstacle-outline",
        "type": "line",
        "filter": ["all", ["==", "user_rdpFeatureType", "wheelObstacle"]],
        "paint": {
            "line-color": "#8b0000",
            "line-width": 3
        },
    },
    {
        "id": "rdp-fieldBoundary-outline",
        "type": "line",
        "filter": ["all", ["==", "user_rdpFeatureType", "fieldBoundary"]],
        "paint": {
            "line-color": "#0000ff",
            "line-width": 3
        },
    },
    {
        "id": "rdp-wetAreaBoundary-outline",
        "type": "line",
        "filter": ["all", ["==", "user_rdpFeatureType", "wetAreaBoundary"]],
        "paint": {
            "line-color": "#87CEEB",
            "line-width": 3
        },
    },
    {
        "id": "rdp-pivotCenterBoundary-outline",
        "type": "line",
        "filter": ["all", ["==", "user_rdpFeatureType", "pivotCenterBoundary"]],
        "paint": {
            "line-color": "#000000",
            "line-width": 3
        },
    },
    {
      "id": "rdp-system/lateral",
      "type": "line",
      "filter": ["all", ["==", "user_rdpFeatureType", "system/lateral"],
      ["==", "user_validity", "valid"]],
      "paint": {
          "line-color": "#fcce03",
          "line-width": 3
      },
    },
    {
      "id": "rdp-system/canal",
      "type": "fill",
      "filter": ["all", 
        ["==", "user_rdpFeatureType", "system/canal"],
        // ["==", "user_validity", "valid"]
      ],
      "paint": {
          'fill-color': '#0dd7de',
          'fill-opacity': 0.9
      },
    },
    {
        "id": "sweepline",
        "type": "line",
        "filter": ["all", ["==", "user_rdpFeatureType", "sweepline"]],
        "paint": {
            "line-color": "#696969",
            "line-width": 3
        },
    },
    {
        "id": "rdp-system/pivotCenter-active",
        "type": "circle",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/pivotCenter"],
          ["==", "user_activeSystem", true]
        ],
        'paint': {
          'circle-radius': 5,
          'circle-color': '#ff0000'
        }
    },
    {
        "id": "rdp-system/pivotCenter-inactive",
        "type": "circle",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/pivotCenter"],
          ["==", "user_activeSystem", false]
        ],
        'paint': {
          'circle-radius': 5,
          'circle-color': '#808080'
        }
    },
    {
      "id": "rdp-system/wheelTrack-active",
        "type": "line",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/wheelTrack"],
          ["==", "user_activeSystem", true],
          ["==", "user_validity", "valid"]
        ],
        "paint": {
            "line-color": "#0000ff",
            "line-width": 1
        },
    },
    {
      "id": "rdp-system/wheelTrack-inactive",
        "type": "line",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/wheelTrack"],
          ["==", "user_activeSystem", false],
          ["==", "user_validity", "valid"]
        ],
        "paint": {
            "line-color": "#808080",
            "line-width": 0.5
        },
    },
    // {
    //   "id": "rdp-system/sacEndBoom",
    //     "type": "line",
    //     "filter": ["all", 
    //       ["==", "user_rdpFeatureType", "system/sacEndBoom"],
    //       ["==", "user_activeSystem", true],
    //       ["==", "user_validity", "valid"]
    //     ],
    //     "paint": {
    //         // "line-color": "#808080",
    //         "line-color": "#ff0000",
    //         "line-width": 1
    //     },
    // },
    {
        "id": "rdp/wheelTrack-warning",
        "type": "line",
        "filter": ["all", 
        ["==", "user_rdpFeatureType", "system/wheelTrack"],
          ["==", "user_validity", "warning"]
        ],
        'paint': {
          'line-color': '#ffbf00',
          "line-width": 0.5
        }
    },
    {
        "id": "rdp/wheelTrack-critical",
        "type": "line",
        "filter": ["all", 
        ["==", "user_rdpFeatureType", "system/wheelTrack"],
          ["==", "user_validity", "critical"]
        ],
        'paint': {
          'line-color': '#ff0000',
          "line-width": 0.5
        }
    },
    {
        "id": "rdp-system/irrigatedArea-active",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/irrigatedArea"],
          ["==", "user_activeSystem", true],
          ["==", "user_validity", "valid"]
        ],
        'paint': {
          'fill-color': '#0000ff',
          'fill-opacity': 0.3,
          "fill-antialias": false
        }
    },
    {
        "id": "rdp-system/irrigatedArea-inactive",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/irrigatedArea"],
          ["==", "user_activeSystem", false],
          ["==", "user_validity", "valid"]
        ],
        'paint': {
          'fill-color': '#808080',
          'fill-opacity': 0.3
        }
    },
    {
        "id": "rdp-system/irrigatedArea-dropSpan-active",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/irrigatedArea-dropSpan"],
          ["==", "user_activeSystem", true],
          ["==", "user_validity", "valid"]
        ],
        'paint': {
          'fill-color': '#008080',
          'fill-opacity': 0.3,
          "fill-antialias": false
        }
    },
    {
        "id": "rdp-system/irrigatedArea-dropSpan-inactive",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/irrigatedArea-dropSpan"],
          ["==", "user_activeSystem", false],
          ["==", "user_validity", "valid"]
        ],
        'paint': {
          'fill-color': '#808080',
          'fill-opacity': 0.3
        }
    },
    {
        "id": "rdp-system/irrigatedArea-flexSide-active",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/irrigatedArea-flexSide"],
          ["==", "user_activeSystem", true],
          ["==", "user_validity", "valid"]
        ],
        'paint': {
          'fill-color': '#5dbb63',
          'fill-opacity': 0.3,
          "fill-antialias": false
        }
    },
    {
        "id": "rdp-system/irrigatedArea-flexSide-inactive",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/irrigatedArea-flexSide"],
          ["==", "user_activeSystem", false],
          ["==", "user_validity", "valid"]
        ],
        'paint': {
          'fill-color': '#808080',
          'fill-opacity': 0.3
        }
    },
    {
        "id": "rdp-system/irrigatedArea-sac-active",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/irrigatedArea-sac"],
          ["==", "user_activeSystem", true],
          ["==", "user_validity", "valid"]
        ],
        'paint': {
          'fill-color': '#ffffff',
          'fill-opacity': 0.3,
          "fill-antialias": false
        }
    },
    {
        "id": "rdp-system/irrigatedArea-sac-inactive",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/irrigatedArea-sac"],
          ["==", "user_activeSystem", false],
          ["==", "user_validity", "valid"]
        ],
        'paint': {
          'fill-color': '#808080',
          'fill-opacity': 0.3
        }
    },
    {
        "id": "rdp-system/irrigatedArea-endGun-active",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/irrigatedArea-endGun"],
          ["==", "user_activeSystem", true],
          ["==", "user_validity", "valid"]
        ],
        'paint': {
          'fill-color': '#7EC8E3',
          'fill-opacity': 0.3,
          "fill-antialias": false
        }
    },
    {
        "id": "rdp-system/irrigatedArea-endGun-inactive",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "system/irrigatedArea-endGun"],
          ["==", "user_activeSystem", false],
          ["==", "user_validity", "valid"]
        ],
        'paint': {
          'fill-color': '#808080',
          'fill-opacity': 0.3
        }
      },
    // These are currenlty not used anywhere, what happed to blockade?    
    // {
    //   "id": "rdp-system/blockade-active",
    //   "type": "circle",
    //   "filter": ["all", 
    //     ["==", "user_rdpFeatureType", "system/blockade"],
    //     ["==", "user_activeSystem", true]
    //   ],
    //   'paint': {
    //     'circle-radius': 5,
    //     'circle-color': '#ff0000'
    //   }
    // },
    // {
    //     "id": "rdp-system/blockade-inactive",
    //     "type": "circle",
    //     "filter": ["all", 
    //       ["==", "user_rdpFeatureType", "system/blockade"],
    //       ["==", "user_activeSystem", false]
    //     ],
    //     'paint': {
    //       'circle-radius': 5,
    //       'circle-color': '#808080',
    //   }
    // },
    // center pivot select mode
    {
        "id": "rdp-centerPivotSelectMode/irrigatedArea-valid",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "centerPivotSelectMode/irrigatedArea"],
          ["==", "user_validity", "valid"]
        ],
        'paint': {
          'fill-color': '#00ff00',
          'fill-opacity': 0.3
        }
    },
    {
        "id": "rdp/irrigatedArea-warning",
        "type": "fill",
        "filter": ["all", 
          ['!=', '$type', 'LineString'],
          ["==", "user_validity", "warning"]
        ],
        'paint': {
          'fill-color': '#ffbf00',
          'fill-opacity': 0.3
        }
    },
    {
        "id": "rdp/irrigatedArea-critical",
        "type": "fill",
        "filter": ["all", 
          ['!=', '$type', 'LineString'],
          ["==", "user_validity", "critical"]
        ],
        'paint': {
          'fill-color': '#ff0000',
          'fill-opacity': 0.3
        }
    },
    {
      "id": "rdp-centerPivotSelectMode/wheelTracks",
      "type": "line",
      "filter": ["all", 
        ["==", "user_rdpFeatureType", "centerPivotSelectMode/wheelTracks"]
      ],
      "paint": {
          "line-color": "black",
          "line-width": 1,
          'line-dasharray': [2, 1]
      },
    },
    // Dynamic optimization styles
    {
      "id": "rdp-dyanimcCenterValid",
      "type": "circle",
      "filter": ["all", ["==", "user_rdpFeatureType", "dynamicCenterValid"]],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#00ff00'
      }
    },
    {
      "id": "rdp-dyanimcOptimization-label",
      "type": "symbol",
      "filter": ["all", ["==", "user_rdpDynamicOptimizationLabel", true]],
      'layout': {
          // get the title name from the source's "title" property
          'text-field': ['get', 'user_title'],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
          ],
          'text-radial-offset': 1,
          'text-anchor': 'bottom',
        },
        "paint": {
          "text-halo-width": 0.3,
          "text-halo-color": "black",
          "text-color": "white",
       }
    },
    {
      "id": "rdp-dyanimcCenterInvalid",
      "type": "circle",
      "filter": ["all", ["==", "user_rdpFeatureType", "dynamicCenterInvalid"]],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#ff0000'
      }
    },
    {
        "id": "rdp-dynamicOptimization/irrigatedArea-flangedSide",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "dynamicOptimization/irrigatedArea-flangedSide"]
        ],
        'paint': {
          'fill-color': '#0000ff',
          'fill-opacity': 0.3
        }
    },
    {
        "id": "rdp-dynamicOptimization/irrigatedArea-flexSide",
        "type": "fill",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "dynamicOptimization/irrigatedArea-flexSide"]
        ],
        'paint': {
          'fill-color': '#5dbb63',
          'fill-opacity': 0.3
        }
    },
    {
      "id": "rdp-dynamicOptimization/wheelTrack",
        "type": "line",
        "filter": ["all", 
          ["==", "user_rdpFeatureType", "dynamicOptimization/wheelTrack"]
        ],
        "paint": {
          "line-color": "#808080",
            "line-width": 0.5
        },
    },
    {
      "id": "rdp-dynamicOptimization/feedLine-invalid",
      "type": "line",
      "filter": ["all", ["==", "user_rdpFeatureType", "dynamicOptimization/feedLine-invalid"]],
      "paint": {
          "line-color": "#ff0000",
          "line-width": 3
      },
    },
    // project list map
    {
      "id": "rdp-projectList/fieldBoundary",
      "type": "fill",
      "filter": [
        "all", 
        ["==", "user_rdpFeatureType", "fieldBoundary"],
        ["==", "user_rdpProjectListMap", true]
      ],
      "paint": {
          'fill-color': "#0000ff",
          'fill-opacity': 0.3
      },
    },
    // presistent measurements:
    {
      "id": "rdp-persistent-measurement-line",
      "type": "line",
      "filter": ["all", ["==", "user_rdpFeatureType", "measurement"]],
      "paint": {
          "line-color": "#ffffff",
          "line-width": 2,
          "line-dasharray": [2,1]
      },
    },
    {
      "id": "rdp-persistent-measurement-label",
      "type": "symbol",
      "filter": ["all", ["==", "user_rdpFeatureType", "measurement"]],       
      'layout': {
        "symbol-placement": "point",
        'text-field': ['get', 'user_label'],
        'text-radial-offset': 0.5,
        'text-anchor': 'bottom',
        "text-allow-overlap": true,
        "text-ignore-placement": true,
      },
      "paint": {
        "text-halo-width": 0.3,
        "text-halo-color": "black",
        "text-color": "white",
     }
    },
    // clearances:
    {
      "id": "rdp/boundaryClearance-equipment",
      "type": "fill",
      "filter": [
        "all", 
        ["==", "user_rdpFeatureType", "boundaryClearance"],
        ["==", "user_rdpClearanceType", ESegmentClearanceType.EquipmentClearance],
      ],
      "paint": {
          'fill-color': "#ffffff",
          'fill-opacity': 0.3
      },
    },
    {
      "id": "rdp/boundaryClearance-sTower",
      "type": "fill",
      "filter": [
        "all", 
        ["==", "user_rdpFeatureType", "boundaryClearance"],
        ["==", "user_rdpClearanceType", ESegmentClearanceType.STowerClearance],
      ],
      "paint": {
          'fill-color': "#FF0000",
          'fill-opacity': 0.3
      },
    },
    {
      "id": "rdp/boundaryClearance-hTower",
      "type": "fill",
      "filter": [
        "all", 
        ["==", "user_rdpFeatureType", "boundaryClearance"],
        ["==", "user_rdpClearanceType", ESegmentClearanceType.HTowerClearance],
      ],
      "paint": {
          'fill-color': "#FFFF00",
          'fill-opacity': 0.3
      },
    },
    {
      "id": "rdp/obstacleClearance-equipment",
      "type": "fill",
      "filter": [
        "all", 
        ["==", "user_rdpFeatureType", "obstacleClearance"],
        ["==", "user_rdpClearanceType", ESegmentClearanceType.EquipmentClearance],
      ],
      "paint": {
          'fill-color': "#ffffff",
          'fill-opacity': 0.3
      },
    },
    {
      "id": "rdp/obstacleClearance-sTower",
      "type": "fill",
      "filter": [
        "all", 
        ["==", "user_rdpFeatureType", "obstacleClearance"],
        ["==", "user_rdpClearanceType", ESegmentClearanceType.STowerClearance],
      ],
      "paint": {
          'fill-color': "#FF0000",
          'fill-opacity': 0.3
      },
    },
    {
      "id": "rdp/obstacleClearance-hTower",
      "type": "fill",
      "filter": [
        "all", 
        ["==", "user_rdpFeatureType", "obstacleClearance"],
        ["==", "user_rdpClearanceType", ESegmentClearanceType.HTowerClearance],
      ],
      "paint": {
          'fill-color': "#FFFF00",
          'fill-opacity': 0.3
      },
    },
    {
      "id": "rdp/segment-select-mode-clearance-equipment",
      "type": "fill",
      "filter": [
        "all", 
        ["==", "user_rdpFeatureType", "segment-select-mode-clearance-equipment"]
      ],
      "paint": {
          'fill-color': "#ffffff",
          'fill-opacity': 0.5
      },
    },
    {
      "id": "rdp/segment-select-mode-clearance-sTower",
      "type": "fill",
      "filter": [
        "all", 
        ["==", "user_rdpFeatureType", "segment-select-mode-clearance-sTower"]
      ],
      "paint": {
          'fill-color': "#FF0000",
          'fill-opacity': 0.5
      },
    },
    {
      "id": "rdp/segment-select-mode-clearance-hTower",
      "type": "fill",
      "filter": [
        "all", 
        ["==", "user_rdpFeatureType", "segment-select-mode-clearance-hTower"]
      ],
      "paint": {
          'fill-color': "#FFFF00",
          'fill-opacity': 0.5
      },
    },
    // Custom vertex labels:
    {
      'id': 'gl-draw-vertex-label',
      'type': 'symbol',
      'filter': ['all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static']
      ],      
      'layout': {
        "symbol-placement": "point",
        'text-field': ['get', 'user_label'],
        'text-radial-offset': 0.5,
        'text-anchor': 'bottom',
        "text-allow-overlap": true,
        "text-ignore-placement": true,
      },
      "paint": {
        "text-halo-width": 0.3,
        "text-halo-color": "black",
        "text-color": "white",
     }
    },
    {
      'id': 'gl-draw-vertex-delete',
      'type': 'circle',
      'filter': ['all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['==', 'delete', true]
      ],      
      'paint': {
        'circle-radius': 5,
        'circle-color': '#ff0000'
      }
    },
    // Elevation labels:
    {
      "id": "rdp-elevation-max",
      "type": "circle",
      "filter": ["all", ["==", "user_rdpFeatureType", "elevation-max"]],
      'paint': {
        'circle-radius': 5,
        "circle-opacity": 0,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#ff0000"
      }
    },
    {
      "id": "rdp-elevation-min",
      "type": "circle",
      "filter": ["all", ["==", "user_rdpFeatureType", "elevation-min"]],
      'paint': {
        'circle-radius': 5,
        "circle-opacity": 0,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#ffff00"
      }
    },
    {
      "id": "rdp-system-max-elevation-line",
      "type": "line",
      "filter": ["all", ["==", "user_rdpFeatureType", "system-max-elevation-line"]],
      "paint": {
          "line-color": "#ffa500",
          "line-width": 2,
          // "line-dasharray": [2,1]
      },
    },
    {
      "id": "rdp-system-max-elevation-point",
      "type": "circle",
      "filter": ["all", ["==", "user_rdpFeatureType", "system-max-elevation-point"]],
      'paint': {
        'circle-radius': 5,
        "circle-opacity": 0,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#ffa500",
      }
    },
];

export default mapDrawStyles;