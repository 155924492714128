import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert, Button, Card, Stack, Typography } from "@mui/material";
import i18next, { t } from "i18next";
import IAction from "rdptypes/IAction";
import { SideEnum } from "rdptypes/helpers/SideEnum";
import * as React from "react";
import { createNewMultiAction } from "../../../../../actions/MultiAction";
import { createSetEndOfSystemAction } from "../../../../../actions/SetEndOfSystemAction";
import { createNewUpdateSystemPropertyAction } from "../../../../../actions/UpdateSystemProperty";
import { pageStyles } from "../../../../../components/roe/styles";
import IDbProject from "../../../../../db/IDbProject";
import IDrawerTab from "../IDrawerTab";

export const hasWarnings = (args: { dbPrj: IDbProject; layoutId: string; systemId: string }) => {
    const layout = args.dbPrj.state.layouts[args.layoutId];
    if (!layout) return false;
    const system = layout.systems[args.systemId];
    if (!system) return false;
    if (system.proposalGenerated) return true;
    if(system.sacOptimizerResult) {
        if (!system.sacOptimizerResult.success) return true;
    }
    return false;
}

const warningsTab: IDrawerTab = {
    icon: <WarningAmberIcon />,
    getTitle: () => i18next.format(t("warnings"), 'capitalize-each'),
    component: (props) => {
        const { dbPrj, layoutId, systemId, authState } = props;
        const layout = dbPrj.state.layouts[layoutId];
        if (!layout) return null;
        const system = layout.systems[systemId];
        if (!system) return null;

        const warningCards: React.ReactNode[] = [];

        if (!hasWarnings(props)) {
            warningCards.push(
                <Card key={"sac-reduce"} style={{ padding: 10 }} variant="outlined">
                    <Typography>
                        {i18next.format(t("field-design-drawer.warnings.no-warnings.body"), 'capitalize')}
                    </Typography>
                </Card>
            )
        };
        
        if (system.proposalGenerated) {
            warningCards.push(
                <Alert severity="warning">{t("proposals.already-generated-proposal")}</Alert>
            )
        }

        if (system.sacOptimizerResult && system.sacOptimizerResult.success === false) {
            if (system.sacOptimizerResult.canReduce) {
                const { reduce } = system.sacOptimizerResult;
                const clockwiseCompassHeadingStart =
                    (Math.ceil((reduce.newPartialPivotAngleDegreesMin + 360) % 360) * 10) / 10;
                const clockwiseCompassHeadingEnd =
                    (Math.floor((reduce.newPartialPivotAngleDegreesMax + 360) % 360) * 10) / 10;

                const onReduce = () => {
                    const actions: IAction[] = [
                        createNewUpdateSystemPropertyAction(
                            layoutId,
                            systemId,
                            "Circle.CenterPivot.isPartialPivot",
                            true,
                            authState
                        ),
                        createNewUpdateSystemPropertyAction(
                            layoutId,
                            systemId,
                            "Circle.CenterPivot.clockwiseCompassHeadingStart",
                            clockwiseCompassHeadingStart,
                            authState
                        ),
                        createNewUpdateSystemPropertyAction(
                            layoutId,
                            systemId,
                            "Circle.CenterPivot.clockwiseCompassHeadingEnd",
                            clockwiseCompassHeadingEnd,
                            authState
                        )
                    ];
                    dbPrj.pushAction(
                        createNewMultiAction(
                            actions,
                            authState
                        )
                    );
                }

                const onRemoveSAC = () => {
                    dbPrj.pushAction(
                        createSetEndOfSystemAction(
                            layoutId,
                            systemId,
                            SideEnum.Flanged,
                            { clear: true },
                            authState
                        )
                    );
                }
                const node = (
                    <Card key={"sac-reduce"} style={{ padding: 10 }} variant="outlined">
                        <Typography sx={{ ...pageStyles.cardTitleStyle, margin: 0, flex: 1 }}>
                            {i18next.format(t("field-design-drawer.warnings.sac-reduce.title"), 'capitalize-each')}
                        </Typography>
                        <Typography>
                            {i18next.format(t("field-design-drawer.warnings.sac-reduce.body1"), 'capitalize')}
                        </Typography>
                        <br />
                        <Typography>
                            {i18next.format(t("field-design-drawer.warnings.sac-reduce.body2"), 'capitalize')}
                        </Typography>
                        <br />
                        <Stack direction="row" justifyContent={'flex-end'} alignItems={'center'} gap={2}>
                            <Button sx={{ width: 120 }} variant="contained" color='error' size="small" onClick={onRemoveSAC}>
                                {i18next.format(t("field-design-drawer.warnings.sac-reduce.btn-remove"), 'capitalize')}
                            </Button>
                            <Button sx={{ width: 120 }} variant="contained" size="small" onClick={onReduce}>
                                {i18next.format(t("field-design-drawer.warnings.sac-reduce.btn-reduce"), 'capitalize')}
                            </Button>
                        </Stack>
                    </Card>
                )
                warningCards.push(node);
            }
            else {
                const onRemoveSAC = () => {
                    dbPrj.pushAction(
                        createSetEndOfSystemAction(
                            layoutId,
                            systemId,
                            SideEnum.Flanged,
                            { clear: true },
                            authState
                        )
                    );
                }
                const node = (
                    <Card key={"sac-reduce"} style={{ padding: 10 }} variant="outlined">
                        <Typography sx={{ ...pageStyles.cardTitleStyle, margin: 0, flex: 1 }}>
                            {i18next.format(t("field-design-drawer.warnings.sac-result.title"), 'capitalize-each')}
                        </Typography>
                        <Typography>
                            {i18next.format(t("field-design-drawer.warnings.sac-result.body1"), 'capitalize-each')}
                        </Typography>
                        <br />
                        <Typography>
                            {i18next.format(t("field-design-drawer.warnings.sac-result.body2"), 'capitalize-each')}
                        </Typography>
                        <br />
                        <Stack direction="row" justifyContent={'flex-end'} alignItems={'center'} gap={2}>
                            <Button sx={{ width: 120 }} variant="contained" color='error' size="small" onClick={onRemoveSAC}>
                                {i18next.format(t("field-design-drawer.warnings.sac-result.btn-remove"), 'capitalize')}
                            </Button>
                        </Stack>
                    </Card>
                )
                warningCards.push(node);
            }
        }

        return (
            <Stack direction='column' margin={1}>
                {
                    ...warningCards
                }
            </Stack>
        )
    }
}

export default warningsTab;