export class Interlocked {
    public static Increment(value: [ number ]) {
        value[0]++;
        return value[0];
    }
    
    public static CompareExchange<T>(v1: [ T ], v2: T, comparand: T) {
        if (v1[0] === comparand) {
            v1[0] = v2;
        }
    }
}