import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import * as React from "react";
import { PropsWithChildren } from "react";

interface IProps {
    onCancel: () => void;
    onConfirm: () => void;
}

const ShareFieldDialog: React.FC<PropsWithChildren<IProps>> = ({ 
    onConfirm, onCancel
}) => {

    return (
        <Dialog onClose={onCancel} open={true}>
            <DialogTitle>Share field by email</DialogTitle>

            <DialogContent>
            <DialogContentText>
                You can share your field in read-only mode by entering their email address below. They will receive a
                link to log in and view the field.
              </DialogContentText>
              <TextField
                required
                label="Recipient email address"
                autoFocus
                fullWidth
                variant="standard"
              />
            </DialogContent>

            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={onConfirm}>
                    Send link
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShareFieldDialog;
