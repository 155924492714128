import { MotorLocations } from "./CommonEnums";

export const GetEnumDescription_MotorLocations = (value: MotorLocations) => {
    switch (value) {
        case MotorLocations.PivotCenter: return "Pivot Center";
        case MotorLocations.PowerTower: return "Power Tower";
        case MotorLocations.A100Pump: return "A100 Pump";
        case MotorLocations.Tower: return "Tower";
        case MotorLocations.SecondaryPT: return "Secondary PT";
        case MotorLocations.SACAirComp: return "SAC AirComp";
        case MotorLocations.SACSteering: return "SAC Steering";
        case MotorLocations.SACTower: return "SAC Tower";
        case MotorLocations.BoosterPump: return "Booster Pump";
        case MotorLocations.VRIAirCompPowerTower: return "VRI AirComp Power Tower";
        case MotorLocations.VRIAirCompOuterTower: return "VRI AirComp OuterTower";
        case MotorLocations.DualSprinklerPkAirComp: return "Dual SpPk AirComp";
        case MotorLocations.WrapJointAirComp: return "WrapSpan AirComp";
        default:
            throw new Error("Unknown MotorLocation " + value);
    }
}