import IAction from "rdptypes/IAction";
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction } from './helpers';

export const actionTypeId = "UndoAction";

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
});

export const createNewUndoAction = (authState: IAuthState) => createAction(
        actionTypeId,
        {
        },
        authState);