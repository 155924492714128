import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Alert, AlertTitle, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Tooltip } from "@mui/material";
import { PDFViewer } from "@react-pdf/renderer";
import { t } from "i18next";
import IDbGrower from "rdptypes/api/IGrowerBase";
import { PaymentTypes, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { strLanguageText } from "roedata/roe_migration/TranslationsLib";
import VRI_Zones from "roedata/roe_migration/VRIClass.VRI_Zones";
import { canHaveVRI } from "roedata/roe_migration/Valids.Valids";
import DevSettingsCtx from "../../../../db/DevSettingsCtx";
import IProject from "../../../../model/project/IProject";
import DealerDataCtx from "../../../../userData/DealerDataCtx";
import { IDocumentType, getDocTypeStr } from "./../../../../docGeneration/DocumentGenerationHelpers";
import ElectricalReport from "./../../../../docGeneration/docTypes/ElectricalReport";
import IrrigationSystemPerformanceSummary from "./../../../../docGeneration/docTypes/IrrigationSystemPerformanceSummary";
import IrrigationSystemProposal from "./../../../../docGeneration/docTypes/IrrigationSystemProposal";
import IrrigationSystemPurchaseAgreement from "./../../../../docGeneration/docTypes/IrrigationSystemPurchaseAgreement";
import IrrigationSystemPurchaseOrder from "./../../../../docGeneration/docTypes/IrrigationSystemPurchaseOrder";
import VRIReport from "./../../../../docGeneration/docTypes/VRIReport";
import { getGroupedPartsForSystem } from "./ProposalHelpers";

interface Props {
    onDiscardProposal: () => void;
    project: IProject;
    systemId: string;
    layoutId: string;
    grower: IDbGrower;
};

const CoastCapitalPdf = new URL('../../../../assets/proposalDocs/Coast Capital - Canada.pdf', import.meta.url);
const AchPdf = new URL('../../../../assets/proposalDocs/Electronic Debit-ACH authorization form.pdf', import.meta.url);
const FaxedChequePdf = new URL('../../../../assets/proposalDocs/FAX CH FORM.pdf', import.meta.url);
const HuntingtonPdf = new URL('../../../../assets/proposalDocs/Huntington - USA.pdf', import.meta.url);
const UnicoPdf = new URL('../../../../assets/proposalDocs/UNICO Irrigation Insurance PDF Application.pdf', import.meta.url);

const getStaticPdfUrl = (dt: IDocumentType): URL | undefined => {
    switch (dt) {

        case IDocumentType.faxedCheque:
            return FaxedChequePdf;
        case IDocumentType.ach:
            return AchPdf;
        case IDocumentType.huntington:
            return HuntingtonPdf;
        case IDocumentType.unico:
            return UnicoPdf;
        case IDocumentType.coastCapital:
            return CoastCapitalPdf;
        default:
            return undefined;
    }
}

const supportsOnlineForm = (dt: IDocumentType): URL | undefined => {
    switch (dt) {

        case IDocumentType.faxedCheque:
            return false;
        case IDocumentType.ach:
        case IDocumentType.huntington:
        case IDocumentType.unico:
        case IDocumentType.coastCapital:
            return true;
        default:
            return false;
    }
}

export const Report: React.FC<{
    docType: IDocumentType, grower: IDbGrower, systemId: string, layoutId: string, project: IProject
}> = ({ docType, grower, project, systemId, layoutId }) => {

    const layout = project.layouts[layoutId!];
    const system = layout.systems[systemId!];

    switch (docType) {
        case IDocumentType.irrigationSystemProposal:
            return <IrrigationSystemProposal grower={grower} systemId={systemId} project={project} layoutId={layoutId} />;
        case IDocumentType.irrigationSystemPurchaseAgreement:
            return <IrrigationSystemPurchaseAgreement grower={grower} systemId={systemId} project={project} layoutId={layoutId} />;
        case IDocumentType.irrigationSystemPurchaseOrder:
            return <IrrigationSystemPurchaseOrder grower={grower} systemId={systemId} project={project} layoutId={layoutId} />;
        case IDocumentType.electricalReport:
            return <ElectricalReport system={system} />;
        case IDocumentType.irrigationSystemPerformanceSummary:
            return <IrrigationSystemPerformanceSummary grower={grower} systemId={systemId} project={project} layoutId={layoutId} />;
        case IDocumentType.vriReport:
            return <VRIReport grower={grower} system={system} />;
    }
}

const ViewProposalRenderer: FC<Props> = (props) => {
    const { grower, systemId, project, layoutId, onDiscardProposal } = props;
    const [documentType, setDocumentType] = useState(IDocumentType[IDocumentType.irrigationSystemProposal]);
    const layout = project.layouts[layoutId!];
    const system = layout.systems[systemId!];

    const groupedSystemParts = getGroupedPartsForSystem(system).partGroups;

    const user = React.useContext(DealerDataCtx);
    const devSettings = React.useContext(DevSettingsCtx);

    const supported = (docType: IDocumentType) => {
        const systemType = system.SystemProperties.SystemType;
        switch (docType) {
            case IDocumentType.irrigationSystemPerformanceSummary:
            case IDocumentType.electricalReport:
                return systemType !== SystemTypes.Ancillary &&
                    systemType !== SystemTypes.SwingArmRetro;
            case IDocumentType.vriReport:
                let allowed = canHaveVRI(system);
                if (allowed) {
                    let vriZones = new VRI_Zones(system);
                    let anyInUse = false;
                    for (let i = 1; i <= vriZones.Count; i++) {
                        if (!anyInUse) {
                            anyInUse = vriZones.Get(i).InUse;
                        }
                    }
                    if (!anyInUse) {
                        allowed = false;
                    }
                }
                return allowed;
            case IDocumentType.coastCapital:
                return system.QuoteProperties.ProposalInformation.paymentTerms === PaymentTypes.ReinkeCoastCapital;
            case IDocumentType.huntington:
                return system.QuoteProperties.ProposalInformation.paymentTerms === PaymentTypes.ReinkeHuntington;
            case IDocumentType.unico:
                return system.QuoteProperties.ProposalInformation.hasMidAmericaRiskInsurance;
            default:
                return true;
        }
    }

    const staticPdfUrl = getStaticPdfUrl(IDocumentType[documentType]);

    React.useEffect(() => {
        if (supportsOnlineForm(IDocumentType[documentType])) {
            setTimeout(() => {
                alert("You can either print and complete the document manually or fill in the document using your web browser. Once completed you must then print the file and send it to the service provider. Their information is located on the form. It will not be saved with this proposal.")
            }, 2000);
        }
    }, [staticPdfUrl]);

    return (
        <>
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Stack sx={{ width: '100%' }} spacing={2} direction="row">
                    <FormControl sx={{ height: 48 }}>
                        <InputLabel>{strLanguageText("sltReportType")}</InputLabel>
                        <Select
                            id="roe-document-select"
                            value={documentType}
                            label={strLanguageText("sltReportType")}
                            onChange={(e) => {
                                setDocumentType(e.target.value);
                            }}
                        >
                            {Object.keys(IDocumentType).filter(key => !isNaN(Number(IDocumentType[key]))).map((t) => {
                                if (supported(IDocumentType[t])) {
                                    return <MenuItem value={t}>{getDocTypeStr(IDocumentType[t])}</MenuItem>
                                }
                            })}
                        </Select>
                    </FormControl>
                    <Tooltip title={t("proposals.discard-proposal")} placement="bottom">
                        <IconButton color="warning" onClick={props.onDiscardProposal}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </Tooltip>
                    <Button onClick={props.onDiscardProposal}></Button>
                </Stack>
                {!staticPdfUrl && <ErrorBoundary fallbackRender={({ error, resetErrorBoundary }) => <Alert severity="error"><AlertTitle>Error generating PDF</AlertTitle>{JSON.stringify(error)}</Alert>}>
                    <div style={{ width: "100%", paddingRight: 25 }}>
                        {
                            groupedSystemParts !== null && <PDFViewer height={700} style={{ width: "100%" }} showToolbar={true}>
                                { /* We have to bridge all conexts that might be needed within the PDF renderers. 
                        See https://github.com/facebook/react/issues/17275#issuecomment-550322731 */ }
                                <DealerDataCtx.Provider value={user}>
                                    <DevSettingsCtx.Provider value={devSettings}>
                                        <Report docType={IDocumentType[documentType]} grower={grower} systemId={systemId} layoutId={layoutId} project={project} />
                                    </DevSettingsCtx.Provider>
                                </DealerDataCtx.Provider>
                            </PDFViewer>
                        }
                    </div>
                </ErrorBoundary>}
                {staticPdfUrl && <object data={staticPdfUrl.href} style={{ height: 700, width: "100%" }} />}
            </Stack>
        </>
    );
};

export default ViewProposalRenderer;