import { BoomBackDevices } from "rdptypes/project/ISprinklers";
import { SprinklerEngineWarning } from "./SprinklerEngineWarning";

export class EngineRecord {
    public TotalGPM: number = 0;
    public SystemGPM: number = 0;
    public MaximumSystemGPM: number = 0;
    public MaximumTopInletPSI: number = 0;
    public MinimumSystemGPM: number = 0;
    public SystemPressureLossAtMaxSystemGPM: number = 0;
    public SystemPressureLoss: number = 0;
    public SideGPM: number = 0;
    public NonAdjustedEndPressure: number = 0;
    public get Elevation() {
        return this._Elevation;
    }

    public set Elevation(dat: number) {
        this._Elevation = dat;
        this.AdjustedEndPressure = this.NonAdjustedEndPressure + this._Elevation / 2.31;
    }

    private _Elevation: number = 0;
    public get EndPressure() {
        return this.NonAdjustedEndPressure;
    }

    public set EndPressure(dat: number) {
        this.NonAdjustedEndPressure = dat;
        this.AdjustedEndPressure = this.NonAdjustedEndPressure + this._Elevation / 2.31;
    }

    public AdjustedEndPressure: number = 0;
    public EndOfSystemCoverage: number = 0;
    public TowerBoomBackStart: number = 0;
    public TowerBoomBackEnd: number = 0;
    public BoomBackDevice: BoomBackDevices = BoomBackDevices.D3000;
    public Warnings: SprinklerEngineWarning[];
    public Reset = () => {
        this.MaximumSystemGPM = 0;
        this.MaximumTopInletPSI = 0;
        this.MinimumSystemGPM = 0;
        this.EndOfSystemCoverage = 0;
        this.Warnings = [];
    }

}

