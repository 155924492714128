import IAction from "rdptypes/IAction";
import IActionData from "rdptypes/IActionData";
import { SideEnum, getSide } from 'rdptypes/helpers/SideEnum';
import { BuckBoostTypes, WrapAroundSpanTypes } from 'rdptypes/project/ISystemBase.AutoGenerated';
import { ISpan, ITower } from 'rdptypes/project/Types';
import IAuthState from '../auth/IAuthState';
import IProject from "../model/project/IProject";
import { registerExecutor } from "./actionExecutorRegistry";
import { createAction, recordLayoutChange, recordProjectChange, recordSystemChange } from './helpers';
import { renumberSpanAndTowers, switchSidesIfNeeded } from './helpers/spans';

const actionTypeId = "AddSpanTower";

interface ActionData extends IActionData {
    layoutId: string;
    systemId: string;
    side: SideEnum;
    spanDefaults?: ISpan;
    towerDefaults?: ITower;
}

export const createSpanAndTower = (newId: number, spanDefaults?: ISpan, towerDefaults?: ITower) => {
    // START: create the new span
    const newSpan: ISpan = {};
    newSpan.SpanNumber = newId;
    newSpan.Disconnecting = false;
    newSpan.dropSpanStartRelativeToPreviousSpanStart = undefined;
    newSpan.dropSpanEndRelativeToPreviousSpanEnd = undefined;
    if (spanDefaults) {
        newSpan.Extension = spanDefaults.Extension;
        newSpan.ExtraCableConductors = spanDefaults.ExtraCableConductors;
        newSpan.LargeTrussRods = spanDefaults.LargeTrussRods;
        newSpan.Length = spanDefaults.Length;
        newSpan.MinCableGauge = spanDefaults.MinCableGauge;
        newSpan.Spacing = spanDefaults.Spacing;
        newSpan.SpanType = spanDefaults.SpanType;
        newSpan.Stabilizers = spanDefaults.Stabilizers;
    }
    // END: create the new span

    // START: create the new tower
    const newTower: ITower = {};
    newTower.TowerNumber = newId;
    newTower.WrapAroundSpan = WrapAroundSpanTypes.None;
    newTower.BuckBoost = BuckBoostTypes.aNone;
    if (towerDefaults) {
        newTower.AgriTrac = towerDefaults.AgriTrac;
        newTower.AirCompressor = towerDefaults.AirCompressor;
        newTower.AutomatedValves = towerDefaults.AutomatedValves;
        newTower.CenterDrive = towerDefaults.CenterDrive;
        newTower.ControlLineBoosterPump = towerDefaults.ControlLineBoosterPump;
        newTower.Guidance = towerDefaults.Guidance;
        newTower.HeavyDuty = towerDefaults.HeavyDuty;
        newTower.StiffenerStabilizers = towerDefaults.StiffenerStabilizers;
        newTower.StiffenerTrussToPipe = towerDefaults.StiffenerTrussToPipe;
        newTower.ThreeTires = towerDefaults.ThreeTires;
        newTower.ThreeTiresFlex = towerDefaults.ThreeTiresFlex;
        newTower.Tires = towerDefaults.Tires // Tires is an object
            ? { ...towerDefaults.Tires }
            : {};
        newTower.TowerAutoReverse = towerDefaults.TowerAutoReverse;
        newTower.TowerAutoStop = towerDefaults.TowerAutoStop;
        newTower.TowerBaseAccessStep = towerDefaults.TowerBaseAccessStep;
        newTower.TowerCrossBrace = towerDefaults.TowerCrossBrace;
        newTower.TowerType = towerDefaults.TowerType;
    }
    if (!newTower.Tires) {
        newTower.Tires = {}
    }
    // END: create the new tower

    return { span: newSpan, tower: newTower };
}

registerExecutor(actionTypeId, (action: IAction, state: IProject) => {
    const data = action.data as ActionData;
    const sys = state.layouts[data.layoutId].systems[data.systemId];
    const side = getSide(sys, data.side);

    const spans = side.Span;
    
    let endBoom: ISpan | undefined;
    if (spans.length !== 0 && spans[spans.length - 1].EndBoom) {
        endBoom = spans.pop();
    }

    let swingArm: ISpan | undefined;
    if (spans.length !== 0 && spans[spans.length - 1].SwingArm) {
        swingArm = spans.pop();
    }
    const towers = side.Tower;

    let newId = (spans && spans.length > 0) ? Math.max(...spans.map(x => x.SpanNumber)) + 1 : 1;
    
    // START: create the new span and tower
    const newSpanAndTower = createSpanAndTower(newId, data.spanDefaults, data.towerDefaults);
    const newSpan = newSpanAndTower.span;
    const newTower = newSpanAndTower.tower;
    if (!newTower.TowerType) {
        newTower.TowerType = (towers && towers.length) 
            ? towers[towers.length - 1].TowerType 
            : undefined;
    }
    // END: create the new span and tower

    spans.push(newSpan); 
    towers.push(newTower); 


    if (swingArm) {
        spans.push(swingArm);
    }
    if (endBoom) {
        spans.push(endBoom);
    }

    renumberSpanAndTowers(side);
    switchSidesIfNeeded(sys);
    
    recordProjectChange(action, state);
    recordLayoutChange(action, state, data.layoutId);
    recordSystemChange(action, state, data.layoutId, data.systemId);
});

export const createAddSpanTowerAction = (
    layoutId: string,
    systemId: string,
    side: SideEnum,
    options: {
        spanDefaults?: ISpan,
        towerDefaults?: ITower,
    } = {},
    authState: IAuthState) => createAction(
        actionTypeId,
        {
            layoutId,
            systemId,
            side,
            spanDefaults: options.spanDefaults,
            towerDefaults: options.towerDefaults
        } as ActionData,
        authState);