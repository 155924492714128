import AllOutIcon from "@mui/icons-material/AllOut";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandIcon from "@mui/icons-material/Expand";
import {
    Chip,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
} from "@mui/material";
import { Feature } from "@turf/turf";
import i18next, { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import { ESegmentClearanceType } from "../../../../../GeometryHelpers/SegmentHelper";
import { createNewUpdateLayoutPropertyAction } from "../../../../../actions/UpdateLayoutProperty";
import AuthCtx from "../../../../../auth/AuthCtx";
import IAuthState from "../../../../../auth/IAuthState";
import { IEditMode, IOpts, SEGMENT_SELECT } from "../../../../../components/Map/SegmentSelectMode";
import { IMapContext, MapContext } from "../../../../../components/Map/mapContext";
import DbCtx from "../../../../../db/DbCtx";
import IDbProject from "../../../../../db/IDbProject";
import { IMapFeaturePermissions } from "../mapFeaturePermissions";
import SetClearancesDialog, { getCanSetAllClearances, getCanSetClearanceTypes } from "./SetClearancesDialog";

interface Props {
    feature: Feature;
    projectId: string;
    layoutId: string;
    onDeleteCallback: () => void;
    permissions: IMapFeaturePermissions;
    currentSegmentSelectEditMode?: IEditMode;
}

const getTitle = (feature: Feature) => {
    let title = "";
    switch (feature.properties.rdpFeatureType) {
        // Title
        case 'electricline': 
            title = i18next.format(t('electric-line'), 'capitalize-each');
            break;
        case 'canal': 
            title = i18next.format(t('canal'), 'capitalize-each');
            break;
        case 'line': 
            title = i18next.format(t('line'), 'capitalize-each');
            break;
        case 'point': 
            title = i18next.format(t('point'), 'capitalize-each');
            break;
        case 'pump': 
            title = i18next.format(t('pump'), 'capitalize-each');
            break;
        case 'waterline': 
            title = i18next.format(t('water-line'), 'capitalize-each');
            break;
        case 'label': 
            title = i18next.format(t('label'), 'capitalize-each');
            break;
        case 'obstacle':
            title=i18next.format(t('span-obstacle'), 'capitalize-each');
            break;
        case 'wheelObstacle':
            title=i18next.format(t('wheel-obstacle'), 'capitalize-each');
            break;
        case 'wetAreaBoundary':
            title=i18next.format(t('wet-area-boundary'), 'capitalize-each');
            break;
        case 'pivotCenterBoundary':
            title=i18next.format(t('pivot-center-boundary'), 'capitalize-each');
            break;
        case 'fieldBoundary':
            title=i18next.format(t('field-boundary'), 'capitalize-each');
            break;
    }
    return title;
};

const getOnDelete = (
    feature: Feature,
    dbProject: IDbProject,
    layoutId: string,
    authState: IAuthState,
    permissions: IMapFeaturePermissions
): (() => void) | undefined => {
    const layout = dbProject.state.layouts[layoutId];
    switch (feature.properties.rdpFeatureType) {
        // onDelete
        case "electricline":
        case "canal":
        case "line":
        case "point":
        case "pump":
        case "waterline":
        case "label":
            return () => {
                const { annotationIndex } = feature.properties;
                if (annotationIndex === undefined || layout.annotations[annotationIndex] === undefined) return;
                const updatedAnnotations = structuredClone(layout.annotations);
                updatedAnnotations.splice(annotationIndex, 1);
                dbProject.pushAction(
                    createNewUpdateLayoutPropertyAction(layoutId, "annotations", updatedAnnotations, authState)
                );
            };
        case "obstacle":
            if (!permissions.editObstacles) return undefined;
            return () => {
                const { obstacleIndex } = feature.properties;
                if (obstacleIndex === undefined || layout.obstacles[obstacleIndex] === undefined) return;
                const updated = structuredClone(layout.obstacles);
                updated.splice(obstacleIndex, 1);
                dbProject.pushAction(createNewUpdateLayoutPropertyAction(layoutId, "obstacles", updated, authState));
            };
        case "wheelObstacle":
            if (!permissions.editObstacles) return undefined;
            return () => {
                const { wheelObstacleIndex } = feature.properties;
                if (wheelObstacleIndex === undefined || layout.wheelObstacles[wheelObstacleIndex] === undefined) return;
                const updated = structuredClone(layout.wheelObstacles);
                updated.splice(wheelObstacleIndex, 1);
                dbProject.pushAction(
                    createNewUpdateLayoutPropertyAction(layoutId, "wheelObstacles", updated, authState)
                );
            };
        case "wetAreaBoundary":
            if (!permissions.editBoundaries) return undefined;
            return () => {
                dbProject.pushAction(
                    createNewUpdateLayoutPropertyAction(layoutId, "wetAreaBoundary", undefined, authState)
                );
            };
        case "pivotCenterBoundary":
            if (!permissions.editBoundaries) return undefined;
            return () => {
                dbProject.pushAction(
                    createNewUpdateLayoutPropertyAction(layoutId, "pivotCenterBoundary", undefined, authState)
                );
            };
        case "fieldBoundary":
        default:
            // disable delete boundary for field boundary:
            return undefined;
    }
};

const getEnterSegmenmtSelectMode = (
    feature: Feature,
    editMode: ESegmentClearanceType,
    map: IMapContext,
    permissions: IMapFeaturePermissions
): (() => void) | undefined => {
    switch (feature.properties.rdpFeatureType) {
        case "obstacle":
        case "wheelObstacle":
            if (!permissions.editObstacles) return undefined;
            break;
        case "pivotCenterBoundary":
        case "fieldBoundary":
        case "wetAreaBoundary":
            if (!permissions.editBoundaries) return undefined;
            break;
    }
    switch (feature.properties.rdpFeatureType) {
        case "obstacle":
        case "wheelObstacle":
        case "pivotCenterBoundary":
        case "fieldBoundary":
            return () => {
                map.changeMode("simple_select");
                setTimeout(() => map.changeMode(SEGMENT_SELECT, { featureId: feature.id, editMode } as IOpts), 0);
            };

        case "wetAreaBoundary":
        default:
            return undefined;
    }
};

const getEnterSegmenmtSelectMode_DeleteVerticies = (
    feature: Feature,
    map: IMapContext,
    permissions: IMapFeaturePermissions
): (() => void) | undefined => {
    switch (feature.properties.rdpFeatureType) {
        case "obstacle":
        case "wheelObstacle":
            if (!permissions.editObstacles) return undefined;
            break;
        case "pivotCenterBoundary":
        case "fieldBoundary":
        case "wetAreaBoundary":
            if (!permissions.editBoundaries) return undefined;
            break;
    }
    switch (feature.properties.rdpFeatureType) {
        case "obstacle":
        case "wheelObstacle":
        case "pivotCenterBoundary":
        case "fieldBoundary":
        case "wetAreaBoundary":
            return () => {
                map.changeMode("simple_select");
                setTimeout(() => map.changeMode(SEGMENT_SELECT, { featureId: feature.id, editMode: 'deleteVerticies' } as IOpts), 0);
            };
        default:
            return undefined;
    }
};

const getEnterSegmenmtSelectMode_EditVerticies = (
    feature: Feature,
    map: IMapContext,
    permissions: IMapFeaturePermissions
): (() => void) | undefined => {
    switch (feature.properties.rdpFeatureType) {
        case "obstacle":
        case "wheelObstacle":
            if (!permissions.editObstacles) return undefined;
            break;
        case "pivotCenterBoundary":
        case "fieldBoundary":
        case "wetAreaBoundary":
            if (!permissions.editBoundaries) return undefined;
            break;
    }
    switch (feature.properties.rdpFeatureType) {
        case "obstacle":
        case "wheelObstacle":
        case "pivotCenterBoundary":
        case "fieldBoundary":
        case "wetAreaBoundary":
            return () => {
                map.changeMode("simple_select");
                setTimeout(() => map.changeMode(SEGMENT_SELECT, { featureId: feature.id, editMode: 'verticies' } as IOpts), 0);
            };
        default:
            return undefined;
    }
};

  
const MapSpeedDialFeatureSelected: FC<Props> = (props) => {
    const dbProject = React.useContext(DbCtx).projects[props.projectId];
    const authState = React.useContext(AuthCtx);
    const map = React.useContext(MapContext);

    //disable hover open/close for speeddial
    const [open, setOpen] = React.useState(true);

    const title = getTitle(props.feature);
    const handleDelete = getOnDelete(props.feature, dbProject, props.layoutId, authState, props.permissions);
    const handleDeleteVerticies = props.currentSegmentSelectEditMode !== 'deleteVerticies' && getEnterSegmenmtSelectMode_DeleteVerticies(props.feature, map, props.permissions);
    const handleEditVerticies = props.currentSegmentSelectEditMode !== 'verticies' && getEnterSegmenmtSelectMode_EditVerticies(props.feature, map, props.permissions);
    const handleEnterSegmentSelectClearanceMode = props.currentSegmentSelectEditMode !== ESegmentClearanceType.EquipmentClearance && getEnterSegmenmtSelectMode(
        props.feature,
        ESegmentClearanceType.EquipmentClearance,
        map, props.permissions
    );
    const handleEnterSegmentSelectSTowerClearanceMode = props.currentSegmentSelectEditMode !== ESegmentClearanceType.STowerClearance && getEnterSegmenmtSelectMode(
        props.feature,
        ESegmentClearanceType.STowerClearance,
        map, props.permissions
    );
    const handleEnterSegmentSelectHTowerClearanceMode = props.currentSegmentSelectEditMode !== ESegmentClearanceType.HTowerClearance && getEnterSegmenmtSelectMode(
        props.feature,
        ESegmentClearanceType.HTowerClearance,
        map, props.permissions
    );

    const [showEditAllClearancesDialog, setShowEditAllClearancesDialog] = React.useState<boolean>(false);

    const onClickShowClearanceDialog = () => {
        setShowEditAllClearancesDialog(true);
    };

    const clearanceTypes = getCanSetClearanceTypes(props.feature);

    return (
        <>
            <SpeedDial
                ariaLabel="Edit feature"
                sx={{ position: "absolute", bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                open={open}
                onClick={() => {
                    setOpen(!open);
                }}
                onMouseEnter={() => {}}
            >
                {handleDeleteVerticies && (
                    <SpeedDialAction
                        key="delete-verticies"
                        icon="DP"
                        tooltipTitle={<span>{i18next.format(`${i18next.t("delete-verticies")}`, 'capitalize-each')}</span>}
                        tooltipOpen={true}
                        onClick={() => {
                            handleDeleteVerticies();
                        }}
                        style={{
                            whiteSpace: "nowrap",
                        }}
                        
                    />
                )}
                {handleEditVerticies && (
                    <SpeedDialAction
                        key="edit-verticies"
                        icon="EP"
                        tooltipTitle={<span>{i18next.format(`${i18next.t("edit-verticies")}`, 'capitalize-each')}</span>}
                        tooltipOpen={true}
                        onClick={() => {
                            handleEditVerticies();
                        }}
                        style={{
                            whiteSpace: "nowrap",
                        }}
                    />
                )}
                {handleDelete && (
                    <SpeedDialAction
                        key="delete"
                        icon={<DeleteIcon />}
                        tooltipTitle={<span>{i18next.format(`${i18next.t("delete")}`, 'capitalize')}</span>}
                        tooltipOpen={true}
                        onClick={() => {
                            handleDelete();
                            props.onDeleteCallback();
                        }}
                    />
                )}
                {handleEnterSegmentSelectSTowerClearanceMode && clearanceTypes.sTower && (
                    <SpeedDialAction
                        key="sTowerClearance"
                        icon="SC"
                        tooltipTitle={<span>{t("map-display.speed-dial.s-tower-clearances")}</span>}
                        tooltipOpen={true}
                        onClick={handleEnterSegmentSelectSTowerClearanceMode}
                        style={{
                            whiteSpace: "nowrap",
                        }}
                    />
                )}
                {handleEnterSegmentSelectHTowerClearanceMode && clearanceTypes.hTower && (
                    <SpeedDialAction
                        key="hTowerClearance"
                        icon="HC"
                        tooltipTitle={<span>{t("map-display.speed-dial.h-tower-clearances")}</span>}
                        tooltipOpen={true}
                        onClick={handleEnterSegmentSelectHTowerClearanceMode}
                        style={{
                            whiteSpace: "nowrap",
                        }}
                    />
                )}
                {handleEnterSegmentSelectClearanceMode && clearanceTypes.equipment && (
                    <SpeedDialAction
                        key="edgeClearance"
                        icon={<ExpandIcon />}
                        tooltipTitle={<span>{t("map-display.speed-dial.edge-clearance")}</span>}
                        tooltipOpen={true}
                        onClick={handleEnterSegmentSelectClearanceMode}
                        style={{
                            whiteSpace: "nowrap",
                        }}
                    />
                )}
                {
                    getCanSetAllClearances(props.feature, props.permissions) && (
                        <SpeedDialAction
                            key="setClearance"
                            icon={<AllOutIcon />}
                            tooltipTitle={<span>{t("map-display.speed-dial.set-all-clearances")}</span>}
                            tooltipOpen={true}
                            onClick={onClickShowClearanceDialog}
                            style={{
                                whiteSpace: "nowrap",
                            }}
                        />
                    )
                }
            </SpeedDial>
            <Chip sx={{ position: "absolute", bottom: 28, right: 80 }} label={title} color="primary" />
            <SetClearancesDialog
                open={showEditAllClearancesDialog}
                onClose={() => setShowEditAllClearancesDialog(false)}
                feature={props.feature}
                projectId={props.projectId}
                layoutId={props.layoutId}
            />
        </>
    );
};

export default MapSpeedDialFeatureSelected;
