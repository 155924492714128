import i18next, { t } from "i18next";
import * as React from "react";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import { ERdpFeatureType } from "../../../helpers/ERdpFeatureTypes";
import { ERdpLabels } from "../../../helpers/ERdpLabels";
import ListItemCheckbox from "./ListItemCheckbox";
import NestedMenuListItem from "./NestedMenuListItem";

interface Props{
    className?: string;
}

const LabelsListItem: React.FC<Props> = (props) => {

    const settingsState = React.useContext(DevSettingsCtx).mapSettings;

    const checkedElevation = settingsState.mapLabels.includes(ERdpLabels.ELEVATION);

    // Boundaries:
    const checkedBoundaryEquipment = settingsState.mapLabels.includes(ERdpFeatureType.FIELD_BOUNDARY);
    const checkedBoundaryPivotCenter = settingsState.mapLabels.includes(ERdpFeatureType.PIVOT_CENTER_BOUNDARY);
    const checkedBoundaryWetArea = settingsState.mapLabels.includes(ERdpFeatureType.WET_AREA_BOUNDARY);
    const checkedBoundaries = checkedBoundaryEquipment && checkedBoundaryPivotCenter && checkedBoundaryWetArea;
    const intermediateBoundaries = !checkedBoundaries && (checkedBoundaryEquipment || checkedBoundaryPivotCenter || checkedBoundaryWetArea);
    const handleToggleBoundaries = () => {
        const nextState = new Set(settingsState.mapLabels);
        if (checkedBoundaries) {
            nextState.delete(ERdpFeatureType.FIELD_BOUNDARY);
            nextState.delete(ERdpFeatureType.PIVOT_CENTER_BOUNDARY);
            nextState.delete(ERdpFeatureType.WET_AREA_BOUNDARY);
        }
        else {
            nextState.add(ERdpFeatureType.FIELD_BOUNDARY);
            nextState.add(ERdpFeatureType.PIVOT_CENTER_BOUNDARY);
            nextState.add(ERdpFeatureType.WET_AREA_BOUNDARY);
        }
        settingsState.setMapLabels(Array.from(nextState));
    }
    
    // Obstacles:
    const checkedObstacleSpan = settingsState.mapLabels.includes(ERdpFeatureType.SPAN_OBSTACLE);
    const checkedObstacleWheel = settingsState.mapLabels.includes(ERdpFeatureType.WHEEL_OBSTACLE);
    const checkedObstacles = checkedObstacleSpan && checkedObstacleWheel;
    const intermediateObstacles = !checkedObstacles && (checkedObstacleSpan || checkedObstacleWheel);
    const handleToggleObstacles = () => {
        const nextState = new Set(settingsState.mapLabels);
        if (checkedObstacles) {
            nextState.delete(ERdpFeatureType.SPAN_OBSTACLE);
            nextState.delete(ERdpFeatureType.WHEEL_OBSTACLE);
        }
        else {
            nextState.add(ERdpFeatureType.SPAN_OBSTACLE);
            nextState.add(ERdpFeatureType.WHEEL_OBSTACLE);
        }
        settingsState.setMapLabels(Array.from(nextState));
    }
    
    // Annotations:
    const checkedAnnotationsCanal = settingsState.mapLabels.includes(ERdpFeatureType.CANAL_ANNOTATION);
    const checkedAnnotationsElectricLine = settingsState.mapLabels.includes(ERdpFeatureType.ELECTRIC_LINE_ANNOTATION);
    const checkedAnnotationsLine = settingsState.mapLabels.includes(ERdpFeatureType.LINE_ANNOTATION);
    const checkedAnnotationsPoint = settingsState.mapLabels.includes(ERdpFeatureType.POINT_ANNOTATION);
    const checkedAnnotationsPump = settingsState.mapLabels.includes(ERdpFeatureType.PUMP_ANNOTATION);
    const checkedAnnotationsWaterLine = settingsState.mapLabels.includes(ERdpFeatureType.WATER_LINE_ANNOTATION);
    const checkedAnnotations = checkedAnnotationsCanal &&
        checkedAnnotationsElectricLine &&
        checkedAnnotationsLine &&
        checkedAnnotationsPoint &&
        checkedAnnotationsPump &&
        checkedAnnotationsWaterLine;
    const intermediateAnnotations = !checkedAnnotations && (
        checkedAnnotationsCanal ||
        checkedAnnotationsElectricLine ||
        checkedAnnotationsLine ||
        checkedAnnotationsPoint ||
        checkedAnnotationsPump ||
        checkedAnnotationsWaterLine
    );
    const handleToggleAnnotations = () => {
        const nextState = new Set(settingsState.mapLabels);
        if (checkedAnnotations) {
            nextState.delete(ERdpFeatureType.CANAL_ANNOTATION);
            nextState.delete(ERdpFeatureType.ELECTRIC_LINE_ANNOTATION);
            nextState.delete(ERdpFeatureType.LINE_ANNOTATION);
            nextState.delete(ERdpFeatureType.POINT_ANNOTATION);
            nextState.delete(ERdpFeatureType.PUMP_ANNOTATION);
            nextState.delete(ERdpFeatureType.WATER_LINE_ANNOTATION);
        }
        else {
            nextState.add(ERdpFeatureType.CANAL_ANNOTATION);
            nextState.add(ERdpFeatureType.ELECTRIC_LINE_ANNOTATION);
            nextState.add(ERdpFeatureType.LINE_ANNOTATION);
            nextState.add(ERdpFeatureType.POINT_ANNOTATION);
            nextState.add(ERdpFeatureType.PUMP_ANNOTATION);
            nextState.add(ERdpFeatureType.WATER_LINE_ANNOTATION);
        }
        settingsState.setMapLabels(Array.from(nextState));
    }

    const handleToggleLabel = (label: ERdpFeatureType | ERdpLabels) => {
        const nextState = new Set(settingsState.mapLabels);
        nextState.has(label) ? nextState.delete(label) : nextState.add(label);
        settingsState.setMapLabels(Array.from(nextState));
    }

    // Systems:
    const checkedSystemsCenterPivotSpan = settingsState.mapLabels.includes(ERdpFeatureType.CENTER_PIVOT_SYSTEM);
    const checkedSystemsLateral = settingsState.mapLabels.includes(ERdpFeatureType.LATERAL_SYSTEM);
    const checkedSystemsWheelTracks = settingsState.mapLabels.includes(ERdpFeatureType.WHEEL_TRACKS);
    const checkedSystems = checkedSystemsCenterPivotSpan && checkedSystemsLateral && checkedSystemsWheelTracks;
    const intermediateSystems = !checkedSystems && (checkedSystemsCenterPivotSpan || checkedSystemsLateral || checkedSystemsWheelTracks);
    const handleToggleSystems = () => {
        const nextState = new Set(settingsState.mapLabels);
        if (checkedSystems) {
            nextState.delete(ERdpFeatureType.CENTER_PIVOT_SYSTEM);
            nextState.delete(ERdpFeatureType.LATERAL_SYSTEM);
            nextState.delete(ERdpFeatureType.WHEEL_TRACKS);
        }
        else {
            nextState.add(ERdpFeatureType.CENTER_PIVOT_SYSTEM);
            nextState.add(ERdpFeatureType.LATERAL_SYSTEM);
            nextState.add(ERdpFeatureType.WHEEL_TRACKS);
        }
        settingsState.setMapLabels(Array.from(nextState));
    }

    const ns = "nav.labels."

    return (
        <NestedMenuListItem
            className={props.className}
            parentItem={
                <ListItemCheckbox
                    checked={settingsState.showLabels}
                    label={i18next.format(t(ns + 'show-labels'), 'capitalize-each')}
                    onClick={() => settingsState.setShowLabels(!settingsState.showLabels)}
                />
            }
        >
            <ListItemCheckbox
                checked={checkedElevation}
                label={i18next.format(t('elevation'), 'capitalize')}
                onClick={() => handleToggleLabel(ERdpLabels.ELEVATION)}
                disabled={!settingsState.showLabels}
            />
            {/* Boundaries */}
            <NestedMenuListItem
                parentItem={
                    <ListItemCheckbox
                        indeterminate={intermediateBoundaries}
                        checked={checkedBoundaries}
                        label={i18next.format(t('boundaries'), 'capitalize')}
                        onClick={handleToggleBoundaries}
                        disabled={!settingsState.showLabels}
                    />
                }
            >
                <ListItemCheckbox
                    checked={checkedBoundaryEquipment}
                    label={i18next.format(t('equipment'), 'capitalize')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.FIELD_BOUNDARY)}
                    disabled={!settingsState.showLabels}
                />
                <ListItemCheckbox
                    checked={checkedBoundaryPivotCenter}
                    label={i18next.format(t('pivot-center'), 'capitalize-each')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.PIVOT_CENTER_BOUNDARY)}
                    disabled={!settingsState.showLabels}
                />
                <ListItemCheckbox
                    checked={checkedBoundaryWetArea}
                    label={i18next.format(t('wet-area'), 'capitalize-each')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.WET_AREA_BOUNDARY)}
                    disabled={!settingsState.showLabels}
                />

            </NestedMenuListItem>
            
            {/* Obstacles */}
            <NestedMenuListItem
                parentItem={
                    <ListItemCheckbox
                        indeterminate={intermediateObstacles}
                        checked={checkedObstacles}
                        label={i18next.format(t('obstacles'), 'capitalize')}
                        onClick={handleToggleObstacles}
                        disabled={!settingsState.showLabels}
                    />
                }
            >
                <ListItemCheckbox
                    checked={checkedObstacleSpan}
                    label={i18next.format(t('span'), 'capitalize')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.SPAN_OBSTACLE)}
                    disabled={!settingsState.showLabels}
                />
                <ListItemCheckbox
                    checked={checkedObstacleWheel}
                    label={i18next.format(t('wheel'), 'capitalize')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.WHEEL_OBSTACLE)}
                    disabled={!settingsState.showLabels}
                />

            </NestedMenuListItem>
            
            {/* Annotations */}
            <NestedMenuListItem
                parentItem={
                    <ListItemCheckbox
                        indeterminate={intermediateAnnotations}
                        checked={checkedAnnotations}
                        label={i18next.format(t('annotations'), 'capitalize')}
                        onClick={handleToggleAnnotations}
                        disabled={!settingsState.showLabels}
                    />
                }
            >
                <ListItemCheckbox
                    checked={checkedAnnotationsCanal}
                    label={i18next.format(t('canal'), 'capitalize')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.CANAL_ANNOTATION)}
                    disabled={!settingsState.showLabels}
                />
                <ListItemCheckbox
                    checked={checkedAnnotationsElectricLine}
                    label={i18next.format(t('electric-line'), 'capitalize-each')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.ELECTRIC_LINE_ANNOTATION)}
                    disabled={!settingsState.showLabels}
                />
                <ListItemCheckbox
                    checked={checkedAnnotationsLine}
                    label={i18next.format(t('line'), 'capitalize')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.LINE_ANNOTATION)}
                    disabled={!settingsState.showLabels}
                />
                <ListItemCheckbox
                    checked={checkedAnnotationsPoint}
                    label={i18next.format(t('point'), 'capitalize')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.POINT_ANNOTATION)}
                    disabled={!settingsState.showLabels}
                />
                <ListItemCheckbox
                    checked={checkedAnnotationsPump}
                    label={i18next.format(t('pump'), 'capitalize')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.PUMP_ANNOTATION)}
                    disabled={!settingsState.showLabels}
                />
                <ListItemCheckbox
                    checked={checkedAnnotationsWaterLine}
                    label={i18next.format(t('water-line'), 'capitalize-each')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.WATER_LINE_ANNOTATION)}
                    disabled={!settingsState.showLabels}
                />

            </NestedMenuListItem>
            
            {/* Systems */}
            <NestedMenuListItem
                parentItem={
                    <ListItemCheckbox
                        indeterminate={intermediateSystems}
                        checked={checkedSystems}
                        label={i18next.format(t('systems'), 'capitalize')}
                        onClick={handleToggleSystems}
                        disabled={!settingsState.showLabels}
                    />
                }
            >
                <ListItemCheckbox
                    checked={checkedSystemsCenterPivotSpan}
                    label={i18next.format(t('center-pivot'), 'capitalize-each')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.CENTER_PIVOT_SYSTEM)}
                    disabled={!settingsState.showLabels}
                />
                <ListItemCheckbox
                    checked={checkedSystemsLateral}
                    label={i18next.format(t('lateral'), 'capitalize')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.LATERAL_SYSTEM)}
                    disabled={!settingsState.showLabels}
                />
                <ListItemCheckbox
                    checked={checkedSystemsWheelTracks}
                    label={i18next.format(t('common-phrases.sltWheelTrack'), 'capitalize-each')}
                    onClick={() => handleToggleLabel(ERdpFeatureType.WHEEL_TRACKS)}
                    disabled={!settingsState.showLabels}
                />

            </NestedMenuListItem>

        </NestedMenuListItem>

    );
};

export default LabelsListItem;
