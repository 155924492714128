import { convertLength } from "@turf/turf";
import { endBoomLengthsFeet } from "rdptypes/reinkeComponents";
import { DisplayLengthUnitBuilder, DisplayLengthUnits } from "../helpers/lengths";

export const getEndBoomLengths = (units: DisplayLengthUnits) => {
    return endBoomLengthsFeet.map(x => {
        return {
            originalValue: x,
            convertedValue: convertLength(x, 'feet', units),
            label: new DisplayLengthUnitBuilder(x, 'feet')
                .convert(units)
                .appendValue(units === 'feet' ? undefined : 1)
                .appendSymbol()
                .toString()
        }
    })
}