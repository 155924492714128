import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Feature } from "geojson";
import { CircleSelectModeExtension } from "./CircleSelectModeExtension";
import ISelectModeExtension from "./ISelectModeExtension";

import { AllGeoJSON, LineString, MultiLineString, Point, Polygon, area, bearing, booleanEqual, center, convertArea, lineDistance, lineString, midpoint, point, segmentEach } from "@turf/turf";
import i18next, { t } from "i18next";
import { rdpFunctionsFromMap } from ".";
import { ILineElevation } from "../../GeometryHelpers/GeometryProvider";
import CenterPivotGeometryHelper from "../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper from "../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { staticDevSettingsDbProvider } from "../../db/DevSettingsDbProvider";
import { ERdpFeatureType } from "../../helpers/ERdpFeatureTypes";
import { ERdpLabels } from "../../helpers/ERdpLabels";
import { DisplayAreaUnitBuilder } from "../../helpers/areas";
import { DisplayLengthUnitBuilder } from "../../helpers/lengths";
import { isPartialPivot } from "../../helpers/system";
import { AnnotationType, annotationTypeToString } from "../../model/project/IAnnotation";
import { IOptimizedSystem } from "../../optimization/centerPivot";
import { costPerAcre } from "../../optimization/objectives/compareSystems";
import { SectorSelectModeExtension } from "./SectorSelectModeExtension";
import { SEGMENT_SELECT } from "./SegmentSelectMode";
import * as Constants from "./copied-from-mapbox-gl-draw/constants";
import createSupplementaryPoints from "./copied-from-mapbox-gl-draw/create_supplementary_points";
import doubleClickZoom from './copied-from-mapbox-gl-draw/double_click_zoom';

const SimpleSelectMode = MapboxDraw.modes.simple_select;
export const ExtSimpleSelectMode = { ...SimpleSelectMode };
export default ExtSimpleSelectMode;

const extensions: ISelectModeExtension[] = [
    CircleSelectModeExtension,
    SectorSelectModeExtension
];

const RESOLUTION_FEET = 10;

const prettyPrintFeatureType = (rdpFeatureType: string): string => {
    switch (rdpFeatureType) {
        case "canal": return annotationTypeToString(AnnotationType.canal, true);
        case "waterline": return annotationTypeToString(AnnotationType.waterLine, true);
        case "electricline": return annotationTypeToString(AnnotationType.electricLine, true);
        case "line": return annotationTypeToString(AnnotationType.line, true);
        case "pump": return annotationTypeToString(AnnotationType.pump, true);
        case "point": return annotationTypeToString(AnnotationType.point, true);

        case "fieldBoundary": return i18next.format(i18next.t('field-boundary'), 'capitalize-each');   
        case "wetAreaBoundary": return i18next.format(i18next.t('wet-area-boundary'), 'capitalize-each');   
        case "pivotCenterBoundary": return i18next.format(i18next.t('pivot-center-boundary'), 'capitalize-each');   
        case "obstacle": return i18next.format(i18next.t('span-obstacle'), 'capitalize-each');   
        case "wheelObstacle": return i18next.format(i18next.t('wheel-obstacle'), 'capitalize-each');   
        default:
            return "";
    }
}

const createDisplayLabel = (geojson: AllGeoJSON, label: string): (Feature<Point> | undefined) => {
    let best: { length: number, segment: LineString } | undefined = undefined;
    segmentEach(geojson, (segment) => {
        const l = lineDistance(segment);
        if (!best || best.length < l) {
            best = {
                length: l,
                segment: segment.geometry
            }
        }
    });
    if (!best) return undefined;
    const position = midpoint(best.segment.coordinates[0], best.segment.coordinates[1]);
    // Note: rotation is read by the mapDrawStyles style sheet
    let rotation = ((bearing(best.segment.coordinates[0], best.segment.coordinates[1]) + 360) % 180 - 90);
    
    return point(position.geometry.coordinates, {
        display_label: label,
        rotation: rotation
    })
}

const handleElevationLabel = (geojson: Feature, map: mapboxgl.Map, display: (geojson: any) => void) => {
    if (!staticDevSettingsDbProvider.mapSettings.showLabels.get() || 
        !staticDevSettingsDbProvider.mapSettings.mapLabels.get().includes(ERdpLabels.ELEVATION) ||
        !staticDevSettingsDbProvider.mapSettings.mapLabels.get().includes(geojson.properties.user_rdpFeatureType)) return;

    switch (geojson.properties.user_rdpFeatureType) {
        case ERdpFeatureType.FIELD_BOUNDARY: {
            // handleFieldBoundaryElevationLabel(geojson, map, display);
            break;
        }
        case ERdpFeatureType.CENTER_PIVOT_SYSTEM: {
            handleCenterPivotElevationLabel(geojson, map, display);
            break;
        }
        case ERdpFeatureType.LATERAL_SYSTEM: {
            handleLateralElevationLabel(geojson, map, display);
            break;
        }
        case ERdpFeatureType.CANAL_ANNOTATION: 
        case ERdpFeatureType.WATER_LINE_ANNOTATION: 
        case ERdpFeatureType.ELECTRIC_LINE_ANNOTATION: 
        case ERdpFeatureType.LINE_ANNOTATION: {
            handleAnnotationLineElevationLabel(geojson, map, display);
            break;
        }
            
    
        default:
            break;
    }

}

const handleCenterPivotElevationLabel = (geojson: Feature, map: mapboxgl.Map, display: (geojson: any) => void) => {
    const geometryState = rdpFunctionsFromMap(map).getGeometryState();
    if (!geometryState?.elevation?.available) return;
    if (CenterPivotGeometryHelper.isCenterPivot(geojson as any)) {
        const definition = CenterPivotGeometryHelper.getDefinition(geojson as any);
        const systemElevationObj = geometryState?.elevation.systems[definition.systemId];
        if (!systemElevationObj || systemElevationObj.loading) return;
        const systemElevation = systemElevationObj.systemElevation;
        if (!systemElevation || systemElevation.type !== "centerPivot") return;
        const system = rdpFunctionsFromMap(map)?.getProject()?.layouts[definition.layoutId]?.systems[definition.systemId];
        if (!system) return;

        const { lengths } = staticDevSettingsDbProvider.display.get();
        
        const systemMin = systemElevation.system?.min;
        if (systemMin?.properties?.relativeToCenter) {
            display(point(systemMin.geometry.coordinates, {
                display_label: `${system.name} elevation minimum:\n` + new DisplayLengthUnitBuilder(systemMin.properties.relativeToCenter, 'meters')
                    .convert(lengths)
                    .appendValue(1)
                    .appendString(" ")
                    .appendShortName()
                    .toString(),
                rotation: 0,
                user_rdpFeatureType: 'elevation-min'
            }))
        }
        
        const systemMax = systemElevation.system?.max;
        const systemMaxFeed = systemElevation.center;
        if (
            systemMax?.properties?.relativeToCenter !== null && 
            systemMaxFeed?.properties?.relativeToCenter !== null
        ) {
            // display(
            //     lineString(
            //         [
            //             systemMax.geometry.coordinates,
            //             systemMaxFeed.geometry.coordinates
            //         ], {
            //             user_rdpFeatureType: 'system-max-elevation-line'
            //         }
            //     )
            // )
            display(
                point(
                    systemMax.geometry.coordinates, {
                        display_label: `${system.name} system maximum:\n` + new DisplayLengthUnitBuilder(systemMax.properties.relativeToCenter, 'meters')
                            .convert(lengths)
                            .appendString("+")
                            .appendValue(1)
                            .appendString(" ")
                            .appendShortName()
                            .toString(),
                        rotation: 0,
                        user_rdpFeatureType: 'system-max-elevation-point'
                    }
                )
            )
        }
    }
}

const handleLineLabels = (lineLabel: string, lineElevation: ILineElevation, display: (geojson: any) => void) => {
    if (!lineElevation) return;

    const { lengths } = staticDevSettingsDbProvider.display.get();
    const lineMin = lineElevation.min;
    const lineMax = lineElevation.max;
    const lineStart = lineElevation.start;
    const lineEnd = lineElevation.end;

    let minLabel = `${lineLabel} line min`;
    let showStart = lineStart.properties?.relativeToCenter !== null;
    let showEnd = lineEnd.properties?.relativeToCenter !== null;
    if (booleanEqual(lineMin, lineStart)) {
        minLabel += "/start";
        showStart = false;
    }
    if (booleanEqual(lineMin, lineEnd)) {
        minLabel += "/end";
        showEnd = false;
    }
    
    let maxLabel = `${lineLabel} line max`;
    if (booleanEqual(lineMax.geometry, lineStart.geometry)) {
        maxLabel += "/start";
        showStart = false;
    }
    if (booleanEqual(lineMax, lineEnd)) {
        maxLabel += "/end";
        showEnd = false;
    }

    if (lineMin.properties?.relativeToCenter !== null) {
        display(point(lineMin.geometry.coordinates, {
            display_label: `${minLabel}:\n` + new DisplayLengthUnitBuilder(lineMin.properties.relativeToCenter, 'meters')
                .convert(lengths)
                .appendValue(1)
                .appendString(" ")
                .appendShortName()
                .toString(),
            rotation: 0,
            user_rdpFeatureType: 'elevation-min',
            textAnchor: 'top'
        }))
    }
    if (lineMax.properties?.relativeToCenter !== null) {
        display(point(lineMax.geometry.coordinates, {
            display_label: `${maxLabel}:\n` + new DisplayLengthUnitBuilder(lineMax.properties.relativeToCenter, 'meters')
                .convert(lengths)
                .appendString('+')
                .appendValue(1)
                .appendString(" ")
                .appendShortName()
                .toString(),
            rotation: 0,
            user_rdpFeatureType: 'elevation-max',
            textAnchor: 'top'
        }))
    }
    if (showStart) {
        display(point(lineStart.geometry.coordinates, {
            display_label: `${lineLabel} line start:\n` + new DisplayLengthUnitBuilder(lineStart.properties.relativeToCenter, 'meters')
                .convert(lengths)
                .appendString(lineStart.properties.relativeToCenter > 0 ? '+' : '')
                .appendValue(1)
                .appendString(" ")
                .appendShortName()
                .toString(),
            rotation: 0,
            user_rdpFeatureType: lineStart.properties.relativeToCenter > 0 ? 'elevation-max' : 'elevation-min',
            textAnchor: 'top'
        }))
    }
    if (showEnd) {
        display(point(lineEnd.geometry.coordinates, {
            display_label: `${lineLabel} line end:\n` + new DisplayLengthUnitBuilder(lineEnd.properties.relativeToCenter, 'meters')
                .convert(lengths)
                .appendString(lineEnd.properties.relativeToCenter > 0 ? '+' : '')
                .appendValue(1)
                .appendString(" ")
                .appendShortName()
                .toString(),
            rotation: 0,
            user_rdpFeatureType: lineEnd.properties.relativeToCenter > 0 ? 'elevation-max' : 'elevation-min',
            textAnchor: 'top'
        }))
    }

}
const handleLateralElevationLabel = (geojson: Feature, map: mapboxgl.Map, display: (geojson: any) => void) => {
    const geometryState = rdpFunctionsFromMap(map).getGeometryState();
    if (!geometryState?.elevation?.available) return;
    if (LateralGeometryHelper.isLateral(geojson as any)) {
        const definition = LateralGeometryHelper.getDefinition(geojson as any);
        const systemElevationObj = geometryState?.elevation.systems[definition.systemId];
        if (!systemElevationObj || systemElevationObj.loading) return;
        const systemElevation = systemElevationObj.systemElevation;
        if (!systemElevation || systemElevation.type !== "lateral") return;
        const system = rdpFunctionsFromMap(map)?.getProject()?.layouts[definition.layoutId]?.systems[definition.systemId];
        if (!system) return;

        const { lengths } = staticDevSettingsDbProvider.display.get();
        handleLineLabels(system.name, systemElevation.feedLine, display);

        const systemMax = systemElevation.system?.max;
        const systemMaxFeed = systemElevation.system?.feed;
        if (
            systemMax?.properties?.relativeToCenter !== null && 
            systemMaxFeed?.properties?.relativeToCenter !== null
        ) {
            display(
                lineString(
                    [
                        systemMax.geometry.coordinates,
                        systemMaxFeed.geometry.coordinates
                    ], {
                        user_rdpFeatureType: 'system-max-elevation-line'
                    }
                )
            )
            display(
                point(
                    systemMax.geometry.coordinates, {
                        display_label: `${system.name} system maximum:\n` + new DisplayLengthUnitBuilder(systemMax.properties.relativeToCenter, 'meters')
                            .convert(lengths)
                            .appendString("+")
                            .appendValue(1)
                            .appendString(" ")
                            .appendShortName()
                            .toString(),
                        rotation: 0,
                        user_rdpFeatureType: 'system-max-elevation-point'
                    }
                )
            )
        }
    }
}

const handleAnnotationLineElevationLabel = (geojson: Feature, map: mapboxgl.Map, display: (geojson: any) => void) => {
    const geometryState = rdpFunctionsFromMap(map).getGeometryState();
    if (!geometryState?.elevation?.available) return;
    if (geojson.geometry.type !== 'LineString' || !geojson.geometry.coordinates.length) return;
    
    const line = geojson as Feature<LineString>;
    const lineElevation = geometryState?.elevation.getLine(line.geometry);
    handleLineLabels("", lineElevation, display);
}

const handleCenterPivotCostPerAcreLabel = (geojson: Feature, map: mapboxgl.Map, display: (geojson: any) => void) => {
    if (geojson.geometry.type !== 'Point') return;
    if (CenterPivotGeometryHelper.isCenterPivot(geojson as any)) {
        const definition = CenterPivotGeometryHelper.getDefinition(geojson as any);
        const system = rdpFunctionsFromMap(map)?.getProject()?.layouts[definition.layoutId]?.systems[definition.systemId];
        if (!system) return;

        const os: IOptimizedSystem = {
            center: system.centerPivot.point,
            primaryEndGun: system.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypePrimary,
            secondaryEndGun: system.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypeSecondary,
            spanTowers: [],            
        };
        if (isPartialPivot(system)) {
            os.clockwiseCompassHeadingStart = system.Circle.CenterPivot.clockwiseCompassHeadingStart;
            os.clockwiseCompassHeadingEnd = system.Circle.CenterPivot.clockwiseCompassHeadingEnd;
        }
        const spans = (system.FlangedSide?.Span || []);
        for (let i = 0; i < spans.length; i++) {
            const span = system.FlangedSide.Span[i];
            const tower = system.FlangedSide.Tower[i];
            if (span.EndBoom) {
                // pass
            }
            else if (span.SwingArm) {
                // pass
            }
            else {
                os.spanTowers.push({
                    extension: span.Extension,
                    spanLength: span.Length,
                    anticlockwiseWrapAngleRelativeToPreviousSpanDegrees: tower.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
                    clockwiseWrapAngleRelativeToPreviousSpanDegrees: tower.clockwiseWrapAngleRelativeToPreviousSpanDegrees,
                })
            }
        }
        const cpa = costPerAcre(os) * 10000;
        console.log("cpa", cpa, os)
        display(point(system.centerPivot.point.coordinates, {
            display_label: `cpa: ${isFinite(cpa) ? cpa.toFixed(1) : "-"}`,
            textAnchor: "top"
        }))
    }
}

ExtSimpleSelectMode.toDisplayFeatures = function(this: MapboxDraw.DrawCustomModeThis, state, geojson: Feature, display) {

    geojson.properties.active = (this.isSelected(geojson.properties.id)) ? "true" : "false";
    // START display labels:
    handleElevationLabel(geojson, this.map, display);
    //handleCenterPivotCostPerAcreLabel(geojson, this.map, display);
    if (staticDevSettingsDbProvider.mapSettings.showLabels.get() && staticDevSettingsDbProvider.mapSettings.mapLabels.get().includes(geojson.properties.user_rdpFeatureType)) {
        switch (geojson.properties.user_rdpFeatureType) {
            case "pump":
            case "point": {
                    if (geojson.geometry.type !== 'Point') break;
                    const p = geojson.geometry as Point;
                    display(point(p.coordinates, {
                        display_label: prettyPrintFeatureType(geojson.properties.user_rdpFeatureType),
                        rotation: 0
                    }))
                    break;
                }
            case "canal":
            case "waterline":
            case "electricline":
            case "line": {
                if (geojson.geometry.type !== 'LineString') break;
                const line = geojson.geometry as LineString;
                const { lengths } = staticDevSettingsDbProvider.display.get();
                const len = lineDistance(geojson, {units: lengths}).toFixed(2) + " " + t(lengths);
                const display_label = prettyPrintFeatureType(geojson.properties.user_rdpFeatureType) + ": " + len;
                const dl = createDisplayLabel(line, display_label);
                if (dl) {
                    display(dl)
                }
                break;
            }
            case 'fieldBoundary':
            case 'wetAreaBoundary':
            case 'pivotCenterBoundary': 
            case 'obstacle': 
            case 'wheelObstacle': {
                if (geojson.geometry.type !== 'Polygon') break;
                const poly = geojson.geometry as Polygon;
                const { areas } = staticDevSettingsDbProvider.display.get();
                const a_m2 = area(geojson); 
                const a_acres = convertArea(a_m2, 'meters', 'acres');
                const irrigatedAreaString = new DisplayAreaUnitBuilder(a_acres, 'acres')
                    .convert(areas)
                    .appendValue(1)
                    .appendString(" ")
                    .appendLongName()
                    .toString();
                    
                const display_label = prettyPrintFeatureType(geojson.properties.user_rdpFeatureType) + ": " + irrigatedAreaString;
                const dl = createDisplayLabel(poly, display_label);
                if (dl) {
                    display(dl)
                }
                break;
            }
            case "system/pivotCenter": {
                    if (geojson.geometry.type !== 'Point') break;
                    const p = geojson.geometry as Point;
                    let display_label: string;
                    if (geojson.properties.user_system?.name) {
                        display_label = geojson.properties.user_system.name;
                    }
                    else {
                        display_label = i18next.format(i18next.t('center-pivot'), 'capitalize-each')
                    }
                    display(point(p.coordinates, {
                        display_label,
                        rotation: 0
                    }))
                }
                break;
            case "system/lateral": {
                    if (geojson.geometry.type !== 'LineString') break;
                    const line = geojson.geometry as LineString;
                    const mid = center(line);
                    // Note: rotation is read by the mapDrawStyles style sheet
                    let rotation = ((bearing(line.coordinates[0], line.coordinates[1]) + 360) % 180 - 90);
                    let display_label: string;
                    if (geojson.properties.user_system?.name) {
                        display_label = geojson.properties.user_system.name;
                    }
                    else {
                        display_label = i18next.format(i18next.t('lateral'), 'capitalize-each')
                    }  
                    const dl = createDisplayLabel(line, display_label);
                    if (dl) {
                        display(dl)
                    }
                }
                break;
            case "system/wheelTrack":
                if (geojson.geometry.type !== 'MultiLineString' || !geojson.properties?.user_rdpSpanNumberLabels) break;
                const rdpSpanNumberLabels = geojson.properties.user_rdpSpanNumberLabels as string[];
                const line = geojson.geometry as MultiLineString;
                for (let i = 0; i < line.coordinates.length; i++) {
                    const p = line.coordinates[i][0];
                    const display_label = rdpSpanNumberLabels[i];
                    if (!p || !display_label) continue;
                    display(point(p, {
                        display_label,
                        rotation: 0
                    }))
                }
                break;
            default:
                break;
        }
    }
    // END display labels

    display(geojson);
    (this as any).fireActionable();
    if (geojson.properties.active !== "true" || geojson.geometry.type === "Point") return;
    
    let supplementaryPoints: Feature[] | undefined | null;

    for (const ext of extensions) {
        if (ext.supportsEditingFeature(geojson)) {
            supplementaryPoints = ext.createSupplementaryPoints(geojson);
        }
    }

    if (!supplementaryPoints) {
        supplementaryPoints = createSupplementaryPoints(geojson) as Feature[];
    }

    supplementaryPoints.forEach(display);
};

const rdpFeaturePriority = (rdpFeatureType: string) => {
    switch (rdpFeatureType) {
        case "measurement":
        case "boundaryClearance":
        case "obstacleClearance":
        case 'fieldBoundary':
        case 'pivotCenterBoundary':
        case 'wetAreaBoundary':
        case 'obstacle':
        case 'wheelObstacle':
            return 1;
        default:
            return 2;
    }
}
const sortFeaturesByPriority = (features: MapboxDraw.DrawFeature[]) => {
    const sorted = features.slice().sort((f1, f2) => {
        const rdpFeatureType1 = f1.properties?.user_rdpFeatureType;
        const rdpFeatureType2 = f2.properties?.user_rdpFeatureType;
        return rdpFeaturePriority(rdpFeatureType1) - rdpFeaturePriority(rdpFeatureType2);
    })
    return sorted;
}

ExtSimpleSelectMode.clickOnFeature = function(this, state, e) {
    // mapbox zorder is hard to understand. And so, to control which feature is selected
    // when overlapping features exist, we are first finding the features at the click location.
    // 2, sorting them by our custom priority, 3, choosing the higest priority feature,
    // 4, setting this feature as the featureTarget on the event (as this event is passed on in cases)
    const featuresAtClick = this.featuresAt(e);
    const featureTarget = sortFeaturesByPriority(featuresAtClick)[0] as any;
    if (!featureTarget) return;
    e.featureTarget = featureTarget;
    if (CenterPivotGeometryHelper.isCenterPivot(featureTarget) && CenterPivotGeometryHelper.isEditable(featureTarget)) {
        // Stop everything
        doubleClickZoom.disable(this);
        this.stopExtendedInteractions(state);

        // Enter center pivot select mode        
        const featureId = featureTarget.properties.id;
        const incommingFeature = this.getFeature(featureId);
        const definition = CenterPivotGeometryHelper.getDefinition(incommingFeature as any);

        if (definition) {
            return this.changeMode("center_pivot_select", {
                definition,
                featureId,
            });
        }
    }
    else if (LateralGeometryHelper.isLateral(featureTarget)) {
        // Stop everything
        doubleClickZoom.disable(this);
        this.stopExtendedInteractions(state);

        // Enter lateral select mode        
        const featureId = featureTarget.properties.id;
        const incommingFeature = this.getFeature(featureId);
        const definition = LateralGeometryHelper.getDefinition(incommingFeature as any);

        if (definition) {
            return this.changeMode("lateral_select", {
                definition,
                featureId,
            });
        }
    }
    else if (
        featureTarget.properties?.user_rdpFeatureType === 'measurement'
    ) {
        // Do nothing: Don't allow this to be selected
    }
    else if (
        featureTarget.properties?.user_rdpFeatureType === 'boundaryClearance' ||
        featureTarget.properties?.user_rdpFeatureType === 'obstacleClearance'
    ) {
        // Act as though we clicked the parent feature:
        if (featureTarget.properties?.user_parentId) {
            const parentId = featureTarget.properties.user_parentId;
            const parentFeature = this.getFeature(parentId);
            if (parentFeature) {
                return this.changeMode(SEGMENT_SELECT, {
                  featureId: parentId
                });
            }
        }
    }
    else if (
        featureTarget.properties?.user_rdpFeatureType === 'fieldBoundary' ||
        featureTarget.properties?.user_rdpFeatureType === 'pivotCenterBoundary' ||
        featureTarget.properties?.user_rdpFeatureType === 'wetAreaBoundary' ||
        featureTarget.properties?.user_rdpFeatureType === 'obstacle' ||
        featureTarget.properties?.user_rdpFeatureType === 'wheelObstacle'
    ) {
        // Act as though we clicked the parent feature:
        if (featureTarget.properties?.id) {
            const featureId = featureTarget.properties.id;
            return this.changeMode(SEGMENT_SELECT, {
              featureId
            });
        }
    }
    else if (this.getFeature(featureTarget.properties.id)?.type === Constants.geojsonTypes.POINT) {
        return SimpleSelectMode.clickOnFeature.call(this, state, e);
    }
    else if (featureTarget.properties?.id) {
        // can only enter direct select mode if the feature is not a point
        const featureId = featureTarget.properties.id;
        // sometimes this fires after a feature has been deleted. For example,
        // if the user ends the dynamic lateral optimization with a double click
        // then the end vertex of the lateral is the feature, but this is deleted when leaving the
        // mode.
        // As a fix, only enter direc select if the feature exists:
        const fromStateFeature = this.getFeature(featureTarget.properties.id);
        if (fromStateFeature) {
            return this.changeMode(Constants.modes.DIRECT_SELECT, {
              featureId
            });
        }
        else {
            console.log("extSimpleSelect: feature no longer exists")
        }
    }
}