import { DefaultD3000, DefaultFitting, DefaultHoseDrop, DefaultKometSpray, DefaultLDN, DefaultRegulator, DefaultRigidDrop, DeviceTypes, DropTypes, ID3000, IFitting, IHoseDrop, IKometSpray, ILDN, IRegulator, IRigidDrop, NozzleClipTypes, OutletValveTypes, SuperSprayPadTypes } from "./ISprinklers";
import { EndGunTypes } from "./ISystemBase.AutoGenerated";

export default interface ISprinklerChart {
    IrrigationProperties: IIrrigationProperties;
    Outlet: IOutlet[];
    AdditionalSpanData?: IAdditionalSpanData[];
}

export interface IAdditionalSpanData {
    GPMDelivered: number;
    GPMRequired: number;
}

export const DefaultOutlet: IOutlet = {
    OutletNumber: 0,
    SpanNumber: 0,
    Location: 0,
    Radius: 0,
    HookLocation: 0,
    HookRadius: 0,
    RelativeHeight: 0,
    AbsoluteHeight: 0,
    TopOfDropHeight: 0,
    DeviceHeight: 0,
    IsTowerBoomBackOutlet: false,
    IsSACOutlet: false,
    IsEndBoomOutlet: false,
    Device: DeviceTypes.None,
    UseNelson3030: false,
    UseNelsonAD3030MT: false,
    ESP: false,
    DeviceDoubled: false,
    NozzleClip: NozzleClipTypes.None,
    Drop: DropTypes.None,
    DropLength: 0,
    Fitting: DefaultFitting,
    HoseDrop: DefaultHoseDrop,
    RigidDrop: DefaultRigidDrop,
    SuperSprayPad: SuperSprayPadTypes.CottonChemWhite,
    LDN: DefaultLDN,
    D3000: DefaultD3000,
    KometSpray: DefaultKometSpray,
    TrashBusterBody: false,
    Valve: OutletValveTypes.None,
    Regulator: DefaultRegulator,
    NozzleIndex: 0,
    Pressure: 0,
    GPMRequired: 0,
    GPMRequiredNonAdjusted: 0,
    GPMDelivered: 0,
    DownstreamGPM: 0,
    FurrowArmAdjustment: 0,
    MaximumSpacingSetting: 0,
    VRIControlled: false
}

export interface IOutlet {
    RelativeHeight: number;
    OutletNumber: number;
    VRIControlled: boolean;

    Device: DeviceTypes;
    NozzleClip: NozzleClipTypes;

    SpanNumber: number;
    
    IsTowerBoomBackOutlet: boolean;
    IsSACOutlet: boolean;
    IsEndBoomOutlet: boolean;
    DeviceDoubled: boolean;
    GPMDelivered: number;
    UseNelsonAD3030MT: boolean;
    TrashBusterBody: boolean;

    Location: number;

    NozzleIndex: number;
    UseNelson3030: boolean;

    Fitting: IFitting;

    Valve: OutletValveTypes;
    Drop: DropTypes;

    HoseDrop: IHoseDrop;
    RigidDrop: IRigidDrop;
    TopOfDropHeight?: number;
    DeviceHeight: number;
    AbsoluteHeight: number;
    Pressure: number;

    D3000: ID3000;
    ESP: boolean;
    HookLocation: number;
    DropLength?: number;

    Regulator: IRegulator;

    LDN: ILDN;
    KometSpray: IKometSpray;
    SuperSprayPad: SuperSprayPadTypes;

    MaximumSpacingSetting: number;
    GPMRequired: number;
    DownstreamGPM: number;
    GPMRequiredNonAdjusted: number;
    FurrowArmAdjustment: number;

    Radius: number;
    HookRadius: number;
}

export interface IIrrigationProperties {
    EndGunIrrigationProperties?: IEndGunIrrigationProperties;
    SecondaryEndGunIrrigationProperties?: IEndGunIrrigationProperties;
    ComputedEndPressure?: number;
    DesignedEndPressure?: number;
    CoverageRadiusWithEndGun?: number;
    CoverageRadiusWithoutEndGun?: number;
    CoverageRadiusWithoutSwingArm?: number;
    CoverageRadiusWithSecondaryEndGun?: number;
    PressureExceededCount?: number;
    PressureNotMetCount?: number;
    SideGPM?: number;
}

export interface IEndGunIrrigationProperties {
    EndGunTypeCalculated?: EndGunTypes;
    DiffuserCalculated?: boolean;
    Pressure?: number;//PSI
    Radius?: number;
    GPMDelivered?: number;
    LimitedByBoosterPump?: boolean;
    GPMRequired?: number;
    NozzleSize?: number;
}