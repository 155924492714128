import Undo from "@mui/icons-material/Undo";
import {
  Box,
  CssBaseline,
  Fab,
  IconButton,
  Toolbar,
  Tooltip
} from "@mui/material";
import i18next, { t } from "i18next";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CenterPivotGeometryHelper from "../../../GeometryHelpers/SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper from "../../../GeometryHelpers/SystemGeometryHelpers/LateralGeometryHelper";
import { createNewUndoAction } from "../../../actions/UndoAction";
import AuthCtx from "../../../auth/AuthCtx";
import FieldInformationAppBarItem from "../../../components/FieldInformationAppBarItem";
import RdpAppBar from "../../../components/RdpAppBar";
import SystemDiagramView from "../../../components/SystemDiagramView";
import SystemDesigner from "../../../components/roe/SystemDesigner";
import DbCtx from "../../../db/DbCtx";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import IDbProject from "../../../db/IDbProject";
import { DisplayAreaUnitBuilder } from "../../../helpers/areas";
import { ProjectType } from "../../../model/project/IProject";
import ValidationWorkerProvider from "../../../workers/ValidationWorker/SingleThreadValidationProvider";
import ValidationWorkerCtx from "../../../workers/ValidationWorker/ValidationWorkerCtx";
import CopyProjectButton from "./CopyProjectButton";
import DeleteSystemButton from "./DeleteSystemButton";
import ForceRedirectDialog from "./ForceRedirectDialog";
import ResetProposalButton from "./ResetProposalButton";
import SystemNameField from "./SystemNameField";

const UndoButton = () => {
  const { projectId, layoutId, systemId } = useParams();
  const dbState = useContext(DbCtx);
  const dbPrj = dbState.projects[projectId!];
  const authState = useContext(AuthCtx);

  const vws = useContext(ValidationWorkerCtx);
  return <Tooltip title={t("nav.undo")} placement="bottom">
      <IconButton
          size="large"
          aria-label="undo"
          onClick={async () => {
            const newProject = dbPrj.pushAction(createNewUndoAction(authState));
            await vws.validate((await newProject).layouts[layoutId].systems[systemId], [], "");
          }}
          color="inherit"
      >
          <Undo />
      </IconButton>
  </Tooltip>
};

const SystemDesign = () => {

  const settings = React.useContext(DevSettingsCtx);
  const [ irrigatedArea, setIrrigatedArea ] = useState<number | undefined>(undefined);
  const [ showBottomDrawer, setShowBottomDrawer ] = useState(false);

  const navigate = useNavigate();
  const dbState = useContext(DbCtx);

  const { projectId, layoutId, systemId } = useParams();
  const dbPrj = dbState.projects[projectId!];
  const project = dbPrj.state;
  const grower = dbState.growers[project.growerId];
  const [newDbPrj, setNewDbPrj] = useState<IDbProject>(undefined);

  // START: Refresh system designer
  // NOTE, when resetting the proposal, the rendered system designer will
  // hold values that become undefined. As such, we want to force the
  // system designer component to redraw. This is done by
  // changing the systemDesignerRefreshKey
  const [systemDesignerRefreshKey, setSystemDesignerRefreshKey] = useState(uuidv4());
  const forceRefreshSystemDesigner = () => {
    setSystemDesignerRefreshKey(uuidv4());
  }
  // END: Refresh system designer

  useEffect(() => { 
    // irrigated area:
    const system = project.layouts[layoutId!]?.systems[systemId!];
    if (!system || project.projectType !== ProjectType.LayoutAndDesign) {
      return;
    }
    switch (system.SystemProperties.SystemType) {
      case SystemTypes.CenterPivot: {
        const centerPivotGeometry = new CenterPivotGeometryHelper({
          project,
          layoutId: layoutId!,
          systemId: systemId!
        });
        setIrrigatedArea(centerPivotGeometry.irrigatedAreaAcres);
        break;
      }
      case SystemTypes.HoseFeedMaxigator:
        case SystemTypes.CanalFeedMaxigator:
        {
        const lateralGeometry = new LateralGeometryHelper({
          project,
          layoutId: layoutId!,
          systemId: systemId!
        });
        setIrrigatedArea(lateralGeometry.getIrrigatedAreaAcres());
        break;
      }
       
    
      default:
        break;
    }
  }, [project, layoutId, systemId]);

  const system = project.layouts[layoutId!]?.systems[systemId!];
  if (!system) {
    return <>{t("waiting-for-system")}</>;
  }
  const irrigatedAreaString = irrigatedArea
    ? new DisplayAreaUnitBuilder(irrigatedArea, 'acres')
      .convert(settings.dealerSettings.display.current.areas)
      .appendValue(1)
      .appendString(" ")
      .appendLongName()
      .toString()

    : "none";


    var rightContent = [];

    rightContent.push(<UndoButton />);

    if(dbPrj.state.projectType === ProjectType.DesignOnly){
      rightContent.push(
        <CopyProjectButton
          projectId={projectId}
          onCopy={(newProjectId) => {
            navigate(`/projects/${newProjectId}`);
          }}
        />);
    }
    rightContent.push(
      <ResetProposalButton
              projectId={projectId}
              layoutId={layoutId}
              systemId={systemId}
              onReset={() => {
                forceRefreshSystemDesigner();
              }}
            />
    );
    rightContent.push(
      <DeleteSystemButton
              projectId={projectId}
              layoutId={layoutId}
              systemId={systemId}
              onDelete={() => {
                if (dbPrj.state.projectType === ProjectType.DesignOnly){
                  navigate(`/projects?grower=${dbPrj.state.growerId}`);
                }
                else {
                  navigate(`/projects/${projectId}/layouts/${layoutId}/map`);
                }
              }}
            />
    );
  
  return (
    <ValidationWorkerProvider dbPrj={dbPrj} layoutId={layoutId} systemId={systemId} grower={grower}>
      <CssBaseline />
      <RdpAppBar
        leftContent={
          <>
            <SystemNameField />
            {
              project.projectType == ProjectType.LayoutAndDesign && (
                <FieldInformationAppBarItem 
                      project={project}
                      layoutId={layoutId}
                />
              )
            }
          </>
        }
        rightContent={rightContent }
        onClose={() =>  navigate( project.projectType === ProjectType.DesignOnly ? `/projects?grower=${project.growerId}` : `/projects/${projectId}/layouts/${layoutId}/map`)}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          alignItems: "center",
        }}
      >
        
        <Box>
          <Toolbar variant="dense" />
        </Box>

        <Box sx={{ overflow: "auto", flex: 1, padding: 2, width: "100%" }}>
          <SystemDesigner
            key={systemDesignerRefreshKey}
            dbPrj={dbPrj}
            layoutId={layoutId!}
            systemId={systemId!}
            sideViewOpen={project.projectType === ProjectType.LayoutAndDesign ? showBottomDrawer : undefined}
            supportsLiveProposal
          />
        </Box>
        { 
          project.projectType === ProjectType.LayoutAndDesign && showBottomDrawer && (
            <>
              <SystemDiagramView
                layoutId={layoutId!}
                systemId={systemId!}
                projectId={projectId!}
              />
            </>
          )
        }
      </Box>
      {
        project.projectType === ProjectType.LayoutAndDesign && (
          <Tooltip title={`${showBottomDrawer ? i18next.format(t('close'), 'capitalize') : i18next.format(t('open'), 'capitalize')} ${t("side-view")}`} placement="top">
              <Fab 
                  variant="extended"
                  disabled={project.projectType !== ProjectType.LayoutAndDesign}
                  color='primary'
                  aria-label="side-view" 
                  sx={{
                      marginBottom: 2,
                      position: "fixed",
                      bottom: showBottomDrawer ? 300 : 0,
                      left: "50%",
                      zIndex: 1300
                  }}
                  onClick={() => setShowBottomDrawer(!showBottomDrawer)}
              >
                  {t("side-view")}
              </Fab>
          </Tooltip>
        )
      }
      <ForceRedirectDialog 
        projectId={projectId}
        layoutId={layoutId}
        systemId={systemId}
      />
    </ValidationWorkerProvider>
  );
};

export default SystemDesign;
