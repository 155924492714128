import { BoosterPumpTypes, ElectricalFrequencies, EndGunTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { GPMRadiusHelper } from "./EndGunHelper.GPMRadiusHelper";
import EndGunInfo, { EndGunItem } from "./EndGunInfo";

export class CalculateGpmRadiusResult {
    public NozzleIndex: number;
    public GPM: number;
    public Radius: number;
    public Pressure: number;
    public EndGunNozzle: EndGunNozzle;
}

export class EndGunNozzle {
    public Diameter: number;
}

export class EndGunHelper {


    public BoosterPumpLimitExceeded: boolean = false;
    public NozzleLimitReached: boolean = false;
    public readonly EndGunType: EndGunTypes = EndGunTypes.None;
    public readonly UseDiffuser: boolean = false;
    public FrequencyHz: ElectricalFrequencies = ElectricalFrequencies.a50;
    public BoosterPumpType: BoosterPumpTypes = BoosterPumpTypes.None;
    private IsLateralMove: boolean = false;
    private SideGPM: number = 0;
    private SystemPipeRadius: number = 0;
    private SystemCoverageRadius: number = 0;
    public SystemEndPressure: number = 0;
    public PrimaryGunRadius: number = 0;
    public CurrentNozzleIndex: number = 0;
    public Nozzles: EndGunNozzle[] = [];
    private GPMRH: GPMRadiusHelper;
    constructor(LateralMove: boolean, SideGPM: number, BoosterPump: BoosterPumpTypes, Frequency: ElectricalFrequencies, SysPipeRadius: number, SysCoverageRadius: number, SysEndPressure: number, egType: EndGunTypes, Diffuser: boolean) {
        this.EndGunType = egType;
        this.UseDiffuser = Diffuser;
        this.BoosterPumpType = BoosterPump;
        this.FrequencyHz = Frequency;
        this.IsLateralMove = LateralMove;
        this.SideGPM = SideGPM;
        this.SystemPipeRadius = SysPipeRadius / 120;
        this.SystemCoverageRadius = SysCoverageRadius / 120;
        this.SystemEndPressure = SysEndPressure;
        if (this.EndGunType === EndGunTypes.R75 && this.SystemEndPressure < 40) {
            this.EndGunType = EndGunTypes.R75LP;
        }
        this.GPMRH = new GPMRadiusHelper(this);
        this.AddNozzles(this.EndGunType);
    }

    private AddNozzles = (egt: EndGunTypes) => {
        if (egt === EndGunTypes.SR100NV) {
            egt = EndGunTypes.SR100;
        }
        if (egt === EndGunTypes.DualP85) {
            egt = EndGunTypes.SingleP85;
        }
        let egItems: EndGunItem[] = EndGunInfo.filter((e) =>
            e.EndGunType === egt);
        egItems.forEach((i) => {
            this.GPMRH.EndGunItemsByType.push(i);
        }
        );
        this.Nozzles.push({
            Diameter: 0
        });
        let diameters: number[] = egItems.map((s) =>
            s.Nozzle);
        diameters.forEach((d) => {
            this.Nozzles.push({
                Diameter: d
            });
        }
        );
    }

    public get NozzleDiameter() {
        if (this.CurrentNozzleIndex === 0) {
            this.ComputeEndGunNozzle();
        }
        return this.Nozzles[this.CurrentNozzleIndex].Diameter;
    }

    public get Radius() {
        if (this.CurrentNozzleIndex === 0) {
            this.ComputeEndGunNozzle();
        }
        return this._radius;
    }

    private _radius: number;
    public get Pressure() {
        if (this.CurrentNozzleIndex === 0) {
            this.ComputeEndGunNozzle();
        }
        return this._pressure;
    }

    private _pressure: number;
    public get GPM() {
        if (this.CurrentNozzleIndex === 0) {
            this.ComputeEndGunNozzle();
        }
        return this._gpm;
    }

    private _gpm: number = 0;
    public get RequiredEndGunGPM() {
        let LengthWithPrimaryGun: number;
        let LengthWithSecondaryGun: number;
        if (this.PrimaryGunRadius > 0) {
            LengthWithPrimaryGun = this.SystemPipeRadius + this.PrimaryGunRadius;
            LengthWithSecondaryGun = this.SystemPipeRadius + this.GPMRH.CalculateGpmRadius().Radius;
        } else {
            LengthWithPrimaryGun = this.SystemPipeRadius + this.GPMRH.CalculateGpmRadius().Radius;
            LengthWithSecondaryGun = LengthWithPrimaryGun;
        }
        if (this.IsLateralMove) {
            return this.SideGPM * ((LengthWithSecondaryGun - this.SystemCoverageRadius) / LengthWithPrimaryGun);
        } else {
            return this.SideGPM * ((Math.pow(LengthWithSecondaryGun, 2) - Math.pow(this.SystemCoverageRadius, 2)) / Math.pow(LengthWithPrimaryGun, 2));
        }
    }

    public get MinimumRequiredEndGunGPM() {
        if (this.EndGunType === EndGunTypes.None) {
            return 0;
        }
        if ([EndGunTypes.SR100, EndGunTypes.SR100NV, EndGunTypes.SR75, EndGunTypes.DualP85, EndGunTypes.SimeWing, EndGunTypes.TwinMax, EndGunTypes.Twin101Ultra].indexOf(this.EndGunType) !== -1) {
            return 0.955 * this.RequiredEndGunGPM;
        }
        if ([EndGunTypes.SingleP85, EndGunTypes.R55i, EndGunTypes.R55, EndGunTypes.R75, EndGunTypes.R75LP].indexOf(this.EndGunType) !== -1) {
            return 0.975 * this.RequiredEndGunGPM;
        }
        throw new Error(`{NameOf(MinimumRequiredEndGunGPM)} does not yet have a calculation for {NameOf(EndGunTypes)}.{EndGunType}`);
    }

    private ComputeEndGunNozzle = () => {
        let result: CalculateGpmRadiusResult = {
            NozzleIndex: 0,
            GPM: 0,
            Radius: 0,
            Pressure: 0,
            EndGunNozzle: {
                Diameter: 0
            }
        };
        do {
            if (this.BoosterPumpLimitExceeded || this.NozzleLimitReached) {
                break;
            }
            if ([EndGunTypes.SR100, EndGunTypes.SR100NV].indexOf(this.EndGunType) !== -1 && this.Nozzles[this.CurrentNozzleIndex].Diameter >= 0.9 && this.BoosterPumpType === BoosterPumpTypes.TwoHP) {
                this.BoosterPumpLimitExceeded = true;
                this.PrevSmallerNozzle();
                result = this.GPMRH.CalculateGpmRadius();
                break;
            }
            if (!this.NextLargerNozzleIndex()) {
                break;
            }
            result = this.GPMRH.CalculateGpmRadius();
        } while (!(result.GPM >= this.MinimumRequiredEndGunGPM));
        this._gpm = result.GPM;
        this._radius = result.Radius;
        this._pressure = result.Pressure;
    }

    public PrevSmallerNozzle = () => {
        if (this.CurrentNozzleIndex > 0) {
            this.CurrentNozzleIndex -= 1;
        }
    }

    public NextLargerNozzleIndex = (): boolean => {
        if (this.CurrentNozzleIndex < this.Nozzles.length - 1) {
            this.CurrentNozzleIndex += 1;
            return true;
        } else {
            return false;
        }
    }

}

