import { View } from "@react-pdf/renderer";
import { t } from "i18next";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { getLastTowerDistanceFeet, getPercentOfCircleCoverage, getSystemHoursToApplyOneInch, getTowerSpeedFeetPerMinute, getValuesFromFieldDesign } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import IProject from "../../model/project/IProject";
import { inchesToMm } from "../DocumentGenerationHelpers";
import FixedWidthTable, { ICell, IRow } from "./table/FixedWidthTable";

export interface Props {
    quote: QuoteClass,
    depthMode: boolean;
    systemId: string;
    layoutId: string;
    project: IProject;
}

const PercentTimerTable: FC<Props> = (props) => {
    let {depthMode, quote, systemId, layoutId, project} = props;

    let systemPercentOfCircleCoverage = getPercentOfCircleCoverage(quote);

    const ns = "common-phrases."
    let rows: IRow[] = [{cells: [{
        text: depthMode ? (t(ns + "sltDepthApplied") + " " + t(ns + "sltInches")) : t(ns + "sltPercentTimerSetting"),
        widthPc: 20,
        textAlignCenter: true,
        bottomBorder: true
    },{
        text: depthMode ? (t(ns + "sltDepthApplied") + " " + t(ns + "sltMillimeter")) : (t(ns + "sltDepthApplied") + " " + t(ns + "sltInches")),
        widthPc: 20,
        textAlignCenter: true,
        bottomBorder: true
    }, {
        text: depthMode ? t(ns + "sltPercentTimerSetting") : (t(ns + "sltDepthApplied") + " " + t(ns + "sltMillimeter")),
        widthPc: 20,
        textAlignCenter: true,
        bottomBorder: true
    }, {
        text: t(ns + "sltFullCircle") + " " + t(ns + "sltHours"),
        widthPc: 20,
        textAlignCenter: true,
        bottomBorder: true
    }, {
        text: `${systemPercentOfCircleCoverage.toFixed(2)}% ${t(ns + "sltHours")}`,
        widthPc: 20,
        textAlignCenter: true,
        bottomBorder: true
    }]}];


    let towerSpeed = getTowerSpeedFeetPerMinute(quote);
    if (typeof(towerSpeed) === "number"){
        for (let pc = 0.1; pc <= 1; pc += 0.1) {
            let hoursPerRev = ((getLastTowerDistanceFeet(quote) * 0.105) / towerSpeed);
            if (!depthMode){
                hoursPerRev = hoursPerRev/(pc);
            }

            let valuesFromFieldDesign = getValuesFromFieldDesign(quote, systemId, layoutId, project);
            let systemHoursToApplyOneInch = getSystemHoursToApplyOneInch(quote, valuesFromFieldDesign);

            let oneInchPercent = typeof systemHoursToApplyOneInch === "undefined" ? undefined : hoursPerRev / systemHoursToApplyOneInch;
            let depthAppliedInches = typeof oneInchPercent === "undefined" ? undefined : (depthMode ? pc : oneInchPercent);
            let applicationPercent = typeof oneInchPercent === "undefined" ? undefined : oneInchPercent/(pc/100);

            let percentTimerCell: ICell =  {
                text: typeof applicationPercent === "undefined" ? "N/A" : depthMode ? applicationPercent.toFixed(2) + "%" : `${(pc * 100).toFixed(0)}%`,
                widthPc: 20,
                textAlignCenter: true
            };

            let depthAppliedInchesCell: ICell = {
                text: typeof depthAppliedInches === "undefined" ? "N/A" : depthAppliedInches.toFixed(2),
                widthPc: 20,
                textAlignCenter: true
            };

            let depthAppliedMmCell: ICell = {
                text: typeof depthAppliedInches === "undefined" ? "N/A" : inchesToMm(depthAppliedInches).toFixed(2),
                widthPc: 20,
                textAlignCenter: true
            };

            let cells: ICell[] = [depthAppliedInchesCell, depthAppliedMmCell];
            if (depthMode){
                cells.push(percentTimerCell);
            }
            else {
                cells.unshift(percentTimerCell);
            }
            cells.push({
                text: typeof applicationPercent === "undefined" ? "N/A" : depthMode? ((hoursPerRev/applicationPercent) * 100).toFixed(2) : hoursPerRev.toFixed(2),
                widthPc: 20,
                textAlignCenter: true
            }, {
                text:  typeof applicationPercent === "undefined" ? "N/A" : depthMode ? ((hoursPerRev/applicationPercent) * systemPercentOfCircleCoverage).toFixed(2) : (hoursPerRev * (systemPercentOfCircleCoverage/100)).toFixed(2),
                widthPc: 20,
                textAlignCenter: true
            });

            rows.push({cells});
        }
    }

    return (<View style={{paddingLeft: 15, paddingRight: 15}}>
            <FixedWidthTable data={{rows}}/>
        </View>
    );
};

export default PercentTimerTable;