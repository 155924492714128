export default interface IPartsCatalogEntry {
    description: string | null;
    mapicsDescription: string;
    customerReportsDescription: string | null;
    proposalSection: ProposalSection;
    upgradeToPartNumber: string | null;
    roeSuppressOnCustomerReports: boolean;
}

export enum ProposalSection {
    Standard = "std",
    Upgrade = "upg",
    Option = "opt"
}