import { Text, View } from "@react-pdf/renderer";
import { t } from "i18next";
import { BuckBoostTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { IsCenterFeed, IsDualSided } from "roedata/roe_migration/SystemFunctions";
import { SystemSpanSide, getSpans, getTowerBuckboosts } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import IDevSettingsState from "../../db/IDevSettingsState";
import { DisplayLengthUnitBuilder } from "../../helpers/lengths";
import ISystem from "../../model/project/ISystem";
import { documentStyles } from "../styles";
import FixedWidthTable, { ICell, IRow } from "./table/FixedWidthTable";

export interface IProposalSpan {
    number: string;
    type: string;
    lengthFeet: number | undefined; //undefined if EB
    ext: string;
    spacingInches: number | undefined;
    tireSize: string | undefined;//undefined if EB
    psiLoss: number;
    spanCable: string;
    endingLocationFeet: number;
}

export interface ISpanInformationTableTowerBuckBoost {
    buckboostType: BuckBoostTypes;
}

interface Props {
    system: ISystem;
    quote: QuoteClass;
};

let ns = "common-phrases.";

const getSpanTableHeaderRow = (): ICell[] => {
    return [
        {
            text: t(ns + "sltNumberNo"),
            header: true,
            widthPc: 9
        },
        {
            text: t(ns + "sltType"),
            header: true,
            widthPc: 12
        },
        {
            text: t(ns + "sltLength"),
            header: true,
            widthPc: 10
        },
        {
            text: t(ns + "sltExt"),
            header: true,
            widthPc: 5
        },
        {
            text: t(ns + "sltSpacing"),
            header: true,
            widthPc: 10
        },
        {
            text: t(ns + "sltWheelTrack"),
            header: true,
            widthPc: 12
        },
        {
            text: t(ns + "sltTireSize"),
            header: true,
            widthPc: 20
        },
        {
            text: t(ns + "sltPSILoss"),
            header: true,
            widthPc: 10
        },
        {
            text: t(ns + "sltCable"),
            header: true,
            widthPc: 12
        }
    ];
}

const convertSpanToTableRow = (span: IProposalSpan, settings: IDevSettingsState): ICell[] => {
    let spanLength = typeof span.lengthFeet !== "undefined" ? new DisplayLengthUnitBuilder(span.lengthFeet, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(0)
        .appendString(" ")
        .appendSymbol()
        .toString() : t("none");

    let spacing = typeof span.spacingInches !== "undefined" ? new DisplayLengthUnitBuilder(span.spacingInches, 'inches')
        .convert(settings.dealerSettings.display.current.minorLengths)
        .appendValue(0)
        .appendString(" ")
        .appendSymbol()
        .toString() : "N/A";
        
    let endingLocation = typeof span.endingLocationFeet !== "undefined" ? new DisplayLengthUnitBuilder(span.endingLocationFeet, 'feet')
        .convert(settings.dealerSettings.display.current.lengths)
        .appendValue(0)
        .appendString(" ")
        .appendSymbol()
        .toString() : "N/A";

    return [
        {
            text: span.number,
            widthPc: 9
        },
        {
            text: span.type,
            widthPc: 12
        },
        {
            text: spanLength,
            widthPc: 10
        },
        {
            text: span.ext,
            widthPc: 5
        },
        {
            text: spacing,
            widthPc: 10
        },
        {
            text: endingLocation,
            widthPc: 12
        },
        {
            text: span.tireSize,
            widthPc: 20
        },
        {
            text: isNaN(span.psiLoss) ? "N/A" : span.psiLoss.toFixed(1),
            widthPc: 10
        },
        {
            text: span.spanCable,
            widthPc: 12
        }
    ];
}

const convertTowerBuckBoostToRow = (tower: ISpanInformationTableTowerBuckBoost): ICell[] => {
    return [
        {
            text: tower.buckboostType === BuckBoostTypes.a15KVA ? t(ns + "slt15KVA") : t(ns + "slt22_5KVA"),
            widthPc: 10
        },
        {
            text: t(ns + "sltBuckandBoostTransformer1"),
            widthPc: 27
        },
        {
            text: t(ns + "sltTower"),
            widthPc: 63
        }
    ];
}

const SpanInformationTable: FC<Props> = (props) => {
    const settings = React.useContext(DevSettingsCtx);
    let {system, quote} = props;
    let flangedSideRows: IRow[] = [{
        cells: getSpanTableHeaderRow()
    }];

    let flangedSideSpans = getSpans(system, SystemSpanSide.flanged, quote);
    flangedSideSpans.forEach((span) => {
        flangedSideRows.push({
            cells: convertSpanToTableRow(span, settings)
        });
    });

    let flangedSideTowers = getTowerBuckboosts(quote, SystemSpanSide.flanged);
    flangedSideTowers.forEach((t) => {
        flangedSideRows.push({
            cells: convertTowerBuckBoostToRow(t)
        });
    });

    let flexSideRows: IRow[] = [{
        cells: getSpanTableHeaderRow()
    }];

    let flexSideSpans = getSpans(system, SystemSpanSide.flex, quote);
    flexSideSpans.forEach((span) => {
        flexSideRows.push({
            cells: convertSpanToTableRow(span, settings)
        });
    });
    
    let flexSideTowers = getTowerBuckboosts(quote, SystemSpanSide.flex);
    flexSideTowers.forEach((t) => {
        flexSideRows.push({
            cells: convertTowerBuckBoostToRow(t)
        });
    });

    let header1 = !IsDualSided(system) ? t(ns + "sltSpanInformation") : t(ns + "sltRightSpanInfo");
    let header2 = IsCenterFeed(system) ? t(ns + "sltLeftSpanInfo") : t(ns + "sltPowerTowerEnd");

    return (<View wrap={false} style={documentStyles.section}>
        <View style={documentStyles.heading}>
                <Text style={documentStyles.sectionHeader}>{header1}</Text>
            </View>
            <View style={{paddingLeft: 15, paddingRight: 15}}>
                <FixedWidthTable
                    data={{rows: flangedSideRows}}
                />
            </View>
        { flexSideSpans.length > 0 && <View>
            <View style={documentStyles.heading}>
                    <Text style={documentStyles.sectionHeader}>{header2}</Text>
                </View>
                <View style={{paddingLeft: 15, paddingRight: 15}}>
                    <FixedWidthTable
                        data={{rows: flexSideRows}}
                    />
                </View>
            </View>
        }
        </View>
    );
};

export default SpanInformationTable;