export class Vertex {

    // private memebers:
    private x: number;
    private y: number;

    // constructor:
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    // public accessors:
    public get X(): number {
        return this.x;
    }
    public get Y(): number {
        return this.y;
    }
}