import { Point, Polygon, area, booleanDisjoint, destination, sector } from "@turf/turf";
import { IOptimizedSystem } from "..";
import { combinations } from "../combinations";
import { OPTIMIZATION_CONSTANTS } from "../constants";
import wrapSpanOptimizer2 from "./wrapSpan2";

interface IArgs {
    optimizedPartialPivot: IOptimizedSystem;
    maxNumberOfWrapSpans?: number;
    bufferedWheelObstacles: Polygon[];
    bufferedObstacles: Polygon[];
    bufferedFieldBoundary: Polygon;
}

const optimize = (args: IArgs) => {

    const { bufferedWheelObstacles, bufferedObstacles, bufferedFieldBoundary } = args;

    const baseOptimizedPartialPivot = structuredClone(args.optimizedPartialPivot);
    if (baseOptimizedPartialPivot.spanTowers.length - 2 < 1) {
        // then not enough spans to wrap on, return the incomming system
        console.log("exiting wrap optimizer early")
        return args.optimizedPartialPivot;
    }

    // START: Add the additional lengths
    // Note, the spanf LengthInFeet function adds 2 ft to the begining of a pivot
    // and 1 ft to the end if there is no end boom
    // To handle this in the wrap optimizer, the spans include these lengths.
    // They are removed again before returning
    baseOptimizedPartialPivot.spanTowers[0].spanLength += 2;
    if (!(baseOptimizedPartialPivot.endBoom || baseOptimizedPartialPivot.sac)) {
        baseOptimizedPartialPivot.spanTowers[baseOptimizedPartialPivot.spanTowers.length - 1].spanLength += 1;
    }
    // END: Add the additional lengths

    let maxNumberOfWrapSpans = args.maxNumberOfWrapSpans === undefined 
        ? 1 
        : Math.min(args.maxNumberOfWrapSpans, baseOptimizedPartialPivot.spanTowers.length - 1);
    const combs = combinations(baseOptimizedPartialPivot.spanTowers.length - 2, maxNumberOfWrapSpans);
    const systemLength = baseOptimizedPartialPivot.spanTowers.reduce((partialSum, a) => partialSum + a.spanLength + (a.extension ? 4 : 0), 0) + (baseOptimizedPartialPivot.endBoom || 0);

    let bestArea = 0;
    let bestOptimizedPartialPivot = baseOptimizedPartialPivot;
    const fwdPivotSector = sector(
        baseOptimizedPartialPivot.center,
        systemLength,
        baseOptimizedPartialPivot.clockwiseCompassHeadingEnd! - 0.1,
        baseOptimizedPartialPivot.clockwiseCompassHeadingEnd!,
        { units: 'feet' }
    ).geometry;
    const aftPivotSector = sector(
        baseOptimizedPartialPivot.center,
        systemLength,
        baseOptimizedPartialPivot.clockwiseCompassHeadingStart!,
        baseOptimizedPartialPivot.clockwiseCompassHeadingStart! + 0.1,
        { units: 'feet' }
    ).geometry;

    const wheelObstacles = bufferedWheelObstacles
        .filter(wo => {
            return !bufferedObstacles.some(o => !booleanDisjoint(o, wo))
        });
    
    const combinationResultsMap: {
        [spanCommaKey: string]: {
            remainingLengthFeet: number;
            optimizedPartialPivot: IOptimizedSystem;
            fwdObstacles: Polygon[];
            aftObstacles: Polygon[];
            finalSpan: number;
            wrapAreas: number;
            previousFwdCenter: Point;
            previousAftCenter: Point;
            previousFwdBearing: number;
            previousAftBearing: number;
        }
    } = {};

    for (const comb of combs) {
        // const t = Date.now();

        const partialComb = comb.slice(0,-1).join(",");
        const previousCombination = combinationResultsMap[partialComb];

        if (!previousCombination && comb.length !== 1) {
            // This should not occur, incase it does we will skip this combination:
            continue;
        }

        let remainingLengthFeet: number;
        let incommingOptimizedPartialPivot: IOptimizedSystem;
        let incommingFwdObstacles: Polygon[];
        let incommingAftObstacles: Polygon[];
        let remainingSpan: number;
        let fromSpan: number;
        let wrapAreas: number;
        let previousFwdCenter: Point;
        let previousAftCenter: Point;
        let previousFwdBearing: number;
        let previousAftBearing: number;
        if (previousCombination) {
            remainingLengthFeet = previousCombination.remainingLengthFeet;
            incommingOptimizedPartialPivot = previousCombination.optimizedPartialPivot;
            incommingFwdObstacles = previousCombination.fwdObstacles;
            incommingAftObstacles = previousCombination.aftObstacles;
            remainingSpan = comb.slice(-1)[0];
            fromSpan = previousCombination.finalSpan + 1;
            wrapAreas = previousCombination.wrapAreas;
            previousFwdCenter = previousCombination.previousFwdCenter;
            previousAftCenter = previousCombination.previousAftCenter;
            previousFwdBearing = previousCombination.previousFwdBearing;
            previousAftBearing = previousCombination.previousAftBearing;
        }
        else {
            remainingLengthFeet = systemLength;
            incommingOptimizedPartialPivot = baseOptimizedPartialPivot;
            // we dont want the wraps to collide with the pivot along the same side, and so, consider only the opposite side of the pivot sector
            incommingFwdObstacles = [ ...bufferedObstacles, aftPivotSector ];
            incommingAftObstacles = [ ...bufferedObstacles, fwdPivotSector ];
            remainingSpan = comb[0];
            fromSpan = 0;
            wrapAreas = 0;
            previousFwdCenter = baseOptimizedPartialPivot.center;
            previousAftCenter = baseOptimizedPartialPivot.center;
            previousFwdBearing = baseOptimizedPartialPivot.clockwiseCompassHeadingEnd!;
            previousAftBearing = baseOptimizedPartialPivot.clockwiseCompassHeadingStart!;
        }
        const optimizedPartialPivotDeepCopy: IOptimizedSystem = structuredClone(incommingOptimizedPartialPivot);
        const fwdObstacles = [ ...incommingFwdObstacles ];
        const aftObstacles = [ ...incommingAftObstacles ];

        let spanTower:  {
            spanLength: number;
            clockwiseWrapAngleRelativeToPreviousSpanDegrees?: number;
            anticlockwiseWrapAngleRelativeToPreviousSpanDegrees?: number;
        } | undefined = undefined;

        let l = 0;
        while (fromSpan <= remainingSpan) {
            spanTower = optimizedPartialPivotDeepCopy.spanTowers[fromSpan];
            remainingLengthFeet -= spanTower.spanLength; 
            l += spanTower.spanLength;
            fromSpan++;
        }
        previousFwdCenter = destination(previousFwdCenter, l, previousFwdBearing, { units: 'feet' }).geometry;
        previousAftCenter = destination(previousAftCenter, l, previousAftBearing, { units: 'feet' }).geometry;

        // The spans are those past this tower
        const wrapSpans = optimizedPartialPivotDeepCopy.spanTowers.slice(remainingSpan + 1 );
        const fwdWrapSpan = wrapSpanOptimizer2({
            fieldBoundary: bufferedFieldBoundary,
            radiusFeet: remainingLengthFeet,
            obstacles: fwdObstacles,
            minBearing: previousAftBearing,
            maxBearing: previousFwdBearing,
            center: previousFwdCenter,
            spans: wrapSpans.map(x => x.spanLength + (x.extension ? 4 : 0)),
            wheelObstacles
        });
        if (fwdWrapSpan) {
            const wrapSector = sector(
                previousFwdCenter,
                remainingLengthFeet,
                previousFwdBearing,
                previousFwdBearing + fwdWrapSpan,
                { units: 'feet', steps: OPTIMIZATION_CONSTANTS.SECTOR_STEPS }
            )
            wrapAreas += area(wrapSector);
            aftObstacles.push(wrapSector.geometry);
            previousFwdBearing = previousFwdBearing + fwdWrapSpan 
            spanTower.clockwiseWrapAngleRelativeToPreviousSpanDegrees = fwdWrapSpan;
        }

        const aftWrapSpan = wrapSpanOptimizer2({
            fieldBoundary: bufferedFieldBoundary,
            radiusFeet: remainingLengthFeet,
            obstacles: aftObstacles,
            minBearing: previousAftBearing,
            maxBearing: previousFwdBearing,
            center: previousAftCenter,
            aft: true,
            spans: wrapSpans.map(x => x.spanLength + (x.extension ? 4 : 0)),
            wheelObstacles
        });
        if (aftWrapSpan) {
            const wrapSector = sector(
                previousAftCenter,
                remainingLengthFeet,
                previousAftBearing - aftWrapSpan,
                previousAftBearing,
                { units: 'feet', steps: OPTIMIZATION_CONSTANTS.SECTOR_STEPS }
            )
            wrapAreas += area(wrapSector);
            fwdObstacles.push(wrapSector.geometry);
            previousAftBearing = previousAftBearing - aftWrapSpan 
            spanTower.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees = aftWrapSpan;
        }

        if (wrapAreas > bestArea) {
            bestArea = wrapAreas;
            bestOptimizedPartialPivot = optimizedPartialPivotDeepCopy;
        }   

        
        combinationResultsMap[comb.join(",")] = {
            aftObstacles,
            finalSpan: remainingSpan,
            remainingLengthFeet,
            optimizedPartialPivot: optimizedPartialPivotDeepCopy,
            fwdObstacles,
            wrapAreas,
            previousFwdCenter,
            previousFwdBearing,
            previousAftCenter,
            previousAftBearing
        }

        // const d = Date.now() - t;
        // if (d > maxDelta) maxDelta = d;
    }
    
    // START: Remove the additional lengths
    // See begin of fn for reason why they were added
    bestOptimizedPartialPivot.spanTowers[0].spanLength -= 2;
    if (!(bestOptimizedPartialPivot.endBoom || bestOptimizedPartialPivot.sac)) {
        bestOptimizedPartialPivot.spanTowers[bestOptimizedPartialPivot.spanTowers.length - 1].spanLength -= 1;
    }
    // END: Remove the additional lengths

    return bestOptimizedPartialPivot;
}

export default optimize;