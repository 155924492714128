import { ISystemBase } from "../../../../rdptypes/project/ISystemBase.AutoGenerated";
import { canHaveVRI } from "../../../roe_migration/Valids.Valids";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "vri",
            title: "VRI",
            position: 9,
            disableDealerSettings: true,
            visible: (sys: ISystemBase) => {
                return canHaveVRI(sys);
            },
            components: [
                {
                    type: "vri",
                },
            ],
        },
    ],
};