import schema from "../data/poSchema";
import CSection2 from "./CSection2";
import PartsAssembler from "./PartsAssembler";
export class CheckValvesClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/
    }
    public WritePO = (PO: PartsAssembler) => {
        if (this.Quote.System.CheckValves.CV4InchQty > 0) {
            PO.InsertPart(schema.groups.CheckValves.groupId, "119105", this.Quote.System.CheckValves.CV4InchQty); // VALVE-CHEM CV4RL-4
        }
        if (this.Quote.System.CheckValves.CV6InchQty > 0) {
            PO.InsertPart(schema.groups.CheckValves.groupId, "119106", this.Quote.System.CheckValves.CV6InchQty); // VALVE-CHEM CV6RL-6"
        }
        if (this.Quote.System.CheckValves.CV8InchQty > 0) {
            PO.InsertPart(schema.groups.CheckValves.groupId, "119107", this.Quote.System.CheckValves.CV8InchQty); // VALVE-CHEM CV8RL-8
        }
        if (this.Quote.System.CheckValves.CV10InchQty > 0) {
            PO.InsertPart(schema.groups.CheckValves.groupId, "119108", this.Quote.System.CheckValves.CV10InchQty); // VALVE-CHEM CV10RL-10"
        }
        if (this.Quote.System.CheckValves.CV12InchQty > 0) {
            PO.InsertPart(schema.groups.CheckValves.groupId, "119109", this.Quote.System.CheckValves.CV12InchQty); // VALVE-CHEM CV12RL-12
        }
        if (this.Quote.System.CheckValves.Gasket6InchQty > 0) {
            PO.InsertPart(schema.groups.CheckValves_FlangeGaskets.groupId, "119136", this.Quote.System.CheckValves.Gasket6InchQty); // GASKET-6" FLG CHECK VLV
        }
        if (this.Quote.System.CheckValves.Gasket8InchQty > 0) {
            PO.InsertPart(schema.groups.CheckValves_FlangeGaskets.groupId, "119138", this.Quote.System.CheckValves.Gasket8InchQty); // GASKET-8" FLG CHECK VLV
        }
        if (this.Quote.System.CheckValves.Gasket10InchQty > 0) {
            PO.InsertPart(schema.groups.CheckValves_FlangeGaskets.groupId, "119139", this.Quote.System.CheckValves.Gasket10InchQty); // GASKET-10" X 3/16" 2PLY CI
        }
        if (this.Quote.System.CheckValves.Flange616Qty > 0) {
            PO.InsertPart(schema.groups.CheckValves_FlangeOptions.groupId, "129119", this.Quote.System.CheckValves.Flange616Qty); // FLANGE-CHECK VLV-6-1/16 ID
        }
        if (this.Quote.System.CheckValves.Flange6Qty > 0) {
            PO.InsertPart(schema.groups.CheckValves_FlangeOptions.groupId, "129120", this.Quote.System.CheckValves.Flange6Qty); // FLANGE-8 OD X 6 ID-CHECK VLV
        }
        if (this.Quote.System.CheckValves.Flange816Qty > 0) {
            PO.InsertPart(schema.groups.CheckValves_FlangeOptions.groupId, "119124", this.Quote.System.CheckValves.Flange816Qty); // FLANGE-8-1/16IDX13.5 OD 8 HOLE
        }
        if (this.Quote.System.CheckValves.Flange8to6Qty > 0) {
            PO.InsertPart(schema.groups.CheckValves_FlangeOptions.groupId, "149104-G", this.Quote.System.CheckValves.Flange8to6Qty); // ADAPTER-8 X 6-CHECK VALVE
        }
        if (this.Quote.System.CheckValves.Flange8PlainQty > 0) {
            PO.InsertPart(schema.groups.CheckValves_FlangeOptions.groupId, "149105", this.Quote.System.CheckValves.Flange8PlainQty); // ADAPTER-8" DRESSER COUP
        }
    }
}