import { createNewUpdateLayoutPropertyAction } from "../../../../actions/UpdateLayoutProperty";
import IAuthState from "../../../../auth/IAuthState";
import { IDrawUpdateExtEvent_SegmentUpdated } from "../../../../components/Map/SegmentSelectMode";
import IDbProject from "../../../../db/IDbProject";
import IFieldBoundary from "../../../../model/project/IFieldBoundary";
import IObstacle from "../../../../model/project/IObstacle";
import IPivotCenterBoundary from "../../../../model/project/IPivotCenterBoundary";
import IWetAreaBoundary from "../../../../model/project/IWetAreaBoundary";

export const handleSegmentUpdated = (
    event: IDrawUpdateExtEvent_SegmentUpdated,
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState,
) => {
    const feature = event.definition.feature;
    const id = feature.id;
    const rdpFeatureType = feature.properties?.rdpFeatureType as string | undefined;
    if (!rdpFeatureType || !id) {
        return;
    }
    const project = dbPrj.state;
    const layout = project.layouts[layoutId!];
    switch (rdpFeatureType) {
        // polygon types:
        case "fieldBoundary": {
            const { definition } = feature.properties as { definition: IFieldBoundary };
            if (!definition) return;
            const fieldBoundary = layout.fieldBoundary;
            if (fieldBoundary) {
                fieldBoundary.segments = definition.segments;
                console.log("Updating fieldBoundary", fieldBoundary);
                dbPrj.pushAction(
                    createNewUpdateLayoutPropertyAction(
                        layoutId,
                        "fieldBoundary",
                        layout.fieldBoundary,
                        authState
                    )
                )
            }
            break;
        }
        case "pivotCenterBoundary": {
            const { definition } = feature.properties as { definition: IPivotCenterBoundary };
            if (!definition) return;
            const pivotCenterBoundary = layout.pivotCenterBoundary;
            if (pivotCenterBoundary) {
                pivotCenterBoundary.segments = definition.segments;
                console.log("Updating pivotCenterBoundary", pivotCenterBoundary);
                dbPrj.pushAction(
                    createNewUpdateLayoutPropertyAction(
                        layoutId,
                        "pivotCenterBoundary",
                        layout.pivotCenterBoundary,
                        authState
                    )
                )
            }
            break;
        }
        case "wetAreaBoundary": {
            const { definition } = feature.properties as { definition: IWetAreaBoundary };
            if (!definition) return;
            const wetAreaBoundary = layout.wetAreaBoundary;
            if (wetAreaBoundary) {
                wetAreaBoundary.segments = definition.segments;
                console.log("Updating wetAreaBoundary", wetAreaBoundary);
                dbPrj.pushAction(
                    createNewUpdateLayoutPropertyAction(
                        layoutId,
                        "wetAreaBoundary",
                        layout.wetAreaBoundary,
                        authState
                    )
                )
            }
            break;
        }
        case "obstacle": {
            const { obstacleIndex, definition } = feature.properties as { obstacleIndex: number, definition: IObstacle };
            if (obstacleIndex === undefined || layout.obstacles[obstacleIndex] === undefined || !definition) return;
            const obs = layout.obstacles[obstacleIndex];
            if (obs) {
                obs.segments = definition.segments;
                dbPrj.pushAction(
                    createNewUpdateLayoutPropertyAction(
                        layoutId,
                        "obstacles",
                        layout.obstacles,
                        authState
                    )
                )
            }
            break;
        }
        case "wheelObstacle": {
            const { wheelObstacleIndex, definition } = feature.properties as { wheelObstacleIndex: number, definition: IObstacle };
            if (wheelObstacleIndex === undefined || layout.wheelObstacles[wheelObstacleIndex] === undefined || !definition) return;
            const obs = layout.wheelObstacles[wheelObstacleIndex];
            if (obs) {
                obs.segments = definition.segments;
                dbPrj.pushAction(
                    createNewUpdateLayoutPropertyAction(
                        layoutId,
                        "wheelObstacles",
                        layout.wheelObstacles,
                        authState
                    )
                )
            }
            break;
        }
    }
}