import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import { FC } from "react";
import { Interaction } from "../../interactionState";

export type ICloseBoundaryResponse = {
    interaction: Interaction.DrawBoundary | Interaction.DrawWetAreaBoundary | Interaction.DrawPivotCenterBoundary;
    message: string;
};
interface Props {
    onClose: (args: ICloseBoundaryResponse) => void;
    onCancel: () => void;
    open: boolean;
    hasFieldBoundary: boolean;
}

enum BoundaryTypes {
    Equipment,
    WetArea,
    PivotCenter,
}

const AddBoundaryDialog: FC<Props> = (props) => {
    const handleClick = (type: BoundaryTypes) => {
        let response: ICloseBoundaryResponse | null = null;
        switch (type) {
            case BoundaryTypes.Equipment:
                response = {
                    interaction: Interaction.DrawBoundary,
                    message: "Please draw the boundary polygon by clicking on the corners in sequence",
                };
                break;
            case BoundaryTypes.WetArea:
                response = {
                    interaction: Interaction.DrawWetAreaBoundary,
                    message: "Please draw the wet area polygon by clicking on the corners in sequence",
                };
                break;
            case BoundaryTypes.PivotCenter:
                response = {
                    interaction: Interaction.DrawPivotCenterBoundary,
                    message: "Please draw the pivot center polygon by clicking on the corners in sequence",
                };
                break;
        }
        if (response) {
            props.onClose(response);
        }
    };

    const ns = "map-display.";

    return (
        <Dialog onClose={() => props.onCancel()} open={props.open}>
            <DialogTitle>{i18next.format(`${i18next.t(ns + "select-boundary-type")}`, 'capitalize-each')}</DialogTitle>
            <List sx={{ pt: 0 }}>
                {
                    !props.hasFieldBoundary && (
                        <ListItem>
                            <ListItemButton autoFocus onClick={() => handleClick(BoundaryTypes.Equipment)}>
                                <ListItemText sx={{ textAlign: "center" }} primary={i18next.format(`${i18next.t("equipment")}`, 'capitalize-each')} />
                            </ListItemButton>
                        </ListItem>
                    )
                }
                <ListItem>
                    <ListItemButton autoFocus onClick={() => handleClick(BoundaryTypes.WetArea)}>
                        <ListItemText sx={{ textAlign: "center" }} primary={i18next.format(`${i18next.t("wet-area")}`, 'capitalize-each')} />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton autoFocus onClick={() => handleClick(BoundaryTypes.PivotCenter)}>
                        <ListItemText sx={{ textAlign: "center" }} primary={i18next.format(`${i18next.t("pivot-center")}`, 'capitalize-each')} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Dialog>
    );
};

export default AddBoundaryDialog;
