import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from "@mui/material";
import * as React from "react";
import { FC, useState } from "react";

interface Props {
    open: boolean;
    onClose: () => any;
    onOk: (numberOfZones: number) => any;
}

const NumberOfZonesDialog: FC<Props> = (props) => {
    const [numberOfZones, setNumberOfZones] = useState(6);
    
    return (
        <Dialog
            open={props.open}
            onClose={() => {props.onClose()}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
            Number of zones
          <IconButton
            aria-label="close"
            onClick={() => {props.onClose()}}
            sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
              <DialogContentText>
                Please select the number of zones.
              </DialogContentText>
              <TextField
                type="number"
                autoFocus
                id="number-of-zones"
                fullWidth
                variant="standard"
                value={numberOfZones}
                onChange={(e) => {
                    setNumberOfZones(parseInt(e.target.value));
                }}
              />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {props.onOk(numberOfZones);}}>OK</Button>
          </DialogActions>
      </Dialog>
    );
};

export default NumberOfZonesDialog;