import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";

import { BBox, bbox, buffer, ellipse, Feature } from "@turf/turf";
import { t } from "i18next";
import DbCtx from "../../db/DbCtx";
import DevSettingsCtx from "../../db/DevSettingsCtx";
import { BoundaryHelper } from "../../GeometryHelpers/BoundaryHelper";
import { GeometryCtx } from "../../GeometryHelpers/GeometryProvider";
import Map, { IDrawUpdateExtEvent } from "../Map";
import { IMapContext } from "../Map/mapContext";
interface Props {
    children?: React.ReactNode;
    layoutId: string;
    projectId: string;

    // If defined, the active system id prop will render only that system as active.
    // If not defined, all systems will be rendered active
    // Currently, this active status is used by the mapStyles
    activeSystemIds?: string[];

    drawMode?: string | { mode: string, options: any};
    onDrawModeChanged?: (newDrawMode: string) => any;
    onDrawCreate?: (features: Feature[], map: IMapContext) => any;
    onDrawUpdateExt?: (event: IDrawUpdateExtEvent) => any;
    onDrawSelectionChange?: (args: { features: Feature[] }) => void;

    additionalFeatures?: Feature[]; // allow additional 1-off features to be rendered
    
    interactive?: boolean;
    draw_system_mode?: string | undefined; 
    showProposalGeneratedWarning?: boolean;
}

const LayoutMap: FC<Props> = ({ additionalFeatures, drawMode, draw_system_mode, ...props }) => {
    const dbState = useContext(DbCtx);
    const geometryCtx = useContext(GeometryCtx);

    const { projectId, layoutId } = props;
    const dbProject = dbState.projects[projectId!];
    const project = dbProject.state;
    const layout = project.layouts[layoutId!];

    const getDrawFeatures = (drawMode?: string | { mode: string, options: { test: string }}) => {
        let checkedDrawMode: string | undefined = undefined;
        if (drawMode) {
            checkedDrawMode = (typeof drawMode === 'string') ? drawMode : drawMode.mode
        }
        const features =  [
            ...geometryCtx.project.drawFeatures,
            ...geometryCtx.layout.drawFeatures,
            ...geometryCtx.system.drawFeatures(props.activeSystemIds, checkedDrawMode)
        ]
        if (additionalFeatures) features.push(...additionalFeatures);;
        return features;
    }

    const [ drawFeatures, setDrawFeatures ] = useState([]);
    const devSettingsState = useContext(DevSettingsCtx);

    useEffect(() => {
        setDrawFeatures(getDrawFeatures(draw_system_mode));
    }, [geometryCtx, draw_system_mode, props.activeSystemIds, dbState, projectId, layoutId, additionalFeatures, devSettingsState.dealerSettings.display.current, devSettingsState.mapSettings.showClearances]);

    //need to wait for dbState to update after pushAction
    if (!project.approximateLocation) {
        return <>{t("map-display.loading")}...</>;
    }

    let initialBounds: BBox;
    if (layout.fieldBoundary) {
        initialBounds = bbox(buffer(BoundaryHelper.getPolygon(layout.fieldBoundary), 0.1));
    } else {
        initialBounds = bbox(ellipse(project.approximateLocation!, 5, 5));
    }

    const mapDealerSettings = devSettingsState.dealerSettings.map;

    return (
        <Map
            // bounds only applies when the map is first drawn. The Map does not respond to
            // changes to its bounds prop.
            bounds={initialBounds as [number, number, number, number]}
            drawMode={drawMode}
            onDrawModeChanged={props.onDrawModeChanged}
            onDrawCreate={props.onDrawCreate}
            onDrawUpdateExt={props.onDrawUpdateExt}
            drawFeatures={drawFeatures}
            interactive={props.interactive}
            children={props.children}
            onDrawSelectionChange={props.onDrawSelectionChange}
            projectId={projectId}
            layoutId={layoutId}
            showProposalGeneratedWarning={props.showProposalGeneratedWarning}
        />
    );
}

export default LayoutMap;