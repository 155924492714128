import Avatar from "@mui/material/Avatar";
import * as React from "react";
import { FC } from "react";

// Copied from https://mui.com/material-ui/react-avatar/
const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

// Copied from https://mui.com/material-ui/react-avatar/
const stringAvatar = (name: string) => {
    let initials;
    if (name.length) {
        initials = name[0];
        const re = /([ -])(\S)/g;

        let match = re.exec(name);
        if (match) {
            if (match[1] === "-") {
                initials += "-";
            }
            initials += match[2].toUpperCase();
        }
        
        match = re.exec(name);
        if (match) {
            if (match[1] === "-") {
                initials += "-";
                initials += match[2].toUpperCase();
            }
        }
    } else {
        initials = "?";
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
            width: 24,
            height: 24,
            fontSize: initials.length < 3 ? 12 : initials.length < 4 ? 11 : 10,
        },
        children: initials,
    };
}

interface Props {
    name: string;
}

const NameAvatar: FC<Props> = (props) => {
    return (
        <Avatar
            {...stringAvatar(props.name)}
        />
    );
}

export default NameAvatar;