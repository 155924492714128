import * as React from "react";
import ReinkeIcon from "../../components/Icons/ReinkeIcon";
import { primaryColor } from "../roe/styles";

interface Props {
    title: string;
    inline?: boolean;
}

const Spinner: React.FC<Props> = ({inline, title}) => {
    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: inline ? "left" : "center", width: "100%", height: "100%", flexDirection: inline ? "row" : "column", gap: inline ? "10px" : 0}}>
            <div className="coin">
                <ReinkeIcon height={inline ? 40 : 60} width={inline ? 40 : 60}/>
            </div>
            <p style={{color: inline ? "auto" : primaryColor, textTransform: inline ? "none" : "uppercase", fontWeight: inline ? "normal" : "bold"}}>{title}</p>
        </div>
    );
}

export default Spinner;