import {
    FilledInputProps,
    InputProps,
    OutlinedInputProps,
    TextField,
    TextFieldProps
} from "@mui/material";
import { Units, convertLength } from "@turf/turf";
import * as React from "react";


interface Props {
    value: number;
    setValue: (value: number) => void;
    originalUnit: Units;
    displayUnit: Units;
    textFieldProps?: TextFieldProps;
    isValid?: (value: number) => boolean;
    invertedUnits?: boolean;
    fullWidth?: boolean;
    InputProps?: Partial<FilledInputProps> | Partial<OutlinedInputProps> | Partial<InputProps>
}

const pattern = /^[0-9]\d*(\.)?(\d+)?$/

const convertLengthWithInversion = (value: number, originalUnit: Units, targetUnits: Units, invert: boolean) => {
    if (originalUnit === targetUnits) {
        return value;
    }
    if (invert) {
        return convertLength(value, originalUnit, targetUnits);
    }
    else {
        return value * convertLength(1, targetUnits, originalUnit);
    }
}

const PositiveConvertedDecimalField: React.FC<Props> = ({
    value, setValue, originalUnit, displayUnit, textFieldProps = {}, isValid = () => true, invertedUnits = false,
    InputProps, fullWidth = undefined
}) => {

    const [ displayValue, setDisplayValue ] = React.useState(
        convertLengthWithInversion(value, originalUnit, displayUnit, invertedUnits).toString()
    )
    const [ hasValueChanged, setHasValueChanged ] = React.useState(false);
    const [isError, setIsError] = React.useState((!isValid(value)) || false)
            
    React.useEffect(() => {
        if (!hasValueChanged) return;
        const valueDisplay = parseFloat(displayValue);
        const valueOriginal = convertLengthWithInversion(valueDisplay, displayUnit, originalUnit, invertedUnits);
        if (valueOriginal !== value) {
            if (isValid(valueOriginal)) {
                setValue(valueOriginal);
            }
        }
    }, [ displayValue ]);

    const testAndSet = (incommingValue: string) => {
        const t = pattern.test(incommingValue)
        if (!t) return; 
        const nextValue = Number(incommingValue);
        if (!Number.isNaN(nextValue)) {
            setHasValueChanged(true);
            setDisplayValue(incommingValue);
            setIsError(!isValid(convertLengthWithInversion(nextValue, displayUnit, originalUnit, invertedUnits)));
        }
    }

    let helperTextNodes: React.ReactNode[] = [];
    if (isError) {
        helperTextNodes.push(<b>{`Stored value is ${value}.`}</b>)
    }
    if (textFieldProps.helperText) {
        helperTextNodes.push(textFieldProps.helperText);
    }
    const helperText = helperTextNodes.length
        ? (
            <>
                {
                    helperTextNodes.map((n,idx) => {
                        if (idx === helperTextNodes.length - 1) {
                            return (
                                <React.Fragment key={idx}>
                                    {n}
                                </React.Fragment>
                            )
                        }
                        else {
                            return (
                                <React.Fragment key={idx}>
                                    {n}
                                    <br />
                                </React.Fragment>
                            )
                        }
                    })
                }
            </>
        )
        : undefined;

    return (
        <TextField 
            {...textFieldProps}
            variant="standard"
            size="small"
            helperText={helperText}
            value={displayValue}
            onChange={(ev) => testAndSet(ev.target.value)}
            type="text"
            inputProps={{ inputMode: 'numeric' }} 
            error={isError}
            fullWidth={fullWidth}
            InputProps={InputProps}
        />
    );
};

export default PositiveConvertedDecimalField;
