import { Box } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { useContext } from "react";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import CustomDefaultsRadioGroup from "../CustomDefaultsRadioGroup";
import DealerSettingsColorPicker from "./DealerSettingsColorPicker";

export interface IDealerMapFillColor {
    id: string;
    fillColor?: string;
    lineColor?: string;
    textColor?: string;
    textHaloColor?: string;
    circleColor?: string;
}

export interface IMapSettingsFormState {
    colors: IDealerMapFillColor[];
}

export const CUSTOM_MAP_SETTINGS_DEFAULT_FORM_STATE: IMapSettingsFormState = {
    colors: []
}

const TabBody: React.FC = () => {
    
    const devSettingsState = useContext(DevSettingsCtx);
    const { setFormState, formState } = devSettingsState.dealerSettings.map.custom;
    const {colors} = formState;

    const setColors = (colors: IDealerMapFillColor[]) => setFormState({ ...formState, colors });

    return (
        <>
            <CustomDefaultsRadioGroup 
                title={t("dealer-settings.map-settings")}
                useCustomDefaults={devSettingsState.dealerSettings.map.useCustom}
                setUseCustomDefaults={devSettingsState.dealerSettings.map.setUseCustom}
            />
             {
                devSettingsState.dealerSettings.map.useCustom && (
                    <Box sx={{ overflow: "auto", flex: 1, padding: 2, width: "100%" }}>
                        <DealerSettingsColorPicker dealerColors={colors} addOrUpdateDealerColor={(color) => {
                            let dealerSettingsColors = [...colors];
                            let existingIdx = dealerSettingsColors.findIndex(x => x.id === color.id);

                            if (existingIdx === - 1){
                                dealerSettingsColors.push({id: color.id});
                            }

                            existingIdx = dealerSettingsColors.findIndex(x => x.id === color.id);
                            let dsc = dealerSettingsColors[existingIdx];

                            if (color.lineColor){
                                dsc.lineColor = color.lineColor;
                            }
                            if (color.fillColor){
                                dsc.fillColor = color.fillColor;
                            }
                            if (color.textColor){
                                dsc.textColor = color.textColor;
                            }
                            if (color.textHaloColor){
                                dsc.textHaloColor = color.textHaloColor;
                            }
                            if (color.circleColor){
                                dsc.circleColor = color.circleColor;
                            }

                            setColors(dealerSettingsColors);
                        }}/>
                    </Box>
                )
             }   
        </>
    );
}

export default <TabBody />;
