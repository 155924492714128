import { RadiusToAcres } from "./CommonFunctions";
import VRI_Zones from "./VRIClass.VRI_Zones";

export default class VRI_Zone {
    public InUse: boolean = false;
    public Controlled: boolean = false;

    public PrevZone: VRI_Zone = undefined;
    // '  Public NextZone As VRI_Zone

    private m_Parent: VRI_Zones = undefined;
    private m_EndingLocation: number = 0;
    private m_DeviceBeforeEndingLocation: number = 0;
    private m_DeviceAfterEndingLocation: number = 0;

    constructor(Parent: VRI_Zones) {
        this.m_Parent = Parent;
    }


    public get StartingLocation(): number {
        if (!this.PrevZone){
            return this.m_Parent.StartingLocation;
        }
        return this.PrevZone.EndingLocation;
    }
    
    public get EndingLocation(): number {
        return this.m_EndingLocation;
    }

    public SetEndingLocation(value: number, fixed: boolean = false) {
        this.m_EndingLocation = value;
        if (!this.InUse || fixed) {
            return;
        } 
        for (let i = 2; i <= this.m_Parent.OutletsInUseCount + 1; i++) {
            if (i > this.m_Parent.OutletsInUseCount || this.m_Parent.DeviceLocationByIdx(i) > this.m_EndingLocation) {
                this.m_DeviceBeforeEndingLocation = i - 1;
                this.m_DeviceAfterEndingLocation = i;
                break;
            }
        }
        // 'Tune the location
        if (this.m_DeviceAfterEndingLocation > this.m_Parent.OutletsInUseCount) {
            this.m_EndingLocation = this.m_Parent.EndingLocation;
        }
        else {
            let PrevDeviceLocation: number = this.m_Parent.DeviceLocationByIdx(this.m_DeviceBeforeEndingLocation);
            if (typeof(PrevDeviceLocation) !== "undefined") {
                this.m_EndingLocation = (this.m_Parent.DeviceLocationByIdx(this.m_DeviceAfterEndingLocation) - PrevDeviceLocation) / 2 + PrevDeviceLocation;
            }
        }
    }
    
    //     'Public ReadOnly Property DeviceBeforeStartingLocation As Integer
    //     '  Get
    //     '    If PrevZone Is Nothing Then Return 0
    //     '    Return PrevZone.DeviceBeforeEndingLocation
    //     '  End Get
    //     'End Property
    
    public get DeviceAfterStartingLocation(): number {
        if (typeof(this.PrevZone) === "undefined") return 1;
        return this.PrevZone.m_DeviceAfterEndingLocation;
    }
    
    public get DeviceBeforeEndingLocation(): number {
        return this.m_DeviceBeforeEndingLocation;
    }

    
    public get DeviceAfterEndingLocation(): number {
        return this.m_DeviceAfterEndingLocation;
    }

    public get DeviceCount(): number {
        return this.m_DeviceAfterEndingLocation - this.DeviceAfterStartingLocation;
    }

    public get Length(): number {
        return this.EndingLocation - this.StartingLocation;
    }
    
    public get Acres(): number {
        if (this.m_Parent.Maxi){
            return (this.Length * this.m_Parent.MaxiRun) / 43560;
        }
        else {
            return (RadiusToAcres(this.EndingLocation) - RadiusToAcres(this.StartingLocation)) * this.m_Parent.PercentCircle;
        }
    }

    public get MaxDiameter(): number {
        let max: number = 0;
        for (let i = this.DeviceAfterStartingLocation; i<= this.DeviceBeforeEndingLocation; i++){
            if (this.m_Parent.DeviceWateringDiameter[i] > max){
                max = this.m_Parent.DeviceWateringDiameter[i];
            }
        }

        return max;
    }
    
    private ControlledZoneIndex(): number {
        let inc: number = 0;
        if (this.Controlled && this.InUse) {
            inc = 1;
        }
        if (this.PrevZone === undefined) return inc;
        return this.PrevZone.ControlledZoneIndex() + inc;
    }
    
    public ValveBoxIndex(): number {
        if (!this.InUse) return 0;
        if (!this.Controlled) return 0;
        let i: number = this.ControlledZoneIndex();
        let k: number = Math.floor(i / 6);
        if (i % 6 === 0) {
            k -= 1;
        }
        return k + 1;
    }

    private getZoneRelay(): string {
        if (!this.InUse) return "";
        if (!this.Controlled) return "NC";

        let i = this.ControlledZoneIndex();
        let k = Math.floor(i/6);

        if (i % 6 === 0){
            k--;
        }

        return String.fromCharCode(65 + k);
    }

    private getRelayAddress(): string {
        if (!this.InUse) return "";
        if (!this.Controlled) return "";

        let j = this.ControlledZoneIndex() % 6;
        if (j === 0) j = 6;
        return j.toString();
    }

    public get ZoneRelayAddress(): string {
        return this.getZoneRelay() + this.getRelayAddress();
    }
}