import { Span } from "../Types";

export class SpanRecord {
    public Span: Span;
    public StartLocation: number = 0;
    public EndLocation: number = 0;
    public InsideDiameter: number = 0;
    public CFactor: number = 0;
    public StartRadius: number = 0;
    public EndRadius: number = 0;
}

