import { BoomBackTypes } from "../../../rdptypes/project/ISprinklers";
import { AnyPolyLineSpans } from "../OtherHelpers";
import QuoteClass from "../QuoteClass";
import { IsDualSided } from "../SystemFunctions";
import { BoomBackDeviceOptionOk, BoomBackMaterialOptionOk, BoomBackOptionOk } from "../Valids.Valids";

export default class SprinklersBoomBackForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        let sprinklerProps = this.Quote.System.SprinklerProperties;
        let sprinklers = this.Quote.System.FlangedSide.Sprinklers;
        let anyPoly = AnyPolyLineSpans(this.Quote.System);

        if (!BoomBackOptionOk(sprinklers.BoomBacks.BoomBackType, {
            AnyPoly: anyPoly,
            AnyLowProfiles: this.Quote.RightTowersClass.AnyLowProfiles(),
        })){
            this.Quote.Warnings.Add(`Flanged side Boom Back option not OK`);
        }

        if (sprinklers.BoomBacks.BoomBackType !== BoomBackTypes.None){
            if (!BoomBackDeviceOptionOk(sprinklers.BoomBacks.Device, {
                BoomBackType: sprinklers.BoomBacks.BoomBackType,
                SprinklerManufacturerType: sprinklerProps.SprinklerEngineCode,
            })){
                this.Quote.Warnings.Add(`Selected flanged side Boom Back device is not OK`);
            }
            if (!BoomBackMaterialOptionOk(sprinklers.BoomBacks.PipeMaterial, {
                Country: this.Quote.System.DealerProperties.Country,
                AnyAlum: this.Quote.RightSpansClass.AnyAluminum(),
                BoomBackType: sprinklers.BoomBacks.BoomBackType,
                HasValvesAndControls: sprinklers.BoomBacks.Valves,
            })){
                this.Quote.Warnings.Add(`Selected flanged side Boom Back material is not OK`);
            }
        }

        if (IsDualSided(this.Quote.System)){
            sprinklers = this.Quote.System.FlexSide.Sprinklers;
            if (!BoomBackOptionOk(sprinklers.BoomBacks.BoomBackType, {
                AnyPoly: anyPoly,
                AnyLowProfiles: this.Quote.LeftTowersClass.AnyLowProfiles()
            })){
                this.Quote.Warnings.Add(`Flex side Boom Back option not OK`);
            }

            if (sprinklers.BoomBacks.BoomBackType !== BoomBackTypes.None){
                if (!BoomBackDeviceOptionOk(sprinklers.BoomBacks.Device, {
                    BoomBackType: sprinklers.BoomBacks.BoomBackType,
                    SprinklerManufacturerType: sprinklerProps.SprinklerEngineCode,
                })){
                    this.Quote.Warnings.Add(`Selected flex side Boom Back device is not OK`);
                }
                if (!BoomBackMaterialOptionOk(sprinklers.BoomBacks.PipeMaterial, {
                    Country: this.Quote.System.DealerProperties.Country,
                    AnyAlum: this.Quote.LeftSpansClass.AnyAluminum(),
                    BoomBackType: sprinklers.BoomBacks.BoomBackType,
                    HasValvesAndControls: sprinklers.BoomBacks.Valves,
                })){
                    this.Quote.Warnings.Add(`Selected flex side Boom Back material is not OK`);
                }
            }
        }


    }
}