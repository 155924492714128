import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { DeviceWeights, DropTypes, HoseClamps, HoseDropWeights, HoseTopFittings, IPackage, RegulatorLocations, RegulatorTypes, UPipeFittings, UPipeReaches, UPipeTypes, WeightBottomFittings, WeightTopFittings } from "rdptypes/project/ISprinklers";
import { setSprinklerPackage } from ".";
import ISystem from "../../../../../../model/project/ISystem";
import { GetDeviceWeights, GetDropWeightOptions, GetHoseTopFittings, GetRegulatorLocationOptions, GetUPipeReachOptions, GetUPipeTypeOptions, GetUpipeFittingOptions, GetWeightBottomFittingOptions, GetWeightTopFittingOptions, getHoseBottomClampOptions } from "../../helpers/Rules";
import { getInitialPackageState } from "../../helpers/SprinklerHelper";
import { IFnExecutePropertyValueMap, IPropertyValueMap, ISprinklerPackageHoseDropValidator, ISprinklerValidatorChoiceFieldWithSetAndClear, ISprinklerValidatorFieldWithSet } from "../interfaces";

const groundClearanceValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<number> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.GroundClearance;

    const getError = () => {
        return !value;
    }

    const isError = getError();
    
    const set = (v: number) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    GroundClearance: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}
const reinkeBlueValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.ReinkeBlue;

    const allowableValues = [ false, true ];
    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    ReinkeBlue: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.ReinkeBlue);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const uPipeReachValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<UPipeReaches> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.UPipeReach;

    const getAllowableValues = () => {
        return GetUPipeReachOptions(sprinklerPackage, sys, side === SideEnum.Flex);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: UPipeReaches) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    UPipeReach: v,
                    UPipeFitting: null
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.UPipeReach);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const uPipeFittingValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<UPipeFittings> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.UPipeFitting;

    const getAllowableValues = () => {
        return GetUpipeFittingOptions(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: UPipeFittings) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    UPipeFitting: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.UPipeFitting);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const regulatorLocationValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<RegulatorLocations> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.RegulatorLocation;

    const getAllowableValues = () => {
        return GetRegulatorLocationOptions(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (sprinklerPackage.Regulator.RegulatorType === RegulatorTypes.None) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: RegulatorLocations) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    RegulatorLocation: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.RegulatorLocation);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const screwClampValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.ScrewClamp;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    ScrewClamp: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const allowableValues = [ false, true ];
    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.ScrewClamp);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const dragAdapterValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.DragAdapter;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    DragAdapter: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const allowableValues = [ false, true ];
    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.DragAdapter);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const dragSockValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.DragSock;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    DragSock: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const allowableValues = [ false, true ];
    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.DragSock);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const useKometTrussRodSlingsValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.UseKometTrussRodSlings;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    UseKometTrussRodSlings: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const allowableValues = [ false, true ];
    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.UseKometTrussRodSlings);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const devicesDoubledValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.DevicesDoubled;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                DevicesDoubled: v
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const allowableValues = [ false, true ];
    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).DevicesDoubled);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const uPipeTypeValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<UPipeTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.UPipeType;

    const getAllowableValues = () => {
        return GetUPipeTypeOptions(sprinklerPackage, sys, side === SideEnum.Flex);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: UPipeTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    UPipeType: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.UPipeType);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const hoseTopFittingValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<HoseTopFittings> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.HoseTopFitting;

    const getAllowableValues = () => {
        return GetHoseTopFittings(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: HoseTopFittings) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    HoseTopFitting: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.HoseTopFitting);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const hoseTopClampValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<HoseClamps> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.HoseTopClamp;

    const getAllowableValues = () => {
        return Object.keys(HoseClamps) as HoseClamps[];
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: HoseClamps) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    HoseTopClamp: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.HoseTopClamp);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const hoseBottomClampValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<HoseClamps> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.HoseBottomClamp;

    const getAllowableValues = () => {
        return getHoseBottomClampOptions(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: HoseClamps) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    HoseBottomClamp: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.HoseBottomClamp);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const weightValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<HoseDropWeights> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.Weight;

    const getAllowableValues = () => {
        return GetDropWeightOptions(sprinklerPackage, sys, side === SideEnum.Flex);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: HoseDropWeights) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    Weight: v,
                    WeightBottomFitting: null, 
                    WeightTopFitting: null
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.Weight);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const weightTopFittingValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<WeightTopFittings> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.WeightTopFitting;

    const getAllowableValues = () => {
        return GetWeightTopFittingOptions(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (allowableValues.length === 0) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: WeightTopFittings) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    WeightTopFitting: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.WeightTopFitting);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const weightBottomFittingValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<WeightBottomFittings> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.WeightBottomFitting;

    const getAllowableValues = () => {
        return GetWeightBottomFittingOptions(sprinklerPackage, sys, side === SideEnum.Flex);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (allowableValues.length === 0) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: WeightBottomFittings) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    WeightBottomFitting: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.WeightBottomFitting);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const deviceWeightValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<DeviceWeights> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.DeviceWeight;

    const getAllowableValues = () => {
        return GetDeviceWeights(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: DeviceWeights) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    DeviceWeight: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.DeviceWeight);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const substituteSTxHBValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.HoseDrop.SubstituteSTxHB;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                HoseDrop: {
                    ...sprinklerPackage.HoseDrop,
                    SubstituteSTxHB: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const allowableValues = [ false, true ];
    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).HoseDrop.SubstituteSTxHB);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}

export const hoseDropValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerPackageHoseDropValidator => {
    const options = {
        groundClearance: groundClearanceValidator(sys, side, packageIdx, executePvm, excludeProperties),
        reinkeBlue: reinkeBlueValidator(sys, side, packageIdx, executePvm, excludeProperties),
        uPipeReach: uPipeReachValidator(sys, side, packageIdx, executePvm, excludeProperties),
        uPipeFitting: uPipeFittingValidator(sys, side, packageIdx, executePvm, excludeProperties),
        regulatorLocation: regulatorLocationValidator(sys, side, packageIdx, executePvm, excludeProperties),
        screwClamp: screwClampValidator(sys, side, packageIdx, executePvm, excludeProperties),
        dragAdapter: dragAdapterValidator(sys, side, packageIdx, executePvm, excludeProperties),
        dragSock: dragSockValidator(sys, side, packageIdx, executePvm, excludeProperties),
        useKometTrussRodSlings: useKometTrussRodSlingsValidator(sys, side, packageIdx, executePvm, excludeProperties),
        devicesDoubled: devicesDoubledValidator(sys, side, packageIdx, executePvm, excludeProperties),
        uPipeType: uPipeTypeValidator(sys, side, packageIdx, executePvm, excludeProperties),
        hoseTopFitting: hoseTopFittingValidator(sys, side, packageIdx, executePvm, excludeProperties),
        hoseTopClamp: hoseTopClampValidator(sys, side, packageIdx, executePvm, excludeProperties),
        hoseBottomClamp: hoseBottomClampValidator(sys, side, packageIdx, executePvm, excludeProperties),
        weight: weightValidator(sys, side, packageIdx, executePvm, excludeProperties),
        weightTopFitting: weightTopFittingValidator(sys, side, packageIdx, executePvm, excludeProperties),
        weightBottomFitting: weightBottomFittingValidator(sys, side, packageIdx, executePvm, excludeProperties),
        deviceWeight: deviceWeightValidator(sys, side, packageIdx, executePvm, excludeProperties),
        substituteSTxHB: substituteSTxHBValidator(sys, side, packageIdx, executePvm, excludeProperties),
    }

    return {
        ...options,
        isError: getSide(sys, side).Sprinklers.Package[packageIdx].Drop === DropTypes.Hose
            ? Object.values(options).some(x => x.isError)
            : false
    }
}