import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { D3000Plates, DeviceTypes, IPackage, LDNChemPadTypes, LDNPadTypes, LDNTypes, SuperSprayPadTypes, idxWobbler, idxXiWob } from "rdptypes/project/ISprinklers";
import { idxRSNPlates } from "roedata/roe_migration/Valids.dto.Valids";
import { setSprinklerPackage } from ".";
import ISystem from "../../../../../../model/project/ISystem";
import { D3000SelectEnabled, DetermineIs3000FCNFromDeviceType, DetermineRSAPlateFromDeviceType, DetermineSprinklerDeviceType, DetermineSprinklerExtrasFromPackage, GetD3000PlateOptions, GetLDNChemPadTypeOptions, GetLDNPadTypeOptions, GetLDNTypeOptions, GetRSN3000Options, GetSuperSprayPadOptions, GetXiWobTypes } from "../../helpers/Rules";
import { getInitialPackageState } from "../../helpers/SprinklerHelper";
import { IFnExecutePropertyValueMap, IPropertyValueMap, ISprinklerPackagePlateValidator, ISprinklerValidatorArrayChoiceFieldWithSet, ISprinklerValidatorChoiceFieldWithSet, ISprinklerValidatorChoiceFieldWithSetAndClear, ISprinklerValidatorFieldWithSet } from "../interfaces";

const plateTypeValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<idxRSNPlates> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.userSelectedPlate
        ? DetermineRSAPlateFromDeviceType(sprinklerPackage.Device)
        : undefined;

    const getAllowableValues = () => {
        return GetRSN3000Options(sprinklerPackage, sys);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!allowableValues.length) return false;
        if (!sprinklerPackage.userSelectedPlate) return true;
        if (value === undefined || value === null) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: idxRSNPlates) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const plateExtras = DetermineSprinklerExtrasFromPackage(sprinklerPackage);
            var newType = DetermineSprinklerDeviceType({...plateExtras, plateType: v}, sprinklerPackage.Drop);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                Device: newType,
                userSelectedPlate: true
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        sprinklerPackage.userSelectedPlate = false;
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}

const platesValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorArrayChoiceFieldWithSet<D3000Plates> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = [
        sprinklerPackage.D3000.Plate1, 
        sprinklerPackage.D3000.Plate2, 
        sprinklerPackage.D3000.Plate3
    ].filter(x => !(x === undefined || x === null));

    const getAllowableValues = () => {
        return GetD3000PlateOptions(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!D3000SelectEnabled(sprinklerPackage.Device)) return false;
        if (!sprinklerPackage.D3000.Plate1) return true;
        for (const v of value) {
            if (!allowableValues.includes(v)) return true;
        }
        return false;
    }

    const isError = getError();
    
    const set = (v: D3000Plates[]) => {
        const incommingFiltered = v.filter(x => !(x === undefined || x === null));
        if (incommingFiltered.length === value.length) {
            let allEqual = true;
            for (let i = 0; i < incommingFiltered.length && allEqual; i++) {
                allEqual = (incommingFiltered[i] === value[i]);
            }
            if (allEqual) return;
        }
        let pvm: IPropertyValueMap = {};
        const clonedSys = structuredClone(sys);
        const updatedPackage: IPackage = {
            ...sprinklerPackage
        };    
        updatedPackage.D3000.Plate1 = incommingFiltered.length > 0 && incommingFiltered[0] ? incommingFiltered[0] : null;
        updatedPackage.D3000.Plate2 = incommingFiltered.length > 1 && incommingFiltered[1] ? incommingFiltered[1] : null;
        updatedPackage.D3000.Plate3 = incommingFiltered.length > 2 && incommingFiltered[2] ? incommingFiltered[2] : null;     
        pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
        executePvm(pvm);
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}
const sprinklerConverterValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.D3000.SprinklerConverter;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                D3000: {
                    ...sprinklerPackage.D3000,
                    SprinklerConverter: v
                }
            };
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}
const bubblerClipLEPAValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.D3000.BubblerClipLEPA;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                D3000: {
                    ...sprinklerPackage.D3000,
                    BubblerClipLEPA: v
                }
            };
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}
const trashBusterBodyValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.TrashBusterBody;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                TrashBusterBody: v
            };
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}
const isFCN3000Validator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = DetermineIs3000FCNFromDeviceType(sprinklerPackage.Device);

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            
            const plateExtras = DetermineSprinklerExtrasFromPackage(sprinklerPackage);
            const newType = DetermineSprinklerDeviceType({ ...plateExtras, isFCN3000: v }, sprinklerPackage.Drop);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                Device: newType
            };
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}

const ldnTypeValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<LDNTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.LDN.LDNType;

    const getAllowableValues = () => {
        return GetLDNTypeOptions(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (sprinklerPackage.Device !== DeviceTypes.SenningerLDNSpray) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: LDNTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                LDN: {
                    ...sprinklerPackage.LDN,
                    LDNType: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }
    
    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).LDN.LDNType);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const padValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<LDNPadTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.LDN.Pad;

    const getAllowableValues = () => {
        return GetLDNPadTypeOptions(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (sprinklerPackage.Device !== DeviceTypes.SenningerLDNSpray) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: LDNPadTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                LDN: {
                    ...sprinklerPackage.LDN,
                    Pad: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }
    
    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).LDN.Pad);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const chemPadValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<LDNChemPadTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.LDN.ChemPad;

    const getAllowableValues = () => {
        return GetLDNChemPadTypeOptions(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (sprinklerPackage.Device !== DeviceTypes.SenningerLDNSpray) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: LDNChemPadTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                LDN: {
                    ...sprinklerPackage.LDN,
                    ChemPad: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }
    
    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).LDN.ChemPad);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}
const bubblerShroudValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.LDN.BubblerShroud;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                LDN: {
                    ...sprinklerPackage.LDN,
                    BubblerShroud: v
                }
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}
const wobblerValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSet<idxWobbler> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = DetermineSprinklerExtrasFromPackage(sprinklerPackage).wobbler;

    const getAllowableValues = () => {
        return Object.keys(idxWobbler) as idxWobbler[];
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        return false;
    }

    const isError = getError();
    
    const set = (v: idxWobbler) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            
            const plateExtras = DetermineSprinklerExtrasFromPackage(sprinklerPackage);
            const newType = DetermineSprinklerDeviceType({ ...plateExtras, wobbler: v }, sprinklerPackage.Drop);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                Device: newType
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}
const xiWobValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSet<idxXiWob> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = DetermineSprinklerExtrasFromPackage(sprinklerPackage).xiWob;

    const getAllowableValues = () => {
        return GetXiWobTypes(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        return false;
    }

    const isError = getError();
    
    const set = (v: idxXiWob) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            
            const plateExtras = DetermineSprinklerExtrasFromPackage(sprinklerPackage);
            const newType = DetermineSprinklerDeviceType({ ...plateExtras, xiWob: v }, sprinklerPackage.Drop);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                Device: newType
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}
const wobblerType605BlueValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.Device === DeviceTypes.SenningerXcelWobbler; // TODO?? <-- there was a todonote in PlateOptions.tsx

    const isError = false;
    
    const set = (v: boolean) => {
        // pass!
    }

    return {
        isError,
        value,
        set
    }
}
const superSprayPadValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSet<SuperSprayPadTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.SuperSprayPad;

    const getAllowableValues = () => {
        return GetSuperSprayPadOptions(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (sprinklerPackage.Device !== DeviceTypes.SenningerSuperSpray) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: SuperSprayPadTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                SuperSprayPad: v
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}

export const plateValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerPackagePlateValidator => {
    const d3000 = {
        plates: platesValidator(sys, side, packageIdx, executePvm, excludeProperties),
        sprinklerConverter: sprinklerConverterValidator(sys, side, packageIdx, executePvm, excludeProperties),
        bubblerClipLEPA: bubblerClipLEPAValidator(sys, side, packageIdx, executePvm, excludeProperties),
        trashBusterBody: trashBusterBodyValidator(sys, side, packageIdx, executePvm, excludeProperties),
        isFCN3000: isFCN3000Validator(sys, side, packageIdx, executePvm, excludeProperties),
    };
    const ldn = {
        ldnType: ldnTypeValidator(sys, side, packageIdx, executePvm, excludeProperties),
        pad: padValidator(sys, side, packageIdx, executePvm, excludeProperties),
        chemPad: chemPadValidator(sys, side, packageIdx, executePvm, excludeProperties),
        bubblerShroud: bubblerShroudValidator(sys, side, packageIdx, executePvm, excludeProperties),
        wobbler: wobblerValidator(sys, side, packageIdx, executePvm, excludeProperties),
        xiWob: xiWobValidator(sys, side, packageIdx, executePvm, excludeProperties),
        wobblerType605Blue: wobblerType605BlueValidator(sys, side, packageIdx, executePvm, excludeProperties),
        superSprayPad: superSprayPadValidator(sys, side, packageIdx, executePvm, excludeProperties),
    }
    const plateType = plateTypeValidator(sys, side, packageIdx, executePvm, excludeProperties);
    return {
        plateType,
        d3000,
        ldn,
        isError: plateType.isError ||
            Object.values(d3000).some(x => x.isError) || 
            Object.values(ldn).some(x => x.isError)
    }
}