import styled from "@emotion/styled";
import { Box, Button, DialogContentText, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { t } from "i18next";
import { Position } from "rdptypes/geoTypes";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { createNewUpdateProjectPropertyAction } from "../../actions/UpdateProjectProperty";
import AuthCtx from "../../auth/AuthCtx";
import IDbProject from "../../db/IDbProject";
import Map from "../Map";
import ReactMapboxGeocoder from "../ReactMapboxGeocoder";
import DmsEditor, { DmsMode } from "./DmsEditor";
import PlssEditor from "./PlssEditor";

interface Props {
    dbPrj: IDbProject;
    defaultAddress?: string;
    defaultPosition?: Position;

    onClickBack: () => any;
    onClickNext: () => any;
};

const MapDiv = styled.div`
    position: relative;
    width: 100%;
    height: 400px;
`;

enum SearchMethod {
    address,
    latlond,
    latlondm,
    latlondms,
    plss
}

const SelectFieldLocation: FC<Props> = (props) => {
    const ns = "projects.";
    const subns = "choose-on-map."

    const authState = useContext(AuthCtx);

    const project = props.dbPrj.state;

    const [approximateLocation, setApproximateLocation] = useState(project.approximateLocation);

    const [ searchMethod, setSearchMethod ] = useState<SearchMethod>(SearchMethod.address);

    const onClickNext = () => {
        props.dbPrj.pushAction({
            ...createNewUpdateProjectPropertyAction("approximateLocation", approximateLocation, authState),
            undoMode: "block", // Don't allow undoing new project actions
        });
        props.onClickNext();
    };

    return (
        <>
            <DialogContent>
                <DialogContentText>{t(ns + subns + "choose-approx")}</DialogContentText>
                <Box my={2}>
      <FormControl>
        <InputLabel>Search</InputLabel>
                    <Select
                        value={searchMethod}
                        label="Search"
                        onChange={e => setSearchMethod(e.target.value as SearchMethod)}
                    >
                        <MenuItem value={SearchMethod.address}>Address</MenuItem>
                        <MenuItem value={SearchMethod.latlond}>Lat/Lon (D)</MenuItem>
                        <MenuItem value={SearchMethod.latlondm}>Lat/Lon (DM)</MenuItem>
                        <MenuItem value={SearchMethod.latlondms}>Lat/Lon (DMS)</MenuItem>
                        <MenuItem value={SearchMethod.plss}>PLSS</MenuItem>
                    </Select>
                    </FormControl>
                    { searchMethod === SearchMethod.address && <ReactMapboxGeocoder defaultPosition={props.defaultPosition} defaultAddress={props.defaultAddress} onGeocoderResult={coord => setApproximateLocation(coord)} /> }
                    { searchMethod === SearchMethod.latlond && <DmsEditor mode={DmsMode.d} value={approximateLocation} onChange={setApproximateLocation} /> }
                    { searchMethod === SearchMethod.latlondm && <DmsEditor mode={DmsMode.dm} value={approximateLocation} onChange={setApproximateLocation} /> }
                    { searchMethod === SearchMethod.latlondms && <DmsEditor mode={DmsMode.dms} value={approximateLocation} onChange={setApproximateLocation} /> }
                    { searchMethod === SearchMethod.plss && <PlssEditor value={approximateLocation} onChange={setApproximateLocation} /> }
                </Box>
                {approximateLocation !== undefined &&
                    <DialogContentText mt={2}>{t(ns + subns + "now-drag")}</DialogContentText>
                }
                <Box mt={2}>
                    <MapDiv>
                        {approximateLocation !== undefined &&
                            <Map center={approximateLocation} zoom={12} onMove={(coord) => {
                                setApproximateLocation(coord);
                            }} />
                        }
                    </MapDiv>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClickBack}>{t("back")}</Button>
                <Button onClick={onClickNext} disabled={approximateLocation === undefined}>{t("next")}</Button>
            </DialogActions>
        </>
    );
};

export default SelectFieldLocation;