import { SpanTypes } from "../project/ISystemBase.AutoGenerated";

export const spanTypesInches = [
    { value: SpanTypes.E2100, inches: 10, label: "E2100 10\" Galvanized Pipe", conversionLabel: (value: string) => `E2100 ${value} Galvanized Pipe` },
    { value: SpanTypes.E2085, inches: 8 + 5/8, label: "E2085 8 5/8\" Galvanized Pipe", conversionLabel: (value: string) => `E2085 ${value} Galvanized Pipe` },
    { value: SpanTypes.E2065, inches: 6 + 5/8, label: "E2065 6 5/8\" Galvanized Pipe", conversionLabel: (value: string) => `E2065 ${value} Galvanized Pipe` },
    { value: SpanTypes.E2060, inches: 6, label: "E2060 6\" Galvanized Pipe", conversionLabel: (value: string) => `E2060 ${value} Galvanized Pipe` },
    { value: SpanTypes.E2045, inches: 4 + 1/2, label: "E2045 4 1/2\" Galvanized Pipe", conversionLabel: (value: string) => `E2045 ${value} Galvanized Pipe` },
    { value: SpanTypes.E2665, inches: 6 + 5/8, label: "E2665 6 5/8\" Painted CN+ Pipe", conversionLabel: (value: string) => `E2665 ${value} Painted CN+ Pipe` },
    { value: SpanTypes.E2660, inches: 6, label: "E2660 6\" Painted CN+ Pipe", conversionLabel: (value: string) => `E2660 ${value} Painted CN+ Pipe` },
    { value: SpanTypes.AlumIV, inches: 6, label: "Alum IV 6\" Aluminium Truss, Tower and Pipe", conversionLabel: (value: string) => `Alum IV ${value} Aluminium Truss, Tower and Pipe` },
    { value: SpanTypes.A80G, inches: 8, label: "A80G 8\" Aluminium Pipe", conversionLabel: (value: string) => `A80G ${value} Aluminium Pipe` },
    { value: SpanTypes.A60G, inches: 6, label: "A60G 6\" Aluminium Pipe", conversionLabel: (value: string) => `A60G ${value} Aluminium Pipe` },
    { value: SpanTypes.S2085G, inches: 8 + 5/8, label: "S2085G 8 5/8\" Stainless Pipe (Unavailable)", conversionLabel: (value: string) => `S2085G ${value} Stainless Pipe (Unavailable)` },
    { value: SpanTypes.S2065G, inches: 6 + 5/8, label: "S2065G 6 5/8\" Stainless Pipe", conversionLabel: (value: string) => `S2065G ${value} Stainless Pipe` },
    { value: SpanTypes.PL2085G, inches: 8 + 5/8, label: "PL2085G 8 5/8\" Poly-lined", conversionLabel: (value: string) => `PL2085G ${value} Poly-lined` },
    { value: SpanTypes.PL2065G, inches: 6 + 5/8, label: "PL2065G 6 5/8\" Poly-lined", conversionLabel: (value: string) => `PL2065G ${value} Poly-lined` }
];