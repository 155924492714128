import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Button, Divider, Menu, MenuItem } from "@mui/material";
import * as React from "react";
import { useContext, useState } from "react";

import i18next, { t } from "i18next";
import { deleteLayoutAction } from "../../../../actions/DeleteLayoutAction";
import { duplicateLayoutAction } from "../../../../actions/DuplicateLayoutAction";
import AuthCtx from "../../../../auth/AuthCtx";
import ConfirmationDialog from "../../../../components/General/ConfirmationDialog";
import DbCtx from "../../../../db/DbCtx";
import NewLayoutDialog from "./NewLayoutDialog";
import RenameLayoutDialog from "./RenameLayoutDialog";

interface IProps {
    projectId: string;
    layoutId: string;
    navigateToAnotherLayout: (layoutId: string) => void;
    autoNavigateToAnotherLayout: (layoutId?: string) => void;
}

const LayoutsMenu: React.FC<IProps> = ({
    layoutId, projectId, navigateToAnotherLayout, autoNavigateToAnotherLayout
}) => {
    const authState = useContext(AuthCtx);
    const dbState = useContext(DbCtx);

    const dbProject = dbState.projects[projectId!];
    const project = dbProject.state;
    const layout = project.layouts[layoutId!];

    const [layoutsMenuTarget, setLayoutsMenuTarget] = useState<null | HTMLElement>(null);
    const [confirmDeleteLayoutDialogOpen, setConfirmDeleteLayoutDialogOpen] = useState(false);
    const [renameLayoutDialogOpen, setRenameLayoutDialogOpen] = useState(false);
    const [newLayoutDialogOpen, setNewLayoutDialogOpen] = useState(false);

    const openLayoutsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setLayoutsMenuTarget(event.currentTarget);
    };

    const closeLayoutsMenu = () => {
        setLayoutsMenuTarget(null);
    };

    const ns = "nav.layouts.";

    return (
        <>
            <Button
                endIcon={<ArrowDropDown />}
                onClick={openLayoutsMenu}
                sx={{ color: "inherit" }}
            >               
                <div style={{overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'none'}}>{layout.name}</div>
            </Button>

            <Menu
                anchorEl={layoutsMenuTarget}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={!!layoutsMenuTarget}
                onClose={closeLayoutsMenu}
            >
                {Object.keys(project.layouts).map((otherLayoutId) => (
                    <MenuItem
                        key={otherLayoutId}
                        onClick={() => {
                            navigateToAnotherLayout(otherLayoutId);
                            closeLayoutsMenu();
                        }}
                        selected={layoutId === otherLayoutId}
                    >
                        {project.layouts[otherLayoutId].name}
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem
                    onClick={() => {
                        closeLayoutsMenu();
                        setNewLayoutDialogOpen(true);
                    }}
                >
                    {t(ns + "new-layout")}
                </MenuItem>
                <MenuItem
                    disabled={Object.keys(dbProject.state.layouts).length <= 1}
                    onClick={() => {
                        closeLayoutsMenu();
                        setConfirmDeleteLayoutDialogOpen(true);
                    }}
                >
                    {i18next.format(t('delete'), 'capitalize')} {layout.name}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        closeLayoutsMenu();
                        setRenameLayoutDialogOpen(true);
                    }}
                >
                    {i18next.format(t('rename'), 'capitalize')} {layout.name}
                </MenuItem>
            </Menu>
            
            <ConfirmationDialog
                open={confirmDeleteLayoutDialogOpen}
                onConfirm={() => {
                    const { action } = deleteLayoutAction(authState, layoutId!);
                    autoNavigateToAnotherLayout(Object.keys(dbProject.state.layouts).filter(id => id !== layoutId)[0]);
                    dbProject.pushAction(action);
                    setConfirmDeleteLayoutDialogOpen(false);
                }}
                onClose={() => {
                    setConfirmDeleteLayoutDialogOpen(false);
                }}
                text={t(ns + "confirm-delete-layout")}
                title={i18next.format(t(ns + 'delete-layout'), 'capitalize-each')}
            />

            {
                newLayoutDialogOpen && (
                    <NewLayoutDialog
                        open={newLayoutDialogOpen}
                        onClose={() => {
                            setNewLayoutDialogOpen(false);
                        }}
                        onConfirm={(args) => {
                            const { action, newLayoutId } = duplicateLayoutAction(
                                authState,
                                layoutId!,
                                Object.keys(layout.systems).length,
                                {
                                    boundaries: args.boundaries,
                                    obstacles: args.obstacles,
                                    systems: args.systemIds,
                                    annotations: args.annotations
                                },
                            );
                            dbProject.pushAction(action);
                            autoNavigateToAnotherLayout(newLayoutId);
                            setNewLayoutDialogOpen(false);
                        }}
                        layout={layout}
                    />
                )
            }

            {
                renameLayoutDialogOpen && (
                    <RenameLayoutDialog
                        projectId={projectId}
                        layoutId={layoutId}
                        onClose={() => setRenameLayoutDialogOpen(false)}
                    />
                )
            }
        </>
    );
};

export default LayoutsMenu;
