import { SideEnum } from "../../../rdptypes/helpers/SideEnum";
import { CartTypes, HoseFeedTypes, SystemTypes, TowerHeights } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import { ISide } from "../../../rdptypes/project/Types";
import QuoteClass from "../QuoteClass";
import { FieldSets, IsDualSided, SwingArmAllowed } from "../SystemFunctions";
import { TowersClass } from "../TowersClass";
import { EndOfSystemTypeOptionOK, VerticalBoosterMountOK } from "../Valids.Valids";

export default class EndOfSystemForm {
    constructor(private Quote: QuoteClass, private side: SideEnum) {
    }

    public validate = (): void => {
        let flangedEOS = this.Quote.System.FlangedSide.EndOfSystem;
        if (!VerticalBoosterMountOK(flangedEOS.VerticalBoosterMount, flangedEOS.EndGun.BoosterPump)) {
            this.Quote.Warnings.Add(`Vertical booster pump mount is not OK for selected flanged side end gun booster pump.`);
        }

        if (IsDualSided(this.Quote.System)){
            let flexEOS = this.Quote.System.FlexSide.EndOfSystem;
            if (flexEOS.EndGun && !VerticalBoosterMountOK(flexEOS.VerticalBoosterMount, flexEOS.EndGun.BoosterPump)) {
                this.Quote.Warnings.Add(`Vertical booster pump mount is not OK for selected flex side end gun booster pump.`);
            }
        }
        
        if ((this.side === SideEnum.Flanged && FieldSets(this.Quote.System).FlangedEndOfSystem.DataValid()) || 
            (this.side === SideEnum.Flex && FieldSets(this.Quote.System).FlexEndOfSystem.DataValid())
        ) {
        
            let towers: TowersClass;
            let side: ISide;
            let powerTowerEndBoomOptionSelected = false;

            let st = this.Quote.System.SystemProperties.SystemType;
            let towerHeightSource = TowerHeights.Standard;

            if (this.side === SideEnum.Flanged){
                towers = this.Quote.RightTowersClass;
                side = this.Quote.System.FlangedSide;
            }
            else {
                towers = this.Quote.LeftTowersClass;
                side = this.Quote.System.FlexSide;
            }

            if (st === SystemTypes.HoseFeedMaxigator){
                let hf = this.Quote.System.Lateral?.HoseFeed;
                if (hf){
                    powerTowerEndBoomOptionSelected = hf.PowerTowerEndBoom;
                    if (hf.HoseFeedType === HoseFeedTypes.Sugargator) towerHeightSource = TowerHeights.Sugargator;
                }
            }
            else if (st === SystemTypes.CenterPivot) {
                if (this.Quote.System?.Circle?.CenterPivot?.PivotCenterHeight){
                    towerHeightSource = this.Quote.System.Circle.CenterPivot.PivotCenterHeight;
                }
            }
            else if (st === SystemTypes.CanalFeedMaxigator) {
                if (this.Quote.System?.Lateral?.CanalFeed?.Cart && this.Quote.System?.Lateral?.CanalFeed?.Cart === CartTypes.Sugargator){
                    towerHeightSource = TowerHeights.Sugargator;
                }
            }

            if (!EndOfSystemTypeOptionOK(side.EndOfSystem.EndOfSystemType, {
                Towers: towers,
                PowerTowerEndBoomOptionSelected: powerTowerEndBoomOptionSelected,
                SystemType: st,
                TowerHeightSource: towerHeightSource,
                SwingArmAllowed: SwingArmAllowed(this.Quote.System),
            })){
                this.Quote.Warnings.Add(`End of system type invalid.`);
            }
        }
    }
}