import * as React from "react";
import { PropsWithChildren, useEffect } from "react";
import EndGunWorkerContext from "./EndGunWorkerContext";

const isSupported = !!window.Worker;
let didWarn = false;

const EndGunWorkerContextWrapper: React.FC<PropsWithChildren> = ({ 
    children
}) => {
    useEffect(() => {
        if (!isSupported && !didWarn) {
            didWarn = true;
            alert("Your browser does not support workers. As a result End Guns will not be generated.")
        }
    }, [])
    
    if (!isSupported) {
        {children}
    }

    return (
        <EndGunWorkerContext>
            {children}
        </EndGunWorkerContext>
    );
}

export default EndGunWorkerContextWrapper;