import { LineString, Point } from "@turf/turf";
import { createNewUpdateLayoutPropertyAction } from "../../../../actions/UpdateLayoutProperty";
import IAuthState from "../../../../auth/IAuthState";
import { IDrawUpdateEvent_ChangeCoordinates, IDrawUpdateEvent_Move } from "../../../../components/Map";
import IDbProject from "../../../../db/IDbProject";

export const handleChangeOrMove = (
    event: IDrawUpdateEvent_ChangeCoordinates | IDrawUpdateEvent_Move,
    dbPrj: IDbProject,
    layoutId: string,
    authState: IAuthState
) => {
    if (event.definition.features.length !== 1) {
        return;
    }
    const feature = event.definition.features[0];
    const id = feature.id;
    const rdpFeatureType = feature.properties?.rdpFeatureType as string | undefined;
    if (!rdpFeatureType || !id) {
        return;
    }
    const project = dbPrj.state;
    const layout = project.layouts[layoutId!];
    switch (rdpFeatureType) {
        // line types:
        case 'canal':
        case "electricline": 
        case "waterline": 
        case "line": {
            if (feature.geometry.type !== 'LineString') return;
            const { annotationIndex } = feature.properties;
            if (annotationIndex === undefined || layout.annotations[annotationIndex] === undefined) return;
            const updatedAnnotations = structuredClone(layout.annotations);
            const line = feature.geometry as LineString;
            updatedAnnotations[annotationIndex].lineAnnotation.line = line;
            dbPrj.pushAction(
                createNewUpdateLayoutPropertyAction(
                    layoutId,
                    "annotations",
                    updatedAnnotations,
                    authState
                )
            )
            break;
        }
        
        // point types:
        case "point": 
        case "pump": {
            if (feature.geometry.type !== 'Point') return;
            const { annotationIndex } = feature.properties;
            if (annotationIndex === undefined || layout.annotations[annotationIndex] === undefined) return;
            const updatedAnnotations = structuredClone(layout.annotations);
            const p = feature.geometry as Point;
            updatedAnnotations[annotationIndex].pointAnnotation.point = p;
            dbPrj.pushAction(
                createNewUpdateLayoutPropertyAction(
                    layoutId,
                    "annotations",
                    updatedAnnotations,
                    authState
                )
            )
            break;
        }
        case "label": {
            if (feature.geometry.type !== 'Point') return;
            const { annotationIndex } = feature.properties;
            if (annotationIndex === undefined || layout.annotations[annotationIndex] === undefined) return;
            const updatedAnnotations = structuredClone(layout.annotations);
            const p = feature.geometry as Point;
            updatedAnnotations[annotationIndex].textAnnotation.point = p;
            dbPrj.pushAction(
                createNewUpdateLayoutPropertyAction(
                    layoutId,
                    "annotations",
                    updatedAnnotations,
                    authState
                )
            )
            break;
        }
    
        default:
            console.log("Unhandled update", rdpFeatureType)
            break;
    }
}