import { ISpan, ITower } from "rdptypes/project/Types";
import ISystem from "../../../model/project/ISystem";

export const hasSystemChanged = (previousSystem: ISystem | undefined, incommingSystem: ISystem): boolean => {
    if (!previousSystem) return true;
  
    const fieldGetters = [
        (a: ISystem) => a.overlapping,
        (a: ISystem) => a.SystemProperties?.SystemType,
        (a: ISystem) => a.Circle?.CenterPivot?.isPartialPivot,
        (a: ISystem) => a.Circle?.CenterPivot?.clockwiseCompassHeadingStart,
        (a: ISystem) => a.Circle?.CenterPivot?.clockwiseCompassHeadingEnd,
        (a: ISystem) => a.Circle?.SwingArm?.LeadingSwingArm,
        (a: ISystem) => a.centerPivot?.point?.coordinates[0],
        (a: ISystem) => a.centerPivot?.point?.coordinates[1],
        (a: ISystem) => a.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypePrimary,
        (a: ISystem) => a.FlangedSide?.EndOfSystem?.EndGun?.EndGunTypeSecondary,
        (a: ISystem) => a.FlangedSide?.EndOfSystem?.SwingArmLength,
        (a: ISystem) => a.FlangedSide?.EndOfSystem?.EndOfSystemType,
        (a: ISystem) => a.FlangedSide?.Span?.length,        
        (a: ISystem) => a.FlangedSide?.Tower?.length,    
        (a: ISystem) => a.endGuns?.flangedSidePrimaryThrow,    
        (a: ISystem) => a.endGuns?.flangedSideSecondaryThrow,
        (a: ISystem) => a.lateral?.canalWidthFeet,
        (a: ISystem) => a.lateral?.distanceFromCanalCenterToAftSide,
        (a: ISystem) => a.lateral?.distanceFromCanalCenterToFwdSide,
        (a: ISystem) => a.flexIsFwd,
        (a: ISystem) => a.lateral?.line?.coordinates[0][0],
        (a: ISystem) => a.lateral?.line?.coordinates[0][1],
        (a: ISystem) => a.lateral?.line?.coordinates[1][0],
        (a: ISystem) => a.lateral?.line?.coordinates[1][1]
        
    ]
    if (fieldGetters.some(fieldGetter => fieldGetter(incommingSystem) !== fieldGetter(previousSystem))) {
        return true;
    }

    // we will compare each span tower for a change. The fieldGetters check has already checked that the 
    // number of spans is the same between previous system and incomming system. Even so, we will compare
    // with a nullable spanTower incase some tower is undefined at an index.
    const spanFieldGetters = [
        (a?: ISpan) => a?.Length,
        (a?: ISpan) => a?.Extension,
        (a?: ISpan) => a?.dropSpanEndRelativeToPreviousSpanEnd,
        (a?: ISpan) => a?.dropSpanStartRelativeToPreviousSpanStart,
        (a?: ISpan) => a?.Disconnecting
    ]
    for (let i = 0; i < incommingSystem.FlangedSide?.Span?.length || 0; i++) {
        if (spanFieldGetters.some(fieldGetter => fieldGetter(incommingSystem.FlangedSide?.Span[i]) !== fieldGetter(previousSystem.FlangedSide?.Span[i]))) {
            return true;
        }
    }

    // we will compare each span tower for a change. The fieldGetters check has already checked that the 
    // number of spans is the same between previous system and incomming system. Even so, we will compare
    // with a nullable spanTower incase some tower is undefined at an index.
    const towerFieldGetters = [
        (a?: ITower) => a?.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees,
        (a?: ITower) => a?.clockwiseWrapAngleRelativeToPreviousSpanDegrees,
        (a?: ITower) => a?.WrapAroundSpan,
    ]
    for (let i = 0; i < incommingSystem.FlangedSide?.Span?.length || 0; i++) {
        if (towerFieldGetters.some(fieldGetter => fieldGetter(incommingSystem.FlangedSide?.Tower[i]) !== fieldGetter(previousSystem.FlangedSide?.Tower[i]))) {
            return true;
        }
    }
    
    
    // If we have gotten this far, then the systems are the same for this check
    return false;

}