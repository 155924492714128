import { SideEnum, getSide } from "rdptypes/helpers/SideEnum";
import { DeviceTypes, DeviceWeights, DropTypes, IPackage, KometSprayTypes, NozzleClipTypes, OutletValveTypes, RegulatorTypes, idxSprinkler } from "rdptypes/project/ISprinklers";
import { SprinklerManufacturerTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { AnyPolyLineSpans, DeterminePackageTypeFromDeviceType, IsA100_CF200 } from "roedata/roe_migration/OtherHelpers";
import { IsMaxigator } from "roedata/roe_migration/SystemFunctions";
import { PackageTypeOptionKometOK, SprinklerSenningerPackageTypeOptionOK } from "roedata/roe_migration/Valids.Valids";
import { generateSprinklerImprover } from "..";
import ISystem from "../../../../../../model/project/ISystem";
import { DetermineSprinklerDeviceType, DetermineSprinklerExtrasFromPackage, GetNozzleClipFromPackageType, GetValveTypes } from "../../helpers/Rules";
import { SecondNozzleGPMOptionValid, SpacingEnumToDouble, ThirdNozzleGPMOptionValid, getAvailableDropTypes, getInitialPackageState, getSpacingDropdownValues, getSpecificPackageTypeKeys } from "../../helpers/SprinklerHelper";
import { resetChart } from "../helpers";
import { IFnExecutePropertyValueMap, IPropertyValueMap, ISprinklerPackageValidator, ISprinklerValidator, ISprinklerValidatorChoiceFieldWithSet, ISprinklerValidatorChoiceFieldWithSetAndClear, ISprinklerValidatorChoiceFieldWithSetAndClearAndShow, ISprinklerValidatorChoiceFieldWithSet_Spacing, ISprinklerValidatorFieldWithSet } from "../interfaces";
import { fittingValidator } from "./fittingValidator";
import { hoseDropValidator } from "./hoseDropValidator";
import { plateValidator } from "./plateValidator";
import { regulatorsValidator } from "./regulatorValidator";
import { rigidDropValidator } from "./rigidDropValidator";
// import { createTimerLog } from "../../../../../../helpers/logging";

type ISelectorFn<T> = (field: ISprinklerValidatorChoiceFieldWithSetAndClear<T>) => { success: true, value: T } | { success: false };
const defaultSelector = <T>(field: ISprinklerValidatorChoiceFieldWithSetAndClear<T>): { success: true, value: T } | { success: false } => {
    if (field.allowableValues.length === 1) {
        return {
            success: true,
            value: field.allowableValues[0]
        }
    }
    return { success: false };
}
const improveField = <T>(
    sys: ISystem, pvm: IPropertyValueMap,
    ignoreProperties: string[],
    propertyKey: string,
    getter: (improver: ISprinklerValidator) => ISprinklerValidatorChoiceFieldWithSetAndClear<T>,
    selector: ISelectorFn<T> = defaultSelector,
) => {
    let newPropertyMap: IPropertyValueMap = {
        ...pvm
    }
    const improver = generateSprinklerImprover({ 
        sys, 
        executePvm: (incommingPvm) => {
            newPropertyMap = {
                ...newPropertyMap,
                ...incommingPvm
            }
        },
        excludeProperties: ignoreProperties
    });
    const field = getter(improver);
    if (field.isError) {
        const result = selector(field);
        if (result.success) {
            if (!ignoreProperties.includes(propertyKey)) {
                ignoreProperties.push(propertyKey);
                field.set(result.value);
            }
        }
        else {
            field.clear();
        }
    }

    return newPropertyMap;
}

export const setSprinklerPackage = (sys: ISystem, side: SideEnum, packageIdx: number, value: any, propertyMap: IPropertyValueMap, ignoreProperties: string[], currentProperty?: string): IPropertyValueMap => {
    // const t = createTimerLog("set.setSprinklerPackage");
    const base = side === SideEnum.Flanged
        ? "FlangedSide.Sprinklers.Package"
        : "FlexSide.Sprinklers.Package";
    const sysSide = getSide(sys, side);
    sysSide.Sprinklers.Package[packageIdx] = value;
    let newPropertyMap = {
        ...propertyMap
    }
    newPropertyMap[base + "[" + packageIdx + "]"] = value;
    if (currentProperty) {
        ignoreProperties.push(currentProperty);
    }

    newPropertyMap = resetChart(sys, newPropertyMap);
    newPropertyMap = improveField(sys, newPropertyMap,
        ignoreProperties,
        "spacing", 
        (improver) => {
            const field = improver.getSide(side).packages[packageIdx].spacing;
            // here we are setting the field to error true so the improver
            // will always update the value.
            // We dont have to worry about over setting as the setter/clear will
            // only write the update if the value is different to the value 
            // in the model
            field.isError = true;
            return field;
        },
        (field) => {
            if (field.allowableValues.length > 0) {
                const max = field.allowableValues.reduce((prev, crnt) => Math.max(prev, crnt), 0);
                return {
                    success: true, value: max
                }
            }
            return { success: false };
        }
    );
    newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "plate", (improver) => improver.getSide(side).packages[packageIdx].plate.plateType);    

    newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "regulatorType", (improver) => improver.getSide(side).packages[packageIdx].regulators.regulatorType);
    if ((sysSide.Sprinklers.Package[packageIdx].Regulator.RegulatorType ?? RegulatorTypes.None) !== RegulatorTypes.None) {
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "regulators.pressure", (improver) => improver.getSide(side).packages[packageIdx].regulators.pressure);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "regulators.thread", (improver) => improver.getSide(side).packages[packageIdx].regulators.thread);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "regulators.asNeeded", (improver) => improver.getSide(side).packages[packageIdx].regulators.asNeeded);
    }
    
    newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "nozzleClip", (improver) => improver.getSide(side).packages[packageIdx].nozzleClip);
    
    newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "drop", (improver) => improver.getSide(side).packages[packageIdx].drop);
    
    if (sysSide.Sprinklers.Package[packageIdx].Drop === DropTypes.Hose) {
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.reinkeBlue", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.reinkeBlue);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.uPipeReach", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.uPipeReach);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.uPipeFitting", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.uPipeFitting);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.regulatorLocation", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.regulatorLocation);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.uPipeType", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.uPipeType);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.hoseTopFitting", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.hoseTopFitting);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.hoseTopClamp", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.hoseTopClamp);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.hoseBottomClamp", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.hoseBottomClamp);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.weight", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.weight);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.weightTopFitting", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.weightTopFitting);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.weightBottomFitting", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.weightBottomFitting);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.deviceWeight", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.deviceWeight);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.screwClamp", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.screwClamp);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.dragAdapter", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.dragAdapter);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.dragSock", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.dragSock);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.useKometTrussRodSlings", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.useKometTrussRodSlings);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.devicesDoubled", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.devicesDoubled);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "hoseDrop.substituteSTxHB", (improver) => improver.getSide(side).packages[packageIdx].hoseDrop.substituteSTxHB);    
    }
    
    if (sysSide.Sprinklers.Package[packageIdx].Drop === DropTypes.Rigid) {
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "rigidDrop.dropType", (improver) => improver.getSide(side).packages[packageIdx].rigidDrop.dropType);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "rigidDrop.dropMaterial", (improver) => improver.getSide(side).packages[packageIdx].rigidDrop.dropMaterial);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "rigidDrop.length", (improver) => improver.getSide(side).packages[packageIdx].rigidDrop.length);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "rigidDrop.uPipeMaterial", (improver) => improver.getSide(side).packages[packageIdx].rigidDrop.uPipeMaterial);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "rigidDrop.deviceWeight", (improver) => improver.getSide(side).packages[packageIdx].rigidDrop.deviceWeight);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "rigidDrop.bottomFitting", (improver) => improver.getSide(side).packages[packageIdx].rigidDrop.bottomFitting);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "rigidDrop.reinforcementClamp", (improver) => improver.getSide(side).packages[packageIdx].rigidDrop.reinforcementClamp);
    }

    newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "valve", (improver) => improver.getSide(side).packages[packageIdx].valve);

    if (sysSide.Sprinklers.Package[packageIdx].Device === DeviceTypes.SenningerLDNSpray) {
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "ldn.ldnType", (improver) => improver.getSide(side).packages[packageIdx].plate.ldn.ldnType);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "ldn.pad", (improver) => improver.getSide(side).packages[packageIdx].plate.ldn.pad);
        newPropertyMap = improveField(sys, newPropertyMap, ignoreProperties, "ldn.chemPad", (improver) => improver.getSide(side).packages[packageIdx].plate.ldn.chemPad);
    }

    return newPropertyMap;
}

const endingLocationValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<number> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.EndingLocation;

    const isError = false;
    
    const set = (v: number) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);

            const updatedPackage: IPackage = {
                ...sprinklerPackage, 
                EndingLocation: v
            };
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}

const packageTypeValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSet<idxSprinkler> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = DeterminePackageTypeFromDeviceType(sprinklerPackage);

    const getAllowableValues = () => {                                 
        const manufacturerSpecific = getSpecificPackageTypeKeys(sys.SprinklerProperties.SprinklerEngineCode);
        switch (sys.SprinklerProperties.SprinklerEngineCode) {
            case SprinklerManufacturerTypes.Nelson:
                return manufacturerSpecific;
            case SprinklerManufacturerTypes.Komet:
                return manufacturerSpecific.filter(v => {
                    return PackageTypeOptionKometOK(
                        v, {
                            AnyPolyLinedSpans: AnyPolyLineSpans(sys)
                        }
                    )
                });
            case SprinklerManufacturerTypes.Senninger:
                return manufacturerSpecific.filter(v => {
                    return SprinklerSenningerPackageTypeOptionOK(
                        v, {
                            IsA100: IsA100_CF200(sys),
                            IsAnyPolyLinedSpans: AnyPolyLineSpans(sys)
                        }
                    )
                });
            default:
                return [];
        }
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();


    const determineKometPadFromPackageType = (packageType: idxSprinkler): KometSprayTypes | null => {
        switch (packageType) {
            case idxSprinkler.sprKometSprayBlue:
                return KometSprayTypes.Blue;
            case idxSprinkler.sprKometSprayGrey:
                return KometSprayTypes.Grey;
            case idxSprinkler.sprKometSprayBlack:
                return KometSprayTypes.Black;
            case idxSprinkler.sprKometSprayYellow:
                return KometSprayTypes.Yellow;
        }
        return null;
    }
    
    const set = (v: idxSprinkler) => {
        if (v !== value) {
            // const t = createTimerLog("set");
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);

            const kometPad = determineKometPadFromPackageType(v);
            const plateExtras = DetermineSprinklerExtrasFromPackage(sprinklerPackage);
            const newType = DetermineSprinklerDeviceType({
                ...plateExtras, 
                packageType: v
            }, sprinklerPackage.Drop);
            const endingLocation = sprinklerPackage.EndingLocation;
            const updatedPackage: IPackage = {
                ...getInitialPackageState(sprinklerPackage.PackageNumber), 
                EndingLocation: endingLocation,
                Device: newType, 
                KometSpray: { Pad: kometPad },
                UseNelson3030: v === idxSprinkler.NelsonO3030OrbitorFX,
                userSelectedPlate: false
            };    
            // t.logDeltaMS("init");
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            // t.logDeltaMS("pvm");
            executePvm(pvm);
            // t.logDeltaMS("executePvm");
        }
    }

    return {
        allowableValues,
        isError,
        value,
        set
    }
}

const useNelson3030Validator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.UseNelson3030;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);

            const updatedPackage: IPackage = {
                ...sprinklerPackage, 
                UseNelson3030: v,
                UseNelsonAD3030MT: false,
                Valve: v ? OutletValveTypes.None : undefined
            };
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}

const useNelsonAD3030MTValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<boolean> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.UseNelsonAD3030MT;

    const isError = false;
    
    const set = (v: boolean) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);

            const updatedPackage: IPackage = {
                ...sprinklerPackage, 
                UseNelsonAD3030MT: v, 
                NozzleClip: NozzleClipTypes.None
            };
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}

const spacingValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSet_Spacing => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.Spacing;

    const allowableSpacingClassValues = getSpacingDropdownValues(sprinklerPackage, sys, sysSide);
    const allowableValues = allowableSpacingClassValues.map(x => SpacingEnumToDouble(x.value));

    const getDisabled = () => {
        const packageType = DeterminePackageTypeFromDeviceType(sprinklerPackage);
        const enabled = packageType === idxSprinkler.NelsonD3000Spray || IsMaxigator(sys) || 
            packageType === idxSprinkler.NelsonA3000Accelerator || packageType === idxSprinkler.NelsonO3000Orbitor || packageType === idxSprinkler.NelsonO3030OrbitorFX ||
            packageType === idxSprinkler.NelsonS3000Spinner || packageType === idxSprinkler.NelsonR3000Rotator
            || packageType === idxSprinkler.sprSenningerSuperSpray || packageType === idxSprinkler.sprSenningerLDNSpray || packageType === idxSprinkler.sprSenningerQuadSpray
            || packageType === idxSprinkler.sprSenningerIWob || packageType === idxSprinkler.sprSenningerXiwob || packageType === idxSprinkler.sprSenningerXcelWobbler
            || packageType === idxSprinkler.sprKometSprayBlack || packageType === idxSprinkler.sprKometSprayBlue || packageType === idxSprinkler.sprKometSprayGrey || packageType === idxSprinkler.sprKometSprayYellow
            || packageType === idxSprinkler.sprKometTwisterBlack || packageType === idxSprinkler.sprKometTwisterBlue || packageType === idxSprinkler.sprKometTwisterYellow || packageType === idxSprinkler.sprKometTwisterWhite;
        return !enabled;
    }
    const isDisabled = getDisabled();

    const getError = () => {
        if (isDisabled) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: number) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage, 
                Spacing: v
            };
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties, "spacing");
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).Spacing);
    }


    return {
        allowableValues,
        isError,
        value,
        set,
        allowableSpacingClassValues,
        clear,
        isDisabled
    }
}

const nozzleClipValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<NozzleClipTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.NozzleClip;

    const getAllowableValues = () => {      
        const packageType = DeterminePackageTypeFromDeviceType(sprinklerPackage);    
        const nozzleClipOptions: NozzleClipTypes[] = GetNozzleClipFromPackageType(packageType, sprinklerPackage, sys);
        return nozzleClipOptions;
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!allowableValues.length) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: NozzleClipTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                NozzleClip: v
            };    
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).NozzleClip);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}

const secondNozzleGPMValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<number> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.SecondNozzleGPM;

    const isError = !SecondNozzleGPMOptionValid(sys, sprinklerPackage) &&
        !(sprinklerPackage.NozzleClip === NozzleClipTypes.None || nozzleClipValidator(sys, side, packageIdx, executePvm, excludeProperties).allowableValues.length === 0);
    
    const set = (v: number) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);

            const updatedPackage: IPackage = {
                ...sprinklerPackage, 
                SecondNozzleGPM: v
            };
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}

const thirdNozzleGPMValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorFieldWithSet<number> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.ThirdNozzleGPM;

    const isError = sprinklerPackage.NozzleClip === NozzleClipTypes.Triple && !ThirdNozzleGPMOptionValid(sys, sprinklerPackage);
    
    const set = (v: number) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);

            const updatedPackage: IPackage = {
                ...sprinklerPackage, 
                ThirdNozzleGPM: v
            };
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    return {
        isError,
        value,
        set
    }
}

const dropValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClear<DropTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.Drop;

    const getAllowableValues = () => {      
        return getAvailableDropTypes(sys, sprinklerPackage, sysSide);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!sprinklerPackage) return true;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: DropTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                Drop: v
            };    
            if(!GetValveTypes(updatedPackage).includes(sprinklerPackage.Valve)){ //Valve option need to be reset
                updatedPackage.Valve = null;
            }
            const isKomet = sys.SprinklerProperties.SprinklerEngineCode === SprinklerManufacturerTypes.Komet;
            if(updatedPackage.Drop === DropTypes.Hose && isKomet){ // Device Weight need to be set to default (1lb) as it is hidden on UI when Komet type
                updatedPackage.HoseDrop = {
                    ...updatedPackage.HoseDrop, 
                    DeviceWeight: DeviceWeights.OnePound
                }
            }

            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).Drop);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear
    }
}

const valveValidator = (sys: ISystem, side: SideEnum, packageIdx: number, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerValidatorChoiceFieldWithSetAndClearAndShow<OutletValveTypes> => {
    const sysSide = getSide(sys, side);
    const sprinklerPackage = sysSide.Sprinklers.Package[packageIdx];
    const value = sprinklerPackage.Valve;

    const showSection = !sprinklerPackage.UseNelson3030;

    const getAllowableValues = () => {      
        return GetValveTypes(sprinklerPackage);
    };
    const allowableValues = getAllowableValues();

    const getError = () => {
        if (!sprinklerPackage) return true;
        if (!showSection) return false;
        if (!value) return true;
        if (!allowableValues.includes(value)) return true;
        return false;
    }

    const isError = getError();
    
    const set = (v: OutletValveTypes) => {
        if (v !== value) {
            let pvm: IPropertyValueMap = {};
            const clonedSys = structuredClone(sys);
            
            const updatedPackage: IPackage = {
                ...sprinklerPackage,
                Valve: v
            };
            pvm = setSprinklerPackage(clonedSys, side, packageIdx, updatedPackage, pvm, excludeProperties);
            executePvm(pvm);
        }
    }

    const clear = () => {
        set(getInitialPackageState(sprinklerPackage.PackageNumber).Valve);
    }

    return {
        allowableValues,
        isError,
        value,
        set,
        clear,
        showSection
    }
}

export const packagesValidator = (sys: ISystem, side: SideEnum, executePvm: IFnExecutePropertyValueMap, excludeProperties: string[]): ISprinklerPackageValidator[] => {
    let hasEOS = false;
    return getSide(sys, side).Sprinklers.Package.map((pkg, idx) => {
        const options = {
            endingLocation: endingLocationValidator(sys, side, idx, executePvm, excludeProperties),
            packageType: packageTypeValidator(sys, side, idx, executePvm, excludeProperties),
            useNelson3030: useNelson3030Validator(sys, side, idx, executePvm, excludeProperties),
            useNelsonAD3030MT: useNelsonAD3030MTValidator(sys, side, idx, executePvm, excludeProperties),
            spacing: spacingValidator(sys, side, idx, executePvm, excludeProperties),
            regulators: regulatorsValidator(sys, side, idx, executePvm, excludeProperties),
            nozzleClip: nozzleClipValidator(sys, side, idx, executePvm, excludeProperties),
            secondNozzleGPM: secondNozzleGPMValidator(sys, side, idx, executePvm, excludeProperties),
            thirdNozzleGPM: thirdNozzleGPMValidator(sys, side, idx, executePvm, excludeProperties),
            drop: dropValidator(sys, side, idx, executePvm, excludeProperties),
            fitting: fittingValidator(sys, side, idx, executePvm, excludeProperties),
            rigidDrop: rigidDropValidator(sys, side, idx, executePvm, excludeProperties),
            hoseDrop: hoseDropValidator(sys, side, idx, executePvm, excludeProperties),
            valve: valveValidator(sys, side, idx, executePvm, excludeProperties),
            plate: plateValidator(sys, side, idx, executePvm, excludeProperties),
        }
        const ret =  {
            ...options,
            isError: !hasEOS
                ? Object.values(options).some(x => x.isError)
                : false
        }
        hasEOS = hasEOS || ((options.endingLocation.value ?? 0) === 0);
        return ret;
    })
}