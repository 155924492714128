import { t } from "i18next";
import { gpmToM3ph, m3phToGpm } from "../docGeneration/DocumentGenerationHelpers";

export type DisplayIrrigationRateUnits = 'gpm' | 'cmh';  
export const DisplayIrrigationRateUnitsMap = {
    gpm: {
        longName: 'GPM',
        fullName: 'gallons per minute',
        shortName: 'GPM',
        value: 'gpm' as DisplayIrrigationRateUnits
    }, 
    cmh: {
        longName: 'm3/h',
        fullName: 'cubic meters per hour',
        shortName: 'm3/h',
        value: 'cmh' as DisplayIrrigationRateUnits
    }
} as const;

export class DisplayIrrigationRateUnitBuilder {
    static shortName = (unit: DisplayIrrigationRateUnits) => DisplayIrrigationRateUnitsMap[unit].shortName;
    static longName = (unit: DisplayIrrigationRateUnits) => DisplayIrrigationRateUnitsMap[unit].longName;
    static fullName = (unit: DisplayIrrigationRateUnits) => DisplayIrrigationRateUnitsMap[unit].fullName;

    static convert = (value: number, from: DisplayIrrigationRateUnits, to: DisplayIrrigationRateUnits) => {
        if (from === to) return value;
        if (to === "gpm"){
            return m3phToGpm(value);
        }
        return gpmToM3ph(value);
    }

    private _value: number;
    private _unit: DisplayIrrigationRateUnits;
    private _formattedString = "";

    constructor(value: number, unit: DisplayIrrigationRateUnits) {
        this._value = value;
        this._unit = unit;
    }

    convert = (finalUnit: DisplayIrrigationRateUnits) => {
        this._value = DisplayIrrigationRateUnitBuilder.convert(this._value, this._unit, finalUnit);
        this._unit = finalUnit;
        return this;
    }

    appendValue = (toFixed?: number) => {
        if (toFixed === undefined) {
            this._formattedString += this._value.toString();
        }
        else {
            this._formattedString += this._value.toFixed(toFixed);
        }
        return this;
    }

    appendString = (value: string) => {
        this._formattedString += value;
        return this;
    }

    appendShortName = () => {
        this._formattedString += DisplayIrrigationRateUnitBuilder.shortName(this._unit);
        return this;
    }
    
    appendLongName = () => {
        this._formattedString += t(DisplayIrrigationRateUnitBuilder.longName(this._unit));
        return this;
    }

    appendFullName = () => {
        this._formattedString += t(DisplayIrrigationRateUnitBuilder.fullName(this._unit));
        return this;
    }

    toString = () => {
        return this._formattedString;
    }

}
