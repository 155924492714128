import * as React from "react";
import { useContext } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { DialogContent } from "@mui/material";
import i18next, { t } from "i18next";
import { deleteSystemAction } from "../../../actions/DeleteSystemAction";
import { createNewUpdateProjectPropertyAction } from "../../../actions/UpdateProjectProperty";
import AuthCtx from "../../../auth/AuthCtx";
import DbCtx from "../../../db/DbCtx";
import { ProjectState, ProjectType } from "../../../model/project/IProject";

interface IProps {
    projectId: string;
    layoutId: string;
    systemId: string;
    onCancel: () => void;
    onDelete: () => void;
    fromSystemDesign: boolean;
}

const DeleteSystemConfirmationDialog: React.FC<IProps> = ({
    projectId, layoutId, systemId, onCancel, onDelete, fromSystemDesign
}) => {
    
    const authState = useContext(AuthCtx);
    const dbState = useContext(DbCtx);    
    const dbPrj = dbState.projects[projectId];

    const handleCancel = () => {
        onCancel();
    }
    
    const handleOK = () => {
        if(dbPrj.state.projectType === ProjectType.DesignOnly){
            dbPrj.pushAction(createNewUpdateProjectPropertyAction("projectState", ProjectState.Deleted, authState));
        } else {
            dbPrj.pushAction(deleteSystemAction(layoutId, systemId, authState));
        }
        onDelete();
    }

    return (
        <Dialog open={true} onClose={handleCancel}>
            <DialogTitle>{i18next.format(t('delete-system'), 'capitalize-each')}</DialogTitle>
            <DialogContent>
                <p>{t("nav.systems.confirm-delete-system")}</p>
                {
                    dbPrj.state.projectType === ProjectType.LayoutAndDesign && fromSystemDesign && <p>
                        {t("nav.systems.delete-will-remove-field")}
                    </p>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>{t("cancel")}</Button>
                <Button onClick={handleOK}>{t("ok")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteSystemConfirmationDialog;