import { Box, Typography } from "@mui/material";
import ICard from "rdptypes/roe/ICard";
import * as React from "react";
import { FC } from "react";
import IDbProject from "../../../db/IDbProject";
import { pageStyles } from "../styles";
import ComponentListRenderer from "./ComponentListRenderer";
import IComponentRenderContext from "./IComponentRenderContext";

interface Props {
    cmp: ICard,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    ctx: IComponentRenderContext,
    ignoreImgTooltips?: boolean;
};

const BoxRenderer: FC<Props> = (props) => {
    let parentGroupLabel = props.cmp.title !== "" ? props.cmp.title : null;

    return (
        <>
            <Box>
                {props.cmp.title !== "" &&
                    <Typography sx={pageStyles.cardTitleStyle}>{props.cmp.title}</Typography>
                }         
                {props.cmp.children &&
                        <ComponentListRenderer
                            parentGroupLabel={parentGroupLabel}
                            oneColumn={false}
                            components={props.cmp.children}
                            dbPrj={props.dbPrj}
                            ignoreImgTooltips={props.ignoreImgTooltips}
                            inline={props.cmp.childrenInline}
                            layoutId={props.layoutId}
                            systemId={props.systemId}
                            ctx={props.ctx}
                             />
                }
            </Box>
        </>
    );
};

export default BoxRenderer;