import { SpanTypes, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";

class SpanProductRule {
    constructor(public FirstSpanOnFlangeSideOk = true) {

    }
}

let Rules: {[spanType: string]: {[spanLength: number]: {[systemType: string]: SpanProductRule}}};

export const GetSpanProductRule = (spType: SpanTypes, spLength: number, sysType: SystemTypes): SpanProductRule | undefined => {
    if (spType in Rules) {
        const dLengths = Rules[spType];
        if (spLength in dLengths) {
            const dSystemType = dLengths[spLength];
            if (sysType in dSystemType) {
                const rule = dSystemType[sysType];
                return rule;
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
}

const InitRule = (spType: SpanTypes, spLength: number, sysType: SystemTypes, spRule: SpanProductRule) => {
    if (!Rules[spType]) Rules[spType] = {};
    if (!Rules[spType][spLength]) Rules[spType][spLength] = {};
    Rules[spType][spLength][sysType] = spRule;
}

const Init = () => {
    Rules = {};

    InitRule(SpanTypes.E2100, 80, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2100, 99, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2100, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2100, 120, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2100, 137, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.E2085, 80, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2085, 99, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2085, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2085, 137, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2085, 140, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2085, 156, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2085, 160, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.E2085, 80, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2085, 99, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2085, 118, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2085, 137, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2085, 140, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2085, 156, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2085, 160, SystemTypes.HoseFeedMaxigator, new SpanProductRule());

    InitRule(SpanTypes.E2085, 80, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2085, 99, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2085, 118, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2085, 137, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2085, 140, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2085, 156, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2085, 160, SystemTypes.CanalFeedMaxigator, new SpanProductRule());

    InitRule(SpanTypes.E2085, 80, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.E2085, 99, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.E2085, 118, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2085, 137, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2085, 140, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2085, 156, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2085, 160, SystemTypes.KwikTow, new SpanProductRule());

    InitRule(SpanTypes.E2065, 80, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2065, 99, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2065, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2065, 137, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2065, 140, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2065, 156, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2065, 160, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2065, 175, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2065, 180, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2065, 186.7, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2065, 194, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2065, 204, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.E2065, 80, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 99, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 118, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 137, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 140, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 156, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 160, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 175, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 180, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2065, 186.7, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2065, 194, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2065, 204, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2065, 213, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.E2065, 80, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 99, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 118, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 137, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 140, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 156, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 160, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 175, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2065, 180, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2065, 186.7, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2065, 194, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2065, 204, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.E2065, 80, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.E2065, 99, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.E2065, 118, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2065, 137, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2065, 140, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2065, 156, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2065, 160, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2065, 175, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2065, 180, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2065, 186.7, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2065, 194, SystemTypes.KwikTow, new SpanProductRule());

    InitRule(SpanTypes.E2060, 80, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2060, 99, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2060, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2060, 137, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2060, 140, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2060, 156, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2060, 160, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2060, 175, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2060, 180, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2060, 186.7, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2060, 194, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2060, 204, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2060, 213, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.E2060, 80, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 99, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 118, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 137, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 140, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 156, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 160, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 175, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 180, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 186.7, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 194, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 204, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 213, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.E2060, 80, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 99, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 118, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 137, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 140, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 156, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 160, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 175, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 180, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 186.7, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 194, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 204, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 213, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.E2060, 80, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 99, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.E2060, 118, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2060, 137, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2060, 140, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2060, 156, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2060, 160, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2060, 175, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2060, 180, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2060, 186.7, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2060, 194, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2060, 213, SystemTypes.KwikTow, new SpanProductRule());

    InitRule(SpanTypes.E2045, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2045, 137, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2045, 156, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2045, 175, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2045, 194, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.E2045, 118, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2045, 137, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2045, 156, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2045, 175, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2045, 194, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.E2045, 118, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2045, 137, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2045, 156, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2045, 175, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2045, 194, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.E2045, 118, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2045, 137, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2045, 156, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2045, 175, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2045, 194, SystemTypes.KwikTow, new SpanProductRule());

    InitRule(SpanTypes.E2665, 80, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2665, 99, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2665, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2665, 137, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2665, 156, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2665, 160, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2665, 175, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2665, 194, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.E2665, 80, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 99, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 118, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 137, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 156, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 160, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 175, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 194, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2665, 204, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2665, 213, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.E2665, 80, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 99, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 118, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 137, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 156, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 160, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 175, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.E2665, 194, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.E2665, 80, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.E2665, 99, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.E2665, 118, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2665, 137, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2665, 156, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2665, 160, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2665, 175, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2665, 194, SystemTypes.KwikTow, new SpanProductRule());

    InitRule(SpanTypes.E2660, 80, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2660, 99, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2660, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2660, 137, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2660, 156, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2660, 160, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2660, 175, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2660, 194, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.E2660, 213, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.E2660, 80, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 99, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 118, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 137, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 156, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 160, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 175, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 194, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 204, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 213, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.E2660, 80, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 99, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 118, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 137, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 156, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 160, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 175, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 194, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 213, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.E2660, 80, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 99, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.E2660, 118, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2660, 137, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2660, 156, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2660, 160, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2660, 175, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2660, 194, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.E2660, 213, SystemTypes.KwikTow, new SpanProductRule());

    InitRule(SpanTypes.AlumIV, 80, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.AlumIV, 99, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.AlumIV, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.AlumIV, 137, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.AlumIV, 140, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.AlumIV, 156, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.AlumIV, 160, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.AlumIV, 80, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 99, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 118, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 137, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 140, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 156, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 160, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.AlumIV, 80, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 99, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 118, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 137, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 140, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 156, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 160, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.AlumIV, 80, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 99, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.AlumIV, 118, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.AlumIV, 137, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.AlumIV, 140, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.AlumIV, 156, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.AlumIV, 160, SystemTypes.KwikTow, new SpanProductRule(false));

    InitRule(SpanTypes.A80G, 80, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.A80G, 99, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.A80G, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.A80G, 137, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.A80G, 156, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.A80G, 160, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.A80G, 80, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A80G, 99, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A80G, 118, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A80G, 137, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A80G, 156, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A80G, 160, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.A80G, 80, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A80G, 99, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A80G, 118, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A80G, 137, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A80G, 156, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A80G, 160, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.A80G, 80, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.A80G, 99, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.A80G, 118, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.A80G, 137, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.A80G, 156, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.A80G, 160, SystemTypes.KwikTow, new SpanProductRule(false));

    InitRule(SpanTypes.A60G, 80, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.A60G, 99, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.A60G, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.A60G, 137, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.A60G, 140, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.A60G, 156, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.A60G, 160, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.A60G, 80, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 99, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 118, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 137, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 140, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 156, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 160, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.A60G, 80, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 99, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 118, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 137, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 140, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 156, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 160, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.A60G, 80, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 99, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.A60G, 118, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.A60G, 137, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.A60G, 140, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.A60G, 156, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.A60G, 160, SystemTypes.KwikTow, new SpanProductRule(false));

    InitRule(SpanTypes.S2065G, 80, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 99, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 137, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 140, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 156, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 160, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 175, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.S2065G, 80, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 99, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 118, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 137, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 140, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 156, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 160, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 175, SystemTypes.HoseFeedMaxigator, new SpanProductRule());

    InitRule(SpanTypes.S2065G, 80, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 99, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 118, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 137, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 140, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 156, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 160, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 175, SystemTypes.CanalFeedMaxigator, new SpanProductRule());

    InitRule(SpanTypes.S2065G, 80, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.S2065G, 99, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.S2065G, 118, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 137, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 140, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 156, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 160, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.S2065G, 175, SystemTypes.KwikTow, new SpanProductRule());

    InitRule(SpanTypes.PL2085G, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.PL2085G, 137, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.PL2085G, 156, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.PL2085G, 160, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.PL2085G, 118, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2085G, 137, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2085G, 156, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2085G, 160, SystemTypes.HoseFeedMaxigator, new SpanProductRule());

    InitRule(SpanTypes.PL2085G, 118, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2085G, 137, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2085G, 156, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2085G, 160, SystemTypes.CanalFeedMaxigator, new SpanProductRule());

    InitRule(SpanTypes.PL2065G, 80, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 99, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 118, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 137, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 156, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 160, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 175, SystemTypes.CenterPivot, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 194, SystemTypes.CenterPivot, new SpanProductRule());

    InitRule(SpanTypes.PL2065G, 80, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 99, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 118, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 137, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 156, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 160, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 175, SystemTypes.HoseFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 194, SystemTypes.HoseFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.PL2065G, 80, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.PL2065G, 99, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));
    InitRule(SpanTypes.PL2065G, 118, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 137, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 156, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 160, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 175, SystemTypes.CanalFeedMaxigator, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 194, SystemTypes.CanalFeedMaxigator, new SpanProductRule(false));

    InitRule(SpanTypes.PL2065G, 80, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.PL2065G, 99, SystemTypes.KwikTow, new SpanProductRule(false));
    InitRule(SpanTypes.PL2065G, 118, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 137, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 156, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 160, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 175, SystemTypes.KwikTow, new SpanProductRule());
    InitRule(SpanTypes.PL2065G, 194, SystemTypes.KwikTow, new SpanProductRule());
}

Init();