import { t } from "i18next";
import * as React from "react";
import { useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import DbCtx from "../../db/DbCtx";
import { ProjectType } from "../../model/project/IProject";

const ProjectIndex = () => {
    const { projectId } = useParams();

    const dbState = useContext(DbCtx);

    const project_ = dbState.projects[projectId!];
    if (typeof project_ === "undefined"){
        return (<>Waiting for project</>);
    }

    const project = project_.state;
    // Note that the UI ensures that each project always has at least one layout, we default
    // to the most recent.
    const allLayoutIds = Object.keys(project.layouts);
    if (!allLayoutIds.length) {
        return (
            <>Waiting for layout</>
        );
    }

    let defaultLayoutId = allLayoutIds[0];
    for (let i = 1; i < allLayoutIds.length; i++) {
        if (project.layouts[allLayoutIds[i]].lastModifiedTimestamp
            > project.layouts[defaultLayoutId].lastModifiedTimestamp) {
                defaultLayoutId = allLayoutIds[i];
        }
    }
    
    if (project.projectType === ProjectType.LayoutAndDesign) {
        const href = `/projects/${projectId}/layouts/${defaultLayoutId}/map`;
        return (
            <Navigate to={href} />
        );
    } else {
        const allSystemIds = Object.keys(project.layouts[defaultLayoutId].systems);
        if (!allSystemIds.length) {
            return (
                <>{t("waiting-for-system")}</>
            );
        }
        if (allSystemIds.length !== 1) {
            return (
                <>{t("too-many-systems")}</>
            );
        }
        const href = `/projects/${projectId}/layouts/${defaultLayoutId}/systems/${allSystemIds[0]}/design`;
        return (
            <Navigate to={href} />
        );
    }

};

export default ProjectIndex;