export default {
    pages: [
        {
            changeRequiresNewSprinklers: false,
            id: "miscItems",
            title: {"en" : "Miscellaneous Items", "es": "Miscelaneos"},
            position: 15,
            visible: () => true,
            components: [
                {
                    type: "miscItems",
                },
            ],
        },
    ],
};