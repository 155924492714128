import * as React from "react";
import { FC, PropsWithChildren, useContext } from "react";
import Login from "../routes/pages/Login";
import AuthCtx from "./AuthCtx";

const LoginWrapper: FC<PropsWithChildren> = ({ children }) => {
    const auth = useContext(AuthCtx);

    if (auth.assumedUserId && auth.loggedInUserId) {
        return children;
    } else {
        return <Login />;
    }
}

export default LoginWrapper;