import { Feature, LineString, Point, Polygon, Position, Properties, Units, destination, lineString, polygon } from "@turf/turf";
import { v4 as uuidV4 } from "uuid";

export default class FeatureHelpers {
    static GetPointDrawFeature (point: Point, properties: Properties): Feature<Point> {
        return {
            type: "Feature",
            geometry: point,
            id: uuidV4(),
            properties
        }
    }

    static GetLineArcDrawFeature = (
        center: Point, 
        radius: number, 
        bearingStart: number,
        bearingEnd: number,
        properties: Properties,
        options?: {
            units?: Units;
            degreeIncrement?: number;
            direction?: "clockwise" | "anticlockwise"
        }
    ): Feature<LineString> => {
        // instead of using turf's built in lineArc function, a custom one is used
        // so that the number of points along the arc radius can be controlled.
        // turf steps seem to be for the complete circle, not between the bearings
        // further more, turf draws short of the end bearing (up to the final step)
        
        const units: Units = (options && options.units) ? options.units : "feet";
        const degreeIncrement: number = (options && options.degreeIncrement && options.degreeIncrement > 0) 
            ? options.degreeIncrement : 0.1;
        const clockwise = (options && options.direction)
                ? options.direction !== 'anticlockwise'
                : true;
        const arcPositions: Position[] = []; 

        // start point
        arcPositions.push(
            destination(
                center,
                radius,
                bearingStart,
                { units }
            ).geometry.coordinates
        );

        // intermediate points 
        if (clockwise) {
            const iStart = Math.ceil(bearingStart / degreeIncrement) * degreeIncrement;
            const iEnd = Math.floor(bearingEnd / degreeIncrement) * degreeIncrement + (bearingStart < bearingEnd ? 0 : 360);
            for (let i = iStart; i <= iEnd; i += degreeIncrement) {
                arcPositions.push(
                    destination(
                        center,
                        radius,
                        i,
                        { units }
                    ).geometry.coordinates
                );
            }
        }
        else {
            const iStart = Math.floor(bearingStart / degreeIncrement) * degreeIncrement;
            const iEnd = Math.ceil(bearingEnd / degreeIncrement) * degreeIncrement - (bearingStart > bearingEnd ? 0 : 360);
            for (let i = iStart; i >= iEnd; i -= degreeIncrement) {
                arcPositions.push(
                    destination(
                        center,
                        radius,
                        i,
                        { units }
                    ).geometry.coordinates
                );
            }
        }
        
        // end point
        arcPositions.push(
            destination(
                center,
                radius,
                bearingEnd,
                { units }
            ).geometry.coordinates
        );

        const arc = lineString(arcPositions);
        return {
            type: "Feature",
            geometry: arc.geometry,
            id: uuidV4(),
            properties
        }
    }

    static GetSectorDrawFeature(
        center: Point, 
        radius: number, 
        bearingStart: number,
        bearingEnd: number,
        properties: Properties,
        options?: {
            units?: Units;
            degreeIncrement?: number;
        }
    ): Feature<Polygon> {
        // instead of using turf's built in sector function, a custom one is used
        // so that the number of points along the arc radius can be controlled
        // turf steps seem to be for the complete circle, not between the bearings
        // further more, turf draws short of the end bearing (up to the final step)
        const units: Units = (options && options.units) ? options.units : "feet";
        const degreeIncrement: number = (options && options.degreeIncrement && options.degreeIncrement > 0) 
            ? options.degreeIncrement : 0.1;
        const steps: number = 360 / degreeIncrement;
        
        // is this a circle?:
        if ((bearingStart % 360) === (bearingEnd) % 360) {
            return FeatureHelpers.GetCircleDrawFeature(
                center,
                radius,
                properties,
                options
            )
        }

        // then it is a sector:
        const outerArc = FeatureHelpers.GetLineArcDrawFeature(
            center,
            radius,
            bearingStart,
            bearingEnd,
            properties,
            options
        );

        // is this a circle or a sector:
        let sectorFeature = polygon([
            [
                center.coordinates,
                ...outerArc.geometry.coordinates,
                center.coordinates
            ]
        ]);

        return {
            type: "Feature",
            geometry: sectorFeature.geometry,
            id: uuidV4(),
            properties
        }
    }
    
    static GetCircleDrawFeature(
        center: Point, 
        radius: number, 
        properties: Properties,
        options?: {
            units?: Units;
            degreeIncrement?: number;
        }
    ): Feature<Polygon> {
        // instead of using turf's built in sector function, a custom one is used
        // so that the number of points along the arc radius can be controlled
        // turf steps seem to be for the complete circle, not between the bearings
        // further more, turf draws short of the end bearing (up to the final step)
        const outerArc = FeatureHelpers.GetLineArcDrawFeature(
            center,
            radius,
            0,
            360,
            null,
            options
        );

        // is this a circle or a sector:
        let circleFeature = polygon([
            outerArc.geometry.coordinates
        ]);

        return {
            type: "Feature",
            geometry: circleFeature.geometry,
            id: uuidV4(),
            properties
        }
    }

    static GetAnnulusSectorDrawFeature(
        center: Point, 
        radiusInner: number, 
        radiusOuter: number, 
        bearingStart: number,
        bearingEnd: number,
        properties: Properties,
        options?: {
            units?: Units;
            degreeIncrement?: number;
        }
    ): Feature<Polygon> {
        const units: Units = (options && options.units) ? options.units : "feet";
        const degreeIncrement: number = (options && options.degreeIncrement && options.degreeIncrement > 0) 
            ? options.degreeIncrement : 0.1;
        const steps: number = 360 / degreeIncrement;
        
        // is this a circle?:
        if ((bearingStart % 360) === (bearingEnd) % 360) {
            return FeatureHelpers.GetAnnulusDrawFeature(
                center,
                radiusInner,
                radiusOuter,
                properties,
                options
            )
        }

        // then it is a annulus sector:
        const outerArc = FeatureHelpers.GetLineArcDrawFeature(
            center,
            radiusOuter,
            bearingStart,
            bearingEnd,
            null,
            options
        );

        const innerArc = FeatureHelpers.GetLineArcDrawFeature(
            center,
            radiusInner,
            bearingStart,
            bearingEnd,
            null,
            options
        );

        // is this a circle or a sector:
        let annulusSectorFeature = polygon([
            [
                ...outerArc.geometry.coordinates,
                ...innerArc.geometry.coordinates.reverse(),
                outerArc.geometry.coordinates[0]
            ]
        ]);

        return {
            type: "Feature",
            geometry: annulusSectorFeature.geometry,
            id: uuidV4(),
            properties
        }
    }

    static GetAnnulusDrawFeature(
        center: Point, 
        radiusInner: number, 
        radiusOuter: number, 
        properties: Properties,
        options?: {
            units?: Units;
            degreeIncrement?: number;
        }
    ): Feature<Polygon> {        
        const outerCircle = FeatureHelpers.GetCircleDrawFeature(
            center,
            radiusOuter,
            null,
            options
        );
        
        const innerCircle = FeatureHelpers.GetCircleDrawFeature(
            center,
            radiusInner,
            null,
            options
        );

        // is this a circle or a sector:
        let annulusFeature = polygon([
            outerCircle.geometry.coordinates[0],
            innerCircle.geometry.coordinates[0]
        ]);

        return {
            type: "Feature",
            geometry: annulusFeature.geometry,
            id: uuidV4(),
            properties
        }
    }
}
