import { MenuItem } from "@mui/material";
import i18next from "i18next";
import * as React from "react";
import ConfirmationDialog from "../General/ConfirmationDialog";

interface Props {
    deleteProject: () => any;
    onClick?: () => void;
}

const DeleteProjectMenuItem: React.FC<Props> = ({
    deleteProject,
    onClick = () => {}
}) => {
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = React.useState(false);

    const ns = "projects.";

    return (
        <>
            <MenuItem onClick={() => {
                onClick();
                setConfirmDeleteDialogOpen(true);
            }}>{i18next.format(i18next.t(ns + "delete-field"), 'capitalize-each')}</MenuItem>
            <ConfirmationDialog
                onClose={() => {setConfirmDeleteDialogOpen(false);}}
                open={confirmDeleteDialogOpen}
                text={i18next.t(ns + "confirm-delete-field")}
                title={i18next.format(i18next.t(ns + "delete-field"), 'capitalize')}
                onConfirm={() => {
                    setConfirmDeleteDialogOpen(false);
                    deleteProject();
                }}
            />
        </>
    );
};

export default DeleteProjectMenuItem;