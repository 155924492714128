import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Box, Button, Container, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { useContext } from "react";
import AuthCtx from "../../auth/AuthCtx";
import ReinkeLogo from "../../components/Icons/ReinkeLogo";
import Spinner from "../../components/Spinner";
import { VERSION } from "../../constants";

const backgroundImage = new URL(
    '../../components/roe/componentRenderers/ProductLineChoiceRenderer/e3CardImage.jpg?as=webp&width=2000',
    import.meta.url
);

const Login = () => {
    const { instance, accounts, inProgress } = useMsal();
    
    const authState = useContext(AuthCtx);

    const selectAccount = (account: AccountInfo) => {
        authState.setLoggedInUserId(account.localAccountId);
        authState.setAssumedUserId(account.localAccountId);
    }

    const loginNewAccount = () => {
        instance.loginRedirect({
            scopes: ["openid"],
            prompt: "select_account"
        });
        return false;
    }

    const logout = (account: AccountInfo) => {
        instance.logout({
            account
        });
    }

    const ns = "login.";

    return (
        <>
            <div style={{ height: '100%', backgroundImage: "url(" + backgroundImage + ")", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display: "flex", alignItems: 'center' }} >
                <Container style={{ backgroundColor: 'white', padding: 40, borderRadius: 5, maxWidth: 500 }} >
                    <ReinkeLogo height={60} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    <h3>{t(ns + "welcome")}</h3>
                    { inProgress === "startup" && <Spinner inline title="Preparing to sign you in..." />}
                    { inProgress === "handleRedirect" && <Spinner inline title="Signing you in..." />}
                    { inProgress === "acquireToken" && <Spinner inline title="Redirecting you to the sign in page..." />}
                    { inProgress === "login" && <Spinner inline title="Redirecting you to the sign in page..." />}
                    { inProgress === "logout" && <Spinner inline title="Signing you out..." />}
                    { inProgress === "none" && accounts.length > 0 &&
                        <>
                            <h4>{t(ns + "choose-account")}</h4>
                            <List>
                            { accounts.map(account =>
                                <ListItem disablePadding
                                secondaryAction={
                                    <Button size="small"onClick={() => logout(account)}>Sign out</Button>
                                }>
                                    <ListItemButton onClick={() => selectAccount(account)}>
                                        <ListItemText primary={account.name ?? account.localAccountId} secondary={account.username} />
                                    </ListItemButton>
                                </ListItem>
                            ) }
                            </List>
                            <h4>{t(ns + "some-accounts-not-mine")} <a href="#" onClick={loginNewAccount}>{t(ns + "log-in")}</a></h4>
                        </>
                    }
                    { inProgress === "none" && !accounts.length &&
                        <Box sx={{textAlign: "center", mt: 4, mb: 4}}>
                            <Button variant="contained" onClick={loginNewAccount} size="large">Sign in</Button>
                        </Box>
                    }
                    <div><Typography variant="caption">Version: {VERSION}</Typography></div>
                </Container>
            </div>
        </>
    );
}

export default Login;